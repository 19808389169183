// import SampleTransform from '../../material/SampleTransform';
import Border3DComponentType from '../../borders/Border3DComponentType';
// import MattressDA from '../../mattress/MattressDA';
// import SampleCollector from '../../sample/SampleCollector';
// import SampleDA from '../../sample/SampleDA';
// import QuiltCollector from '../../quilt/QuiltCollector';
// import StringUtils from '../../utils/StringUtils';

import {
  Property
//  notifyPropertyChanged
} from '../utils';

// const PROPERTY_CHANGE_PARAMS = ' _property_change_params_ ';

export default {
  create(data, id, session) {
    const buildParams = null;
    const mattressData = null;

    const result = Border3DComponentType.createMaterial(data, mattressData, buildParams, null);

    result.metaData = result.metaData || {};

    const md = result.metaData;

    md.source = data;

    return result;
  },
  update(object, data, id, session) {
    // #if DEBUG
    console.log('update', object, data, id, session);
    // #endif
    /*
    const params = session ? session.getParams() : null;
    const buildParams = params ? params.buildParams : null;
    const assetCollections = buildParams ? buildParams.assetCollections : null;
    const sampleAssets = assetCollections ? assetCollections.samples : null;

    object.setColorType(data.colorType);
    object.setColorMultiplier(data.color);

    const textureData = data.texture;
    const sampleID = textureData ? textureData.id : null;
    let sampleData = null;

    if (sampleID !== null && typeof (sampleID) !== 'undefined') {
      const sample = sampleAssets.getAssetByName(sampleID);

      sampleData = sample ? sample.getSampleData() : null;

      object.setSampleAsset(sample);
    }
    let sampleTransform = object.getSampleTransform();

    if (!sampleTransform) {
      sampleTransform = new SampleTransform();
      object.setSampleTransform(sampleTransform);
    }

    sampleTransform.setSampleData(sampleData);
    sampleTransform.setSampleConfigData(textureData);

    object.setSampleData(sampleData);
    object.setSampleConfigData(textureData);


    return;
    */
  },
  getProperty(object, data, property, params) {
    const key = (property instanceof Property) ? property.getKey() : Property.getKey(property);

    if (key === 'color' || key === 'sample-color') {
      if (object && object.get) {
        return object.get('colorMultiplier');
      }
    }
    // TODO: height

    return null;
    // const isPropertyInstance = property instanceof Property;
    // const key = (isPropertyInstance) ? property.getKey() : Property.getKey(property);
    //
    // if (key) {
    //   if (StringUtils.startsWith(key, 'sample')) {
    //     const dataData = data ? data['@data'] : null;
    //
    //     const transf = object && object.getSampleTransform ? object.getSampleTransform() : null;
    //
    //     const tex = dataData ? dataData.texture : null;
    //
    //     if (key === 'sample') {
    //       return tex ? tex.id : null;
    //     } else if (key === 'sample-x-offset') {
    //       return transf ? transf.getOffsetX() : 0;
    //     } else if (key === 'sample-y-offset') {
    //       return transf ? transf.getOffsetY() : 0;
    //     } else if (key === 'sample-x-align' || key === 'sample-y-align') {
    //       return key === 'sample-x-align' ? SampleDA.getTextureAlignX(tex, 0.5) : SampleDA.getTextureAlignY(tex, 0.5);
    //     } else if (key === 'sample-rotation' || key === 'sample-rotate') {
    //       return transf ? transf.getRotation() : 0;
    //     } else if (key === 'sample-color') {
    //       return dataData ? dataData.color : null;
    //     }
    //   }
    // }
  },
  setProperty(object, data, property, value, params) {
    // #if DEBUG
    console.log('set property', object, data, property, params);
    // #endif
    const key = (property instanceof Property) ? property.getKey() : Property.getKey(property);

    if (key === 'color' || key === 'sample-color') {
      if (object && object.set) {
        object.set('colorMultiplier', value);
      }
      const dataData = data && data['@data'];

      if (dataData) {
        dataData.color = value;
      }
    }
    // TODO: height

    // const key = (property instanceof Property) ? property.getKey() : Property.getKey(property);
    // const d = data['@data'];
    // let prevValue = value;
    //
    // if (!d.texture) {
    //   d.texture = {};
    // }
    // const tex = d.texture;
    //
    // if (!tex.offset) {
    //   tex.offset = {};
    // }
    // const off = tex.offset;
    //
    // off.x = off.x || 0;
    // off.y = off.y || 0;
    // tex.rotation = tex.rotation || 0;
    //
    // const outputParams = params ? params.outputParams : null;
    // let propertyChangeParams = outputParams || this[PROPERTY_CHANGE_PARAMS];
    //
    // if (propertyChangeParams && !outputParams) {
    //   propertyChangeParams.refresh = false;
    //   propertyChangeParams.reload = false;
    //   propertyChangeParams.overrideReload = false;
    //   propertyChangeParams.overrideRefresh = false;
    // }
    //
    // if (key === 'sample') {
    //   // tex.id = value;
    //   MattressDA.setComponentSample(d, value);
    // } else if (key === 'sample-x-offset') {
    //   prevValue = off.x;
    //   off.x = value;
    // } else if (key === 'sample-y-offset') {
    //   prevValue = off.y;
    //   off.y = value;
    // } else if (key === 'sample-x-align') {
    //   SampleDA.setAlign(tex, value, null);
    // } else if (key === 'sample-y-align') {
    //   SampleDA.setAlign(tex, null, value);
    // } else if (key === 'sample-rotate') {
    //   let val = value;
    //
    //   if (typeof (val) === 'object' && val !== null) {
    //     val = val.key;
    //   }
    //   if (typeof (val) === 'string') {
    //     const INC = 90;
    //     const DEC = -INC;
    //
    //     val = val === 'decrease' || val === 'cc' ? DEC : INC;
    //   }
    //   if (typeof (val) === 'number' && !isNaN(val)) {
    //     tex.rotation = tex.rotation || 0;
    //     const MAX_ROTATION = 360;
    //
    //     tex.rotation = (((tex.rotation + val) % MAX_ROTATION) + MAX_ROTATION) % MAX_ROTATION;
    //   }
    // } else if (key === 'sample-rotation') {
    //   prevValue = tex.rotation;
    //   tex.rotation = value;
    // } else if (key === 'sample-color') {
    //   prevValue = d ? d.color : null;
    //   const prevSample = MattressDA.getComponentSampleID(d, value);
    //
    //   MattressDA.setComponentColor(d, value);
    //
    //   const curSample = MattressDA.getComponentSampleID(d, value);
    //
    //   if (prevSample !== curSample) {
    //     if (!propertyChangeParams) {
    //       propertyChangeParams = {};
    //       this[PROPERTY_CHANGE_PARAMS] = {};
    //     }
    //     propertyChangeParams.overrideRefresh = true;
    //     propertyChangeParams.refresh = true;
    //   }
      /*
      if (d) {
        d.color = value;
      }
      */
    // }

    // notifyPropertyChanged(object, data, property, value, prevValue, params, propertyChangeParams);
  }
};
