import ThreeMaterial from './ThreeMaterial';
import ThreeMaterialUtils from './ThreeMaterialUtils';
// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
// #endif
// import ColorUtils from '../utils/ColorUtils';
import * as THREE from 'three';

// let tempColorValues = null;

const shaders = {
  vertex: [
    'precision mediump float;',

    'attribute vec3 position;',
    'attribute vec3 normal;',
    'attribute vec2 uv;',
    'attribute vec2 staticUV;',
    'attribute vec4 tangent;',
    'attribute vec3 bitangent;',

    'varying vec3 v_viewPosition;',
    'varying vec3 v_viewNormal;',
    'varying vec3 v_tangent;',
    'varying vec3 v_bitangent;',

    'varying vec2 v_sampleUV;',
    'varying vec2 v_quiltUV;',
    // 'varying vec2 v_uv;',
    'varying vec2 v_staticUV;',

    'uniform mat4 projectionMatrix;',
    'uniform mat4 modelViewMatrix;',
    'uniform mat4 modelMatrix;',
    'uniform mat4 cameraWorldMatrix;',
    'uniform mat3 normalMatrix;',

    'uniform mat4 sampleTextureTransform;',
    // 'uniform vec2 sampleScale;',
    // 'uniform vec2 sampleOffset;',

    'uniform mat4 quiltTextureTransform;',
    'uniform vec2 quiltScale;',
    'uniform vec2 quiltOffset;',

    'vec2 transformUV(vec2 uv, mat4 m) {',
    ' return (m * vec4(vec3(uv, 0), 1)).xy;',
    '}',

    'void main() {',
    ' vec2 sampleUV = uv;',
    ' vec2 quiltUV = uv;',

    // ' v_uv =  uv;',

    // ' sampleUV -= sampleOffset;',
    ' sampleUV = transformUV(sampleUV, sampleTextureTransform);',
    // ' sampleUV /= sampleScale;',

    // ' quiltUV -= quiltOffset;',
    ' quiltUV = transformUV(quiltUV, quiltTextureTransform);',
    // ' quiltUV /= quiltScale;',

    ' v_sampleUV = sampleUV;',
    ' v_quiltUV = quiltUV;',

    ' v_staticUV = staticUV;',

    ' vec4 pos = vec4(position, 1.0);',
    ' vec3 tgt = normalize(tangent.xyz);',
    ' vec3 btg = normalize(bitangent.xyz) * tangent.w;',

    // ' v_tangent = (modelMatrix * vec4(tgt, 0.0)).xyz;',
    // ' v_bitangent = (modelMatrix * vec4(btg, 0.0)).xyz;',
    ' v_tangent = (cameraWorldMatrix * vec4((normalMatrix * tgt), 0.0)).xyz;',
    ' v_bitangent = (cameraWorldMatrix * vec4((normalMatrix * btg), 0.0)).xyz;',

    // ' v_viewNormal = normalize((modelViewMatrix * vec4(normal,0.0)).xyz);',
    ' v_viewNormal = normalize(normalMatrix * normal);',

    // 'v_viewNormal = normalMatrix * normal;',
    // 'v_viewNormal = normal;',
    ' pos = modelViewMatrix * pos;',
    ' v_viewPosition = pos.xyz;',
    ' pos = projectionMatrix * pos;',
    ' gl_Position = pos;',
    '}'
  ].join('\n'),
  fragment: [
    'precision mediump float;',
    'varying vec3 v_viewNormal;',
    'varying vec3 v_viewPosition;',
    'varying vec3 v_tangent;',
    'varying vec3 v_bitangent;',

    'varying vec2 v_sampleUV;',
    'varying vec2 v_quiltUV;',
    // 'varying vec2 v_uv;',
    'varying vec2 v_staticUV;',

    'uniform mat4 cameraWorldMatrix;',
    'uniform mat4 viewMatrix;',

    'uniform mat4 sampleTextureTransform;',
    'uniform mat4 quiltTextureTransform;',

    'uniform vec3 colorMultiplier;',

    // Sample uniforms
    'uniform sampler2D sampleTexture;',
    'uniform sampler2D sampleNormalMap;',
    'uniform sampler2D sampleSpecularMap;',
    'uniform float sampleNormalScale;',

    // Quilt uniforms
    'uniform float quiltOcclusionValue;',
    'uniform sampler2D quiltOcclusionMap;',
    'uniform sampler2D quiltNormalMap;',
    'uniform float quiltNormalScale;',

    'uniform vec2 quiltRepeat;',
    'uniform vec2 quiltInfiniteRepeat;',

    'uniform sampler2D staticMap;',
    'uniform sampler2D staticNormalMap;',
    'uniform float staticNormalMapScale;',

    'uniform sampler2D brdfMap;',
    'uniform float brdfFactor;',

    'uniform float specularPower;',
    'uniform float specularMultiplier;',
    'uniform float metallic;',

    'uniform float fresnelPower;',
    'uniform float fresnelMultiplier;',

    'uniform vec3 light2Translate;',
    'uniform float light2Intensity;',
    'uniform float light1Intensity;',

    // test uniform
    // 'uniform sampler2D brdf;',

    // Normalizes only the x, y and z components of a vec4
    'vec4 normalize3Vec4(vec4 v) {',
    ' v.xyz = normalize(v.xyz);',
    ' return v;',
    '}',

    // removes scaling from a matrix
    'mat4 normalizeMatrix(mat4 m) {',
    '  return mat4(normalize3Vec4(m[0]), normalize3Vec4(m[1]), normalize3Vec4(m[2]), m[3]);',
    '}',

    'vec3 getNormalPixel(sampler2D smp, vec2 uv, float scale, mat4 matrix) {',
    ' vec4 pix = texture2D(smp, uv);',
    ' vec3 res = pix.xyz * 2.0 - vec3(1.0);',
    ' res = (normalizeMatrix(matrix) * vec4(res, 0.0)).xyz;',
    ' res.xy *= scale;',
    ' return normalize(res);',
    '}',

    // Adds a tangent-space normal vector to the current normal + updates the tangents
    'void addNormal(vec3 n, inout vec3 tgt, inout vec3 btg, inout vec3 nrm) {',
    ' nrm = normalize(tgt * n.x + btg * n.y + nrm * n.z);',
    ' btg = normalize(cross(nrm, tgt));',
    ' tgt = normalize(cross(btg, nrm));',
    '}',

    'void addPixelNormal(in sampler2D smp, in vec2 uv, in float scale, mat4 nMatrix, inout vec3 tgt, inout vec3 btg, inout vec3 nrm) {',
    ' vec3 n = getNormalPixel(smp, uv, scale, nMatrix);',
    ' addNormal(n, tgt, btg, nrm);',
    '}',

    /*
    'float smootherStep(float x) {',
    ' return x * x * x * (x * (x * 6.0 - 15.0) + 10.0);',
    '}',
    */

    'float smoothT(float t) {',
    ' return (3.0 - 2.0 * t) * t * t;',
    '}',

    'float clamp01(float t) {',
    ' return clamp(t, 0.0, 1.0);',
    '}',

    /*
     * @param {float} t - Input uv rectangle value between 0 and 1
     * @return {float} - smooth interpolation of the uv rectangle border, if it fades out
     */
    'float smoothUVBorder(float t) {',
    '  return smoothT(clamp01(t));',
    '}',

    /*
     * Creates a rectangle mask in uv space: returns 1.0 for every pixel inside the 2d box,
     * 0.0 for every pixel outside the 2d rectangle
     * @param {vec2} coord - The input uv coordinate
     * @param {vec2} topLeft - The top left corner of the uv rectangle
     * @param {vec2} bottomRight - The bottom right corner of the uv rectangle
     * @param {float} fadeBorder - How much the borders should fade out. Value should be between 0 and 1
     * @param {vec2} infinity - If x = 1, the rectangle won't have left and right edges, same for y and top and bottom edges.
     * @return {float} - Value between 0 and 1. 0 is outside the rectangle, 1 is inside.
     */
    'float uvRectMask(vec2 coord, vec2 topLeft, vec2 bottomRight, float fadeBorder, vec2 infinity) {',
    '  float t = 1.0;',
    '  t *= mix((1.0 - step(coord.x, topLeft.x)), 1.0, infinity.x);',
    '  t *= mix((1.0 - step(bottomRight.x, coord.x)), 1.0, infinity.x);',
    '  t *= mix((1.0 - step(coord.y, topLeft.y)), 1.0, infinity.y);',
    '  t *= mix((1.0 - step(bottomRight.y, coord.y)), 1.0, infinity.y);',

    ' float fb = fadeBorder;',
    ' fb = clamp(fb, 0.0001, 1.0);',

    ' t *= mix(smoothUVBorder((coord.x - topLeft.x) / fb), 1.0, infinity.x);',
    ' t *= mix(smoothUVBorder((coord.y - topLeft.y) / fb), 1.0, infinity.y);',
    ' t *= mix(smoothUVBorder((bottomRight.x - coord.x) / fb), 1.0, infinity.x);',
    ' t *= mix(smoothUVBorder((bottomRight.y - coord.y) / fb), 1.0, infinity.y);',

    '  return t;',
    '}',

    'void pointLight(',
    '  vec3 lightPos,',
    '  vec3 lightColor,',
    '  vec3 lightSpecularColor,',
    '  float lightSpecularPower,',

    '  float diffuseFactor,',

    '  float minDiffuse, float maxDiffuse,',
    '  float minSpecular, float maxSpecular,',

    // surface params
    '  vec3 surfacePos,',
    '  vec3 surfaceNormal,',
    '  vec3 surfaceRefVector,',

    // output params
    '  inout vec3 outputDiffuse,',
    '  inout vec3 outputSpecular) {',

    ' vec3 LS = lightPos - surfacePos;',
    ' vec3 nLS = normalize(LS);',

    ' float diffuse = dot(nLS, surfaceNormal);',
    ' float specular = dot(nLS, surfaceRefVector);',

    ' vec2 brdfUV = vec2(0.5 * (specular + 1.0), 0.5 * (-diffuse + 1.0));',
    ' vec3 brdfColor = texture2D(brdfMap, brdfUV).xyz;',

    // Diffuse
    ' float diffRange = max(0.001, maxDiffuse - minDiffuse);',
    ' diffuse = (diffuse - minDiffuse) / diffRange;',
    ' diffuse = clamp(diffuse, 0.0, 1.0);',
    // 'diffuse = 3.0 * diffuse * diffuse - 2.0 * diffuse * diffuse * diffuse;',
    // 'diffuse = smootherStep(diffuse);',
    ' diffuse = pow(diffuse, 0.75);',
    ' diffuse = mix(1.0, diffuse, diffuseFactor);',
    ' vec3 diffColor = lightColor * diffuse;',
    ' diffColor = mix(diffColor, brdfColor * lightColor, brdfFactor);',
    ' outputDiffuse += diffColor;',

    // Specular
    ' float specularRange = max(0.001, maxSpecular - minSpecular);',
    ' specular = (specular - minSpecular) / specularRange;',
    ' specular = clamp(specular, 0.0, 1.0);',

    ' outputSpecular += lightSpecularColor * pow(specular, lightSpecularPower);',
    '}',

    'void main() {',
    ' vec3 viewNrm = normalize(v_viewNormal);',
    ' vec3 viewGeomNrm = viewNrm;',
    ' vec3 viewPos = v_viewPosition;',
    ' vec3 worldPos = (cameraWorldMatrix * vec4(viewPos, 1.0)).xyz;',

    ' vec3 geomNormal = (cameraWorldMatrix * vec4(viewNrm, 0.0)).xyz;',
    ' vec3 nrm = geomNormal;',
    ' vec3 tgt = v_tangent;',
    ' vec3 btg = v_bitangent;',

    ' vec2 quiltUV = v_quiltUV;',
    ' const float quiltBorderFade = 0.1;', // TODO: make uniform?
    // vec2 coord, vec2 topLeft, vec2 bottomRight, float fadeBorder, float infiniteX, float infiniteY
    ' float quiltUVMask = uvRectMask(quiltUV, vec2(0.0), quiltRepeat, quiltBorderFade, quiltInfiniteRepeat);',

    ' const mat4 defMatrix = mat4(vec4(1,0,0,0), vec4(0,1,0,0),vec4(0,0,1,0),vec4(1,0,0,1));',
    ' addPixelNormal(staticNormalMap, v_staticUV, staticNormalMapScale, defMatrix, tgt, btg, nrm);',
    ' addPixelNormal(sampleNormalMap, v_sampleUV, sampleNormalScale, sampleTextureTransform, tgt, btg, nrm);',
    ' addPixelNormal(quiltNormalMap, quiltUV, quiltNormalScale * quiltUVMask, quiltTextureTransform, tgt, btg, nrm);',

    ' viewNrm = normalize((viewMatrix * vec4(nrm, 0.0)).xyz);',
    ' vec3 reflVec = normalize(viewPos - viewNrm * dot(viewNrm, viewPos) * 2.0);',
    // 'vec3 reflVec = (viewPos - viewNrm * dot(viewNrm, viewPos) * 2.0);',
    ' reflVec = (cameraWorldMatrix * vec4(reflVec, 0.0)).xyz;',

    ' vec3 diffuse = vec3(0.0);',
    ' vec3 specular = vec3(0.0);',

    // frontal light
    ' vec3 lightPos = cameraWorldMatrix[3].xyz;',
    ' lightPos += (cameraWorldMatrix * vec4(light2Translate, 0.0)).xyz;',
    ' pointLight(lightPos, vec3(0.6) * light2Intensity,vec3(0.5) * specularMultiplier, specularPower, 0.5, -1.0, 1.0, 0.0, 1.0, worldPos, nrm, reflVec, diffuse, specular);',

    // static light from above
    ' lightPos.y = 150.0;',
    ' lightPos.x = 0.0;',
    ' lightPos.z = 0.0;',
    ' pointLight(lightPos, vec3(0.4) * light1Intensity,vec3(0.5) * specularMultiplier, specularPower, 1.0, -1.0, 1.0, 0.9, 1.0, worldPos, nrm, reflVec, diffuse, specular);',

    ' float fresnel = dot(normalize(viewPos), viewGeomNrm);',
    ' float minF = -1.0;',
    ' float maxF = 0.0;',
    ' fresnel = (fresnel - minF) / (maxF - minF);',
    ' fresnel = clamp(fresnel, 0.0, 1.0);',
    ' fresnel = pow(fresnel, fresnelPower);',
    ' fresnel *= fresnelMultiplier;',
    ' fresnel *= (viewGeomNrm.y + 1.0) * 0.5;',

    ' vec3 resColor = vec3(1.0);',
    ' vec4 sampleTexturePixel = texture2D(sampleTexture, v_sampleUV);',
    ' resColor *= sampleTexturePixel.xyz;',
    ' resColor *= colorMultiplier;',

    'vec4 sampleSpecularPixel = texture2D(sampleSpecularMap, v_sampleUV);',

    // 'specular *= max(vec3(0.1), resColor);',
    ' specular *= mix(vec3(1.0), (vec3(0.2) + resColor), metallic);',
    ' specular *= sampleSpecularPixel.xyz;',
    // 'specular += vec3(fresnel);',

    'vec4 quiltOcclusionPixel = texture2D(quiltOcclusionMap, quiltUV);',
    'diffuse *= mix(vec3(1.0), quiltOcclusionPixel.xyz, quiltOcclusionValue);',

    // 'diffuse *= texture2D(staticMap, v_uv).xyz;',
    'diffuse *= texture2D(staticMap, v_staticUV).xyz;',

    ' vec3 outputColorPlastic = resColor * diffuse + specular;',
    ' vec3 outputColorMetal = resColor * diffuse * (vec3(1.0) + specular * 2.0);',
    ' vec3 outputColor = mix(outputColorPlastic, outputColorMetal, metallic);',

    ' outputColor += vec3(fresnel) * resColor;',
    ' gl_FragColor = vec4(outputColor, 1.0);',

      // brdf test
      /*
      'lightPos = vec3(0,400,0);',
      'float viewSurfaceValue = clamp(dot(normalize(-viewPos), viewNrm), 0.0, 1.0);',
      'float lightSurfaceValue = clamp((dot(normalize(lightPos - worldPos), nrm) + 1.0) * 0.5, 0.0, 1.0);',
      'vec4 brdfPixel = texture2D(brdf, vec2(lightSurfaceValue, viewSurfaceValue));',
      'gl_FragColor = brdfPixel;',
      */
      // 'gl_FragColor = vec4(vec3(vec2(lightSurfaceValue, viewSurfaceValue), 0.0), 1.0);',
    '}'
  ].join('\n')
};

let fabricMaterialInstance = null;

function createColorCanvas(color) {
  const width = 8;
  const height = 8;
  const canvas = document.createElement('canvas');

  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');
  let col = color;

  if (typeof (col) === 'number') {
    const radix = 16;
    const pfx = '000000';
    const len = pfx.length;

    col = pfx + color.toString(radix).toUpperCase();
    col = col.substring(col.length - len, col.length);

    col = `#${col}`;
  }
  ctx.fillStyle = col;
  ctx.fillRect(0, 0, width, height);

  return canvas;
}

function createColorTexture(color) {
  const canvas = createColorCanvas(color);
  const tex = new THREE.Texture();

  tex.image = canvas;
  tex.needsUpdate = true;

  return tex;
}

function createColorCubeTexture(color) {
  const canvas = createColorCanvas(color);
  const tex = new THREE.CubeTexture([
    canvas, canvas, canvas,
    canvas, canvas, canvas
  ]);

  tex.needsUpdate = true;

  return tex;
}

// Uniform definitions
const uniformDefs = {
  cameraWorldMatrix: {type: 'matrix4'},

  // test uniforms
  // brdf: new THREE.Uniform(newTextureByURL('brdf/test3.jpg', {wrapS: THREE.ClampToEdgeWrapping, wrapT: THREE.ClampToEdgeWrapping})),

  sampleTexture: {type: 'texture'},
  sampleSpecularMap: {type: 'texture'},
  sampleNormalMap: {type: 'normalmap'},
  sampleNormalScale: {type: 'float', def: 1},
  sampleTextureTransform: {type: 'matrix4'},

  quiltInfiniteRepeat: {type: 'vec2', def: {x: 1, y: 1}},
  quiltRepeat: {type: 'vec2', def: {x: 1, y: 1}},

  quiltOcclusionValue: {type: 'float', def: 0},
  quiltOcclusionMap: {type: 'texture'},
  quiltNormalMap: {type: 'normalmap'},
  quiltNormalScale: {type: 'float', def: 1},
  quiltTextureTransform: {type: 'matrix4'},

  staticMap: {type: 'texture'},
  staticNormalMap: {type: 'normalmap'},
  staticNormalMapScale: {type: 'float', def: 1},

  specularPower: {type: 'float', def: 1},
  specularMultiplier: {type: 'float', def: 0},
  metallic: {type: 'float', def: 0},

  fresnelMultiplier: {type: 'float', def: 0},
  fresnelPower: {type: 'float', def: 1},

  brdfMap: {type: 'texture'},
  brdfFactor: {type: 'float', def: 0},

  color: {type: 'color', def: 0xFFFFFF}
};

let allowNewInstance = false;

export default class ThreeFabricMaterial extends ThreeMaterial {
  constructor() {
    super();
    if (!allowNewInstance) {
      throw new Error('Cannot create new ThreeFabricMaterial. Use ThreeFabricMaterial.create() instead.');
    }
    // When isRawShaderMaterial is set to true, three.js won't add custom shader code
    this.isRawShaderMaterial = true;
    this.vertexShader = this._getVertexShaderSource(); // shaders.vertex;
    this.fragmentShader = this._getFragmentShaderSource(); // shaders.fragment;
  }
  _getVertexShaderSource() {
    return ThreeFabricMaterial._getVertexShaderSource();
  }

  _getFragmentShaderSource() {
    return ThreeFabricMaterial._getFragmentShaderSource();
  }

  static _getVertexShaderSource() {
    return shaders.vertex;
  }

  static _getFragmentShaderSource() {
    return shaders.fragment;
  }

  setColorMultiplier(R, G, B) {
    const vec = this.getUniformValue('colorMultiplier');
    const newVec = ThreeMaterialUtils.colorToVec3(R, G, B, vec);

    if (newVec !== vec) {
      this.setUniformValue('colorMultiplier', newVec);
    }
    /*
    let r = R, g = G, b = B;

    const tR = typeof (R);
    const tG = typeof (G);
    const tB = typeof (B);

    if ((R === null || tR === 'undefined') &&
      (G === null || tG === 'undefined') &&
      (B === null || tB === 'undefined')) {
      r = g = b = 1.0;
    } else if (R instanceof THREE.Vector3) {
      r = R.x;
      g = R.y;
      b = R.z;
    } else if (tR === 'string' || tG !== 'number' || tB !== 'number') {
      tempColorValues = ColorUtils.colorToRGBA(R, tempColorValues);
      const div = 255;

      r = tempColorValues[0] / div;
      g = tempColorValues[1] / div;
      b = tempColorValues[2] / div;
    }
    let vec = this.getUniformValue('colorMultiplier');

    if (!vec) {
      vec = new THREE.Vector3();
      this.setUniformValue('colorMultiplier', vec);
    }
    vec.x = r;
    vec.y = g;
    vec.z = b;
    */
  }

  getColorMultiplier() {
    return this.getUniformValue('colorMultiplier');
  }

  setStaticTexture(texture) {
    let tex = texture;

    if (!tex) {
      tex = this._getDefaultTexture('texture');
    }
    this.setUniformValue('staticMap', tex);
  }

  setStaticNormalMap(texture) {
    let tex = texture;

    if (!tex) {
      tex = this._getDefaultTexture('normal');
    }
    this.setUniformValue('staticNormalMap', tex);
  }

  setStaticNormalScale(value) {
    this.setUniformValue('staticNormalMapScale', value);
  }

  getQuiltRepeatX() {
    const v = this.getUniformValue('quiltRepeat');

    if (!v) {
      return 0;
    }

    return v.x;
  }

  setQuiltRepeatX(v) {
    this.setQuiltRepeat(v, this.getQuiltRepeatY());
  }
  getQuiltRepeatY() {
    const v = this.getUniformValue('quiltRepeat');

    if (!v) {
      return 0;
    }

    return v.y;
  }

  setQuiltRepeatY(v) {
    this.setQuiltRepeat(this.getQuiltRepeatX(), v);
  }
  setQuiltRepeat(x, y) {
    let X = x, Y = y;

    if (x instanceof THREE.Vector2) {
      X = x.x;
      Y = x.y;
    }
    let vecRepeat = this.getUniformValue('quiltRepeat');
    let vecInfRepeat = this.getUniformValue('quiltInfiniteRepeat');

    if (!vecRepeat) {
      vecRepeat = new THREE.Vector2();
      this.setUniformValue('quiltRepeat', vecRepeat);
    }
    if (!vecInfRepeat) {
      vecInfRepeat = new THREE.Vector2();
      this.setUniformValue('quiltInfiniteRepeat', vecInfRepeat);
    }
    const infRepeatX = X > 0 ? 0 : 1;
    const infRepeatY = Y > 0 ? 0 : 1;

    X = X < 1 ? 1 : X;
    Y = Y < 1 ? 1 : Y;

    vecRepeat.set(X, Y);
    vecInfRepeat.set(infRepeatX, infRepeatY);
  }

  setQuiltTexture(texture) {
    if (!texture) {
      return;
    }

    this.setUniformValue('quiltOcclusionMap', texture);
  }

  getQuiltTexture() {
    return this.getUniformValue('quiltOcclusionMap');
  }

  setQuiltTextureValue(value) {
    return this.setUniformValue('quiltOcclusionValue', value);
  }

  getQuiltTextureValue() {
    return this.getUniformValue('quiltOcclusionValue');
  }

  setQuiltNormalMap(texture) {
    if (!texture) {
      return;
    }

    this.setUniformValue('quiltNormalMap', texture);
  }

  getQuiltNormalMap() {
    return this.getUniformValue('quiltNormalMap');
  }

  setQuiltNormalIntensity(v) {
    this.setUniformValue('quiltNormalScale', v);
  }
  getQuiltNormalIntensity(v) {
    return this.getUniformValue('quiltNormalScale');
  }

  setSampleTexture(texture) {
    if (!texture) {
      return;
    }
    this.setUniformValue('sampleTexture', texture);
  }

  setSampleNormalMap(normalmap) {
    if (!normalmap) {
      return;
    }
    this.setUniformValue('sampleNormalMap', normalmap);
  }

  setSampleNormalScale(v) {
    this.setUniformValue('sampleNormalScale', v);
  }

  setSampleSpecularMap(map) {
    if (!map) {
      return;
    }
    this.setUniformValue('sampleSpecularMap', map);
  }

  getQuiltTransformMatrix(create = false) {
    let res = this.getUniformValue('quiltTextureTransform');

    if (res || !create) {
      return res;
    }
    res = new THREE.Matrix4();
    this.setUniformValue('quiltTextureTransform', res);

    return res;
  }

  setQuiltTransformMatrix(v) {
    if (v instanceof THREE.Matrix4) {
      this.setUniformValue('quiltTextureTransform', v);
    } else {
      const curr = this.getUniformValue('quiltTextureTransform');

      if (curr) {
        curr.identity();
      }
    }
  }

  getSampleTransformMatrix(create = false) {
    let res = this.getUniformValue('sampleTextureTransform');

    if (res || !create) {
      return res;
    }
    res = new THREE.Matrix4();
    this.setUniformValue('sampleTextureTransform', res);

    return res;
  }

  setSampleTransformMatrix(v) {
    if (v instanceof THREE.Matrix4) {
      this.setUniformValue('sampleTextureTransform', v);
    } else {
      const curr = this.getUniformValue('sampleTextureTransform');

      if (curr) {
        curr.identity();
      }
    }
  }

  get quiltTransformMatrix() {
    return this.getQuiltTransformMatrix();
  }

  set quiltTransformMatrix(v) {
    this.setQuiltTransformMatrix(v);
  }

  get sampleTransformMatrix() {
    return this.getSampleTransformMatrix();
  }

  set sampleTransformMatrix(v) {
    this.setSampleTransformMatrix(v);
  }

  setSampleTextureScale(texScaleX, texScaleY) {
    let vec = this.getUniformValue('sampleScale');

    if (!vec) {
      vec = new THREE.Vector2();
    }
    const fallback = 0.001;
    const tsx = texScaleX === 0 ? fallback : texScaleX;
    const tsy = texScaleY === 0 ? fallback : texScaleY;

    vec.set(tsx, tsy);
    this.setUniformValue('sampleScale', vec);
  }

  setSampleTextureOffsetX(texOffX) {
    // #if DEBUG
    BD3DLogger.warn('setSampleTextureOffsetX deprecated');
    // #endif
    let vec = this.getUniformValue('sampleOffset');

    if (!vec) {
      vec = new THREE.Vector2();
    }
    vec.x = texOffX;

    this.setUniformValue('sampleOffset', vec);
  }

  setSampleTextureOffsetY(texOffY) {
    // #if DEBUG
    BD3DLogger.warn('setSampleTextureOffsetY deprecated');
    // #endif
    let vec = this.getUniformValue('sampleOffset');

    if (!vec) {
      vec = new THREE.Vector2();
    }
    vec.y = texOffY;

    this.setUniformValue('sampleOffset', vec);
  }

  setSampleTextureOffset(texOffX, texOffY) {
    // #if DEBUG
    BD3DLogger.warn('setSampleTextureOffset deprecated');
    // #endif

    let vec = this.getUniformValue('sampleOffset');

    if (!vec) {
      vec = new THREE.Vector2();
    }
    vec.set(texOffX, texOffY);
    this.setUniformValue('sampleOffset', vec);
  }

  setQuiltTextureScale(quiltScaleX, quiltScaleY) {
    // #if DEBUG
    BD3DLogger.warn('setQuiltTextureScale deprecated');
    // #endif
    let vec = this.getUniformValue('quiltScale');

    if (!vec) {
      vec = new THREE.Vector2();
    }
    const fallback = 0.001;
    const qsx = quiltScaleX === 0 ? fallback : quiltScaleX;
    const qsy = quiltScaleY === 0 ? fallback : quiltScaleY;

    vec.set(qsx, qsy);

    this.setUniformValue('quiltScale', vec);
  }

  setQuiltTextureOffset(quiltOffX, quiltOffY) {
    // #if DEBUG
    BD3DLogger.warn('setQuiltTextureOffset deprecated');
    // #endif
    let vec = this.getUniformValue('quiltOffset');

    if (!vec) {
      vec = new THREE.Vector2();
    }
    vec.set(quiltOffX, quiltOffY);

    this.setUniformValue('quiltOffset', vec);
  }

  setSpecularMultiplier(v) {
    this.setUniformValue('specularMultiplier', v);
  }

  setSpecularPower(v) {
    this.setUniformValue('specularPower', v);
  }

  setBRDFMap(map) {
    let m = map;

    if (!m) {
      m = ThreeFabricMaterial._getColorTexture('#FFFFFF');
    }

    this.setUniformValue('brdfMap', m);
  }

  setBRDFFactor(v) {
    this.setUniformValue('brdfFactor', v);
  }

  /**
   * @method _disposeDefaultTexture
   * @private
   * @static
   * @description disposes a static default texture
   * @param {THREE.Texture} tex - three.js texture instance
   * @returns {void}
   **/
  static _disposeDefaultTexture(tex) {
    if (!tex || !(tex instanceof THREE.Texture)) {
      return;
    }
    tex.dispose();
  }

  /**
   * @method _disposeDefaultTexturesOfMap
   * @private
   * @static
   * @description disposes the texture instances in a map/dictionary
   * @param {Object} map - map/dictionary containing three.js texture instances
   * @returns {void}
   **/
  static _disposeDefaultTexturesOfMap(map) {
    if (!map) {
      return;
    }
    for (const v in map) {
      if (map.hasOwnProperty(v)) {
        this._disposeDefaultTexture(map[v]);
      }
    }
  }

  /**
   * @method _disposeDefaultTextures
   * @private
   * @static
   * @description disposes all used default textures
   * @returns {void}
   **/
  static _disposeDefaultTextures() {
    this._disposeDefaultTexturesOfMap(this._colorCubeTextures);
    this._disposeDefaultTexturesOfMap(this._colorTextures);
    this._disposeDefaultTexturesOfMap(this._defaultTextures);
    this._colorTextures = null;
    this._defaultTextures = null;
  }

  /**
  * @method _disposeInstance
  * @private
  * @static
  * @description disposes the main static instance
  * that's used to clone new material instances
  * @returns {void}
  **/
  static _disposeInstance() {
    if (!fabricMaterialInstance) {
      return;
    }
    fabricMaterialInstance.dispose();
    fabricMaterialInstance = null;
  }

  /**
   * @method dispose
   * @static
   * @description disposes all static resources this class uses.
   * @returns {void}
   **/
  static dispose() {
    this._disposeDefaultTextures();
    this._disposeInstance();
  }

  /**
   * @method _getDefaultMatrix
   * @static
   * @private
   * @description returns the default/fallback value for mat4 uniforms
   * @returns {THREE.Matrix4} matrix
   **/
  static _getDefaultMatrix() {
    let res = this._defaultMatrix;

    if (!res) {
      res = new THREE.Matrix4();
    }

    return res;
  }

  /**
   * @method _getColorTexture
   * @static
   * @private
   * @param {String} col - color (hex code like #FFFFFF)
   * @description returns a color texture
   * @returns {THREE.Texture} texture
   **/
  static _getColorTexture(col) {
    let map = this._colorTextures;

    if (!map) {
      map = this._colorTextures = {};
    }

    let res = map[col];

    if (!res) {
      res = map[col] = createColorTexture(col);
    }

    return res;
  }

  /**
   * @method _getDefaultTextureColor
   * @static
   * @private
   * @description returns the color of a default texture
   * @param {String} key - name of the default texture ('texture' / 'normal'/ ...)
   * @param {String} fallback - fallback color
   * @returns {String} color value
   **/
  static _getDefaultTextureColor(key, fallback) {
    const k = key ? key.toLowerCase() : null;

    if (!k) {
      return fallback;
    }
    let map = this._defaultTextureColors;

    if (!map) {
      map = {
        normal: '#8080FF',
        texture: '#FFFFFF'
      };
    }
    if (!map[key]) {
      return fallback;
    }

    return map[key];
  }

  /**
   * @method _getColorCubeTexture
   * @static
   * @private
   * @description returns a colored cube texture
   * @param {String} color - color (#hex value)
   * @returns {THREE.CubeTexture} cube texture
   **/
  static _getColorCubeTexture(color) {
    let map = this._colorCubeTextures;

    if (!map) {
      map = {};
    }
    let res = map[color];

    if (!res) {
      res = map[color] = createColorCubeTexture(color);
    }

    return res;
  }

  /**
   * @method _getDefaultCubeTexture
   * @static
   * @private
   * @description returns a default cube texture.
   *  Uses _getDefaultTextureColor to create a colored cube texture
   * @param {String} key - name of the default cube texture
   * @returns {THREE.CubeTexture} cube texture
   **/
  static _getDefaultCubeTexture(key) {
    let map = this._defaultCubeTextures;

    if (!map) {
      map = this._defaultCubeTextures = {};
    }
    let res = map[key];

    if (!res) {
      const color = this._getDefaultTextureColor(key, '#000000');

      res = map[key] = this._getColorCubeTexture(color);
    }

    return res;
  }

  /**
  * @method _getDefaultTexture
  * @static
  * @private
  * @description returns a default texture.
  *  Uses _getDefaultTextureColor to create a colored texture
  * @param {String} key - name of the default texture
  * @returns {THREE.Texture} texture
   **/
  static _getDefaultTexture(key) {
    let map = this._defaultTextures;

    if (!map) {
      map = this._defaultTextures = {};
    }
    let res = map[key];

    if (!res) {
      const color = this._getDefaultTextureColor(key, '#FFFFFF');

      res = map[key] = this._getColorTexture(color);
    }

    return res;
  }

  static getInstance() {
    if (!fabricMaterialInstance) {
      allowNewInstance = true;
      fabricMaterialInstance = new ThreeFabricMaterial();
      allowNewInstance = false;
    }

    return fabricMaterialInstance;
  }

  // If a uniform has no value set, use a default value
  static _fixMissingUniform(uName, res) {
    const uDef = uniformDefs[uName];

    if (!uDef) {
      return;
    }

    if (uDef) {
      if (!res.hasUniformValue(uName)) {
        const type = uDef.type ? uDef.type.toLowerCase() : uDef.type;
        let val = null;
        const def = uDef.def;

        if (type === 'matrix4' || type === 'matrix') {
          val = this._getDefaultMatrix();
        } else if (type === 'texture') {
          val = this._getDefaultTexture('texture');
        } else if (type === 'normalmap') {
          val = this._getDefaultTexture('normal');
        } else if (type === 'vec2') {
          let x = 0;
          let y = 0;

          if (def) {
            x = def.x;
            y = def.y;
          }
          val = new THREE.Vector2(x, y);
        } else if (type === 'float' || type === 'scalar') {
          val = 0.0;
          if (typeof (def) !== 'undefined' && def !== null && !isNaN(def)) {
            val = def;
          }
        } else if (type === 'color') {
          let R = 1.0;
          let G = 1.0;
          let B = 1.0;

          if (typeof (def) !== 'undefined' && def !== null && !isNaN(def)) {
            const rShift = 16;
            const gShift = 8;
            const mask = 0xFF;
            const nDiv = 255;

            R = ((def >> rShift) & mask) / nDiv;
            G = ((def >> gShift) & mask) / nDiv;
            B = (def & mask) / nDiv;
          }
          val = new THREE.Vector3(R, G, B);
        }
        const t = typeof (val);

        if (val === null || t === 'undefined' || (t === 'number' && isNaN(val))) {
          val = 0;
        }
        res.setUniformValue(uName, val);
      }
    }
  }

  // Set default values to missing uniforms
  static _fixMissingUniforms(res) {
    for (const v in uniformDefs) {
      if (uniformDefs.hasOwnProperty(v)) {
        this._fixMissingUniform(v, res);
      }
    }
  }

  static create(uniforms, properties) {
    const inst = ThreeFabricMaterial.getInstance();

    allowNewInstance = true;
    const res = ThreeMaterialUtils.create(inst, uniforms, properties);

    allowNewInstance = false;

    this._fixMissingUniforms(res);

    return res;
  }
}
