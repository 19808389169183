import OBJFileAsset from '../../asset/OBJFileAsset';

export default {
  create(data, id, session) {
    const {
      name, url
    } = data;
    const res = new OBJFileAsset(name, url);

    return res;
  },
  update(object, data, id, session) {
    object.url = data.url;
  }
};
