import Numeric from './Numeric';

export function parseNumber(value, params = null, defaultValue = null) {
  if (value === null) {
    return defaultValue;
  }
  let type = typeof (value);
  let res = value;

  if (type === 'undefined') {
    return defaultValue;
  }

  if (value instanceof Numeric) {
    return parseNumber(value.getNumber(params), params, defaultValue);
  }
  if (type === 'object') {
    res = res.valueOf();
    type = typeof (res);
  }
  if (type === 'string') {
    res = parseFloat(res);
    type = typeof (res);
  }

  if (type === 'boolean') {
    return res ? 1 : 0;
  }

  if (type === 'number') {
    if (isNaN(res)) {
      return defaultValue;
    }

    return res;
  }

  return defaultValue;
}
