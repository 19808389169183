export default class BD3DLogger {
  static log(...args) {
    const l = this.logger;

    if (!l || !l.log) {
      return;
    }
    l.log(...args);
  }

  static warn(...args) {
    const l = this.logger;

    if (!l || !l.warn) {
      return;
    }
    l.warn(...args);
  }

  static error(...args) {
    const l = this.logger;

    if (!l || !l.error) {
      return;
    }
    l.error(...args);
  }

  static info(...args) {
    const l = this.logger;

    if (!l || !l.info) {
      return;
    }
    l.info(...args);
  }

  static time(msg) {
    const l = this.logger;

    if (!l || !l._log) {
      return;
    }
    l._log('time', msg);
  }

  static timeEnd(msg) {
    const l = this.logger;

    if (!l || !l._log) {
      return;
    }
    l._log('timeEnd', msg);
  }
}
