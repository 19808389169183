import ColorList from './ColorList';
import StringUtils from '../utils/StringUtils';

// TODO: temporary zipper colors
const COL_ZIPPER = new ColorList([
  {
    id: '0242',
    color: '#1c1c1d',
    color3d: '#444444'
  },
  {
    id: '0240',
    color: '#44494d',
    color3d: '#6a7a82'
  },
  {
    id: '0238',
    color: '#2d2421',
    color3d: '#675851'
  },
  {
    id: '0236',
    color: '#cab8a4',
    color3d: '#e5d5c7'
  },
  {
    id: '0234',
    color: '#999d9b',
    color3d: '#9da2a1'
  },
  {
    id: '0232',
    color: '#545b53',
    color3d: '#697368'
  },
  {
    id: '0230',
    color: '#b1b2ac',
    color3d: '#d1d1c6'
  },
  {
    id: '0228',
    color: '#999ea2',
    color3d: '#c1cad1'
  },
  {
    id: '0226',
    color: '#898a83',
    color3d: '#b2b4a8'
  },
  {
    id: '0224',
    color: '#404c72',
    color3d: '#7384ba'
  },
  {
    id: '0222',
    color: '#eaeae9',
    color3d: '#ececec'
  },
  {
    id: '0220',
    color: '#f1f1f1',
    color3d: '#f2f2f2'
  },
  {
    id: '0218',
    color: '#e9eff3',
    color3d: '#e9f8ff'
  },
  {
    id: 'US-0001',
    color: '#a4d65e'
  },
  {
    id: 'US-0002',
    color: '#f5e1a4'
  },
  {
    id: 'US-0003',
    color: '#e4d5d3'
  },
  {
    id: 'US-0004',
    color: '#6c4b1e'
  },
  {
    id: 'US-0005',
    color: '#84754e'
  },
  {
    id: 'US-0006',
    color: '#5c88da'
  },
  {
    id: 'US-0007',
    color: '#a7c6ed'
  },
  {
    id: 'US-0008',
    color: '#c1c6c8'
  },
  {
    id: 'US-0009',
    color: '#54585a'
  },
  {
    id: 'US-0010',
    color: '#bdc5db'
  },
  {
    id: 'US-0011',
    color: '#8c8985'
  },
  {
    id: 'US-0012',
    color: '#6c6463'
  }
]);

const COL_TAPE = new ColorList([
  {
    id: '0091',
    color: '#FFFFFF'
  },
  {
    id: '0092',
    color: '#FFFFFF'
  },
  {
    id: '0093',
    color: '#d9eff7'
  },
  {
    id: '0094',
    color: '#f3e6d4'
  },
  {
    id: '0095',
    color: '#b3a699'
  },
  {
    id: '0096',
    color: '#a5bdc0'
  },
  {
    id: '0097',
    color: '#d6dddb'
  },
  {
    id: '0098',
    color: '#4e5d68'
  },
  {
    id: '0099',
    color: '#455250'
  },
  {
    id: '0100',
    color: '#feffff'
  },
  {
    id: '0101',
    color: '#fefefe'
  },
  {
    id: '0102',
    color: '#e8f5f9'
  },
  {
    id: '0103',
    color: '#f5eee3'
  },
  {
    id: '0104',
    color: '#cbccc6',
    color3d: '#e5dbcf'
  },
  {
    id: '0105',
    color: '#c4d3d5'
  },
  {
    id: '0106',
    color: '#e4e8e7'
  },
  {
    id: '0107',
    color: '#94a4a6'
  },
  {
    id: '0108',
    color: '#95a5ac'
  },
  {
    id: 'US-0001',
    color: '#a4d65e'
  },
  {
    id: 'US-0002',
    color: '#f5e1a4'
  },
  {
    id: 'US-0003',
    color: '#e4d5d3'
  },
  {
    id: 'US-0004',
    color: '#6c4b1e'
  },
  {
    id: 'US-0005',
    color: '#84754e'
  },
  {
    id: 'US-0006',
    color: '#5c88da'
  },
  {
    id: 'US-0007',
    color: '#a7c6ed'
  },
  {
    id: 'US-0008',
    color: '#c1c6c8'
  },
  {
    id: 'US-0009',
    color: '#54585a'
  },
  {
    id: 'US-0010',
    color: '#bdc5db'
  },
  {
    id: 'US-0011',
    color: '#8c8985'
  },
  {
    id: 'US-0012',
    color: '#6c6463'
  }
]);

const COL_BORDER_3D = new ColorList([
  {
    id: 'EX000016',
    name: 'WHITE',
    color: '#d1dcdb'
  },
  {
    id: 'EX00006',
    name: 'ECRU',
    color: '#d9ddd2'
  },
  {
    id: 'EX000055',
    name: 'BEIGE',
    color: '#cdbfa6'
  },
  {
    id: 'EX000023',
    name: 'LIGHT GREY',
    color: '#838680'
  },
  {
    id: 'EX000053',
    name: 'TAUPE',
    color: '#5f6160'
  },
  {
    id: 'EX000025',
    name: 'LIGHT BLUE',
    color: '#cad9db'
  },
  {
    id: 'EX000037',
    name: 'SILVER',
    color: '#9fa7a1'
  },
  {
    id: 'EX000050',
    name: 'DARK GREY',
    color: '#444a4f'
  },
  {
    id: 'EX00051',
    name: 'CHOCOLATE',
    color: '#312d2c'
  },
  {
    id: 'EX000004',
    name: 'BLACK',
    color: '#171a1d'
  }
]);

const COL_RIBBON = new ColorList([
  {
    id: '0010',
    color: '#323f49'
  },
  {
    id: '0009',
    color: '#45544a'
  },
  {
    id: '0008',
    color: '#45473e'
  },
  {
    id: '0007',
    color: '#1d2722'
  },
  {
    id: '0006',
    color: '#b4cde5'
  },
  {
    id: '0005',
    color: '#fbf3e4'
  },
  {
    id: '0004',
    color: '#ccdadc'
  },
  {
    id: '0003',
    color: '#c4c7c0',
    color3d: '#e1dcd3'
  },
  {
    id: '0002',
    color: '#909d97'
  },
  {
    id: '0001',
    color: '#f8f9f9'
  }
]);

const COL_PIPING_MATTE = new ColorList([
  {
    id: '0100',
    color: '#313c3c'
  },
  {
    id: '0109',
    color: '#eef1eb'
  },
  {
    id: '0108',
    color: '#b5b6a4'
  },
  {
    id: '0107',
    color: '#7a8f83'
  },
  {
    id: '0110',
    color: '#e6e5db'
  },
  {
    id: '0106',
    color: '#8ea1a4'
  },
  {
    id: '0105',
    color: '#9dbbd6'
  },
  {
    id: '0104',
    color: '#304565'
  },
  {
    id: '0103',
    color: '#1d2330'
  },
  {
    id: '0102',
    color: '#151d1f'
  },
  {
    id: '0101',
    color: '#2f292b'
  },
  {
    id: 'US-0001',
    color: '#a4d65e'
  },
  {
    id: 'US-0002',
    color: '#f5e1a4'
  },
  {
    id: 'US-0003',
    color: '#e4d5d3'
  },
  {
    id: 'US-0004',
    color: '#6c4b1e'
  },
  {
    id: 'US-0005',
    color: '#84754e'
  },
  {
    id: 'US-0006',
    color: '#5c88da'
  },
  {
    id: 'US-0007',
    color: '#a7c6ed'
  },
  {
    id: 'US-0008',
    color: '#c1c6c8'
  },
  {
    id: 'US-0009',
    color: '#54585a'
  },
  {
    id: 'US-0010',
    color: '#bdc5db'
  },
  {
    id: 'US-0011',
    color: '#8c8985'
  },
  {
    id: 'US-0012',
    color: '#6c6463'
  }
]);

const COL_PIPING_GLOSSY = new ColorList([
  {
    id: '0010',
    color: '#2b3841'
  },
  {
    id: '0009',
    color: '#3e4d44'
  },
  {
    id: '0008',
    color: '#3d4039'
  },
  {
    id: '0007',
    color: '#1c231f'
  },
  {
    id: '0006',
    color: '#96bcdb'
  },
  {
    id: '0005',
    color: '#ece9e3'
  },
  {
    id: '0004',
    color: '#beced0'
  },
  {
    id: '0003',
    color: '#aeb5ad'
  },
  {
    id: '0002',
    color: '#8c9a93'
  },
  {
    id: '0001',
    color: '#e3e7eb'
  },
  {
    id: 'US-0001',
    color: '#a4d65e'
  },
  {
    id: 'US-0002',
    color: '#f5e1a4'
  },
  {
    id: 'US-0003',
    color: '#e4d5d3'
  },
  {
    id: 'US-0004',
    color: '#6c4b1e'
  },
  {
    id: 'US-0005',
    color: '#84754e'
  },
  {
    id: 'US-0006',
    color: '#5c88da'
  },
  {
    id: 'US-0007',
    color: '#a7c6ed'
  },
  {
    id: 'US-0008',
    color: '#c1c6c8'
  },
  {
    id: 'US-0009',
    color: '#54585a'
  },
  {
    id: 'US-0010',
    color: '#bdc5db'
  },
  {
    id: 'US-0011',
    color: '#8c8985'
  },
  {
    id: 'US-0012',
    color: '#6c6463'
  }
]);

const typeMap = {
  zipper: COL_ZIPPER,
  tape: COL_TAPE,
  ribbon: COL_RIBBON,
  ribbonmatte: COL_RIBBON,
  ribboncotton: COL_RIBBON,
  ribbonglossy: COL_RIBBON,
  ribbonsatin: COL_RIBBON,
  piping: COL_PIPING_MATTE,
  pipingmatte: COL_PIPING_MATTE,
  pipingglossy: COL_PIPING_GLOSSY,
  pipingcotton: COL_PIPING_MATTE,
  pipingsatin: COL_PIPING_GLOSSY,
  border3d: COL_BORDER_3D
};

export default class Colors {
  static getZipperColors() {
    this._getColorArray(typeMap.zipper, true);
  }
  static getTapeColors() {
    return this._getColorArray(typeMap.tape, true);
  }

  static getRibbonGlossyColors() {
    return this._getColorArray(typeMap.ribbon, true);
  }

  static getRibbonMatteColors() {
    return this._getColorArray(typeMap.ribbon, true);
  }

  static getPipingGlossyColors() {
    return this._getColorArray(typeMap.pipingglossy, true);
  }

  static getPipingMatteColors() {
    return this._getColorArray(typeMap.pipingmatte, true);
  }

  static getBorder3DColors() {
    return this._getColorArray(typeMap.pipingmatte, true);
  }

  static getColorTypes() {
    const map = typeMap;

    if (!map) {
      return null;
    }
    if (Object.keys) {
      return Object.keys(map);
    }
    const arr = [];

    for (const v in map) {
      if (map.hasOwnProperty(v)) {
        arr.push(v);
      }
    }

    return arr;
  }

  static get tapeColors() {
    return this.getTapeColors();
  }

  static get ribbonGlossyColors() {
    this.getRibbonGlossyColors();
  }

  static get ribbonMatteColors() {
    this.getRibbonMatteColors();
  }

  static get pipingGlossyColors() {
    return this.getPipingGlossyColors();
  }

  static get pipingMatteColors() {
    return this.getPipingMatteColors();
  }

  static get border3DColors() {
    return this.getBorder3DColors();
  }

  static get colorTypes() {
    return this.getColorTypes();
  }

  static getColorsByComponentTypeAndMaterial(componentType, material) {
    if (!componentType) {
      return null;
    }
    const type = this._getColorTypeByComponentAndMaterial(componentType, material);
    const res = this.getColorsByType(type);

    if (!res) {
      return this.getColorsByType(componentType);
    }

    return res;
  }

  static getColorsByType(type) {
    const typeName = this._getTypeName(type);

    if (!typeName) {
      return null;
    }
    const colList = typeMap[typeName];

    return this._getColorArray(colList, true);
  }

  static _getColorTypeByComponentAndMaterial(componentType, material) {
    if (!componentType) {
      return null;
    }
    let type = componentType;

    if (componentType === 'ribbon' || componentType === 'piping') {
      let mtl = material;

      if (!mtl) {
        mtl = 'cotton';
      }
      type = type + mtl;
    }

    return type;
  }

  static getColorByComponentTypeMaterialAndId(componentType, material, id) {
    const type = this._getColorTypeByComponentAndMaterial(componentType, material);
    const res = this.getColorByTypeAndId(type, id);

    if (!res) {
      return this.getColorByTypeAndId(componentType, id);
    }

    return res;
  }

  static getColorByTypeAndId(type, id) {
    let typeName = type;

    if (!typeName || !id) {
      return null;
    }
    typeName = this._getTypeName(typeName);

    const colList = typeMap[typeName];

    if (!colList) {
      return null;
    }

    return colList.getColor(id);
  }

  static _getTypeName(type) {
    let typeName = type;

    typeName = typeName.toLowerCase();
    typeName = StringUtils.replace(typeName, '-', '');
    typeName = StringUtils.replace(typeName, '_', '');
    typeName = StringUtils.replace(typeName, ' ', '');

    return typeName;
  }

  static _getColorArray(colList, copy = true) {
    if (!colList) {
      return null;
    }
    let arr = null;

    if (colList instanceof ColorList) {
      arr = colList.getColors();
    } else if ((colList instanceof Array) || (Array.isArray && Array.isArray(colList))) {
      arr = colList.concat();
    }
    if (arr && copy) {
      return arr.concat();
    }

    return arr;
  }

}
