import * as THREE from 'three';

export default class OrbitController {
  constructor(target = null) {
    let tgt = target;

    if (!tgt) {
      tgt = this._newGroup();
    }

    this._target = tgt;

    this._rotationXGroup = this._newGroup();
    this._rotationYGroup = this._newGroup();

    this._rotationXGroup.add(this._target);
    this._rotationYGroup.add(this._rotationXGroup);

    this._positionGroup = this._rotationYGroup;

    this._container = this._rotationYGroup;
    this._distanceContainer = this._target;
  }

  getContainer() {
    return this._container;
  }

  get container() {
    return this.getContainer();
  }

  setTarget(tgt) {
    if (tgt === this._target) {
      return;
    }
    this._target = tgt;

    this._distanceContainer = tgt;
    if (!this._rotationXGroup) {
      return;
    }
    this._rotationXGroup.add(tgt);
  }

  getTarget() {
    return this._target;
  }

  get target() {
    return this.getTarget();
  }

  setTargetOffset(X, Y) {
    const tgt = this.getTarget();

    tgt.position.x = X;
    tgt.position.y = Y;
  }

  getTargetOffsetX() {
    const tgt = this.getTarget();

    return tgt.position.x;
  }

  setTargetOffsetX(v) {
    const tgt = this.getTarget();

    tgt.position.x = v;
  }

  getTargetOffsetY() {
    const tgt = this.getTarget();

    return tgt.position.y;
  }

  setTargetOffsetY(v) {
    const tgt = this.getTarget();

    tgt.position.y = v;
  }

  get targetOffsetX() {
    return this.getTargetOffsetX();
  }

  set targetOffsetX(v) {
    this.setTargetOffsetX(v);
  }

  get targetOffsetY() {
    return this.getTargetOffsetY();
  }

  set targetOffsetY(v) {
    this.setTargetOffsetY(v);
  }

  _newGroup() {
    return new THREE.Group();
  }

  setPosition(x, y, z) {
    const pos = this._positionGroup.position;

    pos.x = x;
    pos.y = y;
    pos.z = z;
  }

  getPositionX() {
    return this._positionGroup.position.x;
  }

  setPositionX(v) {
    this._positionGroup.position.x = v;
  }

  getPositionY() {
    return this._positionGroup.position.y;
  }

  setPositionY(v) {
    this._positionGroup.position.y = v;
  }

  getPositionZ() {
    return this._positionGroup.position.z;
  }

  setPositionZ(v) {
    this._positionGroup.position.z = v;
  }


  getDistance() {
    return this._distanceContainer.position.z;
  }

  setDistance(v) {
    this._distanceContainer.position.z = v;
  }

  setRotation(x, y) {
    this.setRotationX(x);
    this.setRotationY(y);
  }

  getRotationX() {
    return this._rotationXGroup.rotation.x;
  }

  setRotationX(v) {
    this._rotationXGroup.rotation.x = v;
  }

  getRotationY() {
    return this._rotationYGroup.rotation.y;
  }

  setRotationY(v) {
    this._rotationYGroup.rotation.y = v;
  }

  get rotationX() {
    return this.getRotationX();
  }

  set rotationX(v) {
    return this.setRotationX(v);
  }

  get rotationY() {
    return this.getRotationY();
  }

  set rotationY(v) {
    return this.setRotationY(v);
  }

  get distance() {
    return this.getDistance();
  }

  set distance(d) {
    this.setDistance(d);
  }

  get positionX() {
    return this.getPositionX();
  }

  set positionX(v) {
    this.setPositionX(v);
  }

  get positionY() {
    return this.getPositionY();
  }

  set positionY(v) {
    this.setPositionY(v);
  }

  get positionZ() {
    return this.getPositionZ();
  }

  set positionZ(v) {
    this.setPositionZ(v);
  }
}
