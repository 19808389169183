const DEFAULT_COLOR_CANVAS_WIDTH = 8;
const DEFAULT_COLOR_CANVAS_HEIGHT = 8;

export default class CanvasUtils {
  static getColorCanvas(color,
    width = DEFAULT_COLOR_CANVAS_WIDTH,
    height = DEFAULT_COLOR_CANVAS_HEIGHT) {
    const cvs = document.createElement('canvas');

    if (!cvs) {
      return null;
    }
    cvs.width = width;
    cvs.height = height;
    this._colorCanvas(color, cvs, width, height);

    return cvs;
  }

  static colorCanvas(color, canvas) {
    this._colorCanvas(color, canvas, canvas.width, canvas.height);
  }

  static _colorCanvas(color, canvas, w, h) {
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext('2d');

    ctx.fillStyle = color;
    ctx.fillRect(0, 0, w, h);
  }

  static resize(img, w, h) {
    if (!img) {
      return null;
    }
    const cvs = document.createElement('canvas');

    if (!cvs) {
      return null;
    }

    cvs.width = w;
    cvs.height = h;

    const ctx = cvs.getContext('2d');

    ctx.drawImage(img, 0, 0, w, h);

    return cvs;
  }

  /**
  * @method resizeToPO2
  * @static
  * @description Resizes an image to power-of-2
  * If the image width & height already are powers of 2, the image is returned,
  * else, a canvas element is created using those power-of-2 dimensions and
  * the source image as drawing content
  * @param {Image|Canvas} image - Source image, can also be anything that can be drawn on a canvas
  * @param {Object} params - Params object with following optional parameters:
  * maxWidth - maximum width, if the source image is larger than this value, it will be downscaled
  *   A value of 0 means no limit
  * maxHeight - maximum height, if the source image is larger than this value, it will be downscaled
  *   A value of 0 means no limit
  * maxSize - fallback value if maxWidth or maxHeight is not set
  * @returns {Image|Canvas} result
  */
  static resizeToPO2(image, params) {
    const w = image.width;
    const h = image.height;

    const tw = this.getPO2Width(w, params);
    const th = this.getPO2Height(h, params);

    if (tw === w && th === h) {
      return image;
    }

    return this.resize(image, tw, th);
  }

  static getPO2Width(width, params) {
    let w = this._ceilPO2(width);
    let maxSize = null;

    if (params) {
      maxSize = params.maxWidth;

      if (!maxSize) {
        maxSize = params.maxSize;
      }
    }


    if (!maxSize) {
      return w;
    }

    maxSize = this._floorPO2(maxSize);

    w = w < maxSize ? w : maxSize;

    return w;
  }

  static getPO2Height(height, params) {
    let h = this._ceilPO2(height);
    let maxSize = null;

    if (params) {
      maxSize = params.maxHeight;

      if (!maxSize) {
        maxSize = params.maxSize;
      }
    }

    if (!maxSize) {
      return h;
    }

    maxSize = this._floorPO2(maxSize);

    h = h < maxSize ? h : maxSize;

    return h;
  }

  static _floorPO2(value) {
    if ((value & (value - 1)) === 0) {
      return value;
    }
    const c = this._ceilPO2(value);

    return c >> 1;
  }

  static _ceilPO2(value) {
    if ((value & (value - 1)) === 0) {
      return value;
    }
    let v = value;
    const _1 = 1, _2 = 2, _4 = 4, _8 = 8, _16 = 16, _32 = 32;

    v |= v >> _1;
    v |= v >> _2;
    v |= v >> _4;
    v |= v >> _8;
    v |= v >> _16;
    v |= v >> _32;
    ++v;

    return v;
  }
}
