import BD3DMaterial from './BD3DMaterial';
import MaterialTypes from './MaterialTypes';

export default class BD3DPlasticMaterial extends BD3DMaterial {
  constructor(settings = null) {
    super(MaterialTypes.PLASTIC, settings);
  }

  getSpecularPower() {
    return this._specularPower;
  }

  setSpecularPower(v) {
    this._specularPower = v;
  }

  getSpecularMultiplier() {
    return this._specularMultiplier;
  }

  setSpecularMultiplier(v) {
    this._specularMultiplier = v;
  }

  getColorMultiplier() {
    return this._colorMultiplier;
  }

  setColorMultiplier(v) {
    this._colorMultiplier = v;
  }


  getLightmap() {
    return this._lightmap;
  }

  setLightmap(v) {
    this._lightmap = v;
  }

  get specularPower() {
    return this.getSpecularPower();
  }

  set specularPower(v) {
    this.setSpecularPower(v);
  }


  get specularMultiplier() {
    return this.getSpecularMultiplier();
  }

  set specularMultiplier(v) {
    this.setSpecularMultiplier(v);
  }


  get colorMultiplier() {
    return this.getColorMultiplier();
  }

  set colorMultiplier(v) {
    this.setColorMultiplier(v);
  }


  get lightmap() {
    return this.getLightmap();
  }

  set lightmap(v) {
    this.setLightmap(v);
  }

}
