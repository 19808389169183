import Vector2 from '../../bgr/bgr3d/geom/Vector2';
import Vector3 from '../../bgr/bgr3d/geom/Vector3';
import Matrix4Math from '../../bgr/bgr3d/math/Matrix4Math';
import Vertex from '../../bgr/bgr3d/geom/Vertex';
import Polygon from '../../bgr/bgr3d/geom/Polygon';
import Geometry from '../../bgr/bgr3d/geom/Geometry';
import GeomUtils from '../../bgr/bgr3d/utils/GeomUtils';

const _0 = 0, _1 = 1, _2 = 2, // _3 = 3,
  _4 = 4, _5 = 5, _6 = 6, // _7 = 7,
  _8 = 8, _9 = 9, _10 = 10, // _11 = 11,
  _12 = 12, _13 = 13, _14 = 14; // _15 = 15;

export default class BorderGeomUtils {
  static _transformShapeVertex(v, m) {
    if (!m) {
      return v;
    }
    let x;
    let y;
    let z;
    let nx = 0;
    let ny = 0;
    let nz = 1;
    let tx;
    let ty;
    let tz;

    let nrm = this._getVertexNormal(v);

    // Swap x and z coordinates
    const X = 2;
    const Y = 1;
    const Z = 0;

    x = v.getCoord(X);
    y = v.getCoord(Y);
    z = -v.getCoord(Z);

    if (nrm) {
      nx = nrm.getCoord(X);
      ny = nrm.getCoord(Y);
      nz = -nrm.getCoord(Z);
    }

    tx = x * m[_0] + y * m[_4] + z * m[_8] + m[_12];
    ty = x * m[_1] + y * m[_5] + z * m[_9] + m[_13];
    tz = x * m[_2] + y * m[_6] + z * m[_10] + m[_14];
    x = tx;
    y = ty;
    z = tz;

    tx = nx * m[_0] + ny * m[_4] + nz * m[_8];
    ty = nx * m[_1] + ny * m[_5] + nz * m[_9];
    tz = nx * m[_2] + ny * m[_6] + nz * m[_10];
    nx = tx;
    ny = ty;
    nz = tz;

    const pos = new Vector3(x, y, z);
    const vert = new Vertex();

    nrm = new Vector3(nx, ny, nz);
    vert.position = pos;
    if (!vert.attributes) {
      vert.attributes = {};
    }
    vert.attributes.normal = nrm;

    const polyVert = new Vertex();

    polyVert.position = vert;
    polyVert.attributes = {
      normal: nrm
    };

    return polyVert;
  }

  static _getVertexUV(v) {
    if (v instanceof Vertex) {
      const atts = v.attributes;

      if (!atts) {
        return null;
      }

      return atts.uv;
    }
    if (v instanceof Vector2) {
      return v;
    }

    return null;
  }

  static _getVertexNormal(v) {
    if (v instanceof Vertex) {
      const atts = v.attributes;

      if (!atts) {
        return null;
      }
      const nrm = atts.normal;

      if (nrm) {
        return nrm;
      }

      return this._getVertexNormal(v.position);
    }
    if (v instanceof Vector3) {
      return v;
    }

    return null;
  }

  static _getPathVertexMatrix(v, result, upx_, upy_, upz_) {
    let upx = upx_;
    let upy = upy_;
    let upz = upz_;
    const nrm = this._getVertexNormal(v);
    let res = result;

    if (!nrm) {
      if (res) {
        Matrix4Math.identity(res);
      }

      return res;
    }
    const x = v.getCoord(0);
    const y = v.getCoord(1);
    const z = v.getCoord(2);

    res = Matrix4Math.identity(res);

    if (upx === undefined || upx === null) {
      upx = 0;
    }
    if (upy === undefined || upy === null) {
      upy = 1;
    }
    if (upz === undefined || upz === null) {
      upz = 0;
    }
    let d;
    let zAxisX = nrm.getCoord(0);
    let zAxisY = nrm.getCoord(1);
    let zAxisZ = nrm.getCoord(2);

    d = zAxisX * zAxisX + zAxisY * zAxisY + zAxisZ * zAxisZ;
    if (d !== 0 && d !== 1) {
      d = 1.0 / Math.sqrt(d);
      zAxisX *= d;
      zAxisY *= d;
      zAxisZ *= d;
    }

    const xAxisX = upz * zAxisY - upy * zAxisZ;
    const xAxisY = upx * zAxisZ - upz * zAxisX;
    const xAxisZ = upy * zAxisX - upx * zAxisY;

    d = xAxisX * xAxisX + xAxisY * xAxisY + xAxisZ * xAxisZ;
    if (d !== 0 && d !== 1) {
      d = 1.0 / Math.sqrt(d);
      zAxisX *= d;
      zAxisY *= d;
      zAxisZ *= d;
    }

    let yAxisX = xAxisY * zAxisZ - xAxisZ * zAxisY;
    let yAxisY = xAxisZ * zAxisX - xAxisX * zAxisZ;
    let yAxisZ = xAxisX * zAxisY - xAxisY * zAxisX;

    const dot = yAxisX * upx + yAxisY * upy + yAxisZ * upz;

    if (dot < 0) {
      yAxisX *= -1;
      yAxisY *= -1;
      yAxisZ *= -1;
    }

    d = yAxisX * yAxisX + yAxisY * yAxisY + yAxisZ * yAxisZ;
    if (d !== 0 && d !== 1) {
      d = 1.0 / Math.sqrt(d);
      yAxisX *= d;
      yAxisY *= d;
      yAxisZ *= d;
    }

    res[_0] = xAxisX;
    res[_1] = xAxisY;
    res[_2] = xAxisZ;

    res[_4] = yAxisX;
    res[_5] = yAxisY;
    res[_6] = yAxisZ;

    res[_8] = zAxisX;
    res[_9] = zAxisY;
    res[_10] = zAxisZ;

    res[_12] = x;
    res[_13] = y;
    res[_14] = z;

    return res;
  }

  static borderExtrusion(shape, path, hasUVCorrection, mattressData, params, res = null) {
    if (!path || !shape) {
      return res;
    }
    const numPathVerts = path.length;

    if (numPathVerts === 0) {
      return null;
    }
    const numShapeVerts = shape.length;

    if (numShapeVerts === 0) {
      return null;
    }
    let x;
    let y;
    let row;

    // Create grid of vertices around the path
    const grid = [];

    const numPathVertsWrapped = numPathVerts + 1;

    // Current texture-U coordinate
    let curU = 0;

    // Previous path coords, used to calculate the UV coordinates
    let prevPathX = 0;
    let prevPathZ = 0;

    let pvMatrix = null;

    const vertices = [];
    const polyVertices = [];
    const uvs = [];
    let uv;

    let computeTangents = true;
    let computeVertexNormals = true;

    if (params) {
      computeTangents = params.computeTangents;
      computeVertexNormals = params.computeVertexNormals;
    }
    if (computeTangents === undefined || computeTangents === null) {
      computeTangents = true;
    }
    if (computeVertexNormals === undefined || computeVertexNormals === null) {
      computeVertexNormals = true;
    }
    const uvCorrection = (hasUVCorrection === false) ? 0 : 1;

    for (x = 0; x < numPathVertsWrapped; ++x) {
      const wrapX = x % numPathVerts;
      const pv = path[wrapX];
      const pX = pv.getCoord(0);
      const pZ = pv.getCoord(2);

      let texVOffset = 0;

      if (pv && pv.attributes) {
        const pvUV = pv.attributes.uv;

        if (pvUV) {
          texVOffset = pvUV.getCoord(1);
        }
      }

      let dist = 0;

      if (x > 0) {
        const dx = pX - prevPathX;
        const dz = pZ - prevPathZ;

        dist = dx * dx + dz * dz;
        if (dist !== 0 && dist !== 1) {
          dist = Math.sqrt(dist);
        }
      }
      curU += dist;


      pvMatrix = this._getPathVertexMatrix(pv, pvMatrix);
      row = grid[x];
      if (!row) {
        row = grid[x] = [];
      }
      const wrapRow = grid[wrapX];

      for (y = 0; y < numShapeVerts; ++y) {
        const sv = shape[y]; // shape vertex
        const svUV = this._getVertexUV(sv);
        let curV = svUV ? svUV.getCoord(1) : 0;

        const wrapVert = wrapRow[y]; // returns the vertex in the first row if evaluating the last row
        let svt = null; // shape vertex (transformed)

        if (wrapVert) {
          // Reuse the first position & normal, but use different uv coords
          svt = new Vertex();
          svt.position = wrapVert.position;
          svt.attributes = {
            normal: this._getVertexNormal(wrapVert)
          };
        } else {
          // Create a new vertex by transforming the vertex from the
          // source shape using the transformation matrix of the current path point
          svt = this._transformShapeVertex(sv, pvMatrix);

          vertices.push(svt.position);
        }
        if (!svt.attributes) {
          svt.attributes = {};
        }
        uv = svt.attributes.uv;
        if (!uv) {
          uv = svt.attributes.uv = new Vector2();
          uvs.push(uv);
        }
        curV += texVOffset * uvCorrection;

        uv.setCoords(curU, -curV);
        polyVertices.push(svt);

        row[y] = svt;
      }

      prevPathX = pX;
      prevPathZ = pZ;
    }
    // Create quads from the grid vertices
    const polys = [];

    // for (x = 0; x < numPathVertsWrapped; ++x) {

    for (x = 0; x < numPathVerts; ++x) {
      // const x2 = (x + 1) % numPathVertsWrapped;
      const x2 = (x + 1) % (numPathVertsWrapped);

      row = grid[x];
      const nextRow = grid[x2];

      for (y = 0; y < numShapeVerts - 1; ++y) {
        const y2 = (y + 1) % numShapeVerts;

        // find vertices in grid
        const vTL = row[y];
        const vTR = nextRow[y];
        const vBL = row[y2];
        const vBR = nextRow[y2];

        // var polyVerts = [vTL, vTR, vBR, vBL];
        const polyVerts = [vBL, vBR, vTR, vTL];
        const poly = new Polygon(polyVerts);

        polys.push(poly);
      }
    }
    if (computeVertexNormals) {
      GeomUtils.calculateVertexNormals(polys);
    }
    if (computeTangents) {
      GeomUtils.calculateTangentsForPolygons(polys);
    }
    /*
    let adjustUVs = false;

    if (compType === 'zipper') {
      adjustUVs = true;
    }
    */

    /*
    if (adjustUVs) {
      // fit uvs
      const numUvs = uvs.length;
      let first = true;
      let minU = 0;
      let maxU = 0;
      let minV = 0;
      let maxV = 0;
      let i;
      let U;
      let V;

      for (i = 0; i < numUvs; ++i) {
        uv = uvs[i];
        if (uv) {
          U = uv.getCoord(0);
          V = uv.getCoord(1);
          if (first) {
            first = false;
            minU = maxU = U;
            minV = maxV = V;
          } else {
            minU = U < minU ? U : minU;
            minV = V < minV ? V : minV;
            maxU = U > maxU ? U : maxU;
            maxV = V > maxV ? V : maxV;
          }
        }
      }
      if (!first) {
        const texW = maxU - minU;
        const texH = maxV - minV;

        if (texW !== 0 && texH !== 0) {
          const scaleX = 1 / texW;
          const scaleY = 1 / texH;

          if (compType === 'zipper') {
            const scale = scaleX > scaleY ? scaleX : scaleY;

            for (i = 0; i < numUvs; ++i) {
              uv = uvs[i];
              if (uv) {
                U = uv.getCoord(0);
                V = uv.getCoord(1);
                U = (U - minU) * scale;
                V = (V - minV) * scale;
                uv.setCoords(U, V);
              }
            }
          }
        }
      }
    }

    const uvData = {};

    GeomUtils.normalizeUVs(uvs, params, uvData);
    */

    let geom = res;

    if (!geom) {
      geom = new Geometry();
    }

    geom.polygons = polys;
    geom.polygonVertices = polyVertices;
    geom.vertices = vertices;

    return geom;
  }
}
