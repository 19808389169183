
export default class LoaderState {
  constructor(name) {
    this.name = name;
  }
  static isFinished(state) {
    if (!state) {
      return false;
    }

    return state === LoaderState.ERROR || state === LoaderState.CANCELED || state === LoaderState.COMPLETED;
  }
}

LoaderState.IDLE = new LoaderState('idle'); // Loader not started yet
LoaderState.ERROR = new LoaderState('error'); // Loader failed
LoaderState.CANCELED = new LoaderState('canceled'); // Loader canceled
LoaderState.COMPLETED = new LoaderState('completed'); // Loader completed
LoaderState.PROGRESS = new LoaderState('progress'); // Loader running
