import Numeric from './Numeric';

const VALUE = ' _value_ ';

export default class NumericValue extends Numeric {
  constructor(val = 0) {
    super();
    this[VALUE] = val;
  }
  getNumber() {
    return this[VALUE];
  }
  setNumber(v) {
    this[VALUE] = v;
  }
}
