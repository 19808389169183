const NOT_FOUND = -1;

export default class ArrayUtils {
  static addOnce(array, value, equalsFunc = null) {
    if (!array) {
      return;
    }
    const index = this.findIndex(array, value, equalsFunc, 0);

    if (index < 0) {
      array.push(value);
    }
  }

  static isArray(arr) {
    if (!arr) {
      return false;
    }
    if (Array.isArray) {
      return Array.isArray(arr);
    }

    return arr instanceof Array;
  }

  /**
   * @method joinArrays
   * @static
   * @description Joins multiple arrays into one array. Ignores null values.
   * If just one array is provided, that array will be returned
   * Example:
   *  const array1 = [1, 2, 3];
   *  const array2 = [4, 5, 6];
   *  ArrayUtils.joinArrays(array1) // [1, 2, 3]
   *  ArrayUtils.joinArrays(array2) // [4, 5, 6]
   *  ArrayUtils.joinArrays(array1, null) // [1, 2, 3]
   *  ArrayUtils.joinArrays(null, array2) // [4, 5, 6]
   *  ArrayUtils.joinArrays(array1, array2) // [1, 2, 3, 4, 5, 6]
   *  ArrayUtils.joinArrays(array2, array1) // [4, 5, 6, 1, 2, 3]
   *  ArrayUtils.joinArrays(array1, array1) // [1, 2, 3, 1, 2, 3]
   *  ArrayUtils.joinArrays() // null
   *  ArrayUtils.joinArrays(array1) === array1 // true;
   *  ArrayUtils.joinArrays(array1, null) === array1 // true;
   *  ArrayUtils.joinArrays(null, array1) === array1 // true;
   * @returns {Array} - joined arrays
   * */
  static joinArrays(...arrays) {
    const num = arrays ? arrays.length : null;

    if (!num) {
      return null;
    }

    let res = null;
    let resIsNewArray = false;

    for (let i = 0; i < num; ++i) {
      const arr = arrays[i];
      const arrL = arr ? arr.length : 0;

      if (arrL > 0) {
        if (res) {
          if (!resIsNewArray) {
            resIsNewArray = true;
            res = res.concat();
          }
          for (let j = 0; j < arrL; ++j) {
            res.push(arr[j]);
          }
        } else {
          res = arr;
        }
      }
    }

    return res;
  }

  static copy(fromArray, toArray = null) {
    if (!toArray && fromArray && fromArray.concat) {
      return fromArray.concat();
    }
    const res = toArray || [];

    if (!fromArray) {
      return res;
    }
    const num = fromArray.length;

    if (!num) {
      return res;
    }
    res.length = num;
    for (let i = 0; i < num; ++i) {
      res[i] = fromArray[i];
    }

    return res;
  }

  static findIndex(array, equalsData, equalsFunc = null, fromIndex = 0) {
    if (!array) {
      return NOT_FOUND;
    }

    if (array.findIndex && equalsFunc && !equalsData) {
      return array.findIndex(equalsFunc);
    }

    const len = array.length;

    if (len === 0) {
      return NOT_FOUND;
    }

    if (!equalsFunc && array.indexOf) {
      return array.indexOf(equalsData, fromIndex);
    }

    for (let i = fromIndex; i < len; ++i) {
      const value = array[i];

      if (equalsFunc) {
        if (equalsFunc(value, i, array, equalsData)) {
          return i;
        }
      } else if (value === equalsData) {
        return i;
      }
    }

    return NOT_FOUND;
  }

  static map(array, map, params, resultArray = null) {
    if (!array || !map) {
      if (resultArray) {
        return this.copy(array, resultArray);
      }

      return array;
    }
    const num = array.length;

    if (!num) {
      if (resultArray) {
        return this.copy(array, resultArray);
      }

      return array;
    }

    const isFunc = typeof (map) === 'function' || (map.call && map.apply);

    if (!isFunc && !map.map) {
      if (resultArray) {
        return this.copy(array, resultArray);
      }

      return array;
    }
    let result = resultArray || array;

    if (result !== array) {
      result.length = num;
    }


    for (let i = 0; i < num; ++i) {
      const item = array[i];
      let mapItem = item;

      if (isFunc) {
        mapItem = map(item, i, num, array, params);
      } else {
        mapItem = map.map(item, i, num, array, params);
      }
      if (mapItem !== item && result === array) {
        result = this.copy(array);
      }
      result[i] = mapItem;
    }

    return result;
  }

  static filter(array, filter, filterParams = null, resultArray = null) {
    if (!array || !filter) {
      if (resultArray) {
        return this.copy(array, resultArray);
      }

      return array;
    }
    const num = array.length;

    if (!num) {
      if (resultArray) {
        return this.copy(array, resultArray);
      }

      return array;
    }

    const isFunc = typeof (filter) === 'function' || (filter.call && filter.apply);

    if (!isFunc && !filter.filter) {
      if (resultArray) {
        return this.copy(array, resultArray);
      }

      return array;
    }

    let result = resultArray || array;

    for (let i = 0; i < num; ++i) {
      const item = array[i];
      let include = true;

      if (isFunc) {
        include = filter(item, i, num, array, filterParams);
      } else {
        include = filter.filter(item, i, num, array, filterParams);
      }
      if (include) {
        if (result !== array) {
          result.push(item);
        }
      } else if (result === array) {
        result = array.slice(0, i);
      }
    }

    return result;
  }
}
