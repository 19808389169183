let reqAnimFrame = window.requestAnimationFrame;
let cancelAnimFrame = window.cancelAnimationFrame;

if (!reqAnimFrame) {
  const vendors = ['webkit', 'moz', 'o', 'ms'];
  const numVendors = vendors.length;

  for (let i = 0; i < numVendors; ++i) {
    const vendor = vendors[i];

    reqAnimFrame = window[`${vendor}RequestAnimationFrame`];
    cancelAnimFrame = window[`${vendor}CancelAnimationFrame`];
    if (reqAnimFrame) {
      break;
    }
  }

  if (!reqAnimFrame) {
    const delay = 16;

    reqAnimFrame = callback => {
      return window.setTimeout(callback, delay);
    };
    cancelAnimFrame = id => {
      window.clearTimeout(id);
    };
  }
}

let getAnimationTime;

if (typeof (performance) !== 'undefined' && performance.now) {
  getAnimationTime = () => {
    return performance.now();
  };
} else if (Date.now) {
  getAnimationTime = () => {
    return Date.now();
  };
} else {
  getAnimationTime = () => {
    const d = new Date();

    return d.getTime();
  };
}

export {
  getAnimationTime,
  reqAnimFrame,
  cancelAnimFrame
};
