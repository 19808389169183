import BorderComponentType from './BorderComponentType';
import Vector2 from '../../bgr/bgr3d/geom/Vector2';
import Vector3 from '../../bgr/bgr3d/geom/Vector3';
import Vertex from '../../bgr/bgr3d/geom/Vertex';
import MaterialTypes from '../material/MaterialTypes';
import BD3DFabricMaterial from '../material/BD3DFabricMaterial';
import MattressGeomUtils from '../geom/MattressGeomUtils';
import GeomUtils from '../../bgr/bgr3d/utils/GeomUtils';

const adjustUVParams = {
  keepUVAspect: true,
  uvMaxFit: true
};

/**
* @class RoundComponentType
* @abstract
* @extends BorderComponentType
* @description Base class for border components with an ellipsoid border surface
      c==========
     /
    /
    |
    |
    \
     \
     c===========
*/
export default class RoundBorderComponentType extends BorderComponentType {
  constructor() {
    super();
    this.thickness = 0;
    this.thicknessFactor = 0.0;
    this.roundness = 1;
  }

  adjustUVs(uvs, geom, data, mattressData) {
    if (!MattressGeomUtils || !adjustUVParams || !GeomUtils) {
      return;
    }

    let minY = 0, maxY = 0;

    for (let i = 0; i < uvs.length; ++i) {
      const uv = uvs[i];

      const ypos = uv.getCoord(1);

      if (i === 0) {
        minY = maxY = ypos;
      } else {
        minY = ypos < minY ? ypos : minY;
        maxY = ypos > maxY ? ypos : maxY;
      }
    }

    /*
    const outputData = {};

    GeomUtils.normalizeUVs(uvs, adjustUVParams, outputData);

    MattressGeomUtils.assignGeometryUVWorldTransform(geom, outputData);
    // */
  }

  getMaterial(data, mattressData, buildParams = null, result = null) {
    let res = result;

    if (!res) {
      res = new BD3DFabricMaterial();
    }
    res.setType(MaterialTypes.SAMPLE);
    res.setFabricType(data.material);
    res.setSettings(data.texture);

    return res;
  }

  getRoundness(data) {
    if (data) {
      const r = this._parseNum(data.roundness, null);

      if (this._isset(r)) {
        return r;
      }
    }

    if (this._isset(this.roundness)) {
      return this.roundness;
    }

    // return 0.5;
    return 1;
  }

  getAbsThickness(data) {
    if (data) {
      const th = this._parseNum(data.thickness, null);

      if (this._isset(th)) {
        return th;
      }
    }

    return this.thickness || 0;
  }

  getRelThickness(data) {
    if (data) {
      const thF = this._parseNum(data.thicknessFactor, null);

      if (this._isset(thF)) {
        return thF;
      }
    }

    return this.thicknessFactor || 0;
  }

  getResultThickness(borderComponentData, mattressData) {
    const thickness = this.getThickness(borderComponentData, mattressData);
    const roundness = this.getRoundness(borderComponentData, mattressData);

    return thickness * roundness;
  }

  getThickness(borderComponentData, mattressData) {
    const absThickness = this.getAbsThickness(borderComponentData);
    const relThickness = this.getRelThickness(borderComponentData);
    const h = this.getHeight(borderComponentData);

    return absThickness + relThickness * h;
  }

  hasDeformShape(data, mattressData) {
    if (!data) {
      return false;
    }
    const roundness = this.getRoundness(data, mattressData);

    if (roundness <= 0) {
      return false;
    }
    const thickness = this.getThickness(data, mattressData);

    return thickness > 0;
  }

  /**
   * @method getShapeXByY
   * @override
   * @description calculates the x-coordinate on the border shape by a given y-coordinate
   * @param {Number} y - y coordinate
   * @param {Object} data - border data of the config object
   * @param {Object} mattressData - mattressData
   * @return {Number} x - x-value
   **/
   // NOTE: Currently not 100% correct.
   // The bug has something to do with the roundness property &
   // the interpolation between a 'round' shape and a straight line
  getShapeXByY(y, data, mattressData) {
    const height = this.getHeight(data, mattressData);
    const thickness = this.getThickness(data, mattressData);
    const roundness = this.getRoundness(data, mattressData);
    let cosine = y / (height * 0.5);

    cosine = cosine < -1 ? -1 : cosine;
    cosine = cosine > 1 ? 1 : cosine;

    const sine = Math.sqrt(1 - cosine * cosine);
    const roundRes = -sine * thickness;

    /*
    var angle = Math.atan2(sine, cosine);
    var anglepct = angle / Math.PI;
    */

    const res = roundRes * roundness;

    return res;
  }

  /**
   * @method deformVertices
   * @param {Object} borderComponentData - border component json
   * @param {Object} mattressData - mattress json
   * @param {Object} yOffset - vertex y-offset
   * @param {Object} vOffset - texture v-coordinate offset
   * @param {Number} startX - force start x-coordinate value (null to ignore)
   * @param {Number} endX - force end x-coordinate value (null to ignore)
   * @param {BorderShape} borderShape - border shape instance
   * @param {BorderShape} arr - optional preallocated source array
   * @param {int} arrayInd - optional start index in the array
   * @returns {Array} array - the array of vertices
   * */
  deformVertices(borderComponentData, mattressData, yOffset, vOffset, startX, endX, borderShape, arr, arrayInd) {
    // input params
    const data = borderComponentData;
    let array = arr;
    let arrayIndex = arrayInd;
    const subdivs = 16;
    const height = this.getHeight(data, mattressData);

    if (height <= 0) {
      return arr;
    }

    // deform vertices
    if (arrayIndex === undefined || arrayIndex === null || arrayIndex < 0) {
      arrayIndex = 0;
    }

    // TODO: move some calculations to utils
    const roundness = this.getRoundness(data);
    const thickness = this.getThickness(data);
    const depth = this.getDepth(data);
    const top = height * 0.5;
    const bottom = -height * 0.5;
    let prevX;
    let prevY;
    let curV = 0;
    let i;
    let index;
    let vertex = null;
    let vertexPos = null;
    let normal = null;
    let uv = null;

    const hasStartX = startX !== null && startX !== undefined && !isNaN(startX);
    const hasEndX = endX !== null && endX !== undefined && !isNaN(endX);

    for (i = 0; i <= (subdivs); ++i) {
      const t = (i / subdivs);

      vertex = null;
      vertexPos = null;
      normal = null;
      uv = null;
      let cosine = -t * 2 + 1;
      let sine = 0; // = Math.sqrt(1 - cosine * cosine);

      let xpos = 0; // -sine * 10;
      let ypos = -(t - 0.5) * height;

      const angle = t * Math.PI + Math.PI * 0.5;

      cosine = Math.cos(angle);
      sine = Math.sin(angle);

      const rX = thickness;
      const rY = height * 0.5;

      const xposEllipse = cosine * rX;
      const yposEllipse = sine * rY;

      xpos = xpos + (xposEllipse - xpos) * roundness;
      ypos = ypos + (yposEllipse - ypos) * roundness;

      let dist = 0;

      if (i > 0) {
        const dx = xpos - prevX;
        const dy = ypos - prevY;

        dist = Math.sqrt(dx * dx + dy * dy);
      }

      curV += dist;

      index = arrayIndex + i;

      if (array) {
        vertex = array[index];
      }

      if (vertex) {
        vertexPos = vertex.position;
        if (vertex.attributes) {
          normal = vertex.attributes.normal;
          uv = vertex.attributes.uv;
        }
      } else {
        vertex = new Vertex();
      }

      if (!normal) {
        normal = new Vector3(-1, 0, 0);
      }

      let nx = -1;
      let ny = 0;
      const normalAspect = rX / rY;
      const nxEllipse = cosine;

      const nyEllipse = sine * normalAspect;

      nx = nx + (nxEllipse - nx) * roundness;
      ny = ny + (nyEllipse - ny) * roundness;

      let n = nx * nx + ny * ny;

      if (n !== 0 && n !== 1) {
        n = 1.0 / Math.sqrt(n);
        nx *= n;
        ny *= n;
      }

      normal.setCoord(0, nx);
      normal.setCoord(1, ny);
      normal.setCoord(2, 0);

      if (!uv) {
        uv = new Vector2(0, curV + vOffset);
      }
      uv.setCoord(0, 0);
      uv.setCoord(1, ypos);

      if (!vertexPos) {
        vertexPos = new Vector3(0, 0, 0);
      }

      xpos -= depth;

      // const borderRange = 2;
      const relBorderRange = 0.25;
      const borderRange = height * relBorderRange;

      if (i === 0 && hasStartX) {
        xpos = startX;
      } else if (i === subdivs && hasEndX) {
        xpos = endX;
      } else {
        let bt;

        if (hasStartX) {
          bt = (top - ypos) / borderRange;

          bt = bt < 0 ? 0 : bt;
          bt = bt > 1 ? 1 : bt;
          bt = 1.0 - bt;

          xpos = xpos + (startX - xpos) * bt;
        }
        if (hasEndX) {
          bt = (ypos - bottom) / borderRange;

          bt = bt < 0 ? 0 : bt;
          bt = bt > 1 ? 1 : bt;
          bt = 1.0 - bt;

          xpos = xpos + (endX - xpos) * bt;
        }
      }

      /*
      if (startX !== null && startX !== undefined && !isNaN(startX)) {
        if (i === 0) {
          xpos = startX;
        } else {
          let bt = (top - ypos) / borderRange;

          bt = bt < 0 ? 0 : bt;
          bt = bt > 1 ? 1 : bt;
          bt = 1.0 - bt;

          xpos = xpos + (startX - xpos) * bt;
        }
      }
      if (endX !== null && endX !== undefined && !isNaN(endX)) {
        if (i === subdivs) {
          xpos = endX;
        } else {
          let bt = (ypos - bottom) / borderRange;

          bt = bt < 0 ? 0 : bt;
          bt = bt > 1 ? 1 : bt;
          bt = 1.0 - bt;

          xpos = xpos + (endX - xpos) * bt;
        }
      }
      */

      vertexPos.setCoord(0, xpos);
      vertexPos.setCoord(1, ypos);
      vertexPos.setCoord(2, 0);

      vertexPos = this.borderTransformVector3(vertexPos, yOffset, borderShape, false, true, vertexPos);
      normal = this.borderTransformVector3(normal, yOffset - ypos, borderShape, true, true, normal);

      vertex.position = vertexPos;
      if (!vertex.attributes) {
        vertex.attributes = {};
      }
      vertex.attributes.uv = uv;
      vertex.attributes.normal = normal;
      if (!array) {
        array = [];
      }
      array[index] = vertex;

      prevX = xpos;
      prevY = ypos;
    }
    this.computeVertexNormals(array, subdivs + 1, arrayIndex);

    return array;
  }

  addVertices(borderComponentData, mattressData, yOffset, startX, endX, borderShape, array, index) {
    let ind = index;

    if ((ind === null || ind === undefined)) {
      if (array) {
        ind = array.length;
      } else {
        ind = 0;
      }
    }

    return this.deformVertices(borderComponentData, mattressData, yOffset, 0, startX, endX, borderShape, array, ind);
  }

  getHeight(data, mattressData) {
    if (!data) {
      return 0;
    }

    return this._parseNum(data.height,
      this._parseNum(this.height, 1));
  }

  getTop(data, mattressData) {
    return -this.getHeight(data, mattressData) * 0.5;
  }

  getBottom(data, mattressData) {
    return this.getHeight(data, mattressData) * 0.5;
  }
}
