export default class ControllerPlugin {
  constructor(args) {
    let keys, name, enabled;

    if (args) {
      keys = args.keys;
      name = args.name;
      enabled = args.enabled !== false;
    }

    this._keys = keys;
    this._name = name;

    if (enabled) {
      this.setEnabled(enabled);
    }
  }

  getTypeName() {
    return null;
  }

  dispose() {
    this.setEnabled(false);
  }

  getProperty(name) {
    return null;
  }

  setProperty(name, value) {
    return;
  }

  setProperties(properties) {
    return;
  }

  isEnabled() {
    return this._enabled === true;
  }

  setEnabled(enabled = true) {
    const e = enabled !== false;

    if (this._enabled === e) {
      return;
    }
    this._enabled = e;
    this._updateEnabled();
  }

  _updateEnabled() {
    return;
  }

  get enabled() {
    return this.isEnabled();
  }

  set enabled(b) {
    this.setEnabled(b);
  }
}
