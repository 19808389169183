
const TYPE_NUMBER = 'number';
const UNDEFINED = 'undefined';

const DEBUG = true;

// const EMPTY_ARRAY = null;

const EMPTY_ARRAY = [];

/* eslint-disable */
// This seems to fix a bug in safari (Reflect.apply -> TypeError: undefined is not an object!)
let tempArrayToFixSafariBug = null;
/* eslint-enable */

function deepCopy(value, visited = null) {
  if (!value) {
    return value;
  }

  const t = typeof (value);

  // primitive values
  if (t === 'number' || t === 'string' || t === 'boolean') {
    return value;
  }
  let vis = visited;

  if (vis && vis.has && vis.has(value)) {
    return vis.get(value);
  }

  if (!vis) {
    if (typeof (WeakMap) !== 'undefined') {
      vis = new WeakMap();
    } else if (typeof (Map) !== 'undefined') {
      vis = new Map();
    }
  }
  let res = null;

  if ((value instanceof Array) || (Array.isArray && Array.isArray(value))) {
    res = [];
    vis.set(value, res);
    const len = value.length;

    res.length = len;
    for (let i = 0; i < len; ++i) {
      res[i] = deepCopy(value[i], vis);
    }

    return res;
  }
  if (t === 'object') {
    res = {};
    vis.set(value, res);

    for (const v in value) {
      if (value.hasOwnProperty(v)) {
        res[v] = deepCopy(value[v], vis);
      }
    }

    return res;
  }

  return null;
}

let newSymbol;

if (typeof (Symbol) !== 'undefined' && typeof (Symbol()) === 'symbol') {
  newSymbol = name => {
    return Symbol(name);
  };
} else {
  {
    let counter = 0;

    newSymbol = name => {
      const HEX = 16;
      const c = counter.toString(HEX);
      const n = name || '';

      ++counter;

      return ` _#$_${c}_${n}#_ `;
    };
  }
}


export default class Utils {

  static newSymbol(name) {
    return newSymbol(name);
  }

  static deepCopy(object) {
    return deepCopy(object);
  }

  /**
   * Calls Reflect.apply but auto fixes null/undefined as arguments array
   * @param  {Function} func - function
   * @param  {Object} scope = null - scope
   * @param  {Array} args = null - Arguments array
   * @return {Object} - Whatever Reflect.apply returns
   */
  static applyFunc(func, scope = null, args = null) {
    if (!func) {
      return null;
    }

    const sc = scope;
    let a = args;

    if (a === null || typeof (a) === UNDEFINED) {
      a = EMPTY_ARRAY;
    }

    // This guy fixes the safari bug. If you leave this line out, you get a 'TypeError: undefined is not an object!'
    tempArrayToFixSafariBug = a;

    let res = null;

    res = Reflect.apply(func, sc, a);

    tempArrayToFixSafariBug = null;

    return res;
  }

  static callFunc(func, scope = null, ...args) {
    return Utils.applyFunc(func, scope, args);
  }

  /**
   * Exposes values to the public global scope
   * @param  {String} name - property name
   * @param  {Object} value - value
   * @return {void}
   */
  static expose(name, value) {
    if (name === null || typeof (name) === UNDEFINED) {
      return;
    }
    let global = null;

    if (typeof (window) !== UNDEFINED) {
      global = window;
    }
    if (global === null || typeof (global) === UNDEFINED) {
      return;
    }
    global[name] = value;
  }

  /**
   * Same as Utils.expose() but only enabled when in DEBUG mode
   * @param  {String} name - property name
   * @param  {Object} value - property value
   * @return {void}
   */
  static exposeDebug(name, value) {
    if (!DEBUG) {
      return;
    }
    Utils.expose(name, value);
  }

  /**
   * Returns the first valid value (not null, not undefined, not NaN)
   * @param  {...Object} values - Values to check
   * @return {Object} - First non-null, non-undefined, non-NaN value from the arguments.
   * Only returns null if no valid value is found
   */
  static tryValues(...values) {
    const l = values.length;

    if (l === 0) {
      return null;
    }
    for (let i = 0; i < l; ++i) {
      const val = values[i];
      const t = typeof (val);

      if (val !== null && t !== UNDEFINED) {
        if (t === TYPE_NUMBER) {
          if (!isNaN(val)) {
            return val;
          }
        } else {
          return val;
        }
      }
    }

    return null;
  }

  /**
   * Replacement for the ternary operator (?:) because ESLint complains about it.
   * @param  {Boolean} condition - Condition value
   * @param  {Object} trueValue - Value to be returned if condition is true
   * @param  {Object} falseValue - Value to be returned if condition is false
   * @return {Object} - If condition is true, trueValue will be returned, else falseValue will be returned
   */
  static ternary(condition, trueValue, falseValue) {
    if (condition) {
      return trueValue;
    }

    return falseValue;
  }

  /**
   * @method isPlainObject
   * @static
   * @description Checks whether an object is a plain object
   *  and does not inherit any other class than Object
   * @param {Object} obj - Object
   * @returns {boolean} - true if obj is a plain object
   **/
  static isPlainObject(obj) {
    if (typeof obj === 'object' && obj !== null) {
      let targetOb = null;

      if (typeof (Reflect) === 'undefined') {
        targetOb = Object;
      } else {
        targetOb = Reflect;
      }
      const funcName = 'getPrototypeOf';
      const func = targetOb[funcName];

      if (func && func.call) {
        const proto = targetOb[funcName](obj);

        return proto === Object.prototype || proto === null;
      }

      return obj.constructor === Object;
    }

    return false;
  }
}
