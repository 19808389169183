import RoundBorderComponentType from './RoundBorderComponentType';
import BD3DMaterial from '../material/BD3DMaterial';
import MaterialType from '../material/MaterialType';
import MaterialTypes from '../material/MaterialTypes';
import Node3DMaterialUtils from '../material/Node3DMaterialUtils';
import MattressGeomUtils from '../geom/MattressGeomUtils';
import GeomUtils from '../../bgr/bgr3d/utils/GeomUtils';
// import MaterialGeometryNode3D from '../../bgr/bgr3d/scenegraph/MaterialGeometryNode3D';
// import ContainerNode3D from '../../bgr/bgr3d/scenegraph/ContainerNode3D';
import BD3DGeometryNode3D from '../scenegraph/BD3DGeometryNode3D';
import BD3DContainerNode3D from '../scenegraph/BD3DContainerNode3D';
import BD3DNodeUtils from '../scenegraph/BD3DNodeUtils';

import Node3DCloner from '../../bgr/bgr3d/cloners/Node3DCloner';
import SRTTransform3D from '../../bgr/bgr3d/transform/SRTTransform3D';

// import AssetManager from '../asset/AssetManager';
import ImageAsset from '../asset/ImageAsset';
import Object3DAsset from '../asset/Object3DAsset';

import BD3DNodeTypes from '../scenegraph/BD3DNodeTypes';
import Utils from '../utils/Utils';

const zipperMaterialType = new MaterialType('zipper');
const adjustUVParams = {uvMaxFit: true};
const plasticMaterialType = MaterialTypes.PLASTIC; // new MaterialType('plastic');

const DEFAULT_PULLER_TYPE = '1';

const Assets = {
  TEX_ASSET_DEFAULT_COLOR: new ImageAsset('zipper.types.default.tex_color'),
  TEX_ASSET_DEFAULT_NORMAL: new ImageAsset('zipper.types.default.tex_normal'),
  TEX_ASSET_DEFAULT_SPECULAR: new ImageAsset('zipper.types.default.tex_specular'),

  TEX_ASSET_SPIRAL_COIL_COLOR: new ImageAsset('zipper.types.spiral_coil.tex_color'),
  TEX_ASSET_SPIRAL_COIL_NORMAL: new ImageAsset('zipper.types.spiral_coil.tex_normal'),
  TEX_ASSET_SPIRAL_COIL_SPECULAR: new ImageAsset('zipper.types.spiral_coil.tex_specular'),

  TEX_ASSET_REVERSE_COIL_COLOR: new ImageAsset('zipper.types.reverse_coil.tex_color'),
  TEX_ASSET_REVERSE_COIL_NORMAL: new ImageAsset('zipper.types.reverse_coil.tex_normal'),

  OBJECT_ZIPPER_PULLER: new Object3DAsset('zipper.pullers.puller1.object'),
  TEX_ZIPPER_PULLER_LIGHTMAP: new ImageAsset('zipper.pullers.puller1.tex_lightmap'),

  OBJECT_H_ZIPPER_PULLER: new Object3DAsset('zipper.pullers.puller2.object'),
  TEX_H_ZIPPER_PULLER_LIGHTMAP: new ImageAsset('zipper.pullers.puller2.tex_lightmap')
};

export default class ZipperBorderComponentType extends RoundBorderComponentType {
  constructor() {
    super();
    this.height = 1;
  }

  getNodeType() {
    return BD3DNodeTypes.zipper;
  }

  static getMaterialType() {
    return zipperMaterialType;
  }
  static get materialType() {
    return this.getMaterialType();
  }

  _getPullerType(data) {
    if (!data) {
      return null;
    }
    if (typeof (data.puller) !== 'undefined') {
      return data.puller;
    }
    if (typeof (data.pullerType) !== 'undefined') {
      return data.pullerType;
    }

    return data.pullertype;
  }

  _getZipperType(data) {
    if (!data) {
      return null;
    }

    return Utils.tryValues(data.zipperType, data.zippertype);
  }

  addAssets(data, params, array = null, assetManager = null) {
    return this.addZipperAssets(data, params, array, assetManager);
  }

  addZipperAssets(data, params, array = null, assetManager = null, defaultType = null, pullerType = null) {
    let arr = array;

    if (!data) {
      return arr;
    }

    let zt = this._getZipperType(data);
    let pt = this._getPullerType(data);

    if (typeof (pt) === 'undefined') {
      pt = DEFAULT_PULLER_TYPE;
    }

    if (zt === null || typeof (zt) === 'undefined') {
      zt = defaultType;
    }

    if (zt === 'spiral_coil') {
      arr = assetManager.addAssetToArray(Assets.TEX_ASSET_SPIRAL_COIL_COLOR, arr, params);
      arr = assetManager.addAssetToArray(Assets.TEX_ASSET_SPIRAL_COIL_NORMAL, arr, params);
      arr = assetManager.addAssetToArray(Assets.TEX_ASSET_SPIRAL_COIL_SPECULAR, arr, params);
    } else if (zt === 'reverse_coil') {
      arr = assetManager.addAssetToArray(Assets.TEX_ASSET_REVERSE_COIL_COLOR, arr, params);
      arr = assetManager.addAssetToArray(Assets.TEX_ASSET_REVERSE_COIL_NORMAL, arr, params);
    } else {
      arr = assetManager.addAssetToArray(Assets.TEX_ASSET_DEFAULT_COLOR, arr, params);
      arr = assetManager.addAssetToArray(Assets.TEX_ASSET_DEFAULT_NORMAL, arr, params);
      arr = assetManager.addAssetToArray(Assets.TEX_ASSET_DEFAULT_SPECULAR, arr, params);
    }

    if (pt === '2' || pt === 2 || pullerType === 'horizontal_puller') {
      arr = assetManager.addAssetToArray(Assets.OBJECT_H_ZIPPER_PULLER, arr, params);
      arr = assetManager.addAssetToArray(Assets.TEX_H_ZIPPER_PULLER_LIGHTMAP, arr, params);
    } else if (pt === 1 || pt === '1') {
      arr = assetManager.addAssetToArray(Assets.OBJECT_ZIPPER_PULLER, arr, params);
      arr = assetManager.addAssetToArray(Assets.TEX_ZIPPER_PULLER_LIGHTMAP, arr, params);
    }

    return arr;
  }

  createPuller(data, mattressData, color = null, buildParams = null, type = null) {
    let col = color;

    if (col === null || typeof (col) === 'undefined') {
      col = data ? data.color : null;
    }
    let pt = this._getPullerType(data);
    let zipperPullerObjectAsset = null;
    let lightMap = null;

    if (typeof (pt) === 'undefined') {
      pt = DEFAULT_PULLER_TYPE;
    }

    if (pt === 2 || pt === '2' || type === 'horizontal_puller') {
      zipperPullerObjectAsset = Assets.OBJECT_H_ZIPPER_PULLER;
      lightMap = Assets.TEX_H_ZIPPER_PULLER_LIGHTMAP;
    } else if (pt === 1 || pt === '1') {
      zipperPullerObjectAsset = Assets.OBJECT_ZIPPER_PULLER;
      lightMap = Assets.TEX_ZIPPER_PULLER_LIGHTMAP;
    }
    // const zipperPullerObjectAsset = buildParams.assets.getAsset('zipper_puller_object');
    let zipperPullerNode3D = zipperPullerObjectAsset ? zipperPullerObjectAsset.getNode3D() : null;

    if (zipperPullerNode3D) {
      zipperPullerNode3D = Node3DCloner.clone(zipperPullerNode3D, {cloneGeometry: false});
      BD3DNodeUtils.setNodeType(zipperPullerNode3D, BD3DNodeTypes.zipper, true);

      const children = zipperPullerNode3D.children;
      const numChildren = children.length;

      // const lightMap = buildParams.assets.getAssetByName('zipper_puller_lightmap');

      for (let i = 0; i < numChildren; ++i) {
        const child = children[i];
        let mat = Node3DMaterialUtils.getMaterial(child);

        if (!mat) {
          mat = new BD3DMaterial();
        }
        mat.setType(plasticMaterialType);
        mat.set('lightmap', lightMap);
        mat.set('colorMultiplier', col);
        mat.set('colorType', 'zipper');
        Node3DMaterialUtils.setMaterial(child, mat);
      }
    }

    return zipperPullerNode3D;
  }

  createNode3D(geom, borderComponentData, mattressData, borderLoop, borderShape, borderCurveGraph, buildParams) {
    const geomNode = new BD3DGeometryNode3D(geom);
    const canSetPuller = borderLoop !== null && borderLoop !== undefined;

    let material = Node3DMaterialUtils.getMaterial(geomNode);

    material = this.getMaterial(borderComponentData, mattressData, buildParams, material);
    Node3DMaterialUtils.setMaterial(geomNode, material);

    if (!canSetPuller) {
      return geomNode;
    }

    let posOffX = 0, posOffY = 0, posOffZ = 0;

    if (geom && geom.userData) {
      const borderOffset = geom.userData.borderMiddleOffset;

      if (typeof (borderOffset) !== 'undefined' && borderOffset !== null) {
        this._tempTransform = borderShape.getTransformAtLength(borderOffset, this._tempTransform);
        if (this._tempTransform) {
          if (this._tempTransform.locationName !== 'middle') {
            // Zipper puller location in the curved part -> temporary solution to disable the zipper puller
            return geomNode;
          }
          const m4 = this._tempTransform.matrix4;

          posOffX = m4.getX();
          posOffY = m4.getY();
          posOffZ = m4.getZ();
        }
      }
    }

    const numBorderVerts = borderLoop.length;
    // Find a position on the border loop where the puller mesh should not be rotated to align the slope
    // Don't start at position index 0, there is currently a UV alignment error.
    // -> The puller should not set visual attention to this position ;)
    var pullerPosition = borderComponentData && borderComponentData.pullerPosition;

    const fromIndex = typeof pullerPosition === 'number' ? 
      ((numBorderVerts * (((pullerPosition % 1) + 1) % 1)) | 0)
      : (numBorderVerts >> 1);
    
    let vec = borderLoop[fromIndex];

    for (let i = 0; i < numBorderVerts; ++i) {
      const I = (i + fromIndex) % numBorderVerts;
      const prevI = (((I - 1) % numBorderVerts) + numBorderVerts) % numBorderVerts;
      const nextI = (I + 1) % numBorderVerts;
      const v = borderLoop[I];
      const prevV = borderLoop[prevI];
      const nextV = borderLoop[nextI];
      const dy1 = prevV.getCoord(1) - v.getCoord(1);
      const dy2 = nextV.getCoord(1) - v.getCoord(1);
      const toll = 0.001;

      if (dy1 <= toll && dy1 >= -toll && dy2 <= toll && dy2 >= -toll) {
        vec = v;
        break;
      }
    }

    const pos = vec;
    let rotY = 0;

    let depth = this.getDepth(borderComponentData, mattressData);

    if (typeof (depth) === 'string') {
      depth = parseFloat(depth);
    }

    if (vec.attributes && vec.attributes.normal) {
      const nrm = vec.attributes.normal;
      const nrmX = nrm.getCoord(0);
      const nrmZ = nrm.getCoord(2);

      if (typeof (depth) === 'number' && !isNaN(depth)) {
        posOffX += nrmX * depth;
        posOffZ += nrmZ * depth;
      }

      rotY = Math.atan2(-nrmZ, nrmX);
    }
    rotY += Math.PI * 0.5;

    const pullerColor = borderComponentData.color;
    const zipperPullerNode3D = this.createPuller(borderComponentData, mattressData, pullerColor, buildParams, null);

    const children = [geomNode];

    if (zipperPullerNode3D) {
      zipperPullerNode3D.transform = new SRTTransform3D();

      const pullerScaleFactor = 1;
      const scale = this.getHeight(borderComponentData, mattressData) * pullerScaleFactor;

      zipperPullerNode3D.transform.scale.setCoords(scale, scale, scale);

      zipperPullerNode3D.transform.position.setCoords(
        pos.getCoord(0) + posOffX,
        pos.getCoord(1) + posOffY,
        pos.getCoord(2) + posOffZ);

      zipperPullerNode3D.transform.rotation.setCoord(1, rotY);
      children.push(zipperPullerNode3D);
    }

    const res = new BD3DContainerNode3D(children);

    res.setSelectable(true);

    return res;
  }

  hasTextureUVCorrection() {
    return false;
  }

  adjustUVs(uvs, geom, data, mattressData) {
    let outputData = MattressGeomUtils.getGeometryUVWorldTransform(geom);

    if (!outputData) {
      outputData = {};
    }

    GeomUtils.normalizeUVs(uvs, adjustUVParams, outputData);

    // MattressGeomUtils.assignGeometryUVData(geom, outputData);
    MattressGeomUtils.assignGeometryUVWorldTransform(geom, outputData);
  }

  _getZipperMaterialType() {
    return zipperMaterialType;
  }

  getMaterial(data, mattressData, buildParams = null, result = null) {
    return this.getZipperMaterial(data, mattressData, buildParams, result);
  }

  getZipperMaterial(data, mattressData, buildParams = null, result = null, defaultZipperType = null) {
    let res = result;

    if (!res) {
      res = new BD3DMaterial();
    }
    res.setType(this._getZipperMaterialType());

    let zt = this._getZipperType(data);

    if (zt === null || typeof (zt) === 'undefined') {
      zt = defaultZipperType;
    }


    let zipperTexture = null, zipperNormalMap = null, zipperSpecularMask = null;

    if (zt === 'spiral_coil') {
      zipperTexture = Assets.TEX_ASSET_SPIRAL_COIL_COLOR;
      zipperNormalMap = Assets.TEX_ASSET_SPIRAL_COIL_NORMAL;
      zipperSpecularMask = Assets.TEX_ASSET_SPIRAL_COIL_SPECULAR;
    } else if (zt === 'reverse_coil') {
      zipperTexture = Assets.TEX_ASSET_REVERSE_COIL_COLOR;
      zipperNormalMap = Assets.TEX_ASSET_REVERSE_COIL_NORMAL;
    } else {
      zipperTexture = Assets.TEX_ASSET_DEFAULT_COLOR;
      zipperNormalMap = Assets.TEX_ASSET_DEFAULT_NORMAL;
      zipperSpecularMask = Assets.TEX_ASSET_DEFAULT_SPECULAR;
    }

    res.set('diffuse', zipperTexture);
    res.set('normal', zipperNormalMap);
    res.set('specularMask', zipperSpecularMask);
    res.set('colorType', 'zipper');
    res.set('colorMultiplier', data.color);

    return res;
  }
}
