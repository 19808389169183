/* eslint max-depth: 0 */
import Vertex from '../../bgr/bgr3d/geom/Vertex';
import Vector2 from '../../bgr/bgr3d/geom/Vector2';
import Vector3 from '../../bgr/bgr3d/geom/Vector3';
import Polygon from '../../bgr/bgr3d/geom/Polygon';
// import Geometry from '../../bgr/bgr3d/geom/Geometry'
import Geometry from '../../bgr/bgr3d/geom/Geometry';
import VectorMath from '../../bgr/bgr3d/math/VectorMath';

// import VectorMath from '../../bgr/bgr3d/math/VectorMath';
import BD3DGeometry from '../geom/BD3DGeometry';
import GeomUtils from '../../bgr/bgr3d/utils/GeomUtils';
import Utils from '../../bgr/common/utils/Utils';
import Graph from '../graph/Graph';
import GraphUtils from '../graph/GraphUtils';
import BorderContourUtil from './BorderContourUtil';
import GeometryNode3D from '../../bgr/bgr3d/scenegraph/GeometryNode3D';
// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
// #endif
/*
import Graph from '../graph/Graph';
import GraphUtils from '../graph/GraphUtils';
*/

const TOP_LEFT = 0;
const TOP_RIGHT = 1;
const BOTTOM_LEFT = 2;
const BOTTOM_RIGHT = 3;

const LEFT = 0;
const TOP = 1;
const RIGHT = 2;
const BOTTOM = 3;

const HALF = 0.5;
const HALF_PI = HALF * Math.PI;

const DEFAULT_MIN_SUBDIVS = 8;
const DEFAULT_MAX_SUBDIVS = 50;

function tryValues(...args) {
  return Utils.tryValues(...args);
}

// #if DEBUG
function timeStart(name) {
  BD3DLogger.time(name);
}
function timeEnd(name) {
  BD3DLogger.timeEnd(name);
}
// #endif

function vRotateZ(vector, angle) {
  return VectorMath.zRotate(vector, angle);
}

function isSeamEdgeIndex(edgeIndex, seamIndices) {
  if (!seamIndices) {
    return false;
  }

  return seamIndices.indexOf(edgeIndex, 0) >= 0;
}

/*
const TopVertexLocationCategory = {
  TOP: 'TOP',
  TOP_STRAIGHT_BORDER_EDGE: 'TOP_STRAIGHT_BORDER_EDGE',
  TOP_CORNER: 'TOP_CORNER',
  SIDE_STRAIGHT_BORDER_EDGE: 'SIDE_STRAIGHT_BORDER_EDGE',
  SIDE_CORNER: 'SIDE_CORNER'
};

const TopVertexLocation = {
  TOP: {category: TopVertexLocationCategory.TOP},

  TOP_LEFT_EDGE: {category: TopVertexLocationCategory.TOP_STRAIGHT_BORDER_EDGE},
  TOP_RIGHT_EDGE: {category: TopVertexLocationCategory.TOP_STRAIGHT_BORDER_EDGE},
  TOP_UPPER_EDGE: {category: TopVertexLocationCategory.TOP_STRAIGHT_BORDER_EDGE},
  TOP_LOWER_EDGE: {category: TopVertexLocationCategory.TOP_STRAIGHT_BORDER_EDGE},

  TOP_UPPER_LEFT_CORNER: {category: TopVertexLocationCategory.TOP_CORNER},
  TOP_UPPER_RIGHT_CORNER: {category: TopVertexLocationCategory.TOP_CORNER},
  TOP_LOWER_LEFT_CORNER: {category: TopVertexLocationCategory.TOP_CORNER},
  TOP_LOWER_RIGHT_CORNER: {category: TopVertexLocationCategory.TOP_CORNER},

  SIDE_LEFT_EDGE: {category: TopVertexLocationCategory.SIDE_STRAIGHT_BORDER_EDGE},
  SIDE_RIGHT_EDGE: {category: TopVertexLocationCategory.SIDE_STRAIGHT_BORDER_EDGE},
  SIDE_UPPER_EDGE: {category: TopVertexLocationCategory.SIDE_STRAIGHT_BORDER_EDGE},
  SIDE_LOWER_EDGE: {category: TopVertexLocationCategory.SIDE_STRAIGHT_BORDER_EDGE},

  SIDE_UPPER_LEFT_CORNER: {category: TopVertexLocationCategory.SIDE_CORNER},
  SIDE_UPPER_RIGHT_CORNER: {category: TopVertexLocationCategory.SIDE_CORNER},
  SIDE_LOWER_LEFT_CORNER: {category: TopVertexLocationCategory.SIDE_CORNER},
  SIDE_LOWER_RIGHT_CORNER: {category: TopVertexLocationCategory.SIDE_CORNER}
};
*/
export default class MattressGeomUtils {
  // TODO: fix normals when border radius = 0
  // TODO: fix normals & topology when border radius = 0 & corner radius = 0

  /**
  * @method generateTopMesh
  * @description - Creates the top mesh of the mattress. If the scaleY param is set to -1,
  * this method will create the bottom mesh of the mattress.
  * @param {Number} width - The width of the mattress (x-axis)
  * @param {Number} length - The length of the mattress (z-axis)
  * @param {Number} height - The height of the mattress (y-axis)
  * @param {Number} cornerRadius -  Radius of the 4 corner edges of the mattress
  *     _________
  *    /   |
  *   |___  Corner radius viewed from the top
  *   |
  * @param {Number} borderRadius - Radius of the border. This value should not be greater than the corner radius
  *      ___________
  *    /  |
  *   |---   Border radius from the side
  *   |
  * @param {Number} scaleY - Y-scale value. Use 1 to create the top part of the mattress, -1 for the bottom part.
  * @param {Graph} borderCurve - Graph instance using a spline curve to calculate a y-value by a given x.
  *   Imagine the deforming spline being wrapped around the mattress from the left side,
  *   following the back and right to the front side, going counter-clockwise if looking from above
  *   If x is a value between 0 and 0.25, the y-value of the left side will be deformed
  *   If x is a value between 0.25 and 0.5, the y-value of the bottom side will be deformed
  *   If x is a value between 0.5 and 0.75, the y-value of the right side will be deformed
  *   If x is a value between 0.75 and 1, the y-value of the top side will be deformed
  *   |   left  |  back   |  right  |  front  | y = 1
  *   |---------|---------|---------|---------|
  *   |         |         |         |         | y = -1
  *   x = 0               ->              x = 1
  * @param {Graph} borderCurveMinY - the min value of the border shape
  * @param {Graph} borderCurveMaxY - the max value of the border shape
  * @param {Graph} borderCurvePositiveHeight - the height of the border curve in positive space
  *   The borderCurve instance usually returns a value between -1 and 1.
  *   The 'borderCurveNegativeHeight' value will be multiplied with the borderCurve result if < 0
  *   Note: usually borderCurvePositiveHeight and borderCurveNegativeHeight should have equal values,
  *   but having these two as separate parameters gives more flexibility
  * @param {Graph} borderCurveNegativeHeight - the height of the border curve in negative space
  *   The borderCurve instance usually returns a value between -1 and 1.
  *   The 'borderCurvePositiveHeight' value will be multiplied with the borderCurve result if > 0
  * @param {Object} params - optional params
  * @returns {Geometry} geometry instance
  **/

  static createTopBottomGeometry(width, length, height, cornerRadius, borderRadius,
    isTop, borderCurve, borderCurveMinY, borderCurveMaxY,
    borderCurvePositiveHeight, borderCurveNegativeHeight, params, extraOutputParams = null, resultGeom = null) {

    let extraOutput = extraOutputParams;

    if (extraOutput) {
      extraOutput.borderLoop = null;
    } else {
      extraOutput = {};
    }

    const isTopType = typeof (isTop);
    const negOne = -1;
    let scaleY = 1;

    if (isTopType === 'number') {
      scaleY = isTop;
    } else if (isTopType === 'boolean') {
      scaleY = isTop ? 1 : negOne;
    }
    const polys = MattressGeomUtils._generateTopPolys(width, length, height,
      cornerRadius, borderRadius, scaleY,
      borderCurve, borderCurveMinY, borderCurveMaxY,
      borderCurvePositiveHeight, borderCurveNegativeHeight, params, extraOutput);

    let geom = resultGeom;

    if (!geom) {
      geom = new Geometry();
    }

    geom.polygons = polys;

    let vertices = null;
    let polyVertices = null;
    let uvs = null;
    let normals = null;

    vertices = extraOutput.vertices;
    polyVertices = extraOutput.polygonVertices;
    uvs = extraOutput.uvs;
    normals = extraOutput.normals;

    if (!vertices) {
      vertices = GeomUtils.getVertexPositionsFromPolys(polys);
    }
    if (!polyVertices) {
      polyVertices = GeomUtils.getVerticesFromPolys(polys);
    }
    if (!uvs) {
      uvs = GeomUtils.getUVsFromPolygons(polys);
    }
    if (!normals) {
      normals = GeomUtils.getNormalsFromPolygons(polys);
    }

    geom.vertices = vertices;
    geom.polygonVertices = polyVertices;

    geom.vertexAttributes = {
      uv: uvs,
      normal: normals,
      position: vertices
    };

    if (extraOutput) {
      if (!geom.userData) {
        geom.userData = {};
      }
      const uvData = extraOutput.uvData;
      let geomUVData = this.getGeometryUVWorldTransform(geom);

      if (!geomUVData) {
        geomUVData = {};
        this.assignGeometryUVWorldTransform(geom, geomUVData);
      }
      geomUVData.scaleU = uvData.scaleU;
      geomUVData.scaleV = uvData.scaleV;
      geomUVData.translateU = uvData.translateU;
      geomUVData.translateV = uvData.translateV;
      geomUVData.minU = uvData.minU;
      geomUVData.minV = uvData.minV;
      geomUVData.maxU = uvData.maxU;
      geomUVData.maxV = uvData.maxV;
    }

    return geom;
  }

  static getGeometryUVData(geom) {
    // #if DEBUG
    BD3DLogger.warn('getGeometryUVData is deprecated, use getGeometryUVWorldTransform instead');
    // #endif

    return this.getGeometryUVWorldTransform(geom);
  }

  static getGeometryUVWorldTransform(geom) {
    if (!geom) {
      return null;
    }
    if (geom instanceof GeometryNode3D) {
      return this.getGeometryUVWorldTransform(geom.geometry);
    }
    if (geom instanceof BD3DGeometry) {
      return geom.getUVWorldTransform();
    }
    const ud = geom.userData;

    if (!ud) {
      return null;
    }

    return ud.uvData;
  }

  static setGeometryUVWorldTransform(geom, scaleU, scaleV, translateU, translateV) {
    if (!geom) {
      return;
    }
    let tr = this.getGeometryUVWorldTransform(geom);

    if (!tr) {
      tr = {};
      this.assignGeometryUVWorldTransform(geom, tr);
    }

    tr.scaleU = scaleU;
    tr.scaleV = scaleV;
    tr.translateU = translateU;
    tr.translateV = translateV;
  }

  static assignGeometryUVData(geom, uvData) {
    // #if DEBUG
    BD3DLogger.warn('assignGeometryUVData is deprecated, use assignGeometryUVWorldTransform');
    // #endif
    this.assignGeometryUVWorldTransform(geom, uvData);
  }

  static assignDefaultGeometryUVWorldTransform(geom, params) {
    if (!geom) {
      return null;
    }
    let uvs = null;

    if (geom.attributes) {
      uvs = geom.attributes.uv || geom.attributes.uvs;
    }
    if (!uvs) {
      uvs = GeomUtils.getUVsFromPolygons(geom.polygons);
    }
    const output = {};

    GeomUtils.normalizeUVs(uvs, params, output);
    this.assignGeometryUVWorldTransform(geom, output);

    return output;
  }

  static assignGeometryUVWorldTransform(geom, uvData) {
    if (!geom) {
      return;
    }
    if (geom instanceof BD3DGeometry) {
      geom.uvWorldTransform = uvData;
    }
    if (uvData && !geom.userData) {
      geom.userData = {};
    }
    if (geom.userData) {
      geom.userData.uvData = uvData;
    }
  }

  static _newPolyVertex(vertexPosition, attributes) {
    return new Vertex(vertexPosition, attributes);
  }

  static _newQuad(v1, v2, v3, v4, flip = false) {
    const verts = [v1, v2, v3, v4];

    if (flip) {
      verts.reverse();
    }

    return new Polygon(verts);
  }

  // Rotates a vector about the y-axis
  static _vRotateY(vector, angle, cx = 0, cz = 0) {
    if (angle === 0) {
      return;
    }
    const x = vector.getCoord(0) - cx;
    const z = vector.getCoord(2) - cz;

    const cosine = Math.cos(angle);
    const sine = Math.sin(angle);

    const tx = x * cosine - z * sine;
    const tz = z * cosine + x * sine;

    vector.setCoord(0, tx + cx);
    vector.setCoord(2, tz + cz);
  }

  static _createGridQuads(grid, polygons, flip = false) {
    if (!grid) {
      return;
    }
    const numRows = grid.length;
    // const flip = false;

    const lastRowIndex = numRows - 1;

    for (let y = 0; y < lastRowIndex; ++y) {
      const row = grid[y];
      const nextRow = y < lastRowIndex ? grid[y + 1] : null;

      if (row && nextRow) {
        const numCols1 = row.length;
        const numCols2 = nextRow.length;
        const numCols = numCols1 < numCols2 ? numCols1 : numCols2;
        const lastColIndex = numCols - 1;

        for (let x = 0; x < lastColIndex; ++x) {
          const vTL = row[x];
          const vTR = row[x + 1];
          const vBL = nextRow[x];
          const vBR = nextRow[x + 1];

          if (vTL && vTR && vBL && vBR) {
            // const poly = MattressGeomUtils._newQuad(vTL, vTR, vBR, vBL, flip);
            const poly = MattressGeomUtils._newQuad(vTL, vBL, vBR, vTR, flip);

            polygons.push(poly);
          }
        }
      }
    }
  }
  /*
  Top/Bottom geometry parameters:

  x_subdivs: horizontale subdivisions van bovenuit bekeken
  y_subdivs: horizontale subdivisions vanaf de zijkant bekeken
  z_subdivs: verticale subdivisions van bovenuit bekeken
  corner_subdivs: aantal subdivisions in de hoeken, hoe meer subdivs, hoe afgeronder de hoeken van bovenuit bekeken
  border_subdivs: aantal subdivisions aan de afgeronde randjes, hoe meer subdivs, hoe afgeronder de hoeken vanaf de zijkant bekeken
  concentric_subdivs: aantal subdivisions in het vlak vanuit de middelste grid tot de border. Enkel wanneer corner radius > border radius
  subdivs: fallback waarde als geen van bovenstaande is meegegeven

  x_edge_size: edge size over de x-as -> x_subdivs wordt herberekend als deze waarde is meegegeven
  y_edge_size: edge size over de y-as -> y_subdivs wordt herberekend als deze waarde is meegegeven
  z_edge_size: edge size over de z-as -> z_subdivs wordt herberekend als deze waarde is meegegeven
  corner_edge_size: edge size in de hoeken -> corner_subdivs wordt herberekend als deze waarde is meegegeven
  border_edge_size: edge size aan de randjes -> border_subdivs wordt herberekend als deze waarde is meegegeven
  concentric_edge_size: edge size aan de randen in het platte boven of ondervlak -> concentric_subdivs wordt herberekend als deze waarde is meegegeven
  edge_size: fallback waarde als geen van bovenstaande is meegegeven

  min_x_subdivs: minimum aantal onderverdelingen over de x-as
  max_x_subdivs: maximum aantal onderverdelingen over de x-as
  min_y_subdivs: minimum aantal onderverdelingen over de y-as
  max_y_subdivs: maximum aantal onderverdelingen over de y-as
  min_z_subdivs: minimum aantal onderverdelingen over de z-as
  max_z_subdivs: maximum aantal onderverdelingen over de z-as
  min_corner_subdivs: minimum aantal corner subdivs
  max_corner_subdivs: maximum aantal corner subdivs
  min_border_subdivs: minimum aantal border subdivs
  max_border_subdivs: maximum aantal border subdivs
  min_subdivs: fallback waarde als geen van bovenstaane minimum waarden is meegegeven
  max_subdivs: fallback waarde als geen van bovenstaane maximum waarden is meegegeven

  */

  /**
   * @function addStraightLine
   * @description Internal function - Adds a straight line of vertices to
   *  a vertices array, based on an array of source vertices, a distance and
   *  the edge type
   * @param {int} edgeType - ID of the edge
   *  LEFT = 0
   *  TOP = 1
   *  RIGHT = 2
   *  BOTTOM = 3
   * @param {Array} vertices - Vertices of the source edge
   * @param {Number} distanceFromEdge - Distance from the source edge to the new vertices
   * @param {Number} uvDistanceFromEdge - Distance from the source edge
   *  uv coordinates to the uv coordinates of the new vertex
   * @param {Number} uvYScale - uv y-scale (scales V coordinate)
   * @param {Number} ypos - Y-position of the new vertices
   * @param {Boolean} includeFirst - If true, includes the first vertex of the line
   * @param {Boolean} includeLast - If true, includes the last vertex of the line
   * @param {Vector3} originalNormal - Normal of the left edge, will be rotated based on the edgeType param
   * (See the Vector3 class from BD3DGeom.js)
   * @param {Boolean} isTop - Whether it's used for the top or bottom mesh
   * @param {Array} loop - Optional preallocated array
   * @param {Object} session - Object containing useful data during the creation of the top and bottom meshes
   * @return {Array} loop of vertices
   * */
  static _addStraightLine(edgeType, vertices, distanceFromEdge, uvDistanceFromEdge, uvYScale, ypos, includeFirst, includeLast, originalNormal, isTop, loop, session) {
    const num = vertices.length;
    let normal = originalNormal;
    const startIndex = includeFirst ? 0 : 1;
    let endIndex = num;

    if (!includeLast) {
      --endIndex;
    }

    const nx = normal.getCoord(0);
    const ny = normal.getCoord(1);
    const nz = normal.getCoord(2);

    const isVerticalNormal = (nx === 0 && nz === 0); // if true, normal does not change when rotating about the y axis

    // direction
    let dirX = 0;
    let dirZ = 0;
    let normalAngle = 0;

    const negOne = -1;

    if (edgeType === LEFT) {
      dirX = negOne;
      normalAngle = 0;
    } else if (edgeType === TOP) {
      dirZ = negOne;
      normalAngle = Math.PI * 0.5;
    } else if (edgeType === RIGHT) {
      dirX = 1;
      normalAngle = Math.PI;
    } else if (edgeType === BOTTOM) {
      dirZ = 1;
      normalAngle = -Math.PI * 0.5;
    }

    let uvs = null;
    let normals = null;
    let verts = null;
    let polyverts = null;

    if (session && session.storeVertexData !== false) {
      uvs = session.uvs;
      normals = session.normals;
      verts = session.vertices;
      polyverts = session.polygonVertices;
    }

    if (!isVerticalNormal) {
      normal = new Vector3(nx, ny, nz);
      this._vRotateY(normal, normalAngle);

      if (normals) {
        normals.push(normal);
      }
    }

    for (let i = startIndex; i < endIndex; ++i) {
      const srcVert = vertices[i];
      const srcX = srcVert.getCoord(0);
      const srcZ = srcVert.getCoord(2);

      const polyVert = this._newPolyVertex(null, null, session);

      const vert = new Vertex();
      const pos = new Vector3();

      const X = srcX + dirX * distanceFromEdge;
      const Y = ypos;
      const Z = srcZ + dirZ * distanceFromEdge;

      pos.setCoord(0, X);
      pos.setCoord(1, Y);
      pos.setCoord(2, Z);

      let U = srcX + dirX * uvDistanceFromEdge;
      const V = uvYScale * (srcZ + dirZ * uvDistanceFromEdge);

      if (!isTop) {
        U *= negOne;
      }
      const uv = new Vector2(U, V);

      vert.position = pos;
      polyVert.position = vert;

      if (!polyVert.userData) {
        polyVert.userData = {};
      }
      polyVert.userData.sideID = edgeType;
      polyVert.userData.sideVertexIndex = i;

      if (!polyVert.attributes) {
        polyVert.attributes = {};
      }
      if (!vert.attributes) {
        vert.attributes = {};
      }
      polyVert.attributes.uv = uv;
      polyVert.attributes.normal = normal;
      vert.attributes.normal = normal;

      if (uvs) {
        uvs.push(uv);
      }
      if (polyverts) {
        polyverts.push(polyVert);
      }
      if (verts) {
        verts.push(vert);
      }

      loop.push(polyVert);
    }

    return loop;
  }

  static _addCornerArc(corner, xpos, ypos, zpos, distance, uvDistance, uvYScale, subdivs, includeFirst, includeLast, normal, isTop, loop, session) {
    const PI05 = Math.PI * 0.5;
    let angleOffset = 0;
    let normalAngleOffset = 0;

    let side1, side2; // assign side id's to corner vertices

    if (corner === TOP_LEFT) {
      side1 = LEFT;
      side2 = TOP;
      angleOffset = Math.PI;
      normalAngleOffset = 0;
    } else if (corner === TOP_RIGHT) {
      side1 = TOP;
      side2 = RIGHT;
      angleOffset = -Math.PI * 0.5;
      normalAngleOffset = Math.PI * 0.5;
    } else if (corner === BOTTOM_LEFT) {
      side1 = BOTTOM;
      side2 = LEFT;
      angleOffset = Math.PI * 0.5;
      normalAngleOffset = -Math.PI * 0.5;
    } else if (corner === BOTTOM_RIGHT) {
      side1 = RIGHT;
      side2 = BOTTOM;
      angleOffset = 0;
      normalAngleOffset = Math.PI;
    }
    const startIndex = includeFirst ? 0 : 1;
    let endIndex = subdivs;

    if (!includeLast) {
      --endIndex;
    }

    const nx = normal.getCoord(0);
    const ny = normal.getCoord(1);
    const nz = normal.getCoord(2);
    const isVerticalNormal = nx === 0 && nz === 0;

    let nrm = normal;

    let uvs = null;
    let normals = null;
    let polyverts = null;
    let verts = null;

    if (session) {
      uvs = session.uvs;
      verts = session.vertices;
      polyverts = session.polygonVertices;
      normals = session.normals;
    }

    const negOne = -1;

    for (let i = startIndex; i <= endIndex; ++i) {
      let pct = i / subdivs;

      pct = 1 - pct;
      const a = pct * PI05;
      const ang = a + angleOffset;
      const normalAng = a + normalAngleOffset;
      const sine = Math.sin(ang);
      const cosine = Math.cos(ang);

      if (!isVerticalNormal) {
        nrm = new Vector3(nx, ny, nz);
        this._vRotateY(nrm, normalAng);
      }

      const x = xpos + cosine * distance;
      const y = ypos;
      const z = zpos + sine * distance;

      let u = xpos + cosine * uvDistance;
      const v = uvYScale * (zpos + sine * uvDistance);

      if (!isTop) {
        u *= negOne;
      }
      const uv = new Vector2(u, v);

      const pos = new Vector3(x, y, z);
      const vertex = new Vertex(pos);

      // const polyvert = new Vertex(vertex);
      const polyvert = this._newPolyVertex(vertex, null, session);

      if (!polyvert.userData) {
        polyvert.userData = {};
      }
      polyvert.userData.cornerID = corner;
      polyvert.userData.sideID = pct < 0.5 ? side1 : side2;

      if (!vertex.attributes) {
        vertex.attributes = {};
      }
      if (!polyvert.attributes) {
        polyvert.attributes = {};
      }
      polyvert.attributes.uv = uv;
      polyvert.attributes.normal = nrm;
      vertex.attributes.normal = nrm;


      if (uvs) {
        uvs.push(uv);
      }
      if (normals && !isVerticalNormal) {
        normals.push(nrm);
      }
      if (verts) {
        verts.push(vertex);
      }
      if (polyverts) {
        polyverts.push(polyvert);
      }

      loop.push(polyvert);
    }

    return loop;
  }

  /**
   * @function createTopRoundRectLoop
   * @description Creates a loop of vertices forming a round rectangle,
   * using the vertices of the edges of a given rectangle.
   * Used for constructing the top and bottom mesh of the mattress
   *
   *                  top vertices
   *                    .......
   *                    .     .
   *  left vertices     .     .   right vertices
   *                    .     .
   *                    .......
   *                bottom vertices
   *
   * Result:
   *              _________
   *            /           \
   *            |  ........  |
   *            |  .      .  |
   *            |  .      .  |
   *            |  .      .  |
   *            |  ........  |
   *            \___________/

   * @param {Array} leftVertices
   *  Array of vertices of the left side of the rectangle
   * @param {Array} bottomVertices
   *  Array of vertices of the bottom side of the rectangle
   * @param {Array} rightVertices
   *  Array of vertices of the right side of the rectangle
   * @param {Array} topVertices
   *  Array of vertices of the top side of the rectangle
   * @param {Number} distanceFromEdge
   *  The distance from the source rectangle edge to the new rectangle loop
   * @param {Number} uvDistanceFromEdge
   *  The distance from the source rectangle texture coords to
   *  the new rectangle texture coords
   * @param {Number} uvYScale - y scale for the texture V coordinates
   * @param {Number} ypos
   *  The y-position of the rectangle's vertices
   * @param {Vector3} normal
   *  The normal direction of the left edge. This vector will be rotated
   *  following the loop around the top-left corner to the top edge and so on.
   *
   * @param {Boolean} isTop
   *  Set to true if this loop is created for the top mesh
   * @param {int} cornerSubdivs - number of subdivisions in the corner
   * @param {Object} session
   *  Object used to store all sorts of stuff that's important
   *  during the creation of the top or bottom mesh of the mattress.
   * @returns {Array} loop
   * */
  static _createTopRoundRectLoop(leftVertices, bottomVertices, rightVertices, topVertices, distanceFromEdge, uvDistanceFromEdge, uvYScale, ypos, normal, isTop, cornerSubdivs, session) {
    const loop = [];

    const TLVert = leftVertices[0];
    const TRVert = rightVertices[rightVertices.length - 1];
    const BRVert = rightVertices[0];
    const BLVert = leftVertices[leftVertices.length - 1];

    this._addStraightLine(LEFT, leftVertices, distanceFromEdge, uvDistanceFromEdge, uvYScale, ypos, true, true, normal, isTop, loop, session);
    this._addCornerArc(BOTTOM_LEFT, BLVert.getCoord(0), ypos, BLVert.getCoord(2), distanceFromEdge, uvDistanceFromEdge, uvYScale, cornerSubdivs, false, false, normal, isTop, loop, session);
    this._addStraightLine(BOTTOM, bottomVertices, distanceFromEdge, uvDistanceFromEdge, uvYScale, ypos, true, true, normal, isTop, loop, session);
    this._addCornerArc(BOTTOM_RIGHT, BRVert.getCoord(0), ypos, BRVert.getCoord(2), distanceFromEdge, uvDistanceFromEdge, uvYScale, cornerSubdivs, false, false, normal, isTop, loop, session);
    this._addStraightLine(RIGHT, rightVertices, distanceFromEdge, uvDistanceFromEdge, uvYScale, ypos, true, true, normal, isTop, loop, session);
    this._addCornerArc(TOP_RIGHT, TRVert.getCoord(0), ypos, TRVert.getCoord(2), distanceFromEdge, uvDistanceFromEdge, uvYScale, cornerSubdivs, false, false, normal, isTop, loop, session);
    this._addStraightLine(TOP, topVertices, distanceFromEdge, uvDistanceFromEdge, uvYScale, ypos, true, true, normal, isTop, loop, session);
    this._addCornerArc(TOP_LEFT, TLVert.getCoord(0), ypos, TLVert.getCoord(2), distanceFromEdge, uvDistanceFromEdge, uvYScale, cornerSubdivs, false, false, normal, isTop, loop, session);

    return loop;
  }
  /**
   * @method _createTopGrid
   * @description Creates the topmost polygons in a grid
   *    __ __ __ __
   *   |__|__|__|__|
   *   |__|__|__|__|
   *   |__|__|__|__|
   *   |__|__|__|__|
   *   |__|__|__|__|
   *   |__|__|__|__|
   *   |__|__|__|__|
   *
   * @static
   * @private
   * @param {Object} buildData - build data
   * @return {void}
   **/
  static _createTopGrid(buildData) {
    let topGridData = buildData.topGridData;

    if (!topGridData) {
      topGridData = buildData.topGridData = {};
    }
    const mattressData = buildData.mattressData;

    const width = mattressData.width;
    const length = mattressData.length;
    const height = buildData.topHeight;
    const cornerRadius = mattressData.cornerRadius;
    const scaleY = mattressData.scaleY;

    const uvYScale = buildData.uvYScale;
    const params = buildData.params;

    let lineSubdivsX = -1;
    let lineSubdivsZ = -1;

    const midSizeX = width - cornerRadius * 2;
    const midSizeZ = length - cornerRadius * 2;

    const hMidSizeX = midSizeX * HALF;
    const hMidSizeZ = midSizeZ * HALF;

    const resultVertices = buildData.vertices;
    const resultPolyVertices = buildData.polygonVertices;
    const resultUvs = buildData.uvs;
    const flatTopVertices = buildData.flatTopVertices;
    const flatTopUVs = buildData.flatTopUVs;
    const flatTopPolygonVertices = buildData.flatTopPolygonVertices;

    let minSubdivsX = DEFAULT_MIN_SUBDIVS;
    let maxSubdivsX = DEFAULT_MAX_SUBDIVS;
    let minSubdivsZ = DEFAULT_MIN_SUBDIVS;
    let maxSubdivsZ = DEFAULT_MAX_SUBDIVS;

    if (params) {
      minSubdivsX = tryValues(params.minXSubdivs, params.minSubdivs, minSubdivsX);
      minSubdivsZ = tryValues(params.minZSubdivs, params.minSubdivs, minSubdivsZ);
      maxSubdivsX = tryValues(params.maxXSubdivs, params.maxSubdivs, maxSubdivsX);
      maxSubdivsZ = tryValues(params.maxZSubdivs, params.maxSubdivs, maxSubdivsZ);

      const edgeSizeX = tryValues(params.xEdgeSize, params.edgeSize);
      const edgeSizeZ = tryValues(params.zEdgeSize, params.edgeSize);

      if (edgeSizeX !== null && typeof (edgeSizeX) !== 'undefined') {
        lineSubdivsX = (midSizeX / edgeSizeX) | 0;
      } else {
        lineSubdivsX = tryValues(params.xSubdivs, params.subdivs);
      }

      if (edgeSizeZ !== null && typeof (edgeSizeZ) !== 'undefined') {
        lineSubdivsZ = (midSizeZ / edgeSizeZ) | 0;
      } else {
        lineSubdivsZ = tryValues(params.zSubdivs, params.subdivs);
      }
    }

    const DEFAULT_SUBDIVS_X = 10;
    const DEFAULT_SUBDIVS_Z = 10;
    const FIXED_MIN_SUBDIVS = 2;

    if (lineSubdivsX === null || typeof (lineSubdivsX) === 'undefined' || lineSubdivsX < 0) {
      lineSubdivsX = DEFAULT_SUBDIVS_X;
    }
    if (lineSubdivsZ === null || typeof (lineSubdivsZ) === 'undefined' || lineSubdivsZ < 0) {
      lineSubdivsZ = DEFAULT_SUBDIVS_Z;
    }

    lineSubdivsX = lineSubdivsX < minSubdivsX ? minSubdivsX : lineSubdivsX;
    lineSubdivsX = lineSubdivsX > maxSubdivsX ? maxSubdivsX : lineSubdivsX;
    lineSubdivsZ = lineSubdivsZ < minSubdivsZ ? minSubdivsZ : lineSubdivsZ;
    lineSubdivsZ = lineSubdivsZ > maxSubdivsZ ? maxSubdivsZ : lineSubdivsZ;

    // In case min values in params are too small
    lineSubdivsX = lineSubdivsX < FIXED_MIN_SUBDIVS ? FIXED_MIN_SUBDIVS : lineSubdivsX;
    lineSubdivsZ = lineSubdivsZ < FIXED_MIN_SUBDIVS ? FIXED_MIN_SUBDIVS : lineSubdivsZ;

    const lastXIndex = lineSubdivsX - 1;
    const lastZIndex = lineSubdivsZ - 1;

    const negOne = -1;
    const nY = scaleY > 0 ? 1 : negOne;
    const flipPolys = scaleY < 0;
    const normal = new Vector3(0, nY, 0);
    const grid = [];

    let topLeftVertex = null;
    let topRightVertex = null;
    let bottomLeftVertex = null;
    let bottomRightVertex = null;

    let leftVertices = topGridData.leftVertices;
    let topVertices = topGridData.topVertices;
    let rightVertices = topGridData.rightVertices;
    let bottomVertices = topGridData.bottomVertices;

    if (!leftVertices) {
      leftVertices = topGridData.leftVertices = [];
    }

    if (!topVertices) {
      topVertices = topGridData.topVertices = [];
    }

    if (!rightVertices) {
      rightVertices = topGridData.rightVertices = [];
    }

    if (!bottomVertices) {
      bottomVertices = topGridData.bottomVertices = [];
    }

    for (let y = 0; y < lineSubdivsZ; ++y) {
      const isTop = y === 0;
      const isBottom = y === lastZIndex;
      let row = grid[y];

      if (!row) {
        row = grid[y] = [];
      }

      for (let x = 0; x < lineSubdivsX; ++x) {
        const isLeft = x === 0;
        const isRight = x === lastXIndex;

        const vertX = -hMidSizeX + midSizeX * (x / lastXIndex);
        const vertY = height * scaleY;
        const vertZ = -hMidSizeZ + midSizeZ * (y / lastZIndex);

        const vertPos = new Vector3(vertX, vertY, vertZ);
        const vert = new Vertex(vertPos);

        resultVertices.push(vert);

        const polyVert = MattressGeomUtils._newPolyVertex(vert, null, buildData);

        resultPolyVertices.push(polyVert);

        let U = vertX;
        const V = vertZ * uvYScale;

        if (scaleY < 0) {
          U *= negOne;
        }
        const uv = new Vector2(U, V);

        resultUvs.push(uv);

        GeomUtils.assignVertexAttribute(vert, 'normal', normal);
        GeomUtils.assignVertexAttribute(polyVert, 'normal', normal);
        GeomUtils.assignVertexAttribute(polyVert, 'uv', uv);

        if (!polyVert.userData) {
          polyVert.userData = {};
        }
        polyVert.userData.locationName = 'centerPatch';

        if (flatTopVertices) {
          flatTopVertices.push(vert);
        }
        if (flatTopUVs) {
          flatTopVertices.push(uv);
        }
        if (flatTopPolygonVertices) {
          flatTopPolygonVertices.push(polyVert);
        }

        const isTopLeft = isTop && isLeft;
        const isTopRight = isTop && isRight;
        const isBottomLeft = isBottom && isLeft;
        const isBottomRight = isBottom && isRight;

        if (isLeft) {
          leftVertices.push(polyVert);
          polyVert.userData.sideID = LEFT;
        }
        if (isTop) {
          topVertices.push(polyVert);
          polyVert.userData.sideID = TOP;
        }
        if (isRight) {
          rightVertices.push(polyVert);
          polyVert.userData.sideID = RIGHT;
        }
        if (isBottom) {
          bottomVertices.push(polyVert);
          polyVert.userData.sideID = BOTTOM;
        }

        if (isTopLeft) {
          topLeftVertex = polyVert;
        }
        if (isTopRight) {
          topRightVertex = polyVert;
        }
        if (isBottomLeft) {
          bottomLeftVertex = polyVert;
        }
        if (isBottomRight) {
          bottomRightVertex = polyVert;
        }

        polyVert.userData.isTop = isTop;
        polyVert.userData.isBottom = isBottom;
        polyVert.userData.isRight = isRight;
        polyVert.userData.isLeft = isLeft;

        polyVert.userData.isTopLeft = isTopLeft;
        polyVert.userData.isTopRight = isTopRight;
        polyVert.userData.isBottomLeft = isBottomLeft;
        polyVert.userData.isBottomRight = isBottomRight;
        polyVert.userData.xIndex = x;
        polyVert.userData.yIndex = y;

        row[x] = polyVert;

        // const index = x + y * lineSubdivsX;
        // grid[index] = polyVert;
      }
    }

    rightVertices.reverse();
    topVertices.reverse();

    topGridData.topLeftVertex = topLeftVertex;
    topGridData.topRightVertex = topRightVertex;
    topGridData.bottomLeftVertex = bottomLeftVertex;
    topGridData.bottomRightVertex = bottomRightVertex;

    MattressGeomUtils._createGridQuads(grid, buildData.polygons, flipPolys);
  }

  static _createConcentricGeometry(buildData) {
    // #if DEBUG
    timeStart('border vertices');
    // #endif
    const maskHeight = buildData.topHeight;
    const mattressData = buildData.mattressData;
    const borderRadius = mattressData.borderRadius;
    const cornerRadius = mattressData.cornerRadius;
    const scaleY = mattressData.scaleY;
    const params = buildData.params;

    let concentricSubdivs = 10;
    let cornerSubdivs = 10;
    let borderSubdivs = 10;

    let minConcentricSubdivs = DEFAULT_MIN_SUBDIVS;
    let maxConcentricSubdivs = DEFAULT_MAX_SUBDIVS;
    let minCornerSubdivs = DEFAULT_MIN_SUBDIVS;
    let maxCornerSubdivs = DEFAULT_MAX_SUBDIVS;
    let minBorderSubdivs = DEFAULT_MIN_SUBDIVS;
    let maxBorderSubdivs = DEFAULT_MAX_SUBDIVS;

    if (params) {
      let concentricEdgeSize = 0;
      let cornerEdgeSize = 0;
      let borderEdgeSize = 0;

      concentricEdgeSize = tryValues(params.concentricEdgeSize, params.edgeSize, concentricEdgeSize);
      cornerEdgeSize = tryValues(params.cornerEdgeSize, params.edgeSize, cornerEdgeSize);
      borderEdgeSize = tryValues(params.borderEdgeSize, params.edgeSize, borderEdgeSize);

      if (concentricEdgeSize > 0) {
        const concentricLength = cornerRadius - borderRadius;

        concentricSubdivs = (concentricLength / concentricEdgeSize) | 0;
      } else {
        concentricSubdivs = tryValues(params.concentricSubdivs, concentricSubdivs);
      }

      if (cornerEdgeSize > 0) {
        const cornerLength = (cornerRadius * HALF_PI);

        cornerSubdivs = (cornerLength / cornerEdgeSize) | 0;
      } else {
        cornerSubdivs = tryValues(params.cornerSubdivs, cornerSubdivs);
      }

      if (borderEdgeSize > 0) {
        const borderLength = (borderRadius * HALF_PI);

        borderSubdivs = (borderLength / borderEdgeSize) | 0;
      } else {
        borderSubdivs = tryValues(params.borderSubdivs, borderSubdivs);
      }

      minConcentricSubdivs = tryValues(params.minConcentricSubdivs, params.minSubdivs, minConcentricSubdivs);
      minCornerSubdivs = tryValues(params.minCornerSubdivs, params.minSubdivs, minCornerSubdivs);
      minBorderSubdivs = tryValues(params.minBorderSubdivs, params.minSubdivs, minBorderSubdivs);

      maxConcentricSubdivs = tryValues(params.maxConcentricSubdivs, params.maxSubdivs, maxConcentricSubdivs);
      maxCornerSubdivs = tryValues(params.maxCornerSubdivs, params.maxSubdivs, maxCornerSubdivs);
      maxBorderSubdivs = tryValues(params.maxBorderSubdivs, params.maxSubdivs, maxBorderSubdivs);
    }

    concentricSubdivs = concentricSubdivs < minConcentricSubdivs ? minConcentricSubdivs : concentricSubdivs;
    concentricSubdivs = concentricSubdivs > maxConcentricSubdivs ? maxConcentricSubdivs : concentricSubdivs;

    cornerSubdivs = cornerSubdivs < minCornerSubdivs ? minCornerSubdivs : cornerSubdivs;
    cornerSubdivs = cornerSubdivs > maxCornerSubdivs ? maxCornerSubdivs : cornerSubdivs;

    borderSubdivs = borderSubdivs < minBorderSubdivs ? minBorderSubdivs : borderSubdivs;
    borderSubdivs = borderSubdivs > maxBorderSubdivs ? maxBorderSubdivs : borderSubdivs;

    let edgeLoopIndex = 0;

    let borderGrid = [];

    buildData.borderGrid = borderGrid;

    // const angleFactor = Math.sin((maskHeight / borderRadius) * Math.PI * 0.5);
    const maskHeightClampBorderRadius = maskHeight > borderRadius ? borderRadius : maskHeight;
    const cosineAngleFactor = (borderRadius - maskHeightClampBorderRadius) / borderRadius;
    const angleFactor = Math.acos(cosineAngleFactor) / (Math.PI * 0.5);

    let firstRow; // first edge loop

    const isMattressTop = scaleY >= 0;
    let loop = null;

    // Building up from the innermost edge loop to the outermost edge loop
    const verticalNormalY = scaleY < 0 ? -1 : 1;
    const verticalNormal = new Vector3(0, verticalNormalY, 0);

    let topBorderUVDistance = 0;

    const leftVertices = buildData.topGridData.leftVertices;
    const topVertices = buildData.topGridData.topVertices;
    const rightVertices = buildData.topGridData.rightVertices;
    const bottomVertices = buildData.topGridData.bottomVertices;

    if (cornerRadius > 0) {
      let numCols = 0;

      const uvYScale = buildData.uvYScale;
      const height = buildData.topHeight;

      edgeLoopIndex = 0;
      const flatCornerWidth = (cornerRadius - borderRadius);
      let roundEdgeLoopDist = 0;

      let dist, pct;

      // flat part where corner radius has influence but the border radius does not
      if (concentricSubdivs < 1) {
        concentricSubdivs = 1;
      }
      // #if DEBUG
      timeStart(' flat polys');
      // #endif
      if (flatCornerWidth > 0) {
        for (let i = 1; i <= concentricSubdivs; ++i) {
          pct = i / concentricSubdivs;
          dist = pct * flatCornerWidth;
          topBorderUVDistance = dist;
          loop = this._createTopRoundRectLoop(leftVertices, bottomVertices, rightVertices, topVertices,
            dist, dist, uvYScale, height * scaleY, verticalNormal, isMattressTop, cornerSubdivs, buildData
          );

          borderGrid[edgeLoopIndex++] = loop;
          roundEdgeLoopDist = dist;
        }
      }
      // #if DEBUG
      timeEnd(' flat polys');
      // #endif

      // moving down the border
      // #if DEBUG
      timeStart(' round polys');
      // #endif
      if (borderRadius > 0) {
        for (let i = 1; i <= borderSubdivs; ++i) {
          pct = i / borderSubdivs;
          // dist = roundEdgeLoopDist + pct * borderRadius;
          let angle = pct * Math.PI * 0.5;

          angle *= angleFactor;
          const roundDist = roundEdgeLoopDist + Math.sin(angle) * borderRadius;
          const roundYOffset = (1.0 - Math.cos(angle)) * borderRadius;
          const yoffset = roundYOffset;

          dist = roundDist;
          const uvDist = roundEdgeLoopDist + angle * borderRadius;
          let nrm = verticalNormal;

          if (angle !== 0) {
            nrm = new Vector3(nrm.getCoord(0), nrm.getCoord(1), nrm.getCoord(2));

            vRotateZ(nrm, angle);

            if (!isMattressTop) {
              nrm.setCoord(0, -nrm.getCoord(0));
              nrm.setCoord(2, -nrm.getCoord(2));
            }
          }
          loop = this._createTopRoundRectLoop(leftVertices, bottomVertices, rightVertices, topVertices,
            dist, uvDist, uvYScale, (height - yoffset) * scaleY, nrm, isMattressTop, cornerSubdivs, buildData
          );

          borderGrid[edgeLoopIndex++] = loop;

          topBorderUVDistance = uvDist;
        }
      }

      buildData.topBorderUVDistance = topBorderUVDistance;


      // vertex loop around the mattress
      const borderLoop = this._createTopRoundRectLoop(leftVertices, bottomVertices, rightVertices, topVertices,
        cornerRadius, 0, uvYScale, 0,
        new Vector3(-1, 0, 0), // normal
        isMattressTop, cornerSubdivs);

      let blVert, blUV;

      if (borderLoop) {
        const num = borderLoop.length;

        for (let i = 0; i < num; ++i) {
          blVert = borderLoop[i];
          if (blVert) {
            blVert.setCoord(1, 0);
          }
          if (blVert.attributes) {
            blUV = blVert.attributes.uv;
            if (blUV) {
              blUV.setCoord(0, 0);
              blUV.setCoord(1, 0);
            }
          }
        }
      }

      buildData.borderLoop = borderLoop;

      const flipPolys = scaleY < 0;

      let vertTL = null;
      let vertTR = null;
      let vertBL = null;
      let vertBR = null;

      // #if DEBUG
      timeEnd(' round polys');
      // #endif
      numCols = loop ? loop.length : 0;

      //
      // create faces from vertices in border grid
      // #if DEBUG
      timeStart(' border - create polys');
      // #endif

      const numRows = edgeLoopIndex;
      // const numCols = columnCounter.index;

      for (let y = 0; y < numRows - 1; ++y) {
        let y1 = y + 1;

        y1 %= numRows;

        const hSeams = buildData.hSeams;

        const polys = buildData.polygons;

        if (!isSeamEdgeIndex(y, hSeams)) {
          const row = borderGrid[y];

          numCols = row.length;
          for (let x = 0; x < numCols; ++x) {
            let x1 = x + 1;

            x1 %= numCols;
            vertTL = borderGrid[y][x];
            vertTR = borderGrid[y][x1];
            vertBL = borderGrid[y1][x];
            vertBR = borderGrid[y1][x1];
            const poly = this._newQuad(vertTR, vertTL, vertBL, vertBR, flipPolys);
            // const poly = this._newQuad(vertBR, vertBL, vertTL, vertTR, flipPolys);

            polys.push(poly);
          }
        }
      }
      firstRow = borderGrid[0];
    } else {
      firstRow = leftVertices.concat(topVertices, rightVertices, bottomVertices);
      // remove doubles at the corners
      const num = firstRow.length;
      let prevVert = null;

      for (let i = num - 1; i >= 0; --i) {
        const vert = firstRow[i];

        prevVert = firstRow[(i + 1) % num];
        if (vert === prevVert || vert === null || vert === undefined) {
          firstRow.splice(i, 1);
        }
      }
      borderGrid = [firstRow];
      buildData.borderGrid = borderGrid;
    }
    // #if DEBUG
    timeEnd(' border - create polys');
    // #endif

    this._connectTopPlaneWithBorder(firstRow, buildData);
  }

  static _connectTopPlaneWithBorder(firstRow, buildData) {
    if (!firstRow) {
      return;
    }
    const mattressData = buildData.mattressData;
    const scaleY = mattressData.scaleY;
    const flipPolys = scaleY < 0;
    const num = firstRow.length;
    const topGridData = buildData.topGridData;

    const topLeftVertex = topGridData.topLeftVertex;
    const topRightVertex = topGridData.topRightVertex;
    const bottomLeftVertex = topGridData.bottomLeftVertex;
    const bottomRightVertex = topGridData.bottomRightVertex;

    const leftVertices = topGridData.leftVertices;
    const topVertices = topGridData.topVertices;
    const rightVertices = topGridData.rightVertices;
    const bottomVertices = topGridData.bottomVertices;

    const polys = buildData.polygons;

    for (let i = 0; i < num; ++i) {
      let i2 = i + 1;

      i2 %= num;

      const vert = firstRow[i];
      const vert2 = firstRow[i2];
      const tVert = this._getBorderGridCenterTargetVertex(vert, topLeftVertex, topRightVertex, bottomLeftVertex, bottomRightVertex, leftVertices, topVertices, rightVertices, bottomVertices);
      const tVert2 = this._getBorderGridCenterTargetVertex(vert2, topLeftVertex, topRightVertex, bottomLeftVertex, bottomRightVertex, leftVertices, topVertices, rightVertices, bottomVertices);

      let poly = null;
      let polyVerts;

      if (tVert && tVert2) {
        if (tVert === tVert2) {
          // probably a corner triangle
          // polyVerts = [tVert, vert2, vert];
          polyVerts = [vert, vert2, tVert];
        } else {
          // side quad
          polyVerts = [vert, vert2, tVert2, tVert];
          // polyVerts = [tVert, tVert2, vert2, vert];
        }
        if (polyVerts) {
          if (flipPolys) {
            polyVerts.reverse();
          }
          poly = new Polygon(polyVerts);
          if (poly) {
            polys.push(poly);
          }
        }
      }
    }
  }

  static _getBorderGridCenterTargetVertex(vertex, topLeft, topRight, bottomLeft, bottomRight, leftVerts, topVerts, rightVerts, bottomVerts) {
    if (!vertex) {
      return null;
    }
    const ud = vertex.userData;

    if (!ud) {
      return null;
    }
    const cID = ud.cornerID;
    const sID = ud.sideID;

    if (cID !== undefined && cID !== null) {
      if (cID === TOP_LEFT) {
        return topLeft;
      }
      if (cID === TOP_RIGHT) {
        return topRight;
      }
      if (cID === BOTTOM_LEFT) {
        return bottomLeft;
      }
      if (cID === BOTTOM_RIGHT) {
        return bottomRight;
      }
    } else if (sID !== undefined && sID !== undefined) {
      let arr;

      if (sID === LEFT) {
        arr = leftVerts;
      } else if (sID === TOP) {
        arr = topVerts;
      } else if (sID === RIGHT) {
        arr = rightVerts;
      } else if (sID === BOTTOM) {
        arr = bottomVerts;
      }
      if (arr === undefined || arr === null) {
        return null;
      }
      const idx = ud.sideVertexIndex;

      if (idx === undefined || idx === null) {
        return null;
      }

      return arr[idx];
    }

    return null;
  }
  static _calcPolyLineVertexNormal(vert, prevVert, nextVert, xcoord = 0, ycoord = 1, result = null) {
    let res = result;
    const v = vert;
    const prevV = prevVert;
    const nextV = nextVert;

    let prevDx = v.getCoord(xcoord) - prevV.getCoord(xcoord);
    let prevDy = v.getCoord(ycoord) - prevV.getCoord(ycoord);

    let nextDx = nextV.getCoord(xcoord) - v.getCoord(xcoord);
    let nextDy = nextV.getCoord(ycoord) - v.getCoord(ycoord);

    let n = prevDx * prevDx + prevDy * prevDy;

    if (n !== 0 && n !== 1) {
      n = 1.0 / Math.sqrt(n);
      prevDx *= n;
      prevDy *= n;
    }

    n = nextDx * nextDx + nextDy * nextDy;
    if (n !== 0 && n !== 1) {
      n = 1.0 / Math.sqrt(n);
      nextDx *= n;
      nextDy *= n;
    }
    // normal of edge 1
    const n1x = -prevDy;
    const n1y = prevDx;

    // normal of edge 2
    const n2x = -nextDy;
    const n2y = nextDx;

    // average normal of edges
    let nx = (n1x + n2x) * 0.5;
    let ny = (n1y + n2y) * 0.5;

    if (!res) {
      res = new Vector3(0, 0, 0);
    }
    res.setCoord(xcoord, nx);
    res.setCoord(ycoord, ny);

    nx = Math.random() * 2 - 1;
    ny = Math.random() * 2 - 1;

    VectorMath.normalize(res);

    return res;
  }

  static _calcEdgeLoopNormals(verts, result = null) {
    let res = result;

    if (!verts) {
      return res;
    }
    const num = verts.length;

    if (!res) {
      res = [];
    }
    res.length = num;

    for (let i = 0; i < num; ++i) {
      let prevI = (i - 1) % num;
      const nextI = (i + 1) % num;

      if (prevI < 0) {
        prevI += num;
      }

      const v = verts[i];
      const prevV = verts[prevI];
      const nextV = verts[nextI];

      res[i] = this._calcPolyLineVertexNormal(v, prevV, nextV, 0, 2, res[i]);
    }

    return res;
  }

  static _createSides(buildData) {
    const mattressData = buildData.mattressData;
    const topHeight = buildData.topHeight;
    const borderRadius = mattressData.borderRadius;
    const cornerRadius = mattressData.cornerRadius;
    const mattressWidth = mattressData.width;
    const mattressLength = mattressData.length;

    const maskHeightMinusBorderRadius = topHeight - borderRadius;
    const params = buildData.params;
    const scaleY = mattressData.scaleY;
    const borderLoop = buildData.borderLoop;

    const resultVertices = buildData.vertices;
    const resultPolyVertices = buildData.polygonVertices;
    const resultUvs = buildData.uvs;

    const borderCurveData = buildData.borderCurveData;
    let borderCurve = borderCurveData.shape;
    const borderCurveMinY = borderCurveData.minY;
    const borderCurveMaxY = borderCurveData.maxY;
    const borderCurveNegativeHeight = borderCurveData.negativeHeight;
    const borderCurvePositiveHeight = borderCurveData.positiveHeight;

    let vSeams = buildData.vSeams;

    const uvYScale = buildData.uvYScale;
    const flipPolys = scaleY < 0;

    // TODO: borderGrid
    const borderGrid = buildData.borderGrid;

    // TODO: borderCurveMinY

    if (borderCurve) {
      if (Graph) {
        if (!(borderCurve instanceof Graph)) {
          if (GraphUtils) {
            borderCurve = GraphUtils.graphFromJSONObject(borderCurve);
          } else {
            borderCurve = null;
          }
        }
      } else {
        borderCurve = null;
      }
    }
    if (maskHeightMinusBorderRadius > 0 || GraphUtils.hasGraph(borderCurve)) {
      const polys = buildData.polygons;

      // #if DEBUG
      timeStart(' create side polys');
      // #endif

      const sideGrid = [];
      const sideGridVertexPositions = [];

      let goingDownRowStartIndex = -1;// edge loop index where the geometry starts building from the corner downwards

      goingDownRowStartIndex = borderGrid.length - 1;

      let hSeams = buildData.hSeams;

      if (!hSeams) {
        hSeams = buildData.hSeams = [];
      }

      hSeams.push(goingDownRowStartIndex);
      let outerEdgeLoop = borderGrid[goingDownRowStartIndex];
      const outerEdgeLoopNormals = this._calcEdgeLoopNormals(outerEdgeLoop);

      let numverts = outerEdgeLoop ? outerEdgeLoop.length : 0;

      let outerVert;
      let curSideId;

      const outerEdgeLoopVertexPositions = []; // array holds the vertex positions only (no duplicates with different uvs etc)

      // create a copy of the outer edge loop with duplicates at the corners for uv seams
      let prevVert;
      const outerEdgeLoopCopy = [];
      let I = 0;

      curSideId = -1;
      for (let i = 0; i < numverts; ++i) {
        outerVert = outerEdgeLoop[i];
        let ud = outerVert.userData;

        if (!ud) {
          ud = outerVert.userData = {};
        }

        outerEdgeLoopVertexPositions[i] = outerVert.position;
        let posUD = outerVert.position.userData;

        if (!posUD) {
          posUD = outerVert.position.userData = {};
        }
        posUD.sideID = ud.sideID;

        if (ud) {
          ud.vertexPosIndex = i;
          if (ud.sideID !== curSideId) {
            if (curSideId > -1) {
              const newVert = new Vertex(prevVert.position);

              if (prevVert.attributes) {
                if (!newVert.attributes) {
                  newVert.attributes = {};
                }
                newVert.attributes.uv = prevVert.attributes.uv;
              }
              newVert.userData = {sideID: ud.sideID};
              newVert.userData.seam = true;
              newVert.userData.vertexPosIndex = prevVert.userData.vertexPosIndex;

              if (newVert.userData.vertexPosIndex === undefined || newVert.userData.vertexPosIndex === null) {
                newVert.userData.vertexPosIndex = i - 1;
              }
              if (!vSeams) {
                vSeams = buildData.vSeams = [];
              }
              vSeams.push(I);
              outerEdgeLoopCopy[I++] = newVert;
            }
            curSideId = ud.sideID;
          }
        }
        outerEdgeLoopCopy[I++] = outerVert;

        prevVert = outerVert;
      }
      outerEdgeLoop = outerEdgeLoopCopy;
      numverts = outerEdgeLoop.length;

      // Assign indices to vertices per side -> can be important for UV coordinate calculation & custom shape deformation
      curSideId = -1;
      let sideIndexCounter = 0;

      // count a bit further to correct the indices of the first edge
      let countTo = numverts << 1;

      for (let i = 0; i < countTo; ++i) {
        I = i % numverts;

        outerVert = outerEdgeLoop[I];
        const ud = outerVert.userData;

        if (ud) {
          if (ud.sideID !== curSideId) {
            curSideId = ud.sideID;
            sideIndexCounter = 0;
          }
          ud.sideIndex = sideIndexCounter;
          // outerVert.setCoord(1, outerVert.getCoord(1) + ud.sideIndex * 0.1);
        }
        ++sideIndexCounter;
      }

      const numVertPositions = outerEdgeLoopVertexPositions.length;
      const numVertPosPerSide = [];

      // Doing the same for vertex positions only
      curSideId = -1;
      sideIndexCounter = 0;
      // count a bit further to correct the indices of the first edge
      countTo = numVertPositions << 1;
      for (let i = 0; i < countTo; ++i) {
        I = i % numVertPositions;
        outerVert = outerEdgeLoopVertexPositions[I];
        const ud = outerVert.userData;

        if (ud) {
          if (ud.sideID !== curSideId) {
            if (curSideId >= 0) {
              numVertPosPerSide[curSideId] = sideIndexCounter;
            }
            curSideId = ud.sideID;
            sideIndexCounter = 0;
          }
          ud.sideIndex = sideIndexCounter;
        }
        // note: sideIndex increases counter-clockwise viewing from above (topleft to bottomleft, bottomleft to bottomright, ...)
        // note: sideID increases clockwise viewing from above (left -> top -> right -> bottom)

        // outerVert.setCoord(1, outerVert.getCoord(1) + ud.sideIndex * 0.5);
        // outerVert.setCoord(1, outerVert.getCoord(1) + ud.sideID * 3);

        ++sideIndexCounter;
      }
      const _3 = 3;
      const _4 = 4;
      let numLoops = (maskHeightMinusBorderRadius / _3) | 0;
      let minSideLoops = DEFAULT_MIN_SUBDIVS;
      let maxSideLoops = DEFAULT_MAX_SUBDIVS;

      if (params) {
        const DEFAULT_SUBDIVS_Y = 4;

        const yEdgeSize = tryValues(params.yEdgeSize, params.edgeSize, 0);

        if (yEdgeSize === 0) {
          numLoops = tryValues(params.ySubdivs, params.subdivs, DEFAULT_SUBDIVS_Y);
        } else {
          numLoops = (maskHeightMinusBorderRadius / yEdgeSize) | 0;
        }

        minSideLoops = tryValues(params.minYSubdivs, params.minSubdivs, DEFAULT_MIN_SUBDIVS);
        maxSideLoops = tryValues(params.maxYSubdivs, params.maxSubdivs, DEFAULT_MAX_SUBDIVS);

      }
      numLoops = numLoops | 0;

      if (numLoops < minSideLoops) {
        numLoops = minSideLoops;
      }
      if (numLoops > maxSideLoops) {
        numLoops = maxSideLoops;
      }
      const spaceY = maskHeightMinusBorderRadius / (numLoops - 1);
      let ypos = 0;
      let loopIndex = 0;

      let sideID = -1;
      let sideIndex = -1;

      const edgeOrder = [LEFT, BOTTOM, RIGHT, TOP];

      const negOne = -1;

      // Create a grid with only vertex positions
      for (let y = 0; y < numLoops; ++y) {
        let row = sideGridVertexPositions[y];

        if (!row) {
          row = sideGridVertexPositions[y] = [];
        }
        const yPct = y / (numLoops - 1);

        for (let x = 0; x < numVertPositions; ++x) {
          const targetVPos = outerEdgeLoopVertexPositions[x];
          let yOffset = ypos * scaleY;
          let yOffsetScale = 1;
          let absShapeOffset = 0;

          if (borderCurve) {
            sideID = negOne;
            sideIndex = negOne;
            let sidePct = -1;

            // TODO: improve graph x-position lookup
            sidePct = this._getGraphXFromMattressLocation(
              targetVPos.getCoord(0), targetVPos.getCoord(1), targetVPos.getCoord(2),
              mattressWidth, mattressLength, cornerRadius);

            if (sidePct < 0 && targetVPos.userData) {
              sideID = targetVPos.userData.sideID;
              const edgeIndex = edgeOrder.indexOf(sideID, 0);

              sideIndex = targetVPos.userData.sideIndex;
              const totalVPerSide = numVertPosPerSide[sideID];

              sidePct = (sideIndex + 1) / (totalVPerSide);
              sidePct = (sidePct / _4) + (edgeIndex / _4);
            }
            if (sidePct < 0) {
              sidePct = 0;
            }

            // Shape value should be a value between 1 (top) to -1 (bottom), so 0 in the middle
            let shapeValue = borderCurve.getY(sidePct);

            if (borderCurveMinY !== null && borderCurveMinY !== undefined) {
              shapeValue = shapeValue < borderCurveMinY ? borderCurveMinY : shapeValue;
            }
            if (borderCurveMaxY !== null && borderCurveMaxY !== undefined) {
              shapeValue = shapeValue > borderCurveMaxY ? borderCurveMaxY : shapeValue;
            }

            if (shapeValue < 0) {
              absShapeOffset = shapeValue * borderCurveNegativeHeight;
            } else {
              absShapeOffset = shapeValue * borderCurvePositiveHeight;
            }
            if (y === 0) {
              const blVert = borderLoop[x];

              if (blVert) {
                blVert.setCoord(1, blVert.getCoord(1) + absShapeOffset);
                if (blVert.attributes) {
                  const blUV = blVert.attributes.uv;

                  if (blUV) {
                    // blUV.setCoord(1, blUV.getCoord(1) + absShapeOffset);
                    blUV.setCoord(1, absShapeOffset);
                  }
                }
              }
            }
            absShapeOffset *= yPct;

            // calculate the y offset
            yOffsetScale = 0;
            if (scaleY < 0) {
              // y offset for bottom part
              yOffsetScale = shapeValue + 1;
            } else {
              // y offset for top part
              yOffsetScale = -shapeValue + 1;
              if (yOffsetScale < 0) {
                yOffsetScale = 0;
              }
            }
            yOffset += absShapeOffset;

            // yOffset *= yOffsetScale;
          }

          let vpos = targetVPos;

          if (yOffset !== 0) {
            let newY = targetVPos.getCoord(1) + yOffset;
            let ylimit;

            if (scaleY < 0) {
              ylimit = -(topHeight - borderRadius);
              // const ylimit = 0;
              if (newY < ylimit) {
                newY = ylimit;
              }
            } else if (scaleY > 0) {
              ylimit = topHeight - borderRadius;
              if (newY > ylimit) {
                newY = ylimit;
              }
            }

            vpos = new Vertex(new Vector3(
              targetVPos.getCoord(0),
              // targetVPos.getCoord(1) + yOffset,
              newY,
              targetVPos.getCoord(2)
            ));
            resultVertices.push(vpos);

            // const nrm = targetVPos.attributes ? targetVPos.attributes.normal : null;
            const nrm = outerEdgeLoopNormals[x];

            if (nrm) {
              GeomUtils.assignVertexAttribute(vpos, 'normal', nrm);
            }
            let vposUD = vpos.userData;

            if (yOffsetScale !== 1) {
              if (!vposUD) {
                vposUD = vpos.userData = {};
              }
              // vposUD.shapeFactor = yOffsetScale;
              vposUD.absShapeOffset = absShapeOffset * scaleY;
            }
          }
          row[x] = vpos;
        }
        ypos -= spaceY;
      }

      ypos = 0;

      const hMidsizeX = (mattressWidth - cornerRadius - cornerRadius) * 0.5;
      const hMidsizeZ = (mattressLength - cornerRadius - cornerRadius) * 0.5;

      // Create polygon vertices, calculate the uvs per polygon & reuse vertex positions from the grid (sideGridVertexPositions)
      const hUVWidth = hMidsizeX + buildData.topBorderUVDistance;
      const hUVHeight = hMidsizeZ + buildData.topBorderUVDistance;

      for (let y = 0; y < numLoops; ++y) {
        const edgeLoop = [];
        let colIndex = 0;
        const vposTargetRow = sideGridVertexPositions[y];

        for (let x = 0; x < numverts; ++x) {
          const targetVert = outerEdgeLoop[x];

          const targetVertUD = targetVert.userData;
          let vposIndex = x;

          if (targetVertUD) {
            vposIndex = targetVertUD.vertexPosIndex;
          }
          const tgtVPos = vposTargetRow[vposIndex];


          sideID = TOP;
          sideIndex = 0;
          if (targetVert.userData) {
            sideID = targetVert.userData.sideID;
            sideIndex = targetVert.userData.sideIndex;
          }
          // newVert = new Vertex(tgtVPos);
          const newVert = this._newPolyVertex(tgtVPos, null, buildData);

          resultPolyVertices.push(newVert);

          let srcUV = null;
          let srcNrm = null;

          if (targetVert.attributes) {
            srcUV = targetVert.attributes.uv;
            srcNrm = targetVert.attributes.normal;
          }
          if (!srcUV) {
            srcUV = new Vector2(0, 0);
          }

          if (!srcNrm) {
            if (tgtVPos.attributes) {
              srcNrm = tgtVPos.attributes.normal;
            }
          }
          if (!srcNrm) {
            srcNrm = new Vector3(0, 0, 1);
          }

          // uv = getTopMeshSideUV(sideID, tgtVPos, ypos, hw, hd, scaleY, srcUV);
          const uv = this._getTopMeshSideUV(sideID, tgtVPos, ypos, hUVWidth, hUVHeight, scaleY, srcUV);

          if (uvYScale !== 1) {
            uv.setCoord(1, uv.getCoord(1) * uvYScale);
          }
          if (resultUvs) {
            resultUvs.push(uv);
          }

          GeomUtils.assignVertexAttribute(newVert, 'uv', uv);
          GeomUtils.assignVertexAttribute(newVert, 'normal', srcNrm);
          edgeLoop[colIndex++] = newVert;

        }
        sideGrid[loopIndex] = edgeLoop;
        ++loopIndex;
        ypos -= spaceY;
      }
      buildData.edgeLoopIndex = loopIndex;

      /*
      if (sideGrid) {
        lastLoop = sideGrid[sideGrid.length - 1];
      }
      */

      // Create polygons from vertices in side grid
      for (let y = 0; y < (numLoops - 1); ++y) {
        for (let x = 0; x < numverts; ++x) {
          if (!isSeamEdgeIndex(x + 1, vSeams)) {
            const x1 = (x + 1) % numverts;

            const vertTL = sideGrid[y][x];
            const vertTR = sideGrid[y][x1];
            const vertBL = sideGrid[y + 1][x];
            const vertBR = sideGrid[y + 1][x1];

            const polyVerts = [vertTL, vertBL, vertBR, vertTR];

            // polyVerts = [vertTR, vertBR, vertBL, vertTL];
            if (flipPolys) {
              polyVerts.reverse();
            }
            const poly = new Polygon(polyVerts);

            polys.push(poly);
          }
        }
      }
      // #if DEBUG
      timeEnd(' create side polys');
      // #endif
    }
  }

  static _graphXFromContourInfo(info) {
    const negOne = -1;

    if (!info || !info.valid) {
      return negOne;
    }
    const side = info.side;
    let sideIndices = this._sideIndices;

    if (!sideIndices) {
      const SideTypes = BorderContourUtil.SideTypes;

      sideIndices = this._sideIndices = {};
      sideIndices[SideTypes.LEFT] = 0;
      sideIndices[SideTypes.BACK] = 1;
      sideIndices[SideTypes.RIGHT] = 2;
      sideIndices[SideTypes.FRONT] = 3;
    }
    const sideIndex = sideIndices[side];
    const sideLength = info.sideLength;
    const sideTotalLength = info.sideTotalLength;
    const sidePct = sideLength / sideTotalLength;
    const DIV4 = 0.25;
    const totalPct = (sidePct + sideIndex) * DIV4;

    return totalPct;
  }

  static _getGraphXFromMattressLocation(x, y, z, mattressWidth, mattressLength, cornerRadius) {
    this._tempBorderContourInfo = BorderContourUtil.getClosestBorderContourInfo(
      x, y, z,
      mattressWidth, mattressLength, cornerRadius, null, this._tempBorderContourInfo);

    const res = this._graphXFromContourInfo(this._tempBorderContourInfo);

    /*
    const info = this._tempBorderContourInfo;
    const infojson = {
      x: x,
      y: y,
      z: z,
      ix: info.x,
      iy: info.y,
      iz: info.z,
      side: info.side,
      pct: res
    };

    console.info('contourinfo=', JSON.stringify(infojson));
    if (x !== info.x || y !== info.y || z !== info.z) {
      const dx = Math.abs(x - info.x);
      const dz = Math.abs(y - info.y);
      const dy = Math.abs(z - info.z);
      const toll = 0.001;

      if (dx > toll || dy > toll || dz > toll) {
        console.error('faaaack', x, info.x, y, info.y, z, info.z);
      }
    }
    */

    return res;
  }

  static _getTopMeshSideUV(sideID, vpos, srcYpos, hw, hd, scaleY, srcUV) {
    let uValue, vValue;
    let ypos = srcYpos;
    const vposUD = vpos.userData;

    if (vposUD) {
      /*
      var sf = vposUD.shapeFactor;
      if (sf !== undefined && sf !== null) {
        ypos *= sf;
      }
      */
      const absShapeOff = vposUD.absShapeOffset;

      if (absShapeOff !== undefined && absShapeOff !== null) {
        ypos += absShapeOff;
      }
    }
    if (sideID === TOP) {
      uValue = vpos.getCoord(0);
      // vValue = -targetVert.getCoord(2) - targetVert.getCoord(1) - ypos;
      vValue = -hd + ypos;
    } else if (sideID === BOTTOM) {
      uValue = vpos.getCoord(0);
      // vValue = -targetVert.getCoord(2) + targetVert.getCoord(1) + ypos;
      vValue = hd - ypos;
    } else if (sideID === LEFT) {
      uValue = -hw + ypos;
      vValue = vpos.getCoord(2);
    } else if (sideID === RIGHT) {
      uValue = hw - ypos;
      vValue = vpos.getCoord(2);
    }
    if (scaleY < 0) {
      uValue *= -1;
    }

    const uv = new Vector2(uValue, vValue);

    return uv;
  }
  /**
   * params:
   *  computeTangents boolean (true)
   * */

  static _generateTopPolys(width, length, height, cornerRadius, borderRadius, scaleY,
    borderCurve, borderCurveMinY, borderCurveMaxY,
    borderCurvePositiveHeight, borderCurveNegativeHeight, params = null, extraOutput = null) {

    let cornerR = cornerRadius;
    let borderR = borderRadius;
    const halfW = width * HALF;
    const halfL = length * HALF;

    cornerR = cornerR > halfW ? halfW : cornerR;
    cornerR = cornerR > halfL ? halfL : cornerR;
    borderR = borderR > cornerR ? cornerR : borderR;

    // TODO: get these values from the params object
    const uvYscale = 1; // flip V coordinate?

    const buildData = {
      topHeight: height,
      mattressData: {
        width: width,
        length: length,
        cornerRadius: cornerR,
        borderRadius: borderR,
        scaleY: scaleY
      },
      borderCurveData: {
        shape: borderCurve,
        positiveHeight: borderCurvePositiveHeight,
        negativeHeight: borderCurveNegativeHeight,
        minY: borderCurveMinY,
        maxY: borderCurveMaxY
      },
      params: params,
      vertices: [],
      polygonVertices: [],
      polygons: [],
      uvs: []
    };

    buildData.uvYScale = uvYscale;

    MattressGeomUtils._createTopGrid(buildData);

    MattressGeomUtils._createConcentricGeometry(buildData);

    MattressGeomUtils._createSides(buildData);

    let computeTangents = true;

    if (params) {
      computeTangents = params.computeTangents !== false;
    }

    if (computeTangents) {
      GeomUtils.calculateTangentsForPolygons(buildData.polygons);
    }

    let uvData = null;

    if (extraOutput) {
      uvData = {};
      extraOutput.uvData = uvData;
    }
    GeomUtils.normalizeUVs(buildData.uvs, params, uvData);

    if (extraOutput) {
      extraOutput.vertices = buildData.vertices;
      extraOutput.polygonVertices = buildData.polygonVertices;
    }
    extraOutput.borderLoop = buildData.borderLoop;

    return buildData.polygons;
  }

  static generateSideProfilePath(height, radius, roundness, result, params) {
    let res = result;

    if (!res) {
      res = [];
    }
    let rndness = roundness;

    rndness = rndness < 0 ? 0 : rndness;
    rndness = rndness > 1 ? 1 : rndness;

    const minR = 0.25;
    const maxR = 0;
    let linear = (rndness - minR) / (maxR - minR);

    linear = linear < 0 ? 0 : linear;
    linear = linear > 1 ? 1 : linear;

    const counter = {};
    const offx = radius * roundness;
    const hh = height * 0.5;

    // addCornerArc(fromX, fromY, toX, toY, centerX, centerY, includeFirst, includeLast, xcoord, ycoord, zcoord, path, counter, onNewPoint, params);

    GeomUtils.addCornerArc(
      0, hh,
      offx, hh - radius,
      0, hh - radius,
      true, true, linear, 0, 1, 2, res, counter, null, params);

    GeomUtils.addLine(
      offx, hh - radius,
      offx, -hh + radius,
      false, false, 0, 1, 2, res, counter, null, params
    );

    GeomUtils.addCornerArc(
      offx, -hh + radius,
      0, -hh,
      0, -hh + radius,
      true, true, linear, 0, 1, 2, res, counter, null, params);

    const computeNormals = true;
    const computeUVs = true;

    if (computeNormals) {
      GeomUtils.computePathVertexNormals(res, 0, 1, -1);
    }
    if (computeUVs) {
      GeomUtils.computePathUV(res, 0);
    }

    return res;
  }
}
