import MattressDA from './MattressDA';
import SampleCollector from '../sample/SampleCollector';
import QuiltCollector from '../quilt/QuiltCollector';

const DEFAULT_NOISE_SCALE = MattressDA.DEFAULT_NOISE_SCALE;
const P_ID = '@ID';
const P_ID_LC = '@id';

let idCounter = 0;

function findSingleById(single, index, numSingles, singles, id) {
  if (!single) {
    return false;
  }

  return single[P_ID] === id ||
    single[P_ID_LC] === id ||
    single.id === id;
}

/**
* @class MattressConfigDA
* @description Mattress config data accessor
*/
export default class MattressConfigDA {

  static setVersion(config, v) {
    if (!config) {
      return;
    }
    config.version = v;
  }

  static getVersion(config) {
    if (!config) {
      return null;
    }

    return config.version;
  }

  static getObjectID(object, create = true) {
    if (!object) {
      return null;
    }
    if (typeof (object) !== 'object') {
      return null;
    }
    let id = object[P_ID] || object[P_ID_LC];

    if (id || !create) {
      return id;
    }
    // create id
    const rRange = 10000;
    const sep = 'O';
    const r1 = (Math.random() * rRange) | 0;
    const r2 = (Math.random() * rRange) | 0;
    const HEX = 16;
    let t = 0;

    if (Date.now) {
      t = Date.now();
    } else {
      const d = new Date();

      t = d.getTime();
    }
    const c = idCounter;

    ++idCounter;
    const maxCount = 0xFFFFFF;

    idCounter %= maxCount;

    id = r1.toString(HEX) + sep + r2.toString(HEX) + sep + t.toString(HEX) + sep + c.toString(HEX);

    if (Reflect.defineProperty) {
      try {
        Reflect.defineProperty(object, P_ID, {value: id, enumerable: true});
      } catch (e) {
        object[P_ID] = id;
      }
    } else {
      object[P_ID] = id;
    }
    if (object[P_ID] !== id) {
      // Rare case when defineProperty fails
      object[P_ID] = id;
    }

    return id;
  }

  static _getIDIndex(id) {
    if (!id) {
      return -1;
    }
    let i = id;

    if (typeof (id) === 'object') {
      i = id[P_ID];
    }
    if (!i || typeof (i) !== 'string') {
      return -1;
    }
    const idx = i.lastIndexOf('O');

    if (idx >= 0) {
      i = i.substring(idx + 1, i.length);
    }
    const HEX = 16;

    return parseInt(i, HEX);
  }


  static _getObjectID(object, type = null) {
    if (!object) {
      return null;
    }

    return object[P_ID] || object[P_ID_LC];
  }

  static getObjectByID(id, object, type = null) {
    if (!object || !id) {
      return null;
    }
    if (this._getObjectID(object, type) === id) {
      return object;
    }
    if (object.singles) {
      const singles = object.singles;
      const num = singles ? singles.length : 0;

      for (let i = 0; i < num; ++i) {
        const single = singles[i];
        const res = this.getObjectByID(id, single, 'single');

        if (res) {
          return res;
        }
      }
    }

    let res = null;

    res = this._getObjectID(id, object.texture, 'texture');

    if (res) {
      return res;
    }

    res = this._getObjectID(id, object.quilt, 'quilt');
    if (res) {
      return res;
    }

    if (type === 'single') {
      res = this.getObjectByID(id, object.top, 'top');
      if (res) {
        return res;
      }

      res = this.getObjectByID(id, object.bottom, 'bottom');
      if (res) {
        return res;
      }

      if (object.border) {
        const border = object.border;

        if (this._getObjectID(border, 'border') === id) {
          return border;
        }
        const bordercomps = border ? border.components : null;
        const numBorderComps = bordercomps ? bordercomps.length : 0;

        for (let i = 0; i < numBorderComps; ++i) {
          const comp = bordercomps[i];

          if (this._getObjectID(comp, 'bordercomponent') === id) {
            return comp;
          }
          res = this.getObjectByID(id, comp, 'bordercomponent');

          if (res) {
            return res;
          }
        }
      }
    }

    return null;
  }

  static getSingleProperty(config, single, property) {
    if (!property) {
      return null;
    }
    const s = this.getSingle(config, single);

    return MattressDA.getProperty(s, property);
  }

  static setSingleProperty(config, single, property, value) {
    if (!property) {
      return;
    }
    const s = this.getSingle(config, single);

    MattressDA.setProperty(s, property, value);
  }

  static getSingleName(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.getSingleName(s);
  }

  static setSingleName(config, single, value) {
    const s = this.getSingle(config, single);

    return MattressDA.setSingleName(s, value);
  }

  static getSamples(config, params, array) {
    return SampleCollector.getSamplesByConfig(config, params, array);
  }

  static getQuilts(config, params, array) {
    return QuiltCollector.getQuiltsByConfig(config, params, array);
  }

  static getSingles(config) {
    if (!config) {
      return null;
    }

    return config.singles;
  }

  static getNumSingles(config) {
    const singles = this.getSingles(config);

    if (!singles) {
      return 0;
    }

    return singles.length;
  }

  static getSingle(config, single) {
    const t = typeof (single);

    if (t === 'object') {
      return single;
    }
    const singles = this.getSingles(config);
    const numSingles = singles ? singles.length : 0;

    if (numSingles === 0) {
      return null;
    }
    if (t === 'number') {
      return singles[single];
    } else if (t === 'string') {
      for (let i = 0; i < numSingles; ++i) {
        const s = singles[i];

        if (s && s.id === single) {
          return s;
        }
      }
    } else if (numSingles === 1) {
      return singles[0];
    }

    return null;
  }

  static getSize(config, single, key, fallback) {
    const s = this.getSingle(config, single);

    if (!s) {
      return fallback;
    }

    return MattressDA.getSize(s, key, fallback);
  }

  static setSize(config, single, key, value) {
    const s = this.getSingle(config, single);

    if (!s) {
      return;
    }
    MattressDA.setSize(s, key, value);
  }

  static setSample(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setSample(s, value);
  }

  static getTopSample(config, single, fallback) {
    const s = this.getSingle(config, single);

    return MattressDA.getTopSample(s, fallback);
  }

  static setTopSample(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setTopSample(s, value);
  }

  static getBottomMirrorPanelSampleID(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.getBottomMirrorPanelSampleID(s);
  }

  static setBottomMirrorPanelSampleID(config, single, sampleID) {
    const s = this.getSingle(config, single);

    return MattressDA.setBottomMirrorPanelSampleID(s, sampleID);
  }

  static isBottomMirrorPanelEnabled(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.isBottomMirrorPanelEnabled(s);
  }

  static setBottomMirrorPanelEnabled(config, single, b) {
    const s = this.getSingle(config, single);

    MattressDA.setBottomMirrorPanelEnabled(s, b);
  }

  static getBottomMirrorPanel(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.getBottomMirrorPanel(s);
  }

  static setBottomMirrorPanel(config, single, mp) {
    const s = this.getSingle(config, single);

    MattressDA.setBottomMirrorPanel(s, mp);
  }

  static getBottomSample(config, single, fallback) {
    const s = this.getSingle(config, single);

    return MattressDA.getBottomSample(s, fallback);
  }

  static setBottomSample(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setBottomSample(s, value);
  }


  // -- Sample offset --

  static getTopSampleOffsetX(config, single, fallback) {
    const s = this.getSingle(config, single);

    return MattressDA.getTopSampleOffsetX(s, fallback);
  }

  static setTopSampleOffsetX(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setTopSampleOffsetX(s, value);
  }

  static getTopSampleOffsetY(config, single, fallback) {
    const s = this.getSingle(config, single);

    return MattressDA.getTopSampleOffsetY(s, fallback);
  }

  static setTopSampleOffsetY(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setTopSampleOffsetY(s, value);
  }

  static getBottomSampleOffsetX(config, single, fallback) {
    const s = this.getSingle(config, single);

    return MattressDA.getBottomSampleOffsetX(s, fallback);
  }

  static setBottomSampleOffsetX(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setBottomSampleOffsetX(s, value);
  }

  static getBottomSampleOffsetY(config, single, fallback) {
    const s = this.getSingle(config, single);

    return MattressDA.getBottomSampleOffsetY(s, fallback);
  }

  static setBottomSampleOffsetY(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setBottomSampleOffsetY(s, value);
  }

  static setTopSampleAlign(config, single, x, y) {
    const s = this.getSingle(config, single);

    MattressDA.setTopSampleAlign(s, x, y);
  }

  static getTopSampleAlignX(config, single, sampleService, fallback) {
    const s = this.getSingle(config, single);

    return MattressDA.getTopSampleAlignX(s, sampleService, fallback);
  }

  static setTopSampleAlignX(config, single, x) {
    const s = this.getSingle(config, single);

    MattressDA.setTopSampleAlignX(s, x);
  }

  static getTopSampleAlignY(config, single, sampleService, fallback) {
    const s = this.getSingle(config, single);

    return MattressDA.getTopSampleAlignY(s, sampleService, fallback);
  }

  static setTopSampleAlignY(config, single, x) {
    const s = this.getSingle(config, single);

    MattressDA.setTopSampleAlignY(s, x);
  }

  static setBottomSampleAlign(config, single, x, y) {
    const s = this.getSingle(config, single);

    MattressDA.setBottomSampleAlign(s, x, y);
  }

  static getBottomSampleAlignX(config, single, sampleService, fallback) {
    const s = this.getSingle(config, single);

    return MattressDA.getBottomSampleAlignX(s, sampleService, fallback);
  }

  static setBottomSampleAlignX(config, single, x) {
    const s = this.getSingle(config, single);

    MattressDA.setBottomSampleAlignX(s, x);
  }

  static getBottomSampleAlignY(config, single, sampleService, fallback) {
    const s = this.getSingle(config, single);

    return MattressDA.getBottomSampleAlignY(s, sampleService, fallback);
  }

  static setBottomSampleAlignY(config, single, x) {
    const s = this.getSingle(config, single);

    MattressDA.setBottomSampleAlignY(s, x);
  }

  // -- Sample rotation --

  static getTopSampleRotation(config, single, sampleService, fallback = 0) {
    const s = this.getSingle(config, single);

    return MattressDA.getTopSampleRotation(s, sampleService, fallback);
  }

  static setTopSampleRotation(config, single, v) {
    const s = this.getSingle(config, single);

    MattressDA.setTopSampleRotation(s, v);
  }

  static getBottomSampleRotation(config, single, sampleService, fallback) {
    const s = this.getSingle(config, single);

    return MattressDA.getBottomSampleRotation(s, sampleService, fallback);
  }

  static setBottomSampleRotation(config, single, v) {
    const s = this.getSingle(config, single);

    MattressDA.setBottomSampleRotation(s, v);
  }

  // -- top height --

  static getTopHeight(config, single, fallback = 0) {
    const s = this.getSingle(config, single);

    return MattressDA.getTopHeight(s, fallback);
  }

  static setTopHeight(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setTopHeight(s, value);
  }

  static getBottomHeight(config, single, fallback = 0) {
    const s = this.getSingle(config, single);

    return MattressDA.getBottomHeight(s, fallback);
  }

  static setBottomHeight(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setBottomHeight(s, value);
  }

  static getTopBorderRadius(config, single, fallback) {
    const s = this.getSingle(config, single);

    return MattressDA.getTopBorderRadius(s, fallback);
  }

  static setTopBorderRadius(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setTopBorderRadius(s, value);
  }

  static getBottomBorderRadius(config, single, fallback) {
    const s = this.getSingle(config, single);

    return MattressDA.getBottomBorderRadius(s, fallback);
  }

  static setBottomBorderRadius(config, single, value) {
    const s = this.getSingle(config, single);

    return MattressDA.setBottomBorderRadius(s, value);
  }

  static getCornerRadius(config, single, fallback) {
    const s = this.getSingle(config, single);

    return MattressDA.getCornerRadius(s, fallback);
  }

  static setCornerRadius(config, single, value) {
    const s = this.getSingle(config, single);

    return MattressDA.setCornerRadius(s, value);
  }

  // Top quilt align
  static getTopQuiltAlignX(config, single, quiltService) {
    const s = this.getSingle(config, single);

    return MattressDA.getTopQuiltAlignX(s, quiltService);
  }

  static setTopQuiltAlignX(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setTopQuiltAlignX(s, value);
  }

  static getTopQuiltAlignY(config, single, quiltService) {
    const s = this.getSingle(config, single);

    return MattressDA.getTopQuiltAlignY(s, quiltService);
  }

  static setTopQuiltAlignY(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setTopQuiltAlignY(s, value);
  }

  static setTopQuiltAlign(config, single, x, y) {
    const s = this.getSingle(config, single);

    MattressDA.setTopQuiltAlign(s, x, y);
  }

  // bottom quilt align

  static getBottomQuiltAlignX(config, single, quiltService) {
    const s = this.getSingle(config, single);

    return MattressDA.getBottomQuiltAlignX(s, quiltService);
  }

  static setBottomQuiltAlignX(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setBottomQuiltAlignX(s, value);
  }

  static getBottomQuiltAlignY(config, single, quiltService) {
    const s = this.getSingle(config, single);

    return MattressDA.getBottomQuiltAlignY(s, quiltService);
  }

  static setBottomQuiltAlignY(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setBottomQuiltAlignY(s, value);
  }

  static setBottomQuiltAlign(config, single, x, y) {
    const s = this.getSingle(config, single);

    MattressDA.getBottomQuiltAlign(s, x, y);
  }

  static getBorderComponentName(config, single, borderComponent) {
    const s = this.getSingle(config, single);

    return MattressDA.getBorderComponentName(s, borderComponent);
  }

  static setBorderComponentName(config, single, borderComponent, value) {
    const s = this.getSingle(config, single);

    MattressDA.setBorderComponentName(s, borderComponent, value);
  }

  static getBorderQuiltAlignX(config, single, border, quiltService) {
    const s = this.getSingle(config, single);

    return MattressDA.getBorderQuiltAlignX(s, border, quiltService);
  }

  static setBorderQuiltAlignX(config, single, border, value) {
    const s = this.getSingle(config, single);

    MattressDA.setBorderQuiltAlignX(s, border, value);
  }

  static getBorderQuiltAlignY(config, single, border, quiltService) {
    const s = this.getSingle(config, single);

    return MattressDA.getBorderQuiltAlignY(s, border, quiltService);
  }

  static setBorderQuiltAlignY(config, single, border, value) {
    const s = this.getSingle(config, single);

    MattressDA.setBorderQuiltAlignY(s, border, value);
  }

  static setBorderQuiltAlign(config, single, border, x, y) {
    const s = this.getSingle(config, single);

    MattressDA.setBorderQuiltAlign(s, border, x, y);
  }

  // /---

  static getHandleData(config, single, create = false) {
    const s = this.getSingle(config, single);

    return MattressDA.getHandleData(s, create);
  }

  static getMaxHandles(config, single, atFront) {
    const s = this.getSingle(config, single);

    return MattressDA.getMaxHandlesOfSingle(s, atFront);
  }

  static setMaxHandles(config, single, atFront, value = -1) {
    const s = this.getSingle(config, single);

    MattressDA.setMaxHandlesOfSingle(s, atFront, value);
  }

  static getHandleMaterial(config, single, part, create, fallback = null) {
    const s = this.getSingle(config, single);

    return MattressDA.getHandleMaterial(s, part, create, fallback);
  }

  static getHandleTextureSlotsBySingle(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.getHandleTextureSlotsBySingle(s);
  }

  static getAvailableHandles(config, single, params) {
    const s = this.getSingle(config, single);

    return MattressDA.getAvailableHandles(s, params);
  }

  static getAvailableHandlesOnBorder(config, single, border, params) {
    const s = this.getSingle(config, single);

    return MattressDA.getAvailableHandlesOnBorder(s, border, params);
  }

  static allowHandle(config, single, type) {
    const s = this.getSingle(config, single);

    return MattressDA.allowHandle(s, type);
  }

  static allowHandleOnBorder(config, single, border, type) {
    const s = this.getSingle(config, single);

    return MattressDA.allowHandleOnBorder(s, border, type);
  }

  static canSetHandleSample(config, single, part) {
    const s = this.getSingle(config, single);

    return MattressDA.canSetHandleSample(s, part);
  }

  static getHandleSample(config, single, part, fallback = null) {
    const s = this.getSingle(config, single);

    return MattressDA.getHandleSample(s, part, fallback);
  }

  static setHandleSample(config, single, part, value) {
    const s = this.getSingle(config, single);

    MattressDA.setHandleSample(s, part, value);
  }

  static removeAllHandles(config) {
    const singles = this.getSingles(config);
    const num = (singles && singles.length) || 0;
    let result = false;
    for (let i = 0; i < num; ++i) {
      const single = singles[i];
      const handleType = this.getHandleType(config, single);
      if (handleType) {
        this.setHandleType(config, single, null);
        result = true;
      }
    }
    return result;
  }

  static hasSingleWithHandles(config) {
    const singles = this.getSingles(config);
    const num = (singles && singles.length) || 0;
    for (let i = 0; i < num; ++i) {
      const single = singles[i];
      const handleType = this.getHandleType(config, single);
      if (handleType !== null && typeof handleType !== 'undefined') {
        return true;
      }
    }
    return false;
  }

  static getHandleType(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.getHandleType(s);
  }

  static setHandleType(config, single, value, handleParams = null, handleMaterials = null) {
    const s = this.getSingle(config, single);

    return MattressDA.setHandleType(s, value, handleParams, handleMaterials);
  }

  static canSetHandleColor(config, single, part) {
    const s = this.getSingle(config, single);

    return MattressDA.canSetHandleColor(s, part);
  }

  static getHandleColor(config, single, part, fallback) {
    const s = this.getSingle(config, single);

    return MattressDA.getHandleColor(s, part, fallback);
  }

  static setHandleColor(config, single, part, value) {
    const s = this.getSingle(config, single);

    return MattressDA.setHandleColor(s, part, value);
  }

  static singleHasCustomizableLogoHandle(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.singleHasCustomizableLogoHandle(s);
  }

  static getLogoHandleImageID(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.getLogoHandleImageID(s);
  }

  static setLogoHandleImageID(config, single, id) {
    const s = this.getSingle(config, single);

    MattressDA.setLogoHandleImageID(s, id);
  }

  static getLogoHandleImageURL(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.getLogoHandleImageURL(s);
  }

  static setLogoHandleImageURL(config, single, url) {
    const s = this.getSingle(config, single);

    MattressDA.setLogoHandleImageURL(s, url);
  }

  // -- border
  static removeBorderComponent(data, single, border) {
    const s = this.getSingle(data, single);

    return MattressDA.removeBorderComponent(s, border);
  }

  static addBorderComponent(data, single, type, index = -1) {
    const s = this.getSingle(data, single);

    return MattressDA.addBorderComponent(s, type, index);
  }

  static hasEditableBorderPropertyDefined(data, single, property, border = null) {
    const s = this.getSingle(data, single);

    return MattressDA.hasEditableBorderPropertyDefined(s, property, border);
  }

  static canChangeBorderComponentProperty(data, single, border, property) {
    const s = this.getSingle(data, single);

    if (!s) {
      return false;
    }
    let fallbackValue = true;

    if (!this.hasEditableBorderPropertyDefined(data, single, property, border)) {
      const propLc = property && property.toLowerCase ? property.toLowerCase() : property;

      if (propLc === 'height') {
        const numSingles = this.getNumSingles(data);

        fallbackValue = numSingles === 1;
      }
    }

    return MattressDA.canChangeBorderComponentProperty(s, border, property, fallbackValue);
  }

  static canChangeBorderComponentHeight(data, single, border) {
    return this.canChangeBorderComponentProperty(data, single, border, 'height');
  }

  static getBorderComponents(data, single) {
    const s = this.getSingle(data, single);

    return MattressDA.getBorderComponents(s);
  }

  static getBorderComponent(data, single, key) {
    const s = this.getSingle(data, single);

    return MattressDA.getBorderComponent(s, key);
  }

  static singleHasAvailableBorderComponentTypes(data, single) {
    const s = this.getSingle(data, single);

    return MattressDA.singleHasAvailableBorderComponentTypes(s);
  }

  static getAvailableBorderComponentTypes(data, single) {
    const s = this.getSingle(data, single);

    return MattressDA.getAvailableBorderComponentTypes(s);
  }

  static getBorderComponentIndex(data, single, border) {
    const s = this.getSingle(data, single);

    return MattressDA.getBorderComponentIndex(s, border);
  }

  static setBorderComponentIndex(data, single, border, index) {
    const s = this.getSingle(data, single);

    return MattressDA.setBorderComponentIndex(s, border, index);
  }

  static getBorderComponentHeight(data, single, borderComponent, fallback) {
    const s = this.getSingle(data, single);

    return MattressDA.getBorderComponentHeight(s, borderComponent, fallback);
  }

  static setBorderComponentHeight(data, single, borderComponent, value) {
    const s = this.getSingle(data, single);

    MattressDA.setBorderComponentHeight(s, borderComponent, value);
  }

  static getBorderComponentDepth(data, single, borderComponent, fallback) {
    const s = this.getSingle(data, single);

    return MattressDA.getBorderComponentDepth(s, borderComponent, fallback);
  }

  static setBorderComponentDepth(data, single, borderComponent, value) {
    const s = this.getSingle(data, single);

    MattressDA.setBorderComponentDepth(s, borderComponent, value);
  }

  static getBorderComponentTextureRotation(data, single, borderComponent, sampleService, fallback = 0) {
    const s = this.getSingle(data, single);

    return MattressDA.getBorderComponentTextureRotation(s, borderComponent, sampleService, fallback);
  }

  static setBorderComponentTextureRotation(data, single, borderComponent, value) {
    const s = this.getSingle(data, single);

    MattressDA.setBorderComponentTextureRotation(s, borderComponent, value);
  }

  static getBorderComponentTextureOffsetX(data, single, borderComponent, fallback = 0) {
    const s = this.getSingle(data, single);

    return MattressDA.getBorderComponentTextureOffsetX(s, borderComponent, fallback);
  }

  static getBorderComponentTextureOffsetY(data, single, borderComponent, fallback = 0) {
    const s = this.getSingle(data, single);

    return MattressDA.getBorderComponentTextureOffsetY(s, borderComponent, fallback);
  }

  static setBorderComponentTextureOffset(data, single, borderComponent, x, y) {
    const s = this.getSingle(data, single);

    MattressDA.setBorderComponentTextureOffset(s, borderComponent, x, y);
  }

  static setBorderComponentTextureOffsetX(data, single, borderComponent, x) {
    const s = this.getSingle(data, single);

    MattressDA.setBorderComponentTextureOffsetX(s, borderComponent, x);
  }

  static setBorderComponentTextureOffsetY(data, single, borderComponent, y) {
    const s = this.getSingle(data, single);

    MattressDA.setBorderComponentTextureOffsetY(s, borderComponent, y);
  }

  static setBorderComponentTextureAlign(data, single, borderComponent, x, y) {
    const s = this.getSingle(data, single);

    MattressDA.setBorderComponentTextureAlign(s, borderComponent, x, y);
  }

  static getBorderComponentTextureAlignX(data, single, borderComponent, sampleService, fallback = 0.5) {
    const s = this.getSingle(data, single);

    return MattressDA.getBorderComponentTextureAlignX(s, borderComponent, sampleService, fallback);
  }

  static setBorderComponentTextureAlignX(data, single, borderComponent, value) {
    const s = this.getSingle(data, single);

    MattressDA.setBorderComponentTextureAlignX(s, borderComponent, value);
  }

  static getBorderComponentTextureAlignY(data, single, borderComponent, sampleService, fallback = 0.5) {
    const s = this.getSingle(data, single);

    return MattressDA.getBorderComponentTextureAlignY(s, borderComponent, sampleService, fallback);
  }

  static setBorderComponentTextureAlignY(data, single, borderComponent, value) {
    const s = this.getSingle(data, single);

    MattressDA.setBorderComponentTextureAlignY(s, borderComponent, value);
  }

  static getBorderComponentSample(data, single, borderComponent) {
    const s = this.getSingle(data, single);

    return MattressDA.getBorderComponentSample(s, borderComponent);
  }

  static setBorderComponentSample(data, single, borderComponent, value) {
    const s = this.getSingle(data, single);

    MattressDA.setBorderComponentSample(s, borderComponent, value);
  }

  static getBorderComponentSampleID(data, single, borderComponent, fallback) {
    const s = this.getSingle(data, single);

    return MattressDA.getBorderComponentSampleID(s, borderComponent, fallback);
  }

  static setBorderComponentSampleID(data, single, borderComponent, value) {
    const s = this.getSingle(data, single);

    MattressDA.setBorderComponentSampleID(s, borderComponent, value);
  }

  static getBorderComponentMaterialType(data, single, borderComponent, fallback) {
    const s = this.getSingle(data, single);

    return MattressDA.getBorderComponentMaterialType(s, borderComponent, fallback);

  }
  static setBorderComponentMaterialType(data, single, borderComponent, materialType) {
    const s = this.getSingle(data, single);

    MattressDA.setBorderComponentMaterialType(s, borderComponent, materialType);
  }

  static getBorderComponentColorId(data, single, borderComponent, fallback) {
    const s = this.getSingle(data, single);

    return MattressDA.getBorderComponentColorId(s, borderComponent);
  }

  static getBorderComponentColor(data, single, borderComponent, fallback) {
    const s = this.getSingle(data, single);

    return MattressDA.getBorderComponentColor(s, borderComponent);
  }

  static setBorderComponentColor(data, single, borderComponent, color) {
    const s = this.getSingle(data, single);

    return MattressDA.setBorderComponentColor(s, borderComponent, color);
  }

  static getBorderComponentZipperType(data, single, borderComponent, fallback) {
    const s = this.getSingle(data, single);

    return MattressDA.getBorderComponentZipperType(s, borderComponent, fallback);
  }

  static setBorderComponentZipperType(data, single, borderComponent, zipperType) {
    const s = this.getSingle(data, single);

    MattressDA.setBorderComponentZipperType(s, borderComponent, zipperType);
  }

  static getBorderQuiltFoamValue(data, single, borderComponent) {
    const s = this.getSingle(data, single);

    return MattressDA.getBorderQuiltFoamValue(s, borderComponent);
  }

  static setBorderQuiltFoamValue(data, single, borderComponent, value) {
    const s = this.getSingle(data, single);

    MattressDA.setBorderQuiltFoamValue(s, borderComponent, value);
  }

  static getBorderRibbon(data, single, borderComponent) {
    const s = this.getSingle(data, single);

    return MattressDA.getBorderRibbon(s, borderComponent);
  }

  static setBorderRibbon(data, single, borderComponent, value) {
    const s = this.getSingle(data, single);

    MattressDA.setBorderRibbon(s, borderComponent, value);
  }

  static isBorderRibbonEnabled(data, single, borderComponent) {
    const s = this.getSingle(data, single);

    return MattressDA.isBorderRibbonEnabled(s, borderComponent);
  }

  static setBorderRibbonEnabled(data, single, borderComponent, enabled) {
    const s = this.getSingle(data, single);

    return MattressDA.setBorderRibbonEnabled(s, borderComponent, enabled);
  }

  static getBorderRibbonColor(data, single, borderComponent) {
    const s = this.getSingle(data, single);

    return MattressDA.getBorderRibbonColor(s, borderComponent);
  }

  static setBorderRibbonColor(data, single, borderComponent, value) {
    const s = this.getSingle(data, single);

    MattressDA.setBorderRibbonColor(s, borderComponent, value);
  }

  static getBorderRibbonMaterial(data, single, borderComponent) {
    const s = this.getSingle(data, single);

    return MattressDA.getBorderRibbonMaterial(s, borderComponent);
  }

  static setBorderRibbonMaterial(data, single, borderComponent, value) {
    const s = this.getSingle(data, single);

    MattressDA.setBorderRibbonMaterial(s, borderComponent, value);
  }

  static getTotalBorderComponentHeight(data, single) {
    const s = this.getSingle(data, single);

    return MattressDA.getTotalBorderComponentHeight(s);
  }

  static getLegs(config, single, fallback = null) {
    const s = this.getSingle(config, single);

    return MattressDA.getLegs(s) || fallback;
  }

  static setLegs(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setLegs(s, value);
  }

  static getLegHeight(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.getLegHeight(s);
  }

  static setLegHeight(config, single, value) {
    const s = this.getSingle(config, single);

    return MattressDA.setLegHeight(s, value);
  }

  // ////////////////////////////////////////////////////////////////
  //
  // Quilt methods
  //
  // ////////////////////////////////////////////////////////////////

  // Top quilt methods
  static getTopQuiltID(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.getTopQuiltID(s);
  }

  static setTopQuiltID(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setTopQuiltID(s, value);
  }

  static getTopQuiltRepeatX(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.getTopQuiltRepeatX(s);
  }

  static setTopQuiltRepeatX(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setTopQuiltRepeatX(s, value);
  }

  static getTopQuiltRepeatY(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.getTopQuiltRepeatY(s);
  }

  static setTopQuiltRepeatY(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setTopQuiltRepeatY(s, value);
  }

  static getTopQuiltRepeatType(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.getTopQuiltRepeatType(s);
  }

  static setTopQuiltRepeatType(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setTopQuiltRepeatType(s, value);
  }

  static getTopQuiltOffsetX(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.getTopQuiltOffsetX(s);
  }

  static setTopQuiltOffsetX(config, single, x) {
    const s = this.getSingle(config, single);

    MattressDA.setTopQuiltOffsetX(s, x);
  }

  static getTopQuiltOffsetY(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.getTopQuiltOffsetY(s);
  }

  static setTopQuiltOffsetY(config, single, y) {
    const s = this.getSingle(config, single);

    MattressDA.setTopQuiltOffsetY(s, y);
  }

  static setTopQuiltOffset(config, single, x, y) {
    const s = this.getSingle(config, single);

    MattressDA.setTopQuiltOffset(s, x, y);
  }

  static getTopQuiltRotation(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.getTopQuiltRotation(s);
  }

  static setTopQuiltRotation(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setTopQuiltRotation(s, value);
  }

  static getTopQuiltFoamValue(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.getTopQuiltFoamValue(s);
  }

  static setTopQuiltFoamValue(config, single, value) {
    const s = this.getSingle(config, single);

    return MattressDA.setTopQuiltFoamValue(s, value);
  }

  // Bottom quilt methods
  static getBottomQuiltID(config, single, fallback) {
    const s = this.getSingle(config, single);

    return MattressDA.getBottomQuiltID(s);
  }

  static setBottomQuiltID(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setBottomQuiltID(s, value);
  }

  static getBottomQuiltRepeatType(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.getBottomQuiltRepeatType(s);
  }

  static setBottomQuiltRepeatType(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setBottomQuiltRepeatType(s, value);
  }

  static getBottomQuiltRepeatX(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.getBottomQuiltRepeatX(s);
  }

  static setBottomQuiltRepeatX(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setBottomQuiltRepeatX(s, value);
  }

  static getBottomQuiltRepeatY(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.getBottomQuiltRepeatY(s);
  }

  static setBottomQuiltRepeatY(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setBottomQuiltRepeatY(s, value);
  }

  static getBottomQuiltOffsetX(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.getBottomQuiltOffsetX(s);
  }

  static setBottomQuiltOffsetX(config, single, x) {
    const s = this.getSingle(config, single);

    MattressDA.setBottomQuiltOffsetX(s, x);
  }

  static getBottomQuiltOffsetY(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.getBottomQuiltOffsetY(s);
  }

  static setBottomQuiltOffsetY(config, single, y) {
    const s = this.getSingle(config, single);

    MattressDA.setBottomQuiltOffsetY(s, y);
  }

  static setBottomQuiltOffset(config, single, x, y) {
    const s = this.getSingle(config, single);

    MattressDA.setBottomQuiltOffset(s, x, y);
  }

  static getBottomQuiltRotation(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.getBottomQuiltRotation(s);
  }

  static setBottomQuiltRotation(config, single, value) {
    const s = this.getSingle(config, single);

    MattressDA.setBottomQuiltRotation(s, value);
  }

  static getBottomQuiltFoamValue(config, single) {
    const s = this.getSingle(config, single);

    return MattressDA.getBottomQuiltFoamValue(s);
  }

  static setBottomQuiltFoamValue(config, single, value) {
    const s = this.getSingle(config, single);

    return MattressDA.setBottomQuiltFoamValue(s, value);
  }

  // Border quilt methods
  static getBorderQuiltID(config, single, border) {
    const s = this.getSingle(config, single);

    return MattressDA.getBorderQuiltID(s, border);
  }

  static setBorderQuiltID(config, single, border, value) {
    const s = this.getSingle(config, single);

    MattressDA.setBorderQuiltID(s, border, value);
  }

  static getNoiseValue(config, single, fallback = 0) {
    const s = this.getSingle(config, single);

    return MattressDA.getNoiseValue(s, fallback);
  }

  static setNoiseValue(config, single, value) {
    const s = this.getSingle(config, single);

    return MattressDA.setNoiseValue(s, value);
  }

  static getNoiseScale(config, single, fallback = DEFAULT_NOISE_SCALE) {
    const s = this.getSingle(config, single);

    return MattressDA.getNoiseScale(s, fallback);
  }

  static setNoiseScale(config, single, value) {
    const s = this.getSingle(config, single);

    return MattressDA.setNoiseScale(s, value);
  }

  // ////////////////////////////////////////////////////////////////
  //
  // Background stuff
  //
  // ////////////////////////////////////////////////////////////////

  // returns background data if it's a json object
  static getBackgroundData(config) {
    const bg = this._getBackground(config);

    if (bg && typeof (bg) === 'object') {
      return bg;
    }

    return null;
  }

  static _getBackground(config) {
    if (!config) {
      return null;
    }

    return config.background;
  }
  static _setBackground(config, bg) {
    if (!config) {
      return;
    }

    config.background = bg;
  }

  static _clearBackground(config) {
    if (!config) {
      return;
    }
    const bg = config.background;

    if (!bg) {
      return;
    }
    if (bg.id) {
      bg.id = null;
    }
    if (bg.color) {
      bg.color = null;
    }
  }

  static setBackgroundId(config, id) {
    if (!config) {
      return;
    }
    this._clearBackground(config);
    let bgData = this.getBackgroundData(config);

    if (!bgData) {
      bgData = {};
      this._setBackground(config, bgData);
    }
    bgData.id = id;
  }

  static getBackgroundId(config) {
    const bgData = this._getBackground(config);
    const t = typeof (bgData);

    if (t === 'string') {
      return bgData;
    } else if (t === 'number') {
      return bgData;
    } else if (t === 'object' && bgData) {
      return bgData.id;
    }

    return null;
  }

  static getBackgroundColor(config) {
    const bgData = this._getBackground(config);

    if (!bgData) {
      return null;
    }

    return bgData.color;
  }

  static setBackgroundColor(config, value) {
    if (!config) {
      return;
    }
    this._clearBackground(config);
    let bgData = this._getBackground(config);

    if (!bgData) {
      bgData = {};
      this._setBackground(config, bgData);
    }

    bgData.color = value;
  }

  static clearBackground(config) {
    if (!config) {
      return;
    }
    if (config.background) {
      config.background = null;
    }
  }

  static getMainSingle(config) {
    const index = this.getMainSingleIndex(config);
    const singles = this.getSingles(config);

    if (index < 0 || !singles) {
      return null;
    }

    return singles[index];
  }

  static findSingleIndex(config, condition, params) {
    if (!condition) {
      return -1;
    }
    const singles = this.getSingles(config);
    const numSingles = singles ? singles.length : 0;

    for (let i = 0; i < numSingles; ++i) {
      const single = singles[i];

      if (condition(single, i, numSingles, singles, params)) {
        return i;
      }
    }

    return -1;
  }

  static findSingle(config, condition, params) {
    const index = this.findSingleIndex(config, condition, params);

    if (index < 0) {
      return null;
    }
    const singles = this.getSingles(config);
    const single = singles ? singles[index] : null;

    return single;
  }

  static getMainSingleIndex(config) {
    if (!config) {
      return -1;
    }
    const {controllers} = config;
    const numControllers = controllers ? controllers.length : 0;

    for (let i = 0; i < numControllers; ++i) {
      const contr = controllers[i];

      if (contr && contr.type === 'controllerplugin' && contr.plugin === 'boxspring') {
        const {params} = contr;
        const stack = params && params.stack;
        const stackCount = stack ? stack.length : 0;
        let singleId = null;

        if (stackCount > 0) {
          singleId = stack[stackCount - 1];
        }
        if (!singleId) {
          const mattressId = params && params.mattress;
          const topperId = params && params.topper;

          singleId = topperId || mattressId;
        }
        if (singleId) {
          const index = this.findSingleIndex(config, findSingleById, singleId);

          if (index >= 0) {
            return index;
          }
        }
      }
    }
    const singles = this.getSingles(config);
    const numSingles = singles ? singles.length : 0;

    if (numSingles === 1) {
      return 0;
    }

    return -1;
  }
}
