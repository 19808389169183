import Transform3D from '../transform/Transform3D';
import SRTTransform3D from '../transform/SRTTransform3D';

import VectorCloner from './VectorCloner';

export default class Transform3DCloner {
  static _cloneSRTTransform3D(srtTransform3D, params = null) {
    const position = VectorCloner.clone(srtTransform3D.getPosition(), params);
    const rotation = VectorCloner.clone(srtTransform3D.getRotation(), params);
    const scale = VectorCloner.clone(srtTransform3D.getScale(), params);
    const rotationOrder = srtTransform3D.getRotationOrder();

    return new SRTTransform3D(position, rotation, scale, rotationOrder);
  }

  static clone(transform3D, params = null) {
    if (!transform3D) {
      return null;
    }
    if (!transform3D instanceof Transform3D) {
      return null;
    }

    if (transform3D instanceof SRTTransform3D) {
      return this._cloneSRTTransform3D(transform3D, params);
    }

    // Should not be possible
    return new Transform3D();
  }
}
