import Node3D from '../../bgr3d/scenegraph/Node3D';
import GeometryNode3D from '../../bgr3d/scenegraph/GeometryNode3D';
import ContainerNode3D from '../../bgr3d/scenegraph/ContainerNode3D';
import Geometry from '../../bgr3d/geom/Geometry';
import Transform3D from '../../bgr3d/transform/Transform3D';
import SRTTransform3D from '../../bgr3d/transform/SRTTransform3D';
import * as THREE from 'three';

// TODO: support for geometry with indices > 65535

const ITEM_SIZES = {
  position: 3,
  uv: 2,
  normal: 3,
  tangent: 4,
  bitangent: 3
};

/**
* @function toArrayViewValues
* @private
* @description Tries to give you an ArrayView version of the 'values' parameter
* if the values already is a type of ArrayView or the ArrayView is not supported,
* the function will return the values parameter immediately.
* The function will try to use the arrayView param as result,
* but if it is null or the length is not equal to the length of the values
* parameter, a new ArrayView is returned
* @param {Array|ArrayView} values - Array or Float32Array of input values
* @param {Function|Class} AVClass - ArrayView class (Float32Array, Uint16Array, ...)
* @param {ArrayView} arrayView - Optional preallocated Float32Array that can be used as result
* @returns {Float32Array} - Returns a Float32Array with values of the values parameter
*/
function toArrayViewValues(values, AVClass, arrayView = null) {
  if (AVClass === null || typeof (AVClass) === 'undefined') {
    return values;
  }

  if (values instanceof AVClass) {
    return values;
  }

  let arrVw = arrayView;

  const l = values ? values.length : 0;

  if (!arrVw || (!(arrVw instanceof AVClass)) || arrVw.length !== l) {
    arrVw = new AVClass(l);
  }
  if (!arrVw) {
    return values;
  }
  arrVw.set(values);

  return arrVw;
}

// 'toArrayViewValues' wrapper for Float32Arrays
function toFloat32ArrayValues(values, float32Array = null) {
  if (typeof (Float32Array) === 'undefined') {
    return values;
  }

  return toArrayViewValues(values, Float32Array, float32Array);
}

export default class BGR3DToThreeConverter {
  _arraysAreDifferent(arr1, arr2) {
    if (arr1 === arr2) {
      return false;
    }
    const l1 = arr1.length;
    const l2 = arr2.length;

    if (l1 !== l2) {
      return true;
    }

    for (let i = 0; i < l1; ++i) {
      if (arr1[i] !== arr2[i]) {
        return true;
      }
    }

    return false;
  }

  _checkBufferAttributeNeedsUpdate(bufferAttribute, values, params) {
    if (params) {
      if (params.checkBufferAttributeNeedsUpdate === false) {
        // Params object tells us we don't have to check all array elements for differences
        return true;
      }
    }
    if (!bufferAttribute) {
      return true;
    }
    const attArray = bufferAttribute.array;

    if (!attArray) {
      return true;
    }

    return this._arraysAreDifferent(attArray, values);
  }
  _findCachedThreeObject(target, params) {
    if (this.getCachedThreeObject) {
      return this.getCachedThreeObject(target, params);
    }
    if (!target) {
      return null;
    }
    if (params) {
      if (params.map) {
        return params.map.get(target);
      }
    }
    const md = target.userData;

    if (!md) {
      return null;
    }

    return md.threeObject;
  }

  _storeCachedThreeObject(target, value, params = null) {
    if (this.setCachedThreeObject) {
      this.setCachedThreeObject(target, value, params);

      return;
    }
    if (!target) {
      return;
    }
    if (params) {
      const map = params.map;

      if (map) {
        map.set(target, value);

        return;
      }
    }
    let md = target.userData;

    if (!md && value) {
      md = target.userData = {};
    }
    if (!md) {
      return;
    }
    md.threeObject = value;
  }

  _removeChildren(container) {
    if (!container) {
      return;
    }
    const children = container.children;

    if (!children) {
      return;
    }
    const l = children.length;

    if (l === 0) {
      return;
    }
    for (let i = l - 1; i >= 0; --i) {
      container.remove(children[i]);
    }
  }

  convertGeometry(geom, params = null, result = null) {
    if (!geom || !(geom instanceof Geometry)) {
      return null;
    }

    let res = result;

    if (!res) {
      res = this._findCachedThreeObject(geom, params);
    }

    res = this.convertGeometryToBufferGeometry(geom, params, res);

    this._storeCachedThreeObject(geom, res, params);

    return res;
  }

  // Called whenever a geometry is converted
  _onGeometry(geom, params, res) {
    if (params) {

      if (this.onGeometry) {
        this.onGeometry(geom, res, params);
      }

      // Call callback in params
      if (params.onGeometry) {
        params.onGeometry(geom, res, params);
      }
    }
  }

  convertGeometryToBufferGeometry(geom, params, result) {
    if (!geom || !(geom instanceof Geometry)) {
      return result;
    }

    let res = result;

    if (!res) {
      res = this._findCachedThreeObject(geom, params);
    }

    let useIndices = true;

    if (params) {
      useIndices = params.useIndices !== false;
      if (res !== null && typeof (res) !== 'undefined') {
        if (params.shouldConvertGeometry && params.shouldConvertGeometry(geom, params, res) === false) {
          this._onGeometry(geom, params, res);

          return res;
        }
        if (this.shouldConvertGeometry && this.shouldConvertGeometry(geom, params, res) === false) {
          this._onGeometry(geom, params, res);

          return res;
        }
      }
    }

    res = this._convertPolysToBufferGeometry(geom.polygons, geom.polygonVertices, useIndices, params, res);

    this._storeCachedThreeObject(geom, res, params);

    this._onGeometry(geom, params, res);

    return res;
  }

  shouldConvertGeometry(geom, params, res) {
    if (!geom && !res) {

      return false;
    }

    return true;
  }

  _isStandardAttributeName(name) {
    if (!name) {
      return false;
    }

    return name === 'position' || name === 'uv' || name === 'normal' || name === 'tangent' || name === 'bitangent';
  }

  _addExtraVertexAttributeData(attName, att, vertex, arrays, params = null) {
    if (att && attName && !this._isStandardAttributeName(attName)) {
      const vAtts = vertex.attributes;

      let itemSize = att.itemSize;
      const arr = arrays[attName];
      const def = att.defaultValue;

      const flipY = params ? params.flipY === true : false;

      if (arr) {
        const vAtt = vAtts[attName];

        if (typeof (itemSize) !== 'number' || isNaN(itemSize)) {
          itemSize = def ? def.length : 0;
        }

        for (let i = 0; i < itemSize; ++i) {
          if (vAtt) {
            let v = vAtt.getCoord(i);

            v = (i === 1 && flipY) ? 1.0 - v : v;
            arr.push(v);
          } else {
            let v = def ? def[i] : 0;

            v = (typeof (v) === 'number' && !isNaN(v)) ? v : 0;
            arr.push(v);
          }
        }
      }
    }
  }

  // Add custom attributes from params
  _addExtraPolyVertexData(vertex, arrays, params = null) {
    const extraAtts = params.geometryAttributes;

    if ((extraAtts instanceof Array) || (Array.isArray && Array.isArray(extraAtts))) {
      const num = extraAtts.length;

      for (let i = 0; i < num; ++i) {
        const att = extraAtts[i];
        const attName = att ? att.name : null;

        this._addExtraVertexAttributeData(attName, att, vertex, arrays, params);
      }
    } else {
      for (const v in extraAtts) {
        if (extraAtts.hasOwnProperty(v)) {
          this._addExtraVertexAttributeData(v, extraAtts[v], vertex, arrays, params);
        }
      }
    }
  }

  _addOtherVertexAttribs(arrays, atts) {
    for (const v in atts) {
      if (atts.hasOwnProperty(v) && !this._isStandardAttributeName(v) && arrays[v]) {
        const arr = arrays[v];
        const att = atts[v];

        if (att) {
          const itemSize = att.getDimension();

          for (let i = 0; i < itemSize; ++i) {
            arr.push(att.getCoord(i));
          }
        }
      }
    }
  }

  _addPolyVertexData(vertex, arrays, params = null) {
    arrays.position.push(vertex.getCoord(0), vertex.getCoord(1), vertex.getCoord(2));
    const atts = vertex.attributes;
    const WCOORD = 3;

    if (atts) {
      let flipV = false;

      if (params) {
        flipV = params.flipV === true;

        if (params.geometryAttributes) {
          this._addExtraPolyVertexData(vertex, arrays, params);
        }
      }

      // Add other coords defined in the vertex attibutes
      this._addOtherVertexAttribs(arrays, atts);

      if (arrays.uv) {
        if (atts.uv) {
          let v = atts.uv.getCoord(1);

          if (flipV) {
            v = 1 - v;
          }
          arrays.uv.push(atts.uv.getCoord(0), v);
        } else {
          arrays.uv.push(0, flipV ? 1 : 0);
        }
      }
      if (arrays.normal) {
        if (atts.normal) {
          arrays.normal.push(atts.normal.getCoord(0), atts.normal.getCoord(1), atts.normal.getCoord(2));
        } else {
          arrays.normal.push(0, 0, 1);
        }
      }
      if (arrays.tangent) {
        if (atts.tangent) {
          arrays.tangent.push(atts.tangent.getCoord(0), atts.tangent.getCoord(1), atts.tangent.getCoord(2), atts.tangent.getCoord(WCOORD));
        } else {
          arrays.tangent.push(1, 0, 0, 1);
        }
      }
      if (arrays.bitangent) {
        if (atts.bitangent) {
          arrays.bitangent.push(atts.bitangent.getCoord(0), atts.bitangent.getCoord(1), atts.bitangent.getCoord(2));
        } else {
          arrays.bitangent.push(0, 1, 0);
        }
      }
    }
  }

  _addPolyVertex(vertex, indices, arrays, vertexMap, vertexCounter, maxIndex, params) {
    if (indices) {
      let index = vertexMap.get(vertex);

      if (index === null || index === undefined || index < 0) {
        index = vertexCounter[0];
        if (maxIndex > 0 && index > maxIndex) {
          return false;
        }
        vertexCounter[0] = index + 1;
        vertexMap.set(vertex, index);


        this._addPolyVertexData(vertex, arrays, params);
      }
      indices.push(index);
    } else {
      this._addPolyVertexData(vertex, arrays, params);
    }

    return true;
  }

  _getErrorObject(name) {
    if (!name) {
      return null;
    }
    let errors = this._errors;

    if (!errors) {
      errors = this._errors = {
        indexOverflow: {}
      };
    }

    return errors[name];

  }
  _addPolyData(poly, indices, arrays, useIndices, polyVertexMap, polyVertCounter, maxIndex, params) {
    const polyVerts = poly.vertices;
    const numPolyVerts = polyVerts.length;

    if (numPolyVerts > 2) {

      const endIndex = numPolyVerts - 1;

      for (let j = 1; j < endIndex; ++j) {
        const resV1 = this._addPolyVertex(polyVerts[0], indices, arrays, polyVertexMap, polyVertCounter, maxIndex, params);
        const resV2 = this._addPolyVertex(polyVerts[j], indices, arrays, polyVertexMap, polyVertCounter, maxIndex, params);
        const resV3 = this._addPolyVertex(polyVerts[j + 1], indices, arrays, polyVertexMap, polyVertCounter, maxIndex, params);

        if ((!resV1 || !resV2 || !resV3) && useIndices) {
          if (polyVertCounter[0] > maxIndex) {
            return this._getErrorObject('indexOverflow');
          }
        }
      }
    }

    return null;
  }

  // TODO: check for different buffer lengths -> dispose
  /*
    Add extra geometry attributes:
      Using an array:
        params.geometryAttributes = [
          {name: 'morphTarget', itemSize: 3},
          {name: 'secondUV', itemSize: 2, flipY: true/false (true => 1 - y), defaultValue: [0, 0]},
          ...
        ]
      Using an object
        params.geometryAttributes = {
          morphTarget: {itemSize: 3},
          secondUV: {itemSize: 2, flipY: true/false},
          ...
        }

  */
  _convertPolysToBufferGeometry(polys, polygonVertices, useIndices, params, res) {
    if (!polys) {
      return null;
    }
    const numpolys = polys.length;

    if (numpolys === 0) {
      return null;
    }
    const _3 = 3;
    let bg = res;

    if (res) {
      // quickfix (always dispose old geometry)
      // TODO: only dispose if geometry is changed
      res.dispose();
      bg = null;
    }

    if (!bg) {
      bg = new THREE.BufferGeometry();
    }
    // let useIndices = true;

    let includeTangents = true;
    let includeBitangents = true;
    let uvName = null;
    let tgtName = null;
    let btgName = null;
    let IndexArrayClass = null;

    if (params) {
      // useIndices = params.useIndices !== false;
      includeTangents = params.includeTangents !== false;
      includeBitangents = params.includeBitangents !== false;
    }
    if (!uvName) {
      uvName = 'uv';
    }
    if (!tgtName) {
      tgtName = 'tangent';
    }
    if (!btgName) {
      btgName = 'bitangent';
    }
    const itemSizes = ITEM_SIZES;

    const arrays = {
      position: [],
      uv: [],
      normal: []
    };

    const indices = useIndices ? [] : null;

    if (useIndices) {
      if (params) {
        IndexArrayClass = params.indexArrayClass;
      }
    }
    if (!IndexArrayClass) {
      IndexArrayClass = Uint16Array;
    }
    let maxIndex = -1;

    if (IndexArrayClass === Uint16Array) {
      const MAX_16_BIT_VALUE = 65536;

      maxIndex = MAX_16_BIT_VALUE;
    }

    if (includeTangents) {
      arrays.tangent = [];
    }
    if (includeBitangents) {
      arrays.bitangent = [];
    }
    let polyVertexMap;
    let polyVertCounter;

    if (useIndices) {
      polyVertexMap = new WeakMap();
      polyVertCounter = [0];
    }

    let extraAttributeSizes = null;
    let extraAttributes = null;

    const firstVertexAtts = polys[0] && polys[0].vertices && polys[0].vertices[0] ? polys[0].vertices[0].attributes : null;

    if (firstVertexAtts) {
      for (const v in firstVertexAtts) {
        if (!this._isStandardAttributeName(v)) {
          if (!extraAttributeSizes) {
            extraAttributeSizes = {};
          }
          const vec = firstVertexAtts[v];

          if (vec) {
            arrays[v] = [];
            extraAttributeSizes[v] = vec.getDimension();
          }
        }
      }
    }

    if (params && params.geometryAttributes) {
      if (!extraAttributeSizes) {
        extraAttributeSizes = {};
      }
      extraAttributes = params.geometryAttributes;
      if ((extraAttributes instanceof Array) || (Array.isArray && Array.isArray(extraAttributes))) {
        const num = extraAttributes.length;

        for (let i = 0; i < num; ++i) {
          const att = extraAttributes[i];

          if (att && att.name && !arrays[name]) {
            const name = att.name;

            arrays[name] = [];
            extraAttributeSizes[name] = att.itemSize;
          }
        }
      } else {
        for (const v in extraAttributes) {
          if (extraAttributes.hasOwnProperty(v) && !arrays[v]) {
            const val = extraAttributes[v];
            const itemSize = (typeof (val) === 'number') ? (val) : (val.itemSize || val.size);

            arrays[v] = [];
            extraAttributeSizes[v] = itemSize;
          }
        }
      }
    }

    for (let i = 0; i < numpolys; ++i) {
      const poly = polys[i];

      if (poly) {
        const addPolyRes = this._addPolyData(poly, indices, arrays, useIndices, polyVertexMap, polyVertCounter, maxIndex, params);

        if (useIndices && addPolyRes === this._getErrorObject('indexOverflow')) {
          // Index overflow! Create buffergeometry without indices :(
          return this._convertPolysToBufferGeometry(polys, polygonVertices, false, params, res);
        }
      }
    }
    let v;
    let buffAtt;
    let arr, f32Arr, uArr;
    let buffAttNeedsUpdate;

    let shouldDispose = false;

    for (v in arrays) {
      if (arrays.hasOwnProperty(v)) {
        arr = arrays[v];
        f32Arr = null;
        buffAttNeedsUpdate = true;

        buffAtt = bg.attributes[v];

        if (buffAtt) {
          f32Arr = buffAtt.array;
          buffAttNeedsUpdate = this._checkBufferAttributeNeedsUpdate(buffAtt, arr, params);
        }

        if (buffAttNeedsUpdate) {
          f32Arr = toFloat32ArrayValues(arr, f32Arr);

          if (!buffAtt || !buffAtt.array || f32Arr.length !== buffAtt.array.length) {
            shouldDispose = true;
          }
          if (buffAtt) {
            buffAtt.array = f32Arr;
            buffAtt.needsUpdate = true;
          }
        }

        if (!buffAtt) {
          let itemSize = itemSizes[v];

          if ((typeof (itemSize) !== 'number' || isNaN(itemSize)) && extraAttributeSizes) {
            itemSize = extraAttributeSizes[v];
          }
          // #if DEBUG
          if (typeof (itemSize) !== 'number' || isNaN(itemSize)) {
            console.warn('Invalid item size', itemSize);
          }
          // #endif
          buffAtt = new THREE.BufferAttribute(f32Arr, itemSize, false);
          bg.addAttribute(v, buffAtt);
        }
      }
    }
    if (useIndices) {
      buffAtt = null;
      uArr = null;
      buffAttNeedsUpdate = true;

      if (bg.getIndex) {
        buffAtt = bg.getIndex();
      } else {
        buffAtt = bg.attributes.index;
      }

      if (buffAtt) {
        uArr = buffAtt.array;
        buffAttNeedsUpdate = this._checkBufferAttributeNeedsUpdate(buffAtt, indices, params);
      }

      if (buffAttNeedsUpdate) {
        uArr = toArrayViewValues(indices, IndexArrayClass, uArr);
        if (buffAtt) {
          buffAtt.needsUpdate = true;
        }
      }

      if (!buffAtt) {
        if (bg.setIndex) {
          buffAtt = new THREE.BufferAttribute(uArr, 1, false);
          bg.setIndex(buffAtt);
        } else {
          buffAtt = new THREE.BufferAttribute(uArr, _3, false);
          bg.addAttribute('index', buffAtt);
        }
      }
      if (params) {
        if (params.onCreatedBufferAttribute) {
          params.onBufferAttribute(buffAtt, params, buffAttNeedsUpdate);
        }
      }
    }

    if (shouldDispose && bg === res) {
      if (res) {
        /* eslint-disable */
        debugger;
        /* eslint-enable */
        res.dispose();
      }
      bg.dispose();
    }

    return bg;
  }

  convertGeometryNode3D(geomNode, params = null, result = null) {
    if (!geomNode || !(geomNode instanceof GeometryNode3D)) {
      return null;
    }
    let res = result;

    if (!res) {
      res = this._findCachedThreeObject(geomNode, params);
    }
    const srcGeom = geomNode.getGeometry ? geomNode.getGeometry() : geomNode.geometry;
    const geom = this.convertGeometry(srcGeom, params);

    if (!geom) {
      if (res) {
        res.geometry = null;
      }

      return null;
    }

    if (!res) {
      res = new THREE.Mesh();
    }
    this._storeCachedThreeObject(geomNode, res, params);


    res.geometry = geom;

    this._convertedNode(geomNode, params, res);

    if (this.onGeometryNode3D) {
      this.onGeometryNode3D(geomNode, res, params);
    }
    if (this.onGeometryNode) {
      this.onGeometryNode(geomNode, res, params);
    }

    if (params) {
      if (params.onGeometryNode) {
        params.onGeometryNode(geomNode, res, params);
      }
      if (params.onGeometryNode3D) {
        params.onGeometryNode3D(geomNode, res, params);
      }
    }

    return res;
  }

  convertContainerNode3D(node, params = null, result = null) {
    if (!node || !(node instanceof ContainerNode3D)) {
      return null;
    }
    let res = result;

    if (!res) {
      res = this._findCachedThreeObject(node, params);
    }
    if (!res) {
      if (THREE.Group) {
        res = new THREE.Group();
      } else {
        res = new THREE.Object3D();
      }
    }
    this._storeCachedThreeObject(node, res, params);

    this._removeChildren(res);

    const children = node.children;

    if (!children) {
      return res;
    }
    const num = children.length;

    if (num === 0) {
      return res;
    }

    for (let i = 0; i < num; ++i) {
      const child = this.convertNode3D(children[i], params);

      if (child) {
        res.add(child);
      }
    }

    this._convertedNode(node, params, res);

    if (params) {
      if (params.onContainerNode) {
        params.onContainerNode(node, res, params);
      }
      if (params.onContainerNode3D) {
        params.onContainerNode3D(node, res, params);
      }
    }

    return res;
  }

  convertNode3D(node, params = null, result = null) {
    if (!(node instanceof Node3D)) {
      return result;
    }
    let res = result;

    if (node instanceof ContainerNode3D) {
      res = this.convertContainerNode3D(node, params, res);
    } else if (node instanceof GeometryNode3D) {
      res = this.convertGeometryNode3D(node, params, res);
    }

    return res;
  }

  _convertedNode(node, params, res) {
    this._applyTransform3D(res, node.transform);

    if (params) {
      if (params.onNode) {
        params.onNode(node, res, params);
      }
      if (params.onNode3D) {
        params.onNode3D(node, res, params);
      }
    }
  }

  _toThreeRotationOrder(order) {
    return order;
  }

  _applyTransform3D(threeObject3D, transform3D) {
    let tr3D = transform3D;

    if (tr3D instanceof Node3D) {
      tr3D = tr3D.transform;
    }

    if (!threeObject3D || !tr3D) {
      return;
    }
    if (!(tr3D instanceof Transform3D)) {
      return;
    }
    if (tr3D instanceof SRTTransform3D) {
      const pos = tr3D.position;
      const rot = tr3D.rotation;
      const rotOrder = tr3D.rotationOrder;
      const scale = tr3D.scale;

      if (pos) {
        threeObject3D.position.set(pos.getX(), pos.getY(), pos.getZ());
      }
      if (rot) {
        threeObject3D.rotation.set(rot.getX(), rot.getY(), rot.getZ(), this._toThreeRotationOrder(rotOrder));
      }
      if (scale) {
        threeObject3D.scale.set(scale.getX(), scale.getY(), scale.getZ());
      }
    } else {
      // Set matrix manually
      threeObject3D.matrixAutoUpdate = false;
      if (!threeObject3D.matrix) {
        threeObject3D.matrix = new THREE.Matrix4();
      }
      tr3D.applyMatrix4(threeObject3D.matrix.elements);
    }
  }

  convert(value, params = null, result = null) {
    if (!value) {
      return value;
    }

    if (value instanceof Node3D) {
      return this.convertNode3D(value, params, result);
    } else if (value instanceof Geometry) {
      return this.convertGeometry(value, params, result);
    }

    return null;
  }
}
