import DOMElementController from './DOMElementController';

export default class WheelController extends DOMElementController {
  constructor(element, enabled = true) {
    super(element, enabled);
  }

  _preventNativeWheelEvent(evt) {
    return this._preventNativeEvent(evt);
  }

  _setElementEnabled(elem, e = true) {
    if (!elem) {
      return;
    }

    let wheelHandler = this._wheelHandler;

    if (e) {
      const that = this;

      if (!wheelHandler) {
        wheelHandler = this._wheelHandler = evt => {
          that._handleWheel(evt);
        };
      }
      elem.addEventListener('DOMMouseScroll', wheelHandler);
      elem.addEventListener('mousewheel', wheelHandler);
      elem.addEventListener('onmousewheel', wheelHandler);
    } else if (wheelHandler) {
      elem.removeEventListener('DOMMouseScroll', wheelHandler);
      elem.removeEventListener('mousewheel', wheelHandler);
      elem.removeEventListener('onmousewheel', wheelHandler);
    }
  }

  _getWheelDeltaY(event) {
    return WheelController.getWheelDeltaY(event);
  }

  static getWheelDeltaY(event) {
    if (!event) {
      return 0;
    }
    let wd = event.wheelDeltaY;

    if (typeof (wd) === 'undefined' || wd === null) {
      wd = event.wheelDelta;
    }
    if (typeof (wd) === 'undefined' || wd === null) {
      wd = event.detail;
      if (wd !== null && typeof (wd) !== 'undefined') {
        const detailMultiplier = 120;

        wd = -wd * detailMultiplier;
      }
    }

    return wd;
  }

  _handleWheel(event) {
    const dy = this._getWheelDeltaY(event);
    let eventObject = this._wheelEvent;

    if (!eventObject) {
      eventObject = this._wheelEvent = {};
    }
    eventObject.type = 'wheel';
    eventObject.deltaY = dy;
    this.dispatchEvent(eventObject);

    return this._preventNativeWheelEvent(event);
  }


  dispose() {
    super.dispose();
    this._wheelHandler = null;
  }
}
