import MattressDA from '../mattress/MattressDA';
import Matrix4 from '../../bgr/bgr3d/geom/Matrix4';

function dCloseTo(d, negToll, posToll) {
  const res = d <= posToll && d >= -negToll;

  return res;
}

function closeTo(value, other, negToll, posToll) {
  const d = value - other;

  return dCloseTo(d, negToll, posToll);
}

const DIV4 = 0.25;
const PI05 = Math.PI * 0.5;
const PI025 = Math.PI * DIV4;
const SIDE_LEFT = 'side_left';
const SIDE_RIGHT = 'side_right';
const SIDE_FRONT = 'side_front';
const SIDE_BACK = 'side_back';

const EDGE_LEFT = 'edge_left';
const EDGE_BACK = 'edge_back';
const EDGE_RIGHT = 'edge_right';
const EDGE_FRONT = 'edge_front';

const EDGE_BACK_LEFT = 'edge_back_left';
const EDGE_BACK_RIGHT = 'edge_back_right';
const EDGE_FRONT_LEFT = 'edge_front_left';
const EDGE_FRONT_RIGHT = 'edge_front_right';

const EdgeTypes = {
  LEFT: EDGE_LEFT,
  BACK: EDGE_BACK,
  RIGHT: EDGE_RIGHT,
  FRONT: EDGE_FRONT,

  BACK_LEFT: EDGE_BACK_LEFT,
  BACK_RIGHT: EDGE_BACK_RIGHT,
  FRONT_LEFT: EDGE_FRONT_LEFT,
  FRONT_RIGHT: EDGE_FRONT_RIGHT
};

const SideTypes = {
  LEFT: SIDE_LEFT,
  BACK: SIDE_BACK,
  RIGHT: SIDE_RIGHT,
  FRONT: SIDE_FRONT
};

/**
* @class BorderContourUtil
* @description Util to get information about any position of the mattress border
*/
export default class BorderContourUtil {
  /**
   * @method getClosestBorderContourInfoOfMattress
   * @static
   * @description Same as getClosestBorderContourInfoOfMattress, but this method
   * uses the data object of a single to get its properties
   * @param {Number} x - The x-position
   * @param {Number} y - The y-position
   * @param {Number} z - The z-position
   * @param {Object} single - Data object of a single matress
   * @param {Object} params - Optional params object
   * @param {Object} result - Optional preallocated result object
   * @returns {Object} result
   **/
  static getClosestBorderContourInfoOfMattress(x, y, z, single, params = null, result = null) {
    const res = result;

    if (res) {
      res.valid = false;
    }

    if (!single) {
      return res;
    }
    const box = single.box;

    if (!box) {
      return res;
    }
    const size = box.size;

    if (!size) {
      return res;
    }
    const radius = box.radius;

    if (!radius) {
      return res;
    }

    const width = size.width;
    const length = size.length;
    const cornerRadius = MattressDA.getResultCornerRadius(single);

    return this.getClosestBorderContourInfo(x, y, z, width, length, cornerRadius, params, res);
  }

  static getBorderContourInfoOfMattressAtLength(position, single, params, result) {
    const res = result;

    if (res) {
      res.valid = false;
    }

    if (!single) {
      return res;
    }
    const box = single.box;

    if (!box) {
      return res;
    }
    const size = box.size;

    if (!size) {
      return res;
    }
    const radius = box.radius;

    if (!radius) {
      return res;
    }

    const width = size.width;
    const length = size.length;
    const cornerRadius = MattressDA.getResultCornerRadius(single);

    return this.getBorderContourInfoAtLength(position, width, length, cornerRadius, params, res);
  }

  static getBorderContourInfoAtLength(position, width, length, cornerRadius, params, result) {
    let cR = cornerRadius;
    let res = result;

    const hW = width * 0.5;
    const hL = length * 0.5;
    const hSize = hW < hL ? hW : hL;
    let pos = position;

    cR = cR < hSize ? cR : hSize;

    const innerW = width - cR - cR;
    const innerL = length - cR - cR;
    const cornerLen = cornerRadius * Math.PI * 0.5;
    const numCorners = 4;
    const totalLen = cornerLen * numCorners + innerW * 2 + innerL * 2;

    pos %= totalLen;
    if (pos < 0) {
      pos += totalLen;
    }

    const p1 = innerL;
    const p2 = p1 + cornerLen;
    const p3 = p2 + innerW;
    const p4 = p3 + cornerLen;
    const p5 = p4 + innerL;
    const p6 = p5 + cornerLen;
    const p7 = p6 + innerW;
    const p8 = p7 + cornerLen;

    if (!res) {
      res = {};
    }
    let valid = false;
    let x = 0;
    const y = 0;
    let z = 0;

    let normalX = 0;
    const normalY = 0;
    let normalZ = 0;

    let side = null;
    let edge = null;
    let angle = 0;

    let sideLength = 0;
    let sideTotalLength = 0;

    const hcL = cornerLen * 0.5; // half corner length

    let onCorner = false;

    if (pos < p1) {
      valid = true;
      x = -hW;
      z = -hL + pos + cR;
      side = SIDE_LEFT;
      edge = EDGE_LEFT;
      angle = Math.PI;
      sideLength = pos + hcL;
      sideTotalLength = innerL + cornerLen;
      normalX = -1;
    } else if (pos < p2) {
      valid = true;
      const rpos = pos - p1;
      const ang = rpos / cR;
      const cosine = Math.cos(ang);
      const sine = Math.sin(ang);

      normalX = -cosine;
      normalZ = sine;

      x = -hW + cR - cosine * cR;
      z = hL - cR + sine * cR;
      side = rpos < hcL ? SIDE_LEFT : SIDE_BACK;
      edge = EDGE_BACK_LEFT;
      onCorner = true;
      if (side === SIDE_LEFT) {
        sideTotalLength = innerL + cornerLen;
        sideLength = hcL + innerL + rpos;
      } else {
        sideTotalLength = innerW + cornerLen;
        sideLength = rpos - hcL;
      }
      angle = ang + Math.PI;
    } else if (pos < p3) {
      const deg270 = 1.5;
      const rpos = pos - p2;

      angle = Math.PI * deg270;

      normalZ = 1;

      valid = true;
      x = -hW + cR + rpos;
      z = hL;
      side = SIDE_BACK;
      edge = EDGE_BACK;
      sideTotalLength = innerW + cornerLen;
      sideLength = rpos + hcL;
    } else if (pos < p4) {
      valid = true;
      const rpos = pos - p3;
      const ang = (rpos / cR);
      const posAng = PI05 - ang;
      const deg270 = 1.5;
      const cosine = Math.cos(posAng);
      const sine = Math.sin(posAng);

      normalX = cosine;
      normalZ = sine;

      x = hW - cR + cosine * cR;
      z = hL - cR + sine * cR;
      angle = Math.PI * deg270 + ang;
      edge = EDGE_BACK_RIGHT;
      onCorner = true;
      side = rpos < hcL ? SIDE_BACK : SIDE_RIGHT;
      if (side === SIDE_BACK) {
        sideTotalLength = innerW + cornerLen;
        sideLength = hcL + innerW + rpos;
      } else {
        sideTotalLength = innerL + cornerLen;
        sideLength = rpos - hcL;
      }
    } else if (pos < p5) {
      const rpos = pos - p4;

      valid = true;
      side = SIDE_RIGHT;
      edge = EDGE_RIGHT;
      angle = 0;

      normalX = 1;
      x = hW;
      z = hL - cR - rpos;
      sideTotalLength = innerL + cornerLen;
      sideLength = rpos + hcL;
    } else if (pos < p6) {
      const rpos = pos - p5;
      const ang = rpos / cR;
      const sine = Math.sin(ang);
      const cosine = Math.cos(ang);

      valid = true;
      normalX = cosine;
      normalZ = -sine;

      side = rpos < hcL ? SIDE_RIGHT : SIDE_FRONT;
      if (side === SIDE_RIGHT) {
        sideTotalLength = innerL + cornerLen;
        sideLength = rpos + innerL + hcL;
      } else {
        sideLength = rpos - hcL;
        sideTotalLength = innerW + cornerLen;
      }
      edge = EDGE_FRONT_RIGHT;
      onCorner = true;
      angle = ang;
      x = hW - cR + cosine * cR;
      z = -hL + cR - sine * cR;
    } else if (pos < p7) {
      valid = true;
      const rpos = pos - p6;

      normalZ = -1;

      x = hW - cR - rpos;
      z = -hL;
      angle = PI05;
      edge = EDGE_FRONT;
      side = SIDE_FRONT;
      sideTotalLength = innerW + cornerLen;
      sideLength = rpos + hcL;
    } else if (pos < p8) {
      valid = true;
      const rpos = pos - p7;
      const ang = (rpos / cR) + PI05;
      const cosine = Math.cos(ang);
      const sine = Math.sin(ang);

      normalX = cosine;
      normalZ = -sine;

      edge = EDGE_FRONT_LEFT;
      onCorner = true;
      side = rpos < hcL ? SIDE_FRONT : SIDE_LEFT;

      if (side === SIDE_FRONT) {
        sideLength = hcL + innerW + rpos;
        sideTotalLength = innerW + cornerLen;
      } else {
        sideLength = rpos - hcL;
        sideTotalLength = innerL + cornerLen;
      }

      x = -hW + cR + cosine * cR;
      z = -hL + cR - sine * cR;
      angle = ang;
    }
    res.valid = valid;
    res.x = x;
    res.y = y;
    res.z = z;
    res.normalX = normalX;
    res.normalY = normalY;
    res.normalZ = normalZ;
    res.side = side;
    res.edge = edge;
    res.onCorner = onCorner;
    res.length = pos;
    res.totalLength = totalLen;
    res.angle = angle;
    res.sideLength = sideLength;
    res.sideTotalLength = sideTotalLength;

    if (valid) {
      this._updateMatrix4(res);
    }

    return res;
  }

  static _updateMatrix4(res) {
    if (!res) {
      return;
    }
    const nX = res.normalX;
    const nZ = res.normalZ;
    const x = res.x;
    const y = res.y;
    const z = res.z;
    let m = res.matrix4;

    if (!m) {
      m = res.matrix4 = new Matrix4();
    }
    m.setValues(
      nZ, 0, -nX, 0,
      0, 1, 0, 0,
      nX, 0, nZ, 0,
      x, y, z, 1
    );
  }

  /**
   * @method getClosestBorderContourInfo
   * @static
   * @description Returns information about a part of the contour of the mattress based
   * on a given location in 3D space
   * @param {Number} x - The x-position
   * @param {Number} y - The y-position
   * @param {Number} z - The z-position
   * @param {Object} width - width of the mattress
   * @param {Object} length - length of the mattress
   * @param {Object} cornerRadius - Corner radius
   * @param {Object} params - Optional params object - Not used at the moment
   * @param {Object} result - Optional preallocated result object
   * @returns {Object} result contains the following properties:
     x {Number} - the x-coordinate of the closest point on the contour
     y {Number} - the y-coordinate of the closest point on the contour
     z {Number} - the z-coordinate of the closest point on the contour
     normalX {Number} - the x-normal value of the closest point on the contour
     normalY {Number} - the y-normal value of the closest point on the contour
     normalZ {Number} - the z-normal value of the closest point on the contour
     length {Number} - the length (distance) from the beginning at the
      top-left position right below the top-left corner, to the location of the result
     totalLength {Number} - the total length of the contour
     sideLength {Number} - the length (distance) from the beginning of the side where the result is located
     sideTotalLength {Number} - the total length of the side where the result is located
     side {String} - the id/name of the side (left, right, front, back)
     edge {String} - the id/name of the edge (edge_left, edge_front_left, edge_front, ...)
     angle {Number} - Angle of the normal of the closest point on the border contour as seen in a top view
     valid {Boolean} - true if a valid location on the border contour is found
   **/
  static getClosestBorderContourInfo(x, y, z, width, length, cornerRadius, params = null, result = null) {
    let res = result;

    if (res) {
      res.valid = false;
    }

    if (!res) {
      res = {};
    }
    let distToll = 10;
    let negDistToll = 10;

    if (params) {
      if (params.positiveTollerance !== null && typeof (params.positiveTollerance) !== 'undefined') {
        distToll = params.positiveTollerance;
      } else if (params.tollerance !== null && typeof (params.tollerance) !== 'undefined') {
        distToll = params.tollerance;
      }

      if (params.negativeTollerance !== null && typeof (params.negativeTollerance) !== 'undefined') {
        negDistToll = params.negativeTollerance;
      } else if (params.tollerance !== null && typeof (params.tollerance) !== 'undefined') {
        negDistToll = params.tollerance;
      }
    }

    const _3 = 3;
    const _4 = 4;

    const hw = width * 0.5;
    const hl = length * 0.5;

    const innerW = width - cornerRadius - cornerRadius;
    const innerL = length - cornerRadius - cornerRadius;
    const hInnerW = innerW * 0.5;
    const hInnerL = innerL * 0.5;

    const cornerLen = cornerRadius * PI05;
    const totalLen = innerL * 2 + innerW * 2 + cornerLen * _4;

    const dLeft = x + hw;
    const dRight = hw - x;
    const dFront = -hl - z;
    const dBack = hl - z;

    const absDLeft = dLeft < 0 ? -dLeft : dLeft;
    const absDRight = dRight < 0 ? -dRight : dRight;
    const absDFront = dFront < 0 ? -dFront : dFront;
    const absDBack = dBack < 0 ? -dBack : dBack;

    const xSide = absDLeft < absDRight ? SIDE_LEFT : SIDE_RIGHT;
    const zSide = absDFront < absDBack ? SIDE_FRONT : SIDE_BACK;

    let side = null;
    let edge = null;

    const xInside = x <= hInnerW && x >= -hInnerW;
    const zInside = z <= hInnerL && z >= -hInnerL;

    let resX = 0;
    const resY = y;
    let resZ = 0;

    // len = The distance from the beginning of the contour
    // (from the front-left location below the corner radius to the back-left, back-right, front-right and back to front-left)
    let len = 0;

    // sideLen = The distance from the beginning of the side (left, back, right or front)
    let sideLen = 0;
    let sideTotalLen = 0;
    let found = false;
    let angle = 0;
    let normalX = 0;
    const normalY = 0;
    let normalZ = 0;

    // Calculate the length of the left and right edge (vertical straight part + front and back half corner curves)
    const vEdgeLength = innerL + cornerLen;
    // Calculate the length of the front and back edge (horizontal straight part + left and right half corner curves)
    const hEdgeLength = innerW + cornerLen;

    if (!found && xSide === SIDE_LEFT) {
      if (zInside) {
        if (dCloseTo(dLeft, distToll, negDistToll)) {
          resX = -hw;
          resZ = z;
          // len = z - (-hl + cornerRadius);
          len = z + hl - cornerRadius;
          found = true;
          edge = EDGE_LEFT;
          side = SIDE_LEFT;
          angle = Math.PI;
          normalX = -1;
        }
      }
    }
    if (!found && xSide === SIDE_RIGHT) {
      if (zInside) {
        if (dCloseTo(dRight, distToll, negDistToll)) {
          resX = hw;
          resZ = z;
          len = ((hl - cornerRadius) - z) + cornerLen * 2 + innerW + innerL;
          found = true;
          edge = EDGE_RIGHT;
          side = SIDE_RIGHT;
          angle = 0;
          normalX = 1;
        }
      }
    }
    if (!found && zSide === SIDE_BACK) {
      if (xInside) {
        if (dCloseTo(dBack, distToll, negDistToll)) {
          resX = x;
          resZ = hl;
          len = innerL + cornerLen + (x + hw - cornerRadius);
          found = true;
          edge = EDGE_BACK;
          side = SIDE_BACK;
          angle = PI05;
          normalZ = 1;
        }
      }
    }
    if (!found && zSide === SIDE_FRONT) {
      if (xInside) {
        if (dCloseTo(dFront, negDistToll, distToll)) {
          resX = x;
          resZ = -hl;
          len = innerL * 2 + innerW + cornerLen * _3 + (hw - x - cornerRadius);
          found = true;
          edge = EDGE_FRONT;
          side = SIDE_FRONT;
          angle = -PI05;
          normalZ = -1;
        }
      }
    }

    let dx, dz, d, cornerX, cornerZ, nx, nz, startAng, onCorner = false;

    if (!found && !xInside && !zInside) {
      if (xSide === SIDE_LEFT && zSide === SIDE_BACK) {
        cornerX = -hw + cornerRadius;
        cornerZ = hl - cornerRadius;
        len += innerL;
        startAng = -Math.PI;
        edge = EDGE_BACK_LEFT;
        onCorner = true;
      } else if (xSide === SIDE_RIGHT && zSide === SIDE_BACK) {
        cornerX = hw - cornerRadius;
        cornerZ = hl - cornerRadius;
        len += innerL + innerW + cornerLen;
        startAng = -PI05;
        edge = EDGE_BACK_RIGHT;
        onCorner = true;
      } else if (xSide === SIDE_LEFT && zSide === SIDE_FRONT) {
        cornerX = -hw + cornerRadius;
        cornerZ = -hl + cornerRadius;
        len += innerL * 2 + cornerLen * _3 + innerW * 2;
        startAng = PI05;
        edge = EDGE_FRONT_LEFT;
        onCorner = true;
      } else if (xSide === SIDE_RIGHT && zSide === SIDE_FRONT) {
        cornerX = hw - cornerRadius;
        cornerZ = -hl + cornerRadius;
        len += innerL * 2 + cornerLen * 2 + innerW;
        startAng = 0;
        edge = EDGE_FRONT_RIGHT;
        onCorner = true;
      }
      dx = x - cornerX;
      dz = z - cornerZ;

      d = Math.sqrt(dx * dx + dz * dz);
      if (closeTo(d, cornerRadius, negDistToll, distToll)) {
        const ang = Math.atan2(dz, dx);

        angle = ang;
        let dAng = (-ang - startAng);

        if (dAng < 0) {
          dAng += Math.PI * 2;
        }
        const halfWay = (dAng > PI025);

        if (edge === EDGE_BACK_LEFT) {
          side = halfWay ? SIDE_BACK : SIDE_LEFT;
        } else if (edge === EDGE_BACK_RIGHT) {
          side = halfWay ? SIDE_RIGHT : SIDE_BACK;
        } else if (edge === EDGE_FRONT_RIGHT) {
          side = halfWay ? SIDE_FRONT : SIDE_RIGHT;
        } else {
          side = halfWay ? SIDE_LEFT : SIDE_FRONT;
        }

        len += dAng * cornerRadius;

        nx = dx;
        nz = dz;
        if (d !== 0) {
          d = 1 / d;
          nx *= d;
          nz *= d;
        }
        normalX = nx;
        normalZ = nz;

        resX = cornerX + nx * cornerRadius;
        resZ = cornerZ + nz * cornerRadius;

        found = true;
      }
    }

    // Calculate length / position at the sides

    // Make it start in the front-left corner halfway the corner circle
    sideLen = len + cornerLen * 0.5;
    sideLen %= totalLen;
    /*

    // Assume we're at the left edge
    sideTotalLen = vEdgeLength;

    // Check if we're at a larger length than the left edge length
    if (sideLen > vEdgeLength) {
      // We're at a larger length than the left edge length
      // We could be located at the back, right or front edge.
      // Assume we're currently at the back edge
      sideLen -= vEdgeLength;
      sideTotalLen = hEdgeLength;
      // Check if we're at a length larger than the left edge and the back edge
      if (sideLen > hEdgeLength) {
        // We're at a length larger than the left edge and the back edge,
        // so we could be located at the right edge or the front edge.
        // Assume we're currently at the right edge
        sideLen -= hEdgeLength;
        sideTotalLen = vEdgeLength;
        // Check if we're at a length larger than the left edge and the back edge and the right edge
        if (sideLen > vEdgeLength) {
          // We're at a length larger than the left edge and the back edge AND the right edge
          // We can only be located at the front edge
          sideLen -= vEdgeLength;
          sideTotalLen = hEdgeLength;
        }
      }
    }
    // */

    switch (side) {
        case SIDE_LEFT:
          sideTotalLen = vEdgeLength;
          break;
        case SIDE_BACK:
          sideLen -= vEdgeLength;
          sideTotalLen = hEdgeLength;
          break;
        case SIDE_RIGHT:
          sideTotalLen = vEdgeLength;
          sideLen -= (vEdgeLength + hEdgeLength);
          break;
        case SIDE_FRONT:
          sideTotalLen = hEdgeLength;
          sideLen -= (vEdgeLength + vEdgeLength + hEdgeLength);
          break;
    }

    res.x = resX;
    res.y = resY;
    res.z = resZ;
    res.normalX = normalX;
    res.normalY = normalY;
    res.normalZ = normalZ;
    res.length = len;
    res.totalLength = totalLen;
    res.sideLength = sideLen;
    res.sideTotalLength = sideTotalLen; // Total side length (left/right or front/back)
    res.side = side;
    res.edge = edge;
    res.onCorner = onCorner;
    res.angle = angle;
    res.valid = found;

    if (found) {
      this._updateMatrix4(res);
    }

    return res;
  }
}

BorderContourUtil.EdgeTypes = EdgeTypes;
BorderContourUtil.SideTypes = SideTypes;
