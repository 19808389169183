export default class MaterialType {
  constructor(name = null) {
    this._name = name;
  }

  getName() {
    return this._name;
  }

  get name() {
    return this.getName();
  }
}
