import Node3D from './Node3D';
import Geometry from '../geom/Geometry';

export default class GeometryNode3D extends Node3D {
  constructor(geometry = null, transform = null) {
    super(transform);
    this.geometry = geometry;
  }

  getGeometry() {
    return this.geometry;
  }

  setGeometry(v) {
    if (v instanceof Geometry) {
      this.geometry = v;
    }
  }
}
