import MattressConfigObjectTypes from './MattressConfigObjectTypes';
import Utils from '../utils/Utils';
import StringUtils from '../utils/StringUtils';
import QuiltDA from '../quilt/QuiltDA';
import MattressDA from './MattressDA';
import MattressConfigProperty from './MattressConfigProperty';

function newPropertyInfo(data) {
  if (!data) {
    return data;
  }

  data[MattressConfigProperty.KIND] = 'property';

  return data;
}

function addControllerPropertyData(prop, data, arr) {
  if (!prop) {
    return arr;
  }
  const res = arr || [];
  const propData = {};

  propData.label = prop.label || prop.name;
  propData.name = prop.name;
  propData.value = prop.value;

  propData.dataType = prop.dataType || 'numeric';

  if (propData.dataType === 'numeric') {
    propData.min = prop.min;
    propData.max = prop.max;
  }

  propData.tags = prop.tags;
  propData.property = newPropertyInfo({propertyName: 'controller_property', controller: data, controllerName: data.name, controllerPropertyName: prop.name});

  res.push(propData);

  return res;
}

class PropertyCollector {
  constructor(name, getProperties) {

    if (name && typeof (name) === 'object') {
      this._name = name.name;
      this._getProperties = name.getProperties;
    } else {
      this._name = name;
      this._getProperties = getProperties;
    }
  }

  getProperties(data, params, recursive, array) {
    const gp = this._getProperties;

    if (!gp) {
      return array;
    }

    return gp(data, params, recursive, array);
  }

  getName() {
    return this._name;
  }

  get name() {
    return this.getName();
  }
}

// This array will contain all property collector instances
const propertyCollectors = [];

{

  function getPropertyAttrib(props, propName, attrib, fallback) {
    if (!props || !propName || !attrib) {
      return fallback;
    }
    const prop = props[propName];

    if (!prop) {
      return fallback;
    }
    const att = prop[attrib];

    if (att === null || typeof (att) === 'undefined') {
      return fallback;
    }
    if (typeof (att) === 'number' && isNaN(att)) {
      return fallback;
    }

    return att;
  }

  function getPropertyNumericAttrib(props, propName, attrib, fallback) {
    const res = getPropertyAttrib(props, propName, attrib, fallback);

    return Utils.parseNumber(res, fallback);
  }

  function initDefProperty(prop, propertyType, data, params) {
    return prop;
  }

  function initPanelProperty(panelType, prop, propertyType, data, params) {
    let tags = prop.tags;

    if (!tags) {
      tags = prop.tags = [];
    }
    const tagName = `${panelType}-panel`;
    const mattressConfig = params ? params.mattressConfig : null;
    const dataInfo = mattressConfig ? mattressConfig._getObjectInfo(data) : null;
    const single = dataInfo ? dataInfo.single : null;

    if (tags.indexOf(tagName, 0) >= 0) {
      tags.push(tagName);
    }
    const propName = `${panelType}_${propertyType}`;

    prop.property = newPropertyInfo({propertyName: propName, single: single});

    return prop;
  }

  function initTopPanelProperty(prop, propertyType, data, params) {
    const res = initPanelProperty('top', prop, propertyType, data, params);

    if (!res) {
      return prop;
    }

    return res;
  }

  function initBottomPanelProperty(prop, propertyType, data, params) {
    const res = initPanelProperty('bottom', prop, propertyType, data, params);

    if (!res) {
      return prop;
    }

    return res;
  }
/*
  function initTopMirrorPanelProperty(prop, params) {

  }

  function initBottomMirrorPanelProperty(prop, params) {

  }

  function initBorderProperty(prop, params) {

  }
*/
  function addFabricProperties(data, prefix, labelPrefix, initPropertyFunc, params, array) {
    const arr = array || {};
    const ipf = initPropertyFunc ? initPropertyFunc : initDefProperty;
    const tagSample = 'sample';
    const tagQuilt = 'quilt';

    arr.push(ipf({
      name: `${prefix}_sample`,
      label: `${labelPrefix} sample`,
      tags: [prefix, tagSample],
      dataType: 'sample'
    }, 'sample', data, params));

    arr.push(ipf({
      name: `${prefix}_sample_angle`,
      label: `${labelPrefix} angle`,
      tags: [prefix, tagSample],
      dataType: 'number',
      value: 0,
      min: 0,
      max: 360,
      step: 90
    }, 'sample_angle', data, params));

    arr.push(ipf({
      name: `${prefix}_sample_xalign`,
      label: `${labelPrefix} x - align`,
      tags: [prefix, tagSample],
      dataType: 'number',
      value: 0
    }, 'sample_xalign', data, params));

    arr.push(ipf({
      name: `${prefix}_sample_yalign`,
      label: `${labelPrefix} y - align`,
      tags: [prefix, tagSample],
      dataType: 'number',
      value: 0
    }, 'sample_yalign', data, params));

    // Quilts
    arr.push(ipf({
      name: `${prefix}_quilt`,
      label: `${labelPrefix} quilt`,
      tags: [prefix, tagQuilt],
      dataType: 'quilt',
      value: 0
    }, 'quilt', data, params));

    arr.push(ipf({
      name: `${prefix}_quilt_angle`,
      label: `${labelPrefix} quilt angle`,
      tags: [prefix, tagQuilt],
      dataType: 'number',
      value: 0,
      min: 0,
      max: 360,
      step: 90
    }, 'quilt_angle', data, params));

    arr.push(ipf({
      name: `${prefix}_quilt_xoffset`,
      label: `${labelPrefix} quilt x - offset`,
      tags: [prefix, tagQuilt],
      dataType: 'number',
      value: 0
    }, 'quilt_xoffset', data, params));

    arr.push(ipf({
      name: `${prefix}_quilt_yoffset`,
      label: `${labelPrefix} quilt y - offset`,
      tags: [prefix, tagQuilt],
      dataType: 'number',
      value: 0
    }, 'quilt_yoffset', data, params));

    arr.push(ipf({
      name: `${prefix}_quilt_repeattype`,
      label: `${labelPrefix} quilt repeat type`,
      tags: [prefix, tagQuilt],
      dataType: 'quiltRepeatType',
      value: 0
    }, 'quilt_repeattype', data, params));

    arr.push(ipf({
      name: `${prefix}_quilt_xrepeat`,
      label: `${labelPrefix} quilt x repeat`,
      tags: [prefix, tagQuilt],
      dataType: 'number',
      value: 0,
      min: 1,
      max: 20,
      step: 1
    }, 'quilt_xrepeat', data, params));

    arr.push(ipf({
      name: `${prefix}_quilt_yrepeat`,
      label: `${labelPrefix} quilt y repeat`,
      tags: [prefix, tagQuilt],
      dataType: 'number',
      value: 0,
      min: 1,
      max: 20,
      step: 1
    }, 'quilt_yrepeat', data, params));

    arr.push(ipf({
      name: `${prefix}_quilt_foam`,
      label: `${labelPrefix} quilt foam`,
      tags: [prefix, tagQuilt],
      dataType: 'number',
      value: 0
    }, 'quilt_foam', params));

    arr.push(ipf({
      name: `${prefix}_quilt_softness`,
      label: `${labelPrefix} quilt softness`,
      tags: [prefix, tagQuilt],
      dataType: 'number',
      value: 0
    }, 'quilt_softness', data, params));

    arr.push(ipf({
      name: `${prefix}_quilt_thickness`,
      label: `${labelPrefix} quilt thickness`,
      tags: [prefix, tagQuilt],
      dataType: 'number',
      min: 0,
      value: QuiltDA.getThickness(data.quilt)
    }, 'quilt_thickness', data, params));

    return arr;
  }

  function getPanelProperties(name, data, params, recursive, array) {
    let arr = array || [];
    const initPropertyFunc = name === 'top' ? initTopPanelProperty : initBottomPanelProperty;

    arr = addFabricProperties(data[name], name, StringUtils.capitalize(name), initPropertyFunc, params, arr);

    return arr;
  }

  const borderComponentPropertyCollector = new PropertyCollector({
    name: MattressConfigObjectTypes.BORDER_COMPONENT.name,
    getProperties(data, params, recursive, array = null) {
      if (!data) {
        return array;
      }
      const type = data.type;

      if (!type) {
        return array;
      }

      return array;
    }
  });

  const borderComponentsPropertyCollector = new PropertyCollector({
    name: MattressConfigObjectTypes.BORDER_COMPONENTS.name,
    getProperties(data, params, recursive, array = null) {
      if (!data) {
        return array;
      }
      let arr = array || [];

      const mattressConfig = params ? params.mattressConfig : null;

      let topPanel = null;
      let bottomPanel = null;
      let single = null;

      if (mattressConfig) {
        const info = mattressConfig._getObjectInfo(data);

        single = info ? info.single : null;

        topPanel = single ? single.top : null;
        bottomPanel = single ? single.bottom : null;
      }
      let oldSingle = null;

      if (params) {
        oldSingle = params.single;
        params.single = single;
      }

      if (topPanel && topPanel.mirrorPanel) {
        // treat top panel as border if a mirror panel is set
        addFabricProperties(topPanel, 'border_fabric_', 'Border fabric', initTopPanelProperty, params, arr);
      }

      if ((data instanceof Array) || (Array.isArray && Array.isArray(data))) {
        const l = data.length;

        for (let i = 0; i < l; ++i) {
          const borderComp = data[i];

          arr = borderComponentPropertyCollector.getProperties(borderComp, params, recursive, arr);
        }
      }

      if (bottomPanel && bottomPanel.mirrorPanel) {
        // treat bottom panel as border if a mirror panel is set
        addFabricProperties(topPanel, 'border_fabric_', 'Border fabric', initBottomPanelProperty, params, arr);
      }


      if (params) {
        params.single = oldSingle;
      }

      return arr;
    }
  });

  const borderPropertyCollector = new PropertyCollector({
    name: MattressConfigObjectTypes.BORDER.name,
    getProperties(data, params, recursive, array = null) {
      if (!data) {
        return array;
      }

      return borderComponentsPropertyCollector.getProperties(data.components, params, recursive, array);
    }
  });

  const topPanelPropertyCollector = new PropertyCollector({
    name: MattressConfigObjectTypes.TOP_PANEL.name,
    getProperties(data, params, recursive, array = null) {
      return getPanelProperties('top', data, params, recursive, array);
    }
  });

  const bottomPanelPropertyCollector = new PropertyCollector({
    name: MattressConfigObjectTypes.BOTTOM_PANEL.name,
    getProperties(data, params, recursive, array = null) {
      return getPanelProperties('bottom', data, params, recursive, array);
    }
  });

  const singlePropertyCollector = new PropertyCollector({
    name: MattressConfigObjectTypes.SINGLE.name,
    getProperties(data, params, recursive, array = null) {
      if (!data) {
        return array;
      }
      let arr = array || [];
      const props = data.properties;
      let propData;

      const DEF_MIN_SIZE = 50;
      const DEF_MAX_SIZE = 220;

      const DEF_MIN_TOPBOTTOMHEIGHT = 1;
      const DEF_MAX_TOPBOTTOMHEIGHT = 35;
      const DEF_MIN_TOPBOTTOMRADIUS = 0;
      const DEF_MAX_TOPBOTTOMRADIUS = 50;
      const DEF_MIN_CORNERRADIUS = 0;
      const DEF_MAX_CORNERRADIUS = 50;

      const dimensionProperties = params.onlyDimensionProperties === true;

      if (!props || (props.width && props.width.visible !== false)) {
        propData = {
          label: 'Width',
          name: 'width',
          tags: ['single', 'dimensions'],
          dataType: 'numeric',
          value: MattressDA.getWidth(data, 0),
          min: getPropertyNumericAttrib(props, 'width', 'min', DEF_MIN_SIZE),
          max: getPropertyNumericAttrib(props, 'width', 'max', DEF_MAX_SIZE),
          property: newPropertyInfo({name: 'single_width', single: data})
        };

        arr.push(propData);
      }
      if (!props || (props.length && props.length.visible !== false)) {
        propData = {
          label: 'Length',
          name: 'length',
          category: 'dimensions',
          dataType: 'numeric',
          value: MattressDA.getLength(data, 0),
          min: getPropertyNumericAttrib(props, 'length', 'min', DEF_MIN_SIZE),
          max: getPropertyNumericAttrib(props, 'length', 'max', DEF_MAX_SIZE),
          property: newPropertyInfo({name: 'single_length', single: data})
        };
        arr.push(propData);
      }
      if (!props || (props.topHeight && props.topHeight.visible !== false)) {
        propData = {
          label: 'Top height',
          name: 'top_height',
          category: 'dimensions',
          dataType: 'numeric',
          value: MattressDA.getTopHeight(data, 0),
          min: getPropertyNumericAttrib(props, 'topHeight', 'min', DEF_MIN_TOPBOTTOMHEIGHT),
          max: getPropertyNumericAttrib(props, 'topHeight', 'max', DEF_MAX_TOPBOTTOMHEIGHT),
          property: newPropertyInfo({name: 'single_topheight', single: data})
        };
        arr.push(propData);
      }
      if (!props || (props.bottomHeight && props.bottomHeight.visible !== false)) {
        propData = {
          label: 'Bottom height',
          name: 'bottom_height',
          category: 'dimensions',
          dataType: 'numeric',
          value: MattressDA.getBottomHeight(data, 0),
          min: getPropertyNumericAttrib(props, 'bottomHeight', 'min', DEF_MIN_TOPBOTTOMHEIGHT),
          max: getPropertyNumericAttrib(props, 'bottomHeight', 'max', DEF_MAX_TOPBOTTOMHEIGHT),
          property: newPropertyInfo({name: 'single_bottomheight', single: data})
        };
        arr.push(propData);
      }
      if (!props || (props.topRadius && props.topRadius.visible !== false)) {
        propData = {
          label: 'Top radius',
          name: 'top_radius',
          category: 'dimensions',
          dataType: 'numeric',
          value: MattressDA.getTopBorderRadius(data, 0),
          min: getPropertyNumericAttrib(props, 'topBorderRadius', 'min', DEF_MIN_TOPBOTTOMRADIUS),
          max: getPropertyNumericAttrib(props, 'topBorderRadius', 'max', DEF_MAX_TOPBOTTOMRADIUS),
          property: newPropertyInfo({name: 'single_topradius', single: data})
        };
        arr.push(propData);
      }
      if (!props || (props.bottomRadius && props.bottomRadius.visible !== false)) {
        propData = {
          label: 'Bottom radius',
          name: 'bottom_radius',
          category: 'dimensions',
          dataType: 'numeric',
          value: MattressDA.getBottomBorderRadius(data, 0),
          min: getPropertyNumericAttrib(props, 'bottomBorderRadius', 'min', DEF_MIN_TOPBOTTOMRADIUS),
          max: getPropertyNumericAttrib(props, 'bottomBorderRadius', 'max', DEF_MAX_TOPBOTTOMRADIUS),
          property: newPropertyInfo({name: 'single_bottomradius', single: data})
        };
        arr.push(propData);
      }
      if (!props || (props.cornerRadius && props.cornerRadius.visible !== false)) {
        propData = {
          label: 'Corner radius',
          name: 'corner_radius',
          category: 'dimensions',
          dataType: 'numeric',
          value: MattressDA.getCornerRadius(data, 0),
          min: getPropertyNumericAttrib(props, 'cornerRadius', 'min', DEF_MIN_CORNERRADIUS),
          max: getPropertyNumericAttrib(props, 'cornerRadius', 'max', DEF_MAX_CORNERRADIUS),
          property: newPropertyInfo({name: 'single_cornerradius', single: data})
        };
        arr.push(propData);
      }

      if (recursive && !dimensionProperties) {
        arr = topPanelPropertyCollector.getProperties(data, params, recursive, arr);
        arr = bottomPanelPropertyCollector.getProperties(data, params, recursive, arr);
        arr = borderPropertyCollector.getProperties(data.border, params, recursive, arr);
      }

      return arr;
    }
  });

  const controllerPropertyCollector = new PropertyCollector({
    name: MattressConfigObjectTypes.CONTROLLER.name,
    getProperties(data, params, recursive, array = null) {
      if (!data) {
        return array;
      }
      let arr = array;
      const props = data.properties;

      if (props) {
        if (!arr) {
          arr = [];
        }
        if ((props instanceof Array) || (Array.isArray && Array.isArray(props))) {
          const num = props.length;

          for (let i = 0; i < num; ++i) {
            const prop = props[i];

            if (prop && prop.visible !== false) {
              addControllerPropertyData(prop, data, arr);
            }
          }
        }
      }

      return arr;
    }
  });

  const configPropertyCollector = new PropertyCollector({
    name: MattressConfigObjectTypes.CONFIG.name,
    getProperties(data, params, recursive, array) {
      if (!data) {
        return array;
      }
      let arr = array;

      if (recursive) {
        const controllers = data.controllers;

        if (controllers) {
          const num = controllers.length;

          for (let i = 0; i < num; ++i) {
            const contr = controllers[i];

            arr = controllerPropertyCollector.getProperties(contr, params, recursive, arr);
          }
        }

        const singles = data.singles;

        if (singles) {
          const num = singles.length;

          for (let i = 0; i < num; ++i) {
            const single = singles[i];

            if (single) {
              singlePropertyCollector.getProperties(single, params, recursive, arr);
            }
          }
        }
      }

      return arr;
    }
  });

  propertyCollectors.push(configPropertyCollector);
  propertyCollectors.push(controllerPropertyCollector);
  propertyCollectors.push(singlePropertyCollector);
  propertyCollectors.push(topPanelPropertyCollector);
  propertyCollectors.push(bottomPanelPropertyCollector);
  propertyCollectors.push(borderPropertyCollector);
  propertyCollectors.push(borderComponentsPropertyCollector);
  propertyCollectors.push(borderComponentPropertyCollector);
}

// Create a map containing all property collectors,
// using its 'name' property as key
const propertyCollectorMap = {};

{
  const num = propertyCollectors.length;

  for (let i = 0; i < num; ++i) {
    const pc = propertyCollectors[i];
    const name = (pc && pc.getName) ? pc.getName() : null;

    if (name) {
      propertyCollectorMap[name.toLowerCase()] = pc;
    }
  }
}

/**
* @class MattressConfigPropertyCollector
* @description Util to collect all properties associated with a given object
*/
export default class MattressConfigPropertyCollector {
  static _getObjectTypeName(objectType) {
    if (!objectType) {
      return null;
    }
    if (typeof (objectType) === 'string') {
      return objectType;
    }
    if (typeof (objectType.name) === 'string') {
      return objectType.name;
    }

    return null;
  }

  static getProperties(objectType, data, params, recursive, array = null) {
    const objectTypeName = this._getObjectTypeName(objectType);

    if (!objectTypeName) {
      return array;
    }
    const arr = array || [];
    const collector = propertyCollectorMap[objectTypeName.toLowerCase()];

    if (!collector) {
      return arr;
    }

    return collector.getProperties(data, params, recursive, arr);
  }
}
