import Loader from '../../bgr/common/loading/Loader';
import ImageLoader from '../../bgr/common/loading/ImageLoader';
import LoaderList from '../../bgr/common/loading/LoaderList';
import LoaderState from '../../bgr/common/loading/LoaderState';
import SampleService from '../services/SampleService';
// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
// #endif

let sampleService = null;

export default class SampleLoader extends Loader {
  constructor(args) {
    super();
    let id = null;
    let sampleLoadParams = null;

    if (typeof (args) === 'string' || typeof (args) === 'number') {
      id = args;
    } else if (typeof (args) === 'object') {
      id = args.id;
      sampleLoadParams = args.sampleLoadParams;
    }

    this.id = id;
    this._sampleLoadParams = sampleLoadParams;
  }

  setSampleService(svc) {
    this._sampleService = svc;
  }

  set sampleService(svc) {
    this.setSampleService(svc);
  }

  _getSampleService() {
    let res = this._sampleService;

    if (!res) {
      if (!sampleService) {
        sampleService = new SampleService();
      }
      res = sampleService;
    }

    return res;
  }

  _getSampleCallback() {
    let sampleCB = this._sampleCallback;

    if (!sampleCB) {
      const that = this;

      sampleCB = this._sampleCallback = res => {
        that._handleSampleFinished(res);
      };
    }

    return sampleCB;
  }

  _newImageLoader(url) {
    if (!url) {
      return null;
    }
    const res = new ImageLoader(url);

    res.crossOrigin = 'Anonymous';

    return res;
  }

  _getImageURL(svc, json, ...keys) {
    if (!svc) {
      return null;
    }
    const l = keys.length;

    if (!l) {
      return null;
    }
    for (let i = 0; i < l; ++i) {
      const url = svc.getSampleImageURL(json, keys[i]);

      if (url) {
        return url;
      }
    }

    return null;
  }

  _handleSampleFinished(res) {
    this._result = {
      json: res
    };

    let texImageLoader = null, bumpImageLoader = null, normalImageLoader = null,
      specularImageLoader = null;
    const svc = this._getSampleService();

    if (res) {
      let url;

      url = this._getImageURL(svc, res, 'base_normal', 'baseNormal', 'basenormal', 'base-normal');
      normalImageLoader = this._newImageLoader(url);

      url = this._getImageURL(svc, res, 'base_bump', 'baseBump', 'basebump', 'base-bump');
      bumpImageLoader = this._newImageLoader(url);

      url = this._getImageURL(svc, res, 'base_image', 'baseImage', 'baseimage', 'base-image');
      texImageLoader = this._newImageLoader(url);

      // const specularMap = res ? (res.base_specular || res.base_specularmap || res.base_specularMap) : null;

      url = this._getImageURL(svc, res, 'base_specular', 'baseSpecular', 'basespecular', 'base-specular');

      if (url) {
        specularImageLoader = this._newImageLoader(url);
      }
    }

    if (!this._imageLoaderMap) {
      this._imageLoaderMap = {};
    }
    this._imageLoaderMap.texture = texImageLoader;
    this._imageLoaderMap.bump = bumpImageLoader;
    this._imageLoaderMap.normal = normalImageLoader;
    this._imageLoaderMap.specular = specularImageLoader;

    const loaders = [];

    if (!texImageLoader && !bumpImageLoader) {
      this._changeState(LoaderState.COMPLETED);
    }
    if (texImageLoader) {
      loaders.push(texImageLoader);
    }
    if (bumpImageLoader) {
      loaders.push(bumpImageLoader);
    }
    if (normalImageLoader) {
      loaders.push(normalImageLoader);
    }
    if (specularImageLoader) {
      loaders.push(specularImageLoader);
    }
    const loaderList = new LoaderList(loaders);

    loaderList.once('finished', this._getImagesFinishedHandler());

    this._loaderList = loaderList;

    loaderList.start();
  }

  _getImagesFinishedHandler() {
    let h = this._imagesFinishedHandler;

    if (!h) {
      const that = this;

      h = this._imagesFinishedHandler = evt => {
        that._handleImagesFinished(evt);
      };
    }

    return h;
  }

  _handleImagesFinished(evt) {
    let texImage, bumpImage, specularImage, normalImage;

    if (this._imageLoaderMap.texture) {
      texImage = this._imageLoaderMap.texture.getImage();
      this._imageLoaderMap.texture.removeEventListeners();
      this._imageLoaderMap.texture = null;
    }

    if (this._imageLoaderMap.bump) {
      bumpImage = this._imageLoaderMap.bump.getImage();
      this._imageLoaderMap.bump.removeEventListeners();
      this._imageLoaderMap.bump = null;
    }
    if (this._imageLoaderMap.specular) {
      specularImage = this._imageLoaderMap.specular.getImage();
      this._imageLoaderMap.specular.removeEventListeners();
      this._imageLoaderMap.specular = null;
    }
    if (this._imageLoaderMap.normal) {
      normalImage = this._imageLoaderMap.normal.getImage();
      this._imageLoaderMap.normal.removeEventListeners();
      this._imageLoaderMap.normal = null;
    }

    if (!this._result) {
      this._result = {};
    }

    this._result.images = {
      texture: texImage,
      bump: bumpImage,
      specular: specularImage,
      normal: normalImage
    };

    if (this._loaderList) {
      this._loaderList.removeEventListeners();
    }
    // #if DEBUG
    if (!this._loaderList) {
      BD3DLogger.warn('No sample images loaded? (loader list is null)');
    }
    // #endif
    this._loaderList = null;

    this._changeState(LoaderState.COMPLETED);
  }

  getResult() {
    return this._result;
  }

  get result() {
    return this.getResult();
  }

  // Optional parameters for the sample service (resolution, compression, ...)
  getSampleLoadParams() {
    return this._sampleLoadParams;
  }

  _cancelLoading() {
    const imgLoaderList = this._loaderList;

    if (imgLoaderList) {
      imgLoaderList.cancel();
      imgLoaderList.removeEventListener('finished', this._getImagesFinishedHandler());
      this._loaderList = null;
    }
  }

  reset() {
    super.reset();
    this._cancelLoading();
    this._result = null;
  }


  cancel() {
    if (this.getState() !== LoaderState.PROGRESS) {
      return;
    }
    this._cancelLoading();

    this._result = null;
    super.cancel();
  }

  start() {
    const st = this.getState();

    if (st !== null && typeof (st) !== 'undefined' && st === LoaderState.PROGRESS) {
      return;
    }

    const id = this.id;

    if (id === null || typeof (id) === 'undefined') {
      this._changeState(LoaderState.ERROR);

      return;
    }

    this._changeState(LoaderState.PROGRESS);
    const svc = this._getSampleService();

    const cb = this._getSampleCallback();

    const sampleParams = this.getSampleLoadParams();

    svc.getSample(id, sampleParams, cb);

  }
}
