import DOMElementController from './DOMElementController';

export default class DragController extends DOMElementController {
  constructor(element, enabled = true) {
    super(element, enabled);
  }

  _preventNativePressEvent(evt) {
    return this._preventNativeEvent(evt);
  }

  _preventNativeDragEvent(evt) {
    return this._preventNativeEvent(evt);
  }

  _preventNativeReleaseEvent(evt) {
    return this._preventNativeEvent(evt);
  }

  _setElementEnabled(elem, e = true) {
    if (!elem) {
      return;
    }

    let pressHandler = this._pressHandler;

    if (e) {
      const that = this;

      if (!pressHandler) {
        pressHandler = this._pressHandler = evt => {
          that._handlePress(evt);
        };
      }
      elem.addEventListener('mousedown', pressHandler);
      elem.addEventListener('touchstart', pressHandler);
    } else if (pressHandler) {
      elem.removeEventListener('mousedown', pressHandler);
      elem.removeEventListener('touchstart', pressHandler);
      this._stopDrag();
    }
  }

  _getPointerCoords(event, index = 0) {
    let res = null;

    if (event.touches && event.touches.length > index) {
      res = event.touches[index];
    }
    if (!res) {
      if (event.changedTouches && event.changedTouches.length > index) {
        res = event.changedTouches[index];
      }
    }
    if (!res && typeof (event.clientX) !== 'undefined') {
      res = event;
    }

    return res;
  }

  hasDragged() {
    return this._dragged;
  }

  get dragged() {
    return this.hasDragged();
  }

  _handlePress(event) {
    const elem = this.getElement();
    const bounds = elem.getBoundingClientRect();

    const coords = this._getPointerCoords(event);
    const x = coords.clientX - bounds.left;
    const y = coords.clientY - bounds.top;

    this._elementBounds = bounds;
    this._prevX = x;
    this._prevY = y;
    this._pressX = x;
    this._pressY = y;
    this._dragged = false;

    let startDragEvent = this._startDragEvent;

    if (!startDragEvent) {
      startDragEvent = this._startDragEvent = {};
    }
    startDragEvent.x = x;
    startDragEvent.y = y;
    startDragEvent.originalEvent = event;
    startDragEvent.type = 'startdrag';

    this.dispatchEvent(startDragEvent);

    this._startDrag();

    return this._preventNativePressEvent(event);
  }

  _handleDrag(evt) {
    // const elem = this.getElement();
    const coords = this._getPointerCoords(evt);
    // const bounds = elem.getBoundingClientRect();
    let bounds = this._elementBounds;

    if (!bounds) {
      const elem = this.getElement();

      bounds = elem.getBoundingClientRect();
    }

    const x = coords.clientX - bounds.left;
    const y = coords.clientY - bounds.top;
    const px = this._prevX;
    const py = this._prevY;

    if (!this.isDrag || this.isDrag(evt, x, y, px, py)) {
      this._dragged = true;
      const dx = x - px;
      const dy = y - py;

      let dragEvent = this._dragEvent;

      if (!dragEvent) {
        dragEvent = this._dragEvent = {};
      }
      dragEvent.x = x;
      dragEvent.y = y;
      dragEvent.dx = dx;
      dragEvent.dy = dy;
      dragEvent.startX = this._pressX;
      dragEvent.startY = this._pressY;
      dragEvent.originalEvent = evt;
      dragEvent.type = 'drag';

      this.dispatchEvent(dragEvent);

      this._prevX = x;
      this._prevY = y;
    }

    return this._preventNativeDragEvent(evt);
  }

  _handleRelease(evt) {
    this._stopDrag();
    let stopDragEvent = this._stopDragEvent;

    if (!stopDragEvent) {
      stopDragEvent = this._stopDragEvent = {};
    }
    const coords = this._getPointerCoords(evt);
    let bounds = this._elementBounds;

    if (!bounds) {
      const elem = this.getElement();

      bounds = elem.getBoundingClientRect();
    }

    stopDragEvent.type = 'stopdrag';
    stopDragEvent.dragged = this._dragged;
    stopDragEvent.x = coords.clientX - bounds.left;
    stopDragEvent.y = coords.clientY - bounds.top;

    stopDragEvent.originalEvent = evt;

    this.dispatchEvent(stopDragEvent);

    return this._preventNativeReleaseEvent(evt);
  }

  _startDrag() {
    let dragHandler = this._dragHandler;
    let releaseHandler = this._releaseHandler;
    const that = this;

    if (!dragHandler) {
      dragHandler = this._dragHandler = evt => {
        return that._handleDrag(evt);
      };
    }

    if (!releaseHandler) {
      releaseHandler = this._releaseHandler = evt => {
        return that._handleRelease(evt);
      };
    }

    window.addEventListener('mousemove', dragHandler);
    window.addEventListener('touchmove', dragHandler);
    window.addEventListener('mouseup', releaseHandler);
    window.addEventListener('touchend', releaseHandler);
  }

  _stopDrag() {
    const dragHandler = this._dragHandler;
    const releaseHandler = this._releaseHandler;

    if (dragHandler) {
      window.removeEventListener('mousemove', dragHandler);
      window.removeEventListener('touchmove', dragHandler);
    }
    if (releaseHandler) {
      window.removeEventListener('mouseup', releaseHandler);
      window.removeEventListener('touchend', releaseHandler);
    }
  }

  dispose() {
    super.dispose();
    this._pressHandler = null;
    this._dragHandler = null;
    this._releaseHandler = null;
  }
}
