import DragController from './DragController';
// import DOMElementController from './DOMElementController';

const POINTERTYPE_TOUCH = 'touch';
const POINTERTYPE_MOUSE = 'mouse';

export default class TouchDragController extends DragController {
  constructor(element, enabled = true) {
    super(element, enabled);
  }

  _handlePress(evt) {
    const elem = this.getElement();
    const bounds = elem.getBoundingClientRect();

    this._pressedTouchData = this._getTouchData(evt, this._pressedTouchData);
    this._prevTouchData = this._copyTouchData(this._pressedTouchData, this._prevTouchData);

    const prTD = this._pressedTouchData;

    this._elementBounds = bounds;
    this._dragged = false;

    let startDragEvent = this._startDragEvent;

    if (!startDragEvent) {
      startDragEvent = this._startDragEvent = {};
    }
    startDragEvent.x = prTD.x - bounds.left;
    startDragEvent.y = prTD.y - bounds.top;
    startDragEvent.angle = prTD.angle;
    startDragEvent.distance = prTD.distance;
    startDragEvent.numPointers = prTD.numPointers;
    startDragEvent.numTouches = prTD.numTouches;
    startDragEvent.pointerType = prTD.pointerType;

    startDragEvent.originalEvent = evt;
    startDragEvent.type = 'startdrag';

    this.dispatchEvent(startDragEvent);

    this._startDrag();

    return this._preventNativePressEvent(evt);
  }

  _handleDrag(evt) {
    this._touchData = this._getTouchData(evt, this._touchData);
    const td = this._touchData;
    const ptd = this._prevTouchData;

    let bounds = this._elementBounds;

    if (!bounds) {
      const elem = this.getElement();

      bounds = elem.getBoundingClientRect();
    }

    if (!this.isDrag || this.isDrag(evt, td, ptd, bounds)) {
      this._dragged = true;

      const x = td.x - bounds.left;
      const y = td.y - bounds.top;
      const px = ptd.x - bounds.left;
      const py = ptd.y - bounds.top;

      const prTD = this._pressedTouchData;

      const dAngle = td.angle - ptd.angle;
      const dDistance = td.distance - ptd.distance;

      const dx = x - px;
      const dy = y - py;

      let dragEvent = this._dragEvent;

      if (!dragEvent) {
        dragEvent = this._dragEvent = {};
      }
      dragEvent.x = x;
      dragEvent.y = y;
      dragEvent.angle = td.angle;
      dragEvent.distance = td.distance;
      dragEvent.numPointers = td.numPointers;
      dragEvent.numTouches = td.numTouches;

      dragEvent.dx = dx;
      dragEvent.dy = dy;
      dragEvent.dAngle = dAngle;
      dragEvent.dDistance = dDistance;

      dragEvent.startX = prTD.x;
      dragEvent.startY = prTD.y;
      dragEvent.startAngle = prTD.angle;
      dragEvent.startDistance = prTD.distance;
      dragEvent.startNumPointers = prTD.numPointers;
      dragEvent.startNumTouches = prTD.numTouches;

      dragEvent.pointerType = td.pointerType;

      dragEvent.originalEvent = evt;

      dragEvent.type = 'drag';

      this.dispatchEvent(dragEvent);

      this._prevTouchData = this._copyTouchData(this._touchData, this._prevTouchData);
    }

    return this._preventNativeDragEvent(evt);
  }

  _handleRelease(evt) {
    this._stopDrag();
    let stopDragEvent = this._stopDragEvent;

    if (!stopDragEvent) {
      stopDragEvent = this._stopDragEvent = {};
    }
    // const coords = this._getPointerCoords(evt);

    this._touchData = this._getTouchData(evt, this._touchData);
    const td = this._touchData;

    let bounds = this._elementBounds;

    if (!bounds) {
      const elem = this.getElement();

      bounds = elem.getBoundingClientRect();
    }

    stopDragEvent.type = 'stopdrag';
    stopDragEvent.dragged = this._dragged;
    stopDragEvent.x = td.x - bounds.left;
    stopDragEvent.y = td.y - bounds.top;
    stopDragEvent.distance = td.angle;
    stopDragEvent.angle = td.angle;
    stopDragEvent.numPointers = td.numPointers;
    stopDragEvent.numTouches = td.numTouches;
    stopDragEvent.pointerType = td.pointerType;

    stopDragEvent.originalEvent = evt;

    this.dispatchEvent(stopDragEvent);

    return this._preventNativeReleaseEvent(evt);
  }

  _copyTouchData(source, result = null) {
    let res = result;

    if (!res) {
      res = {};
    }
    const srcTouches = source.touches;

    res.numPointers = source.numPointers;
    res.numTouches = source.numTouches;
    res.x = source.x;
    res.y = source.y;
    res.distance = source.distance;
    res.angle = source.angle;
    res.pointerType = source.pointerType;

    if (srcTouches) {
      const numT = srcTouches.length;

      if (!res.touches) {
        res.touches = [];
      }
      res.touches.length = numT;

      for (let i = 0; i < numT; ++i) {
        res.touches[i] = this._copyTouch(srcTouches[i], res.touches[i]);
      }
    } else {
      res.touches = null;
    }

    return res;
  }

  _copyTouch(source, result = null) {
    let res = result;

    if (res) {
      res.x = 0;
      res.y = 0;
    }

    if (!source) {
      return res;
    }
    if (!res) {
      res = {};
    }
    res.x = source.x;
    res.y = source.y;

    return res;
  }

  _getTouchData(evt, result = null) {
    let res = result;

    if (!res) {
      res = {};
    }

    let numPointers = 0, numTouches = 0, touches = null, pointerType = null;

    if (evt.touches && evt.touches.length > 0) {
      numPointers = evt.touches.length;
      numTouches = numPointers;
      touches = evt.touches;
      pointerType = POINTERTYPE_TOUCH;
    } else if (evt.changedTouches && evt.changedTouches.length > 0) {
      numPointers = evt.changedTouches.length;
      touches = evt.changedTouches;
      numTouches = numPointers;
      pointerType = POINTERTYPE_TOUCH;
    } else if (typeof (evt.clientX) === 'number') {
      numPointers = 1;
      pointerType = POINTERTYPE_MOUSE;
    }

    res.numPointers = numPointers;
    res.numTouches = numTouches;
    res.pointerType = pointerType;
    res.distance = 0;
    res.angle = 0;
    res.x = 0;
    res.y = 0;

    if (numPointers <= 0) {
      return res;
    }
    let centerX = 0, centerY = 0, angle = 0, distance = 0;

    if (touches) {
      let resTouches = res.touches;

      if (!resTouches) {
        resTouches = res.touches = [];
      }

      for (let i = 0; i < numPointers; ++i) {
        const touch = touches[i];
        const X = touch.clientX;
        const Y = touch.clientY;
        let resTouch = resTouches[i];

        if (!resTouch) {
          resTouch = resTouches[i] = {};
        }

        resTouch.x = X;
        resTouch.y = Y;

        centerX += X;
        centerY += Y;
      }
      if (numPointers > 1) {
        const x1 = touches[0].clientX;
        const y1 = touches[0].clientY;
        const x2 = touches[1].clientX;
        const y2 = touches[1].clientY;

        const dx = x2 - x1;
        const dy = y2 - y1;

        angle = Math.atan2(dy, dx);
        distance = Math.sqrt(dx * dx + dy * dy);
      }
      centerX /= numPointers;
      centerY /= numPointers;
    } else {
      centerX = evt.clientX;
      centerY = evt.clientY;
    }
    res.x = centerX;
    res.y = centerY;
    res.angle = angle;
    res.distance = distance;

    return res;
  }
}
