import Vector from './Vector';

const coordNames = ['_x', '_y', '_z', '_w'];
const X_COORD_INDEX = 0;
const Y_COORD_INDEX = 1;
const DIM = 2;

export default class Vector2 extends Vector {
  constructor(x = 0, y = 0) {
    super();
    this._x = x;
    this._y = y;
  }

  getX() {
    return this.getCoord(X_COORD_INDEX);
  }

  setX(value) {
    this.setCoord(X_COORD_INDEX, value);
  }

  get x() {
    return this.getX();
  }

  set x(value) {
    this.setX(value);
  }

  getY() {
    return this.getCoord(Y_COORD_INDEX);
  }

  setY(value) {
    this.setCoord(Y_COORD_INDEX, value);
  }

  get y() {
    return this.getY();
  }

  set y(value) {
    this.setY(value);
  }

  // Access using vector[0]
  get [X_COORD_INDEX]() {
    return this.getCoord(X_COORD_INDEX);
  }

  set [X_COORD_INDEX](value) {
    return this.setCoord(X_COORD_INDEX, value);
  }

  get [Y_COORD_INDEX]() {
    return this.getCoord(Y_COORD_INDEX);
  }

  set [Y_COORD_INDEX](value) {
    return this.setCoord(Y_COORD_INDEX, value);
  }

  getCoord(index) {
    if (index < 0) {
      return 0;
    }
    if (index > this.getDimension()) {
      return 0;
    }
    const name = coordNames[index];

    return this[name];
  }

  setCoord(index, value) {
    if (index < 0) {
      return;
    }
    if (index >= this.getDimension()) {
      return;
    }
    const name = coordNames[index];

    this[name] = value;

    return;
  }

  getDimension() {
    return DIM;
  }
}
