import * as THREE from 'three';
import Utils from '../utils/Utils';

let instance;

const DEFAULT_JPEG_BACKGROUND_COLOR = 0xFFFFFF;

const TYPE_PNG = 'image/png';
const TYPE_JPEG = 'image/jpeg';

const mimeTypeMap = {
  png: TYPE_PNG,
  'image/png': TYPE_PNG,
  'img/png': TYPE_PNG,

  jpeg: TYPE_JPEG,
  jpg: TYPE_JPEG,
  'image/jpg': TYPE_JPEG,
  'image/jpeg': TYPE_JPEG,
  'img/jpg': TYPE_JPEG,
  'img/jpeg': TYPE_JPEG
};

const qualityMap = {
  best: 1,
  great: 0.85,
  good: 0.75,
  medium: 0.5,
  low: 0.25,
  lowest: 0.1,
  bad: 0.1
};

export default class ThreeScreenshotUtil {
  static getInstance() {
    if (!instance) {
      instance = new ThreeScreenshotUtil();
    }

    return instance;
  }

  static dispose() {
    this.disposeThree();
    instance = null;
  }

  static disposeThree() {
    if (instance) {
      instance.dispose();
    }
    if (this._defaultMaterial) {
      if (this._defaultMaterial.dispose) {
        this._defaultMaterial.dispose();
      }
      this._defaultMaterial = null;
    }
  }

  static get instance() {
    return this.getInstance();
  }

  constructor() {
    this.onBeforeRender = null;
    this.onAfterRender = null;
  }

  dispose() {
    this.disposeThreeObjects();
  }

  disposeThreeObjects() {
    this.disposeThree();
    this._qScene = null;
    this._qMesh = null;
    this._qCam = null;
    this._quadGeom = null;
  }

  disposeThree() {
    this._quadGeom = null;
  }

  _getDefaultMaterial(texture, texWidth, texHeight) {
    let res = this._defaultMaterial;

    if (!res) {
      res = this._defaultMaterial = new THREE.RawShaderMaterial({
        vertexShader: [
          'precision mediump float;',
          'attribute vec3 position;',
          'varying vec2 v_uv;',
          'void main() {',
          ' vec4 pos = vec4(position, 1.0);',
          ' v_uv = 0.5 * (pos.xy + vec2(1.0));',
          ' gl_Position = pos;',
          '}'
        ].join('\n'),
        fragmentShader: [
          'precision mediump float;',
          'varying vec2 v_uv;',
          'uniform sampler2D texture;',
          'uniform vec2 multisample;',
          'void main() {',
          ' vec4 pix = texture2D(texture, v_uv);',
          ' vec4 pixTR = texture2D(texture, v_uv + vec2(1.0, 0.0) * multisample);',
          ' vec4 pixBL = texture2D(texture, v_uv + vec2(0.0, 1.0) * multisample);',
          ' vec4 pixBR = texture2D(texture, v_uv + vec2(1.0, 1.0) * multisample);',

          ' pix = (pix + pixTR + pixBL + pixBR) / 4.0;',

          // fix premultiplied alpha
          ' pix.rgb /= pix.a;',
          // ' gl_FragColor = vec4(vec3(v_uv, 0), 1.0);',
          ' gl_FragColor = pix;',
          '}'
        ].join('\n')
      });
    }
    let uniforms = res.uniforms;

    if (!uniforms) {
      uniforms = res.uniforms = {};
    }

    let texUniform = uniforms.texture;

    if (!texUniform) {
      texUniform = uniforms.texture = new THREE.Uniform();
    }

    let msUniform = uniforms.multisample;

    if (!msUniform) {
      msUniform = uniforms.multisample = new THREE.Uniform();
    }

    texUniform.value = texture;

    let ms = msUniform.value;

    if (!ms) {
      ms = msUniform.value = new THREE.Vector2();
    }
    ms.x = 1 / texWidth;
    ms.y = 1 / texHeight;

    return res;
  }

  _getQuadScene(create = false) {
    let res = this._qScene;

    if (res || !create) {
      return res;
    }
    res = this._qScene = new THREE.Scene();
    res.add(this._getQuadMesh(true));

    return res;
  }
  _getQuadMesh(create = false) {
    let res = this._qMesh;

    if (res || !create) {
      return res;
    }
    res = this._qMesh = new THREE.Mesh();

    return res;
  }

  _getQuadCamera(create = false) {
    let res = this._qCam;

    if (res || !create) {
      return res;
    }
    res = this._qCam = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);

    return res;
  }

  _getQuadGeom(renderer, create = false) {
    if (!renderer) {
      return null;
    }

    if (typeof (Map) === 'undefined') {
      let res = this._quadGeom;

      if (res) {
        return res;
      }
      res = this._quadGeom = new THREE.PlaneBufferGeometry(2, 2);

      return res;
    }
    let map = this._quadGeomMap;

    if (!map && create) {
      map = new Map();
      this._quadGeomMap = map;
    }
    if (!map) {
      return null;
    }
    let geom = map.get(renderer);

    if (!geom && create) {
      geom = new THREE.PlaneBufferGeometry(2, 2);
      map.set(renderer, geom);
    }

    return geom;
  }
  _getRendererWidth(renderer, fallback) {
    if (!renderer) {
      return fallback;
    }
    const elem = renderer.domElement;

    if (!elem) {
      return fallback;
    }
    const pr = renderer.getPixelRatio();

    return elem.width / pr;
  }
  _getRendererHeight(renderer, fallback) {
    if (!renderer) {
      return fallback;
    }
    const elem = renderer.domElement;

    if (!elem) {
      return fallback;
    }
    const pr = renderer.getPixelRatio();

    return elem.height / pr;
  }

  getScreenshotBase64(renderer, scene, camera, params) {
    const dataURI = this.getScreenshotDataURI(renderer, scene, camera, params);

    if (!dataURI) {
      return null;
    }
    const search = 'base64,';
    const idx = dataURI.indexOf(search, 0);

    if (idx < 0) {
      return dataURI;
    }

    return dataURI.substring(idx + search.length, dataURI.length);
  }

  getScreenshotDataURI(renderer, scene, camera, params) {
    let canvas = null;

    if (params) {
      if (params.useDrawingBuffer === true) {
        canvas = renderer.domElement;
      }
      // If not using the renderer's drawing buffer, use a render target
      // This is slower, but should also work if 'preserveDrawingBuffer' is false.
    }
    if (!canvas) {
      canvas = this.getScreenshotCanvas(renderer, scene, camera, params);
    }

    if (!canvas) {
      return null;
    }
    // let mimeType = null;
    const mimeType = this._getMimeTypeFromParams(params);
    let encOptions = null;

    if (params) {
      // mimeType = params.mimeType || params.imageType || params.type;
      encOptions = params.encoderOptions || params.quality;
    }

    // mimeType = this._getMimeType(mimeType);

    if (mimeType === TYPE_JPEG) {
      encOptions = this._getQuality(encOptions);
    }

    return canvas.toDataURL(mimeType, encOptions);
  }

  _getQuality(quality) {
    let q = quality;

    if (typeof (q) === 'number' && !isNaN(q)) {
      return q;
    }
    if (typeof (q) === 'string') {
      q = qualityMap[q.toLowerCase()];

      if (typeof (q) !== 'number' || isNaN(q)) {
        q = parseFloat(quality);
      }
    }
    if (typeof (q) !== 'number' || isNaN(q)) {
      return 1;
    }

    return q;
  }

  _getMimeType(mimeType) {
    let mt = mimeType;

    if (!mt || typeof (mt) !== 'string') {
      return TYPE_PNG;
    }
    mt = mimeTypeMap[mt.toLowerCase()];
    if (!mt || typeof (mt) !== 'string') {
      return TYPE_PNG;
    }

    return mt;
  }

  getMimeTypeFromParams(params, fallback) {
    return this._getMimeTypeFromParams(params, fallback);
  }

  _getMimeTypeFromParams(params, fallback) {
    if (!params) {
      return fallback;
    }
    let mimeType = params.mimeType || params.imageType || params.type || params.format || params.imageFormat || params.imageformat || params.mimetype || params.imagetype;

    mimeType = this._getMimeType(mimeType);

    return mimeType;
  }

  getBackgroundColorFromParams(params, fallback) {
    return this._getBackgroundColorFromParams(params, fallback);
  }

  getBackgroundAlphaFromParams(params, fallback) {
    return this._getBackgroundAlphaFromParams(params, fallback);
  }

  _getBackgroundColorFromParams(params, fallback) {
    if (!params) {
      return fallback;
    }

    return Utils.tryValues(params.backgroundColor, params.backgroundcolor, params.background, fallback);
  }

  _getBackgroundAlphaFromParams(params, fallback) {
    if (!params) {
      return fallback;
    }

    return Utils.tryValues(params.backgroundAlpha, params.backgroundalpha, fallback);
  }

  // #if DEBUG
  showScreenshot(renderer, scene, camera, params = null) {
    let method = null;

    if (params) {
      method = params.method;
    }

    if (method) {
      method = method.toLowerCase();
    }
    let width = 0;
    let height = 0;

    if (params) {
      width = params.width;
      height = params.height;
    }
    if (typeof (width) !== 'number' || isNaN(width)) {
      width = this._getRendererWidth(renderer, params);
    }
    if (typeof (height) !== 'number' || isNaN(height)) {
      height = this._getRendererHeight(renderer, params);
    }

    const winProps = `left=800,top=800,width=${width},height=${height},toolbar=no,status=no,menubar=no,titlebar=no`;


    if (method === 'base64' || method === 'datauri' || method === 'dataurl') {
      const b64 = this.getScreenshotDataURI(renderer, scene, camera, params);

      window.open(b64, '_blank', winProps);
    } else {
      const canvas = this.getScreenshotCanvas(renderer, scene, camera, params);
      const win = window.open('', '_blank', winProps);
      const doc = win.document;

      doc.body.appendChild(canvas);
    }

  }
  // #endif

  getScreenshotCanvas(renderer, scene, camera, params, result = null) {
    return this._getScreenshotCanvasDirect(renderer, scene, camera, params, result);
  }

  _getScreenshotCanvasDirect(renderer, scene, camera, params, result = null) {
    const doc = document;
    let canvas = doc.createElement('canvas');
    const context = canvas.getContext('2d');

    this._tempSize = this._getResultSize(renderer, params, this._tempSize);
    const destWidth = this._tempSize.width;
    const destHeight = this._tempSize.height;

    let w = destWidth;
    let h = destHeight;

    // This param should make sure the screenshot shows everything that's visible in the current canvas
    // so it doesnt cut off parts left or right if the aspect ratio does not match
    let fitCurrentResolution = Boolean(params && params.fitCurrentResolution);

    if (fitCurrentResolution) {
      const rendererWidth = this._getRendererWidth(renderer);
      const rendererHeight = this._getRendererHeight(renderer);

      fitCurrentResolution = (rendererWidth / rendererHeight) > (destWidth / destHeight);

      if (fitCurrentResolution) {
        const scaleX = w / rendererWidth;
        const scaleY = h / rendererHeight;
        const scale = (scaleX > scaleY) ? scaleX : scaleY;

        w = (rendererWidth * scale) | 0;
        h = (rendererHeight * scale) | 0;
      }
    }

    canvas.width = w;
    canvas.height = h;

    if (w === 0 || h === 0) {
      return canvas;
    }

    const imgData = context.getImageData(0, 0, w, h);
    const pixelData = this.getScreenshotPixels(renderer, scene, camera, params, imgData, w, h);

    const flipY = params ? params.flipY !== false : true;

    context.putImageData(pixelData, 0, 0);

    if (flipY) {
      const canvas2 = doc.createElement('canvas');
      const context2 = canvas2.getContext('2d');

      canvas2.width = w;
      canvas2.height = h;

      context2.setTransform(1, 0, 0, -1, 0, h);
      context2.drawImage(canvas, 0, 0);
      context2.setTransform(1, 0, 0, 1, 0, 0);

      canvas = canvas2;
    }
    if (fitCurrentResolution && (w !== destWidth || h !== destHeight)) {
      const canvas2 = doc.createElement('canvas');
      const context2 = canvas2.getContext('2d');

      canvas2.width = destWidth;
      canvas2.height = destHeight;

      const scaleX = destWidth / w;
      const scaleY = destHeight / h;
      const scale = scaleX < scaleY ? scaleX : scaleY;
      const drawWidth = (w * scale) | 0;
      const drawHeight = (h * scale) | 0;
      const drawX = (destWidth - drawWidth) >> 1;
      const drawY = (destHeight - drawHeight) >> 1;

      context2.drawImage(canvas, drawX, drawY, drawWidth, drawHeight);

      canvas = canvas2;
    }

    return canvas;
  }

  // Old method to create a screenshot canvas
  _getScreenshotCanvasCopy(renderer, scene, camera, params, result = null) {
    const pixelData = this.getScreenshotPixels(renderer, scene, camera, params);
    let res = result;

    if (!pixelData) {
      return res;
    }

    if (!res) {
      res = document.createElement('canvas');
    }
    const w = pixelData.width;
    const h = pixelData.height;
    const data = pixelData.data;

    res.width = w;
    res.height = h;

    const ctx = res.getContext('2d');
    const imgData = ctx.createImageData(w, h);
    const imgDataData = imgData.data;

    const num = (w * h) << 2;
    let flipY = true;

    if (params) {
      flipY = params.flipY !== false;
    }

    if (flipY) {
      let i = 0;
      const maxY = h - 1;
      const rO = 0;
      const gO = 1;
      const bO = 2;
      const aO = 3;

      for (let y = 0; y < h; ++y) {
        for (let x = 0; x < w; ++x) {
          const i2 = x + (maxY - y) * w;
          const off = i << 2;
          const off2 = i2 << 2;

          imgDataData[off + rO] = data[off2 + rO];
          imgDataData[off + gO] = data[off2 + gO];
          imgDataData[off + bO] = data[off2 + bO];
          imgDataData[off + aO] = data[off2 + aO];

          ++i;
        }
      }
    } else {
      for (let i = 0; i < num; ++i) {
        imgDataData[i] = data[i];
      }
    }

    ctx.putImageData(imgData, 0, 0);

    return res;
  }

  getScreenshotPixels(renderer, scene, camera, params, result = null, width = -1, height = -1) {
    let wdth = width;
    let hth = height;

    if (result) {
      if ((!wdth || wdth < 0) && result.width) {
        wdth = result.width;
      }
      if ((!hth || hth < 0) && result.height) {
        hth = result.height;
      }
    }

    const rt = this.getScreenshotRenderTarget(renderer, scene, camera, params, wdth, hth);

    if (!rt) {
      return null;
    }
    const w = rt.width;
    const h = rt.height;
    let res = result;

    if (!res) {
      res = {};
    }
    let arrBuff = null;
    let buff = null;
    let reuseArrBuff = false;
    const numValues = (w * h) << 2;

    if (res.data) {
      if (res.data.length === numValues) {
        arrBuff = res.data.buffer;
      }
    }
    if (arrBuff) {
      reuseArrBuff = true;
      buff = new Uint8Array(arrBuff);
    } else {
      buff = new Uint8Array(numValues);
    }

    renderer.readRenderTargetPixels(rt, 0, 0, w, h, buff);

    if (!reuseArrBuff) {
      res.data = buff;
    }

    if (typeof (ImageData) === 'undefined' || !(res instanceof ImageData)) {
      res.width = w;
      res.height = h;
    }

    rt.dispose();

    return res;
  }

  _ceilPO2(value) {
    let v = value;

    if ((v & (v - 1)) === 0) {
      return v;
    }
    const _1 = 1;
    const _2 = 2;
    const _4 = 4;
    const _8 = 8;
    const _16 = 16;
    const _32 = 32;

    v |= v >> _1;
    v |= v >> _2;
    v |= v >> _4;
    v |= v >> _8;
    v |= v >> _16;
    v |= v >> _32;

    ++v;

    return v;
  }

  _floorPO2(value) {
    let v = value;

    if ((v & (v - 1)) === 0) {
      return v;
    }
    v = this._ceilPO2(v);
    v >>= 1;

    return v;
  }

  _fixTextureSize(size, usePO2, renderer, params) {
    let s = size;

    if (usePO2) {
      s = this._ceilPO2(s);
    }

    if (!renderer) {
      return s;
    }
    const gl = renderer.context;

    if (!gl) {
      return s;
    }
    let maxSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);

    if (usePO2) {
      maxSize = this._floorPO2(maxSize);
    }

    s = s > maxSize ? maxSize : s;

    return s;
  }

  _getResultSize(renderer, params, output = null) {
    let width = 0, height = 0, o = output;

    if (params) {
      width = params.width;
      height = params.height;
    }
    if (typeof (width) !== 'number' || isNaN(width) || width <= 0) {
      width = this._getRendererWidth(renderer);
    }
    if (typeof (height) !== 'number' || isNaN(height) || height <= 0) {
      height = this._getRendererHeight(renderer);
    }
    width = this._fixTextureSize(width, false, renderer, params);
    height = this._fixTextureSize(height, false, renderer, params);

    if (!o) {
      o = {};
    }
    o.width = width;
    o.height = height;

    return o;
  }

  getScreenshotRenderTarget(renderer, scene, camera, params, resultWidth = -1, resultHeight = -1) {
    let width, height, ms, postProcess, render;

    if (!renderer || !scene || !camera) {
      return null;
    }
    width = resultWidth;
    height = resultHeight;

    if (params) {
      if (!width || width < 0) {
        width = params.width;
      }
      if (!height || height < 0) {
        height = params.height;
      }
      ms = params.multisample;
      postProcess = params.getPostProcessMaterial || params.postProcess;
      render = params.render || params.renderCallback;
    }
    if (typeof (ms) !== 'number' || isNaN(ms)) {
      ms = 1.0;
    }
    if (typeof (width) !== 'number' || isNaN(width)) {
      width = this._getRendererWidth(renderer);
    }
    if (typeof (height) !== 'number' || isNaN(height)) {
      height = this._getRendererHeight(renderer);
    }
    width = this._fixTextureSize(width, false, renderer, params);
    height = this._fixTextureSize(height, false, renderer, params);

    const w = this._fixTextureSize(width * ms, false, renderer, params) | 0;
    const h = this._fixTextureSize(height * ms, false, renderer, params) | 0;
    const rt = new THREE.WebGLRenderTarget(w, h);

    // rt.texture.flipY = false;
    // rt.needsUpdate = true;

    const aspect = w / h;
    const oldAspect = camera.aspect;
    let rt2 = null;

    if (aspect !== camera.aspect) {
      camera.aspect = aspect;
      camera.updateProjectionMatrix();
    }

    let bgColor = this._getBackgroundColorFromParams(params);
    let bgAlpha = this._getBackgroundAlphaFromParams(params);

    const imageFormat = this._getMimeTypeFromParams(params);

    if ((imageFormat === TYPE_JPEG)) {
      if ((typeof (bgColor) === 'undefined') || (bgColor === null)) {
        bgColor = DEFAULT_JPEG_BACKGROUND_COLOR;
      }
      if ((typeof (bgAlpha) === 'undefined') || (bgAlpha === null)) {
        bgAlpha = 1;
      }
    }

    if (rt) {
      renderer.setRenderTarget(rt);
    }

    const oldBGColor = renderer.getClearColor();
    const oldBGAlpha = renderer.getClearAlpha();

    if ((typeof (bgColor) !== 'undefined' && bgColor !== null)) {
      if (typeof (bgAlpha) === 'undefined' || bgAlpha === null) {
        bgAlpha = 1;
      }
      renderer.setClearColor(bgColor, bgAlpha);
    }
    const onBeforeRender = params && params.onBeforeRender;
    const onAfterRender = params && params.onAfterRender;
    const thisOnBeforeRender = this.onBeforeRender;
    const thisOnAfterRender = this.onAfterRender;


    if (onBeforeRender) {
      onBeforeRender(renderer, scene, camera, rt, params, w, h);
    }
    if (thisOnBeforeRender) {
      thisOnBeforeRender(renderer, scene, camera, rt, params, w, h);
    }
    if (render) {
      render(renderer, scene, camera, rt, true, params, this);
    } else {
      renderer.render(scene, camera, rt, true);
    }
    if (thisOnAfterRender) {
      thisOnAfterRender(renderer, scene, camera, rt, params, w, h);
    }
    if (onAfterRender) {
      onAfterRender(renderer, scene, camera, rt, params, w, h);
    }

    if (oldAspect !== camera.aspect) {
      camera.aspect = oldAspect;
      camera.updateProjectionMatrix();
    }

    if (postProcess || ms !== 1) {
      const qscene = this._getQuadScene(true);
      const qmesh = this._getQuadMesh(true);
      const qgeom = this._getQuadGeom(renderer, true);
      const qcam = this._getQuadCamera(true);
      let mat = null;

      if (postProcess) {
        if (postProcess.getMaterial) {
          mat = postProcess.getMaterial(rt, renderer, params);
        } else {
          mat = postProcess(rt, renderer, params);
        }
      }
      if (!mat) {
        mat = this._getDefaultMaterial(rt.texture, rt.width, rt.height);
      }

      qmesh.geometry = qgeom;
      qmesh.material = mat;

      rt2 = new THREE.WebGLRenderTarget(width | 0, height | 0);
      // rt2.texture.flipY = false;
      renderer.render(qscene, qcam, rt2, true);
      rt.dispose();

      if (postProcess && postProcess.afterRender) {
        postProcess.afterRender(renderer, params);
      }
      renderer.setClearColor(oldBGColor, oldBGAlpha);

      return rt2;
    }
    renderer.setClearColor(oldBGColor, oldBGAlpha);

    return rt;
  }
}
