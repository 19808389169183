import EventDispatcher from '../events/EventDispatcher';

/**
* @class ComponentController
* @extends EventDispatcher
* @abstract
* @description Controller to add logic to a component
*/
export default class ComponentController extends EventDispatcher {

  constructor() {
    super();
    this._enabled = true;
  }
  isEnabled() {
    return this._enabled === true;
  }

  setEnabled(b = true) {
    if (this._enabled === b) {
      return;
    }
    this._enabled = b;
    this._updateEnabled();
  }

  _updateEnabled() {
    this._setComponentEnabled(this.getComponent(), this.isEnabled());
  }

  getComponent() {
    return this._component;
  }

  setComponent(comp) {
    if (this._component === comp) {
      return;
    }
    this._setComponentEnabled(this._component, false);
    this._component = comp;
    this._setComponentEnabled(this._component, true);
    this._changedComponent(this._component);
  }

  get component() {
    return this.getComponent();
  }

  set component(c) {
    this.setComponent(c);
  }
  /**
   * @method _changedComponent
   * @description Method called whenever a new component is set
   * @protected
   * @abstract
   * @returns {void}
   * */
  _changedComponent() {
    return;
  }

  _setComponentEnabled(comp, e = true) {
    if (!comp) {
      return;
    }
    let resizeHandler = this._resizeComponentHandler;

    if (e) {
      if (!resizeHandler) {
        const that = this;

        resizeHandler = this._resizeComponentHandler = evt => {
          that._handleResizeComponent(evt);
        };
      }
      comp.addEventListener('resize', resizeHandler);

      this._changedComponentSize();
    } else if (resizeHandler) {
      comp.removeEventListener('resize', resizeHandler);
    }
  }
  /**
   * @method _changedComponentSize
   * @description Handler for component resize events
   * @protected
   * @returns {void}
   * */
  _handleResizeComponent() {
    this._changedComponentSize();
  }

  /**
   * @method _changedComponentSize
   * @description Method called whenever the component's size changed.
   * @protected
   * @abstract
   * @returns {void}
   * */
  _changedComponentSize() {
    return;
  }
}
