import BD3DSceneParser from './BD3DSceneParser';
import { initOutputParams } from './utils';
import BD3DSampleFabricMaterial from '../material/BD3DSampleFabricMaterial';
import BD3DMaterial from '../material/BD3DMaterial';
import Asset from '../asset/Asset';
import Utils from '../utils/Utils';
import ArrayUtils from '../../bgr/common/utils/ArrayUtils';
import EventDispatcher from '../../bgr/common/events/EventDispatcher';
import Matrix4 from '../../bgr/bgr3d/geom/Matrix4';
import Matrix4Math from '../../bgr/bgr3d/math/Matrix4Math';

const SET_PROPERTY_PARAMS = Symbol('set_property_params');
const OUTPUT_PARAMS = Symbol('output_params');

/**
 * @class BD3DScene
 * @description Manages 3D content apart from singles
 * @extends EventDispatcher
 * */
export default class BD3DScene extends EventDispatcher {
  setData(data) {
    this._data = data;
    this._parsedData = null;
  }

  getSceneData() {
    const data = this._data;

    if (!data) {
      return null;
    }
    if (data.scene) {
      return data.scene;
    } else if (!data.singles) {
      return data;
    }

    return null;
  }

  getPropertyValue(property, params = null) {
    const parser = this._parser;

    if (!parser) {
      return null;
    }

    return parser.getPropertyValue(property, params);
  }

  _getSetPropertyParams() {
    let res = this[SET_PROPERTY_PARAMS];

    if (res) {
      return res;
    }
    res = {};

    this[SET_PROPERTY_PARAMS] = res;

    return res;
  }

  _getPropertyChangedEvent() {
    let res = this._propertyChangedEvent;

    if (res) {
      return res;
    }
    res = {};

    this._propertyChangedEvent = res;

    return res;
  }

  _notifyPropertyChanged(object, data, property, value, previousValue, eventParams) {
    const evt = this._getPropertyChangedEvent();

    evt.type = 'change_property';
    evt.object = object;
    evt.objectData = data;
    evt.property = property;
    evt.value = value;
    evt.params = eventParams;

    this.dispatchEvent(evt);
  }

  getGlobalProperties(resultArray = null) {
    const parser = this._parser;

    if (!parser) {
      return null;
    }

    return parser.getGlobalProperties(resultArray);
  }

  setPropertyValue(property, value, params = null, propertyParams = null) {
    const parser = this._parser;

    if (!parser || !property) {
      return null;
    }
    const p = this._getSetPropertyParams();

    p.params = params;
    p.propertyParams = propertyParams;
    p.outputParams = null;
    const outputParams = initOutputParams(this[OUTPUT_PARAMS]);
    this[OUTPUT_PARAMS] = outputParams;
    p.outputParams = outputParams;
    outputParams.options = params;

    let target;
    let propertyObject = property;

    if (typeof (propertyObject) === 'string') {
      propertyObject = this.getObjectById(propertyObject);
    }

    if (!propertyObject) {
      return null;
    }

    // notifier: Event dispatcher to dispatch change-events
    // p.notifier = this;

    const previousValue = parser.getPropertyValue(propertyObject, value, p);
    const res = parser.setPropertyValue(propertyObject, value, p);


    if (propertyObject.getTarget) {
      target = propertyObject.getTarget();
    } else if (propertyObject.data) {
      target = propertyObject.data.target;
    }

    const object = this.getObjectById(target);
    const data = this.getDataById(target);

    this._notifyPropertyChanged(object, data, propertyObject, value, previousValue, p.outputParams);

    return res;
  }

  getPropertiesByTarget(target, params = null, resultArray = null) {
    const parser = this._parser;

    if (!parser) {
      return null;
    }

    return parser.getPropertiesByTarget(target, params, resultArray);
  }

  callMethod(method, params, options) {
    const parser = this._parser;

    if (!parser) {
      return null;
    }

    return parser.callMethod(method, params, options);
  }

  callMethodOn(target, method, params, options) {
    const parser = this._parser;

    if (!parser) {
      return null;
    }

    return parser.callMethodOn(target, method, params, options);
  }

  getSamples(params, resultArray = null) {
    const res = resultArray;

    const methodParams = {params, result: res};
    let methodRes = this.callMethod('getSamples', methodParams);

    if (!methodRes) {
      methodRes = methodParams.result;
    }
    if (methodRes) {
      return methodRes;
    }

    return res;
  }

  getSamplesOf(target, params, resultArray = null) {
    const res = resultArray;

    let tgt = target;
    const tgtData = this.getDataByObject(tgt);

    if (tgtData && Utils.isPlainObject(tgtData)) {
      tgt = tgtData;
    }

    const methodParams = {params, result: res};
    let methodRes = this.callMethodOn(tgt, 'getSamples', methodParams);

    if (!methodRes) {
      methodRes = methodParams.result;
    }
    if (methodRes) {
      return methodRes;
    }

    return res;
  }

  getQuilts(params, resultArray = null) {
    const res = resultArray;

    const methodParams = {params, result: res};
    let methodRes = this.callMethod('getQuilts', methodParams);

    if (!methodRes) {
      methodRes = methodParams.result;
    }
    if (methodRes) {
      return methodRes;
    }

    return res;
  }

  getObjectById(id) {
    const parser = this._parser;

    return parser ? parser.getObjectById(id) : null;
  }

  getDataById(id) {
    const parser = this._parser;

    return parser ? parser.getDataById(id) : null;
  }

  getDataByObject(object) {
    if (!object) {
      return null;
    }

    const parser = this._parser;

    if (!parser) {
      return null;
    }

    return parser.getDataByObject(object);
  }

  getObjectByData(data) {
    if (!data) {
      return null;
    }

    const parser = this._parser;

    if (!parser) {
      return null;
    }

    return parser.getObjectByData(data);
  }

  getData() {
    return this._data;
  }

  dispose() {
    this.removeEventListeners();

    const parser = this._parser;

    if (parser) {
      parser.dispose();
      this._parser = null;
    }

    // TODO: Dispose assets
    // TODO: Dispose scenegraph three

    this._parsedData = null;
  }

  _getParser() {
    let parser = this._parser;

    if (parser) {
      return parser;
    }
    parser = new BD3DSceneParser();
    this._parser = parser;

    return parser;
  }

  _updateParsedData(params = null) {
    const sceneData = this.getSceneData();
    const parser = this._getParser();

    const res = parser.parseJSON(sceneData, params);

    this._parsedData = res;

    this.updateNodeTree();

    return res;
  }

  updateNodeTreeNode(node, parent, parentMatrix) {
    if (!node) {
      return;
    }
    const ud = node.userData || {};

    node.userData = ud;
    ud.parent = parent;

    const {transform} = node;
    const localMatrix = ud.matrix || new Matrix4();

    ud.matrix = localMatrix;

    Matrix4Math.identity(localMatrix);
    if (transform && transform.applyMatrix4) {
      transform.applyMatrix4(localMatrix);
    }

    const worldMatrix = ud.globalMatrix || new Matrix4();
    const inverseWorldMatrix = ud.inverseWorldMatrix || new Matrix4();

    ud.globalMatrix = worldMatrix;
    ud.inverseWorldMatrix = inverseWorldMatrix;

    if (parentMatrix) {
      Matrix4Math.multiply(parentMatrix, localMatrix, worldMatrix);
    } else {
      Matrix4Math.copy(localMatrix, worldMatrix);
    }
    Matrix4Math.getInverse(worldMatrix, inverseWorldMatrix);

    if (node.getChildren) {
      const children = node.getChildren();
      const numChildren = children ? children.length : 0;

      for (let i = 0; i < numChildren; ++i) {
        const child = children[i];

        this.updateNodeTreeNode(child, node, worldMatrix);
      }
    }
  }

  updateNodeTree() {
    const parsedData = this._parsedData;

    if (!parsedData) {
      return;
    }

    this.updateNodeTreeNode(parsedData.content);
  }

  _getParsedData(params = null) {
    let res = this._parsedData;

    if (!res) {
      res = this._updateParsedData(params);
    }

    return res;
  }

  _collectUsedAsset(asset, params, array, type) {
    if (!asset) {
      return array;
    }

    let arr = array;
    const assetCollectParams = params ? params.assetCollectParams : null;
    let allow = true;

    if (asset instanceof Asset) {
      allow = !assetCollectParams || !assetCollectParams.condition || assetCollectParams.condition(asset, assetCollectParams);
    }

    if (allow) {
      if (type === 'material') {
        const assetMgr = params ? params.assetManager : null;

        if (assetMgr) {
          arr = assetMgr._addSampleQuiltAssets(asset, arr, assetCollectParams);
          if (!asset || !asset.texture || typeof (asset.texture.id) === 'undefined' || asset.texture.id === null) {
            arr = assetMgr.addAssetToArray('defaultfabric.color', arr, params);
            arr = assetMgr.addAssetToArray('defaultfabric.normal', arr, params);
          }
        }
      } else {
        if (!arr) {
          arr = [];
        }
        ArrayUtils.addOnce(arr, asset);
      }

      const addedAssets = assetCollectParams ? assetCollectParams.addedAssets : null;

      if (addedAssets) {
        ArrayUtils.addOnce(addedAssets, asset);
      }
    }

    return arr;
  }

  _collectUsedAssets(node, params, array) {
    if (!node) {
      return array;
    }
    let arr = array;

    if (node.asset) {
      const asset = node.asset;

      arr = this._collectUsedAsset(asset, params, array);
    }
    if (node.materialData) {
      arr = this._collectUsedAsset(node.materialData, params, array, 'material');

      // params.assetManager._addSampleQuiltAssets(node.materialData, array, params.assetCollectParams);
    }
    if (node.getMaterial) {
      const mtl = node.getMaterial();

      if (mtl instanceof BD3DSampleFabricMaterial) {
        const mtlData = this.getDataByObject(mtl);
        let srcData = null;

        if (mtlData) {
          srcData = mtlData['@data'] ? mtlData['@data'] : mtlData;
        }

        if (!srcData) {
          const md = mtl.metaData;

          srcData = md ? md.source : null;
        }

        if (srcData) {
          arr = this._collectUsedAsset(srcData, params, arr, 'material');
          // arr = params.assetManager._addSampleQuiltAssets(md.source, arr, params.assetCollectParams);
        }
      } else if (mtl instanceof BD3DMaterial) {
        arr = this._collectUsedAsset(mtl.get('diffuse'), params, arr);
        arr = this._collectUsedAsset(mtl.get('normal'), params, arr);
      }
    }
    if (node.getChildren) {
      const children = node.getChildren();
      const num = children ? children.length : 0;

      for (let i = 0; i < num; ++i) {
        arr = this._collectUsedAssets(children[i], params, arr);
      }
    }

    return arr;
  }

  getAssets(params, array = null) {
    const pd = this._getParsedData();

    if (!pd) {
      return array;
    }

    return this._collectUsedAssets(pd.content, params, array);
  }

  getContent(params = null) {
    const pd = this._getParsedData(params);

    if (!pd) {
      return null;
    }

    return pd.content;
  }

  updateContent(params = null) {
    this._updateParsedData(params);
  }

  _translateText(key, locale, lang, fallback) {
    if (!locale || !key) {
      return fallback;
    }
    let res = null;

    if (locale.getTranslation) {
      res = locale.getTranslation(key, lang);

      if (!res) {
        res = locale.getTranslation(key, locale.getDefaultLanguage());
      }
    } else {
      if (!lang) {
        return fallback;
      }
      const map = locale[lang];

      if (!map) {
        return fallback;
      }

      return map[key] || fallback;
    }

    return res || fallback;
  }

  _getSampleInfoPerComponentOfChildren(children, lookingForType = null, params = null, resultArray = null) {
    if (!children) {
      return resultArray;
    }
    const numChildren = children ? children.length : 0;

    if (!numChildren) {
      return resultArray;
    }
    let res = resultArray;

    for (let i = 0; i < numChildren; ++i) {
      const child = children[i];
      const newRes = this._getSampleInfoPerComponent(child, lookingForType, params, res);

      if (newRes) {
        res = newRes;
      }
    }

    return res;
  }

  _getSampleInfoPerComponent(node, lookingForType = null, params = null, resultArray = null) {
    /*
      returns
      [
        {
          title: 'Object 01',
          id: 'object01',
          components: [
            {
              title: 'Component 01',
              type: 'sample/...',
              sample: ...
            },
            ...
          ]
        },
        ...
      ]
    */
    let res = resultArray;
    let meta = null;
    const locale = params ? params.locale : null;
    const lang = params ? params.lang : null;

    if (!node || !lookingForType) {
      return res;
    }
    let children = null;

    if (node.getChildren) {
      children = node.getChildren();
    }

    let nodeData = node;

    if (node['@meta']) {
      meta = node['@meta'];

      const data = node['@data'];

      if (!children) {
        children = data.children;
      }
    }
    if (!meta) {
      nodeData = this.getDataByObject(node);

      if (nodeData && nodeData['@meta']) {
        meta = nodeData['@meta'];
      }
    }
    const type = meta ? meta.type : null;
    let isObject = false;

    if (type === lookingForType) {
      const object = {};

      let title = meta ? meta.title : null;
      const id = nodeData ? nodeData['@id'] : null;

      title = this._translateText(title, locale, lang, title);

      if (title) {
        object.title = title;
      }

      if (id) {
        object.id = id;
      }

      isObject = lookingForType === 'object';

      if (isObject) {
        const components = this._getSampleInfoPerComponentOfChildren(children, 'component', params);

        object.components = components;
      } else {
        const samples = this.getSamplesOf(nodeData);
        const sample = samples ? samples[0] : null;
        const sampleId = sample ? sample.sampleId : null;
        let sampleResult = sampleId;

        if (params && params.parseSample) {
          sampleResult = params.parseSample(sampleResult);
        }

        object.type = 'sample';
        object.sample = sampleResult;
      }
      res = res || [];

      res.push(object);

    }
    if (!isObject) {
      const newRes = this._getSampleInfoPerComponentOfChildren(children, lookingForType, params, res);

      if (newRes) {
        res = newRes;
      }
    }

    return res;
  }

  getSampleInfoPerComponent(node, params = null, resultArray = null) {
    let n = node;

    if (!n) {
      n = this._parsedData ? this._parsedData.content : null;
    }

    return this._getSampleInfoPerComponent(n, 'object', params, resultArray);
  }
}
