import QuiltAsset from '../asset/QuiltAsset';
import QuiltDA from '../quilt/QuiltDA';
import QuiltTypes from '../quilt/QuiltTypes';
import QuiltConfig from '../quilt/QuiltConfig';
import MattressDA from '../mattress/MattressDA';
import MattressGeomUtils from '../geom/MattressGeomUtils';
import BD3DSampleFabricMaterial from './BD3DSampleFabricMaterial';
import SampleTransform from './SampleTransform';
import QuiltTransform from './QuiltTransform';
import MaterialTypes from './MaterialTypes';
import Node3DMaterialUtils from './Node3DMaterialUtils';

export default class FabricMaterialUtils {
  static assignMaterialToNode(node, sampleConfigData, quiltConfigData, singlePart, buildParams, materialType = null, recursive = false, resultMaterial = null) {
    if (!node) {
      return;
    }
    let material = resultMaterial || Node3DMaterialUtils.getMaterial(node);
    let uvWorldTransform = MattressGeomUtils.getGeometryUVWorldTransform(node);

    if (!uvWorldTransform && material && material.getSampleTransform) {
      const st = material.getSampleTransform();

      uvWorldTransform = st && st.getGeometryUVData ? st.getGeometryUVData() : null;
    }
    const resMtl = this._getMaterialFromData(sampleConfigData, quiltConfigData, buildParams, uvWorldTransform, node, materialType, material, singlePart);

    material = resMtl || material;

    if (recursive) {
      Node3DMaterialUtils.assignMaterial(node, material);
    } else {
      Node3DMaterialUtils.setMaterial(node, material);
    }
  }

  static getBorderMaterialFromData(sampleConfigData, quiltConfigData, buildParams, uvWorldTransform, materialType = null, mtl = null) {
    return this.getMaterialFromData(sampleConfigData, quiltConfigData, buildParams, uvWorldTransform, materialType, mtl, 'border');
  }

  static getMaterialFromData(sampleConfigData, quiltConfigData, buildParams, uvWorldTransform, materialType = null, mtl = null, singlePart = null) {
    return this._getMaterialFromData(sampleConfigData, quiltConfigData, buildParams, uvWorldTransform, null, materialType, mtl, singlePart);
  }

  static _getMaterialFromData(sampleConfigData, quiltConfigData, buildParams, uvWorldTransform, node, mtlType = null, mtl = null, singlePart = null) {
    let quiltAsset = null;
    const quiltType = QuiltTypes.getTypeName(QuiltDA.getType(quiltConfigData));

    let material = mtl;

    if (!(material instanceof BD3DSampleFabricMaterial)) {
      material = new BD3DSampleFabricMaterial();
    }

    material.clear();

    if (quiltType === QuiltTypes.GRID || quiltType === 'custom') {
      let ud = null;

      if (node) {
        ud = node.userData;
        if (!ud) {
          ud = node.userData = {};
        }
      }
      if (!ud) {
        ud = material.userData;
        if (!ud) {
          ud = material.userData = {};
        }
      }

      quiltAsset = ud.gridQuiltAsset;
      if (!quiltAsset) {
        quiltAsset = ud.gridQuiltAsset = new QuiltAsset();
      }
      quiltAsset.setQuiltConfigData(quiltConfigData);
    } else {
      const quiltID = MattressDA.getQuiltID(quiltConfigData);

      if (quiltID !== null && typeof (quiltID) !== 'undefined') {
        quiltAsset = buildParams.assetCollections.quilts.getAssetByName(quiltID);
      } else if (quiltConfigData && quiltConfigData.img && quiltConfigData.img.src) {
        const quiltURL = quiltConfigData.img.src;

        quiltAsset = buildParams.assetCollections.quilts.getAssetByName(quiltURL);
      }
    }
    const sampleID = MattressDA.getSampleID(sampleConfigData);

    const sampleAsset = buildParams.assetCollections.samples.getAssetByName(sampleID);
    // const uvWorldTransform = MattressGeomUtils.getGeometryUVWorldTransform(node);

    const quiltData = quiltAsset ? quiltAsset.quiltData : null;
    const sampleData = sampleAsset ? sampleAsset.sampleData : null;

    let quiltConfig = material.getQuiltConfig();

    if (!quiltConfig) {
      quiltConfig = new QuiltConfig();
      material.setQuiltConfig(quiltConfig);
    }
    quiltConfig.setQuiltAsset(quiltAsset);
    quiltConfig.setConfigData(quiltConfigData);
    quiltConfig.setData(quiltData);

    material.setQuiltAsset(quiltAsset);
    material.setSampleAsset(sampleAsset);

    // assign sample transform
    let sampleTransf = material.getSampleTransform();
    let quiltTransf = material.getQuiltTransform();

    if (!sampleTransf) {
      sampleTransf = new SampleTransform();
    }
    if (!quiltTransf) {
      quiltTransf = new QuiltTransform();
    }

    if (sampleAsset) {
      sampleTransf.setSourceAspectRatio(sampleAsset.getImageWidth(), sampleAsset.getImageHeight());
    }
    if (quiltConfig) {
      quiltTransf.setSourceAspectRatio(quiltConfig.getImageWidth(), quiltConfig.getImageHeight());
    }

    let fallbackSampleWidth = 1;
    let fallbackSampleHeight = 1;
    let fallbackQuiltWidth = 1;
    let fallbackQuiltHeight = 1;

    if (sampleAsset) {
      fallbackSampleWidth = sampleAsset.getFallbackWidth();
      fallbackSampleHeight = sampleAsset.getFallbackHeight();
    }
    if (quiltAsset) {
      fallbackQuiltWidth = quiltAsset.getFallbackWidth();
      fallbackQuiltHeight = quiltAsset.getFallbackHeight();
    }

    // set fallback size
    sampleTransf.setRealSize(fallbackSampleWidth, fallbackSampleHeight);
    sampleTransf.setSampleConfigData(sampleConfigData);
    sampleTransf.setSampleData(sampleData);
    sampleTransf.setGeometryUVData(uvWorldTransform);

    quiltTransf.setRealSize(fallbackQuiltWidth, fallbackQuiltHeight);
    quiltTransf.setQuiltConfigData(quiltConfigData);
    quiltTransf.setQuiltData(quiltData);
    quiltTransf.setGeometryUVData(uvWorldTransform);

    material.setSampleTransform(sampleTransf);
    material.setQuiltTransform(quiltTransf);

    if (!sampleAsset) {
      const assetCollections = buildParams ? buildParams.assetCollections : null;

      const defFabricColor = assetCollections.commonTextures.getAssetByName('defaultfabric.color');
      const defFabricNormal = assetCollections.commonTextures.getAssetByName('defaultfabric.normal');
      let realW = 2;
      let realH = 2;

      if (defFabricColor && defFabricColor.metaData && defFabricColor.metaData.realSize) {
        const realSize = defFabricColor.metaData.realSize;

        realW = realSize.width;
        realH = realSize.height;
      }

      material.setSampleTexture(defFabricColor);
      material.setSampleNormalMap(defFabricNormal);
      sampleTransf.setSampleConfigData(null);
      sampleTransf.setSampleData(null);
      sampleTransf.setRealWidth(realW);
      sampleTransf.setRealHeight(realH);
    }

    if (sampleTransf) {
      sampleTransf.setSinglePart(singlePart);
    }
    if (quiltTransf) {
      quiltTransf.setSinglePart(singlePart);
    }

    material.setType(mtlType || MaterialTypes.FABRIC);

    return material;
  }
}
