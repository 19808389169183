/* global Float32Array, Uint32Array, Map */
/* jslint2 unused: false */

import Vertex from '../geom/Vertex';
import Vector from '../geom/Vector';
import Vector2 from '../geom/Vector2';
import Vector3 from '../geom/Vector3';
import Vector4 from '../geom/Vector4';
import Polygon from '../geom/Polygon';
import Geometry from '../geom/Geometry';
import VectorMath from '../math/VectorMath';

import Utils from '../../common/utils/Utils';
// import Geometry from '../geom/Geometry';

// Internal utility functions

function callCallback(callback, ...args) {
  return callback(...args);
}

function findVertexNormal(vertex) {
  if (!vertex) {
    return null;
  }
  let res = null;

  if (vertex.position instanceof Vertex) {
    if (vertex.position.attributes) {
      res = vertex.position.attributes.normal;
    }
  }
  if (!res) {
    if (vertex.attributes) {
      res = vertex.attributes.normal;
    }
  }

  return res;
}

// #if DEBUG
function timeStart(name) {
  // console.time(name);

  return;
}

function timeEnd(name) {
  // console.timeEnd(name);
  return;
}
// #endif

function tryValues(...values) {
  return Utils.tryValues(...values);
}

function swizzleCoord(x, y, z, coord) {
  if (coord === 0) {
    return x;
  }
  if (coord === 1) {
    return y;
  }
  if (coord === 2) {
    return z;
  }

  return 0;
}

export default class GeomUtils {
  /*
  static _initBoundingBox(box, x, y, z, force) {
    if (!box) {
      return;
    }
    if (force || typeof (box.minx) === 'undefined') {
      box.minx = x;
    }
    if (force || typeof (box.miny) === 'undefined') {
      box.minx = y;
    }
    if (force || typeof (box.minz) === 'undefined') {
      box.minz = z;
    }
    if (force || typeof (box.maxx) === 'undefined') {
      box.maxx = x;
    }
    if (force || typeof (box.maxy) === 'undefined') {
      box.maxx = y;
    }
    if (force || typeof (box.maxz) === 'undefined') {
      box.maxz = z;
    }
  }

  static _expandBoundingBoxByVectorCoords(x, y, z, result = null) {
    let valid = true;
    let res = result;

    if (res) {
      valid = res.valid !== false;
    } else {
      res = {};
      valid = false;
    }

    if (valid) {
      GeomUtils._initBoundingBox(res, x, y, z);

      res.minx = x < res.minx ? x : res.minx;
      res.miny = y < res.miny ? y : res.miny;
      res.minz = z < res.minz ? z : res.minz;

      res.maxx = x > res.maxx ? x : res.maxx;
      res.maxy = y > res.maxy ? y : res.maxy;
      res.maxz = z > res.maxz ? z : res.maxz;
    } else {
      GeomUtils._initBoundingBox(res, x, y, z, true);
    }

    return res;
  }

  static _expandBoundingBoxByVector(vector, res = null) {
    if (!vector) {
      return res;
    }
    const x = vector.getCoord(0);
    const y = vector.getCoord(1);
    const z = vector.getCoord(2);

    return GeomUtils._expandBoundingBoxByVectorCoords(x, y, z, res);
  }

  static _expandBoundingBoxByVectors(vectors, result = null) {
    if (!vectors) {
      return result;
    }
    const num = vectors.length;

    if (num === 0) {
      return result;
    }
    let res = result;

    for (let i = 0; i < num; ++i) {
      const vec = vectors[i];

      res = GeomUtils._expandBoundingBoxByVector(vec, res);
    }

    return res;
  }

  static _expandBoundingBoxByPolygon(poly, res = null) {
    if (!poly) {
      return res;
    }
    const verts = poly.vertices;

    return GeomUtils._expandBoundingBoxByVectors(verts, res);
  }

  static _expandBoundingBoxByPolygons(polys, result = null) {
    if (!polys) {
      return result;
    }
    const num = polys.length;

    if (num === 0) {
      return result;
    }
    let res = result;

    for (let i = 0; i < num; ++i) {
      const poly = polys[i];

      res = GeomUtils._expandBoundingBoxByPolygon(poly, res);
    }

    return res;
  }

  static _getGeometryBounds(geometry, result = null) {
    if (result) {
      if (result.valid === false) {
        result.minx = result.maxx = 0;
        result.miny = result.maxy = 0;
        result.minz = result.maxz = 0;
        result.width = result.height = 0;
      }
    }
    if (!geometry) {
      return result;
    }

    const polys = geometry.polygons;

    let res = result;

    res = GeomUtils._expandBoundingBoxByPolygons(polys, res);

    if (res) {
      res.width = res.maxx - res.minx;
      res.height = res.maxy - res.miny;
      res.depth = res.maxz - res.minz;
    }

    return res;
  }
  */

  static _loopVertsOfPoly(verts, polyIndex, callback, callbackData) {
    if (!verts) {
      return;
    }
    const numv = verts.length;

    if (numv === 0) {
      return;
    }

    for (let j = 0; j < numv; ++j) {
      const vert = verts[j];

      if (vert) {
        callCallback(callback, vert, polyIndex, j, callbackData);
      }
    }
  }

  static loopPolyVerts(polys, callback, callbackData) {
    if (!polys) {
      return;
    }
    const num = polys.length;

    if (num === 0) {
      return;
    }

    for (let i = 0; i < num; ++i) {
      const poly = polys[i];

      if (poly) {
        const verts = poly.vertices;

        GeomUtils._loopVertsOfPoly(verts, i, callback, callbackData);
      }
    }
  }

  static getVerticesFromPolys(polys, result = null) {
    let res = result;
    let callbackData = this._getVerticesFromPolysCallbackData;

    if (!callbackData) {
      callbackData = this._getVerticesFromPolysCallbackData = {
        data: {},
        callback: (vert, polyIndex, vertIndex, data) => {
          let arr = data.array;
          let map = data.map;

          if (!map) {
            map = data.map = new WeakMap();
          }
          if (!map.has(vert)) {
            map.set(vert, true);
            if (!arr) {
              arr = data.array = [];
            }
            arr.push(vert);
          }
        }
      };
    }

    callbackData.data.array = res;
    callbackData.data.map = null;
    GeomUtils.loopPolyVerts(polys, callbackData.callback, callbackData.data);
    callbackData.data.map = null;
    if (!res) {
      res = callbackData.data.array;
    }

    return res;
  }

  static getVertexPositionsFromPolys(polys, result = null) {
    let res = result;
    let callbackData = this._getVertexPositionsFromPolysCallbackData;

    if (!callbackData) {
      callbackData = this._getVertexPositionsFromPolysCallbackData = {
        data: {},
        callback: (vert, polyIndex, vertIndex, data) => {
          let arr = data.array;
          let map = data.map;

          if (!map) {
            map = data.map = new WeakMap();
          }
          const pos = vert.position;

          if (!map.has(pos)) {
            map.set(pos, true);
            if (!arr) {
              arr = data.array = [];
            }
            arr.push(pos);
          }
        }
      };
    }

    callbackData.data.array = res;
    callbackData.data.map = null;
    GeomUtils.loopPolyVerts(polys, callbackData.callback, callbackData.data);
    callbackData.data.map = null;
    if (!res) {
      res = callbackData.data.array;
    }

    return res;
  }

  static getUVsFromPolygons(polys, res = null) {
    return GeomUtils.getAttributeDataFromPolygons(polys, 'uv', res);
  }

  static getNormalsFromPolygons(polys, res = null) {
    return GeomUtils.getAttributeDataFromPolygons(polys, 'normal', res);
  }

  static getAttributeDataFromPolygons(polys, attributeName, result = null) {
    if (!attributeName || !polys || polys.length === 0) {
      return result;
    }

    let res = result;
    let callbackData = this._getVertexAttributesFromPolysCallbackData;

    if (!callbackData) {
      callbackData = this._getVertexAttributesFromPolysCallbackData = {
        data: {},
        callback: (vert, polyIndex, vertIndex, data) => {
          if (!vert) {
            return;
          }
          const attributes = vert.attributes;

          if (!attributes) {
            return;
          }
          const attName = data.attributeName;
          const att = attributes[attName];

          if (!att) {
            return;
          }

          let arr = data.array;
          let map = data.map;

          if (!map) {
            map = data.map = new WeakMap();
          }

          if (!map.has(att)) {
            map.set(att, true);
            if (!arr) {
              arr = data.array = [];
            }
            arr.push(att);
          }
        }
      };
    }

    callbackData.data.array = res;
    callbackData.data.map = null;
    callbackData.data.attributeName = attributeName;
    GeomUtils.loopPolyVerts(polys, callbackData.callback, callbackData.data);
    callbackData.data.map = null;
    if (!res) {
      res = callbackData.data.array;
    }

    return res;
  }

  static getEdgeNormal(p1, p2, xcoord = 0, ycoord = 1, result = null) {
    let res = result;
    const x1 = p1.getCoord(xcoord);
    const y1 = p1.getCoord(ycoord);
    const x2 = p2.getCoord(xcoord);
    const y2 = p2.getCoord(ycoord);

    const dx = x1 - x2;
    const dy = y1 - y2;
    let d = dx * dx + dy * dy;
    let ux = dx;
    let uy = dy;

    if (d !== 0 && d !== 1) {
      d = Math.sqrt(d);
      const invD = 1.0 / d;

      ux *= invD;
      uy *= invD;
    }
    const nx = -uy;
    const ny = ux;

    if (res) {
      res.setCoord(0, 0);
      res.setCoord(1, 0);
      res.setCoord(2, 0);
    } else {
      res = new Vector3(0, 0, 0);
    }
    res.setCoord(xcoord, nx);
    res.setCoord(ycoord, ny);

    return res;
  }

  static computePathVertexNormals(path, isClosedPath, xcoord = 0, ycoord = 1, zcoord = 2, scale = 1) {
    if (!path) {
      return;
    }
    let closed = isClosedPath;
    let sc = scale;
    let num = path.length;

    if (num === 0) {
      return;
    }
    if (sc === undefined || sc === null) {
      sc = 1;
    }
    const half = 0.5;

    if (path[0] === path[num - 1]) {
      --num;
      closed = true;
    }

    for (let i = 0; i < num; ++i) {
      let prevI = i - 1;
      let nextI = i + 1;

      if (!closed) {
        prevI %= num;
        nextI %= num;
        if (prevI < 0) {
          prevI += num;
        }
      }

      const pt = path[i];
      const prevPt = (prevI >= 0 && prevI < num) ? path[prevI] : null;
      const nextPt = (nextI >= 0 && nextI < num) ? path[nextI] : null;

      let nrm = findVertexNormal(pt);
      let nx1 = 0, ny1 = 0, nz1 = 0, nx2 = 0, ny2 = 0, nz2 = 0,
        nx = 0, ny = 0, nz = 0;

      if (nrm) {
        nx = nrm.getCoord(xcoord);
        ny = nrm.getCoord(ycoord);
        nz = nrm.getCoord(zcoord);
      }

      if (prevPt) {
        nrm = GeomUtils.getEdgeNormal(prevPt, pt, xcoord, ycoord, nrm);
        if (nrm) {
          nx1 = nrm.getCoord(xcoord);
          ny1 = nrm.getCoord(ycoord);
          nz1 = nrm.getCoord(zcoord);
        }
      }

      if (nextPt) {
        nrm = GeomUtils.getEdgeNormal(pt, nextPt, xcoord, ycoord, nrm);
        if (nrm) {
          nx2 = nrm.getCoord(xcoord);
          ny2 = nrm.getCoord(ycoord);
          nz2 = nrm.getCoord(zcoord);
        }
      }

      // calculate average between 2 normals
      if (prevPt && nextPt) {
        nx = (nx1 + nx2) * half;
        ny = (ny1 + ny2) * half;
        nz = (nz1 + nz2) * half;
      } else if (prevPt) {
        nx = nx1;
        ny = ny1;
        nz = nz1;
      } else if (nextPt) {
        nx = nx2;
        ny = ny2;
        nz = nz2;
      }

      // normalize average
      let n = nx * nx + ny * ny + nz * nz;

      if (n !== 0 && n !== 1) {
        n = 1.0 / Math.sqrt(n);
        nx *= n;
        ny *= n;
        nz *= n;
      }
      if (!nrm) {
        nrm = new Vector3();
      }
      nx *= sc;
      ny *= sc;
      nz *= sc;

      nrm.setCoord(xcoord, nx);
      nrm.setCoord(ycoord, ny);
      nrm.setCoord(zcoord, nz);

      GeomUtils.assignVertexAttribute(pt, 'normal', nrm);
      GeomUtils.assignVertexAttribute(pt.position, 'normal', nrm);
    }
  }

  static assignVertexAttribute(vertex, name, value) {
    if (!vertex) {
      return;
    }
    if (!(vertex instanceof Vertex)) {
      return;
    }
    let attributes = vertex.attributes;

    if (!attributes) {
      if (value !== undefined && value !== null) {
        attributes = vertex.attributes = {};
      }
    }
    if (attributes) {
      attributes[name] = value;
    }
  }

  /**
   * @method computePathUV
   * @static
   * @description computes the U or the V coordinate based on the distance between
   * the path's vertices
   * @param {Array} path - array of Vectors
   * @param {int} coord - which coord to set, U (0) or V(1).
   * U (0) means horizontal layout from left to right,
   * V (1) means vertical layout from top to bottom
   * @return {void}
   **/
  static computePathUV(path, coord = 0) {
    if (!path) {
      return;
    }
    const num = path.length;

    if (num === 0) {
      return;
    }

    let curD = 0;
    let prevV;

    for (let i = 0; i < num; ++i) {
      const v = path[i];

      if (v) {
        if (prevV) {
          const dx = v.getCoord(0) - prevV.getCoord(0);
          const dy = v.getCoord(1) - prevV.getCoord(1);
          const dz = v.getCoord(2) - prevV.getCoord(2);
          const d = Math.sqrt(dx * dx + dy * dy + dz * dz);

          curD += d;
        }
        let uv = null;

        if (!v.userData) {
          v.userData = {};
        }
        v.userData.distance = curD;
        if (v.attributes) {
          uv = v.attributes.uv;
        }
        if (!uv) {
          uv = new Vector2();
          if (!v.attributes) {
            v.attributes = {};
          }
          v.attributes.uv = uv;
        }
        uv.setCoord(0, 0);
        uv.setCoord(1, 0);
        uv.setCoord(coord, curD);
        prevV = v;
      }
    }
  }

  static addCornerArc(fromX, fromY, toX, toY, centerX, centerY, includeFirst,
    includeLast, isLinear, xcoord, ycoord, zcoord, path, counter, onNewPoint, params) {

    let linear = isLinear;
    const fromDX = fromX - centerX;
    const fromDY = fromY - centerY;
    // var fromD = fromDX * fromDX + fromDY * fromDY;
    const toDX = toX - centerX;
    const toDY = toY - centerY;
    // var toD = toDX * toDX + toDY * toDY;

    const dX = toX - fromX;
    const dY = toY - fromY;
    let d = dX * dX + dY * dY;
    const half = 0.5;

    if (d !== 0 && d !== 1) {
      d = Math.sqrt(d);
    }
    let subDivs = 10;

    if (params) {
      let ellipseSubdivs = params.ellipseSubdivisions;
      let ellipseSpacing = params.ellipseSpacing;

      if (ellipseSubdivs === undefined || ellipseSubdivs === null) {
        ellipseSubdivs = params.cornerSubdivisions;
      }
      if (ellipseSubdivs === undefined || ellipseSubdivs === null) {
        ellipseSubdivs = params.subdivisions;
      }
      if (ellipseSpacing === undefined || ellipseSpacing === null) {
        ellipseSpacing = params.spacing;
      }
      if (ellipseSubdivs !== undefined && ellipseSubdivs !== null) {
        subDivs = ellipseSubdivs;
      } else if (ellipseSpacing !== undefined && ellipseSpacing !== null) {
        const spacing = ellipseSpacing;

        subDivs = d / spacing;


        subDivs = (subDivs + half) | 0;
      }
    }
    if (linear === undefined || linear === null) {
      linear = 0;
    }
    if (subDivs > 0) {
      const firstIndex = includeFirst ? 0 : 1;
      const lastIndex = includeLast ? subDivs : subDivs - 1;

      for (let i = firstIndex; i <= lastIndex; ++i) {
        const pct = i / subDivs;
        const a = pct * Math.PI * half;
        let x = Math.cos(a);
        let y = Math.sin(a);
        const tx = x * fromDX + y * toDX + centerX;
        const ty = x * fromDY + y * toDY + centerY;

        x = tx;
        y = ty;

        const ix = fromX + dX * pct;
        const iy = fromY + dY * pct;

        x = x + (ix - x) * linear;
        y = y + (iy - y) * linear;

        const X = swizzleCoord(x, y, 0, xcoord);
        const Y = swizzleCoord(x, y, 0, ycoord);
        const Z = swizzleCoord(x, y, 0, zcoord);

        GeomUtils._addPathVertex(X, Y, Z, path, counter, onNewPoint);
      }
    }
  }

  static generateRoundRectPath(width, depth, cornerRadius, splinePath, params) {
    let path = splinePath;
    const half = 0.5;
    const hW = width * half;
    const hH = depth * half;
    let cR = cornerRadius;

    if (hW < cR) {
      cR = hW;
    }
    if (hH < cR) {
      cR = hH;
    }
    if (cR < 0) {
      cR = 0;
    }
    if (!path) {
      path = [];
    }
    const xc = 0;
    const yc = 2;
    const zc = 1;

    const counter = {value: 0};

    if (cR > 0) {
      GeomUtils.addLine(-hW, hH - cR, -hW, -hH + cR, true, true, xc, yc, zc, path, counter, GeomUtils._onAddPointL, params);

      GeomUtils.addCornerArc(
        -hW, -hH + cR,
        -hW + cR, -hH,
        -hW + cR, -hH + cR, false, false, 0, xc, yc, zc, path, counter, GeomUtils._onAddPointTL, params);

      GeomUtils.addLine(-hW + cR, -hH, hW - cR, -hH, true, true, xc, yc, zc, path, counter, GeomUtils._onAddPointT, params);

      GeomUtils.addCornerArc(
        hW - cR, -hH,
        hW, -hH + cR,
        hW - cR, -hH + cR, false, false, 0, xc, yc, zc, path, counter, GeomUtils._onAddPointTR, params);

      // addPathVertex(hW, 0, -hH + cR, path, counter);
      // addPathVertex(hW, 0, hH - cR, path, counter);
      GeomUtils.addLine(
        hW, -hH + cR,
        hW, hH - cR,
         true, true, xc, yc, zc, path, counter, GeomUtils._onAddPointR, params);

      GeomUtils.addCornerArc(
        hW, hH - cR,
        hW - cR, hH,
        hW - cR, hH - cR, false, false, 0, xc, yc, zc, path, counter, GeomUtils._onAddPointBR, params);

      GeomUtils.addLine(
        hW - cR, hH,
        -hW + cR, hH,
         true, true, xc, yc, zc, path, counter, GeomUtils._onAddPointB, params);

      GeomUtils.addCornerArc(
        -hW + cR, hH,
        -hW, hH - cR,
        -hW + cR, hH - cR, false, false, 0, xc, yc, zc, path, counter, GeomUtils._onAddPointBL, params);

    } else {
      // left edge
      GeomUtils.addLine(-hW, hH,
        -hW, -hH,
        true, true, xc, yc, zc, path, counter, GeomUtils._onAddPointL, params);

      GeomUtils.addLine(-hW, -hH,
        hW, -hH,
        false, true, xc, yc, zc, path, counter, GeomUtils._onAddPointT, params);

      GeomUtils.addLine(hW, -hH,
        hW, hH,
        false, true, xc, yc, zc, path, counter, GeomUtils._onAddPointR, params);

      GeomUtils.addLine(hW, hH,
        -hW, hH,
        false, false, xc, yc, zc, path, counter, GeomUtils._onAddPointB, params);
    }
    const lastIndex = counter.value;
    let lastVert = path[lastIndex];
    const firstVert = path[0];

    if (!lastVert) {
      lastVert = new Vertex(firstVert.position);
      path[lastIndex] = lastVert;
    }
    lastVert.position = firstVert.position;
    path.length = lastIndex + 1;

    const computeNormals = true;
    const computeUVs = true;

    if (computeNormals) {
      GeomUtils.computePathVertexNormals(path, 0, 2);
    }
    if (computeUVs) {
      GeomUtils.computePathUV(path, 0);
    }

    // bottomleft corner
    return path;
  }

  static _addPathVertex(x, y, z, path, counter, callback) {
    let index = counter.value;

    if (index === undefined || index === null) {
      index = counter.value = 0;
    }
    ++counter.value;
    const res = GeomUtils._setPathVertex(x, y, z, path, index);

    if (callback) {
      callback(res, index);

      return res;
    }

    return res;
  }

  static _setPathVertex(x, y, z, path, index) {
    let vert = path[index];
    let pos = null;

    if (!vert) {
      pos = new Vertex(new Vector3(x, y, z));
      vert = new Vertex(pos);
      path[index] = vert;
    }

    if (!vert.position) {
      vert.position = new Vertex(new Vector3(x, y, z));
    }
    vert.position.x = x;
    vert.position.y = y;
    vert.position.z = z;

    return vert;
  }

  static addLine(fromX, fromY, toX, toY, includeFirst, includeLast, xcoord, ycoord, zcoord, path, counter, onNewPoint, params) {
    let subDivs = 0;
    let d = 0;
    const dx = toX - fromX;
    const dy = toY - fromY;
    const half = 0.5;

    if (params) {
      let lineSubdivs = params.lineSubdivisions;
      let lineSpacing = params.lineSpacing;

      if (lineSubdivs === undefined || lineSubdivs === null) {
        lineSubdivs = params.subdivisions;
      }
      if (lineSpacing === undefined || lineSpacing === null) {
        lineSpacing = params.spacing;
      }
      if (lineSubdivs !== undefined && lineSubdivs !== null) {
        subDivs = lineSubdivs;
      } else if (lineSpacing !== undefined && lineSpacing !== null) {
        d = dx * dx + dy * dy;
        if (d !== 0 && d !== 1) {
          d = Math.sqrt(d);
        }
        subDivs = d / lineSpacing;
        subDivs = (subDivs + half) | 0;
      }
    }

    let X, Y, Z;

    if (subDivs > 0) {
      const firstIndex = includeFirst ? 0 : 1;
      const lastIndex = includeLast ? subDivs : subDivs - 1;

      for (let i = firstIndex; i <= lastIndex; ++i) {
        const pct = i / subDivs;
        const x = fromX + dx * pct;
        const y = fromY + dy * pct;

        X = swizzleCoord(x, y, 0, xcoord);
        Y = swizzleCoord(x, y, 0, ycoord);
        Z = swizzleCoord(x, y, 0, zcoord);

        GeomUtils._addPathVertex(X, Y, Z, path, counter, onNewPoint);
      }
    } else {
      if (includeFirst) {
        X = swizzleCoord(fromX, fromY, 0, xcoord);
        Y = swizzleCoord(fromX, fromY, 0, ycoord);
        Z = swizzleCoord(fromX, fromY, 0, zcoord);
        GeomUtils._addPathVertex(X, Y, Z, path, counter, onNewPoint);
      }
      if (includeLast) {
        X = swizzleCoord(toX, toY, 0, xcoord);
        Y = swizzleCoord(toX, toY, 0, ycoord);
        Z = swizzleCoord(toX, toY, 0, zcoord);
        GeomUtils._addPathVertex(X, Y, Z, path, counter, onNewPoint);
      }
    }
  }

  // Find uv coordinates from polygons + normalize
  static normalizePolygonUVs(polys, params = null, outputData = null, resultArray = null) {
    if (!polys) {
      return resultArray;
    }
    // #if DEBUG
    timeStart('collect mesh uvs');
    // #endif
    const uvs = GeomUtils.getUVsFromPolygons(polys, resultArray);

    // #if DEBUG
    timeEnd('collect mesh uvs');
    // #endif

    // #if DEBUG
    timeStart('normalize mesh uvs');
    // #endif
    GeomUtils.normalizeUVs(uvs, params, outputData);
    // #if DEBUG
    timeEnd('normalize mesh uvs');
    // #endif

    return resultArray;
  }

  /**
   * @method normalizeUVs
   * @static
   * @description Scales uv coordinates to fit a rectangle of 1 by 1
   * @param {Array} uvs - Array of Vector2 instances
   * @param {Object} params - optional object containing parameters:
   *  - keepUVAspect | uvKeepAspect {Boolean} - keep aspect ratio
   *  - uvAlignX {Number} - x alignment of the uvs (0 = left, 0.5 = center = default, 1 = right)
   *  - uvAlignY {Number} - y alignment of the uvs (0 = top, 0.5 = middle = default, 1 = bottom)
   *  - maxUVFit {boolean}
   *      If true,
   *     _______               _ _ ___________ _ _
   *    |_ _ _ _|             |     |       |     |
   *    |_u_ _v_| will become |  U  |       |  V  |
   *    |_______|             |_ _ _|_______|_ _ _|
   *    0       1                   0       1
   *
   *  - minScale {Number} - the minimum scale factor
   *  - maxScale {Number} - the maximum scale factor
   * @param {Object} output - optional object where extra info will be stored:
   *  - minU {Number}
   *  - maxU {Number}
   *  - minV {Number}
   *  - maxV {Number}
   *  - scaleU {Number}
   *  - scaleV {Number}
   *  - translateU {Number}
   *  - translateV {Number}
   * @return {void}
   * */
  static normalizeUVs(uvs, params = null, output = null) {
    if (!uvs) {
      return;
    }
    let keepAspect = true;

    if (params) {
      keepAspect = (params.keepUVAspect !== false && params.uvKeepAspect !== false);
    }
    const num = uvs.length;
    let minU, maxU, minV, maxV;
    let first = true;

    for (let i = 0; i < num; ++i) {
      const uv = uvs[i];

      if (uv) {
        const u = uv.getCoord(0);
        const v = uv.getCoord(1);

        if (first) {
          first = false;
          minU = maxU = u;
          minV = maxV = v;
        } else {
          minU = u < minU ? u : minU;
          minV = v < minV ? v : minV;
          maxU = u > maxU ? u : maxU;
          maxV = v > maxV ? v : maxV;
        }
      }
    }
    if (first) {
      return;
    }
    const W = maxU - minU;
    const H = maxV - minV;
    let scaleX = W === 0 ? 0 : 1 / W;
    let scaleY = H === 0 ? 0 : 1 / H;
    let alignX = 0.5;
    let alignY = 0.5;

    if (params) {
      if (typeof (params.uvAlignX) === 'number' && !isNaN(params.uvAlignX)) {
        alignX = params.uvAlignX;
      }
      if (typeof (params.uvAlignY) === 'number' && !isNaN(params.uvAlignY)) {
        alignY = params.uvAlignY;
      }
    }

    if (keepAspect) {
      let scale = 1;

      if (params && (params.maxUVFit === true || params.uvMaxFit === true)) {
        // Keep aspect ratio but try to fill as much space as possible
        // Some parts of the geometry could be placed outside
        // the normalized uv box
        scale = (scaleX > scaleY) ? scaleX : scaleY;
      } else {
        // Tries to fit everything inside the uv box so no geometry will be
        // placed outside the normalized uv box
        scale = (scaleX < scaleY) ? scaleX : scaleY;
      }

      if (params && typeof (params.maxScale) === 'undefined' && !isNaN(params.maxScale)) {
        scale = scale > params.maxScale ? params.maxScale : scale;
      }
      if (params && typeof (params.minScale) === 'undefined' && !isNaN(params.minScale)) {
        scale = scale < params.minScale ? params.minScale : scale;
      }

      scaleX = scale;
      scaleY = scale;
    }
    if (params && typeof (params.minScale) === 'number' && !isNaN(params.minScale)) {
      scaleX = scaleX < params.minScale ? params.minScale : scaleX;
      scaleY = scaleY < params.minScale ? params.minScale : scaleY;
    }
    if (params && typeof (params.maxScale) === 'number' && !isNaN(params.maxScale)) {
      scaleX = scaleX > params.maxScale ? params.maxScale : scaleX;
      scaleY = scaleY > params.maxScale ? params.maxScale : scaleY;
    }
    const newW = W * scaleX;
    const newH = H * scaleY;

    // const translateX = minU * scaleX + alignX - newW * alignX;
    // const translateY = minV * scaleY + alignY - newH * alignY;
    const translateX = -minU * scaleX + alignX - newW * alignX;
    const translateY = -minV * scaleY + alignY - newH * alignY;

    if (output) {
      output.minU = minU;
      output.minV = minV;
      output.maxU = maxU;
      output.maxV = maxV;
      output.scaleU = scaleX;
      output.scaleV = scaleY;
      output.translateU = translateX;
      output.translateV = translateY;
    }

    for (let i = 0; i < num; ++i) {
      const uv = uvs[i];

      if (uv) {
        // const u = (uv.getCoord(0) - minU) * scaleX + alignX - newW * alignX;
        // const v = (uv.getCoord(1) - minV) * scaleY + alignY - newH * alignY;
        const u = uv.getCoord(0) * scaleX + translateX;
        const v = uv.getCoord(1) * scaleY + translateY;

        uv.setCoord(0, u);
        uv.setCoord(1, v);
      }
    }
  }

  static _clearPolygonTriangles(polygon) {
    if (!polygon) {
      return;
    }
    const ud = polygon.userData;

    if (!ud) {
      return;
    }
    if (!ud.triangles) {
      return;
    }
    ud.triangles.length = 0;
  }

  static _assignTriangleToPolygon(triangle, polygon) {
    if (!triangle) {
      return;
    }
    if (!polygon) {
      return;
    }
    let polyData = polygon.userData;

    if (!polyData) {
      polyData = polygon.userData = {};
    }

    if (!polyData.triangles) {
      polyData.triangles = [];
    }

    polyData.triangles.push(triangle);
  }

  static triangulatePolygons(polys, params = null, triangles = null) {
    let tris = triangles;

    if (!polys) {
      return tris;
    }
    const num = polys.length;

    if (num === 0) {
      return tris;
    }
    for (let i = 0; i < num; ++i) {
      const poly = polys[i];

      if (poly) {
        tris = GeomUtils.triangulatePolygon(poly, params, tris);
      }
    }

    return tris;
  }

  static triangulatePolygon(poly, params = null, triangles = null) {
    let tris = triangles;

    if (!poly) {
      return tris;
    }

    const verts = poly.vertices;
    const _3 = 3;

    if (!verts) {
      return tris;
    }
    const numv = verts.length;

    if (numv < _3) {
      return tris;
    }
    let storeTrianglesPerPolygon = true;

    if (params) {
      storeTrianglesPerPolygon = params.storeTrianglesPerPolygon !== false;
    }
    if (storeTrianglesPerPolygon) {
      GeomUtils._clearPolygonTriangles(poly);
    }
    if (numv === _3) {
      if (!tris) {
        tris = [];
      }
      tris.push(poly);

      if (storeTrianglesPerPolygon) {
        GeomUtils._assignTriangleToPolygon(poly, poly);
      }

      return tris;
    }

    // Create triangle fan
    const v1 = verts[0];
    const count = numv - 1;

    for (let i = 1; i < count; ++i) {
      const v2 = verts[i];
      const v3 = verts[i + 1];

      if (v1 && v2 && v3) {
        const tri = new Polygon([
          v1, v2, v3
        ]);

        if (storeTrianglesPerPolygon) {
          GeomUtils._assignTriangleToPolygon(tri, poly);
        }
        if (!tris) {
          tris = [];
        }
        tris.push(tri);
      }
    }

    return tris;
  }

  static getVertexAttribute(vertex, name) {
    if (!vertex || !name) {
      return null;
    }
    const atts = vertex.attributes;

    if (!atts) {
      return null;
    }
    const a = atts[name];

    return a;
  }

  static setVertexAttribute(vertex, name, value) {
    if (!vertex || !name) {
      return;
    }
    let atts = vertex.attributes;

    if (!atts) {
      atts = vertex.attributes = {};
    }
    atts[name] = value;
  }

  static calculatePolygonVertexNormals(input) {
    return this._calculateVertexNormals(input, true);
  }

  static calculateVertexNormals(input) {
    return this._calculateVertexNormals(input, false);
  }

  static _calculateVertexNormals(input, usePolyVerts = true) {
    let polys = null;
    let geom = null;

    if (input instanceof Geometry) {
      geom = input;
      polys = geom.polygons;
    } else if (input instanceof Array && input[0] instanceof Polygon) {
      polys = input;
    }

    if (!polys) {
      return;
    }

    const numPolys = polys.length;

    let polysByVertex = null;
    let polyVertsByVertex = null;
    let vertices = null;

    for (let i = 0; i < numPolys; ++i) {
      const poly = polys[i];
      const verts = poly ? poly.vertices : null;
      const numverts = verts ? verts.length : 0;

      for (let j = 0; j < numverts; ++j) {
        let vert = verts[j];
        const polyVert = vert;

        if (!usePolyVerts && (vert instanceof Vertex) && (vert.position instanceof Vector)) {
          // Not using polygon vertices? Use the position vector of each vertex instead
          vert = vert.position;
        }
        if (!polysByVertex) {
          polysByVertex = new WeakMap();
        }
        let list = polysByVertex.get(vert);

        if (!usePolyVerts) {
          if (!polyVertsByVertex) {
            polyVertsByVertex = new WeakMap();
          }
          let polyVertsList = polyVertsByVertex.get(vert);

          if (!polyVertsList) {
            polyVertsList = [];
            polyVertsByVertex.set(vert, polyVertsList);
          }
          if (polyVertsList.indexOf(polyVert, 0) < 0) {
            polyVertsList.push(polyVert);
          }
        }

        if (!list) {
          list = [];
          if (!vertices) {
            vertices = [];
          }
          vertices.push(vert);

          // Vertex + triangle list association
          polysByVertex.set(vert, list);
        }

        list.push(poly);
      }
    }
    const numV = vertices.length;

    for (let i = 0; i < numV; ++i) {
      const vert = vertices[i];
      const vpolys = polysByVertex ? polysByVertex.get(vert) : null;
      const nump = vpolys ? vpolys.length : 0;

      if (vert instanceof Vertex) {
        let atts = vert.attributes;
        // let nrm = atts ? atts.normal : null;

        let nrm = new Vector3(0, 0, 1);

        let x = 0, y = 0, z = 0;

        for (let j = 0; j < nump; ++j) {
          const poly = vpolys[j];

          nrm = this._calcPolygonNormal(poly, true, nrm);

          x += nrm.getCoord(0);
          y += nrm.getCoord(1);
          z += nrm.getCoord(2);
        }
        const div = nump === 0 ? 0 : 1.0 / nump;

        x *= div;
        y *= div;
        z *= div;

        if (nrm) {
          nrm.setCoord(0, x);
          nrm.setCoord(1, y);
          nrm.setCoord(2, z);
        } else {
          nrm = new Vector3(x, y, z);
        }
        if (!atts) {
          atts = vert.attributes = {};
        }

        atts.normal = nrm;

        if (!usePolyVerts && polyVertsByVertex) {
          const polyVertslist = polyVertsByVertex.get(vert);

          this._assignNormalToVerts(polyVertslist, nrm);
        }
      }

    }
  }
  static _assignNormalToVerts(verts, normal) {
    if (!verts || !normal) {
      return;
    }
    const l = verts.length;

    if (l === 0) {
      return;
    }
    for (let i = 0; i < l; ++i) {
      const v = verts[i];

      if (v && v instanceof Vertex) {
        if (!v.attributes) {
          v.attributes = {};
        }
        v.attributes.normal = normal;
      }
    }
  }
  // signed polygon area = _calcPolygonNormal(polygon, false, result) / 2
  // polygon area = abs(signed polygon area)
  static _calcPolygonNormal(polygon, normalize = true, result = null) {
    if (!polygon) {
      return null;
    }
    const verts = polygon.vertices;
    const numV = verts.length;

    let res = result;

    if (numV === 0) {
      return null;
    }

    let x = 0;
    let y = 0;
    let z = 0;

    for (let i = 0; i < numV; ++i) {
      const i2 = (i + 1) % numV;

      const v1 = verts[i];
      const v2 = verts[i2];

      res = VectorMath.cross(v1, v2, res);

      if (res) {
        x += res.getCoord(0);
        y += res.getCoord(1);
        z += res.getCoord(2);
      }
    }
    if (res) {
      res.setCoord(0, x);
      res.setCoord(1, y);
      res.setCoord(2, z);
    }

    if (normalize) {
      res = VectorMath.normalize(res, res);
    }

    return res;
  }

  static _calculateTangentsForTriangleVertex(vertex, vertex2, vertex3, uvAttName, tgtAttName, btgAttName) {
    if (!vertex || !vertex2 || !vertex3) {
      return;
    }

    const pos = vertex.position;
    const uv = GeomUtils.getVertexAttribute(vertex, uvAttName);
    let tgt = GeomUtils.getVertexAttribute(vertex, tgtAttName);
    let btg = GeomUtils.getVertexAttribute(vertex, btgAttName);

    const pos2 = vertex2.position;
    const uv2 = GeomUtils.getVertexAttribute(vertex2, uvAttName);

    const pos3 = vertex3.position;
    const uv3 = GeomUtils.getVertexAttribute(vertex3, uvAttName);

    if (!uv || !uv2 || !uv3 || !pos || !pos2 || !pos3) {
      return;
    }

    const x1 = pos2.getCoord(0) - pos.getCoord(0);
    const x2 = pos3.getCoord(0) - pos.getCoord(0);
    const y1 = pos2.getCoord(1) - pos.getCoord(1);
    const y2 = pos3.getCoord(1) - pos.getCoord(1);
    const z1 = pos2.getCoord(2) - pos.getCoord(2);
    const z2 = pos3.getCoord(2) - pos.getCoord(2);

    const u1 = uv2.getCoord(0) - uv.getCoord(0);
    const u2 = uv3.getCoord(0) - uv.getCoord(0);
    const v1 = uv2.getCoord(1) - uv.getCoord(1);
    const v2 = uv3.getCoord(1) - uv.getCoord(1);

    const det = (u1 * v2 - u2 * v1);
    const invDet = det === 0 ? 0 : 1.0 / det;
    const tx = (v2 * x1 - v1 * x2) * invDet;
    const ty = (v2 * y1 - v1 * y2) * invDet;
    const tz = (v2 * z1 - v1 * z2) * invDet;
    const tw = 1.0;

    const bx = (u1 * x2 - u2 * x1) * invDet;
    const by = (u1 * y2 - u2 * y1) * invDet;
    const bz = (u1 * z2 - u2 * z1) * invDet;

    if (!tgt) {
      tgt = new Vector4();
      GeomUtils.setVertexAttribute(vertex, tgtAttName, tgt);
    }
    tgt.setCoords(tx, ty, tz, tw);

    if (!btg) {
      btg = new Vector3();
      GeomUtils.setVertexAttribute(vertex, btgAttName, btg);
    }
    btg.setCoords(bx, by, bz);
  }

  static orthogonalize(vec, normal) {
    if (!vec || !normal) {
      return;
    }
    let x = vec.getCoord(0);
    let y = vec.getCoord(1);
    let z = vec.getCoord(2);

    const nx = normal.getCoord(0);
    const ny = normal.getCoord(1);
    const nz = normal.getCoord(2);

    const d = nx * x + ny * y + nz * z;

    x -= nx * d;
    y -= ny * d;
    z -= nz * d;

    vec.setCoords(x, y, z);
  }

  /**
   * @method calculateTangentsForVertex
   * @static
   * @description Calculates the tangent & bitangent vectors for a triangle vertex
   * and stores them as vertex attributes.
   * This method needs the two other vertices of the triangle forming the two
   * adjacent edges of vertex to calculate the tangent and the bitangent vectors.
   * @param {Vector} vertex - The vertex to calculate the tangents for
   * @param {Vector} vertex2 - The first other vertex of the triangle
   * @param {Vector} vertex3 - The second other vertex of the triangle
   * @param {Object} params - optional params object
   *  - uvName or uvAttributeName (default = 'uv') - the name of the uv vertex attribute
   *  - tangentName or tangentAttributeName (default = 'tangent') - the name of the tangent vertex attribute
   *  - bitangentName or bitangentAttributeName (default = 'bitangent') - the name of the bitangent vertex attribute
   *  - normalName or normalAttributeName (default = 'normal') - the name of the normal vertex attribute
   *  - normalizeTangents or normalize (default = true) - Should normalize the calculated tangent
   *  - normalizeBitangents or normalize (default = true) - Should normalize the calculated bitangent
   *  - orthogonalize (default = true) - Makes the tangent and bitangent orthogonal to the vertex normal plane
   *  - calculateHandedness or handedness (default = true) - Calculates the handedness multiplier and stores it
   *    in the 'w' component of the tangent. Should be 1 or -1.
   * @returns {void}
   * */
  static calculateTangentsForVertex(vertex, vertex2, vertex3, params) {
    if (!vertex || !vertex2 || !vertex3) {
      return;
    }
    let normalizeTangents = true;
    let normalizeBitangents = true;
    let orthogonalize = true;
    let calculateHandedness = true;

    let uvAttName = 'uv';
    let tgtAttName = 'tangent';
    let btgAttName = 'bitangent';
    let nrmAttName = 'normal';

    if (params) {
      uvAttName = tryValues(params.uvName, params.uvAttributeName, uvAttName);
      tgtAttName = tryValues(params.tangentName, params.tangentAttributeName, tgtAttName);
      btgAttName = tryValues(params.bitangentName, params.bitangentAttributeName, btgAttName);
      nrmAttName = tryValues(params.normalName, params.normalAttributeName, nrmAttName);

      normalizeTangents = tryValues(params.normalizeTangents, params.normalize, normalizeTangents);
      normalizeBitangents = tryValues(params.normalizeBitangents, params.normalize, normalizeBitangents);
      calculateHandedness = tryValues(params.calculateHandedness, params.computeHandedness, params.handedness, calculateHandedness);
      orthogonalize = tryValues(params.orthogonalize, orthogonalize);
    }

    GeomUtils._calculateTangentsForTriangleVertex(vertex, vertex2, vertex3, uvAttName, tgtAttName, btgAttName);

    const nrm = GeomUtils.getVertexAttribute(vertex, nrmAttName);
    const tgt = GeomUtils.getVertexAttribute(vertex, tgtAttName);
    const btg = GeomUtils.getVertexAttribute(vertex, btgAttName);

    if (!nrm || !tgt || !btg) {
      return;
    }

    if (orthogonalize) {
      GeomUtils.orthogonalize(tgt, nrm);
      GeomUtils.orthogonalize(btg, nrm);
    }

    if (normalizeTangents) {
      VectorMath.normalize(tgt);
    }

    if (normalizeBitangents) {
      VectorMath.normalize(btg);
    }

    if (calculateHandedness) {
      const tx = tgt.getCoord(0);
      const ty = tgt.getCoord(1);
      const tz = tgt.getCoord(2);
      // Cross product between normal and bitangent
      const cx = btg.getCoord(1) * nrm.getCoord(2) - btg.getCoord(2) * nrm.getCoord(1);
      const cy = btg.getCoord(2) * nrm.getCoord(0) - btg.getCoord(0) * nrm.getCoord(2);
      const cz = btg.getCoord(0) * nrm.getCoord(1) - btg.getCoord(1) * nrm.getCoord(0);
      // Dot product to check if the cross between the normal and tangent
      // points in the same direction as the tangent vector
      const _3 = 3;
      const neg1 = -1;
      const tdot = cx * tx + cy * ty + cz * tz;
      const tw = tdot < 0 ? neg1 : 1;

      tgt.setCoord(_3, tw);
    }
  }

  static calculatePolygonTangents(poly, params = null) {
    if (!poly) {
      return;
    }
    const verts = poly.vertices;

    if (!verts) {
      return;
    }
    const _3 = 3;
    const numv = verts.length;

    if (numv < _3) {
      return;
    }
    if (numv > _3) {
      if (this._tempTris) {
        this._tempTris.length = 0;
      }
      this._tempTris = GeomUtils.triangulatePolygon(poly, this._tempTris);
      // const tris = GeomUtils.triangulatePolygon(poly);
      const tris = this._tempTris;

      GeomUtils.calculateTangentsForPolygons(tris, params);

      return;
    }

    const v1 = verts[0];
    const v2 = verts[1];
    const v3 = verts[2];

    GeomUtils.calculateTangentsForVertex(v1, v2, v3, params);
    GeomUtils.calculateTangentsForVertex(v2, v1, v3, params);
    GeomUtils.calculateTangentsForVertex(v3, v1, v2, params);
  }

  static calculateTangentsForPolygons(polys, params = null) {
    if (!polys) {
      return;
    }
    const num = polys.length;

    if (num === 0) {
      return;
    }
    for (let i = 0; i < num; ++i) {
      const poly = polys[i];

      GeomUtils.calculatePolygonTangents(poly, params);
    }
  }

  static flipPolygons(source) {
    let polys = null;

    if (source instanceof Array) {
      polys = source;
    } else if (source instanceof Geometry) {
      polys = source.polygons;
    }

    if (!polys) {
      return;
    }
    const num = polys.length;

    for (let i = 0; i < num; ++i) {
      const poly = polys[i];

      if (poly) {
        poly.vertices.reverse();
      }
    }
  }
}

  /*

  var GeomUtils = function () {
  };
  GeomUtils.generateRoundRectPath = generateRoundRectPath;
  GeomUtils.getPolygonVerts = getPolygonVerts;

  GeomUtils.getMeshVerts = getMeshVerts;
  GeomUtils.getMeshUVs = getMeshUVs;
  GeomUtils.getMeshNormals = getMeshNormals;
  GeomUtils.getMeshBounds = getMeshBounds;

  GeomUtils.findVertexNormal = findVertexNormal;
  GeomUtils.addLine = addLine;
  GeomUtils.addCornerArc = addCornerArc;
  GeomUtils.computePathVertexNormals = computePathVertexNormals;
  GeomUtils.computePathUV = computePathUV;
  GeomUtils.assignVertexAttribute = assignVertexAttribute;
  GeomUtils.normalizeUVs = normalizeUVs;
  GeomUtils.normalizePolygonUVs = normalizePolygonUVs;

  GeomUtils.computePolygonTangents = computePolygonTangents;
  GeomUtils.computeTangentsForPolygons = computeTangentsForPolygons;


  ns.GeomUtils = GeomUtils;
  */
