import OBJParser3D from '../../bgr/bgr3d/parsers/OBJParser3D';
import Vertex from '../../bgr/bgr3d/geom/Vertex';
import Vector2 from '../../bgr/bgr3d/geom/Vector2';
import Vector3 from '../../bgr/bgr3d/geom/Vector3';
import Polygon from '../../bgr/bgr3d/geom/Polygon';
// import Geometry from '../../bgr/bgr3d/geom/Geometry';
import BD3DGeometry from '../geom/BD3DGeometry';
import BD3DContainerNode3D from '../scenegraph/BD3DContainerNode3D';
import BD3DGeometryNode3D from '../scenegraph/BD3DGeometryNode3D';
// import ContainerNode3D from '../../bgr/bgr3d/scenegraph/ContainerNode3D';
// import GeometryNode3D from '../../bgr/bgr3d/scenegraph/GeometryNode3D';
import GeomUtils from '../../bgr/bgr3d/utils/GeomUtils';
// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
// #endif

const XCoord = 0;
const YCoord = 1;
const ZCoord = 2;

const DEFAULT_FLIP_V = true;

function expandBBox(x, y, z, session) {
  if (session && session.params) {
    if (!session.params.calculateBoundingBox) {
      return;
    }

    const obj = session.currentObject;

    if (obj) {
      let ud = obj.userData;

      if (!ud) {
        ud = obj.userData = {};
      }
      let bbox = ud.boundingBox;

      if (!bbox) {
        bbox = ud.boundingBox = {};
      }
      if (bbox.minx === undefined) {
        bbox.minx = bbox.maxx = x;
        bbox.miny = bbox.maxy = y;
        bbox.minz = bbox.maxz = z;
      } else {
        bbox.minx = x < bbox.minx ? x : bbox.minx;
        bbox.maxx = x > bbox.maxx ? x : bbox.maxx;
        bbox.miny = y < bbox.miny ? y : bbox.miny;
        bbox.maxy = y > bbox.maxy ? y : bbox.maxy;
        bbox.minz = z < bbox.minz ? z : bbox.minz;
        bbox.maxz = z > bbox.maxz ? z : bbox.maxz;
      }
    }
  }
}

function shouldCalcTangents(session, parser) {
  if (!GeomUtils) {
    return false;
  }
  let calcTangents = (session && session.params) ? session.params.calculateTangents : null;

  if (calcTangents === null || typeof (calcTangents) === 'undefined') {
    const p = parser;

    calcTangents = p.calculateTangents;
  }

  return calcTangents === true;
}

const defaultCallbacks = {
  onComment: function (comment, lineIndex, session, parser) {
    // #if DEBUG
    BD3DLogger.log('obj comment: ', comment);
    // #endif

    return;
  },
  // create the result object
  getResult: function (session, parser) {
    const objects = session.objects;
    const objectsByName = session.objectsByName;

    const container = new BD3DContainerNode3D();
    let children = container.children;

    if (!children) {
      children = container.children = [];
    }
    const num = objects.length;

    const calcTangents = shouldCalcTangents(session, parser);

    for (let i = 0; i < num; ++i) {
      const geom = objects[i];

      if (geom) {
        if (calcTangents) {
          GeomUtils.calculateTangentsForPolygons(geom.polygons);
        }
        const node = new BD3DGeometryNode3D(geom);

        const ud = geom.userData;

        let materialName = null;

        if (ud) {
          const bb = ud.boundingBox;

          if (bb) {
            bb.width = bb.maxx - bb.minx;
            bb.height = bb.maxy - bb.miny;
            bb.depth = bb.maxz - bb.minz;
          }

          if (ud.materialName) {
            materialName = ud.materialName;
          }
        }

        if (materialName) {
          if (!node.userData) {
            node.userData = {};
          }
          node.userData.materialName = ud.materialName;
        }

        children.push(node);
      }
    }

    const res = {
      geometries: objects,
      geometriesByName: objectsByName,
      node: container
    };

    if (session.materialNames) {
      res.materialNames = session.materialNames;
    }

    return res;
  },
  useMaterial: function (materialName, line, index, session, parser) {
    if (!session) {
      return;
    }
    if (!materialName || materialName.length === 0) {
      return;
    }
    session.currentMaterial = materialName;

    let materialNames = session.materialNames;

    if (!materialNames) {
      materialNames = session.materialNames = [];
    }
    if (materialNames.indexOf(materialName, 0) < 0) {
      materialNames.push(materialName);
    }

    const curObj = session.currentObject;

    if (curObj) {
      let ud = curObj.userData;

      if (!ud) {
        ud = curObj.userData = {};
      }

      ud.materialName = materialName;
    }
  },
  newObject: function (name, session) {
    const g = new BD3DGeometry();

    if (!g.userData) {
      g.userData = {};
    }
    g.userData.name = name;

    return g;
  },
  addVertex: function (x, y, z, line, index, session, parser) {
    let vertices = session.vertices;

    if (!vertices) {
      vertices = session.vertices = [];
    }
    const v = new Vertex(new Vector3(x, y, z));

    vertices.push(v);

    const curObj = session.currentObject;

    if (curObj) {
      if (!curObj.vertices) {
        curObj.vertices = [];
      }
      curObj.vertices.push(v);
    }
  },
  addUV: function (X, Y, line, index, session, parser) {
    const x = X;
    let y = Y;
    const curObj = session.currentObject;

    let uvs = session.uvs;

    if (!uvs) {
      uvs = session.uvs = [];
    }

    let flipV = DEFAULT_FLIP_V;

    if (session && session.params) {
      if (session.params.flipV !== undefined && session.params.flipV !== null) {
        flipV = session.params.flipV;
      }
    }

    if (flipV) {
      y = 1 - y;
    }

    const uv = new Vector2(x, y);

    if (!curObj.attributes) {
      curObj.attributes = {};
    }
    if (!curObj.attributes.uvs) {
      curObj.attributes.uvs = [];
    }

    curObj.attributes.uvs.push(uv);

    uvs.push(uv);

    if (curObj) {
      if (!curObj.userData) {
        curObj.userData = {};
      }
      if (!curObj.userData.uvs) {
        curObj.userData.uvs = [];
      }
    }
  },
  addNormal: function (x, y, z, line, index, session, parser) {
    let normals = session.normals;
    const curObj = session.currentObject;

    if (!normals) {
      normals = session.normals = [];
    }
    const normal = new Vector3(x, y, z);

    if (!curObj.attributes) {
      curObj.attributes = {};
    }
    if (!curObj.attributes.normals) {
      curObj.attributes.normals = [];
    }

    curObj.attributes.normals.push(normal);

    normals.push(normal);
  },
  newPolygonVertex: function (vIndex, uvIndex, nIndex, line, index, session, parser) {
    let verts = null, uvs = null, normals = null, curObj = null;

    if (session) {
      curObj = session.currentObject;
      verts = session.vertices;
      uvs = session.uvs;
      normals = session.normals;
    }
    const v = verts && vIndex >= 0 ? verts[vIndex] : null;
    const uv = uvs && uvIndex >= 0 ? uvs[uvIndex] : null;
    const n = normals && nIndex >= 0 ? normals[nIndex] : null;

    if (v) {
      expandBBox(
        v.getCoord(XCoord),
        v.getCoord(YCoord),
        v.getCoord(ZCoord),
        session
      );
    }

    const vert = new Vertex();

    vert.position = v;
    vert.attributes = {
      uv: uv,
      normal: n
    };

    if (!curObj.polygonVertices) {
      curObj.polygonVertices = [];
    }
    curObj.polygonVertices.push(vert);

    return vert;
  },
  newPolygon: function (verts, line, index, session, parser) {
    const poly = new Polygon(verts);

    return poly;
  }
};

/**
* @class BD3DOBJParser3D
* @extends OBJParser3D
* @description OBJParser using the internal BD3D mesh structure & used classes
* The params parameter of the parse method (last param) can contain the following properties:
* - merge: Default false. If true, all objects will be merged into one object
* - flipV: flip the texture V-coordinates
* - calculateBoundingBox - Default false. Calculates the min, max, width and height values of each mesh
* - calculateTangents - Default false. Calculates the tangents & bitangents of each polygon vertex
*/
export default class BD3DOBJParser3D extends OBJParser3D {
  /**
   * @constructor
   * @param {Object} args - Arguments object
  */
  constructor(args) {
    let a = args;

    let calcTangents = false;

    if (args) {
      calcTangents = args.calculateTangents === true;
    }

    if (!a) {
      a = {};
    }
    if (!a.callbacks) {
      a.callbacks = defaultCallbacks;
    }

    super(a);

    this.calculateTangents = calcTangents;
  }
}
