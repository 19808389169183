import URLResolver from './URLResolver';
import URLUtils from '../../bgr/common/utils/URLUtils';

const httpPrefix = 'http://';
const httpsPrefix = 'https://';

export default class DefaultURLResolver extends URLResolver {
  constructor(args = null) {
    super(args);

    let assetPath = null;

    if (args) {
      assetPath = args.assetPath;
    }

    this._assetPath = assetPath;
  }

  getAssetPath() {
    return this._assetPath;
  }

  setAssetPath(ap) {
    this._assetPath = ap;
  }

  get assetPath() {
    return this.getAssetPath();
  }

  set assetPath(ap) {
    this.setAssetPath(ap);
  }

  resolveURL(url, params = null) {
    let resURL = null;

    if (typeof (url) === 'string') {
      resURL = url;
      // Incoming URL is a string

      if (url.substring(0, httpPrefix.length) === httpPrefix || url.substring(0, httpsPrefix.length) === httpsPrefix) {
        // Incoming URL starts with http:// or https:// -> probably an absolute path, so no need to resolve
        return url;
      }

      // Incoming URL is a relative path, prepend the fixed assets path
      const assetPath = this.getAssetPath();

      if (!assetPath) {
        return resURL;
      }

      resURL = URLUtils.joinURL(assetPath, resURL);
    }

    return resURL;
  }
}
