const DIM = 0;
const ZERO = 0;

export default class Vector {

  getCoord() {
    return ZERO;
  }

  setCoord() {
    return;
  }

  setCoords(...values) {
    let l = values.length;
    const dim = this.getDimension();

    if (dim < l) {
      l = dim;
    }

    for (let i = 0; i < l; ++i) {
      this.setCoord(i, values[i]);
    }
  }

  getDimension() {
    return DIM;
  }

  get dimension() {
    return this.getDimension();
  }
}
