/**
 * @class GraphPoint
 * @description Point in graph containing bezier handles
 **/
export default class GraphPoint {
  constructor(location, handleLeft, handleRight) {
    this.location = location;
    this.handleLeft = handleLeft;
    this.handleRight = handleRight;
    this.metaData = null;
  }

  static getX(point, fallback = 0) {
    let pt = point;

    if (!pt) {
      return fallback;
    }
    if (pt instanceof GraphPoint) {
      pt = pt.location;
      if (!pt) {
        return fallback;
      }
    }
    if (pt instanceof Array) {
      return pt[0];
    }
    if (typeof (Float32Array) !== 'undefined' && pt instanceof Float32Array) {
      return pt[0];
    }
    let res = pt.x;

    if (res === undefined || res === null) {
      res = pt.X;
    }
    if (res === undefined || res === null) {
      return fallback;
    }

    return res;
  }

  static setX(point, value) {
    let pt = point;

    if (!pt) {
      return;
    }
    if (pt instanceof GraphPoint) {
      pt = pt.location;
    }
    if (!pt) {
      return;
    }
    if (pt instanceof Array) {
      pt[0] = value;
    } else if (pt.X !== undefined && pt.X !== null) {
      pt.X = value;
    } else {
      pt.x = value;
    }
  }

  static getY(point, fallback = 0) {
    let pt = point;

    if (!pt) {
      return fallback;
    }
    if (pt instanceof GraphPoint) {
      pt = pt.location;
      if (!pt) {
        return fallback;
      }
    }
    if (pt instanceof Array) {
      return pt[1];
    }
    if (typeof (Float32Array) !== 'undefined' && pt instanceof Float32Array) {
      return pt[1];
    }
    let res = pt.y;

    if (res === undefined || res === null) {
      res = pt.Y;
    }
    if (res === undefined || res === null) {
      return fallback;
    }

    return res;
  }

  static setY(point, value) {
    let pt = point;

    if (!pt) {
      return;
    }
    if (pt instanceof GraphPoint) {
      pt = pt.location;
    }
    if (!pt) {
      return;
    }
    if (pt instanceof Array) {
      pt[1] = value;
    } else if (pt.Y !== undefined && pt.Y !== null) {
      pt.Y = value;
    } else {
      pt.y = value;
    }
  }

  getX() {
    return GraphPoint.getX(this.location);
  }

  getY() {
    return GraphPoint.getY(this.location);
  }

  get x() {
    return this.getX();
  }

  get y() {
    return this.getY();
  }

  getHandleLeftX() {
    return GraphPoint.getX(this.handleLeft);
  }

  getHandleLeftY() {
    return GraphPoint.getY(this.handleLeft);
  }

  getHandleRightX() {
    return GraphPoint.getX(this.handleRight);
  }

  getHandleRightY() {
    return GraphPoint.getY(this.handleRight);
  }
}
