const NUM = 16;

const _0 = 0, _1 = 1, _2 = 2, _3 = 3,
  _4 = 4, _5 = 5, _6 = 6, _7 = 7,
  _8 = 8, _9 = 9, _10 = 10, _11 = 11,
  _12 = 12, _13 = 13, _14 = 14, _15 = 15;

export default class Matrix4 {
  constructor() {
    this._matrix = new Float32Array(NUM);
  }

  get(index) {
    return this._matrix[index];
  }

  set(index, value) {
    this._matrix[index] = value;
  }

  getElements() {
    return this._matrix;
  }

  setElements(values) {
    if (values.length !== NUM) {
      throw new Error('Invalid number of arguments');
    }

    const m = this._matrix;

    for (let i = 0; i < NUM; ++i) {
      m[i] = values[i];
    }
  }

  get elements() {
    return this._matrix;
  }

  set elements(values) {
    this.setElements(values);
  }
  /*
  // setValues with default params (slower :()
  setValues(v0 = 1, v1 = 0, v2 = 0, v3 = 0,
    v4 = 0, v5 = 1, v6 = 0, v7 = 0,
    v8 = 0, v9 = 0, v10 = 1, v11 = 0,
    v12 = 0, v13 = 0, v14 = 0, v15 = 1) {
    const m = this._matrix;

    m[_0] = v0; m[_1] = v1; m[_2] = v2; m[_3] = v3;
    m[_4] = v0; m[_5] = v1; m[_6] = v2; m[_7] = v3;
    m[_8] = v0; m[_9] = v1; m[_10] = v2; m[_11] = v3;
    m[_12] = v0; m[_13] = v1; m[_14] = v2; m[_15] = v3;
  }
  */

  setValues(v0, v1, v2, v3,
    v4, v5, v6, v7,
    v8, v9, v10, v11,
    v12, v13, v14, v15) {
    const m = this._matrix;

    m[_0] = v0; m[_1] = v1; m[_2] = v2; m[_3] = v3;
    m[_4] = v4; m[_5] = v5; m[_6] = v6; m[_7] = v7;
    m[_8] = v8; m[_9] = v9; m[_10] = v10; m[_11] = v11;
    m[_12] = v12; m[_13] = v13; m[_14] = v14; m[_15] = v15;
  }

  getX() {
    return this.get(_12);
  }

  getY() {
    return this.get(_13);
  }

  getZ() {
    return this.get(_14);
  }

  get x() {
    return this.get(_12);
  }

  set x(value) {
    this.set(_12, value);
  }

  get y() {
    return this.get(_13);
  }

  set y(value) {
    this.set(_13, value);
  }

  get z() {
    return this.get(_14);
  }

  set z(value) {
    this.set(_14, value);
  }
}

for (let i = 0; i < NUM; ++i) {
  (function (index) {
    Reflect.defineProperty(Matrix4.prototype, index, {
      get: function () {
        return this.get(index);
      },
      set: function (value) {
        this.set(index, value);
      }
    });
  }(i));
}
