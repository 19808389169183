import AssetManager from '../asset/AssetManager';
import DefaultURLResolver from '../loading/DefaultURLResolver';
import URLResolver from './URLResolver';
import EventDispatcher from '../../bgr/common/events/EventDispatcher';
import ImageLoader from '../../bgr/common/loading/ImageLoader';
import LoaderList from '../../bgr/common/loading/LoaderList';
import LoaderState from '../../bgr/common/loading/LoaderState';
import Loader3D from '../../bgr/bgr3d/loading/Loader3D';
import BD3DOBJParser3D from '../parsers/BD3DOBJParser3D';

import ImageAsset from '../asset/ImageAsset';
import Object3DAsset from '../asset/Object3DAsset';
import SampleAsset from '../asset/SampleAsset';
import SampleLoader from './SampleLoader';
import QuiltAsset from '../asset/QuiltAsset';
import QuiltLoader from './QuiltLoader';
import BackgroundAsset from '../asset/BackgroundAsset';
import BackgroundLoader from './BackgroundLoader';

import SampleService from '../services/SampleService';
import QuiltService from '../services/QuiltService';
import BackgroundService from '../services/BackgroundService';
// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
// #endif

let defaultSampleService = null;
let defaultQuiltService = null;
let defaultBackgroundService = null;

// Condition function to select which assets should be collected for loading
function assetCollectCondition(asset, params) {
  if (!asset) {
    return false;
  }
  const data = asset.getData();

  if (data) {
    // Assume the asset has already been loaded since it contains data.
    return false;
  }

  let addedAssets = params.addedAssets;

  if (!addedAssets) {
    addedAssets = params.addedAssets = [];
  }
  if (addedAssets.indexOf(asset, 0) >= 0) {
    return false;
  }

  return true;
}

// Map with parsers
const parsers = {
  // OBJ file format parser for 3D objects
  objParser: new BD3DOBJParser3D({calculateTangents: true})
};

const assetTypes = [
  {
    name: 'image',
    keys: [
      'image',
      'img',
      'jpg',
      'jpeg',
      'png'
    ]
  },
  {
    name: 'object3d',
    keys: [
      'obj',
      'object3d'
    ]
  }
];
const assetTypeMap = (function () {
  const res = {};
  const l = assetTypes.length;

  for (let i = 0; i < l; ++i) {
    const assetType = assetTypes[i];
    let keys = null;

    if (assetType) {
      keys = assetType.keys;
    }
    if (keys) {
      const numkeys = keys.length;

      for (let j = 0; j < numkeys; ++j) {
        const key = keys[j];

        if (key) {
          res[key] = assetType;
        }
      }
    }
  }

  return res;
}());

function getAssetType(key) {
  return assetTypeMap[key];
}

export default class LoadingManager extends EventDispatcher {
  constructor(args = null) {
    super();
    let urlResolver, assetMgr;

    if (args) {
      urlResolver = args.urlResolver || args.URLResolver;
      assetMgr = args.assetManager;
    }
    if (assetMgr instanceof AssetManager) {
      this.setAssetManager(assetMgr);
    }
    if (urlResolver instanceof URLResolver) {
      this.setURLResolver(urlResolver);
    }
  }

  getAssetManager() {
    return this._assetManager;
  }

  setAssetManager(assetMgr) {
    this._assetManager = assetMgr;
  }

  get assetManager() {
    return this.getAssetManager();
  }

  set assetManager(assetMgr) {
    this.setAssetManager(assetMgr);
  }

  getDefaultURLResolver() {
    if (!this._defaultURLResolver) {
      this._defaultURLResolver = new DefaultURLResolver();
    }

    return this._defaultURLResolver;
  }

  setURLResolver(u) {
    this._urlResolver = u;
  }

  getURLResolver() {
    let resolver = this._urlResolver;

    if (!resolver) {
      resolver = this.getDefaultURLResolver();
    }

    return resolver;
  }

  get urlResolver() {
    return this.getURLResolver();
  }

  set urlResolver(u) {
    this.setURLResolver(u);
  }

  _getAssetParseParams(asset) {
    const info = this._getAssetInfo(asset);
    const t = typeof (info);

    if (t === 'string' || t === 'undefined' || info === null) {
      return null;
    }

    return info.parseParams;
  }

  _getAssetInfo(asset) {
    let name = null;

    if (typeof (asset) === 'string') {
      name = asset;
    } else {
      name = asset.name;
    }
    if (!name) {
      return null;
    }
    const mapping = this._getAssetInfoMapping();

    if (!mapping) {
      return null;
    }

    return mapping[name];
  }

  _getAssetInfoMapping() {
    let res = this._assetInfoMapping;

    if (!res) {
      const hHandleUVWorldTransform = {
        // scaleU: 22.269095528455285,
        // scaleV: 16.05090909090909,
        minU: -11,
        minV: -2.75,
        maxU: 11,
        maxV: 2.75,
        scaleU: 0.044905281344821905,
        scaleV: 0.06230176710466697
      };

      const vHandleUVWorldTransform = {
        // scaleU: 22.920805785203523,
        // scaleV: 27.117540681557614
        minU: -2.9772251,
        minV: -11.2293987,
        maxU: 2.9797161,
        maxV: 11.6072044,
        scaleU: 0.04332228451367966,
        scaleV: 0.03669705248373412
      };

      // Vertical handle object size
      const vHandleObjectSize = [6.0, 22.8561, 2.2372]; // eslint-disable-line

      // Horizontal handle object size (full width)
      // const hHandleObjectSize = [21.91279, 5.499998, 1.342541]; // eslint-disable-line

      // Horizontal handle object size (width without stitched parts)
      const hHandleObjectSize = [14.5, 5.499998, 1.342541]; // eslint-disable-line

      // Real size in cm
      const defaultFabricRealSize = {
        width: 2,
        height: 2
      };

      const realDefaultMirrorPanelFabricSize = {
        width: 68,
        height: 80
      };

      // TODO: move to external config
      res = this._assetInfoMapping = {
        'bd.logo': 'logo/bdlogo.jpg',
        'bd.logo.fabric': 'handles/logo/logo_handle.png',
        'bd.logo.fabric.background': 'handles/logo/logo_handle_background.jpg',

        // default fabric
        'defaultfabric.color': {
          url: 'defaultfabric.jpg',
          metaData: {
            realSize: defaultFabricRealSize
          }
        },
        'defaultfabric.normal': {
          url: 'defaultfabric_normal.jpg',
          metaData: {
            realSize: defaultFabricRealSize
          }
        },

        'defaultmirrorpanelfabric.color': {
          url: 'mirrorpanel/mirrorpanel_texture.jpg',
          metaData: {
            realSize: realDefaultMirrorPanelFabricSize
          }
        },

        'defaultmirrorpanelfabric.normal': {
          url: 'mirrorpanel/mirrorpanel_bump.jpg',
          metaData: {
            realSize: realDefaultMirrorPanelFabricSize
          }
        },

        'satin.brdf': 'brdf/satin_brdf.jpg',

        // handles
        'handle.horizontal.HHS012.object': {
          url: 'handles/horizontal/HHS012.obj',
          metaData: {
            uvWorldTransform: hHandleUVWorldTransform,
            objectSize: hHandleObjectSize
          },
          parseParams: {}
        },
        'handle.horizontal.HHS013.object': {
          url: 'handles/horizontal/HHS013.obj',
          metaData: {
            uvWorldTransform: hHandleUVWorldTransform,
            objectSize: hHandleObjectSize
          }
        },
        'handle.horizontal.HHS014.texture': {
          url: 'handles/horizontal/HHS014.jpg'
        },
        'handle.horizontal.HHS015.object': {
          url: 'handles/horizontal/HHS015.obj',
          metaData: {
            uvWorldTransform: hHandleUVWorldTransform,
            objectSize: hHandleObjectSize
          }
        },
        'handle.horizontal.HHS016.object': {
          url: 'handles/horizontal/HHS016.obj',
          metaData: {
            uvWorldTransform: hHandleUVWorldTransform,
            objectSize: hHandleObjectSize
          }
        },
        'handle.horizontal.HHS016+17.object': {
          url: 'handles/horizontal/HHS016.obj',
          metaData: {
            uvWorldTransform: hHandleUVWorldTransform,
            objectSize: hHandleObjectSize
          }
        },
        'handle.horizontal.HHS017.object': {
          url: 'handles/horizontal/HHS016.obj',
          metaData: {
            uvWorldTransform: hHandleUVWorldTransform,
            objectSize: hHandleObjectSize
          }
        },
        'handle.vertical.HVS00001.object': {
          url: 'handles/vertical/HVS00001.obj',
          metaData: {
            uvWorldTransform: vHandleUVWorldTransform,
            objectSize: vHandleObjectSize
          }
        },
        'handle.vertical.HVS00002.object': {
          url: 'handles/vertical/HVS00002.obj',
          metaData: {
            uvWorldTransform: vHandleUVWorldTransform,
            objectSize: vHandleObjectSize
          }
        },
        'handle.vertical.HVS003.texture': {
          url: 'handles/vertical/HVS003.jpg'
        },
        'handle.vertical.HV004.object': {
          url: 'handles/vertical/HV004.obj',
          metaData: {
            uvWorldTransform: vHandleUVWorldTransform,
            objectSize: vHandleObjectSize
          }
        },
        'handle.vertical.HVS005.object': {
          url: 'handles/vertical/HVS005.obj',
          metaData: {
            uvWorldTransform: vHandleUVWorldTransform,
            objectSize: vHandleObjectSize
          }
        },
        'handle.vertical.HVS006.object': {
          url: 'handles/vertical/HVS006.obj',
          metaData: {
            uvWorldTransform: vHandleUVWorldTransform,
            objectSize: vHandleObjectSize
          }
        },
        'handle.vertical.HV007.object': {
          url: 'handles/vertical/HV007.obj',
          metaData: {
            uvWorldTransform: {
              minU: -7.5,
              minV: -11.2293987,
              maxU: 7.5,
              maxV: 11.6072044,
              scaleU: 0.0284282,
              scaleV: 0.03184772925764192
            },
            objectSize: [15.0, 22.8561, 2.2372]
          }
        },
        'handle.vertical.HV008.object': {
          url: 'handles/vertical/HV008.obj',
          metaData: {
            uvWorldTransform: {
              minU: -4.0,
              minV: -11.2293987,
              maxU: 4.0,
              maxV: 11.6072044,
              scaleU: 0.0284282 * (15 / 8),
              scaleV: 0.03184772925764192
            },
            objectSize: [8.0, 22.8561, 2.2372]
          }
        },
        'handle.vertical.HV012.object': {
          url: 'handles/vertical/HV012.obj',
          metaData: {
            uvWorldTransform: {
              minU: -6,
              minV: -11.2293987,
              maxU: 6,
              maxV: 11.6072044,
              scaleU: 0.0284282 * (15.0 / 12.0),
              scaleV: 0.03184772925764192
            },
            objectSize: [12.0, 22.8561, 2.2372]
          }
        },

        // pocket handles
        'handle.pocket.HHP031.object': {
          url: 'handles/pocket/hhp031.obj'
          // url: 'handles/suitcase/hs042.obj'
        },
        'handle.pocket.HHP032.object': {
          url: 'handles/pocket/hhp032.obj'
        },
        'handle.pocket.HHP033.object': {
          url: 'handles/pocket/hhp033.obj'
        },

        'handle.pocket.HHP031.normal': {
          url: 'handles/pocket/hhp031_normalmap.jpg'
          // url: 'handles/suitcase/hs042.obj'
        },
        'handle.pocket.HHP032.normal': {
          url: 'handles/pocket/hhp032_normalmap.jpg'
        },
        'handle.pocket.HHP033.normal': {
          url: 'handles/pocket/hhp033_normalmap.jpg'
        },

        // suitcase handles
        'handle.suitcase.HS041.object': {
          url: 'handles/suitcase/hs041.obj'
        },
        'handle.suitcase.HS042.object': {
          url: 'handles/suitcase/hs042.obj'
        },
        'handle.suitcase.HS043.object': {
          url: 'handles/suitcase/hs043.obj'
        },

        // legs
        'legs.leg_0.object': {
          url: 'legs/leg_0.obj'
        },
        'legs.leg_1.object': {
          url: 'legs/leg_1.obj'
        },
        'legs.leg_2.object': {
          url: 'legs/leg_2.obj'
        },

        // zipper
        'zipper.types.default.tex_color': 'zipper/zipper_color_white.jpg',
        'zipper.types.default.tex_normal': 'zipper/zipper_normal.jpg',
        'zipper.types.default.tex_specular': 'zipper/zipper_specularmask.jpg',

        'zipper.types.spiral_coil.tex_color': 'zipper/Zipper2_Color.jpg',
        'zipper.types.spiral_coil.tex_normal': 'zipper/Zipper2_Normal.jpg',
        'zipper.types.spiral_coil.tex_specular': 'zipper/Zipper2_specularmask.jpg',

        'zipper.types.reverse_coil.tex_color': 'zipper/Zipper3_Color.jpg',
        'zipper.types.reverse_coil.tex_normal': 'zipper/Zipper3_Normal.png',

        'zipper.pullers.puller1.object': 'zipper/zipper_puller.obj',
        'zipper.pullers.puller1.tex_lightmap': 'zipper/zipper_puller_lightmap.jpg',

        'zipper.pullers.puller2.object': 'zipper/zipper_h_puller.obj',
        'zipper.pullers.puller2.tex_lightmap': 'zipper/zipper_h_puller_lightmap.jpg',

        // 3d border
        'border3d.tex_color': 'border3d/border3d_diffuse.jpg',
        'border3d.tex_normal': 'border3d/border3d_normal.jpg',
        'border3d.tex_specular': 'border3d/border3d_specular.jpg',

        // fabric ribbon
        'ribbon.satin.tex_color': 'ribbon/ribbon_satin_texture.jpg',
        'ribbon.satin.tex_normal': 'ribbon/ribbon_satin_normal.jpg',
        'ribbon.cotton.tex_color': 'ribbon/ribbon_satin_texture.jpg',
        'ribbon.cotton.tex_normal': 'ribbon/ribbon_satin_normal.jpg',

        '': ''
      };
    }

    return res;
  }

  // Tries to find the asset manager in the params object. If not found,
  // the asset manager of the loadingManager is chosen
  _findAssetManager(params) {
    let mgr = null;

    if (params) {
      mgr = params.assetManager;
    }
    if (!mgr || !(mgr instanceof AssetManager)) {
      mgr = this.getAssetManager();
    }

    return mgr;
  }

  _getAssetURL(asset, params = null) {
    if (!asset) {
      return null;
    }

    const name = asset.getName();
    const mapping = this._getAssetInfoMapping();
    let url = null;

    if (mapping) {
      url = mapping[name];
    }
    if (!url) {
      if (asset.getURL) {
        url = asset.getURL();
      }
      if (!url) {
        url = asset.url;
      }
    }

    if (url && typeof (url) !== 'string') {
      url = url.url;
    }

    if (!url) {
      const mgr = this._findAssetManager(params);

      if (mgr && (mgr instanceof AssetManager)) {
        url = mgr.getAssetURL(asset);
      }
    }

    if (!url) {
      return url;
    }

    return this._resolveURL(url);
  }

  _resolveURL(url, params = null) {
    let resolver = null;

    if (params) {
      resolver = params.urlResolver || params.URLResolver;
    }
    if (!resolver || !(resolver instanceof URLResolver)) {
      resolver = this.getURLResolver();
    }
    if (!resolver || !(resolver instanceof URLResolver)) {
      return url;
    }

    return resolver.resolveURL(url, params);
  }

  getLoadersByConfig(config, params = null, array = null) {
    const assetMgr = this._findAssetManager(params);

    if (!assetMgr || !(assetMgr instanceof AssetManager)) {
      return array;
    }

    let assetCollectParams = this._assetCollectParams;

    if (!assetCollectParams) {
      assetCollectParams = this._assetCollectParams = {};
    }
    if (assetCollectParams.addedAssets) {
      assetCollectParams.addedAssets.length = 0;
    }
    assetCollectParams.condition = assetCollectCondition;
    const assets = assetMgr.getAssetsByConfig(config, assetCollectParams);

    return this.getLoadersByAssets(assets, params, array);
  }

  _createLoaderFromAsset(asset, url, params = null) {
    if (!asset) {
      return null;
    }
    let type = asset.getType();
    const data = asset.getData();

    if (data) {
      return null;
    }

    if (type) {
      type = type.toLowerCase();
    }
    let loader = null;

    const assetType = getAssetType(type);
    const assetTypeName = assetType ? assetType.name : null;

    if ((asset instanceof Object3DAsset) || (assetTypeName === 'object3d')) {
      let parseParams = this._getAssetParseParams(asset);

      if (!parseParams && asset.metaData) {
        parseParams = asset.metaData.parseParams;
      }
      loader = new Loader3D({
        url: url,
        parser: parsers.objParser,
        parseParams: parseParams
      });
    } else if ((asset instanceof ImageAsset) || (assetTypeName === 'image')) {
      loader = new ImageLoader({
        url: url,
        crossOrigin: 'Anonymous'
      });
    } else if (asset instanceof SampleAsset) {
      loader = new SampleLoader(asset.getSampleID());
      loader.setSampleService(this.getSampleService());
    } else if (asset instanceof QuiltAsset) {
      loader = new QuiltLoader({quiltConfigData: asset.getQuiltConfigData()});
      loader.setQuiltService(this.getQuiltService());
    } else if (asset instanceof BackgroundAsset) {
      loader = new BackgroundLoader(asset.getName());
      loader.setBackgroundService(this.getBackgroundService());
    }

    return loader;
  }

  getBackgroundService() {
    const res = this._backgroundService;

    if (!res) {
      if (!defaultBackgroundService) {
        defaultBackgroundService = new BackgroundService();
      }

      return defaultBackgroundService;
    }

    return res;
  }

  setBackgroundService(v) {
    if (!(v instanceof BackgroundService)) {
      return;
    }
    this._backgroundService = v;
  }

  get backgroundService() {
    return this.getBackgroundSerice();
  }

  set backgroundService(v) {
    this.setBackgroundService(v);
  }

  setSampleService(service) {
    let svc = service;

    if (svc && !(svc instanceof SampleService)) {
      // #if DEBUG
      BD3DLogger.warn('Invalid sample service', svc);
      // #endif

      svc = null;
    }
    this._sampleService = svc;
  }

  getSampleService() {
    const res = this._sampleService;

    if (!res) {
      if (!defaultSampleService) {
        defaultSampleService = new SampleService();
      }

      return defaultSampleService;
    }

    return res;
  }

  get sampleService() {
    return this.getSampleService();
  }

  set sampleService(svc) {
    this.setSampleService(svc);
  }

  getQuiltService() {
    const res = this._quiltService;

    if (!res) {
      if (!defaultQuiltService) {
        defaultQuiltService = new QuiltService();
      }

      return defaultQuiltService;
    }

    return res;
  }

  setQuiltService(service) {
    let svc = service;

    if (svc && !(svc instanceof QuiltService)) {
      // #if DEBUG
      BD3DLogger.warn('Invalid quilt service: ', svc);
      // #endif

      svc = null;
    }

    this._quiltService = svc;
  }

  get quiltService() {
    return this.getQuiltService();
  }

  set quiltService(svc) {
    this.setQuiltService(svc);
  }

  _getLoaderFromAsset(asset, loaderByURLMap, params = null) {
    const url = this._getAssetURL(asset, params);

    let loader = null;

    if (typeof (url) === 'string') {
      if (loaderByURLMap) {
        loader = loaderByURLMap[url];
      }
      if (!loader) {
        loader = this._createLoaderFromAsset(asset, url, params);
        if (loader && loaderByURLMap) {
          loaderByURLMap[url] = loader;
        }
      }
    }
    if (!loader) {
      loader = this._createLoaderFromAsset(asset, null, params);
    }
    if (loader) {
      let md = loader.metaData;

      if (!md) {
        md = loader.metaData = {};
      }
      let loaderAssets = md.assets;

      if (!loaderAssets) {
        loaderAssets = md.assets = [];
      }
      if (loaderAssets.indexOf(asset, 0) < 0) {
        loaderAssets.push(asset);
      }

      return loader;
    }

    return null;
  }

  _addLoaderByAsset(asset, loaderByURLMap, params = null, array = null) {
    const ldr = this._getLoaderFromAsset(asset, loaderByURLMap, params);
    let arr = array;

    if (ldr) {
      if (!arr) {
        arr = [];
      }
      if (arr.indexOf(ldr, 0) < 0) {
        arr.push(ldr);
      }
    }

    return arr;
  }

  getLoadersByAssets(assets, params = null, array = null) {
    if (!assets) {
      return array;
    }
    const num = assets.length;

    if (num === 0) {
      return array;
    }
    let arr = array;

    const loaderByURLMap = {};

    for (let i = 0; i < num; ++i) {
      const asset = assets[i];

      arr = this._addLoaderByAsset(asset, loaderByURLMap, params, arr);
    }
    // #if DEBUG
    BD3DLogger.log('load assets: ', arr, assets);
    // #endif

    return arr;
  }

  _getLoaderListFinishedHandler(create = true) {
    let handlerObject = this._loaderListFinishedHandler;

    if (!create) {
      return handlerObject;
    }

    if (!handlerObject) {
      const that = this;

      handlerObject = {
        handler: evt => {
          return that._handleLoaderListFinished(evt, handlerObject);
        }
      };
      this._loaderListFinishedHandler = handlerObject;
    }

    return handlerObject;
  }

  _getLoaderFinishedHandler(create = true) {
    let handlerObject = this._loaderFinishedHandler;

    if (!create) {
      return handlerObject;
    }

    if (!handlerObject) {
      const that = this;

      handlerObject = {
        handler: evt => {
          return that._handleLoaderFinished(evt, handlerObject);
        }
      };
      this._loaderFinishedHandler = handlerObject;
    }

    return handlerObject;
  }

  _loadingFinished(params = null) {
    // #if DEBUG
    BD3DLogger.log('all loading done');
    // #endif

    const EVENT = 'finished';

    if (this.hasEventListener(EVENT)) {
      const event = {type: EVENT, params };

      this.dispatchEvent(event);
    }
  }

  _handleLoaderListFinished(evt, handler) {
    const ldr = evt.loader;

    if (ldr instanceof LoaderList) {
      const loaders = ldr.loaders;

      if (loaders) {
        loaders.length = 0;
      }
    }
    const params = handler && handler.params;
    this._loadingFinished(params);
  }

  _assignAssetMetaData(asset) {
    if (!asset) {
      return;
    }
    const info = this._getAssetInfo(asset);

    if (!info) {
      return;
    }

    let md = asset.metaData;
    const infoMD = info.metaData;

    if (infoMD) {
      if (!md) {
        md = asset.metaData = {};
      }
      for (const v in infoMD) {
        if (infoMD.hasOwnProperty(v)) {
          md[v] = infoMD[v];
        }
      }
    }
  }

  _handleLoaderFinished(evt, handler) {
    if (!evt) {
      return;
    }
    const loader = evt.loader;

    if (!loader) {
      return;
    }

    let data = null;

    if (loader instanceof ImageLoader) {
      data = {image: loader.getImage()};
    } else if (loader instanceof Loader3D) {
      data = loader.getData();
    } else if (loader instanceof SampleLoader) {
      data = loader.getResult();
    } else if (loader instanceof QuiltLoader) {
      data = loader.getResult();
    } else if (loader instanceof BackgroundLoader) {
      data = loader.getResult();
    }

    const md = loader.metaData;

    if (md) {
      const assets = md.assets;

      if (assets) {
        const numAssets = assets.length;

        for (let i = 0; i < numAssets; ++i) {
          const asset = assets[i];

          if (asset) {
            this._assignAssetMetaData(asset);
            asset.setData(data);
          }
        }
      }
    }
  }

  dispose() {
    this.cancelLoadConfig();
  }

  cancelLoadConfig() {
    const loaderList = this._loaderList;

    if (!loaderList) {
      return;
    }
    const finishedHandler = this._getLoaderListFinishedHandler(false);

    if (finishedHandler) {
      loaderList.removeEventListener('finished', finishedHandler.handler);
    }

    this._stopLoadingDelay();
    loaderList.cancel();
    this._cancelLoaderRecursive(loaderList);
  }

  _cancelLoaderRecursive(loader) {
    if (!loader) {
      return;
    }
    const finishedHandler = this._getLoaderFinishedHandler(false);

    loader.removeEventListener('finished', finishedHandler.handler);

    if (loader instanceof LoaderList) {
      const loaders = loader.loaders;

      if (loaders) {
        const num = loaders.length;

        for (let i = 0; i < num; ++i) {
          this._cancelLoaderRecursive(loaders[i]);
        }
      }
    }
  }

  _loadLoaders(loadersArray, params) {
    this.cancelLoadConfig();

    let loaderList = this._loaderList;

    const numLoaders = loadersArray ? loadersArray.length : 0;
    const hasLoaders = numLoaders > 0;

    if (!loaderList && hasLoaders) {
      loaderList = this._loaderList = new LoaderList();
      if (!loaderList.metaData) {
        loaderList.metaData = {};
      }
      loaderList.metaData.name = 'mainloader';
    }

    if (hasLoaders) {
      for (let i = 0; i < numLoaders; ++i) {
        const ldr = loadersArray[i];

        if (ldr) {
          const ldrFinishedHandler = this._getLoaderFinishedHandler();

          ldr.once('finished', ldrFinishedHandler.handler);
        }
      }
    }
    if (loaderList) {
      loaderList.loaders = loadersArray;
      loaderList.reset();
    }

    if (hasLoaders) {
      const finishedHandler = this._getLoaderListFinishedHandler();

      finishedHandler.params = params;

      if (params && params.onfinished) {
        loaderList.once('finished', params.onfinished);
      }

      loaderList.once('finished', finishedHandler.handler);

      loaderList.start();
    } else {
      let delay = 0;

      // Gives the UI the time to update the loading/building state
      const minLoadDelay = this.getMinLoadDelay();

      if (typeof (minLoadDelay) === 'number') {
        delay = minLoadDelay;
      }

      if (delay > 0) {
        let handler = this._loadingFinishedDelayHandler;

        if (!handler) {
          const that = this;

          handler = this._loadingFinishedDelayHandler = () => {
            that._loadingFinished();
          };
        }
        this._stopLoadingDelay();
        this._loadingFinishedDelayID = setTimeout(handler, delay);
      } else {
        this._loadingFinished();
      }
    }
  }

  getMinLoadDelay() {
    return this._minLoadDelay;
  }

  setMinLoadDelay(v) {
    this._minLoadDelay = v;
  }

  get minLoadDelay() {
    return this.getMinLoadDelay();
  }

  set minLoadDelay(v) {
    this.setMinLoadDelay(v);
  }

  _stopLoadingDelay() {
    if (typeof (this._loadingFinishedDelayID) === 'number') {
      clearTimeout(this._loadingFinishedDelayID);
    }
  }

  isLoading() {
    const loader = this._loaderList;

    if (!loader) {
      return false;
    }

    return loader.getState() === LoaderState.PROGRESS;
  }

  loadAssets(assets, params) {
    this.cancelLoadConfig();

    const loaderList = this._loaderList;
    let loadersArray = null;

    if (loaderList) {
      loadersArray = loaderList.loaders;
    }
    if (loadersArray) {
      loadersArray.length = 0;
    }
    loadersArray = this.getLoadersByAssets(assets, params, loadersArray);

    this._loadLoaders(loadersArray, params);
  }

  loadConfig(config, params) {
    this.cancelLoadConfig();

    const loaderList = this._loaderList;
    let loadersArray = null;

    if (loaderList) {
      loadersArray = loaderList.loaders;
    }
    if (loadersArray) {
      loadersArray.length = 0;
    }
    loadersArray = this.getLoadersByConfig(config, params, loadersArray);
    this._loadLoaders(loadersArray, params);
  }
}
