export default class Asset {
  constructor(name, type, data = null) {
    this._name = name;
    this._type = type;
    this._data = data;
    this.metaData = null;
  }

  getName() {
    return this._name;
  }

  get name() {
    return this.getName();
  }

  getType() {
    return this._type;
  }

  get type() {
    return this.getType();
  }

  setData(d) {
    this._data = d;
  }

  getData() {
    return this._data;
  }

  get data() {
    return this.getData();
  }

  set data(value) {
    this.setData(value);
  }
}
