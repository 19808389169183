export default class Numeric {

  valueOf() {
    return this.getNumber();
  }

  getValue(params = null) {
    return this.getNumber(params);
  }

  get value() {
    return this.getNumber();
  }

  getNumber(params = null) {
    return 0;
  }
}
