import Vector from '../geom/Vector';
import Vector2 from '../geom/Vector2';
import Vector3 from '../geom/Vector3';
import Vector4 from '../geom/Vector4';
import Vertex from '../geom/Vertex';

export default class VectorCloner {
  static _cloneVectorData(source, dest, params = null) {
    const l = source.getDimension();

    for (let i = 0; i < l; ++i) {
      const coord = source.getCoord(i);

      dest.setCoord(i, coord);
    }

    return dest;
  }

  static _cloneVertex(vertex, params = null) {
    if (!vertex) {
      return null;
    }
    const posCopy = this.clone(vertex.position, params);
    const atts = vertex.attributes;

    const res = new Vertex(posCopy);

    if (atts) {
      const attsCopy = {};

      res.attributes = attsCopy;

      for (const v in atts) {
        if (atts.hasOwnProperty(v)) {
          const att = atts[v];
          const attCopy = this.clone(att, params);

          attsCopy[v] = attCopy;
        }
      }
    }

    return res;
  }

  static clone(vector, params = null) {
    if (!vector) {
      return null;
    }
    if (!vector instanceof Vector) {
      return null;
    }

    if (vector instanceof Vector4) {
      return this._cloneVectorData(vector, new Vector4(), params);
    } else if (vector instanceof Vector3) {
      return this._cloneVectorData(vector, new Vector3(), params);
    } else if (vector instanceof Vector2) {
      return this._cloneVectorData(vector, new Vector2(), params);
    } else if (vector instanceof Vertex) {
      return this._cloneVertex(vector, params);
    }

    return null;
  }
}
