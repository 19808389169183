import BGRUtils from '../../bgr/common/utils/Utils';

const TYPE_UNDEFINED = 'undefined';
const TYPE_NUMBER = 'number';
const TYPE_BOOLEAN = 'boolean';
const TYPE_STRING = 'string';
const TYPE_OBJECT = 'object';

export default class Utils {
  static newSymbol(name) {
    return BGRUtils.newSymbol(name);
  }

  static deepCopy(value) {
    return BGRUtils.deepCopy(value);
  }

  static isPlainObject(obj) {
    return BGRUtils.isPlainObject(obj);
  }

  static tryValues(...values) {
    const l = values.length;

    if (!l) {
      return null;
    }
    for (let i = 0; i < l; ++i) {
      const v = values[i];

      if (!this.isNull(v)) {
        if (typeof (v) === TYPE_NUMBER) {
          if (!isNaN(v)) {
            return v;
          }
        } else {
          return v;
        }
      }
    }

    return null;
  }

  static isNull(value) {
    return value === null || typeof (value) === TYPE_UNDEFINED;
  }

  static isNumber(value) {
    return typeof (value) === TYPE_NUMBER && !isNaN(value);
  }

  static parseNumber(number, fallback) {
    let num = number;

    if (num === null) {
      return fallback;
    }
    let t = typeof (num);

    if (t === TYPE_UNDEFINED) {
      return fallback;
    }
    if (t === TYPE_OBJECT) {
      num = number.valueOf();
      t = typeof (num);
    }
    if (t === TYPE_STRING) {
      num = parseFloat(num);
      t = typeof (num);
    }
    if (t === TYPE_BOOLEAN) {
      return num ? 1 : 0;
    }
    if (t === TYPE_NUMBER) {
      if (isNaN(num)) {
        return fallback;
      }

      return num;
    }

    return fallback;
  }
}
