export default class Dispatcher {
  static setListener(target, listener, enabled = true) {
    if (!target || !listener) {
      return false;
    }
    if (enabled && !target.addListener) {
      return false;
    }
    if (!enabled && !target.removeListener) {
      return false;
    }

    if (enabled) {
      return target.addListener(listener);
    }

    return target.removeListener(listener);
  }
  /**
   * @method _flagOnce
   * @protected
   * @final
   * @description Flags the listener object to be called once
   * @param {Function} listener - The listener object
   * @return {void}
   * */
  _flagOnce(listener) {
    if (!listener) {
      return;
    }
    let map = this._onceMap;

    if (!map) {
      if (typeof (WeakMap) !== 'undefined') {
        map = this._onceMap = new WeakMap();
      } else if (typeof (Map) !== 'undefined') {
        map = this._onceMap = new Map();
      }
    }
    map.set(listener, true);
  }

  /**
   * @method _unflagOnce
   * @protected
   * @final
   * @description Removes flag that indicates the listener object to be called once
   * @param {Function} listener - The listener object
   * @return {void}
   * */
  _unflagOnce(listener) {
    if (!listener) {
      return;
    }
    const map = this._onceMap;

    if (!map) {
      return;
    }
    map.set(listener, false);
  }

  /**
   * @method _isOnce
   * @protected
   * @final
   * @description Returns true if the listener will be called once
   * @param {Function} listener - The listener object
   * @return {Boolean} result - returns true if the listener will be called once
   * */
  _isOnce(listener) {
    const map = this._onceMap;

    if (!map) {
      return false;
    }

    return map.get(listener) === true;
  }

  /**
   * @method addListener
   * @description Adds a single listener
   * @param {Function} listener - The listener function
   * @return {Boolean} result - returns true if added successfully
   * */
  addListener(listener) {
    if (!listener) {
      return false;
    }
    const lis = this._getListenerObject(listener);

    this._unflagOnce(lis);

    return this._addListener(lis);
  }

  /**
   * @method _addListener
   * @private
   * @description Adds a single listener. Called internally by 'addListener' and 'once'
   * @param {Function} listener - The listener function
   * @return {Boolean} result - returns true if added successfully
   * */
  _addListener(listener) {
    if (!listener) {
      return false;
    }
    let listeners = this._listeners;

    if (!listeners) {
      listeners = this._listeners = [];
    }
    if (listeners.indexOf(listener, 0) < 0) {
      listeners.push(listener);

      return true;
    }

    return false;
  }

  /**
   * @method once
   * @description Adds a single listener that will be called once and removed immediately after.
   * @param {Function} listener - The listener function
   * @return {Boolean} result - returns true if added successfully
   * */
  once(listener) {
    if (!listener) {
      return false;
    }
    const lis = this._getListenerObject(listener);

    this._flagOnce(lis);

    return this._addListener(lis);
  }

  /**
  * @method hasListener
  * @description Returns true if the dispatcher contains the given listener
  * @param {Function} lis - The listener function
  * @return {Boolean} result - True if the dispatcher contains the listener
  * */
  hasListener(lis) {
    const listeners = this._listeners;

    if (!listeners) {
      return false;
    }
    const lisObj = this._getListenerObject(lis);

    return listeners.indexOf(lisObj, 0) >= 0;
  }

  /**
   * @method hasListener
   * @description Returns true if the dispatcher contains any listeners
   * @return {Boolean} result - True if the dispatcher contains any listeners
   * */
  hasListeners() {
    const listeners = this._listeners;

    if (!listeners) {
      return false;
    }

    return listeners.length > 0;
  }

  /**
   * @method removeListener
   * @description Removes a single listener
   * @param {Function} listener - The listener function
   * @return {Boolean} result - returns true if removed successfully
   * */
  removeListener(listener) {
    if (!listener) {
      return false;
    }

    const lis = this._getListenerObject(listener);

    this._unflagOnce(lis);

    const listeners = this._listeners;

    if (!listeners) {
      return false;
    }
    const index = listeners.indexOf(lis, 0);

    if (index < 0) {
      return false;
    }
    listeners.splice(index, 1);

    return true;
  }

  /**
   * @method removeListeners
   * @description Removes all listeners
   * @returns {void}
   * */
  removeListeners() {
    this._onceMap = null;

    const listeners = this._listeners;

    if (!listeners) {
      return;
    }
    listeners.length = 0;
  }

  /**
  * @method _getListenerFunc
  * @protected
  * @description Returns the function associated with a listener object
  * By default, the listener object is the function itself.
  * Override to change the behavior
  * @param {Object} lis - The listener object
  * @return {Function} function - The listener function
  * */
  _getListenerFunc(lis) {
    return lis;
  }

  /**
  * @method _getListenerObject
  * @protected
  * @description Creates a new listener object by a function.
  * By default, the listener object is the function itself.
  * Override to change the behavior
  * @param {function} func - The listener function
  * @return {Object} listener - The listener object
  * */
  _getListenerObject(func) {
    return func;
  }

  /**
   * @method _dispatchListener
   * @description Dispatches a single listener
   * @private
   * @param {Object} lis - The listener
   * @param {Array} args - Function arguments
   * @return {Boolean} result - True if dispatched successfully
   * */
  _dispatchListener(lis, args) {
    if (!lis) {
      return false;
    }
    const fn = this._getListenerFunc(lis);

    if (!fn) {
      return false;
    }
    const listeners = this._listeners;

    if (!listeners) {
      return false;
    }

    if (listeners.length === 0) {
      return false;
    }
    const index = listeners.indexOf(lis, 0);

    if (index < 0) {
      return false;
    }
    let result;

    const o = this._isOnce(lis);

    if (o) {
      listeners.splice(index, 1);
      this._unflagOnce(lis);
    }

    if (args) {
      result = fn(...args);
    } else {
      result = fn();
    }

    return (result !== false);
  }

  /**
   * @method dispatch
   * @description Calls all listener functions of this dispatcher
   * @param {...Object} args - function arguments
   * @return {Boolean} result - True if dispatched successfully
   * */
  dispatch(...args) {
    const listeners = this._listeners;

    if (!listeners) {
      return false;
    }
    const num = listeners.length;

    if (num === 0) {
      return false;
    }

    let listenersCopy = this._listenersCopy;

    if (!listenersCopy) {
      listenersCopy = this._listenersCopy = [];
    }

    for (let i = 0; i < num; ++i) {
      listenersCopy[i] = listeners[i];
    }
    listenersCopy.length = num;

    let result = false;

    for (let i = 0; i < num; ++i) {
      const lis = listenersCopy[i];

      if (this._dispatchListener(lis, args) !== false) {
        result = true;
      }
    }

    return result;
  }
}
