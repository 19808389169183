import QuiltDA from './QuiltDA';
import QuiltTypes from './QuiltTypes';
import BumpNormalUtils from '../utils/BumpNormalUtils';
import CanvasUtils from '../utils/CanvasUtils';
// ImageDistanceGlow creates a glow image from a distance field
import ImageDistanceGlow from '../quilt/ImageDistanceGlow';

export default class QuiltConfig {
  constructor(configData, data, quiltAsset) {
    this._configData = configData;
    this._data = data;
    this._quiltAsset = quiltAsset;
    this._quiltImage = null;

    this.userData = null;
    this._cache = null;
  }

  getQuiltAsset() {
    return this._quiltAsset;
  }

  setQuiltAsset(q) {
    this._quiltAsset = q;
  }

  get quiltAsset() {
    return this.getQuiltAsset();
  }

  set quiltAsset(q) {
    this.setQuiltAsset(q);
  }

  isInvalid() {
    const res = this._validate(false);

    return res;
  }

  isValid() {
    return !this.isInvalid();
  }

  _validate(updateCache) {
    let cache = this._cache;

    if (!cache) {
      if (updateCache) {
        cache = this._cache = {};
      }

      return true;
    }

    const quiltConfigData = this._configData;
    const quiltData = this._data;
    const softness = QuiltDA.getSoftness(quiltConfigData, quiltData, 0.5);
    const thickness = QuiltDA.getThickness(quiltConfigData, quiltData, 0);
    const realWidth = QuiltDA.getRealWidth(quiltConfigData, quiltData, 0);
    const realHeight = QuiltDA.getRealHeight(quiltConfigData, quiltData, 0);
    const type = QuiltDA.getType(quiltConfigData, quiltData);

    const qa = this._quiltAsset;

    const bumpAsset = qa ? qa.getBumpMap() : null;
    const bumpImage = bumpAsset ? bumpAsset.getImage() : null;
    const width = CanvasUtils.getWidth(bumpImage);
    const height = CanvasUtils.getHeight(bumpImage);

    const res = (
      // cache.quiltConfigData !== quiltConfigData ||
      // cache.quiltData !== quiltData ||
      cache.type !== type ||
      cache.image !== bumpImage ||
      cache.imageWidth !== width ||
      cache.imageHeight !== height ||
      cache.realWidth !== realWidth ||
      cache.realHeight !== realHeight ||
      cache.softness !== softness ||
      cache.thickness !== thickness
    );

    if (res && updateCache) {
      // cache.quiltConfigData = quiltConfigData;
      // cache.quiltData = quiltData;
      cache.type = type;
      cache.image = bumpImage;
      cache.imageWidth = width;
      cache.imageHeight = height;
      cache.realWidth = realWidth;
      cache.realHeight = realHeight;
      cache.softness = softness;
      cache.thickness = thickness;
    }

    return res;
  }

  getQuiltBumpImage() {
    let update = false;

    if (!this.quiltBumpImage) {
      update = true;
    }

    let cache = this._cache;

    if (!cache) {
      cache = this._cache = {};
    }

    update = this._validate(true) || update;
    /*
    const quiltConfigData = this._configData;
    const quiltData = this._data;
    const softness = QuiltDA.getSoftness(quiltConfigData, quiltData, 0.5);
    const thickness = QuiltDA.getThickness(quiltConfigData, quiltData, 0);
    const realWidth = QuiltDA.getRealWidth(quiltConfigData, quiltData, 0);
    const realHeight = QuiltDA.getRealHeight(quiltConfigData, quiltData, 0);

    const qa = this._quiltAsset;

    const bumpAsset = qa.getBumpMap();
    const bumpImage = bumpAsset ? bumpAsset.getImage() : null;
    const width = CanvasUtils.getWidth(bumpImage);
    const height = CanvasUtils.getHeight(bumpImage);


    if (
      cache.quiltConfigData !== quiltConfigData ||
      cache.quiltData !== quiltData ||
      cache.image !== bumpImage ||
      cache.imageWidth !== width ||
      cache.imageHeight !== height ||
      cache.realWidth !== realWidth ||
      cache.realHeight !== realHeight ||
      cache.softness !== softness ||
      cache.thickness !== thickness
    ) {
      cache.quiltConfigData = quiltConfigData;
      cache.quiltData = quiltData;
      cache.image = bumpImage;
      cache.imageWidth = width;
      cache.imageHeight = height;
      cache.realWidth = realWidth;
      cache.realHeight = realHeight;
      cache.softness = softness;
      cache.thickness = thickness;
      update = true;
    }
    */

    if (update) {
      const qa = this._quiltAsset;

      const bumpAsset = qa ? qa.getBumpMap() : null;
      const bumpImage = bumpAsset ? bumpAsset.getImage() : null;

      const distField = qa ? qa.getBumpDistanceField() : null;

      this._quiltBumpImage = this._applyGlow(bumpImage, distField, this._quiltBumpImage);
    }

    return this._quiltBumpImage;
  }

  getSoftness() {
    return QuiltDA.getSoftness(this._configData, this._data, 0.5);
  }

  getThickness() {
    return QuiltDA.getThickness(this._configData, this._data, 0.5);
  }

  _applyGlow(src, distField, dest) {
    if (!BumpNormalUtils.isBumpMap(src)) {
      return src;
    }
    const quiltConfigData = this._configData;
    const quiltData = this._data;
    const softness = QuiltDA.getSoftness(quiltConfigData, quiltData, 0.5);
    const thickness = QuiltDA.getThickness(quiltConfigData, quiltData, 0);
    const realWidth = QuiltDA.getRealWidth(quiltConfigData, quiltData, 0);
    const realHeight = QuiltDA.getRealHeight(quiltConfigData, quiltData, 0);

    const width = CanvasUtils.getWidth(src);
    const height = CanvasUtils.getHeight(src);

    // Convert thickness & softness to real size (pixel units to cm)
    const scaleX = width / realWidth;
    const scaleY = height / realHeight;
    const scale = scaleX < scaleY ? scaleX : scaleY;
    const realThickness = thickness * scale * 0.5; // divide by 2 because thickness affects both sides of a line
    /*
                  ^              ^
    thickness / 2 |              |
                  V              |
    quilt line    -----------    | total thickness
                  ^              |
    thickness / 2 |              |
                  V              V
    */
    const realSoftness = softness * scale;

    const softnessMultiplier = 10;
    const distancesArray = distField;
    const minDist = realThickness;
    const maxDist = realThickness + realSoftness * softnessMultiplier;
    const interpFunc = null;
    const glowParams = null;
    let dst = dest;

    if (!dst) {
      dst = document.createElement('canvas');
    }
    dst.width = width;
    dst.height = height;

    dst = ImageDistanceGlow.applyGlow(dst, minDist, maxDist, width, height, distancesArray, interpFunc, null, glowParams);

    return dst;
    /*
    if (bumpImage && BumpNormalUtils.isBumpMap(bumpImage)) {
      const glowParams = null;
      const width = CanvasUtils.getWidth(bumpImage);
      const height = CanvasUtils.getHeight(bumpImage);
      const imgData = CanvasUtils.toImageData(bumpImage);
      const distancesArray = ImageDistanceTransform.pixelsToDistances(imgData.data, width, height, glowParams);
      const minDist = 0;
      const maxDist = 100;
      const interpFunc = null;

      const bumpCanvas = ImageDistanceGlow.applyGlow(bumpImage, minDist, maxDist, width, height, distancesArray, interpFunc, null, glowParams);

      return bumpCanvas;
    }

    return bumpImage;
    */
  }

  getRepeatType() {
    return QuiltDA.getRepeatType(this._configData, this._data);
  }

  getImageWidth() {
    const asset = this._quiltAsset;

    if (!asset) {
      return 0;
    }

    return asset.getImageWidth();
  }

  getImageHeight() {
    const asset = this._quiltAsset;

    if (!asset) {
      return 0;
    }

    return asset.getImageHeight();
  }

  getWidth(fallback = 0) {
    return QuiltDA.getRealWidth(this._configData, this._data, fallback);
  }

  getHeight(fallback = 0) {
    return QuiltDA.getRealHeight(this._configData, this._data, fallback);
  }

  getRepeatX(fallback = 0) {
    return QuiltDA.getResultRepeatX(this._configData, this._data, fallback);
  }

  getRepeatY(fallback = 0) {
    return QuiltDA.getResultRepeatY(this._configData, this._data, fallback);
  }

  getQuiltType() {
    const type = QuiltDA.getType(this._configData, this._data);

    return QuiltTypes.getTypeName(type);
  }

  getQuiltID() {
    const quiltConfigData = this._configData;

    if (typeof (quiltConfigData) === 'string') {
      return quiltConfigData;
    }

    return QuiltDA.getID(quiltConfigData);
  }

  getQuiltFoamValue(fallback = 2) {
    return QuiltDA.getFoamThickness(this._configData, this._data, fallback);
  }

  setQuiltFoamValue(value) {
    return QuiltDA.setFoamThickness(this._configData, value);
  }

  getMinBlurScale(fallback = 0) {
    return QuiltDA.getMinBlurScale(this._configData, this._data, fallback);
  }

  getMaxBlurScale(fallback = 0) {
    return QuiltDA.getMaxBlurScale(this._configData, this._data, fallback);
  }

  getNumBlurPasses(fallback = 1) {
    return QuiltDA.getNumBlurPasses(this._configData, this._data, fallback);
  }

  getConfigData() {
    return this._configData;
  }

  setConfigData(v) {
    this._configData = v;
  }

  getData() {
    return this._data;
  }

  setData(v) {
    this._data = v;
  }

  get configData() {
    return this.getConfigData();
  }

  set configData(v) {
    this.setConfigData(v);
  }

  get data() {
    return this.getData();
  }

  set data(v) {
    this.setData(v);
  }
}
