import BorderComponentTypes from './BorderComponentTypes';
import StringUtils from '../utils/StringUtils';

const borderComponentTypeDict = {
  BORDER: 'BORDER3D'
};

function translateBorderComponentType(type) {
  let t = type;

  t = StringUtils.replace(t, '-', '');
  t = StringUtils.replace(t, '_', '');
  t = StringUtils.replace(t, ' ', '');
  t = t.toUpperCase();

  const nt = borderComponentTypeDict[t];

  if (nt) {
    return nt;
  }

  return t;
}

export default class BorderComponentUtils {
  static getBorderComponentTypeByName(name) {
    if (!name) {
      return null;
    }
    const n = translateBorderComponentType(name);

    if (n) {
      return BorderComponentTypes[n.toUpperCase()];
    }

    return BorderComponentTypes[name.toUpperCase()];
  }
}
