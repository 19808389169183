import ImageDistanceTransform from './ImageDistanceTransform';
import CanvasUtils from '../utils/CanvasUtils';

function isArr(arr) {
  if (!arr) {
    return false;
  }

  return (arr instanceof Array) || (Array.isArray && Array.isArray(arr));
}

export default class ImageDistanceGlow {
  /**
   * @method applyGlow
   * @param {Image|Canvas|ImageData|Uint8Array|Uint8ClampedArray|Uint32Array|Array} source - source image data
   * @param {Number} minDist - minimum distance
   * @param {Number} maxDist - maximum glow distance
   * @param {Number} width - the width of the source image. Only used if source is not a Canvas, Image or ImageData
   * @param {Number} height - the height of the source image. Only used if source is not a Canvas, Image or ImageData
   * @param {Array|Float32Array} distancesArray - The array with the closest distances of each pixel
   * @param {Function} interpolationFunc - Optional gradient interpolation function (default = linear interpolation)
   *  Examples:
   *    // Linear interpolation
   *    function (t) {
   *      return t;
   *    }
   *
   *    // Smooth interpolation
   *    function (t) {
   *      return (3 - 2 * t) * t * t;
   *    }
   *
   *    // Round interpolation
   *    function (t) {
   *      const i = 1.0 - t;
   *
   *      return Math.sqrt(1 - i * i);
   *    }
   * @param {Function} getColorFunc - Optional color lookup function (default returns a grayscale)
   *  Example
   *    function (t, params) {
   *      const R = t * 255;
   *      const G = (1 - t) * 255;
   *      const B = 0;
   *      const A = 255;
   *
   *      return (R) | (G << 8) | (B << 16) | (A << 24);
   *    }
   * @param {Object} params - optional params object
   * @returns {Canvas|ImageData|Uint8Array|Uint8ClampedArray|Uint32Array|Array} - Same ref as the 'source' param, unless source is an Image (then a Canvas is returned).
   * */
  static applyGlow(source, minDist = 0, maxDist = 100, width = 0, height = 0, distancesArray = null, interpolationFunc = null, getColorFunc = null, params = null) {
    let pixels = source, wi = width, he = height;

    const isCvs = CanvasUtils.isCanvas(pixels);
    const isImg = CanvasUtils.isImage(pixels);
    const isImgData = CanvasUtils.isImageData(pixels);
    const interpFunc = interpolationFunc ? interpolationFunc : ImageDistanceTransform.CircleInterpolate;

    let res = pixels, imgData = null;

    if (isCvs || isImg || isImgData) {
      wi = CanvasUtils.getWidth(pixels);
      he = CanvasUtils.getHeight(pixels);

      if (isCvs || isImg) {
        res = CanvasUtils.toCanvas(pixels);
        const ctx = res.getContext('2d');

        imgData = ctx.getImageData(0, 0, wi, he);
        pixels = imgData.data;

      } else if (isImgData) {
        pixels = pixels.data;
      }
    }
    if (!((pixels instanceof Uint32Array) || (pixels instanceof Uint8Array) || (pixels instanceof Uint8ClampedArray) || isArr(pixels))) {
      return null;
    }
    ImageDistanceTransform.applyGlow(pixels, minDist, maxDist, wi, he, distancesArray, interpFunc, getColorFunc, params);

    if (imgData && CanvasUtils.isCanvas(res)) {
      const ctx = res.getContext('2d');

      ctx.putImageData(imgData, 0, 0);
    }

    return res;
  }
}
