import * as THREE from 'three';
import CanvasUtils from '../../bgr/common/utils/CanvasUtils';
import ThreeBumpToNormalConverter from './ThreeBumpToNormalConverter';

const DEFAULT_MAX_TEXTURE_SIZE = 4096;

export default class ThreeAssetUtils {
  _getImageAssetNormalTexture(bumpToNormalConverter, imageAsset, params = null, conversionParams = null) {
    if (!imageAsset) {
      return null;
    }
    let d = imageAsset.getData();
    let img = null;

    if (d instanceof Image) {
      img = d;
      d = {};
      d.image = img;
      imageAsset.setData(d);
    }

    let tex = d.texture;

    if (tex) {
      return tex;
    }

    if (!img) {
      img = imageAsset.getImage();
    }

    if (!ThreeBumpToNormalConverter.isBumpMap(img)) {
      return this._getImageAssetTexture(imageAsset);
    }

    const converter = bumpToNormalConverter;

    let renderer = null;

    if (params) {
      renderer = params.renderer;
    }
    if (!renderer) {
      renderer = this.renderer || this._renderer;
    }

    let convParams = conversionParams;

    if (!convParams) {
      convParams = {
        normalIntensity: 10,
        method: 'readpixels',
        textureParams: {
          wrapS: THREE.RepeatWrapping,
          wrapT: THREE.RepeatWrapping
        }
        // method: null
      };
    }

    const res = converter.convertToNormalTexture(img, renderer, convParams);

    if (res instanceof THREE.WebGLRenderTarget) {
      d.renderTarget = res;
      tex = res.texture;
    } else if (res instanceof THREE.Texture) {
      tex = res;
    }
    d.texture = tex;

    return tex;
  }

  static _getTextureResizeParams(params) {
    let res = this._textureResizeParams;

    if (!res) {
      res = this._textureResizeParams = {};
    }

    return res;
  }

  static _getMaxTextureSize(params) {
    if (!params) {
      return DEFAULT_MAX_TEXTURE_SIZE;
    }
    const settings = params.settings;

    if (settings && settings.maxTextureSize) {
      return settings.maxTextureSize;
    }

    const ctx = params.context || params.gl;

    if (ctx && ctx.getParameter) {
      ctx.getParameter(ctx.MAX_TEXTURE_SIZE);
    }

    return DEFAULT_MAX_TEXTURE_SIZE;
  }

  static getImageAssetTexture(imageAsset, params) {
    if (!imageAsset) {
      return null;
    }
    let d = imageAsset.getData();

    if (!d) {
      return null;
    }

    let img = null;

    if (d instanceof Image) {
      img = d;

      d = {};
      imageAsset.setData(d);
    }

    let tex = d.texture;

    if (tex) {
      return tex;
    }
    if (!img) {
      img = imageAsset.getImage();
    }
    if (!img) {
      return null;
    }
    const texResizeParams = this._getTextureResizeParams(params);

    texResizeParams.maxSize = this._getMaxTextureSize(params);

    const texImage = CanvasUtils.resizeToPO2(img, texResizeParams);

    if (!texImage) {
      return null;
    }

    d.textureImage = texImage;

    tex = new THREE.Texture();
    tex.flipY = false;
    tex.image = texImage;
    tex.anisotropy = 4;
    tex.wrapS = THREE.RepeatWrapping;
    tex.wrapT = THREE.RepeatWrapping;

    tex.needsUpdate = true;

    d.texture = tex;

    return tex;
  }
}
