import BD3DFabricMaterial from './BD3DFabricMaterial';
import MattressDA from '../mattress/MattressDA';

export default class BD3DSampleFabricMaterial extends BD3DFabricMaterial {
  constructor() {
    super();
    this._sampleAsset = null;
    this._quiltAsset = null;
    this._quiltConfig = null;
  }

  clear() {
    super.clear();
    this.setSampleAsset(null);
    this.setQuiltAsset(null);
    this.setQuiltConfig(null);
  }

  _getClone() {
    let res = super._getClone();

    if (res instanceof BD3DSampleFabricMaterial) {
      return res;
    }
    res = new BD3DSampleFabricMaterial(this.getType());

    return res;
  }

  _initClone(res) {
    super._initClone(res);
    res.setSampleAsset(this.getSampleAsset());
    res.setQuiltAsset(this.getQuiltAsset());
    res.setQuiltConfig(this.getQuiltConfig());
    res.setFabricType(this.getFabricType());
    res.setQuiltTexture(this.getQuiltTexture());
    res.getQuiltNormalMap(this.getQuiltNormalMap());
    res.setSampleTexture(this.getSampleTexture());
    res.setSampleNormalMap(this.getSampleNormalMap());
    res.setSampleSpecularMap(this.getSampleSpecularMap());
  }

  getQuiltNormalIntensity() {
    // QuiltDA.getFoamThickness
    const cfg = this.getQuiltConfig();

    if (cfg) {
      const div = 5;

      return cfg.getQuiltFoamValue() / div;
    }

    return super.getQuiltNormalIntensity();
  }

  getQuiltRepeatX() {
    const qconfig = this.getQuiltConfig();

    if (qconfig) {
      return qconfig.getRepeatX();
    }

    const q = this.getQuiltAsset();

    if (q) {
      return q.getRepeatX();
    }

    return super.getQuiltRepeatX();
  }

  getQuiltRepeatY() {
    const qconfig = this.getQuiltConfig();

    if (qconfig) {
      return qconfig.getRepeatY();
    }

    const q = this.getQuiltAsset();

    if (q) {
      return q.getRepeatY();
    }

    return super.getQuiltRepeatY();
  }

  getQuiltWidth() {
    const qconfig = this.getQuiltConfig();
    const fallback = super.getQuiltWidth();

    if (qconfig) {
      return qconfig.getWidth(fallback);
    }
    const asset = this.getQuiltAsset();

    if (asset) {
      return asset.getWidth(fallback);
    }

    return fallback;
  }

  getQuiltHeight() {
    const fallback = super.getQuiltHeight();
    const qconfig = this.getQuiltConfig();

    if (qconfig) {
      return qconfig.getWidth(fallback);
    }

    const asset = this.getQuiltAsset();

    if (asset) {
      return asset.getWidth(fallback);
    }

    return fallback;
  }

  getQuiltConfig() {
    return this._quiltConfig;
  }

  setQuiltConfig(cfg) {
    this._quiltConfig = cfg;
  }

  get quiltConfig() {
    return this.getQuiltConfig();
  }

  set quiltConfig(v) {
    this.setQuiltConfig(v);
  }

  getQuiltAsset() {
    return this._quiltAsset;
  }

  setQuiltAsset(asset) {
    this._quiltAsset = asset;
  }

  getSampleAsset() {
    return this._sampleAsset;
  }

  setSampleAsset(asset) {
    this._sampleAsset = asset;
  }

  getSampleConfigData() {
    const asset = this.getSampleAsset();

    if (asset) {
      const res = asset.getSampleConfigData();

      if (res) {
        return res;
      }
    }

    return this._sampleConfigData;
  }

  setSampleConfigData(v) {
    this._sampleConfigData = v;
  }

  getSampleData() {
    const asset = this.getSampleAsset();

    if (asset) {
      const res = asset.getSampleData();

      if (res) {
        return res;
      }
    }

    return this._sampleData;
  }

  setSampleData(sd) {
    this._sampleData = sd;
  }

  getSampleID() {
    let res = null;
    const sd = this.getSampleData();

    res = MattressDA.getSampleID(sd);
    if (typeof (res) !== 'undefined' && res !== null) {
      return res;
    }
    const scd = this.getSampleConfigData();

    res = MattressDA.getSampleID(scd);
    if (typeof (res) !== 'undefined' && res !== null) {
      return res;
    }

    return this._sampleID;
  }

  get sampleID() {
    return this.getSampleID();
  }

  getQuiltData() {
    return this._quiltData;
  }

  setQuiltData(d) {
    this._quiltData = d;
  }


  getQuiltTexture() {
    const quiltAsset = this.getQuiltAsset();

    if (quiltAsset) {
      const res = quiltAsset.getTexture();

      if (res) {
        return res;
      }
    }

    return this.quiltTexture;
  }

  getQuiltNormalMap() {
    const quiltAsset = this.getQuiltAsset();

    if (quiltAsset) {
      const res = quiltAsset.getNormalMap();

      if (res) {
        return res;
      }
    }

    return this.quiltNormalMap;
  }

  getQuiltBumpMap() {
    const quiltAsset = this.getQuiltAsset();

    if (quiltAsset) {
      const res = quiltAsset.getBumpMap();

      if (res) {
        return res;
      }
    }

    return super.getQuiltBumpMap();
  }

  // Returns the child asset of a sample asset
  _getSampleAssetAsset(key, fallback) {
    const asset = this.getSampleAsset();

    if (!asset) {
      return fallback;
    }
    const childAsset = asset.getAsset(key);

    if (!childAsset) {
      return fallback;
    }

    return childAsset;
  }

  get sampleAsset() {
    return this.getSampleAsset();
  }

  set sampleAsset(v) {
    this.setSampleAsset(v);
  }

  getSampleTexture() {
    return this._getSampleAssetAsset('texture', super.getSampleTexture());
  }

  getSampleWidth() {
    const fallback = super.getSampleWidth();
    const asset = this.getSampleAsset();

    if (asset) {
      return asset.getWidth(fallback);
    }

    return fallback;
  }

  getSampleHeight() {
    const fallback = super.getSampleHeight();
    const asset = this.getSampleAsset();

    if (asset) {
      return asset.getHeight(fallback);
    }

    return fallback;
  }

  getSampleNormalMap() {
    return this._getSampleAssetAsset('normal',
      this._getSampleAssetAsset('bump', super.getSampleNormalMap()));
  }

  setSampleNormalMap(v) {
    this.sampleNormalMap = v;
  }

  getSampleSpecularMap() {
    return this._getSampleAssetAsset('specular', super.getSampleSpecularMap());
  }

  setSampleSpecularMap(v) {
    this.sampleSpecularMap = v;
  }

  setSampleOffset(x, y) {
    let off = this.get('offset');

    if (!off) {
      off = {};
      this.set('offset', off);
    }

    off.x = x;
    off.y = y;
  }

  getSampleOffsetX() {
    const off = this.get('offset');

    if (!off) {
      return 0;
    }

    return off.x;
  }

  setSampleOffsetX(v) {
    let off = this.get('offset');

    if (!off) {
      off = {};
      this.set('offset', off);
    }

    off.x = v;
  }

  getSampleOffsetY() {
    const off = this.get('offset');

    if (!off) {
      return 0;
    }

    return off.y;
  }

  setSampleOffsetY(v) {
    let off = this.get('offset');

    if (!off) {
      off = {};
      this.set('offset', off);
    }

    off.y = v;
  }
}
