import GraphicsComponentController from '../../common/controller/GraphicsComponentController';
import ThreeComponent from '../component/ThreeComponent';

export default class ThreeComponentController extends GraphicsComponentController {
  getRenderer() {
    const comp = this.getComponent();

    if (!comp) {
      return null;
    }
    if (comp instanceof ThreeComponent) {
      return comp.getRenderer();
    }

    return null;
  }
  get renderer() {
    return this.getRenderer();
  }
}
