import XHRLoader from '../../common/loading/XHRLoader';
import Parser3D from '../parsers/Parser3D';

export default class Loader3D extends XHRLoader {
  constructor(params) {
    super(params);
    if (params) {
      if ((params.parser) && (params.parser instanceof Parser3D)) {
        this.setParser(params.parser);
      }
      this.parseParams = params.parseParams;
    }
  }

  getParser() {
    return this._parser;
  }

  setParser(p) {
    if (p instanceof Parser3D) {
      this._parser = p;
    }
  }

  getResponse() {
    let res = this._data;

    if (res === null || typeof (res) === 'undefined') {
      const resp = super.getResponse();
      const parser = this.getParser();

      if (parser && parser instanceof Parser3D) {
        res = parser.parse(resp, this.parseParams);
        this._data = res;
      }
    }

    return res;
  }

  getData() {
    return this.getResponse();
  }

  get data() {
    return this.getData();
  }

  reset() {
    super.reset();
    this._data = null;
  }
}
