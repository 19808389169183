import Mattress3DBuilder from './Mattress3DBuilder';

// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
// #endif

import LoadingManager from '../loading/LoadingManager';
import AssetManager from '../asset/AssetManager';
// import URLResolver from '../loading/URLResolver';
// import URLUtils from '../../bgr/common/utils/URLUtils';

import ContainerNode3D from '../../bgr/bgr3d/scenegraph/ContainerNode3D';
import EventDispatcher from '../../bgr/common/events/EventDispatcher';
import {getAnimationTime, reqAnimFrame, cancelAnimFrame} from '../../bgr/common/anim/anim.js';


const EVENT_COMPLETE = Symbol('EVENT_COMPLETE');
const EVENT_LOADED = Symbol('EVENT_LOADED');
const EVENT_BUILT = Symbol('EVENT_BUILT');

/**
* @class Mattress3DFactory
* @description Factory where 3D mattresses are constructed.
* Has an asset manager that keeps track of (external) assets
* Has a loading manager that should load all required assets
* Has a builder that builds 3D mattresses from configs (json data)
*
* The loading manager requests all needed assets from the asset manager using the input config
* The asset manager returns the assets that are needed for the current config
* The loading manager creates separate loaders for each asset that still has no data
*  and uses a URLResolver to translate the asset's url data to a valid url if needed.
* When all assets are loaded, the loading manager dispatches a 'finished' event
*  and the builder can start building 3D objects from the config and the loaded assets
*/
export default class Mattress3DFactory extends EventDispatcher {

  _getAssetManager() {
    let mgr = this._assetManager;

    if (!mgr) {
      mgr = this._assetManager = new AssetManager();
    }

    return mgr;
  }

  getAssetManager() {
    return this._getAssetManager();
  }

  get assetManager() {
    return this._getAssetManager();
  }

  /*
  // Fixed asset path. Currently hard-coded but should be an external param
  _getAssetPath() {
    return '../assets';
  }

  _getURLResolver() {
    let res = this._urlResolver;

    if (!res) {
      const that = this;
      const httpPrefix = 'http://';
      const httpsPrefix = 'https://';

      res = this._urlResolver = new URLResolver({
        onResolveURL: url => {
          let resURL = null;

          if (typeof (url) === 'string') {
            resURL = url;
            // Incoming URL is a string

            if (url.substring(0, httpPrefix.length) === httpPrefix || url.substring(0, httpsPrefix.length) === httpsPrefix) {
              // Incoming URL starts with http:// or https:// -> probably an absolute path, so no need to resolve
              return url;
            }

            // Incoming URL is a relative path, prepend the fixed assets path
            const assetPath = that._getAssetPath();

            if (!assetPath) {
              return resURL;
            }

            resURL = URLUtils.joinURL(assetPath, resURL);
          }

          return resURL;
        }
      });
    }

    return res;
  }
  */

  getBackgroundService() {
    const loadingMgr = this._getLoadingManager();

    if (!loadingMgr) {
      return null;
    }

    return loadingMgr.getBackgroundService();
  }

  setBackgroundService(svc) {
    const loadingMgr = this._getLoadingManager();

    if (!loadingMgr) {
      return;
    }

    loadingMgr.setBackgroundService(svc);
  }

  get backgroundService() {
    return this.getBackgroundService();
  }

  set backgroundService(v) {
    this.setBackgroundService(v);
  }

  setSampleService(svc) {
    const loadingMgr = this._getLoadingManager();

    if (!loadingMgr) {
      return;
    }

    loadingMgr.setSampleService(svc);
  }

  getSampleService() {
    const loadingMgr = this._getLoadingManager();

    if (!loadingMgr) {
      return null;
    }

    return loadingMgr.getSampleService();
  }

  set sampleService(svc) {
    this.setSampleService(svc);
  }

  get sampleService() {
    return this.getSampleService();
  }

  setQuiltService(svc) {
    const loadingMgr = this._getLoadingManager();

    if (!loadingMgr) {
      // #if DEBUG
      BD3DLogger.warn('Unable to set quilt service - no loading manager');
      // #endif

      return;
    }

    loadingMgr.setQuiltService(svc);
  }

  getQuiltService() {
    const loadingMgr = this._getLoadingManager();

    if (!loadingMgr) {
      return null;
    }

    return loadingMgr.getQuiltService();
  }

  get quiltService() {
    return this.getQuiltService();
  }

  set quiltService(svc) {
    this.setQuiltService(svc);
  }

  _getLoadingManager() {
    let mgr = this._loadingManager;

    if (!mgr) {
      mgr = this._loadingManager = new LoadingManager({
        // urlResolver: this._getURLResolver(),
        assetManager: this._getAssetManager()
      });
    }

    return mgr;
  }

  _getBuilder() {
    let b = this._mattressBuilder;

    if (!b) {
      b = new Mattress3DBuilder();
    }

    return b;
  }

  clearNode(node) {
    this._clearNode(node);
  }

  _clearNode(node) {
    if (!node) {
      return;
    }
    if (node instanceof ContainerNode3D) {
      let children = null;

      if (node.removeChildren) {
        node.removeChildren();
      } else if (node.getChildren) {
        children = node.getChildren();
      } else if (node.children) {
        children = node.children;
      }
      if (children) {
        children.length = 0;
      }
    }
  }

  _getBuildParams(params = null) {
    let res = this._buildParams;

    if (!res) {
      res = this._buildParams = {};
    }

    if (params) {
      for (const v in params) {
        if (params.hasOwnProperty(v)) {
          res[v] = params[v];
        }
      }
    }

    return res;
  }

  loadAndBuildMattressConfig(config, buildParams = null, res = null) {
    const loadingMgr = this._getLoadingManager();

    if (!config || !loadingMgr) {
      this._clearNode(res);
      this._loadingComplete(config, buildParams, res);

      return;
    }

    const bp = this._getBuildParams(buildParams);
    const am = this._getAssetManager();

    bp.assets = am.assets;
    bp.assetCollections = am.assetCollections;

    const usedAssets = am.getAssetsByConfig(config);

    const prevAssets = this._prevAssets;

    // if (prevAssets) {
    //   const num = prevAssets.length;

    //   for (let i = num - 1; i >= 0; --i) {
    //     const prevAsset = prevAssets[i];

    //     if (!usedAssets || usedAssets.indexOf(prevAsset, 0) < 0) {
    //       if (usedAssets) {
    //         prevAssets.splice(i, 1);
    //       }
    //       this._onRemovedAsset(prevAsset);
    //     }
    //   }
    // }
    // if (usedAssets) {
    //   const num = usedAssets.length;
    //   let newPrevAssets = prevAssets;

    //   for (let i = 0; i < num; ++i) {
    //     const asset = usedAssets[i];

    //     if (!prevAssets || prevAssets.indexOf(asset, 0) < 0) {
    //       this._onAddedAsset(asset);
    //       if (!newPrevAssets) {
    //         newPrevAssets = this._prevAssets = [];
    //       }
    //       newPrevAssets.push(asset);
    //     }
    //   }
    // } else if (prevAssets) {
    //   prevAssets.length = 0;
    // }

    this._prevAssets = am.compareAndDisposeUnusedAssets(usedAssets, prevAssets);

    loadingMgr.once('finished', this._getLoadingFinishedHandler(config, bp, res));
    loadingMgr.loadConfig(config, {assetManager: am});
  }

  isLoading() {
    const loadingMgr = this._getLoadingManager();

    if (!loadingMgr) {
      return false;
    }

    return loadingMgr.isLoading();
  }

  cancelLoadConfig() {
    const loadingMgr = this._getLoadingManager();

    if (!loadingMgr) {
      return;
    }

    loadingMgr.cancelLoadConfig();
  }

  dispose() {
    this.cancelLoadConfig();
    const loadingMgr = this._getLoadingManager();

    if (loadingMgr) {
      loadingMgr.removeEventListeners();
      loadingMgr.dispose();
    }

    const assetMgr = this._assetManager;

    if (assetMgr) {
      assetMgr.dispose();
    }
  }

  // _onRemovedAsset(asset) {
  //   const assetMgr = this._getAssetManager();

  //   if (!assetMgr || assetMgr.assets) {
  //     return;
  //   }

  //   assetMgr.assetCollections.samples.removeAsset(asset);
  // }

  // _onAddedAsset(asset) {
  // }

  _getLoadingFinishedHandler(config, buildParams, res) {
    let handler = this._loadingFinishedHandler;

    if (!handler) {
      const that = this;

      handler = this._loadingFinishedHandler = {
        func: evt => {
          return that._handleLoadingFinished(evt, handler.config, handler.buildParams, handler.result);
        }
      };
    }
    handler.config = config;
    handler.buildParams = buildParams;
    handler.result = res;

    return handler.func;
  }

  _handleLoadingFinished(evt, config, buildParams = null, result = null) {
    this._dispatchFactoryEvent('loaded', EVENT_LOADED, config, buildParams, result);
    this._loadingComplete(config, buildParams, result);
  }

  _dispatchFactoryEvent(evtType, key, config, buildParams, result) {
    if (this.hasEventListener(evtType)) {
      const evt = (key && this[key]) || { type: evtType, options: null, config: null, result: null };
      if (key) {
        this[key] = evt;
      }
      evt.type = evtType;
      evt.config = config;
      evt.buildParams = buildParams;
      evt.result = result;

      this.dispatchEvent(evt);
    }
  }

  _dispatchComplete(config, buildParams, result) {
    this._dispatchFactoryEvent('complete', EVENT_COMPLETE, config, buildParams, result);
  }

  _loadingComplete(config, buildParams = null, res = null) {
    if (this.useBuildRequest) {
      this._requestBuild(config, buildParams, res);
    } else {
      this._buildLoadedMattressConfig(config, buildParams, res);
      this._dispatchComplete(config, buildParams, res);
    }
  }

  _requestBuild(config, buildParams, res) {
    let brd = this._buildRequestData, brh;

    if (!brd) {
      brd = {};
    }
    brd.config = config;
    brd.buildParams = buildParams;
    brd.result = res;
    brd.time = getAnimationTime();
    brh = brd.handler;

    if (typeof (brd.ID) === 'number' && brd.ID > -1) {
      cancelAnimFrame(brd.ID);
    }

    if (!brh) {
      const that = this;

      brh = brd.handler = () => {
        const t = getAnimationTime();
        const dt = t - brd.time;
        const DELAY = 1;

        if (dt > DELAY) {
          const cfg = brd.config;
          const bp = brd.buildParams;
          const r = brd.result;

          if (cfg) {
            that._buildLoadedMattressConfig(cfg, bp, r);
          }

          brd.ID = -1;
          brd.config = null;
          brd.buildParams = null;
          brd.result = null;

          that._dispatchComplete(cfg, buildParams);
        } else {
          brd.ID = reqAnimFrame(brd.handler);
        }
      };
    }
    brd.ID = reqAnimFrame(brh);
  }

  clearMattressContainer() {
    this._clearNode(this._result);
  }

  _buildLoadedMattressConfig(config, buildParams, res = null) {
    let result = res;

    this._clearNode(res);

    if (!result) {
      result = this._result;
    }

    /*
    if ((buildParams.dataNode3DMap) && (buildParams.dataNode3DMap instanceof Map) && buildParams.dataNode3DMap.clear) {
      buildParams.dataNode3DMap.clear();
    }
    */

    this._result = this.buildMattressConfig(config, buildParams, result);

    /*
    if (buildParams) {
      this._dataNode3DMap = buildParams.dataNode3DMap;
    }
    */
    /*
    let builtEvent = this._builtEventObject;

    if (!builtEvent) {
      builtEvent = this._builtEventObject = {};
    }
    builtEvent.type = 'built';
    builtEvent.buildParams = buildParams;
    builtEvent.result = result;
    builtEvent.config = config;
    this.dispatchEvent(builtEvent);
    */
    this._dispatchFactoryEvent('built', EVENT_BUILT, config, buildParams, result);
  }

  getNode3DByData(data) {
    return this._getDataNode3DAssociation(data, this._dataNode3DMap);
  }

  getDataByNode3D(node3D) {
    return this._getDataNode3DAssociation(node3D, this._dataNode3DMap);
  }

  _getDataNode3DAssociation(key, map) {
    if (!key) {
      return null;
    }

    if (!map) {
      return null;
    }

    return map.get(key);
  }

  getResult() {
    return this._result;
  }

  get result() {
    return this.getResult();
  }

  buildMattressConfig(config, buildParams = null, res = null) {
    const builder = this._getBuilder();

    if (!builder) {
      return res;
    }
    let bp = buildParams;
    const am = this._getAssetManager();

    if (bp !== this._buildParams) {
      bp = this._getBuildParams(bp);
    }
    bp.assets = am.assets;
    bp.assetCollections = am.assetCollections;

    // Store old mappings in prevDataNode3DMap to reuse geometry/materials/... while building
    let prevDataNode3DMap = buildParams ? buildParams.prevDataNode3DMap : null;

    if (prevDataNode3DMap && prevDataNode3DMap.clear) {
      prevDataNode3DMap.clear();
    }

    if (buildParams && buildParams.dataNode3DMap) {
      if (!prevDataNode3DMap) {
        prevDataNode3DMap = new WeakMap();
        buildParams.prevDataNode3DMap = prevDataNode3DMap;
      }
      for (const [key, value] of buildParams.dataNode3DMap) {
        prevDataNode3DMap.set(key, value);
      }
    }

    if (buildParams && (buildParams.dataNode3DMap) && (buildParams.dataNode3DMap instanceof Map) && buildParams.dataNode3DMap.clear) {
      buildParams.dataNode3DMap.clear();
    }

    const result = builder.buildMattressConfig(config, bp, res);

    if (result) {
      config.updateSceneContent({buildParams});
      const sceneContent = config.getSceneContent();

      result.addChild(sceneContent);
    }

    if (buildParams) {
      this._dataNode3DMap = buildParams.dataNode3DMap;
    }

    if (prevDataNode3DMap && prevDataNode3DMap.clear) {
      prevDataNode3DMap.clear();
    }

    return result;
  }
}
