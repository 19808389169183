// All utils are used internally by BD3DSceneParser
import Property from './Property';
import MattressGeomUtils from '../../geom/MattressGeomUtils';
import BD3DGeometry from '../../geom/BD3DGeometry';
import Node3D from '../../../bgr/bgr3d/scenegraph/Node3D';
import ContainerNode3D from '../../../bgr/bgr3d/scenegraph/ContainerNode3D';
import GeometryNode3D from '../../../bgr/bgr3d/scenegraph/GeometryNode3D';

const propertyChangedEvent = {};

export function setGeometryUVWorldTransformRecursive(target, params) {
  if (!params) {
    return null;
  }
  if (target instanceof BD3DGeometry) {
    const {
      uscale, vscale, uoffset, voffset,
      xscale, yscale,
      umin, umax, vmin, vmax
    } = params;

    MattressGeomUtils.setGeometryUVWorldTransform(target, uscale, vscale, uoffset, voffset);
    const transf = MattressGeomUtils.getGeometryUVWorldTransform(target);

    if (transf) {
      transf.minU = umin;
      transf.minV = vmin;
      transf.maxU = umax;
      transf.maxV = vmax;

      transf.scaleX = xscale;
      transf.scaleY = yscale;
    }

    return transf;
  } else if (target instanceof Node3D) {
    if (target instanceof ContainerNode3D) {
      const children = target.getChildren();
      const numChildren = children ? children.length : 0;
      let res = null;

      for (let i = 0; i < numChildren; ++i) {
        res = setGeometryUVWorldTransformRecursive(children[i], params);
      }

      return res;
    } else if (target instanceof GeometryNode3D) {
      return setGeometryUVWorldTransformRecursive(target.getGeometry(), params);
    }
  }

  return null;
}

export function notifyPropertyChanged(object, data, property, value, previousValue, params) {
  if (!params) {
    return;
  }
  const p = params.params;

  if (!p) {
    return;
  }

  const {notifier} = p;

  if (!notifier || !notifier.dispatchEvent) {
    return;
  }
  const evt = propertyChangedEvent;

  evt.type = 'change_property';
  evt.object = object;
  evt.objectData = data;
  evt.property = property;
  evt.value = value;

  notifier.dispatchEvent(evt);
}

export function parseNumber(value, session, def = 0) {
  if (value === null) {
    return def;
  }
  let type = typeof (value);

  if (type === 'undefined') {
    return def;
  }
  let res = value;

  if (type === 'object') {
    res = res.valueOf();
    type = typeof (res);
  }
  if (type === 'string') {
    res = parseFloat(res);
    if (isNaN(res)) {
      return def;
    }
    type = typeof (res);
  }
  if (type === 'boolean') {
    return res ? 1 : 0;
  }
  if (type === 'number') {
    return res;
  }

  return def;
}

export function getFactoryTypes(params) {
  return params ? (params.factories || params.factoryTypes) : null;
}

export function parseValue(data, id, session) {
  const parser = session.getData('parser');

  parser._parseValue(data, null, null, session.getData('session'));
}

export function castValue(data, classType, id, session) {
  if (!classType) {
    return parseValue(data, id, session);
  }
  if (data instanceof classType) {
    return data;
  }
  const res = parseValue(data, id, session);

  if (res instanceof classType) {
    return res;
  }

  return null;
}

export function initOutputParams(result = null) {
  const res = result || {
    options: null,
    targetObject: null,
    targetData: null,
    refresh: false,
    reload: false,
    refreshRender: false, // refresh render data (three.js)
    overrideReload: false,
    overrideRefresh: false,
    overrideRefreshRender: false
  };
  res.options = null;
  res.targetObject = null;
  res.targetData = null;
  res.refresh = false;
  res.reload = false;
  res.overrideReload = false;
  res.overrideRefresh = false;
  return res;
}

export {
  Property
};
