import HandleTypes from './HandleTypes';
import HandleStyle from './HandleStyle';

const horizontal = HandleTypes.HORIZONTAL;
const vertical = HandleTypes.VERTICAL;
const suitcase = HandleTypes.SUITCASE;
const pocket = HandleTypes.POCKET;

const NO_EDITABLE_COMPONENTS = [];
const EDITABLE_FABRIC_COMPONENT = ['fabric'];
const EDITABLE_TAPE_AND_FABRIC_COMPONENTS = ['tape', 'fabric'];
const EDITABLE_FABRIC_AND_TAPE_COMPONENTS = ['fabric', 'tape'];

const list = [
  new HandleStyle('HVS00001', vertical, EDITABLE_FABRIC_COMPONENT),
  new HandleStyle('HVS00002', vertical, EDITABLE_FABRIC_COMPONENT, false),
  new HandleStyle('HVS003', vertical, NO_EDITABLE_COMPONENTS),
  new HandleStyle('HV004', vertical, EDITABLE_FABRIC_COMPONENT, false),
  new HandleStyle('HVS005', vertical, EDITABLE_TAPE_AND_FABRIC_COMPONENTS),
  new HandleStyle('HVS006', vertical, EDITABLE_FABRIC_AND_TAPE_COMPONENTS),
  new HandleStyle('HV007', vertical, EDITABLE_FABRIC_COMPONENT, false),
  new HandleStyle('HV008', vertical, EDITABLE_FABRIC_COMPONENT),
  new HandleStyle('HV012', vertical, EDITABLE_FABRIC_COMPONENT),

  new HandleStyle('HHS012', horizontal, EDITABLE_FABRIC_COMPONENT),
  new HandleStyle('HHS013', horizontal, EDITABLE_FABRIC_COMPONENT, false),
  new HandleStyle('HHS014', horizontal, NO_EDITABLE_COMPONENTS),
  new HandleStyle('HHS015', horizontal, EDITABLE_FABRIC_COMPONENT, false),
  new HandleStyle('HHS016', horizontal, EDITABLE_TAPE_AND_FABRIC_COMPONENTS),
  new HandleStyle('HHS017', horizontal, EDITABLE_FABRIC_AND_TAPE_COMPONENTS),

  new HandleStyle('HS041', suitcase, NO_EDITABLE_COMPONENTS, false),
  new HandleStyle('HS042', suitcase, NO_EDITABLE_COMPONENTS, false),
  new HandleStyle('HS043', suitcase, NO_EDITABLE_COMPONENTS, false),

  new HandleStyle('HHP031', pocket, NO_EDITABLE_COMPONENTS, false),
  new HandleStyle('HHP032', pocket, NO_EDITABLE_COMPONENTS, false),
  new HandleStyle('HHP033', pocket, NO_EDITABLE_COMPONENTS, false)
];

let map = null;

function getMap() {
  if (map) {
    return map;
  }
  const num = list.length;

  map = {};
  for (let i = 0; i < num; ++i) {
    const hs = list[i];

    if (hs) {
      const id = hs.getID();

      map[id] = hs;
    }
  }

  return map;
}

export default class HandleStyles {
  static getHandleComponentTypes(style) {
    let stID = null;
    let stObj = null;


    if (style instanceof HandleStyle) {
      stObj = style;
      stID = stObj.getID();
    } else if (typeof (style) === 'string') {
      stID = style;
      stObj = this.get(stID);
    }

    if (!stObj) {
      return null;
    }

    if (stObj.editableComponents) {
      return stObj.editableComponents;
    }

    // TODO: remove code below
    const type = stObj.getType();

    if (type === HandleTypes.VERTICAL || type === HandleTypes.HORIZONTAL) {
      const stlc = stID && stID.toLowerCase ? stID.toLowerCase() : null;

      if (
        stlc === 'hvs005' || stlc === 'hvs006' ||
        stlc === 'hhs016' || stlc === 'hhs017'
      ) {
        return ['tape', 'fabric'];
      } else if (stlc === 'hhs014' || stlc === 'hvs003') {
        return null;
      }

      return ['fabric'];
    }

    return null;
  }

  static getHandleStyles() {
    const lst = list;
    const num = lst ? lst.length : 0;
    const res = [];

    if (!num) {
      return res;
    }

    for (let i = 0; i < num; ++i) {
      const handleStyle = lst[i];

      if (handleStyle) {
        const id = handleStyle.getID();
        const type = handleStyle.getType();
        const typeName = type && type.getTypeName ? type.getTypeName() : null;

        const obj = {
          active: handleStyle.isActive(),
          name: id,
          category: typeName,
          componentTypes: this.getHandleComponentTypes(handleStyle)
        };

        res.push(obj);
      }
    }

    return res;
  }

  static getFromJSON(data) {
    if (!data) {
      return null;
    }
    const handleStyleData = data.style;
    const handleTypeName = data.type;
    let handleStyle = null;

    if (typeof (handleStyleData) === 'string') {
      handleStyle = this.get(handleStyleData);

    }
    if (!handleStyle) {
      // Maybe the style is accidentally set under 'type'
      handleStyle = this.get(handleTypeName);
    }
    if (!handleStyle) {
      if (typeof (handleStyleData) === 'object' && handleStyleData !== null) {
        handleStyle = this.get(handleStyleData.id);
      }
    }

    return handleStyle;
  }
  static getNumHandles() {
    return list ? list.length : 0;
  }

  static get numHandles() {
    return this.getNumHandles();
  }

  static get(name) {
    const t = typeof (name);

    if (name === null || t === 'undefined') {
      return null;
    }
    if (t === 'number') {
      return list[name];
    }
    const m = this.getMap();

    if (!m) {
      return null;
    }
    let res = m[name];

    if (!res) {
      res = m[name.toUpperCase()];
    }

    return res;
  }

  static getMap() {
    return getMap();
  }

  static get map() {
    return this.getMap();
  }
}
