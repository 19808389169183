import GraphPoint from './GraphPoint';
import Graph from './Graph';
// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
// #endif

function nearlyEquals(a, b, toll) {
  const d = a > b ? a - b : b - a;

  return d <= toll;
}

function graphPointCausesDeform(pt, index, numPoints, toll) {
  let y = 0;
  const lastIndex = numPoints - 1;

  if (pt) {
    if (numPoints > 1) {
      if (index > 0 && pt.handleLeft) {
        y = GraphPoint.getY(pt.handleLeft);
        if (!nearlyEquals(y, 0, toll)) {
          return true;
        }
      }
      if (index < lastIndex && pt.handleRight) {
        y = GraphPoint.getY(pt.handleRight);
        if (!nearlyEquals(y, 0, toll)) {
          return true;
        }
      }
    }
    y = GraphPoint.getY(pt.location);
  }
  if (y !== 0) {
    return true;
  }

  return false;
}

export default class GraphUtils {
  /**
   * @method hasGraph
   * @static
   * @description Returns true if the graph has coordinates different from 0
   * @param {Graph} graph - Graph object
   * @return {Boolean} - True if a valid graph is found
   * */
  static hasGraph(graph) {
    if (!graph) {
      return false;
    }
    const points = graph.points;

    if (!points) {
      return false;
    }
    const numPoints = points.length;

    if (numPoints === 0) {
      return false;
    }
    const zeroToll = 0.001;

    for (let i = 0; i < numPoints; ++i) {
      const pt = points[i];

      if (graphPointCausesDeform(pt, i, numPoints, zeroToll)) {
        return true;
      }
    }

    return false;
  }

  static graphToJSONObject(graph, result = null) {
    let res = result;

    if (!graph) {
      return res;
    }

    if (!res) {
      res = {};
    }
    const points = graph.points;

    if (points) {
      const nump = points.length;

      for (let i = 0; i < nump; ++i) {
        const pt = points[i];
        const loc = pt.location;

        const X = GraphPoint.getX(loc, 0);
        const Y = GraphPoint.getY(loc, 0);

        const jsonPt = {
          location: {x: X, y: Y}
        };

        if (pt.handleLeft) {
          jsonPt.handleLeft = {x: GraphPoint.getX(pt.handleLeft), y: GraphPoint.getY(pt.handleLeft)};
        }
        if (pt.handleRight) {
          jsonPt.handleRight = {x: GraphPoint.getX(pt.handleRight), y: GraphPoint.getY(pt.handleRight)};
        }
        if (!res.points) {
          res.points = [];
        }
        res.points[i] = jsonPt;
      }
    }

    return res;
  }

  static graphToJSONString(graph, params) {
    const jsonObj = GraphUtils.graphToJSONObject(graph);

    if (!jsonObj) {
      return 'null';
    }

    let indent = '';

    if (params) {
      if (params.indent) {
        indent = params.indent;
      } else if (params.format) {
        indent = '\t';
      }
    }

    return JSON.stringify(jsonObj, null, indent);
  }

  static graphFromJSONString(jsonObject) {
    return GraphUtils.graphFromJSONObject(jsonObject);
  }

  static _convertJSONToGraphPoint(jsonPoint, resPoint = null) {
    const pt = resPoint;
    let loc = pt.location;
    let hl = pt.handleLeft;
    let hr = pt.handleRight;

    if (!loc) {
      loc = pt.location = {x: 0, y: 0};
    }

    const jsonLoc = jsonPoint.location || jsonPoint.loc || jsonPoint.l || jsonPoint;
    const jsonHL = jsonPoint.handleleft || jsonPoint.handle_left || jsonPoint.handleLeft || jsonPoint.hl;
    const jsonHR = jsonPoint.handleright || jsonPoint.handle_right || jsonPoint.handleRight || jsonPoint.hr;

    loc.x = GraphPoint.getX(jsonLoc);
    loc.y = GraphPoint.getY(jsonLoc);

    if (jsonHL) {
      if (!hl) {
        hl = pt.handleLeft = {};
      }
      hl.x = GraphPoint.getX(jsonHL);
      hl.y = GraphPoint.getY(jsonHL);
    } else {
      pt.handleLeft = null;
    }

    if (jsonHR) {
      if (!hr) {
        hr = pt.handleRight = {};
      }
      hr.x = GraphPoint.getX(jsonHR);
      hr.y = GraphPoint.getY(jsonHR);
    } else {
      pt.handleRight = null;
    }
  }

  static graphFromJSONObject(jsonObject, result = null) {
    let res = result;
    let jsonObj = jsonObject;

    if (!jsonObject) {
      res.points = null;

      return res;
    }
    if (typeof (jsonObj) === 'string') {
      try {
        jsonObj = JSON.parse(jsonObject);
      } catch (ex) {
        jsonObj = null;
        // #if DEBUG
        BD3DLogger.warn(ex);
        // #endif
      }
    }
    if (!jsonObj) {
      res.points = null;

      return res;
    }

    const jsonPoints = jsonObj.points;

    if (!jsonPoints) {
      return res;
    }

    const num = jsonPoints.length;

    if (num === 0) {
      res.points = [];

      return res;
    }

    for (let i = 0; i < num; ++i) {
      const jsonPoint = jsonPoints[i];
      let pt = null;

      if (res && res.points) {
        pt = res.points[i];
      }
      if (!pt) {
        pt = new GraphPoint();
        if (!res) {
          res = new Graph();
        }
        if (!res.points) {
          res.points = [];
        }
        res.points[i] = pt;
      }
      GraphUtils._convertJSONToGraphPoint(jsonPoint, pt);

    }

    return res;
  }
}
