export default class StringUtils {
  static trim(str) {
    if (!str) {
      return str;
    }
    const l = str.length;
    const first = str.charCodeAt(0);
    const last = str.charCodeAt(l - 1);
    const LAST_WS_CODE = 32;

    if (first > LAST_WS_CODE && last > LAST_WS_CODE) {
      return str;
    }

    if (str.trim) {
      return str.trim();
    }

    return str;
  }

  static capitalize(string) {
    if (!string) {
      return string;
    }
    const l = string.length;

    if (!l) {
      return string;
    }
    const first = string.charAt(0);

    if (l < 2) {
      return first;
    }
    const last = string.substring(1, l);

    return first.toUpperCase() + last;
  }

  static replace(string, search, replace) {
    let str = string;

    if (!str) {
      return str;
    }
    if (!search) {
      return str;
    }
    let repl = replace;

    if (!repl) {
      repl = '';
    }
    if (repl === search) {
      return str;
    }
    if (str.replace) {
      if (repl.indexOf(search, 0) < 0) {
        while (str.indexOf(search, 0) >= 0) {
          str = str.replace(search, repl);
        }

        return str;
      }

      return str.replace(search, repl);
    }

    if (repl.indexOf(search, 0) < 0) {
      while (str.indexOf(search, 0) >= 0) {
        str = str.split(search).join(repl);
      }

      return str;
    }

    return str.split(search).join(repl);
  }

  static startsWith(string, value, fromIndex = 0) {
    if (!string || !value) {
      return false;
    }
    const valueLen = value.length;
    const strLen = string.length;

    if ((strLen - fromIndex) < valueLen) {
      return false;
    }

    return string.substring(fromIndex, fromIndex + valueLen) === value;
  }
}
