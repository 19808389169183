import BGraphParser from '../../bgr/common/bgraph/BGraphParser.js';
import ArrayUtils from '../../bgr/common/utils/ArrayUtils';

import * as factoryTypes from './types';
import {
  Property
} from './utils';

const OBJECT_MAP = Symbol('object_map');
const DATA_MAP = Symbol('data_map');
const SET_PROPERTY_PARAMS = Symbol('set_property_params');

function forceNull(value) {
  if (typeof (value) === 'undefined') {
    return null;
  }

  return value;
}

function isPropertyGlobal(property) {
  if (!property || !property.get) {
    return false;
  }

  return property.get('global');
}

function getPropertySelectionTarget(property) {
  return property.get('selectionTarget') ||
    property.get('selection-target') ||
    property.get('selection_target') ||
    property.get('target');
}

const propertyFilters = {
  byTarget: {
    params: {},
    filter(value) {
      const {params} = this;
      const tgt = params ? params.target : null;
      const name = params ? params.name : null;
      const type = params ? params.type : null;
      const includeGlobalProperties = params ? params.includeGlobalProperties : false;
      const isGlobalProperty = isPropertyGlobal(value);

      if (isGlobalProperty) {
        return includeGlobalProperties;
      }

      const selectionTarget = getPropertySelectionTarget(value);

      return ((!tgt || (selectionTarget === tgt)) &&
        (!name || (value.get('name') === name)) &&
        (!type || (value.get('type') === type)));
    }
  },
  global: {
    params: {},
    filter(value) {
      return isPropertyGlobal(value);
    }
  }
};

const factories = Object.assign({}, factoryTypes);

factories.ContainerNode3D = factories.ContainerNode;
factories.AssetNode3D = factories.AssetNode;
factories.CollisionBoxNode3D = factories.CollisionBoxNode;

function getObjectMap(scope, create = true) {
  let res = scope[OBJECT_MAP];

  if (res || !create) {
    return res;
  }

  res = new Map();

  scope[OBJECT_MAP] = res;

  return res;
}

function getDataMap(scope, create = true) {
  let res = scope[DATA_MAP];

  if (res || !create) {
    return res;
  }
  res = new Map();

  scope[DATA_MAP] = res;

  return res;
}

function getCachedObject(scope, data, create = true) {
  if (!data) {
    return null;
  }
  const map = getObjectMap(scope, create);

  if (!map) {
    return null;
  }

  return map.get(data);
}

function getDataByObject(scope, object) {
  if (!object) {
    return null;
  }
  const map = getDataMap(scope, false);

  if (!map) {
    return null;
  }

  return map.get(object);
}

function setCachedObject(scope, data, value) {
  if (data === value) {
    return;
  }
  const map = getObjectMap(scope, true);
  const dataMap = getDataMap(scope, true);

  if (map) {
    map.set(data, value);
  }
  if (dataMap) {
    dataMap.set(value, data);
  }
}

let instance = null;

export default class BD3DGraphParser extends BGraphParser {

  static getInstance() {
    if (instance === null) {
      instance = new BD3DGraphParser();
    }

    return instance;
  }

  static get instance() {
    return this.getInstance();
  }

  clear() {
    const prevObjects = this._previousObjectMap;

    if (prevObjects) {
      if (prevObjects.clear) {
        prevObjects.clear();
      }
      this._previousObjectMap = null;
    }

    this._parsedData = null;

    this._clearMaps();
  }

  dispose() {
    this.clear();
  }

  getFactory(id, value, key, parent, session) {
    const res = factories[id];

    if (res) {
      if (res.create) {
        return res.create;
      } else if (res.call && res.apply) {
        return res;
      }
    }

    return null;
  }

  _initSession(session) {
    super._initSession(session);
    const {accessor} = session;

    accessor.setData('parser', this);
    accessor.setData('session', session);
  }

  _onNewObject(key, object, params) {
    // console.log('new object:', object);
  }

  _onGarbageObject(key, object, params) {
    if (object && object.dispose) {
      object.dispose();
    }
    console.log('dispose of object:', object);
  }

  getDataByObject(object) {
    return getDataByObject(this, object);
  }

  getObjectByData(data) {
    return getCachedObject(this, data, false);
  }

  _onParsedInstance(value, result, session) {
    if (!result) {
      return;
    }

    if (typeof (result) === 'object') {
      if (session) {
        const params = session.getParams ? session.getParams() : session.params;

        if (params && params.onParsedInstance) {
          const sessionAccessor = session ? session.accessor || session : null;

          params.onParsedInstance(value, result, sessionAccessor);
        }
      }
    }

    return;
  }

  parseJSON(source, params = null) {
    this._parsedData = null;

    let previousObjectMap = this._previousObjectMap;

    if (previousObjectMap && previousObjectMap.clear) {
      previousObjectMap.clear();
    }
    let objectMap = getObjectMap(this, false);

    if (objectMap) {
      if (!previousObjectMap) {
        previousObjectMap = new Map();
        this._previousObjectMap = previousObjectMap;
      }
      for (const [key, value] of objectMap) {
        previousObjectMap.set(key, value);
      }
    }

    this._clearMap('idByObject');
    this._clearMap('dataById');
    this._clearMap('objectById');
    this._clearMap(DATA_MAP);

    const result = super.parseJSON(source, params);

    this._parsedData = result;

    objectMap = getObjectMap(this, false);
    if (objectMap) {
      for (const [key] of objectMap) {
        if (!previousObjectMap || !previousObjectMap.has(key)) {
          // new value
          this._onNewObject(key, objectMap.get(key), params);
        }
      }
    }
    if (previousObjectMap) {
      for (const [key] of previousObjectMap) {
        if (!objectMap || !objectMap.has(key)) {
          // garbage collect
          this._onGarbageObject(key, previousObjectMap.get(key), params);
        }
      }
      if (previousObjectMap.clear) {
        previousObjectMap.clear();
      }
    }

    return result;
  }

  _copyKeyValues(srcData, dstData, key, parent, session) {
    if (typeof (srcData) === 'object' && !srcData['@ref']) {
      for (const v in srcData) {
        if (srcData.hasOwnProperty(v)) { // eslint-disable-line
          const val = srcData[v];

          dstData[v] = this._parseValue(val, key, parent, session);
        }
      }
    }
  }

  _newMap(weak = false) {
    if (weak && typeof (WeakMap) !== 'undefined') {
      return new WeakMap();
    } else if (typeof (Map) !== 'undefined') {
      return new Map();
    }

    return null;
  }

  _clearMap(map) {
    if (typeof (map) === 'string') {
      const m = this[map];

      if (m) {
        this._clearMap(m);
        this[map] = null;
      }

      return;
    }
    if (!map || !map.clear) {
      return;
    }
    map.clear();
  }

  _clearMaps() {
    this._clearMap('idByObject');
    this._clearMap('dataById');
    this._clearMap('objectById');
    this._clearMap(DATA_MAP);
    this._clearMap(OBJECT_MAP);
  }

  _assignToMap(name, key, value) {
    if (!name || !key) {
      return;
    }
    let map = this[name];

    if (!map) {
      const weak = false; // TODO: decide whether to use a weakmap or not

      map = this._newMap(weak);
      this[name] = map;
    }
    if (map && map.set) {
      map.set(key, value);
    }
  }

  _getFromMap(map, key) {
    if (!map || !key) {
      return null;
    }
    if (typeof (map) === 'string') {
      const m = this[map];

      return this._getFromMap(m, key);
    }
    if (map.get) {
      return map.get(key);
    }

    return null;
  }

  _assignId(reslt, id, session, value) {
    super._assignId(reslt, id, session);

    if (!id) {
      return;
    }
    this._assignToMap('idByObject', reslt, id);
    this._assignToMap('idByObject', value, id);
    this._assignToMap('dataById', id, value);
    this._assignToMap('objectById', id, reslt);
  }

  getIdOfObject(object) {
    return this._getFromMap('idByObject', object);
  }

  getDataById(id) {
    return this._getFromMap('dataById', id);
  }

  getObjectById(id) {
    return this._getFromMap('objectById', id);
  }

  _parseValue(value, key, parent, session) {
    if (value !== null && typeof (value) === 'object') {
      const cached = getCachedObject(this, value, true);

      if (cached) {
        const type = value['@type'];
        const id = value['@id'];
        const dataValue = value['@data'];
        let data = dataValue;
        let hasData = false;

        if (data !== null && typeof (dataValue) !== 'undefined') {
          data = this._parseValue(dataValue, key, parent, session);
          hasData = true;
        }

        setCachedObject(this, cached, value); // assign again to make getDataByObject() working again
        this._assignId(cached, id, session, value);

        if (type) {
          const factory = factories[type];

          if (factory && factory.update) {
            const sessionAccessor = session ? session.accessor : null;

            factory.update(cached, data, id, sessionAccessor, value);
          }
        } else if ((value instanceof Array) || (Array.isArray && Array.isArray(value))) {
          const l = value.length;

          for (let i = 0; i < l; ++i) {
            cached[i] = this._parseValue(value[i], key, parent, session);
          }
        } else if (typeof (value) === 'object' && !value['@ref']) {
          let srcData = value;

          if (hasData) {
            srcData = dataValue;
          }
          this._copyKeyValues(srcData, cached, key, parent, session);
        }

        return cached;
      }
      const res = super._parseValue(value, key, parent, session);

      setCachedObject(this, value, res);

      return res;
    }

    return super._parseValue(value, key, parent, session);
  }

  _getGetPropertyParams() {
    let res = this[SET_PROPERTY_PARAMS];

    if (res) {
      return res;
    }
    res = {};

    this[SET_PROPERTY_PARAMS] = res;

    return res;
  }

  _getSetPropertyParams() {
    let res = this[SET_PROPERTY_PARAMS];

    if (res) {
      return res;
    }
    res = {};

    this[SET_PROPERTY_PARAMS] = res;

    return res;
  }

  getPropertyValue(property, params = null) {
    if (!property) {
      return null;
    }
    let isPropertyInstance = false;

    let prop = property;

    if (typeof (prop) === 'string') {
      prop = this.getObjectById(prop);
      isPropertyInstance = prop instanceof Property;

      if (!isPropertyInstance) {
        prop = this.getDataById(prop);
      }
    }
    isPropertyInstance = prop instanceof Property;

    if (!isPropertyInstance) {
      prop = this.getObjectByData(property);

      isPropertyInstance = prop instanceof Property;

      if (!prop) {
        prop = property;
      }
    }

    const target = isPropertyInstance ? prop.getTarget() : Property.getTarget(prop);
    const data = this.getDataById(target);

    if (!data) {
      return null;
    }
    const type = data['@type'];

    if (type) {
      const factory = factories[type];

      if (factory && factory.getProperty) {
        const object = this.getObjectById(target);

        const getPropertyParams = this._getGetPropertyParams();

        getPropertyParams.parser = this;
        getPropertyParams.params = params;
        getPropertyParams.factories = factories;

        const result = forceNull(factory.getProperty(object, data, prop, getPropertyParams));

        getPropertyParams.parser = null;
        getPropertyParams.params = null;
        getPropertyParams.factories = null;

        return result;
      }
    }
    const name = isPropertyInstance ? prop.getKey() : Property.getKey(prop);

    if (data['@data']) {
      return forceNull(data['@data'][name]);
    }

    return forceNull(data[name]);
  }

  setPropertyValue(property, value, params) {
    if (!property) {
      return;
    }
    let prop = property;

    let isPropertyInstance = false;

    if (typeof (prop) === 'string') {
      prop = this.getObjectById(prop);
      isPropertyInstance = prop instanceof Property;

      if (!isPropertyInstance) {
        prop = this.getDataById(prop);
      }
    }

    isPropertyInstance = property instanceof Property;

    if (!isPropertyInstance) {
      prop = this.getObjectByData(property);
      isPropertyInstance = prop instanceof Property;

      if (!prop) {
        prop = property;
      }
    }

    const target = isPropertyInstance ? prop.getTarget() : Property.getTarget(prop);
    const data = this.getDataById(target);

    if (!data) {
      return;
    }
    const type = data['@type'];

    if (type) {
      const factory = factories[type];

      if (factory && factory.setProperty) {
        const object = this.getObjectById(target);

        const setPropertyParams = this._getSetPropertyParams();

        setPropertyParams.parser = this;
        setPropertyParams.params = params;
        setPropertyParams.factories = factories;

        factory.setProperty(object, data, property, value, setPropertyParams);

        setPropertyParams.parser = null;
        setPropertyParams.params = null;
        setPropertyParams.factories = null;

        return;
      }
    }
    const name = isPropertyInstance ? prop.getKey() : Property.getKey(prop);

    if (data['@data']) {
      data['@data'][name] = value;
    }

    data[name] = value;
  }

  getGlobalProperties(resultArray = null) {
    const propertyFilter = propertyFilters.global;
    const parsed = this._parsedData;
    const properties = parsed ? parsed.properties : null;

    return ArrayUtils.filter(properties, propertyFilter, null, resultArray);
  }

  getPropertiesByTarget(target, params = null, resultArray = null) {
    if (!target) {
      return null;
    }
    let id = target;

    if (typeof (target) !== 'string') {
      id = this.getIdOfObject(target);
    }

    if (!id) {
      return null;
    }

    let includeGlobalProperties = false;

    if (params) {
      includeGlobalProperties = params.includeGlobalProperties;
    }

    const propertyFilter = propertyFilters.byTarget;

    const parsed = this._parsedData;
    const properties = parsed ? parsed.properties : null;
    const propertyFilterParams = propertyFilter.params;

    propertyFilterParams.target = id;
    propertyFilterParams.name = null;
    propertyFilterParams.type = null;
    propertyFilterParams.includeGlobalProperties = includeGlobalProperties;

    const res = ArrayUtils.filter(properties, propertyFilter, null, resultArray);

    propertyFilterParams.target = null;
    propertyFilterParams.name = null;
    propertyFilterParams.type = null;
    propertyFilterParams.includeGlobalProperties = false;

    return res;
  }

  callMethod(method, params, options = null) {
    return this.callMethodOn(null, method, params, options);
  }

  callMethodOn(target, method, params, options = null) {
    let tgt = target;

    if (typeof (tgt) === 'string') {
      tgt = this.getDataById(tgt);
    }

    if (!tgt) {
      tgt = this.getDataByObject(this._parsedData);
    }

    return this._callMethodOn(tgt, method, params, options);
  }

  _callMethodOn(target, method, params, options = null) {
    const tgt = target;

    if (typeof (tgt) !== 'object') {
      return null;
    }

    const data = tgt;

    if (!data) {
      return null;
    }
    const type = data['@type'];

    if (type) {
      const factory = factories[type];

      if (factory && factory.callMethod) {
        const object = this.getObjectByData(target);

        const setPropertyParams = this._getSetPropertyParams();

        setPropertyParams.parser = this;
        setPropertyParams.params = options;
        setPropertyParams.factories = factories;

        const result = factory.callMethod(object, data, method, params, setPropertyParams);

        setPropertyParams.parser = null;
        setPropertyParams.params = null;
        setPropertyParams.factories = null;

        return result;
      }
    } else if ((data instanceof Array) || (Array.isArray && Array.isArray(data))) {
      const num = data.length;
      let res = null;

      for (let i = 0; i < num; ++i) {
        const itemRes = this._callMethodOn(data[i], method, params, options);

        if (itemRes) {
          res = itemRes;
        }
      }

      return res;
    } else {
      let res = null;

      for (const v in data) {
        if (data.hasOwnProperty(v)) {
          const itemRes = this._callMethodOn(data[v], method, params, options);

          if (itemRes) {
            res = itemRes;
          }
        }
      }

      return res;
    }

    return null;
  }
}
