/* eslint-disable max-depth */
import BD3DSampleFabricMaterial from '../material/BD3DSampleFabricMaterial.js';
import Matrix4Math from '../../bgr/bgr3d/math/Matrix4Math.js';
import GeometryNode3D from '../../bgr/bgr3d/scenegraph/GeometryNode3D.js';
import VecMat4Math from '../../bgr/bgr3d/math/VecMat4Math.js';
import ContainerNode3D from '../../bgr/bgr3d/scenegraph/ContainerNode3D.js';
import BD3DFabricMaterial from '../material/BD3DFabricMaterial.js';
import BD3DMaterial from '../material/BD3DMaterial.js';
import Colors from '../colors/Colors.js';

function findChildName(parentNode3D, childNode3D) {
  if (!parentNode3D || !childNode3D) {
    return null;
  }
  const {userData} = parentNode3D;

  if (!userData) {
    return null;
  }
  const {childMap} = userData;

  if (!childMap) {
    return null;
  }
  for (const v in childMap) {
    if (userData.hasOwnProperty(v)) {
      const n = childMap[v];

      if (n === childNode3D) {
        return v;
      }
    }
  }

  return null;
}

function newMap() {
  return new Map();
}

function addToArray(sourceArr, appendArr) {
  if (!sourceArr || !appendArr) {
    return sourceArr;
  }
  const l = appendArr.length;

  for (let i = 0; i < l; ++i) {
    sourceArr.push(appendArr[i]);
  }

  return sourceArr;
}

function findImage(object) {
  if (object && object.getImage) {
    return findImage(object.getImage());
  }
  if (object instanceof Image) {
    return object;
  }

  return null;
}

function traverseOBJ(node3D, session, parentMatrix) {
  if (!node3D) {
    return;
  }
  const options = session && session.options;
  const saveMaterials = options ? options.materials !== false : true;
  const nodeIndex = session.nodeIndex || 0;

  session.nodeIndex = nodeIndex + 1;

  const sess = session;
  const {currentName} = sess;
  const {transform} = node3D;
  let worldMatrix = null;
  let nodeName = currentName;
  const {userData} = node3D;

  if (userData && userData.name) {
    nodeName = userData.name;
  }
  nodeName = nodeName || `object_${nodeIndex}`;
  const localMatrix = transform && transform.getMatrix4 && transform.getMatrix4();

  if (localMatrix || parentMatrix) {
    if (localMatrix && parentMatrix) {
      worldMatrix = Matrix4Math.multiply(parentMatrix, localMatrix, worldMatrix);
    } else if (localMatrix) {
      worldMatrix = localMatrix;
    } else if (parentMatrix) {
      worldMatrix = parentMatrix;
    }
  }

  if (node3D instanceof GeometryNode3D) {
    const geom = node3D.getGeometry();
    const wm = worldMatrix && worldMatrix.getElements ? worldMatrix.getElements() : worldMatrix;
    const mtl = node3D.getMaterial ? node3D.getMaterial() : null;
    let mtlName = null;
    let uvMatrix = null;

    if (mtl && saveMaterials) {
      const mtlMap = session.mtlMap || newMap();
      let sampleTransform = null;

      if (mtl.getSampleTransform) {
        sampleTransform = mtl.getSampleTransform();
      } else if (mtl.get) {
        sampleTransform = mtl.get('sampleTransform');
      }
      const sampleMatrix = (sampleTransform && sampleTransform.applyMatrix4) ? [
        1, 0, 0, 0,
        0, 1, 0, 0,
        0, 0, 1, 0,
        0, 0, 0, 1
      ] : null;

      if (sampleMatrix) {
        sampleTransform.applyMatrix4(sampleMatrix);
      }
      uvMatrix = sampleMatrix;


      session.mtlMap = mtlMap;
      mtlName = mtlMap.get(mtl);
      if (!mtlName) {
        mtlName = `${nodeName}_mtl`;
        mtlMap.set(mtl, mtlName);

        const mtlInfo = session.mtlInfo || {
          name: session.name,
          map: null,
          list: null
        };

        session.mtlInfo = mtlInfo;
        const mtlInfoMap = mtlInfo.map || {};
        const mtlInfoArr = mtlInfo.list || [];

        mtlInfo.map = mtlInfoMap;
        mtlInfo.list = mtlInfoArr;

        if (!mtlInfoMap[mtlName]) {
          const info = {
            name: mtlName,
            material: mtl
          };

          mtlInfoMap[mtlName] = info;
          mtlInfoArr.push(info);
        }
      }
    }

    const {
      polygons
    } = geom;
    const numPolygons = polygons.length;

    if (numPolygons > 0) {
      const vertMap = sess.vertMap || newMap();
      const uvMap = sess.uvMap || newMap();
      const normalMap = sess.normalMap || newMap();
      const lines = sess.lines || [];
      let globalVertIndex = sess.globalVertIndex || 0;
      let globalUVIndex = sess.globalUVIndex || 0;
      let globalNormalIndex = sess.globalNormalIndex || 0;

      // sess.vertMap = vertMap;
      // sess.uvMap = uvMap;
      // sess.normalMap = normalMap;
      sess.lines = lines;

      lines.push(`o ${nodeName}`);
      if (mtlName && saveMaterials) {
        lines.push(`usemtl ${mtlName}`);
      }

      let objVerts = null;
      let objUvs = null;
      let objNormals = null;
      let faceLines = null;

      for (let i = 0; i < numPolygons; ++i) {
        faceLines = faceLines || [];
        const poly = polygons[i];
        const verts = poly.vertices;
        const numVerts = verts.length;
        const faceVertItems = [];

        for (let j = 0; j < numVerts; ++j) {
          const vert = verts[j];
          const pos = vert.position;
          const atts = vert.attributes;
          const normal = atts && atts.normal;
          const uv = atts && atts.uv;
          let vertIndex = 0;
          let uvIndex = -1;
          let normalIndex = -1;

          if (vertMap.has(pos)) {
            vertIndex = vertMap.get(pos);
          } else {
            objVerts = objVerts || [];
            let x = pos.getCoord(0);
            let y = pos.getCoord(1);
            let z = pos.getCoord(2);

            if (wm) {
              const tx = VecMat4Math.transformVectorX(x, y, z, 1, wm);
              const ty = VecMat4Math.transformVectorY(x, y, z, 1, wm);
              const tz = VecMat4Math.transformVectorZ(x, y, z, 1, wm);

              x = tx;
              y = ty;
              z = tz;
            }
            objVerts.push(`v ${x} ${y} ${z}`);
            vertIndex = globalVertIndex;
            ++globalVertIndex;
            vertMap.set(pos, vertIndex);
          }
          if (uv) {
            objUvs = objUvs || [];
            if (uvMap.has(uv)) {
              uvIndex = uvMap.get(uv);
            } else {
              let u = uv.getCoord(0);
              let v = uv.getCoord(1);
              const m = uvMatrix;

              if (m) {
                const tu = VecMat4Math.transformVectorX(u, v, 0, 1, m);
                const tv = VecMat4Math.transformVectorY(u, v, 0, 1, m);

                u = tu;
                v = tv;
              }

              objUvs.push(`vt ${u} ${1 - v}`);
              uvIndex = globalUVIndex;
              ++globalUVIndex;
              uvMap.set(uv, uvIndex);
            }
          }
          if (normal) {
            objNormals = objNormals || [];
            if (normalMap.has(normal)) {
              normalIndex = normalMap.get(normal);
            } else {
              normalIndex = globalNormalIndex;
              let x = normal.getCoord(0);
              let y = normal.getCoord(1);
              let z = normal.getCoord(2);

              if (wm) {
                const tx = VecMat4Math.transformVectorX(x, y, z, 0, wm);
                const ty = VecMat4Math.transformVectorY(x, y, z, 0, wm);
                const tz = VecMat4Math.transformVectorZ(x, y, z, 0, wm);

                x = tx;
                y = ty;
                z = tz;
              }
              objNormals.push(`vn ${x} ${y} ${z}`);

              ++globalNormalIndex;
              normalMap.set(normal, normalIndex);
            }
          }
          let faceVertexParam = vertIndex + 1;

          if (uvIndex >= 0 && normalIndex >= 0) {
            faceVertexParam = `${vertIndex + 1}/${uvIndex + 1}/${normalIndex + 1}`;
          } else if (uvIndex >= 0) {
            faceVertexParam = `${vertIndex + 1}/${uvIndex + 1}`;
          } else if (normalIndex >= 0) {
            faceVertexParam = `${vertIndex + 1}//${normalIndex + 1}`;
          }
          faceVertItems.push(faceVertexParam);
        }
        faceLines.push(`f ${faceVertItems.join(' ')}`);
      }
      addToArray(lines, objVerts);
      addToArray(lines, objUvs);
      addToArray(lines, objNormals);
      addToArray(lines, faceLines);
      sess.globalVertIndex = globalVertIndex;
      sess.globalUVIndex = globalUVIndex;
      sess.globalNormalIndex = globalNormalIndex;
    }
  }

  if (node3D instanceof ContainerNode3D) {
    const children = node3D.getChildren();
    const numChildren = children ? children.length : 0;

    for (let i = 0; i < numChildren; ++i) {
      const child = children[i];
      const name = findChildName(node3D, child);

      if (name) {
        sess.currentName = name;
      } else {
        sess.currentName = nodeName ? `${nodeName}_${i}` : `unnamed_object_${sess.nodeIndex + 1}`;
      }
      traverseOBJ(children[i], session, worldMatrix);
    }
  }
  sess.currentName = currentName;

  // BD3D.Matrix4Math.multiply();
}

const DEFAULT_COLOR = 0xFFFFFF;

function parseColor(color, objectType, fallback = DEFAULT_COLOR) {
  if (typeof (color) === 'string') {
    if (!color) {
      return fallback;
    }
    if (color.charAt(0) === '#') {
      return color;
    }
    const typeName = objectType && (
      ((typeof (objectType) === 'object') && objectType.getName) ?
        objectType.getName() : objectType
    );

    return parseColor(Colors.getColorByTypeAndId(typeName, color));
  }

  return color;
}

function parseColorAsInt(color, objectType, fallback = DEFAULT_COLOR) {
  const col = parseColor(color, objectType, fallback);
  const type = typeof (col);

  if (type === 'string') {
    let str = col;

    if (str.charAt(0) === '#') {
      str = str.substring(1, str.length);
    }

    return parseInt(str, 16);
  } else if (col && type === 'object') {
    return parseColorAsInt(col.color3d || col.color);
  }

  return col;
}

function addExt(filename, extension) {
  if (!filename || !extension) {
    return filename;
  }
  const ext = extension.charAt(0) === '.' ? extension : `.${extension}`;

  const extLen = ext.length;
  const fnLen = filename.length;

  if (filename.substring(fnLen - extLen, fnLen).toLowerCase() === ext) {
    return filename;
  }

  return filename + ext;
}

function generateOBJ(name, node3D, options) {
  const session = {
    currentName: null,
    lines: [
      '# Created by BD3D'
    ],
    globalVertIndex: 0,
    globalUVIndex: 0,
    globalNormalIndex: 0,
    vertMap: null,
    uvMap: null,
    normalMap: null,

    nodeIndex: null,
    mtlMap: null,
    mtlInfo: null,
    options,
    name
  };

  traverseOBJ(node3D, session, null);
  const materialInfo = session.mtlInfo;

  const materialList = materialInfo && materialInfo.list;
  const numMaterials = materialList ? materialList.length : 0;
  const mtllines = [];
  const textures = [];

  if (numMaterials > 0) {
    session.lines.splice(1, 0, `mtllib ${addExt(materialInfo.name, '.mtl')}`);
  }
  const objContent = session.lines.join('\n');

  const texNameMap = new Map();

  for (let i = 0; i < numMaterials; ++i) {
    const mtlobj = materialList[i];
    const mtl = mtlobj && mtlobj.material;
    const mtlname = mtlobj && mtlobj.name;

    mtllines.push(`newmtl ${mtlname}`);
    let hasMaterial = false;
    let diffuseTextureImage = null;
    let specularTextureImage = null;
    const diffuseColor = 0xFFFFFF; // Is this ever used?

    let diffuseColorMultiplier = 0xFFFFFF;

    if (mtl instanceof BD3DMaterial) {
      hasMaterial = true;
      const type = mtl.getType();
      const settings = mtl.getSettings();

      if (settings) {
        const {colorType} = settings;

        diffuseTextureImage = findImage(settings.diffuse) || findImage(settings.lightmap);
        specularTextureImage = findImage(settings.specularMask);

        diffuseColorMultiplier = parseColorAsInt(
          settings.colorMultiplier,
          colorType || type,
          diffuseColorMultiplier
        );
      }

      // border3d
      // plastic
      // const typename = type && type.getName();

      // if (typename === 'border3d') {

      // }
    }

    if (mtl instanceof BD3DFabricMaterial) {
      const diffuseColorType = mtl.getColorType();

      hasMaterial = true;
      diffuseColorMultiplier = parseColorAsInt(mtl.getColorMultiplier(), diffuseColorType, diffuseColorMultiplier);
    }

    if (mtl instanceof BD3DSampleFabricMaterial) {
      hasMaterial = true;
      diffuseTextureImage = findImage(mtl.getSampleTexture());
      // const normalmap = findImage(mtl.getSampleNormalMap());
      specularTextureImage = findImage(mtl.getSampleSpecularMap());
    }
    if (hasMaterial) {
      const shiftR = 16;
      const shiftG = 8;
      const shiftB = 0;
      const mask = 0xFF;
      const diffuseRm = ((diffuseColorMultiplier >> shiftR) & mask) / mask;
      const diffuseGm = ((diffuseColorMultiplier >> shiftG) & mask) / mask;
      const diffuseBm = ((diffuseColorMultiplier >> shiftB) & mask) / mask;
      const diffuseR = (((diffuseColor >> shiftR) & mask) / mask) * diffuseRm;
      const diffuseG = (((diffuseColor >> shiftG) & mask) / mask) * diffuseGm;
      const diffuseB = (((diffuseColor >> shiftB) & mask) / mask) * diffuseBm;

      // mtllines.push(`Ka ${diffuseR} ${diffuseG} ${diffuseB}`);
      mtllines.push('Ka 0.000 0.000 0.000');
      mtllines.push(`Kd ${diffuseR} ${diffuseG} ${diffuseB}`);
      if (specularTextureImage) {
        mtllines.push('Ks 1.000 1.000 1.000');
      } else {
        mtllines.push('Ks 0.000 0.000 0.000');
      }
      mtllines.push('d 1.0');

      // 0		Color on and Ambient off
      // 1		Color on and Ambient on
      // 2		Highlight on
      // 3		Reflection on and Ray trace on
      // 4		Transparency: Glass on
      //     Reflection: Ray trace on
      // 5		Reflection: Fresnel on and Ray trace on
      // 6		Transparency: Refraction on
      //     Reflection: Fresnel off and Ray trace on
      // 7		Transparency: Refraction on
      //     Reflection: Fresnel on and Ray trace on
      // 8		Reflection on and Ray trace off
      // 9		Transparency: Glass on
      //     Reflection: Ray trace off
      // 10		Casts shadows onto invisible surfaces
      // mtllines.push('illum 2'); // highlight on
      mtllines.push('illum 0'); // Color on and Ambient off

      if (diffuseTextureImage) {
        let texFileName = texNameMap.get(diffuseTextureImage);

        if (!texFileName) {
          texFileName = `${mtlname}_diffuse.jpg`;
          texNameMap.set(diffuseTextureImage, texFileName);
          textures.push({
            name: texFileName,
            format: 'image/jpeg',
            image: diffuseTextureImage
          });
        }

        mtllines.push(`map_Kd ${texFileName}`);
      }
      if (specularTextureImage) {
        let texFileName = texNameMap.get(specularTextureImage);

        if (!texFileName) {
          texFileName = `${mtlname}_specular.jpg`;
          texNameMap.set(specularTextureImage, texFileName);
          textures.push({
            name: texFileName,
            format: 'image/jpeg',
            image: specularTextureImage
          });
        }

        mtllines.push(`map_Ks ${texFileName}`);
      }
    }
  }
  materialInfo.content = mtllines.join('\n');
  materialInfo.textures = textures;
  console.log('materialinfo', materialInfo);

  return {
    obj: {
      name,
      content: objContent
    },
    mtl: materialInfo
  };
}

export default class OBJExporter {
  exportMattress(node3D, options, name) {
    const n = name || (options && options.name);

    return generateOBJ(n, node3D, options);
  }
  exportMattressZip(node3D, options, name) {
    if (typeof (JSZip) === 'undefined') {
      // #if DEBUG
      console.warn('JSZip not included');
      // #endif

      return null;
    }
    const callback = options && options.callback;
    const objOutput = this.exportMattress(node3D, null, name);

    if (objOutput) {
      const {obj, mtl} = objOutput;
      const objcontent = obj && obj.content;
      const mtlcontent = mtl && mtl.content;
      const objname = obj && obj.name;
      const mtlname = mtl && mtl.name;
      const mtltextures = mtl && mtl.textures;

      if ((objcontent && objname) || (mtlname && mtlcontent)) {
        const Zip = window.JSZip;
        const zip = new Zip();

        if (objcontent && objname) {
          const objfilename = `${objname}.obj`;

          zip.file(objfilename, objcontent);
        }
        if (mtlcontent && mtlname) {
          const mtlfilename = `${mtlname}.mtl`;

          zip.file(mtlfilename, mtlcontent);
        }
        if (mtltextures) {
          const numTextures = mtltextures.length;

          for (let i = 0; i < numTextures; ++i) {
            const tex = mtltextures[i];

            if (tex) {
              const {
                format,
                image,
                name: texname
              } = tex;

              if (image) {
                const canvas = document.createElement('canvas');

                canvas.width = image.naturalWidth || image.width;
                canvas.height = image.naturalHeight || image.height;
                const context = canvas.getContext('2d');

                context.drawImage(image, 0, 0);
                let base64 = canvas.toDataURL(format || 'image/jpeg');

                if (base64) {
                  const search = 'base64,';
                  const index = base64.indexOf(search);

                  if (index >= 0) {
                    base64 = base64.substring(index + search.length, base64.length);
                  }
                  const filename = texname;

                  zip.file(filename, base64, {base64: true});
                }
              }
            }
          }
        }
        // *
        zip.generateAsync({type: 'blob'})
        .then(content => {
          if (callback) {
            const zipfilename = `${name}.zip`;

            return callback(content, zipfilename);
          }

          return null;
        });
        // */
      }
    }

    return null;
  }
}
