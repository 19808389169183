export default class BD3DNodeType {
  constructor(name, parent = null, userData = null) {
    this.name = name;
    this.parent = parent;
    this.userData = null;
  }

  static isType(node, other) {
    if (!node || !other) {
      return false;
    }
    if (node === other) {
      return true;
    }
    const p = node.parent;

    if (p && p instanceof BD3DNodeType) {
      return p.isType(p, other);
    }

    return false;
  }

  isType(other) {
    return BD3DNodeType.isType(this, other);
  }
}
