export default class BackgroundService {
  _asString(id) {
    if (typeof (id) === 'string') {
      return id;
    }

    return `${id}`;
  }
  addBackground(bg, id) {
    if (!bg) {
      return;
    }
    let map = this._backgroundMap;

    if (!map) {
      map = this._backgroundMap = {};
    }

    const bgId = this._asString(bg.id);

    if (bgId) {
      map[bgId] = bg;
    }
    const bgName = this._asString(bg.name);

    if (bgName) {
      map[bgName] = bg;
    }
    const ID = this._asString(id);

    if (ID) {
      map[ID] = bg;
    }
  }

  removeBackground(background) {
    let bg = background;
    let t = typeof (bg);
    const map = this._backgroundMap;

    if (!map) {
      return;
    }

    if (t === 'object') {
      for (const v in map) {
        if (map.hasOwnProperty(v)) {
          const b = map[v];

          if (b === bg) {
            Reflect.deleteProperty(map, v);
          }
        }
      }
      const id = this._asString(bg.id);
      const name = this._asString(bg.name);

      if (id) {
        Reflect.deleteProperty(map, id);
      }
      if (name) {
        Reflect.deleteProperty(map, name);
      }
    }

    if (t === 'number') {
      bg = this._asString(bg);
      t = typeof (bg);
    }

    if (t === 'string') {
      Reflect.deleteProperty(map, bg);
    }
  }

  removeBackgrounds() {
    if (this._backgroundMap) {
      this._backgroundMap = null;
    }
  }
  addBackgrounds(backgrounds) {
    if (!backgrounds) {
      return;
    }
    if ((backgrounds instanceof Array) || (Array.isArray && Array.isArray(backgrounds))) {
      const l = backgrounds.length;

      for (let i = 0; i < l; ++i) {
        this.addBackground(backgrounds[i]);
      }
    } else if (typeof (backgrounds) === 'object') {
      for (const v in backgrounds) {
        if (backgrounds.hasOwnProperty(v)) {
          this.addBackground(backgrounds[v], v);
        }
      }
    }
  }

  _getCachedBackgroundById(id) {
    const map = this._backgroundMap;

    if (!map) {
      return null;
    }

    const ID = this._asString(id);

    if (!ID) {
      return null;
    }

    return map[ID];
  }

  getBackgroundById(id, callback) {
    const res = this._getCachedBackgroundById(id);

    if (callback) {
      callback(res);

      return res;
    }

    return res;
    // Maybe provide loading a background from a backend service directly if needed
  }
}
