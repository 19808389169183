import Dispatcher from './Dispatcher';
// import Utils from '../utils/Utils';

const UNDEFINED = 'undefined';
const EVENT_ARG_INDEX = 0;

/**
 * @class EventDispatcher
 */
export default class EventDispatcher {
  static setEventListener(target, type, listener, enabled = true) {
    if (!target || !type || !listener) {
      return false;
    }
    if (enabled && !target.addEventListener) {
      return false;
    }
    if (!enabled && !target.removeEventListener) {
      return false;
    }

    if (enabled) {
      return target.addEventListener(type, listener);
    }

    return target.removeEventListener(type, listener);
  }
  /**
   * Adds an event listener
   * @param {String} type - Event type
   * @param {function} listener - Event listener function
   * @return {void}
   */
  addEventListener(type, listener) {
    if (typeof (type) === UNDEFINED || type === null) {
      return false;
    }
    if (typeof (listener) === UNDEFINED || listener === null) {
      return false;
    }
    const dispatcher = this._getDispatcher(type, true);

    return dispatcher.addListener(listener);
  }

  /**
   * Adds an event listener to be called once
   * @param  {[type]} type     [description]
   * @param  {[type]} listener [description]
   * @return {[type]}          [description]
   */
  once(type, listener) {
    if (typeof (type) === UNDEFINED || type === null) {
      return false;
    }
    if (typeof (listener) === UNDEFINED || type === null) {
      return false;
    }
    const dispatcher = this._getDispatcher(type, true);

    return dispatcher.once(listener);
  }

  /**
   * Removes an event listener
   * @param  {String} type - Event type
   * @param  {Function} listener - Event listener function
   * @return {void}
   */
  removeEventListener(type, listener) {
    if (typeof (type) === UNDEFINED || type === null) {
      return false;
    }
    if (typeof (listener) === UNDEFINED || listener === null) {
      return false;
    }
    const dispatcher = this._getDispatcher(type, false);

    if (dispatcher === null || typeof (dispatcher) === UNDEFINED) {
      return false;
    }

    return dispatcher.removeListener(listener);
  }

  /**
   * Removes all event listeners of a given event type. Or all event types at once.
   * @param  {String} type = null - Event type
   * @return {void}
   */
  removeEventListeners(type = null) {

    if (typeof (type) === UNDEFINED || type === null) {
      // remove ALL event listeners
      const map = this._getDispatcherMap(false);

      if (map === null || typeof (map) === UNDEFINED) {
        return;
      }
      for (const v in map) {
        if (map.hasOwnProperty(v)) {
          const dispatcher = map[v];

          if (typeof (dispatcher) !== UNDEFINED && dispatcher !== null) {
            dispatcher.removeListeners();
          }
        }
      }
    } else {
      const dispatcher = this._getDispatcher(type, false);

      if (typeof (dispatcher) === UNDEFINED || dispatcher === null) {
        return;
      }
      dispatcher.removeListeners();
    }
  }

  /**
   * Returns true if the event dispatcher has listeners
   * hasEventListener() returns true if the dispatcher has any event listener
   * hasEventListener(type) returns true if the dispatcher has any event listener of a given type
   * hasEventListener(type, listener) returns true if the dispatcher contains a given event listener of a given type
   * @param  {String}  type = null - Event type
   * @param  {Function} listener = null - Event listener function
   * @return {Boolean} - Whether the event dispatcher contains an event listener
   */
  hasEventListener(type = null, listener = null) {
    let t = type;
    let l = listener;

    if (typeof t === 'function' || (typeof t.apply !== 'undefined' && typeof t.call !== 'undefined')) {
      l = t;
      t = null;
    }

    if (t === null || typeof (t) === UNDEFINED) {
      const map = this._getDispatcherMap(false);

      if (typeof (map) === UNDEFINED || map === null) {
        return false;
      }
      for (const v in map) {
        if (map.hasOwnProperty(v)) {
          const disp = map[v];

          if ((l === null || typeof (l) === 'undefined') && this._dispatcherHasListeners(disp)) {
            return true;
          } else if (this._dispatcherHasListener(disp, l)) {
            return true;
          }
        }
      }
    } else {
      const disp = this._getDispatcher(t, false);

      if (l === null || typeof (l) === 'undefined') {
        return this._dispatcherHasListeners(disp);
      }

      return this._dispatcherHasListener(disp, l);
    }

    return false;
  }

  /**
   * Dispatches an event
   * @param  {...Object} args - Arguments
   * @return {boolean} - Returns true if the event has been dispatched successfully
   */
  dispatchEvent(...args) {
    const event = args[EVENT_ARG_INDEX];
    const t = typeof (event);

    if (t === UNDEFINED || event === null) {
      return false;
    }
    let eventType = null;

    if (t === 'string') {
      eventType = event;
    } else {
      eventType = event.type;
    }

    if (typeof (eventType) === UNDEFINED || eventType === null) {
      return false;
    }

    const disp = this._getDispatcher(eventType, false);

    if (typeof (disp) === UNDEFINED || disp === null) {
      return false;
    }

    return disp.dispatch(...args);
    // return Utils.applyFunc(disp.dispatch, disp, args);
  }

  /**
   * Returns true if a given dispatcher contains a listener
   * @private
   * @param  {Dispatcher} dispatcher - Dispatcher instance
   * @param  {Function} listener - Listener function
   * @return {boolean} - true if the dispatcher contains a given event listener
   */
  _dispatcherHasListener(dispatcher, listener) {
    if (dispatcher === null || typeof (dispatcher) === UNDEFINED) {
      return false;
    }

    return dispatcher.hasListener(listener);
  }

  /**
   * Returns true if a given dispatcher contains any listeners
   * @private
   * @param  {Dispatcher} dispatcher - Dispatcher instance
   * @return {boolean} - true if the dispatcher contains listeners
   */
  _dispatcherHasListeners(dispatcher) {
    if (dispatcher === null || typeof (dispatcher) === UNDEFINED) {
      return false;
    }

    return dispatcher.hasListeners();
  }

  /**
   * Returns a dispatcher associated with an event name
   * @private
   * @param  {String} type - Event type
   * @param  {boolean} create - Creates the dispatcher if non existing
   * @return {Dispatcher} Dispatcher associated with the eventName
   */
  _getDispatcher(type, create = true) {
    if (type === null || typeof (type) === UNDEFINED) {
      return null;
    }
    const dispatcherMap = this._getDispatcherMap(create);

    if (dispatcherMap === null || typeof (dispatcherMap) === UNDEFINED) {
      return null;
    }
    let res = null;

    res = dispatcherMap[type];
    if (!create) {
      return res;
    }
    if (res === null || typeof (res) === UNDEFINED) {
      res = new Dispatcher();
      dispatcherMap[type] = res;
    }

    return res;
  }

  /**
   * Returns the map of event dispatchers
   * @param  {boolean} create = true - Creates the event dispatcher map if non existing
   * @return {Object} - the map/dictionary containing Dispatchers per event type
   */
  _getDispatcherMap(create = true) {
    let map = this._dispatchers;

    if (!create) {
      return map;
    }
    if (map === null || typeof (map) === UNDEFINED) {
      map = {};
      this._dispatchers = map;
    }

    return map;
  }
}
