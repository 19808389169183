import * as THREE from 'three';

let phdInstance = null;

export default class PointerHitDetection {
  static getInstance() {
    if (!phdInstance) {
      phdInstance = new PointerHitDetection();
    }

    return phdInstance;
  }

  get instance() {
    return this.getInstance();
  }

  static getObjectsUnderAbsLocFromRenderer(x, y, container = null, camera = null, renderer = null) {
    return this.getInstance().getObjectsUnderAbsLocFromRenderer(x, y, container, camera, renderer);
  }

  static getObjectsUnderAbsLocFromCanvas(x, y, container, camera, canvas, pixelScale) {
    return this.getInstance().getObjectsUnderAbsLocFromCanvas(x, y, container, camera, canvas, pixelScale);
  }

  static getObjectsUnderAbsLoc(x, y, screenWidth, screenHeight, container = null, camera = null) {
    return this.getInstance().getObjectsUnderAbsLoc(x, y, screenWidth, screenHeight, container, camera);
  }

  static getObjectsUnderNdcLoc(x, y, container = null, camera = null) {
    return this.getInstance().getObjectsUnderAbsLoc(x, y, container, camera);
  }

  /**
   * @method getObjectsUnderAbsLocFromRenderer
   * @description Returns the objects under the absolute hit location using a THREE.WebGLRenderer instance
   * @param {Number} x - a value between 0 and the renderer's width
   * @param {Number} y - a value between 0 and the renderer's height
   * @param {THREE.Object} container - three.js container
   * @param {THREE.Camera} camera - three.js camera
   * @param {THREE.WebGLRenderer} renderer - three.js renderer
   * @return {Array} objects under the hit location
   * */
  getObjectsUnderAbsLocFromRenderer(x, y, container = null, camera = null, renderer = null) {
    let r = renderer;

    if (!r) {
      r = this.renderer;
    }
    if (!r) {
      return null;
    }
    const canvas = r.domElement;

    if (!canvas) {
      return null;
    }
    const pixelScale = r.getPixelRatio();

    return this.getObjectsUnderAbsLocFromCanvas(x, y, container, camera, canvas, pixelScale);
  }

  getObjectsUnderAbsLocFromCanvas(x, y, container, camera, canvas, pixelScale) {
    let cvs = canvas;

    if (!cvs) {
      cvs = this.canvas;
    }
    if (!cvs) {
      return null;
    }
    let ps = pixelScale;

    if (!ps) {
      ps = window.devicePixelRatio;
    }
    if (!ps) {
      ps = 1;
    }
    const invPs = 1.0 / ps;
    const screenW = cvs.width * invPs;
    const screenH = cvs.height * invPs;

    return this.getObjectsUnderAbsLoc(x, y, screenW, screenH, container, camera);
  }

  getObjectsUnderAbsLoc(x, y, screenWidth, screenHeight, container = null, camera = null) {
    let w = screenWidth;
    let h = screenHeight;

    w = w < 1 ? 1 : w;
    h = h < 1 ? 1 : h;

    const hw = w * 0.5;
    const hh = h * 0.5;

    const ndcX = (x - hw) / hw;
    const ndcY = (hh - y) / hh;

    return this.getObjectsUnderNdcLoc(ndcX, ndcY, container, camera);
  }

  getObjectsUnderNdcLoc(x, y, container = null, camera = null) {
    let cont = container;
    let cam = camera;

    if (!cont) {
      cont = this.container;
    }
    if (!cam) {
      cam = this.camera;
    }
    if (!cont || !cam) {
      return null;
    }
    let vec = this._vector;

    if (!vec) {
      vec = this._vector = new THREE.Vector2();
    }
    vec.x = x;
    vec.y = y;

    let rc = this._raycaster;

    if (!rc) {
      rc = this._raycaster = new THREE.Raycaster();
    }
    rc.setFromCamera(vec, camera);

    if ((container instanceof Array) || (Array.isArray && Array.isArray(container))) {
      return rc.intersectObjects(container, true);
    }

    return rc.intersectObject(container, true);
  }
}
