import BorderComponentType from './BorderComponentType';
import Vector2 from '../../bgr/bgr3d/geom/Vector2';
import Vector3 from '../../bgr/bgr3d/geom/Vector3';
import Vertex from '../../bgr/bgr3d/geom/Vertex';
import BD3DFabricMaterial from '../material/BD3DFabricMaterial';
import MaterialType from '../material/MaterialType';
import SRTTransform3D from '../../bgr/bgr3d/transform/SRTTransform3D';

import BD3DNodeTypes from '../scenegraph/BD3DNodeTypes';

const DEFAULT_RELATIVE_OFFSET = 0.5;
const DEFAULT_RADIUS = 0.6;
const SUBDIVISIONS = 8;

const pipingMaterialType = new MaterialType('piping');

export default class PipingBorderComponentType extends BorderComponentType {
  _getPipingMaterialType() {
    return pipingMaterialType;
  }

  getNodeType() {
    return BD3DNodeTypes.piping;
  }

  hasTextureUVCorrection() {
    return false;
  }

  addAssets(data, params, array = null, assetManager = null) {
    if (!data) {
      return array;
    }
    let arr = array;

    arr = assetManager.addAssetToArray('defaultfabric.color', arr, params);
    arr = assetManager.addAssetToArray('defaultfabric.normal', arr, params);

    return arr;
  }

  getMaterial(data, mattressData, buildParams = null, result = null) {
    let res = result;

    if (!res) {
      res = new BD3DFabricMaterial();
    }
    const fabricType = data.material; // cotton / satin

    res.setType(this._getPipingMaterialType());
    if (res instanceof BD3DFabricMaterial) {
      res.setFabricType(fabricType);
    }
    const assets = buildParams.assets;
    /*
    const KEY_TEX = 'defaultfabric';
    const KEY_NORMALMAP = 'defaultfabric_normalmap';

    const texture = assets.getAsset(KEY_TEX);
    const normalMap = assets.getAsset(KEY_NORMALMAP);
    */

    // TODO: different default fabric for satin
    if (fabricType === null || typeof (fabricType) === 'undefined' || fabricType === 'cotton') {
      const texture = assets.getAsset('defaultfabric.color');
      const normalMap = assets.getAsset('defaultfabric.normal');

      res.set('diffuse', texture);
      res.set('normal', normalMap);
      if (res instanceof BD3DFabricMaterial) {
        res.setSampleTexture(texture);
        res.setSampleNormalMap(normalMap);
        res.setSampleTransform(new SRTTransform3D(null, null, new Vector3(0.5, 0.5, 1)));
      }
    }
    if (res.setColorMultiplier) {
      res.setColorMultiplier(data.color);
    }

    res.set('specularMask', null);

    return res;
  }
  deformVertices(data, mattressData, yOffset, startX, endX, borderShape, arr, arrayInd) {
    // input params
    const radius = this.getRadius(data);
    let arrayIndex = arrayInd;
    let array = arr;
    const relativeOffset = this.getRelativeOffset(data);
    const depth = this.getDepth(data, mattressData);

    // deform vertices
    if (arrayIndex === undefined || arrayIndex === null || arrayIndex < 0) {
      arrayIndex = 0;
    }

    let cosine = relativeOffset;
    let sine = Math.sqrt(1 - cosine * cosine);
    let startAngle = Math.atan2(-sine, cosine);
    let endAngle = Math.atan2(sine, cosine);

    if (endAngle <= 0) {
      endAngle += Math.PI * 2;
    }
    if (startAngle <= 0) {
      startAngle += Math.PI * 2;
    }

    let lastDist = -1;
    let curTexV = 0;

    const numSubdivs = SUBDIVISIONS;

    // TODO: move some calculations to utils

    for (let i = 0; i <= (numSubdivs); ++i) {
      const t = (i / numSubdivs);
      const index = arrayIndex + i;
      const ang = startAngle + (endAngle - startAngle) * t;
      const dist = ang * radius;
      let dDist = 0;

      if (lastDist >= 0) {
        dDist = dist - lastDist;
        curTexV += dDist;
      }
      lastDist = dist;
      sine = Math.sin(ang);
      cosine = Math.cos(ang);
      let xpos = cosine * radius - (relativeOffset) * radius - depth;
      const ypos = -sine * radius;

      let vertex = null;
      let vertexPos = null;
      let normal = null;
      let uv = null;

      if (array) {
        vertex = array[index];
      }

      if (vertex) {
        vertexPos = vertex.position;
        if (vertex.attributes) {
          normal = vertex.attributes.normal;
          uv = vertex.attributes.uv;
        }
      } else {
        vertex = new Vertex();
      }

      if (!normal) {
        normal = new Vector3(cosine, sine, 0);
      }
      normal.setCoords(cosine, -sine);

      if (!uv) {
        uv = new Vector2(0, curTexV);
      }

      if (startX !== null && startX !== undefined && !isNaN(startX)) {
        if (i === 0) {
          xpos = startX;
        }
      }
      if (endX !== null && endX !== undefined && !isNaN(endX)) {
        if (i === 0) {
          xpos = endX;
        }
      }

      if (!vertexPos) {
        vertexPos = new Vector3(xpos, ypos, 0);
      }

      vertexPos = this.borderTransformVector3(vertexPos, yOffset, borderShape, false, true, vertexPos);
      normal = this.borderTransformVector3(normal, yOffset, borderShape, true, true, normal);

      vertex.position = vertexPos;
      if (!vertex.attributes) {
        vertex.attributes = {};
      }
      vertex.attributes.uv = uv;
      vertex.attributes.normal = normal;
      if (!array) {
        array = [];
      }
      array[index] = vertex;
    }
    this.computeVertexNormals(array, numSubdivs + 1, arrayIndex);

    return array;
  }

  addVertices(borderComponentData, mattressData, yOffset, startX, endX, borderShape, array, ind) {
    let index = ind;

    if ((index === null || index === undefined)) {
      if (array) {
        index = array.length;
      } else {
        index = 0;
      }
    }

    return this.deformVertices(borderComponentData, mattressData, yOffset, startX, endX, borderShape, array, index);
  }

  /**
   * @function getRelativeOffset
   * @description Offset from the center of the circle to the rightmost edge
   * -1: piping is completely inside the mattress
   *  0: piping is half inside the mattress
   *  1: piping is touching the mattress with one tiny little point
   *           ___|_
   *          /   | \
   *         |    |  |
   *          \___|_/
   *        -1   0|   1
   * outside         inside
   * @param {Object} data - data containing properties of the piping component
   * @returns {Number}
   * */
  // relativeOffset: offset from the center of the circle to the rightmost edge

  getRelativeOffset(data) {
    if (data) {
      return this._parseNum(data.relativeOffset, DEFAULT_RELATIVE_OFFSET);
    }

    return DEFAULT_RELATIVE_OFFSET;
  }

  getRadius(borderComponentData, mattressData) {
    const data = borderComponentData;

    if (data) {
      const radius = this._parseNum(data.radius, null);

      if (this._isset(radius)) {
        return radius;
      }
      const height = this._parseNum(data.height, null);

      if (this._isset(height)) {
        return height * 0.5;
      }
    }

    return DEFAULT_RADIUS;
  }

  getStartY(borderComponentData, mattressData) {
    const rOff = this.getRelativeOffset(borderComponentData, mattressData);
    const radius = this.getRadius(borderComponentData, mattressData);

    return Math.sqrt(1 - rOff * rOff) * radius;
  }

  getBottom(borderComponentData, mattressData) {
    return this.getStartY(borderComponentData, mattressData);
  }

  getTop(data) {
    return -this.getStartY(data);
  }
}
