// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
// #endif

function isNumber(nr) {
  return (typeof (nr) === 'number' && !isNaN(nr));
}

function isUintArr(arr) {
  if (!arr) {
    return false;
  }

  return (arr instanceof Uint8Array) || (arr instanceof Uint8ClampedArray);
}

function newElement(type) {
  if (!type) {
    return null;
  }
  if (typeof (document) === 'undefined') {
    // #if DEBUG
    BD3DLogger.warn('Can\'t create element ', type, ' - no DOM');
    // #endif

    return null;
  }

  return document.createElement(type);
}

function isElement(el, type) {
  if (!el || !type) {
    return false;
  }
  const nn = el.nodeName;

  if (!nn) {
    return false;
  }

  return nn.toLowerCase() === type.toLowerCase();
}

function isImage(img) {
  if (typeof (Image) !== 'undefined' && img instanceof Image) {
    return true;
  }

  return isElement(img, 'img');
}

function isCanvas(cvs) {
  return isElement(cvs, 'canvas') && cvs.getContext;
}

function isImageData(imgData) {
  if (!imgData) {
    return false;
  }
  if ((typeof (ImageData) !== 'undefined') && (imgData instanceof ImageData)) {
    return true;
  }

  return isNumber(imgData.width) && isNumber(imgData.height) && isUintArr(imgData.data);
}

function getWidthOf(data) {
  if (!data) {
    // #if DEBUG
    BD3DLogger.warn('CanvasUtils::getWidth - invalid source:', data);
    // #endif

    return 0;
  }
  if (isImage(data)) {
    if (isNumber(data.naturalWidth)) {
      return data.naturalWidth;
    }

    return data.width;
  } else if (isCanvas(data) || isImageData(data)) {
    return data.width;
  }

  // #if DEBUG
  BD3DLogger.warn('CanvasUtils::getWidth - invalid source:', data);
  // #endif

  return 0;
}

function getHeightOf(data) {
  if (!data) {
    // #if DEBUG
    BD3DLogger.warn('CanvasUtils::getHeight - invalid source:', data);
    // #endif

    return 0;
  }
  if (isImage(data)) {
    if (isNumber(data.naturalHeight)) {
      return data.naturalHeight;
    }

    return data.height;
  } else if (isCanvas(data) || isImageData(data)) {
    return data.height;
  }

  // #if DEBUG
  BD3DLogger.warn('CanvasUtils::getHeight - invalid source:', data);
  // #endif

  return 0;
}

function toCanvas(src, params) {
  if (!src) {
    // #if DEBUG
    BD3DLogger.warn('CanvasUtils::toCanvas - invalid source:', src);
    // #endif

    return null;
  }

  if (isCanvas(src)) {
    return src;
  }
  const cvs = newElement('canvas');

  const width = getWidthOf(src);
  const height = getHeightOf(src);

  if (width === 0 || height === 0) {
    // #if DEBUG
    BD3DLogger.warn('CanvasUtils::toCanvas - cant create canvas from ', src, ': invalid size (width=', width, ', height=', height, ')');
    // #endif

    return null;
  }

  cvs.width = width;
  cvs.height = height;

  const ctx = cvs.getContext('2d');

  if (isImage(src)) {
    ctx.drawImage(src, 0, 0);
  } else if (isImageData(src)) {
    ctx.putImageData(src, 0, 0);
  }

  return cvs;
}

function toImage(source, params) {
  if (!source) {
    // #if DEBUG
    BD3DLogger.warn('CanvasUtils::toImage - invalid source', source);
    // #endif

    return null;
  }

  if (isImage(source)) {
    return source;
  }
  let img = typeof (Image) === 'undefined' ? newElement('img') : new Image();

  if (isCanvas(source) && source.toDataURL) {
    if (params && params.callback) {
      const handler = evt => {
        if (img.removeEventListener) {
          img.removeEventListener('load', handler);
        } else if (img.detachEvent) {
          img.detachEvent('load', handler);
        }

        return params.callback(evt);
      };

      if (img.addEventListener) {
        img.addEventListener('load', handler);
      } else if (img.attachEvent) {
        img.attachEvent('load', handler);
      }
    }
    img.src = source.toDataURL();
  } else if (isImageData(source)) {
    img = toImage(toCanvas(source, params), params);
  }

  return img;
}

function toImageData(source, params) {
  if (!source) {
    // #if DEBUG
    BD3DLogger.warn('CanvasUtils::toImageData - invalid source:', source);
    // #endif

    return null;
  }

  if (isImageData(source)) {
    return source;
  }

  if (isImage(source)) {
    return toImageData(toCanvas(source, params));
  } else if (isCanvas(source)) {
    const ctx = source.getContext('2d');

    return ctx.getImageData(0, 0, source.width, source.height);
  }

  return null;
}

function resizeImage(image, width, height) {
  const oldW = getWidthOf(image);
  const oldH = getHeightOf(image);

  if (oldW === width && oldH === height) {
    return image;
  }
  const cvs = newElement('canvas');

  if (!cvs) {
    return cvs;
  }
  cvs.width = width;
  cvs.height = height;
  if (width <= 0 || height <= 0) {
    return cvs;
  }
  if (!isImage(image) && !isCanvas(image)) {
    return cvs;
  }
  const ctx = cvs.getContext('2d');

  ctx.drawImage(image, 0, 0, width, height);

  return cvs;
}

export default class CanvasUtils {
  static resize(source, width, height) {
    return resizeImage(source, width, height);
  }

  static isCanvas(source) {
    return isCanvas(source);
  }

  static isImage(source) {
    return isImage(source);
  }

  static isImageData(source) {
    return isImageData(source);
  }

  static toCanvas(source, params = null) {
    return toCanvas(source, params);
  }

  static toImage(source, params = null) {
    return toImage(source, params);
  }

  static toImageData(source, params = null) {
    return toImageData(source, params);
  }

  static getWidth(source) {
    return getWidthOf(source);
  }

  static getHeight(source) {
    return getHeightOf(source);
  }
}
