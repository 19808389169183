import LoaderState from '../../bgr/common/loading/LoaderState';
import XHRLoader from '../../bgr/common/loading/XHRLoader';
import EventDispatcher from '../../bgr/common/events/EventDispatcher';
// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
// #endif

// const SAMPLES_URL = 'http://app.idc-app.be/en/AJAXapp/f=samples/service=sampleJSON/userSha=59a09769652e98d231b132b37ecd7958eb9541e1';
const SAMPLES_URL = 'samples.json';

// Temporary util to load sample data
export default class SampleService extends EventDispatcher {
  constructor() {
    super();
    this.onGetSample = null;
    this.onGetSampleURL = null;
    this.onGetSampleImageURL = null;
    this._samplesById = null;
    this._samples = null;
  }
  getSamples(callback) {
    const samples = this._samples;

    if (samples) {
      if (callback) {
        callback(samples);

        return samples;
      }

      return samples;
    }
    let samplesLoader = this._samplesLoader;

    if (!samplesLoader) {
      samplesLoader = this._samplesLoader = new XHRLoader();
    }
    let state = samplesLoader.getState();

    if (state === LoaderState.IDLE) {
      samplesLoader.once('finished', this._getSamplesFinishedHandler());
      samplesLoader.responseType = 'json';
      samplesLoader.setSource(SAMPLES_URL);
      samplesLoader.start();
    }
    state = samplesLoader.getState();
    if (callback && state === LoaderState.PROGRESS) {
      const that = this;

      this.once('finished', evt => {
        callback(that._samples);
      });
    }

    return null;
  }

  getSamplesPromise() {
    const that = this;

    return new Promise((resolve, reject) => {
      that.getSamples(resolve);
    });
  }

  _getSamplesFinishedHandler() {
    let res = this._samplesFinishedHandler;

    if (!res) {
      const that = this;

      res = this._samplesFinishedHandler = evt => {
        that._handleSamplesFinished(evt);
      };
    }

    return res;
  }

  _getJSONResponse(ldr) {
    const resp = ldr.getResponse();
    let json = null;

    if (typeof (resp) === 'string') {
      try {
        json = JSON.parse(resp);
      } catch (ex) {
        // #if DEBUG
        BD3DLogger.warn(ex);
        // #endif
        json = null;
      }
    }
    if (json) {
      return json;
    }

    return resp;
  }

  _handleSamplesFinished(evt) {
    this._samples = this._getJSONResponse(evt.loader);
    this.dispatchEvent('finished');
  }

  _makeDict(arr, key) {
    if (!arr) {
      return null;
    }
    const res = {};

    const l = arr.length;

    if (l === 0) {
      return res;
    }

    for (let i = 0; i < l; ++i) {
      const item = arr[i];

      if (item && item[key]) {
        const k = item[key];

        res[k] = item;
      }
    }

    return res;
  }

  _getSamplesById(cb) {
    const that = this;

    return this.getSamplesPromise().then(result => {
      const res = that._makeDict(result, 'id');

      that._samplesById = res;

      cb(res);
    });
  }

  _getSamplesByIdPromise() {
    const that = this;

    return new Promise((resolve, reject) => {
      that._getSamplesById(resolve);
    });
  }

  _createGetSampleURL(id) {
    const escapedID = escape(id);

    if (this.onGetSampleURL) {
      return this.onGetSampleURL(escapedID);
    }

    return null;

    // return `http://app.idc-app.be/en/AJAXapp/f=samples/service=sampleJSON/sampleID=${escapedID}`;
    // return `http://192.168.1.162:8080/en/AJAXapp/f=samples/service=sampleJSON/usersha=123/sampleID=${escapedID}`;
  }

  _createGetSampleImageURL(id, imageKey, sample = null) {

    if (this.onGetSampleImageURL) {
      const escapedID = escape(id);
      const escapedImageKey = escape(imageKey);

      return this.onGetSampleImageURL(escapedID, escapedImageKey, sample);
    }

    return null;
  }

  _findSampleID(sample) {
    const t = typeof (sample);

    if (t === 'string') {
      return sample;
    } else if (t === 'number') {
      return `${sample}`;
    } else if (t === 'object') {
      return this._findSampleID(sample.id);
    }

    return null;
  }

  getSampleImageURL(sample, imageKey) {
    let smp = null;
    const t = typeof (sample);

    if (t === 'object') {
      smp = sample;
    }
    const id = this._findSampleID(sample);
    let url = null;

    if (id !== null && typeof (id) !== 'undefined') {
      url = this._createGetSampleImageURL(id, imageKey, sample);
    }

    if (url !== null && typeof (url) !== 'undefined') {
      return url;
    }

    if (smp) {
      return smp[imageKey];
    }

    return null;
  }

  _createSampleJSONLoader(id) {
    const url = this._createGetSampleURL(id);

    if (!url || typeof (url) !== 'string') {
      return null;
    }
    const ldr = new XHRLoader();

    ldr.setSource(url);

    return ldr;
  }

  removeSample(sample) {
    if (!sample) {
      return;
    }
    const map = this._samplesById;

    if (!map) {
      return;
    }
    for (const v in map) {
      if (map.hasOwnProperty(v)) {
        const value = map[v];

        if (value === sample || v === sample) {
          Reflect.deleteProperty(map, v);
        }
      }
    }
  }

  removeSamples(samples) {
    if (!samples) {
      return;
    }
    const l = samples.length;

    if (!l) {
      return;
    }
    for (let i = 0; i < l; ++i) {
      this.removeSample(samples[i]);
    }
  }

  removeAllSamples() {
    this._samplesById = null;
  }

  addSample(sample, id) {
    this._addSample(sample, id);
  }

  _addSample(sample, id) {
    let map = this._samplesById;

    if (!sample) {
      if (map && id !== null && typeof (id) !== 'undefined') {
        if (typeof (id) === 'string') {
          map[id] = null;
        } else {
          map[`${id}`] = null;
        }
      }

      return;
    }

    let sampleID = sample.id;
    const MATERIAL_NUMBER = 'material_number';
    let sampleMN = sample.materialNumber || sample[MATERIAL_NUMBER];

    let ID = id;

    if (sampleID !== null && typeof (sampleID) !== 'undefined') {
      if (typeof (sampleID) !== 'string') {
        sampleID = `${sampleID}`;
      }
    } else {
      sampleID = null;
    }

    if (sampleMN !== null && typeof (sampleMN) !== 'undefined') {
      if (typeof (sampleMN) !== 'string') {
        sampleMN = `${sampleMN}`;
      }
    } else {
      sampleMN = null;
    }

    if (ID) {
      if (typeof (ID) !== 'string') {
        ID = `${ID}`;
      }
    } else {
      ID = null;
    }
    if (!ID && !sampleID && !sampleMN) {
      return;
    }
    if (!map) {
      map = this._samplesById = {};
    }
    if (ID) {
      map[ID] = sample;
    }
    if (sampleID && sampleID !== ID) {
      if (sampleID) { // sampleID could be null / undefined at this point. I think.
        map[sampleID] = sample;
      }
    }
    if (sampleMN && sampleMN !== sampleID && sampleMN !== ID) {
      if (sampleMN) {
        map[sampleMN] = sample;
      }
    }
  }

  addSamples(samples) {
    if (!samples) {
      return;
    }
    let map = this._samplesById;

    if (!map) {
      map = this._samplesById = {};
    }

    if ((samples instanceof Array) || (Array.isArray && Array.isArray(samples))) {
      const num = samples.length;

      if (!num) {
        return;
      }
      for (let i = 0; i < num; ++i) {
        this._addSample(samples[i]);
      }
    } else if (typeof (samples) === 'object') {
      for (const v in samples) {
        if (samples.hasOwnProperty(v)) {
          const sample = samples[v];

          this._addSample(sample, v);
        }
      }
    }
  }

  _getCachedSampleById(id) {
    const map = this._samplesById;

    if (!map) {
      return null;
    }
    let key;

    if (typeof (id) === 'number') {
      key = `${id}`;
    } else if (typeof (id) === 'string') {
      key = id;
    }

    return map[key];
  }

  _retainLoader(id, ldr) {
    if (!ldr) {
      return;
    }
    let m = this._loaderMap;
    let idmap = this._loaderIdMap;

    if (!m) {
      m = this._loaderMap = {};
    }

    if (!idmap) {
      idmap = new WeakMap();
    }

    const idString = `${id}`;

    m[idString] = ldr;

    idmap.set(ldr, idString);

  }

  _getSampleJSONLoaderById(id) {
    const m = this._loaderMap;

    if (!m) {
      return null;
    }

    return m[`${id}`];
  }

  _releaseLoader(sampleId, loader) {
    const m = this._loaderMap;
    const idMap = this._loaderIdMap;

    let ldr = loader;
    let id = `${sampleId}`;

    if (m && id && !ldr) {
      ldr = m[id];
    }
    if (idMap && ldr && !id) {
      id = idMap.get(ldr);
    }

    if (m && id) {
      Reflect.deleteProperty(m, id);
    }
    if (idMap && ldr) {
      idMap.delete(ldr);
    }
  }

  _getSampleJSONFinishedHandler() {
    let res = this._sampleJSONFinishedHandler;

    if (!res) {
      const that = this;

      res = this._sampleJSONFinishedHandler = evt => {
        that._handleSampleJSONFinished(evt);
      };
    }

    return res;
  }

  _handleSampleJSONFinished(evt) {
    const ldr = evt.loader;

    if (!ldr) {
      this._releaseLoader(null, ldr);

      return;
    }

    let resp = ldr.getResponse();

    if (typeof (resp) === 'string') {
      try {
        resp = JSON.parse(resp);
      } catch (e) {
        resp = null;
      }
    }
    const id = resp ? resp.id : null;

    this._releaseLoader(null, ldr);

    let map = this._samplesById;

    if (!map && id !== null && typeof (id) !== 'undefined') {
      map = this._samplesById = {};
    }
    if (id !== null && typeof (id) !== 'undefined') {
      map[`${id}`] = resp;
    }

    // #if DEBUG
    BD3DLogger.log('sample resp=', resp);
    // #endif

    const callbacks = this._getLoaderCallbacks(id, ldr);

    if (callbacks) {
      const num = callbacks.length;

      for (let i = 0; i < num; ++i) {
        const cb = callbacks[i];

        if (cb) {
          this._callCallback(cb, resp);
        }
      }
    }
    this._removeCallbacksFromLoader(ldr);
  }

  getSampleById(id) {
    const map = this._samplesById;

    if (!map) {
      return null;
    }
    const idString = typeof (id) === 'string' ? id : `${id}`;

    return map[idString];
  }

  getSample(id, params, callback) {
    const map = this._samplesById;

    const idString = typeof (id) === 'string' ? id : `${id}`;

    if (map && map[idString]) {
      const res = map[idString];

      if (callback) {
        callback(res);

        return res;
      }

      return res;
    }

    if (this.onGetSample) {
      // NOTE: onGetSample should not call the callback AND return a value,
      // or the callback will be called twice.
      // If onGetSample calls the callback, the function should not return a value
      // If onGetSample returns a value, it should not have called the callback
      // Maybe I can fix this in the future by using a temporary callback and
      // a flag to check if the callback has been called in onGetSample
      const onGetSampleRes = this.onGetSample(id, params, callback);

      if (onGetSampleRes) {
        if (onGetSampleRes instanceof Promise) {
          // onGetSample returns a promise
          onGetSampleRes.then(callback);

          return null;
        }

        // onGetSample returns the sample object
        if (callback) {
          callback(onGetSampleRes);

          return onGetSampleRes;
        }

        return onGetSampleRes;
      }
      if (onGetSampleRes !== false) {
        return null;
      }
      // if onGetSample() returns false (onGetSampleRes === false), use the default behavior
    }

    const res = this._getCachedSampleById(id);

    if (res) {
      if (callback) {
        callback(res);

        return res;
      }

      return res;
    }
    let ldr = this._getSampleJSONLoaderById(id);

    if (!ldr) {
      ldr = this._createSampleJSONLoader(id);
      if (ldr) {
        ldr.once('finished', this._getSampleJSONFinishedHandler());
        this._retainLoader(id, ldr);
        ldr.start();
      }
    }

    if (ldr) {
      const state = ldr.getState();

      if (LoaderState.isFinished(state)) {
        callback(null);

        return null;
      }
      this._addCallbackToLoader(id, ldr, callback);
    } else {
      callback(null);

      return null;
    }


    return null;
  }

  _callCallback(cb, res) {
    if (!cb) {
      return;
    }
    cb(res);
  }

  _getLoaderCallbacks(id, ldr) {
    if (!ldr) {
      return null;
    }
    const md = ldr.metaData;

    if (!md) {
      return null;
    }

    return md.callbacks;
  }

  _removeCallbacksFromLoader(id, ldr) {
    if (!ldr) {
      return;
    }
    const md = ldr.metaData;

    if (!md) {
      return;
    }

    if (!md.callbacks) {
      return;
    }

    md.callbacks = null;
  }

  _addCallbackToLoader(id, ldr, cb) {
    if (!cb) {
      return;
    }
    if (!ldr) {
      return;
    }
    let md = ldr.metaData;

    if (!md) {
      md = ldr.metaData = {};
    }

    let callbacks = md.callbacks;

    if (!callbacks) {
      callbacks = md.callbacks = [];
    }

    if (callbacks.indexOf(cb, 0) >= 0) {
      return;
    }

    callbacks.push(cb);
  }
  /*
  getSampleById(id, callback) {
    if (this._samplesById) {
      const res = this._samplesById[id];

      if (callback) {
        callback(res);

        return res;
      }

      return res;
    }
    this._getSamplesByIdPromise().then(result => {
      if (result && callback) {
        callback(result[id]);

        return;
      }
    });

    return null;
  }
  */

  getSamplePromise(id, params) {
    const that = this;

    return new Promise((resolve, reject) => {
      that.getSample(id, params, resolve);
    });
  }

  getSampleImage(id, key, callback, sampleObject = null) {
    const url = this._createGetSampleImageURL(id, key, sampleObject);

    if (!url) {
      callback(null, null);

      return null;
    }
    const img = new Image();

    if (callback) {
      const handler = evt => {
        img.removeEventListener('load', handler);
        img.removeEventListener('error', handler);
        callback(evt, img);
      };

      img.addEventListener('load', handler);
      img.addEventListener('error', handler);
    }

    return img;
  }
}
