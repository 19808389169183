import EventDispatcher from '../events/EventDispatcher';

export default class DOMElementController extends EventDispatcher {
  constructor(element, enabled = true) {
    super();
    this._element = element;
    this._enabled = enabled;

    this._updateEnabled();
  }

  getElement() {
    return this._element;
  }

  setElement(elem) {
    if (this._element === elem) {
      return;
    }
    this._setElementEnabled(this._element, false);
    this._element = elem;
    this._setElementEnabled(this._element, this.isEnabled());
  }

  isEnabled() {
    return this._enabled === true;
  }

  setEnabled(e = true) {
    if (this._enabled === e) {
      return;
    }
    this._enabled = e;
    this._updateEnabled();
  }

  _updateEnabled() {
    const e = this.isEnabled();

    this._setElementEnabled(this._element, e);
  }

  _preventNativeEvent(evt) {
    evt.preventDefault();

    return false;
  }

  _setElementEnabled(elem, e = true) {
    return;
  }

  dispose() {
    this.setEnabled(false);
    this.setElement(null);
  }
}
