import BorderComponentType from './BorderComponentType';
import Vector from '../../bgr/bgr3d/geom/Vector';
import Vector2 from '../../bgr/bgr3d/geom/Vector2';
import Vector3 from '../../bgr/bgr3d/geom/Vector3';
import Vertex from '../../bgr/bgr3d/geom/Vertex';
import BD3DSampleFabricMaterial from '../material/BD3DSampleFabricMaterial';
import MaterialTypes from '../material/MaterialTypes';
import FabricMaterialUtils from '../material/FabricMaterialUtils';

const DEFAULT_INSET_Y = 0.5;

export default class GussetBorderComponentType extends BorderComponentType {
  /*
    {
      "type": "gusset",
      "height": 10,
      "inset": 10,
      "depth": 0,

      "texture": {
        "id": "2261"
      }
    }

  */

  constructor() {
    super();
    this.inset = 0;
  }

  getMaterial(data, mattressData, buildParams = null, result = null) {
    let res = result;

    if (!res) {
      // res = new BD3DFabricMaterial();
      res = new BD3DSampleFabricMaterial();
    }
    res.setType(MaterialTypes.SAMPLE);
    res.setFabricType(data.material);
    res.setSettings(data.texture);

    res = FabricMaterialUtils.getBorderMaterialFromData(data.texture, data.quilt, buildParams, null, MaterialTypes.SAMPLE, res);

    return res;
  }

  addVertices(borderComponentData, mattressData, yOffset, startX, endX, borderShape, array, index) {
    let ind = index;

    if ((ind === null || ind === undefined)) {
      if (array) {
        ind = array.length;
      } else {
        ind = 0;
      }
    }

    return this.deformVertices(borderComponentData, mattressData, yOffset, 0, startX, endX, borderShape, array, ind);
  }

  deformVertices(borderComponentData, mattressData, yOffset, vOffset, startX, endX, borderShape, array, startIndex) {
    let arr = array;

    if (!arr) {
      arr = [];
    }
    let ind = startIndex;

    if (typeof (ind) !== 'number') {
      ind = 0;
    }

    let inset = this.getInset(borderComponentData, mattressData);
    const height = this.getHeight(borderComponentData, mattressData);
    const depth = this.getDepth(borderComponentData, mattressData);
    let insetY = this.getInsetY(borderComponentData, mattressData);

    if (insetY < 0) {
      insetY = 0;
    } else if (insetY > 1) {
      insetY = 1;
    }

    inset = inset < 0 ? -inset : inset;

    const topHeight = height * insetY;
    const bottomHeight = height * (1 - insetY);
    const radius = inset;
    const numSubdivsPerHalf = 6;
    const numSubdivs = ((numSubdivsPerHalf) << 1);
    const PI05 = Math.PI * 0.5;

    const lastIndex = numSubdivs - 1;
    const hasStartX = startX !== null && typeof (startX) === 'number' && !isNaN(startX);
    const hasEndX = endX !== null && typeof (endX) === 'number' && !isNaN(endX);
    let prevVert = null;
    let texVOffset = 0;
    const distToll = 0.001;

    for (let i = 0; i < numSubdivs; ++i) {
      const I = ind + (numSubdivs - i - 1);
      let polyVertex = arr[I];

      if (!polyVertex) {
        polyVertex = new Vertex();
        arr[I] = polyVertex;
      }
      let vertex = polyVertex;
      let uv = null;

      if (polyVertex instanceof Vertex && !(polyVertex.position instanceof Vector)) {
        vertex = polyVertex.position = new Vertex();
      }
      if (polyVertex instanceof Vertex) {
        if (!polyVertex.attributes) {
          polyVertex.attributes = {};
        }
        if (!polyVertex.attributes.uv) {
          polyVertex.attributes.uv = new Vector2();
        }
        uv = polyVertex.attributes.uv;
      }

      if (vertex instanceof Vertex && !(vertex.position instanceof Vector)) {
        vertex.position = new Vector3();
      }

      if (inset > 0) {
        if (i < numSubdivsPerHalf) {
          // upper quarter circle
          const t = i / (numSubdivsPerHalf - 1);
          const a = t * PI05;

          const xoff = (1 - Math.cos(a)) * radius + depth;
          const yoff = Math.sin(a) * topHeight;

          polyVertex.setCoord(0, xoff);
          polyVertex.setCoord(1, yoff);
        } else {
          const t = (i - numSubdivsPerHalf) / (numSubdivsPerHalf - 1);
          const a = t * PI05 + PI05;
          const xoff = Math.cos(a) * radius + depth + radius;
          const yoff = -Math.sin(a) * bottomHeight + depth + topHeight + bottomHeight;

          polyVertex.setCoord(0, xoff);
          polyVertex.setCoord(1, yoff);
        }

      } else {
        const t = i / lastIndex;
        const ypos = t * height;

        polyVertex.setCoord(1, ypos);
      }
      uv.setCoord(0, 0);
      uv.setCoord(1, texVOffset);

      if (i === 0 && hasStartX) {
        polyVertex.setCoord(0, startX);
      } else if (i === lastIndex && hasEndX) {
        polyVertex.setCoord(0, endX);
      }

      polyVertex.setCoord(1, polyVertex.getCoord(1) - height * 0.5);

      polyVertex = this.borderTransformVector3(polyVertex, yOffset, borderShape, false, true, polyVertex);

      if (prevVert) {
        const dx = polyVertex.getCoord(0) - prevVert.getCoord(0);
        const dy = polyVertex.getCoord(1) - prevVert.getCoord(1);
        let d = dx * dx + dy * dy;
        const absD = d < 0 ? -d : d;

        if (absD < distToll) {
          const off = 0.2;

          d = off * off;
          polyVertex.setCoord(1, polyVertex.getCoord(1) + off);
        }

        if (d !== 0 && d !== 1) {
          d = Math.sqrt(d);
        }
        texVOffset += d;
      }
      uv.setCoord(1, texVOffset);

      prevVert = polyVertex;
    }

    this.computeVertexNormals(arr, numSubdivs, ind);

    return arr;
  }

  getInset(data, mattressData) {
    if (!data) {
      return this._parseNum(this.inset, 0);
    }

    return this._parseNum(data.inset, this._parseNum(this.inset, 0));
  }

  getInsetY(data, mattressData) {
    if (!data) {
      return this._parseNum(this.insetY, DEFAULT_INSET_Y);
    }

    return this._parseNum(data.insetY, this._parseNum(this.insetY, DEFAULT_INSET_Y));
  }

  getHeight(data, mattressData) {
    if (!data) {
      return 0;
    }

    return this._parseNum(data.height, this._parseNum(this.height, 1));
  }

  getTop(data, mattressData) {
    return -this.getHeight(data, mattressData) * 0.5;
  }

  getBottom(data, mattressData) {
    return this.getHeight(data, mattressData) * 0.5;
  }
}
