import ThreeTextureFilter from '../three/ThreeTextureFilter';
import QuiltBumpBlurThree from '../three/QuiltBumpBlurThree';

import * as THREE from 'three';

const WHITE_BG = 0xFFFFFF;
const DEFAULT_LINE_THICKNESS = 32;
const DEFAULT_TEXTURE_SIZE = 1024;

// TODO: reuse bump to normal shader
// TODO: reuse plane geometry

/**
* @class GridQuiltGeneratorThree
* @description Generates a grid quilt using a three.js render target
**/
export default class GridQuiltGeneratorThree extends ThreeTextureFilter {
  constructor() {
    super();
    // In case the bump-to-normal shader is overridden
    this.bumpToNormalPixelSizeUniform = null;
    this.bumpToNormalTextureUniform = null;
    this.bumpToNormalIntensityUniform = null;
  }
  setBumpToNormalShader(sh) {
    if (sh instanceof THREE.Material) {
      this._heightToNormalMaterial = sh;
    }
  }

  getBumpToNormalPixelSizeUniformName(uniform) {
    if (this.bumpToNormalPixelSizeUniform) {
      return this.bumpToNormalPixelSizeUniform;
    }

    return 'pixelSize';
  }

  getBumpToNormalTextureUniformName(uniform) {
    if (this.bumpToNormalTextureUniform) {
      return this.bumpToNormalTextureUniform;
    }

    return 'texture';
  }

  getBumpToNormalIntensityUniformName(uniform) {
    if (this.bumpToNormalIntensityUniform) {
      return this.bumpToNormalIntensityUniform;
    }

    return 'normalIntensity';
  }
  getBumpToNormalScaleUniformName(uniform) {
    if (this.bumpToNormalScaleUniform) {
      return this.bumpToNormalScaleUniform;
    }

    return 'normalScale';
  }

  _getHeightToNormalMaterial(create) {
    let res = this._heightToNormalMaterial;

    if (!res) {
      res = this._defaultHeightToNormalMaterial;
    }
    if (!res && create) {
      res = this._defaultHeightToNormalMaterial = new THREE.RawShaderMaterial({
        vertexShader: [
          'precision mediump float;',
          'attribute vec3 position;',
          'varying vec2 v_uv;',
          'void main() {',
          ' v_uv = 0.5 * (position.xy + vec2(1.0));',
          ' gl_Position = vec4(position, 1.0);',
          '}'
        ].join('\n'),
        fragmentShader: [
          'precision mediump float;',
          'uniform sampler2D texture;',
          'uniform vec2 pixelSize;',
          'uniform float normalIntensity;',
          'uniform vec3 normalScale;',
          'varying vec2 v_uv;',

          'vec4 samplePixel(sampler2D texture, vec2 uv) {',
          ' vec2 coord = fract(fract(uv) + vec2(1.0));',
          ' return texture2D(texture, coord);',
          '}',

          'vec3 getBumpVec(sampler2D bump, vec2 uv, vec2 off, float ref) {',
          ' float value = samplePixel(bump, uv + off).x;',
          ' return vec3(off, normalIntensity * ((value - ref) / 255.0));',
          '}',

          'vec3 getSampleNormal(sampler2D bump, vec2 uv) {',
          ' vec2 ps = pixelSize;',
          ' float ref = samplePixel(bump, uv).x;',
          ' vec3 vLeft = getBumpVec(bump, uv, vec2(-ps.x, 0.0), ref);',
          ' vec3 vTopLeft = getBumpVec(bump, uv, vec2(-ps.x, -ps.y), ref);',
          ' vec3 vTop = getBumpVec(bump, uv, vec2(0, -ps.y), ref);',
          ' vec3 vTopRight = getBumpVec(bump, uv, vec2(ps.x, -ps.y), ref);',
          ' vec3 vRight = getBumpVec(bump, uv, vec2(ps.x, 0), ref);',
          ' vec3 vBottomRight = getBumpVec(bump, uv, vec2(ps.x, ps.y), ref);',
          ' vec3 vBottom = getBumpVec(bump, uv, vec2(0, ps.y), ref);',
          ' vec3 vBottomLeft = getBumpVec(bump, uv, vec2(-ps.x, ps.y), ref);',
          ' vec3 res = vec3(0.0);',
          ' res += cross(vLeft, vTopLeft);',
          ' res += cross(vTopLeft, vTop);',
          ' res += cross(vTop, vTopRight);',
          ' res += cross(vTopRight, vRight);',
          ' res += cross(vRight, vBottomRight);',
          ' res += cross(vBottomRight, vBottom);',
          ' res += cross(vBottom, vBottomLeft);',
          ' res += cross(vBottomLeft, vLeft);',
          ' res *= 0.125;', // divide by 8
          ' res = normalize(res);',

          ' return res;',
          '}',

          'vec4 sampleNormal(sampler2D bump, vec2 uv) {',
          ' vec3 nrm = getSampleNormal(bump, uv);',
          ' nrm *= normalScale;',
          ' nrm += vec3(1.0);',
          ' nrm *= 0.5;',
          ' return vec4(nrm, 1.0);',
          '}',

          'void main() {',
          ' vec4 pix = sampleNormal(texture, v_uv);',
          // ' gl_FragColor = vec4(v_uv.x, v_uv.y, 0.0, 1.0);',
          ' gl_FragColor = pix;',
          '}'
        ].join('\n')
      });
    }

    return res;
  }
  _getQuiltShaderMaterial(create) {
    let res = this._quiltShaderMaterial;

    if (!res && create) {
      res = this._quiltShaderMaterial = new THREE.RawShaderMaterial({
        vertexShader: [
          'precision mediump float;',
          'attribute vec3 position;',
          'varying vec2 v_pos;',
          'uniform float zValue;',
          'uniform vec2 scale;',
          'uniform vec2 translate;',
          'void main() {',
          ' vec4 pos = vec4(position, 1.0);',
          ' pos.xy *= scale;',
          ' pos.xy += translate;',
          ' pos.z = zValue;',
          ' v_pos = pos.xy;',
          ' gl_Position = pos;',
          '}'
        ].join('\n'),
        fragmentShader: [
          'precision mediump float;',
          'uniform vec2 startPoint;',
          'uniform vec2 endPoint;',
          'uniform float range;',
          'uniform float brightness;',
          'varying vec2 v_pos;',
          'void main() {',
          ' vec2 dvec = endPoint - startPoint;',
          ' dvec.xy = normalize(dvec.yx * vec2(-1.0, 1.0));',
          ' float dist = dot(dvec, v_pos - startPoint);',
          ' dist = dist / range;',
          ' dist = clamp(abs(dist), 0.0, 1.0);',
          ' dist = 1.0 - dist;',
          ' dist = sqrt(1.0 - dist * dist);',
          // ' gl_FragColor = vec4(v_pos.x, v_pos.y, 0.0, 1.0);',
          // ' gl_FragColor = vec4(dist, dist, dist, 1.0);',
          ' gl_FragColor = vec4(vec3(brightness), 1.0 - dist);',
          // ' gl_FragColor.xyz = mix(vec3(1.0, 0.0, 0.0), vec3(0.0, 0.0, 1.0), dist);', // test
          // ' gl_FragColor.a = 1.0;', // test
          '}'
        ].join('\n')
      });
    }

    return res;
  }
  _getMaterialUniforms(mat, create) {
    if (!mat) {
      return false;
    }
    if (mat.uniforms || !create) {
      return mat.uniforms;
    }
    mat.uniforms = {};

    return mat.uniforms;
  }
  _getMaterialUniform(mat, name, create) {
    if (!mat || !name) {
      return null;
    }
    const uniforms = this._getMaterialUniforms(mat, create);

    if (!uniforms) {
      return null;
    }
    let res = uniforms[name];

    if (res || !create) {
      return res;
    }
    res = uniforms[name] = new THREE.Uniform();

    return res;
  }
  _setMaterialUniform(mat, name, uniform) {
    if (!mat || !name) {
      return;
    }
    if (!(uniform instanceof THREE.Uniform)) {
      return;
    }
    if (!mat.uniforms) {
      mat.uniforms = {};
    }
    mat.uniforms[name] = uniform;
  }

  _getMaterialUniformValue(mat, name) {
    const u = this._getMaterialUniform(mat, name, false);

    if (!u) {
      return null;
    }

    return u.value;
  }

  _setMaterialUniformValue(mat, name, value) {
    const u = this._getMaterialUniform(mat, name, true);

    if (!u) {
      return;
    }
    u.value = value;
  }

  _setMaterialUniformVec2(mat, name, x, y) {
    let X = x, Y = y;

    if (x instanceof THREE.Vector2) {
      X = x.x;
      Y = x.y;
    }
    let vec2 = this._getMaterialUniformValue(mat, name);

    if (vec2) {
      vec2.x = X;
      vec2.y = Y;
    } else {
      vec2 = new THREE.Vector2(X, Y);
      this._setMaterialUniformValue(mat, name, vec2);
    }

    return vec2;
  }

  _setMaterialUniformVec3(mat, name, x, y, z) {
    let X = x, Y = y, Z = z;

    if (x instanceof THREE.Vector3) {
      X = x.x;
      Y = x.y;
      Z = x.z;
    }
    let vec3 = this._getMaterialUniformValue(mat, name);

    if (vec3) {
      vec3.x = X;
      vec3.y = Y;
      vec3.z = Z;
    } else {
      vec3 = new THREE.Vector3(X, Y, Z);
      this._setMaterialUniformValue(mat, name, vec3);
    }

    return vec3;
  }

  dispose() {
    const geom = this._defaultPlaneGeometry;

    if (geom && geom.dispose) {
      geom.dispose();
    }
    this._defaultPlaneGeometry = null;

    let mat = this._getQuiltShaderMaterial(false);

    if (mat && mat.dispose) {
      mat.dispose();
    }

    mat = this._defaultHeightToNormalMaterial;

    if (mat && mat.dispose) {
      mat.dispose();
    }
    this._defaultHeightToNormalMaterial = null;

    const rt = this._normalRenderTarget;

    if (rt && rt.dispose) {
      rt.dispose();
    }
  }

  _getPlaneScene(create) {
    let res = this._planeScene;

    if (res || !create) {
      return res;
    }

    res = this._planeScene = new THREE.Scene();
    const mesh = this._getPlaneMesh(create);

    if (mesh) {
      res.add(mesh);
    }

    return res;
  }

  _getPlaneMesh(create) {
    let res = this._planeMesh;

    if (res || !create) {
      res.geometry = this._getPlaneGeometry(create);

      return res;
    }
    res = this._planeMesh = new THREE.Mesh();
    res.geometry = this._getPlaneGeometry(create);
    res.frustumCulled = false;

    return res;
  }

  setPlaneGeometry(g) {
    if ((g instanceof THREE.Geometry) || (g instanceof THREE.BufferGeometry)) {
      this._planeGeometry = g;
    }
  }

  _getPlaneGeometry(create) {
    let res = this._planeGeometry;

    if (res) {
      return res;
    }

    res = this._defaultPlaneGeometry;
    if (res || !create) {
      return res;
    }
    res = this._defaultPlaneGeometry = new THREE.PlaneBufferGeometry(2, 2);

    return res;
  }

  _getCamera(create) {
    let cam = this._camera;

    if (cam || !create) {
      return cam;
    }
    cam = this._camera = new THREE.OrthographicCamera();

    return cam;
  }
  _toArray(obj) {
    if (!obj) {
      return null;
    }
    if ((obj instanceof Array) || (Array.isArray && Array.isArray(obj))) {
      return obj;
    }
    if (typeof (obj) === 'object') {
      const RADIX = 10;

      let res = null;

      for (const v in obj) {
        if (obj.hasOwnProperty(v)) {
          let idx = -1;

          if (typeof (v) === 'string') {
            idx = parseInt(v, RADIX);
          } else if (typeof (v) === 'number') {
            idx = v | 0;
          }
          if (typeof (idx) === 'number' && !isNaN(idx) && idx >= 0) {
            if (!res) {
              res = [];
            }
            res[idx] = obj[v];
          }
        }
      }

      return res;
    }

    return null;
  }
  _renderLines(values, size, mat, renderer, scene, cam, renderTgt, vertical, invert, lineThickness) {
    if (!values) {
      return;
    }
    const num = values.length;

    if (num === 0) {
      return;
    }

    const space = size / num;

    for (let i = 0; i < num; ++i) {
      let I = i;

      if (invert) {
        I = num - i - 1;
      }
      const val = values[i];

      if (val) {
        const pos = ((I * space) / (size * 0.5)) - 1.0 + (space / size);

        if (vertical) {
          this._setMaterialUniformVec2(mat, 'translate', 0, pos);
          this._setMaterialUniformVec2(mat, 'scale', 1, lineThickness);
          this._setMaterialUniformVec2(mat, 'startPoint', 0, pos);
          this._setMaterialUniformVec2(mat, 'endPoint', 1, pos);
        } else {
          this._setMaterialUniformVec2(mat, 'translate', pos, 0);
          this._setMaterialUniformVec2(mat, 'scale', lineThickness, 1);
          this._setMaterialUniformVec2(mat, 'startPoint', pos, 0);
          this._setMaterialUniformVec2(mat, 'endPoint', pos, 1);
        }
        renderer.render(scene, cam, renderTgt, false);
      }
    }
  }

  getTexture(renderer, data, width = DEFAULT_TEXTURE_SIZE, height = DEFAULT_TEXTURE_SIZE, textureParams = null, params = null, result = null) {
    let res = result;

    if (!res) {
      res = new THREE.Texture();
    }
    let oldW = 0, oldH = 0;

    if (res.image) {
      oldW = res.image.width;
      oldH = res.image.height;
    }
    const c = this.getCanvas(renderer, data, width, height, params, res.image);
    let texNeedsUpdate = false;

    if (res.image !== c || c.width !== oldW || c.height !== oldH) {
      res.image = c;
      texNeedsUpdate = true;
    }

    if (textureParams) {
      for (const v in textureParams) {
        if (textureParams.hasOwnProperty(v)) {
          const value = textureParams[v];

          if (res[v] !== value) {
            res[v] = value;
            texNeedsUpdate = true;
          }
        }
      }
    }

    if (texNeedsUpdate) {
      res.needsUpdate = true;
    }

    return res;
  }

  getCanvas(renderer, data, width = DEFAULT_TEXTURE_SIZE, height = DEFAULT_TEXTURE_SIZE, params = null, result = null) {
    let res = result;

    if (!res || !res.getContext) {
      res = document.createElement('canvas');
    }

    const rt = this.getRenderTarget(renderer, data, width, height, params);

    res.width = width;
    res.height = height;

    const resCanvas = this.convertRenderTargetToCanvas(renderer, rt, res, params);

    if (resCanvas) {
      res = resCanvas;
    }

    rt.dispose();

    return res;
  }

  getRenderTarget(renderer, data, width = DEFAULT_TEXTURE_SIZE, height = DEFAULT_TEXTURE_SIZE, params = null) {
    const rt = new THREE.WebGLRenderTarget();

    rt.setSize(width, height);

    this.render(renderer, data, params, rt);

    return rt;
  }

  /**
  * @method render
  * @description Renders horizontal & vertical quilt lines from a quilt data object, using three.js
  * @param {THREE.WebGLRenderer} renderer - three.js renderer
  * @param {Object} data - quilt data
  * @param {Object} params - optional parameters
  *   - normalMap {Boolean} - Renders a normal map instead of a bump map {default = true}
  *   - lineThickness, thickness {Number} - fallback line thickness if not specified in the data object
  *   - depth {Number} - fallback depth value if not specified in the data object
  *   - normalIntensity {Number} - fallback normal intensity if no intensity value specified in the data object
  *   - normalScale {Object} - fallback normalScale if the normal vector scalar is not specified in the data object
  *   - override {Object} - params object used to override the properties 'lineThickness' and 'depth'
  * @param {THREE.WebGLRenderTarget} renderTarget - optional webgl render target
  * @return {void}
  */
  render(renderer, data, params, renderTarget) {
    if (!renderer) {
      return;
    }
    let width = 0, height = 0, normalMap = true, blurSettings = null;

    if (params) {
      normalMap = params.normalMap !== false;
      blurSettings = params.blur || params.blurSettings;
    }

    if (renderTarget) {
      width = renderTarget.width;
      height = renderTarget.height;
    } else {
      const pr = renderer.getPixelRatio();
      const elem = renderer.domElement;

      width = elem.width / pr;
      height = elem.height / pr;
    }

    let rtNormalMap, rtBlur;

    if (normalMap) {
      rtNormalMap = this._normalRenderTarget;
      if (!rtNormalMap) {
        rtNormalMap = this._normalRenderTarget = new THREE.WebGLRenderTarget(width, height);
      }
      rtNormalMap.setSize(width, height);
    }
    const hasBlur = blurSettings && (blurSettings.min > 0 || blurSettings.max > 0) && blurSettings.passes > 0;

    if (hasBlur) {
      rtBlur = this._blurRenderTarget;
      if (!rtBlur) {
        rtBlur = this._blurRenderTarget = new THREE.WebGLRenderTarget(width, height);
      }
      rtBlur.setSize(width, height);
    }

    let rtIn = null;
    let rtOut = null;

    const size = width > height ? width : height;
    let depth = -1;
    let override = null;

    let lineThickness = -1;

    if (typeof (lineThickness) !== 'number' || isNaN(lineThickness) || lineThickness < 0) {
      if (data) {
        lineThickness = data.thickness;
      }
    }
    if (typeof (lineThickness) !== 'number' || isNaN(lineThickness) || lineThickness < 0) {
      if (params) {
        lineThickness = params.lineThickness;
      }
    }

    if (typeof (depth) !== 'number' || isNaN(depth) || depth < 0) {
      if (data) {
        depth = data.depth;
      }
    }
    if (typeof (depth) !== 'number' || isNaN(depth) || depth < 0) {
      if (params) {
        depth = params.depth;
      }
    }

    override = params ? params.override : null;
    if (override) {
      if (typeof (override.lineThickness) === 'number' && !isNaN(override.lineThickness)) {
        lineThickness = override.lineThickness;
      }
      if (typeof (override.depth) === 'number' && !isNaN(override.depth)) {
        depth = override.depth;
      }
    }
    if (typeof (lineThickness) !== 'number' || isNaN(lineThickness) || lineThickness < 0) {
      lineThickness = DEFAULT_LINE_THICKNESS;
    }

    lineThickness = lineThickness / size;

    const scene = this._getPlaneScene(true);
    const cam = this._getCamera(true);
    const mesh = this._getPlaneMesh(true);

    let mat = this._getQuiltShaderMaterial(true);

    mat.transparent = true;
    // mat.depthWrite = false;
    // mat.depthTest = false;

    mesh.material = mat;
    if (depth < 0) {
      depth = 0;
    }
    this._setMaterialUniformValue(mat, 'zValue', 0);
    this._setMaterialUniformVec2(mat, 'scale', 1, 1);
    this._setMaterialUniformVec2(mat, 'translate', 0, 0);
    this._setMaterialUniformValue(mat, 'range', lineThickness);
    this._setMaterialUniformValue(mat, 'brightness', 1 - depth);

    const oldColor = renderer.getClearColor();
    const oldAlpha = renderer.getClearAlpha();
    const oldAutoClear = renderer.autoClear;

    if (hasBlur) {
      rtOut = rtBlur;
    } else if (normalMap) {
      rtOut = rtNormalMap;
    } else {
      rtOut = renderTarget;
    }

    renderer.autoClear = false;
    if (rtOut) {
      renderer.setRenderTarget(rtOut);
    }
    renderer.setClearColor(WHITE_BG, 1.0);
    renderer.clear();

    // x lines
    this._renderLines(this._toArray(data.x), width, mat, renderer, scene, cam, rtOut, false, false, lineThickness);
    // y lines
    this._renderLines(this._toArray(data.y), height, mat, renderer, scene, cam, rtOut, true, true, lineThickness);

    if (hasBlur) {
      let blurUtil = this._blurUtil;

      if (!blurUtil) {
        blurUtil = this._blurUtil = new QuiltBumpBlurThree();
      }
      const minBlur = blurSettings.min;
      const maxBlur = blurSettings.max;
      const passes = blurSettings.passes;

      rtIn = rtOut;

      if (normalMap) {
        rtOut = rtNormalMap;
      } else {
        rtOut = renderTarget;
      }

      blurUtil.renderBlur(rtIn, renderer, minBlur, maxBlur, passes, rtOut);
    }

    if (normalMap && rtOut !== renderTarget) {
      let normalIntensity = 1.0, nScale, nScaleX = 1, nScaleY = 1, nScaleZ = 1;

      if (data) {
        normalIntensity = data.intensity;
        nScale = data.normalScale;

        if (nScale) {
          nScaleX = nScale.x;
          nScaleY = nScale.y;
          nScaleZ = nScale.z;
        }
      }
      if (typeof (normalIntensity) !== 'number' || isNaN(normalIntensity)) {
        if (params) {
          normalIntensity = params.normalIntensity;
        }
      }
      if (!nScale) {
        if (params) {
          nScale = params.normalScale;
          if (nScale) {
            nScaleX = nScale.x;
            nScaleY = nScale.y;
            nScaleZ = nScale.z;
          }
        }
      }
      if (typeof (normalIntensity) !== 'number' || isNaN(normalIntensity)) {
        normalIntensity = 1;
      }
      if (typeof (nScaleX) !== 'number' || isNaN(nScaleX)) {
        nScaleX = 1;
      }
      if (typeof (nScaleY) !== 'number' || isNaN(nScaleY)) {
        nScaleY = 1;
      }
      if (typeof (nScaleZ) !== 'number' || isNaN(nScaleZ)) {
        nScaleZ = 1;
      }
      mat = this._getHeightToNormalMaterial(true);
      this._setMaterialUniformValue(mat, this.getBumpToNormalTextureUniformName(), rtOut.texture);
      this._setMaterialUniformVec2(mat, this.getBumpToNormalPixelSizeUniformName(), 1 / width, 1 / height);
      this._setMaterialUniformValue(mat, this.getBumpToNormalIntensityUniformName(), normalIntensity);
      this._setMaterialUniformVec3(mat, this.getBumpToNormalScaleUniformName(), normalIntensity, nScaleX, nScaleY, nScaleZ);
      mesh.material = mat;

      renderer.render(scene, cam, renderTarget, false);
    }

    /*
    this._setMaterialUniformVec2(mat, 'startPoint', 0.5, 0);
    this._setMaterialUniformVec2(mat, 'endPoint', 1, 1);
    renderer.render(scene, cam, null, false);
    */

    renderer.setClearColor(oldColor, oldAlpha);
    renderer.autoClear = oldAutoClear;
  }
}
