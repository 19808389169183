
import BGR3D from './bgr/bgr3d/BGR3D';
import BD3D from './bd3d/BD3D';
import BD3DBuildInfo from './bd3d/BD3DBuildInfo';
// #if DEBUG
import * as THREE from 'three';
// #endif

window.BGR3D = BGR3D;
window.BD3D = BD3D;

  // #if DEBUG
if (typeof (window) !== 'undefined') {
  window.THREE = THREE;
}
// #endif

const version = BD3DBuildInfo.getVersion();
const buildDate = BD3DBuildInfo.getBuildDate();
const buildMode = BD3DBuildInfo.getBuildMode();
let debugString = null;

// #if DEBUG
debugString = ' - debug';
// #else
// debugString = '';
// #endif

console.log(`BD3D Library v.${version} (${buildMode}${debugString}) - Last update: ${buildDate}`);
