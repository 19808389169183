import ArrayUtils from '../../bgr/common/utils/ArrayUtils';

const borderComponentTypes = [
  {name: 'piping'},
  {name: 'tape'},
  {name: 'zipper'},
  {name: 'gusset'},
  {name: 'fabric'},
  {name: 'border3d'}
];

const borderComponentTypeByName = {};

{
  const num = borderComponentTypes.length;

  for (let i = 0; i < num; ++i) {
    const bct = borderComponentTypes[i];
    const name = bct && bct.name;

    if (name) {
      borderComponentTypeByName[name] = bct;
    }
  }
}

function copyBorderComponentType(type) {
  if (!type) {
    return null;
  }
  const {name} = type;

  return {name};
}

function isValidBorderComponentType(type) {
  if (!type) {
    return false;
  }
  const bct = borderComponentTypeByName[type];

  return bct !== null && typeof (bct) !== 'undefined';
}

function toAvailableBorderComponentType(item) {
  const type = typeof item;

  if (type === 'string') {
    return isValidBorderComponentType(item) ? {name: item} : null;
  } else if (type === 'object' && item) {
    if (item.name) {
      return isValidBorderComponentType(item.name) ? item : null;
    }
    const name = item.type;

    return isValidBorderComponentType(name) ? {name} : null;
  }

  return null;
}
function filterNulls(item) {
  return item !== null && typeof (item) !== 'undefined';
}

export default class BorderComponentDA {
  static isValidBorderComponentType(type) {
    return isValidBorderComponentType(type);
  }

  static getAvailableBorderComponents() {
    return ArrayUtils.map(borderComponentTypes, copyBorderComponentType);
  }

  static filterAvailableBorderComponentTypes(availableTypes) {
    return ArrayUtils.filter(
      ArrayUtils.map(availableTypes, toAvailableBorderComponentType),
      filterNulls
    );
  }
}
