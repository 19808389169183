import * as THREE from 'three';
import MattressDA from '../mattress/MattressDA';
import {getDistFromNode, getDistToAABB} from '../utils/cameracollisionutil';
import SRTTransform3D from '../../bgr/bgr3d/transform/SRTTransform3D';
import RotationOrder from '../../bgr/bgr3d/geom/RotationOrder';
import Matrix4 from '../../bgr/bgr3d/geom/Matrix4';
import Matrix4Math from '../../bgr/bgr3d/math/Matrix4Math';
import VecMat4Math from '../../bgr/bgr3d/math/VecMat4Math';

let tempSRTTransform, tempInverse;
const defaultParams = {margin: 10};

export default class MattressCameraCollisionController {
  constructor(mattressController) {
    this.mattressController = mattressController;
    this.enabled = true;

    this._enableMattressController(mattressController, this.enabled);
  }

  dispose() {
    this.setMattressController(null);
    this.setEnabled(false);
  }

  setMattressController(mc) {
    const old = this.mattresssController;

    if (old === mc) {
      return;
    }
    this.mattressController = mc;
    this._enableMattressController(old, false);
    this._enableMattressController(mc, true);
  }

  isEnabled() {
    return this.enabled;
  }

  setEnabled(e) {
    const old = this.enabled;

    if (old === e) {
      return;
    }
    this.enabled = e;
    this._enableMattressController(this.mattressController, e);
  }

  _handleMattressBuilt(evt, data, result, buildParams, bbox) {
    if (!result) {
      return;
    }
    const width = MattressDA.getWidth(data);
    const length = MattressDA.getLength(data);
    const halfW = width * 0.5;
    const halfL = length * 0.5;

    if (!result) {
      return;
    }

    if (!result.userData) {
      result.userData = {};
    }

    let matrix = result.userData.matrix;

    if (!matrix) {
      matrix = result.userData.matrix = new Matrix4();
    }
    Matrix4Math.identity(matrix);
    result.transform.applyMatrix4(matrix);
    result.userData.inverseMatrix = Matrix4Math.getInverse(matrix, result.userData.inverseMatrix);

    let dataBBox = result.userData.dataBoundingBox;

    if (!dataBBox) {
      dataBBox = result.userData.dataBoundingBox = {};
    }

    dataBBox.minx = -halfW;
    dataBBox.maxx = halfW;
    dataBBox.minz = -halfL;
    dataBBox.maxz = halfL;
    dataBBox.miny = 0;
    dataBBox.maxy = MattressDA.getResultHeight(data);
  }

  _enableMattressController(mc, e) {
    if (!mc) {
      return;
    }
    let handler = this._mattressBuiltHandler;

    if (e) {
      // this.dispatchEvent('mattress_built', data, result, buildParams, bbox);
      if (!handler) {
        handler = (evt, data, result, buildParams, bbox) => {
          this._handleMattressBuilt(evt, data, result, buildParams, bbox);
        };
        this._mattressBuiltHandler = handler;
      }
      mc.addEventListener('mattress_built', handler);
    } else if (handler) {
      mc.removeEventListener('mattress_built', handler);
    }
  }

  getDistToSingle(single, x, y, z, dx, dy, dz, params) {
    if (!single) {
      return null;
    }
    if (!tempSRTTransform) {
      tempSRTTransform = new SRTTransform3D();
    }
    let margin = 0;

    if (params) {
      if (typeof (params.margin) === 'number') {
        margin = params.margin;
      }
    }

    const height = MattressDA.getResultHeight(single);
    const width = MattressDA.getWidth(single);
    const length = MattressDA.getLength(single);

    // construct single matrix
    const posx = MattressDA.getTranslationX(single);
    const posy = MattressDA.getTranslationY(single);
    const posz = MattressDA.getTranslationZ(single);
    const rotx = MattressDA.getRotationX(single);
    const roty = MattressDA.getRotationY(single);
    const rotz = MattressDA.getRotationZ(single);

    tempSRTTransform.position.setCoords(posx, posy, posz);
    tempSRTTransform.rotation.setCoords(rotx, roty, rotz);
    tempSRTTransform.setRotationOrder(RotationOrder.ZYX);
    const m4 = tempSRTTransform.getMatrix4(true);

    tempInverse = Matrix4Math.getInverse(m4, tempInverse);

    let tx = x, ty = y, tz = z, tdx = dx, tdy = dy, tdz = dz;

    // transform the ray origin & direction to the local space of the single
    if (tempInverse) {
      const m = tempInverse;

      tx = VecMat4Math.transformVectorX(x, y, z, 1, m);
      ty = VecMat4Math.transformVectorY(x, y, z, 1, m);
      tz = VecMat4Math.transformVectorZ(x, y, z, 1, m);

      tdx = VecMat4Math.transformVectorX(dx, dy, dz, 0, m);
      tdy = VecMat4Math.transformVectorY(dx, dy, dz, 0, m);
      tdz = VecMat4Math.transformVectorZ(dx, dy, dz, 0, m);
    }

    const maxx = (width * 0.5) + margin;
    const minx = (-maxx);
    const maxz = (length * 0.5) + margin;
    const minz = (-maxz);
    const maxy = height + margin;
    const miny = -margin;

    const t = getDistToAABB(tx, ty, tz, tdx, tdy, tdz, minx, miny, minz, maxx, maxy, maxz, null);

    return t;
  }

  getDistFromScene(x, y, z, dx, dy, dz, params) {
    let mattressNode = null;
    const mc = this.mattressController;

    if (mc.getMattressNode) {
      mattressNode = mc.getMattressNode();
    } else {
      const factory = mc._getMattress3DFactory();

      if (factory && factory.getResult) {
        mattressNode = factory.getResult();
      }
    }
    const dist = getDistFromNode(mattressNode, x, y, z, dx, dy, dz, params);

    return dist;
  }

  getDistFromJSON(x, y, z, dx, dy, dz, params) {
    const mc = this.mattressController;
    const json = mc.getMattressConfigJSON();

    if (!json) {
      return null;
    }
    const singles = json.singles;

    if (!singles) {
      return null;
    }
    const numS = singles.length;

    if (!numS) {
      return null;
    }
    let min = null;

    for (let i = 0; i < numS; ++i) {
      const s = singles[i];
      const d = this.getDistToSingle(s, x, y, z, dx, dy, dz, params);

      if (d !== null && typeof (d) === 'number') {
        if (min === null || typeof (min) !== 'number') {
          min = d;
        } else {
          min = d < min ? d : min;
        }
      }
    }

    return min;
  }

  getDist(x, y, z, dx, dy, dz, params) {
    if (this.METHOD === 'json') {
      return this.getDistFromJSON(x, y, z, dx, dy, dz, params);
    }

    return this.getDistFromScene(x, y, z, dx, dy, dz, params);
  }

  threeUpdateWorldMatrix(o) {
    if (!o) {
      return;
    }
    if (o.matrixAutoUpdate) {
      o.updateMatrix();
    }
    const p = o.parent;

    if (p) {
      this.threeUpdateWorldMatrix(p);
      if (!o.matrixWorld) {
        o.matrixWorld = new THREE.Matrix4();
      }
      o.matrixWorld.multiplyMatrices(p.matrixWorld, o.matrix);
    }
  }

  updateCamZoom() {
    if (!this.enabled) {
      return;
    }
    const mc = this.mattressController;

    const cam = mc._camera;

    this.threeUpdateWorldMatrix(cam);
    const mw = cam.matrixWorld ? cam.matrixWorld.elements : null;

    let x = 0, y = 0, z = 0;
    let dx = 0, dy = 0, dz = 1;

    if (mw) {
      const CAM_MATRIX_X = 12;
      const CAM_MATRIX_Y = 13;
      const CAM_MATRIX_Z = 14;
      const CAM_MATRIX_DX = 8;
      const CAM_MATRIX_DY = 9;
      const CAM_MATRIX_DZ = 10;

      x = mw[CAM_MATRIX_X];
      y = mw[CAM_MATRIX_Y];
      z = mw[CAM_MATRIX_Z];

      dx = -mw[CAM_MATRIX_DX];
      dy = -mw[CAM_MATRIX_DY];
      dz = -mw[CAM_MATRIX_DZ];
    }
    const t = this.getDist(x, y, z, dx, dy, dz, defaultParams);

    if (t !== null) {

      const oc = mc._orbitController;
      const dist = oc.getDistance();

      if (t < 0 || this.stickyCam) {
        oc.setDistance(dist - t);
      }

      // #if DEBUG
      if (!this.testEnabled) {
        return;
      }

      const resX = x + dx * t;
      const resY = y + dy * t;
      const resZ = z + dz * t;

      let indicator = window.TEST_INDICATOR;

      if (!indicator) {
        let geom = window.TEST_INDICATOR_GEOM;

        if (!geom) {
          geom = new THREE.SphereBufferGeometry(0.5);
          window.TEST_INDICATOR_GEOM = geom;
        }
        let mat = window.TEST_INDICATOR_MAT;

        if (!mat) {
          mat = window.TEST_INDICATOR_MAT = new THREE.MeshBasicMaterial({
            color: 0x000088
          });
        }
        indicator = new THREE.Mesh(geom, mat);
        const scene = mc.getScene();

        scene.add(indicator);
        // window.TEST_INDICATOR = indicator;
      }
      const indicatorPos = indicator.position;

      indicatorPos.x = resX;
      indicatorPos.y = resY;
      indicatorPos.z = resZ;
      // #endif
    }
  }
}

/*
MattressThreeComponentController.prototype.setZoom = function (v) {
  const oc = this._orbitController;

  oc.setDistance(v);
  updateCamZoom.call(this);
};

MattressThreeComponentController.prototype.checkCameraInsideObject = function (v) {
  updateCamZoom.call(this);
};


window.addEventListener('keydown', function (evt) {
  if (evt.keyCode === 65) {
    testEnabled = true;
    evt.preventDefault();
    return false;
  }
  if (evt.keyCode === 90) {
    stickyCam = true;
    evt.preventDefault();
    return false;
  }
});
window.addEventListener('keyup', function (evt) {
  if (evt.keyCode === 65) {
    testEnabled = false;
  }
  if (evt.keyCode === 90) {
    stickyCam = false;
  }
});
//*/
