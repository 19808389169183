import AssetHolder from './AssetHolder';
import Asset from './Asset';

/**
 * @class AssetHolderCollection
 * @extends AssetHolder
 * @description AssetHolder extension that holds AssetHolder instances itself
 * Useful for organizing assets by creating hierarchies of AssetHolders
 *
 * */
export default class AssetHolderCollection extends AssetHolder {
  constructor(name, assetHolders) {
    super();
    let n, a;

    if (typeof (name) === 'string') {
      n = name;
    } else if (name instanceof Array) {
      a = name;
    }

    if (typeof (assetHolders) === 'string') {
      n = assetHolders;
    } else if (assetHolders instanceof Array) {
      a = assetHolders;
    }

    this._name = n;
    this.addAssetHolders(a);
  }

  addAssetHolders(holders) {
    if (!holders) {
      return;
    }
    const num = holders.length;

    if (num === 0) {
      return;
    }

    for (let i = 0; i < num; ++i) {
      this.addAssetHolder(holders[i]);
    }
  }

  findAsset(...args) {
    return this.findAssetByPath(args);
  }

  findAssetHolder(...args) {
    return this.findAssetHolderByPath(args);
  }

  findAssetHolderByPath(path) {
    return this._findAssetHolderByPath(path);
  }

  _findAssetHolderByPath(path, endIndex = -1) {
    if (path instanceof Array) {
      const arr = path;
      const num = arr.length;

      if (num === 0) {
        return null;
      }

      const eIdx = endIndex < 0 ? num : endIndex;

      if (eIdx === 0) {
        return null;
      }

      const that = this;
      let res = that;

      for (let i = 0; res && i < eIdx; ++i) {
        const part = arr[i];

        if (!part) {
          return null;
        }
        if (res && res instanceof AssetHolder) {
          res = res.getAssetHolder(part);
        } else {
          break;
        }
      }
      if (res instanceof AssetHolder) {
        return res;
      }
    }

    return null;
  }

  findAssetByPath(path) {
    if (!path) {
      return null;
    }
    if (path instanceof Array) {
      const lastIndex = path.length - 1;
      const assetHolder = this._findAssetHolderByPath(path, lastIndex);

      if (!assetHolder || (!(assetHolder instanceof AssetHolder))) {
        return null;
      }
      const key = path[lastIndex];
      const asset = assetHolder.getAsset(key);

      if (asset instanceof Asset) {
        return asset;
      }
    }

    return null;
  }

  _parseAssetHolder(holder) {
    if (!holder) {
      return null;
    }
    let h = holder;

    if (typeof (h) === 'string') {
      if (!(this._assetHoldersByName)) {
        return null;
      }
      h = this._assetHoldersByName[h];
    }

    return h;
  }

  getAssetOfHolderByName(holder, key) {
    const h = this._parseAssetHolder(holder);

    if (!h || !key) {
      return null;
    }

    if (!(h instanceof AssetHolder)) {
      return null;
    }

    return h.getAsset(key);
  }

  // ! Very inefficient to use
  getAssetAt(index) {
    if (index < 0 || isNaN(index)) {
      return null;
    }
    const holders = this._assetHolders;

    if (!holders) {
      return null;
    }

    const num = holders.length;

    if (num === 0) {
      return null;
    }

    let off = 0;

    for (let i = 0; i < num; ++i) {
      const h = holders[i];

      if (h) {
        const len = h.getNumAssets();
        const endIndex = off + len;

        if (index >= off && index < endIndex) {
          return h.getAssetAt(index - off);
        }
        off = endIndex;
      }
    }

    return null;
  }

  getAssetByName(key) {
    if (!key) {
      return null;
    }
    const holders = this._assetHolders;

    if (!holders) {
      return null;
    }

    const num = holders.length;

    if (num === 0) {
      return null;
    }
    for (let i = 0; i < num; ++i) {
      const holder = holders[i];
      const asset = this.getAssetOfHolderByName(holder, key);

      if (asset) {
        return asset;
      }
    }

    return null;
  }

  getNumAssets() {
    const holders = this._assetHolders;

    if (!holders) {
      return 0;
    }
    const num = holders.length;

    if (num === 0) {
      return 0;
    }
    let res = 0;

    for (let i = 0; i < num; ++i) {
      const aH = holders[i];

      if (aH && aH instanceof AssetHolder) {
        res += aH.getNumAssets();
      }
    }

    return res;
  }

  getNumAssetHolders() {
    const assetHolders = this._assetHolders;

    if (!assetHolders) {
      return 0;
    }

    return assetHolders.length;
  }

  getAssetHolderAt(index) {
    const holders = this._assetHolders;

    if (!holders) {
      return null;
    }

    const h = holders[index];

    return h;
  }

  getAssetHolderByName(name) {
    if (!name) {
      return null;
    }
    const map = this._assetHoldersByName;

    if (!map) {
      return null;
    }

    return map[name];
  }

  getAssetHolder(key) {
    const t = typeof (key);

    if (t === 'number') {
      return this.getAssetHolderAt(key);
    } else if (t === 'string') {
      return this.getAssetHolderByName(key);
    }

    return null;
  }

  get numAssetHolders() {
    return this.getNumAssetHolders();
  }

  forEachAsset(callback, callbackData = null) {
    if (!callback) {
      return;
    }
    const assetHolders = this._assetHolders;

    if (!assetHolders) {
      return;
    }
    const num = assetHolders.length;

    if (num === 0) {
      return;
    }

    for (let i = 0; i < num; ++i) {
      const assetHolder = assetHolders[i];

      if (assetHolder && assetHolder instanceof AssetHolder) {
        assetHolder.forEachAsset(callback, callbackData);
      }
    }
  }

  removeAsset(asset) {
    if (asset === null || typeof (asset) === 'undefined') {
      return;
    }
    const holders = this._assetHolders;

    if (!holders) {
      return;
    }

    const num = holders.length;

    if (num === 0) {
      return;
    }
    for (let i = 0; i < num; ++i) {
      const holder = holders[i];

      if (holder && holder instanceof AssetHolder) {
        holder.removeAsset(asset);
      }
    }
  }

  /**
   * @method clearAssets
   * @description Clears assets only
   * @return {void}
   * */
  clearAssets() {
    const holders = this._assetHolders;

    if (!holders) {
      return;
    }

    const num = holders.length;

    if (num === 0) {
      return;
    }
    for (let i = 0; i < num; ++i) {
      const holder = holders[i];

      if (holder && holder instanceof AssetHolder) {
        holder.clearAssets();
      }
    }
  }

  clear() {
    const holders = this._assetHolders;

    if (!holders) {
      return;
    }

    const num = holders.length;

    if (num === 0) {
      return;
    }
    for (let i = 0; i < num; ++i) {
      const holder = holders[i];

      if (holder && holder instanceof AssetHolder) {
        holder.clear();
      }
    }
    this._assetHolders = null;
    this._assetHoldersByName = null;
  }

  _removeAssetHolderFromMap(assetHolder, map) {
    if (!assetHolder || !map) {
      return;
    }
    for (const v in map) {
      if (map.hasOwnProperty(v)) {
        const a = map[v];

        if (a === assetHolder) {
          Reflect.deleteProperty(map, v);
        }
      }
    }
  }

  _removeAssetHolderFromArray(assetHolder, array) {
    if (!assetHolder || !array) {
      return;
    }
    const idx = array.indexOf(assetHolder, 0);

    if (idx < 0) {
      return;
    }
    array.splice(idx, 1);
  }

  removeAssetHolder(assetHolder) {
    if (!assetHolder) {
      return;
    }
    const aH = this._parseAssetHolder(assetHolder);

    const arr = this._assetHolders;
    const map = this._assetHoldersByName;

    if (aH instanceof AssetHolder) {
      this._removeAssetHolderFromArray(aH, arr);
      this._removeAssetHolderFromMap(aH, map);
    }
  }

  addAssetHolder(holder, key = null) {
    if (!holder) {
      return;
    }
    if (!(holder instanceof AssetHolder)) {
      return;
    }
    let k = key;

    if (!k) {
      k = holder.name;
    }
    if (k) {
      if (!this._assetHoldersByName) {
        this._assetHoldersByName = {};
      }
      this._assetHoldersByName[k] = holder;
    }

    let arr = this._assetHolders;

    if (!arr) {
      arr = this._assetHolders = [];
    }

    if (arr.indexOf(holder, 0) < 0) {
      arr.push(holder);
    }
  }
}
