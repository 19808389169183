import ContainerNode3D from '../../bgr/bgr3d/scenegraph/ContainerNode3D';

export default class BD3DContainerNode3D extends ContainerNode3D {
  constructor(children = null, transform = null, selectable = false, selectionModes = null) {
    super(children, transform, selectable);
    this._selectable = selectable;
    this._nodeType = null;

    this.selectionModes = selectionModes;
  }

  getNodeType() {
    return this._nodeType;
  }

  setNodeType(t) {
    this._nodeType = t;
  }

  get nodeType() {
    return this.getNodeType();
  }

  set nodeType(t) {
    this.setNodeType(t);
  }

  isSelectable() {
    return this._selectable;
  }

  setSelectable(b) {
    this._selectable = b;
  }

  get selectable() {
    return this.isSelectable();
  }

  set selectable(b) {
    this.setSelectable(b);
  }

  getBoundingBox() {
    return this._boundingBox;
  }

  setBoundingBox(b) {
    this._boundingBox = b;
  }

  get boundingBox() {
    return this.getBoundingBox();
  }

  set boundingBox(b) {
    return this.setBoundingBox(b);
  }

  hasSelectionMode(mode) {
    const modes = this.selectionModes;

    if ((modes instanceof Array) || (Array.isArray && Array.isArray(modes))) {
      return modes.indexOf(mode, 0) >= 0;
    } else if (modes && typeof (modes) === 'object') {
      return modes[mode] === true;
    }

    return false;
  }
}
