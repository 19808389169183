import Asset from './Asset';
import Node3D from '../../bgr/bgr3d/scenegraph/Node3D';
import ContainerNode3D from '../../bgr/bgr3d/scenegraph/ContainerNode3D';
// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
// #endif
// import GeometryNode3D from '../../bgr/bgr3d/scenegraph/GeometryNode3D';

const TYPE_3DOBJECT = 'object3d';

export default class Object3DAsset extends Asset {
  constructor(name) {
    super(name, TYPE_3DOBJECT);

    return this;
  }

  setData(data) {
    super.setData(data);
    this._initNode3D(this.getNode3D());
  }

  _initNode3D(n) {
    if (!n) {
      return;
    }
    if (n instanceof Node3D) {
      if (!n.userData) {
        n.userData = {};
      }
      n.userData.isAsset = true;
      n.userData.asset = this;

      if (n instanceof ContainerNode3D) {
        const children = n.getChildren();
        const num = children ? children.length : 0;

        if (!num) {
          return;
        }
        for (let i = 0; i < num; ++i) {
          this._initNode3D(children[i]);
        }
      }
    }
  }

  getGeometry(key) {
    const d = this.getData();

    if (!d) {
      return null;
    }
    if (typeof (key) === 'string') {
      const geomByName = d.geometriesByName;

      if (geomByName) {
        if (geomByName[key]) {
          return geomByName[key];
        }
      }
    }
    let i, l, geom, ud;
    const geomArr = d.geometries;

    if (geomArr) {
      if (typeof (key) === 'number') {
        if (geomArr[key]) {
          return geomArr[key];
        }
      }
      l = geomArr.length;
      for (i = 0; i < l; ++i) {
        geom = geomArr[i];
        if (geom) {
          ud = geom.userData;
          if (ud && ud.name === key) {
            return geom;
          }
        }
      }
    }

    return null;
  }

  getNode() {
    // #if DEBUG
    BD3DLogger.warn('Object3DAsset.getNode() is deprecated. Use Object3DAsset.getNode3D instead');
    // #endif

    return this.getNode3D();
  }

  getNode3D() {
    let d = this.getData();

    if (!d) {
      return null;
    }
    if (d instanceof Node3D) {
      return d;
    }
    d = d.node;
    if (d instanceof Node3D) {
      return d;
    }

    return null;
  }

  get node3D() {
    return this.getNode3D();
  }
}

Object3DAsset.TYPE = TYPE_3DOBJECT;
