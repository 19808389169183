import EventDispatcher from '../events/EventDispatcher';
import LoaderState from './LoaderState';
import LoaderEvent from './LoaderEvent';

const UNDEFINED = 'undefined';

/**
 * Loader
 * @abstract
 * @extends EventDispatcher
 */
export default class Loader extends EventDispatcher {
  constructor() {
    super();
    this.metaData = null;
  }
  /**
   * Start the loader
   * @return {void}
   */
  start() {
    this._changeState(LoaderState.COMPLETED);
  }

  /**
   * Cancel loading
   * @return {void}
   */
  cancel() {
    this._changeState(LoaderState.CANCELED);
  }

  /**
   * Retrieve meta data value
   * @param  {String} key - key
   * @return {Object} - value
   */
  getUserDataValue(key) {
    if (key === null || typeof (key) === UNDEFINED) {
      return null;
    }
    const ud = this.userData;

    if (ud === null || typeof (ud) === UNDEFINED) {
      return null;
    }

    if ((typeof (Map) !== UNDEFINED && ud instanceof Map) ||
      (typeof (WeakMap) !== UNDEFINED && ud instanceof WeakMap)) {
      return ud.get(key);
    }

    return ud[key];
  }

  /**
   * Assigns meta data to this instance
   * @param {String} key - Key
   * @param {Object} value - Value
   * @return {void}
   */
  setUserDataValue(key, value) {
    if (key === null || typeof (key) === UNDEFINED) {
      return;
    }

    let ud = this.userData;

    if (ud === null || typeof (ud) === UNDEFINED) {
      if (value !== null && typeof (value) !== UNDEFINED) {
        if (typeof (Map) === UNDEFINED) {
          ud = {};
        } else {
          ud = new Map();
        }
        this.userData = ud;
      }
    }
    if (ud === null || typeof (ud) === UNDEFINED) {
      return;
    }
    if ((typeof (Map) !== UNDEFINED && ud instanceof Map) || typeof (WeakMap !== UNDEFINED) && (ud instanceof WeakMap)) {
      ud.set(key, value);
    } else {
      ud[key] = value;
    }
  }

  /**
   * Resets the loader so it can load again
   * @param {Object} params - Params object
   * @returns {void}
   */
  reset(params = null) {
    if (this._loaderState === LoaderState.PROGRESS) {
      this.cancel();
    }
    this._loaderState = LoaderState.IDLE;
  }

  /**
   * The current loading state (IDLE, PROGRESS, COMPLETE, CANCELED, ERROR, ...)
   * @see LoaderState
   * @return {LoaderState} LoaderState
   */
  getState() {
    if (this._loaderState === null || typeof (this._loaderState) === UNDEFINED) {
      this._loaderState = LoaderState.IDLE;
    }

    return this._loaderState;
  }

  /**
   * Internal method to change the loader's state
   * @protected
   * @param  {LoaderState} s - The new loader state
   * @see {LoaderState}
   * @return {void}
   */
  _changeState(s) {
    if (this._loaderState === s) {
      return;
    }
    this._loaderState = s;
    if (s === LoaderState.COMPLETED) {
      this._dispatchComplete();
      this._dispatchFinished();
    } else if (s === LoaderState.ERROR) {
      this._dispatchError();
      this._dispatchFinished();
    } else if (s === LoaderState.CANCELED) {
      this._dispatchCanceled();
      this._dispatchFinished();
    }
  }

  /**
   * Internal loader event object
   * @param  {[type]} type [description]
   * @param  {[type]} data =             null [description]
   * @return {[type]}      [description]
   */
  _getLoaderEventObject(type, data = null) {
    if (type === null || typeof (type) === UNDEFINED) {
      return null;
    }
    let loaderEventByType = this._loaderEventByType;

    if (loaderEventByType === null || typeof (loaderEventByType) === UNDEFINED) {
      loaderEventByType = this._loaderEventByType = {};
    }
    let res = loaderEventByType[type];

    if (res === null || typeof (res) === UNDEFINED) {
      res = new LoaderEvent(type);
    }
    res.loader = this;
    res.data = data;

    return res;
  }

  /**
   * Dispatches the complete event
   * @return {void}
   */
  _dispatchComplete() {
    if (this.hasEventListener(LoaderEvent.COMPLETED)) {
      this.dispatchEvent(this._getLoaderEventObject(LoaderEvent.COMPLETED));
    }
  }

  /**
   * Dispatches the error event
   * @return {void}
   */
  _dispatchError() {
    if (this.hasEventListener(LoaderEvent.ERROR)) {
      this.dispatchEvent(this._getLoaderEventObject(LoaderEvent.ERROR));
    }
  }

  /**
   * Dispatches the canceled event
   * @return {void}
   */
  _dispatchCanceled() {
    if (this.hasEventListener(LoaderEvent.CANCELED)) {
      this.dispatchEvent(this._getLoaderEventObject(LoaderEvent.CANCELED));
    }
  }

  /**
   * Dispatches the finished
   * @return {void}
   */
  _dispatchFinished() {
    if (this.hasEventListener(LoaderEvent.FINISHED)) {
      this.dispatchEvent(this._getLoaderEventObject(LoaderEvent.FINISHED));
    }
  }
}
