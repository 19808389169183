/**
internal properties
------------------------
property: {name: "single_width", single: }
controller_property

single_width:Number
single_length:Number
single_topHeight:Number
single_bottomHeight:Number
single_topRadius:Number
single_bottomRadius:Number
single_cornerRadius:Number

single_bordercurve

single_legType

single_handle_type
single_handle_material
single_handle_material_tape
single_handle_material_fabric

top_sample:Sample
top_sample_xoffset:Number
top_sample_yoffset:Number
top_sample_angle:int
top_quilt:Quilt
top_quilt_xoffset:Number
top_quilt_yoffset:Number
top_quilt_angle:int
top_quilt_repeatType:Enum<RepeatType>
top_quilt_xrepeat:int
top_quilt_yrepeat:int
top_quilt_foam:Number
top_quilt_softness:Number
top_quilt_thickness:Number

bottom_sample:Sample
bottom_sample_xoffset:Number
bottom_sample_yoffset:Number
bottom_sample_angle:int
bottom_quilt:Quilt
bottom_quilt_xoffset:Number
bottom_quilt_yoffset:Number
bottom_quilt_angle:int
bottom_quilt_repeatType:Enum<RepeatType>
bottom_quilt_xrepeat:int
bottom_quilt_yrepeat:int
bottom_quilt_foam:Number
bottom_quilt_softness:Number
bottom_quilt_thickness:Number

topMirrorPanel_sample:Sample
topMirrorPanel_sample_xoffset:Number
topMirrorPanel_sample_yoffset:Number
topMirrorPanel_sample_angle:int
topMirrorPanel_quilt:Quilt
topMirrorPanel_quilt_xoffset:Number
topMirrorPanel_quilt_yoffset:Number
topMirrorPanel_quilt_angle:int
topMirrorPanel_quilt_repeatType:Enum<RepeatType>
topMirrorPanel_quilt_xrepeat:int
topMirrorPanel_quilt_yrepeat:int
topMirrorPanel_quilt_foam:Number
topMirrorPanel_quilt_softness:Number
topMirrorPanel_quilt_thickness:Number

bottomMirrorPanel_sample:Sample
bottomMirrorPanel_sample_xoffset:Number
bottomMirrorPanel_sample_yoffset:Number
bottomMirrorPanel_sample_angle:int
bottomMirrorPanel_quilt:Quilt
bottomMirrorPanel_quilt_xoffset:Number
bottomMirrorPanel_quilt_yoffset:Number
bottomMirrorPanel_quilt_angle:int
bottomMirrorPanel_quilt_repeatType:Enum<RepeatType>
bottomMirrorPanel_quilt_xrepeat:int
bottomMirrorPanel_quilt_yrepeat:int
bottomMirrorPanel_quilt_foam:Number
bottomMirrorPanel_quilt_softness:Number
bottomMirrorPanel_quilt_thickness:Number

borderfabric_sample:Sample
borderfabric_sample_xoffset:Number
borderfabric_sample_yoffset:Number
borderfabric_sample_angle:int
borderfabric_quilt:Quilt
borderfabric_quilt_xoffset:Number
borderfabric_quilt_yoffset:Number
borderfabric_quilt_angle:int
borderfabric_quilt_repeatType:Enum<RepeatType>
borderfabric_quilt_xrepeat:int
borderfabric_quilt_yrepeat:int
borderfabric_quilt_foam:Number
borderfabric_quilt_softness:Number
borderfabric_quilt_thickness:Number

borderfabric_quilttype:String (custom/preset)
borderfabric_quiltlinex (index, true/false)
borderfabric_quiltliney (index, true/false)

border_depth:Number
border_height:Number
border_color:String
border_material:String (satin/cotton)

borderzipper_zippertype:String (teeth/spiral_coil/reverse_coil)
borderzipper_puller: int/string? (0, 1, 2)
*/

const MattressConfigObjectTypes = {
  CONFIG: {
    name: 'config'
  },
  GROUP: {
    name: 'group'
  },
  CONTROLLER: {
    name: 'controller'
  },
  SINGLE: {
    name: 'single'
  },

  LEGS: {
    name: 'legs'
  },

  HANDLES: {
    name: 'handles'
  },

  TOP_PANEL: {
    name: 'topPanel'
  },
  BOTTOM_PANEL: {
    name: 'bottomPanel'
  },
  BORDER: {
    name: 'border'
  },
  BORDER_COMPONENTS: {
    name: 'borderComponents'
  },
  BORDER_COMPONENT: {
    name: 'borderComponent'
  },
  CUSTOM: {
    name: 'custom'
  }
};

export default MattressConfigObjectTypes;
