import ThreeTextureFilter from '../three/ThreeTextureFilter';
import * as THREE from 'three';
// TODO: reuse stuff from & move stuff to BumpNormalUtils && CanvasUtils
// import BumpNormalUtils from '../utils/BumpNormalUtils';

const ROFFSET = 0;
const GOFFSET = 1;
const BOFFSET = 2;
// const AOFFSET = 3;

const RSHIFT = 0;
const GSHIFT = 8;
const BSHIFT = 16;
// const ASHIFT = 24;

function isElement(elem, type) {
  if (!type || !elem) {
    return false;
  }
  let nn = elem.nodeName;

  if (!nn) {
    return false;
  }

  nn = nn.toLowerCase();
  const t1 = type.toLowerCase();

  return t1 === nn;
}

function isCanvas(canvas) {
  if (!canvas) {
    return false;
  }

  return isElement(canvas, 'canvas') && canvas.getContext;
}

function isImage(obj) {
  if (!obj) {
    return false;
  }
  if (typeof (Image) !== 'undefined' && (obj instanceof Image)) {
    return true;
  }

  return isElement(obj, 'image');
}

function isThreeTexture(tex) {
  if (!tex) {
    return false;
  }
  if (typeof (THREE) === 'undefined') {
    return false;
  }
  const Texture = THREE.Texture;

  if (typeof (Texture) === 'undefined' || Texture === null) {
    return false;
  }

  return tex instanceof Texture;
}

function isBumpMapPixel(R, G, B, params) {
  let toll = 32;

  if (params) {
    if (typeof (params.tollerance) !== 'undefined' && params.tollerance !== null) {
      toll = params.tollerance;
    }
  }
  const divAvg = 3;
  const avg = (R + G + B) / divAvg;

  const diffR = R > avg ? R - avg : avg - R;
  const diffG = G > avg ? G - avg : avg - G;
  const diffB = B > avg ? B - avg : avg - B;

  return (diffR < toll) && (diffG < toll) && (diffB < toll);
}

function ceilPO2(value) {
  let v = value;

  if ((v & (v - 1)) === 0) {
    return v;
  }
  const s1 = 1;
  const s2 = 2;
  const s4 = 4;
  const s8 = 8;
  const s16 = 16;
  const s32 = 32;

  v |= v >> s1;
  v |= v >> s2;
  v |= v >> s4;
  v |= v >> s8;
  v |= v >> s16;
  v |= v >> s32;

  return v + s1;
}

function floorPO2(v) {
  if ((v & (v - 1)) === 0) {
    return v;
  }
  const c = ceilPO2(v);

  return c >> 1;
}

function nearestPO2(v) {
  if ((v & (v - 1)) === 0) {
    return v;
  }
  const c = ceilPO2(v);
  const f = c >> 1;
  const d1 = v - f;
  const d2 = c - v;

  return (d1 < d2) ? f : c;
}

const METHOD_FLOOR_TEXTURE_SIZE = 0;
const METHOD_ROUND_TEXTURE_SIZE = 1;
const METHOD_CEIL_TEXTURE_SIZE = 2;

let tempTextureSize = null;

function getTextureSize(tex, result) {
  let res = result;

  if (res) {
    res.width = 0;
    res.height = 0;
  }
  if (!tex) {
    return res;
  }

  if (isCanvas(tex)) {
    if (!res) {
      res = {};
    }
    res.width = tex.width;
    res.height = tex.height;

    return res;
  } else if (isImage(tex)) {
    if (!res) {
      res = {};
    }
    if (typeof (tex.naturalWidth) === 'undefined') {
      let cssW = null, cssH = null;

      // temporarily reset css value
      // when the css width or height is set for an image,
      // the width and height properties give incorrect values
      if (tex.style) {
        cssW = tex.style.width;
        cssH = tex.style.height;
        tex.style.width = null;
        tex.style.height = null;
      }
      res.width = tex.width;
      res.height = tex.height;

      if (tex.style) {
        tex.style.width = cssW;
        tex.style.height = cssH;
      }
    } else {
      res.width = tex.naturalWidth;
      res.height = tex.naturalHeight;
    }

    return res;
  } else if (isThreeTexture(tex)) {
    return getTextureSize(tex.image, res);
  }

  return res;
}


function getTextureWidth(tex, fallback) {
  tempTextureSize = getTextureSize(tex, tempTextureSize);

  return tempTextureSize.width;
}

function getTextureHeight(tex, fallback) {
  tempTextureSize = getTextureSize(tex, tempTextureSize);

  return tempTextureSize.height;
}

function getGLMaxTexSize(context, clampValue = -1) {
  if (!context) {
    return clampValue;
  }
  let ctx = context;

  if (context instanceof THREE.WebGLRenderer) {
    ctx = context.context;
  }
  if (!ctx) {
    return clampValue;
  }
  const glMaxTexSize = ctx.getParameter(ctx.MAX_TEXTURE_SIZE);

  let res = clampValue;

  if (res < 0 || res === null || typeof (res) === 'undefined' || isNaN(res)) {
    res = glMaxTexSize;
  } else {
    res = res < glMaxTexSize ? res : glMaxTexSize;
  }

  return res;
}

function getFixedTextureSize(size, minSize, maxSize, method) {
  let min = minSize;
  let max = maxSize;

  if (typeof (min) !== 'undefined' && min !== null && !isNaN(min)) {
    min = floorPO2(min);
  }
  if (typeof (max) !== 'undefined' && max !== null && !isNaN(max)) {
    max = floorPO2(max);
  }
  let res = size;

  if (method === METHOD_FLOOR_TEXTURE_SIZE) {
    res = floorPO2(res);
  } else if (method === METHOD_ROUND_TEXTURE_SIZE) {
    res = nearestPO2(res);
  } else {
    res = ceilPO2(res);
  }
  if (min > 0 && min !== null && typeof (min) !== 'undefined' && !isNaN(min)) {
    res = res < min ? min : res;
  }
  if (max > 0 && max !== null && typeof (max) !== 'undefined' && !isNaN(max)) {
    res = res > max ? max : res;
  }

  return res;
}

function resizeImage(img, width, height) {
  tempTextureSize = getTextureSize(img, tempTextureSize);
  const w = tempTextureSize.width;
  const h = tempTextureSize.height;

  if (width === w && height === h) {
    return img;
  }
  const canvas = document.createElement('canvas');

  canvas.width = width;
  canvas.height = height;
  const context = canvas.getContext('2d');

  context.drawImage(img, 0, 0, width, height);

  return canvas;

}

function fixTextureSize(source, minSize, maxSize, method) {
  if ((typeof (minSize) === 'undefined' || minSize === null || isNaN(minSize)) && (typeof (maxSize) === 'undefined' || maxSize === null || isNaN(maxSize))) {
    return source;
  }
  tempTextureSize = getTextureSize(source, tempTextureSize);
  const w = getFixedTextureSize(tempTextureSize.width, minSize, maxSize, method);
  const h = getFixedTextureSize(tempTextureSize.height, minSize, maxSize, method);

  return resizeImage(source, w, h);
}

function isBumpMapPixels(pixels, width, height, params) {
  if (!pixels) {
    return false;
  }

  let numChecks = 10;

  if (params) {
    if (typeof (params.numChecks) !== 'undefined' && params.numChecks !== null) {
      numChecks = params.numChecks;
    }
  }
  const isUint32 = pixels instanceof Uint32Array;
  const numValues = pixels.length;
  let numPixels = numValues;
  let numBumpPixels = 0;

  if (!isUint32) {
    numPixels = numValues >> 2;
  }

  for (let i = 1; i <= numChecks; ++i) {
    const index = ((i / numChecks) * numPixels) | 0;
    let R = 0, G = 0, B = 0;

    if (isUint32) {
      const pixel = pixels[index];
      const mask = 0xFF;

      R = (pixel >> RSHIFT) & mask;
      G = (pixel >> GSHIFT) & mask;
      B = (pixel >> BSHIFT) & mask;
    } else {
      const offset = index << 2;

      R = pixels[offset + ROFFSET];
      G = pixels[offset + GOFFSET];
      B = pixels[offset + BOFFSET];
    }
    if (isBumpMapPixel(R, G, B, params)) {
      ++numBumpPixels;
    }
  }

  return (numBumpPixels > (numChecks >> 1));
}

function isBumpMap(obj, params) {
  if (!obj) {
    return false;
  }
  if (isImage(obj)) {
    const w = obj.width;
    const h = obj.height;

    if (!w || !h) {
      return false;
    }
    const cvs = document.createElement('canvas');

    cvs.width = w;
    cvs.height = h;
    const ctx = cvs.getContext('2d');

    ctx.drawImage(obj, 0, 0);

    return isBumpMap(cvs, params);
  } else if (isCanvas(obj)) {
    if (!obj.width || !obj.height) {
      return false;
    }
    const ctx = obj.getContext('2d');
    const imgData = ctx.getImageData(0, 0, obj.width, obj.height);

    return isBumpMap(imgData, params);
  } else if (typeof (ImageData) !== 'undefined' && obj instanceof ImageData) {
    if (!obj.width || !obj.height) {
      return false;
    }

    return isBumpMapPixels(obj.data, obj.width, obj.height, params);
  } else if (isThreeTexture(obj)) {
    return isBumpMap(obj.image, params);
  }

  return false;
}

function setTextureInvalid(tex, b) {
  if (!tex) {
    return;
  }

  if (typeof (tex.version) === 'number') {
    if (b) {
      ++tex.version;
    }
  }
  tex.needsUpdate = b;
}

function setTextureParam(tex, key, value) {
  if (!tex) {
    return;
  }
  if (typeof (tex[key]) !== 'undefined') {
    tex[key] = value;
  }
}

function makeTexture(source, params, maxSize, minSize = 1, resizeMethod = METHOD_CEIL_TEXTURE_SIZE) {
  if (isThreeTexture(source)) {
    return source;
  }
  if (isCanvas(source) || isImage(source)) {
    if (typeof (THREE) === 'undefined') {
      return null;
    }
    const tex = new THREE.Texture();

    tex.image = fixTextureSize(source, minSize, maxSize, resizeMethod);
    if (params) {
      for (const v in params) {
        if (params.hasOwnProperty(v)) {
          setTextureParam(tex, v, params[v]);
        }
      }
    }
    setTextureInvalid(tex, true);

    return tex;
  }

  return null;
}
/*
function getTextureSizeValue(tex, key, fallback) {
  if (!tex) {
    return fallback;
  }
  if (isCanvas(tex) || isImage(tex)) {
    let css = null;

    // temporarily reset css value
    // when the css width or height is set for an image,
    // the width and height properties give incorrect values
    if (tex.style) {
      css = tex.style[key];
      tex.style[key] = null;
    }
    const res = tex[key];

    if (tex.style) {
      tex.style[key] = css;
    }

    return res;
  } else if (isThreeTexture(tex)) {
    return getTextureSizeValue(tex.image, key);
  }

  return fallback;
}
*/
function tryValues(...args) {
  const l = args.length;

  if (l === 0) {
    return null;
  }
  for (let i = 0; i < l; ++i) {
    const v = args[i];

    if (v !== null && typeof (v) !== 'undefined') {
      if (typeof (v) !== 'number' || !isNaN(v)) {
        return v;
      }
    }
  }

  return null;
}

export default class ThreeBumpToNormalConverter extends ThreeTextureFilter {
  /**
   * @method isBumpMap
   * @static
   * @description Returns true if an image is a grayscale bumpmap
   * @param {Image|Canvas|Texture} source - Source image
   * @param {Object} params - Optional params object
   *  numChecks {int} - number of pixels to check if grayscale. Optional, default = 10
   * @returns {boolean} - True if the source image is a grayscale bump iimage
   * */
  static isBumpMap(source, params) {
    return isBumpMap(source, params);
  }

  static _getShader() {
    let m = this._shaderMaterial;

    if (!m) {
      m = this._shaderMaterial = new THREE.RawShaderMaterial({
        vertexShader: [
          'precision mediump float;',
          'attribute vec3 position;',
          'attribute vec2 uv;',
          'varying vec2 v_uv;',
          'void main() {',
          ' v_uv = uv;',
          ' gl_Position = vec4(position, 1.0);',
          '}'
        ].join('\n'),
        fragmentShader: [
          'precision mediump float;',
          'varying vec2 v_uv;',
          'uniform sampler2D texture;',
          'uniform vec2 pixelSize;',
          'uniform vec3 normalScale;',
          'uniform float normalIntensity;',

          'void addCrossNormal(vec3 v1, vec3 v2, inout vec3 res) {',
          ' res += normalize(cross(v1, v2));',
          '}',

          'vec3 getDir3(vec2 dir, vec2 uv, float refZ, float intensity) {',
          ' float z = (texture2D(texture, fract(uv + dir * pixelSize)).x - refZ) * intensity;',
          ' return normalize(vec3(dir, z));',
          '}',

          'void main() {',
          ' vec2 uv = v_uv;',
          ' vec4 pix = texture2D(texture, uv);',
          ' float refZ = pix.x;',
          ' float intensity = normalIntensity;',

          ' vec3 vL = getDir3(vec2(-1.0, 0.0), uv, refZ, intensity);',
          ' vec3 vTL = getDir3(vec2(-1.0, -1.0), uv, refZ, intensity);',
          ' vec3 vT = getDir3(vec2(0.0, -1.0), uv, refZ, intensity);',
          ' vec3 vTR = getDir3(vec2(1.0, -1.0), uv, refZ, intensity);',
          ' vec3 vR = getDir3(vec2(1.0, 0.0), uv, refZ, intensity);',
          ' vec3 vBR = getDir3(vec2(1.0, 1.0), uv, refZ, intensity);',
          ' vec3 vB = getDir3(vec2(0.0, 1.0), uv, refZ, intensity);',
          ' vec3 vBL = getDir3(vec2(-1.0, 1.0), uv, refZ, intensity);',

          ' vec3 resNrm = vec3(0.0);',
          ' addCrossNormal(vL, vTL, resNrm);',
          ' addCrossNormal(vTL, vT, resNrm);',
          ' addCrossNormal(vT, vTR, resNrm);',
          ' addCrossNormal(vTR, vR, resNrm);',
          ' addCrossNormal(vR, vBR, resNrm);',
          ' addCrossNormal(vBR, vB, resNrm);',
          ' addCrossNormal(vB,  vBL, resNrm);',
          ' addCrossNormal(vBL, vL, resNrm);',

          ' resNrm /= 8.0;',
          ' resNrm = normalize(resNrm);',

          ' resNrm *= normalScale;',

          ' resNrm = (resNrm + vec3(1.0)) * 0.5;',
          ' gl_FragColor = vec4(resNrm, pix.a);',
          '}'
        ].join('\n')
      });
    }

    return m;
  }

  _getShader() {
    return ThreeBumpToNormalConverter._getShader();
  }

  _getRenderer() {
    let r = this._renderer;

    if (!r) {
      r = this._renderer = new THREE.WebGLRenderer();
    }

    return r;
  }

  _invalidateTexture(tex, b = true) {
    if (!tex || !b) {
      return;
    }
    if (typeof (tex.version) === 'number') {
      ++tex.version;

      return;
    }
    tex.needsUpdate = true;
  }

  _setupTextureFilters(tex) {
    const filter = THREE.LinearFilter;

    // Fix texture filters to prevent seams
    let texNeedsUpdate = false;

    if (tex.minFilter !== filter) {
      tex.minFilter = filter;
      texNeedsUpdate = true;
    }
    if (tex.magFilter !== filter) {
      tex.magFilter = filter;
      texNeedsUpdate = true;
    }
    this._invalidateTexture(tex, texNeedsUpdate);
  }

  // #if DEBUG
  _debugRender(texture, renderer, params, clamp = -1) {
    if (!texture) {
      return;
    }
    const maxSize = getGLMaxTexSize(renderer, clamp);
    let tex = null, w = 0, h = 0, oldMinFilter, oldMagFilter,
      texNeedsUpdate = false;

    if (texture instanceof THREE.WebGLRenderTarget) {
      tex = texture.texture;
      w = texture.width;
      h = texture.height;
      oldMinFilter = tex.minFilter;
      oldMagFilter = tex.magFilter;
    } else {
      tex = makeTexture(texture, params, maxSize, 1, METHOD_CEIL_TEXTURE_SIZE);
      const img = tex.image;

      w = img.width;
      h = img.height;
      oldMinFilter = tex.minFilter;
      oldMagFilter = tex.magFilter;
    }

    this._setupTextureFilters(tex);

    const scene = this.getPlaneScene();
    const camera = this.getCamera();
    const planeMesh = this.getPlaneMesh();

    const shader = this._getShader();

    if (!shader.uniforms) {
      shader.uniforms = {};
    }
    if (shader.uniforms.texture) {
      shader.uniforms.texture.value = tex;
    } else {
      shader.uniforms.texture = new THREE.Uniform(tex);
    }

    if (!shader.uniforms.pixelSize) {
      shader.uniforms.pixelSize = new THREE.Uniform(new THREE.Vector2(0, 0));
    }
    if (!shader.uniforms.pixelSize.value) {
      shader.uniforms.pixelSize.value = new THREE.Vector2(0, 0);
    }
    shader.uniforms.pixelSize.value.x = 1 / w;
    shader.uniforms.pixelSize.value.y = 1 / h;

    if (!shader.uniforms.normalScale) {
      shader.uniforms.normalScale = new THREE.Uniform(new THREE.Vector3(0, 0, 0));
    }
    if (!shader.uniforms.normalScale.value) {
      shader.uniforms.normalScale.value = new THREE.Vector3(0, 0, 0);
    }
    if (!shader.uniforms.normalIntensity) {
      shader.uniforms.normalIntensity = new THREE.Uniform(1);
    }

    const normalScaleVec = shader.uniforms.normalScale.value;
    let normalScaleX = 1;
    let normalScaleY = 1;
    let normalScaleZ = 1;
    let normalIntensity = 1;

    if (params) {
      normalIntensity = tryValues(params.normalIntensity, normalIntensity);
      normalScaleX = tryValues(params.normalScaleX, normalScaleX);
      normalScaleY = tryValues(params.normalScaleY, normalScaleY);
      normalScaleZ = tryValues(params.normalScaleZ, normalScaleZ);
      if (params.normalScale) {
        normalScaleX = tryValues(params.normalScale.x, normalScaleX);
        normalScaleY = tryValues(params.normalScale.y, normalScaleY);
        normalScaleZ = tryValues(params.normalScale.z, normalScaleZ);
      }
    }

    normalScaleVec.x = normalScaleX;// * normalIntensity;
    normalScaleVec.y = normalScaleY;// * normalIntensity;
    normalScaleVec.z = normalScaleZ;

    shader.uniforms.normalIntensity.value = normalIntensity;

    planeMesh.material = shader;

    renderer.render(scene, camera);

    if (tex === texture) {
      if (oldMinFilter && tex.minFilter !== oldMinFilter) {
        tex.minFilter = oldMinFilter;
        texNeedsUpdate = true;
      }
      if (oldMagFilter && tex.magFilter !== oldMagFilter) {
        tex.magFilter = oldMagFilter;
        texNeedsUpdate = true;
      }
      this._invalidateTexture(tex, texNeedsUpdate);
    } else {
      tex.dispose();
    }

  }
  // #endif

  /**
   * @method convertToImageData
   * @description Converts a bumpmap (image/canvas/texture) to a normal map as ImageData
   *  Uses convertToPixels internally
   * @param {Image|Canvas|THREE.Texture} source - source image
   * @param {THREE.WebGLRenderer} renderer - webgl renderer
   * @param {Object} params - optional params
   *  - normalIntensity
   *  - normalScale{x,y,z}: an object containing x, y and z values
   *    example: {x: 1, y: -1, z: 1} inverts the green (y) channel
   * @param {ImageData} result - optional preallocated ImageData as result
   * @returns {ImageData} - ImageData instance
   * */
  convertToImageData(source, renderer, params, result) {
    const width = getTextureWidth(source);
    const height = getTextureHeight(source);

    return this._convertToImageData(source, renderer, params, result, width, height);
  }

  _convertToImageData(source, renderer, params, result, width, height) {
    let res = result;
    let src = source;

    let minTexSize = null, maxTexSize = null, resizeMethod = null;

    if (params) {
      minTexSize = params.minTextureSize;
      maxTexSize = params.maxTextureSize;
      resizeMethod = params.textureResizeMethod;
    }

    const fixedWidth = getFixedTextureSize(width, minTexSize, maxTexSize, resizeMethod);
    const fixedHeight = getFixedTextureSize(height, minTexSize, maxTexSize, resizeMethod);

    if (!res || width !== fixedWidth || height !== fixedHeight) {
      if (typeof (ImageData) === 'undefined') {
        return res;
      }

      res = new ImageData(fixedWidth, fixedHeight);
      // TODO: resize source
      src = resizeImage(src, fixedWidth, fixedHeight);
    }
    const pixels = this._convertToPixels(src, renderer, params, null, width, height);

    if (pixels && res.data && res.data.length === pixels.length) {
      // TODO: make new image data
      res.data.set(pixels);
    }
    let keepOriginalSize = false;
    let flipY = false;

    if (params) {
      keepOriginalSize = params.keepOriginalSize === true;
      if (typeof (params.flipY) === 'boolean') {
        flipY = params.flipY === true;
      }
    }
    if ((keepOriginalSize && fixedWidth !== width || fixedHeight !== height) || flipY) {
      const canvas1 = document.createElement('canvas');
      const ctx1 = canvas1.getContext('2d');

      canvas1.width = fixedWidth;
      canvas1.height = fixedHeight;

      const canvas2 = document.createElement('canvas');
      const ctx2 = canvas2.getContext('2d');

      canvas2.width = width;
      canvas2.height = height;

      ctx1.putImageData(res, 0, 0);
      // ctx2.drawImage(canvas1, 0, 0, width, height);
      if (flipY) {
        ctx2.save();
        ctx2.translate(0, height);
        ctx2.scale(1, -1);
      }
      ctx2.drawImage(canvas1, 0, 0, width, height);
      if (flipY) {
        ctx2.restore();
      }

      res = ctx2.getImageData(0, 0, width, height);
    }

    return res;
  }

  /**
   * @method convertToPixels
   * @description Converts a bumpmap (image/canvas/texture) to a normal map as pixel data
   * @param {Image|Canvas|THREE.Texture} source - source image
   * @param {THREE.WebGLRenderer} renderer - webgl renderer
   * @param {Object} params - optional params
   *  - normalIntensity
   *  - normalScale{x,y,z}: an object containing x, y and z values
   *    example: {x: 1, y: -1, z: 1} inverts the green (y) channel
   * @param {Uint8Array} result - optional preallocated Uint8Array as result
   * @returns {Uint8Array} - RGBA values in a uint8 array
   * */
  convertToPixels(source, renderer, params, result) {
    return this._convertToPixels(source, renderer, params, result);
  }
  _convertToPixels(source, renderer, params, result, texWidth = -1, texHeight = -1) {
    if (!source) {
      return null;
    }
    let r = renderer;

    if (!r) {
      r = this._getRenderer();

    }
    let res = result;

    const rt = this._convertToRenderTarget(source, r, params, texWidth, texHeight);

    if (!rt) {
      return res;
    }
    let width = rt.width;
    let height = rt.height;

    if (typeof (width) === 'undefined' || width === null || isNaN(width)) {
      width = getTextureWidth(source);
    }
    if (typeof (height) === 'undefined' || height === null || isNaN(height)) {
      height = getTextureHeight(source);
    }

    const buffsize = (width * height) << 2;

    if (!res || !(res instanceof Uint8Array) || (res.length !== buffsize)) {
      res = new Uint8Array(buffsize);
    }

    r.readRenderTargetPixels(rt, 0, 0, width, height, res);

    rt.dispose();

    // Probably not needed
    if (rt.texture) {
      rt.texture.dispose();
    }

    return res;
  }

  _convertToRenderTarget(source, renderer, params, texWidth = -1, texHeight = -1) {
    let textureParams = null;
    let maxTexSize = null, minTexSize = null, texResizeMethod = -1;

    if (params) {
      textureParams = params.textureParams;
      maxTexSize = params.maxTextureSize;
      minTexSize = params.minTextureSize;
      texResizeMethod = params.textureResizeMethod;
    }

    maxTexSize = getGLMaxTexSize(renderer, maxTexSize);

    const tex = makeTexture(source, textureParams, maxTexSize, minTexSize, texResizeMethod);

    if (!tex) {
      return null;
    }

    const w = texWidth >= 0 ? texWidth : getTextureWidth(tex, 0);
    const h = texHeight >= 0 ? texHeight : getTextureHeight(tex, 0);

    const rtOptions = {
      wrapS: tex.wrapS,
      wrapT: tex.wrapT,
      magFilter: tex.magFilter,
      minFilter: tex.minFilter,
      format: tex.format,
      type: tex.type,
      anisotropy: tex.anisotropy,
      encoding: tex.encoding,
      depthBuffer: false,
      stencilBuffer: false
    };

    if (textureParams) {
      for (const v in textureParams) {
        if (textureParams.hasOwnProperty(v)) {
          const val = textureParams[v];

          if (typeof (val) !== 'undefined' && val !== null) {
            rtOptions[v] = val;
          }
        }
      }
    }

    this._setupTextureFilters(tex);

    const scene = this.getPlaneScene();
    const camera = this.getCamera();
    const planeMesh = this.getPlaneMesh();

    const shader = this._getShader();

    if (!shader.uniforms) {
      shader.uniforms = {};
    }
    if (shader.uniforms.texture) {
      shader.uniforms.texture.value = tex;
    } else {
      shader.uniforms.texture = new THREE.Uniform(tex);
    }

    if (!shader.uniforms.pixelSize) {
      shader.uniforms.pixelSize = new THREE.Uniform(new THREE.Vector2(0, 0));
    }
    if (!shader.uniforms.pixelSize.value) {
      shader.uniforms.pixelSize.value = new THREE.Vector2(0, 0);
    }
    shader.uniforms.pixelSize.value.x = 1 / w;
    shader.uniforms.pixelSize.value.y = 1 / h;

    if (!shader.uniforms.normalScale) {
      shader.uniforms.normalScale = new THREE.Uniform(new THREE.Vector3(0, 0, 0));
    }
    if (!shader.uniforms.normalScale.value) {
      shader.uniforms.normalScale.value = new THREE.Vector3(0, 0, 0);
    }
    if (!shader.uniforms.normalIntensity) {
      shader.uniforms.normalIntensity = new THREE.Uniform(1);
    }

    const normalScaleVec = shader.uniforms.normalScale.value;
    let normalScaleX = 1;
    let normalScaleY = 1;
    let normalScaleZ = 1;
    let normalIntensity = 1;

    if (params) {
      normalIntensity = tryValues(params.normalIntensity, normalIntensity);
      normalScaleX = tryValues(params.normalScaleX, normalScaleX);
      normalScaleY = tryValues(params.normalScaleY, normalScaleY);
      normalScaleZ = tryValues(params.normalScaleZ, normalScaleZ);
      if (params.normalScale) {
        normalScaleX = tryValues(params.normalScale.x, normalScaleX);
        normalScaleY = tryValues(params.normalScale.y, normalScaleY);
        normalScaleZ = tryValues(params.normalScale.z, normalScaleZ);
      }
    }

    normalScaleVec.x = normalScaleX;// * normalIntensity;
    normalScaleVec.y = normalScaleY;// * normalIntensity;
    normalScaleVec.z = normalScaleZ;

    shader.uniforms.normalIntensity.value = normalIntensity;

    planeMesh.material = shader;

    const renderTarget = new THREE.WebGLRenderTarget(w, h, rtOptions);

    renderer.render(scene, camera, renderTarget);
    // renderer.render(scene, camera);

    if (source === tex) {
      let texNeedsUpdate = false;

      if (tex.minFilter !== rtOptions.minFilter) {
        tex.minFilter = rtOptions.minFilter;
        texNeedsUpdate = true;
      }
      if (tex.magFilter !== rtOptions.magFilter) {
        tex.magFilter = rtOptions.magFilter;
        texNeedsUpdate = true;
      }
      this._invalidateTexture(tex, texNeedsUpdate);
    }

    if (source !== tex) {
      tex.dispose();
    }

    return renderTarget;
  }

  /**
   * @method convertToNormalTexture
   * @description converts a bump map (image/canvas/texture) to a normal map
   * Ignores if the source image is not a bump map
   * @param {Image|Canvas|Texture} source - source image as bump map
   * @param {THREE.WebGLRenderer} renderer - three.js webgl renderer.
   *  Performs the rendering using a shader that converts the bump map to a normal map
   * @param {Object} params - optional params object:
   *  - method {string}: 'readpixels' or 'renderTarget'
   *    - 'readpixels' renders to a renderTarget and uses its pixel data to create a canvas for a new Texture
   *        The used renderTarget is disposed afterwards.
   *        This method is a bit slower but the result is only a texture, not a renderTarget wrapped around a texture
   *
   *    - 'renderTarget' also renders to a renderTarget but returns that renderTarget immediately.
   *        Use renderTarget.texture as the real texture result
   *        This method is faster but you get a renderTarget wrapped around the texture instead of the texture itself,
   *        so it leaves a framebuffer in memory.
   *  - textureParams: texture params (minFilter, magFilter, wrapS, wrapT, ...)
   *  - normalIntensity
   *  - normalScale{x,y,z}: an object containing x, y and z values
   *    example: {x: 1, y: -1, z: 1} inverts the green (y) channel
   * @returns {THREE.Texture|THREE.WebGLRenderTarget} - a texture or renderTarget, depends on the 'method' parameter
   * */
  convertToNormalTexture(source, renderer, params) {
    if (!source) {
      return null;
    }
    let textureParams = null;
    let minTexSize = -1, maxTexSize = -1, texResizeMethod = -1;

    if (params) {
      textureParams = params.textureParams;
      minTexSize = params.minTextureSize;
      maxTexSize = params.maxTextureSize;
      texResizeMethod = params.textureResizeMethod;
    }

    maxTexSize = getGLMaxTexSize(renderer, maxTexSize);
    let tex = null;

    const isWebGLRenderTarget = source instanceof THREE.WebGLRenderTarget;

    let rtWidth = -1;
    let rtHeight = -1;

    if (isWebGLRenderTarget) {
      tex = source.texture;
      rtWidth = source.width;
      rtHeight = source.height;
    } else {
      tex = makeTexture(source, textureParams, maxTexSize, minTexSize, texResizeMethod);
    }


    // We can't check if a WebGLRenderTarget is a bump map
    if (!isWebGLRenderTarget && !isBumpMap(source)) {
      // already a normal map
      return tex;
    }
    if (!renderer) {
      return tex;
    }

    let method = null;

    if (params) {
      method = params.method;
    }
    if (method && typeof (method) === 'string') {
      method = method.toLowerCase();
    }

    if (method === 'readpixels') {
      const canvas = document.createElement('canvas');

      if (isWebGLRenderTarget) {
        if (!tempTextureSize) {
          tempTextureSize = {};
        }
        tempTextureSize.width = source.width;
        tempTextureSize.height = source.height;
      } else {
        tempTextureSize = getTextureSize(tex, tempTextureSize);
      }
      const width = getFixedTextureSize(tempTextureSize.width, minTexSize, maxTexSize, texResizeMethod); // getTextureWidth(tex);
      const height = getFixedTextureSize(tempTextureSize.height, minTexSize, maxTexSize, texResizeMethod); // getTextureHeight(tex);
      const ctx = canvas.getContext('2d');

      canvas.width = width;
      canvas.height = height;

      let imgData = ctx.getImageData(0, 0, width, height);

      imgData = this._convertToImageData(tex, renderer, params, imgData, width, height);

      ctx.putImageData(imgData, 0, 0);

      // Dispose tex if it's a temporary texture
      if (tex !== source) {
        if (!((source instanceof THREE.WebGLRenderTarget) && (source.texture === tex))) {
          tex.dispose();
        }
      }

      return makeTexture(canvas, textureParams, maxTexSize, minTexSize, texResizeMethod);
    }


    const rt = this._convertToRenderTarget(tex, renderer, params, rtWidth, rtHeight);

    if (!rt) {
      return tex;
    }

    if (tex !== source) {
      tex.dispose();
    }

    return rt;

    // Can't use the code below.
    // RenderTarget.dispose() also disposes the texture while we only need to
    // dispose the framebuffer
    /*
    let res = tex;

    if (rt) {
      res = rt.texture;
      rt.dispose();
    }

    if (tex !== source) {
      tex.dispose();
    }
    return res;
    */

  }
}
