import GeometryCloner from '../../bgr/bgr3d/cloners/GeometryCloner';
import VecMat4Math from '../../bgr/bgr3d/math/VecMat4Math';
import Matrix4Math from '../../bgr/bgr3d/math/Matrix4Math';
import Matrix4 from '../../bgr/bgr3d/geom/Matrix4';
import GeomUtils from '../../bgr/bgr3d/utils/GeomUtils';
import GeometryModifier from './GeometryModifier';
import OpenSimplexNoise from 'open-simplex-noise';

const noiseXSeed = 100;
const noiseYSeed = 200;
const noiseZSeed = 300;

const noiserX = new OpenSimplexNoise(noiseXSeed);
const noiserY = new OpenSimplexNoise(noiseYSeed);
const noiserZ = new OpenSimplexNoise(noiseZSeed);

export default class NoiseGeometryModifier extends GeometryModifier {
  constructor(settings = null) {
    super();
    this.settings = settings;
  }

  getModifiedGeometry(sourceGeometry, params = null, matrix = null, resultGeometry = null) {
    return NoiseGeometryModifier.getModifiedGeometry(sourceGeometry, this.settings, params, matrix, resultGeometry);
  }

  static getModifiedGeometry(sourceGeometry, settings = null, params = null, matrix, resultGeometry = null) {
    if (!sourceGeometry) {
      return resultGeometry;
    }
    let resGeom = resultGeometry, recreate = false;
    let resVerts, srcVerts = null;

    srcVerts = sourceGeometry.vertices;
    if (!srcVerts) {
      return resultGeometry;
    }

    if (resGeom) {
      resVerts = resGeom.vertices;

      if (!(resVerts) || !(srcVerts) || (resVerts.length !== srcVerts.length)) {
        recreate = true;
      }
    } else {
      recreate = true;
    }
    if (recreate) {
      resGeom = GeometryCloner.clone(sourceGeometry, params);
      resVerts = resGeom.vertices;
    }
    const numV = srcVerts.length;
    let scale = 1, offX = 0, offY = 0, offZ = 0,
      intensityX = 1,
      intensityY = 1,
      intensityZ = 1;

    if (settings) {
      scale = settings.scale;
      offX = settings.offsetX;
      offY = settings.offsetY;
      offZ = settings.offsetZ;
      intensityX = settings.intensityX;
      intensityY = settings.intensityY;
      intensityZ = settings.intensityZ;
    }
    let m = matrix;
    let im = matrix;

    if (m instanceof Matrix4) {
      im = Matrix4Math.getInverse(m);

      im = im.getElements();
      m = m.getElements();

    }

    for (let i = 0; i < numV; ++i) {
      const srcVert = srcVerts[i];
      const resVert = resVerts[i];

      let nx = 0, ny = 1, nz = 0;

      if (srcVert.attributes && srcVert.attributes.normal) {
        const normal = srcVert.attributes.normal;

        nx = normal.getCoord(0);
        ny = normal.getCoord(1);
        nz = normal.getCoord(2);
      }

      const srcX = srcVert.getCoord(0);
      const srcY = srcVert.getCoord(1);
      const srcZ = srcVert.getCoord(2);

      let tx = srcX;
      let ty = srcY;
      let tz = srcZ;

      if (m) {
        tx = VecMat4Math.transformVectorX(srcX, srcY, srcZ, 1, m);
        ty = VecMat4Math.transformVectorY(srcX, srcY, srcZ, 1, m);
        tz = VecMat4Math.transformVectorZ(srcX, srcY, srcZ, 1, m);

        // Normal to global space
        const tnx = VecMat4Math.transformVectorX(nx, ny, nz, 0, m);
        const tny = VecMat4Math.transformVectorY(nx, ny, nz, 0, m);
        const tnz = VecMat4Math.transformVectorZ(nx, ny, nz, 0, m);

        nx = tnx;
        ny = tny;
        nz = tnz;

        let n = nx * nx + ny * ny + nz * nz;

        if (n !== 0 && n !== 1) {
          n = 1.0 / Math.sqrt(n);
          nx *= n;
          ny *= n;
          nz *= n;
        }
      }

      let dX = noiserX.noise3D(tx * scale + offX, ty * scale + offY, tz * scale + offZ) * intensityX;
      let dY = noiserY.noise3D(tx * scale + offX, ty * scale + offY, tz * scale + offZ) * intensityY;
      let dZ = noiserZ.noise3D(tx * scale + offX, ty * scale + offY, tz * scale + offZ) * intensityZ;

      // Scale noise to 0 when the vertex normal points downward
      /*
      let factorY = -ny;

      factorY = factorY < 0 ? 0 : factorY;
      factorY = factorY > 1 ? 1 : factorY;
      factorY = 1.0 - factorY;

      dY *= factorY;
      */


      if (im) {
        const tdx = VecMat4Math.transformVectorX(dX, dY, dZ, 0, im);
        const tdy = VecMat4Math.transformVectorY(dX, dY, dZ, 0, im);
        const tdz = VecMat4Math.transformVectorZ(dX, dY, dZ, 0, im);

        dX = tdx;
        dY = tdy;
        dZ = tdz;
      }

      const dstX = srcX + dX;
      const dstY = srcY + dY;
      const dstZ = srcZ + dZ;

      resVert.setCoord(0, dstX);
      resVert.setCoord(1, dstY);
      resVert.setCoord(2, dstZ);

    }
    GeomUtils.calculateVertexNormals(resGeom);
    GeomUtils.calculateTangentsForPolygons(resGeom.polygons);

    return resGeom;
  }
}
