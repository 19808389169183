import Animation from './Animation';
import Animator from './Animator';
import AnimEvent from './AnimEvent';

const EVENT_COMPLETE = 'complete';

/**
 * Animation that runs a limited time
 */
export default class TweenAnimation extends Animation {
  /**
   * Constructor
   * @param  {Number} duration = 1 - Tween duration in seconds
   * @param  {Function} animateCallback = null - Animate callback function (animation.onAnimate)
   * @param  {Function} completeCallback = null - Complete callback function (animation.onComplete)
   * @param  {Object} data = null - Optional arbitrary data
   * @param  {Animator} animator = null - Optional animator
   */
  constructor(duration = 1, animateCallback = null, completeCallback = null, data = null, animator = null) {
    super(animateCallback, data);
    this.duration = duration;
    this.time = 0;
    this.onComplete = completeCallback;
    this._animator = animator;
  }

  getAnimator() {
    return this._animator;
  }

  setAnimator(a) {
    if (this._playing) {
      // #if DEBUG
      console.warn('Cannot change the animator while the tween is playing');
      // #endif

      return;
    }
    this._animator = a;
  }

  get animator() {
    return this.getAnimator();
  }

  set animator(a) {
    this.setAnimator(a);
  }

  isPlaying() {
    return this._playing === true;
  }

  get playing() {
    return this.isPlaying();
  }

  _tweenAnimate(event) {
    return;
  }

  _tweenStart() {
    return;
  }

  _tweenPlay(animator) {
    return;
  }

  _tweenComplete(event) {
    return;
  }

  start() {
    this.time = 0;
    this._tweenStart();
    if (this.duration > 0) {
      this.play();
    } else {
      this._complete();
    }
  }

  play() {
    this._playing = true;
    let a = this.getAnimator();

    if (!a) {
      a = TweenAnimation.getDefaultAnimator();
    }
    this._tweenPlay(a);
    a.addAnimation(this);
  }

  stop(animator = null) {
    let a = animator;

    if (!a) {
      a = TweenAnimation.getDefaultAnimator();
    }
    a.removeAnimation(this);
    this._playing = false;
  }

  _complete() {
    const animator = this.getAnimator();
    const onComplete = this.onComplete;

    animator.removeAnimation(this);

    this._playing = false;

    let complEvt = this._completeEvent;

    if (!complEvt) {
      complEvt = new AnimEvent();
    }
    complEvt.animator = animator;
    complEvt.animation = this;
    complEvt.deltaTime = 0;
    complEvt.deltaTimeMs = 0;
    complEvt.type = EVENT_COMPLETE;

    this._tweenComplete(complEvt);

    if (onComplete) {
      onComplete(complEvt);
    }

    this.dispatchEvent(complEvt);
  }


  animate(event) {
    let t = this.time;
    let dt = 1;

    if (event) {
      dt = event.deltaTime;
    }

    t += dt;

    const complete = t >= this.duration;

    if (complete) {
      t = this.duration;
    }

    this.time = t;

    super.animate(event);
    this._tweenAnimate(event);

    if (complete) {
      this._complete();
    }
  }

  static getDefaultAnimator() {
    let a = this._defaultAnimator;

    if (a) {
      return a;
    }
    a = this._defaultAnimator = new Animator();

    return a;
  }
}
