import EventDispatcher from '../events/EventDispatcher';

const EVENT_KEYCHANGE = 'keychange';

export default class KeyListener extends EventDispatcher {
  constructor(target = null, enabled = true) {
    super();
    this.setTarget(target);
    this.setEnabled(enabled);
  }

  getTarget() {
    return this._target;
  }

  setTarget(tgt) {
    if (this._target === tgt) {
      return;
    }
    this._setTargetEnabled(this._target, false);
    this._target = tgt;
    this._setTargetEnabled(tgt, this.isEnabled());
  }

  get target() {
    return this.getTarget();
  }

  set target(tgt) {
    this.setTarget(tgt);
  }

  isEnabled() {
    return this._enabled === true;
  }

  setEnabled(e) {
    if (this._enabled === e) {
      return;
    }
    this._enabled = e;
    this._updateEnabled();
  }

  get enabled() {
    return this.isEnabled();
  }

  set enabled(e) {
    this.setEnabled(e);
  }

  onpress(keycode, b) {
    return;
  }

  onkeydown(evt) {
    return;
  }

  onkeyup(evt) {
    return;
  }

  _updateEnabled() {
    this._setTargetEnabled(this._target, this._enabled);
  }

  _setTargetEnabled(tgt, e) {
    if (!tgt) {
      return;
    }

    let keyDownHandler = this._keyDownHandler;
    let keyUpHandler = this._keyUpHandler;

    if (e) {
      const that = this;

      if (!keyDownHandler) {
        keyDownHandler = this._keyDownHandler = function (evt) {
          return that._handleKeyDown(evt);
        };
      }
      if (!keyUpHandler) {
        keyUpHandler = this._keyUpHandler = function (evt) {
          return that._handleKeyUp(evt);
        };
      }
      tgt.addEventListener('keydown', keyDownHandler);
      tgt.addEventListener('keyup', keyUpHandler);
    } else {
      if (keyDownHandler) {
        tgt.removeEventListener('keydown', keyDownHandler);
      }
      if (keyUpHandler) {
        tgt.removeEventListener('keyup', keyUpHandler);
      }
    }
  }

  dispose() {
    this.setEnabled(false);
    this.setTarget(null);
    this._keyDownMap = null;
    this._keyDownHandler = null;
    this._keyUpHandler = null;
  }

  isKeyDown(code) {
    const map = this._keyDownMap;

    if (!map) {
      return false;
    }

    return map[code] === true;
  }

  _getKeyCode(evt) {
    if (!evt) {
      return -1;
    }
    const k = evt.keyCode;

    return k;
  }

  _setKeyDown(k, down) {
    let b = down;

    if (k < 0) {
      return;
    }
    b = b === true;
    const old = this.isKeyDown(k);

    if (old === b) {
      return;
    }

    let map = this._keyDownMap;

    if (!map && b) {
      map = this._keyDownMap = [];
    }
    if (!map) {
      return;
    }
    map[k] = b;

    if (this.onkeychange) {
      this.onkeychange(k, b);
    }

    if (this.hasEventListener(EVENT_KEYCHANGE)) {
      this.dispatchEvent(this._getKeyChangeEvent(k, b));
    }

  }

  _createKeyEvent(type, keyCode) {
    return {type: type, keyCode: keyCode};
  }

  _getKeyChangeEvent(keyCode = 0, down = false) {
    let res = this._keyChangeEvent;

    if (res) {
      res.type = EVENT_KEYCHANGE;
      res.keyCode = keyCode;
      res.down = down;
    } else {
      res = {
        type: EVENT_KEYCHANGE,
        keyCode: keyCode,
        down: down
      };
    }

    return res;
  }

  _handleKeyUp(evt) {
    const k = this._getKeyCode(evt);

    this._setKeyDown(k, false);
    if (this.onkeyup) {
      this.onkeyup(evt);
    }
    this.dispatchEvent(evt);
  }

  _handleKeyDown(evt) {
    const k = this._getKeyCode(evt);

    this._setKeyDown(k, true);
    if (this.onkeydown) {
      this.onkeydown(evt);
    }
    this.dispatchEvent(evt);
  }
}
KeyListener.EVENT_KEYCHANGE = EVENT_KEYCHANGE;
