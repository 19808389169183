export default class Property {
  constructor(data) {
    this.data = data;
  }

  get(key) {
    const {data} = this;

    if (data) {
      return data[key];
    }

    return null;
  }

  getTarget() {
    return this.get('target');
  }

  getType() {
    return this.get('type');
  }

  getKey() {
    return this.get('key');
  }

  static get(prop, name) {
    if (!prop || !name) {
      return null;
    }
    const {data} = prop;

    if (!data) {
      return null;
    }

    return data[name];
  }

  static getTarget(prop) {
    return this.get(prop, 'target');
  }

  static getKey(prop) {
    return this.get(prop, 'key');
  }

  static getType(prop) {
    return this.get(prop, 'type');
  }
}
