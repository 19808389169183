import EventDispatcher from '../../bgr/common/events/EventDispatcher';

const EVENT_TYPE_UNDO = 'undo';
const EVENT_TYPE_REDO = 'redo';
const EVENT_TYPE_DISPOSE_STATE = 'dispose_state';

class HistoryItem {
  constructor(state = null, previous = null, next = null) {
    this.state = state;
    this.previous = previous;
    this.next = next;
  }
}

export default class HistoryManager extends EventDispatcher {
  constructor(initialState = null, properties = null) {
    super();
    let maxLevels = 0;

    if (properties) {
      if (typeof (properties.maxLevels) === 'number') {
        maxLevels = properties.maxLevels;
      }
    }
    this._currentItem = null;
    this._firstItem = null;
    this._stateIndex = 0;

    this.maxLevels = maxLevels;
    this._eventMap = null;

    this.reset(initialState);
  }

  getCurrentState() {
    const item = this._currentItem;

    if (!item) {
      return null;
    }

    return item.state;
  }

  get currentState() {
    return this.getCurrentState();
  }

  reset(initialState = null) {
    const item = new HistoryItem(initialState);

    this._stateIndex = 0;
    this._firstItem = item;
    this._currentItem = item;
  }

  _getFirstItem() {
    const first = this._firstItem;

    if (first) {
      return first;
    }
    let item = this._currentItem;
    let res = item;

    while (item) {
      if (item) {
        res = item;
      }
      item = item.previous;
    }
    this._firstItem = res;

    return res;
  }

  _setFirstItem(value) {
    this._firstItem = value;
  }

  registerState(state) {
    const currentItem = this._currentItem;
    const item = new HistoryItem(state, currentItem, null);

    if (currentItem.next) {
      this._disposeItem(currentItem.next);
    }
    currentItem.next = item;
    this._currentItem = item;
    this._incrementStateIndex();
  }

  _incrementStateIndex() {
    if (this.maxLevels > 0) {
      ++this._stateIndex;
      if (this._stateIndex > this.maxLevels) {
        this._stateIndex = this.maxLevels;

        const first = this._getFirstItem();

        if (first) {
          const newFirst = first.next;

          this._disposeItem(first.previous);
          first.previous = null;

          if (newFirst) {
            this._disposeItem(newFirst.previous);
            newFirst.previous = null;
            this._setFirstItem(newFirst);
          }
        }
      }
    }
  }

  _disposeItem(item) {
    if (!item) {
      return;
    }
    const state = item.state;

    if (!state) {
      return;
    }
    const evtType = EVENT_TYPE_DISPOSE_STATE;

    if (!this.hasEventListener(evtType)) {
      return;
    }
    const evtObj = this._getEventObject(evtType, true);

    if (!evtObj) {
      return;
    }

    evtObj.type = EVENT_TYPE_DISPOSE_STATE;
    evtObj.state = state;

    this.dispatchEvent(evtObj);
  }

  undoAvailable() {
    const item = this._currentItem;

    if (!item) {
      return false;
    }
    if (item.previous) {
      return true;
    }

    return false;
  }

  redoAvailable() {
    const item = this._currentItem;

    if (!item) {
      return false;
    }
    if (item.next) {
      return true;
    }

    return false;
  }

  undo() {
    const curr = this._currentItem;
    const newCurr = curr.previous;

    if (newCurr) {
      --this._stateIndex;
      if (this._stateIndex < 0) {
        this._stateIndex = 0;
      }

      this._currentItem = newCurr;
      this._dispatchChangeState(EVENT_TYPE_UNDO, newCurr, curr);
    }
  }

  redo() {
    const curr = this._currentItem;
    const newCurr = curr.next;

    if (newCurr) {
      this._incrementStateIndex();

      this._currentItem = newCurr;
      this._dispatchChangeState(EVENT_TYPE_REDO, newCurr, curr);
    }
  }

  _getEventObject(type, create) {
    let map = this._eventMap;

    if (!map) {
      if (!create) {
        return null;
      }
      map = this._eventMap = {};
    }
    let evtObj = map[type];

    if (evtObj || !create) {
      return evtObj;
    }

    if (!evtObj) {
      evtObj = map[type] = {};
    }

    return evtObj;
  }

  _dispatchChangeState(type, newState, oldState) {
    if (!type) {
      return false;
    }
    if (!this.hasEventListener(type)) {
      return false;
    }
    const evtObj = this._getEventObject(type, true);

    if (!evtObj) {
      return false;
    }
    let newSt = newState;
    let oldSt = oldState;

    if (newSt instanceof HistoryItem) {
      newSt = newSt.state;
    }
    if (oldSt instanceof HistoryItem) {
      oldSt = oldSt.state;
    }
    evtObj.type = type;
    evtObj.oldState = oldSt;
    evtObj.newState = newSt;

    return this.dispatchEvent(evtObj);
  }
}
