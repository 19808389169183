import BD3DNodeType from './BD3DNodeType';

const panel = new BD3DNodeType('panel');
const topPanel = new BD3DNodeType('topPanel', panel);
const bottomPanel = new BD3DNodeType('bottomPanel', panel);

const mirrorPanel = new BD3DNodeType('mirrorPanel');
const topMirrorPanel = new BD3DNodeType('topMirrorPanel', mirrorPanel);
const bottomMirrorPanel = new BD3DNodeType('bottomMirrorPanel', mirrorPanel);

const borderComponent = new BD3DNodeType('borderComponent');
const piping = new BD3DNodeType('piping', borderComponent);
const tape = new BD3DNodeType('tape', borderComponent);
const borderFabric = new BD3DNodeType('borderFabric', borderComponent);
const borderRibbon = new BD3DNodeType('borderRibbon', borderComponent);
const border3d = new BD3DNodeType('border3d', borderComponent);
const zipper = new BD3DNodeType('zipper', borderComponent);

const handle = new BD3DNodeType('handle');
const verticalHandle = new BD3DNodeType('verticalHandle', handle);
const horizontalHandle = new BD3DNodeType('horizontalHandle', handle);
const suitcaseHandle = new BD3DNodeType('suitcaseHandle', handle);
const pocketHandle = new BD3DNodeType('pocketHandle', handle);

const BD3DNodeTypes = {
  panel, topPanel, bottomPanel,
  mirrorPanel, topMirrorPanel, bottomMirrorPanel,
  borderComponent, piping, tape, borderFabric, borderRibbon, border3d, zipper,
  handle, verticalHandle, horizontalHandle, suitcaseHandle, pocketHandle
};

export default BD3DNodeTypes;
