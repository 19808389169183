import ThreeTextureFilter from '../three/ThreeTextureFilter';
import CanvasUtils from '../utils/CanvasUtils';
import * as THREE from 'three';

const _1 = 1, _2 = 2, _4 = 4, _8 = 8, _16 = 16, _32 = 32;

function toPO2(value, roundMethod) {
  let v = value;

  if (v & (v - 1) === 0) {
    return v;
  }

  v |= v >> _1;
  v |= v >> _2;
  v |= v >> _4;
  v |= v >> _8;
  v |= v >> _16;
  v |= v >> _32;
  ++v;

  if (roundMethod === 2) {
    return v;
  }
  const floored = v >> 1;

  if (roundMethod === 0) {
    return floored;
  }

  const d1 = value - floored;
  const d2 = v - value;

  return d1 > d2 ? v : floored;
}

function toPO2Max(value, roundMethod, max = -1) {
  const v = toPO2(value, roundMethod);

  if (max >= 0 && v > max) {
    return max;
  }

  return v;
}

/**
* @class QuiltBumpBlurThree
* @description Blurs away the sharp corners in a smooth quilt texture
*  (Uses the quilt pixel values for blur scale, dark pixels have less blur than lighter pixels)
*/
export default class QuiltBumpBlurThree extends ThreeTextureFilter {

  static dispose() {
    const blurShaderMat = this._blurShaderMaterial;

    if (blurShaderMat) {
      blurShaderMat.dispose();
    }

    this._blurShaderMaterial = null;
  }

  static _getBlurShaderMaterial(create = true) {
    let res = this._blurShaderMaterial;

    if (res || !create) {
      return res;
    }
    res = this._blurShaderMaterial = new THREE.RawShaderMaterial({
      vertexShader: [
        'precision mediump float;',
        'attribute vec3 position;',
        'varying vec2 v_uv;',
        'void main() {',
        ' v_uv = 0.5 * (position.xy + vec2(1.0));',
        ' gl_Position = vec4(position, 1.0);',
        '}'
      ].join('\n'),

      fragmentShader: [
        '#define ITERATIONS 10',
        'precision mediump float;',
        'varying vec2 v_uv;',
        'uniform sampler2D texture;',
        'uniform float imageSize;',
        'uniform vec2 direction;',
        // 'uniform float scale;',
        'uniform vec2 scale;',
        'void main() {',
        ' vec4 src = vec4(0.0);',
        ' vec2 uv = v_uv;',
        ' float sc = scale.x + (scale.y - scale.x) * texture2D(texture, uv).x;',

        ' float pixelSize = 1.0 / imageSize;',
        ' vec2 dir = normalize(direction);',
        ' vec2 inc = dir * pixelSize * sc;',
        ' vec2 off = -dir * pixelSize * sc * float(ITERATIONS) * 0.5;',
        ' for (int i = 0; i < ITERATIONS; ++i) {',
        '   src += texture2D(texture, fract(uv + off));',
        '   off += inc;',
        ' }',
        ' src /= float(ITERATIONS);',
        ' gl_FragColor = src;',
        '}'
      ].join('\n')
    });

    return res;
  }

  _getBlurShaderMaterial(create = true) {
    return QuiltBumpBlurThree._getBlurShaderMaterial(create);
  }

  _newBlurRenderTarget(w, h, filter) {
    const res = new THREE.WebGLRenderTarget();
    let update = false;

    if (res.texture.minFilter !== filter) {
      res.texture.minFilter = filter;
      update = true;
    }
    if (res.texture.magFilter !== filter) {
      res.texture.magFilter = filter;
      update = true;
    }
    res.setSize(w, h);
    if (update) {
      res.texture.needsUpdate = true;
    }

    return res;
  }

  renderBlur(source, renderer, minBlurScale = 0, maxBlurScale = 1, numPasses = 1, renderTarget = null, params = null) {
    let srcTex, tex, w, h, texNeedsUpdate = false, oldMinFilter, oldMagFilter;

    if (source instanceof THREE.Texture) {
      tex = source;
      srcTex = source;
      w = tex.image.width;
      h = tex.image.height;
      oldMinFilter = tex.minFilter;
      oldMagFilter = tex.magFilter;
    } else if (source instanceof THREE.WebGLRenderTarget) {
      srcTex = source;
      tex = source.texture;
      oldMinFilter = tex.minFilter;
      oldMagFilter = tex.magFilter;
      w = source.width;
      h = source.height;
    } else if (CanvasUtils.isCanvas(source) || CanvasUtils.isImage(source)) {
      tex = new THREE.Texture(source);
      srcTex = tex;
      texNeedsUpdate = true;
      w = source.width;
      h = source.height;
    }
    if (!tex) {
      return;
    }
    const blurShaderMat = this._getBlurShaderMaterial();
    const scene = this.getPlaneScene();
    const mesh = this.getPlaneMesh();
    const cam = this.getCamera();

    if (!blurShaderMat.uniforms) {
      blurShaderMat.uniforms = {};
    }
    if (!blurShaderMat.uniforms.texture) {
      blurShaderMat.uniforms.texture = new THREE.Uniform();
    }

    if (!blurShaderMat.uniforms.imageSize) {
      blurShaderMat.uniforms.imageSize = new THREE.Uniform();
    }
    if (!blurShaderMat.uniforms.direction) {
      blurShaderMat.uniforms.direction = new THREE.Uniform();
    }
    if (!blurShaderMat.uniforms.direction.value) {
      blurShaderMat.uniforms.direction.value = new THREE.Vector2();
    }
    const dir = blurShaderMat.uniforms.direction.value;
    /*
    let sc = 1;
    if (params) {
      if (typeof (params.blurScale) === 'number' && !isNaN(params.blurScale)) {
        sc = params.blurScale;
      }
    }
    */
    // this.setMaterialUniformValue(blurShaderMat, 'scale', sc);
    let scaleVec = this.getMaterialUniformValue(blurShaderMat, 'scale');

    if (!scaleVec) {
      scaleVec = new THREE.Vector2(0, 0);
      this.setMaterialUniformValue(blurShaderMat, 'scale', scaleVec);
    }
    scaleVec.x = minBlurScale;
    scaleVec.y = maxBlurScale;

    const filter = THREE.LinearFilter;
    const gl = renderer.context;

    let maxSize = 2048;

    maxSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);
    maxSize = toPO2(maxSize);

    w = toPO2Max(w, maxSize);
    h = toPO2Max(h, maxSize);

    if (tex.minFilter !== filter) {
      tex.minFilter = filter;
      texNeedsUpdate = true;
    }
    if (tex.magFilter !== filter) {
      tex.magFilter = filter;
      texNeedsUpdate = true;
    }

    if (texNeedsUpdate && tex.image) {
      tex.needsUpdate = true;
    }

    mesh.material = blurShaderMat;

    // blurShaderMat.uniforms.imageSize.value = Math.max(w, h);
    const numP = numPasses;

    if (numP > 1) {
      const totalPasses = numP << 1;
      const rt1 = this._newBlurRenderTarget(w, h, filter);
      const rt2 = this._newBlurRenderTarget(w, h, filter);

      let texIn = tex;
      let texOut = rt1;

      const imgSizeUniform = blurShaderMat.uniforms.imageSize;

      dir.set(1, 0);
      imgSizeUniform.value = w;
      let vblur = false;
      const lastPassIndex = totalPasses - 1;

      for (let i = 0; i < totalPasses; ++i) {
        const secondHalf = i >= numP;

        if (vblur !== secondHalf) {
          vblur = secondHalf;
          dir.set(0, 1);
          imgSizeUniform.value = h;
        }
        this.setMaterialUniformTexture(blurShaderMat, 'texture', texIn);
        if (i === lastPassIndex) {
          texOut = renderTarget;
        }
        renderer.render(scene, cam, texOut);
        if (texOut === rt1) {
          texIn = rt1;
          texOut = rt2;
        } else {
          texIn = rt2;
          texOut = rt1;
        }
      }
      rt1.dispose();
      rt2.dispose();
    } else {
      const blurX = this._newBlurRenderTarget(w, h, filter);

      dir.set(1, 0);
      blurShaderMat.uniforms.imageSize.value = w;

      this.setMaterialUniformTexture(blurShaderMat, 'texture', tex);
      renderer.render(scene, cam, blurX);

      dir.set(0, 1);
      blurShaderMat.uniforms.imageSize.value = h;
      this.setMaterialUniformTexture(blurShaderMat, 'texture', blurX);
      renderer.render(scene, cam, renderTarget);

      blurX.dispose();
    }

    if (srcTex === source) {
      texNeedsUpdate = false;
      if (oldMinFilter && oldMinFilter !== tex.minFilter) {
        tex.minFilter = oldMinFilter;
        texNeedsUpdate = true;
      }
      if (oldMagFilter && oldMagFilter !== tex.magFilter) {
        tex.magFilter = oldMagFilter;
        texNeedsUpdate = true;
      }
      if (texNeedsUpdate && tex.image) {
        tex.needsUpdate = true;
      }
    } else {
      srcTex.dispose();
    }

  }

  getBlurredTexture(source, renderer, minBlurScale = 0, maxBlurScale = 1, numPasses = 1, params = null) {
    let w = 0, h = 0;

    if (source instanceof THREE.Texture) {
      w = source.image.width;
      h = source.image.height;
    } else if (source instanceof THREE.WebGLRenderTarget) {
      w = source.width;
      h = source.height;
    } else if (CanvasUtils.isCanvas(source) || CanvasUtils.isImage(source)) {
      w = source.width;
      h = source.height;
    }

    const res = new THREE.WebGLRenderTarget();

    res.setSize(w, h);
    this.renderBlur(source, renderer, minBlurScale, maxBlurScale, numPasses, res, params);

    if (params) {
      let outputType = params.outputType;

      if (typeof (outputType) === 'string') {
        outputType = outputType.toLowerCase();
        if (outputType === 'texture' || outputType === 'canvas') {
          const canvas = this.convertRenderTargetToCanvas(renderer, res, params.result, params);

          // No need for render target anymore
          res.dispose();

          if (outputType === 'canvas') {
            return canvas;
          }
          const tex = new THREE.Texture();

          tex.image = canvas;
          tex.needsUpdate = true;

          return tex;
          // read render target pixels, store them to
        }
      }
    }

    return res;
  }
}
