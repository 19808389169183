export default class Polygon {
  constructor(vertices) {
    this.vertices = vertices;
    this.userData = null;
  }

  getVertex(index) {
    if (index < 0) {
      return null;
    }
    const verts = this.vertices;

    return verts[index];
  }

  getNumVertices() {
    const verts = this.vertices;

    if (!verts) {
      return 0;
    }

    return verts.length;
  }

  get numVertices() {
    return this.getNumVertices();
  }
}
