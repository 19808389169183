import BD3DContainerNode3D from './BD3DContainerNode3D';
import Node3DCloner from '../../bgr/bgr3d/cloners/Node3DCloner';
import Node3DMaterialUtils from '../material/Node3DMaterialUtils';

// 3D Container node using an Asset
export default class AssetNode3D extends BD3DContainerNode3D {
  constructor(asset = null, transform = null) {
    super(null, transform);
    this.asset = asset;
    this.node3D = null;
    this.material = null;
    this.metaData = null;

    this.setAssetEnabled(asset);
  }

  getMaterial() {
    return this.material;
  }

  setMaterial(m, update = true) {
    this.material = m;
    if (update) {
      this.updateMaterial();
    }
  }

  updateMaterial() {
    const assignMaterialParams = null;

    Node3DMaterialUtils.assignMaterial(this, this.material, assignMaterialParams);
  }

  setAsset(asset) {
    const old = this.asset;

    if (old === asset) {
      return;
    }
    this.asset = asset;
    this.setAssetEnabled(old, false);
    this.setAssetEnabled(asset, true);
  }

  _handleDataChanged(evt) {
    this.node3D = null;
    this.removeChildren();

    const node = this.getNode3D();

    this.addChild(node);
  }

  getDataChangedHandler(createIfNull) {
    let handler = this._dataChangedHandler;

    if (handler || !createIfNull) {
      return handler;
    }
    handler = (evt, data) => {
      return this._handleDataChanged(evt, data);
    };
    this._dataChangedHandler = handler;

    return handler;
  }

  setAssetEnabled(asset, enabled = true) {
    if (!asset) {
      return;
    }
    const eventDispatcher = asset.getEventDispatcher ? asset.getEventDispatcher() : null;

    if (eventDispatcher) {
      if (enabled) {
        const dataChangedHandler = this.getDataChangedHandler(true);

        eventDispatcher.addEventListener('data_changed', dataChangedHandler);
      } else {
        const dataChangedHandler = this.getDataChangedHandler(false);

        eventDispatcher.removeEventListener('data_changed', dataChangedHandler);
      }
    }
  }

  getSourceNode3D() {
    return this.asset && this.asset.getNode3D ? this.asset.getNode3D() : null;
  }

  getNode3D() {
    let {node3D} = this;

    if (node3D) {
      return node3D;
    }
    const src = this.getSourceNode3D();

    if (!src) {
      return null;
    }
    const params = null;

    node3D = Node3DCloner.clone(src, params);

    this.node3D = node3D;

    return node3D;
  }
}
