import Geometry from '../../bgr/bgr3d/geom/Geometry';
import Vertex from '../../bgr/bgr3d/geom/Vertex';
import Vector2 from '../../bgr/bgr3d/geom/Vector2';
import BorderContourUtil from '../geom/BorderContourUtil';
import Node3D from '../../bgr/bgr3d/scenegraph/Node3D';
import GeometryNode3D from '../../bgr/bgr3d/scenegraph/GeometryNode3D';
import ContainerNode3D from '../../bgr/bgr3d/scenegraph/ContainerNode3D';
import GeomUtils from '../../bgr/bgr3d/utils/GeomUtils';
import BoundingBoxUtils from '../../bgr/bgr3d/utils/BoundingBoxUtils';
import MattressDA from '../mattress/MattressDA';
import BorderShape from '../geom/BorderShape';
import Node3DCloner from '../../bgr/bgr3d/cloners/Node3DCloner';
import SRTTransform3D from '../../bgr/bgr3d/transform/SRTTransform3D';
import Matrix4Transform3D from '../../bgr/bgr3d/transform/Matrix4Transform3D';
import RotationOrder from '../../bgr/bgr3d/geom/RotationOrder';
import Matrix4Math from '../../bgr/bgr3d/math/Matrix4Math';
import Matrix4 from '../../bgr/bgr3d/geom/Matrix4';
import MattressGeomUtils from '../geom/MattressGeomUtils';
import ImageAsset from '../asset/ImageAsset';
import SampleAsset from '../asset/SampleAsset';
import HandleStyle from './HandleStyle';
import HandleStyles from './HandleStyles';

const _0 = 0; const _1 = 1; const _2 = 2; // const _3 = 3;
const _4 = 4; const _5 = 5; const _6 = 6; // const _7 = 7;
const _8 = 8; const _9 = 9; const _10 = 10; // const _11 = 11;
const _12 = 12; const _13 = 13; const _14 = 14; // const _15 = 15;


const DEFAULT_ALIGN_Y = 0;

const DEFORM_FALSE = 0;
const DEFORM_TRUE = 1;
const DEFORM_AUTO = 2;

const HandleDeformMode = {
  DEFORM_FALSE: DEFORM_FALSE,
  DEFORM_TRUE: DEFORM_TRUE,
  DEFORM_AUTO: DEFORM_AUTO
};

const HandlePlacementCount = {
  PLACEMENT_AUTO: -1,
  PLACEMENT_NONE: 0,
  PLACEMENT_ONE: 1,
  PLACEMENT_TWO: 2
};

let tempBoundingBox, tempBorderContourData, tempBorderShape, tempBorderShapeTransform;

class InternalUtils {
  static getHandleBoundingBox(handleNode, params) {
    let handleBBox = null;

    if (params) {
      handleBBox = params.handleBoundingBox;
    }
    if (!handleBBox) {
      tempBoundingBox = BoundingBoxUtils.getBoundingBox(handleNode, null, tempBoundingBox);
      handleBBox = tempBoundingBox;
    }

    return tempBoundingBox;
  }

  static getMattressBorderShape(params) {
    let bs = params.borderShape;

    if (bs) {
      return bs;
    }
    if (!tempBorderShape) {
      tempBorderShape = new BorderShape();
    }
    bs = params.borderShape = tempBorderShape;
    const md = params.mattressData;

    return this.borderShapeFromMattressData(md, bs);
  }

  static borderShapeFromMattressData(mattressData, borderShape = null) {
    const md = mattressData;
    const bs = borderShape ? borderShape : new BorderShape();

    const topBorderRadius = MattressDA.getResultTopBorderRadius(md);
    const bottomBorderRadius = MattressDA.getResultBottomBorderRadius(md);
    const borderLength = MattressDA.getTotalBorderComponentHeight(md);
    const topHeight = MattressDA.getTopHeight(md);
    const bottomHeight = MattressDA.getBottomHeight(md);

    bs.setData(topBorderRadius, bottomBorderRadius, borderLength, topHeight, bottomHeight, 0);

    return bs;
  }

  static handleWillBeDeformedByBorderComponent(handleNode, x, z, attachParams) {
    let mattressData, borderNode, borderShape, // borderType, borderData,
      borderTopOffset = 0, borderBottomOffset = 0;

    if (attachParams) {
      mattressData = attachParams.mattressData;
      borderNode = attachParams.borderComponentNode;
      // borderType = attachParams.borderComponentType;
      // borderData = attachParams.borderComponentData;
      borderShape = attachParams.borderShape;
    }

    /*
    if (borderType && borderType.hasDeformShape(borderData, mattressData)) {
      // return true;
    }
    */

    if (borderNode && borderNode.userData) {
      const ud = borderNode.userData;

      borderTopOffset = ud.borderTopOffset;
      borderBottomOffset = ud.borderBottomOffset;
    }

    // const handleBBox = this.getHandleBoundingBox(handleNode, attachParams);

    if (!borderShape) {
      if (!tempBorderShape) {
        tempBorderShape = new BorderShape();
      }
      borderShape = tempBorderShape;
      this.borderShapeFromMattressData(mattressData, borderShape);
    }

    tempBorderShapeTransform = borderShape.getTransformAtLength(borderTopOffset, tempBorderShapeTransform);
    const locationNameTop = tempBorderShapeTransform.locationName;

    tempBorderShapeTransform = borderShape.getTransformAtLength(borderBottomOffset, tempBorderShapeTransform);
    const locationNameBottom = tempBorderShapeTransform.locationName;

    return !(locationNameTop === 'middle' && locationNameBottom === 'middle');
  }

  static handleWillBeDeformedByContour(handleNode, x, z, attachParams) {
    let mattressData = null, contourPos = 0;
    /*
    borderNode,
    borderTopOffset = 0, borderBottomOffset = 0, borderShape;
    */

    if (attachParams) {
      mattressData = attachParams.mattressData;
    }
    // const handleBBox = getHandleBoundingBox.call(this, handleNode, attachParams);

    tempBorderContourData = BorderContourUtil.getClosestBorderContourInfoOfMattress(x, 0, z, mattressData, tempBorderContourData);
    contourPos = tempBorderContourData.length;
    const contourPosLeft = contourPos + tempBoundingBox.minx;
    const contourPosRight = contourPos + tempBoundingBox.maxx;

    tempBorderContourData = BorderContourUtil.getBorderContourInfoOfMattressAtLength(contourPosLeft, mattressData, null, tempBorderContourData);
    const edgeLeft = tempBorderContourData.edge;
    const onCornerLeft = tempBorderContourData.onCornerLeft;

    tempBorderContourData = BorderContourUtil.getBorderContourInfoOfMattressAtLength(contourPosRight, mattressData, null, tempBorderContourData);
    const edgeRight = tempBorderContourData.edge;
    const onCornerRight = tempBorderContourData.onCornerRight;

    return edgeLeft !== edgeRight || onCornerLeft || onCornerRight;
  }

  static handleWillBeDeformed(handleNode, x, z, attachParams) {
    const d1 = this.handleWillBeDeformedByContour(handleNode, x, z, attachParams);
    const d2 = this.handleWillBeDeformedByBorderComponent(handleNode, x, z, attachParams);

    return d1 || d2;
    /*
    return handleWillBeDeformedByContour.call(this, handleNode, x, z, attachParams) ||
      handleWillBeDeformedByBorderComponent.call(this, handleNode, x, z, attachParams);
    */
  }

  static attachHandleVertexToBorder(vertex, params) {
    /*
    const srcX = vertex.getCoord(0);
    const srcY = vertex.getCoord(1);
    const srcZ = vertex.getCoord(2);
    */

    let defaultAlignY = params.alignY;

    if (typeof (defaultAlignY) !== 'number' || isNaN(defaultAlignY)) {
      defaultAlignY = DEFAULT_ALIGN_Y;
    }
    const contourPositionLength = params.contourPositionLength;
    const handleBoundingBox = params.handleBoundingBox;
    const mattressData = params.mattressData;
    const borderComponentNode = params.borderComponentNode;
    const borderComponentType = params.borderComponentType;
    const borderComponentData = params.borderComponentData;
    const getVertexAlignY = params.getVertexAlignY;

    const borderComponentGeom = borderComponentNode.getGeometry ? borderComponentNode.getGeometry() : borderComponentNode.geometry;
    const geomUVData = MattressGeomUtils.getGeometryUVWorldTransform(borderComponentGeom);
    const uvTranslateV = geomUVData ? geomUVData.translateV : 0;
    const uvTranslateU = geomUVData ? geomUVData.translateU : 0;

    let handleHeight = params.handleHeight;

    if (handleHeight === null || handleHeight === undefined) {
      handleHeight = handleBoundingBox.maxy - handleBoundingBox.miny;
    }
    let vertUVMap = params.vertexUVLookup;
    let borderScale = params.borderScale;
    let borderUVScale = params.borderUVScale;
    let borderHeight = params.borderHeight;
    let borderTop = params.borderTop;
    let borderBottom = params.borderBottom;

    if (borderHeight === undefined || borderHeight === null) {
      borderHeight = borderComponentType.getHeight(borderComponentData, mattressData);
      params.borderHeight = borderHeight;
    }

    if (borderTop === undefined || borderTop === null) {
      borderTop = -borderComponentType.getTop(borderComponentData, mattressData);
      params.borderTop = borderTop;
    }
    if (borderBottom === undefined || borderBottom === null) {
      borderBottom = -borderComponentType.getBottom(borderComponentData, mattressData);
      params.borderBottom = borderBottom;
    }

    if (borderScale === null || borderScale === undefined) {
      borderScale = (handleHeight === 0) ? 0 : (borderHeight / handleHeight);
      borderUVScale = borderScale;
      if (borderScale > 1) {
        borderScale = 1;
      }
      if (typeof (params.minScaleY) === 'number' && !isNaN(params.minScaleY)) {
        borderScale = borderScale < params.minScaleY ? params.minScaleY : borderScale;
      }
      params.borderScale = borderScale;
      params.borderUVScale = borderUVScale;
    }

    if (!vertUVMap) {
      vertUVMap = params.vertexUVLookup = new WeakMap();
    }
    const bs = this.getMattressBorderShape(params);

    const borderBottomOffset = borderComponentNode.userData.borderBottomOffset;
    const borderTopOffset = borderComponentNode.userData.borderTopOffset;

    let alignY = defaultAlignY;

    if (getVertexAlignY) {
      alignY = getVertexAlignY(vertex, params);
    }

    let x = vertex.getCoord(0);
    let y = vertex.getCoord(1);
    let z = vertex.getCoord(2);

    y -= handleBoundingBox.miny;
    y *= borderScale;

    let handleV = y;

    handleV += borderBottom;
    handleV *= -1;

    const handleVTop = -(y + borderTop - handleHeight * borderScale);

    handleV = handleV + (handleVTop - handleV) * alignY;

    const uv = new Vector2(x + contourPositionLength + uvTranslateU, handleV + uvTranslateV);

    vertUVMap.set(vertex, uv);

    let localY = y + borderBottom;
    const localYTop = y + borderTop - handleHeight * borderScale;

    localY = localY + (localYTop - localY) * alignY;
    const offX = borderComponentType.getShapeXByY(localY, borderComponentData, mattressData);

    z -= (offX);

    const borderOffsetBottom = borderBottomOffset - y;
    const borderOffsetTop = borderTopOffset + handleHeight * borderScale - y;
    const borderOffset = borderOffsetBottom + (borderOffsetTop - borderOffsetBottom) * alignY;

    tempBorderShapeTransform = bs.getTransformAtLength(borderOffset, tempBorderShapeTransform);
    if (tempBorderShapeTransform && tempBorderShapeTransform.valid) {
      const borderSideMatrix = tempBorderShapeTransform.matrix4;
      const m = borderSideMatrix._matrix;

      y = 0;
      const X = -z;
      const Y = y;
      const Z = x;

      const tx = X * m[0] + Y * m[_4] + Z * m[_8] + m[_12];
      const ty = X * m[1] + Y * m[_5] + Z * m[_9] + m[_13];
      const tz = X * m[2] + Y * m[_6] + Z * m[_10] + m[_14];

      x = tz;
      y = ty;
      z = -tx;
    }

    tempBorderContourData = BorderContourUtil.getBorderContourInfoOfMattressAtLength(contourPositionLength + x, mattressData, null, tempBorderContourData);

    // x -= 80;
    if (tempBorderContourData && tempBorderContourData.valid) {
      const contourMatrix = tempBorderContourData.matrix4;

      x = 0;

      const m = contourMatrix._matrix;
      const tx = x * m[0] + y * m[_4] + z * m[_8] + m[_12];
      const ty = x * m[1] + y * m[_5] + z * m[_9] + m[_13];
      const tz = x * m[2] + y * m[_6] + z * m[_10] + m[_14];

      x = tx;
      y = ty;
      z = tz;
    }

    vertex.setCoord(0, x);
    vertex.setCoord(1, y);
    vertex.setCoord(2, z);
  }

  static attachHandleToBorder(handleNode, x, z, attachParams) {
    let deformMode = false, mattressData,
      borderComponentData, borderComponentType, borderComponentNode,
      getVertexAlignY, minScaleY,
      defaultAlignY = DEFAULT_ALIGN_Y;

    if (attachParams) {
      mattressData = attachParams.mattressData;
      borderComponentData = attachParams.borderComponentData;
      borderComponentType = attachParams.borderComponentType;
      borderComponentNode = attachParams.borderComponentNode;
      defaultAlignY = attachParams.alignY;
      deformMode = attachParams.deformMode;
      getVertexAlignY = attachParams.getVertexAlignY;
      minScaleY = attachParams.minScaleY;
    }

    if (typeof (defaultAlignY) !== 'number' || isNaN(defaultAlignY)) {
      defaultAlignY = DEFAULT_ALIGN_Y;
    }
    let deform = true;

    if (deformMode === DEFORM_TRUE) {
      deform = true;
    } else if (deformMode === DEFORM_FALSE) {
      deform = false;
    } else {
      deform = this.handleWillBeDeformed(handleNode, x, z, attachParams);
    }

    // Find out the location on the border contour
    tempBorderContourData = BorderContourUtil.getClosestBorderContourInfoOfMattress(x, 0, z, mattressData, tempBorderContourData);
    const contourPosLength = tempBorderContourData.length;

    const handleBoundingBox = this.getHandleBoundingBox(handleNode, attachParams);

    const vertexAttachParams = {
      alignY: defaultAlignY,
      mattressData: mattressData,
      minScaleY: minScaleY,
      getVertexAlignY: getVertexAlignY,
      contourPositionLength: contourPosLength,
      handleBoundingBox: handleBoundingBox,
      borderComponentData: borderComponentData,
      borderComponentNode: borderComponentNode,
      borderComponentType: borderComponentType
    };

    const that = this;
    const cloneParams = {
      cloneGeometry: deform,
      vertexAttachParams: vertexAttachParams,
      onClonedPolygonVertex(src, res, par) {
        let v = res;

        if (res instanceof Vertex) {
          v = res.position;
          if (!v) {
            v = res;
          }
        }
        if (par && par.vertexAttachParams && par.vertexAttachParams.vertexUVLookup) {
          const uv = par.vertexAttachParams.vertexUVLookup.get(v);

          if (uv && (res instanceof Vertex)) {
            let atts = res.attributes;

            if (!atts) {
              atts = res.attributes = {};
            }
            const oldUV = src.attributes.uv;

            // res.attributes.staticUV = new Vector2(oldUV.getCoord(0), oldUV.getCoord(1));
            res.attributes.staticUV = oldUV;
            res.attributes.uv = uv;
          }
        }
      },
      onBeforeCloneGeometry(src, res, par) {
        par.vertexAttachParams.geometry = src;
        par.vertexAttachParams.resultGeometry = res;
      },
      onClonedVertex(src, res, par) {
        that.attachHandleVertexToBorder(res, par.vertexAttachParams);
      },
      onClonedGeometry(src, res, par) {
        GeomUtils.calculateVertexNormals(res);
        GeomUtils.calculateTangentsForPolygons(res.polygons);
        // debugger;
      }
    };
    const clone = Node3DCloner.clone(handleNode, cloneParams);

    if (!deform) {
      // No vertex deform, use simple matrix tranform
      //
      let transf = new SRTTransform3D();

      transf.position.setCoord(0, x);
      transf.position.setCoord(1, 0);
      transf.position.setCoord(2, z);

      transf.rotation.setCoord(1, -tempBorderContourData.angle + Math.PI * 0.5);

      const deg45 = 0.25;

      transf.rotation.setCoord(0, Math.PI * deg45);
      transf.setRotationOrder(RotationOrder.YXZ);

      // test
      const bbminx = handleBoundingBox.minx;
      const bbminy = handleBoundingBox.miny;
      // const bbminz = handleBoundingBox.minz;

      const bbmaxx = handleBoundingBox.maxx;
      const bbmaxy = handleBoundingBox.maxy;
      // const bbmaxz = handleBoundingBox.maxz;

      const bbwidth = bbmaxx - bbminx;
      const bbheight = bbmaxy - bbminy;
      // const bblength = bbmaxz - bbminz;

      let mtx = new Matrix4();

      mtx = Matrix4Math.identity(mtx);
      // center x & y
      mtx = Matrix4Math.translateXYZ(mtx,
        -bbminx - bbwidth * 0.5, -bbminy - bbheight * defaultAlignY, 0,
        mtx);

      // transform to local border shape
      const bTop = borderComponentType.getTop(borderComponentData, mattressData);
      const bBottom = borderComponentType.getBottom(borderComponentData, mattressData);
      const localXOff = borderComponentType.getShapeXByY(bTop + (bBottom - bTop) * defaultAlignY, borderComponentData, mattressData);

      mtx = Matrix4Math.translateXYZ(mtx, 0, 0, -localXOff, mtx);

      // transform to global border shape
      const bTopOff = borderComponentNode.userData.borderTopOffset;
      const bBottomOff = borderComponentNode.userData.borderBottomOffset;

      const bs = this.getMattressBorderShape(vertexAttachParams);

      // tempBorderShapeTransform = bs.getTransformAtLength(bTopOff + (bBottomOff - bTopOff) * defaultAlignY, tempBorderShapeTransform);
      tempBorderShapeTransform = bs.getTransformAtLength(bBottomOff + (bTopOff - bBottomOff) * defaultAlignY, tempBorderShapeTransform);

      if (tempBorderShapeTransform.valid && tempBorderShapeTransform.matrix4) {
        const borderShapeMtx = tempBorderShapeTransform.matrix4;

        // The border shape matrix rotates about the local z axis,
        // so we need to temporarily rotate the matrix 90 degrees about the y-axis,
        // transform with the border shape matrix and reverse rotate 90 degrees
        // to make the border rotate about the x-axis
        mtx = Matrix4Math.yRotate(-Math.PI * 0.5, mtx, mtx);

        mtx = Matrix4Math.multiply(borderShapeMtx, mtx, mtx);

        mtx = Matrix4Math.yRotate(Math.PI * 0.5, mtx, mtx);
      }

      // transform to border contour
      mtx = Matrix4Math.multiply(tempBorderContourData.matrix4, mtx, mtx);
      transf = new Matrix4Transform3D(mtx);
      // debugger;

      clone.transform = transf;
    }

    return clone;
  }
}

export default class HandleUtils {
  /**
   * @method largeEnoughForNumHandles
   * @static
   * @description Checks whether a mattress is large enough for placing one or two handles per side
   * @param {object} handleData - Config handle data
   * @param {boolean} atFront - true: check for front & back handles, false: check for left / right side handles
   * @param {number} size - the size of the mattress (use width when atFront is true, length if atFront is false)
   * @param {number} innerSize - the size of the mattress minus the corner radii (use inner width when atFront is true, inner length if atFront is false)
   * @param {number} handleSize - the width of the handle object
   * @param {number} MIN_SIZE - minimum mattress size
   * @param {number} MIN_INNER_SIZE - minimum inner mattress size
   * @param {object} result - preallocated result object (will be returned if not null)
   * @returns {object} - object containing
   *    largeEnoughFor1Handle: if true, the mattress is large enough for placing 1 handle
   *    largeEnoughFor2Handles: if true, the mattress is large enough for placing 2 handles
   * */
  static largeEnoughForNumHandles(handleData, atFront, size, innerSize, handleSize, MIN_SIZE, MIN_INNER_SIZE, result) {
    const res = result || {};
    let largeEnoughFor1Handle = false;
    let largeEnoughFor2Handles = false;

    const placementCount = HandleUtils.getHandlesPlacementCount(handleData, atFront);
    const maxHandles = MattressDA.getMaxHandles(handleData, atFront);

    if (placementCount === HandleUtils.HandlePlacementCount.PLACEMENT_NONE) {
      largeEnoughFor1Handle = false;
      largeEnoughFor2Handles = false;
    } else if (placementCount === HandleUtils.HandlePlacementCount.PLACEMENT_ONE) {
      largeEnoughFor1Handle = true;
      largeEnoughFor2Handles = false;
    } else if (placementCount === HandleUtils.HandlePlacementCount.PLACEMENT_TWO) {
      largeEnoughFor1Handle = true;
      largeEnoughFor2Handles = true;
    } else {
      largeEnoughFor1Handle = innerSize >= handleSize;
      largeEnoughFor2Handles = size > MIN_SIZE && innerSize > MIN_INNER_SIZE;
    }

    if (typeof (maxHandles) === 'number' && !isNaN(maxHandles) && maxHandles >= 0) {
      if (maxHandles < 2) {
        largeEnoughFor2Handles = false;
      }
      if (maxHandles < 1) {
        largeEnoughFor2Handles = false;
        largeEnoughFor1Handle = false;
      }
    }

    res.largeEnoughFor1Handle = largeEnoughFor1Handle;
    res.largeEnoughFor2Handles = largeEnoughFor2Handles;

    return res;
  }
  /**
  * @method getHandlesPlacementCount
  * @static
  * @description Returns the number of handles that should be placed
  * @param {Object} handleData: config data of the handle
  * @param {boolean} atFront: true = front & back, false = left / right side
  * @returns
  *   -1 or PLACEMENT_AUTO (default): Number of handles based on the size of the mattress
  *   0 or PLACEMENT_NONE: No handles
  *   1 or PLACEMENT_NONE: One handle
  *   2 or PLACEMENT_NONE: Two handles
  */
  static getHandlesPlacementCount(handleData, atFront) {
    if (!handleData) {
      return null;
    }

    return (atFront ? handleData.placementCountFront : handleData.placementCountSide);
  }

  static setHandlesPlacementCount(handleData, value, atFront) {
    if (!handleData) {
      return;
    }

    if (atFront !== false) {
      handleData.placementCountFront = value;
    }

    if (atFront !== true) {
      handleData.placementCountSide = value;
    }
  }

  static attachHandleToBorderNode(handleNode, x, y, z, attachParams) {
    return InternalUtils.attachHandleToBorder(handleNode, x, z, attachParams);
  }

  static mapHandleVertexToBorder(vert, params, matrix) {
    let x = vert.getCoord(0);
    let y = vert.getCoord(1);
    let z = vert.getCoord(2);

    let handleBounds = null, borderScale = 1, borderBottom, contourPosition,
      vertUVMap, borderType, borderData, mattressData, borderBottomOffset,
      borderShape, borderSideTransformInfo = null, borderContourInfo = null;

    if (params) {
      handleBounds = params.handleBounds;
      borderScale = params.borderScale;
    }

    y -= handleBounds.miny;
    y *= borderScale;

    let pocketV = y;

    pocketV += borderBottom;
    pocketV *= -1;

    const uv = new Vector2(x + contourPosition, pocketV);

    vertUVMap.set(vert, uv);

    const localY = y + borderBottom;
    const offX = borderType.getShapeXByY(localY, borderData, mattressData);

    z -= (offX);

    const borderOffset = borderBottomOffset - y;

    borderSideTransformInfo = borderShape.getTransformAtLength(borderOffset, borderSideTransformInfo);

    if (borderSideTransformInfo && borderSideTransformInfo.valid) {
      const borderSideMatrix = borderSideTransformInfo.matrix4;
      const m = borderSideMatrix._matrix;

      y = 0;
      const X = -z;
      const Y = y;
      const Z = x;

      const tx = X * m[0] + Y * m[_4] + Z * m[_8] + m[_12];
      const ty = X * m[1] + Y * m[_5] + Z * m[_9] + m[_13];
      const tz = X * m[2] + Y * m[_6] + Z * m[_10] + m[_14];

      x = tz;
      y = ty;
      z = -tx;
    }

    borderContourInfo = BorderContourUtil.getBorderContourInfoOfMattressAtLength(contourPosition + x, mattressData, null, borderContourInfo);

    const xOffset = 80;

    x -= xOffset;

    if (borderContourInfo && borderContourInfo.valid) {
      const contourMatrix = borderContourInfo.matrix4;

      x = 0;

      const m = contourMatrix._matrix;
      const tx = x * m[_0] + y * m[_4] + z * m[_8] + m[_12];
      const ty = x * m[_1] + y * m[_5] + z * m[_9] + m[_13];
      const tz = x * m[_2] + y * m[_6] + z * m[_10] + m[_14];

      x = tx;
      y = ty;
      z = tz;
    }

    vert.setCoord(0, x);
    vert.setCoord(1, y);
    vert.setCoord(2, z);
  }

  static mapHandleToBorder(value, data, matrix) {
    if (value instanceof Geometry) {
      let verts = value.vertices;

      if (!verts) {
        return;
      }

      let pocketBounds, borderTop, borderBottom, borderType, borderData, mattressData, borderShape,
        borderContourInfo, borderSideTransformInfo, contourPosition = 0, borderNode, borderTopOffset, borderBottomOffset;

      if (data) {
        borderTopOffset = data.borderTopOffset;
        borderBottomOffset = data.borderBottomOffset;

        borderNode = data.borderNode;
        if (borderNode) {
          if (typeof (borderTopOffset) === 'undefined') {
            borderTopOffset = borderNode.geometry.userData.borderTopOffset;
          }
          if (typeof (borderBottomOffset) === 'undefined') {
            borderBottomOffset = borderNode.geometry.userData.borderBottomOffset;
          }
        }
        contourPosition = data.contourPosition;
        pocketBounds = data.pocketBounds;
        borderTop = data.borderTop;
        borderBottom = data.borderBottom;
        borderType = data.borderComponentType;
        borderData = data.borderComponentData;
        mattressData = data.mattressData;
        borderShape = data.borderShape;
        borderContourInfo = data.borderContourInfo;
        borderSideTransformInfo = data.borderSideTransformInfo;
      }
      const borderHeight = borderTop - borderBottom;

      let numVerts = verts.length;

      const pocketHeight = pocketBounds.maxy - pocketBounds.miny;

      let borderScale = (pocketHeight === 0) ? 0 : (borderHeight / pocketHeight);

      if (borderScale > 1) {
        borderScale = 1;
      }

      const vertUVMap = new WeakMap();

      for (let i = 0; i < numVerts; ++i) {
        const vert = verts[i];

        let x = vert.getCoord(0);
        let y = vert.getCoord(1);
        let z = vert.getCoord(2);

        y -= pocketBounds.miny;
        y *= borderScale;

        let pocketV = y;

        pocketV += borderBottom;
        pocketV *= -1;

        const uv = new Vector2(x + contourPosition, pocketV);

        vertUVMap.set(vert, uv);

        const localY = y + borderBottom;
        const offX = borderType.getShapeXByY(localY, borderData, mattressData);

        z -= (offX);

        const borderOffset = borderBottomOffset - y;

        borderSideTransformInfo = borderShape.getTransformAtLength(borderOffset, borderSideTransformInfo);
        if (borderSideTransformInfo && borderSideTransformInfo.valid) {
          const borderSideMatrix = borderSideTransformInfo.matrix4;
          const m = borderSideMatrix._matrix;

          y = 0;
          const X = -z;
          const Y = y;
          const Z = x;

          const tx = X * m[0] + Y * m[_4] + Z * m[_8] + m[_12];
          const ty = X * m[1] + Y * m[_5] + Z * m[_9] + m[_13];
          const tz = X * m[2] + Y * m[_6] + Z * m[_10] + m[_14];

          x = tz;
          y = ty;
          z = -tx;
        }

        borderContourInfo = BorderContourUtil.getBorderContourInfoOfMattressAtLength(contourPosition + x, mattressData, null, borderContourInfo);

        const xOffset = 80;

        x -= xOffset;

        if (borderContourInfo && borderContourInfo.valid) {
          const contourMatrix = borderContourInfo.matrix4;

          x = 0;

          const m = contourMatrix._matrix;
          const tx = x * m[_0] + y * m[_4] + z * m[_8] + m[_12];
          const ty = x * m[_1] + y * m[_5] + z * m[_9] + m[_13];
          const tz = x * m[_2] + y * m[_6] + z * m[_10] + m[_14];

          x = tx;
          y = ty;
          z = tz;
        }

        vert.setCoord(0, x);
        vert.setCoord(1, y);
        vert.setCoord(2, z);
      }

      // Assign new uvs based on absolute vertex coordinates
      verts = value.polygonVertices;

      numVerts = verts.length;
      for (let i = 0; i < numVerts; ++i) {
        const polyVert = verts[i];

        if (polyVert) {
          const vert = polyVert.position;
          const uv = vertUVMap.get(vert);

          if (!polyVert.attributes) {
            polyVert.attributes = {};
          }
          // Store old UV in staticUV attribute
          // const oldUV = polyVert.attributes.uv;

          // polyVert.attributes.staticUV = new Vector2(oldUV.getCoord(0), oldUV.getCoord(1));
          polyVert.attributes.uv = uv;
        }
      }
      if (data) {
        data.borderContourInfo = borderContourInfo;
      }
      // BGR3D.GeomUtils.flipPolygons(value);

      GeomUtils.calculateVertexNormals(value);

    } else if (value instanceof Node3D) {
      // TODO: transform + multiply with parent matrix
      // const parentMatrix = matrix;
      const curMatrix = matrix;

      if (value instanceof GeometryNode3D) {
        this.mapHandleToBorder(value.geometry, data, curMatrix);
      } else if (value instanceof ContainerNode3D) {
        const children = value.getChildren();
        const numChildren = children.length;

        for (let i = 0; i < numChildren; ++i) {
          const child = children[i];

          this.mapHandleToBorder(child, data, curMatrix);
        }
      }
    }
  }

  static getHandleParams(handle) {
    return handle && handle.params;
  }

  static getLogoHandleImageData(handle) {
    const handleParams = this.getHandleParams(handle);

    if (handleParams) {
      const img = handleParams.image || handleParams.img;

      if (img) {
        return img;
      }
    }

    return handle.image || handle.img;
  }

  static getLogoHandleImageURL(handle) {
    const imgDta = this.getLogoHandleImageData(handle);

    if (typeof (imgDta) === 'string') {
      return imgDta;
    }

    return imgDta && (imgDta.image || imgDta.img || imgDta.source || imgDta.src);
  }

  static getLogoHandleImageID(handle) {
    const imgDta = this.getLogoHandleImageData(handle);

    if (imgDta && typeof (imgDta) === 'object') {
      const {id} = imgDta;

      if (id) {
        return id;
      }
    }

    return null;
    // return getHandleImageURL(handle);
  }

  static getLogoHandleImageSize(handle) {
    const imgData = this.getLogoHandleImageData(handle);
    const imgSize = imgData && imgData.size;

    if (imgSize) {
      return imgSize;
    }

    const params = this.getHandleParams(handle);

    return params && params.size;
  }

  static getLogoHandleImageAlign(handle) {
    const imgData = this.getLogoHandleImageData(handle);
    const imgSize = imgData && imgData.align;

    if (imgSize) {
      return imgSize;
    }

    const params = this.getHandleParams(handle);

    return params && params.align;
  }

  static getLogoHandleImageLocalAlign(handle) {
    const imgData = this.getLogoHandleImageData(handle);
    const imgSize = imgData && imgData.localAlign;

    if (imgSize) {
      return imgSize;
    }

    const params = this.getHandleParams(handle);

    return params && params.localAlign;
  }

  static addLogoHandleImageAssets(data, assetManager, params = null, array = null) {
    if (!assetManager) {
      return array;
    }
    let arr = array;
    const imgURL = this.getLogoHandleImageURL(data);
    const imgID = this.getLogoHandleImageID(data);
    let logoFabric = null;

    logoFabric = imgID && assetManager.assetCollections.samples.getAssetByName(imgID);
    if (!logoFabric) {
      logoFabric = imgID && assetManager.assetCollections.temporary.getAssetByName(imgID);
    }
    if (!logoFabric) {
      const id = imgURL || imgID;

      logoFabric = imgURL && assetManager.assetCollections.temporary.getAssetByName(id);
    }
    if (!logoFabric && imgID) {
      const imgData = this.getLogoHandleImageData(data);

      logoFabric = new SampleAsset(imgID, imgID, imgData, imgData);
      assetManager.assetCollections.samples.addAsset(logoFabric, imgID);
    }
    if (!logoFabric && imgURL) {
      const id = imgURL || imgID;

      logoFabric = new ImageAsset(id);
      assetManager.setAssetURL(logoFabric, imgURL);
      assetManager.assetCollections.temporary.addAsset(logoFabric, id);
    }

    if (!logoFabric) {
      logoFabric = assetManager.assetCollections.commonTextures.getAssetByName('bd.logo.fabric');
    }
    const logoFabricBg = assetManager.assetCollections.commonTextures.getAssetByName('bd.logo.fabric.background');

    arr = assetManager.addAssetToArray(logoFabric, arr, params);
    arr = assetManager.addAssetToArray(logoFabricBg, arr, params);

    return arr;
  }

  static getLogoHandleImageOffset(handleData) {
    const imageData = this.getLogoHandleImageData(handleData);

    return imageData && (imageData.offset || imageData.translation || imageData.translate || imageData.position || imageData.location);
  }

  static setupLogoHandleMaterial(handleData, mat, assetCollections, objectAsset) {
    // mat.setImageSize(10, 10);
    const size = this.getLogoHandleImageSize(handleData);
    const offset = this.getLogoHandleImageOffset(handleData);
    const imgAlign = this.getLogoHandleImageAlign(handleData);
    const imgLocalAlign = this.getLogoHandleImageLocalAlign(handleData);
    const imageURL = this.getLogoHandleImageURL(handleData);
    const imageID = this.getLogoHandleImageID(handleData);

    let width = 0;
    let height = 0;

    const objectMeta = objectAsset && objectAsset.metaData;
    const objectSize = objectMeta && (objectMeta.objectSize || objectMeta.size);

    let logoFabric = imageID && assetCollections.samples.getAssetByName(imageID);

    if (!logoFabric) {
      logoFabric = imageID ? assetCollections.temporary.getAssetByName(imageID) : null;
      if (!logoFabric) {
        logoFabric = imageURL ? assetCollections.temporary.getAssetByName(imageURL) : null;
      }
    }
    if (logoFabric instanceof SampleAsset) {
      width = logoFabric.getWidth();
      height = logoFabric.getHeight();
      logoFabric = logoFabric.getTexture();
    }

    if (size) {
      width = width || (size.x || size.width);
      height = height || (size.y || size.height);
    }

    const offsetX = (offset && offset.x) || 0;
    const offsetY = (offset && offset.y) || 0;

    let alignX = (imgAlign && imgAlign.x);
    let alignY = (imgAlign && imgAlign.y);
    let localAlignX = (imgLocalAlign && imgLocalAlign.x);
    let localAlignY = (imgLocalAlign && imgLocalAlign.y);

    if (typeof (alignX) !== 'number' || isNaN(alignX)) {
      alignX = 0.5;
    }
    if (typeof (alignY) !== 'number' || isNaN(alignY)) {
      alignY = 0.5;
    }
    if (typeof (localAlignX) !== 'number' || isNaN(localAlignX)) {
      localAlignX = 0.5;
    }
    if (typeof (localAlignY) !== 'number' || isNaN(localAlignY)) {
      localAlignY = 0.5;
    }

    if (!logoFabric) {
      logoFabric = assetCollections.commonTextures.getAssetByName('bd.logo.fabric');
    }
    const logoFabricBg = assetCollections.commonTextures.getAssetByName('bd.logo.fabric.background');

    if (width > 0 && height > 0) {
      mat.setImageSize(width, height);
    }
    mat.setImageOffset(offsetX, offsetY);
    mat.setFabricTexture(logoFabricBg);
    mat.setImageTexture(logoFabric);
    mat.setImageAlignment(alignX, alignY);
    mat.setImageLocalAlignment(localAlignX, localAlignY);
    if (objectSize) {
      mat.setObjectSize(objectSize[0], objectSize[1], objectSize[2]);
    }
  }

  static isHandleActive(handleData) {
    if (!handleData) {
      return false;
    }
    let handleStyle = handleData;

    if (handleStyle instanceof HandleStyle) {
      return handleStyle.active;
    }
    if (typeof handleStyle === 'object') {
      handleStyle = handleStyle.type;
    }
    if (typeof handleStyle === 'string') {
      handleStyle = HandleStyles.get(handleStyle);
    }
    if (handleStyle instanceof HandleStyle) {
      return handleStyle.active;
    }

    return false;
  }
}
HandleUtils.HandleDeformMode = HandleDeformMode;
HandleUtils.HandlePlacementCount = HandlePlacementCount;
