const HEX = 16;
const _3 = 3;
const _4 = 4;
const _10 = 10;

const Rshift = 16;
const Gshift = 8;
const Bshift = 0;

const MASK8 = 0xFF;

function isNumericArray(v) {
  if (!v) {
    return false;
  }
  if (v instanceof Array || (Array.isArray && Array.isArray(v))) {
    return true;
  }
  const TYPE_UNDEFINED = 'undefined';

  if ((typeof (Float32Array) !== TYPE_UNDEFINED) && (v instanceof Float32Array)) {
    return true;
  }
  if ((typeof (Float64Array) !== TYPE_UNDEFINED) && (v instanceof Float64Array)) {
    return true;
  }
  if ((typeof (Uint8ClampedArray) !== TYPE_UNDEFINED) && (v instanceof Uint8ClampedArray)) {
    return true;
  }
  if ((typeof (Uint8Array) !== TYPE_UNDEFINED) && (v instanceof Uint8Array)) {
    return true;
  }
  if ((typeof (Int8Array) !== TYPE_UNDEFINED) && (v instanceof Int8Array)) {
    return true;
  }
  if ((typeof (Uint16Array) !== TYPE_UNDEFINED) && (v instanceof Uint16Array)) {
    return true;
  }
  if ((typeof (Int16Array) !== TYPE_UNDEFINED) && (v instanceof Int16Array)) {
    return true;
  }
  if ((typeof (Uint32Array) !== TYPE_UNDEFINED) && (v instanceof Uint32Array)) {
    return true;
  }
  if ((typeof (Int32Array) !== TYPE_UNDEFINED) && (v instanceof Int32Array)) {
    return true;
  }

  return false;
}

function rgbaArrayMap(value, index, arr) {
  if (index < _3) {
    return parseInt(value, _10);
  }

  return parseFloat(value);
}

function anyValue(...args) {
  const l = args.length;

  for (let i = 0; i < l; ++i) {
    const a = args[i];
    const t = typeof (a);

    if (t !== 'undefined' && a !== null) {
      if (t === 'number') {
        if (!isNaN(a)) {
          return a;
        }
      } else {
        return a;
      }
    }
  }

  return null;
}

let tempValues = null;
const hexPrefixes = [
  '#000000',
  '#00000',
  '#0000',
  '#000',
  '#00',
  '#0',
  '#'
];

function clampMinColor(r, g, b, clampMin, res) {
  let R = r;
  let G = g;
  let B = b;

  const min = Math.min(R, G, B);

  if (min < clampMin) {
    const diff = clampMin - min;

    R += diff;
    G += diff;
    B += diff;

    const max = Math.max(R, G, B);

    if (max > 1) {
      let t;

      t = (R - clampMin) / (max - clampMin);
      t = t > 1 ? 1 : t;
      t = t < 0 ? 0 : t;
      R = clampMin + (1 - clampMin) * t;

      t = (G - clampMin) / (max - clampMin);
      t = t > 1 ? 1 : t;
      t = t < 0 ? 0 : t;
      G = clampMin + (1 - clampMin) * t;

      t = (B - clampMin) / (max - clampMin);
      t = t > 1 ? 1 : t;
      t = t < 0 ? 0 : t;
      B = clampMin + (1 - clampMin) * t;
    }
  }

  if (res !== null && typeof (res) === 'object') {
    if (isNumericArray(res)) {
      res[0] = R;
      res[1] = G;
      res[2] = B;

      return res;
    }
    if (typeof (res.x) === 'number') {
      res.x = R;
      res.y = G;
      res.z = B;
    } else {
      res.r = R;
      res.g = G;
      res.b = B;
    }

    return res;
  }


  R = Math.round(R * MASK8);
  G = Math.round(G * MASK8);
  B = Math.round(B * MASK8);

  return (R << Rshift) | (G << Gshift) | B;
}

export default class ColorUtils {
  /**
   * @method clampMinColor
   * @static
   * @description clamps a color at a minimum value
   * @param {Number} R - red value between 0 and 1
   * @param {Number} G - green value between 0 and 1
   * @param {Number} B - blue value between 0 and 1
   * @param {Number} min - minimum color value between 0 and 1
   * @param {Object} result - optional result object (Array, Float32Array or Object)
   * @return {uint|Object|Array} - If no result specified, a uint will be returned
   * */
  static clampMinColor(R, G, B, min, result = null) {
    return clampMinColor(R, G, B, min, result);
  }

  static colorToHexString(value) {
    const i = this.colorToInt(value);
    let h = i.toString(HEX);

    h = h.toUpperCase();
    const l = h.length;
    const pfx = hexPrefixes[l];

    if (pfx) {
      h = pfx + h;
    }

    return h;
  }

  static colorToRGBAString(value) {
    tempValues = this.colorToRGBA(value, tempValues);
    const R = tempValues[0];
    const G = tempValues[1];
    const B = tempValues[2];
    const A = tempValues[_3] / MASK8;

    return `rgba(${R},${G},${B},${A})`;
  }

  static colorToRGBString(value) {
    tempValues = this.colorToRGBA(value, tempValues);
    const R = tempValues[0];
    const G = tempValues[1];
    const B = tempValues[2];

    return `rgb(${R},${G},${B})`;
  }

  static colorToInt(value, fallback = 0) {
    if (typeof (value) === 'number') {
      return value;
    }
    tempValues = this.colorToRGBA(value, tempValues);
    const R = tempValues[0];
    const G = tempValues[1];
    const B = tempValues[2];

    return (R << Rshift) | (G << Gshift) | (B << Bshift);
  }

  static colorToRGBA(value, result = null) {
    let val = value;

    if (result && result.length > _3) {
      result[0] = 0;
      result[1] = 0;
      result[2] = 0;
      result[_3] = 0;
    }
    let res = result;

    if (!res) {
      if (typeof (Uint8Array) !== 'undefined') {
        res = new Uint8Array(_4);
      }
    }

    if (typeof (val) === 'number') {
      if (isNaN(val)) {
        return result;
      }
      res[0] = (val >> Rshift) & MASK8;
      res[1] = (val >> Gshift) & MASK8;
      res[2] = (val >> Bshift) & MASK8;
      res[_3] = 255;

      return res;
    }

    if (!val) {
      return res;
    }

    if (isNumericArray(val)) {
      res[0] = anyValue(val[0], 0);
      res[1] = anyValue(val[1], 0);
      res[2] = anyValue(val[2], 0);
      res[_3] = anyValue(val[_3], MASK8);

      return res;
    }

    if (typeof (val) === 'object') {
      res[0] = anyValue(val.R, val.r, val.X, val.x, 0);
      res[1] = anyValue(val.G, val.g, val.Y, val.y, 0);
      res[2] = anyValue(val.B, val.b, val.Z, val.z, 0);
      res[_3] = anyValue(val.A, val.a, val.W, val.w, MASK8);

      return res;
    }
    let R = 0, G = 0, B = 0, A = 255;

    if (typeof (val) !== 'string') {
      res[0] = R;
      res[1] = G;
      res[2] = B;
      res[_3] = A;

      return res;
    }
    if (val.trim) {
      val = val.trim();
    }

    if (val.charAt(0) === '#') {
      val = val.substring(1, val.length);

      const i = parseInt(val, HEX);

      R = (i >> Rshift) & MASK8;
      G = (i >> Gshift) & MASK8;
      B = (i >> Bshift) & MASK8;

    } else if (val.indexOf('rgb', 0) === 0) {
      const bIdx = val.indexOf('(');

      if (bIdx < 0) {
        return res;
      }
      let eIdx = val.indexOf(')', bIdx);

      if (eIdx < 0) {
        eIdx = val.length;
      }
      let paramString = val.substring(bIdx + 1, eIdx);

      while (paramString.indexOf(' ') >= 0) {
        paramString = paramString.replace(' ', '');
      }
      let paramArr = paramString.split(',');

      paramArr = paramArr.map(rgbaArrayMap);

      R = paramArr[0] & MASK8;
      G = paramArr[1] & MASK8;
      B = paramArr[2] & MASK8;

      if (paramArr.length > _3) {
        A = (Math.round(paramArr[_3] * MASK8)) & MASK8;
      }
    }

    res[0] = R;
    res[1] = G;
    res[2] = B;
    res[_3] = A;

    return res;
  }
}
