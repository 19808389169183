import VecMat4Math from '../../bgr/bgr3d/math/VecMat4Math';

// var MattressDA = BD3D.MattressDA;

// TODO: combine getDistTO..Plane functions into one?
function getDistToXYPlane(x, y, z, dx, dy, dz, pz, minx, miny, maxx, maxy) {
  if (dz === 0) {
    return null;
  }
  const t = (pz - z) / dz;

  if (minx === null && miny === null && maxx === null && maxy === null) {
    return t;
  }
  const X = x + dx * t;
  const Y = y + dy * t;

  if (minx !== null && X < minx) {
    return null;
  }
  if (miny !== null && Y < miny) {
    return null;
  }
  if (maxx !== null && X > maxx) {
    return null;
  }
  if (maxy !== null && Y > maxy) {
    return null;
  }

  return t;
}

function getDistToXZPlane(x, y, z, dx, dy, dz, py, minx, minz, maxx, maxz) {
  if (dy === 0) {
    return null;
  }
  const t = (py - y) / dy;

  if (minx === null && minz === null && maxx === null && maxz === null) {
    return t;
  }
  const X = x + dx * t;
  const Z = z + dz * t;

  if (minx !== null && X < minx) {
    return null;
  }
  if (minz !== null && Z < minz) {
    return null;
  }
  if (maxx !== null && X > maxx) {
    return null;
  }
  if (maxz !== null && Z > maxz) {
    return null;
  }

  return t;
}

function getDistToYZPlane(x, y, z, dx, dy, dz, px, miny, minz, maxy, maxz) {
  if (dx === 0) {
    return null;
  }
  const t = (px - x) / dx;

  if (miny === null && minz === null && maxy === null && maxz === null) {
    return t;
  }
  const Y = y + dy * t;
  const Z = z + dz * t;

  if (miny !== null && Y < miny) {
    return null;
  }
  if (minz !== null && Z < minz) {
    return null;
  }
  if (maxy !== null && Y > maxy) {
    return null;
  }
  if (maxz !== null && Z > maxz) {
    return null;
  }

  return t;
}

export function getDistToAABB(x, y, z, dx, dy, dz, minx, miny, minz, maxx, maxy, maxz, params) {
  let res = null, t;

  t = getDistToXZPlane(x, y, z, dx, dy, dz, maxy, minx, minz, maxx, maxz);
  if (t !== null && (res === null || t < res)) {
    res = t;
  }
  t = getDistToXZPlane(x, y, z, dx, dy, dz, miny, minx, minz, maxx, maxz);
  if (t !== null && (res === null || t < res)) {
    res = t;
  }

  t = getDistToYZPlane(x, y, z, dx, dy, dz, maxx, miny, minz, maxy, maxz);
  if (t !== null && (res === null || t < res)) {
    res = t;
  }
  t = getDistToYZPlane(x, y, z, dx, dy, dz, minx, miny, minz, maxy, maxz);
  if (t !== null && (res === null || t < res)) {
    res = t;
  }

  t = getDistToXYPlane(x, y, z, dx, dy, dz, maxz, minx, miny, maxx, maxy);
  if (t !== null && (res === null || t < res)) {
    res = t;
  }
  t = getDistToXYPlane(x, y, z, dx, dy, dz, minz, minx, miny, maxx, maxy);
  if (t !== null && (res === null || t < res)) {
    res = t;
  }

  return res;
}

function getDistToAABBMargin(x, y, z, dx, dy, dz, minx, miny, minz, maxx, maxy, maxz, params) {
  let margin = 0;

  if (params) {
    margin = params.margin || 0;
  }
  const newMinX = minx - margin;
  const newMinY = miny - margin;
  const newMinZ = minz - margin;
  const newMaxX = maxx + margin;
  const newMaxY = maxy + margin;
  const newMaxZ = maxz + margin;

  return getDistToAABB(x, y, z, dx, dy, dz, newMinX, newMinY, newMinZ, newMaxX, newMaxY, newMaxZ, params);
}

function getDistToAABBTransformed(x, y, z, dx, dy, dz, minx, miny, minz, maxx, maxy, maxz, params, matrix) {
  const m = matrix;
  let tx = x, ty = y, tz = z, tdx = dx, tdy = dy, tdz = dz;

  if (m) {
    tx = VecMat4Math.transformVectorX(x, y, z, 1, m);
    ty = VecMat4Math.transformVectorY(x, y, z, 1, m);
    tz = VecMat4Math.transformVectorZ(x, y, z, 1, m);

    tdx = VecMat4Math.transformVectorX(dx, dy, dz, 0, m);
    tdy = VecMat4Math.transformVectorY(dx, dy, dz, 0, m);
    tdz = VecMat4Math.transformVectorZ(dx, dy, dz, 0, m);

    /*
    tx = x * m[0] + y * m[4] + z * m[8] + m[12];
    ty = x * m[1] + y * m[5] + z * m[9] + m[13];
    tz = x * m[2] + y * m[6] + z * m[10] + m[14];

    tdx = dx * m[0] + dy * m[4] + dz * m[8];
    tdy = dx * m[1] + dy * m[5] + dz * m[9];
    tdz = dx * m[2] + dy * m[6] + dz * m[10];
    */
  }

  return getDistToAABBMargin(tx, ty, tz, tdx, tdy, tdz, minx, miny, minz, maxx, maxy, maxz, params);
}

function getNodeInverseWorldMatrix(node) {
  if (!node) {
    return null;
  }
  const ud = node.userData;

  if (!ud) {
    return null;
  }

  const {inverseMatrix, inverseWorldMatrix} = ud;
  const matrix = inverseWorldMatrix || inverseMatrix;

  if (!matrix) {
    return null;
  }

  if (matrix._matrix) {
    return matrix._matrix;
  }

  return matrix;
}

function getDistFromSingleNode(single, x, y, z, dx, dy, dz, params) {
  if (!single) {
    return null;
  }
  const ud = single.userData;

  if (!ud) {
    return null;
  }
  const im = getNodeInverseWorldMatrix(single);
  const bbox = ud.dataBoundingBox;

  if (!im || !bbox) {
    return null;
  }

  const {minx, miny, minz, maxx, maxy, maxz} = bbox;

  return getDistToAABBTransformed(x, y, z, dx, dy, dz, minx, miny, minz, maxx, maxy, maxz, params, im);
}

export function getDistFromNode(node, x, y, z, dx, dy, dz, params, parentMatrix) {
  let res = Infinity;

  if (!node) {
    return res;
  }

  // Check if single
  const singleDist = getDistFromSingleNode(node, x, y, z, dx, dy, dz, params);

  if (singleDist !== null && typeof (singleDist) === 'number' && !isNaN(singleDist)) {
    res = singleDist < res ? singleDist : res;
  }

  // Check if box
  let box = null;

  if (node.getBox) {
    box = node.getBox();
  }
  if (!box) {
    box = node.box;
  }
  if (box) {
    const m = getNodeInverseWorldMatrix(node);
    const {min, max} = box;
    const minx = min.x;
    const miny = min.y;
    const minz = min.z;
    const maxx = max.x;
    const maxy = max.y;
    const maxz = max.z;

    const dist = getDistToAABBTransformed(x, y, z, dx, dy, dz, minx, miny, minz, maxx, maxy, maxz, params, m);

    if (dist !== null && typeof (dist) === 'number' && !isNaN(dist)) {
      res = dist < res ? dist : res;
    }
  }

  // Check if container
  if (node.getChildren) {
    const children = node.getChildren();
    const numChildren = children ? children.length : 0;

    for (let i = 0; i < numChildren; ++i) {
      const child = children[i];
      const dist = getDistFromNode(child, x, y, z, dx, dy, dz, params);

      if (dist !== null && typeof (dist) === 'number' && !isNaN(dist)) {
        res = dist < res ? dist : res;
      }
    }
  }

  return res;
}
/*
var getDistFromScene = function (x, y, z, dx, dy, dz, params) {
  let mattressNode = null;

  if (mc.getMattressNode) {
    mattressNode = mc.getMattressNode();
  } else {
    const factory = this._getMattress3DFactory();

    if (factory && factory.getResult) {
      mattressNode = factory.getResult();
    }
  }
  const dist = getDistFromNode(mattressNode, x, y, z, dx, dy, dz, params);

  return dist;
};

var tempSRTTransform, tempInverse;

var getDistFromJSON = function (x, y, z, dx, dy, dz, params) {
  const json = this.getMattressConfigJSON();
  if (!json) {
    return null;
  }
  const singles = json.singles;
  if (!singles) {
    return null;
  }
  const numS = singles.length;
  if (!numS) {
    return null;
  }
  let min = null;
  for (let i = 0; i < numS; ++i) {
    const s = singles[i];
    const d = getDistToSingle(s, x, y, z, dx, dy, dz, params);
    if (d !== null && typeof (d) === 'number') {
      if (min === null || typeof (min) !== 'number') {
        min = d;
      } else {
        min = d < min ? d : min;
      }
    }
  }
  return min;
};

var getDistToSingle = function (single, x, y, z, dx, dy, dz, params) {
  if (!single) {
    return null;
  }
  if (!tempSRTTransform) {
    tempSRTTransform = new SRTTransform3D();
  }
  var margin = 0;
  if (params) {
    if (typeof (params.margin) === 'number') {
      margin = params.margin;
    }
  }

  const height = MattressDA.getResultHeight(single);
  const width = MattressDA.getWidth(single);
  const length = MattressDA.getLength(single);

  const D2R = 1;
  // construct single matrix
  const posx = MattressDA.getTranslationX(single);
  const posy = MattressDA.getTranslationY(single);
  const posz = MattressDA.getTranslationZ(single);
  const rotx = MattressDA.getRotationX(single);
  const roty = MattressDA.getRotationY(single);
  const rotz = MattressDA.getRotationZ(single);
  tempSRTTransform.position.setCoords(posx, posy, posz);
  tempSRTTransform.rotation.setCoords(rotx, roty, rotz);
  tempSRTTransform.setRotationOrder(RotationOrder.ZYX);
  const m4 = tempSRTTransform.getMatrix4(true);

  tempInverse = Matrix4Math.getInverse(m4, tempInverse);

  let tx = x, ty = y, tz = z, tdx = dx, tdy = dy, tdz = dz;

  // transform the ray origin & direction to the local space of the single
  if (tempInverse) {
    const m = tempInverse;

    tx = x * m[0] + y * m[4] + z * m[8] + m[12];
    ty = x * m[1] + y * m[5] + z * m[9] + m[13];
    tz = x * m[2] + y * m[6] + z * m[10] + m[14];

    tdx = dx * m[0] + dy * m[4] + dz * m[8];
    tdy = dx * m[1] + dy * m[5] + dz * m[9];
    tdz = dx * m[2] + dy * m[6] + dz * m[10];
  }

  var maxx = (width * 0.5) + margin;
  var minx = (-maxx);
  var maxz = (length * 0.5) + margin;
  var minz = (-maxz);
  var maxy = height + margin;
  var miny = -margin;

  var t = getDistToAABB(tx, ty, tz, tdx, tdy, tdz, minx, miny, minz, maxx, maxy, maxz, null);

  return t;

}

var getDist = function (x, y, z, dx, dy, dz, params) {
  if (window.METHOD === 'json') {
    return getDistFromJSON.call(this, x,y,z,dx,dy,dz,params);
  }
  return getDistFromScene.call(this, x, y, z, dx, dy, dz, params);
};

var defaultParams = {margin: 10};
var updateCamZoom = function () {

  let oc = this._orbitController;
  let cam = this._camera;
  threeUpdateWorldMatrix(cam);
  let mw = cam.matrixWorld ? cam.matrixWorld.elements : null;

  let x = 0, y = 0, z = 0;
  let dx = 0, dy = 0, dz = 1;
  if (mw) {
    x = mw[12];
    y = mw[13];
    z = mw[14];

    dx = -mw[8];
    dy = -mw[9];
    dz = -mw[10];
  }
  var t = getDist.call(this, x, y, z, dx, dy, dz, defaultParams);
  if (t !== null) {

    const oc = this._orbitController;
    var dist = oc.getDistance();
    if (t < 0 || stickyCam) {
      oc.setDistance(dist - t);
    }

    if (!testEnabled) {
      return;
    }

    var resX = x + dx * t;
    var resY = y + dy * t;
    var resZ = z + dz * t;

    var indicator = window.TEST_INDICATOR;
    if (!indicator) {
      var geom = window.TEST_INDICATOR_GEOM;
      if (!geom) {
        geom = new THREE.SphereBufferGeometry(0.5);
        window.TEST_INDICATOR_GEOM = geom;
      }
      var mat = window.TEST_INDICATOR_MAT;
      if (!mat) {
        mat = window.TEST_INDICATOR_MAT = new THREE.MeshBasicMaterial({
          color:0x000088
        });
      }
      indicator = new THREE.Mesh(geom, mat);
      var scene = mc.getScene();
      scene.add(indicator);
      //window.TEST_INDICATOR = indicator;
    }
    indicator.position.x = resX;
    indicator.position.y = resY;
    indicator.position.z = resZ;
  }
};

MattressThreeComponentController.prototype.setZoom = function (v) {
  const oc = this._orbitController;

  oc.setDistance(v);
  updateCamZoom.call(this);
};

MattressThreeComponentController.prototype.checkCameraInsideObject = function (v) {
  updateCamZoom.call(this);
};

var superBuildMattress = BD3D.Mattress3DBuilder.prototype.buildMattress;
BD3D.Mattress3DBuilder.prototype.buildMattress = function (data, buildParams, res, bbox) {
  var result = superBuildMattress.call(this, data, buildParams, res, bbox);
  var width = MattressDA.getWidth(data);
  var length = MattressDA.getLength(data);
  var halfW = width * 0.5;
  var halfL = length * 0.5;
  if (!result) {
    return;
  }

  if (!result.userData) {
    result.userData = {};
  }

  let matrix = result.userData.matrix;

  if (!matrix) {
    matrix = result.userData.matrix = new Matrix4();
  }
  Matrix4Math.identity(matrix);
  result.transform.applyMatrix4(matrix);
  result.userData.inverseMatrix = Matrix4Math.getInverse(matrix, result.userData.inverseMatrix);

  let dataBBox = result.userData.dataBoundingBox;

  if (!dataBBox) {
    dataBBox = result.userData.dataBoundingBox = {};
  }

  dataBBox.minx = -halfW;
  dataBBox.maxx = halfW;
  dataBBox.minz = -halfL;
  dataBBox.maxz = halfL;
  dataBBox.miny = 0;
  dataBBox.maxy = MattressDA.getResultHeight(data);

  return result;
};

var testEnabled = false;
var stickyCam = false;

function threeUpdateWorldMatrix(o) {
  if (!o) {
    return;
  }
  if (o.matrixAutoUpdate) {
    o.updateMatrix();
  }
  const p = o.parent;

  if (p) {
    threeUpdateWorldMatrix(p);
    if (!o.matrixWorld) {
      o.matrixWorld = new THREE.Matrix4();
    }
    o.matrixWorld.multiplyMatrices(p.matrixWorld, o.matrix);
  }
};
/*
window.addEventListener('keydown', function (evt) {
  if (evt.keyCode === 65) {
    testEnabled = true;
    evt.preventDefault();
    return false;
  }
  if (evt.keyCode === 90) {
    stickyCam = true;
    evt.preventDefault();
    return false;
  }
});
window.addEventListener('keyup', function (evt) {
  if (evt.keyCode === 65) {
    testEnabled = false;
  }
  if (evt.keyCode === 90) {
    stickyCam = false;
  }
});
//*/
