const DEFAULT_LEG_HEIGHT = 10;

const LEG_TYPES = [
  {
    // Pyramid shape
    id: '0',
    keys: ['pyramid'],
    height: DEFAULT_LEG_HEIGHT
  },
  // Box shape
  {
    id: '1',
    keys: ['box'],
    height: 8
  },
  {
    // Cylinder shape
    id: '2',
    keys: ['cylinder'],
    height: 8
  }
];

const LEG_TYPE_BY_ID = {};

{
  const num = LEG_TYPES.length;

  for (let i = 0; i < num; ++i) {
    const legType = LEG_TYPES[i];
    const id = legType && legType.id;
    const keys = legType && legType.keys;
    const numKeys = keys ? keys.length : 0;

    if (id) {
      LEG_TYPE_BY_ID[id] = legType;
    }

    for (let j = 0; j < numKeys; ++j) {
      const key = keys[j];

      if (key) {
        LEG_TYPE_BY_ID[key] = legType;
      }
    }
  }
}

export default class LegDA {
  static getDefaultLegHeight() {
    return DEFAULT_LEG_HEIGHT;
  }

  static get defaultLegHeight() {
    return this.getDefaultLegHeight();
  }

  static getNumLegTypes() {
    return LEG_TYPES.length;
  }

  static get numLegTypes() {
    return this.getNumLegTypes();
  }

  static getLegType(legType) {
    const type = typeof (legType);

    if (type === 'string') {
      return LEG_TYPE_BY_ID[legType.toLowerCase()];
    }
    if (type === 'number') {
      return LEG_TYPES[legType];
    }
    if (legType && type === 'object') {
      return legType;
    }

    return null;
  }

  static setLegHeight(legData, value) {
    if (!legData) {
      return;
    }
    legData.height = value;
  }

  static getLegHeight(legData) {
    if (!legData) {
      return 0;
    }
    let {height} = legData;

    if (typeof (height) === 'number' && height > 0) {
      return height;
    }
    const type = legData.legType || legData.id;

    if (type === null || typeof (type) === 'undefined') {
      return 0;
    }
    const typeObject = LEG_TYPE_BY_ID[`${type}`];

    height = typeObject.height;

    if (typeof (height) === 'number' && height > 0) {
      return height;
    }

    return DEFAULT_LEG_HEIGHT;
  }
}
