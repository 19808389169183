// TODO: remove eslint disables
/* eslint no-magic-numbers: 0, max-depth: 0 */

// import OpenSimplexNoise from 'open-simplex-noise';
// import GeometryCloner from '../../bgr/bgr3d/cloners/GeometryCloner';

import Utils from '../utils/Utils';
import BorderShape from '../geom/BorderShape';
import BorderComponentType from '../borders/BorderComponentType';
import BorderComponentTypes from '../borders/BorderComponentTypes';
import GraphUtils from '../graph/GraphUtils';
import Graph from '../graph/Graph';
// import VecMat4Math from '../../bgr/bgr3d/math/VecMat4Math';
import Vertex from '../../bgr/bgr3d/geom/Vertex';
import Vector2 from '../../bgr/bgr3d/geom/Vector2';
import Vector3 from '../../bgr/bgr3d/geom/Vector3';
import Matrix4 from '../../bgr/bgr3d/geom/Matrix4';
import Node3D from '../../bgr/bgr3d/scenegraph/Node3D';
import ContainerNode3D from '../../bgr/bgr3d/scenegraph/ContainerNode3D';
import GeometryNode3D from '../../bgr/bgr3d/scenegraph/GeometryNode3D';

import BD3DContainerNode3D from '../scenegraph/BD3DContainerNode3D';
import BD3DGeometryNode3D from '../scenegraph/BD3DGeometryNode3D';
import BD3DNodeTypes from '../scenegraph/BD3DNodeTypes';

import Matrix4Transform3D from '../../bgr/bgr3d/transform/Matrix4Transform3D';
import Geometry from '../../bgr/bgr3d/geom/Geometry';
import Polygon from '../../bgr/bgr3d/geom/Polygon';
import Matrix4Math from '../../bgr/bgr3d/math/Matrix4Math';
import SRTTransform3D from '../../bgr/bgr3d/transform/SRTTransform3D';
import LegType from '../legs/LegType';
import HandleTypes from '../handles/HandleTypes';
import HandleStyles from '../handles/HandleStyles';
import BorderComponentUtils from '../borders/BorderComponentUtils';
import GeomUtils from '../../bgr/bgr3d/utils/GeomUtils';
import MattressGeomUtils from '../geom/MattressGeomUtils';
import BD3DGeometry from '../geom/BD3DGeometry';
import MattressConfig from '../mattress/MattressConfig';
import MattressDA from '../mattress/MattressDA';

import Node3DMaterialUtils from '../material/Node3DMaterialUtils';

import MaterialTypes from '../material/MaterialTypes';
import BD3DMaterial from '../material/BD3DMaterial';
import BD3DFabricMaterial from '../material/BD3DFabricMaterial';
import FabricTransform from '../material/FabricTransform';
import SampleTransform from '../material/SampleTransform';

import NoiseGeometryModifier from '../geommodifiers/NoiseGeometryModifier';
/*
import QuiltTransform from '../material/QuiltTransform';
import BD3DSampleFabricMaterial from '../material/BD3DSampleFabricMaterial';
import QuiltAsset from '../asset/QuiltAsset';
import QuiltDA from '../quilt/QuiltDA';
*/
import FabricMaterialUtils from '../material/FabricMaterialUtils';
// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
// #endif

const DEFAULT_MIRRORPANEL_MARGIN = 5;

const DEFAULT_BORDER_COMPONENT = {
  type: 'fabric',
  thickness: 0,
  height: 100
};
const DEFAULT_BORDER_COMPONENTS = [DEFAULT_BORDER_COMPONENT];

// clone a material and use uv transform properties of a given geometry
function cloneMaterialForGeometry(material, geomNode) {
  const res = (material && material.clone) ? material.clone() : null;

  if (!res) {
    return res;
  }
  let geom = null;

  if (geomNode instanceof Geometry) {
    geom = geomNode;
  } else if (geomNode instanceof GeometryNode3D) {
    geom = geomNode.geometry;
  }
  if (!geom) {
    return res;
  }
  const uvData = MattressGeomUtils.getGeometryUVWorldTransform(geom);

  let resSampleTransf = ((res instanceof BD3DFabricMaterial) && res.getSampleTransform) ? res.getSampleTransform() : null;
  const srcSampleTransf = (material && material.getSampleTransform) ? material.getSampleTransform() : null;
  let resQuiltTransf = ((res instanceof BD3DFabricMaterial) && res.getQuiltTransform) ? res.getQuiltTransform() : null;
  const srcQuiltTransf = (material && material.getQuiltTransform) ? material.getQuiltTransform() : null;

  if (!resSampleTransf && srcSampleTransf && srcSampleTransf.clone) {
    resSampleTransf = srcSampleTransf.clone();
  }
  if (!resQuiltTransf && srcQuiltTransf && srcQuiltTransf.clone) {
    resQuiltTransf = srcQuiltTransf.clone();
  }

  if (resSampleTransf && resSampleTransf === srcSampleTransf) {
    resSampleTransf = resSampleTransf.clone();
  }
  if (resQuiltTransf && resQuiltTransf === srcQuiltTransf) {
    resQuiltTransf = resQuiltTransf.clone();
  }
  if (resSampleTransf instanceof FabricTransform) {
    resSampleTransf.setGeometryUVData(uvData);
  }
  if (resQuiltTransf instanceof FabricTransform) {
    resQuiltTransf.setGeometryUVData(uvData);
  }
  if (res.setSampleTransform) {
    res.setSampleTransform(resSampleTransf);
  }
  if (res.setQuiltTransform) {
    res.setQuiltTransform(resQuiltTransf);
  }

  return res;

}
// Find the best geometry node by giving a node3d instance
// *
function findBorderGeometryNode(node) {
  if (!node) {
    return null;
  }
  if (node instanceof GeometryNode3D) {
    return node;
  }
  if (node instanceof ContainerNode3D) {
    const children = node.getChildren();
    const numChildren = children ? children.length : 0;
    const MAX_VALUE = 1000;
    let value = MAX_VALUE;
    let res = null;

    for (let i = 0; i < numChildren; ++i) {
      const child = children[i];

      if (child && child.getNodeType) {
        const type = child.getNodeType();
        let v = MAX_VALUE;

        if (type === BD3DNodeTypes.borderFabric) {
          v = 0;
        } else if (type === BD3DNodeTypes.border3d) {
          v = 1;
        }
        if (v < value) {
          value = v;
          res = child;
        }
      }
    }
    if (res) {
      return res;
    }
    for (let i = 0; i < numChildren; ++i) {
      const child = children[i];

      res = findBorderGeometryNode(child);
      if (res) {
        return res;
      }
    }
  }

  return null;
}

function setSelectable(node, s) {
  if (!node) {
    return;
  }
  if (node instanceof BD3DContainerNode3D) {
    const children = node.getChildren();

    if (!children) {
      return;
    }
    const numC = children.length;

    if (!numC) {
      return;
    }
    for (let i = 0; i < numC; ++i) {
      setSelectable(children[i], s);
    }
  } else if (node instanceof BD3DGeometryNode3D) {
    node.setSelectable(s);
  }
}
function setNonSelectable(node) {
  setSelectable(node, false);
}

/*
function appendToArray(source, arr) {
  if (!source || !arr) {
    return source || arr;
  }
  const l = arr.length;

  if (!l) {
    return source;
  }
  for (let i = 0; i < l; ++i) {
    source.push(arr[i]);
  }

  return source;
}

// TODO: move to geometry utils
function appendGeometry(base, attachment) {
  if (!base || !attachment) {
    return base || attachment;
  }
  let basePolygons = base.polygons;
  let basePolygonVertices = base.polygonVertices;
  let baseVertices = base.vertices;

  if (!basePolygons) {
    basePolygons = base.polygons = [];
  }
  if (!basePolygonVertices) {
    basePolygonVertices = base.polygonVertices = [];
  }
  if (!baseVertices) {
    baseVertices = base.vertices = [];
  }

  const attPolygons = attachment.polygons;
  const attPolygonVertices = attachment.polygonVertices;
  const attVertices = attachment.vertices;

  if (attPolygons && attPolygonVertices && attVertices) {
    appendToArray(basePolygons, attPolygons);
    appendToArray(basePolygonVertices, attPolygonVertices);
    appendToArray(baseVertices, attVertices);
  }

  return base;
}
*/

/**
* @class Mattress3DBuilder
* @description Builds a 3D mattress from a config (json)
* Note: assets need to be loaded before the builder can build the 3D mattress.
*/
export default class Mattress3DBuilder {
  getBorderShape() {
    let bs = this._borderShape;

    if (bs) {
      return bs;
    }
    bs = this._borderShape = new BorderShape();

    return bs;
  }

  _getBorderComponentTypeByName(name) {
    return BorderComponentUtils.getBorderComponentTypeByName(name);
  }

  // Calculate the height of a single border component
  getBorderComponentHeight(borderComponent, mattressData) {
    if (!borderComponent) {
      return 0;
    }

    const typeName = borderComponent.type.toUpperCase();

    if (!typeName) {
      return 0;
    }

    const type = this._getBorderComponentTypeByName(typeName);

    if (!type) {
      return 0;
    }

    if (type instanceof BorderComponentType) {
      return type.getHeight(borderComponent, mattressData);
    }

    return 0;
  }

  // Calculate & add the heights of each border component in the borderComponents array
  calcBorderHeight(borderComponents, mattressData) {
    return MattressDA.getTotalHeightOfBorderComponents(borderComponents, mattressData);
    /*
    if (!borderComponents) {
      return 0;
    }
    const num = borderComponents.length;

    if (num === 0) {
      return 0;
    }
    let res = 0;

    for (let i = 0; i < num; ++i) {
      res += this.getBorderComponentHeight(borderComponents[i], mattressData);
    }

    return res;
    */
  }

  // returns the border curve graph info from the mattress data object
  findBorderCurveGraph(data) {
    if (!data) {
      return null;
    }
    const border = data.border;

    if (!border) {
      return null;
    }

    const curve = border.curve;

    if (!curve) {
      return null;
    }
    if (typeof (curve) === 'string') {
      // TODO: get a predefined curve from a map by this key
      return null;
    }

    return curve;
  }

  throwError(err, buildParams) {
    if (buildParams && buildParams.onerror) {
      buildParams.onerror(err);

      return;
    }
    // #if DEBUG
    BD3DLogger.error(err);
    // #endif
  }

  _clearNode(node) {
    if (!node) {
      return;
    }
    if (node instanceof ContainerNode3D) {
      const children = node.getChildren();

      if (children) {
        children.length = 0;
      }
    }
  }

  _getCachedValue(data, buildParams) {
    if (!buildParams || !data) {
      return null;
    }
    let map = buildParams.prevDataNode3DMap;
    let res = null;

    if (map && map.has(data)) {
      res = map.get(data);
    }
    if (res) {
      // #if DEBUG
      BD3DLogger.info('Cached value of ', data, '=', res);
      // #endif

      return res;
    }

    map = buildParams.dataNode3DMap;

    if (map && map.has(data)) {
      res = map.get(data);
    }
    // #if DEBUG
    if (res) {
      BD3DLogger.info('Cached value of ', data, '=', res);
    }
    // #endif

    return res;
  }

  _getCachedObjectInfoMap(create = true) {
    let map = this._cachedObjectInfo;

    if (map || !create) {
      return map;
    }

    map = this._cachedObjectInfo = new WeakMap();

    return map;
  }

  _getCachedObjectInfo(object, buildParams) {
    const map = this._getCachedObjectInfoMap(false);

    if (!map) {
      return null;
    }

    return map.get(object);
  }

  _setCachedObjectInfo(object, value, buildParams) {
    const map = this._getCachedObjectInfoMap(true);

    if (!map) {
      return;
    }

    map.set(object, value);
  }

  buildMattressConfig(config, buildParams, res) {
    if (!config) {
      this._clearNode(res);

      return res;
    }
    let result = res;

    if (!result) {
      result = this._getCachedValue(config, buildParams);
    }
    if (!result) {
      result = new BD3DContainerNode3D();
    }

    let singles = null;

    if (config instanceof MattressConfig) {
      singles = config.getSingles();
    } else {
      singles = config.singles;
    }

    if (!singles) {
      return result;
    }

    const numSingles = singles.length;

    let children = result.children;

    if (!children) {
      children = result.children = [];
    }

    if (numSingles === 0) {
      return result;
    }

    // reset bounding box
    const bbox = {
      width: 0,
      height: 0,
      length: 0,
      x: 0,
      y: 0,
      z: 0,
      widthMinZero: 0
    };

    for (let i = 0; i < numSingles; ++i) {
      // let resChild = children[i];
      const single = singles[i];
      const resChild = this._getCachedValue(single, buildParams);

      const node = this.buildMattress(single, buildParams, resChild, bbox);

      this._setNodeSelectionMode(node, 'single', true);

      children[i] = node;
    }

    const aabb = {
      min: {
        x: bbox.x - bbox.width / 2,
        y: bbox.y - bbox.height / 2,
        z: bbox.z - bbox.length / 2
      },
      max: {
        x: bbox.x + bbox.width / 2,
        y: bbox.y + bbox.height / 2,
        z: bbox.z + bbox.length / 2
      }
    };

    result.boundingBox = aabb;

    return result;
  }

  _setNodeSelectionMode(node, mode, flag = true) {
    if (!node || !mode) {
      return;
    }
    node.selectionModes = node.selectionModes || {};
    node.selectionModes[mode] = flag;
  }

  _assignSampleMaterialData(node, sampleConfigData, quiltConfigData, singlePart, buildParams) {
    FabricMaterialUtils.assignMaterialToNode(node, sampleConfigData, quiltConfigData, singlePart, buildParams);
  }
  /*
  _assignSampleMaterialData2(node, sampleConfigData, quiltConfigData, buildParams) {
    if (!node) {
      return;
    }
    let quiltAsset = null;
    const quiltType = QuiltDA.getType(quiltConfigData);

    if (quiltType === 'custom') {
      let ud = node.userData;

      if (!ud) {
        ud = node.userData = {};
      }
      quiltAsset = ud.gridQuiltAsset;
      if (!quiltAsset) {
        quiltAsset = ud.gridQuiltAsset = new QuiltAsset();
      }
      quiltAsset.setQuiltConfigData(quiltConfigData);
    } else {
      const quiltID = MattressDA.getQuiltID(quiltConfigData);

      quiltAsset = buildParams.assetCollections.quilts.getAssetByName(quiltID);
    }
    const sampleID = MattressDA.getSampleID(sampleConfigData);

    const sampleAsset = buildParams.assetCollections.samples.getAssetByName(sampleID);
    const uvWorldTransform = MattressGeomUtils.getGeometryUVWorldTransform(node);

    let material = Node3DMaterialUtils.getMaterial(node);

    if (!(material instanceof BD3DSampleFabricMaterial)) {
      material = new BD3DSampleFabricMaterial();
    }

    material.setQuiltAsset(quiltAsset);
    material.setSampleAsset(sampleAsset);

    // assign sample transform
    let sampleTransf = material.getSampleTransform();
    let quiltTransf = material.getQuiltTransform();

    if (!sampleTransf) {
      sampleTransf = new SampleTransform();
    }
    if (!quiltTransf) {
      quiltTransf = new QuiltTransform();
    }
    sampleTransf.setSampleConfigData(sampleConfigData);
    sampleTransf.setSampleData(sampleAsset.sampleData);
    sampleTransf.setGeometryUVData(uvWorldTransform);

    quiltTransf.setQuiltConfigData(quiltConfigData);
    quiltTransf.setQuiltData(quiltAsset ? quiltAsset.quiltData : null);
    quiltTransf.setGeometryUVData(uvWorldTransform);

    material.setSampleTransform(sampleTransf);
    material.setQuiltTransform(quiltTransf);
    material.setType(MaterialTypes.FABRIC);

    // TODO: setQuiltTransform

    Node3DMaterialUtils.setMaterial(node, material);

    // this._assignMaterialData(node, sampleData);
  }
  */

  _assignMaterialData(node, materialData) {
    if (!node) {
      return;
    }

    let material = Node3DMaterialUtils.getMaterial(node);

    let type = null;

    if (materialData && materialData.id) {
      type = MaterialTypes.SAMPLE;
    }

    if (material) {
      material.setType(type);
      material.setSettings(materialData);
    } else {
      let MatType = BD3DMaterial;

      if (type === MaterialTypes.SAMPLE) {
        MatType = BD3DFabricMaterial;
      }
      material = new MatType(type, materialData);
    }

    if (material instanceof BD3DFabricMaterial) {
      let sampleTransf = material.getSampleTransform();

      if (!sampleTransf) {
        sampleTransf = new SampleTransform();
      }
      sampleTransf.setData(materialData);
      material.setSampleTransform(sampleTransf);
    }

    Node3DMaterialUtils.setMaterial(node, material);
  }

  buildLegs(data, mattressData, buildParams) {
    this.legType = this.legType || new LegType();

    const res = this.legType.create3DLegs(data, mattressData);

    this._associateDataWithNode(data, res, buildParams);

    if (!res) {
      return res;
    }

    if (!res.userData) {
      res.userData = {};
    }
    res.userData.noise = false;

    return res;
  }

  _parseNum(num, fallback) {
    const t = typeof (num);

    if (t === 'number') {
      if (isNaN(num)) {
        return fallback;
      }

      return num;
    }
    if (t === 'string') {
      return this._parseNum(parseFloat(num), fallback);
    }

    if (t === 'object') {
      return this._parseNum(num.valueOf(), fallback);
    }

    return fallback;
  }

  _setGeometryDynamic(geom, dyn) {
    if (!geom) {
      return;
    }
    if (!geom.userData) {
      geom.userData = {};
    }
    geom.userData.dynamic = dyn;
  }

  _setGeometryNodeDynamic(geomNode) {
    if (geomNode instanceof BD3DGeometryNode3D) {
      // Make the result geometry the same as the source geometry
      // so modifiers don't have to clone the source geometry
      geomNode.setResultGeometry(geomNode.getSourceGeometry());
    }
  }

  _createMirrorPanelZipperGeometry(top, borderLoop, zipperHeight, params = null, extraOutput = null) {
    if (!borderLoop || !zipperHeight) {
      return null;
    }
    if (zipperHeight <= 0) {
      return null;
    }
    const numVerts = borderLoop.length;

    if (!numVerts) {
      return null;
    }
    const numSubdivs = 3;

    const grid = [];
    const vertGrid = [];
    const polyVerts = [];
    const verts = [];
    const polys = [];
    const uvs = [];
    const attributes = {uv: uvs};
    const res = new Geometry(polys, polyVerts, verts, attributes);

    this._setGeometryDynamic(res, true);

    let uDist = 0;

    for (let i = 0; i <= numVerts; ++i) {
      let column = grid[i];
      const wrapI = i % numVerts;
      let vertColumn = vertGrid[wrapI];

      if (!column) {
        column = [];
        grid[i] = column;
      }
      if (!vertColumn) {
        vertColumn = [];
        vertGrid[wrapI] = vertColumn;
      }
      const prevI = ((i - 1) % numVerts + numVerts) % numVerts;
      const nextI = (i + 1) % numVerts;
      const prevV = borderLoop[prevI];
      const nextV = borderLoop[nextI];
      const v = borderLoop[wrapI];
      const vx = v.getCoord(0);
      const vy = v.getCoord(1);
      const vz = v.getCoord(2);
      const prX = prevV.getCoord(0);
      const prZ = prevV.getCoord(2);
      const nxX = nextV.getCoord(0);
      const nxZ = nextV.getCoord(2);

      const dx1 = vx - prX;
      const dz1 = vz - prZ;
      const dx2 = nxX - vx;
      const dz2 = nxZ - vz;

      if (i > 0) {
        const d = dx1 * dx1 + dz1 * dz1;

        if (d !== 0) {
          uDist += Math.sqrt(d);
        }
      }

      let nx1 = -dz1;
      let nz1 = dx1;
      let nx2 = -dz2;
      let nz2 = dx2;
      let n = nx1 * nx1 + nz1 * nz1;

      if (n !== 0 && n !== 1) {
        n = 1 / Math.sqrt(n);
        nx1 *= n;
        nz1 *= n;
      }
      n = nx2 * nx2 + nz2 * nz2;
      if (n !== 0 && n !== 1) {
        n = 1 / Math.sqrt(n);
        nx2 *= n;
        nz2 *= n;
      }
      let nx = (nx1 + nx2) * 0.5;
      let nz = (nz1 + nz2) * 0.5;

      n = nx * nx + nz * nz;
      if (n !== 0 && n !== 1) {
        n = 1 / Math.sqrt(n);
        nx *= n;
        nz *= n;
      }
      let vpos = v, uv = null, srcNrm = null, nrm = null;

      if (v instanceof Vertex) {
        if (v.attributes) {
          srcNrm = v.attributes.normal;
        }
        vpos = v.position;
      }
      nrm = srcNrm;
      uv = new Vector2(uDist, 0);
      let vert = vertColumn[0];

      if (vert) {
        vert.position = vpos;
      } else {
        vert = new Vertex(vpos);
      }
      let polyVert = new Vertex(vert, {uv: uv, normal: nrm});

      column[0] = polyVert;
      vertColumn[0] = vert;
      verts.push(vert);
      polyVerts.push(polyVert);
      uvs.push(uv);

      const prevCol = i > 0 ? grid[i - 1] : null;

      for (let j = 0; j < numSubdivs; ++j) {
        const index = j + 1;
        const t = index / numSubdivs;
        const dist = t * zipperHeight;
        const x = vx + nx * dist;
        const y = vy + (-vy) * t;
        const z = vz + nz * dist;

        vpos = new Vector3(x, y, z);
        uv = new Vector2(uDist, dist);
        vert = vertColumn[index];
        if (vert) {
          vert.position = vpos;
        } else {
          vert = new Vertex(vpos);
        }
        polyVert = new Vertex(vert, {uv: uv, normal: nrm});

        uvs.push(uv);
        verts.push(vert);
        polyVerts.push(polyVert);
        column[index] = polyVert;
        vertColumn[index] = vert;
        if (i > 0 && index > 0) {
          const vTopLeft = prevCol[index - 1];
          const vTopRight = column[index - 1];
          const vBottomLeft = prevCol[index];
          const vBottomRight = polyVert;
          const polygonVerts = [vTopLeft, vTopRight, vBottomRight, vBottomLeft];

          if (top) {
            polygonVerts.reverse();
          }
          const poly = new Polygon(polygonVerts);

          polys.push(poly);
        }
      }
      if (extraOutput) {
        if (i === 0) {
          // Calculate puller transformation matrix from vertical edge
          const lastVert = column[column.length - 1];
          const lx = lastVert.getCoord(0);
          const ly = lastVert.getCoord(1);
          const lz = lastVert.getCoord(2);
          const x = (lx + vx) * 0.5;
          const y = (ly + vy) * 0.5;
          const z = (lz + vz) * 0.5;

          let d;
          let dx = lx - vx;
          let dy = ly - vy;
          let dz = lz - vz;

          d = 1 / Math.sqrt(dx * dx + dy * dy + dz * dz);

          dx *= d;
          dy *= d;
          dz *= d;

          const upX = 0, upY = (top ? -1 : 1), upZ = 0;
          let axisXx = dy * upZ - upY * dz;
          let axisXy = dz * upX - upZ * dx;
          let axisXz = dx * upY - upX * dy;

          d = 1 / Math.sqrt(axisXx * axisXx + axisXy * axisXy + axisXz * axisXz);
          axisXx *= d;
          axisXy *= d;
          axisXz *= d;

          let axisZx = dy * axisXz - axisXy * dz;
          let axisZy = dz * axisXx - axisXz * dx;
          let axisZz = dx * axisXy - axisXx * dy;

          d = 1 / Math.sqrt(axisZx * axisZx + axisZy * axisZy + axisZz * axisZz);
          axisZx *= d;
          axisZy *= d;
          axisZz *= d;
          let pullerMatrix = extraOutput.pullerMatrix;

          if (!pullerMatrix) {
            pullerMatrix = extraOutput.pullerMatrix = new Matrix4();
          }
          pullerMatrix.setValues(
            -axisXx, -axisXy, -axisXz, 0,
            dx, dy, dz, 0,
            axisZx, axisZy, axisZz, 0,
            x, y, z, 1
          );

        }
      }
    }
    GeomUtils.normalizeUVs(uvs, {
      maxUVFit: true
    });
    GeomUtils.calculateVertexNormals(polys);

    return res;
  }

  _hasZipperBorderComponent(data) {
    if (!data) {
      return false;
    }
    const border = data.border;

    if (!border) {
      return false;
    }
    const components = border.components;

    if (!components) {
      return false;
    }
    const num = components.length;

    if (!num) {
      return false;
    }
    for (let i = 0; i < num; ++i) {
      const b = components[i];

      if (b) {
        const type = b.type;

        if (type && type.toLowerCase() === 'zipper') {
          return true;
        }
      }
    }

    return false;
  }

  _getMirrorPanel(top, data, buildParams, result = null) {
    const topData = top ? data.top : data.bottom;
    const defaultZipperType = 'spiral_coil';
    let mirrorPanelData = null;

    if (topData) {
      mirrorPanelData = topData.mirrorPanel;
    }
    if (!mirrorPanelData && !top) {
      mirrorPanelData = data.mirrorPanel;
    }

    if (!mirrorPanelData || mirrorPanelData.enabled === false || mirrorPanelData.enabled === 0) {
      return null;
    }

    const mirrorPanelTexData = mirrorPanelData.texture;
    const mirrorPanelQuiltData = mirrorPanelData.quilt;

    const mattressData = null;
    let zipperHeight = 2; // zipper height in cm
    let margin = 5; // distance in cm from border

    if (mirrorPanelData.zipperHeight !== null && typeof (mirrorPanelData.zipperHeight) !== 'undefined') {
      zipperHeight = Utils.parseNumber(mirrorPanelData.zipperWidth,
        Utils.parseNumber(mirrorPanelData.zipperSize,
          Utils.parseNumber(mirrorPanelData.zipperHeight, zipperHeight)
          )
      );
    }
    if (mirrorPanelData.margin !== null && typeof (mirrorPanelData.margin) !== 'undefined') {
      margin = Utils.parseNumber(mirrorPanelData.margin, margin);
    }

    const totalMargin = margin + zipperHeight;
    const dMargin = totalMargin * 2;

    const topBorderRadius = top ? MattressDA.getResultTopBorderRadius(data) : MattressDA.getResultBottomBorderRadius(data);
    const dTopBorderRadius = topBorderRadius * 2;
    const width = MattressDA.getWidth(data) - dMargin - dTopBorderRadius;
    const length = MattressDA.getLength(data) - dMargin - dTopBorderRadius;
    const topHeight = top ? MattressDA.getTopHeight(data) : 0;// MattressDA.getBottomHeight(data);
    // const height = MattressDA.getHeight(data);
    let cornerRadius = MattressDA.getResultCornerRadius(data) - margin - topBorderRadius;

    if (cornerRadius < 4) {
      cornerRadius = 4;
    }
    let borderRadius = cornerRadius * 0.5;

    borderRadius = borderRadius < 0 ? 0 : borderRadius;
    let edgeSize = 100;

    if (this._singleHasNoise(data, buildParams)) {
      edgeSize = 4;
    }

    const height = borderRadius;
    const borderCurveGraph = null;
    const graphMinY = 0;
    const graphMaxY = 0;
    const graphHeight = 0;
    const meshGenParams = {
      xEdgeSize: edgeSize,
      zEdgeSize: edgeSize,
      subdivs: 2,
      minSubdivs: 2,
      edgeSize: 100,
      maxConcentricSubdivs: 10,
      concentricEdgeSize: 2,
      cornerEdgeSize: 1,
      borderEdgeSize: 1
    };
    const meshExtraOutput = {};
    let resNode = result;
    let resGeom = null;
    const defScale = 0.25;
    let scale = defScale;
    const hasZipper = zipperHeight && (zipperHeight > 0) && !this._hasZipperBorderComponent(data);

    if (!resNode) {
      resNode = new BD3DContainerNode3D();
    }
    resNode.removeChildren();

    if (hasZipper) {
      scale = 0.01;
    }
    const scaleY = top ? scale : -scale;

    resGeom = MattressGeomUtils.createTopBottomGeometry(width, length, height,
      cornerRadius, borderRadius, scaleY,
      borderCurveGraph, graphMinY, graphMaxY, graphHeight, graphHeight,
      meshGenParams, meshExtraOutput, resGeom);
      // */
    if (!resGeom.userData) {
      resGeom.userData = {};
    }
    // #if DEBUG
    resGeom.userData.name = 'mirrorpanel';
    // #endif

    const borderLoop = meshExtraOutput.borderLoop;

    if (hasZipper) {
      const verts = resGeom.vertices;
      let numVerts = verts.length;
      const offset = top ? defScale : -defScale;

      for (let i = 0; i < numVerts; ++i) {
        const vert = verts[i];

        vert.setCoord(1, vert.getCoord(1) + offset);
      }
      numVerts = borderLoop.length;
      for (let i = 0; i < numVerts; ++i) {
        const vert = borderLoop[i];

        vert.setCoord(1, vert.getCoord(1) + offset);
      }
    }

    const geomNode = new BD3DGeometryNode3D(resGeom);

    this._setGeometryNodeDynamic(geomNode);

    let zipperNode = null;

    if (hasZipper) {
      const zipperGeomOutput = {};
      const zipperGeom = this._createMirrorPanelZipperGeometry(top, borderLoop, zipperHeight, null, zipperGeomOutput);

      zipperNode = new BD3DGeometryNode3D(zipperGeom);
      this._setGeometryNodeDynamic(zipperNode);

      if (zipperGeomOutput && zipperGeomOutput.pullerMatrix) {
        const zipperPullerNode = BorderComponentTypes.ZIPPER.createPuller(mirrorPanelData, mattressData, null, buildParams, 'horizontal_puller');
        const zipperPullerTransf = new Matrix4Transform3D();

        zipperPullerTransf.setMatrix(zipperGeomOutput.pullerMatrix);
        zipperPullerNode.transform = zipperPullerTransf;

        resNode.addChild(zipperPullerNode);
      }
      let zipperMaterial = null;

      zipperMaterial = BorderComponentTypes.ZIPPER.getZipperMaterial(mirrorPanelData, mattressData, buildParams, zipperMaterial, defaultZipperType);
      Node3DMaterialUtils.setMaterial(zipperNode, zipperMaterial);
    }


    // resNode.removeChildren();

    resNode.addChild(geomNode);
    if (hasZipper && zipperNode) {
      resNode.addChild(zipperNode);
    }

    let mirrorPanelTexId = null;

    if (typeof (mirrorPanelTexData) === 'string') {
      mirrorPanelTexId = mirrorPanelTexData;
    } else if (mirrorPanelTexData) {
      mirrorPanelTexId = mirrorPanelTexData.id;
    }
    if (mirrorPanelTexId) {
      const singlePart = top ? 'toppanel' : 'bottompanel';

      this._assignSampleMaterialData(geomNode, mirrorPanelTexData, mirrorPanelQuiltData, singlePart, buildParams);
    } else {
      // TODO: default sample
      const mtl = new BD3DFabricMaterial();
      const commonTextures = buildParams.assetCollections.commonTextures;
      // const defaultFabricColor = commonTextures._assetsByName['defaultfabric.color'];
      // const defaultFabricNormal = commonTextures._assetsByName['defaultfabric.normal'];
      // let realWidth = 2;
      // let realHeight = 2;

      const defaultFabricColor = commonTextures.getAssetByName('defaultmirrorpanelfabric.color');
      const defaultFabricNormal = commonTextures.getAssetByName('defaultmirrorpanelfabric.normal');
      let realWidth = 68;
      let realHeight = 80;

      if (defaultFabricColor && defaultFabricColor.metaData && defaultFabricColor.metaData.realSize) {
        realWidth = defaultFabricColor.metaData.realSize.width;
        realHeight = defaultFabricColor.metaData.realSize.height;
      }
      const uvData = MattressGeomUtils.getGeometryUVData(resGeom);
      const sampleTransform = new SampleTransform();

      sampleTransform.setRealWidth(realWidth);
      sampleTransform.setRealHeight(realHeight);
      sampleTransform.setGeometryUVData(uvData);

      mtl.setSampleTexture(defaultFabricColor);
      mtl.setSampleNormalMap(defaultFabricNormal);
      mtl.setSampleTransform(sampleTransform);

      Node3DMaterialUtils.setMaterial(geomNode, mtl);
    }

    if (!resNode.userData) {
      resNode.userData = {};
    }
    resNode.userData.name = `mirror_${(top ? 'top' : 'bottom')}`;
    const transf = new SRTTransform3D();
    let offsetY = topHeight;

    if (height <= 0) {
      offsetY += 0.1;
    }
    offsetY = top ? offsetY : -offsetY;

    transf.position.setCoord(1, offsetY);

    resNode.transform = transf;
    resNode.setSelectable(true);
    this._setNodeSelectionMode(resNode, 'component', true);

    this._associateDataWithNode(mirrorPanelData, resNode, buildParams);

    return resNode;
  }

  _createMirrorPanel(isTop, data, sampleData, quiltData, zipperData, topHeight, buildParams, result = null) {
    // const topData = isTop ? data.top : data.bottom;
    const defaultZipperType = 'spiral_coil';
    const mattressData = data;
    let zipperHeight = 0; // zipper height in cm
    const margin = DEFAULT_MIRRORPANEL_MARGIN; // distance in cm from border

    if (zipperData && zipperData.height !== null && typeof (zipperData.height) !== 'undefined') {
      zipperHeight = Utils.parseNumber(zipperData.height, zipperHeight);
    }
    let topOrBottomData = null;

    if (data) {
      topOrBottomData = isTop ? data.top : data.bottom;
    }

    const totalMargin = margin;// + zipperHeight;
    const dMargin = totalMargin * 2;

    const topBorderRadius = isTop ? MattressDA.getResultTopBorderRadius(data) : MattressDA.getResultBottomBorderRadius(data);
    const dTopBorderRadius = topBorderRadius * 2;
    const width = MattressDA.getWidth(data) - dMargin - dTopBorderRadius;
    const length = MattressDA.getLength(data) - dMargin - dTopBorderRadius;
    // const topHeight = top ? MattressDA.getTopHeight(data) : MattressDA.getBottomHeight(data);
    // const height = MattressDA.getHeight(data);
    let cornerRadius = MattressDA.getResultCornerRadius(data) - margin - topBorderRadius;

    if (cornerRadius < 4) {
      cornerRadius = 4;
    }
    const minWidthLength = width < length ? width : length;
    const minCornerR = minWidthLength * 0.5;

    if (cornerRadius > minCornerR) {
      cornerRadius = minCornerR;
    }
    let borderRadius = cornerRadius * 0.5;

    borderRadius = borderRadius < 0 ? 0 : borderRadius;
    let edgeSize = 100;

    if (this._singleHasNoise(data, buildParams)) {
      edgeSize = 4;
    }

    const height = borderRadius;
    const borderCurveGraph = null;
    const graphMinY = 0;
    const graphMaxY = 0;
    const graphHeight = 0;
    const meshGenParams = {
      xEdgeSize: edgeSize,
      zEdgeSize: edgeSize,
      subdivs: 2,
      minSubdivs: 2,
      edgeSize: 100,
      maxConcentricSubdivs: 10,
      concentricEdgeSize: 2,
      cornerEdgeSize: 1,
      borderEdgeSize: 1
    };
    const meshExtraOutput = {};
    let resNode = result;
    let resGeom = null;
    const defScale = 0.25;
    let scale = defScale;
    const hasZipper = zipperHeight && (zipperHeight > 0);

    if (!resNode) {
      resNode = new BD3DContainerNode3D();
    }
    resNode.removeChildren();

    if (hasZipper) {
      scale = 0.01;
    }
    const scaleY = isTop ? scale : -scale;

    resGeom = MattressGeomUtils.createTopBottomGeometry(width, length, height,
      cornerRadius, borderRadius, scaleY,
      borderCurveGraph, graphMinY, graphMaxY, graphHeight, graphHeight,
      meshGenParams, meshExtraOutput, resGeom);
      // */
    if (!resGeom.userData) {
      resGeom.userData = {};
    }
    // #if DEBUG
    resGeom.userData.name = 'mirrorpanel';
    // #endif

    const borderLoop = meshExtraOutput.borderLoop;

    if (hasZipper) {
      const verts = resGeom.vertices;
      let numVerts = verts.length;
      const offset = isTop ? defScale : -defScale;

      for (let i = 0; i < numVerts; ++i) {
        const vert = verts[i];

        vert.setCoord(1, vert.getCoord(1) + offset);
      }
      numVerts = borderLoop.length;
      for (let i = 0; i < numVerts; ++i) {
        const vert = borderLoop[i];

        vert.setCoord(1, vert.getCoord(1) + offset);
      }
    }

    const geomNode = new BD3DGeometryNode3D(resGeom);

    this._setGeometryNodeDynamic(geomNode);

    let zipperNode = null;

    if (hasZipper) {
      const zipperGeomOutput = {};
      const zipperGeom = this._createMirrorPanelZipperGeometry(isTop, borderLoop, zipperHeight, null, zipperGeomOutput);

      const zipperGeomNode = new BD3DGeometryNode3D(zipperGeom);

      this._setGeometryNodeDynamic(zipperGeomNode);

      zipperNode = new BD3DContainerNode3D();
      zipperNode.addChild(zipperGeomNode);
      zipperNode.setNodeType(BD3DNodeTypes.zipper);
      this._associateDataWithNode(zipperData, zipperNode, buildParams);

      if (zipperGeomOutput && zipperGeomOutput.pullerMatrix) {
        const zipperColor = zipperData ? zipperData.color : null;
        const zipperPullerNode = BorderComponentTypes.ZIPPER.createPuller(zipperData, mattressData, zipperColor, buildParams, 'horizontal_puller');
        const zipperPullerTransf = new Matrix4Transform3D();

        zipperPullerTransf.setMatrix(zipperGeomOutput.pullerMatrix);
        zipperPullerNode.transform = zipperPullerTransf;

        zipperNode.addChild(zipperPullerNode);
      }
      let zipperMaterial = null;

      zipperMaterial = BorderComponentTypes.ZIPPER.getZipperMaterial(zipperData, mattressData, buildParams, zipperMaterial, defaultZipperType);
      Node3DMaterialUtils.setMaterial(zipperGeomNode, zipperMaterial);
      zipperNode.setSelectable(true);
      this._setNodeSelectionMode(zipperNode, 'component', true);
    }
    // resNode.removeChildren();

    resNode.addChild(geomNode);
    geomNode.setSelectable(true);
    this._setNodeSelectionMode(geomNode, 'component', true);

    this._associateDataWithNode(topOrBottomData, geomNode, buildParams);

    if (hasZipper && zipperNode) {
      resNode.addChild(zipperNode);
    }

    let mirrorPanelTexId = null;

    if (typeof (sampleData) === 'string') {
      mirrorPanelTexId = sampleData;
    } else if (sampleData) {
      mirrorPanelTexId = sampleData.id;
    }
    if (mirrorPanelTexId) {
      const singlePart = isTop ? 'toppanel' : 'bottompanel';

      this._assignSampleMaterialData(geomNode, sampleData, quiltData, singlePart, buildParams);
    } else {
      // TODO: default sample
      const mtl = new BD3DFabricMaterial();
      const commonTextures = buildParams.assetCollections.commonTextures;
      // const defaultFabricColor = commonTextures._assetsByName['defaultfabric.color'];
      // const defaultFabricNormal = commonTextures._assetsByName['defaultfabric.normal'];
      // let realWidth = 2;
      // let realHeight = 2;

      const defaultFabricColor = commonTextures.getAssetByName('defaultmirrorpanelfabric.color');
      const defaultFabricNormal = commonTextures.getAssetByName('defaultmirrorpanelfabric.normal');
      let realWidth = 68;
      let realHeight = 80;

      const defFabricRealSize = defaultFabricColor && defaultFabricColor.metaData ? defaultFabricColor.metaData.realSize : null;

      if (defFabricRealSize) {
        realWidth = defFabricRealSize.width;
        realHeight = defFabricRealSize.height;
      }
      const uvData = MattressGeomUtils.getGeometryUVData(resGeom);
      const sampleTransform = new SampleTransform();

      sampleTransform.setRealWidth(realWidth);
      sampleTransform.setRealHeight(realHeight);
      sampleTransform.setGeometryUVData(uvData);

      mtl.setSampleTexture(defaultFabricColor);
      mtl.setSampleNormalMap(defaultFabricNormal);
      mtl.setSampleTransform(sampleTransform);

      Node3DMaterialUtils.setMaterial(geomNode, mtl);
    }

    if (!resNode.userData) {
      resNode.userData = {};
    }
    resNode.userData.name = `mirror_${(isTop ? 'top' : 'bottom')}`;
    const transf = new SRTTransform3D();
    let offsetY = topHeight;

    if (height <= 0) {
      offsetY += 0.1;
    }
    offsetY = isTop ? offsetY : -offsetY;

    transf.position.setCoord(1, offsetY);

    resNode.transform = transf;
    // resNode.setSelectable(true);

    return resNode;
  }

  _notifyMattressBuilt(data, buildParams, bbox, result) {
    if (buildParams && buildParams.onAfterBuildMattress) {
      buildParams.onAfterBuildMattress(data, result, buildParams, bbox);
    }
  }

  buildMattress(data, buildParams, res, bbox) {
    let result = res;

    if (!data || data.enabled === false) {
      if (result instanceof ContainerNode3D) {
        result.removeChildren();
      }

      this._notifyMattressBuilt(data, buildParams, bbox, result);

      return result;
    }

    // #if DEBUG
    BD3DLogger.time('build mattress');
    // #endif

    let topBorderRadius = 10;
    let bottomBorderRadius = 10;
    let cornerRadius = 20;
    let topHeight = 10;
    let bottomHeight = 10;

    let width = 120;
    let length = 200;
    let height = 20;

    const box = data.box;

    if (box) {
      const boxSize = box.size;

      if (boxSize) {
        width = this._parseNum(boxSize.width, width);
        height = this._parseNum(boxSize.height, height);
        length = this._parseNum(boxSize.length, length);
      }
      const boxBorder = box.border;

      if (boxBorder) {
        topHeight = this._parseNum(boxBorder.top, topHeight);
        bottomHeight = this._parseNum(boxBorder.bottom, bottomHeight);
      }
      const boxRadius = box.radius;

      if (boxRadius) {
        topBorderRadius = this._parseNum(boxRadius.top, topBorderRadius);
        bottomBorderRadius = this._parseNum(boxRadius.bottom, bottomBorderRadius);
        cornerRadius = this._parseNum(boxRadius.corners, cornerRadius);
      }
    }
    // top & bottom quilt & sample

    let topTexture = null;
    let bottomTexture = null;
    let topQuilt = null;
    let bottomQuilt = null;

    let topTexture3D = null;
    let bottomTexture3D = null;
    let topQuilt3D = null;
    let bottomQuilt3D = null;

    if (data.top && data.top.texture && data.top.texture.id) {
      topTexture = data.top.texture;
    }
    if (data.top && data.top.quilt) {
      topQuilt = data.top.quilt;
    }

    if (data.bottom && data.bottom.texture && data.bottom.texture.id) {
      bottomTexture = data.bottom.texture;
    }

    if (data.bottom && data.bottom.quilt) {
      bottomQuilt = data.bottom.quilt;
    }

    if (!topTexture) {
      topTexture = data.texture;
    }
    if (!bottomTexture) {
      bottomTexture = data.texture;
    }
    if (!topQuilt) {
      topQuilt = data.quilt;
    }
    if (!bottomQuilt) {
      bottomQuilt = data.quilt;
    }

    topTexture3D = topTexture;
    bottomTexture3D = bottomTexture;
    topQuilt3D = topQuilt;
    bottomQuilt3D = bottomQuilt;

    const topHasMirrorPanel = topHeight < 0;
    const bottomHasMirrorPanel = bottomHeight < 0;

    topHeight = topHeight < 0 ? 0 : topHeight;
    bottomHeight = bottomHeight < 0 ? 0 : bottomHeight;

    const topVisible = data.top && data.top.visible !== false;
    const bottomVisible = data.bottom && data.bottom.visible !== false;

    // topheight & bottomheight values used for creating 3d geometry

    const minValue = 0.001;

    // Quick fix - result mesh has missing polygons when bottomHeight is 0
    // TODO: fix Quick fix
    bottomHeight = bottomHeight < minValue ? minValue : bottomHeight;
    topHeight = topHeight < minValue ? minValue : topHeight;
    topBorderRadius = topBorderRadius < minValue ? minValue : topBorderRadius;
    bottomBorderRadius = bottomBorderRadius < minValue ? minValue : bottomBorderRadius;
    cornerRadius = cornerRadius < minValue ? minValue : cornerRadius;

    const border = data.border;
    let borderComponents = null;

    if (border) {
      borderComponents = border.components;
    }

    let topMirrorPanelZipperData = null;
    let bottomMirrorPanelZipperData = null;
    let topMirrorPanelBorderComponent = null;
    let bottomMirrorPanelBorderComponent = null;
    let topMirrorPanelBorderComponentHeight = 0;
    let bottomMirrorPanelBorderComponentHeight = 0;

    let mirrorPanelSharedBorder = false; // true if top & border mesh share the same border component reference

    let topHeight3D = topHeight;
    let bottomHeight3D = bottomHeight;

    if (topHasMirrorPanel || bottomHasMirrorPanel) {
      borderComponents = borderComponents ? borderComponents.concat() : null;

      if (topHasMirrorPanel && borderComponents) {
        const topMirrorPanelMargin = topVisible ? DEFAULT_MIRRORPANEL_MARGIN : 0;
        let num = borderComponents.length;
        const firstComp = num > 0 ? borderComponents[0] : null;
        const firstCompType = firstComp ? firstComp.type : null;

        if (firstCompType === 'zipper') {
          topMirrorPanelZipperData = firstComp;
          borderComponents.shift();
          --num;
        }
        topMirrorPanelBorderComponent = null;
        // Get rid of all non-fabric border components
        // until a first fabric border component is found
        while (!topMirrorPanelBorderComponent && num > 0) {
          const bc = borderComponents[0];

          if (bc.type === 'fabric'/* && bc.type !== 'border3d' && bc.type !== 'border' */) {
            topMirrorPanelBorderComponent = bc;
          } else {
            borderComponents.shift();
            --num;
          }
        }
        // topMirrorPanelBorderComponent = (num > 0) ? borderComponents[0] : null;
        if (topMirrorPanelBorderComponent) {
          topMirrorPanelBorderComponentHeight = topMirrorPanelBorderComponent.height;
          topMirrorPanelBorderComponent.height -= topMirrorPanelMargin;
        }
      }
      if (bottomHasMirrorPanel && borderComponents) {
        const bottomMirrorPanelMargin = bottomVisible ? DEFAULT_MIRRORPANEL_MARGIN : 0;
        let num = borderComponents.length;
        const lastComp = borderComponents ? borderComponents[num - 1] : null;
        const lastCompType = lastComp ? lastComp.type : null;

        if (lastCompType === 'zipper') {
          bottomMirrorPanelZipperData = lastComp;
          borderComponents.pop();
          --num;
        }
        bottomMirrorPanelBorderComponent = null;
        while (!bottomMirrorPanelBorderComponent && num > 0) {
          const bc = borderComponents[num - 1];

          if (bc.type === 'fabric') {
            bottomMirrorPanelBorderComponent = bc;
          } else {
            borderComponents.pop();
            --num;
          }
        }
        // bottomMirrorPanelBorderComponent = (num > 0) ? borderComponents[num - 1] : null;
        if (bottomMirrorPanelBorderComponent) {
          bottomMirrorPanelBorderComponentHeight = bottomMirrorPanelBorderComponent.height;
          bottomMirrorPanelBorderComponent.height -= bottomMirrorPanelMargin;
        }
      }
      const MIN_BORDER_HEIGHT = 1;

      if (topMirrorPanelBorderComponent && topMirrorPanelBorderComponent.height < MIN_BORDER_HEIGHT) {
        topMirrorPanelBorderComponent.height = MIN_BORDER_HEIGHT;
      }
      if (bottomMirrorPanelBorderComponent && bottomMirrorPanelBorderComponent !== topMirrorPanelBorderComponent && bottomMirrorPanelBorderComponent.height < MIN_BORDER_HEIGHT) {
        bottomMirrorPanelBorderComponent.height = MIN_BORDER_HEIGHT;
      }

      if (!topMirrorPanelBorderComponent) {
        topMirrorPanelBorderComponent = null;
      }
      if (!bottomMirrorPanelBorderComponent) {
        bottomMirrorPanelBorderComponent = null;
      }
      mirrorPanelSharedBorder = (topMirrorPanelBorderComponent === bottomMirrorPanelBorderComponent);
      // Use top & bottom meshes as border meshes to support curved borders
      if (!mirrorPanelSharedBorder) {
        let idx;

        if (topHasMirrorPanel && topMirrorPanelBorderComponent) {
          // const margin = DEFAULT_MIRRORPANEL_MARGIN;

          topHeight3D = topMirrorPanelBorderComponent.height;// - margin - topBorderRadius * Math.PI * 0.5;
          topTexture3D = topMirrorPanelBorderComponent.texture;
          topQuilt3D = topMirrorPanelBorderComponent.quilt;

          idx = borderComponents.indexOf(topMirrorPanelBorderComponent, 0);
          if (idx >= 0) {
            borderComponents.splice(idx, 1);
          }
        }
        if (bottomHasMirrorPanel && bottomMirrorPanelBorderComponent) {
          // const margin = DEFAULT_MIRRORPANEL_MARGIN;

          bottomHeight3D = bottomMirrorPanelBorderComponent.height;// - margin - bottomBorderRadius * Math.PI * 0.5;
          bottomTexture3D = bottomMirrorPanelBorderComponent.texture;
          bottomQuilt3D = bottomMirrorPanelBorderComponent.quilt;

          idx = borderComponents.indexOf(bottomMirrorPanelBorderComponent, 0);
          if (idx >= 0) {
            borderComponents.splice(idx, 1);
          }
        }
      }
    }

    if (!borderComponents) {
      borderComponents = DEFAULT_BORDER_COMPONENTS;
      const comp = borderComponents[0];

      // Calculate fallback border height using box.height and top & bottom border radius
      comp.height = BorderShape.calculateBorderHeight(height, topBorderRadius, bottomBorderRadius, topHeight3D, bottomHeight3D);
    }

    const halfW = width * 0.5;
    const halfL = length * 0.5;
    const halfSize = halfW < halfL ? halfW : halfL;

    if (cornerRadius >= halfSize) {
      cornerRadius = halfSize - 0.001;
    }

    // var sourceMattressHeight = height;

    let topBR = topBorderRadius < cornerRadius ? topBorderRadius : cornerRadius;
    let bottomBR = bottomBorderRadius < cornerRadius ? bottomBorderRadius : cornerRadius;

    const borderHeight = this.calcBorderHeight(borderComponents, data);
    const bs = this.getBorderShape();

    bs.setData(topBR, bottomBR, borderHeight, topHeight3D, bottomHeight3D);
    height = bs.getMattressHeight();
    topBR = bs.getTopBorderRadius();
    bottomBR = bs.getBottomBorderRadius();

    // const scaleYTop = 1;
    // const scaleYBottom = -1;
    let borderCurveGraph = this.findBorderCurveGraph(data);

    if (borderCurveGraph) {
      if (!(borderCurveGraph instanceof Graph)) {
        this._defaultGraphInstance = borderCurveGraph = GraphUtils.graphFromJSONObject(borderCurveGraph, this._defaultGraphInstance);
      }
    }

    const topMeshExtraOutput = {};
    const bottomMeshExtraOutput = {};

    let topMeshGenParams = {
      spacing: 2,

      xEdgeSize: 4,
      zEdgeSize: 4,

      // cornerSubdivisions: 10,
      cornerEdgeSpacing: 0.5,
      // borderSubdivisions: 10,
      borderRoundEdgeSpacing: 0.5
      // sideLineSpacing: 2
    };

    // TODO: test - remove lines below
    /*
    topMeshGenParams = {
      spacing: 20,
      //cornerSubdivisions: 10,
      cornerEdgeSpacing: 10,
      //borderSubdivisions: 10,
      borderRoundEdgeSpacing: 10,
      //sideLineSpacing: 2
    };
    */
    // /*
    topMeshGenParams = {
      xEdgeSize: 4,
      zEdgeSize: 4,
      concentricEdgeSize: 2,
      borderEdgeSize: 2,
      cornerEdgeSize: 2
    };
    // */
    // end test

    const bottomMeshGenParams = topMeshGenParams;

    const graphTopHeight = topHeight3D - topBR;
    const graphBottomHeight = bottomHeight3D - bottomBR;

    const graphHeight = graphTopHeight > graphBottomHeight ? graphTopHeight : graphBottomHeight;
    const graphMinY = -graphBottomHeight / graphHeight;
    const graphMaxY = graphTopHeight / graphHeight;
    //

    let borderCurveAllowed = true;

    // Make sure no border curve will be applied when the top or bottom height is smaller than the top or bottom radius
    // Border curve can only change the y-coordinates of the vertices, it can't deform vertices following the top or bottom border circle
    // borderCurveAllowed = (topHeight >= topBR) && (bottomHeight >= bottomBR);
    borderCurveAllowed = (topHeight3D >= topBR) && (bottomHeight3D >= bottomBR);

    if (!borderCurveAllowed) {
      borderCurveGraph = null;
    }
    let topMesh = null;
    let bottomMesh = null;

    if (result && result.userData && result.userData.childMap) {
      topMesh = result.userData.childMap.topGeometry;
      bottomMesh = result.userData.childMap.bottomGeometry;
    }

    topMesh = MattressGeomUtils.createTopBottomGeometry(width, length, topHeight3D,
      cornerRadius, topBR, true,
      borderCurveGraph, graphMinY, graphMaxY, graphHeight, graphHeight,
      topMeshGenParams, topMeshExtraOutput, topMesh);

    bottomMesh = MattressGeomUtils.createTopBottomGeometry(width, length, bottomHeight3D,
      cornerRadius, bottomBR, false,
      borderCurveGraph, graphMinY, graphMaxY, graphHeight, graphHeight,
      bottomMeshGenParams, bottomMeshExtraOutput, bottomMesh);

    const topData = MattressDA.getTopData(data, true);
    const bottomData = MattressDA.getBottomData(data, true);
    let dataInfo = this._getCachedObjectInfo(data, buildParams);

    if (!dataInfo) {
      dataInfo = {};
      this._setCachedObjectInfo(data, dataInfo, buildParams);
    }

    let topContainerNode = dataInfo.topContainerNode; // this._getCachedValue(topData, buildParams);
    let bottomContainerNode = dataInfo.bottomContainerNode; // this._getCachedValue(bottomData, buildParams);

    let bottomMirrorPanelNode = null;
    let topMirrorPanelNode = null;

    if (topContainerNode && topContainerNode.userData) {
      topMirrorPanelNode = topContainerNode.userData.mirrorPanel;
    }
    if (bottomContainerNode && bottomContainerNode.userData) {
      bottomMirrorPanelNode = bottomContainerNode.userData.mirrorPanel;
    }

    bottomMirrorPanelNode = this._getMirrorPanel(false, data, buildParams, bottomMirrorPanelNode);
    topMirrorPanelNode = this._getMirrorPanel(true, data, buildParams, topMirrorPanelNode);
    if (topMirrorPanelNode && topMirrorPanelNode.setSelectable) {
      topMirrorPanelNode.setSelectable(true);
      this._setNodeSelectionMode(topMirrorPanelNode, 'component', true);
    }
    if (bottomMirrorPanelNode && bottomMirrorPanelNode.setSelectable) {
      bottomMirrorPanelNode.setSelectable(true);
      this._setNodeSelectionMode(bottomMirrorPanelNode, 'component', true);
    }

    if (topHasMirrorPanel && topVisible) {
      topMirrorPanelNode = this._createMirrorPanel(true, data, topTexture, bottomQuilt, topMirrorPanelZipperData, topHeight3D, buildParams, topMirrorPanelNode);
    }
    if (bottomHasMirrorPanel && bottomVisible) {
      bottomMirrorPanelNode = this._createMirrorPanel(false, data, bottomTexture, topQuilt, bottomMirrorPanelZipperData, bottomHeight, buildParams, bottomMirrorPanelNode);
    }

    // TODO: quickfix - add 'dynamic' property to indicate geometry rebuild
    // Used to avoid memory leaks in ThreeManager && BGR3DToThreeConverter
    this._setGeometryDynamic(topMesh, true);
    this._setGeometryDynamic(bottomMesh, true);

    const borderLoop = topMeshExtraOutput.borderLoop;

    // #if DEBUG
    window.topMesh = topMesh;
    window.bottomMesh = bottomMesh;
    // #endif

    // #if DEBUG
    BD3DLogger.time('border node');
    // #endif
    let borderNode = null;

    if (result && result.userData) {
      borderNode = result.userData.borderNode;
    }

    // bookmark
    // borderCurveGraph
    borderNode = this._buildBorder(borderComponents, data, bs, borderHeight, borderLoop, borderCurveGraph, buildParams, borderNode);

    // #if DEBUG
    BD3DLogger.timeEnd('border node');
    // #endif

    let handlesNode = null;
    const handle = MattressDA.getHandleData(data, false);

    if (handle) {
      handlesNode = this.buildHandles(handle, borderNode ? borderNode.children : null, data, bs, borderCurveGraph, buildParams, handlesNode);
    }
    if ((handlesNode instanceof BD3DContainerNode3D) || (handlesNode instanceof BD3DGeometryNode3D)) {
      handlesNode.setSelectable(true);
      this._setNodeSelectionMode(handlesNode, 'component', true);
    }

    this._associateDataWithNode(handle, handlesNode, buildParams);

    const middleNode = new BD3DContainerNode3D();

    if (borderNode) {
      middleNode.addChild(borderNode);
    }

    const handlesContainer = middleNode;

    if (handlesNode) {
      handlesContainer.addChild(handlesNode);
    }

    // this.initGeometry(topMesh);
    // this.initGeometry(bottomMesh);

    let topMeshNode = null;
    let bottomMeshNode = null;

    if (topContainerNode && topContainerNode.userData) {
      topMeshNode = topContainerNode.userData.geometryNode;
    }
    if (bottomContainerNode && bottomContainerNode.userData) {
      bottomMeshNode = bottomContainerNode.userData.geometryNode;
    }

    // Don't add top or bottom if a mirror panel is taking its place
    // if (!topHasMirrorPanel) {
    if (!topMeshNode) {
      topMeshNode = new BD3DGeometryNode3D(topMesh);
    }

    topMeshNode.geometry = topMesh;
    this._setGeometryNodeDynamic(topMeshNode);
    topMeshNode.setNodeType(BD3DNodeTypes.topPanel);
    topMeshNode.setSelectable(true);
    this._setNodeSelectionMode(topMeshNode, 'component', true);
    // }

    // if (!bottomHasMirrorPanel) {
    if (!bottomMeshNode) {
      bottomMeshNode = new BD3DGeometryNode3D(bottomMesh);
    }
    bottomMeshNode.geometry = bottomMesh;
    this._setGeometryNodeDynamic(bottomMeshNode);
    bottomMeshNode.setNodeType(BD3DNodeTypes.bottomPanel);
    bottomMeshNode.setSelectable(true);
    this._setNodeSelectionMode(bottomMeshNode, 'component', true);
    // }

    if (!topContainerNode) {
      topContainerNode = new BD3DContainerNode3D();
    }
    dataInfo.topContainerNode = topContainerNode;

    if (!bottomContainerNode) {
      bottomContainerNode = new BD3DContainerNode3D();
    }
    dataInfo.bottomContainerNode = bottomContainerNode;

    // Append top & bottom geometry to first / last border component(s)
    if ((topHasMirrorPanel || bottomHasMirrorPanel) && mirrorPanelSharedBorder) {
      if (borderNode instanceof ContainerNode3D) {
        const children = borderNode.getChildren();
        const numChildren = children ? children.length : 0;

        if (topHasMirrorPanel) {
          let firstNode = (children && numChildren > 0) ? children[0] : null;
          const firstGeomNode = findBorderGeometryNode(firstNode);
          let firstMat = (firstGeomNode && firstGeomNode.getMaterial) ? firstGeomNode.getMaterial() : null;

          if (!(firstNode instanceof ContainerNode3D)) {
            setNonSelectable(firstNode);

            const cnode = new BD3DContainerNode3D();

            if (firstNode.getNodeType && cnode.setNodeType) {
              cnode.setNodeType(firstNode.getNodeType());
            }
            cnode.addChild(firstNode);
            // children[numChildren - 1] = firstNode;
            firstNode = cnode;
            children[numChildren - 1] = firstNode;
          }

          if (!topMeshNode) {
            topMeshNode = new BD3DGeometryNode3D(topMesh);
          }
          firstMat = cloneMaterialForGeometry(firstMat, topMeshNode);

          topMeshNode.setMaterial(firstMat);
          topMeshNode.geometry = topMesh;
          this._setGeometryNodeDynamic(topMeshNode);
          const nodeType = firstGeomNode && firstGeomNode.getNodeType ? firstGeomNode.getNodeType() : BD3DNodeTypes.borderFabric;

          topMeshNode.setNodeType(nodeType);
          topMeshNode.setSelectable(false);
          if (!(topMeshNode.transform instanceof SRTTransform3D)) {
            topMeshNode.transform = new SRTTransform3D();
          }
          topMeshNode.transform.position.setCoord(1, height * 0.5);
          firstNode.addChild(topMeshNode);
          firstNode.setSelectable(true);
          this._associateDataWithNode(topMirrorPanelBorderComponent, firstNode, buildParams);
        }
        if (bottomHasMirrorPanel) {
          let lastNode = (children && numChildren > 0) ? children[numChildren - 1] : null;

          const lastGeomNode = findBorderGeometryNode(lastNode);
          let lastMat = (lastGeomNode && lastGeomNode.getMaterial) ? lastGeomNode.getMaterial() : null;


          if (!(lastNode instanceof ContainerNode3D)) {
            const cnode = new BD3DContainerNode3D();

            setNonSelectable(lastNode);

            if (lastNode.getNodeType && cnode.setNodeType) {
              cnode.setNodeType(lastNode.getNodeType());
            }
            cnode.addChild(lastNode);
            // children[numChildren - 1] = lastNode;
            lastNode = cnode;
            children[numChildren - 1] = lastNode;
          }

          if (!bottomMeshNode) {
            bottomMeshNode = new BD3DGeometryNode3D(bottomMesh);
          }
          lastMat = cloneMaterialForGeometry(lastMat, bottomMeshNode);
          bottomMeshNode.setMaterial(lastMat);
          bottomMeshNode.geometry = bottomMesh;
          this._setGeometryNodeDynamic(bottomMeshNode);
          const nodeType = lastGeomNode && lastGeomNode.getNodeType ? lastGeomNode.getNodeType() : BD3DNodeTypes.borderFabric;

          bottomMeshNode.setNodeType(nodeType);
          bottomMeshNode.setSelectable(false);
          if (!(bottomMeshNode.transform instanceof SRTTransform3D)) {
            bottomMeshNode.transform = new SRTTransform3D();
          }
          bottomMeshNode.transform.position.setCoord(1, -height * 0.5);
          lastNode.addChild(bottomMeshNode);
          lastNode.setSelectable(true);
          this._setNodeSelectionMode(lastNode, 'component', true);
          this._associateDataWithNode(bottomMirrorPanelBorderComponent, lastNode, buildParams);
        }
      }
    }

    // reset border height after subtracting the mirror panel margin
    if (topMirrorPanelBorderComponent) {
      topMirrorPanelBorderComponent.height = topMirrorPanelBorderComponentHeight;
    }
    if (bottomMirrorPanelBorderComponent && bottomMirrorPanelBorderComponent !== topMirrorPanelBorderComponent) {
      bottomMirrorPanelBorderComponent.height = bottomMirrorPanelBorderComponentHeight;
    }

    topContainerNode.removeChildren();
    bottomContainerNode.removeChildren();

    if (!topContainerNode.userData) {
      topContainerNode.userData = {};
    }
    topContainerNode.userData.geometryNode = topMeshNode;
    if (topMirrorPanelNode || topContainerNode.userData.mirrorPanelNode) {
      topContainerNode.userData.mirrorPanelNode = topMirrorPanelNode;
    }

    if (!bottomContainerNode.userData) {
      bottomContainerNode.userData = {};
    }
    bottomContainerNode.userData.geometryNode = bottomMeshNode;
    if (bottomMirrorPanelNode || bottomContainerNode.userData.mirrorPanelNode) {
      bottomContainerNode.userData.mirrorPanelNode = bottomMirrorPanelNode;
    }
    if (!topHasMirrorPanel || !mirrorPanelSharedBorder) {
      topContainerNode.addChild(topMeshNode);
    }
    if (topMirrorPanelNode) {
      topContainerNode.addChild(topMirrorPanelNode);
    }
    /*
    if (!bottomHasMirrorPanel || !mirrorPanelSharedBorder) {
      bottomContainerNode.addChild(bottomMeshNode);
    }
    */
    if (!bottomHasMirrorPanel || !mirrorPanelSharedBorder) {
      bottomContainerNode.addChild(bottomMeshNode);
    }
    if (bottomMirrorPanelNode) {
      bottomContainerNode.addChild(bottomMirrorPanelNode);
    }
    let topNode = topMeshNode;
    let bottomNode = bottomMeshNode;

    if (topHasMirrorPanel) {
      topNode = topMirrorPanelNode;
    }
    if (bottomHasMirrorPanel) {
      bottomNode = bottomMirrorPanelNode;
    }
    if (topMirrorPanelBorderComponent && !mirrorPanelSharedBorder) {
      this._associateDataWithNode(topMirrorPanelBorderComponent, topMeshNode, buildParams);
    }
    if (bottomMirrorPanelBorderComponent && !mirrorPanelSharedBorder) {
      this._associateDataWithNode(bottomMirrorPanelBorderComponent, bottomMeshNode, buildParams);
    }

    if (!topHasMirrorPanel) {
      this._associateDataWithNode(topData, topNode, buildParams);
    }
    if (!bottomHasMirrorPanel) {
      this._associateDataWithNode(bottomData, bottomNode, buildParams);
    }

    if (topMeshNode && (!topHasMirrorPanel || !mirrorPanelSharedBorder)) {
      const singlePart = topHasMirrorPanel ? 'border' : 'top';

      this._assignSampleMaterialData(topMeshNode, topTexture3D, topQuilt3D, singlePart, buildParams);
    }
    if (bottomMeshNode && (!bottomHasMirrorPanel || !mirrorPanelSharedBorder)) {
      const singlePart = bottomHasMirrorPanel ? 'border' : 'bottom';

      this._assignSampleMaterialData(bottomMeshNode, bottomTexture3D, bottomQuilt3D, singlePart, buildParams);
    }

    let ypos = 0;
    const borderYSpace = bs.getBorderHeight();

    this.setNodePosition(bottomContainerNode, 0, ypos, 0);
    if (bottomMeshNode && !mirrorPanelSharedBorder) {
      this.setNodePosition(bottomMeshNode, 0, ypos + bottomHeight3D, 0);
    }
    ypos += bottomHeight3D;
    // this.setNodePosition(bottomContainerNode, 0, ypos, 0);
    ypos += borderYSpace * 0.5;
    this.setNodePosition(middleNode, 0, height * 0.5, 0);
    ypos += borderYSpace * 0.5;
    this.setNodePosition(topContainerNode, 0, height - topHeight3D, 0);

    this.setMeshName(middleNode, 'middle');
    this.setMeshName(topMesh, 'top');
    this.setMeshName(topMeshNode, 'top');
    this.setMeshName(topContainerNode, 'top');
    this.setMeshName(bottomMesh, 'bottom');
    this.setMeshName(bottomMeshNode, 'bottom');
    this.setMeshName(bottomContainerNode, 'bottom');
    this.setMeshName(borderNode, 'border');
    this.setMeshName(handlesNode, 'handlers');

    if (result) {
      if (result.children) {
        result.children.length = 0;
      }
      if (!result.children) {
        result.children = [];
      }
      result.children[0] = topContainerNode;
      result.children[1] = middleNode;
      result.children[2] = bottomContainerNode;
    } else {
      result = new BD3DContainerNode3D([topContainerNode, middleNode, bottomContainerNode]);
    }

    const legs = MattressDA.getLegs(data, false);

    if (legs) {
      result.addChild(this.buildLegs(legs, data, buildParams));
    }

    if (!result.transform) {
      result.transform = new SRTTransform3D();
    }

    let posX = 0, posY = 0, posZ = 0;
    let rotX = 0, rotY = 0, rotZ = 0;

    if (data.translation) {
      posX = data.translation.x;
      posY = data.translation.y;
      posZ = data.translation.z;
    }
    if (data.rotation) {
      rotX = data.rotation.x;
      rotY = data.rotation.y;
      rotZ = data.rotation.z;
    }

    if (legs) {
      const legHeight = MattressDA.getLegHeight(data);

      posY += legHeight;
    }

    // calculate bounding box
    if (posX === 0) {
      bbox.widthMinZero = width / 2;
    }

    const singleBoundingHeight = height + posY;
    const singleBoundingWidth = width / 2 + posX;
    const singleBoundingLength = length + posZ;

    if (singleBoundingWidth > (bbox.width - bbox.widthMinZero)) {
      bbox.width = singleBoundingWidth + bbox.widthMinZero;
    }
    if (singleBoundingHeight > bbox.height) {
      bbox.height = singleBoundingHeight;
    }
    if (singleBoundingLength > bbox.length) {
      bbox.length = singleBoundingLength;
    }

    bbox.x = bbox.widthMinZero;
    if (bbox.width === bbox.widthMinZero * 2) {
      bbox.x = 0;
    }
    bbox.y = bbox.height / 2;
    bbox.z = 0;

    result.transform.position.setCoord(0, posX);
    result.transform.position.setCoord(1, posY);
    result.transform.position.setCoord(2, posZ);

    result.transform.rotation.setCoord(0, rotX);
    result.transform.rotation.setCoord(1, rotY);
    result.transform.rotation.setCoord(2, rotZ);

    if (!result.userData) {
      result.userData = {};
    }
    if (result.userData.childMap) {
      const childMap = result.userData.childMap;

      childMap.topGeometry = topMesh;
      childMap.bottomGeometry = bottomMesh;
      childMap.top = topContainerNode;
      childMap.bottom = bottomContainerNode;
    } else {
      result.userData.childMap = {
        topGeometry: topMesh,
        bottomGeometry: bottomMesh,
        top: topContainerNode,
        bottom: bottomContainerNode
      };
    }

    this._associateDataWithNode(data, result, buildParams);

    if (this._objectHasNoise(result, data, buildParams)) {
      this._addNoise(result, data, buildParams);
    } else {
      this.initGeometry(topMesh);
      this.initGeometry(bottomMesh);
    }

    result.setSelectable(true);

    // #if DEBUG
    BD3DLogger.timeEnd('build mattress');
    // #endif

    this._notifyMattressBuilt(data, buildParams, bbox, result);

    return result;
  }

  _singleHasNoise(data, buildParams) {
    // #if DEBUG
    if (window.NOISE === true) {
      return true;
    }
    // #endif

    return MattressDA.hasNoise(data);
  }

  _geometryHasNoise(geom, data, buildParams) {
    const singleNoise = this._singleHasNoise(data, buildParams);

    if (!singleNoise) {
      return false;
    }
    if (!geom) {
      return false;
    }
    if (geom.userData && geom.userData.noise === false) {
      return false;
    }

    return true;
  }

  _objectHasNoise(object, data, buildParams) {
    const singleNoise = this._singleHasNoise(data, buildParams);

    if (!singleNoise) {
      return false;
    }
    if (object.userData && object.userData.noise === false) {
      return false;
    }

    return true;
  }

  static _getDefaultNoiseSettings() {
    let res = this._defaultNoiseSettings;

    if (res) {
      return res;
    }
    res = {};

    const intensityX = 0.7, intensityY = 0, intensityZ = 0.7,
      offsetX = 0, offsetY = 0, offsetZ = 0, scale = 0.08;

    res.intensityX = intensityX;
    res.intensityY = intensityY;
    res.intensityZ = intensityZ;
    res.scale = scale;
    res.offsetX = offsetX;
    res.offsetY = offsetY;
    res.offsetZ = offsetZ;

    return res;
  }

  _getDefaultNoiseSettings() {
    return Mattress3DBuilder._getDefaultNoiseSettings();
  }

  _addNoise(object, data, buildParams, parentMatrix = null, enabled = true) {
    // TODO: fix pocket handles bug when noise is added
    // TODO: fix mirror panel noise (more subdivs?)
    let enabl = enabled;

    if (!this._objectHasNoise(object, data, buildParams)) {
      enabl = false;
    }
    if (object instanceof Node3D) {
      let resultMatrix;

      const transform = object.transform;
      const localMatrix = transform ? transform.getMatrix4(true) : null;

      if (localMatrix && parentMatrix) {
        resultMatrix = Matrix4Math.multiply(parentMatrix, localMatrix);
      } else if (localMatrix) {
        resultMatrix = localMatrix;
      } else if (parentMatrix) {
        resultMatrix = parentMatrix;
      }

      if (object instanceof ContainerNode3D) {
        const children = object.getChildren();
        const numChildren = children ? children.length : 0;

        for (let i = 0; i < numChildren; ++i) {
          this._addNoise(children[i], data, buildParams, resultMatrix, enabl);
        }
      } else if (object instanceof BD3DGeometryNode3D) {
        object.useModifiers = enabl;
        if (!enabl) {

          return;
        }
        const noiseSettings = this._getDefaultNoiseSettings();
        const noiseVal = MattressDA.getNoiseValue(data, 0) * 0.1;
        const noiseScale = MattressDA.getNoiseScale(data, 0.125);

        noiseSettings.intensityX = noiseVal;
        noiseSettings.intensityY = noiseVal;
        noiseSettings.intensityZ = noiseVal;
        noiseSettings.scale = noiseScale;
        noiseSettings.offsetX = 0;
        noiseSettings.offsetY = 0;
        noiseSettings.offsetZ = 0;

        const srcGeom = object.getSourceGeometry();
        let resGeom = object.getResultGeometry();

        resGeom = NoiseGeometryModifier.getModifiedGeometry(srcGeom, noiseSettings, null, resultMatrix, resGeom);

        object.setResultGeometry(resGeom);
      }

      // #if DEBUG
      if ((object instanceof GeometryNode3D) && !(object instanceof BD3DGeometryNode3D)) {
        BD3DLogger.warn('Can\'t apply noise to ', object, '(not an instance of BD3DGeometryNode3D)');
      }
      // #endif
    }
  }

  buildHandles(handle, borderElements, mattressData, borderShape, borderCurveGraph, buildParams, resultNode = null) {
    if (resultNode) {
      resultNode.removeChildren();
    }
    if (!handle || !mattressData) {
      return resultNode;
    }
    if (!handle.type) {
      return resultNode;
    }
    const type = handle.type.toUpperCase();

    if (!type) {
      return resultNode;
    }
    let handleType = HandleTypes[type];

    if (!handleType) {
      const handleStyle = HandleStyles.getFromJSON(handle);

      if (handleStyle) {
        handleType = handleStyle.type;
      }
    }

    if (!handleType) {
      return resultNode;
    }
    let handleErrorInfo = this._handleErrorInfo;

    if (!handleErrorInfo) {
      handleErrorInfo = this._handleErrorInfo = {};
    }
    handleErrorInfo.error = null;

    if (!handleType.isAllowed(handle, mattressData, handleErrorInfo)) {
      // this._throwError('Can\'t use handles on this mattress', handleErrorInfo, buildParams);
      this._throwError(handleErrorInfo.error, buildParams);

      return resultNode;
    }
    // #if DEBUG
    BD3DLogger.log('Yay! We\'re allowed to add handles');
    // #endif

    return handleType.create3DHandles(handle, borderElements, mattressData, borderShape, borderCurveGraph, buildParams, resultNode);
  }

  _throwError(err, buildParams) {
    if (buildParams && buildParams.onerror) {
      buildParams.onerror(err);

      return;
    } else if (buildParams && buildParams.throwErrors) {
      throw new Error(err);
    }
    // #if DEBUG
    BD3DLogger.error(err);
    // #endif
  }

  _disposeGeometry(geom) {
    if (!geom) {
      return;
    }
    const ud = geom.userData;

    if (!ud) {
      return;
    }

    const ed = ud.eventDispatcher;

    if (!ed) {
      return;
    }
    let de = ud.disposeEvent;

    if (!de) {
      de = ud.disposeEvent = {};
    }
    de.type = 'dispose';
    de.geometry = geom;

    ed.dispatchEvent(de);
  }

  _disposeNode(node) {
    if (!node) {
      return;
    }
    if (node instanceof GeometryNode3D) {
      this._disposeGeometry(node.geometry);
    } else if (node instanceof ContainerNode3D) {
      const children = node.getChildren();

      if (!children) {
        return;
      }
      const num = children.length;

      if (num === 0) {
        return;
      }

      for (let i = 0; i < num; ++i) {
        this._disposeNode(children[i]);
      }
    }
  }

  _associateDataWithNode(data, node, buildParams) {
    if (!buildParams) {
      return;
    }
    if (!data && !node) {
      return;
    }
    // #if DEBUG
    if (!data || !node) {
      BD3DLogger.warn('Associate data with node3d - invalid values', data, node);
    }
    // #endif
    let map = null;

    map = buildParams.dataNode3DMap;
    if (!map) {
      map = buildParams.dataNode3DMap = new Map();
    }
    if (data) {
      map.set(data, node);
    }
    if (node) {
      map.set(node, data);
    }
  }

  _findPrevBorderComp(i, components, mattressData) {
    if (!components) {
      return null;
    }
    if (i <= 0) {
      return null;
    }
    let j = i - 1;
    let comp = components[j];

    if (this._validBorderComponent(comp, mattressData)) {
      return comp;
    }
    --j;
    while (j >= 0) {
      comp = components[j];

      if (this._validBorderComponent(comp, mattressData)) {
        return comp;
      }

      --j;
    }

    return null;
  }

  _findNextBorderComp(i, components, mattressData) {
    if (!components) {
      return null;
    }
    const num = components.length;

    if (i >= (num - 1)) {
      return null;
    }
    let j = i + 1;
    let comp = components[j];

    if (this._validBorderComponent(comp, mattressData)) {
      return comp;
    }
    ++j;
    while (j < num) {
      comp = components[j];
      if (this._validBorderComponent(comp, mattressData)) {
        return comp;
      }
      ++j;
    }

    return null;
  }

  _validBorderComponent(comp, mattressData) {
    if (!comp) {
      return false;
    }
    const compTypeName = comp.type;

    if (!compTypeName) {
      return false;
    }

    const compType = this._getBorderComponentTypeByName(compTypeName);

    if (!compType) {
      return false;
    }

    return compType.isValid(comp, mattressData);
  }

  buildBorder(borderComponents, mattressData, borderShape, borderHeight, borderLoop, borderCurveGraph, buildParams, result) {
    const res = result;
    let components = borderComponents;
    let border = null;

    if (!borderComponents || !borderComponents.length) {
      if (!mattressData) {
        return res;
      }
      border = mattressData.border;

      if (!border) {
        return res;
      }
      const bcomponents = border.components;

      if (!bcomponents || !bcomponents.length) {
        return res;
      }

      components = bcomponents;
    }

    return this._buildBorder(components, mattressData, borderShape, borderHeight, borderLoop, borderCurveGraph, buildParams, result);
  }

  _buildBorder(borderComponents, mattressData, borderShape, borderHeight, borderLoop, borderCurveGraph, buildParams, result) {
    let res = result;
    const components = borderComponents;

    const border = mattressData ? mattressData.border : null;

    if (!components) {
      return res;
    }
    const num = components.length;

    if (num === 0) {
      return res;
    }

    let yOffset = 0;

    if (res && res instanceof ContainerNode3D) {
      // dispose old geometries
      const children = res.getChildren();
      const numChildren = children ? children.length : 0;

      for (let i = 0; i < numChildren; ++i) {
        this._disposeNode(children[i]);
      }
    }

    // const borderCompGeomMap = null;

    for (let i = 0; i < num; ++i) {
      const comp = components[i];

      if (comp) {
        const type = comp.type;

        if (type) {
          const borderCompType = this._getBorderComponentTypeByName(type);

          if (borderCompType) {
            const vertArray = []; // vertices to be extruded
            const top = borderCompType.getTop(comp, mattressData);
            const bottom = borderCompType.getBottom(comp, mattressData);
            const depth = borderCompType.getDepth(comp, mattressData);

            const topY = yOffset;

            yOffset -= top;
            const centerY = yOffset;

            let borderCompGeom = null;
            /*
            if (borderCompGeomMap) {
              borderCompGeom = borderCompGeomMap.get(comp);
            }
            */

            const prevComp = this._findPrevBorderComp(i, components, mattressData);
            const nextComp = this._findNextBorderComp(i, components, mattressData);

            // const prevComp = i > 0 ? components[i - 1] : null;
            // const nextComp = i < (num - 1) ? components[i + 1] : null;
            const prevCompTypeName = prevComp ? prevComp.type : null;
            const nextCompTypeName = nextComp ? nextComp.type : null;

            const prevCompType = this._getBorderComponentTypeByName(prevCompTypeName);
            const nextCompType = this._getBorderComponentTypeByName(nextCompTypeName);

            // Find out what the start and end x values should be,
            // based on the minimum depth values between the current border component
            // & its neighbours

            let startX = null;
            let endX = null;

            if (prevCompType) {
              const prevDepth = prevCompType.getDepth(prevComp, mattressData);

              if (depth !== prevDepth) {
                startX = depth < prevDepth ? depth : prevDepth;
              }
            }
            if (nextCompType) {
              const nextDepth = nextCompType.getDepth(nextComp, mattressData);

              if (depth !== nextDepth) {
                endX = depth < nextDepth ? depth : nextDepth;
              }
            }

            if (startX !== null && startX !== undefined) {
              startX = -startX;
            }
            if (endX !== null && endX !== undefined) {
              endX = -endX;
            }
            if (i === 0) {
              startX = 0;
            }
            if (i === (num - 1)) {
              endX = 0;
            }

            // Negative startX & endX because the border 'expands' to the left
            borderCompType.addVertices(comp, mattressData, yOffset, startX, endX, borderShape, vertArray, 0);

            borderCompGeom = this._makeShapePathExtrusion(vertArray, borderLoop, comp, mattressData, borderCompGeom);

            const geomUVData = MattressGeomUtils.getGeometryUVWorldTransform(borderCompGeom) || {};

            if (borderCompGeom && borderCompGeom.polygons) {
              GeomUtils.normalizePolygonUVs(borderCompGeom.polygons, {
                minScale: 1, maxScale: 1,
                uvAlignX: 0, uvAlignY: 1
              }, geomUVData);
            }

            MattressGeomUtils.assignGeometryUVWorldTransform(borderCompGeom, geomUVData);

            if (!this._geometryHasNoise(borderCompGeom, mattressData, buildParams)) {
              this.initGeometry(borderCompGeom);
            }

            /*
            if (borderCompGeomMap) {
              borderCompGeomMap.set(comp, borderCompGeom);
            }
            */

            yOffset += bottom;
            const bottomY = yOffset;

            // var polys = borderCompGeom.polygons;
            // BD3DLogger.info('BORDER VERTICES=', borderCompGeom.vertices.length, ns.GeomUtils.getMeshVerts.call(this, polys).length);
            // BD3DLogger.info('BORDER POLYVERTICES=',borderCompGeom.polygonVertices.length, ns.GeomUtils.getPolygonVerts.call(this, polys).length);
            if (borderCompGeom) {
              let ud = borderCompGeom.userData;

              if (!ud) {
                ud = borderCompGeom.userData = {};
              }
              ud.borderTopOffset = topY;
              ud.borderMiddleOffset = centerY;
              ud.borderBottomOffset = bottomY;
              ud.borderComponentType = borderCompType;

              let borderCompNode = borderCompType.createNode3D(borderCompGeom, comp, mattressData, borderLoop, borderShape, borderCurveGraph, buildParams);

              if (!borderCompNode) {
                borderCompNode = new BD3DGeometryNode3D(borderCompGeom);

                let material = Node3DMaterialUtils.getMaterial(borderCompNode);

                material = borderCompType.getMaterial(comp, mattressData, buildParams, material);

                Node3DMaterialUtils.setMaterial(borderCompNode, material);
              }
              if (borderCompNode.setNodeType) {
                borderCompNode.setNodeType(borderCompType.getNodeType());
              }

              ud = borderCompNode.userData;
              if (!ud) {
                ud = borderCompNode.userData = {};
              }
              ud.borderTopOffset = topY;
              ud.borderMiddleOffset = centerY;
              ud.borderBottomOffset = bottomY;
              ud.borderComponentType = borderCompType;

              if (borderCompNode instanceof BD3DGeometryNode3D) {
                borderCompNode.setSelectable(true);
              } else if (borderCompNode instanceof BD3DContainerNode3D) {
                borderCompNode.setSelectable(true);
              }
              this._setNodeSelectionMode(borderCompNode, 'component', true);

              // Node3DMaterialUtils.assignMaterialSettings(node, materialLib);
              // Node3DMaterialUtils.setMaterialSettings(node, materialSettings);

              if (!res) {
                res = new BD3DContainerNode3D();
              }
              this._associateDataWithNode(comp, borderCompNode, buildParams);

              res.addChild(borderCompNode);
            }
          }
        }
      }
    }
    if (border) {
      this._associateDataWithNode(border, res, buildParams);
    }
    if (components) {
      this._associateDataWithNode(components, res, buildParams);
    }

    return res;
  }

  _getVertexUV(v) {
    if (v instanceof Vertex) {
      const atts = v.attributes;

      if (!atts) {
        return null;
      }

      return atts.uv;
    }
    if (v instanceof Vector2) {
      return v;
    }

    return null;
  }

  _getVertexNormal(v) {
    if (v instanceof Vertex) {
      const atts = v.attributes;

      if (!atts) {
        return null;
      }
      const nrm = atts.normal;

      if (nrm) {
        return nrm;
      }

      return this._getVertexNormal(v.position);
    }
    if (v instanceof Vector3) {
      return v;
    }

    return null;
  }

  _getPathVertexMatrix(v, result, upx_, upy_, upz_) {
    let upx = upx_;
    let upy = upy_;
    let upz = upz_;
    const nrm = this._getVertexNormal(v);
    let res = result;

    if (!nrm) {
      if (res) {
        Matrix4Math.identity(res);
      }

      return res;
    }
    const x = v.getCoord(0);
    const y = v.getCoord(1);
    const z = v.getCoord(2);

    res = Matrix4Math.identity(res);

    if (upx === undefined || upx === null) {
      upx = 0;
    }
    if (upy === undefined || upy === null) {
      upy = 1;
    }
    if (upz === undefined || upz === null) {
      upz = 0;
    }
    let d;
    let zAxisX = nrm.getCoord(0);
    let zAxisY = nrm.getCoord(1);
    let zAxisZ = nrm.getCoord(2);

    d = zAxisX * zAxisX + zAxisY * zAxisY + zAxisZ * zAxisZ;
    if (d !== 0 && d !== 1) {
      d = 1.0 / Math.sqrt(d);
      zAxisX *= d;
      zAxisY *= d;
      zAxisZ *= d;
    }

    const xAxisX = upz * zAxisY - upy * zAxisZ;
    const xAxisY = upx * zAxisZ - upz * zAxisX;
    const xAxisZ = upy * zAxisX - upx * zAxisY;

    d = xAxisX * xAxisX + xAxisY * xAxisY + xAxisZ * xAxisZ;
    if (d !== 0 && d !== 1) {
      d = 1.0 / Math.sqrt(d);
      zAxisX *= d;
      zAxisY *= d;
      zAxisZ *= d;
    }

    let yAxisX = xAxisY * zAxisZ - xAxisZ * zAxisY;
    let yAxisY = xAxisZ * zAxisX - xAxisX * zAxisZ;
    let yAxisZ = xAxisX * zAxisY - xAxisY * zAxisX;

    const dot = yAxisX * upx + yAxisY * upy + yAxisZ * upz;

    if (dot < 0) {
      yAxisX *= -1;
      yAxisY *= -1;
      yAxisZ *= -1;
    }

    d = yAxisX * yAxisX + yAxisY * yAxisY + yAxisZ * yAxisZ;
    if (d !== 0 && d !== 1) {
      d = 1.0 / Math.sqrt(d);
      yAxisX *= d;
      yAxisY *= d;
      yAxisZ *= d;
    }

    res[0] = xAxisX;
    res[1] = xAxisY;
    res[2] = xAxisZ;

    res[4] = yAxisX;
    res[5] = yAxisY;
    res[6] = yAxisZ;

    res[8] = zAxisX;
    res[9] = zAxisY;
    res[10] = zAxisZ;

    res[12] = x;
    res[13] = y;
    res[14] = z;

    return res;
  }

  _transformShapeVertex(v, m) {
    if (!m) {
      return v;
    }
    let x;
    let y;
    let z;
    let nx = 0;
    let ny = 0;
    let nz = 1;
    let tx;
    let ty;
    let tz;

    let nrm = this._getVertexNormal(v);

    // Swap x and z coordinates
    const X = 2;
    const Y = 1;
    const Z = 0;

    x = v.getCoord(X);
    y = v.getCoord(Y);
    z = -v.getCoord(Z);

    if (nrm) {
      nx = nrm.getCoord(X);
      ny = nrm.getCoord(Y);
      nz = -nrm.getCoord(Z);
    }

    tx = x * m[0] + y * m[4] + z * m[8] + m[12];
    ty = x * m[1] + y * m[5] + z * m[9] + m[13];
    tz = x * m[2] + y * m[6] + z * m[10] + m[14];
    x = tx;
    y = ty;
    z = tz;

    tx = nx * m[0] + ny * m[4] + nz * m[8];
    ty = nx * m[1] + ny * m[5] + nz * m[9];
    tz = nx * m[2] + ny * m[6] + nz * m[10];
    nx = tx;
    ny = ty;
    nz = tz;

    const pos = new Vector3(x, y, z);
    const vert = new Vertex();

    nrm = new Vector3(nx, ny, nz);
    vert.position = pos;
    if (!vert.attributes) {
      vert.attributes = {};
    }
    vert.attributes.normal = nrm;

    const polyVert = new Vertex();

    polyVert.position = vert;
    polyVert.attributes = {
      normal: nrm
    };

    return polyVert;
  }

  // TODO: use BorderGeomUtils.borderExtrusion(shape, path, hasUVCorrection, mattressData, params, res);
  _makeShapePathExtrusion(shape, path, comp, mattressData, params, res = null) {
    if (!path || !shape) {
      return res;
    }
    const numPathVerts = path.length;

    if (numPathVerts === 0) {
      return null;
    }
    const numShapeVerts = shape.length;

    if (numShapeVerts === 0) {
      return null;
    }
    let x;
    let y;
    let row;

    // Create grid of vertices around the path
    const grid = [];

    const numPathVertsWrapped = numPathVerts + 1;

    // Current texture-U coordinate
    let curU = 0;

    // Previous path coords, used to calculate the UV coordinates
    let prevPathX = 0;
    let prevPathZ = 0;

    let pvMatrix = null;

    const vertices = [];
    const polyVertices = [];
    const uvs = [];
    let uv;

    let computeTangents;

    if (params) {
      computeTangents = params.computeTangents;
    }
    if (computeTangents === undefined || computeTangents === null) {
      computeTangents = true;
    }
    let uvCorrection = 1;

    const compTypeObject = this._getBorderComponentTypeByName(comp.type);

    if (compTypeObject && compTypeObject.hasTextureUVCorrection() === false) {
      uvCorrection = 0;
    }

    for (x = 0; x < numPathVertsWrapped; ++x) {
      const wrapX = x % numPathVerts;
      const pv = path[wrapX];
      const pX = pv.getCoord(0);
      const pZ = pv.getCoord(2);

      let texVOffset = 0;

      if (pv && pv.attributes) {
        const pvUV = pv.attributes.uv;

        if (pvUV) {
          texVOffset = pvUV.getCoord(1);
        }
      }

      let dist = 0;

      if (x > 0) {
        const dx = pX - prevPathX;
        const dz = pZ - prevPathZ;

        dist = dx * dx + dz * dz;
        if (dist !== 0 && dist !== 1) {
          dist = Math.sqrt(dist);
        }
      }
      curU += dist;


      pvMatrix = this._getPathVertexMatrix(pv, pvMatrix);
      row = grid[x];
      if (!row) {
        row = grid[x] = [];
      }
      const wrapRow = grid[wrapX];

      for (y = 0; y < numShapeVerts; ++y) {
        const sv = shape[y]; // shape vertex
        const svUV = this._getVertexUV(sv);
        let curV = svUV ? svUV.getCoord(1) : 0;

        const wrapVert = wrapRow[y]; // returns the vertex in the first row if evaluating the last row
        let svt = null; // shape vertex (transformed)

        if (wrapVert) {
          // Reuse the first position & normal, but use different uv coords
          svt = new Vertex();
          svt.position = wrapVert.position;
          svt.attributes = {
            normal: this._getVertexNormal(wrapVert)
          };
        } else {
          // Create a new vertex by transforming the vertex from the
          // source shape using the transformation matrix of the current path point
          svt = this._transformShapeVertex(sv, pvMatrix);

          vertices.push(svt.position);
        }
        if (!svt.attributes) {
          svt.attributes = {};
        }
        uv = svt.attributes.uv;
        if (!uv) {
          uv = svt.attributes.uv = new Vector2();
          uvs.push(uv);
        }
        curV += texVOffset * uvCorrection;

        uv.setCoords(curU, -curV);
        polyVertices.push(svt);

        row[y] = svt;
      }

      prevPathX = pX;
      prevPathZ = pZ;
    }
    // Create quads from the grid vertices
    const polys = [];

    // for (x = 0; x < numPathVertsWrapped; ++x) {

    for (x = 0; x < numPathVerts; ++x) {
      // const x2 = (x + 1) % numPathVertsWrapped;
      const x2 = (x + 1) % (numPathVertsWrapped);

      row = grid[x];
      const nextRow = grid[x2];

      for (y = 0; y < numShapeVerts - 1; ++y) {
        const y2 = (y + 1) % numShapeVerts;

        // find vertices in grid
        const vTL = row[y];
        const vTR = nextRow[y];
        const vBL = row[y2];
        const vBR = nextRow[y2];

        // var polyVerts = [vTL, vTR, vBR, vBL];
        const polyVerts = [vBL, vBR, vTR, vTL];
        const poly = new Polygon(polyVerts);

        polys.push(poly);
      }
    }

    if (computeTangents) {
      GeomUtils.calculateTangentsForPolygons(polys);
    }
    /*
    let adjustUVs = false;

    if (compType === 'zipper') {
      adjustUVs = true;
    }
    */

    /*
    if (adjustUVs) {
      // fit uvs
      const numUvs = uvs.length;
      let first = true;
      let minU = 0;
      let maxU = 0;
      let minV = 0;
      let maxV = 0;
      let i;
      let U;
      let V;

      for (i = 0; i < numUvs; ++i) {
        uv = uvs[i];
        if (uv) {
          U = uv.getCoord(0);
          V = uv.getCoord(1);
          if (first) {
            first = false;
            minU = maxU = U;
            minV = maxV = V;
          } else {
            minU = U < minU ? U : minU;
            minV = V < minV ? V : minV;
            maxU = U > maxU ? U : maxU;
            maxV = V > maxV ? V : maxV;
          }
        }
      }
      if (!first) {
        const texW = maxU - minU;
        const texH = maxV - minV;

        if (texW !== 0 && texH !== 0) {
          const scaleX = 1 / texW;
          const scaleY = 1 / texH;

          if (compType === 'zipper') {
            const scale = scaleX > scaleY ? scaleX : scaleY;

            for (i = 0; i < numUvs; ++i) {
              uv = uvs[i];
              if (uv) {
                U = uv.getCoord(0);
                V = uv.getCoord(1);
                U = (U - minU) * scale;
                V = (V - minV) * scale;
                uv.setCoords(U, V);
              }
            }
          }
        }
      }
    }

    const uvData = {};

    GeomUtils.normalizeUVs(uvs, params, uvData);
    */

    let geom = res;

    if (!geom) {
      geom = new BD3DGeometry();
    }

    geom.polygons = polys;
    geom.polygonVertices = polyVertices;
    geom.vertices = vertices;

    let ud = geom.userData;

    if (!ud) {
      ud = geom.userData = {};
    }
    ud.borderComponent = comp;

    const borderComponentType = this._getBorderComponentTypeByName(comp.type);

    if (borderComponentType) {
      borderComponentType.adjustUVs(uvs, geom, comp, mattressData);
    }

    return geom;
  }

  initGeometry(geom, translateX, translateY, translateZ) {
    // TODO: translate mesh + scale by 10 to convert cm to mm
    if (!geom) {
      return;
    }
    // TODO: fix normals of top geometry if top height === 0
    // GeomUtils.calculatePolygonVertexNormals(geom);
    GeomUtils.calculateVertexNormals(geom);
  }

  setNodePosition(node, x, y, z) {
    if (!node) {
      return;
    }
    let tr = node.transform;

    if (!tr || !(tr instanceof SRTTransform3D)) {
      tr = new SRTTransform3D();
      node.transform = tr;
    }

    let pos = tr.position;

    if (pos) {
      pos.setCoords(x, y, z);
    } else {
      pos = tr.position = new Vector3(x, y, z);
    }
  }

  getMeshName(mesh) {
    if (!mesh) {
      return null;
    }
    const ud = mesh.userData;

    if (!ud) {

      return null;
    }

    return ud.name;
  }

  setMeshName(mesh, name) {
    if (!mesh) {
      return;
    }
    let ud = mesh.userData;

    if (!ud && name) {
      ud = mesh.userData = {};
    }
    if (ud) {
      ud.name = name;
    }
  }
}
