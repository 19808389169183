import * as THREE from 'three';

// Recursively update world matrices from an Object3D to its parents
function updateWorldMatrixUp(object) {
  if (!object || !(object instanceof THREE.Object3D)) {
    return;
  }
  if (object.matrixAutoUpdate) {
    object.updateMatrix();
  }
  let mw = object.matrixWorld;

  if (!mw) {
    mw = object.matrixWorld = new THREE.Matrix4();
  }
  mw.identity();
  mw.multiplyMatrices(object.matrix, mw);
  const p = object.parent;

  if (p) {
    updateWorldMatrixUp(p);
    if (p.matrixWorld) {
      mw.multiplyMatrices(p.matrixWorld, mw);
    }
  }
}

// Recursively update world matrices from an Object3D to its children
function updateWorldMatrixDown(object, parentMatrix = null) {
  if (!object) {
    return;
  }
  if (object instanceof THREE.Object3D) {
    let mw = object.matrixWorld;

    if (!mw) {
      mw = object.matrixWorld = new THREE.Matrix4();
    }
    if (object.matrixAutoUpdate) {
      object.updateMatrix();
    }
    mw.identity();
    mw.multiplyMatrices(object.matrix, mw);
    if (parentMatrix) {
      mw.multiplyMatrices(parentMatrix, mw);
    }

    updateWorldMatrixDown(object.children, mw);
  } else if ((object instanceof Array) || (Array.isArray && Array.isArray(object))) {
    const children = object;
    const numChildren = children ? children.length : 0;

    if (!numChildren) {
      return;
    }
    for (let i = 0; i < numChildren; ++i) {
      const child = children[i];

      updateWorldMatrixDown(child, parentMatrix);
    }
  }

}

export default class ThreeSceneUtils {
  static updateWorldMatrix(object, children = false) {
    if (!object) {
      return object;
    }
    updateWorldMatrixUp(object);
    if (children) {
      updateWorldMatrixDown(object.children, object.parentMatrix);
    }

    return object;
  }

  static updateWorldMatrixUp(object) {
    updateWorldMatrixUp(object);

    return object;
  }

  static updateWorldMatrixDown(object) {
    if (!object) {
      return object;
    }
    updateWorldMatrixDown(object.children, object.parentMatrix);

    return object;
  }

  static _callTraverseCallback(callback, object, params) {
    if (!callback) {
      return;
    }
    callback(object, params);
  }

  static traverse(object, callback, params) {
    if (!object || !callback) {
      return;
    }
    if (object instanceof THREE.Object3D) {
      this._callTraverseCallback(callback, object, params);
      const children = object.children;

      if (children) {
        const num = children.length;

        for (let i = 0; i < num; ++i) {
          this.traverse(children[i], callback, params);
        }
      }
    }
  }
}
