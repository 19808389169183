import Asset from './Asset';
import CanvasUtils from '../utils/CanvasUtils';

const TYPE_IMAGE = 'image';

export default class ImageAsset extends Asset {
  constructor(name) {
    super(name, TYPE_IMAGE);
  }

  _isImageOrCanvas(o) {
    if (!o) {
      return false;
    }

    return CanvasUtils.isImage(o) || CanvasUtils.isCanvas(o);
  }

  getWidth(fallback = 0) {
    const img = this.getImage();

    if (!img) {
      return fallback;
    }
    if (typeof (img.naturalWidth) === 'number') {
      return img.naturalWidth;
    }
    const w = img.width;

    if (typeof (w) === 'number' && !isNaN(w)) {
      return w;
    }

    return fallback;
  }

  getHeight(fallback = 0) {
    const img = this.getImage();

    if (!img) {
      return fallback;
    }
    if (typeof (img.naturalHeight) === 'number') {
      return img.naturalHeight;
    }
    const h = img.height;

    if (typeof (h) === 'number' && !isNaN(h)) {
      return h;
    }

    return fallback;
  }

  getImage() {
    let d = this.getData();

    if (!d) {
      return null;
    }
    if (this._isImageOrCanvas(d)) {
      return d;
    }

    d = d.image;
    if (this._isImageOrCanvas(d)) {
      return d;
    }

    return null;
  }

  setImage(img) {
    if (!this._data || this._data instanceof Image) {
      this._data = img;

      return;
    }
    this._data.image = img;
  }

  get image() {
    return this.getImage();
  }

  set image(img) {
    this.setImage(img);
  }
}

ImageAsset.TYPE = TYPE_IMAGE;
