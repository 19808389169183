const aliasDict = {
  grid: 'grid',
  image: 'image',
  custom: 'grid',
  preset: 'image'
};

export default class QuiltTypes {
  static getTypeName(name) {
    if (!name) {
      return null;
    }
    if (typeof (name) === 'string') {
      return aliasDict[name.toLowerCase()];
    }

    return null;
  }
}

QuiltTypes.NONE = null;
QuiltTypes.IMAGE = 'image';
QuiltTypes.GRID = 'grid';
