import GraphPoint from './GraphPoint';

const NEG_ONE = -1;
const HALF = 0.5;

const compareFunc = (point1, point2) => {
  let p1 = point1;
  let p2 = point2;

  if (p1 === undefined) {
    p1 = null;
  }
  if (p2 === undefined) {
    p2 = null;
  }
  if (p1 === p2) {
    return 0;
  }
  const x1 = GraphPoint.getX(p1);
  const x2 = GraphPoint.getX(p2);

  if (x1 === x2) {
    return 0;
  }
  if (x1 < x2) {
    return NEG_ONE;
  }

  return 1;
};

const getQuadValue = (x1, x2, x3, t) => {
  const X12 = x1 + (x2 - x1) * t;
  const X23 = x2 + (x3 - x2) * t;

  return X12 + (X23 - X12) * t;
};

const getCubicValue = (x1, x2, x3, x4, t) => {
  const X12 = x1 + (x2 - x1) * t;
  const X23 = x2 + (x3 - x2) * t;
  const X34 = x3 + (x4 - x3) * t;

  return getQuadValue(X12, X23, X34, t);
};

const getCubicYByX = (x, x1, y1, x2, y2, x3, y3, x4, y4, params) => {
  let fromT = 0;
  let toT = 1;
  let midT = fromT;
  let num = 10;

  if (params) {
    if (params.bezierPrecision) {
      num = params.bezierPrecision;
    }
  }
  for (let i = 0; i < num; ++i) {
    midT = (fromT + toT) * HALF;
    // var fromX = getCubicValue(x1, x2, x3, x4, fromT);
    // var toX = getCubicValue(x1, x2, x3, x4, toT);
    const midX = getCubicValue(x1, x2, x3, x4, midT);

    if (x < midX) {
      toT = midT;
    } else {
      fromT = midT;
    }
  }

  return getCubicValue(y1, y2, y3, y4, midT);
};

const getQuadYByX = (x, x1, y1, x2, y2, x3, y3, params) => {
  let fromT = 0;
  let toT = 1;
  let midT = fromT;
  let num = 10;

  if (params) {
    if (params.bezierPrecision) {
      num = params.bezierPrecision;
    }
  }
  for (let i = 0; i < num; ++i) {
    midT = (fromT + toT) * HALF;
    // var fromX = getQuadValue(x1, x2, x3, fromT);
    // var toX = getQuadValue(x1, x2, x3, toT);
    const midX = getQuadValue(x1, x2, x3, midT);

    if (x < midX) {
      toT = midT;
    } else {
      fromT = midT;
    }
  }

  return getQuadValue(y1, y2, y3, midT);
};

/**
 * @class Graph
 * @description 2D graph containing points & curves used to calulate the Y-coordinate by a given X-coordinate
 * */
export default class Graph {
  constructor(args) {
    let points = null;

    if (args) {
      if (args instanceof Array) {
        points = args;
      } else {
        points = args.points;
      }
    }
    this.points = points;
    this.sortPoints();
  }

  sortPoints() {
    const p = this.points;

    if (!p) {
      return;
    }
    p.sort(compareFunc);
  }

  /**
   * @method getY
   * @description returns the matching y - coordinate by a given x - coordinate
   * @param {Number} x - the x - coordinate
   * @param {Object} params - Optional params object.
   *  - bezierPrecision: how much steps to take to compute the bezier y coordinate
   * @return {Number} - the y-coordinate of a point on the curve
   **/
  getY(x, params) {
    const arr = this.points;

    if (!arr) {
      return 0;
    }
    const len = arr.length;

    if (len === 0) {
      return 0;
    }

    if (len === 1) {
      return GraphPoint.getY(arr[0]);
    }
    let fromIndex = 0;
    let toIndex = len - 1;
    let loop = true;
    let i = 0;

    while (loop && i < len) {
      const diff = toIndex - fromIndex;
      const fromPt = arr[fromIndex];
      const toPt = arr[toIndex];
      const xFrom = GraphPoint.getX(fromPt);
      const xTo = GraphPoint.getX(toPt);

      if (diff < 2) {
        loop = false;

        const fromHR = fromPt.handleRight;
        const toHL = toPt.handleLeft;

        let yFrom = GraphPoint.getY(fromPt);
        let yTo = GraphPoint.getY(toPt);

        if (fromHR && toHL) {
          return getCubicYByX(x,
            xFrom, yFrom,
            GraphPoint.getX(fromHR) + xFrom, GraphPoint.getY(fromHR) + yFrom,
            GraphPoint.getX(toHL) + xTo, GraphPoint.getY(toHL) + yTo,
            xTo, yTo,
            params
          );
        } else if (fromHR || toHL) {
          const c = fromHR ? fromHR : toHL;
          let offX = xFrom;
          let offY = yFrom;

          if (c === toHL) {
            offX = xTo;
            offY = yTo;
          }

          return getQuadYByX(x,
            xFrom, yFrom,
            GraphPoint.getX(c) + offX,
            GraphPoint.getY(c) + offY,
            xTo, yTo,
            params
          );
        }
        const d = xTo - xFrom;

        if (d !== 0) {
          let t = (x - xFrom) / d;

          t = t < 0 ? 0 : t;
          t = t > 1 ? 1 : t;
          yFrom = GraphPoint.getY(fromPt);
          yTo = GraphPoint.getY(toPt);

          return yFrom + (yTo - yFrom) * t;
        }

        return GraphPoint.getY(fromPt);
      }

      const midIndex = (fromIndex + toIndex) >> 1;
      const midPt = arr[midIndex];
      const xMid = GraphPoint.getX(midPt);

      if (x < xFrom) {
        return GraphPoint.getY(fromPt);
      } else if (x > xTo) {
        return GraphPoint.getY(toPt);
      } else if (x >= xFrom && x <= xMid) {
        toIndex = midIndex;
      } else if (x >= xMid && x <= xTo) {
        fromIndex = midIndex;
      }
      ++i;
    }

    return 0;
  }
}
