import EventDispatcher from '../../bgr/common/events/EventDispatcher';
import ArrayUtils from '../../bgr/common/utils/ArrayUtils';
import MattressConfigDA from './MattressConfigDA';
import MattressDA from './MattressDA';
import Utils from '../utils/Utils';
import MattressConfigObjectTypes from './MattressConfigObjectTypes';
import MattressConfigPropertyUtil from './MattressConfigPropertyUtil';
import MattressConfigPropertyCollector from './MattressConfigPropertyCollector';
import HandleStyles from '../handles/HandleStyles';
import ControllerPluginManager from '../controllerplugin/ControllerPluginManager';
import BD3DScene from '../configscene/BD3DScene';
import ConfigLocale from '../locale/ConfigLocale';

// import MattressProperties from './MattressProperties';


/*
function getPropertyByName(name) {
  if (!name) {
    return null;
  }
  const n = name.toUpperCase();

  return MattressProperties[n];
}
function getSizePropertyByName(name) {
  return getPropertyByName(name);
}
*/

class PropertyType {
  constructor(args) {
    if (args) {
      this.type = args.type;
      this.property = args.property;
      this.metaData = args.metaData;
    }
  }
}

const PropertyTypes = {
  SIZE: new PropertyType({type: 'size', property: null}),

  NOISE_SCALE: new PropertyType({type: 'noise', property: 'noise_scale'}),
  NOISE_VALUE: new PropertyType({type: 'noise', property: 'noise_value'}),

  TOP_FABRIC_ID: new PropertyType({type: 'top_fabric', property: 'top_fabric_id'}),
  BOTTOM_FABRIC_ID: new PropertyType({type: 'bottom_fabric', property: 'bottom_fabric_id'}),

  FABRIC: new PropertyType({type: 'fabric', property: 'fabric'}),
  TOP_FABRIC_OFFSET_X: new PropertyType({type: 'top_fabric', metaData: {part: 'top', property: 'x'}, property: 'top_fabric_offset_x'}),
  TOP_FABRIC_OFFSET_Y: new PropertyType({type: 'top_fabric', metaData: {part: 'top', property: 'y'}, property: 'top_fabric_offset_y'}),
  BOTTOM_FABRIC_OFFSET_X: new PropertyType({type: 'bottom_fabric', metaData: {part: 'bottom', property: 'x'}, property: 'bottom_fabric_offset_x'}),
  BOTTOM_FABRIC_OFFSET_Y: new PropertyType({type: 'bottom_fabric', metaData: {part: 'bottom', property: 'y'}, property: 'bottom_fabric_offset_y'}),
  TOP_FABRIC_ROTATION: new PropertyType({type: 'top_fabric', metaData: {part: 'top', property: 'rotation'}, property: 'top_fabric_rotation'}),
  BOTTOM_FABRIC_ROTATION: new PropertyType({type: 'bottom_fabric', metaData: {part: 'bottom', property: 'rotation'}, property: 'bottom_fabric_rotation'}),

  TOP_FABRIC_ALIGN: new PropertyType({type: 'top_fabric', metaData: {part: 'top'}, property: 'top_fabric_align'}),
  TOP_FABRIC_ALIGN_X: new PropertyType({type: 'top_fabric', metaData: {part: 'top', property: 'x'}, property: 'top_fabric_align_x'}),
  TOP_FABRIC_ALIGN_Y: new PropertyType({type: 'top_fabric', metaData: {part: 'top', property: 'y'}, property: 'top_fabric_align_y'}),
  BOTTOM_FABRIC_ALIGN: new PropertyType({type: 'bottom_fabric', metaData: {part: 'bottom'}, property: 'bottom_fabric_align'}),
  BOTTOM_FABRIC_ALIGN_X: new PropertyType({type: 'bottom_fabric', metaData: {part: 'bottom', property: 'x'}, property: 'bottom_fabric_align_x'}),
  BOTTOM_FABRIC_ALIGN_Y: new PropertyType({type: 'bottom_fabric', metaData: {part: 'bottom', property: 'y'}, property: 'bottom_fabric_align_y'}),

  BOTTOM_MIRROR_PANEL: new PropertyType({type: 'bottom_mirror_panel', property: 'bottom_mirror_panel'}),
  BOTTOM_MIRROR_PANEL_ENABLED: new PropertyType({type: 'bottom_mirror_panel', property: 'bottom_mirror_panel_enabled'}),
  BOTTOM_MIRROR_PANEL_SAMPLE_ID: new PropertyType({type: 'bottom_mirror_panel', property: 'bottom_mirror_panel_sample_id'}),

  HANDLE_TYPE: new PropertyType({type: 'handle', property: 'handle_type'}),
  HANDLE_FABRIC: new PropertyType({type: 'handle', property: 'handle_fabric'}),
  HANDLE_COLOR: new PropertyType({type: 'handle', property: 'handle_color'}),
  HANDLE_MAX_COUNT: new PropertyType({type: 'handle', property: 'handle_max_count'}),
  HANDLE_LOGO_ID: new PropertyType({type: 'handle', property: 'handle_logo_id'}),
  HANDLE_LOGO_URL: new PropertyType({type: 'handle', property: 'handle_logo_url'}),

  TOP_QUILT_ID: new PropertyType({type: 'top_quilt', property: 'top_quilt_id'}),
  TOP_QUILT_OFFSET: new PropertyType({type: 'top_quilt', property: 'top_quilt_offset', metaData: {part: 'top'}}),
  TOP_QUILT_OFFSET_X: new PropertyType({type: 'top_quilt', property: 'top_quilt_offset_x', metaData: {part: 'top', property: 'x'}}),
  TOP_QUILT_OFFSET_Y: new PropertyType({type: 'top_quilt', property: 'top_quilt_offset_y', metaData: {part: 'top', property: 'y'}}),
  TOP_QUILT_ROTATION: new PropertyType({type: 'top_quilt', property: 'top_quilt_rotation', metaData: {part: 'top', property: 'rotation'}}),
  TOP_QUILT_REPEAT_X: new PropertyType({type: 'top_quilt', property: 'top_quilt_repeat_x', metaData: {part: 'top', property: 'repeat_x'}}),
  TOP_QUILT_REPEAT_Y: new PropertyType({type: 'top_quilt', property: 'top_quilt_repeat_y', metaData: {part: 'top', property: 'repeat_y'}}),
  TOP_QUILT_REPEAT_TYPE: new PropertyType({type: 'top_quilt', property: 'top_quilt_repeat_type', metaData: {part: 'top', property: 'repeat_type'}}),
  TOP_QUILT_FOAM_VALUE: new PropertyType({type: 'top_quilt', property: 'top_quilt_foam_value', metaData: {part: 'top', property: 'foam_value'}}),
  TOP_QUILT_ALIGN_X: new PropertyType({type: 'top_quilt', metaData: {part: 'top', property: 'align-x'}, property: 'top_quilt_align_x'}),
  TOP_QUILT_ALIGN_Y: new PropertyType({type: 'top_quilt', metaData: {part: 'top', property: 'align-y'}, property: 'top_quilt_align_y'}),
  TOP_QUILT_ALIGN_XY: new PropertyType({type: 'top_quilt', metaData: {part: 'top', property: 'align-xy'}, property: 'top_quilt_align_xy'}),

  BOTTOM_QUILT_ID: new PropertyType({type: 'bottom_quilt', property: 'bottom_quilt_id'}),
  BOTTOM_QUILT_OFFSET: new PropertyType({type: 'bottom_quilt', property: 'bottom_quilt_offset'}),
  BOTTOM_QUILT_OFFSET_X: new PropertyType({type: 'bottom_quilt', property: 'bottom_quilt_offset_x', metaData: {part: 'bottom', property: 'x'}}),
  BOTTOM_QUILT_OFFSET_Y: new PropertyType({type: 'bottom_quilt', property: 'bottom_quilt_offset_y', metaData: {part: 'bottom', property: 'y'}}),
  BOTTOM_QUILT_ROTATION: new PropertyType({type: 'bottom_quilt', property: 'bottom_quilt_rotation', metaData: {part: 'bottom', property: 'rotation'}}),
  BOTTOM_QUILT_REPEAT_X: new PropertyType({type: 'bottom_quilt', property: 'bottom_quilt_repeat_x', metaData: {part: 'bottom', property: 'repeat_x'}}),
  BOTTOM_QUILT_REPEAT_Y: new PropertyType({type: 'bottom_quilt', property: 'bottom_quilt_repeat_y', metaData: {part: 'bottom', property: 'repeat_y'}}),
  BOTTOM_QUILT_REPEAT_TYPE: new PropertyType({type: 'bottom_quilt', property: 'bottom_quilt_repeat_type', metaData: {part: 'bottom', property: 'repeat_type'}}),
  BOTTOM_QUILT_FOAM_VALUE: new PropertyType({type: 'bottom_quilt', property: 'bottom_quilt_foam_value', metaData: {part: 'bottom', property: 'foam_value'}}),
  BOTTOM_QUILT_ALIGN_X: new PropertyType({type: 'bottom_quilt', metaData: {part: 'bottom', property: 'align-x'}, property: 'bottom_quilt_align_x'}),
  BOTTOM_QUILT_ALIGN_Y: new PropertyType({type: 'bottom_quilt', metaData: {part: 'bottom', property: 'align-y'}, property: 'bottom_quilt_align_y'}),
  BOTTOM_QUILT_ALIGN_XY: new PropertyType({type: 'bottom_quilt', metaData: {part: 'bottom', property: 'align-xy'}, property: 'bottom_quilt_align_xy'}),

  TOP_BORDER_HEIGHT: new PropertyType({type: 'border_height', property: 'top_border_height'}),
  BOTTOM_BORDER_HEIGHT: new PropertyType({type: 'border_height', property: 'bottom_border_height'}),
  TOP_BORDER_RADIUS: new PropertyType({type: 'border_radius', property: 'top_border_radius'}),
  BOTTOM_BORDER_RADIUS: new PropertyType({type: 'border_radius', property: 'bottom_border_radius'}),
  CORNER_RADIUS: new PropertyType({type: 'corner_radius', property: 'corner_radius'}),
  LEG_TYPE: new PropertyType({type: 'leg', property: 'leg_type'}),
  LEG_HEIGHT: new PropertyType({type: 'leg', property: 'leg_height'}),

  BACKGROUND_ID: new PropertyType({type: 'background', property: 'background_id'}),
  BACKGROUND_COLOR: new PropertyType({type: 'background', property: 'background_color'}),
  BACKGROUND_CLEAR: new PropertyType({type: 'background', property: 'background_clear'}),
  BACKGROUND: new PropertyType({type: 'background', property: 'background'})
};

const DEFAULT_NOISE_SCALE = MattressDA.DEFAULT_NOISE_SCALE;

function newMap() {
  if (typeof (WeakMap) !== 'undefined') {
    return new WeakMap();
  }
  if (typeof (Map) !== 'undefined') {
    return new Map();
  }

  return null;
}

export default class MattressConfig extends EventDispatcher {
  /**
   * @param {object} data - config of mattress
   */
  constructor(data) {
    super();
    this._data = data;
    this._eventsEnabled = true;
  }

  static create(data) {
    const res = new MattressConfig();

    res.setData(data);

    return res;
  }

  getJSONString(params = null) {
    let indent = null;
    const d = this._data;

    if (params) {
      if (typeof (params) === 'string') {
        indent = params;
      } else {
        indent = params.indent;
      }
    }

    return JSON.stringify(d, null, indent);
  }

  exportData(params) {
    const EVENT_NAME = 'export_data';

    if (this.hasEventListener(EVENT_NAME)) {
      this.dispatchEvent({type: EVENT_NAME, data: this._data});
    }
    const data = this.getData();

    const quiltParams = params ? params.quiltParams : null;
    const sampleParams = params ? params.sampleParams : null;

    const res = {
      json: data,
      quilts: this.getQuiltTypesAndIds(quiltParams),
      quiltIds: this.getQuiltIds(quiltParams),
      samples: this.getSampleTypesAndIds(sampleParams),
      sampleIds: this.getSampleIds(sampleParams)
    };

    this._setDirty(false);

    return res;
  }
  /**
   * @return {object} returns config of mattress
   */
  getData() {
    return this._data;
  }

  /**
   * @param {Object|String} data - config of mattress (json format)
   * @param {Object} params - optional params object
   */
  setData(data, params = null) {
    let d = data;

    if (typeof (d) === 'string') {
      try {
        d = JSON.parse(d);
      } catch (e) {
        d = null;
      }
    }
    const old = this._data;

    const locale = this._locale;

    if (locale) {
      locale.setData(d);
    }

    this._data = d;

    this._setDirty(false);

    if (d) {
      if (d.notes) {
        Reflect.deleteProperty(d, 'notes');
      }
    }

    this._resetData(d);
    this._initData(d);

    this._updateControllers();

    this._dispatchChangedData(d, old, params);
  }

  getLocale(createIfNull = true) {
    let res = this._locale;
    const data = this.getData();

    if (!data) {
      if (res) {
        res.setData(data);
      }

      return res;
    }

    if (res || !createIfNull) {
      res.setData(data);

      return res;
    }

    res = new ConfigLocale(data);
    this._locale = res;

    return res;
  }

  get locale() {
    return this.getLocale(true);
  }

  getTranslation(lang, key) {
    if (!lang || !key) {
      return null;
    }
    const locale = this.getLocale(true);

    if (!locale) {
      return null;
    }

    return locale.getTranslation(key, lang);
  }

  get dirty() {
    return this.isDirty();
  }

  isDirty() {
    return this._dirty === true;
  }

  _setDirty(flag) {
    if (this._dirty === flag) {
      return;
    }
    this._dirty = flag;

    const EVENT_NAME = 'changed_dirty';
    const event = this._getEventObject(EVENT_NAME, true);

    event.type = EVENT_NAME;
    event.target = this;
    event.dirty = flag;

    this.dispatchEvent(event);
  }
  // /////////////////////////////////////////////////////////
  //
  // Controllers
  //
  // /////////////////////////////////////////////////////////

  getNumControllers() {
    const contrManager = this._controllerPluginManager;

    if (contrManager && contrManager.getNumControllers) {
      return contrManager.getNumControllers();
    }

    return 0;
  }

  get numControllers() {
    return this.getNumControllers();
  }

  getController(key) {
    const contrManager = this._controllerPluginManager;

    if (contrManager && contrManager.getController) {
      return contrManager.getController(key);
    }

    return null;
  }

  getControllerProperty(controllerName, propertyName, fallback = null) {
    const contrManager = this._controllerPluginManager;

    if (!contrManager) {
      return fallback;
    }

    return contrManager.getControllerProperty(controllerName, propertyName, fallback);
  }

  setControllerProperty(controllerName, propertyName, value) {
    const contrManager = this._controllerPluginManager;

    if (!contrManager) {
      return;
    }

    const old = contrManager.getControllerProperty(controllerName, propertyName);

    if (old === value) {
      return;
    }

    contrManager.setControllerProperty(controllerName, propertyName, value);

    const controller = contrManager.getController(controllerName);

    this._changedProperty(null, {type: 'controller_property', controllerName: controllerName, controller: controller, properyName: propertyName}, value, old);
  }

  _updateControllers() {
    const d = this._data;
    const controllers = d ? d.controllers : null;
    let contrManager = this._controllerPluginManager;

    contrManager = this._controllerPluginManager;

    if (controllers && !contrManager) {
      contrManager = this._controllerPluginManager = new ControllerPluginManager();
    }
    if (contrManager) {
      contrManager.setConfig(d);
    }
  }

  _removeDataInfo(obj) {
    if (!obj) {
      return;
    }
    const map = this._dataInfoMap;

    if (map && map.delete) {
      map.delete(obj);
    }
    const arr = this._dataInfoArray;

    if (arr) {
      let num = arr.length;
      let i = 0;

      while (i < num) {
        const value = arr[i];
        const valueObj = value && value.object;

        if (value) {
          value.index = i;
        }

        if (valueObj === obj) {
          arr.splice(i, 1);
          --num;
        } else {
          ++i;
        }
      }
    }
  }
  _setDataInfo(obj, type, parent, session) {
    if (!obj) {
      return null;
    }

    this._initCheckObjectID(obj);

    let map = this._dataInfoMap;

    if (!map) {
      map = this._dataInfoMap = newMap();
    }
    let info = map.get(obj);

    if (info) {
      info.object = obj;
      info.type = type;
      info.parent = parent;
    } else {
      info = {object: obj, type: type, parent: parent, index: 0};

      map.set(obj, info);
    }

    // Add to flat array & assign an index
    let array = this._dataInfoArray;

    if (!array) {
      array = this._dataInfoArray = [];
    }
    if (array.indexOf(obj, 0) < 0) {
      const index = array.length;

      info.index = index;
      array.push(info);
    }

    return info;
  }

  _setSingleDataInfo(obj, type, parent, single, session) {
    const res = this._setDataInfo(obj, type, parent, session);

    if (!res) {
      return res;
    }
    res.single = single;

    return res;
  }

  _getObjectInfo(obj) {
    if (!obj) {
      return null;
    }
    const map = this._dataInfoMap;

    if (!map) {
      return null;
    }

    return map.get(obj);
  }

  getObjectByID(id) {
    let result = MattressConfigDA.getObjectByID(id, this.getData());

    if (!result) {
      const scene = this.getScene();

      result = scene.getDataById(id);
    }

    return result;
  }

  getObjectType(obj) {
    return this._getObjectType(obj);
  }

  getObjectTypeName(obj) {
    const ot = this._getObjectType(obj);

    if (!ot) {
      return null;
    }

    return ot.name;
  }

  _getObjectType(obj) {
    if (!obj) {
      return null;
    }
    const info = this._getObjectInfo(obj);

    if (!info) {
      return null;
    }

    return info.type;
  }

  getObjectIndex(object) {
    const internalInfo = this._getObjectInfo(object);

    if (!internalInfo) {
      return null;
    }

    return internalInfo.index;
  }

  getObjectAt(index) {
    const arr = this._dataInfoArray;
    let i = index;

    const t = typeof (i);

    if (t !== 'number') {
      if (!i) {
        return null;
      }
      if (t === 'string') {
        i = parseInt(i, 10);
      }
    }
    if (typeof (i) !== 'number' || isNaN(i)) {
      return null;
    }


    if (!arr) {
      return null;
    }
    const item = arr[i | 0];

    if (!item) {
      return null;
    }

    return item.object;
  }

  _fixObjectMaterial(object) {
    if (!object) {
      return;
    }
    if (object.ribbon) {
      if (!object.ribbon.material) {
        object.ribbon.material = MattressDA.getDefaultMaterialType();
      }
    }

    const typeName = object.type;

    if (!typeName || !typeName.toLowerCase) {
      return;
    }
    const typeNameLc = typeName.toLowerCase();

    if (!object.material && typeNameLc === 'piping' || typeNameLc === 'tape') {
      object.material = MattressDA.getDefaultMaterialType();
    }
  }

  _getGroupObject(group) {
    if (group === null) {
      return null;
    }
    const t = typeof (group);

    if (t === 'undefined') {
      return null;
    }
    if (t === 'object') {
      return group;
    }

    if (t === 'string') {
      const data = this.getData();
      const id = group;

      if (!data) {
        return null;
      }
      const groups = data.groups;
      const numGroups = groups ? groups.length : 0;

      for (let i = 0; i < numGroups; ++i) {
        const curGr = groups[i];
        let grId = this._getIDOfObject(curGr, 'id');

        if (grId === null) {
          grId = this._getIDOfObject(groups[i], '@id');
        }
        if (grId === null) {
          grId = this._getIDOfObject(groups[i], '@ID');
        }
        if (grId === id) {
          return curGr;
        }
      }
    }

    return null;
  }

  _getBoxSpringController() {
    const mgr = this._controllerPluginManager;

    if (!mgr) {
      return null;
    }

    return mgr.getBoxSpringController();
  }

  getMainSingle() {
    const boxSpringController = this._getBoxSpringController();

    if (boxSpringController) {
      return boxSpringController.getTopMostSingle();
    }

    return MattressConfigDA.getMainSingle(this.getData());
  }

  getMainSingleIndex() {
    const single = this.getMainSingle();

    if (single) {
      const singles = this.getSingles();

      if (singles && singles.length > 0) {
        return ArrayUtils.findIndex(singles, single);
      }
    }

    return MattressConfigDA.getMainSingleIndex(this.getData());
  }

  get mainSingle() {
    return this.getMainSingle();
  }

  get mainSingleIndex() {
    return this.getMainSingleIndex();
  }

  getSingleName(single) {
    const data = this.getData();

    return MattressConfigDA.getSingleName(data, single);
  }

  setSingleName(single, value) {
    const old = this.getSingleName(single);

    if (old === value) {
      return;
    }
    const data = this.getData();

    MattressConfigDA.setSingleName(data, single, value);

    this._setDirty(true);
  }

  applySampleToGroup(group, sampleID) {
    const res = this._applySampleToGroup(group, sampleID);

    if (res) {
      this._changedProperty(null, PropertyTypes.GROUP_SAMPLE, sampleID, null);
    }
  }

  applyQuiltToGroup(group, quiltID) {
    const res = this._applyQuiltToGroup(group, quiltID);

    if (res) {
      this._changedProperty(null, PropertyTypes.GROUP_QUILT, quiltID, null);
    }
  }

  applySampleToObject(object, sampleID) {
    const res = this._applySampleToObject(object, sampleID);

    if (res) {
      let propType = null;
      const inf = this._getObjectInfo(object);

      // temporary property type
      propType = inf.type;

      this._changedProperty(null, propType, sampleID, null);
    }
  }

  applyQuiltToObject(object, quiltID) {
    const res = this._applyQuiltToObject(object, quiltID);

    if (res) {
      let propType = null;
      const inf = this._getObjectInfo(object);

      // temporary property type
      propType = inf.type;

      this._changedProperty(null, propType, quiltID, null);
    }
  }

  _applySampleToGroup(group, sampleID) {
    if (!group) {
      return false;
    }
    const gr = this._getGroupObject(group);

    if (!gr) {
      return false;
    }
    const elements = gr.elements;
    const numElements = elements ? elements.length : 0;
    let res = false;

    for (let i = 0; i < numElements; ++i) {
      const elem = elements[i];
      let allowSample = true;

      if (typeof (elem) === 'object') {
        allowSample = elem.samples !== false;
      }
      if (allowSample) {
        const elemId = this._getIDOfObject(elem, 'id');
        const obj = this.getObjectByID(elemId);

        res = this._applySampleToObject(obj, sampleID) || res;
      }
    }

    return res;
  }

  _applyQuiltToGroup(group, quiltID) {
    if (!group) {
      return false;
    }
    const gr = this._getGroupObject(group);

    if (!gr) {
      return false;
    }
    const elements = gr.elements;
    const numElements = elements ? elements.length : 0;
    let res = false;

    for (let i = 0; i < numElements; ++i) {
      const elem = elements[i];
      let allowQuilt = true;

      if (typeof (elem) === 'object') {
        allowQuilt = elem.quilts !== false;
      }
      if (allowQuilt) {
        const elemId = this._getIDOfObject(elem, 'id');
        const obj = this.getObjectByID(elemId);

        res = this._applyQuiltToObject(obj, quiltID) || res;
      }
    }

    return res;
  }

  _applySampleToObject(obj, sampleID) {
    if (!obj) {
      return false;
    }
    const inf = this._getObjectInfo(obj);

    if (!inf) {
      return false;
    }
    const objectType = inf.type;
    let res = false;

    if (
      objectType === MattressConfigObjectTypes.TOP_PANEL ||
      objectType === MattressConfigObjectTypes.BOTTOM_PANEL ||
      objectType === MattressConfigObjectTypes.BORDER_COMPONENT
    ) {
      if (!obj.texture) {
        obj.texture = {};
      }
      res = (obj.texture.id !== sampleID);
      if (res) {
        MattressDA.resetTextureData(obj.texture);
        obj.texture.id = sampleID;
      }
    } else if (objectType === MattressConfigObjectTypes.GROUP) {
      res = this._applySampleToGroup(obj, sampleID);
    }

    return res;
  }

  _applyQuiltToObject(obj, quiltID) {
    if (!obj) {
      return false;
    }
    const inf = this._getObjectInfo(obj);

    if (!inf) {
      return false;
    }
    const objectType = inf.type;
    let res = false;

    if (
      objectType === MattressConfigObjectTypes.TOP_PANEL ||
      objectType === MattressConfigObjectTypes.BOTTOM_PANEL ||
      objectType === MattressConfigObjectTypes.BORDER_COMPONENT
    ) {
      if (!obj.quilt) {
        obj.quilt = {};
      }
      res = (obj.quilt.id !== quiltID);
      if (res) {
        MattressDA.resetQuiltData(obj.quilt);
        obj.quilt.id = quiltID;
      }
    } else if (objectType === MattressConfigObjectTypes.GROUP) {
      res = this._applyQuiltToGroup(obj, quiltID);
    }

    return res;
  }

  _getIDOfObject(obj, key) {
    if (obj === null) {
      return null;
    }
    let t = typeof (obj);

    if (t === 'undefined') {
      return null;
    }

    if (t === 'string' || t === 'number') {
      return obj;
    }
    if (!key) {
      return null;
    }
    const res = obj[key];

    if (res === null) {
      return null;
    }

    t = typeof (res);

    if (t === 'undefined') {
      return null;
    }

    return res;
  }

  getObjectInfo(object) {
    if (!object) {
      return null;
    }
    const res = {};
    const internalInfo = this._getObjectInfo(object);
    const ot = internalInfo ? internalInfo.type : null;
    let single = null, singleIndex = -1, index = -1;

    if (internalInfo) {
      single = internalInfo.single;
      index = internalInfo.index;
    }

    let texture = null, quilt = null;

    if (object.texture) {
      texture = object.texture;
    }
    if (object.quilt) {
      quilt = object.quilt;
    }

    let typeName = null;

    if (ot) {
      typeName = ot.name;

      if (ot === MattressConfigObjectTypes.BORDER_COMPONENT) {
        if (object.type) {
          typeName = object.type;

          const {borderComponentIndex} = internalInfo;

          if (borderComponentIndex !== null && typeof (borderComponentIndex) !== 'undefined' && borderComponentIndex >= 0) {
            res.borderComponentIndex = borderComponentIndex;
          }

          const typeNameLc = typeName.toLowerCase();

          this._fixObjectMaterial(object);

          if (typeNameLc === 'border-3d' || typeNameLc === 'border3d') {
            typeName = '3d-border';
          }
        }
      } else if (ot === MattressConfigObjectTypes.TOP_PANEL) {
        typeName = 'top';
      } else if (ot === MattressConfigObjectTypes.BOTTOM_PANEL) {
        typeName = 'bottom';
      } else if (ot === MattressConfigObjectTypes.HANDLES) {
        texture = null;
        quilt = null;

        const handleStyle = HandleStyles.getFromJSON(object);
        const handleStyleName = (handleStyle && handleStyle.getID) ? handleStyle.getID() : null;
        const handleType = (handleStyle && handleStyle.getType) ? handleStyle.getType() : null;
        const handleTypeName = (handleType && handleType.getTypeName) ? handleType.getTypeName() : null;
        const handleEditableComponents = (handleStyle && handleStyle.editableComponents && handleStyle.editableComponents.concat) ? handleStyle.editableComponents.concat() : null;

        texture = MattressDA.getHandleSample(single, 'fabric');

        // Custom logo
        if (this.singleHasCustomizableLogoHandle()) {
          const logoID = MattressConfigDA.getLogoHandleImageID(this.getData(), internalInfo.single);
          const sampleService = this.getSampleService();

          // sampleService.getSampleById(object.image.id);
          let logoData = null;

          if (sampleService && logoID) {
            logoData = sampleService.getSampleById(logoID);
          }
          res.handleLogo = logoData;
        }

        // TODO: Confusing terminology! Do something about it!
        res.handleCategory = handleTypeName;
        res.handleType = handleStyleName;
        res.handleEditableComponents = handleEditableComponents;
      } else if (ot === MattressConfigObjectTypes.GROUP) {
        const elements = object.elements;
        const numElements = elements ? elements.length : 0;
        const elemArr = [];

        single = null;
        let firstSingle = null;

        quilt = null;
        texture = null;

        for (let i = 0; i < numElements; ++i) {
          const elem = elements[i];
          let elemId = null;

          if (typeof (elem) === 'string') {
            elemId = elem;
          } else if (typeof (elem) === 'object') {
            elemId = elem.id;
          }
          if (elemId) {
            const elemObj = this.getObjectByID(elemId);
            let elemSingle = null;

            if (elemObj) {
              const objectInfo = this._getObjectInfo(elemObj);

              elemSingle = objectInfo.single;

              elemArr.push(elemObj);
            }
            const elemTexId = this._getIDOfObject(elemObj.texture, 'id');
            const elemQuiltId = this._getIDOfObject(elemObj.quilt, 'id');

            if (elemTexId !== null && texture === null) {
              texture = elemTexId;
            }
            if (elemQuiltId !== null && quilt === null) {
              quilt = elemQuiltId;
            }

            if (!firstSingle) {
              firstSingle = elemSingle;
              single = firstSingle;
            }
            if (firstSingle !== elemSingle) {
              single = null;
            }
          }
        }
        res.elements = elemArr;
      }
    }

    if (single) {
      const data = this._data;
      const singles = data ? data.singles : null;

      singleIndex = singles ? singles.indexOf(single, 0) : -1;
    }
    res.single = single;
    res.singleIndex = singleIndex;
    res.index = index;
    res.object = object;

    let texID = this._getIDOfObject(texture, 'id');

    const sceneData = this.getScene();

    if (!texID && sceneData && sceneData.getObjectByData(object)) {
      const samples = sceneData.callMethodOn(object, 'getSamples');
      const sample = samples ? samples[0] : null;

      texID = sample ? sample.sampleId : null;
    }

    const sampleService = this.getSampleService();
    let sample = null;

    if (texID && sampleService) {
      sample = sampleService.getSampleById(texID);
    }
    res.sample = sample;

    if (quilt !== null && typeof (quilt) !== 'undefined') {
      if (quilt.type === 'custom') {
        res.quilt = 'custom';
      } else {
        const qID = this._getIDOfObject(quilt, 'id');
        const qSvc = this.getQuiltService();
        let quiltData = null;

        if (qSvc && qID) {
          quiltData = qSvc.getQuiltById(qID);
        }
        res.quilt = quiltData;
      }
    }

    res.type = typeName;

    return res;
  }

  getSceneSampleInfoPerComponent(params = null, resultArray = null) {
    const sceneData = this.getScene();

    if (!sceneData) {
      return resultArray;
    }
    const lang = params ? params.language || params.lang : null;
    const translate = params ? params.translate !== false : true;
    const parseSample = params ? params.parseSample : null;

    const p = {
      lang,
      parseSample,
      locale: translate ? this.getLocale(true) : null
    };

    return sceneData.getSampleInfoPerComponent(null, p, resultArray);
  }

  objectIsType(obj, type) {
    const ot = this._getObjectType(obj);

    if (ot === null || typeof (ot) === 'undefined') {
      return type === null || typeof (type) === 'undefined';
    }
    if (typeof (type) === 'string') {
      return ot.name === type;
    }

    return ot === type;
  }

  // Appends all properties of 'properties' to 'object', only if 'object' doesnt have the property yet
  _appendCopiedData(object, properties) {
    if (!properties) {
      return object;
    }
    const t = typeof (object);

    if (t === 'number' || t === 'string' || t === 'boolean') {
      return object;
    }

    if (!object) {
      return Utils.deepCopy(properties);
    }
    for (const v in properties) {
      if (properties.hasOwnProperty(v)) {
        const old = object[v];

        if (old === null || typeof (old) === 'undefined') {
          object[v] = this._appendCopiedData(object[v], properties[v]);
        }
      }
    }

    return object;
  }

  _initCheckObjectID(obj) {
    if (!obj) {
      return;
    }
    const P_ID = '@ID';
    const id = obj[P_ID];

    if (!id) {
      // MattressConfigDA.getObjectID(obj); // automatic assign of @ID property

      return;
    }
    if (typeof (Reflect) !== 'undefined' && Reflect.defineProperty) {
      if (Reflect.deleteProperty(obj, P_ID)) {
        // Make @ID property readonly
        try {
          Reflect.defineProperty(obj, P_ID, {
            value: id,
            enumerable: true
          });
        } catch (e) {
          obj[P_ID] = id;
        }
      }
    }
  }

  _initSingle(d, parent, session) {
    if (!d) {
      return;
    }
    this._setSingleDataInfo(d, MattressConfigObjectTypes.SINGLE, parent, d, session);

    if (!d.top) {
      d.top = {};
    }
    if (!d.bottom) {
      d.bottom = {};
    }
    this._setSingleDataInfo(d.top.texture, MattressConfigObjectTypes.TOP_SAMPLE, d.top, d, session);
    this._setSingleDataInfo(d.top.quilt, MattressConfigObjectTypes.BOTTOM_QUILT, d.top, d, session);
    this._setSingleDataInfo(d.bottom.texture, MattressConfigObjectTypes.BOTTOM_SAMPLE, d.bottom, d, session);
    this._setSingleDataInfo(d.bottom.quilt, MattressConfigObjectTypes.BOTTOM_QUILT, d.bottom, d, session);
    this._setSingleDataInfo(d.top.mirrorPanel, MattressConfigObjectTypes.TOP_MIRRORPANEL, d.top, d, session);
    this._setSingleDataInfo(d.bottom.mirrorPanel, MattressConfigObjectTypes.BOTTOM_MIRRORPANEL, d.bottom, d, session);

    d.top.texture = this._appendCopiedData(d.top.texture, d.texture);
    d.top.quilt = this._appendCopiedData(d.top.quilt, d.quilt);
    d.bottom.texture = this._appendCopiedData(d.bottom.texture, d.texture);
    d.bottom.quilt = this._appendCopiedData(d.bottom.quilt, d.quilt);

    Reflect.deleteProperty(d, 'texture');
    Reflect.deleteProperty(d, 'quilt');

    this._setSingleDataInfo(d.top, MattressConfigObjectTypes.TOP_PANEL, d, d, session);
    this._setSingleDataInfo(d.bottom, MattressConfigObjectTypes.BOTTOM_PANEL, d, d, session);
    const border = d.border;

    this._setSingleDataInfo(border, MattressConfigObjectTypes.BORDER, d, d, session);
    const borderComponents = border ? border.components : null;

    this._setSingleDataInfo(borderComponents, MattressConfigObjectTypes.BORDER_COMPONENTS, border, d, session);
    const numBC = borderComponents ? borderComponents.length : 0;

    for (let i = 0; i < numBC; ++i) {
      const borderComp = borderComponents[i];
      const borderCompInfo = this._setSingleDataInfo(borderComp, MattressConfigObjectTypes.BORDER_COMPONENT, borderComponents, d, session);

      if (borderCompInfo) {
        borderCompInfo.borderComponentIndex = i;
      }
    }

    const handleData = MattressDA.getHandleData(d);

    if (handleData) {
      this._setSingleDataInfo(handleData, MattressConfigObjectTypes.HANDLES, d, d, session);
    }

    const legData = MattressDA.getLegs(d);

    if (legData) {
      this._setSingleDataInfo(legData, MattressConfigObjectTypes.LEGS, d, d, session);
    }
  }

  _reinitData(d) {
    this._resetData(d);
    this._initData(d);
  }

  _resetData(d) {
    if (this._locale) {
      this._locale.dispose();
    }

    if (this._dataInfoMap && this._dataInfoMap.clear) {
      this._dataInfoMap.clear();
    }
    this._dataInfoMap = newMap();

    if (this._dataInfoArray) {
      this._dataInfoArray.length = 0;
    }
    this._objectIDGroupMap = null;

    const sceneData = this.sceneData;

    if (sceneData) {
      if (sceneData.dispose) {
        sceneData.dispose();
      }
      this.sceneData = null;
    }
  }

  _initData(d) {
    if (!d) {
      return;
    }
    const session = {};

    this._setDataInfo(d, MattressConfigObjectTypes.CONFIG, null, session);

    const groups = d.groups;

    if (groups) {
      const numGroups = groups.length;

      for (let i = 0; i < numGroups; ++i) {
        const gr = groups[i];

        if (gr) {
          this._initGroup(gr, session, d);
        }
      }
    }

    const controllers = d.controllers;

    if (controllers) {
      const num = controllers.length;

      for (let i = 0; i < num; ++i) {
        const contr = controllers[i];

        this._setDataInfo(contr, MattressConfigObjectTypes.CONTROLLER, d, session);
      }
    }
    const singles = d.singles;

    if (singles) {
      const num = singles.length;

      for (let i = 0; i < num; ++i) {
        const single = singles[i];

        this._setDataInfo(single, MattressConfigObjectTypes.SINGLE, d, session);

        this._initSingle(single, d, session);
      }
    }

    {
      const p = {session};

      let onParsedInstance = this._onParsedSceneInstance;

      if (!onParsedInstance) {
        onParsedInstance = (value, result, sess) => {
          let params = null;

          if (sess) {
            params = sess.getParams ? sess.getParams() : sess.params;
          }
          const s = params ? params.session : null;

          this._setDataInfo(value, MattressConfigObjectTypes.CUSTOM, d, s);
        };
        this._onParsedSceneInstance = onParsedInstance;
      }

      p.onParsedInstance = onParsedInstance;
      this.updateSceneContent(p);
    }
  }

  _initGroup(group, session, data) {
    if (!group) {
      return;
    }

    this._setDataInfo(group, MattressConfigObjectTypes.GROUP, data, session);

    const elements = group.elements || group.components;
    const numElements = elements ? elements.length : 0;

    let map = this._objectIDGroupMap;

    if (!map) {
      map = this._objectIDGroupMap = {};
    }

    for (let i = 0; i < numElements; ++i) {
      const elem = elements[i];
      let elemId = elem;

      if (typeof (elem) === 'object' && elem !== null) {
        elemId = elem.id;
      }
      if (elemId) {
        let groups = map[elemId];

        if (!groups) {
          groups = map[elemId] = [];
        }
        groups.push(group);
      }
    }
  }

  getGroupByObject(obj) {
    if (!obj) {
      return null;
    }
    let id = null;

    if (typeof (obj) === 'string') {
      id = obj;
    } else if (typeof (obj) === 'object' && obj !== null) {
      id = obj['@id'] || obj['@ID'];
    }

    if (!id) {
      return null;
    }
    const map = this._objectIDGroupMap;

    if (!map) {
      return null;
    }
    const gr = map[id];

    if (!gr) {
      return null;
    }
    if ((gr instanceof Array) || (Array.isArray && Array.isArray(gr))) {
      if (!gr.length) {
        return null;
      }

      return gr[0];
    }

    return gr;
  }

  get data() {
    return this.getData();
  }

  set data(d) {
    this.setData(d);
  }

  getVersion() {
    return MattressConfigDA.getVersion(this.getData());
  }

  setVersion(v) {
    return MattressConfigDA.setVersion(this.getData(), v);
  }

  get version() {
    return this.getVersion();
  }

  set version(v) {
    this.setVersion(v);
  }

  getQuilts(params = null, resArray = null) {
    let res = resArray;

    res = MattressConfigDA.getQuilts(this.getData(), params, res);

    const scene = this.sceneData;

    if (scene) {
      res = scene.getQuilts(params, res);
    }

    return res;
  }

  getQuiltIds(params) {
    const quiltArray = this.getQuilts(params);
    const res = [];

    if (!quiltArray) {
      return res;
    }
    const num = quiltArray.length;

    if (!num) {
      return res;
    }
    for (let i = 0; i < num; ++i) {
      const quiltInfo = quiltArray[i];

      if (quiltInfo && typeof (quiltInfo.quiltId) !== 'undefined' && quiltInfo.quiltId !== null) {
        if (res.indexOf(quiltInfo.quiltId, 0) < 0) {
          res.push(quiltInfo.quiltId);
        }
      }
    }

    return res;
  }

  getQuiltTypesAndIds(params = null) {
    const quiltArray = this.getQuilts(params);
    const res = [];

    if (!quiltArray) {
      return res;
    }
    const num = quiltArray.length;

    if (!num) {
      return res;
    }
    const map = {};

    // Allow duplicate quilts
    const duplicateSamples = params ? (params.duplicateSamples === true) : false;
    const typePriorities = (params && params.typePriorities) ? (params.typePriorities) : ['topPanel', 'bottomPanel', 'border'];
    const qsvc = this.getQuiltService();
    const excludeHardCodedQuilts = params ? (params.excludeHardCodedQuilts !== false) : true;

    for (let i = 0; i < num; ++i) {
      const quiltInfo = quiltArray[i];

      if (quiltInfo) {
        const quilt = quiltInfo.quilt;
        const quiltConfigId = quiltInfo.quiltId;
        const quiltConfigIdString = `${quiltConfigId}`;

        const quiltData = qsvc ? qsvc.getQuiltById(quiltConfigIdString) : null;
        const quiltIsHardCoded = qsvc ? qsvc.isHardCoded(quiltData) : null;
        const quiltId = (quiltData && quiltData.id) ? quiltData.id : quiltConfigId;
        const quiltName = (quiltData && quiltData.name) ? quiltData.name : null;
        const quiltIdString = `${quiltId}`;

        const address = quiltInfo.address;
        let srcPart = address ? address.part : null;
        let part = srcPart;

        srcPart = srcPart ? srcPart.toLowerCase() : srcPart;
        part = part ? part.toLowerCase() : part;

        if (part === 'topmirrorpanel') {
          part = 'topPanel';
        } else if (part === 'bottommirrorpanel') {
          part = 'bottomPanel';
        }
        if (srcPart === 'top' || srcPart === 'bottom') {
          const single = address ? address.single : null;

          // If a mirrorPanel is set, the top or bottom panels are borders in real life
          if (srcPart === 'top' && single && single.top && single.top.mirrorPanel) {
            part = 'border';
          } else if (srcPart === 'bottom' && single && single.bottom && single.bottom.mirrorPanel) {
            part = 'border';
          } else {
            part = srcPart === 'top' ? 'topPanel' : 'bottomPanel';
          }
        }

        if (part && quilt && !(excludeHardCodedQuilts && quiltIsHardCoded)) {
          if (duplicateSamples) {
            let quiltMap = map[part];

            if (!quiltMap) {
              quiltMap = map[part] = {};
            }
            let quiltFromMap = quiltMap[quiltIdString];

            if (!quiltFromMap) {
              quiltFromMap = quilt;
              quiltMap[quiltIdString] = quiltFromMap;
              res.push({id: quiltId, type: part, configQuiltId: quiltConfigId, quiltName: quiltName});
            }
          } else {
            // {quilt01: {type: topPanel, id:quilt01}, quilt02: {type: borderPanel, id:quilt02}, ...}
            let quiltPartData = map[quiltIdString];

            if (!quiltPartData) {
              quiltPartData = map[quiltIdString] = {id: quiltId, configQuiltId: quiltConfigId, quiltName: quiltName};
              res.push(quiltPartData);
            }

            const oldPart = quiltPartData.type;

            if (oldPart) {
              const newPartPriority = typePriorities.indexOf(part, 0);
              const oldPartPriorty = typePriorities.indexOf(oldPart, 0);

              quiltPartData.type = newPartPriority < oldPartPriorty ? part : oldPart;
            } else {
              quiltPartData.type = part;
            }
          }
        }
      }
    }

    return res;
  }

  getSamples(params = null, resArray = null) {
    let res = resArray;

    res = MattressConfigDA.getSamples(this.getData(), params, res);
    const scene = this.sceneData;

    if (scene && scene.getSamples) {
      res = scene.getSamples(params, res);
    }

    return res;
  }

  /**
   * @method getSampleTypesAndIds
   * @description returns a list of sample ids and their location types (topPanel, bottomPanel, border)
   * @param {Object} params - Optional object containing any of the following params:
   *  - duplicateSamples {boolean} - (default = false) If true, the result will contain duplicate sample ids (but different location types)
   *  - typePriorities {Array} - If duplicateSamples is not allowed, this array specifies what location type should get priority (default = [topPanel (highest), bottomPanel, border(lowest)])
   * @return {Array} - List of {id: sampleId, type: locationType} objects
   * */
  getSampleTypesAndIds(params = null) {
    const sampleArray = this.getSamples(params);
    const res = [];

    if (!sampleArray) {
      return res;
    }
    const num = sampleArray.length;

    if (!num) {
      return res;
    }
    const map = {};

    // Allow duplicate samples
    const duplicateSamples = params ? (params.duplicateSamples === true) : false;
    const typePriorities = (params && params.typePriorities) ? (params.typePriorities) : ['topPanel', 'bottomPanel', 'border'];

    for (let i = 0; i < num; ++i) {
      const sampleInfo = sampleArray[i];

      if (sampleInfo) {
        const sample = sampleInfo.sample;
        const sampleId = sampleInfo.sampleId;
        const sampleIdString = `${sampleId}`;
        const address = sampleInfo.address;
        let srcPart = address ? address.part : null;
        let part = srcPart;

        srcPart = srcPart ? srcPart.toLowerCase() : srcPart;
        part = part ? part.toLowerCase() : part;

        if (part === 'topmirrorpanel') {
          part = 'topPanel';
        } else if (part === 'bottommirrorpanel') {
          part = 'bottomPanel';
        }

        if (srcPart === 'top' || srcPart === 'bottom') {
          const single = address ? address.single : null;

          // If a mirrorPanel is set, the top or bottom panels are borders in real life
          if (srcPart === 'top' && single && single.top && single.top.mirrorPanel) {
            part = 'border';
          } else if (srcPart === 'bottom' && single && single.bottom && single.bottom.mirrorPanel) {
            part = 'border';
          } else {
            part = srcPart === 'top' ? 'topPanel' : 'bottomPanel';
          }
        }

        if (part === 'toppanel') {
          part = 'topPanel';
        } else if (part === 'bottompanel') {
          part = 'bottomPanel';
        }

        if (part && sample) {
          if (duplicateSamples) {
            let sampleMap = map[part];

            if (!sampleMap) {
              sampleMap = map[part] = {};
            }
            let sampleFromMap = sampleMap[sampleIdString];

            if (!sampleFromMap) {
              sampleFromMap = sample;
              sampleMap[sampleIdString] = sampleFromMap;
              res.push({id: sampleId, type: part});
            }
          } else {
            // {sample01: {type: topPanel, id:sample01}, sample02: {type: borderPanel, id:sample02}, ...}
            let samplePartData = map[sampleIdString];

            if (!samplePartData) {
              samplePartData = map[sampleIdString] = {id: sampleId};
              res.push(samplePartData);
            }

            const oldPart = samplePartData.type;

            if (oldPart) {
              const newPartPriority = typePriorities.indexOf(part, 0);
              const oldPartPriorty = typePriorities.indexOf(oldPart, 0);

              samplePartData.type = newPartPriority < oldPartPriorty ? part : oldPart;
            } else {
              samplePartData.type = part;
            }
          }
        }
      }
    }

    return res;
  }

  getSampleIds(params = null) {
    const sampleArray = this.getSamples(params);
    const res = [];

    if (!sampleArray) {
      return res;
    }
    const num = sampleArray.length;

    if (!num) {
      return res;
    }
    for (let i = 0; i < num; ++i) {
      const sampleInfo = sampleArray[i];

      if (sampleInfo && typeof (sampleInfo.sampleId) !== 'undefined' && sampleInfo.sampleId !== null) {
        if (res.indexOf(sampleInfo.sampleId, 0) < 0) {
          res.push(sampleInfo.sampleId);
        }
      }
    }

    return res;
  }

  /**
   * @return {object} - config of all singles in a mattress
   */
  getSingles() {
    const d = this.getData();

    if (!d) {
      return null;
    }

    return d.singles;
  }

/**
 * @param {string|number|object} single - single with id, index of single or the single object itself
 * @return {object} - single object
 */
  getSingle(single) {
    const d = this.getData();

    return MattressConfigDA.getSingle(d, single);
  }

  /**
   * @method getNumSingles
   * @description Returns the number of singles in the current config
   * @return {int} number of singles
   * */
  getNumSingles() {
    const singles = this.getSingles();

    if (!singles) {
      return null;
    }

    return singles.length;
  }

  get numSingles() {
    return this.getNumSingles();
  }

  getSize(single, key, fallback = 0) {
    const d = this.getData();

    return MattressConfigDA.getSize(d, single, key, fallback);
  }

  setSize(single, key, value) {
    const d = this.getData();

    if (!d) {
      return;
    }

    const old = MattressConfigDA.getSize(d, single, key, value);

    if (old === value) {
      return;
    }
    MattressConfigDA.setSize(d, single, key, value);
    const propType = PropertyTypes.SIZE;

    propType.property = key;

    this._changedProperty(single, propType, value, old);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @return {number | null} width of single, can return null
   */
  getWidth(single) {
    return this.getSize(single, 'width', null);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {number} value - width of single
   */
  setWidth(single, value) {
    this.setSize(single, 'width', value);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @return {number | null} height of single, can return null
   */
  getHeight(single) {
    return this.getSize(single, 'height', null);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {number} value - height of single
   */
  setHeight(single, value) {
    this.setSize(single, 'height', value);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @return {number | null} length of single, can return null
   */
  getLength(single) {
    return this.getSize(single, 'length', null);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {number} value - length of single
   */
  setLength(single, value) {
    this.setSize(single, 'length', value);
  }


  setSample(single, value) {
    const old = this.getTopSample(single);

    MattressConfigDA.setSample(this.getData(), single, value);

    this._changedProperty(single, PropertyTypes.FABRIC, value, old);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @return {string} texture id
   */
  getTopSample(single) {
    return MattressConfigDA.getTopSample(this.getData(), single);
  }

  /**
  * @param {string|number|object} single - single with id, index of single or the single object itself
  * @param {string} value - texture id
  */
  setTopSample(single, value) {
    const old = MattressConfigDA.getTopSample(this.getData(), single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setTopSample(this.getData(), single, value);

    this._changedProperty(single, PropertyTypes.TOP_FABRIC_ID, value, old);
  }

  getBottomMirrorPanel(single) {
    return MattressConfigDA.getBottomMirrorPanel(this.getData(), single);
  }

  setBottomMirrorPanel(single, value) {
    const old = this.getBottomMirrorPanel(single);

    MattressConfigDA.setBottomMirrorPanel(this.getData(), single, value);

    this._changedProperty(single, PropertyTypes.BOTTOM_MIRROR_PANEL, value, old);
  }

  isBottomMirrorPanelEnabled(single) {
    return MattressConfigDA.isBottomMirrorPanelEnabled(this.getData(), single);
  }

  setBottomMirrorPanelEnabled(single, e) {
    const old = this.isBottomMirrorPanelEnabled(single);

    if (old === e) {
      return;
    }
    MattressConfigDA.setBottomMirrorPanelEnabled(this.getData(), single, e);
    this._changedProperty(single, PropertyTypes.BOTTOM_MIRROR_PANEL_ENABLED, e, old);
  }

  getBottomMirrorPanelSampleID(single) {
    return MattressConfigDA.getBottomMirrorPanelSampleID(this.getData(), single);
  }

  setBottomMirrorPanelSampleID(single, value) {
    const old = this.getBottomMirrorPanelSampleID(single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setBottomMirrorPanelSampleID(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.BOTTOM_MIRROR_PANEL_SAMPLE_ID, value, old);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string} value - texture id
   */
  setBottomSample(single, value) {
    const old = MattressConfigDA.getBottomSample(this.getData(), single);

    if (old === value) {
      return;
    }

    MattressConfigDA.setBottomSample(this.getData(), single, value);

    this._changedProperty(single, PropertyTypes.BOTTOM_FABRIC_ID, value, old);
  }

  getBottomSample(single) {
    return MattressConfigDA.getBottomSample(this.getData(), single);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @return {number | null} offset, can be null
   */
  getTopSampleOffsetX(single) {
    return MattressConfigDA.getTopSampleOffsetX(this.getData(), single, null);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {number} value - offset
   */
  setTopSampleOffsetX(single, value) {
    const old = this.getTopSampleOffsetX(single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setTopSampleOffsetX(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.TOP_FABRIC_OFFSET_X, value, old);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @return {number | null} offset, can be null
   */
  getTopSampleOffsetY(single) {
    return MattressConfigDA.getTopSampleOffsetY(this.getData(), single, null);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {number} value - offset
   */
  setTopSampleOffsetY(single, value) {
    const old = this.getTopSampleOffsetY(single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setTopSampleOffsetY(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.TOP_FABRIC_OFFSET_Y, value, old);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @return {number | null} offset, can be null
   */
  getBottomSampleOffsetX(single) {
    return MattressConfigDA.getBottomSampleOffsetX(this.getData(), single, null);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {number} value - offset
   */
  setBottomSampleOffsetX(single, value) {
    const old = this.getBottomSampleOffsetX(single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setBottomSampleOffsetX(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.BOTTOM_FABRIC_OFFSET_X, value, old);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @return {number | null} offset, can be null
   */
  getBottomSampleOffsetY(single) {
    return MattressConfigDA.getBottomSampleOffsetY(this.getData(), single, null);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {number} value - offset
   */
  setBottomSampleOffsetY(single, value) {
    const old = this.getBottomSampleOffsetY(single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setBottomSampleOffsetY(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.BOTTOM_FABRIC_OFFSET_Y, value, old);
  }

  setTopSampleAlign(single, x, y) {
    MattressConfigDA.setTopSampleAlign(this.getData(), single, x, y);
    this._changedProperty(single, PropertyTypes.TOP_FABRIC_ALIGN);
  }

  getSampleService() {
    return this._sampleService;
  }

  setSampleService(ss) {
    this._sampleService = ss;
  }

  getQuiltService() {
    return this._quiltService;
  }

  setQuiltService(qs) {
    this._quiltService = qs;
  }

  getTopSampleAlignX(single, fallback = 0.5) {
    return MattressConfigDA.getTopSampleAlignX(this.getData(), single, this.getSampleService(), fallback);
  }

  setTopSampleAlignX(single, x) {
    const old = this.getTopSampleAlignX(single);

    if (old === x) {
      return;
    }
    MattressConfigDA.setTopSampleAlignX(this.getData(), single, x);
    this._changedProperty(single, PropertyTypes.TOP_FABRIC_ALIGN_X, x, old);
  }

  getTopSampleAlignY(single, fallback = 0.5) {
    return MattressConfigDA.getTopSampleAlignY(this.getData(), single, this.getSampleService(), fallback);
  }

  setTopSampleAlignY(single, y) {
    const old = this.getTopSampleAlignY(single);

    if (old === y) {
      return;
    }
    MattressConfigDA.setTopSampleAlignY(this.getData(), single, y);
    this._changedProperty(single, PropertyTypes.TOP_FABRIC_ALIGN_Y, y, old);
  }

  setBottomSampleAlign(single, x, y) {
    MattressConfigDA.setBottomSampleAlign(this.getData(), single, x, y);
    this._changedProperty(single, PropertyTypes.BOTTOM_FABRIC_ALIGN);
  }

  getBottomSampleAlignX(single, fallback = 0.5) {
    return MattressConfigDA.getBottomSampleAlignX(this.getData(), single, this.getSampleService(), fallback);
  }

  setBottomSampleAlignX(single, x) {
    const old = this.getBottomSampleAlignX(single);

    if (old === x) {
      return;
    }
    MattressConfigDA.setBottomSampleAlignX(this.getData(), single, x);
    this._changedProperty(single, PropertyTypes.BOTTOM_FABRIC_ALIGN_X, x, old);
  }

  getBottomSampleAlignY(single, fallback = 0.5) {
    return MattressConfigDA.getBottomSampleAlignY(this.getData(), single, this.getSampleService(), fallback);
  }

  setBottomSampleAlignY(single, y) {
    const old = this.getBottomSampleAlignY(single);

    if (old === y) {
      return;
    }
    MattressConfigDA.setBottomSampleAlignY(this.getData(), single, y);
    this._changedProperty(single, PropertyTypes.BOTTOM_FABRIC_ALIGN_Y, y, old);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @return {number} rotation
   */
  getTopSampleRotation(single) {
    return MattressConfigDA.getTopSampleRotation(this.getData(), single, this.getSampleService());
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {number} v - rotation
   */
  setTopSampleRotation(single, v) {
    const old = this.getTopSampleRotation(single);

    if (old === v) {
      return;
    }

    MattressConfigDA.setTopSampleRotation(this.getData(), single, v);
    this._changedProperty(single, PropertyTypes.TOP_FABRIC_ROTATION, v, old);
  }

  /**
   * @method rotateTopSample
   * @description Rotates the top sample. If degrees equals 0, nothing will happen
   * @param {Object|int|string} single - single object, single index (int) or single id (string)
   * @param {number} degrees - the amount of degrees to rotate (default = 90)
   * @return {void}
   * */
  rotateTopSample(single, degrees = 90) {
    if (degrees === 0) {
      return;
    }
    this.setTopSampleRotation(single, this.getTopSampleRotation(single) + degrees);
  }

  /**
   * @method increaseTopSampleRotation
   * @description Same behavior as rotateTopSample
   * @param {Object|int|string} single - single object, single index (int) or single id (string)
   * @param {number} degrees - the amount of degrees to rotate (default = 90)
   * @return {void}
   * */
  increaseTopSampleRotation(single, degrees = 90) {
    this.rotateTopSample(single, degrees);
  }

  /**
  * @method decreaseTopSampleRotation
  * @description Same as increaseTopSampleRotation or rotateTopSample but rotates in the opposite direction
  * @param {Object|int|string} single - single object, single index (int) or single id (string)
  * @param {number} degrees - the amount of degrees to rotate (default = 90)
  * @return {void}
  * */
  decreaseTopSampleRotation(single, degrees = 90) {
    this.rotateTopSample(single, -degrees);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @return {number} rotation
   */
  getBottomSampleRotation(single) {
    return MattressConfigDA.getBottomSampleRotation(this.getData(), single, this.getSampleService());
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {number} v - rotation
   */
  setBottomSampleRotation(single, v) {
    const old = this.getBottomSampleRotation(single);

    if (old === v) {
      return;
    }

    MattressConfigDA.setBottomSampleRotation(this.getData(), single, v);
    this._changedProperty(single, PropertyTypes.BOTTOM_FABRIC_ROTATION, v, old);
  }

  /**
   * @method rotateBottomSample
   * @description Rotates the bottom sample. If degrees equals 0, nothing will happen
   * @param {Object|int|string} single - single object, single index (int) or single id (string)
   * @param {number} degrees - the amount of degrees to rotate (default = 90)
   * @return {void}
   * */
  rotateBottomSample(single, degrees = 90) {
    if (degrees === 0) {
      return;
    }
    this.setBottomSampleRotation(single, this.getBottomSampleRotation(single) + degrees);
  }

  /**
   * @method increaseBottomSampleRotation
   * @description Same behavior as rotateBottomSample
   * @param {Object|int|string} single - single object, single index (int) or single id (string)
   * @param {number} degrees - the amount of degrees to rotate (default = 90)
   * @return {void}
   * */
  increaseBottomSampleRotation(single, degrees = 90) {
    this.rotateBottomSample(single, degrees);
  }

  /**
  * @method decreaseBottomSampleRotation
  * @description Same as increaseBottomSampleRotation or rotateBottomSample but rotates in the opposite direction
  * @param {Object|int|string} single - single object, single index (int) or single id (string)
  * @param {number} degrees - the amount of degrees to rotate (default = 90)
  * @return {void}
  * */
  decreaseBottomSampleRotation(single, degrees = 90) {
    this.rotateBottomSample(single, -degrees);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @return {number | null} height, can be null
   */
  getTopHeight(single) {
    return MattressConfigDA.getTopHeight(this.getData(), single, null);
  }


  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {number} value - height
   */
  setTopHeight(single, value) {
    const d = this.getData();
    const oldValue = MattressConfigDA.getTopHeight(d, single);

    if (oldValue === value) {
      return;
    }
    MattressConfigDA.setTopHeight(d, single, value);
    this._changedProperty(single, PropertyTypes.TOP_BORDER_HEIGHT, value, oldValue);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @return {number | null} height, can be null
   */
  getBottomHeight(single) {
    return MattressConfigDA.getBottomHeight(this.getData(), single, null);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {number} value - height
   */
  setBottomHeight(single, value) {
    const d = this.getData();
    const oldValue = MattressConfigDA.getBottomHeight(d, single, value);

    if (oldValue === value) {
      return;
    }
    MattressConfigDA.setBottomHeight(d, single, value);
    this._changedProperty(single, PropertyTypes.BOTTOM_BORDER_HEIGHT, value, oldValue);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @return {number | null} radius, can be null
   */
  getTopBorderRadius(single) {
    return MattressConfigDA.getTopBorderRadius(this.getData(), single, null);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {number} value - radius
   */
  setTopBorderRadius(single, value) {
    const d = this.getData();
    const oldValue = MattressConfigDA.getTopBorderRadius(d, single);

    if (oldValue === value) {
      return;
    }
    MattressConfigDA.setTopBorderRadius(d, single, value);
    this._changedProperty(single, PropertyTypes.TOP_BORDER_RADIUS, value, oldValue);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @return {number | null} radius, can be null
   */
  getBottomBorderRadius(single) {
    return MattressConfigDA.getBottomBorderRadius(this.getData(), single, null);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {number} value - radius
   */
  setBottomBorderRadius(single, value) {
    const d = this.getData();
    const oldValue = MattressConfigDA.getBottomBorderRadius(d, single);

    if (oldValue === value) {
      return;
    }
    MattressConfigDA.setBottomBorderRadius(d, single, value);
    this._changedProperty(single, PropertyTypes.BOTTOM_BORDER_RADIUS, value, oldValue);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @return {number | null} radius, can be null
   */
  getCornerRadius(single) {
    return MattressConfigDA.getCornerRadius(this.getData(), single, null);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {number} value - radius
   */
  setCornerRadius(single, value) {
    const d = this.getData();
    const oldValue = MattressConfigDA.getCornerRadius(d, single);

    if (oldValue === value) {
      return;
    }
    MattressConfigDA.setCornerRadius(d, single, value);
    this._changedProperty(single, PropertyTypes.CORNER_RADIUS, value, oldValue);
  }


  // Top quilt align
  getTopQuiltAlignX(single) {
    return MattressConfigDA.getTopQuiltAlignX(this.getData(), single, this.getQuiltService());
  }

  setTopQuiltAlignX(single, value) {
    const old = this.getTopQuiltAlignX(single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setTopQuiltAlignX(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.TOP_QUILT_ALIGN_X, value, old);
  }

  getTopQuiltAlignY(single) {
    return MattressConfigDA.getTopQuiltAlignY(this.getData(), single, this.getQuiltService());
  }

  setTopQuiltAlignY(single, value) {
    const old = this.getTopQuiltAlignY(single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setTopQuiltAlignY(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.TOP_QUILT_ALIGN_Y, value, old);
  }

  setTopQuiltAlign(single, x, y) {
    const oldX = this.getTopQuiltAlignX(single);
    const oldY = this.getTopQuiltAlignY(single);

    if (x === oldX && y === oldY) {
      return;
    }
    MattressConfigDA.setTopQuiltAlign(this.getData(), single, x, y);
    this._changedProperty(single, PropertyTypes.TOP_QUILT_ALIGN_XY);
  }

  // bottom quilt align

  getBottomQuiltAlignX(single) {
    return MattressConfigDA.getBottomQuiltAlignX(this.getData(), single, this.getQuiltService());
  }

  setBottomQuiltAlignX(single, value) {
    const old = this.getBottomQuiltAlignX(single);

    if (old === value) {
      return;
    }

    MattressConfigDA.setBottomQuiltAlignX(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.BOTTOM_QUILT_ALIGN_X, value, old);
  }

  getBottomQuiltAlignY(single) {
    return MattressConfigDA.getBottomQuiltAlignY(this.getData(), single, this.getQuiltService());
  }

  setBottomQuiltAlignY(single, value) {
    const old = this.getBottomQuiltAlignY(single, value);

    if (old === value) {
      return;
    }
    MattressConfigDA.setBottomQuiltAlignY(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.BOTTOM_QUILT_ALIGN_Y, value, old);
  }

  setBottomQuiltAlign(single, x, y) {
    const oldX = MattressConfigDA.getBottomQuiltAlignX();
    const oldY = MattressConfigDA.getBottomQuiltAlignY();

    if (oldX === x || oldY === y) {
      return;
    }

    MattressConfigDA.getBottomQuiltAlign(this.getData(), single, x, y);
    this._changedProperty(single, PropertyTypes.BOTTOM_QUILT_ALIGN_XY);
  }

  getBorderQuiltAlignX(single, border) {
    return MattressConfigDA.getBorderQuiltAlignX(this.getData(), single, border, this.getQuiltService());
  }

  setBorderQuiltAlignX(single, border, value) {
    const old = this.getBorderQuiltAlignX(single, border, value);

    if (old === value) {
      return;
    }
    MattressConfigDA.setBorderQuiltAlignX(this.getData(), single, border, value);
    this._changedProperty(single, {border: border, property: 'bordercomponent-quilt-align-x'}, value, old);
  }

  getBorderQuiltAlignY(single, border) {
    return MattressConfigDA.getBorderQuiltAlignY(this.getData(), single, border, this.getQuiltService());
  }

  setBorderQuiltAlignY(single, border, value) {
    const old = this.getBorderQuiltAlignY(single, border, value);

    if (old === value) {
      return;
    }
    MattressConfigDA.setBorderQuiltAlignY(this.getData(), single, border, value);
    this._changedProperty(single, {border: border, property: 'bordercomponent-quilt-align-y'}, value, old);
  }

  setBorderQuiltAlign(single, border, x, y) {
    const oldX = this.getBorderQuiltAlignX(single, border);
    const oldY = this.getBorderQuiltAlignY(single, border);

    if (oldX === x && oldY === y) {
      return;
    }
    MattressConfigDA.setBorderQuiltAlign(this.getData(), single, border, x, y);
    this._changedProperty(single, {border: border, property: 'bordercomponent-quilt-align-xy'});
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string | Object} type - type of border as string or the border component object itself
   * @param {number} index - position index of border.
   *  (default = -1, which means the component will be added at the end of the list)
   * @return {object} Added border component object
   */
  addBorderComponent(single, type, index = -1) {
    const configData = this.getData();
    const result = MattressConfigDA.addBorderComponent(configData, single, type, index);

    if (result) {
      const borderComponents = MattressConfigDA.getBorderComponents(configData, single);
      const singleObject = MattressConfigDA.getSingle(configData, single);

      this._setSingleDataInfo(result, MattressConfigObjectTypes.BORDER_COMPONENT, borderComponents, singleObject, null);

      this._changedProperty(single, {border: result, property: 'add-bordercomponent'});
    }

    return result;
  }

  getBorderComponent(single, border) {
    return MattressConfigDA.getBorderComponent(this.getData(), single, border);
  }

  removeBorderComponent(single, border) {
    const borderComp = this.getBorderComponent(single, border);
    const result = MattressConfigDA.removeBorderComponent(this.getData(), single, border);

    if (result) {
      this._removeDataInfo(borderComp);
      this._changedProperty(single, {border: border, property: 'remove-bordercomponent'});
    }

    return result;
  }

  canChangeBorderComponentProperty(single, border, property) {
    const data = this.getData();

    if (
      property === 'height' &&
      !MattressConfigDA.hasEditableBorderPropertyDefined(data, single, property, border)
    ) {
      const fallback = true;

      // fallback to boxSpringController
      const boxspringController = this._getBoxSpringController();

      if (!boxspringController || this.getNumSingles() === 1) {
        return fallback;
      }
      const singleObject = MattressConfigDA.getSingle(data, single);

      // It should be possible to change the border component height
      // when the single is part of the boxspring single stack
      return boxspringController.isStackedSingle(singleObject);
    }

    return MattressConfigDA.canChangeBorderComponentProperty(data, single, border, property);
  }

  canChangeBorderComponentHeight(single, border) {
    return this.canChangeBorderComponentProperty(single, border, 'height');
  }

  getBorderComponentIndex(single, border) {
    return MattressConfigDA.getBorderComponentIndex(this.getData(), single, border);
  }

  setBorderComponentIndex(single, border, index) {
    const old = this.getBorderComponentIndex(single, border);

    if (old === index) {
      return;
    }

    MattressConfigDA.setBorderComponentIndex(this.getData(), single, border, index);
    this._changedProperty(single, {border: border, property: 'set-bordercomponent-index'});
  }

  getBorderComponents(single) {
    return MattressConfigDA.getBorderComponents(this.getData(), single);
  }

  getAvailableBorderComponentTypes(single) {
    const data = this.getData();
    const hasTypes = MattressConfigDA.singleHasAvailableBorderComponentTypes(data, single);

    if (hasTypes) {
      return MattressConfigDA.getAvailableBorderComponentTypes(data, single);
    }
    const singleObject = MattressConfigDA.getSingle(data, single);
    const boxSpringController = this._getBoxSpringController();

    if (boxSpringController) {
      return (
          (singleObject === boxSpringController.getMattress()) ||
          (singleObject === boxSpringController.getTopper())
        ) ?
        MattressConfigDA.getAvailableBorderComponentTypes(data, single) :
        [];
    }

    return MattressConfigDA.getAvailableBorderComponentTypes(data, single);
  }

  getComponentName(component) {
    if (!component) {
      return null;
    }
    const internalInfo = this._getObjectInfo(component);

    if (internalInfo) {
      const objectType = internalInfo.type;

      if (objectType === MattressConfigObjectTypes.SINGLE) {
        return this.getSingleName(component);
      } else if (objectType === MattressConfigObjectTypes.BORDER_COMPONENT) {
        return this.getBorderComponentName(internalInfo.single, component);
      }
    }

    // Fallback
    return component.placeholder || null;
  }

  setComponentName(component, value) {
    if (!component) {
      return;
    }
    const internalInfo = this._getObjectInfo(component);
    let success = false;

    if (internalInfo) {
      const objectType = internalInfo.type;

      if (objectType === MattressConfigObjectTypes.SINGLE) {
        this.setSingleName(component, value);
        success = true;
      } else if (objectType === MattressConfigObjectTypes.BORDER_COMPONENT) {
        this.setBorderComponentName(internalInfo.single, component, value);
        success = true;
      }
    }

    // Fallback
    if (!success) {
      const old = this.getComponentName(component);

      if (old !== value) {
        component.placeholder = value;
        this._setDirty(true);
      }
    }
  }

  getBorderComponentName(single, borderComponent) {
    const data = this.getData();

    return MattressConfigDA.getBorderComponentName(data, single, borderComponent);
  }

  setBorderComponentName(single, borderComponent, value) {
    const old = this.getBorderComponentName(single, borderComponent);

    if (old === value) {
      return;
    }
    const data = this.getData();

    MattressConfigDA.setBorderComponentName(data, single, borderComponent, value);
    this._setDirty(true);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string|number|object} borderComponent - component with id, index of component, or the component object itself
   * @return {number | null} height, can be null
   */
  getBorderComponentHeight(single, borderComponent) {
    return MattressConfigDA.getBorderComponentHeight(this.getData(), single, borderComponent, null);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string|number|object} borderComponent - component with id, index of component or the component object itself
   * @param {number} value - height
   */
  setBorderComponentHeight(single, borderComponent, value) {
    const old = this.getBorderComponentHeight(single, borderComponent);

    if (old === value) {
      return;
    }
    MattressConfigDA.setBorderComponentHeight(this.getData(), single, borderComponent, value);
    this._changedProperty(single, {border: borderComponent, property: 'bordercomponent-height'}, value, old);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string|number|object} borderComponent - component with id, index of component or the component object itself
   * @return {number | null} height, can be null
   */
  getBorderComponentDepth(single, borderComponent) {
    return MattressConfigDA.getBorderComponentDepth(this.getData(), single, borderComponent, null);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string|number|object} borderComponent - component with id, index of component or the component object itself
   * @param {number} value - depth
   */
  setBorderComponentDepth(single, borderComponent, value) {
    const old = this.getBorderComponentDepth(single, borderComponent);

    if (old === value) {
      return;
    }
    MattressConfigDA.setBorderComponentDepth(this.getData(), single, borderComponent, value);
    this._changedProperty(single, {border: borderComponent, property: 'bordercomponent-depth'}, value, old);
  }

  getBorderComponentTextureRotation(single, borderComponent, fallback = 0) {
    return MattressConfigDA.getBorderComponentTextureRotation(this.getData(), single, borderComponent, this.getSampleService(), fallback);
  }

  setBorderComponentTextureRotation(single, borderComponent, value) {
    const old = this.getBorderComponentTextureRotation(single, borderComponent);

    if (old === value) {
      return;
    }

    MattressConfigDA.setBorderComponentTextureRotation(this.getData(), single, borderComponent, value);
    this._changedProperty(single, {border: borderComponent, property: 'bordercomponent-fabric-rotation'}, value, old);
  }

  /**
   * @method rotateBorderComponentTexture
   * @description Rotates a border sample. If degrees equals 0, nothing will happen
   * @param {Object|int|string} single - single object, single index (int) or single id (string)
   * @param {Object|int|string} borderComponent - border component, border component index (int) or border component id (string)
   * @param {number} degrees - the amount of degrees to rotate (default = 90)
   * @return {void}
   * */
  rotateBorderComponentTexture(single, borderComponent, degrees = 90) {
    if (degrees === 0) {
      return;
    }
    this.setBorderComponentTextureRotation(single, borderComponent, this.getBorderComponentTextureRotation(single, borderComponent) + degrees);
  }

  /**
   * @method increaseBorderComponentTextureRotation
   * @description Same behavior as rotateBorderComponentTexture
   * @param {Object|int|string} single - single object, single index (int) or single id (string)
   * @param {Object|int|string} borderComponent - border component, border component index (int) or border component id (string)
   * @param {number} degrees - the amount of degrees to rotate (default = 90)
   * @return {void}
   * */
  increaseBorderComponentTextureRotation(single, borderComponent, degrees = 90) {
    this.rotateBorderComponentTexture(single, borderComponent, degrees);
  }

  /**
  * @method decreaseBorderComponentTextureRotation
  * @description Same as increaseBorderComponentTextureRotation or rotateBorderComponentTexture but rotates in the opposite direction
  * @param {Object|int|string} single - single object, single index (int) or single id (string)
  * @param {Object|int|string} borderComponent - border component, border component index (int) or border component id (string)
  * @param {number} degrees - the amount of degrees to rotate (default = 90)
  * @return {void}
  * */
  decreaseBorderComponentTextureRotation(single, borderComponent, degrees = 90) {
    this.rotateBorderComponentTexture(single, borderComponent, -degrees);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string|number|object} borderComponent - component with id, index of component or the component object itself
   * @return {number | null} offset, can be null
   */
  getBorderComponentTextureOffsetX(single, borderComponent) {
    return MattressConfigDA.getBorderComponentTextureOffsetX(this.getData(), single, borderComponent, null);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string|number|object} borderComponent - component with id, index of component or the component object itself
   * @return {number | null} offset, can be null
   */
  getBorderComponentTextureOffsetY(single, borderComponent) {
    return MattressConfigDA.getBorderComponentTextureOffsetY(this.getData(), single, borderComponent, null);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string|number|object} borderComponent - component with id, index of component or the component object itself
   * @param {number} x - x offset
   * @param {number} y - y offset
   */
  setBorderComponentTextureOffset(single, borderComponent, x, y) {
    MattressConfigDA.setBorderComponentTextureOffset(this.getData(), single, borderComponent, x, y);

    this._changedProperty(single, {border: borderComponent, property: 'bordercomponent-fabric-offset', x: x, y: y});
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string|number|object} borderComponent - component with id, index of component or the component object itself
   * @param {number} x - x offset
   */
  setBorderComponentTextureOffsetX(single, borderComponent, x) {
    const old = this.getBorderComponentTextureOffsetX(single, borderComponent, null);

    if (old === x) {
      return;
    }
    MattressConfigDA.setBorderComponentTextureOffsetX(this.getData(), single, borderComponent, x);
    this._changedProperty(single, {border: borderComponent, property: 'bordercomponent-fabric-offset-x'}, x, old);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string|number|object} borderComponent - component with id, index of component or the component object itself
   * @param {number} y - y offset
   */
  setBorderComponentTextureOffsetY(single, borderComponent, y) {
    const old = this.getBorderComponentTextureOffsetY(single, borderComponent, null);

    if (old === y) {
      return;
    }
    MattressConfigDA.setBorderComponentTextureOffsetY(this.getData(), single, borderComponent, y);
    this._changedProperty(single, {border: borderComponent, property: 'bordercomponent-fabric-offset-y'}, y, old);
  }

  setBorderComponentTextureAlign(single, borderComponent, x, y) {
    MattressConfigDA.setBorderComponentTextureAlign(this.getData(), single, borderComponent, x, y);
  }

  getBorderComponentTextureAlignX(single, borderComponent, fallback = 0.5) {
    return MattressConfigDA.getBorderComponentTextureAlignX(this.getData(), single, borderComponent, this.getSampleService(), fallback);
  }

  setBorderComponentTextureAlignX(single, borderComponent, value) {
    const old = this.getBorderComponentTextureAlignX(single, borderComponent);

    if (old === value) {
      return;
    }
    MattressConfigDA.setBorderComponentTextureAlignX(this.getData(), single, borderComponent, value);

    this._changedProperty(single, {border: borderComponent, property: 'bordercomponent-fabric-align-x'}, value, old);
  }

  getBorderComponentTextureAlignY(single, borderComponent, fallback) {
    return MattressConfigDA.getBorderComponentTextureAlignY(this.getData(), single, borderComponent, this.getSampleService(), fallback);
  }

  setBorderComponentTextureAlignY(single, borderComponent, value) {
    const old = this.getBorderComponentTextureAlignY(single, borderComponent);

    if (old === value) {
      return;
    }

    MattressConfigDA.setBorderComponentTextureAlignY(this.getData(), single, borderComponent, value);
    this._changedProperty(single, {border: borderComponent, property: 'bordercomponent-fabric-align-y'}, value, old);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string|number|object} border - component with id, index of component or the component object itself
   * @return {object} border
   */
  getBorderComponentSample(single, border) {
    return MattressConfigDA.getBorderComponentSample(this.getData(), single, border);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string|number|object} border - component with id, index of component or the component object itself
   * @param {string|object} value - component with id, index of component or the component object itself
   */
  setBorderComponentSample(single, border, value) {
    const old = MattressConfigDA.getBorderComponentSample(this.getData(), single, border);

    if (old === value) {
      return;
    }
    MattressConfigDA.setBorderComponentSample(this.getData(), single, border, value);

    this._changedProperty(single, {border: border, property: 'bordercomponent-sample'}, value, old);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string|number|object} border - component with id, index of component or the component object itself
   * @return {string} sample id
   */
  getBorderComponentSampleID(single, border) {
    return MattressConfigDA.getBorderComponentSampleID(this.getData(), single, border, null);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string|number|object} border - component with id, index of component or the component object itself
   * @param {string} value - id
   */
  setBorderComponentSampleID(single, border, value) {
    const old = this.getBorderComponentSampleID(single, border);

    if (old === value) {
      return;
    }
    MattressConfigDA.setBorderComponentSampleID(this.getData(), single, border, value);
    this._changedProperty(single, {border: border, name: 'bordercomponent-sample'}, value, old);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string|number|object} border - component with id, index of component or the component object itself
   * @return {object|null} materialType, can be null
   */
  getBorderComponentMaterialType(single, border) {
    return MattressConfigDA.getBorderComponentMaterialType(this.getData(), single, border, null);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string|number|object} border - component with id, index of component or the component object itself
   * @param {object} materialType - object of materialType
   */
  setBorderComponentMaterialType(single, border, materialType) {
    const old = this.getBorderComponentMaterialType(single, border);

    if (old === materialType) {
      return;
    }

    MattressConfigDA.setBorderComponentMaterialType(this.getData(), single, border, materialType);

    this._changedProperty(single, {border: border, name: 'bordercomponent-materialtype'}, materialType, old);
  }

  setBorderComponentMaterialTypeAndColor(single, border, materialType, color) {
    const oldMT = this.getBorderComponentMaterialType(single, border);
    const oldCol = this.getBorderComponentColor(single, border);

    if (oldMT === materialType && oldCol === color) {
      return;
    }
    const data = this.getData();

    MattressConfigDA.setBorderComponentMaterialType(data, single, border, materialType);
    MattressConfigDA.setBorderComponentColor(data, single, border, color);

    this._changedProperty(single, {border: border, name: 'bordercomponent-materialtype-color'});
  }

  getBorderComponentColorId(single, borderComponent) {
    return MattressConfigDA.getBorderComponentColorId(this.getData(), single, borderComponent, null);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string|number|object} border - component with id, index of component or the component object itself
   * @return {string|null} hex color, can be null
   */
  getBorderComponentColor(single, border) {
    return MattressConfigDA.getBorderComponentColor(this.getData(), single, border, null);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string|number|object} border - component with id, index of component or the component object itself
   * @param {string} color - hex color
   */
  setBorderComponentColor(single, border, color) {
    const old = this.getBorderComponentColor(single, border);

    if (old === color) {
      return;
    }
    MattressConfigDA.setBorderComponentColor(this.getData(), single, border, color);

    this._changedProperty(single, {border: border, name: 'bordercomponent-color'}, color, old);
  }

  getAvailableZipperTypes(single) {
    return ['teet', 'spiral_coil', 'reverse_coil'];
  }
  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string|number|object} border - component with id, index of component or the component object itself
   * @return {string|null} zipperType, can be null
   */
  getBorderComponentZipperType(single, border) {
    return MattressConfigDA.getBorderComponentZipperType(this.getData(), single, border, null);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string|number|object} border - component with id, index of component or the component object itself
   * @param {string} zipperType - teeth, spiral_coil or reverse_coil
   */
  setBorderComponentZipperType(single, border, zipperType) {
    const old = this.getBorderComponentZipperType(single, border);

    if (old === zipperType) {
      return;
    }
    MattressConfigDA.setBorderComponentZipperType(this.getData(), single, border, zipperType);
    this._changedProperty(single, {border: border, name: 'bordercomponent-zippertype'}, zipperType, old);
  }

  /**
   * @method getBorderRibbon
   * @description returns the ribbon object of a border component (usually a fabric border component)
   * @param {Object|String|int} single - single object, single id (string) or single index
   * @param {Object|String|int} border - border object, border id (string) or border index
   * @return {Object} - ribbon object
   * */
  getBorderRibbon(single, border) {
    return MattressConfigDA.getBorderRibbon(this.getData(), single, border);
  }

  /**
  * @method setBorderRibbon
  * @description Sets the border ribbon object. Use null to remove the ribbon
  * @param {Object|String|int} single - single object, single id (string) or single index
  * @param {Object|String|int} border - border object, border id (string) or border index
  * @param {Object} value - ribbon object or null
  * @return {void}
  * */
  setBorderRibbon(single, border, value) {
    const old = this.getBorderRibbon(single, border);

    MattressConfigDA.setBorderRibbon(this.getData(), single, border, value);
    this._changedProperty(single, {border: border, name: 'bordercomponent-ribbon'}, value, old);
  }

  /**
  * @method isBorderRibbonEnabled
  * @description returns true if the border ribbon is enabled
  * @param {Object|String|int} single - single object, single id (string) or single index
  * @param {Object|String|int} border - border object, border id (string) or border index
  * @return {Boolean} - true if ribbon is enabled
  * */
  isBorderRibbonEnabled(single, border) {
    return MattressConfigDA.isBorderRibbonEnabled(this.getData(), single, border);
  }

  /**
  * @method setBorderRibbonEnabled
  * @description enables or disables the border ribbon
  * @param {Object|String|int} single - single object, single id (string) or single index
  * @param {Object|String|int} border - border object, border id (string) or border index
  * @param {Boolean} enabled - ribbon enabled value
  * @return {void}
  * */
  setBorderRibbonEnabled(single, border, enabled) {
    const old = this.isBorderRibbonEnabled(single, border);

    if (old === enabled) {
      return;
    }
    MattressConfigDA.setBorderRibbonEnabled(this.getData(), single, border, enabled);
    this._changedProperty(single, {border: border, name: 'bordercomponent-ribbon-enabled'}, enabled, old);
  }

  /**
  * @method getBorderRibbonColor
  * @description Returns the border ribbon color
  * @param {Object|String|int} single - single object, single id (string) or single index
  * @param {Object|String|int} border - border object, border id (string) or border index
  * @return {String} border ribbon color
  * */
  getBorderRibbonColor(single, border) {
    return MattressConfigDA.getBorderRibbonColor(this.getData(), single, border);
  }

  /**
  * @method setBorderRibbonColor
  * @description Sets the border ribbon color
  * @param {Object|String|int} single - single object, single id (string) or single index
  * @param {Object|String|int} border - border object, border id (string) or border index
  * @param {String} color - color value (#hex or color code)
  * @return {void}
  * */
  setBorderRibbonColor(single, border, color) {
    const old = this.getBorderRibbonColor(single, border);

    if (old === color) {
      return;
    }
    MattressConfigDA.setBorderRibbonColor(this.getData(), single, border, color);
    this._changedProperty(single, {border: border, name: 'bordercomponent-ribbon-color'}, color, old);
  }

  setBorderRibbonMaterialTypeAndColor(single, border, mt, color) {
    const oldC = this.getBorderRibbonColor(single, border);
    const oldMT = this.getBorderRibbonMaterialType(single, border);

    if (oldC === color && oldMT === mt) {
      return;
    }
    const data = this.getData();

    MattressConfigDA.setBorderRibbonColor(data, single, border, color);
    const newMT = MattressConfigDA.setBorderRibbonMaterial(data, single, border, mt);

    if (newMT === oldMT) {
      return;
    }

    this._changedProperty(single, {border: border, name: 'bordercomponent-ribbon-materialtype-color'});
  }

  /**
  * @method getBorderRibbonMaterialType
  * @description Returns the border ribbon material type
  * @param {Object|String|int} single - single object, single id (string) or single index
  * @param {Object|String|int} border - border object, border id (string) or border index
  * @return {String} border ribbon material type (satin / cotton / null)
  * */
  getBorderRibbonMaterialType(single, border) {
    return MattressConfigDA.getBorderRibbonMaterial(this.getData(), single, border);
  }

  /**
  * @method setBorderRibbonMaterialType
  * @description Sets the border ribbon material type
  * @param {Object|String|int} single - single object, single id (string) or single index
  * @param {Object|String|int} border - border object, border id (string) or border index
  * @param {String} material - material type (satin / cotton or null = default)
  * @return {void}
  * */
  setBorderRibbonMaterialType(single, border, material) {
    const old = this.getBorderRibbonMaterialType(single, border);

    if (old === material) {
      return;
    }
    MattressConfigDA.setBorderRibbonMaterial(this.getData(), single, border, material);
    const newMT = this.getBorderRibbonMaterialType(single, border);

    if (old === newMT) {
      return;
    }

    this._changedProperty(single, {border: border, name: 'bordercomponent-ribbon-material'}, material, old);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @return {number} height
   */
  getTotalBorderComponentHeight(single) {
    return MattressConfigDA.getTotalBorderComponentHeight(this.getData(), single);
  }

  getHandleData(single) {
    const data = this.getData();

    return MattressConfigDA.getHandleData(data, single);
  }

  getMaxHandles(single, atFront) {
    return MattressConfigDA.getMaxHandles(this.getData(), single, atFront);
  }

  setMaxHandles(single, atFront, value = -1) {
    const oldValue = this.getMaxHandles(single, atFront);

    if (oldValue === value) {
      return;
    }
    MattressConfigDA.setMaxHandles(this.getData(), single, atFront, value);

    this._changedProperty(single, PropertyTypes.HANDLE_MAX_COUNT, value, oldValue);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @return {object} textureslots
   */
  getHandleTextureSlotsBySingle(single) {
    return MattressConfigDA.getHandleTextureSlotsBySingle(this.getData(), single);
  }

  getAvailableHandles(single, params) {
    return MattressConfigDA.getAvailableHandles(this.getData(), single, params);
  }

  getAvailableHandlesOnBorder(single, border, params) {
    return MattressConfigDA.getAvailableHandlesOnBorder(this.getData(), single, border, params);
  }

  allowHandle(single, type) {
    return MattressConfigDA.allowHandle(this.getData(), single, type);
  }

  allowHandleOnBorder(single, border, handleType) {
    return MattressConfigDA.allowHandleOnBorder(this.getData(), single, border, handleType);
  }

  canSetHandleColor(single, part) {
    return MattressConfigDA.cansetHandleColor(this.getData(), single, part);
  }

  getHandleColor(single, part, fallback = null) {
    return MattressConfigDA.getHandleColor(this.getData(), single, part, fallback);
  }

  setHandleColor(single, part, value) {
    const old = this.getHandleColor(single, part);

    if (old === value) {
      return;
    }
    const data = this.getData();
    let force = false;

    if (part === null || typeof (part) === 'undefined') {
      const handleData = MattressConfigDA.getHandleData(data, single, false);

      if (handleData && handleData.style && handleData.style.materials) {
        // Previous material is probably a 'multi-material' with different slots
        // (tape / fabric)
        // Now, part equals null so we set a single-material.
        force = true;
      }
    }

    if (!force && old === value) {
      return;
    }

    MattressConfigDA.setHandleColor(this.getData(), single, part, value);
    const property = PropertyTypes.HANDLE_COLOR;

    property.part = part;
    this._changedProperty(single, property, value, old);
    property.part = null;

  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string} part - (optional) part of handle, can be 'fabric', 'tape'
   * @return {bool} true if fabric can be set
   */
  canSetHandleSample(single, part) {
    return MattressConfigDA.canSetHandleSample(this.getData(), single, part);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string} part - (optional) part of handle, can be 'fabric', 'tape'
   * @return {object|null} true if fabric can be set
   */
  getHandleSample(single, part) {
    return MattressConfigDA.getHandleSample(this.getData(), single, part, null);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string} part - (optional) part of handle, can be 'fabric', 'tape'
   * @param {string} value - fabric id
   */
  setHandleSample(single, part, value) {
    if (!this.canSetHandleSample(single, part)) {
      return;
    }
    const old = this.getHandleSample(single, part);
    const data = this.getData();
    let force = false;

    if (part === null || typeof (part) === 'undefined') {
      const handleData = MattressConfigDA.getHandleData(data, single, false);

      if (handleData && handleData.style && handleData.style.materials) {
        // Previous material is probably a 'multi-material' with different slots
        // (tape / fabric)
        // Now, part equals null so we set a single-material.
        force = true;
      }
    }

    if (!force && old === value) {
      return;
    }

    MattressConfigDA.setHandleSample(data, single, part, value);
    const property = PropertyTypes.HANDLE_FABRIC;

    property.part = part;
    this._changedProperty(single, property, value, old);
    property.part = null;
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @return {object|null} handletype
   */
  getHandleType(single) {
    return MattressConfigDA.getHandleType(this.getData(), single);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {string} type - (optional) part of handle, can be 'fabric', 'tape'
   * @param {object} handleParams - extra parameters for type
   * @param {object|string} handleMaterials - optional initial material(s) for the handles
   *  Can be a string (=sample id for all handle components)
   *  Or an object containing the name of the handle component as key and the sample id as value
   *    Example:
   *    {
   *      fabric: '123',
   *      tape: '456'
   *    }
   *  Note: some handle types don't support custom samples (HVS003, HHS014)
   */
  setHandleType(single, type, handleParams = null, handleMaterials = null) {
    const s = MattressConfigDA.getSingle(this.getData(), single);

    if (!s) {
      return;
    }
    const oldHandleData = MattressDA.getHandleData(s, false);
    const old = this.getHandleType(single);

    // check if a border component is given as attachment base -> replace with border component index or ID
    if (handleParams && handleParams.base && typeof (handleParams.base) === 'object') {
      const base = handleParams.base;
      const objectType = this._getObjectType(base);

      if (objectType && objectType.name === 'borderComponent') {
        const baseID = MattressConfigDA.getObjectID(base, true);

        handleParams.base = baseID;
      }
    }

    // TODO: check handle params difference
    MattressConfigDA.setHandleType(this.getData(), single, type, handleParams, handleMaterials);

    const newHandleData = MattressDA.getHandleData(s, false);

    if (oldHandleData !== newHandleData) {
      // added a new object, reinit indices & types & stuff
      this._reinitData(this.getData());
    }

    this._changedProperty(single, PropertyTypes.HANDLE_TYPE, type, old);
  }

  removeAllHandles() {
    const result = MattressConfigDA.removeAllHandles(this.getData());
    if (result) {
      this._reinitData(this.getData());
      this._changedProperty(null, PropertyTypes.HANDLE_TYPE, null);
    }
    return result;
  }

  hasSingleWithHandles() {
    return MattressConfigDA.hasSingleWithHandles(this.getData());
  }


  singleHasCustomizableLogoHandle(single) {
    return MattressConfigDA.singleHasCustomizableLogoHandle(this.getData(), single);
  }

  getLogoHandleImageID(single) {
    return MattressConfigDA.getLogoHandleImageID(this.getData(), single);
  }

  setLogoHandleImageID(single, id) {
    const old = this.getLogoHandleImageID(single);

    if (old === id) {
      return;
    }
    MattressConfigDA.setLogoHandleImageID(this.getData(), single, id);
    this._changedProperty(single, PropertyTypes.HANDLE_LOGO_ID, id, old);
  }

  getLogoHandleImageURL(single) {
    return MattressConfigDA.getLogoHandleImageURL(this.getData(), single);
  }

  setLogoHandleImageURL(single, url) {
    const old = this.getLogoHandleImageID(single);

    if (old === url) {
      return;
    }
    MattressConfigDA.setLogoHandleImageURL(this.getData(), single, url);
    this._changedProperty(single, PropertyTypes.HANDLE_LOGO_URL, url, old);
  }

  // -- legs

  getLegTypes() {
    return [
      {
        id: 0,
        name: 'cone',
        label: 'Cone'
      },
      {
        id: 1,
        name: 'square',
        label: 'Square'
      },
      {
        id: 2,
        name: 'cylinder',
        label: 'Cylinder'
      }
    ];
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @param {object} value - specifics about the leg
   */
  setLegs(single, value) {
    const old = this.getLegs(single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setLegs(this.getData(), single, value);

    // Assign internal data to new legs
    const newLegs = this.getLegs(single);

    if (newLegs && old !== newLegs) {
      const map = this._dataInfoMap;
      const info = (map && old) ? map.get(old) : null;

      if (info) {
        info.object = newLegs;
        map.set(newLegs, info);
      }
      if (!old) {
        this._setDataInfo(newLegs, MattressConfigObjectTypes.LEGS, this.getSingle(single));
      }
    }

    this._changedProperty(single, PropertyTypes.LEG_TYPE, value, old);
  }

  getLegHeight(single) {
    return MattressConfigDA.getLegHeight(this.getData(), single);
  }

  setLegHeight(single, height) {
    const old = this.getLegHeight(single);

    if (old === height) {
      return;
    }
    MattressConfigDA.setLegHeight(this.getData(), single, height);

    this._changedProperty(single, PropertyTypes.LEG_HEIGHT, height, old);
  }

  /**
   * @param {string|number|object} single - single with id, index of single or the single object itself
   * @return {object} legs
   */
  getLegs(single) {
    return MattressConfigDA.getLegs(this.getData(), single);
  }

  // ////////////////////////////////////////////////////////////////
  //
  // Quilt methods
  //
  // ////////////////////////////////////////////////////////////////

  // Top quilt methods
  getTopQuiltID(single) {
    return MattressConfigDA.getTopQuiltID(this.getData(), single);
  }

  setTopQuiltID(single, value) {
    const old = this.getTopQuiltID(single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setTopQuiltID(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.TOP_QUILT_ID, value, old);
  }

  getTopQuiltRepeatX(single) {
    return MattressConfigDA.getTopQuiltRepeatX(this.getData(), single);
  }

  setTopQuiltRepeatX(single, value) {
    const old = this.getTopQuiltRepeatX(single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setTopQuiltRepeatX(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.TOP_QUILT_REPEAT_X, value, old);
  }
  getTopQuiltRepeatY(single) {
    return MattressConfigDA.getTopQuiltRepeatY(this.getData(), single);
  }

  setTopQuiltRepeatY(single, value) {
    const old = this.getTopQuiltRepeatY(single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setTopQuiltRepeatY(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.TOP_QUILT_REPEAT_Y, value, old);
  }

  getTopQuiltRepeatType(single) {
    return MattressConfigDA.getTopQuiltRepeatType(this.getData(), single);
  }

  setTopQuiltRepeatType(single, value) {
    const old = this.getTopQuiltRepeatType(single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setTopQuiltRepeatType(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.TOP_QUILT_REPEAT_TYPE, value, old);
  }

  getTopQuiltOffsetX(single) {
    return MattressConfigDA.getTopQuiltOffsetX(this.getData(), single);
  }

  setTopQuiltOffsetX(single, value) {
    const old = this.getTopQuiltOffsetX(single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setTopQuiltOffsetX(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.TOP_QUILT_OFFSET_X, value, old);
  }

  getTopQuiltOffsetY(single) {
    return MattressConfigDA.getTopQuiltOffsetY(this.getData(), single);
  }

  setTopQuiltOffsetY(single, value) {
    const old = this.getTopQuiltOffsetY(single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setTopQuiltOffsetY(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.TOP_QUILT_OFFSET_Y, value, old);
  }

  setTopQuiltOffset(single, x, y) {
    MattressConfigDA.setTopQuiltOffset(this.getData(), single, x, y);
    this._changedProperty(single, PropertyTypes.TOP_QUILT_OFFSET, null, null);
  }

  getTopQuiltRotation(single) {
    return MattressConfigDA.getTopQuiltRotation(this.getData(), single);
  }

  setTopQuiltRotation(single, value) {
    const old = this.getTopQuiltRotation(single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setTopQuiltRotation(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.TOP_QUILT_ROTATION, value, old);
  }

  getTopQuiltFoamValue(single) {
    return MattressConfigDA.getTopQuiltFoamValue(this.getData(), single);
  }

  setTopQuiltFoamValue(single, value) {
    const old = this.getTopQuiltFoamValue(single);

    if (old === value) {
      return;
    }

    MattressConfigDA.setTopQuiltFoamValue(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.TOP_QUILT_FOAM_VALUE, value, old);
  }

  // Bottom quilt methods
  getBottomQuiltID(single) {
    return MattressConfigDA.getBottomQuiltID(this.getData(), single);
  }

  setBottomQuiltID(single, value) {
    const old = this.getBottomQuiltID(single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setBottomQuiltID(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.BOTTOM_QUILT_ID, value, old);
  }

  getBottomQuiltRepeatType(single) {
    return MattressConfigDA.getBottomQuiltRepeatType(this.getData(), single);
  }

  setBottomQuiltRepeatType(single, value) {
    const old = this.getBottomQuiltRepeatType(single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setBottomQuiltRepeatType(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.BOTTOM_QUILT_REPEAT_TYPE, value, old);
  }

  getBottomQuiltRepeatX(single) {
    return MattressConfigDA.getBottomQuiltRepeatX(this.getData(), single);
  }

  setBottomQuiltRepeatX(single, value) {
    const old = this.getBottomQuiltRepeatX(single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setBottomQuiltRepeatX(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.BOTTOM_QUILT_REPEAT_X, value, old);
  }

  getBottomQuiltRepeatY(single) {
    return MattressConfigDA.getBottomQuiltRepeatY(this.getData(), single);
  }

  setBottomQuiltRepeatY(single, value) {
    const old = this.getBottomQuiltRepeatX(single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setBottomQuiltRepeatY(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.BOTTOM_QUILT_REPEAT_Y, value, old);
  }

  getBottomQuiltOffsetX(single) {
    return MattressConfigDA.getBottomQuiltOffsetX(this.getData(), single);
  }

  setBottomQuiltOffsetX(single, value) {
    const old = this.getBottomQuiltOffsetX(single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setBottomQuiltOffsetX(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.BOTTOM_QUILT_OFFSET_X, value, old);
  }

  getBottomQuiltOffsetY(single) {
    return MattressConfigDA.getBottomQuiltOffsetY(this.getData(), single);
  }

  setBottomQuiltOffsetY(single, value) {
    const old = this.getBottomQuiltOffsetY(single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setBottomQuiltOffsetY(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.BOTTOM_QUILT_OFFSET_Y, value, old);
  }

  setBottomQuiltOffset(single, x, y) {
    MattressConfigDA.setBottomQuiltOffset(this.getData(), single, x, y);
    this._changedProperty(single, PropertyTypes.BOTTOM_QUILT_OFFSET, null, null);
  }

  getBottomQuiltRotation(single) {
    return MattressConfigDA.getBottomQuiltRotation(this.getData(), single);
  }

  setBottomQuiltRotation(single, value) {
    const old = this.getBottomQuiltRotation(single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setBottomQuiltRotation(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.BOTTOM_QUILT_ROTATION, value, old);
  }

  getBottomQuiltFoamValue(single) {
    return MattressConfigDA.getBottomQuiltFoamValue(this.getData(), single);
  }

  setBottomQuiltFoamValue(single, value) {
    const old = this.getBottomQuiltFoamValue(single);

    if (old === value) {
      return;
    }

    MattressConfigDA.setBottomQuiltFoamValue(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.BOTTOM_QUILT_FOAM_VALUE, value, old);
  }

  // Border quilt methods
  getBorderQuiltID(single, border) {
    return MattressConfigDA.getBorderQuiltID(this.getData(), single, border);
  }

  setBorderQuiltID(single, border, value) {
    const old = this.getBorderQuiltID(single, border);

    if (old === value) {
      return;
    }

    MattressConfigDA.setBorderQuiltID(this.getData(), single, border, value);
    this._changedProperty(single, {border: border, name: 'quilt-id'}, value, old);
  }

  getBorderQuiltFoamValue(single, border) {
    return MattressConfigDA.getBorderQuiltFoamValue(this.getData(), single, border);
  }

  setBorderQuiltFoamValue(single, border, value) {
    const old = this.getBorderQuiltFoamValue(single, border);

    if (old === value) {
      return;
    }
    MattressConfigDA.setBorderQuiltFoamValue(this.getData(), single, border, value);
    this._changedProperty(single, {border: border, property: 'bordercomponent-quilt-foam-value'}, value, old);
  }

  static getNoiseValue(single, fallback = 0) {
    return MattressConfigDA.getNoiseValue(this.getData(), single, fallback);
  }

  static setNoiseValue(single, value) {
    const old = this.getNoiseValue(single);

    if (old === value) {
      return;
    }
    MattressConfigDA.setNoiseValue(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.NOISE_VALUE, value, old);
  }

  static getNoiseScale(single, fallback = DEFAULT_NOISE_SCALE) {
    return MattressConfigDA.getNoiseScale(this.getData(), single, fallback);
  }

  static setNoiseScale(single, value) {
    const old = this.getNoiseScale(single);

    if (old === value) {
      return;
    }

    MattressConfigDA.setNoiseScale(this.getData(), single, value);
    this._changedProperty(single, PropertyTypes.NOISE_SCALE, value, old);
  }

  // Get & set single property

  getSingleProperty(single, property, fb) {
    return MattressConfigDA.getSingleProperty(this.getData(), single, property, fb);
  }

  setSingleProperty(single, property, value) {
    MattressConfigDA.setSingleProperty(this.getData(), single, property, value);
  }

  // ////////////////////////////////////////////////////////////////
  //
  // Background stuff
  //
  // ////////////////////////////////////////////////////////////////
  clearBackground() {
    const bg = Utils.tryValues(this.getBackgroundId(), this.getBackgroundColor());

    MattressConfigDA.clearBackground(this.getData());

    this._changedProperty(null, PropertyTypes.BACKGROUND_CLEAR, null, bg);
  }

  getBackgroundId() {
    return MattressConfigDA.getBackgroundId(this.getData());
  }

  setBackgroundId(id) {
    const old = this.getBackgroundId();

    if (old === id) {
      return;
    }

    MattressConfigDA.setBackgroundId(this.getData(), id);

    this._changedProperty(null, PropertyTypes.BACKGROUND_ID, id, old);
  }

  getBackgroundColor() {
    return MattressConfigDA.getBackgroundColor(this.getData());
  }

  setBackgroundColor(color) {
    const old = this.getBackgroundColor();

    if (old === color) {
      return;
    }
    MattressConfigDA.setBackgroundColor(this.getData(), color);

    this._changedProperty(null, PropertyTypes.BACKGROUND_COLOR, color, old);
  }

  setProperty(property, value) {
    if (!property) {
      return;
    }

    MattressConfigPropertyUtil.setProperty(this, property, value);
  }

  getProperty(property, fallback = null) {
    if (!property) {
      return fallback;
    }

    return MattressConfigPropertyUtil.setProperty(this, property, fallback);
  }

  /**
   * @method getProperties
   * @description returns an array of editable properties of the current config
   * @param {Array} array - optional preallocated array where the property dataobjects should be added
   * @return {Array} array
   * */
  getProperties(array = null) {
    return this._getPropertiesOf(this.getData(), true, array);
  }

  /**
  * @method getPropertiesOf
  * @description returns an array of editable properties associated with a given object of a config
  * @param {Object} obj - An top/bottom panel, border component, ...
  * @param {Array} arr - optional preallocated array where the property dataobjects should be added
  * @return {Array} array
  * */
  getPropertiesOf(obj, arr = null) {
    return this._getPropertiesOf(obj, false, arr);
  }

  /**
   * @method getDimensionProperties
   * @description The editable dimension properties of this config object
   * @param {Array} array - Optiona preallocated array as result
   * @return {Array} result array;
   * */
  getDimensionProperties(array = null) {
    // Check for boxspring controllers
    const arr = array || [];
    const data = this.getData();

    if (!data) {
      return array;
    }
    const controllers = data.controllers;
    const numControllers = controllers ? controllers.length : 0;

    let ignoreSingles = null;

    for (let i = 0; i < numControllers; ++i) {
      const contr = controllers[i];

      const type = contr.type;

      if (type === 'controllerplugin') {
        const pluginType = contr.plugin;

        if (!ignoreSingles) {
          ignoreSingles = {};
        }

        if (pluginType === 'boxspring') {
          this._getDimensionPropertiesOfController(contr, arr);


          const params = contr.params;
          const headboard = params ? params.headboard : null;
          const mattress = params ? params.mattress : null;
          const foundationLeft = params ? params.foundationLeft : null;
          const foundationRight = params ? params.foundationRight : null;
          const foundation = params ? params.foundation : null;
          const topper = params ? params.topper : null;

          if (headboard) {
            ignoreSingles[headboard] = true;
          }
          if (mattress) {
            ignoreSingles[mattress] = true;
          }
          if (foundationLeft) {
            ignoreSingles[foundationLeft] = true;
          }
          if (foundationRight) {
            ignoreSingles[foundationRight] = true;
          }
          if (foundation) {
            ignoreSingles[foundation] = true;
          }
          if (topper) {
            ignoreSingles[topper] = true;
          }
        }
      }
    }
    // Singles
    const singles = data.singles;
    const numSingles = singles ? singles.length : 0;

    for (let i = 0; i < numSingles; ++i) {
      const single = singles[i];
      const singleID = single ? (single.id || single['@ID'] || single['@id']) : null;

      if (single && !(ignoreSingles && singleID && ignoreSingles[singleID] === true)) {
        this._getDimensionPropertiesOfSingle(single, arr);
      }
    }

    return arr;
  }
  /**
   * @method getDimensionPropertiesOf
   * @description The editable dimension properties of a single or boxspring controller, ...
   * @param {Object} obj - Single, boxspring controller, ...
   * @param {Array} array - optional preallocated array as result
   * @return {Array} array
   * */
  getDimensionPropertiesOf(obj = null, array = null) {
    if (!obj) {
      return array;
    }
    const type = this._getObjectType(obj);

    if (!type) {
      return array;
    }
    if (type === MattressConfigObjectTypes.CONTROLLER) {
      return this._getDimensionPropertiesOfController(obj, array);
    } else if (type === MattressConfigObjectTypes.SINGLE) {
      return this._getDimensionPropertiesOfController(obj, array);
    }

    return array;
  }

  _getDimensionPropertiesOfController(contr = null, array = null) {
    if (!contr) {
      return array;
    }
    const arr = array || [];
    const properties = MattressConfigPropertyCollector.getProperties(MattressConfigObjectTypes.CONTROLLER.name, contr, {onlyDimensionProperties: true}, false);

    arr.push({type: 'boxspringcontroller', id: contr.name, label: contr.label || contr.name, properties: properties});

    return arr;
  }

  _getDimensionPropertiesOfSingle(single = null, array = null) {
    if (!single) {
      return array;
    }
    const arr = array || [];
    const properties = MattressConfigPropertyCollector.getProperties(MattressConfigObjectTypes.SINGLE.name, single, {onlyDimensionProperties: true}, false);

    arr.push({type: 'single', id: single.name || single.id, label: single.placeholder, properties: properties});

    return arr;
  }

  _getPropertiesOf(obj, recursive, arr = null) {
    if (!obj) {
      return arr;
    }
    const type = this._getObjectType(obj);

    if (!type) {
      return arr;
    }

    const params = {
      mattressConfig: this
    };

    return MattressConfigPropertyCollector.getProperties(type, obj, params, recursive, arr);
  }

  hasScene() {
    const data = this._data;

    if (!data) {
      return false;
    }

    if (!data.scene) {
      return false;
    }

    return true;
  }

  _getSceneData(create = true) {
    let {sceneData} = this;

    if (!sceneData && create) {
      const data = this._data;

      if (!data) {
        return sceneData;
      }

      if (!data.scene) {
        return sceneData;
      }

      sceneData = new BD3DScene();

      let changeScenePropertyHandler = this._changeScenePropertyHandler;

      if (!changeScenePropertyHandler) {
        changeScenePropertyHandler = evt => {
          if (!evt) {
            return;
          }
          this._changedSceneProperty(evt.objectData, evt.property, evt.value, evt.previousValue, evt.params);
        };
      }

      sceneData.addEventListener('change_property', changeScenePropertyHandler);


      sceneData.setData(data);

      this.sceneData = sceneData;
    }

    return sceneData;
  }

  getScene() {
    return this._getSceneData(true);
  }

  getGlobalSceneProperties() {
    const scene = this._getSceneData(false);

    if (!scene || !scene.getGlobalProperties) {
      return null;
    }

    return scene.getGlobalProperties();
  }

  getSceneAssets(params, resultArray = null) {
    const sd = this._getSceneData(true);

    if (!sd) {
      return resultArray;
    }

    return sd.getAssets(params, resultArray);
  }

  getSceneContent(params = null) {
    const sd = this._getSceneData(true);

    if (!sd) {
      return null;
    }

    return sd.getContent(params);
  }

  updateSceneContent(params = null) {
    const sd = this._getSceneData(true);

    if (!sd) {
      return null;
    }

    return sd.updateContent(params);
  }

  getScenePropertiesByTarget(target, params = null, resultArray = null) {
    const scene = this.getScene();

    if (!scene) {
      return resultArray;
    }

    return scene.getPropertiesByTarget(target, params, resultArray);
  }

  getScenePropertyValue(property, params = null) {
    const scene = this.getScene();

    if (!scene) {
      return null;
    }

    return scene.getPropertyValue(property, params);
  }

  setScenePropertyValue(property, value, params = null, propertyParams = null) {
    const scene = this.getScene();

    if (!scene) {
      return null;
    }

    return scene.setPropertyValue(property, value, params, propertyParams);
  }

  /**
   * @return {bool} - return if events are enabled
   */
  getEventsEnabled() {
    return this._eventsEnabled !== false;
  }

  /**
   * @param {bool} e - true if events should be enabled
   */
  setEventsEnabled(e) {
    this._eventsEnabled = e;
  }

  get eventsEnabled() {
    return this.getEventsEnabled();
  }

  set eventsEnabled(v) {
    this.setEventsEnabled(v);
  }

  _getEventObject(type, create = true) {
    let evtObjects = this._eventObjects;

    if (!evtObjects && create) {
      evtObjects = this._eventObjects = {};
    }
    if (!evtObjects) {
      return null;
    }
    let res = evtObjects[type];

    if (!res && create) {
      res = evtObjects[type] = {};
    }

    res.type = type;

    return res;
  }

  _dispatchChangedData(data, oldData, params) {
    if (!this.getEventsEnabled()) {
      return;
    }
    const EVENT = 'changed_data';

    if (!this.hasEventListener(EVENT)) {
      return;
    }
    const event = this._getEventObject(EVENT, true);

    event.target = this;
    event.data = data;
    event.oldData = oldData;
    event.params = params;

    this.dispatchEvent(event);
  }

  _dispatchChangedProperty(single, object, property, value, oldValue, objectType, params) {
    if (!this.getEventsEnabled()) {
      return;
    }
    const EVENT = 'changed_property';

    if (!this.hasEventListener(EVENT)) {
      return;
    }
    const evt = this._getEventObject(EVENT, true);

    evt.target = this;
    evt.single = single;
    evt.object = object;
    evt.property = property;
    evt.value = value;
    evt.oldValue = oldValue;
    evt.data = this.getData();
    evt.objectType = objectType;
    evt.params = params;

    this.dispatchEvent(evt);
  }

  _changedProperty(single, property, value, oldValue, params) {
    this._setDirty(true);

    const controllerMgr = this._controllerPluginManager;

    if (controllerMgr) {
      controllerMgr.notifyPropertyChanged(single, property, value, oldValue, params, this);
    }

    this._dispatchChangedProperty(single, null, property, value, oldValue, 'single', params);
  }

  _changedSceneProperty(object, property, value, oldValue, params) {
    this._setDirty(true);
    this._dispatchChangedProperty(null, object, property, value, oldValue, 'scene-object', params);
  }
}

MattressConfig.PropertyTypes = PropertyTypes;
