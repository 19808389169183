import Vector from '../../bgr/bgr3d/geom/Vector';
import Matrix4 from '../../bgr/bgr3d/geom/Matrix4';
import Matrix4Math from '../../bgr/bgr3d/math/Matrix4Math';
import VecMat4Math from '../../bgr/bgr3d/math/VecMat4Math';
// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
// #endif

const MAX_RECURSION = 2;
const FLOAT_TOLLERANCE = 0.0001;
const BORDER_RADIUS_SOLVE_METHOD = {
  CLAMP: 'clamp',
  SCALE: 'scale'// default
};

const BORDER_LOCATION = {
  TOP: 'top',
  TOP_BORDER: 'top_border',
  SIDE: 'side',
  BOTTOM_BORDER: 'bottom_border',
  BOTTOM: 'bottom'
};

export default class BorderShape {
  calculateBorderHeight(height, topBorderRadius, bottomBorderRadius, topH, bottomH) {
    return BorderShape.calculateBorderHeight(height, topBorderRadius, bottomBorderRadius, topH, bottomH);
  }
   /**
   * @method calculateBorderHeight
   * @static
   * @description Calculates the fallback border height based on the height of the mattress
   * and the top and bottom border radius and height values. Used if no border is specified.
   * @param {Number} height - the height of the mattress
   * @param {Number} topBorderRadius - the radius of the top edges
   * @param {Number} bottomBorderRadius - the radius of the bottom edges
   * @param {Number} topH - the height of the top
   * @param {Number} bottomH - the height of the bottom
   * @returns {Number} - The height of the fallback border component
   * */
  static calculateBorderHeight(height, topBorderRadius, bottomBorderRadius, topH, bottomH) {
    let sine;
    let angle;
    let topArcLen = 0;
    let bottomArcLen = 0;
    let topHeight = topH;
    let bottomHeight = bottomH;

    let topRestH = topBorderRadius - topHeight;
    let bottomRestH = bottomBorderRadius - bottomHeight;
    const topBottomHeight = topHeight + bottomHeight;

    if (topBottomHeight > height) {
      // if top and bottom together exceed the mattress height, scale down proportionally
      const pctTop = topHeight / topBottomHeight;
      const pctBottom = bottomHeight / topBottomHeight;

      topHeight = pctTop * height;
      bottomHeight = pctBottom * height;
    }
    if (topRestH > 0) {
      sine = topRestH / topBorderRadius;
      angle = Math.asin(sine);
      topArcLen = angle * topBorderRadius;
      topRestH = topArcLen;
    }
    if (bottomRestH > 0) {
      sine = bottomRestH / bottomBorderRadius;
      angle = Math.asin(sine);
      bottomArcLen = angle * bottomBorderRadius;
      bottomRestH = bottomArcLen;
    }
    const midHeight = height - bottomBorderRadius - topBorderRadius;
    const borderLength = midHeight + topRestH + bottomRestH;

    return borderLength;
  }

  setData(tpBorderRadius, btmBorderRadius, borderLength, topH, bottomH, recursionInd) {
    let recursionIndex = recursionInd;
    let topHeight = topH;
    let bottomHeight = bottomH;
    let topBorderRadius = tpBorderRadius;
    let bottomBorderRadius = btmBorderRadius;

    if (topHeight < 0) {
      topHeight = 0;
    }
    if (bottomHeight < 0) {
      bottomHeight = 0;
    }
    if (recursionIndex === undefined || recursionIndex === null) {
      recursionIndex = 0;
    }
    if (recursionIndex > MAX_RECURSION) {
      return;
    }

    let sine;
    let angle;
    let topBorderLength = 0;
    let bottomBorderLength = 0;
    let topAngle = 0;
    let bottomAngle = 0;
    let topBorderHeight = 0;
    let bottomBorderHeight = 0;

    if (topBorderRadius > topHeight) {
      topBorderHeight = (topBorderRadius - topHeight);
      sine = topBorderHeight / topBorderRadius;
      angle = Math.asin(sine);
      topAngle = angle;
      topBorderLength = topAngle * topBorderRadius;
    }

    if (bottomBorderRadius > bottomHeight) {
      bottomBorderHeight = (bottomBorderRadius - bottomHeight);
      sine = bottomBorderHeight / bottomBorderRadius;
      angle = Math.asin(sine);
      bottomAngle = angle;
      bottomBorderLength = bottomAngle * bottomBorderRadius;
    }

    const topAndBottomBorderLength = topBorderLength + bottomBorderLength;
    let middleH = borderLength - topAndBottomBorderLength;
    const adjustmentNeeded = middleH < 0;
    const borderRadiusSolveMethod = this.borderRadiusSolveMethod;

    if (adjustmentNeeded) {
      if (middleH < -FLOAT_TOLLERANCE) {
        this._borderMiddleLength = middleH; // TODO: remove test

        if (borderLength > 0 && (borderRadiusSolveMethod === BORDER_RADIUS_SOLVE_METHOD.SCALE || borderRadiusSolveMethod === undefined || borderRadiusSolveMethod === null)) {
          const scaleFactor = borderLength / topAndBottomBorderLength;

          topBorderRadius *= scaleFactor;
          bottomBorderRadius *= scaleFactor;
        } else {
          let pctTop = topHeight === 0 ? 0 : topBorderRadius / topHeight;
          let pctBottom = bottomHeight === 0 ? 0 : bottomBorderRadius / bottomHeight;

          pctTop = pctTop > 1 ? 1 : pctTop;
          pctBottom = pctBottom > 1 ? 1 : pctBottom;
          topBorderRadius = topHeight * pctTop;
          bottomBorderRadius = bottomHeight * pctBottom;
        }

        this.setData(topBorderRadius, bottomBorderRadius, borderLength, topHeight, bottomHeight, recursionIndex + 1);

        return;
      }
      middleH = 0;
    }
    // #if DEBUG
    if (recursionIndex > 1) {
      BD3DLogger.warn('solved in ', recursionIndex, 'steps', 'middle length=', this._borderMiddleLength);
    }
    // #endif
    this._borderRadiusAdjustment = recursionIndex > 0;
    this._solveSteps = recursionIndex;

    this._topHeight = topHeight;
    this._bottomHeight = bottomHeight;
    this._borderTopRadius = topBorderRadius;
    this._borderBottomRadius = bottomBorderRadius;

    this._borderTopAngle = topAngle;
    this._borderBottomAngle = bottomAngle;
    this._borderTopLength = topBorderLength;
    this._borderBottomLength = bottomBorderLength;
    this._borderTopHeight = topBorderHeight;
    this._borderBottomHeight = bottomBorderHeight;
    this._borderMiddleLength = middleH;
    this._borderLength = borderLength;
  }

  borderRadiusAdjustmentNeeded() {
    return this._borderRadiusAdjustment === true;
  }

  getBorderHeight() {
    return this._borderTopHeight + this._borderBottomHeight + this._borderMiddleLength;
  }

  getBorderLength() {
    return this._borderLength;
  }

  getMattressHeight() {
    return this.getBorderHeight() + this._topHeight + this._bottomHeight;
  }

  getTopBorderRadius() {
    return this._borderTopRadius;
  }

  getBottomBorderRadius() {
    return this._borderBottomRadius;
  }

  getBorderTopHeight() {
    return this._borderTopHeight;
  }

  getBorderBottomHeight() {
    return this._borderBottomHeight;
  }

  getBorderTopAngle() {
    return this._borderTopAngle;
  }

  getBorderBottomAngle() {
    return this._borderBottomAngle;
  }

  getBorderTopLength() {
    return this._borderTopLength;
  }

  getBorderBottomLength() {
    return this._borderBottomLength;
  }

  getBorderMiddleLength() {
    return this._borderMiddleLength;
  }

  static getTransformAtY(ypos, topBorderRadius, bottomBorderRadius, height, swapXZ = false, result = null) {
    const PI05 = Math.PI * 0.5;
    let tbr = topBorderRadius;
    let bbr = bottomBorderRadius;
    const sum = tbr + bbr;

    if (sum > height) {
      tbr = (tbr / sum) * height;
      bbr = (bbr / sum) * height;
    }
    const topBorderLen = tbr * PI05;
    const bottomBorderLength = bbr * PI05;

    const hh = height * 0.5;
    const topMinusBorder = hh - tbr;
    const bottomMinusBorder = -hh + bbr;
    const topWithBorderLength = topMinusBorder + topBorderLen;
    const bottomWithBorderLength = bottomMinusBorder - bottomBorderLength;

    let valid = false;
    let X = 0;
    let Y = 0;
    let Z = 0;
    let nX = 0;
    let nY = 0;
    let nZ = 0;
    let locationID = null;
    let dpos = ypos;

    if (ypos <= topMinusBorder && ypos >= bottomMinusBorder) {
      valid = true;
      Y = ypos;
      nX = -1;
      locationID = BORDER_LOCATION.SIDE;
    } else if (ypos >= topMinusBorder && ypos <= topWithBorderLength) {
      dpos = ypos - topMinusBorder;
      const ang = dpos / tbr;
      const sine = Math.sin(ang);
      const cosine = Math.cos(ang);

      X = tbr - cosine * tbr;
      Y = hh - tbr + sine * tbr;
      nX = -cosine;
      nY = sine;
      valid = true;
      locationID = BORDER_LOCATION.TOP_BORDER;
    } else if (ypos > topWithBorderLength) {
      dpos = ypos - topWithBorderLength;

      Y = hh;
      X = tbr + dpos;
      nY = 1;
      valid = true;
      locationID = BORDER_LOCATION.TOP;
    } else if (ypos <= bottomMinusBorder && ypos >= bottomWithBorderLength) {
      valid = true;
      dpos = bottomBorderLength - (ypos - bottomWithBorderLength);
      const ang = (dpos / bbr) + Math.PI;
      const cosine = Math.cos(ang);
      const sine = Math.sin(ang);

      nX = cosine;
      nY = sine;

      X = bbr + cosine * bbr;
      Y = -hh + bbr + sine * bbr;
      valid = true;
      locationID = BORDER_LOCATION.BOTTOM_BORDER;
    } else if (ypos <= bottomWithBorderLength) {
      valid = true;
      dpos = bottomWithBorderLength - ypos;

      X = bbr + dpos;
      Y = -hh;
      nY = -1;
      locationID = BORDER_LOCATION.BOTTOM;
    }
    let res = result;

    if (valid) {
      if (!res) {
        res = {};
      }
      if (swapXZ) {
        const swX = X;
        const swZ = Z;
        const swNX = nX;
        const swNZ = nZ;

        X = -swZ;
        Z = -swX;
        nX = -swNZ;
        nZ = -swNX;
      }
      res.x = X;
      res.y = Y;
      res.z = Z;
      res.normalX = nX;
      res.normalY = nY;
      res.normalZ = nZ;
      res.locationID = locationID;
      res.locationY = dpos;

      let m = res.matrix4;

      if (!m) {
        m = res.matrix4 = new Matrix4();
      }
      if (swapXZ) {
        m.setValues(
          1, 0, 0, 0,
          0, nZ, nY, 0,
          0, nY, nZ, 0,
          X, Y, Z, 1
        );
      } else {
        m.setValues(
          -nX, -nY, 0, 0,
          nY, -nX, 0, 0,
          0, 0, 1, 0,
          X, Y, Z, 1
        );
      }
    }
    if (res) {
      res.valid = valid;
    }

    return res;
  }

  transformVectorAtLength(len, vector, deltaTransform, perVert, res) {
    if (!vector) {
      return res;
    }

    if (typeof (vector) === 'number') {
      throw new Error('Use transformVectorCoordsAtLength instead');
    }
    const x = vector.getCoord(0);
    const y = vector.getCoord(1);
    const z = vector.getCoord(2);

    return this.transformVectorCoordsAtLength(len, x, y, z, deltaTransform, perVert, res);
  }

  transformVectorCoordsAtLength(len, x, y, z, deltaTransform, perVert, res) {
    let xpos = x;
    let ypos = y;
    let zpos = z;

    let perVertex = perVert;
    let length = len;
    let tempTransform = null;

    perVertex = perVertex !== false;

    if (perVertex) {
      length -= ypos;

      /*
      const min = 0;
      const max = this.getBorderLength();

      if (min !== undefined && min !== null && length < min) {
        length += ypos;
        perVertex = false;
      } else if (max !== undefined && max !== null && length > max) {
        length += ypos;
        perVertex = false;
      } else {
        ypos = 0;
      }
      */
      ypos = 0;
    }

    tempTransform = this.getTransformAtLength(length, tempTransform);
    const m4 = tempTransform ? tempTransform.matrix4 : null;

    if (m4) {
      let w = deltaTransform;

      if (typeof (deltaTransform) === 'boolean') {
        w = deltaTransform ? 0 : 1;
      }

      const vec4 = VecMat4Math.transformVectorCoords(xpos, ypos, zpos, w, m4, res);

      if (vec4) {
        xpos = vec4.x;
        ypos = vec4.y;
        zpos = vec4.z;
      }
    } else if (res instanceof Vector) {
      res.setCoord(0, xpos);
      res.setCoord(1, ypos);
      res.setCoord(2, zpos);
    }

    return res;
  }

  /**
   * @method getTransformAtLength
   * @description Calculates the absolute border transformation at a given y-coordinate in the border shape
   *  NOTE: The input length increases from top to bottom, going from 0 (upper part) to border length (lower part),
   *  but the output transform matrix uses inverted y-coordinates, like the opengl/webgl coordinate system,
   *    so the y-value decreases from top (high y-value) to bottom (low y-value)
   *
   * @param {Number} length - The location between 0 and the current border length where to get the transform information from
   * @param {Object} transfrm - optional preallocated transform object.
   *  The transform object has the following properties
   *  * matrix4 {Float32Array} - 4x4 transform matrix.
   *      Indices 0, 1, 4 and 5 contain the information about the rotation (like a 2x2 matrix)
   *      Indices 12 and 13 are the x and y coordinates
   *      Transform a vector with this matrix:
   *        x' = x * matrix[0] + y * matrix[4] + z * matrix[8] + matrix[12]
   *        y' = x * matrix[1] + y * matrix[5] + z * matrix[9] + matrix[13]
   *        z' = x * matrix[2] + y * matrix[6] + z * matrix[10] + matrix[14]
   *      Or, when using only 2D vectors (z equals 0)
   *        x' = x * matrix[0] + y * matrix[4] + matrix[12]
   *        y' = x * matrix[1] + y * matrix[5] + matrix[13]
   *  * valid {Boolean} - True if the given length is a value between 0 and the total border length
   *  * locationName {String} - one of the following:
   *    - top_outside
   *    - top: location inside the border boundaries at the top where the border radius has influence
   *    - middle: middle location inside the border boundaries where the shape makes a straight line downwards
   *    - bottom: location inside the border boundaries at the bottom where the border radius has influence
   *    - bottom_outside: position outside the border boundaries where the given length > border length
   *  * curvePosition {Number} - the current position (= length given as input parameter)
   * @returns {Object} - transform object, same as the transform parameter if specified
   * */
  getTransformAtLength(length, transfrm) {
    const bh = this._borderLength;
    const tbLen = this._borderTopLength;
    const midLen = this._borderMiddleLength;
    const mh = this.getMattressHeight();
    const topBR = this._borderTopRadius;
    const bottomBR = this._borderBottomRadius;
    const topH = this._topHeight;
    const bottomH = this._bottomHeight;
    const borderTopAngle = this._borderTopAngle;
    const borderBottomAngle = this._borderBottomAngle;
    let transform = transfrm;

    const _0 = 0;
    const _1 = 1;
    const _4 = 4;
    const _5 = 5;

    // init transform object
    if (!transform) {
      transform = {};
    }
    transform.valid = false;
    transform.locationName = null;
    transform.curvePosition = length;

    let matrix4 = transform.matrix4;

    if (!matrix4) {
      matrix4 = new Matrix4();
      transform.matrix4 = matrix4;
    }
    // fill matrix with identity values
    matrix4 = Matrix4Math.identity(matrix4);

    const pos1 = 0; // The upper part of the border, position where the top curve starts (if there is any)
    const pos2 = pos1 + tbLen; // position where the top curve ends and the straight middle line starts
    const pos3 = pos2 + midLen; // position where the middle straight line ends and the bottom curve starts
    // var pos4 = pos3 + bbLen; // position where the bottom curve ends / bottom part of the border
    const pos4 = bh; // position where the bottom curve ends / bottom part of the border

    let ypos = 0;
    let xpos = 0;
    let ang;
    let cosine;
    let sine;

    // test
    matrix4.x = xpos;
    matrix4.y = length;

    let cornerpos;

    if (length < 0) {
      // 'negative' region outside the border components part
      transform.locationName = 'top_outside';
      let topRestH = topH - topBR;

      if (length > -topRestH) {
        // straight line before the border radius starts
        ypos = mh * 0.5 - topH - length;
        matrix4.y = ypos;

        return transform;
      }
      // border radius part + horizontal line going right
      ypos = mh * 0.5 - topBR;
      xpos = topBR;
      topRestH = topRestH < 0 ? 0 : topRestH;
      const borderTopRestAngle = Math.PI * 0.5 - borderTopAngle;

      cornerpos = ((-length - topRestH) / (topBR * borderTopRestAngle));

      if (cornerpos < 1) {
        // border radius
        ang = Math.PI - borderTopAngle - cornerpos * borderTopRestAngle;
        cosine = Math.cos(ang);
        sine = Math.sin(ang);

        matrix4.set(_0, -cosine);
        matrix4.set(_1, -sine);
        matrix4.set(_4, sine);
        matrix4.set(_5, -cosine);

        matrix4.x = xpos + cosine * topBR;
        matrix4.y = ypos + sine * topBR;
      } else {
        matrix4 = Matrix4Math.zRotation(-Math.PI * 0.5, matrix4);

        matrix4.x = topBR + (-length - (topRestH + Math.PI * 0.5 * topBR - borderTopAngle * topBR));
        matrix4.y = mh * 0.5;
      }

      return transform;

    }
    if (length > bh) {
      transform.locationName = 'bottom_outside';
      let rlen = length - bh;
      let bottomRestH = bottomH - bottomBR;

      if (rlen < bottomRestH) {
        // straight line down before bottom border
        matrix4.x = 0;
        matrix4.y = -mh * 0.5 + bottomH - rlen;
      } else {
        const bottomBRLen = bottomBR * Math.PI * 0.5;
        const borderBottomLength = borderBottomAngle * bottomBR;

        bottomRestH = bottomRestH < 0 ? 0 : bottomRestH;
        rlen += borderBottomLength;
        rlen -= bottomRestH;

        xpos = bottomBR;
        ypos = -mh * 0.5 + bottomBR;
        cornerpos = rlen / bottomBRLen;
        if (cornerpos < 1) {
          ang = Math.PI + cornerpos * Math.PI * 0.5;

          cosine = Math.cos(ang);
          sine = Math.sin(ang);

          matrix4.set(_0, -cosine);
          matrix4.set(_1, -sine);
          matrix4.set(_4, sine);
          matrix4.set(_5, -cosine);

          matrix4.x = xpos + cosine * bottomBR;
          matrix4.y = ypos + sine * bottomBR;
        } else {
          rlen -= bottomBRLen;

          matrix4 = Matrix4Math.zRotation(Math.PI * 0.5, matrix4);

          matrix4.x = bottomBR + rlen;
          matrix4.y = -mh * 0.5;
        }
      }

      return transform;
    }

    if (midLen > 0 && length >= pos2 && length <= pos3) {
      // middle region
      transform.valid = true;
      transform.locationName = 'middle';
      ypos = topBR > topH ? topBR : topH;
      ypos = mh * 0.5 - ypos;
      ypos -= (length - pos2);
      matrix4.y = ypos;

      return transform;
    }

    if (length >= pos1 && length <= pos2) {
      // top region
      transform.valid = true;
      transform.locationName = 'top';
      xpos = topBR;
      ypos = mh * 0.5 - topBR;
      ang = Math.PI - borderTopAngle + ((length - pos1) / topBR);

      cosine = Math.cos(ang);
      sine = Math.sin(ang);
      xpos += cosine * topBR;
      ypos += sine * topBR;

      matrix4.set(_0, -cosine);
      matrix4.set(_1, -sine);
      matrix4.set(_4, sine);
      matrix4.set(_5, -cosine);

      matrix4.x = xpos;
      matrix4.y = ypos;

      return transform;
    }

    if (length >= pos3 && length <= pos4) {
      // bottom region
      transform.valid = true;
      transform.locationName = 'bottom';

      xpos = bottomBR;
      ypos = -mh * 0.5 + bottomBR;
      ang = Math.PI + ((length - pos3) / bottomBR);
      cosine = Math.cos(ang);
      sine = Math.sin(ang);
      xpos += cosine * bottomBR;
      ypos += sine * bottomBR;

      matrix4.set(_0, -cosine);
      matrix4.set(_1, -sine);
      matrix4.set(_4, sine);
      matrix4.set(_5, -cosine);

      matrix4.x = xpos;
      matrix4.y = ypos;

      return transform;
    }

    return transform;
  }
}
