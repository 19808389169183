import Matrix4 from '../geom/Matrix4';
import Matrix4Math from '../math/Matrix4Math';

export default class Transform3D {
  constructor(matrix = null) {
    let m = matrix;

    if (!m) {
      m = new Matrix4();
    }

    this._matrix = m;
  }

  applyMatrix4(m4) {
    return m4;
  }

  getMatrix4(update = true) {
    if (update) {
      this._matrix = Matrix4Math.identity(this._matrix);
      this.applyMatrix4(this._matrix);
    }

    return this._matrix;
  }

  get matrix4() {
    return this.getMatrix4(false);
  }
}
