import HorizontalHandleType from './HorizontalHandleType';
import PocketHandleType from './PocketHandleType';
import SuitcaseHandleType from './SuitcaseHandleType';
import VerticalHandleType from './VerticalHandleType';

const HandleTypes = {
  HORIZONTAL: new HorizontalHandleType(),
  POCKET: new PocketHandleType(),
  SUITCASE: new SuitcaseHandleType(),
  VERTICAL: new VerticalHandleType()
};

export default HandleTypes;
