import SampleTransform from '../../material/SampleTransform';
import BD3DSampleFabricMaterial from '../../material/BD3DSampleFabricMaterial';
import MattressDA from '../../mattress/MattressDA';
import SampleCollector from '../../sample/SampleCollector';
import SampleDA from '../../sample/SampleDA';
import QuiltCollector from '../../quilt/QuiltCollector';
import StringUtils from '../../utils/StringUtils';

import {
  Property,
  notifyPropertyChanged
} from '../utils';

const PROPERTY_CHANGE_PARAMS = ' _property_change_params_ ';

function updateSampleFabricMaterial(object, data, assetCollections) {
  const sampleAssets = assetCollections ? assetCollections.samples : null;

  object.setColorType(data.colorType);
  object.setColorMultiplier(data.color);

  const textureData = data.texture;
  const sampleID = textureData ? textureData.id : null;
  let sampleData = null;
  let sample = null;

  if (sampleID !== null && typeof (sampleID) !== 'undefined') {
    sample = sampleAssets.getAssetByName(sampleID);
    sampleData = sample ? sample.getSampleData() : null;
  }
  let realWidth = 1;
  let realHeight = 1;

  if (sample) {
    object.setSampleTexture(null);
    object.setSampleNormalMap(null);
  }
  object.setSampleAsset(sample);

  if (!sample) {
    const commonTextures = assetCollections && assetCollections.commonTextures;
    const defFabricColor = commonTextures && commonTextures.getAssetByName('defaultfabric.color');
    const defFabricNormal = commonTextures && commonTextures.getAssetByName('defaultfabric.normal');
    const realSize = defFabricColor && defFabricColor.metaData && defFabricColor.metaData.realSize;

    if (realSize) {
      realWidth = realSize.width;
      realHeight = realSize.height;
    }

    object.setSampleTexture(defFabricColor);
    object.setSampleNormalMap(defFabricNormal);
  }

  let sampleTransform = object.getSampleTransform();

  if (!sampleTransform) {
    sampleTransform = new SampleTransform();
    object.setSampleTransform(sampleTransform);
  }

  if (sample) {
    const sampleImgWidth = sample.getImageWidth();
    const sampleImgHeight = sample.getImageHeight();

    if (sampleImgWidth > 0 && sampleImgHeight > 0) {
      sampleTransform.setSourceAspectRatio(sampleImgWidth, sampleImgHeight);
    }
  }
  sampleTransform.setSampleData(sampleData);
  sampleTransform.setSampleConfigData(textureData);
  sampleTransform.setRealSize(realWidth, realHeight);

  object.setSampleData(sampleData);
  object.setSampleConfigData(textureData);
}

export default {
  create(data, id, session) {
    const result = new BD3DSampleFabricMaterial();

    result.metaData = result.metaData || {};

    const md = result.metaData;

    md.source = data;

    return result;
  },
  update(object, data, id, session) {
    const params = session ? session.getParams() : null;
    const buildParams = params ? params.buildParams : null;
    const assetCollections = buildParams ? buildParams.assetCollections : null;
    updateSampleFabricMaterial(object, data, assetCollections);

    return;
  },
  callMethod(object, data, method, params, options) {
    const dataData = data['@data']; // I need to find better names
    const paramsParams = params ? params.params : null;
    let result = null;

    if (method === 'getSamples') {
      result = params ? params.result : null;
      const texture = dataData ? dataData.texture : null;

      // TODO: specify part (top / pillow / whatever)
      const md = object.metaData;
      const elementType = (md && md.elementType) || '-';

      result = SampleCollector._addTexture(texture, {part: elementType}, paramsParams, result);

      if (params) {
        params.result = result;
      }
    } else if (method === 'getQuilts') {
      result = params ? params.result : null;
      const texture = dataData ? dataData.quilt : null;

      // TODO: specify part (top / pillow / whatever)
      result = QuiltCollector._addQuilt(texture, {part: '-'}, paramsParams, result);

      if (params) {
        params.result = result;
      }
    }

    return result;
  },
  getProperty(object, data, property, params) {
    const isPropertyInstance = property instanceof Property;
    const key = (isPropertyInstance) ? property.getKey() : Property.getKey(property);

    if (key) {
      if (StringUtils.startsWith(key, 'sample')) {
        const dataData = data ? data['@data'] : null;

        const transf = object && object.getSampleTransform ? object.getSampleTransform() : null;

        const tex = dataData ? dataData.texture : null;

        if (key === 'sample') {
          return tex ? tex.id : null;
        } else if (key === 'sample-x-offset') {
          return transf ? transf.getOffsetX() : 0;
        } else if (key === 'sample-y-offset') {
          return transf ? transf.getOffsetY() : 0;
        } else if (key === 'sample-x-align' || key === 'sample-y-align') {
          return key === 'sample-x-align' ? SampleDA.getTextureAlignX(tex, 0.5) : SampleDA.getTextureAlignY(tex, 0.5);
        } else if (key === 'sample-rotation' || key === 'sample-rotate') {
          return transf ? transf.getRotation() : 0;
        } else if (key === 'sample-color') {
          return dataData ? dataData.color : null;
        }
      }
    }

    return 0;
  },
  setProperty(object, data, property, value, params) {
    const key = (property instanceof Property) ? property.getKey() : Property.getKey(property);
    const d = data['@data'];
    let prevValue = value;

    if (!d.texture) {
      d.texture = {};
    }
    const tex = d.texture;

    if (!tex.offset) {
      tex.offset = {};
    }
    const off = tex.offset;

    off.x = off.x || 0;
    off.y = off.y || 0;
    tex.rotation = tex.rotation || 0;

    const sceneParams = params && params.params;
    const outputParams = sceneParams && sceneParams.outputParams;
    let propertyChangeParams = outputParams || this[PROPERTY_CHANGE_PARAMS];

    if (propertyChangeParams && !outputParams) {
      propertyChangeParams.refresh = false;
      propertyChangeParams.reload = false;
      propertyChangeParams.overrideReload = false;
      propertyChangeParams.overrideRefresh = false;
    }

    if (key === 'sample') {
      // tex.id = value;
      MattressDA.setComponentSample(d, value);
    } else if (key === 'sample-x-offset') {
      prevValue = off.x;
      off.x = value;
    } else if (key === 'sample-y-offset') {
      prevValue = off.y;
      off.y = value;
    } else if (key === 'sample-x-align') {
      SampleDA.setAlign(tex, value, null);
    } else if (key === 'sample-y-align') {
      SampleDA.setAlign(tex, null, value);
    } else if (key === 'sample-rotate') {
      let val = value;

      if (typeof (val) === 'object' && val !== null) {
        val = val.key;
      }
      if (typeof (val) === 'string') {
        const INC = 90;
        const DEC = -INC;

        val = val === 'decrease' || val === 'cc' ? DEC : INC;
      }
      if (typeof (val) === 'number' && !isNaN(val)) {
        tex.rotation = tex.rotation || 0;
        const MAX_ROTATION = 360;

        tex.rotation = (((tex.rotation + val) % MAX_ROTATION) + MAX_ROTATION) % MAX_ROTATION;
      }
    } else if (key === 'sample-rotation') {
      prevValue = tex.rotation;
      tex.rotation = value;
    } else if (key === 'sample-color') {
      prevValue = d ? d.color : null;
      let prevSample = MattressDA.getComponentSampleID(d, value);

      MattressDA.setComponentColor(d, value);

      const curSample = MattressDA.getComponentSampleID(d, value);
      if (typeof curSample === 'undefined') {
        curSample = null;
      }
      if (typeof prevSample === 'undefined') {
        prevSample = null;
      }

      if (prevSample !== curSample) {
        if (!propertyChangeParams) {
          propertyChangeParams = {};
          this[PROPERTY_CHANGE_PARAMS] = {};
        }
        propertyChangeParams.overrideRefresh = true;
        propertyChangeParams.refresh = true;
      }
      propertyChangeParams.overrideRefreshRender = true;
      propertyChangeParams.refreshRender = true;
      const dataData = data && data['@data'];

      const propertyParams = sceneParams && sceneParams.propertyParams;
      const assetCollections = propertyParams && propertyParams.assetCollections;

      updateSampleFabricMaterial(object, dataData, assetCollections);
      /*
      if (d) {
        d.color = value;
      }
      */
    }

    notifyPropertyChanged(object, data, property, value, prevValue, params, propertyChangeParams);
  }
};
