/**
 * @class Event
 */
export default class Event {
  /**
   * Event constructor
   * @param  {String} type - Event type
   * @param  {Object} data = null - optional argument for extra data
   */
  constructor(type, data = null) {
    this.type = type;
    this.data = data;
  }
}
