import Geometry from '../../bgr/bgr3d/geom/Geometry';

export default class BD3DGeometry extends Geometry {
  constructor() {
    super();
    // transforms normalized uv coordinates to absolute coordinates
    this.uvWorldTransform = null;
  }

  getUVWorldTransform() {
    const res = this.uvWorldTransform;

    if (res) {
      return res;
    }

    const ud = this.userData;

    if (!ud) {
      return res;
    }

    return ud.uvData;
  }

  setUVScale(u, v) {
    let tr = this.uvWorldTransform;

    if (!tr) {
      tr = this.uvWorldTransform = {};
    }
    tr.scaleU = u;
    tr.scaleV = v;
  }

  setUVBounds(minU, minV, maxU, maxV) {
    let tr = this.uvWorldTransform;

    if (!tr) {
      tr = this.uvWorldTransform = {};
    }
    tr.minU = minU;
    tr.minV = minV;
    tr.maxU = maxU;
    tr.maxV = maxV;
  }

}
