import Vector2 from '../../bgr/bgr3d/geom/Vector2';
import Vector3 from '../../bgr/bgr3d/geom/Vector3';
import Vector from '../../bgr/bgr3d/geom/Vector';
import Vertex from '../../bgr/bgr3d/geom/Vertex';
import BD3DNodeTypes from '../scenegraph/BD3DNodeTypes';

import GeomUtils from '../../bgr/bgr3d/utils/GeomUtils';
import BoundingBoxUtils from '../../bgr/bgr3d/utils/BoundingBoxUtils';


import Utils from '../utils/Utils';

export default class BorderComponentType {
  // Auto correct uv's if a curved border is applied
  hasTextureUVCorrection() {
    return true;
  }

  // Adjust uv coordinates after generating the mesh
  adjustUVs(uvs, geom, data, mattressData) {
    return;
  }

  getMaterial(data, mattressData, buildParams = null, result = null) {
    return null;
  }

  getNodeType() {
    return BD3DNodeTypes.borderComponent;
  }

  addAssets(data, params, array = null) {
    return array;
  }

  _parseNum(number, fallback) {
    return Utils.parseNumber(number, fallback);
  }

  _isset(value) {
    return !Utils.isNull(value);
  }

  _getUVBounds(geometry, result = null) {
    let uvs = null, res = result;

    if (res) {
      res.valid = false;
    }

    if (!geometry) {
      return res;
    }
    if (geometry.attributes) {
      uvs = geometry.attributes.uv || geometry.attributes.uvs;
    }
    if (!uvs) {
      uvs = GeomUtils.getUVsFromPolygons(geometry.polygons);
      if (!geometry.attributes) {
        geometry.attributes = {};
      }
      geometry.attributes.uv = uvs;
    }
    res = BoundingBoxUtils._expandBoundingBoxByVectors(uvs, null, null, res);

    return res;
  }

  // Auto calculate uvs based on the distance between the vertices
  // (Actually only the V-coordinate because it's a single line)
  computeUVs(array, num, fromInd = 0, vMultiplier = 1, vOffset = 0) {
    const endIndex = num;
    const fromIndex = fromInd || 0;
    let prevVert = null;
    let V = 0;

    for (let i = 0; i < endIndex; ++i) {
      const vertex = array[i + fromIndex];
      const x = vertex.getCoord(0);
      const y = vertex.getCoord(1);

      if (prevVert !== null && typeof (prevVert) !== 'undefined') {
        const px = prevVert.getCoord(0);
        const py = prevVert.getCoord(1);

        const dx = x - px;
        const dy = y - py;
        const d = Math.sqrt(dx * dx + dy * dy);

        V += d;
      }

      if (vertex instanceof Vertex) {
        let atts = vertex.attributes;

        if (!atts) {
          atts = vertex.attributes = {};
        }
        let uv = atts.uv;

        if (!uv) {
          uv = atts.uv = new Vector2();
        }

        uv.setCoord(0, 0);
        uv.setCoord(1, V * vMultiplier + vOffset);
      }


      prevVert = vertex;
    }
  }

  // Auto calculate vertex normals, based on the average normal between the adjacent edges
  computeVertexNormals(array, num, fromInd) {
    const endIndex = num;
    const fromIndex = fromInd || 0;

    let i, index, vertex, normal;

    // TODO: move some calculations to utils
    for (i = 0; i < endIndex; ++i) {
      index = fromIndex + i;
      vertex = array[index];
      const prevVert = i > 0 ? array[index - 1] : null;
      const nextVert = i < endIndex ? array[index + 1] : null;
      let dx1;
      let dy1;
      let dx2;
      let dy2;
      let d;
      let nx1;
      let ny1;
      let nx2;
      let ny2;

      if (prevVert) {
        dx1 = vertex.getCoord(0) - prevVert.getCoord(0);
        dy1 = vertex.getCoord(1) - prevVert.getCoord(1);
        nx1 = -dy1;
        ny1 = dx1;
        d = dx1 * dx1 + dy1 * dy1;
        if (d !== 0 && d !== 1) {
          d = 1.0 / Math.sqrt(d);
          nx1 *= d;
          ny1 *= d;
        }
      }

      if (nextVert) {
        dx2 = nextVert.getCoord(0) - vertex.getCoord(0);
        dy2 = nextVert.getCoord(1) - vertex.getCoord(1);
        nx2 = -dy2;
        ny2 = dx2;
        d = dx2 * dx2 + dy2 * dy2;
        if (d !== 0 && d !== 1) {
          d = 1.0 / Math.sqrt(d);
          nx2 *= d;
          ny2 *= d;
        }
      }

      if (prevVert && nextVert) {
        nx1 += nx2;
        ny1 += ny2;
        nx1 *= 0.5;
        ny1 *= 0.5;
        d = nx1 * nx1 + ny1 * ny1;
        if (d !== 0 && d !== 1) {
          d = 1.0 / Math.sqrt(d);
          nx1 *= d;
          ny1 *= d;
        }
      } else if (nextVert) {
        nx1 = nx2;
        ny1 = ny2;
      }
      let atts = vertex.attributes;

      if (!atts) {
        atts = {};
        vertex.attributes = atts;
      }

      normal = atts.normal;

      if (!normal) {
        normal = new Vector3();
        atts.normal = normal;
      }
      normal.setCoord(0, -nx1);
      normal.setCoord(1, -ny1);
      normal.setCoord(2, 0);
    }
  }

  borderTransformVector3(v3, yOffset, borderShape, deltaTransform, perVertex, res) {
    if (!borderShape) {
      if (res) {
        res.setCoord(1, res.getCoord(1) - yOffset);
      }

      return res;
    }
    if (!v3) {
      return res;
    }
    let x = 0;
    let y = 0;
    let z = 0;


    if (v3 instanceof Vector) {
      x = v3.getCoord(0);
      y = v3.getCoord(1);
      z = v3.getCoord(2);
    }

    return borderShape.transformVectorCoordsAtLength(yOffset, x, y, z, deltaTransform, perVertex, res);
  }

  borderTransformVertices(vertices, yOffset, borderShape, perVertex, indexOffset) {
    if (!vertices) {
      return;
    }
    const num = vertices.length;

    if (num === 0) {
      return;
    }

    const indOffset = indexOffset || 0;

    for (let i = 0; i < num; ++i) {
      const vert = vertices[i + indOffset];

      if (vert) {
        this.borderTransformVector3(vert, yOffset, borderShape, false, perVertex, vert);
        const atts = vert.attributes;

        if (atts) {
          const nrm = atts.normal;

          if (nrm) {
            this.borderTransformVector3(vert, yOffset, borderShape, true, perVertex, vert);
          }
        }
      }
    }
  }

  _getNextBorderComponent(componentData, mattressData) {
    return this._getBorderComponentNeigbour(componentData, mattressData, 1);
  }

  _getPreviousBorderComponent(componentData, mattressData) {
    return this._getBorderComponentNeigbour(componentData, mattressData, -1);
  }

  _getBorderComponentNeigbour(componentData, mattressData, offset) {
    if (offset === 0) {
      return componentData;
    }
    if (!mattressData || !componentData) {
      return null;
    }
    const border = mattressData;

    if (!border) {
      return null;
    }
    const borderComps = border.components;

    if (!borderComps) {
      return null;
    }
    const len = borderComps.length;

    if (len < 2) {
      return null;
    }

    const idx = borderComps.indexOf(componentData, 0);

    if (idx < 0) {
      return null;
    }
    const neighbourIndex = idx + offset;

    if (neighbourIndex < 0) {
      return null;
    }
    if (neighbourIndex >= len) {
      return null;
    }

    return borderComps[neighbourIndex];
  }

  createNode3D(geom, borderLoop) {
    // Mattress3DBuilder automatically creates a GeometryNode3D instance if this method returns null
    return null;
  }

  // Returns true if the border component can deform other meshes like handles
  hasDeformShape(data, mattressData) {
    return false;
  }

  getShapeXByY(ypos, data, mattressData) {
    return 0;
  }

  addVertices(borderComponentData, mattressData, yOffset, borderShape, array, fromIndex) {
    // TODO: complete?
    return array;
  }

  getThickness(borderComponentData, mattressData) {
    return 0;
  }

  getResultThickness(borderComponentData, mattressData) {
    return this.getThickness(borderComponentData, mattressData);
  }

  static getBorderComponentDepth(borderComponentData, mattressData) {
    if (!borderComponentData) {
      return 0;
    }
    let d = borderComponentData.depth;

    if (d === undefined || d === null) {
      return 0;
    }

    if (typeof (d) === 'string') {
      d = parseFloat(d);
    }
    if (typeof (d) === 'number') {
      if (isNaN(d)) {
        return 0;
      }

      return d;
    }

    return 0;
  }

  isValid(borderComponentData, mattressData) {
    if (!borderComponentData) {
      return false;
    }

    return true;
  }

  getDepth(borderComponentData, mattressData) {
    return BorderComponentType.getBorderComponentDepth(borderComponentData, mattressData);
  }

  // The amount of space the object uses on the y-axis, usually the same as the physical height
  getSizeY(borderComponentData, mattressData) {
    return this.getHeight(borderComponentData, mattressData);
  }

  // physical height of the object
  getHeight(borderComponentData, mattressData) {
    const top = this.getTop(borderComponentData, mattressData);
    const bottom = this.getBottom(borderComponentData, mattressData);

    return top > bottom ? top - bottom : bottom - top;
  }

    // top value in border-space (increases from top to bottom)
  getTop(borderComponentData, mattressData) {
    return 0;
  }

    // bottom value in border-space (increases from top to bottom)
  getBottom(borderComponentData, mattressData) {
    return 0;
  }

  getNodeMainGeometry(node) {
    return node && node.geometry;
  }
}
