const AShift = 24;
const BShift = 16;
const RShift = 0;
const GShift = 8;

const ROFFSET = 0;
const GOFFSET = 1;
const BOFFSET = 2;

const TYPE_NONE = null;
const TYPE_BUMP = 'bump';
const TYPE_NORMAL = 'normal';

export default class BumpNormalUtils {
  static isBumpMap(img) {
    return this.getBumpType(img) === TYPE_BUMP;
  }

  static isNormalMap(img) {
    return this.getBumpType(img) === TYPE_NORMAL;
  }

  static getBumpType(img) {
    if (!img) {
      return TYPE_NONE;
    }
    if (typeof (img) === 'string') {
      return this.getBumpType(document.getElementById(img));
    }
    if (this._isImage(img)) {
      return this.getBumpType(this._imgToCanvas(img));
    } else if (this._isCanvas(img)) {
      const w = img.width;
      const h = img.height;

      if (!w || !h) {
        return TYPE_NONE;
      }
      const ctx = img.getContext('2d');

      const imgData = ctx.getImageData(0, 0, w, h);
      const data = imgData.data;
      const pixels = new Uint32Array(data.buffer);
      const count = 10;

      let numNormal = 0;
      let numBump = 0;

      const hcount = count >> 1;
      const numPixels = pixels.length;

      for (let i = 0; i < count; ++i) {
        const index = (Math.random() * numPixels) | 0;
        const pix = pixels[index];
        const type = this._getBumpTypeOfPixelColor(pix);

        if (type === TYPE_NORMAL) {
          ++numNormal;
        } else if (type === TYPE_BUMP) {
          ++numBump;
        }
      }
      if (numNormal > hcount) {
        return TYPE_NORMAL;
      } else if (numBump > hcount) {
        return TYPE_BUMP;
      }

      return TYPE_NONE;
    }

    return TYPE_NONE;
  }

  static isBumpMapPixels(pixels, width, height, params) {
    if (!pixels) {
      return false;
    }

    let numChecks = 10;

    if (params) {
      if (typeof (params.numChecks) !== 'undefined' && params.numChecks !== null) {
        numChecks = params.numChecks;
      }
    }
    const isUint32 = pixels instanceof Uint32Array;
    const numValues = pixels.length;
    let numPixels = numValues;
    let numBumpPixels = 0;

    if (!isUint32) {
      numPixels = numValues >> 2;
    }

    for (let i = 1; i <= numChecks; ++i) {
      const index = ((i / numChecks) * numPixels) | 0;
      let R = 0, G = 0, B = 0;

      if (isUint32) {
        const pixel = pixels[index];
        const mask = 0xFF;

        R = (pixel >> RShift) & mask;
        G = (pixel >> GShift) & mask;
        B = (pixel >> BShift) & mask;
      } else {
        const offset = index << 2;

        R = pixels[offset + ROFFSET];
        G = pixels[offset + GOFFSET];
        B = pixels[offset + BOFFSET];
      }
      if (this.isBumpMapPixel(R, G, B, params)) {
        ++numBumpPixels;
      }
    }

    return (numBumpPixels > (numChecks >> 1));
  }

  static isBumpMapPixel(R, G, B, params) {
    let toll = 32;

    if (params) {
      if (typeof (params.tollerance) !== 'undefined' && params.tollerance !== null) {
        toll = params.tollerance;
      }
    }
    const divAvg = 3;
    const avg = (R + G + B) / divAvg;

    const diffR = R > avg ? R - avg : avg - R;
    const diffG = G > avg ? G - avg : avg - G;
    const diffB = B > avg ? B - avg : avg - B;

    return (diffR < toll) && (diffG < toll) && (diffB < toll);
  }

  static _getBumpTypeOfPixelColor(color) {
    if (this._isNormalPixelColor(color)) {
      return TYPE_NORMAL;
    } else if (this._isBumpPixelColor(color)) {
      return TYPE_BUMP;
    }

    return TYPE_NONE;
  }

  static _isBumpPixelColor(color) {
    const mask = 0xFF;
    const r = ((color >> RShift) & mask);
    const g = ((color >> GShift) & mask);
    const b = ((color >> BShift) & mask);
    const avgDiv = 3;

    const avg = (r + g + b) / avgDiv;
    const dR = r > avg ? r - avg : avg - r;
    const dG = g > avg ? g - avg : avg - g;
    const dB = b > avg ? b - avg : avg - b;
    const toll = 32;

    return dR < toll && dG < toll && dB < toll;
  }

  static _isNormalPixelColor(color, tollerance) {
    const mask = 0xFF;
    const r = ((color >> RShift) & mask) / mask;
    const g = ((color >> GShift) & mask) / mask;
    const b = ((color >> BShift) & mask) / mask;

    const nx = (r * 2) - 1;
    const ny = (g * 2) - 1;
    const nz = (b * 2) - 1;
    const n = nx * nx + ny * ny + nz * nz;
    const d = n > 1 ? n - 1 : 1 - n;
    let toll = tollerance;

    if (typeof (toll) === 'undefined' || toll === null || isNaN(toll)) {
      const defToll = 0.05;

      toll = defToll;
    }

    return (d < toll);
  }

  static _imgToCanvas(img) {
    if (!img) {
      return null;
    }
    const w = img.width;
    const h = img.height;
    const res = document.createElement('canvas');

    res.width = w;
    res.height = h;

    const ctx = res.getContext('2d');

    ctx.drawImage(img, 0, 0);

    return res;
  }
  static _isElement(element, type) {
    if (!element || !type) {
      return false;
    }
    if (element.nodeType !== 1) {
      return false;
    }
    const nn = element.nodeName;

    if (!nn) {
      return false;
    }
    const typeLc = type.toLowerCase();
    const nnLc = nn.toLowerCase();

    return (nnLc === typeLc);
  }

  static _isImage(img) {
    if (!img) {
      return false;
    }
    if (img instanceof Image) {
      return true;
    }

    return this._isElement(img, 'IMG');
  }

  static _isCanvas(cvs) {
    if (!cvs) {
      return false;
    }

    return (cvs.getContext !== null) && (typeof (cvs.getContext) !== 'undefined');
  }

  static convertBumpToNormal(bumpMap, multiplier = 1, xMultiplier = 1, yMultiplier = 1, createNew = true) {
    if (!bumpMap) {
      return null;
    }
    if (typeof (bumpMap) === 'string') {
      return this.convertBumpToNormal(document.getElementById(bumpMap), multiplier, xMultiplier, yMultiplier, createNew);
    }
    if (this._isImage(bumpMap)) {
      const canvas = document.createElement('canvas');

      canvas.width = bumpMap.width;
      canvas.height = bumpMap.height;

      const ctx = canvas.getContext('2d');

      ctx.drawImage(bumpMap, 0, 0);

      return this.convertBumpToNormal(canvas, multiplier, xMultiplier, yMultiplier, false);
    } else if (this._isCanvas(bumpMap)) {
      const ctx = bumpMap.getContext('2d');
      const w = bumpMap.width;
      const h = bumpMap.height;
      let destCanvas = bumpMap;
      let destContext = ctx;

      if (createNew) {
        destCanvas = document.createElement('canvas');
        destCanvas.width = w;
        destCanvas.height = h;
        destContext = destCanvas.getContext('2d');
      }
      const srcImgData = ctx.getImageData(0, 0, w, h);
      const dstImgData = destContext.createImageData(w, h);

      this.convertBumpPixelsToNormal(srcImgData.data, w, h, multiplier, xMultiplier, yMultiplier, dstImgData.data);
      destContext.putImageData(dstImgData, 0, 0);

      return destCanvas;
    }

    return null;
  }

  static _toPixelIndex(x, y, width, height) {
    let xpos = x;
    let ypos = y;

    xpos %= width;
    ypos %= height;

    xpos = xpos < 0 ? xpos + width : xpos;
    ypos = ypos < 0 ? ypos + height : ypos;

    return xpos + ypos * width;
  }

  static _getColorHeightValue(color, multiplier) {
    const shift = 0;
    const mask = 0xFF;
    const m = multiplier / mask;

    return ((color >> shift) & mask) * m;
  }

  static _getPixelNormal(value, multX, multY, left, topLeft, top, topRight, right, bottomRight, bottom, bottomLeft, result) {
    let res = result;
    let X = 0;
    let Y = 0;
    let Z = 0;
    const m = 0.125; // multiply (1 / 8) = average normal -> divide by 8 normals

    /*
    [TL][ T][TR]
    [ L][ C][ R]
    [BL][ B][BR]
    */

    res = this._triangleNormal(0, 0, value, -multX, 0, left, -multX, -multY, topLeft, res);
    X += res[0]; Y += res[1]; Z += res[2];

    res = this._triangleNormal(0, 0, value, -multX, -multY, topLeft, 0, -multY, top, res);
    X += res[0]; Y += res[1]; Z += res[2];

    res = this._triangleNormal(0, 0, value, 0, -multY, top, multX, -multY, topRight, res);
    X += res[0]; Y += res[1]; Z += res[2];

    res = this._triangleNormal(0, 0, value, multX, -multY, topRight, multX, 0, right, res);
    X += res[0]; Y += res[1]; Z += res[2];

    res = this._triangleNormal(0, 0, value, multX, 0, right, multX, multY, bottomRight, res);
    X += res[0]; Y += res[1]; Z += res[2];

    res = this._triangleNormal(0, 0, value, multX, multY, bottomRight, 0, multY, bottom, res);
    X += res[0]; Y += res[1]; Z += res[2];

    res = this._triangleNormal(0, 0, value, 0, multY, bottom, -multX, multY, bottomLeft, res);
    X += res[0]; Y += res[1]; Z += res[2];

    res = this._triangleNormal(0, 0, value, -multX, multY, bottomLeft, -multX, 0, left, res);
    X += res[0]; Y += res[1]; Z += res[2];

    X *= m;
    Y *= m;
    Z *= m;

    res[0] = X;
    res[1] = Y;
    res[2] = Z;

    return res;
  }

  static _triangleNormal(cx, cy, cz, x1, y1, z1, x2, y2, z2, result) {
    return this._cross(x1 - cx, y1 - cx, z1 - cx, x2 - cx, y2 - cx, z2 - cx, result);
  }

  static _cross(x1, y1, z1, x2, y2, z2, result) {
    let res = result;

    if (!res) {
      const size = 3;

      res = new Float32Array(size);
    }
    const x = y1 * z2 - y2 * z1;
    const y = z1 * x2 - z2 * x1;
    const z = x1 * y2 - x2 * y1;

    res[0] = x;
    res[1] = y;
    res[2] = z;

    return res;
  }

  static _normalToColor(x, y, z) {
    let X = x, Y = y, Z = z;
    let d = X * X + Y * Y + Z * Z;
    const diff = d > 1 ? d - 1 : 1 - d;
    const toll = 0.001;

    if (d !== 0 && diff > toll) {
      d = 1.0 / Math.sqrt(d);
      X *= d;
      Y *= d;
      Z *= d;
    }
    const max = 255;

    X = (((X + 1) * 0.5) * max) | 0;
    Y = (((Y + 1) * 0.5) * max) | 0;
    Z = (((Z + 1) * 0.5) * max) | 0;
    const A = max;

    const col = (X << RShift) | (Y << GShift) | (Z << BShift) | (A << AShift);

    return col;
  }

  static convertBumpPixelsToNormal(bumpMapPixels, width, height, multiplier = 1, xMultiplier = 1, yMultiplier = 1, destPixels = null) {
    let i = 0;

    let res = destPixels;

    if (!res) {
      if (bumpMapPixels instanceof Uint32Array) {
        res = new Uint32Array(bumpMapPixels.length);
      } else if (bumpMapPixels instanceof Uint8Array) {
        res = new Uint8Array(bumpMapPixels.length);
      } else if (bumpMapPixels instanceof Uint8ClampedArray) {
        res = new Uint8ClampedArray(bumpMapPixels.length);
      }
    }

    const srcBuffer = bumpMapPixels instanceof Uint32Array ? bumpMapPixels : new Uint32Array(bumpMapPixels.buffer);
    const destBuffer = res instanceof Uint32Array ? res : new Uint32Array(res.buffer);
    const mult = multiplier;
    const multx = xMultiplier;
    const multy = yMultiplier;

    let tempVec;

    for (let y = 0; y < height; ++y) {
      for (let x = 0; x < width; ++x) {
        const leftIndex = this._toPixelIndex(x - 1, y, width, height);
        const topLeftIndex = this._toPixelIndex(x - 1, y - 1, width, height);
        const topIndex = this._toPixelIndex(x, y - 1, width, height);
        const topRightIndex = this._toPixelIndex(x + 1, y - 1, width, height);
        const rightIndex = this._toPixelIndex(x + 1, y, width, height);
        const bottomRightIndex = this._toPixelIndex(x + 1, y + 1, width, height);
        const bottomIndex = this._toPixelIndex(x, y + 1, width, height);
        const bottomLeftIndex = this._toPixelIndex(x - 1, y + 1, width, height);

        const srcVal = this._getColorHeightValue(srcBuffer[i], mult);
        const srcLeftVal = this._getColorHeightValue(srcBuffer[leftIndex], mult);
        const srcTopLeftVal = this._getColorHeightValue(srcBuffer[topLeftIndex], mult);
        const srcTopVal = this._getColorHeightValue(srcBuffer[topIndex], mult);
        const srcTopRightVal = this._getColorHeightValue(srcBuffer[topRightIndex], mult);
        const srcRightVal = this._getColorHeightValue(srcBuffer[rightIndex], mult);
        const srcBottomRightVal = this._getColorHeightValue(srcBuffer[bottomRightIndex], mult);
        const srcBottomVal = this._getColorHeightValue(srcBuffer[bottomIndex], mult);
        const srcBottomLeftVal = this._getColorHeightValue(srcBuffer[bottomLeftIndex], mult);

        tempVec = this._getPixelNormal(srcVal, 1, 1, srcLeftVal, srcTopLeftVal, srcTopVal,
          srcTopRightVal, srcRightVal, srcBottomRightVal, srcBottomVal, srcBottomLeftVal, tempVec);

        const nx = tempVec[0] * multx;
        const ny = tempVec[1] * multy;
        const nz = tempVec[2];

        const col = this._normalToColor(nx, ny, nz);

        // const col = 0xFF0000FF;

        destBuffer[i] = col;

        ++i;
      }
    }

    return res;
  }
}
BumpNormalUtils.TYPE_NONE = TYPE_NONE;
BumpNormalUtils.TYPE_BUMP = TYPE_BUMP;
BumpNormalUtils.TYPE_NORMAL = TYPE_NORMAL;
