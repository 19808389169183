import Material from '../../bgr/bgr3d/material/Material';

export default class BD3DMaterial extends Material {
  constructor(type = null, settings = null) {
    super();
    this._type = type;
    this.setSettings(settings);
    this.userData = null;
  }

  clone() {
    const cl = this._getClone();

    this._initClone(cl);

    return cl;
  }

  _getClone() {
    let Cl = this.constructor;

    if (Cl === null || typeof (Cl) === 'undefined') {
      Cl = BD3DMaterial;
    }
    const res = new Cl(this.getType());

    return res;
  }

  _initClone(cl) {
    const newSettings = {};
    const curSettings = this.getSettings();

    if (newSettings && curSettings) {
      for (const v in curSettings) {
        if (curSettings.hasOwnProperty(v)) {
          newSettings[v] = curSettings[v];
        }
      }
    }
    cl.setSettings(newSettings);
  }

  getTexture() {
    return this.get('texture');
  }

  setTexture(t) {
    this.set('texture', t);
  }

  getNormalMap() {
    return this.get('normalmap');
  }

  setNormalMap(v) {
    this.set('normalmap', v);
  }

  setType(t) {
    this._type = t;
  }

  getType() {
    return this._type;
  }

  set type(t) {
    this.setType(t);
  }

  get type() {
    return this.getType();
  }

  setSettings(s) {
    this._settings = s;
  }

  getSettings() {
    return this._settings;
  }

  get settings() {
    return this.getSettings();
  }

  set settings(v) {
    this.setSettings(v);
  }

  get(key) {
    if (!key) {
      return null;
    }
    if (!this._settings) {
      return null;
    }

    return this._settings[key];
  }

  set(key, value) {
    if (!key) {
      return;
    }
    if (!this._settings) {
      this._settings = {};
    }
    let k = key;

    if (typeof (k) !== 'string') {
      k = `${key}`;
    }
    this._settings[k] = value;
  }
}
