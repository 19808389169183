import Loader from './Loader';
import LoaderState from './LoaderState';
import Utils from '../utils/Utils';
// import LoaderEvent from './LoaderEvent';

const UNDEFINED = 'undefined';
const STRING = 'string';
const EMPTY_URL = '';

const EVENT_LOAD = 'load';
const EVENT_ERROR = 'error';


/**
 * Loader
 * @abstract
 * @extends EventDispatcher
 */
export default class ImageLoader extends Loader {
  constructor(args) {
    super(args);
    let image = null;
    let imageSource = null;
    let crossOrigin = null;

    if (args !== null && typeof (args) !== UNDEFINED) {
      if (typeof (Image) !== UNDEFINED && args instanceof Image) {
        image = args;
      } else if (typeof (args) === STRING) {
        imageSource = args;
      } else {
        image = args.image;
        crossOrigin = args.crossOrigin;
        imageSource = Utils.tryValues(args.imageSource, args.src, args.source, args.url,
          args.URL, args.uri, args.URI);
      }
    }
    this.error = null;
    this.image = image;
    this.source = imageSource;
    this.crossOrigin = crossOrigin;
  }

  /**
   * The loaded image
   * @return {[type]} [description]
   */
  getImage() {
    return this.image;
  }

  /**
   * Start the loader
   * @return {void}
   */
  start() {
    let ls = this.getState();

    if (ls === null || typeof (ls) === UNDEFINED) {
      ls = LoaderState.IDLE;
    }
    if (ls !== LoaderState.IDLE) {
      return;
    }

    const imageSource = this.getImageSource();

    if (imageSource === null || typeof (imageSource) === UNDEFINED) {
      this._changeState(LoaderState.ERROR);
      this.error = {message: 'Invalid source'};

      return;
    }

    this._changeState(LoaderState.PROGRESS);
    this.error = null;

    let img = this.image;

    if (img === null || typeof (img) === UNDEFINED) {
      if (typeof (Image) !== UNDEFINED) {
        img = new Image();
        this.image = img;
      }
    }

    const loadHandler = this._getImageEventListener(EVENT_LOAD, img, true);
    const errorHandler = this._getImageEventListener(EVENT_ERROR, img, true);

    img.crossOrigin = this.crossOrigin;

    this._removeImageListeners();
    img.src = EMPTY_URL;

    img.addEventListener(EVENT_LOAD, loadHandler);
    img.addEventListener(EVENT_ERROR, errorHandler);
    img.src = this.getImageSource();

    return;
  }
  _removeImageListeners() {
    const img = this.getImage();
    const loadHandler = this._getImageEventListener(EVENT_LOAD, img, false);
    const errorHandler = this._getImageEventListener(EVENT_ERROR, img, false);

    if (loadHandler !== null && typeof (loadHandler) !== UNDEFINED) {
      img.removeEventListener(EVENT_LOAD, loadHandler);
    }
    if (errorHandler !== null && typeof (errorHandler) !== UNDEFINED) {
      img.removeEventListener(EVENT_ERROR, errorHandler);
    }
  }

  setImageURL(url) {
    this.setImageURI(url);
  }

  setImageURI(uri) {
    this.setImageSource(uri);
  }

  setImageSource(src) {
    if (this.getState() === LoaderState.PROGRESS) {
      throw new Error('Can\'t change the loader\'s source while loading');
    }
    this.source = src;
  }

  setSource(src) {
    this.setImageSource(src);
  }

  getImageURL() {
    return this.getImageURI();
  }

  getImageURI() {
    return this.getImageSource();
  }

  getImageSource() {
    return this.source;
  }

  getSource() {
    return this.getImageSource();
  }

  _handleImageLoaded(evt, image) {
    this._changeState(LoaderState.COMPLETED);
  }

  _handleImageError(evt, image) {
    this._changeState(LoaderState.ERROR);
  }

  _getImageEventListeners(create = true) {
    let evtListeners = this._imageEventListeners;

    if (!create) {
      return evtListeners;
    }
    if (evtListeners === null || typeof (evtListeners) === UNDEFINED) {
      evtListeners = this._imageEventListeners = {};
    }

    return evtListeners;
  }

  _getImageEventListener(type, image, create = true) {
    const evtListeners = this._getImageEventListeners(create);

    if (evtListeners === null || typeof (evtListeners) === UNDEFINED) {
      return null;
    }

    let listener = evtListeners[type];

    if (!create) {
      return listener;
    }

    if (listener === null || typeof (listener) === UNDEFINED) {
      const that = this;

      if (type === 'load') {
        listener = evt => {
          that._handleImageLoaded(evt, image);
        };
      } else if (type === 'error') {
        listener = evt => {
          that._handleImageError(evt, image);
        };
      }
    }

    return listener;
  }

  /**
   * Cancel loading
   * @return {void}
   */
  cancel() {
    const img = this.getImage();

    if (img === null || typeof (img) === UNDEFINED) {
      return;
    }

    this._removeImageListeners();

    img.src = EMPTY_URL;
    super.cancel();

    return;
  }

  dispose(params) {
    super.dispose(params);
    this._removeImageListeners();
    this.image = null;
    this._imageEventListeners = null;
  }

  /**
   * Resets the loader so it can load again
   * @param {Object} params - Params object
   * @returns {void}
   */
  reset(params = null) {
    super.reset(params);
    this.error = null;
  }
}
