import Node3DCloner from '../../bgr/bgr3d/cloners/Node3DCloner';
import Object3DAsset from '../asset/Object3DAsset';
import BD3DContainerNode3D from '../scenegraph/BD3DContainerNode3D';
import SRTTransform3D from '../../bgr/bgr3d/transform/SRTTransform3D';
// import BD3DFabricMaterial from '../material/BD3DFabricMaterial';
import BD3DPlasticMaterial from '../material/BD3DPlasticMaterial';
// import ImageAsset from '../asset/ImageAsset';
import Node3DMaterialUtils from '../material/Node3DMaterialUtils';
import LegDA from '../mattress/LegDA';
// import MaterialType from '../material/MaterialType';

const AssetData = {
  LEG_0_OBJ: {
    defaultHeight: 10,
    asset: new Object3DAsset('legs.leg_0.object')
  },
  LEG_1_OBJ: {
    defaultHeight: 8,
    asset: new Object3DAsset('legs.leg_1.object')
  },
  LEG_2_OBJ: {
    defaultHeight: 8,
    asset: new Object3DAsset('legs.leg_2.object')
  }
};
const DISTANCE_FROM_BORDER = 20;
const DEFAULT_LEG_COLOR = 0x6f6f6e;
const DEFAULT_LEG_SPECULAR_POWER = 2;
const DEFAULT_LEG_SPECULAR_MULTIPLIER = 0.1;
let material;

function onClonedHandleNode(source, result, params) {
  if (!source || !result) {
    return;
  }

  if (!material) {
    material = new BD3DPlasticMaterial();

    material.setColorMultiplier(DEFAULT_LEG_COLOR);
    material.setSpecularPower(DEFAULT_LEG_SPECULAR_POWER);
    material.setSpecularMultiplier(DEFAULT_LEG_SPECULAR_MULTIPLIER);
    /*
    material = new BD3DFabricMaterial();
    material = new BD3DFabricMaterial();
    material.setType(new MaterialType('legs'));
    const texture = Assets.TEX_LEG_COLOR;
    const normalMap = Assets.TEX_LEG_NORMAL;

    material.set('diffuse', texture);
    material.set('normal', normalMap);
    material.set('specularMask', null);
    */
  }

  Node3DMaterialUtils.assignMaterial(result, material);
}

export default class LegType {
  create3DLegs(data, mattressData, buildParams, resultNode = null) {
    if (resultNode) {
      resultNode.removeChildren();
    }

    const resNode = resultNode || new BD3DContainerNode3D();
    const box = mattressData.box;
    const cornerRadius = box.radius.corners;
    const cornerBottom = box.radius.bottom;
    const radius = cornerRadius + cornerBottom / 2;
    const width = box.size.width - radius - DISTANCE_FROM_BORDER;
    const length = box.size.length - radius - DISTANCE_FROM_BORDER;
    const x = width / 2;
    const y = 0;
    const z = length / 2;

    resNode.addChild(this.newLeg(x, y, z, data, mattressData));
    resNode.addChild(this.newLeg(-x, y, z, data, mattressData));
    resNode.addChild(this.newLeg(x, y, -z, data, mattressData));
    resNode.addChild(this.newLeg(-x, y, -z, data, mattressData));

    if (resNode instanceof BD3DContainerNode3D) {
      resNode.setSelectable(true);
    }

    return resNode;
  }

  addAssets(data, params, array = null, assetManager = null) {
    let arr = array;
    const objAsset = this._get3DObjectAsset(data);
    const commonTextures = assetManager.assetCollections.commonTextures;

    const defaultFabricColor = commonTextures.getAssetByName('defaultfabric.color');
    const defaultFabricNormal = commonTextures.getAssetByName('defaultfabric.normal');

    arr = assetManager.addAssetToArray(objAsset, arr, params);
    arr = assetManager.addAssetToArray(defaultFabricColor, arr, params);
    arr = assetManager.addAssetToArray(defaultFabricNormal, arr, params);
    // arr = assetManager.addAssetToArray(Assets.TEX_LEG_COLOR, arr, params);
    // arr = assetManager.addAssetToArray(Assets.TEX_LEG_NORMAL, arr, params);

    return arr;
  }

  _get3DObjectAsset(data) {
    const prefix = 'LEG_';
    const suffix = '_OBJ';
    const assetName = prefix + data.id + suffix;
    const assetData = AssetData[assetName.toUpperCase()];

    return assetData && assetData.asset;
  }

  _get3DObjectAssetData(data) {
    const prefix = 'LEG_';
    const suffix = '_OBJ';
    const assetName = prefix + data.id + suffix;
    const assetData = AssetData[assetName.toUpperCase()];

    return assetData;
  }

  newLeg(x, y, z, data, mattressData) {
    const assetData = this._get3DObjectAssetData(data, mattressData);
    const asset = assetData && assetData.asset;

    if (!asset) {
      return null;
    }

    const node = asset.getNode3D();

    if (!node) {
      return null;
    }

    const cloneGeom = false;
    let clonerParams = this._clonerParams;

    if (!clonerParams) {
      clonerParams = this._clonerParams = {};
    }

    clonerParams.onClonedNode3D = onClonedHandleNode;
    clonerParams.cloneGeometry = cloneGeom;
    clonerParams.data = data;
    clonerParams.assets = asset;

    const res = Node3DCloner.clone(node, clonerParams);
    const tr = res.transform = new SRTTransform3D();
    const legHeight = LegDA.getLegHeight(data);
    const {defaultHeight} = assetData;

    if (defaultHeight && defaultHeight !== legHeight) {
      const scaleY = legHeight / defaultHeight;

      tr.scale.setCoord(1, scaleY);
    }

    tr.position.setCoord(0, x);
    tr.position.setCoord(1, y);
    tr.position.setCoord(2, z);

    if (!res.userData) {
      res.userData = {};
    }
    res.userData.type = 'leg';

    return res;
  }
}
