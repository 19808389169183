import * as THREE from 'three';

const _12 = 12;
const _13 = 13;
const _14 = 14;

export default class ThreeMatrixUtils {
  static translate(matrix, x = 0, y = 0, z = 0) {
    if (!matrix) {
      return;
    }
    const e = matrix.elements;

    if (matrix instanceof THREE.Matrix4) {
      e[_12] += x;
      e[_13] += y;
      e[_14] += z;
    }
  }

  static rotateX(matrix, amount) {
    this.rotate(matrix, 1, 0, 0, amount);
  }

  static rotateY(matrix, amount) {
    this.rotate(matrix, 0, 1, 0, amount);
  }

  static rotateZ(matrix, amount) {
    this.rotate(matrix, 0, 0, 1, amount);
  }

  static rotate(matrix, x, y, z, amount) {
    if (!matrix) {
      return;
    }
    const t = this._getTempMatrix();
    const a = this._getTempVector3();

    a.x = x;
    a.y = y;
    a.z = z;

    t.makeRotationAxis(a, amount);
    matrix.multiplyMatrices(t, matrix);
  }

  static _getTempVector3(create = true) {
    let temp = this._tempVec3;

    if (!temp && create) {
      temp = this._tempVec3 = new THREE.Vector3();
    }

    return temp;
  }

  static _getTempMatrix(create = true) {
    let temp = this._tempMatrix;

    if (!temp && create) {
      temp = this._tempMatrix = new THREE.Matrix4();
    }

    return temp;
  }
}
