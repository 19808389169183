export default class URLResolver {
  constructor(args = null) {
    let onResolve = null;

    if (args) {
      if (typeof (args) === 'function') {
        onResolve = args;
      } else {
        onResolve = args.onResolveURL || args.onResolve;
      }
    }
    if (onResolve) {
      this.onResolveURL = onResolve;
    }
  }

  resolveURL(url, params = null) {
    if (this.onResolveURL) {
      return this.onResolveURL(url, params);
    }

    return url;
  }
}
