import * as THREE from 'three';
import ThreeMaterialUtils from './ThreeMaterialUtils';

export default class ThreeMaterial extends THREE.RawShaderMaterial {
  hasUniform(name) {
    return true;
  }

  hasUniformValue(name) {
    const u = this.getUniform(name);

    if (!u) {
      return false;
    }

    const val = this.getUniformValue(name);
    const t = typeof (val);

    if (t === 'undefined' || val === null) {
      return false;
    }

    if (t === 'number') {
      return !isNaN(name);
    }

    return true;
  }

  getUniform(name, fallback = null) {
    if (!this.hasUniform(name)) {
      return fallback;
    }

    return ThreeMaterialUtils.getMaterialUniform(this, name, fallback);
  }


  setUniform(name, value) {
    if (!this.hasUniform(name)) {
      return;
    }
    ThreeMaterialUtils.setMaterialUniform(this, name, value);
  }

  getUniformValue(name, fallback = null) {
    if (!this.hasUniform(name)) {
      return fallback;
    }

    return ThreeMaterialUtils.getMaterialUniformValue(this, name, fallback);
  }

  setUniformValue(name, value) {
    if (!this.hasUniform(name)) {
      return;
    }
    ThreeMaterialUtils.setMaterialUniformValue(this, name, value);
  }

  _setUniformData(name, value) {
    if (!this.hasUniform(name)) {
      return;
    }
    if (ThreeMaterialUtils.isUniform(value)) {
      this.setUniform(name, value);
    } else {
      this.setUniformValue(name, value);
    }
  }

  setUniforms(uniforms) {
    if (!uniforms) {
      return;
    }
    for (const v in uniforms) {
      if (uniforms.hasOwnProperty(v)) {
        const val = uniforms[v];

        this._setUniformData(v, val);
      }
    }
  }
}
