
export default class HandleStyle {
  constructor(id, type, editableComponents, active = true) {
    this.id = id;
    this.type = type;
    this.editableComponents = editableComponents;
    this.active = active;
  }

  getNumMaterials() {
    const comps = this.editableComponents;

    if (!comps) {
      return 0;
    }

    return comps.length;
    /*
    const id = this.getID();
    const type = this.getType();
    let typeName = type && type.getTypeName ? type.getTypeName() : null;

    typeName = typeName && typeName.toLowerCase ? typeName.toLowerCase() : null;

    if (typeName !== 'horizontal' && typeName !== 'vertical') {
      return 0;
    }

    if (id === 'HVS003' || id === 'HHS014') {
      return 0;
    }

    if (id === 'HHS016' || id === 'HHS017' || id === 'HVS005' || id === 'HVS006') {
      return 2;
    }

    return 1;
    */
  }

  getID() {
    return this.id;
  }

  getType() {
    return this.type;
  }

  getElementTypeByName(part) {
    return HandleStyle.getElementTypeByName(this, part);
  }

  isActive() {
    return this.active;
  }

  static getElementTypeByName(handleStyle, part) {
    if (!handleStyle) {
      return null;
    }
    if (part === 'tape' || part === 'fabric') {
      return part;
    }
    let id = handleStyle.getID();
    const type = handleStyle.getType();
    const typeName = type && type.getName ? type.getName() : null;

    if (typeName === 'pocket' || typeName === 'suitcase') {
      return null;
    }

    id = id && id.toLowerCase ? id.toLowerCase() : null;

    if (id === 'hvs003' || id === 'hhs014') {
      // Handle with logo
      return null;
    }

    if (id === 'hvs005' || id === 'hhs016') {
      if (part === 'middle') {
        return 'fabric';
      } else if (part === 'side') {
        return 'tape';
      }
    } else if (id === 'hvs006' || id === 'hhs017') {
      if (part === 'middle') {
        return 'tape';
      } else if (part === 'side') {
        return 'fabric';
      }
    }

    return 'fabric';
  }
}
