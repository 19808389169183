import * as THREE from 'three';
import Node3D from '../../bgr/bgr3d/scenegraph/Node3D';
import MattressConfigObjectTypes from '../mattress/MattressConfigObjectTypes';

const SelectionMode = {
  NONE: null,
  SINGLE: 'single',
  COMPONENT: 'component'
};

function getBD3DObjectByThreeObject(threeObj, mc) {
  if (threeObj instanceof Node3D) {
    return threeObj;
  }
  if (threeObj instanceof THREE.Object3D) {
    return mc._getNode3DByThreeData(threeObj);
  }

  return null;
}

function getDataByNode3D(n3d, mc) {
  let node3d = n3d;

  if (node3d instanceof THREE.Object3D) {
    node3d = getBD3DObjectByThreeObject(node3d, mc);
  }
  if (node3d instanceof Node3D) {
    return mc.getDataByNode3D(node3d);
  }

  return node3d;
}

function getObjectInfo(object, mc) {
  if (!object) {
    return false;
  }
  const cfg = mc.getMattressConfig();

  if (!cfg) {
    return false;
  }
  const info = cfg._getObjectInfo(object);

  return info;
}

function getSingleObject(object, mc) {
  let singleData = null;
  let data = mc.getDataByNode3D(object);
  let info = null;

  if (data) {
    info = getObjectInfo(data, mc);
    singleData = info ? info.single : null;
  }
  if (!singleData) {
    const threeData = mc._getThreeData(object);

    if (!threeData) {
      return null;
    }
    let t = threeData.parent;

    while (t && !singleData) {
      data = getDataByNode3D(t, mc);
      info = getObjectInfo(data, mc);
      if (info) {
        if (info.single) {
          singleData = info.singleData;
        } else if (info.type === MattressConfigObjectTypes.SINGLE) {
          singleData = data;
        }
      }

      t = t.parent;
    }
  }

  return mc._getNode3DByData(singleData);
}

function dispatchClickNone(controller) {
  const contr = controller;
  let clickEvt = controller._clickNoneEvent;
  const eventType = 'click_none';

  if (!clickEvt) {
    clickEvt = {
      type: eventType
    };
    contr._clickNoneEvent = clickEvt;
  }
  clickEvt.type = eventType;
  const mc = controller.getMattressConfigurator();

  mc.dispatchEvent(clickEvt);
}


function canSelectData(controller, selData, selObj, evt) {
  const mc = controller.getMattressConfigurator();

  if (!mc) {
    return false;
  }
  const condition = mc.getSelectableCondition();
  const type = typeof (condition);

  if (type === 'boolean') {
    return condition;
  }

  if (!condition) {
    return true;
  }
  if (condition.call && condition.apply) {
    return condition(selData);
  }

  return true;
}

function canSelectDataWithEvent(controller, selData, selObj, evt) {
  const canSelect = canSelectData(controller, selData, selObj, evt);
  const result = canSelect === true || canSelect > 0;

  if (result || canSelect < 0 || canSelect === null || typeof (canSelect) === 'undefined') {
    // Do nothing, no event
    return result;
  }
  const mc = controller.getMattressConfigurator();

  if (mc) {
    mc.setSelectedData(null);
  }
  dispatchClickNone(controller);

  return result;
}

export default class ClickSelectionController {
  constructor(args) {
    let enabled = false, mc = null, selMode = null;

    if (args) {
      enabled = args.enabled === true;
      mc = args.mattressConfigurator;
      selMode = args.selectionMode;
    }

    if (!selMode) {
      selMode = SelectionMode.COMPONENT;
    }

    this._mattressConfigurator = mc;
    this._enabled = enabled;
    this._selectionMode = selMode;
    this.deselectOnDisable = true;

    this._updateEnabled();
  }

  getMattressConfigurator() {
    return this._mattressConfigurator;
  }

  setMattressConfigurator(mc) {
    const old = this.getMattressConfigurator();

    if (old === mc) {
      return;
    }
    this._setMattressConfiguratorEnabled(mc, false);
    this._mattressConfigurator = mc;

    this._updateMattressConfigurator();
  }

  get mattressConfigurator() {
    return this.getMattressConfigurator();
  }

  set mattressConfigurator(mc) {
    this.setMattressConfigurator(mc);
  }

  setEnabled(e) {
    if (this._enabled === e) {
      return;
    }
    this._enabled = e;
    this._updateEnabled();
  }

  isEnabled() {
    return this._enabled === true;
  }

  get enabled() {
    return this.isEnabled();
  }

  set enabled(e) {
    this.setEnabled(e);
  }

  _updateEnabled() {
    this._updateMattressConfiguratorEnabled();
  }

  _updateMattressConfigurator() {
    this._updateMattressConfiguratorEnabled();
  }

  _updateMattressConfiguratorEnabled() {
    this._setMattressConfiguratorEnabled(this.getMattressConfigurator(), this.isEnabled());
  }

  _setMattressConfiguratorEnabled(mc, enabled) {
    if (!mc) {
      return;
    }
    let clickHandler = this._clickHandler;

    if (enabled) {
      const that = this;

      if (!clickHandler) {
        clickHandler = this._clickHandler = evt => {
          that._handleClickMattressConfig(evt);
        };
      }

      mc.addEventListener('click', clickHandler);
    } else if (clickHandler) {
      if (this.deselectOnDisable) {
        mc.setSelectedObject(null);
      }
      mc.removeEventListener('click', clickHandler);
    }
  }

  getSelectionMode() {
    return this._selectionMode;
  }

  setSelectionMode(mode) {
    let m = mode;

    if (!m || !m.toUpperCase || !SelectionMode[m.toUpperCase()]) {
      m = null;
    }
    this._selectionMode = m;
  }

  get selectionMode() {
    return this.getSelectionMode();
  }

  set selectionMode(m) {
    this.setSelectionMode(m);
  }

  _handleClickMattressConfig(evt) {
    if (!evt) {
      return;
    }
    const mc = this.getMattressConfigurator();

    if (!mc) {
      return;
    }
    const x = evt.x;
    const y = evt.y;

    let selObj = mc.getSelectableObjectUnderLocation(x, y, this.getSelectionMode());

    const cfg = mc.getMattressConfig();

    if (cfg) {
      const selData = mc.getDataByNode3D(selObj);

      if (!canSelectDataWithEvent(this, selData, selObj, evt)) {
        return;
      }
      const group = cfg.getGroupByObject(selData);

      if (group) {
        this._dispatchClickObject(group, 'data');
        mc.setSelectedData(group);

        return;
      }
    }

    const selMode = this.getSelectionMode();

    if (selMode === SelectionMode.SINGLE) {
      selObj = getSingleObject(selObj, mc);
    } else if (selMode !== SelectionMode.COMPONENT) {
      selObj = null;
    }
    const selData = mc.getDataByNode3D(selObj);

    if (!canSelectDataWithEvent(this, selData, selObj, evt)) {
      return;
    }
    if (selObj && selData) {
      // this._dispatchClickObject(selObj, 'node3d');
      this._dispatchClickObject(selData, 'data');
    } else {
      dispatchClickNone(this);
    }

    // mc.dispatchEvent(clickEvt);
    // mc.setSelectedObject(selObj);
    mc.setSelectedData(selData);
  }

  _dispatchClickObject(obj, objectType) {
    let clickEvt = this._clickObjectEvent;

    if (!clickEvt) {
      clickEvt = this._clickObjectEvent = {};
    }
    clickEvt.type = 'click_object';

    clickEvt.objectType = objectType;
    clickEvt.object = obj;
  }
}
ClickSelectionController.SelectionMode = SelectionMode;
