import Matrix4Math from '../math/Matrix4Math';
import Transform3D from './Transform3D';

export default class Matrix4Transform3D extends Transform3D {

  applyMatrix4(m4) {
    return Matrix4Math.multiply(this._matrix, m4, m4);
  }

  getMatrix4() {
    return this._matrix;
  }

  setMatrix(m) {
    this._matrix = m;
  }

}
