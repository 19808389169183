import Transform3D from '../../bgr/bgr3d/transform/Transform3D';
import Matrix4Math from '../../bgr/bgr3d/math/Matrix4Math';
import ImageAsset from '../asset/ImageAsset';
// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
// #endif

const DEFAULT_PIVOT_X = 0.5;
const DEFAULT_PIVOT_Y = 0.5;
const DEFAULT_ALIGN_X = 0.5;
const DEFAULT_ALIGN_Y = 0.5;

const DEFAULT_LOCAL_ALIGN_X = 0.5;
const DEFAULT_LOCAL_ALIGN_Y = 0.5;

const DEG2RAD = Math.PI / 180;
const MM_TO_CM = 0.1;

function parseNum(value, fallback) {
  let v = value;

  if (v === null) {
    return fallback;
  }
  let t = typeof (v);

  if (t === 'object') {
    v = v.valueOf();
    t = typeof (v);
  }
  if (t === 'string') {
    v = parseFloat(v);
    t = typeof (v);
  }

  if (t === 'number') {
    if (isNaN(v)) {
      return fallback;
    }

    return v;
  }

  return fallback;
}

function scaleMatrix(scaleX, scaleY, matrix) {
  return Matrix4Math.scaleXYZ(matrix, scaleX, scaleY, 1, matrix);
}

function rotateMatrixZ(angle, matrix) {
  return Matrix4Math.zRotate(angle, matrix, matrix);
}

function translateMatrix(x, y, matrix) {
  return Matrix4Math.translateXYZ(matrix, x, y, 0, matrix);
}

function getMatrixInverse(matrix, result) {
  return Matrix4Math.getInverse(matrix, result);
}

/**
* @class FabricTransform
* @description Transforms a matrix4 using the data of a sample / quilt
*  example:
*  {
*     "offset": {"x": 10, "y": 20},
*     "rotation": 180
*  }
*/

export default class FabricTransform extends Transform3D {
  constructor(cd, d) {
    super();
    this._data = d;
    this._configData = cd;
    this._realWidth = 1;
    this._realHeight = 1;
    // fallback offset values if can't be read from (config)Data
    this._offsetX = 0;
    this._offsetY = 0;
    this._scaleX = 1;
    this._scaleY = 1;

    // hard coded offset values
    this.translateX = 0;
    this.translateY = 0;

    this._geometryScaleX = null;
    this._geometryScaleY = null;

    this.userData = null;
  }

  clone() {
    let Cl = this.constructor;

    if (!Cl) {
      Cl = FabricTransform;
    }
    const res = new Cl();

    /*
    res._data = this._data;
    res._configData = this._configData;
    res._realWidth = this._realWidth;
    res._realHeight = this._realHeight;
    res._offsetX = this._offsetX;
    res._offsetY = this._offsetY;
    res._scaleX = this._scaleX;
    res._scaleY = this._scaleY;
    res.translateX = this.translateX;
    res.translateY = this.translateY;

    res._geometryScaleX = this._geometryScaleX = 1;
    res._geometryScaleY = this._geometryScaleY = 1;

    res._singlePart = this._singlePart;
    */
    if (res.copyValues) {
      res.copyValues(this);
    }

    return res;
  }

  copyValues(other) {
    if (!other || (!(other instanceof FabricTransform))) {
      return;
    }
    if (other === this) {
      return;
    }

    this._data = other._data;
    this._configData = other._configData;
    this._offsetX = other._offsetX;
    this._offsetY = other._offsetY;
    this._scaleX = other._scaleX;
    this._scaleY = other._scaleY;
    this.translateX = other.translateX;
    this.translateY = other.translateY;

    this._geometryScaleX = other._geometryScaleX;
    this._geometryScaleY = other._geometryScaleY;

    this._singlePart = other._singlePart;

    this.setRealSize(other._realWidth, other._realHeight);
    this.setSourceSizeUnit(other.getSourceSizeUnit());
    this.setOffset(other._offsetX, other._offsetY);
    this.setConfigRotation(other.getConfigRotation());
    this.setGeometryUVData(other.getGeometryUVData());
    this.setScale(other.getScaleX(), other.getScaleY());
    this.setGeometryScale(other.getGeometryScaleX(), other.getGeometryScaleY());
  }

  getSinglePart() {
    return this._singlePart;
  }

  setSinglePart(p) {
    this._singlePart = p;
  }

  getGeometryScaleX() {
    let res = this._geometryScaleX;

    if (res === null || typeof (res) === 'undefined') {
      const uvData = this.getGeometryUVData();

      if (uvData) {
        res = uvData.scaleX;
      }
    }
    res = parseNum(res, 1);

    return res;
  }

  setGeometryScaleX(v) {
    this._geometryScaleX = v;
  }

  getGeometryScaleY() {
    let res = this._geometryScaleY;

    if (res === null || typeof (res) === 'undefined') {
      const uvData = this.getGeometryUVData();

      if (uvData) {
        res = uvData.scaleY;
      }
    }
    res = parseNum(res, 1);

    return res;
  }

  setGeometryScaleY(v) {
    this._geometryScaleY = v;
  }

  setGeometryScale(x, y) {
    this._geometryScaleX = x;
    this._geometryScaleY = y;
  }

  setSourceSizeUnit(s) {
    this._sourceSizeUnit = s;
  }

  getSourceSizeUnit() {
    return this._sourceSizeUnit;
  }

  getRealWidth() {
    return this._realWidth;
  }

  setRealWidth(rw) {
    this._realWidth = rw;
  }

  getRealHeight() {
    return this._realHeight;
  }

  setRealHeight(rh) {
    this._realHeight = rh;
  }

  setRealSize(w, h) {
    this._realWidth = w;
    this._realHeight = h;
  }

  setOffset(x, y) {
    const o = this._getOffset(true);

    if (!o) {
      this._offsetX = parseNum(x, 0);
      this._offsetY = parseNum(y, 0);

      return;
    }
    o.x = parseNum(x, 0);
    o.y = parseNum(y, 0);
  }

  _getOffset(create = false) {
    const d = this.getConfigData();

    if (!d) {
      return null;
    }

    if (d.offset || !create) {
      return d.offset;
    }

    d.offset = {};

    return d.offset;
  }

  getOffsetX() {
    const o = this._getOffset(false);

    if (!o) {
      return this._offsetX;
    }
    if (typeof (o.x) !== 'number' || isNaN(o.x)) {
      return this._offsetX;
    }

    return o.x;
  }

  setOffsetX(v) {
    const o = this._getOffset(true);

    if (!o) {
      this._offsetX = parseNum(v, 0);

      return;
    }

    o.x = parseNum(v, 0);
  }

  getOffsetY() {
    const o = this._getOffset(false);

    if (!o) {
      return this._offsetY;
    }
    if (typeof (o.y) !== 'number' || isNaN(o.y)) {
      return this._offsetY;
    }

    return o.y;
  }

  setOffsetY(v) {
    const o = this._getOffset(true);

    if (!o) {
      this._offsetY = v;

      return;
    }

    o.y = parseNum(v, 0);
  }

  get offsetX() {
    return this.getOffsetX();
  }

  set offsetX(v) {
    this.setOffsetX(v);
  }

  get offsetY() {
    return this.getOffsetY();
  }

  set offsetY(v) {
    this.setOffsetY(v);
  }

  getResultRotationRads(singlePart) {
    return this.getResultRotation(singlePart) * DEG2RAD;
  }

  getResultRotation(singlePart) {
    const sp = singlePart || this.getSinglePart();
    const configData = this.getConfigData();
    const data = this.getData();
    const dataRotation = this._getRotation(null, data, sp, this._rotation || 0) || 0;
    const configRotation = this._getRotation(configData, null, sp, 0) || 0;

    return dataRotation + configRotation;
  }

  getRotationRads(singlePart) {
    return this.getRotation(singlePart) * DEG2RAD;
  }

  _getRotation(config, data, singlePart, fallback) {
    if (config) {
      if (typeof (config.rotation) === 'number') {
        return config.rotation;
      }
    }
    if (data) {
      if (typeof (data.rotation) === 'number') {
        return data.rotation;
      }
    }

    return fallback;
  }

  // Rotation from mattress config
  getDataRotation(data, singlePart, fallback) {
    return this._getRotation(null, data, singlePart, fallback) || 0;
  }

  // Rotation from sample config
  getConfigRotation(config, singlePart, fallback) {
    return this._getRotation(config, null, singlePart, fallback) || 0;
  }

  getRotation(singlePart) {
    const sp = singlePart || this.getSinglePart();

    return this._getRotation(this.getConfigData(), this.getData(), sp, this._rotation || 0);
  }

  setConfigRotation(v) {
    const d = this.getConfigData();

    if (!d) {
      this._rotation = v;

      return;
    }

    d.rotation = Math.round(parseNum(v, 0));
  }

  get rotation() {
    this.getRotation();
  }

  set configRotation(v) {
    this.setConfigRotation(v);
  }

  get configRotation() {
    return this.getConfigRotation();
  }

  rotate(v) {
    if (v === 0) {
      return;
    }
    const d = this.getConfigData();

    if (!d) {
      if (!this._rotation) {
        this._rotation = 0;
      }
      this._rotation += v;

      return;
    }

    if (typeof (d.rotation) !== 'number' || isNaN(d.rotation)) {
      d.rotation = 0;
    }
    d.rotation += v;
  }

  translate(x, y) {
    if (x === 0 && y === 0) {
      return;
    }

    const d = this.getConfigData();

    if (!d) {
      return;
    }

    let off = d.offset;

    if (!off) {
      off = d.offset = {};
    }
    if (x !== 0) {
      if (typeof (off.x) !== 'number' || isNaN(off.x)) {
        off.x = 0;
      }
      off.x += x;
    }
    if (y !== 0) {
      if (typeof (off.y) !== 'number' || isNaN(off.y)) {
        off.y = 0;
      }
      off.y += y;
    }
  }

  translateX(v) {
    this.translate(v, 0);
  }

  translateY(v) {
    this.translate(0, v);
  }

  _getMatrix() {
    const d = this.getConfigData();

    if (!d) {
      return null;
    }

    return d.transformMatrix || d.matrix || d.transform;
  }

  getGeometryUVData() {
    return this.uvData;
  }

  setGeometryUVData(data) {
    this.uvData = data;
  }

  // Min U value of the geometry uvs in model space
  getGeometryMinU() {
    const uvData = this.getGeometryUVData();

    if (!uvData) {
      return 0;
    }

    return parseNum(uvData.minU, 0);
  }

  // Min V value of the geometry uvs in model space
  getGeometryMinV() {
    const uvData = this.getGeometryUVData();

    if (!uvData) {
      return 0;
    }

    return parseNum(uvData.minV, 0);
  }

  // Max U value of the geometry uvs in model space
  getGeometryMaxU() {
    const uvData = this.getGeometryUVData();

    if (!uvData) {
      return 1;
    }

    return parseNum(uvData.maxU, 1);
  }

  // Max V value of the geometry uvs in model space
  getGeometryMaxV() {
    const uvData = this.getGeometryUVData();

    if (!uvData) {
      return 1;
    }

    return parseNum(uvData.maxV, 1);
  }

  // How much the uv's of the source geometry should scale to get the coordinates in model space
  getGeometryScaleU() {
    const uvData = this.getGeometryUVData();

    if (!uvData) {
      return 1;
    }

    return parseNum(uvData.scaleU, 1);
  }

  // How much the uv's of the source geometry should scale to get the coordinates in model space
  getGeometryScaleV() {
    const uvData = this.getGeometryUVData();

    if (!uvData) {
      return 1;
    }

    return parseNum(uvData.scaleV, 1);
  }

  // How much the uv's of the source geometry should translate in the U direction to get the coordinates in model space
  getGeometryTranslateU() {
    const uvData = this.getGeometryUVData();

    if (!uvData) {
      return 0;
    }

    return parseNum(uvData.translateU, 0);
  }

  // How much the uv's of the source geometry should translate in the V direction to get the coordinates in model space
  getGeometryTranslateV() {
    const uvData = this.getGeometryUVData();

    if (!uvData) {
      return 0;
    }

    return parseNum(uvData.translateV, 0);
  }

  setScale(x, y) {
    this._scaleX = x;
    this._scaleY = y;
  }

  getScaleX() {
    return this._scaleX;
  }

  setScaleX(v) {
    this._scaleX = v;
  }

  getScaleY() {
    return this._scaleY;
  }

  setScaleY(v) {
    this._scaleY = v;
  }

  get scaleX() {
    return this.getScaleX();
  }

  set scaleX(s) {
    this.setScaleX(s);
  }

  get scaleY() {
    return this.getScaleY();
  }

  set scaleY(v) {
    this.setScaleY(v);
  }

  getPivotX() {
    // #if DEBUG
    BD3DLogger.warn('getPivotX not implemented');
    // #endif

    return DEFAULT_PIVOT_X;
  }

  getPivotY() {
    // #if DEBUG
    BD3DLogger.warn('getPivotY not implemented');
    // #endif

    return DEFAULT_PIVOT_Y;
  }

  getLocalAlignX() {
    return DEFAULT_LOCAL_ALIGN_X;
  }

  getLocalAlignY() {
    return DEFAULT_LOCAL_ALIGN_Y;
  }

  getAlignX() {
    // #if DEBUG
    BD3DLogger.warn('getAlignX not implemented');
    // #endif

    return DEFAULT_ALIGN_X;
  }

  setAlignX(v) {
    const qcd = this.getConfigData();

    if (!qcd) {
      this.alignX = v;

      return;
    }
    if (!qcd.align) {
      qcd.align = {};
    }
    qcd.align.x = v;
  }

  getAlignY() {
    // #if DEBUG
    BD3DLogger.warn('getAlignY not implemented');
    // #endif

    return DEFAULT_ALIGN_Y;
  }

  setAlignY(v) {
    const qcd = this.getConfigData();

    if (!qcd) {
      this.alignY = v;

      return;
    }
    if (!qcd.align) {
      qcd.align = {};
    }
    qcd.align.y = v;
  }

  setAlign(x, y) {
    const qcd = this.getConfigData();

    if (!qcd) {
      this.alignX = x;
      this.alignY = y;

      return;
    }
    if (!qcd.align) {
      qcd.align = {};
    }
    qcd.align.x = x;
    qcd.align.y = y;
  }

  getRepeatX() {
    return 1;
  }

  getRepeatY() {
    return 1;
  }

  /**
   * @method setSourceAspectRatio
   * @description Stores the image aspect ratio of the source image to this class to correct the repeat size if needed
   * Usage:
   *  setSourceAspectRatio(width, height) => aspect ratio will be width / height
   *  setSourceAspectRatio(aspectRatio) => aspect ratio will be <aspectRatio>
   *  setSourceAspectRatio(ImageAsset) => aspect ratio will be imageAsset.getWidth() / imageAsset.getHeight();
   * @param {Number|ImageAsset} w - Width of the source image, precalculated aspect ratio or ImageAsset instance
   * @param {Number} h - Optional height param if the width param is used
   * @return {void}
   * */
  setSourceAspectRatio(w, h) {
    const tw = typeof (w);
    const th = typeof (h);
    let res = null;

    if (tw === 'number' && th === 'number') {
      res = h > 0 ? w / h : 0;
    } else if (tw === 'number' && th !== 'number') {
      res = w;
    } else if (w instanceof ImageAsset) {
      const imgW = w.getWidth();
      const imgH = w.getHeight();

      if (typeof (imgW) === 'number' && typeof (imgH) === 'number' && imgH > 0) {
        res = imgW / imgH;
      }
    }
    this.sourceAspectRatio = res;
  }

  applyMatrix4(matrix) {
    const scaleU = this.getGeometryScaleU();
    const scaleV = this.getGeometryScaleV();

    const trU = this.getGeometryTranslateU();
    const trV = this.getGeometryTranslateV();
    const minU = this.getGeometryMinU();
    const minV = this.getGeometryMinV();
    const maxU = this.getGeometryMaxU();
    const maxV = this.getGeometryMaxV();

    const scaleX = this.getScaleX();
    const scaleY = this.getScaleY();

    let sizeMultiplier = 1.0;
    const sourceSizeUnit = this.getSourceSizeUnit();

    if (sourceSizeUnit === 'mm') {
      sizeMultiplier = MM_TO_CM;
    }
    let realWidth = this.getRealWidth();
    let realHeight = this.getRealHeight();

    if (typeof (realWidth) !== 'number' || isNaN(realWidth)) {
      realWidth = 1.0;
    }
    if (typeof (realHeight) !== 'number' || isNaN(realHeight)) {
      realHeight = 1.0;
    }

    realWidth *= sizeMultiplier;
    realHeight *= sizeMultiplier;

    const srcAR = this.sourceAspectRatio;

    if (typeof (srcAR) === 'number' && srcAR > 0) {
      const srcIsPortrait = srcAR < 1;
      const curIsPortrait = realWidth < realHeight;

      if (curIsPortrait !== srcIsPortrait) {
        // swap realWidth & realHeight;
        const tempRealWidth = realWidth;

        realWidth = realHeight;
        realHeight = tempRealWidth;

        // #if DEBUG
        console.warn('Image aspect ratio correction: width=', realWidth, 'height=', realHeight, 'backend data=', this.getData(), 'config data=', this.getConfigData());
        // #endif
      }
    }

    const fabricW = realWidth * scaleX;
    const fabricH = realHeight * scaleY;

    const pivotX = this.getPivotX();
    const pivotY = this.getPivotY();

    const alignX = this.getAlignX();
    const alignY = this.getAlignY();

    const outerAlignX = alignX;
    const outerAlignY = alignY;
    // const innerAlignX = 0.5;
    // const innerAlignY = 0.5;

    const innerAlignX = this.getLocalAlignX();
    const innerAlignY = this.getLocalAlignY();

    const offsetX = this.getOffsetX() + this.translateX;
    const offsetY = this.getOffsetY() + this.translateY;

    // Causes some more offset (currently only for quilt patterns, default = 1)
    const repeatX = this.getRepeatX();
    const repeatY = this.getRepeatY();

    // total fabric size if repeated
    const fabricRepeatW = fabricW * repeatX;
    const fabricRepeatH = fabricH * repeatY;

    let mtx = matrix;

    mtx = scaleMatrix(scaleU, scaleV, mtx);

    // translate offset
    mtx = translateMatrix(offsetX * scaleU, offsetY * scaleV, mtx);
    mtx = translateMatrix(-fabricRepeatW * scaleU * pivotX, -fabricRepeatH * scaleV * pivotY, mtx);
    mtx = rotateMatrixZ(this.getResultRotationRads(), mtx);
    mtx = translateMatrix(fabricRepeatW * scaleU * pivotX, fabricRepeatH * scaleV * pivotY, mtx);

    mtx = translateMatrix(trU, trV, mtx);
    mtx = scaleMatrix(fabricW, fabricH, mtx);
    mtx = translateMatrix(
      (minU + (maxU - minU) * outerAlignX - fabricRepeatW * innerAlignX) * scaleU,
      (minV + (maxV - minV) * outerAlignY - fabricRepeatH * innerAlignY) * scaleV,
      mtx);

    const geomScaleX = this.getGeometryScaleX();
    const geomScaleY = this.getGeometryScaleY();

    mtx = getMatrixInverse(mtx, mtx);
    mtx = scaleMatrix(geomScaleX, geomScaleY, mtx);

    return mtx;
  }

  getConfigData() {
    return this._configData;
  }

  setConfigData(scd) {
    this._configData = scd;
  }

  get configData() {
    return this.getConfigData();
  }

  set configData(cd) {
    this.setConfigData(cd);
  }

  getData() {
    return this._data;
  }

  setData(d) {
    this._data = d;
  }

  get data() {
    return this.getData();
  }

  set data(v) {
    this.setData(v);
  }
}
