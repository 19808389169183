export default class AnimEvent {
  constructor(type, animator = null, animation = null, deltaTime = 0, deltaTimeMs = 0) {
    /**
     * Event type
     * @type {String}
     */
    this.type = type;
    /**
     * Animator instance
     * @type {Animator}
     */
    this.animator = animator;
    /**
    * Animation
    * @type {Animation}
    */
    this.animation = animation;
    /**
     * delta time in seconds
     * @type {Number}
     */
    this.deltaTime = deltaTime;

    /**
     * delta time in milliseconds
     * @type {int}
     */
    this.deltaTimeMs = deltaTimeMs;
  }
}
AnimEvent.ANIMATE = 'animate';
