import ControllerPlugin from './ControllerPlugin';
import MattressDA from '../mattress/MattressDA';
import ArrayUtils from '../../bgr/common/utils/ArrayUtils';
// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
// #endif

const propertyMap = {
  width: {type: 'number'},
  length: {type: 'number'},
  x: {type: 'number'},
  y: {type: 'number'},
  z: {type: 'number'}
};

function toMap(array, key, result = null) {
  if (!array) {
    return result;
  }
  const res = result || {};

  if (!key) {
    return res;
  }
  const l = array.length;

  if (l === 0) {
    return res;
  }
  for (let i = 0; i < l; ++i) {
    const obj = array[i];

    if (obj[key]) {
      res[obj[key]] = obj;
    }
  }

  return res;
}

export default class BoxSpringControllerPlugin extends ControllerPlugin {
  constructor(args) {
    super(args);

    let data = null;
    let mattressConfig = null;

    if (args) {
      data = args.data;
      mattressConfig = args.mattressConfig;
    }

    this._data = data;
    this._mattressConfig = mattressConfig;
    this._propertiesMap = toMap(data.properties, 'name');
    this._singleMap = null;
    this._singleById = null;

    this._updateLayout(false);
  }

  getTypeName() {
    return 'BoxSpringControllerPlugin';
  }

  dispose() {
    super.dispose();
    this._singleById = null;
    this._controller = null;
    this._propertiesMap = null;
    this._data = null;
    this._singleMap = null;
  }

  _updateEnabled() {
    return;
  }

  getName() {
    const data = this._data;

    if (!data) {
      return null;
    }

    return data.name || null;
  }

  get name() {
    return this.getName();
  }

  getWidth() {
    return this.getProperty('width', 0);
  }

  setWidth(w) {
    this.setProperty('width', w);
  }

  getLength() {
    return this.getProperty('length', 0);
  }

  setLength(l) {
    this.setProperty('length', l);
  }

  setSize(w, h) {
    const oldW = this.getWidth();
    const oldH = this.getHeight();

    if (w === oldW && h === oldH) {
      return;
    }
    this._setProperty('width', w);
    this._setProperty('height', h);
    this._refresh();
  }

  getHeadBoardExtend() {
    return this.getProperty('headBoardExtend', 0);
  }

  setHeadBoardExtend(value) {
    return this.setProperty('headBoardExtend', value);
  }

  getTopperExtend() {
    return this.getProperty('topperExtend', 0);
  }

  setTopperExtend(value) {
    return this.setProperty('topperExtend', value);
  }

  getTopperExtendSides(fallback = 0) {
    return this.getProperty('topperExtendSides', fallback);
  }

  setTopperExtendSides(value) {
    return this.setProperty('topperExtendSides', value);
  }

  getTopperExtendFrontBack(fallback = 0) {
    return this.getProperty('topperExtendFrontBack', fallback);
  }

  setTopperExtendFrontBack(value) {
    return this.setProperty('topperExtendFrontBack', value);
  }

  getTopperExtendFront(fallback = 0) {
    return this.getProperty('topperExtendFront', fallback);
  }

  setTopperExtendFront(value) {
    return this.setProperty('topperExtendFront', value);
  }

  getTopperExtendBack(fallback = 0) {
    return this.getProperty('topperExtendBack', fallback);
  }

  setTopperExtendBack(value) {
    return this.setProperty('topperExtendBack', value);
  }

  getMattressExtend() {
    return this.getProperty('mattressExtend', 0);
  }

  setMattressExtend(value) {
    return this.setProperty('mattressExtend', value);
  }

  getMattressExtendX(fallback = 0) {
    return this.getProperty('mattressExtendX', fallback);
  }

  setMattressExtendX(value) {
    return this.setProperty('mattressExtendX', value);
  }

  getMattressExtendZ(fallback = 0) {
    return this.getProperty('mattressExtendZ', fallback);
  }

  setMattressExtendZ(value) {
    return this.setProperty('mattressExtendZ', value);
  }

  getFoundationExtend() {
    let res = this.getProperty('foundationExtend', null);

    if (res === null) {
      res = this.getProperty('bedBaseExtend', 0);
    }

    return res;
  }

  getFoundationExtendBack() {
    let res = this.getProperty('foundationExtendBack', null);

    if (res === null) {
      res = this.getProperty('bedBaseExtendBack', null);
    }
    if (res === null) {
      res = this.getFoundationExtend();
    }

    return res;
  }

  getFoundationExtendFront() {
    let res = this.getProperty('foundationExtendFront', null);

    if (res === null) {
      res = this.getProperty('bedBaseExtendFront', null);
    }
    if (res === null) {
      res = this.getFoundationExtend();
    }

    return res;
  }

  setFoundationExtend(value) {
    return this.setProperty('foundationExtend', value);
  }

  getX() {
    return this.getProperty('x', 0);
  }

  setX(v) {
    this.setProperty('x', v);
  }

  getY() {
    return this.getProperty('y', 0);
  }

  setY(v) {
    this.setProperty('y', v);
  }

  getZ() {
    return this.getProperty('z', 0);
  }

  setZ(v) {
    this.setProperty('z', v);
  }

  get width() {
    return this.getWidth();
  }

  set width(w) {
    this.setWidth(w);
  }

  get length() {
    return this.getLength();
  }

  set length(h) {
    this.setLength(h);
  }

  get x() {
    return this.getX();
  }

  set x(v) {
    this.setX(v);
  }

  get y() {
    return this.getY();
  }

  set y(v) {
    this.setY(v);
  }

  get z() {
    return this.getZ();
  }

  set z(v) {
    this.setZ(v);
  }

  _refresh() {
    if (this._controller) {
      this._controller.refresh();
    }
  }

  setProperties(props) {
    if (!props) {
      return;
    }
    if (typeof (props) !== 'object') {
      return;
    }
    let changed = false;

    if ((props instanceof Array) || (Array.isArray && Array.isArray(props))) {
      const l = props.length;

      for (let i = 0; i < l; ++i) {
        const p = props[i];

        if (p) {
          const old = this.getProperty(p.name);

          if (old !== p.value) {
            this._setProperty(p.name, p.value);
            changed = true;
          }
        }
      }
    } else {
      for (const v in props) {
        if (props.hasOwnProperty(v)) {
          const old = this.getProperty(v);
          const val = props[v];

          if (old !== val) {
            this._setProperty(v, props[v]);
            changed = true;
          }
        }
      }
    }
    if (changed) {
      this._updateLayout();
    }
  }

  _setProperty(name, value) {
    if (!name) {
      // #if DEBUG
      BD3DLogger.warn('BoxSpringControllerPlugin::Could not set property with invalid name:', name);
      // #endif

      return;
    }
    const info = propertyMap[name];

    if (!info) {
      // #if DEBUG
      BD3DLogger.warn('BoxSpringControllerPlugin::Could not set invalid property:', name);
      // #endif

      return;
    }
    if (info.type) {
      if (typeof (info.type) === 'string') {

        if (typeof (value) !== info.type) {
          // #if DEBUG
          const t = typeof (value);

          BD3DLogger.warn('BoxSpringControllerPlugin::Can\'t set property ', name, '( type = ', info.type, ') - Invalid type ( ', t, ' )');
          // #endif

          return;
        }
      } else if (!(value instanceof info.type)) {
        // #if DEBUG
        BD3DLogger.warn('BoxSpringControllerPlugin::Can\'t set property ', name, '(type=', info.type, ') - Invalid type (', value.constructor, ')');
        // #endif

        return;
      }
    }
    const data = this._data;

    if (data) {
      let props = data.properties;
      let propsMap = this._propertiesMap;

      if (!props) {
        props = data.properties = [];
      }
      if (!propsMap) {
        propsMap = this._propertiesMap = {};
      }
      let prop = propsMap[name];

      if (!prop) {
        prop = {name: name, visible: false, value: value};
        propsMap[name] = prop;
        if (props.indexOf(prop, 0) < 0) {
          props.push(prop);
        }
      }
      prop.value = value;
    }
  }

  getProperty(name, fallback = 0) {
    const pr = this._propertiesMap;

    if (!pr) {
      return fallback;
    }
    const data = pr[name];
    let t = typeof (data);

    if (data === null || t === 'undefined') {
      return fallback;
    }
    if (t === 'number') {
      return data;
    }
    t = typeof (data.value);
    if (t === 'number') {
      return data.value;
    }

    return fallback;
  }

  setProperty(name, value) {
    const old = this.getProperty(name);

    if (old === value) {
      return;
    }
    this._setProperty(name, value);
    this._updateLayout();
  }

  _getSingleId(single) {
    if (!single) {
      return null;
    }

    return single['@ID'] || single['@id'] || single.id;
  }

  _getSingleById(id) {
    if (!id) {
      return null;
    }
    let mcfg = this._mattressConfig;

    let singleById = this._singleById;
    const res = singleById && singleById[id];

    if (res) {
      return res;
    }


    if (mcfg.getData) {
      mcfg = mcfg.getData();
    }
    const singles = mcfg && mcfg.singles;
    const numSingles = singles ? singles.length : 0;

    if (!numSingles) {
      return null;
    }

    for (let i = 0; i < numSingles; ++i) {
      const single = singles[i];
      const sId = this._getSingleId(single);

      if (id) {
        if (!singleById) {
          singleById = {};
          this._singleById = singleById;
        }
        singleById[sId] = single;
      }
    }

    return singleById[id];
  }

  _addSingleToMap(single, contrParams, map) {
    const res = map;

    if (!single) {
      return res;
    }
    const headBoardID = contrParams.headboard || contrParams.backpanel;
    const mattressID = contrParams.top || contrParams.toppanel || contrParams.topPanel || contrParams.mattress;
    const foundationID = contrParams.foundation || contrParams.bedbase;
    const foundationLeftID = contrParams.foundationLeft || contrParams.bedbaseLeft;
    const foundationRightID = contrParams.foundationRight || contrParams.bedbaseRight;
    const topperID = contrParams.topper;

    const singleID = this._getSingleId(single);

    if (singleID) {
      const y = MattressDA.getTranslationY(single);

      if (singleID === headBoardID) {
        res.headboard = {single: single, y: y};
      } else if (singleID === mattressID) {
        res.mattress = {single: single, y: y};
      } else if (singleID === foundationID) {
        res.foundation = {single: single, y: y};
      } else if (singleID === foundationLeftID) {
        res.foundationLeft = {single: single, y: y};
      } else if (singleID === foundationRightID) {
        res.foundationRight = {single: single, y: y};
      } else if (singleID === topperID) {
        res.topper = {single: single, y: y};
      }
    }

    return res;
  }

  containsSingle(single) {
    const m = this._singleMap;

    if (!m) {
      return false;
    }
    for (const v in m) {
      if (m.hasOwnProperty(v)) {
        const object = m[v];
        const objectSingle = object ? object.single : null;

        if (objectSingle === single) {
          return true;
        }
      }
    }

    return false;
  }

  _getSingleData(name) {
    if (!name) {
      return null;
    }
    const m = this._singleMap;

    if (!m) {
      return null;
    }

    return m[name];
  }

  _getSingleOfSingleData(singleData) {
    if (!singleData) {
      return null;
    }

    return singleData.single;
  }

  _getSingle(name) {
    return this._getSingleOfSingleData(this._getSingleData(name)) || null;
  }


  _getMattressData() {
    return this._getSingleData('mattress');
  }

  getMattress() {
    return this._getSingleOfSingleData(this._getMattressData());
  }


  get mattress() {
    return this.getMattress();
  }

  _getTopperData() {
    return this._getSingleData('topper');
  }

  getTopper() {
    return this._getSingleOfSingleData(this._getTopperData());
  }


  get topper() {
    return this.getTopper();
  }

  _getHeadboardData() {
    return this._getSingleData('headboard');
  }

  getHeadboard() {
    return this._getSingleOfSingleData(this._getHeadboardData());
  }

  get headboard() {
    return this.getHeadBoard();
  }

  _getFoundationData() {
    const smap = this._getSingleMap();

    return smap && (smap.foundation || smap.bedbase);
  }

  getFoundation() {
    return this._getSingleOfSingleData(this._getFoundationData());
  }

  get foundation() {
    return this.getFoundation();
  }

  _getFoundationLeftData() {
    const smap = this._getSingleMap();

    return smap && (smap.foundationLeft || smap.bedbaseLeft || smap.foundationleft || smap.bedbaseleft);
  }

  getFoundationLeft() {
    return this._getSingleOfSingleData(this._getFoundationLeftData());
  }

  get foundationLeft() {
    return this.getFoundationLeft();
  }

  _getFoundationRightData() {
    const smap = this._getSingleMap();

    return smap && (smap.foundationRight || smap.bedbaseRight || smap.foundationright || smap.bedbaseright);
  }

  getFoundationRight() {
    return this._getSingleOfSingleData(this._getFoundationRightData());
  }

  get foundationRight() {
    return this.getFoundationRight();
  }

  _getSingleAt(index) {
    let mcfg = this._mattressConfig;

    if (!mcfg) {
      return null;
    }

    if (mcfg.getData) {
      mcfg = mcfg.getData();
    }
    if (!mcfg) {
      return null;
    }
    const {singles} = mcfg;
    const numSingles = singles ? singles.length : 0;

    if (!numSingles || index >= numSingles || index < 0) {
      return null;
    }

    return singles[index];
  }

  _getSingleMap(update) {
    let res = this._singleMap;

    if (!res || update) {
      if (!res) {
        res = this._singleMap = {};
      }
      let mcfg = this._mattressConfig;

      if (mcfg.getData) {
        mcfg = mcfg.getData();
      }

      if (mcfg) {
        const contrData = this._data;
        const contrParams = contrData ? contrData.params : null;

        const singles = mcfg ? mcfg.singles : null;
        const numSingles = singles ? singles.length : 0;

        for (let i = 0; i < numSingles; ++i) {
          const single = singles[i];

          this._addSingleToMap(single, contrParams, res);
        }
      }
    }

    return res;
  }

  getTopMostSingle() {
    const stack = this._getStack();
    const stackCount = stack ? stack.length : 0;
    let result = null;

    if (stackCount > 0) {
      const lastId = stack[stackCount - 1];

      if (lastId) {
        result = this._getSingleById(lastId);
      }
    }

    return result || this.getTopper() || this.getMattress();
  }

  get topMostSingle() {
    return this.getTopMostSingle();
  }

  isStackedSingle(single) {
    const stack = this._getStack();
    const stackCount = stack ? stack.length : 0;
    const singleID = typeof (single) === 'string' ? single : this._getSingleId(single);

    for (let i = 0; i < stackCount; ++i) {
      const item = stack[i];
      const itemID = typeof (item) === 'string' ? item : this._getSingleId(item);

      if (item === single || itemID === singleID) {
        return true;
      }
    }

    return false;
  }

  _getStack() {
    const contrData = this._data;
    const contrParams = contrData && contrData.params;
    const stack = contrParams && contrParams.stack;

    return stack;
  }

  _shouldUseBoxspringStack() {
    const contrData = this._data;
    const contrParams = contrData && contrData.params;

    return (contrParams && contrParams.layoutBoxspringStack) !== false;
  }

  // Updates the mattress stack in default order if no stack is defined
  // (from bottom to top: bedbase/foundation -> mattress -> topper)
  _updateBoxspringStack() {
    const layoutBoxspringStack = this._shouldUseBoxspringStack();

    if (!layoutBoxspringStack) {
      // use param layoutBoxspringStack:false to disable layouting the stack
      return false;
    }
    const mattress = this.getMattress();
    const foundation = this.getFoundation();
    const foundationLeft = this.getFoundationLeft();
    const foundationRight = this.getFoundationRight();
    const topper = this.getTopper();

    let single = null;
    let res = false;
    let y = this.getProperty('y', 0);
    let resFoundationHeight = 0;

    single = foundation;
    if (single) {
      MattressDA.setTranslationY(single, y);
      const foundationHeight = MattressDA.getResultHeightWithLegs(single);

      resFoundationHeight = foundationHeight > resFoundationHeight ? foundationHeight : resFoundationHeight;
      res = true;
    }

    single = foundationLeft;
    if (single) {
      MattressDA.setTranslationY(single, y);
      const foundationHeight = MattressDA.getResultHeightWithLegs(single);

      resFoundationHeight = foundationHeight > resFoundationHeight ? foundationHeight : resFoundationHeight;
      res = true;
    }

    single = foundationRight;
    if (single) {
      MattressDA.setTranslationY(single, y);
      const foundationHeight = MattressDA.getResultHeightWithLegs(single);

      resFoundationHeight = foundationHeight > resFoundationHeight ? foundationHeight : resFoundationHeight;
      res = true;
    }
    y += resFoundationHeight;

    single = mattress;
    if (single) {
      MattressDA.setTranslationY(single, y);
      y += MattressDA.getResultHeightWithLegs(single);
      res = true;
    }

    single = topper;
    if (single) {
      MattressDA.setTranslationY(single, y);
      y += MattressDA.getResultHeightWithLegs(single);
      res = true;
    }

    return res;
  }

  _updateStack() {
    const stack = this._getStack();

    if (!stack) {
      return this._updateBoxspringStack();
    }
    const num = stack ? stack.length : 0;

    if (!num) {
      return false;
    }
    let res = false;
    let y = this.getProperty('y', 0);

    for (let i = 0; i < num; ++i) {
      const id = stack[i];
      const single = this._getSingleById(id);

      if (single) {
        MattressDA.setTranslationY(single, y);
        y += MattressDA.getResultHeightWithLegs(single);
        res = true;
      }
    }

    return res;
  }

  _singleAffectsBoxspringStack(single) {
    if (!single) {
      return false;
    }
    const layoutBoxspringStack = this._shouldUseBoxspringStack();

    if (!layoutBoxspringStack) {
      return false;
    }
    const singleType = typeof (single);
    let singleObject = single;

    if (singleType === 'number') {
      singleObject = this._getSingleAt(single);
    } else if (singleType === 'string') {
      singleObject = this._getSingleById(single);
    }
    if (!singleObject) {
      return false;
    }

    return singleObject === this.getMattress() ||
      singleObject === this.getFoundation() ||
      singleObject === this.getFoundationLeft() ||
      singleObject === this.getFoundationRight() ||
      singleObject === this.getTopper();
  }

  _singleAffectsStack(single) {
    const stack = this._getStack();

    if (!stack) {
      return this._singleAffectsBoxspringStack(single);
    }
    const stackCount = stack ? stack.length : 0;

    if (!stackCount) {
      return false;
    }
    let singleObject = single;
    let singleType = typeof (single);
    let singleID = single;

    if (singleType === 'number') {
      singleObject = this._getSingleAt(single);
      singleType = typeof (singleObject);
    }
    if (singleObject && singleType === 'object') {
      singleID = this._getSingleId(singleObject);
    }

    if (!singleID) {
      return null;
    }
    const index = ArrayUtils.findIndex(stack, singleID, null, 0);

    return index >= 0 && index < (stackCount - 1);
  }

  notifyPropertyChanged(single, property, value, oldValue, params, mattressConfig) {
    if (!property) {
      return;
    }
    const propertyname = property.property;

    if (
      (
        propertyname === 'top_border_height' ||
        propertyname === 'bottom_border_height' ||
        propertyname === 'bordercomponent-height' ||
        propertyname === 'add-bordercomponent' ||
        propertyname === 'remove-bordercomponent' ||
        propertyname === 'leg_height'
      ) &&
      this._singleAffectsStack(single)
    ) {
      this._updateStack();
    }
  }

  _updateLayout(shouldRefresh = true) {
    const width = this.getProperty('width', 0);
    const length = this.getProperty('length', 0);
    const x = this.getProperty('x', 0);
    const y = this.getProperty('y', 0);
    const z = this.getProperty('z', 0);

    let canRefresh = false;

    if (width > 0 && length > 0) {
      const headBoardExtend = this.getHeadBoardExtend();
      const foundationExtend = this.getFoundationExtend();
      const foundationExtendBack = this.getFoundationExtendBack();
      const foundationExtendFront = this.getFoundationExtendFront();

      const mattressExtend = this.getMattressExtend();
      const mattressExtendX = this.getMattressExtendX(mattressExtend);
      const mattressExtendZ = this.getMattressExtendZ(mattressExtend);

      const smap = this._getSingleMap();

      if (smap) {
        const headboard = this._getHeadboardData();
        const mattress = this._getMattressData();
        const foundation = this._getFoundationData();
        const foundationLeft = this._getFoundationLeftData();
        const foundationRight = this._getFoundationRightData();
        const topper = this._getTopperData();

        const generalWidth = width;

        const headboardWidth = width + (headBoardExtend * 2);
        const foundationWidth = width + (foundationExtend * 2);

        const hFoundationWidth = foundationWidth * 0.5;
        const headBoardHeight = headboard ? MattressDA.getResultHeight(headboard.single) : 0;
        const generalLength = length;// - headBoardHeight * 2;
        const generalZ = z + headBoardHeight * 0.5;

        const foundationLength = generalLength + foundationExtendFront + foundationExtendBack;
        const foundationZ = generalZ + foundationExtendFront * 0.5 - foundationExtendBack * 0.5;

        if (headboard) {
          const single = headboard.single;

          MattressDA.setWidth(single, headboardWidth);
          MattressDA.setTranslation(single, x, headboard.y + y, z - length * 0.5 - headBoardHeight * 0.5 - foundationExtendBack);
        }

        if (mattress) {
          const mattressLength = generalLength + mattressExtendZ;// - headBoardHeight * 2;
          const mattressZ = generalZ + mattressExtendZ;
          const mattressWidth = generalWidth + (mattressExtendX * 2);

          const single = mattress.single;
          const ypos = mattress.y + y;

          MattressDA.setWidth(single, mattressWidth);
          MattressDA.setLength(single, mattressLength);
          MattressDA.setTranslation(single, x, ypos, mattressZ);
        }
        if (topper) {
          const topperExtend = this.getTopperExtend();
          const topperExtendSides = this.getTopperExtendSides(topperExtend);
          const topperExtendFrontBack = this.getTopperExtendFrontBack(topperExtend);
          const topperExtendFront = this.getTopperExtendFront(topperExtendFrontBack);
          const topperExtendBack = this.getTopperExtendBack(topperExtendFrontBack);


          const single = topper.single;
          const ypos = topper.y + y;
          const topperWidth = generalWidth + (topperExtendSides * 2);
          const topperLength = generalLength + (topperExtendBack + topperExtendFront);

          const topperZ = generalZ - (topperExtendBack * 0.5) + (topperExtendFront * 0.5);

          MattressDA.setWidth(single, topperWidth);
          MattressDA.setLength(single, topperLength);
          MattressDA.setTranslation(single, x, ypos, topperZ);
        }
        if (foundation) {
          const single = foundation.single;

          MattressDA.setWidth(single, foundationWidth);
          MattressDA.setLength(single, foundationLength);
          MattressDA.setTranslation(single, x, y, foundationZ);
        }
        if (foundationLeft) {
          const single = foundationLeft.single;

          MattressDA.setWidth(single, hFoundationWidth);
          MattressDA.setLength(single, foundationLength);
          MattressDA.setTranslation(single, x - hFoundationWidth * 0.5, y, foundationZ);
        }
        if (foundationRight) {
          const single = foundationRight.single;

          MattressDA.setWidth(single, hFoundationWidth);
          MattressDA.setLength(single, foundationLength);
          MattressDA.setTranslation(single, x + hFoundationWidth * 0.5, y, foundationZ);
        }
        canRefresh = true;
      }
    }
    canRefresh = this._updateStack() || canRefresh;
    if (shouldRefresh && canRefresh) {
      this._refresh();
    }
  }
}
