import MattressDA from '../mattress/MattressDA';
import BD3DContainerNode3D from '../scenegraph/BD3DContainerNode3D';
// import BD3DGeometryNode3D from '../scenegraph/BD3DGeometryNode3D';
import ContainerNode3D from '../../bgr/bgr3d/scenegraph/ContainerNode3D';
import GeometryNode3D from '../../bgr/bgr3d/scenegraph/GeometryNode3D';
import Node3DMaterialUtils from '../material/Node3DMaterialUtils';

export default class HandleType {
  getTypeName() {
    const c = this.constructor;

    if (c && c.getTypeName()) {
      return c.getTypeName();
    }

    return null;
  }

  get typeName() {
    return this.getTypeName();
  }

  isAllowedOnBorder(handleData, mattressData, borderData, errorInfo = null) {
    if (!handleData || !mattressData) {
      return false;
    }

    return true;
  }

  /**
   * @method isAllowed
   * @description This method is used to decide if a mattress is allowed to have this type of handle
   * @param {Object} handleData - json object representing the handle
   * @param {Object} mattressData - json object representing a single mattress
   * @param {Object} errorInfo - optional object containing an error if not allowed
   * @returns {Boolean} - true if this type of handle is allowed on the mattress
   * */
  isAllowed(handleData, mattressData, errorInfo = null) {
    if (!handleData || !mattressData) {
      return false;
    }

    return true;
  }

  /**
   * @method create3DHandles
   * @description creates a node containing the 3D geometry of the mattress handles
   * @param {Object} data - data
   * @param {Array} borderElements - border elements
   * @param {Object} mattressData - json object representing a single mattress
   * @param {BorderShape} borderShape - shape of the border
   * @param {Graph} borderCurveGraph - curved border
   * @param {Object} buildParams - extra build parameters
   * @param {Node} resultNode - optional preallocated Node3D instance that should be used as return value
   * @returns {Node} - node containing 3D geometry
   * */
  create3DHandles(data, borderElements, mattressData, borderShape, borderCurveGraph, buildParams, resultNode = null) {
    return resultNode;
  }

  addAssets(data, handleStyle, params, array = null, assetManager = null) {
    return array;
  }

  _getStyleID(data) {
    return MattressDA.getHandleStyleID(data);
  }

  // Group all 3d nodes with the same material name to a shared 3d container node
  _groupNodesPerMaterial(node, resultContainer) {
    if (!node) {
      return resultContainer;
    }
    let resCont = resultContainer;

    if (resCont) {
      resCont.removeChildren();
    } else {
      resCont = new BD3DContainerNode3D();
    }

    let findGeomNodesCb = this._findGeomNodesCallback;

    if (!findGeomNodesCb) {
      findGeomNodesCb = this._findGeomNodesCallback = (n, d) => {
        if (!n) {
          return;
        }
        if (n instanceof GeometryNode3D) {
          let arr = d.array;

          if (!arr) {
            arr = d.array = [];
          }
          if (arr.indexOf(n, 0) < 0) {
            arr.push(n);
          }
        } else if (n instanceof ContainerNode3D) {
          const children = n.getChildren();
          const numC = children ? children.length : 0;

          for (let i = 0; i < numC; ++i) {
            const child = children[i];

            findGeomNodesCb(child, d);
          }
        }
      };
    }
    const findGeomNodeData = {};

    findGeomNodesCb(node, findGeomNodeData);

    const array = findGeomNodeData.array;
    const num = array ? array.length : 0;

    if (!num) {
      return resCont;
    }
    let defContainer = null;
    let containerMap = null;

    for (let i = 0; i < num; ++i) {
      const n = array[i];

      const mn = Node3DMaterialUtils.getMaterialName(n);

      if (mn) {
        if (!containerMap) {
          containerMap = {};
        }
        let cont = containerMap[mn];

        if (!cont) {
          cont = containerMap[mn] = new BD3DContainerNode3D();
          cont.setSelectable(true);
          resCont.addChild(cont);
        }
        cont.addChild(n);
      } else {
        if (!defContainer) {
          defContainer = new BD3DContainerNode3D();
          defContainer.setSelectable(true);
          resCont.addChild(defContainer);
        }
        defContainer.addChild(n);
      }
    }

    return resCont;
  }
}
