import Matrix4 from '../geom/Matrix4';
import RotationOrder from '../geom/RotationOrder';

const NUM = 16;

const DEFAULT_ROTATIONORDER = RotationOrder.XYZ;

const _0 = 0, _1 = 1, _2 = 2, _3 = 3,
  _4 = 4, _5 = 5, _6 = 6, _7 = 7,
  _8 = 8, _9 = 9, _10 = 10, _11 = 11,
  _12 = 12, _13 = 13, _14 = 14, _15 = 15;

let tempMatrix = null;

export default class Matrix4Math {
  static copy(source, destination = null) {
    if (source === destination) {
      return destination;
    }
    if (!source) {
      return destination;
    }
    let dest = destination;

    if (!dest) {
      dest = new Matrix4();
    }
    let d = dest;
    let s = source;

    if (d instanceof Matrix4) {
      d = d.getElements();
    }
    if (s instanceof Matrix4) {
      s = s.getElements();
    }

    for (let i = 0; i < NUM; ++i) {
      d[i] = s[i];
    }

    return dest;
  }

  static identity(dest = null) {
    let d = dest;

    if (!d) {
      d = new Matrix4();
    }
    let e = d;

    if (d instanceof Matrix4) {
      e = d.getElements();
    }

    for (let i = 0; i < NUM; ++i) {
      e[i] = i % _5 === 0 ? 1 : 0;
    }

    return d;
  }

  /**
   * @method invert
   * @static
   * @description Inverts a matrix.
   * If result is null, the matrix itself will be inverted
   * @param {Matrix4} matrix - Matrix to be inverted
   * @param {Matrix4} result - Optional matrix where the result will be stored.
   *  If null, the matrix parameter will be used
   * @return {Matrix4} - Inverted matrix.
   *
   * */
  static invert(matrix, result = null) {
    let res = result;

    if (!res) {
      res = matrix;
    }

    return this.getInverse(matrix, res);
  }

  /**
   * @method getInverse
   * @static
   * @description Returns the inverse of a matrix
   * @param {Matrix4} matrix - the matrix to be inverted
   * @param {Matrix4} result - optional matrix where the result will be stored.
   * If null, a new Matrix4 instance will be created
   * @return {Matrix4} - Inverted matrix.
   *  Same reference as the result parameter if not null.
   **/
  static getInverse(matrix, result = null) {
    let res = result;
    let mtx = matrix;

    if (mtx instanceof Matrix4) {
      mtx = matrix.getElements();
    }

    const m0 = mtx[_0],
      m1 = mtx[_1],
      m2 = mtx[_2],
      m3 = mtx[_3],

      m4 = mtx[_4],
      m5 = mtx[_5],
      m6 = mtx[_6],
      m7 = mtx[_7],

      m8 = mtx[_8],
      m9 = mtx[_9],
      m10 = mtx[_10],
      m11 = mtx[_11],

      m12 = mtx[_12],
      m13 = mtx[_13],
      m14 = mtx[_14],
      m15 = mtx[_15];

    const t11 = m9 * m14 * m7 - m13 * m10 * m7 + m13 * m6 * m11 - m5 * m14 * m11 - m9 * m6 * m15 + m5 * m10 * m15;
    const t12 = m12 * m10 * m7 - m8 * m14 * m7 - m12 * m6 * m11 + m4 * m14 * m11 + m8 * m6 * m15 - m4 * m10 * m15;
    const t13 = m8 * m13 * m7 - m12 * m9 * m7 + m12 * m5 * m11 - m4 * m13 * m11 - m8 * m5 * m15 + m4 * m9 * m15;
    const t14 = m12 * m9 * m6 - m8 * m13 * m6 - m12 * m5 * m10 + m4 * m13 * m10 + m8 * m5 * m14 - m4 * m9 * m14;

    // Calculate determinant
    const det = m0 * t11 + m1 * t12 + m2 * t13 + m3 * t14;

    if (det === 0) {
      // #if DEBUG
      console.warn('Matrix is not invertible:', matrix);
      // #endif

      return this.identity(res);
    }

    const invDet = 1 / det;

    if (!res) {
      res = new Matrix4();
    }
    let r = res;

    if (res instanceof Matrix4) {
      r = res.getElements();
    }

    r[_0] = t11 * invDet;
    r[_1] = (m13 * m10 * m3 - m9 * m14 * m3 - m13 * m2 * m11 + m1 * m14 * m11 + m9 * m2 * m15 - m1 * m10 * m15) * invDet;
    r[_2] = (m5 * m14 * m3 - m13 * m6 * m3 + m13 * m2 * m7 - m1 * m14 * m7 - m5 * m2 * m15 + m1 * m6 * m15) * invDet;
    r[_3] = (m9 * m6 * m3 - m5 * m10 * m3 - m9 * m2 * m7 + m1 * m10 * m7 + m5 * m2 * m11 - m1 * m6 * m11) * invDet;

    r[_4] = t12 * invDet;
    r[_5] = (m8 * m14 * m3 - m12 * m10 * m3 + m12 * m2 * m11 - m0 * m14 * m11 - m8 * m2 * m15 + m0 * m10 * m15) * invDet;
    r[_6] = (m12 * m6 * m3 - m4 * m14 * m3 - m12 * m2 * m7 + m0 * m14 * m7 + m4 * m2 * m15 - m0 * m6 * m15) * invDet;
    r[_7] = (m4 * m10 * m3 - m8 * m6 * m3 + m8 * m2 * m7 - m0 * m10 * m7 - m4 * m2 * m11 + m0 * m6 * m11) * invDet;

    r[_8] = t13 * invDet;
    r[_9] = (m12 * m9 * m3 - m8 * m13 * m3 - m12 * m1 * m11 + m0 * m13 * m11 + m8 * m1 * m15 - m0 * m9 * m15) * invDet;
    r[_10] = (m4 * m13 * m3 - m12 * m5 * m3 + m12 * m1 * m7 - m0 * m13 * m7 - m4 * m1 * m15 + m0 * m5 * m15) * invDet;
    r[_11] = (m8 * m5 * m3 - m4 * m9 * m3 - m8 * m1 * m7 + m0 * m9 * m7 + m4 * m1 * m11 - m0 * m5 * m11) * invDet;

    r[_12] = t14 * invDet;
    r[_13] = (m8 * m13 * m2 - m12 * m9 * m2 + m12 * m1 * m10 - m0 * m13 * m10 - m8 * m1 * m14 + m0 * m9 * m14) * invDet;
    r[_14] = (m12 * m5 * m2 - m4 * m13 * m2 - m12 * m1 * m6 + m0 * m13 * m6 + m4 * m1 * m14 - m0 * m5 * m14) * invDet;
    r[_15] = (m4 * m9 * m2 - m8 * m5 * m2 + m8 * m1 * m6 - m0 * m9 * m6 - m4 * m1 * m10 + m0 * m5 * m10) * invDet;

    return res;
  }

  /**
   * @method multiply
   * @static
   * @description multiplies 2 matrices
   * @param {Matrix4} matrix1 - first matrix
   * @param {Matrix4} matrix2 - second matrix
   * @param {Matrix4} result - optional result matrix. If null, a new Matrix4
   * instance will be created
   * @return {Matrix4} result of m1 * m2.
   *  Same reference as the result matrix parameter if not null
   **/
  static multiply(matrix1, matrix2, result = null) {
    let res = result;

    if (!res) {
      res = new Matrix4();
    }

    if (!matrix1 && !matrix2) {
      res = Matrix4Math.identity(res);

      return res;
    }

    if (!matrix1) {
      return Matrix4Math.copy(matrix2, res);
    } else if (!matrix2) {
      return Matrix4Math.copy(matrix1, res);
    }
    let m1 = matrix1;
    let m2 = matrix2;

    if (matrix1 instanceof Matrix4) {
      m1 = matrix1.getElements();
    }
    if (matrix2 instanceof Matrix4) {
      m2 = matrix2.getElements();
    }

    const v0 = m1[_0] * m2[_0] + m1[_4] * m2[_1] + m1[_8] * m2[_2] + m1[_12] * m2[_3],
      v1 = m1[_1] * m2[_0] + m1[_5] * m2[_1] + m1[_9] * m2[_2] + m1[_13] * m2[_3],
      v2 = m1[_2] * m2[_0] + m1[_6] * m2[_1] + m1[_10] * m2[_2] + m1[_14] * m2[_3],
      v3 = m1[_3] * m2[_0] + m1[_7] * m2[_1] + m1[_11] * m2[_2] + m1[_15] * m2[_3],

      v4 = m1[_0] * m2[_4] + m1[_4] * m2[_5] + m1[_8] * m2[_6] + m1[_12] * m2[_7],
      v5 = m1[_1] * m2[_4] + m1[_5] * m2[_5] + m1[_9] * m2[_6] + m1[_13] * m2[_7],
      v6 = m1[_2] * m2[_4] + m1[_6] * m2[_5] + m1[_10] * m2[_6] + m1[_14] * m2[_7],
      v7 = m1[_3] * m2[_4] + m1[_7] * m2[_5] + m1[_11] * m2[_6] + m1[_15] * m2[_7],

      v8 = m1[_0] * m2[_8] + m1[_4] * m2[_9] + m1[_8] * m2[_10] + m1[_12] * m2[_11],
      v9 = m1[_1] * m2[_8] + m1[_5] * m2[_9] + m1[_9] * m2[_10] + m1[_13] * m2[_11],
      v10 = m1[_2] * m2[_8] + m1[_6] * m2[_9] + m1[_10] * m2[_10] + m1[_14] * m2[_11],
      v11 = m1[_3] * m2[_8] + m1[_7] * m2[_9] + m1[_11] * m2[_10] + m1[_15] * m2[_11],

      v12 = m1[_0] * m2[_12] + m1[_4] * m2[_13] + m1[_8] * m2[_14] + m1[_12] * m2[_15],
      v13 = m1[_1] * m2[_12] + m1[_5] * m2[_13] + m1[_9] * m2[_14] + m1[_13] * m2[_15],
      v14 = m1[_2] * m2[_12] + m1[_6] * m2[_13] + m1[_10] * m2[_14] + m1[_14] * m2[_15],
      v15 = m1[_3] * m2[_12] + m1[_7] * m2[_13] + m1[_11] * m2[_14] + m1[_15] * m2[_15];

    // res.elements = [v0, v1, v2, v3, v4, v5, v6, v7, v8, v9, v10, v11, v12, v13, v14, v15];
    if (res instanceof Matrix4) {
      res.setValues(v0, v1, v2, v3, v4, v5, v6, v7, v8, v9, v10, v11, v12, v13, v14, v15);
    } else {
      res[_0] = v0; res[_1] = v1; res[_2] = v2; res[_3] = v3;
      res[_4] = v4; res[_5] = v5; res[_6] = v6; res[_7] = v7;
      res[_8] = v8; res[_9] = v9; res[_10] = v10; res[_11] = v11;
      res[_12] = v12; res[_13] = v13; res[_14] = v14; res[_15] = v15;
    }

    return res;
  }

  static scaleXYZ(src, x, y, z, result) {
    let res = result;

    if (!src) {
      return src;
    }

    if (!res) {
      res = src;
    }

    res[_0] = src[_0] * x;
    res[_1] = src[_1] * x;
    res[_2] = src[_2] * x;
    res[_3] = src[_3] * x;

    res[_4] = src[_4] * y;
    res[_5] = src[_5] * y;
    res[_6] = src[_6] * y;
    res[_7] = src[_7] * y;

    res[_8] = src[_8] * z;
    res[_9] = src[_9] * z;
    res[_10] = src[_10] * z;
    res[_11] = src[_11] * z;

    return res;
  }

  static scale(src, vector, result) {
    let res = result;

    if (!src || !vector) {
      return src;
    }

    if (!res) {
      res = src;
    }

    res[_0] = src[_0] * vector.x;
    res[_1] = src[_1] * vector.x;
    res[_2] = src[_2] * vector.x;
    res[_3] = src[_3] * vector.x;

    res[_4] = src[_4] * vector.y;
    res[_5] = src[_5] * vector.y;
    res[_6] = src[_6] * vector.y;
    res[_7] = src[_7] * vector.y;

    res[_8] = src[_8] * vector.z;
    res[_9] = src[_9] * vector.z;
    res[_10] = src[_10] * vector.z;
    res[_11] = src[_11] * vector.z;

    return res;
  }

  static translateXYZ(src, x, y, z, result) {
    let res = result;

    if (!src) {
      return src;
    }

    if (!res) {
      res = src;
    }
    let r = res;
    let s = src;

    if (r instanceof Matrix4) {
      r = res.getElements();
    }
    if (s instanceof Matrix4) {
      s = src.getElements();
    }

    r[_12] = s[_12] + x;
    r[_13] = s[_13] + y;
    r[_14] = s[_14] + z;

    return res;
  }

  static translate(src, vector, result) {
    let res = result;

    if (!src || !vector) {
      return src;
    }

    if (!res) {
      res = src;
    }

    res[_12] = src[_12] + vector.x;
    res[_13] = src[_13] + vector.y;
    res[_14] = src[_14] + vector.z;

    return res;
  }

  static xRotation(angle, src, result = null) {
    const res = src || this.identity(result);

    if (angle === 0) {
      return res;
    }

    const cosine = Math.cos(angle);
    const sine = Math.sin(angle);

    res[_5] = cosine;
    res[_6] = sine;
    res[_9] = -sine;
    res[_10] = cosine;

    return res;
  }

  static xRotate(angle, src, result) {
    let res = result;
    let source = src;

    if (angle === 0 && res) {
      return res;
    }

    if (!source) {
      source = this.identity();
    }

    if (!res) {
      res = source;
    }

    tempMatrix = this.xRotation(angle, null, tempMatrix);

    res = this.multiply(tempMatrix, src, res);

    return res;
  }

  static yRotation(angle, src, result) {
    const res = src || this.identity(result);

    if (angle === 0) {
      return res;
    }

    const cosine = Math.cos(angle);
    const sine = Math.sin(angle);

    res[_0] = cosine;
    res[_2] = -sine;
    res[_8] = sine;
    res[_10] = cosine;

    return res;
  }

  static yRotate(angle, src, result) {
    let res = result;
    let source = src;

    if (angle === 0 && res) {
      return res;
    }

    if (!source) {
      source = this.identity();
    }

    if (!res) {
      res = source;
    }

    tempMatrix = this.yRotation(angle, null, tempMatrix);

    res = this.multiply(tempMatrix, src, res);

    return res;
  }

  static zRotation(angle, src, result) {
    const res = src || this.identity(result);

    if (angle === 0) {
      return res;
    }

    const cosine = Math.cos(angle);
    const sine = Math.sin(angle);

    res[_0] = cosine;
    res[_1] = sine;
    res[_4] = -sine;
    res[_5] = cosine;

    return res;
  }

  static zRotate(angle, src, result) {
    let res = result;
    let source = src;

    if (angle === 0 && res) {
      return res;
    }

    if (!source) {
      source = this.identity();
    }

    if (!res) {
      res = source;
    }
    if (angle === 0) {
      if (res !== source) {
        Matrix4Math.copy(source, res);
      }

      return res;
    }

    tempMatrix = this.zRotation(angle, null, tempMatrix);

    res = this.multiply(tempMatrix, src, res);

    return res;
  }

  static rotate(src, vector, rotationOrder, result) {
    let rotOrder = rotationOrder;
    let res = this.copy(src, result);

    if (!src || !vector) {
      return src;
    }

    if (RotationOrder[rotOrder] === null || typeof (RotationOrder[rotOrder]) === 'undefined') {
      rotOrder = DEFAULT_ROTATIONORDER;
    }

    switch (rotOrder) {
        case RotationOrder.XYZ: {
          res = Matrix4Math.zRotate(vector.z, res, res);
          res = Matrix4Math.yRotate(vector.y, res, res);
          res = Matrix4Math.xRotate(vector.x, res, res);
          break;
        }
        case RotationOrder.XZY: {
          res = Matrix4Math.yRotate(vector.y, res, res);
          res = Matrix4Math.zRotate(vector.z, res, res);
          res = Matrix4Math.xRotate(vector.x, res, res);
          break;
        }
        case RotationOrder.YXZ: {
          res = Matrix4Math.zRotate(vector.z, res, res);
          res = Matrix4Math.xRotate(vector.x, res, res);
          res = Matrix4Math.yRotate(vector.y, res, res);
          break;
        }
        case RotationOrder.YZX: {
          res = Matrix4Math.xRotate(vector.x, res, res);
          res = Matrix4Math.zRotate(vector.z, res, res);
          res = Matrix4Math.yRotate(vector.y, res, res);
          break;
        }
        case RotationOrder.ZXY: {
          res = Matrix4Math.yRotate(vector.y, res, res);
          res = Matrix4Math.xRotate(vector.x, res, res);
          res = Matrix4Math.zRotate(vector.z, res, res);
          break;
        }
        case RotationOrder.ZYX: {
          res = Matrix4Math.xRotate(vector.x, res, res);
          res = Matrix4Math.yRotate(vector.y, res, res);
          res = Matrix4Math.zRotate(vector.z, res, res);
          break;
        }
    }

    return res;
  }

  static setPosition(src, xpos, ypos, zpos) {
    const res = src;

    res.x = xpos;
    res.y = ypos;
    res.z = zpos;

    return res;
  }

  static transpose(source, dest) {
    const src = source instanceof Matrix4 ? source.getElements() : source;
    let res = dest;

    if (!res) {
      if (source instanceof Matrix4) {
        res = new Matrix4();
      } else {
        res = new Float32Array(NUM);
      }
    }
    const dst = res instanceof Matrix4 ? res.getElements() : res;

    dst[_0] = src[_0];
    dst[_1] = src[_4];
    dst[_2] = src[_8];
    dst[_3] = src[_12];

    dst[_4] = src[_1];
    dst[_5] = src[_5];
    dst[_6] = src[_9];
    dst[_7] = src[_13];

    dst[_8] = src[_2];
    dst[_9] = src[_6];
    dst[_10] = src[_10];
    dst[_11] = src[_14];

    dst[_12] = src[_3];
    dst[_13] = src[_7];
    dst[_14] = src[_11];
    dst[_15] = src[_15];

    return res;
  }
}
