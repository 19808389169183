import Node3D from '../scenegraph/Node3D';
import GeometryNode3D from '../scenegraph/GeometryNode3D';
import MaterialGeometryNode3D from '../scenegraph/MaterialGeometryNode3D';
import ContainerNode3D from '../scenegraph/ContainerNode3D';

import Transform3DCloner from './Transform3DCloner';
import GeometryCloner from './GeometryCloner';

export default class Node3DCloner {
  static _cloneGeometryNode(geomNode, transform, params = null) {
    let cloneGeom = true;

    if (params) {
      cloneGeom = params.cloneGeometry !== false;
    }
    const geom = cloneGeom ? GeometryCloner.clone(geomNode.geometry, params) : geomNode.geometry;
    let res = null;

    if (params) {
      if (params.onCloneGeometryNode3D) {
        res = params.onCloneGeometryNode3D(geomNode, geom, transform, params);
      } else if (params.onCloneGeometryNode) {
        res = params.onCloneGeometryNode(geomNode, geom, transform, params);
      }
    }
    if (!res) {
      const Cl = geomNode.constructor;

      if (Cl) {
        res = new Cl();
      }
      if (res) {
        if (res.setGeometry) {
          res.setGeometry(geom);
        } else {
          res.geometry = geom;
        }
        res.transform = transform;

        if (res instanceof MaterialGeometryNode3D) {
          res.setMaterial(geomNode.getMaterial());
        }

      } else if (geomNode instanceof MaterialGeometryNode3D) {
        res = new MaterialGeometryNode3D(geom, geomNode.getMaterial(), transform);
      } else {
        res = new GeometryNode3D(geom, transform);
      }
    }

    if (params) {
      if (params.onClonedGeometryNode3D) {
        params.onClonedGeometryNode3D(geomNode, res, params);
      }
      if (params.onClonedGeometryNode) {
        params.onClonedGeometryNode(geomNode, res, params);
      }
    }

    return res;
  }

  static _cloneContainerNode(containerNode, transform, params = null) {
    const children = containerNode.children;
    let Constr = containerNode.constructor;

    if (!Constr) {
      Constr = ContainerNode3D;
    }
    const res = new Constr(null, transform);

    if (children) {
      const num = children.length;
      const childrenCopy = [];

      for (let i = 0; i < num; ++i) {
        const child = children[i];
        const childCopy = this.clone(child, params);

        childrenCopy[i] = childCopy;
      }

      res.setChildren(childrenCopy);
    }
    if (params) {
      if (params.onClonedContainerNode3D) {
        params.onClonedContainerNode3D(containerNode, res, params);
      }
      if (params.onClonedContainerNode) {
        params.onClonedContainerNode(containerNode, res, params);
      }
    }

    return res;
  }

  static clone(node, params = null) {
    if (!node) {
      return null;
    }
    if (!node instanceof Node3D) {
      return null;
    }
    let transform = node.transform;

    transform = Transform3DCloner.clone(transform, params);

    let res = null;

    if (node instanceof GeometryNode3D) {
      res = this._cloneGeometryNode(node, transform, params);
    } else if (node instanceof ContainerNode3D) {
      res = this._cloneContainerNode(node, transform, params);
    }

    if (params) {
      if (params.onClonedNode3D) {
        params.onClonedNode3D(node, res, params);
      }
      if (params.onClonedNode) {
        params.onClonedNode(node, res, params);
      }
    }

    return res;
  }
}
