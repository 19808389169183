import BorderComponentType from './BorderComponentType';
import Vector2 from '../../bgr/bgr3d/geom/Vector2';
import Vector3 from '../../bgr/bgr3d/geom/Vector3';
import Vertex from '../../bgr/bgr3d/geom/Vertex';
// import BD3DFabricMaterial from '../material/BD3DFabricMaterial';
import MattressGeomUtils from '../geom/MattressGeomUtils';
import MaterialTypes from '../material/MaterialTypes';
import FabricMaterialUtils from '../material/FabricMaterialUtils';
import BD3DSampleFabricMaterial from '../material/BD3DSampleFabricMaterial';
import BD3DFabricMaterial from '../material/BD3DFabricMaterial';
import Node3DMaterialUtils from '../material/Node3DMaterialUtils';
import FabricTransform from '../material/FabricTransform';
import BD3DGeometryNode3D from '../scenegraph/BD3DGeometryNode3D';

import BD3DNodeTypes from '../scenegraph/BD3DNodeTypes';

/* eslint-disable */
const TAPESHAPE = [
  /*
  new Vertex(new Vector3(0, 0.3648731, 0)),
  new Vertex(new Vector3(-0.0690153, 0.3747711, 0)),
  new Vertex(new Vector3(-0.1244717, 0.3270434, 0)),
  new Vertex(new Vector3(-0.1020993, 0.2055546, 0)),
  new Vertex(new Vector3(-0.1344376, 0.1492169, 0)),
  new Vertex(new Vector3(-0.2142324, 0.1132178, 0)),
  new Vertex(new Vector3(-0.3464328, 0.1201328, 0)),
  new Vertex(new Vector3(-0.4246683, 0.0851289, 0)),
  new Vertex(new Vector3(-0.458837, 0, 0)),
  new Vertex(new Vector3(-0.4246683, -0.0851289, 0)),
  new Vertex(new Vector3(-0.3464328, -0.1201328, 0)),
  new Vertex(new Vector3(-0.2142324, -0.1132178, 0)),
  new Vertex(new Vector3(-0.1344376, -0.1492169, 0)),
  new Vertex(new Vector3(-0.1020993, -0.2055546, 0)),
  new Vertex(new Vector3(-0.1244717, -0.3270434, 0)),
  new Vertex(new Vector3(-0.0690153, -0.3747711, 0)),
  new Vertex(new Vector3(0, -0.3648731, 0))
  */
  new Vertex(new Vector3(0.0, 0.2256, 0.0)),
  new Vertex(new Vector3(-0.0187, 0.2539, 0.0)),
  new Vertex(new Vector3(-0.0415, 0.2192, 0.0)),
  new Vertex(new Vector3(-0.0912, 0.0552, 0.0)),
  new Vertex(new Vector3(-0.1306, 0.0456, 0.0)),
  new Vertex(new Vector3(-0.2773, 0.1621, 0.0)),
  new Vertex(new Vector3(-0.4611, 0.2303, 0.0)),
  new Vertex(new Vector3(-0.6763, 0.1647, 0.0)),
  new Vertex(new Vector3(-0.7244, 0.0, 0.0)),
  new Vertex(new Vector3(-0.6763, -0.1647, 0.0)),
  new Vertex(new Vector3(-0.4611, -0.2303, 0.0)),
  new Vertex(new Vector3(-0.2773, -0.1557, 0.0)),
  new Vertex(new Vector3(-0.1306, -0.0456, 0.0)),
  new Vertex(new Vector3(-0.0912, -0.0552, 0.0)),
  new Vertex(new Vector3(-0.0415, -0.2192, 0.0)),
  new Vertex(new Vector3(-0.0187, -0.2539, 0.0)),
  new Vertex(new Vector3(0.0, -0.2256, 0.0))
];
let TAPE_SHAPE_HEIGHT = Math.abs(TAPESHAPE[0].getCoord(1) - TAPESHAPE[TAPESHAPE.length - 1].getCoord(1));

/* eslint-enable */
// Calculate shape width
const DEFAULT_HEIGHT = 1;
const MAX_SIZE_Y = 1.5;
const MIN_SIZE_Y = 0.5;

const MAX_SCALE_Y = (MAX_SIZE_Y / TAPE_SHAPE_HEIGHT);
const MIN_SCALE_Y = (MIN_SIZE_Y / TAPE_SHAPE_HEIGHT);

let TAPE_SHAPE_WIDTH = 0;

{
  const num = TAPESHAPE.length;
  let minX = 0, maxX = 0;

  for (let i = 0; i < num; ++i) {
    const v = TAPESHAPE[i];
    const x = v.getCoord(0);

    if (i === 0) {
      minX = x;
      maxX = x;
    } else {
      minX = x < minX ? x : minX;
      maxX = x > maxX ? x : maxX;
    }
  }
  TAPE_SHAPE_WIDTH = maxX - minX;
}

export default class TapeBorderComponentType extends BorderComponentType {
  getNodeType() {
    return BD3DNodeTypes.tape;
  }

  addAssets(data, params, array = null, assetManager = null) {
    if (!data) {
      return array;
    }
    let arr = array;

    arr = super.addAssets(data, params, arr, assetManager);

    if (!arr) {
      arr = array;
    }

    if (!data.texture || data.texture.id === null || typeof (data.texture.id) === 'undefined') {
      arr = assetManager.addAssetToArray('defaultfabric.color', arr, params);
      arr = assetManager.addAssetToArray('defaultfabric.normal', arr, params);
    }

    return arr;
  }

  createNode3D(borderCompGeom, comp, mattressData, borderLoop, borderShape, borderCurveGraph, buildParams) {
    const borderCompNode = new BD3DGeometryNode3D(borderCompGeom);
    let material = Node3DMaterialUtils.getMaterial(borderCompNode);

    material = this.getMaterial(comp, mattressData, buildParams, material);

    if (material instanceof BD3DFabricMaterial) {
      let uvData = MattressGeomUtils.getGeometryUVWorldTransform(borderCompGeom);

      if (!uvData) {
        uvData = MattressGeomUtils.assignDefaultGeometryUVWorldTransform(borderCompGeom);
      }

      const sampleTransform = material.getSampleTransform();
      const quiltTransform = material.getQuiltTransform();


      if (sampleTransform instanceof FabricTransform) {
        sampleTransform.setGeometryUVData(uvData);
      }
      if (quiltTransform instanceof FabricTransform) {
        quiltTransform.setGeometryUVData(uvData);
      }
    }

    Node3DMaterialUtils.setMaterial(borderCompNode, material);

    return borderCompNode;
  }

  getMaterial(data, mattressData, buildParams = null, result = null, targetNode = null) {
    const mtl = FabricMaterialUtils.getBorderMaterialFromData(data.texture, data.quilt, buildParams, null, MaterialTypes.SAMPLE, result);

    mtl.setColorMultiplier(data.color);
    const sampleTex = mtl.getSampleTexture();
    const sampleNrm = mtl.getSampleNormalMap();

    if (!sampleTex && !sampleNrm) {
      // Apply default sample

      const assetCollections = buildParams.assetCollections;
      const sampleColorAsset = assetCollections.commonTextures.getAsset('defaultfabric.color');
      const sampleNormalAsset = assetCollections.commonTextures.getAsset('defaultfabric.normal');

      let realWidth = 2;
      let realHeight = 2;

      if (sampleColorAsset && sampleColorAsset.metaData && sampleColorAsset.metaData.realSize) {
        realWidth = sampleColorAsset.metaData.realSize.width;
        realHeight = sampleColorAsset.metaData.realSize.height;
      }
      const sampleTransform = mtl.getSampleTransform();

      sampleTransform.setRealWidth(realWidth);
      sampleTransform.setRealHeight(realHeight);


      if (mtl instanceof BD3DSampleFabricMaterial) {
        mtl.setSampleAsset(null);
      }
      mtl.setSampleTexture(sampleColorAsset);
      mtl.setSampleNormalMap(sampleNormalAsset);
    }


    return mtl;
    /*
    let res = result;
    if (!res) {
      res = new BD3DFabricMaterial();
    }
    res.setType(MaterialTypes.SAMPLE);
    res.setSettings(data.texture);
    res.setColorMultiplier(data.color);
    res.setFabricType(data.material);
    return res;
    */
  }

  getDepth(data) {
    if (!data) {
      return 0;
    }
    if (data.depth !== undefined && data.depth !== null) {
      return data.depth;
    }

    return 0;
  }

  // deformVertices(borderComponentData, mattressData, yOffset, vOffset, startX, endX, borderShape, arr, arrayInd) {
  // deformVertices(data, yOffset, vOffset, startX, endX, borderShape, arr, arrayInd) {
  deformVertices(data, mattressData, yOffset, vOffset, startX, endX, borderShape, arr, arrayInd) {
    // input params
    let array = arr;
    let arrayIndex = arrayInd;

    // deform vertices
    if (arrayIndex === undefined || arrayIndex === null || arrayIndex < 0) {
      arrayIndex = 0;
    }

    // TODO: move somecalculations to utils
    const depth = this.getDepth(data);
    let prevX;
    let prevY;
    let curV = 0;
    let i;
    let index;
    let vertex = null;
    let vertexPos = null;
    let normal = null;
    let uv = null;

    const numTapeVerts = TAPESHAPE.length;

    const tapeH = this._getShapeHeight(data, mattressData);

    const scaleY = this.getSizeY(data, mattressData) / tapeH;
    // const scaleX = (1 + scaleY) * 0.5;
    const scaleX = this.getSizeX(data, mattressData) / TAPE_SHAPE_WIDTH;

    for (i = 0; i < numTapeVerts; ++i) {
      index = i + arrayIndex;
      const tapeVertex = TAPESHAPE[i];
      let xpos = tapeVertex.getCoord(0) * scaleX;
      let ypos = tapeVertex.getCoord(1) * scaleY;

      if (array) {
        vertex = array[index];
      }
      vertexPos = null;
      normal = null;
      uv = null;

      if (vertex) {
        vertexPos = vertex.position;
        if (vertex.attributes) {
          normal = vertex.attributes.normal;
          uv = vertex.attributes.uv;
        }
      } else {
        vertex = new Vertex();
      }

      vertexPos = vertex.position;

      if (!vertexPos) {
        vertexPos = vertex.position = new Vector3();
      }

      if (!normal) {
        normal = new Vector3(-1, 0, 0);
      }
      if (!uv) {
        uv = new Vector2();
      }

      xpos -= depth;

      if (startX !== null && startX !== undefined && !isNaN(startX)) {
        if (i === 0) {
          xpos = startX;
        }
      }

      if (endX !== null && endX !== undefined && !isNaN(endX)) {
        if (i === (numTapeVerts - 1)) {
          xpos = endX;
        }
      }

      vertexPos.setCoord(0, xpos);
      vertexPos.setCoord(1, ypos);
      vertexPos.setCoord(2, 0);

      vertexPos = this.borderTransformVector3(vertexPos, yOffset, borderShape, false, true, vertexPos);
      xpos = vertexPos.getCoord(0);
      ypos = vertexPos.getCoord(1);
      // normal = this.borderTransformVector3(normal, yOffset - ypos, borderShape, true, true, normal);
      if (i > 0) {
        const dx = xpos - prevX;
        const dy = ypos - prevY;
        let d = dx * dx + dy * dy;

        if (d !== 0 && d !== 1) {
          d = Math.sqrt(d);
        }
        curV += d;
      }
      uv.setCoord(1, curV);

      vertex.position = vertexPos;
      if (!vertex.attributes) {
        vertex.attributes = {};
      }
      vertex.attributes.uv = uv;
      vertex.attributes.normal = normal;
      if (!array) {
        array = [];
      }
      array[index] = vertex;

      prevX = xpos;
      prevY = ypos;
    }
    this.computeVertexNormals(array, numTapeVerts, arrayIndex);

    return array;
  }

  addVertices(data, mattressData, yOffset, startX, endX, borderShape, array, ind) {
    let index = ind;

    if ((index === null || index === undefined)) {
      if (array) {
        index = array.length;
      } else {
        index = 0;
      }
    }

    return this.deformVertices(data, mattressData, yOffset, 0, startX, endX, borderShape, array, index);
    // return this.deformVertices(data, yOffset, 0, borderShape, array, index);
  }

  _getShapeTop() {
    const firstVertex = TAPESHAPE[0];
    const firstY = firstVertex.getCoord(1);

    return firstY;
  }

  _getShapeBottom() {
    const lastVertex = TAPESHAPE[TAPESHAPE.length - 1];
    const lastY = lastVertex.getCoord(1);

    return lastY;
  }

  _getShapeHeight() {
    const top = this._getShapeTop();
    const bottom = this._getShapeBottom();
    const height = (top > bottom) ? (top - bottom) : (bottom - top);

    return height;
  }

  _getScaleFactorY(data, mattressData) {
    const sfY = this._getScaleFactorX(data, mattressData);

    if (sfY > MAX_SCALE_Y) {
      return MAX_SCALE_Y;
    } else if (sfY < MIN_SCALE_Y) {
      return MIN_SCALE_Y;
    }

    return sfY;
  }

  _getScaleFactorX(data, mattressData) {
    if (!data) {
      return 1;
    }
    const h = this.getSizeX(data, mattressData);

    return h / TAPE_SHAPE_WIDTH;
  }

  static getDefaultHeight() {
    // return TAPE_SHAPE_WIDTH;
    return DEFAULT_HEIGHT;
  }

  getDefaultHeight() {
    return TapeBorderComponentType.getDefaultHeight();
  }

  getSizeX(data, mattressData) {
    return this.getHeight(data, mattressData);
  }

  getSizeY(data, mattressData) {
    const sfY = this._getScaleFactorY(data, mattressData);

    return this._getShapeHeight(data, mattressData) * sfY;

    /*
    if (!data) {
      return this._getShapeHeight();
    }
    const h = this._parseNum(data.height, null);

    if (!this._isset(h)) {
      return this._getShapeHeight();
    }
    const scale = h / TAPE_SHAPE_WIDTH;

    return this._getShapeHeight() * scale;
    */
  }

  getHeight(data) {
    if (!data) {
      // return this._getShapeHeight();
      return this.getDefaultHeight(); // TAPE_SHAPE_WIDTH;
    }
    const h = this._parseNum(data.height, null);

    if (this._isset(h)) {
      return h;
    }

    return this.getDefaultHeight(); // TAPE_SHAPE_WIDTH;
    // return this._getShapeHeight();
  }

  getTop(data, mattressData) {
    return -this._getShapeHeight(data, mattressData) * 0.5 * this._getScaleFactorY(data, mattressData);
    // return this._getShapeTop(data, mattressData) * this._getScaleFactor(data, mattressData);
    // return -this.getHeight(data) * 0.5;
  }

  getBottom(data, mattressData) {
    return this._getShapeHeight(data, mattressData) * 0.5 * this._getScaleFactorY(data, mattressData);
    // return this._getShapeBottom(data, mattressData) * this._getScaleFactor(data, mattressData);
    // return this.getHeight(data) * 0.5;
  }
}
