import HandleType from './HandleType';
import ImageAsset from '../asset/ImageAsset';
import Object3DAsset from '../asset/Object3DAsset';
import GraphUtils from '../graph/GraphUtils';
import MattressDA from '../mattress/MattressDA';
import BD3DNodeTypes from '../scenegraph/BD3DNodeTypes';
import HandleUtils from './HandleUtils';
import Node3DMaterialUtils from '../material/Node3DMaterialUtils';
import BD3DContainerNode3D from '../scenegraph/BD3DContainerNode3D';
import BD3DGeometryNode3D from '../scenegraph/BD3DGeometryNode3D';
import BoundingBoxUtils from '../../bgr/bgr3d/utils/BoundingBoxUtils';

import HandleErrorType from './HandleErrorType';
/**
 * @class PocketHandleType
 * @description Pocket handle type, has an opening in a border component like a pocket
 *
 *  ===============================
 *              _______
 *            /        \
 *  ===============================
 *
 *  ===============================
 * */

const MIN_COMPONENTS = 3;
const MIN_LENGTH_FOR_HANDLES = 120;
const MIN_INNER_LENGTH_FOR_HANDLES = 80;
const MIN_BORDER_HEIGHT = 5;

const Assets = {
  HHP031_OBJ: new Object3DAsset('handle.pocket.HHP031.object'),
  HHP032_OBJ: new Object3DAsset('handle.pocket.HHP032.object'),
  HHP033_OBJ: new Object3DAsset('handle.pocket.HHP033.object'),

  HHP031_NORMALMAP: null, // new ImageAsset('handle.pocket.HHP031.normal'),
  HHP032_NORMALMAP: new ImageAsset('handle.pocket.HHP032.normal'),
  HHP033_NORMALMAP: null // new ImageAsset('handle.pocket.HHP033.normal')
};

export default class PocketHandleType extends HandleType {
  static getTypeName() {
    return 'pocket';
  }

  static get typeName() {
    return this.getTypeName();
  }

  isValidPocketComponent(comp) {
    return (comp && comp.type === 'fabric');
  }

  isValidSeparatorComponent(comp) {
    return (comp && comp.type === 'piping');
  }

  addAssets(data, handleStyle, params, array, assetManager) {
    const objAsset = this._get3DObjectAsset(data);
    const normalMapAsset = this._getNormalMapAsset(data);
    let arr = array;

    arr = assetManager.addAssetToArray(objAsset, arr, params);
    arr = assetManager.addAssetToArray(normalMapAsset, arr, params);

    return arr;
  }


  _getNormalMapAsset(data, mattressData, assets) {
    const styleID = this._getStyleID(data);

    if (!styleID) {
      return null;
    }
    const assetName = `${styleID}_NORMALMAP`;
    const asset = Assets[assetName];

    return asset;
  }

  _get3DObjectAsset(data, mattressData, assets) {
    const styleID = this._getStyleID(data);

    if (!styleID) {
      return null;
    }
    const objAssetName = `${styleID}_OBJ`;
    const asset = Assets[objAssetName];

    return asset;
  }

  _getBorderByType(single, type) {
    return null;
  }

  _findBorderByType(single, condition) {
    if (!single || !condition) {
      return null;
    }
    const border = single.border;

    if (!border) {
      return null;
    }
    const comps = border.components;

    if (!comps) {
      return null;
    }
    const numComps = comps.length;

    for (let i = 0; i < numComps; ++i) {
      const comp = comps[i];

      if (condition(comp) === true) {
        return comp;
      }
    }

    return null;
  }

  _getTargetBorder(handleData, mattressData) {
    // return this._getBorderByType(mattressData, 'fabric');
    return this._findBorderByType(mattressData, this.isValidPocketComponent);
  }

  create3DHandles(data, borderNodes, mattressData, borderShape, borderCurveGraph, buildParams, resultNode = null) {
    if (resultNode) {
      resultNode.removeChildren();
    }
    let stName = this._getStyleID(data);
    const single = mattressData;

    if (!stName) {
      stName = MattressDA.getHandleType(single);
    }

    const mattressWidth = MattressDA.getWidth(single);
    const mattressLength = MattressDA.getLength(single);


    // const height = MattressDA.getHeight(single);
    //
    const cornerRadius = MattressDA.getResultCornerRadius(single);
    const innerLength = mattressLength - cornerRadius - cornerRadius;
    const innerWidth = mattressWidth - cornerRadius - cornerRadius;

    const asset = this._get3DObjectAsset({type: stName});
    const node3d = asset.getNode3D();

    const border = this._getTargetBorder(data, mattressData);

    // var mf = mc._getMattress3DFactory();
    // var borderNode = mf.getNode3DByData(border);
    //

    const dataNode3DMap = buildParams ? buildParams.dataNode3DMap : null;
    const borderNode = dataNode3DMap ? dataNode3DMap.get(border) : null;

    const borderType = borderNode.userData.borderComponentType;

    const params = {
      mattressData: single,
      deformMode: HandleUtils.HandleDeformMode.DEFORM_TRUE,
      alignY: 0,
      borderComponentData: border,
      borderComponentType: borderType,
      borderComponentNode: borderNode
    };

    // var handleData = MattressDA.getHandleData(single, false);
    // const handleData = data;
    // const handleNode = dataNode3DMap ? dataNode3DMap.get(handleData) : null;
    // const singleNode = dataNode3DMap ? dataNode3DMap.get(single) : null;

    // const middleContainer = singleNode.children[1];
    const srcMtl = Node3DMaterialUtils.getMaterial(borderNode);

    const node3DBounds = BoundingBoxUtils.getBoundingBox(node3d);
    const handleWidth = node3DBounds.maxx - node3DBounds.minx;

    // const largeEnoughFor1SideHandle = (length - cornerRadius) >= handleWidth;
    // const largeEnoughFor1FrontHandle = (width - cornerRadius) >= handleWidth;

    // const largeEnoughFor2SideHandles = length > MIN_LENGTH_FOR_HANDLES && innerLength > MIN_INNER_LENGTH_FOR_HANDLES;
    // const largeEnoughFor2FrontHandles = width > MIN_LENGTH_FOR_HANDLES && innerWidth > MIN_INNER_LENGTH_FOR_HANDLES;

    let placementCountResult = null;

    placementCountResult = HandleUtils.largeEnoughForNumHandles(data, true, mattressWidth, innerWidth, handleWidth, MIN_LENGTH_FOR_HANDLES, MIN_INNER_LENGTH_FOR_HANDLES, placementCountResult);

    const {largeEnoughFor1Handle: largeEnoughFor1FrontHandle, largeEnoughFor2Handles: largeEnoughFor2FrontHandles} = placementCountResult;

    placementCountResult = HandleUtils.largeEnoughForNumHandles(data, false, mattressLength, innerLength, handleWidth, MIN_LENGTH_FOR_HANDLES, MIN_INNER_LENGTH_FOR_HANDLES, placementCountResult);

    const {largeEnoughFor1Handle: largeEnoughFor1SideHandle, largeEnoughFor2Handles: largeEnoughFor2SideHandles} = placementCountResult;

    const relSidePos = 0.5;
    const relFrontPos = 0.5;

    const resNode = resultNode ? resultNode : new BD3DContainerNode3D();
    const hlength = mattressLength * 0.5;
    const hwidth = mattressWidth * 0.5;


    if (largeEnoughFor2FrontHandles) {
      this._createPocketHandle(node3d, hwidth * relFrontPos, hlength, srcMtl, params, resNode, data, mattressData);
      this._createPocketHandle(node3d, -hwidth * relFrontPos, hlength, srcMtl, params, resNode, data, mattressData);
      this._createPocketHandle(node3d, hwidth * relFrontPos, -hlength, srcMtl, params, resNode, data, mattressData);
      this._createPocketHandle(node3d, -hwidth * relFrontPos, -hlength, srcMtl, params, resNode, data, mattressData);
    } else if (largeEnoughFor1FrontHandle) {
      this._createPocketHandle(node3d, 0, hlength, srcMtl, params, resNode, data, mattressData);
      this._createPocketHandle(node3d, 0, -hlength, srcMtl, params, resNode, data, mattressData);
    }

    if (largeEnoughFor2SideHandles) {
      this._createPocketHandle(node3d, hwidth, hlength * relSidePos, srcMtl, params, resNode, data, mattressData);
      this._createPocketHandle(node3d, hwidth, -hlength * relSidePos, srcMtl, params, resNode, data, mattressData);
      this._createPocketHandle(node3d, -hwidth, hlength * relSidePos, srcMtl, params, resNode, data, mattressData);
      this._createPocketHandle(node3d, -hwidth, -hlength * relSidePos, srcMtl, params, resNode, data, mattressData);
    } else if (largeEnoughFor1SideHandle) {
      this._createPocketHandle(node3d, -hwidth, 0, srcMtl, params, resNode, data, mattressData);
      this._createPocketHandle(node3d, hwidth, 0, srcMtl, params, resNode, data, mattressData);
    }

    return resNode;
  }

  _setNodeType(node, type) {
    if (node instanceof BD3DContainerNode3D) {
      node.setNodeType(type);
      const children = node.getChildren();
      const numChildren = children ? children.length : 0;

      for (let i = 0; i < numChildren; ++i) {
        this._setNodeType(children[i], type);
      }
    }
    if (node instanceof BD3DGeometryNode3D) {
      node.setNodeType(type);
    }
  }

  _createPocketHandle(template, xpos, zpos, srcMtl, params, container, data, mattressData) {
    const handle = HandleUtils.attachHandleToBorderNode(template, xpos, 0, zpos, params);

    this._setNodeType(handle, BD3DNodeTypes.pocketHandle);

    if (handle && srcMtl) {
      // const mtl = srcMtl.clone();
      let mtl = srcMtl;

      if (mtl.getSampleTransform && mtl.getQuiltTransform) {
        mtl = srcMtl.clone();
        mtl.setSampleTransform(srcMtl.getSampleTransform());
        mtl.setQuiltTransform(srcMtl.getQuiltTransform());

        if (mtl.setStaticNormalMap) {
          const normalMap = this._getNormalMapAsset(data, mattressData);

          mtl.setStaticNormalMap(normalMap);
        }
      }

      Node3DMaterialUtils.assignMaterial(handle, mtl);
    }

    if (container && handle) {
      container.addChild(handle);
    }

    return handle;
  }

  _setAllowError(errorInfo, error) {
    if (!errorInfo) {
      return;
    }
    errorInfo.error = error;
  }

  isAllowedOnBorder(handleData, mattressData, borderData, errInf) {
    if (!handleData) {
      return false;
    }
    if (!mattressData) {
      return false;
    }
    if (!borderData) {
      return false;
    }

    if (!this.isValidPocketComponent(borderData)) {
      this._setAllowError(errInf, HandleErrorType.POCKET_BASE_ERROR);

      return false;
    }
    const height = borderData.height;

    if (height < MIN_BORDER_HEIGHT) {
      this._setAllowError(errInf, HandleErrorType.POCKET_BASE_ERROR);

      return false;
    }
    const mattressBorder = mattressData.border;
    const mattressBorderComps = mattressBorder ? mattressBorder.components : null;
    const borderIndex = mattressBorderComps ? mattressBorderComps.indexOf(borderData, 0) : -1;

    if (borderIndex < 0) {
      this._setAllowError(errInf, HandleErrorType.POCKET_BASE_ERROR);

      return false;
    }
    const nextBorder = mattressBorderComps[borderIndex + 1];

    if (!this.isValidSeparatorComponent(nextBorder)) {
      return false;
    }

    this._setAllowError(errInf, null);

    return true;
  }

  isAllowed(handleData, mattressData, errorInfo) {
    if (!handleData || !mattressData) {
      return false;
    }
    const border = mattressData.border;

    if (!border) {
      this._setAllowError(errorInfo, HandleErrorType.POCKET_BASE_ERROR);

      return false;
    }
    const components = border.components;

    if (!components) {
      this._setAllowError(errorInfo, HandleErrorType.POCKET_BASE_ERROR);

      return false;
    }
    const numComponents = components.length;

    if (numComponents < MIN_COMPONENTS) {
      this._setAllowError(errorInfo, HandleErrorType.POCKET_BASE_ERROR);

      return false;
    }

    // For now, can't have handles when using a curved border
    const graph = mattressData.border ? mattressData.border.curve : null;

    if (graph) {
      if (GraphUtils.hasGraph(graph)) {
        this._setAllowError(errorInfo, HandleErrorType.POCKET_CURVEDBORDER_ERROR);

        return false;
      }
    }

    let comp, i;

    // check for fabric - pocket
    for (i = 0; i < numComponents; ++i) {
      comp = components[i];
      if (this.isValidSeparatorComponent(comp)) {
        if (i > 0) {
          const prevComp = components[i - 1];

          if (this.isValidPocketComponent(prevComp)) {
            return true;
          }
        }
      }
    }

    /*
    // Check for fabric - piping - fabric
    for (i = 0; i < numComponents; ++i) {
      comp = components[i];
      if (this.isValidSeparatorComponent(comp)) {
        if (i > 0 && i < (numComponents - 1)) {
          const prevComp = components[i - 1];
          const nextComp = components[i + 1];

          if (this.isValidPocketComponent(prevComp) &&
              this.isValidPocketComponent(nextComp)) {
            return true;
          }
        }
      }
    }
    */

    this._setAllowError(errorInfo, HandleErrorType.POCKET_BASE_ERROR);

    return false;
  }
}
