import NumericValue from '../../../data/numeric/NumericValue';

function getValueFromData(data) {
  let value = data;

  if (typeof (data) === 'object' && typeof (data.value) !== 'undefined') {
    value = data.value;
  }

  return value;
}

export default {
  create(data, id, session) {
    const value = getValueFromData(data);

    return new NumericValue(value);
  },
  update(object, data, id, session, srcData) {
    object.setNumber(getValueFromData(data));
  },
  getProperty(object, data, property, params) {
    // #if DEBUG
    console.log('get numeric property', {object, data, property, params});
    // #endif

    return data['@data'];
  },
  setProperty(object, data, property, value, params) {
    // #if DEBUG
    console.log('set numeric property', {object, data, property, value, params});
    // #endif
    data['@data'] = value;
  }
};
