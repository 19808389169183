import {PerspectiveCamera as ThreePerspectiveCamera} from 'three';
import {
  DEFAULT_SCALEMODE,
  ScaleMode,
  getScaleModeValue
} from '../utils/viewlayout';

const DEG2RAD = Math.PI / 180;
const HALF = 0.5;

const {
  SCALEMODE_FILL,
  SCALEMODE_FIT,
  SCALEMODE_FIT_H,
  SCALEMODE_STRETCH
} = ScaleMode;

export default class BD3DCamera extends ThreePerspectiveCamera {
  constructor(fov, aspect, near, far, targetAspect = -1, scaleMode = null) {
    super(fov, aspect, near, far);
    this.targetAspect = targetAspect;
    this.scaleMode = scaleMode;
  }

  setScaleMode(scaleMode) {
    this.scaleMode = getScaleModeValue(scaleMode, DEFAULT_SCALEMODE);
  }

  updateProjectionMatrix() {
    const near = this.near;
    let top = near * Math.tan(DEG2RAD * 0.5 * this.fov) / this.zoom;
    let height = 2 * top;
    let width = this.aspect * height;
    let left = -HALF * width;
    const view = this.view;

    if (this.view !== null && this.view.enabled) {
      const fullWidth = view.fullWidth;
      const fullHeight = view.fullHeight;

      left += view.offsetX * width / fullWidth;
      top -= view.offsetY * height / fullHeight;
      width *= view.width / fullWidth;
      height *= view.height / fullHeight;
    }

    const a = this.aspect || 1;
    const {targetAspect} = this;

    const sm = this.scaleMode || DEFAULT_SCALEMODE;
    const smFill = sm === SCALEMODE_FILL;
    const smFit = sm === SCALEMODE_FIT;

    if (targetAspect > 0 && a > 0) {
      if (smFill || smFit) {
        if (smFill ? a >= targetAspect : a < targetAspect) {
          const factor = targetAspect / a;

          width *= factor;
          height *= factor;
        }
      } else if (sm === SCALEMODE_STRETCH) {
        width = height * targetAspect;
      } else if (sm === SCALEMODE_FIT_H) {
        width = (2 * top) * targetAspect;
        height = width / a;
      }

      left = -width / 2;
      top = height / 2;
    }

    const skew = this.filmOffset;

    if (skew !== 0) {
      left += near * skew / this.getFilmWidth();
    }

    this.projectionMatrix.makePerspective(left, left + width, top, top - height, near, this.far);
    this.projectionMatrixInverse.getInverse(this.projectionMatrix);
  }
}
