const DEFAULT_PIXELS_TO_FLOATDATA_THRESHOLD = 192;
const DEFAULT_PIXELS_TO_FLOATDATA_MINVALUE = 0;
const DEFAULT_PIXELS_TO_FLOATDATA_MAXVALUE = 255;

/**
* @function dt
* @private
* @description Internal function to operate on a row or column of pixel values
* @param {Float32Array} f - input values
* @param {Number} n - number of elements
* @param {Number} posInf - number that represents positive infinity
* @param {Number} negInf - number that represents negative infinity
* @param {Float32Array} zArr - optional preallocated Float32Array to reduce allocations & garbage collection
* @param {Int32Array} vArr - optional preallocated Int32Array to reduce allocations & garbage collection
* @param {Float32Array} dArr - optional preallocated Float32Array to reduce allocations & garbage collection
* @returns {Float32Array} - same reference as dArr param
*/
function dt(f, n, posInf, negInf, zArr, vArr, dArr) {
  let d = dArr;
  let v = vArr;
  let z = zArr;

  if (!d) {
    d = new Float32Array(n);
  }
  if (!v) {
    v = new Int32Array(n);
  }
  if (!z) {
    z = new Float32Array(n + 1);
  }
  let k = 0;

  v[0] = 0;
  z[0] = negInf;
  z[1] = posInf;

  for (let q = 1; q < n; ++q) {
    const fq = f[q];
    const qsq = q * q;
    const fqQsq = fq + qsq;
    const dQ = 2 * q;
    let vk = v[k];
    let vksq = vk * vk;
    let s = (fqQsq - (f[vk] + vksq)) / (dQ - 2 * vk);

    while (s <= z[k]) {
      k--;
      vk = v[k];
      vksq = vk * vk;
      s = (fqQsq - (f[vk] + vksq)) / (dQ - 2 * vk);
    }
    k++;
    v[k] = q;
    z[k] = s;
    z[k + 1] = posInf;
  }

  k = 0;
  for (let q = 0; q < n; ++q) {
    while (z[k + 1] < q) {
      k++;
    }
    const vk = v[k];
    const qvk = q - vk;
    const qvkSq = qvk * qvk;

    d[q] = qvkSq + f[vk];
  }

  return d;
}

/**
* @function distanceTransform2D
* @private
* @description Generates a 2D distance transform on a Float32Array
*   with only min and max values (calculated by thresholdPixels)
* @param {Float32Array} data - min or max value of a pixel
* @param {Number} width - image width
* @param {Number} height - image height
* @return {Float32Array} - Same as the data parameter,
*   but with squared distance values instead of threshold values
*/
function distanceTransform2D(data, width, height) {
  const posInf = width * width + height * height;
  const negInf = -posInf;

  const maxSize = width > height ? width : height;
  const f = new Float32Array(maxSize);

  // transform along columns
  let d, z, v;

  d = new Float32Array(height);
  v = new Int32Array(height);
  z = new Int32Array(height + 1);
  for (let x = 0; x < width; x++) {
    for (let y = 0; y < height; y++) {
      f[y] = data[x + y * width];
    }
    d = dt(f, height, posInf, negInf, z, v, d);
    for (let y = 0; y < height; y++) {
      data[x + y * width] = d[y];
    }
  }

  // transform along rows
  d = new Float32Array(width);
  v = new Int32Array(width);
  z = new Int32Array(width + 1);
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      f[x] = data[x + y * width];
    }
    d = dt(f, width, posInf, negInf, z, v, d);
    for (let x = 0; x < width; x++) {
      data[x + y * width] = d[x];
    }
  }

  return data;
}

/**
* @function distanceTransform2DRepeat
* @private
* @description similar to distanceTransform2D
*   but generates a 'seamless' distance field.
*   For each pixel row and column, the values are copied 3 times
*   and the middle part is used
*    ____________ ____________ ____________
*   | pattern    | pattern    | pattern    |
*                      ^
*              This part is used
* @see distanceTransform2D
* @param {Float32Array} data - array containing threshold data of an image (only min or max values)
* @param {Number} width - image width
* @param {Number} height - image height
* @returns {Float32Array} - Same as the data parameter
*   but with squared distance values instead of threshold values
*/
function distanceTransform2DRepeat(data, width, height) {
  const m3 = 3;
  const posInf = (width * width + height * height) * m3;
  const negInf = -posInf;

  const width3 = width * m3;
  const height3 = height * m3;

  const maxSize = (width3 > height3) ? width3 : height3;
  const f = new Float32Array(maxSize);

  // transform along columns
  let d, z, v;

  d = new Float32Array(height3);
  v = new Int32Array(height3);
  z = new Int32Array(height3 + 1);

  for (let x = 0; x < width; x++) {
    for (let y = 0; y < height3; y++) {

      f[y] = data[x + (y % height) * width];
    }
    d = dt(f, height3, posInf, negInf, z, v, d);
    for (let y = 0; y < height; y++) {
      data[x + y * width] = d[y + height];
    }
  }

  // transform along rows
  d = new Float32Array(width3);
  v = new Int32Array(width3);
  z = new Int32Array(width3 + 1);
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width3; x++) {
      f[x] = data[(x % width) + y * width];
    }
    d = dt(f, width3, posInf, negInf, z, v, d);
    for (let x = 0; x < width; x++) {
      data[x + y * width] = d[x + width];
    }
  }

  return data;
}

/**
* @function thresholdPixels
* @private
* @description Each value of the data array is replaces by minValue,
* unless it's larger than threshold
* @param {Uint8Array|Uint8ClampedArray|Uint32Array} data - source pixel data
* @param {Number} threshold - threshold
* @param {Number} minValue - minimum value is set if the current value <= threshold
* @param {Number} maxValue - maximum value is set if the current value > threshold
* @param {Function} thresholdFunc - optional function to calculate the pixel threshold value
* @return {Float32Array} - result array
*/
function thresholdPixels(data, threshold, minValue, maxValue, thresholdFunc = null) {
  if (!data) {
    return null;
  }
  const isUint8 = (data instanceof Uint8ClampedArray) || (data instanceof Uint8Array);
  const numValues = data.length;
  const l = isUint8 ? (numValues >> 2) : numValues;

  const res = new Float32Array(l);
  let j;
  let th = threshold;
  let minV = minValue;
  let maxV = maxValue;

  if (typeof (th) !== 'number' || isNaN(th)) {
    th = DEFAULT_PIXELS_TO_FLOATDATA_THRESHOLD;
  }
  if (typeof (minV) !== 'number' || isNaN(minV)) {
    minV = DEFAULT_PIXELS_TO_FLOATDATA_MINVALUE;
  }
  if (typeof (maxV) !== 'number' || isNaN(maxV)) {
    maxV = DEFAULT_PIXELS_TO_FLOATDATA_MAXVALUE;
  }
  j = 0;

  const bytesPerColor = 4;
  const isFloat32Array = data instanceof Float32Array;
  const incr = isUint8 ? bytesPerColor : 1;
  const mask = 0xFF;

  for (let i = 0; i < numValues; i += incr) {
    let v = data[i];

    if (!isFloat32Array) {
      v = v & mask;
    }
    if (thresholdFunc) {
      res[j] = thresholdFunc(v, th, minV, maxV);
    } else {
      res[j] = (v > th) ? maxV : minV;
    }
    ++j;
  }

  return res;
}
/**
* @function squareRootFloats
* @private
* @description Replaces all values in the float array with their square roots
* @param {Float32Array} floats - float array
* @return {Float32Array} - same value as the floats parameter
*/
function squareRootFloats(floats) {
  const l = floats.length;

  for (let i = 0; i < l; ++i) {
    floats[i] = Math.sqrt(floats[i]);
  }

  return floats;
}

/**
* @class ImageDistanceTransform
* @description Util for creating distance fields from bitmaps.
* Used to generate 'soft' quilt pattern bump maps
* Source: http://cs.brown.edu/~pff/dt/
*/
export default class ImageDistanceTransform {
  static CircleInterpolate(value) {
    const t = 1.0 - value;

    return Math.sqrt(1 - t * t);
  }

  static SmoothInterpolate(t) {
    const _3 = 3;

    return (_3 - 2 * t) * t * t;
  }

  // A very useless method
  static LinearInterpolate(value) {
    return value;
  }

  /**
   * @method applyGlow
   * @static
   * @description Applies an outer glow effect to the darker parts of an image
   * Used to generate soft gradients for quilt pattern bump maps.
   * @param {Uint8Array|Uint8ClampedArray|Uint32Array} pixels - pixel data
   * @param {Number} minDist - minimum distance (default = 0)
   * @param {Number} maxDist - maximum distance (default = 100)
   * @param {Number} width - The width of the image
   *  Only used to calculate the distances array if null
   * @param {Number} height - The height of the image.
   *  Only used to calculate the distances array if null
   * @param {Float32Array} distancesArray - optional array containing distances per pixel.
   *  If null, this array will be calculated using pixelsToDistances(pixels, width, height)
   * @param {Function} interpFunc - optional function to change the distance interpolation
   * @param {Function} getColorFunc - optional function to return the color by the distance
   * @param {Object} params - optional parameters, see the 'params' parameter of the 'pixelsToDistances' method
   **/
  static applyGlow(pixels, minDist = 0, maxDist = 100, width = 0, height = 0, distancesArray = null, interpFunc = null, getColorFunc = null, params = null) {
    if (!pixels) {
      return;
    }

    let distances = distancesArray;

    if (!distances) {
      distances = this.pixelsToDistances(pixels, width, height, params);
    }
    if (!distances) {
      return;
    }

    const l = distances.length;
    const range = maxDist - minDist;
    const invRange = (range === 0) ? 0 : (1 / range);
    const alpha = 255;
    const isUint32 = pixels instanceof Uint32Array;

    const MAX_COL_VAL = 255;

    const R_SHIFT = 0;
    const G_SHIFT = 8;
    const B_SHIFT = 16;
    const A_SHIFT = 24;

    const R_OFFSET = 0;
    const G_OFFSET = 1;
    const B_OFFSET = 2;
    const A_OFFSET = 3;
    const MASK = 0xFF;

    for (let i = 0; i < l; ++i) {
      let dist = distances[i];

      dist = (dist - minDist) * invRange;
      dist = dist < 0 ? 0 : dist;
      dist = dist > 1 ? 1 : dist;
      if (interpFunc) {
        dist = interpFunc(dist);
      }
      const value = (dist * MAX_COL_VAL) | 0;

      if (isUint32) {
        if (getColorFunc) {
          pixels[i] = getColorFunc(dist, params);
        } else {
          pixels[i] = (alpha << A_SHIFT) | (value << B_SHIFT) | (value << G_SHIFT) | (value << R_SHIFT);
        }
      } else {
        const offset = i << 2;
        let R = value, G = value, B = value, A = alpha;

        if (getColorFunc) {
          const col = getColorFunc(dist, params);

          R = (col >> R_SHIFT) & MASK;
          G = (col >> G_SHIFT) & MASK;
          B = (col >> B_SHIFT) & MASK;
          A = (col >> A_SHIFT) & MASK;
        }
        pixels[offset + R_OFFSET] = R;
        pixels[offset + G_OFFSET] = G;
        pixels[offset + B_OFFSET] = B;
        pixels[offset + A_OFFSET] = A;
      }
    }
  }

  /**
   * @method thresholdImageToDistances
   * @static
   * @description Generates a distance field of an array containing threshold values
   * @param {Float32Array} thresholdPixelData - ...
   * @param {Number} width - The width of the image
   * @param {Number} height - The height of the image
   * @param {Object} params - optional params:
   * - repeat {Boolean} - create a repeating pattern for seamless images (default = true)
   * - squaredDistances {Boolean} - Keeps squared values if true (default = false)
   * @return {Float32Array} - Array containing distances. Same reference as the 'thresholdPixelData' param
   */
  static thresholdImageToDistances(thresholdPixelData, width, height, params = null) {
    if (!thresholdPixelData) {
      return null;
    }
    let repeat = true, squaredDistances = false;

    if (params) {
      repeat = params.repeat !== false;
      squaredDistances = params.squaredDistances === true;
    }
    let result = thresholdPixelData;

    if (repeat) {
      result = distanceTransform2DRepeat(thresholdPixelData, width, height);
    } else {
      result = distanceTransform2D(thresholdPixelData, width, height);
    }
    if (!squaredDistances) {
      squareRootFloats(result);
    }

    return result;
  }

  /**
   * @method pixelsToDistances
   * @static
   * @description Calculates a distance field of pixels.
   *  Each pixel gets the distance to the closest black pixel assigned
   * @param {Uint8Array|Uint8ClampedArray|Uint32Array} pixelData - Array containing pixel data
   * @param {Number} width - width of the image
   * @param {Number} height - height of the image
   * @param {Object} params - optional parameters:
   *  - threshold {Number} - at which value a pixel is concidered 'low' or 'high'(default = lowest pixel value)
   *  - repeat {Boolean} - Generate a repeating pattern (default = true)
   *  - squaredDistances {Boolean} - Keep squared values if true (default = false)
   *  - getPixelThreshold {Function} - Callback function that returns the threshold value for each pixel
   *      signature: getPixelThreshold(value:Number, threshold: Number, lowValue: Number, highValue: Number)
   *  - getThresholdPixels {Function} - Callback function that returns an array of threshold values for each pixel.
   *      If this value is set, the 'getPixelThreshold' callback will be ignored.
   *      signature: getThresholdPixels(pixelData: Uint8Array|Uint8ClampedArray|Uint32Array, params: Object = null)
   * @return {Float32Array} - array with distances
   * */
  static pixelsToDistances(pixelData, width, height, params = null) {
    if (!pixelData) {
      return null;
    }
    let threshold = -1, repeat = true, squaredDistances = false,
      getPixelThreshold, getThresholdPixels = null;

    if (params) {
      threshold = params.threshold;
      repeat = params.repeat !== false;
      squaredDistances = params.squaredDistances === true;
      getPixelThreshold = params.getPixelThreshold || params.getThreshold;
      getThresholdPixels = params.getThresholdPixels;
    }
    if (threshold < 0 || typeof (threshold) !== 'number' || isNaN(threshold)) {
      threshold = DEFAULT_PIXELS_TO_FLOATDATA_THRESHOLD;
    }
    const minValue = 0;
    // maximum squared distance
    const maxValue = width * width + height * height;
    const maxColorValue = 255;

    threshold = threshold < 0 ? 0 : threshold;
    threshold = threshold > maxColorValue ? maxColorValue : threshold;

    let thresholdPixelData = null;

    if (getThresholdPixels) {
      thresholdPixelData = getThresholdPixels(pixelData, params);
    }
    if (!thresholdPixelData) {
      thresholdPixelData = thresholdPixels(pixelData, threshold, minValue, maxValue, getPixelThreshold);
    }
    let result = thresholdPixelData;

    if (repeat) {
      result = distanceTransform2DRepeat(thresholdPixelData, width, height);
    } else {
      result = distanceTransform2D(thresholdPixelData, width, height);
    }

    if (!squaredDistances) {
      squareRootFloats(result);
    }

    return result;
  }
}
