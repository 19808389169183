const defaultKeys = {
  TYPE: '@type',
  ID: '@id',
  REF: '@ref',
  DATA: '@data'
};

function getKey(instance, name, session) {
  return defaultKeys[name];
}

function getTypeKey(instance, session) {
  return getKey(instance, 'TYPE');
}

function getIDKey(instance, session) {
  return getKey(instance, 'ID');
}

function getDataKey(instance, session) {
  return getKey(instance, 'DATA');
}

function getREFKey(instance, session) {
  return getKey(instance, 'REF');
}

function isFactory(instance, value, session) {
  if (!value) {
    return false;
  }
  const typeKey = getTypeKey(instance, session);
  const isNull = !value[typeKey];

  return !isNull;
}

function getObjectKeys(instance, value) {
  if (Object.keys) {
    return Object.keys(value);
  }
  const res = [];

  for (const v in value) {
    if (value.hasOwnProperty(v)) {
      res.push(v);
    }
  }

  return res;
}

function isRef(instance, value, session) {
  if (!value) {
    return false;
  }
  const typeKey = getREFKey(instance, session);
  const isNull = !value[typeKey];

  return !isNull;
}

function assignId(instance, res, id, session) {
  if (!res || !id || !session) {
    return;
  }
  const idMap = session.idMap || {};

  session.idMap = idMap;
  idMap[id] = res;
}

const NOT_FOUND = Symbol('not_found');

function getById(instance, id, session) {
  if (!id || !session) {
    return NOT_FOUND;
  }
  const {idMap} = session;

  if (!idMap) {
    return NOT_FOUND;
  }
  const res = idMap[id];

  if (typeof (res) === 'undefined') {
    return NOT_FOUND;
  }

  return res;
}

function getFactory(instance, type, value, key, parent, session) {
  if (instance.getFactory) {
    const res = instance.getFactory(type, value, key, parent, session);

    if (res) {
      return res;
    }
  }
  if (session && session.getFactory) {
    return session.getFactory(type, value, key, parent, session.params);
  }

  return null;
}

let parseVal = null;

function parseValue(instance, value, key, parent, session) {
  if (value === null) {
    instance._onParsedValue(null, null, session);

    return null;
  }

  const t = typeof (value);


  if (t === 'undefined' || t === 'string' || t === 'number' || t === 'boolean') {
    instance._onParsedValue(value, value, session);

    return value;
  }
  if (!value) {
    instance._onParsedValue(value, null, session);

    return null;
  }
  if ((value instanceof Array) || (Array.isArray && Array.isArray(value))) {
    const num = value.length;
    let result = value;

    for (let i = 0; i < num; ++i) {
      const item = value[i];
      const resItem = parseVal(instance, item, i, value, session);

      if (resItem !== item) {
        if (result === value) {
          result = value.slice(0, i);
        }
      }
      if (result !== value) {
        result.push(resItem);
      }
    }
    instance._onParsedValue(value, result, session);

    return result;
  }

  if (isRef(instance, value, session)) {
    const refId = value[getREFKey(instance, session)];

    const ref = getById(instance, refId, session);

    if (ref === NOT_FOUND) {
      instance._onParsedValue(value, null, session);

      return null;
    }

    instance._onParsedValue(value, ref, session);

    return ref;
  }
  const id = value[getIDKey(instance, session)];
  const dataValue = value[getDataKey(instance, session)];

  let data = dataValue;
  let hasData = false;

  if (dataValue !== null && typeof (dataValue) !== 'undefined') {
    data = parseVal(instance, dataValue, key, parent, session);
    hasData = true;
  }

  if (isFactory(instance, value, session)) {
    const factories = session.factories || session.API;
    const type = value[getTypeKey(instance, session)];

    if (type) {
      const factory = getFactory(instance, type, value, key, parent, session) || (factories ? factories[type] : null);

      if (factory && factory.call && factory.apply) {
        const sessionAccessor = session ? session.accessor : null;
        const reslt = factory(data, id, sessionAccessor);

        if (instance._assignId) {
          instance._assignId(reslt, id, session, value);
        } else {
          assignId(instance, reslt, id, session);
        }

        instance._onParsedInstance(value, reslt, session);

        return reslt;
      }

      return null;
    }
    const res = parseVal(instance, data, key, parent, session);

    if (instance._assignId) {
      instance._assignId(res, id, session, value);
    } else {
      assignId(instance, res, id, session);
    }
    instance._onParsedValue(value, res, session);

    return res;
  }

  let resObject = hasData ? data : value;
  const srcObject = hasData ? dataValue : value;

  const keys = getObjectKeys(instance, srcObject);
  const numKeys = keys ? keys.length : 0;

  for (let i = 0; i < numKeys; ++i) {
    const k = keys[i];
    const item = srcObject[k];
    const resItem = parseVal(instance, item, k, srcObject, session);

    if (item !== resItem) {
      if (resObject === srcObject) {
        resObject = {};
        for (let j = 0; j < i; ++j) {
          const k2 = keys[j];

          resObject[k2] = srcObject[k2];
        }
      }
    }
    if (resObject !== srcObject) {
      resObject[k] = resItem;
    }
  }

  if (id !== null && typeof (id) !== 'undefined') {
    assignId(instance, resObject, id, session);
  }

  instance._onParsedValue(value, resObject, session);

  return resObject;
}

parseVal = (instance, value, key, parent, session) => {
  if (instance && instance._parseValue) {
    return instance._parseValue(value, key, parent, session);
  }

  return parseValue(instance, value, key, parent, session);
};

const SESSION = Symbol('session');
const SESSION_REF = Symbol('session_ref');

class SessionAccessor {
  constructor(sess) {
    this[SESSION_REF] = sess;
  }

  getData(key) {
    const sess = this[SESSION_REF];
    const data = sess ? sess.data : null;

    if (!data) {
      return null;
    }

    return data[key];
  }

  setData(key, value) {
    const sess = this[SESSION_REF];

    if (!sess) {
      return;
    }
    const data = sess.data || {};

    sess.data = data;
    data[key] = value;
  }

  getParams() {
    const sess = this[SESSION_REF];

    if (!sess) {
      return null;
    }

    return sess.params;
  }

  get params() {
    return this.getParams();
  }

  getObjectById(id) {
    const sess = this[SESSION_REF];

    if (!sess) {
      return null;
    }
    const map = sess.idMap;

    if (!map) {
      return null;
    }

    return map[id];
  }
}

export default class BGraphParser {
  getFactory(id, value, key, parent, session) {
    return null;
  }

  parseJSONString(json, params = null) {
    let jsonObj = json;

    if (typeof (json) === 'string') {
      try {
        jsonObj = JSON.parse(json);
      } catch (e) {
        console.warn(e);
      }
    }

    return this.parseJSON(jsonObj, params);
  }

  // protected
  _initSession(session) {
    return;
  }

  // protected
  _getSession() {
    return this[SESSION];
  }

  // protected
  _onParsedValue(json, result, session) {
    return;
  }

  // protected
  _onParsedInstance(json, result, session) {
    return;
  }

  // protected
  _assignId(object, id, session, value) {
    assignId(this, object, id, session);
  }

  // protected
  _parseValue(value, key, parent, session) {
    return parseValue(this, value, key, parent, session);
  }

  // protected
  _onParsed(value, result, session) {
    if (session !== null) {
      session.idMap = null;
      session.factories = null;
      session.params = null;
    }
  }

  parseJSON(jsonObj, params = null) {
    if (!jsonObj) {
      return null;
    }

    const factories = params ? params.API || params.factories : null;
    const idMap = params ? params.idmap || params.objectsById : null;

    let session = this[SESSION];

    if (session) {
      session.idMap = idMap;
      session.factories = factories;
      session.params = params;
    } else {
      session = {
        idMap,
        factories,
        params,
        accessor: null
      };
      session.accessor = new SessionAccessor(session);
      this[SESSION] = session;
    }
    this._initSession(session);
    const res = parseVal(this, jsonObj, null, null, session);

    if (params) {
      let key = null;

      if (params.idmap) {
        key = 'idmap';
      } else {
        key = 'objectsById';
      }
      params[key] = idMap;
    }
    this._onParsed(jsonObj, res, session);

    return res;
  }
}
