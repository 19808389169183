import Asset from './Asset';
import AssetGroup from './AssetGroup';
import ImageAsset from './ImageAsset';
import MattressDA from '../mattress/MattressDA';
import QuiltDA from '../quilt/QuiltDA';
import QuiltTypes from '../quilt/QuiltTypes';
import CanvasUtils from '../utils/CanvasUtils';
import BumpNormalUtils from '../utils/BumpNormalUtils';
// ImageDistanceTransform calculates the distance field from a bitmap
import ImageDistanceTransform from '../quilt/ImageDistanceTransform';
import {PIXELS_TO_CM} from '../utils/units';

const FALLBACK_TEXTURE_WIDTH = 1000;
const FALLBACK_TEXTURE_HEIGHT = 1000;

// Convert from pixels to cm (96 dpi)
const FALLBACK_TEXTURE_SCALE = PIXELS_TO_CM;

function addImageAsset(quiltAsset, images, key) {
  const img = images[key];
  let imgAsset = null;

  if (img instanceof ImageAsset) {
    imgAsset = img;
  } else if (CanvasUtils.isImage(img) || CanvasUtils.isCanvas(img)) {
    imgAsset = new ImageAsset(key);

    imgAsset.setImage(img);
  }
  if (imgAsset) {
    if (!quiltAsset.assets) {
      quiltAsset.assets = new AssetGroup();
    }
    quiltAsset.assets.addAsset(imgAsset, key);
  }
}

export default class QuiltAsset extends Asset {
  constructor(id, quiltConfigData = null, params = null) {
    super(id, 'quilt', params);
    this.quiltConfigData = quiltConfigData;
    this.quiltData = null;
    this.assets = null;

    this._bumpDistanceField = null;
  }

  getFallbackWidth() {
    return this.getTextureWidth(FALLBACK_TEXTURE_WIDTH) * FALLBACK_TEXTURE_SCALE;
  }

  getFallbackHeight() {
    return this.getTextureHeight(FALLBACK_TEXTURE_HEIGHT) * FALLBACK_TEXTURE_SCALE;
  }

  getTextureWidth(fallback = 0) {
    const tex = this.getBumpMap() || this.getNormalMap() || this.getTexture();

    if (!tex) {
      return fallback;
    }
    if (tex instanceof ImageAsset) {
      return tex.getWidth(fallback);
    }

    return fallback;
  }

  getTextureHeight(fallback = 0) {
    const tex = this.getBumpMap() || this.getNormalMap() || this.getTexture();

    if (!tex) {
      return fallback;
    }
    if (tex instanceof ImageAsset) {
      return tex.getHeight(fallback);
    }

    return fallback;
  }


  getRepeatType() {
    return QuiltDA.getRepeatType(this.quiltConfigData, this.quiltData);
  }

  getRepeatX() {
    return QuiltDA.getResultRepeatX(this.quiltConfigData, this.quiltData, 0);
  }

  getRepeatY() {
    return QuiltDA.getResultRepeatY(this.quiltConfigData, this.quiltData, 0);
  }

  getQuiltConfigData() {
    return this.quiltConfigData;
  }

  setQuiltConfigData(v) {
    this.quiltConfigData = v;
  }

  _getFirstImageAsset() {
    const assets = this.assets;

    if (!assets) {
      return null;
    }
    const num = assets.getNumAssets();

    if (!num) {
      return null;
    }
    for (let i = 0; i < num; ++i) {
      const asset = assets.getAssetAt(i);

      if (asset instanceof ImageAsset) {
        return asset;
      }
    }

    return null;
  }

  getImageWidth() {
    const imgAsset = this._getFirstImageAsset();

    if (!imgAsset) {
      return 0;
    }

    return imgAsset.getWidth();
  }

  getImageHeight() {
    const imgAsset = this._getFirstImageAsset();

    if (!imgAsset) {
      return 0;
    }

    return imgAsset.getHeight();
  }


  getWidth(fallback = 0) {
    return QuiltDA.getRealWidth(this.quiltConfigData, this.quiltData, fallback);
  }

  getHeight(fallback = 0) {
    return QuiltDA.getRealHeight(this.quiltConfigData, this.quiltData, fallback);
  }

  // TODO: return quilt type (image or grid)
  getQuiltType() {
    const type = QuiltDA.getType(this.quiltConfigData, this.quiltData);

    return QuiltTypes.getTypeName(type);
  }

  getQuiltID() {
    const quiltConfigData = this.quiltConfigData;

    if (typeof (quiltConfigData) === 'string') {
      return quiltConfigData;
    }

    return MattressDA.getQuiltID(quiltConfigData);
  }

  getNormalMap() {
    return this.getAsset('normal');
  }

  getBumpMap() {
    return this.getAsset('bump');
  }

  getTexture() {
    return this.getAsset('image') || this.getAsset('texture');
  }

  updateBumpDistanceField() {
    const bumpAsset = this.getBumpMap();
    const bumpImg = bumpAsset ? bumpAsset.getImage() : null;

    if (bumpImg && BumpNormalUtils.isBumpMap(bumpImg)) {
      const distFieldParams = null;
      const imgData = CanvasUtils.toImageData(bumpImg);
      const width = CanvasUtils.getWidth(bumpImg);
      const height = CanvasUtils.getHeight(bumpImg);
      const distancesArray = ImageDistanceTransform.pixelsToDistances(imgData.data, width, height, distFieldParams);

      this._bumpDistanceField = distancesArray;

      return this._bumpDistanceField;
    }

    return null;
  }

  getBumpDistanceField() {
    const distanceField = this._bumpDistanceField;
    const bumpAsset = this.getBumpMap();
    const img = bumpAsset ? bumpAsset.getImage() : null;
    const width = img ? CanvasUtils.getWidth(img) : 0;
    const height = img ? CanvasUtils.getHeight(img) : 0;
    const dataLen = width * height;

    if (distanceField && distanceField.length === dataLen) {
      return distanceField;
    }

    if (!bumpAsset) {
      return null;
    }

    if (!img) {
      return null;
    }
    if (BumpNormalUtils.isBumpMap(img)) {
      const distFieldParams = null;
      const imgData = CanvasUtils.toImageData(img);
      const distancesArray = ImageDistanceTransform.pixelsToDistances(imgData.data, width, height, distFieldParams);

      this._bumpDistanceField = distancesArray;

      return this._bumpDistanceField;
    }

    return null;
  }

  get texture() {
    return this.getTexture();
  }

  get normalMap() {
    return this.getNormalMap();
  }

  get bumpMap() {
    return this.getBumpMap();
  }

  get bumpDistanceField() {
    return this.getBumpDistanceField();
  }

  getAsset(key) {
    if (!key) {
      return null;
    }
    const assets = this.assets;

    if (!assets) {
      return null;
    }

    return assets.getAsset(key);
  }

  setData(data) {
    super.setData(data);

    const assets = this.assets;

    if (assets) {
      assets.clear();
    }

    this.quiltData = data.quiltData || data.data || data.json;
    this._bumpDistanceField = data.bumpDistanceField;
    const images = data.images;

    if (images) {
      for (const v in images) {
        if (images.hasOwnProperty(v)) {
          addImageAsset(this, images, v);
        }
      }
    }
  }
}
