import * as THREE from 'three';
import CanvasUtils from '../../bgr/common/utils/CanvasUtils.js';

export default class AssetUtils {

  static _getTextureImage(img, params) {
    return CanvasUtils.resizeToPO2(img, params);
  }

  static getTexture(imageAsset, params, create = true) {
    if (!imageAsset) {
      return null;
    }
    let d = imageAsset.getData();

    if (!create) {
      if (!d) {
        return null;
      }
      if (d instanceof Image) {
        return null;
      }

      return d.texture;
    }

    let texNeedsUpdate = false;

    let img;

    if (!d || d instanceof Image) {
      img = d;

      d = {image: img};

      imageAsset.setData(d);
    }

    let tex = d.texture;

    if (!tex) {
      tex = d.texture = new THREE.Texture();
      texNeedsUpdate = true;
    }
    let texImage = d.textureImage;

    if (!texImage) {
      texImage = d.textureImage = this._getTextureImage(img);
    }

    if (texImage !== tex.image) {
      tex.image = texImage;
      texNeedsUpdate = true;
    }

    if (texNeedsUpdate) {
      tex.needsUpdate = true;
    }

    return tex;
  }
}
