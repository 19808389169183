import Asset from './Asset';
import AssetHolder from './AssetHolder';

/**
 * @class AssetGroup
 * @extends AssetHolder
 * @description AssetHolder extension that holds real assets
 **/
export default class AssetGroup extends AssetHolder {
  constructor(name, assets) {
    super(null);
    let n, a;

    if (name instanceof Array) {
      a = name;
    } else if (typeof (name) === 'string') {
      n = name;
    }

    if (typeof (assets) === 'string') {
      n = assets;
    } else if (assets instanceof Array) {
      a = assets;
    }

    this._name = n;

    this.addAssets(a);
  }

  getNumAssets() {
    const assets = this._assets;

    if (!assets) {
      return 0;
    }

    return assets.length;
  }

  get numAssets() {
    return this.getNumAssets();
  }

  addAssets(assets) {
    if (!assets) {
      return;
    }
    if (assets instanceof Array) {
      const l = assets.length;

      if (l === 0) {
        return;
      }
      for (let i = 0; i < l; ++i) {
        const asset = assets[i];

        this.addAsset(asset);
      }
    } else {
      let v;

      for (v in assets) {
        if (assets.hasOwnProperty(v)) {
          this.addAsset(assets[v], v);
        }
      }
    }
  }

  clear() {
    this.clearAssets();
  }

  clearAssets() {
    this._assets = null;
    this._assetsByName = null;
  }

  getAssetAt(index) {
    if (index < 0 || isNaN(index)) {
      return null;
    }
    const assets = this._assets;

    if (!assets) {
      return null;
    }

    return assets[index];
  }

  getAssetByName(name) {
    const assetsByName = this._assetsByName;

    if (!assetsByName) {
      return null;
    }

    return assetsByName[name];
  }

  _removeAssetFromMap(asset, map) {
    if (!asset || !map) {
      return;
    }
    for (const v in map) {
      if (map.hasOwnProperty(v)) {
        const a = map[v];

        if (a === asset) {
          Reflect.deleteProperty(map, v);
        }
      }
    }
  }

  _removeAssetFromArray(asset, array) {
    if (!asset || !array) {
      return;
    }
    const idx = array.indexOf(asset, 0);

    if (idx < 0) {
      return;
    }
    array.splice(idx, 1);
  }

  removeAsset(asset) {
    if (asset === null || typeof (asset) === 'undefined') {
      return;
    }
    const assets = this._assets;
    const assetsByName = this._assetsByName;

    if (asset instanceof Asset) {
      this._removeAssetFromArray(asset, assets);
      this._removeAssetFromMap(asset, assetsByName);
    } else if (typeof (asset) === 'string') {
      if (assetsByName) {
        const realAsset = assetsByName[asset];

        this._removeAssetFromArray(realAsset, assets);
        // Can't simply call Reflect.deleteProperty(assetsByName, asset)
        // because other references to this asset could be in the assetsByName map
        //  -> Those references should also be removed
        this._removeAssetFromMap(realAsset, assetsByName);
      }
    }
  }

  _callForEachCallback(cb, asset, cbData) {
    if (!cb) {
      return null;
    }

    return cb(asset, cbData);
  }

  forEachAsset(callback, callbackData = null) {
    if (!callback) {
      return;
    }
    const assets = this._assets;

    if (!assets) {
      return;
    }
    const num = assets.length;

    if (num === 0) {
      return;
    }

    for (let i = 0; i < num; ++i) {
      const asset = assets[i];

      if (asset && asset instanceof Asset) {
        this._callForEachCallback(callback, asset, callbackData);
      }
    }
  }

  addAsset(asset, key = null) {
    let k = key;

    if (!asset) {
      return;
    }
    if (Asset && !(asset instanceof Asset)) {
      return;
    }
    let assets = this._assets;

    if (!assets) {
      assets = this._assets = [];
    }
    const idx = assets.indexOf(asset, 0);

    if (idx < 0) {
      assets.push(asset);
    }
    if (!k) {
      k = asset.getName();
    }
    if (k) {
      let assetsByName = this._assetsByName;

      if (!assetsByName) {
        assetsByName = this._assetsByName = {};
      }
      assetsByName[k] = asset;
    }
  }
}
