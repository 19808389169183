import * as THREE from 'three';


class ShadowRenderer {
  constructor() {
    this._currentRenderer = null;
    this._defaultRenderBufferDirect = null;
    this._depthRenderBufferDirect = null;
  }
  dispose() {
    const dm = this._depthMaterial;

    this._depthMaterial = null;
    if (dm && dm.dispose) {
      dm.dispose();
    }

    this._currentRenderer = null;
  }

  _getCamera() {
    let c = this._camera;

    if (!c) {
      c = this._camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);
    }

    return c;
  }

  _getDepthMaterial() {
    let m = this._depthMaterial;

    if (!m) {
      m = this._depthMaterial = new THREE.RawShaderMaterial({
        vertexShader: [
          'precision mediump float;',

          'attribute vec3 position;',
          'uniform mat4 modelViewMatrix;',
          'uniform mat4 projectionMatrix;',
          'uniform float minimumZ;',
          'uniform float maximumZ;',

          'varying float v_depth;',

          'void main() {',
          '  vec4 pos = vec4(position, 1.0);',
          '  pos = modelViewMatrix * pos;',
          '  float minZ = -minimumZ;',
          '  float maxZ = -maximumZ;',
          '  float zRange = maxZ - minZ;',
          '  v_depth = (pos.z - minZ) / zRange;',
          '  pos = projectionMatrix * pos;',
          '  gl_Position = pos;',
          '}'
        ].join('\n'),
        fragmentShader: [
          'precision mediump float;',

          'varying float v_depth;',

          'void main() {',
          ' float d = clamp(v_depth, 0.0, 1.0);',
          ' d = 1.0 - d;',
          ' d = sqrt(1.0 - d * d);',
          ' gl_FragColor = vec4(vec3(d), 1);',
          '}'
        ].join('\n')
      });
    }

    return m;
  }

  _handleDepthRenderBufferDirect(camera, fog, geometry, material, object, group) {
    const r = this._currentRenderer;
    const defRBD = this._defaultRenderBufferDirect;
    const oldRBD = r.renderBufferDirect;
    const mat = this._getDepthMaterial();
    // const mat = material;

    r.renderBufferDirect = defRBD;
    r.renderBufferDirect(camera, fog, geometry, mat, object, group);

    r.renderBufferDirect = oldRBD;
  }

  renderShadow(renderer, scene, target, width, height, transform, near, far) {
    if (!renderer || !scene) {
      return;
    }
    const c = this._getCamera();
    const hw = width * 0.5;
    const hh = height * 0.5;
    const left = -hw;
    const right = hw;
    const top = hh;
    const bottom = -hh;
    // const near = -1;
    // const far = 20;

    if (transform instanceof THREE.Object3D) {
      c.matrixAutoUpdate = true;
      c.rotation.x = transform.rotation.x;
      c.rotation.y = transform.rotation.y;
      c.rotation.z = transform.rotation.z;
      c.rotation.order = transform.rotation.order;
      c.position.x = transform.position.x;
      c.position.y = transform.position.y;
      c.position.z = transform.position.z;

    } else if (transform instanceof THREE.Matrix4) {
      c.matrixAutoUpdate = false;
      if (!c.matrix) {
        c.matrix = new THREE.Matrix4();
      }
      c.matrix.copy(transform);
      if (!c.matrixWorld) {
        c.matrixWorld = new THREE.Matrix4();
      }
      c.matrixWorld.copy(c.matrix);
      if (!c.matrixWorldInverse) {
        c.matrixWorldInverse = new THREE.Matrix4();
      }
      c.matrixWorldInverse.getInverse(c.matrixWorld, false);
    } else {
      c.matrixAutoUpdate = true;
      c.rotation.x = Math.PI * 0.5;
      c.position.x = 0;
      c.position.y = 0;
      c.position.z = 0;
    }

    const mat = this._getDepthMaterial();

    if (!mat.uniforms) {
      mat.uniforms = {};
    }
    const matU = mat.uniforms;

    if (!matU.minimumZ) {
      matU.minimumZ = new THREE.Uniform(0);
    }
    if (!matU.maximumZ) {
      matU.maximumZ = new THREE.Uniform(0);
    }
    matU.minimumZ.value = near;
    matU.maximumZ.value = far;

    if (
      c.left !== left || c.right !== right ||
      c.top !== top || c.bottom !== bottom ||
      c.near !== near || c.far !== far
    ) {
      c.left = left;
      c.right = right;
      c.top = top;
      c.bottom = bottom;
      c.near = near;
      c.far = far;
      c.updateProjectionMatrix();
    }
    const defRBD = renderer.renderBufferDirect;
    let depthRBD = this._depthRenderBufferDirect;

    this._defaultRenderBufferDirect = defRBD;
    this._currentRenderer = renderer;

    if (!depthRBD) {
      const that = this;

      depthRBD = this._depthRenderBufferDirect = (camera, fog, geometry, material, object, group) => {
        return that._handleDepthRenderBufferDirect(camera, fog, geometry, material, object, group);
      };
    }
    renderer.renderBufferDirect = depthRBD;

    const prevClearColor = renderer.getClearColor();
    const prevClearAlpha = renderer.getClearAlpha();

    if (target) {
      const WHITE = 0xFFFFFF;

      renderer.setRenderTarget(target);
      renderer.setClearColor(WHITE, 1.0);
      renderer.clear();
    }
    renderer.render(scene, c, target, true);
    renderer.renderBufferDirect = defRBD;

    if (target) {
      renderer.setRenderTarget(null);
    }
    renderer.setClearColor(prevClearColor, prevClearAlpha);
  }
}

export default class ShadowPlane extends THREE.Object3D {
  constructor(args = null) {
    super();
    if (args) {
      this._shadowRenderer = args.shadowRenderer;
      this._planeGeometry = args.planeGeometry;
    }
  }

  dispose() {
    const rt = this._getRenderTargets(false);

    if (rt) {
      for (const v in rt) {
        if (rt.hasOwnProperty(v)) {
          const tgt = rt[v];

          if (tgt) {
            tgt.dispose();
            rt[v] = null;
          }
        }
      }
    }
    if (this._interpMaterial) {
      this._interpMaterial.dispose();
    }
    if (this._finalMaterial) {
      this._finalMaterial.dispose();
    }

    return;
  }

  _updateMatrixWorld(object) {
    if (!object || (!object instanceof THREE.Object3D)) {
      return;
    }
    const p = object.parent;

    if (object.matrixAutoUpdate) {
      object.updateMatrix();
    }
    if (!object.matrixWorld) {
      object.matrixWorld = new THREE.Matrix4();
    }
    if (p) {
      this._updateMatrixWorld(p);
      object.matrixWorld.multiplyMatrices(p.matrixWorld, object.matrix);
    } else {
      object.matrixWorld.copy(object.matrix);
    }
  }

  _getMesh() {
    let m = this._mesh;

    if (!m) {
      m = this._mesh = new THREE.Mesh();
      if (!m.userData) {
        m.userData = {};
      }
      m.userData.pointerEvents = false;
      m.rotation.x = Math.PI;
      m.rotation.z = Math.PI;
      m.geometry = this._getPlaneGeometry();
      this.add(m);
    }

    return m;
  }

  _getRenderTargets(create) {
    let renderTargets = this._renderTargets;

    if (renderTargets || !create) {
      return renderTargets;
    }
    renderTargets = this._renderTargets = {};

    return renderTargets;
  }

  _getRenderTarget(name, create) {
    if (!name) {
      return null;
    }
    const renderTargets = this._getRenderTargets(create);

    if (!renderTargets) {
      return null;
    }
    let res = renderTargets[name];

    if (res || !create) {
      return res;
    }
    const DEFW = 512;
    const DEFH = 512;

    res = renderTargets[name] = new THREE.WebGLRenderTarget(DEFW, DEFH);
    res.texture.anisotropy = 4;

    return res;
  }

  _getRTTCam() {
    let c = this._rttCamera;

    if (!c) {
      c = this._rttCamera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);
    }

    return c;
  }

  _getRTTScene() {
    let s = this._rttScene;

    if (!s) {
      s = this._rttScene = new THREE.Scene();
      s.add(this._getRTTMesh());
    }

    return s;
  }

  _getRTTMesh() {
    let m = this._rttMesh;

    if (!m) {
      const g = this._getPlaneGeometry();

      m = this._rttMesh = new THREE.Mesh(g);
    }

    return m;
  }

  _renderRTT(renderer, mtl, target, autoClear) {
    if (!renderer) {
      return;
    }
    const s = this._getRTTScene();
    const c = this._getRTTCam();

    if (mtl) {
      const m = this._getRTTMesh();

      m.material = mtl;
    }

    renderer.render(s, c, target, autoClear);
  }

  _getBlurMaterial(texture, dirX, dirY, minScale, maxScale) {
    let bm = this._blurMaterial;

    if (!bm) {
      bm = ShadowPlane._getBlurMaterial();
    }

    let uniforms = bm.uniforms;

    if (!uniforms) {
      uniforms = bm.uniforms = {};
    }
    if (texture) {
      const tex = this._findTexture(texture);

      let texUniform = uniforms.texture;

      if (!texUniform) {
        texUniform = uniforms.texture = new THREE.Uniform();
      }
      texUniform.value = tex;
    }

    let dirUniform = uniforms.direction;

    if (!dirUniform) {
      dirUniform = uniforms.direction = new THREE.Uniform();
    }
    let dir = dirUniform.value;

    if (!dir) {
      dir = dirUniform.value = new THREE.Vector2(0, 0);
    }
    dir.x = dirX;
    dir.y = dirY;

    let blurRangeUniform = uniforms.blurRange;

    if (!blurRangeUniform) {
      blurRangeUniform = uniforms.blurRange = new THREE.Uniform();
    }

    let blurRange = blurRangeUniform.value;

    if (!blurRange) {
      blurRange = blurRangeUniform.value = new THREE.Vector2();
    }
    blurRange.x = minScale;
    blurRange.y = maxScale;

    return bm;
  }

  static _getBlurMaterial() {
    let m = this._blurMaterial;

    if (!m) {
      m = this._blurMaterial = new THREE.RawShaderMaterial({
        vertexShader: [
          'precision mediump float;',
          'attribute vec3 position;',
          'varying vec2 v_uv;',
          'void main() {',
          ' vec4 pos = vec4(position, 1.0);',
          ' v_uv = (pos.xy + vec2(1.0)) * 0.5;',
          ' gl_Position = pos;',
          '}'
        ].join('\n'),
        fragmentShader: [
          '#define SAMPLES 6',
          'precision mediump float;',
          'varying vec2 v_uv;',
          'uniform sampler2D texture;',
          'uniform vec2 direction;',
          'uniform vec2 blurRange;',
          'void main() {',
          ' float pixelBlurScale = texture2D(texture, v_uv).x;',

          ' float pixelBlurMin = 0.1;',
          ' float pixelBlurMax = 0.2;',
          ' pixelBlurScale = (pixelBlurScale - pixelBlurMin) / (pixelBlurMax - pixelBlurMin);',
          ' pixelBlurScale = clamp(pixelBlurScale, 0.0, 1.0);',

          ' pixelBlurScale = blurRange.x + (blurRange.y - blurRange.x) * pixelBlurScale;',
          ' vec4 res = vec4(0);',
          ' const float hsamples = (float(SAMPLES) - 1.0) / 2.0;',
          ' for (int i = 0; i < SAMPLES; ++i) {',
          '   vec2 uv = v_uv + direction * (float(i) - hsamples) * pixelBlurScale;',
          '   res += texture2D(texture, uv);',
          ' }',
          ' res /= float(SAMPLES);',
          ' gl_FragColor = res;',
          '}'
        ].join('\n')
      });
    }

    return m;
  }

  _findTexture(tex) {
    if (tex instanceof THREE.Texture) {
      return tex;
    }
    if (tex instanceof THREE.WebGLRenderTarget) {
      return tex.texture;
    }

    return null;
  }

  _blurTexture(renderer, tex, texW, texH, minScaleX, maxScaleX, minScaleY, maxScaleY, blurQuality, numPasses, target) {
    if (!tex) {
      return;
    }
    const bm = this._getBlurMaterial(tex, 0, 0, 1, 1);

    if (numPasses < 1) {
      this._renderRTT(renderer, bm, target);
    } else {
      const dir = bm.uniforms.direction.value;
      const blurR = bm.uniforms.blurRange.value;

      const blur1 = this._getRenderTarget('blur1', true);
      const blur2 = this._getRenderTarget('blur2', true);

      const blurW = (texW * blurQuality) | 0;
      const blurH = (texH * blurQuality) | 0;

      blur1.setSize(blurW, blurH);
      blur2.setSize(blurW, blurH);

      let texIn = tex;
      let texOut = blur1;

      const totalPasses = numPasses << 1; // x2 for x and y
      const lastPassIndex = totalPasses - 1;
      const middlePassIndex = numPasses;

      const texUniform = bm.uniforms.texture;

      let vdir = false;

      dir.x = 1 / blurW;
      dir.y = 0;
      blurR.x = minScaleX;
      blurR.y = maxScaleX;

      for (let i = 0; i < totalPasses; ++i) {
        if (i === lastPassIndex) {
          texOut = target;
        }
        const indexSetVDir = i >= middlePassIndex;

        if (indexSetVDir !== vdir) {
          vdir = true;
          dir.x = 0;
          dir.y = 1 / blurH;
          blurR.x = minScaleY;
          blurR.y = maxScaleY;
        }

        texUniform.value = this._findTexture(texIn);
        this._renderRTT(renderer, bm, texOut);
        if (i < lastPassIndex) {
          if (texOut === blur1) {
            texOut = blur2;
            texIn = blur1;
          } else {
            texOut = blur1;
            texIn = blur2;
          }
        }
      }
    }
  }

  _getInterpTextureMaterial(texA, texB, texInterp) {
    let mat = this._interpMaterial;

    if (!mat) {
      mat = this._interpMaterial = new THREE.RawShaderMaterial({
        vertexShader: [
          'precision mediump float;',
          'attribute vec3 position;',
          'varying vec2 v_uv;',
          'void main() {',
          ' vec4 pos = vec4(position, 1.0);',
          ' v_uv = (pos.xy + vec2(1.0)) * 0.5;',
          ' gl_Position = pos;',
          '}'
        ].join('\n'),
        fragmentShader: [
          'precision mediump float;',
          'varying vec2 v_uv;',
          'uniform sampler2D textureA;',
          'uniform sampler2D textureB;',
          'uniform sampler2D interpTex;',
          'void main() {',
          ' vec4 texA = texture2D(textureA, v_uv);',
          ' vec4 texB = texture2D(textureB, v_uv);',
          ' vec4 texI = texture2D(interpTex, v_uv);',

          ' float t = texI.r + (1.0 - texI.a);',
          ' float mixMin = 0.2;',
          ' float mixMax = 0.8;',
          ' t = (t - mixMin) / (mixMax - mixMin);',
          ' t = clamp(t, 0.0, 1.0);',
          ' gl_FragColor = mix(texA, texB, t);',
          '}'
        ].join('\n')
      });
    }
    let u = mat.uniforms;

    if (!u) {
      u = mat.uniforms = {};
    }
    let uTexA = u.textureA;

    if (!uTexA) {
      uTexA = u.textureA = new THREE.Uniform();
    }
    let uTexB = u.textureB;

    if (!uTexB) {
      uTexB = u.textureB = new THREE.Uniform();
    }
    let uTexI = u.interpTex;

    if (!uTexI) {
      uTexI = u.interpTex = new THREE.Uniform();
    }

    uTexA.value = this._findTexture(texA);
    uTexB.value = this._findTexture(texB);
    uTexI.value = this._findTexture(texInterp);

    return mat;
  }

  _interpTexture(renderer, texA, texB, texInterp, output) {
    const mat = this._getInterpTextureMaterial(texA, texB, texInterp);

    this._renderRTT(renderer, mat, output);
  }

  _getFinalMaterial(texture) {
    let mat = this._finalMaterial;

    if (!mat) {
      mat = this._finalMaterial = new THREE.RawShaderMaterial({
        vertexShader: [
          'precision mediump float;',
          'attribute vec3 position;',
          'attribute vec2 uv;',
          'varying vec2 v_uv;',
          'uniform mat4 modelViewMatrix;',
          'uniform mat4 projectionMatrix;',
          'void main() {',
          ' v_uv = uv;',
          ' gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);',
          '}'
        ].join('\n'),
        fragmentShader: [
          'precision mediump float;',
          'varying vec2 v_uv;',
          'precision mediump float;',
          'uniform sampler2D texture;',
          'void main() {',
          ' vec2 uv = v_uv;',
          ' vec4 col = texture2D(texture, v_uv);',

          'col.a = 1.0 - col.r;',
          'col.rgb = vec3(0.0);',

          ' gl_FragColor = col;',
          '}'
        ].join('\n')
      });
    }
    let u = mat.uniforms;

    if (!u) {
      u = mat.uniforms = {};
    }
    let texUniform = u.texture;

    if (!texUniform) {
      texUniform = u.texture = new THREE.Uniform();
    }
    const tex = this._findTexture(texture);

    texUniform.value = tex;
    mat.transparent = true;

    return mat;
  }

  render(renderer, scene) {
    const sr = this._getShadowRenderer();
    const m = this._getMesh();

    const sceneRT = this._getRenderTarget('scene', true);

    // const texW = 512;
    // const texH = 512;
    const texW = 1024;
    const texH = 1024;

    sceneRT.setSize(texW, texH);

    this._updateMatrixWorld(this);

    const maxDepth = 40;
    const minDepth = -1;

    sr.renderShadow(renderer, scene, sceneRT, 2, 2, this.matrixWorld, minDepth, maxDepth);

    const blurredNear = this._getRenderTarget('blurredNear', true);
    const blurredFar = this._getRenderTarget('blurredFar', true);

    const farBlurQuality = 0.25;
    const farBlurPasses = 4;

    const nearBlurQuality = 1;
    const nearBlurPasses = 2;

    let minScaleX, maxScaleX, minScaleY, maxScaleY, blurQuality, numPasses;

    minScaleX = minScaleY = 1;
    maxScaleX = maxScaleY = 1;
    blurQuality = farBlurQuality;
    numPasses = farBlurPasses;

    this._blurTexture(renderer, sceneRT, sceneRT.width, sceneRT.height, minScaleX, maxScaleX, minScaleY, maxScaleY, blurQuality, numPasses, blurredFar);

    minScaleX = minScaleY = 0.5;
    maxScaleX = maxScaleY = 2;
    blurQuality = nearBlurQuality;
    numPasses = nearBlurPasses;
    this._blurTexture(renderer, sceneRT, sceneRT.width, sceneRT.height, minScaleX, maxScaleX, minScaleY, maxScaleY, blurQuality, numPasses, blurredNear);

    const mixedRT = this._getRenderTarget('mixed', true);

    mixedRT.setSize(texW, texH);

    this._interpTexture(renderer, blurredNear, blurredFar, sceneRT, mixedRT);

    m.material = this._getFinalMaterial(mixedRT);
  }

  static _getPlaneGeometry() {
    let pg = this._planeGeometry;

    if (!pg) {
      const _3 = 3;

      pg = this._planeGeometry = new THREE.BufferGeometry();
      const posAtt = new THREE.BufferAttribute(new Float32Array([
        -1, 1, 0,
        -1, -1, 0,
        1, -1, 0,
        1, 1, 0
      ]), _3);
      const uvAtt = new THREE.BufferAttribute(new Float32Array([
        1, 1,
        1, 0,
        0, 0,
        0, 1
      ]), 2);
      const idxAtt = new THREE.BufferAttribute(new Uint16Array([
        0, 1, 2,
        0, 2, _3
      ]), 1);

      pg.addAttribute('position', posAtt);
      pg.addAttribute('uv', uvAtt);
      pg.setIndex(idxAtt);
    }

    return pg;
  }

  static _getShadowRenderer() {
    let sr = this._shadowRenderer;

    if (!sr) {
      sr = this._shadowRenderer = new ShadowRenderer();
    }

    return sr;
  }

  _getShadowRenderer() {
    let sr = this._shadowRenderer;

    if (!sr) {
      sr = ShadowPlane._getShadowRenderer();
    }

    return sr;
  }

  _getPlaneGeometry() {
    let pg = this._planeGeometry;

    if (!pg) {
      pg = ShadowPlane._getPlaneGeometry();
    }

    return pg;
  }

  static dispose() {
    const pg = this._planeGeometry;

    if (pg) {
      pg.dispose();
      this._planeGeometry = null;
    }

    const sr = this._shadowRenderer;

    if (sr) {
      sr.dispose();
      this._shadowRenderer = null;
    }
  }
}
