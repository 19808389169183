import {getAnimationTime, reqAnimFrame, cancelAnimFrame} from './anim.js';
import AnimEvent from './AnimEvent';
import Animation from './Animation';
import EventDispatcher from '../events/EventDispatcher';

const EVENT_ANIMATE = 'animate';
const EVENT_PREANIMATE = 'preanimate';
const EVENT_POSTANIMATE = 'postanimate';
// const UNDEFINED = 'undefined';

/**
 * Manages multiple animation instances for one requestAnimationFrame loop.
 * Initializes a requestAnimationFrame loop (setInterval as fallback) if the animator contains one or more animation instances.
 * Stops the loop if all animations are removed.
 */
export default class Animator extends EventDispatcher {
  constructor() {
    super();
    this.metaData = null;
  }
  _checkStartLoop() {
    const num = this.getNumAnimations();

    if (num > 0) {
      this._startLoop();
    } else {
      this._stopLoop();
    }
  }
  /**
   * Event passed to Animations
   * @return {AnimEvent} - AnimEvent
   */
  _getAnimEvent() {
    let evt = this._animEvent;

    if (evt) {
      return evt;
    }
    evt = new AnimEvent();
    this._animEvent = evt;

    return evt;
  }
  /**
   * Called every loop
   * @final
   * @param  {int} dtMs - delta time in milliseconds
   * @return {void}
   */
  _loop(dtMs) {
    const animations = this._animations;

    if (!animations) {
      // Shouldn't happen
      return;
    }
    const num = animations.length;

    if (num === 0) {
      // Shouldn't happen because the loop stops if the animations array is empty
      return;
    }

    const animEvent = this._getAnimEvent();

    const seconds = 0.001;
    const dt = dtMs * seconds;

    animEvent.animator = this;
    animEvent.animation = null;
    animEvent.deltaTime = dt;
    animEvent.deltaTimeMs = dtMs;

    animEvent.type = EVENT_PREANIMATE;
    this._preAnimate(animEvent);

    animEvent.type = EVENT_ANIMATE;
    for (let i = 0; i < num; ++i) {
      const anim = animations[i];

      if (anim && anim.animate) {
        animEvent.animation = anim;
        anim.animate(animEvent);
        this.dispatchEvent(animEvent);
      }
    }
    animEvent.type = EVENT_POSTANIMATE;
    this._postAnimate(animEvent);

    animEvent.animator = null;
  }
  _preAnimate(animatorEvent) {
    this.dispatchEvent(animatorEvent);
  }
  _postAnimate(animatorEvent) {
    this.dispatchEvent(animatorEvent);
  }
  _startLoop() {
    if (this._running) {
      return;
    }
    this._running = true;

    let callback = this._callback;

    this._firstRun = true;
    this._previousTime = 0;
    if (!callback) {
      const that = this;

      callback = timestamp => {
        let ts = timestamp;

        ts = getAnimationTime();
        /*
        if (ts === null || typeof (ts) === UNDEFINED) {
          if (Date.now) {
            ts = Date.now();
          } else {
            ts = new Date().getTime();
          }
        }
        */
        if (that._firstRun) {
          that._firstRun = false;
          that._previousTime = ts;
        }

        const dt = ts - that._previousTime;

        that._previousTime = ts;

        that._loop(dt);
        if (that._running) {
          that._loopID = reqAnimFrame(callback);
        }
      };
    }
    this._loopID = reqAnimFrame(callback);
  }

  _stopLoop() {
    if (!this._running) {
      return;
    }
    this._running = false;
    if (this._loopID !== null && typeof (this._loopID) !== 'undefined') {
      cancelAnimFrame(this._loopID);
    }
  }

  addAnimation(anim) {
    if (!anim) {
      return anim;
    }
    if (!(anim instanceof Animation)) {
      return anim;
    }
    let animations = this._animations;

    if (!animations) {
      animations = this._animations = [];
    }

    const index = animations.indexOf(anim, 0);

    if (index < 0) {
      animations.push(anim);
      this._checkStartLoop();
    }

    return anim;
  }
  removeAnimation(anim) {
    if (!anim) {
      return anim;
    }
    const animations = this._animations;

    if (animations) {
      const index = animations.indexOf(anim, 0);

      if (index < 0) {
        return anim;
      }
      animations.splice(index, 1);
      this._checkStartLoop();
    }

    return anim;
  }
  removeAnimations() {
    const animations = this._animations;

    if (animations) {
      animations.length = 0;
      this._checkStartLoop();
    }
  }

  getAnimation(index) {
    const animations = this._animations;

    if (animations) {
      return animations[index];
    }

    return null;
  }
  getNumAnimations() {
    const animations = this._animations;

    if (animations) {
      return animations.length;
    }

    return 0;
  }
  get numAnimations() {
    return this.getNumAnimations();
  }
}
