const RotationOrder = {
  XYZ: 'XYZ',
  XZY: 'XZY',
  YXZ: 'YXZ',
  YZX: 'YZX',
  ZXY: 'ZXY',
  ZYX: 'ZYX'
};

export default RotationOrder;
