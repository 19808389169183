import CameraViewSettings from './CameraViewSettings';

const DEG2RAD = Math.PI / 180;

const customCameraViewSettings = {};

{
  const dist = 228;
  const rotx = -15;
  const roty = 38;

  customCameraViewSettings.COVERSOLUTIONS = customCameraViewSettings.COVERSOLUTION =
    new CameraViewSettings('custom', dist, rotx * DEG2RAD, roty * DEG2RAD, 0, 0, 0, 0, 0);
}


export default customCameraViewSettings;
