import ComponentController from './ComponentController';

/**
* @class GraphicsComponentController
* @extends ComponentController
* @description Controller for GraphicsComponents
*/
export default class GraphicsComponentController extends ComponentController {
  constructor() {
    super();
    this._autoRenderEnabled = true;
    this._autoRenderRequestEnabled = true;
  }
  /**
   * @method _changedComponentSize
   * @description Called when the component's size changed
   * @protected
   * @override
   * @return {void}
   * */
  _changedComponentSize() {
    // Default behavior to render each time the component is resized
    this.render();
  }

  /**
   * @method render
   * @description Abstract method to render content on the canvas element of
   * the GraphicsComponent
   * @abstract
   * @return {void};
   * */
  render() {
    return;
  }

  /**
   * @method _autoRender
   * @protected
   * @description Called internally whenever a render is needed,
   * but only renders if auto-rendering is enabled
   * @returns {void}
   * */
  _autoRender() {
    if (this._autoRenderEnabled) {
      this.render();
    }
  }

  /**
  * @method _autoRender
  * @protected
  * @description Same as _autoRender but uses a render request rather than
  * rendering immediately
  * @returns {void}
  * */
  _autoRenderRequest() {
    if (this._autoRenderRequestEnabled) {
      this.renderRequest();
    }
  }

  /**
   * @method _handleRenderRequest
   * @description Handler for render requests. Is called from requestAnimationFrame
   * @return {void}
   * */

  _handleRenderRequest() {
    this.render();
    this._renderRequestPending = false;
    this._renderRequestID = null;
  }

  /**
   * @method cancelRenderRequest
   * @description Cancels the current render request
   * @return {void}
   * */
  cancelRenderRequest() {
    this._renderRequestPending = false;
    if (this._renderRequestID === null || typeof (this._renderRequestID) === 'undefined') {
      return;
    }
    if (typeof (cancelAnimationFrame) === 'undefined') {
      clearTimeout(this._renderRequestID);
    } else {
      cancelAnimationFrame(this._renderRequestID);
    }
    this._renderRequestID = null;
  }

  /**
   * @method renderRequest
   * @description renders after a frame using requestAnimationFrame.
   * @return {void}
   * */
  renderRequest() {
    if (this._renderRequestPending) {
      return;
    }
    this._renderRequestPending = true;

    let handler = this._renderRequestHandler;

    if (!handler) {
      const that = this;

      handler = this._renderRequestHandler = () => {
        that._handleRenderRequest();
      };
    }
    if (typeof (requestAnimationFrame) === 'undefined') {
      this._renderRequestID = setTimeout(handler, 0);
    } else {
      this._renderRequestID = requestAnimationFrame(handler);
    }
  }
}
