/* eslint max-depth: ["error", 6] */
import BD3DNodeUtils from '../scenegraph/BD3DNodeUtils';
import BD3DNodeTypes from '../scenegraph/BD3DNodeTypes';
import HandleType from './HandleType';
import HandleUtils from './HandleUtils';
import GraphUtils from '../graph/GraphUtils';
import BD3DGeometry from '../geom/BD3DGeometry';
import SRTTransform3D from '../../bgr/bgr3d/transform/SRTTransform3D';
import GeometryNode3D from '../../bgr/bgr3d/scenegraph/GeometryNode3D';
import ContainerNode3D from '../../bgr/bgr3d/scenegraph/ContainerNode3D';
import BD3DContainerNode3D from '../scenegraph/BD3DContainerNode3D';
import Node3DCloner from '../../bgr/bgr3d/cloners/Node3DCloner';
import Node3DMaterialUtils from '../material/Node3DMaterialUtils';
import BoundingBoxUtils from '../../bgr/bgr3d/utils/BoundingBoxUtils.js';
import MaterialTypes from '../material/MaterialTypes';
import BD3DFabricMaterial from '../material/BD3DFabricMaterial';
import BD3DMaterial from '../material/BD3DMaterial';
import BD3DSampleFabricMaterial from '../material/BD3DSampleFabricMaterial';
import SampleTransform from '../material/SampleTransform';
import FabricTransform from '../material/FabricTransform';
import Utils from '../utils/Utils';

import Object3DAsset from '../asset/Object3DAsset';
import ImageAsset from '../asset/ImageAsset';
import MattressDA from '../mattress/MattressDA';
import MattressGeomUtils from '../geom/MattressGeomUtils';

import HandleErrorType from './HandleErrorType';
import BorderComponentUtils from '../borders/BorderComponentUtils';
import BD3DHandleLogoMaterial from '../material/BD3DHandleLogoMaterial';

// import MattressGeomUtils from '../geom/MattressGeomUtils';

const MIN_COMPONENTS = 1;
const MIN_LENGTH_FOR_HANDLES = 120;
const MIN_INNER_LENGTH_FOR_HANDLES = 80;
const DEFAULT_HANDLE_HEIGHT = 5;
const MIN_BORDER_HEIGHT = DEFAULT_HANDLE_HEIGHT;

const Assets = {
  HHS012_OBJ: new Object3DAsset('handle.horizontal.HHS012.object'),
  HHS013_OBJ: new Object3DAsset('handle.horizontal.HHS013.object'),
  HHS015_OBJ: new Object3DAsset('handle.horizontal.HHS015.object'),
  HHS016_OBJ: new Object3DAsset('handle.horizontal.HHS016+17.object'),

  HHS014_TEX: new ImageAsset('handle.horizontal.HHS014.texture')


  /*
  HVS00001_OBJ: new Object3DAsset('handle.vertical.HVS00001.object'),
  HVS00002_OBJ: new Object3DAsset('handle.vertical.HVS00002.object'),
  HV004_OBJ: new Object3DAsset('handle.vertical.HV004.object'),
  HVS005_OBJ: new Object3DAsset('handle.vertical.HVS005.object'),
  HVS006_OBJ: new Object3DAsset('handle.vertical.HVS006.object'),
  HHP031_OBJ: new Object3DAsset('handle.pocket.HHP031.object')
  */
};

Assets.HHS017_OBJ = Assets.HHS016_OBJ;
Assets.HHS014_OBJ = Assets.HHS012_OBJ;


/**
 * @class HorizontalHandleType
 * @description Horizontal handle type
 *
 * ===================================
 *     _ ____ _           _ ____ _
 *    |X|____|X|         |X|____|X|
 *
 * ===================================
 *
 **/
export default class HorizontalHandleType extends HandleType {
  static getTypeName() {
    return 'horizontal';
  }

  static get typeName() {
    return this.getTypeName();
  }

  addAssets(data, handleStyle, params, array = null, assetManager = null) {
    let arr = array;
    const objAsset = this._get3DObjectAsset(data);

    let styleID = this._getStyleID(data);

    if (styleID) {
      styleID = styleID.toLowerCase();
    }

    if (styleID === 'hhs014') {
      arr = assetManager.addAssetToArray(Assets.HHS014_TEX, arr, params);
      arr = HandleUtils.addLogoHandleImageAssets(data, assetManager, params, arr) || arr;
    }

    arr = assetManager.addAssetToArray(objAsset, arr, params);

    const style = data.style ? data.style : data;
    const materials = style.materials;

    if (materials) {
      for (const v in materials) {
        if (materials.hasOwnProperty(v)) {
          const mtl = materials[v];

          this._addHandleMaterialAssets(mtl, assetManager, params, array);
        }
      }
    }
    arr = this._addHandleMaterialAssets(style.material, assetManager, params, arr);

    return arr;
    /*
    const hsName = handleStyle.getID();
    let arr = array;

    if (hsName) {
      const objSuffix = '_OBJ';
      const objAssetName = hsName.toUpperCase() + objSuffix;
      const objAsset = Assets[objAssetName];

      arr = assetManager.addAssetToArray(objAsset, arr, params);
    }

    return arr;
    */
  }
  _addHandleMaterialAssets(mtl, assetManager, params, array) {
    let arr = array;
    const sampleData = mtl ? (mtl.sample || mtl.texture) : null;
    const quiltData = mtl ? mtl.quilt : null;
    const sampleAsset = assetManager._parseSampleAsset(sampleData);
    const quiltAsset = assetManager._parseQuiltAsset(quiltData);

    arr = assetManager.addAssetToArray(sampleAsset, arr, params);
    arr = assetManager.addAssetToArray(quiltAsset, arr, params);

    return arr;
  }

  // TODO: Lots of similar code with VerticalHandleType
  _assignMaterial(node, materialLib, fallbackMaterial = null, params = null) {
    Node3DMaterialUtils.assignMaterials(node, materialLib, fallbackMaterial, params);
  }

  _findOutMaterialType(data) {
    if (!data) {
      return null;
    }
    if (data.sample) {
      return MaterialTypes.SAMPLE;
    }

    return null;
  }

  _assignUVData(node, uvData) {
    if (!node) {
      return;
    }
    if (node instanceof GeometryNode3D) {
      const geom = node.geometry;

      if (!geom) {
        return;
      }
      let scX = 1, scY = 1, minU = 0, minV = 0, maxU = 1, maxV = 1;

      if (uvData) {
        // scX = 1 / uvData.scaleU;
        // scY = 1 / uvData.scaleV;
        if (typeof (uvData.scaleU) === 'number') {
          scX = uvData.scaleU;
        }
        if (typeof (uvData.scaleV) === 'number') {
          scY = uvData.scaleV;
        }
        if (typeof (uvData.minU) === 'number') {
          minU = uvData.minU;
        }
        if (typeof (uvData.minV) === 'number') {
          minV = uvData.minV;
        }
        if (typeof (uvData.maxU) === 'number') {
          maxU = uvData.maxU;
        }
        if (typeof (uvData.maxV) === 'number') {
          maxV = uvData.maxV;
        }
      }
      if (geom instanceof BD3DGeometry) {
        geom.setUVScale(scX, scY);
        geom.setUVBounds(minU, minV, maxU, maxV);
      } else {
        let ud = geom.userData;

        if (!ud) {
          ud = geom.userData = {};
        }
        ud.uvData = uvData;
      }
    } else if (node instanceof ContainerNode3D) {
      const children = node.children;
      const num = children ? children.length : 0;

      for (let i = 0; i < num; ++i) {
        this._assignUVData(children[i], uvData);
      }
    }
  }

  _get3DObjectAsset(data, mattressData, assets) {
    const hsName = this._getStyleID(data);

    if (hsName) {
      const objSuffix = '_OBJ';
      const objAssetName = hsName.toUpperCase() + objSuffix;

      const res = Assets[objAssetName];
      let node3d = null;

      if (res) {
        node3d = res.getNode3D();
      }

      if (node3d) {
        if (res.metaData) {
          const uvWorldTransform = res.metaData.uvWorldTransform || res.metaData.uvData;

          this._assignUVData(node3d, uvWorldTransform);
        }
        /*
        let material = null;
        let materials = null;

        if (data) {
          const style = data.style;

          if (style && typeof (style) === 'object') {
            material = style.material;
            materials = style.materials;
          }
        }
        if (material || materials) {
          let params = this._assignMaterialParams;

          if (!params) {
            params = this._assignMaterialParams = {};
          }
          params.getMaterialType = this._findOutMaterialType;
          let createHandleMaterialCallback = this._createHandleMaterialCallback;

          if (!createHandleMaterialCallback) {
            const that = this;

            createHandleMaterialCallback = this._createHandleMaterialCallback = (materialData, paramsObj) => {
              that._createHandleMaterial(materialData, paramsObj);
            };
          }
          params.createMaterial = this._createHandleMaterial;
          this._assignMaterial(node3d, materials, material, params);
        }
        */
      }

      return res;
    }

    return null;
    /*
    let id = 'horizontalHandle';
    let material = null;
    let materials = null;

    if (data) {
      const style = data.style;

      if (style) {
        if (style.object3d) {
          id = style.object3d;
        }
        material = style.material;
        materials = style.materials;
      }
    }
    const res = assets.getAsset(id);
    const node3d = res.getNode3D();

    if (res.metaData) {
      this._assignUVData(node3d, res.metaData.uvData);
    }

    if (material || materials) {
      let params = this._assignMaterialParams;

      if (!params) {
        params = this._assignMaterialParams = {};
      }
      params.getMaterialType = this._findOutMaterialType;
      this._assignMaterial(node3d, materials, material, params);
    }

    return res;
    */
  }
  _setHandleMaterials(data, node3d, handleIndex, totalNumHandles, buildParams, fallbackMaterial) {
    let material = null;
    let materials = null;

    if (data) {
      const style = data.style;

      if (style && typeof (style) === 'object') {
        material = style.material;
        materials = style.materials;
      }
    }
    if (material || materials) {
      let params = this._assignMaterialParams;

      if (!params) {
        params = this._assignMaterialParams = {};
      }
      params.getMaterialType = this._findOutMaterialType;
      let createHandleMaterialCallback = this._createHandleMaterialCallback;

      if (!createHandleMaterialCallback) {
        const that = this;

        createHandleMaterialCallback = this._createHandleMaterialCallback = (materialData, paramsObj) => {
          that._createHandleMaterial(materialData, paramsObj);
        };
      }
      params.createMaterial = this._createHandleMaterial;
      params.buildParams = buildParams;
      params.fallbackMaterial = fallbackMaterial;
      params.handleIndex = handleIndex;
      params.getNode3DMaterialNameFallback = this._getNode3DMaterialNameFallback;
      params.getMaterialFromLib = this._getMaterialFromLib;
      params.handleType = this;
      params.handleData = data;

      this._assignMaterial(node3d, materials, material, params);
    } else {
      this._setupFabricOffset(node3d, handleIndex, totalNumHandles);
    }
  }

  _getNode3DMaterialNameFallback(node, params) {
    return 'middle';
  }
  _getMaterialFromLib(materialName, materialLib, fallbackMaterial, node, params) {
    if (!materialLib) {
      return null;
    }
    let res = null;
    let matName = materialName;

    if (!matName) {
      res = materialLib.fabric;
      if (res) {
        return res;
      }

      return fallbackMaterial;
    }
    const ht = params ? params.handleType : null;
    const hd = params ? params.handleData : null;
    const styleID = ht._getStyleID(hd);
    const styleIDLC = styleID && styleID.toLowerCase ? styleID.toLowerCase() : null;

    if (styleIDLC === 'hhs016') {
      if (matName === 'middle') {
        matName = 'fabric';
      } else if (matName === 'side') {
        matName = 'tape';
      }
    } else if (styleIDLC === 'hhs017') {
      if (matName === 'side') {
        matName = 'fabric';
      } else if (matName === 'middle') {
        matName = 'tape';
      }
    }

    res = materialLib[matName];

    if (!res) {

      if ((matName === 'fabric' && styleIDLC === 'hhs016') || (matName === 'tape' && styleIDLC === 'hhs017')) {
        res = materialLib.middle;
      } else if ((matName === 'fabric' && styleIDLC === 'hhs017') || (matName === 'tape' && styleIDLC === 'hhs016')) {
        res = materialLib.side;
      } else if ((matName === 'middle' && styleIDLC === 'hhs016') || (matName === 'side' && styleIDLC === 'hhs017')) {
        res = materialLib.fabric;
      } else if ((matName === 'middle' && styleIDLC === 'hhs017') || (matName === 'side' && styleIDLC === 'hhs016')) {
        res = materialLib.tape;
      }
    }

    if (!res) {
      return fallbackMaterial;
    }

    return res;
  }

  _createHandleMaterial(materialData, node, params) {
    let fbMat = params ? params.fallbackMaterial : null;

    const index = params ? params.handleIndex : 0;
    const UVSc = 10;
    const sampleOffX = index * 100 + (index / UVSc);
    const sampleOffY = -index - (index / UVSc);

    if (materialData) {
      const sample = materialData.sample || materialData.texture;
      const st = typeof (sample);
      const buildParams = params ? params.buildParams : null;
      const uvData = MattressGeomUtils.getGeometryUVWorldTransform(node);
      let sampleID = null;

      if (sample && st !== 'undefined') {
        if (st === 'string') {
          sampleID = sample;
        } else if (st === 'number') {
          sampleID = `${sample}`;
        } else if (st === 'object') {
          sampleID = sample.id || sample.ID;
        }
      }

      if (sampleID) {
        const sampleAsset = buildParams ? buildParams.assetCollections.samples.getAssetByName(sampleID) : null;

        if (sampleAsset) {
          const mat = new BD3DSampleFabricMaterial();
          const sampleTransform = new SampleTransform();


          sampleTransform.setGeometryUVData(uvData);
          // sampleTransform.setSampleConfigData(sampleAsset.getSampleConfigData());
          sampleTransform.setSampleConfigData(null);
          sampleTransform.setSampleData(sampleAsset.getSampleData());

          sampleTransform.setRealSize(sampleAsset.getFallbackWidth(), sampleAsset.getFallbackHeight());
          sampleTransform.setSourceAspectRatio(sampleAsset.getImageWidth(), sampleAsset.getImageHeight());
          // sampleTransform.setOffset(sampleOffX, sampleOffY);
          sampleTransform.translateX = sampleOffX;
          sampleTransform.translateY = sampleOffY;

          mat.setSampleAsset(sampleAsset);
          mat.setSampleTransform(sampleTransform);

          return mat;
        }
      } else if (materialData.color) {
        const mt = materialData.material || 'cotton';
        const mat = new BD3DFabricMaterial();
        const sampleTransform = new SampleTransform();

        mat.setColorType('tape');

        mat.setColorMultiplier(materialData.color);

        const defaultFabric = buildParams.assetCollections.commonTextures.getAssetByName('defaultfabric.color');
        const defaultFabricNormal = buildParams.assetCollections.commonTextures.getAssetByName('defaultfabric.normal');

        const rs = defaultFabric.metaData ? defaultFabric.metaData.realSize : null;
        const realW = rs ? rs.width : 2;
        const realH = rs ? rs.height : 2;

        sampleTransform.setGeometryUVData(uvData);
        sampleTransform.setRealSize(realW, realH);

        mat.setSampleTexture(defaultFabric);
        mat.setSampleNormalMap(defaultFabricNormal);
        mat.setFabricType(mt);
        mat.setSampleTransform(sampleTransform);

        return mat;
      }
    }

    if (fbMat && fbMat.clone) {
      let srcSampleTransform = null;
      let sampleTransform = null;

      if (fbMat.getSampleTransform) {
        srcSampleTransform = fbMat.getSampleTransform();
      } else if (fbMat.get) {
        srcSampleTransform = fbMat.get('sampleTransform');
      }

      fbMat = fbMat.clone();

      if (fbMat.getSampleTransform) {
        sampleTransform = fbMat.getSampleTransform();
      } else if (fbMat.get) {
        sampleTransform = fbMat.get('sampleTransform');
      }

      if (sampleTransform) {
        sampleTransform.translateX = sampleOffX;
        sampleTransform.translateY = sampleOffY;

        if (srcSampleTransform === sampleTransform && sampleTransform.clone) {
          sampleTransform = sampleTransform.clone();
        }

        const uvData = MattressGeomUtils.getGeometryUVWorldTransform(node);

        sampleTransform.setGeometryUVData(uvData);

        if (fbMat.setSampleTransform) {
          fbMat.setSampleTransform(sampleTransform);
        } else if (fbMat.set) {
          fbMat.set('sampleTransform', sampleTransform);
        }
      }

    }

    return fbMat;
  }

  _getNode3DBoundingBox(node, create = true) {
    if (!node) {
      return null;
    }
    let ud = node.userData;

    if (ud && ud.boundingBox) {
      return ud.boundingBox;
    }
    if (!create) {
      return null;
    }


    if (!ud) {
      ud = node.userData = {};
    }
    let bbox = ud.boundingBox;

    if (!bbox) {
      bbox = ud.boundingBox = BoundingBoxUtils.getBoundingBox(node);
    }

    return bbox;
  }

  _getNode3DWidth(node) {
    if (!node) {
      return 0;
    }
    const box = this._getNode3DBoundingBox(node, true);

    if (!box) {
      return 0;
    }

    let width = box.width;

    if (typeof (width) !== 'number' || width === null || isNaN(width)) {
      width = box.maxx - box.minx;
      box.width = width;
    }

    return width;
  }

  _getNode3DHeight(node) {
    if (!node) {
      return 0;
    }
    const box = this._getNode3DBoundingBox(node, true);

    if (!box) {
      return 0;
    }

    let height = box.height;

    if (typeof (height) !== 'number' || height === null || isNaN(height)) {
      height = box.maxy - box.miny;
      box.height = height;
    }

    return height;
  }

  create3DHandles(data, borderNodes, mattressData, borderShape, borderCurveGraph, buildParams, resultNode = null) {
    if (resultNode) {
      resultNode.removeChildren();
    }
    if (!data || !mattressData) {
      return resultNode;
    }

    const assets = buildParams ? buildParams.assets : null;

    if (!assets) {
      return resultNode;
    }

    const asset = this._get3DObjectAsset(data, mattressData, assets);

    if (!asset) {
      return resultNode;
    }
    const node = asset.getNode3D();

    if (!node) {
      return resultNode;
    }

    let mattressWidth = 0;
    let mattressLength = 0;
    let cornerRadius = 0;

    if (mattressData) {
      if (mattressData.box && mattressData.box.size) {
        mattressWidth = mattressData.box.size.width;
        mattressLength = mattressData.box.size.length;
      }
      if (mattressData.box && mattressData.box.radius) {
        cornerRadius = mattressData.box.radius.corners;
      }
    }

    const hMW = mattressWidth * 0.5;
    const hML = mattressLength * 0.5;
    const hMattressSize = hMW < hML ? hMW : hML;

    cornerRadius = cornerRadius > hMattressSize ? hMattressSize : cornerRadius;

    let baseNode = null;
    let baseComponent = null;

    let borderNode;
    let borderComp;

    let attachmentBase = Utils.tryValues(data.borderComponent, data.attachmentBase, data.base);

    const handleParams = data.params;

    if (!attachmentBase && handleParams) {
      attachmentBase = Utils.tryValues(
        handleParams.borderComponent,
        handleParams.bordercomponent,
        handleParams.attachmentBase,
        handleParams.attachmentbase,
        handleParams.base
      );
    }

    let attachmentType = null;
    let attachmentID = null;

    if (attachmentBase !== null && typeof (attachmentBase) === 'object') {
      attachmentType = attachmentBase.type;
      attachmentID = attachmentBase.id;
    } else {
      attachmentID = attachmentBase;
      attachmentType = 'border';
    }

    let allowed = false;

    if (attachmentType === 'top') {
      allowed = true;
    } else if (attachmentType === 'bottom') {
      allowed = true;
    } else if (!attachmentType || attachmentType === 'border') {
      if (typeof (attachmentID) === 'number') {
        borderNode = borderNodes[attachmentID];
        if (borderNode) {
          borderComp = this.getBorderNodeComponent(borderNode);
          if (this.isValidBase(borderComp)) {
            baseNode = borderNode;
            baseComponent = borderComp;
            allowed = baseNode !== null && baseComponent !== null && typeof (baseNode) !== 'undefined' && typeof (baseComponent) !== 'undefined';
          }
        }
      } else {
        borderComp = MattressDA.getBorderComponent(mattressData, attachmentID);
        borderNode = (buildParams && buildParams.dataNode3DMap) ? buildParams.dataNode3DMap.get(borderComp) : null;
        if (this.isValidBase(borderComp)) {
          baseComponent = borderComp;
          baseNode = borderNode;
          allowed = baseNode !== null && baseComponent !== null && typeof (baseNode) !== 'undefined' && typeof (baseComponent) !== 'undefined';
        }
      }
      if (!allowed) {
        const numBorderNodes = borderNodes ? borderNodes.length : 0;

        // Calculate total height of border components
        const mattressBorder = mattressData.border;
        const borderComponents = mattressBorder ? mattressBorder.components : null;
        const bcHeight = MattressDA.getTotalHeightOfBorderComponents(borderComponents);
        const hBCHeight = bcHeight * 0.5;
        let curY = 0;
        let minDist = -1;

        baseNode = null;

        for (let i = numBorderNodes - 1; i >= 0; --i) {
          borderNode = borderNodes[i];
          const borderData = buildParams && buildParams.dataNode3DMap ? buildParams.dataNode3DMap.get(borderNode) : null;
          const borderCompType = borderNode.userData.borderComponentType;
          const top = borderCompType.getTop(borderData);
          const bottom = borderCompType.getBottom(borderData);
          const borderH = (bottom > top) ? (bottom - top) : (top - bottom);

          const nextY = curY + borderH;

          // borderData same as borderComp?
          borderComp = this.getBorderNodeComponent(borderNode);
          // TODO: blocks nested too deeply
          if (this.isValidBase(borderComp)) {
            const midY = curY + borderH * 0.5;

            // const dist1 = (curY > hBCHeight) ? (curY - hBCHeight) : (hBCHeight - curY);
            // const dist2 = (nextY > hBCHeight) ? (nextY - hBCHeight) : (hBCHeight - nextY);
            // const dist = dist1 < dist2 ? dist1 : dist2;
            const dist = (midY > hBCHeight) ? (midY - hBCHeight) : (hBCHeight - midY);

            if (!baseNode || !baseComponent || !allowed || (dist < minDist)) {
              baseNode = borderNode;
              baseComponent = borderComp;
              allowed = true;
              minDist = dist;
            }
            /*
            baseNode = borderNode;
            baseComponent = borderComp;
            allowed = true;
            break;
            */
          }
          curY = nextY;
        }
      }

      if (!baseNode || !baseComponent) {
        return resultNode;
      }
    }

    if (!allowed) {
      return resultNode;
    }
    const handleHeight = this._getNode3DHeight(node);
    const handleWidth = this._getNode3DWidth(node);

    let yAlign = 0.5;
    let marginBottom = 0;
    let marginTop = 0;
    let yOffset = 0;

    if (data && data.layout) {
      /**
        "layout": {
          "alignY": 1, // value between 0 (top) and 1(bottom). Middle = 0.5
          "margin": 1 // cm, used if marginBottom or marginTop is not set
          "marginBottom": 1 //cm
          "marginTop": 1 //cm
          "yOffset": 1 //cm
        }
      * */
      const lyt = data.layout;

      yAlign = Utils.tryValues(lyt.yAlign, lyt.alignY, lyt.yalign, lyt.aligny, yAlign);
      marginBottom = Utils.tryValues(lyt.marginBottom, lyt.marginbottom, lyt.bottomMargin, lyt.bottommargin, lyt.margin, marginBottom);
      marginTop = Utils.tryValues(lyt.marginTop, lyt.marginTop, lyt.topMargin, lyt.topmargin, lyt.margin, marginTop);
      yOffset = Utils.tryValues(lyt.yOffset, lyt.offsetY, lyt.yoffset, lyt.offsety, yOffset);
    }

    const bbox = this._getNode3DBoundingBox(node);
    // const localOffsetY = -(-bbox.maxy + handleHeight * yAlign)
    const localOffsetY = (bbox.maxy - handleHeight * yAlign);

    let ypos = 0;
    let thicknessOffset = 0;

    if (attachmentType === 'border') {
      const baseNodeGeom = baseNode.geometry;

      if (baseNodeGeom) {
        const ud = baseNodeGeom.userData;

        if (ud) {
          const topOff = ud.borderTopOffset + marginTop;
          const btmOff = ud.borderBottomOffset - marginBottom;
          // const midOff = ud.borderMiddleOffset;

          const midOff = (topOff + (btmOff - topOff) * yAlign) + localOffsetY + yOffset;

          let tempTransform = null;

          tempTransform = borderShape.getTransformAtLength(midOff, tempTransform);
          ypos = tempTransform.matrix4.y;

          const borderCompType = ud.borderComponentType;

          if (borderCompType) {
            const thickness = borderCompType.getResultThickness(baseComponent, mattressData);
            const depth = borderCompType.getDepth(baseComponent, mattressData);

            // thicknessOffset = thickness * 0.5 + depth;
            thicknessOffset = thickness + depth;
          }
        }
      }
    } else {
      // get y-position when attaching to top or bottom
      /*
      "handle": {
        ...
        "attachmentBase": {
          "type": "top" / "bottom"
        },
        ...
      }
      */
      const height = MattressDA.getResultHeight(mattressData);

      allowed = true;
      if (attachmentType === 'top') {
        const topBorderRadius = MattressDA.getTopBorderRadius(mattressData);
        const topHeight = MattressDA.getTopHeight(mattressData) - topBorderRadius;

        ypos = (height * 0.5) - topBorderRadius - ((topHeight - (marginBottom + marginTop)) * yAlign) - localOffsetY - marginTop - yOffset;
      } else if (attachmentType === 'bottom') {
        const bottomBorderRadius = MattressDA.getBottomBorderRadius(mattressData);
        const bottomHeight = MattressDA.getBottomHeight(mattressData) - bottomBorderRadius;

        ypos = (-height * 0.5) + (bottomBorderRadius) + ((bottomHeight - (marginBottom + marginTop)) * (1.0 - yAlign)) - localOffsetY + marginBottom - yOffset;
      } else {
        allowed = false;
      }
    }

    if (!allowed) {
      return resultNode;
    }

    const fallbackMaterial = Node3DMaterialUtils.getMaterial(baseNode);

    const innerWidth = mattressWidth - cornerRadius * 2;
    const innerLength = mattressLength - cornerRadius * 2;
    const hIW = innerWidth * 0.5;
    const hIL = innerLength * 0.5;

    // the inner size (size - corner radius) should be larger or equal to the width of the handle

    let placementCountResult = null;

    placementCountResult = HandleUtils.largeEnoughForNumHandles(data, true, mattressWidth, innerWidth, handleWidth, MIN_LENGTH_FOR_HANDLES, MIN_INNER_LENGTH_FOR_HANDLES, placementCountResult);

    const {largeEnoughFor1Handle: largeEnoughFor1FrontHandle, largeEnoughFor2Handles: largeEnoughFor2FrontHandles} = placementCountResult;

    placementCountResult = HandleUtils.largeEnoughForNumHandles(data, false, mattressLength, innerLength, handleWidth, MIN_LENGTH_FOR_HANDLES, MIN_INNER_LENGTH_FOR_HANDLES, placementCountResult);

    const {largeEnoughFor1Handle: largeEnoughFor1SideHandle, largeEnoughFor2Handles: largeEnoughFor2SideHandles} = placementCountResult;

    const relSidePos = 0.5;
    const relFrontPos = 0.5;

    const container = resultNode ? resultNode : new BD3DContainerNode3D();
    let numHandles = 0;
    let handleIndex = 0;

    const _4 = 4;
    const _2 = 2;

    if (largeEnoughFor2SideHandles) {
      numHandles += _4;
    } else if (largeEnoughFor1SideHandle) {
      numHandles += _2;
    }

    if (largeEnoughFor2FrontHandles) {
      numHandles += _4;
    } else if (largeEnoughFor1FrontHandle) {
      numHandles += _2;
    }

    if (largeEnoughFor2SideHandles) {
      this.createHandle(hMW + thicknessOffset, ypos, hIL * relSidePos, Math.PI * 0.5, handleIndex++, numHandles, node, container, data, mattressData, buildParams, fallbackMaterial, asset);
      this.createHandle(hMW + thicknessOffset, ypos, -hIL * relSidePos, Math.PI * 0.5, handleIndex++, numHandles, node, container, data, mattressData, buildParams, fallbackMaterial, asset);
      this.createHandle(-hMW - thicknessOffset, ypos, hIL * relSidePos, -Math.PI * 0.5, handleIndex++, numHandles, node, container, data, mattressData, buildParams, fallbackMaterial, asset);
      this.createHandle(-hMW - thicknessOffset, ypos, -hIL * relSidePos, -Math.PI * 0.5, handleIndex++, numHandles, node, container, data, mattressData, buildParams, fallbackMaterial, asset);
    } else if (largeEnoughFor1SideHandle) {
      this.createHandle(hMW + thicknessOffset, ypos, 0, Math.PI * 0.5, handleIndex++, numHandles, node, container, data, mattressData, buildParams, fallbackMaterial, asset);
      this.createHandle(-hMW - thicknessOffset, ypos, 0, -Math.PI * 0.5, handleIndex++, numHandles, node, container, data, mattressData, buildParams, fallbackMaterial, asset);
    }
    if (largeEnoughFor2FrontHandles) {
      this.createHandle(-hIW * relFrontPos, ypos, hML + thicknessOffset, 0, handleIndex++, numHandles, node, container, data, mattressData, buildParams, fallbackMaterial, asset);
      this.createHandle(hIW * relFrontPos, ypos, hML + thicknessOffset, 0, handleIndex++, numHandles, node, container, data, mattressData, buildParams, fallbackMaterial, asset);
      this.createHandle(-hIW * relFrontPos, ypos, -hML - thicknessOffset, -Math.PI, handleIndex++, numHandles, node, container, data, mattressData, buildParams, fallbackMaterial, asset);
      this.createHandle(hIW * relFrontPos, ypos, -hML - thicknessOffset, -Math.PI, handleIndex++, numHandles, node, container, data, mattressData, buildParams, fallbackMaterial, asset);
    } else if (largeEnoughFor1FrontHandle) {
      this.createHandle(0, ypos, hML + thicknessOffset, 0, handleIndex++, numHandles, node, container, data, mattressData, buildParams, fallbackMaterial, asset);
      this.createHandle(0, ypos, -hML - thicknessOffset, -Math.PI, handleIndex++, numHandles, node, container, data, mattressData, buildParams, fallbackMaterial, asset);
    }

    return container;
  }

  _setupFabricOffset(source, handleIndex, numHandles) {
    if (source instanceof ContainerNode3D) {
      const children = source.children;

      if (!children) {
        return;
      }
      const numChildren = children.length;

      if (!numChildren) {
        return;
      }
      for (let i = 0; i < numChildren; ++i) {
        this._setupFabricOffset(children[i], handleIndex, numHandles);
      }
    } else if (source instanceof GeometryNode3D) {
      const mat = Node3DMaterialUtils.getMaterial(source);

      let fabricTransform = null;

      if (mat instanceof BD3DFabricMaterial) {
        fabricTransform = mat.getSampleTransform();
      } else if (mat instanceof BD3DMaterial) {
        fabricTransform = mat.get('fabricTransform');
      }

      if (!fabricTransform) {
        fabricTransform = new SRTTransform3D();
        if (mat instanceof BD3DFabricMaterial) {
          mat.setSampleTransform(fabricTransform);
        } else if (mat instanceof BD3DMaterial) {
          mat.set('fabricTransform', fabricTransform);
        }
      }

      if (fabricTransform instanceof SRTTransform3D) {
        const posScale = 0.25;

        fabricTransform.position.x = handleIndex * posScale;
      } else if (fabricTransform instanceof FabricTransform) {

        fabricTransform.translateX = handleIndex * 100 + (handleIndex / numHandles);
        fabricTransform.translateY = -handleIndex - (handleIndex / numHandles);
      }
    }
  }

  _handleClonedGeometryNode3D(source, result, params) {
    const srcMat = Node3DMaterialUtils.getMaterial(source);
    let mat = srcMat;

    const handleTypeInstance = params ? params.handleTypeInstance : null;

    if (mat) {
      mat = mat.clone();
    }

    const handleData = params ? params.handleData : null;

    let styleID = handleTypeInstance._getStyleID(handleData);

    if (styleID) {
      styleID = styleID.toLowerCase();
    }

    if (styleID === 'hhs014') {
      const logoAsset = Assets.HHS014_TEX;
      // params.buildParams.assetCollections.commonTextures.getAssetByName('bd.logo');

      mat = new BD3DFabricMaterial();
      mat.setType(MaterialTypes.FABRIC);

      mat.setStaticTexture(logoAsset);

      // result offset should be 0.4,0.03
      // result scale should be 0.22,0.3
      /*
      const uvScaleU = 0.22;
      const uvScaleV = 0.3;
      const uvTranslateU = 0.4;
      const uvTranslateV = 0.03;
      const uvScaleU = 0.188;
      const uvScaleV = 0.262;
      const uvTranslateU = 0.4;
      const uvTranslateV = 0.68;
      MattressGeomUtils.setGeomUVWorldTransform(result, uvScaleU, uvScaleV, uvTranslateU, uvTranslateV);
      */
    }

    if (!mat) {
      const fbMat = params ? params.fallbackMaterial : null;

      if (fbMat) {
        mat = fbMat.clone();

        if (mat instanceof BD3DFabricMaterial) {
          mat.setQuiltAsset(null);
          mat.setQuiltConfig(null);
          mat.setQuiltData(null);
          mat.setQuiltTexture(null);
          mat.setQuiltBumpMap(null);
          mat.setQuiltNormalMap(null);
        }

        let st = handleTypeInstance._getSampleTransform(mat);
        const fbSt = handleTypeInstance._getSampleTransform(fbMat);

        if (!st || st === fbSt) {
          st = fbSt ? fbSt.clone() : null;
        }
        if (st) {
          const geomUVData = MattressGeomUtils.getGeometryUVWorldTransform(source.getGeometry());

          st.setGeometryUVData(geomUVData);
        }
        handleTypeInstance._setSampleTransform(mat, st);
      }
    }

    {
      let newSampleTransform = handleTypeInstance._getSampleTransform(mat);
      const srcSampleTransform = handleTypeInstance._getSampleTransform(srcMat);

      if (srcSampleTransform === newSampleTransform && newSampleTransform && newSampleTransform.clone) {
        newSampleTransform = newSampleTransform.clone();
      }
      if (srcSampleTransform !== newSampleTransform) {
        newSampleTransform.setConfigData(null);
      }
      if (mat.setSampleTransform) {
        mat.setSampleTransform(newSampleTransform);
      } else if (mat.set) {
        mat.set('sampleTransform', newSampleTransform);
      }
    }

    Node3DMaterialUtils.setMaterial(result, mat);

    if (!result.userData) {
      result.userData = {};
    }
    result.userData.type = 'horizontal_handle';
  }

  _setSampleTransform(mat, st) {
    if (!mat) {
      return;
    }
    if (mat.setSampleTransform) {
      mat.setSampleTransform(st);
    } else if (mat.set) {
      mat.set('sampleTransform', st);
    }
  }

  _getSampleTransform(mat) {
    if (!mat) {
      return null;
    }
    if (mat.getSampleTransform) {
      return mat.getSampleTransform();
    } else if (mat.get) {
      return mat.get('sampleTransform');
    }

    return null;
  }
  _getCloneHandleParams() {
    let res = this._cloneHandleParams;

    if (!res) {
      res = this._cloneHandleParams = {};
    }

    return res;
  }

  createHandle(x, y, z, rotY, handleIndex, totalNumHandles, nodeTemplate, container, data, mattressData, buildParams, fallbackMaterial, object3DAsset) {
    const cloneParams = this._getCloneHandleParams();

    cloneParams.cloneGeometry = false;
    cloneParams.onClonedGeometryNode3D = this._handleClonedGeometryNode3D;
    cloneParams.handleTypeInstance = this;
    cloneParams.handleData = data;
    cloneParams.mattressData = mattressData;
    cloneParams.buildParams = buildParams;
    cloneParams.fallbackMaterial = fallbackMaterial;

    const handleNode = Node3DCloner.clone(nodeTemplate, cloneParams);

    BD3DNodeUtils.setNodeType(handleNode, BD3DNodeTypes.horizontalHandle, true);

    let styleID = this._getStyleID(data);

    if (styleID) {
      styleID = styleID.toLowerCase();
    }

    if (styleID === 'hhs014') {
      const mat = new BD3DHandleLogoMaterial();

      HandleUtils.setupLogoHandleMaterial(data, mat, buildParams.assetCollections, object3DAsset);
      Node3DMaterialUtils.assignMaterial(handleNode, mat, mat, null);
    } else {
      this._setHandleMaterials(data, handleNode, handleIndex, totalNumHandles, buildParams, fallbackMaterial);
    }

    this._setupFabricOffset(handleNode, handleIndex, totalNumHandles);

    const tr = handleNode.transform = new SRTTransform3D();
    const pos = tr.position;
    const rot = tr.rotation;

    pos.setCoord(0, x);
    pos.setCoord(1, y);
    pos.setCoord(2, z);
    rot.setCoord(1, rotY);

    if (container) {
      container.addChild(handleNode);
    }

    return handleNode;
  }

  getBorderNodeTypeObject(node) {
    return null;
  }

  getBorderNodeComponent(node) {
    if (!node) {
      return null;
    }

    const geom = node.geometry;

    if (!geom) {
      return null;
    }

    const ud = geom.userData;

    if (!ud) {
      return null;
    }

    return ud.borderComponent;
  }

  isValidBase(comp) {
    if (!comp) {
      return false;
    }
    const t = comp.type;
    const typeObject = BorderComponentUtils.getBorderComponentTypeByName(t);

    if (!typeObject) {
      return false;
    }
    const top = typeObject.getTop(comp);
    const bottom = typeObject.getBottom(comp);
    const height = (top > bottom) ? (top - bottom) : (bottom - top);

    return (t === 'fabric' || t === 'border' || t === 'border3d') && (height > MIN_BORDER_HEIGHT);
  }

  isHandleActive(handleData) {
    if (!handleData) {
      return false;
    }
    const type = typeof handleData === 'object' ? handleData.type : handleData;

  }

  isAllowedOnBorder(handleData, mattressData, borderData, errInf) {
    if (!handleData) {
      return false;
    }
    if (!mattressData) {
      return false;
    }
    if (!borderData) {
      return false;
    }
    if (!this.isHandleActive(handleData)) {
      return false;
    }

    const type = borderData.type;

    const typeLc = (type && type.toLowerCase) ? type.toLowerCase() : null;

    if (typeLc !== 'fabric' && typeLc !== 'border' && typeLc !== 'border3d') {
      this._setAllowError(errInf, HandleErrorType.HORIZONTAL_BORDER_COMPONENT_BASE_ERROR);

      return false;
    }
    const height = borderData.height;

    if (height < MIN_BORDER_HEIGHT) {
      this._setAllowError(errInf, HandleErrorType.HORIZONTAL_BORDER_COMPONENT_BASE_ERROR);

      return false;
    }

    // For now, can't have handles when using a curved border
    const graph = mattressData.border ? mattressData.border.curve : null;

    if (graph) {
      if (GraphUtils.hasGraph(graph)) {
        this._setAllowError(errInf, HandleErrorType.HORIZONTAL_CURVEDBORDER_ERROR);

        return false;
      }
    }

    this._setAllowError(errInf, null);

    return true;
  }

  _isAllowedOnTopOrBottom(handleData, mattressData) {
    const handlers = MattressDA.getHandleData(mattressData);

    if (handlers) {
      const attachmentBase = Utils.tryValues(handlers.attachmentBase, handlers.base);

      if (attachmentBase && typeof (attachmentBase) === 'object') {
        const minH = MIN_BORDER_HEIGHT;
        let abType = attachmentBase.type;

        abType = (abType && abType.toLowerCase) ? abType.toLowerCase() : abType;

        if ((abType === 'top' || abType === 'toppanel') && (MattressDA.getTopHeight(mattressData) - MattressDA.getTopBorderRadius(mattressData)) > minH) {
          return true;
        }
        if ((abType === 'bottom' || abType === 'bottompanel') && (MattressDA.getBottomHeight(mattressData) - MattressDA.getBottomBorderRadius(mattressData)) > minH) {
          return true;
        }
      }
    }

    return false;
  }

  _setAllowError(errorInfo, error) {
    if (!errorInfo) {
      return;
    }
    errorInfo.error = error;
  }

  isAllowed(handleData, mattressData, errorInfo = null) {
    if (!handleData || !mattressData) {
      return false;
    }

    if (this._isAllowedOnTopOrBottom(handleData, mattressData)) {
      return true;
    }
    const border = mattressData.border;
    const components = border.components;
    const numComponents = components.length;

    if (!border || !components || numComponents < MIN_COMPONENTS) {
      this._setAllowError(errorInfo, HandleErrorType.HORIZONTAL_BORDER_COMPONENT_BASE_ERROR);

      return false;
    }

    // For now, can't have handles when using a curved border
    const graph = mattressData.border ? mattressData.border.curve : null;

    if (graph) {
      if (GraphUtils.hasGraph(graph)) {
        this._setAllowError(errorInfo, HandleErrorType.HORIZONTAL_CURVEDBORDER_ERROR);

        return false;
      }
    }

    // For now, can't have handles when the border part has curved geometry caused by the top or bottom border radius
    if (mattressData.box) {
      const topBorderRadius = MattressDA.getResultTopBorderRadius(mattressData);
      const bottomBorderRadius = MattressDA.getResultBottomBorderRadius(mattressData);
      const height = MattressDA.getResultHeight(mattressData);
      const middleHeight = height - topBorderRadius - bottomBorderRadius;

      if (middleHeight < DEFAULT_HANDLE_HEIGHT) {
        this._setAllowError(errorInfo, HandleErrorType.HORIZONTAL_BORDER_COMPONENT_BASE_ERROR);

        return false;
      }
    }

    let comp;
    let i;

    for (i = 0; i < numComponents; ++i) {
      comp = components[i];
      if (this.isValidBase(comp, i, mattressData)) {
        return true;
      }
    }
    this._setAllowError(errorInfo, HandleErrorType.HORIZONTAL_BORDER_COMPONENT_BASE_ERROR);

    return false;
  }
}
