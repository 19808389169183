// TODO: use 1133 as default sample

import OpenSimplexNoise from 'open-simplex-noise';

import BD3DApp from './app/BD3DApp';
import MattressThreeComponentController from './controller/MattressThreeComponentController';
import BD3DThreeComponent from './component/BD3DThreeComponent';

import BD3DBuildInfo from './BD3DBuildInfo';

import Utils from './utils/Utils';

import BackgroundRenderer from './controller/BackgroundRenderer';

import MattressConfig from './mattress/MattressConfig';
import MattressConfigDA from './mattress/MattressConfigDA';
import MattressDA from './mattress/MattressDA';
import HandleDA from './mattress/HandleDA';
import * as mattressConfigUtils from './mattress/mattressConfigUtils';
// import Property from './mattress/Property';
// import MattressProperties from './mattress/MattressProperties';

import HandleType from './handles/HandleType';
import HandleTypes from './handles/HandleTypes';
import HandleStyle from './handles/HandleStyle';
import HandleStyles from './handles/HandleStyles';

import MattressGeomUtils from './geom/MattressGeomUtils';
import BorderComponentTypes from './borders/BorderComponentTypes';
import BorderShape from './geom/BorderShape';
import BorderContourUtil from './geom/BorderContourUtil';

import Asset from './asset/Asset';
import ImageAsset from './asset/ImageAsset';
import Object3DAsset from './asset/Object3DAsset';
import OBJFileAsset from './asset/OBJFileAsset';
import AssetHolder from './asset/AssetHolder';
import AssetGroup from './asset/AssetGroup';
import AssetHolderCollection from './asset/AssetHolderCollection';
import AssetManager from './asset/AssetManager';
import AssetUtils from './asset/AssetUtils';
import QuiltAsset from './asset/QuiltAsset';
import SampleAsset from './asset/SampleAsset';
import QuiltTransform from './material/QuiltTransform';
import SampleTransform from './material/SampleTransform';

import LoadingManager from './loading/LoadingManager';
import URLResolver from './loading/URLResolver';

import BD3DOBJParser3D from './parsers/BD3DOBJParser3D';
import Mattress3DBuilder from './mattress/Mattress3DBuilder';
import Mattress3DFactory from './mattress/Mattress3DFactory';

import GeometryModifier from './geommodifiers/GeometryModifier';
import NoiseGeometryModifier from './geommodifiers/NoiseGeometryModifier';

import Graph from './graph/Graph';
import GraphPoint from './graph/GraphPoint';
import GraphUtils from './graph/GraphUtils';

import BD3DMaterial from './material/BD3DMaterial';
import BD3DFabricMaterial from './material/BD3DFabricMaterial';
import BD3DSampleFabricMaterial from './material/BD3DSampleFabricMaterial';
import MaterialType from './material/MaterialType';
import Node3DMaterialUtils from './material/Node3DMaterialUtils';

import BGR3DToThreeConverter from '../bgr/three/geom/BGR3DToThreeConverter';
import SampleService from './services/SampleService';
import QuiltService from './services/QuiltService';

import SampleLoader from './loading/SampleLoader';
import QuiltLoader from './loading/QuiltLoader';

import SampleDA from './sample/SampleDA';
import SampleCollector from './sample/SampleCollector';

import QuiltDA from './quilt/QuiltDA';
import QuiltRepeatType from './quilt/QuiltRepeatType';
import QuiltConfig from './quilt/QuiltConfig';
import QuiltCollector from './quilt/QuiltCollector';

import ThreeGeomUtils from '../bgr/three/utils/ThreeGeomUtils';
import ThreeMaterialUtils from './controller/ThreeMaterialUtils';
import ThreeMaterial from './controller/ThreeMaterial';
import ThreeFabricMaterial from './controller/ThreeFabricMaterial';
import ThreeMatrixUtils from './controller/ThreeMatrixUtils';
import PointerHitDetection from './controller/PointerHitDetection';
import ThreeSelectionRenderer from './controller/ThreeSelectionRenderer';
import ThreeManager from './controller/ThreeManager';
import ThreeBumpToNormalConverter from './controller/ThreeBumpToNormalConverter';
import SelectableResult from './controller/SelectableResult.js';

import ThreeScreenshotUtil from './three/ThreeScreenshotUtil';
import RenderPlugin from './three/RenderPlugin';

import QuiltBumpBlurThree from './three/QuiltBumpBlurThree';
import ThreeTextureFilter from './three/ThreeTextureFilter';

import BorderGeomUtils from './borders/BorderGeomUtils';
import HandleUtils from './handles/HandleUtils';
import ColorUtils from './utils/ColorUtils';
import CanvasUtils from './utils/CanvasUtils';
import Colors from './colors/Colors';
import ColorList from './colors/ColorList';

import BD3DContainerNode3D from './scenegraph/BD3DContainerNode3D';
import BD3DGeometryNode3D from './scenegraph/BD3DGeometryNode3D';

import FabricTransform from './material/FabricTransform';

import GridQuiltGeneratorThree from './three/GridQuiltGeneratorThree';
import ImageDistanceTransform from './quilt/ImageDistanceTransform';
import ImageDistanceGlow from './quilt/ImageDistanceGlow';

import EventDispatcher from '../bgr/common/events/EventDispatcher';
import LoaderState from '../bgr/common/loading/LoaderState';
import Loader from '../bgr/common/loading/Loader';
import LoaderList from '../bgr/common/loading/LoaderList';
import XHRLoader from '../bgr/common/loading/XHRLoader';
import ImageLoader from '../bgr/common/loading/ImageLoader';

import ControllerPlugin from './controllerplugin/ControllerPlugin';
import ControllerPluginManager from './controllerplugin/ControllerPluginManager';

import MattressConfigObjectTypes from './mattress/MattressConfigObjectTypes';
import ClickSelectionController from './controller/ClickSelectionController';

import Logger from '../bgr/common/logger/Logger';
import ConsoleLogger from '../bgr/common/logger/ConsoleLogger';

import KeyListener from '../bgr/common/keyboard/KeyListener';
import Matrix4Math from '../bgr/bgr3d/math/Matrix4Math';
import VecMat4Math from '../bgr/bgr3d/math/VecMat4Math';

import * as exporters from './export/index.js';

import * as THREE from 'three';

import HistoryManager from './history/HistoryManager';

import {
  Numeric,
  NumericValue,
  NumericOperation
} from './data/numeric';

import Transform3D from '../bgr/bgr3d/transform/Transform3D';
import Matrix4Transform3D from '../bgr/bgr3d/transform/Matrix4Transform3D';
import SRTTransform3D from '../bgr/bgr3d/transform/SRTTransform3D';
import GeometryNode3D from '../bgr/bgr3d/scenegraph/GeometryNode3D';
import ContainerNode3D from '../bgr/bgr3d/scenegraph/ContainerNode3D';
import Vector from '../bgr/bgr3d/geom/Vector';
import Vector2 from '../bgr/bgr3d/geom/Vector2';
import Vector3 from '../bgr/bgr3d/geom/Vector3';
import Vertex from '../bgr/bgr3d/geom/Vertex';
import Polygon from '../bgr/bgr3d/geom/Polygon';
import Geometry from '../bgr/bgr3d/geom/Geometry';
import Matrix4 from '../bgr/bgr3d/geom/Matrix4';
import ArrayUtils from '../bgr/common/utils/ArrayUtils';
import StringUtils from './utils/StringUtils';

// import JSZip from '../bgr/common/zip/jszip.module.js';

export default {
  BD3DBuildInfo,
  BD3DApp,
  MattressThreeComponentController, BD3DThreeComponent,

  // Property, MattressProperties,
  HandleDA,
  MattressDA, MattressConfigDA,
  MattressConfig,
  MattressConfigObjectTypes,
  mattressConfigUtils,

  ClickSelectionController,

  MattressGeomUtils, BorderComponentTypes,
  Mattress3DBuilder, Mattress3DFactory,

  BD3DMaterial, BD3DFabricMaterial, BD3DSampleFabricMaterial,
  MaterialType, Node3DMaterialUtils,
  FabricTransform,

  Matrix4Math,
  VecMat4Math,

  Graph, GraphPoint, GraphUtils,
  BorderShape, BorderContourUtil,
  BorderGeomUtils,

  Asset, ImageAsset, Object3DAsset, OBJFileAsset,
  AssetHolder, AssetHolderCollection, AssetGroup,
  AssetManager, AssetUtils,

  HandleType, HandleTypes,
  HandleStyle, HandleStyles,

  LoadingManager, URLResolver,

  PointerHitDetection,
  ThreeSelectionRenderer,

  BD3DOBJParser3D,
  SampleService,
  QuiltService,
  SampleLoader,
  QuiltLoader,
  ImageDistanceTransform,
  ImageDistanceGlow,

  SampleDA, QuiltDA,

  QuiltAsset, SampleAsset,
  QuiltTransform, SampleTransform,
  QuiltConfig,

  QuiltRepeatType,

  QuiltCollector, SampleCollector,

  ColorUtils,
  CanvasUtils,
  HandleUtils,

  Colors,
  ColorList,

  BD3DContainerNode3D,
  BD3DGeometryNode3D,

  EventDispatcher,
  LoaderState,
  Loader,
  XHRLoader,
  ImageLoader,

  ControllerPlugin, ControllerPluginManager,

  THREE,
  BackgroundRenderer,

  GridQuiltGeneratorThree,

  ThreeTextureFilter,
  ThreeMaterialUtils, ThreeMaterial, ThreeFabricMaterial,
  ThreeMatrixUtils, ThreeGeomUtils, ThreeManager,
  ThreeBumpToNormalConverter,
  ThreeScreenshotUtil,
  RenderPlugin,
  QuiltBumpBlurThree,
  BGR3DToThreeConverter,

  SelectableResult,

  GeometryModifier,
  NoiseGeometryModifier,

  OpenSimplexNoise,

  Utils: Utils,

  KeyListener,

  HistoryManager,

  Numeric,
  NumericValue,
  NumericOperation,

  Logger,
  ConsoleLogger,

  exporters,
  // JSZip,

  Transform3D,
  Matrix4Transform3D,
  SRTTransform3D,
  GeometryNode3D,
  ContainerNode3D,
  Vector,
  Vector2,
  Vector3,
  Vertex,
  Polygon,
  Geometry,
  Matrix4,
  ArrayUtils,
  StringUtils,
  LoaderList
};
