import EventDispatcher from '../events/EventDispatcher';
/**
 * Doing stuff periodically, driven by an Animator instance
 */
export default class Animation extends EventDispatcher {
  constructor(callback = null, data = null) {
    super();
    this.onAnimate = callback;
    this.data = data;
  }
  /**
   * Called by the animator instance
   * @param  {AnimEvent} event - anim event
   * @return {void}
   */
  animate(event) {
    const onAnimate = this.onAnimate;

    event.animation = this;
    if (onAnimate) {
      // Should already be set in Animator
      onAnimate(event);
    }
    this.dispatchEvent(event);
  }
}
