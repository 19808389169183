import HandleType from './HandleType';
import HandleStyles from './HandleStyles';
import HandleUtils from './HandleUtils';
// import HandleStyles from './HandleStyles';
import GraphUtils from '../graph/GraphUtils';
import BD3DGeometry from '../geom/BD3DGeometry';
import SRTTransform3D from '../../bgr/bgr3d/transform/SRTTransform3D';
import GeometryNode3D from '../../bgr/bgr3d/scenegraph/GeometryNode3D';
import ContainerNode3D from '../../bgr/bgr3d/scenegraph/ContainerNode3D';
import BD3DContainerNode3D from '../scenegraph/BD3DContainerNode3D';
import Node3DCloner from '../../bgr/bgr3d/cloners/Node3DCloner';
import BoundingBoxUtils from '../../bgr/bgr3d/utils/BoundingBoxUtils.js';
// import BD3DFabricMaterial from '../material/BD3DFabricMaterial';
import BD3DHandleLogoMaterial from '../material/BD3DHandleLogoMaterial';
import BD3DSampleFabricMaterial from '../material/BD3DSampleFabricMaterial';
import FabricTransform from '../material/FabricTransform';
import SampleTransform from '../material/SampleTransform';
import Node3DMaterialUtils from '../material/Node3DMaterialUtils';
import BD3DFabricMaterial from '../material/BD3DFabricMaterial';
import MaterialTypes from '../material/MaterialTypes';
import MaterialType from '../material/MaterialType';
import FabricMaterialUtils from '../material/FabricMaterialUtils';
import Object3DAsset from '../asset/Object3DAsset';
import ImageAsset from '../asset/ImageAsset';

import MattressGeomUtils from '../geom/MattressGeomUtils';

import HandleErrorType from './HandleErrorType';
import MattressDA from '../mattress/MattressDA';

const MIN_COMPONENTS = 1;
const MIN_LENGTH_FOR_HANDLES = 120;
const MIN_INNER_LENGTH_FOR_HANDLES = 80;

const Assets = {
  HVS00001_OBJ: new Object3DAsset('handle.vertical.HVS00001.object'),
  HVS00002_OBJ: new Object3DAsset('handle.vertical.HVS00002.object'),
  HV004_OBJ: new Object3DAsset('handle.vertical.HV004.object'),
  HVS005_OBJ: new Object3DAsset('handle.vertical.HVS005.object'),
  HVS006_OBJ: new Object3DAsset('handle.vertical.HVS006.object'),
  HV007_OBJ: new Object3DAsset('handle.vertical.HV007.object'),
  HV008_OBJ: new Object3DAsset('handle.vertical.HV008.object'),
  HV012_OBJ: new Object3DAsset('handle.vertical.HV012.object'),

  HVS003_TEX: new ImageAsset('handle.vertical.HVS003.texture')
  /*
  HHP031_OBJ: new Object3DAsset('handle.pocket.HHP031.object')
  */
};

Assets.HVS003_OBJ = Assets.HVS00001_OBJ;

function findOutMaterialType(data) {
  if (!data) {
    return null;
  }
  if (data.sample) {
    return MaterialTypes.SAMPLE;
  }

  return null;
}

// callback when cloning handles -> copy material settings etc
function onClonedHandleNode(source, result, params) {
  if (!source || !result) {
    return;
  }

  const srcUD = source.userData;
  let resUD = result.userData;

  if (!srcUD) {
    return;
  }
  if (!resUD) {
    resUD = result.userData = {};
  }
  const handleTypeInstance = params.handleTypeInstance;

  let styleID = handleTypeInstance._getStyleID(params.data);

  styleID = styleID ? styleID.toLowerCase() : styleID;


  if (styleID === 'hvs003') {
    const mat = new BD3DHandleLogoMaterial();

    const handleData = params.data;

    HandleUtils.setupLogoHandleMaterial(handleData, mat, params.assetCollections, params.objectAsset);
    Node3DMaterialUtils.assignMaterial(result, mat, mat, null);

    return;
  }

  const materialName = srcUD.materialName;

  if (materialName || resUD.materialName) {
    resUD.materialName = srcUD.materialName;
  }

  let materialLib = null, fallbackMaterial = null;// , materialsSrc = null;

  if (params && params.data && params.data.style) {
    materialLib = params.data.style.materials;
    fallbackMaterial = params.data.style.material;
  }

  let createHandleMaterialCallback = handleTypeInstance._createHandleMaterialCallback;

  if (!createHandleMaterialCallback) {
    createHandleMaterialCallback = handleTypeInstance._createHandleMaterialCallback = (materialData, node3d, paramsObj, geomMatName) => {
      return handleTypeInstance._createHandleMaterial(materialData, node3d, paramsObj, geomMatName);
    };
  }
  const assignMaterialParams = {
    styleID: styleID,
    handleData: params.data,
    transformScaleY: params.scaleY,
    handleIndex: params.handleIndex,
    buildParams: params.buildParams,
    fallbackMaterial: params.fallbackMaterial,
    mattressData: params.mattressData,
    getMaterialType: findOutMaterialType,

    getMaterialFromLib: handleTypeInstance._getMaterialFromLib,
    handleType: handleTypeInstance,

    topBorderCompData: params.topBorderCompData,
    topBorderCompIndex: params.topBorderCompIndex,
    bottomBorderCompData: params.bottomBorderCompData,
    bottomBorderCompIndex: params.bottomBorderCompIndex,

    createMaterial: createHandleMaterialCallback // handleTypeInstance._createHandleMaterial
  };

  Node3DMaterialUtils.assignMaterials(result, materialLib, fallbackMaterial, assignMaterialParams);
}

const TapeMaterialType = new MaterialType('tape');

/**
 * @class VerticalHandleType
 * @description Vertical handle type
 *
 * ==========================
 *     ||              ||
 * ----||--------------||----
 *     ||              ||
 * ==========================
 *
 * */
export default class VerticalHandleType extends HandleType {
  static getTypeName() {
    return 'vertical';
  }

  static get typeName() {
    return this.getTypeName();
  }

  // materialData = config json object
  // elementType = tape / fabric
  _newColorMaterial(materialData, elementType, uvData, buildParams) {
    const mt = materialData.material || 'cotton';
    const mat = new BD3DFabricMaterial();
    const sampleTransform = new SampleTransform();

    if (elementType === 'tape') {
      mat.setColorType('tape');
    }

    mat.setColorMultiplier(materialData.color);

    const defaultFabric = buildParams.assetCollections.commonTextures.getAssetByName('defaultfabric.color');
    const defaultFabricNormal = buildParams.assetCollections.commonTextures.getAssetByName('defaultfabric.normal');

    const rs = defaultFabric.metaData ? defaultFabric.metaData.realSize : null;
    const realW = rs ? rs.width : 2;
    const realH = rs ? rs.height : 2;

    sampleTransform.setGeometryUVData(uvData);
    sampleTransform.setRealSize(realW, realH);

    mat.setSampleTexture(defaultFabric);
    mat.setSampleNormalMap(defaultFabricNormal);
    mat.setFabricType(mt);
    mat.setSampleTransform(sampleTransform);

    return mat;
  }

  _setMaterialSampleOffset(fbMat, sampleOffX, sampleOffY) {
    if (!fbMat) {
      return;
    }
    let tr = fbMat.getSampleTransform();

    if (!tr) {
      tr = new FabricTransform();
      fbMat.setSampleTransform(tr);
    }

    if (!(tr instanceof SampleTransform)) {
      const newTR = new FabricTransform();

      newTR.copyValues(tr);
      tr = newTR;
      fbMat.setSampleTransform(tr);
    }
    // tr.setOffset(sampleOffX, sampleOffY);
    tr.translateX = sampleOffX;
    tr.translateY = sampleOffY;
  }

  _getMaterialFromLib(materialName, materialLib, fallbackMaterial, node, params) {
    if (!materialLib) {
      return null;
    }
    let res = null;
    let matName = materialName;

    if (!matName) {
      res = materialLib.fabric;
      if (res) {
        return res;
      }

      return fallbackMaterial;
    }

    const ht = params ? params.handleType : null;
    const hd = params ? params.handleData : null;
    const styleID = ht._getStyleID(hd);
    const styleIDLC = (styleID && styleID.toLowerCase) ? styleID.toLowerCase() : null;

    if (styleIDLC === 'hvs005') {
      if (matName === 'side') {
        matName = 'tape';
      } else if (matName === 'middle') {
        matName = 'fabric';
      }
    } else if (styleIDLC === 'hvs006') {
      if (matName === 'side') {
        matName = 'fabric';
      } else if (matName === 'middle') {
        matName = 'tape';
      }
    }

    res = materialLib[matName];

    if (!res) {


      if ((matName === 'fabric' && styleIDLC === 'hvs005') || (matName === 'tape' && styleIDLC === 'hvs006')) {
        res = materialLib.middle;
      } else if ((matName === 'fabric' && styleIDLC === 'hvs006') || (matName === 'tape' && styleIDLC === 'hvs005')) {
        res = materialLib.side;
      } else if ((matName === 'middle' && styleIDLC === 'hvs005') || (matName === 'side' && styleIDLC === 'hvs006')) {
        res = materialLib.fabric;
      } else if ((matName === 'middle' && styleIDLC === 'hvs006') || (matName === 'side' && styleIDLC === 'hvs005')) {
        res = materialLib.tape;
      }
    }

    if (!res) {
      return fallbackMaterial;
    }

    return res;
  }

  _createHandleMaterial(materialData, node, params, geometryMaterialName) {
    const buildParams = params ? params.buildParams : null;
    const uvData = MattressGeomUtils.getGeometryUVWorldTransform(node);

    const geomMatName = geometryMaterialName && geometryMaterialName.toLowerCase ? geometryMaterialName.toLowerCase() : null;
    const styleObj = params ? HandleStyles.get(params.styleID) : null;

    let elementType = styleObj.getElementTypeByName(geomMatName);

    if (!elementType && (geomMatName === 'tape' || geomMatName === 'fabric')) {
      elementType = geomMatName;
    }

    const handleIndex = params ? params.handleIndex : 0;
    const sampleOffX = handleIndex * 100;
    const sampleOffY = -handleIndex;

    let transformScaleY = params ? params.transformScaleY : 1;

    if (typeof (transformScaleY) !== 'number' || isNaN(transformScaleY)) {
      transformScaleY = 1.0;
    }

    if (materialData) {

      const sample = materialData.sample || materialData.texture;
      const st = typeof (sample);

      let sampleID = null;

      if (sample && st !== 'undefined') {
        if (st === 'string') {
          sampleID = sample;
        } else if (st === 'number') {
          sampleID = `${sample}`;
        } else if (st === 'object') {
          sampleID = sample.id || sample.ID;
        }
      }
      if (sampleID) {
        const sampleAsset = buildParams ? buildParams.assetCollections.samples.getAssetByName(sampleID) : null;

        if (sampleAsset) {
          const mat = new BD3DSampleFabricMaterial();
          const sampleTransform = new SampleTransform();

          sampleTransform.setGeometryUVData(uvData);
          // sampleTransform.setSampleConfigData(sampleAsset.getSampleConfigData());
          sampleTransform.setSampleConfigData(null);
          sampleTransform.setSampleData(sampleAsset.getSampleData());
          sampleTransform.setGeometryScaleY(transformScaleY);

          // sampleTransform.setOffset(sampleOffX, sampleOffY);
          sampleTransform.translateX = sampleOffX;
          sampleTransform.translateY = sampleOffY;

          sampleTransform.setRealSize(sampleAsset.getFallbackWidth(), sampleAsset.getFallbackHeight());
          sampleTransform.setSourceAspectRatio(sampleAsset.getImageWidth(), sampleAsset.getImageHeight());
          
          mat.setSampleAsset(sampleAsset);
          mat.setSampleTransform(sampleTransform);

          return mat;
        }
      } else if (materialData.color) {
        return this._newColorMaterial(materialData, elementType, uvData, buildParams);
      }
    }

    // fallback material
    let fbMat = params.fallbackMaterial;

    if (!fbMat) {
      let sampleTransform = null;

      // find border components to get the material from
      const mattressData = params.mattressData;
      const border = mattressData ? mattressData.border : null;
      const borderComps = border ? border.components : null;
      const topBorderCompIndex = params.topBorderCompIndex;
      const bottomBorderCompIndex = params.bottomBorderCompIndex;
      const topBorderComp = params.topBorderCompData;
      const bottomBorderComp = params.bottomBorderCompData;
      const step = topBorderCompIndex < bottomBorderCompIndex ? 1 : -1;
      let fabricComponent = params.fabricBorderComponent;

      if (!fabricComponent && elementType === 'fabric') {
        for (let i = topBorderCompIndex; i < bottomBorderCompIndex; i += step) {
          const comp = borderComps[i];

          if (comp && comp.type === 'fabric') {
            fabricComponent = comp;
            break;
          }
        }
        if (!fabricComponent) {
          // look for 3d component
          for (let i = topBorderCompIndex; i < bottomBorderCompIndex; i += step) {
            const comp = borderComps[i];

            if (comp && (comp.type === 'border3d' || comp.type === '3dborder')) {
              fabricComponent = comp;
              break;
            }
          }
        }
        params.fabricBorderComponent = fabricComponent;
      }

      if (elementType === 'tape') {
        // create material from pipings / tape
        // use topBorderComp / bottomBorderComp
        let targetComp = null;

        if (topBorderComp && topBorderComp.type === 'tape') {
          targetComp = topBorderComp;
        } else if (bottomBorderComp && bottomBorderComp.type === 'tape') {
          targetComp = bottomBorderComp;
        }
        if (!targetComp) {
          targetComp = topBorderComp;
        }
        if (targetComp && (targetComp.type === 'piping' || targetComp.type === 'tape')) {
          if (targetComp.type === 'piping' || !(targetComp.texture)) {
            // color material;
            fbMat = this._newColorMaterial(targetComp, elementType, uvData, buildParams);
          } else if (targetComp.texture) {
            fbMat = FabricMaterialUtils.getMaterialFromData(targetComp.texture, null, buildParams, uvData);
            this._setMaterialSampleOffset(fbMat, sampleOffX, sampleOffY);
          }
        }
      } else if (elementType === 'fabric' && fabricComponent) {
        // create material from border fabric
        // use fabricComponent
        fbMat = FabricMaterialUtils.getMaterialFromData(fabricComponent.texture, null, buildParams, uvData);
        sampleTransform = fbMat.getSampleTransform();
        sampleTransform.setGeometryScaleY(transformScaleY);
        this._setMaterialSampleOffset(fbMat, sampleOffX, sampleOffY);
      }

      if (!sampleTransform) {
        sampleTransform = new SampleTransform();
      }
      if (!fbMat) {
        fbMat = new BD3DFabricMaterial();
        let realWidth = 1;
        let realHeight = 1;
        const col = materialData ? materialData.color : null;
        const sampleTexture = buildParams ? buildParams.assetCollections.commonTextures.getAssetByName('defaultfabric.color') : null;
        const sampleNormalMap = buildParams ? buildParams.assetCollections.commonTextures.getAssetByName('defaultfabric.normal') : null;

        if (sampleTexture && sampleTexture.metaData) {
          const realSize = sampleTexture.metaData.realSize;

          if (realSize) {
            realWidth = realSize.width;
            realHeight = realSize.height;
          }
        }

        sampleTransform.setRealWidth(realWidth);
        sampleTransform.setRealHeight(realHeight);
        sampleTransform.setGeometryUVData(uvData);
        fbMat.setSampleTexture(sampleTexture);
        fbMat.setSampleNormalMap(sampleNormalMap);
        fbMat.setSampleTransform(sampleTransform);
        fbMat.setColorMultiplier(col);
      }

      // Usually tape I guess?
      fbMat.setType(elementType === 'tape' ? TapeMaterialType : MaterialTypes.FABRIC);

    }

    return fbMat;
  }

  // TODO: Lots of similar code with HorizontalHandleType
  _assignMaterial(node, materialLib, fallbackMaterial = null) {
    Node3DMaterialUtils.assignMaterialSettings(node, materialLib, fallbackMaterial);
  }

  addAssets(data, handleStyle, params, array = null, assetManager = null) {
    const hsName = handleStyle.getID();
    let arr = array;

    if (hsName) {
      if (hsName.toLowerCase() === 'hvs003') {
        // arr = assetManager.addAssetToArray(Assets.HVS003_TEX, arr, params);
        arr = HandleUtils.addLogoHandleImageAssets(data, assetManager, params, arr) || arr;

        // const imgURL = getHandleImageURL(data);
        // const imgID = getHandleImageID(data);
        // let logoFabric = null;

        // logoFabric = imgID && assetManager.assetCollections.samples.getAssetByName(imgID);
        // if (!logoFabric) {
        //   logoFabric = imgID && assetManager.assetCollections.temporary.getAssetByName(imgID);
        // }
        // if (!logoFabric) {
        //   logoFabric = imgURL && assetManager.assetCollections.temporary.getAssetByName(imgURL);
        // }
        // if (!logoFabric && imgURL) {
        //   logoFabric = new ImageAsset(imgID);
        //   assetManager.setAssetURL(logoFabric, imgURL);
        //   assetManager.assetCollections.temporary.addAsset(logoFabric, imgID);
        // }

        // if (!logoFabric) {
        //   logoFabric = assetManager.assetCollections.commonTextures.getAssetByName('bd.logo.fabric');
        // }
        // const logoFabricBg = assetManager.assetCollections.commonTextures.getAssetByName('bd.logo.fabric.background');

        // arr = assetManager.addAssetToArray(logoFabric, arr, params);
        // arr = assetManager.addAssetToArray(logoFabricBg, arr, params);
      }
      /*
      const objSuffix = '_OBJ';
      const objAssetName = hsName.toUpperCase() + objSuffix;
      const objAsset = Assets[objAssetName];
      */
      const objAsset = this._get3DObjectAsset(data);

      arr = assetManager.addAssetToArray(objAsset, arr, params);
    }

    const style = data.style ? data.style : data;
    const materials = style.materials;

    if (materials) {
      for (const v in materials) {
        if (materials.hasOwnProperty(v)) {
          const mtl = materials[v];

          this._addHandleMaterialAssets(mtl, assetManager, params, array);
        }
      }
    }
    arr = this._addHandleMaterialAssets(style.material, assetManager, params, arr);

    const defSample = assetManager.assetCollections.commonTextures.getAssetByName('defaultfabric.color');
    const defNormal = assetManager.assetCollections.commonTextures.getAssetByName('defaultfabric.normal');

    assetManager.addAssetToArray(defSample, arr, params);
    assetManager.addAssetToArray(defNormal, arr, params);

    return arr;
  }

  _addHandleMaterialAssets(mtl, assetManager, params, array) {
    let arr = array;
    const sampleData = mtl ? (mtl.sample || mtl.texture) : null;
    const quiltData = mtl ? mtl.quilt : null;
    const sampleAsset = assetManager._parseSampleAsset(sampleData);
    const quiltAsset = assetManager._parseQuiltAsset(quiltData);

    arr = assetManager.addAssetToArray(sampleAsset, arr, params);
    arr = assetManager.addAssetToArray(quiltAsset, arr, params);

    return arr;
  }
  /*
  collectAssets(data, assetManager, params = null, array = null) {
    // const OBJ3D = 'verticalHandle_object3D';
    // const OBJ3DURL = 'verticalhandle.obj';

    let assetID = null;

    if (data && data.style && data.style.object3d) {
      assetID = data.style.object3d;
    }

    const assetHolder = assetManager.findAssetHolder('handles');
    let arr = array;

    if (!assetHolder) {
      return arr;
    }
    if (!assetID) {
      assetID = 'verticalHandle';
    }
    const asset = assetHolder.getAsset(assetID);

    if (asset) {
      arr = assetManager.collectAsset(asset, arr, params);
    }

    return arr;
  }
  */

  _get3DObjectAsset(data, mattressData, assets) {
    const handleStyleID = this._getStyleID(data);

    if (!handleStyleID) {
      return null;
    }
    const suffix = '_OBJ';
    const assetName = handleStyleID + suffix;
    const asset = Assets[assetName.toUpperCase()];

    if (asset) {
      const node = asset.getNode3D();

      if (asset.metaData) {
        const uvWorldTransform = asset.metaData.uvWorldTransform || asset.metaData.uvData;

        this._assignUVData(node, uvWorldTransform);
      }
    }

    return asset;
  }

  create3DHandles(data, borderNodes, mattressData, borderShape, borderCurveGraph, buildParams, resultNode = null) {
    if (resultNode) {
      resultNode.removeChildren();
    }
    if (!mattressData || !borderNodes) {
      return resultNode;
    }

    const l = borderNodes.length;
    let i;
    let node;
    let geom;
    let ud;
    let comp;
    let topElement = null;
    let topBorderPos = -1;

    let bottomElement = null;
    let bottomBorderPos = -1;
    let topBorderCompData = null;
    let bottomBorderCompData = null;
    let topBorderCompIndex = -1;
    let bottomBorderCompIndex = -1;

    // find top attachment
    for (i = 0; i < l; ++i) {
      node = borderNodes[i];
      const nodeUserData = node && node.userData;
      const borderComponentType = nodeUserData && nodeUserData.borderComponentType;
      const nodeGeometry = (borderComponentType && borderComponentType.getNodeMainGeometry(node)) || (node && node.geometry);

      if (nodeGeometry) {
        ud = nodeGeometry.userData;
        if (!ud) {
          continue;
        }
        comp = ud.borderComponent;
        if (this.isValidAttachment(comp, i, l, data, mattressData, true)) {
          topElement = node;
          topBorderCompData = comp;
          topBorderCompIndex = i;

          topBorderPos = ud.borderMiddleOffset;
          if (comp.type === 'fabric' || comp.type === 'border' || comp.type === 'border3d') {
            topBorderPos = ud.borderTopOffset - 0.1;
          }
          break;
        }
      }
    }

    // find bottom attachment
    for (i = l - 1; i >= 0; --i) {
      node = borderNodes[i];
      const nodeUserData = node && node.userData;
      const borderComponentType = nodeUserData && nodeUserData.borderComponentType;
      const nodeGeometry = (borderComponentType && borderComponentType.getNodeMainGeometry(node)) || (node && node.geometry);

      if (nodeGeometry) {
        geom = nodeGeometry;
        ud = geom.userData;
        if (!ud) {
          continue;
        }
        comp = ud.borderComponent;
        if (this.isValidAttachment(comp, i, l, data, mattressData, false)) {
          bottomElement = node;
          bottomBorderCompData = comp;
          bottomBorderCompIndex = i;

          bottomBorderPos = ud.borderMiddleOffset;
          if (comp.type === 'fabric' || comp.type === 'border') {
            bottomBorderPos = ud.borderBottomOffset;
          }
          break;
        }
      }
    }

    if (!topElement || !bottomElement) {
      // TODO: error: Could not attach vertical handle. At least 2 pipings are needed
      return resultNode;
    }
    const assets = buildParams ? buildParams.assets : null;
    let mattressWidth = 0;
    let mattressLength = 0;
    let cornerRadius = 0;

    if (mattressData.box && mattressData.box.size) {

      mattressWidth = mattressData.box.size.width;
      mattressLength = mattressData.box.size.length;
    }
    if (mattressData.box && mattressData.box.radius) {
      cornerRadius = mattressData.box.radius.corners;
    }
    const hMattressWidth = mattressWidth * 0.5;
    const hMattressLength = mattressLength * 0.5;

    const hMattressSize = hMattressWidth < hMattressLength ? hMattressWidth : hMattressLength;

    cornerRadius = cornerRadius > hMattressSize ? hMattressSize : cornerRadius;

    let tempTransform;

    tempTransform = borderShape.getTransformAtLength(topBorderPos, tempTransform);
    // const topLocName = tempTransform.locationName;
    const topBorderY = tempTransform.matrix4.y;

    tempTransform = borderShape.getTransformAtLength(bottomBorderPos, tempTransform);
    // const bottomLocName = tempTransform.locationName;
    const bottomBorderY = tempTransform.matrix4.y;

    // TODO: support for more meshes in a container
    // Currently only one geometry instance is allowed

    const resNode = resultNode ? resultNode : new BD3DContainerNode3D();

    /*
    if (topLocName === 'middle' && bottomLocName === 'middle') {
      // No influence by border radius curve -> no vertex deform needed -> use source meshes

    } else {
      // Mesh should have vertex deformation
    }
    */
    // TODO: scale & deform vertical handles

    const fallbackMaterial = null;

    const innerWidth = mattressWidth - cornerRadius * 2;
    const innerLength = mattressLength - cornerRadius * 2;
    const hInnerWidth = innerWidth * 0.5;
    const hInnerLength = innerLength * 0.5;

    let placementCountResult = null;

    const handleWidth = 5;

    placementCountResult = HandleUtils.largeEnoughForNumHandles(data, true, mattressWidth, innerWidth, handleWidth, MIN_LENGTH_FOR_HANDLES, MIN_INNER_LENGTH_FOR_HANDLES, placementCountResult);

    const {largeEnoughFor1Handle: isLargeEnoughFor1TopHandle, largeEnoughFor2Handles: isLargeEnoughFor2TopHandles} = placementCountResult;

    placementCountResult = HandleUtils.largeEnoughForNumHandles(data, false, mattressLength, innerLength, handleWidth, MIN_LENGTH_FOR_HANDLES, MIN_INNER_LENGTH_FOR_HANDLES, placementCountResult);

    const {largeEnoughFor1Handle: isLargeEnoughFor1SideHandle, largeEnoughFor2Handles: isLargeEnoughFor2SideHandles} = placementCountResult;

    const sideRelLoc = 0.5;
    const topRelLoc = 0.5;

    let indexCounter = 0;

    const verticalHandlesBuildData = {
      data: data,
      mattressData: mattressData,
      assets: assets,
      borderShape: borderShape,
      topBorderPos: topBorderPos,
      bottomBorderPos: bottomBorderPos,
      topBorderY: topBorderY,
      bottomBorderY: bottomBorderY,

      topBorderCompData: topBorderCompData,
      bottomBorderCompData: bottomBorderCompData,
      topBorderCompIndex: topBorderCompIndex,
      bottomBorderCompIndex: bottomBorderCompIndex
    };

    // Side handles left & right
    if (isLargeEnoughFor2SideHandles) {
      this.newVerticalHandle(-hMattressWidth, 0, -hInnerLength * sideRelLoc, -Math.PI * 0.5, verticalHandlesBuildData, resNode, buildParams, indexCounter++, fallbackMaterial);
      this.newVerticalHandle(hMattressWidth, 0, -hInnerLength * sideRelLoc, Math.PI * 0.5, verticalHandlesBuildData, resNode, buildParams, indexCounter++, fallbackMaterial);
      this.newVerticalHandle(-hMattressWidth, 0, hInnerLength * sideRelLoc, -Math.PI * 0.5, verticalHandlesBuildData, resNode, buildParams, indexCounter++, fallbackMaterial);
      this.newVerticalHandle(hMattressWidth, 0, hInnerLength * sideRelLoc, Math.PI * 0.5, verticalHandlesBuildData, resNode, buildParams, indexCounter++, fallbackMaterial);
    } else if (isLargeEnoughFor1SideHandle) {
      this.newVerticalHandle(-hMattressWidth, 0, 0, -Math.PI * 0.5, verticalHandlesBuildData, resNode, buildParams, indexCounter++, fallbackMaterial);
      this.newVerticalHandle(hMattressWidth, 0, 0, Math.PI * 0.5, verticalHandlesBuildData, resNode, buildParams, indexCounter++, fallbackMaterial);
    }

    // front & back handles
    if (isLargeEnoughFor2TopHandles) {
      this.newVerticalHandle(-hInnerWidth * topRelLoc, 0, hMattressLength, 0, verticalHandlesBuildData, resNode, buildParams, indexCounter++, fallbackMaterial);
      this.newVerticalHandle(-hInnerWidth * topRelLoc, 0, -hMattressLength, Math.PI, verticalHandlesBuildData, resNode, buildParams, indexCounter++, fallbackMaterial);
      this.newVerticalHandle(hInnerWidth * topRelLoc, 0, hMattressLength, 0, verticalHandlesBuildData, resNode, buildParams, indexCounter++, fallbackMaterial);
      this.newVerticalHandle(hInnerWidth * topRelLoc, 0, -hMattressLength, Math.PI, verticalHandlesBuildData, resNode, buildParams, indexCounter++, fallbackMaterial);
    } else if (isLargeEnoughFor1TopHandle) {
      this.newVerticalHandle(0, 0, hMattressLength, 0, verticalHandlesBuildData, resNode, buildParams, indexCounter++, fallbackMaterial);
      this.newVerticalHandle(0, 0, -hMattressLength, Math.PI, verticalHandlesBuildData, resNode, buildParams, indexCounter++, fallbackMaterial);
    }

    return resNode;
  }

  getNodeHeight(node) {
    if (!node) {
      return 0;
    }

    let ud = node.userData;
    let bbox = null;

    if (ud) {
      bbox = ud.boundingBox;
    }
    if (!bbox) {
      bbox = BoundingBoxUtils.getBoundingBox(node);
      if (!ud) {
        ud = node.userData = {};
      }
      ud.boundingBox = bbox;
    }
    if (!bbox) {
      return 0;
    }
    let height = bbox.height;

    if (typeof (height) === 'undefined' || height === null) {
      height = bbox.maxy - bbox.miny;
      bbox.height = height;
    }

    return height;
  }

  _assignUVData(node, uvData) {
    if (!node) {
      return;
    }
    if (node instanceof GeometryNode3D) {
      const geom = node.geometry;

      if (!geom) {
        return;
      }
      let scX = 1, scY = 1, minU = 0, minV = 0, maxU = 1, maxV = 1;

      if (uvData) {
        // scX = 1 / uvData.scaleU;
        // scY = 1 / uvData.scaleV;
        if (typeof (uvData.scaleU) === 'number') {
          scX = uvData.scaleU;
        }
        if (typeof (uvData.scaleV) === 'number') {
          scY = uvData.scaleV;
        }
        if (typeof (uvData.minU) === 'number') {
          minU = uvData.minU;
        }
        if (typeof (uvData.minV) === 'number') {
          minV = uvData.minV;
        }
        if (typeof (uvData.maxU) === 'number') {
          maxU = uvData.maxU;
        }
        if (typeof (uvData.maxV) === 'number') {
          maxV = uvData.maxV;
        }
      }
      if (geom instanceof BD3DGeometry) {
        geom.setUVScale(scX, scY);
        geom.setUVBounds(minU, minV, maxU, maxV);
      } else {
        let ud = geom.userData;

        if (!ud) {
          ud = geom.userData = {};
        }
        ud.uvData = uvData;
      }
    } else if (node instanceof ContainerNode3D) {
      const children = node.children;
      const num = children ? children.length : 0;

      for (let i = 0; i < num; ++i) {
        this._assignUVData(children[i], uvData);
      }
    }
  }
  /*
  newVerticalHandle(x, y, z, rotY, {data, mattressData, assets, borderShape, topBorderLoc,
    bottomBorderLoc, topBorderY, bottomBorderY}, container, buildParams, index, fallbackMaterial) {
  */

  /*
  newVerticalHandle(x, y, z, rotY, data, mattressData, assets, borderShape, topBorderLoc,
    bottomBorderLoc, topBorderY, bottomBorderY, container, buildParams, index, fallbackMaterial) {
  */
  newVerticalHandle(x, y, z, rotY, verticalHandlesBuildData, container, buildParams, index, fallbackMaterial) {
    const data = verticalHandlesBuildData.data;
    const mattressData = verticalHandlesBuildData.mattressData;
    const assets = verticalHandlesBuildData.assets;
    // const borderShape = verticalHandlesBuildData.borderShape;
    const topBorderY = verticalHandlesBuildData.topBorderY;
    const bottomBorderY = verticalHandlesBuildData.bottomBorderY;
    // const topBorderLoc = verticalHandlesBuildData.topBorderLoc;
    // const bottomBorderLoc = verticalHandlesBuildData.bottomBorderLoc;
    //


    const asset = this._get3DObjectAsset(data, mattressData, assets);

    if (!asset) {
      return null;
    }
    const node = asset.getNode3D();

    if (asset.metaData) {
      const uvWorldTransform = asset.metaData.uvWorldTransform || asset.metaData.uvData;

      this._assignUVData(node, uvWorldTransform);
    }

    if (!node) {
      return null;
    }
    const nodeHeight = this.getNodeHeight(node);
    const borderHeight = topBorderY - bottomBorderY;
    const borderY = (topBorderY + bottomBorderY) * 0.5;
    const scaleY = nodeHeight === 0 ? 0 : borderHeight / nodeHeight;
    const transf = new SRTTransform3D();

    transf.position.setCoord(0, x);
    transf.position.setCoord(1, y + borderY);
    transf.position.setCoord(2, z);

    transf.rotation.setCoord(1, rotY);

    transf.scale.setCoord(1, scaleY);

    const cloneGeom = false;
    let clonerParams = this._clonerParams;

    if (!clonerParams) {
      clonerParams = this._clonerParams = {
        objectAsset: asset,
        handleTypeInstance: this,
        onClonedNode3D: onClonedHandleNode,
        cloneGeometry: cloneGeom,
        data: data,
        assets: assets,
        assetCollections: buildParams.assetCollections,
        buildParams: buildParams,
        handleIndex: index,
        scaleY: scaleY,
        fallbackMaterial: fallbackMaterial,
        mattressData: mattressData,

        topBorderCompData: verticalHandlesBuildData.topBorderCompData,
        bottomBorderCompData: verticalHandlesBuildData.bottomBorderCompData,
        topBorderCompIndex: verticalHandlesBuildData.topBorderCompIndex,
        bottomBorderCompIndex: verticalHandlesBuildData.bottomBorderCompIndex
      };
    }
    clonerParams.objectAsset = asset;
    clonerParams.handleTypeInstance = this;
    clonerParams.onClonedNode3D = onClonedHandleNode;
    clonerParams.cloneGeometry = cloneGeom;
    clonerParams.data = data;
    clonerParams.assets = assets;
    clonerParams.assetCollections = buildParams.assetCollections;
    clonerParams.buildParams = buildParams;
    clonerParams.handleIndex = index;
    clonerParams.scaleY = scaleY;
    clonerParams.fallbackMaterial = fallbackMaterial;
    clonerParams.mattressData = mattressData;

    clonerParams.topBorderCompData = verticalHandlesBuildData.topBorderCompData;
    clonerParams.bottomBorderCompData = verticalHandlesBuildData.bottomBorderCompData;
    clonerParams.topBorderCompIndex = verticalHandlesBuildData.topBorderCompIndex;
    clonerParams.bottomBorderCompIndex = verticalHandlesBuildData.bottomBorderCompIndex;

    const res = Node3DCloner.clone(node, clonerParams);

    res.transform = transf;

    if (container) {
      if (container instanceof Array) {
        container.push(res);
      } else if (container instanceof ContainerNode3D) {
        if (!container.children) {
          container.children = [];
        }
        container.children.push(res);
      }
    }

    if (!res.userData) {
      res.userData = {};
    }
    res.userData.type = 'vertical_handle';

    return res;
  }

  /**
   * @method isValidAttachment
   * @description returns true if a given border component is a valid attachment for a vertical handle
   * @param {Object} comp - border component object
   * @param {int} index - current border component index
   * @param {int} numComponents - number of border components of the single
   * @param {object} data - data object of the handle
   * @param {object} mattressData - data object of the mattress / single
   * @param {boolean} topAttachment - true if top attachment, false if bottom attachment
   * @return {Boolean} - return bool
   * */
  isValidAttachment(comp, index, numComponents, data, mattressData, topAttachment) {
    if (!comp) {
      return false;
    }
    let ct = comp.type;

    if (!ct) {
      return false;
    }

    ct = ct.toLowerCase();

    {
      const dataTopAttachment = data.topAttachment;
      const dataBottomAttachment = data.bottomAttachment;

      const dataAttachment = topAttachment ? dataTopAttachment : dataBottomAttachment;
      const attachmentType = typeof (dataAttachment);

      if (attachmentType === 'number') {
        return dataAttachment === index;

      } else if (attachmentType === 'string') {
        return dataAttachment === comp.id;
      }
    }

    return true;
  }

  /**
  * @method isValidInbetweenBorderComponent
  * @description returns true if the given border component can make enough space between the top and bottom attachment components
  * @param {Object} comp - json object representing a border component (for example mattress.border.components[0])
  * @return {Boolean} - return bool
  * */
  isValidInbetweenBorderComponent(comp) {
    if (!comp) {
      return false;
    }
    let ct = comp.type;

    if (!ct) {
      return false;
    }
    ct = ct.toLowerCase();

    return ct === 'border3d' || ct === 'border' || ct === 'fabric';
  }

  _setAllowError(obj, error) {
    if (!obj) {
      return;
    }
    obj.error = error;
  }

  isAllowedOnBorder(handleData, mattressData, borderData, errorInfo = null) {
    this._setAllowError(errorInfo, HandleErrorType.VERTICAL_BORDER_ERROR);

    return false;
  }

  /**
   * @method isAllowed
   * @description This method is used to decide if a mattress is allowed to have this type of handle
   * A vertical handle is only allowed if a mattress contains 2 piping border components
   * separated by one or more components other than piping, tape or zipper
   * @param {Object} handleData - json object representing a handle
   * @param {Object} mattressData - json object representing a single mattress
   * @param {Object} errorInfo - object that contains an error object if the handle is not allowed
   * @returns {Boolean} - true if this type of handle is allowed on the mattress
   * */
  isAllowed(handleData, mattressData, errorInfo) {
    if (!handleData || !mattressData) {
      return false;
    }
    const border = mattressData.border;

    if (!border) {
      this._setAllowError(errorInfo, HandleErrorType.VERTICAL_ATTACHMENTS_ERROR);

      return false;
    }
    const components = border.components;

    if (!components) {
      this._setAllowError(errorInfo, HandleErrorType.VERTICAL_ATTACHMENTS_ERROR);

      return false;
    }
    const numComponents = components.length;

    if (numComponents < MIN_COMPONENTS) {
      this._setAllowError(errorInfo, HandleErrorType.VERTICAL_ATTACHMENTS_ERROR);

      return false;
    }

    // For now, can't have handles when using a curved border
    const graph = mattressData.border ? mattressData.border.curve : null;

    if (graph) {
      if (GraphUtils.hasGraph(graph)) {
        this._setAllowError(errorInfo, HandleErrorType.VERTICAL_CURVEDBORDER_ERROR);

        return false;
      }
    }
    // For now, can't have handles when the border part has curved geometry caused by the top or bottom border radius
    if (mattressData.box) {
      if (mattressData.box.radius && mattressData.box.border) {
        const topRadius = MattressDA.getResultTopBorderRadius(mattressData);
        const topHeight = mattressData.box.border.top;
        const bottomRadius = MattressDA.getResultBottomBorderRadius(mattressData);;
        const bottomHeight = mattressData.box.border.bottom;
        
        const diffTop = topRadius - topHeight;
        const diffBottom = bottomRadius - bottomHeight;

        if (diffTop > 2) {
          this._setAllowError(errorInfo, HandleErrorType.VERTICAL_TOPBOTTOM_RADIUS_ERROR);

          return false;
        }
        if (diffBottom > 2) {
          this._setAllowError(errorInfo, HandleErrorType.VERTICAL_TOPBOTTOM_RADIUS_ERROR);

          return false;
        }
      }
    }

    let topAttachmentIndex = -1;
    let bottomAttachmentIndex = -1;
    let comp;
    let i;

    // Find top attachment component
    let topAttachmentComp = null;

    for (i = 0; i < numComponents; ++i) {
      comp = components[i];
      if (this.isValidAttachment(comp, i, numComponents, handleData, mattressData, true)) {
        topAttachmentComp = comp;
        topAttachmentIndex = i;
        break;
      }
    }

    // Find bottom attachment component
    let bottomAttachmentComp = null;

    for (i = numComponents - 1; i >= 0; --i) {
      comp = components[i];
      if (this.isValidAttachment(comp, i, numComponents, handleData, mattressData, false)) {
        bottomAttachmentComp = comp;
        bottomAttachmentIndex = i;
        break;
      }
    }

    if (topAttachmentIndex < 0 || bottomAttachmentIndex < 0) {
      this._setAllowError(errorInfo, HandleErrorType.VERTICAL_ATTACHMENTS_ERROR);

      return false;
    }
    const topAttachmentType = topAttachmentComp && topAttachmentComp.type && topAttachmentComp.type.toLowerCase();
    const bottomAttachmentType = bottomAttachmentComp && bottomAttachmentComp.type && bottomAttachmentComp.type.toLowerCase();
    const topIsTapeOrPiping = topAttachmentType === 'piping' || topAttachmentType === 'tape';
    const bottomIsTapeOrPiping = bottomAttachmentType === 'piping' || bottomAttachmentType === 'tape';

    if (
      topAttachmentIndex === bottomAttachmentIndex &&
      (
        topIsTapeOrPiping && bottomIsTapeOrPiping
      )
    ) {
      this._setAllowError(errorInfo, HandleErrorType.VERTICAL_ATTACHMENTS_ERROR);

      return false;
    }
    let startIndex = topAttachmentIndex;
    let endIndex = bottomAttachmentIndex + 1;

    if (topIsTapeOrPiping) {
      ++startIndex;
    }
    if (bottomIsTapeOrPiping) {
      --endIndex;
    }

    // Check if there is at least one border component between the top and bottom attachment other than a piping, tape or zipper
    for (i = startIndex; i < endIndex; ++i) {
      comp = components[i];
      if (this.isValidInbetweenBorderComponent(comp)) {
        return true;
      }
    }

    this._setAllowError(errorInfo, HandleErrorType.VERTICAL_BORDER_COMPONENTS_ERROR);

    return false;
  }
}
