import BD3DMaterial from './BD3DMaterial';
import Transform3D from '../../bgr/bgr3d/transform/Transform3D';

export default class BD3DFabricMaterial extends BD3DMaterial {
  constructor() {
    super();
    this._sampleTransform = null;
    this._quiltTransform = null;
    this.quiltNormalIntensity = 2;
    this.staticNormalMapScale = 1;
    this.metaData = null;
  }

  _getClone() {
    let res = super._getClone();

    if (res instanceof BD3DFabricMaterial) {
      return res;
    }
    res = new BD3DFabricMaterial(this.getType());

    return res;
  }

  _initClone(res) {
    super._initClone(res);
    res.setFabricType(this.getFabricType());
    res.setQuiltTexture(this.getQuiltTexture());
    res.getQuiltNormalMap(this.getQuiltNormalMap());
    res.setSampleTexture(this.getSampleTexture());
    res.setSampleNormalMap(this.getSampleNormalMap());
    res.setSampleSpecularMap(this.getSampleSpecularMap());
    res.setStaticTexture(this.getStaticTexture());
    res.setStaticNormalMap(this.getStaticNormalMap());
    res.setStaticNormalMapScale(this.getStaticNormalMapScale());
    res.setColorMultiplier(this.getColorMultiplier());
    res.setColorType(this.getColorType());
  }

  clear() {
    this.setSampleTexture(null);
    this.setSampleNormalMap(null);
    this.setSampleSpecularMap(null);
    this.setQuiltTexture(null);
    this.setQuiltNormalMap(null);
    this.setColorType(null);
  }

  getColorType() {
    return this.colorType;
  }

  setColorType(ct) {
    this.colorType = ct;
  }

  getColorMultiplier() {
    return this.colorMultiplier;
  }

  setColorMultiplier(v) {
    this.colorMultiplier = v;
  }

  // Texture that doesnt change (lightmap, ambient occlusion map, ...)
  getStaticTexture() {
    return this.staticTexture;
  }

  setStaticTexture(st) {
    this.staticTexture = st;
  }

  getStaticNormalMap() {
    return this.staticNormalMap;
  }

  setStaticNormalMap(st) {
    this.staticNormalMap = st;
  }

  getStaticNormalMapScale(st) {
    return this.staticNormalMapScale;
  }

  setStaticNormalMapScale(st) {
    this.staticNormalMapScale = st;
  }

  // Quilt settings

  getQuiltWidth() {
    return this._quiltWidth;
  }

  setQuiltWidth(v) {
    this._quiltWidth = v;
  }

  getQuiltHeight() {
    return this._quiltHeight;
  }

  setQuiltHeight(v) {
    this._quiltHeight = v;
  }

  get quiltWidth() {
    return this.getQuiltWidth();
  }

  set quiltWidth(v) {
    this.setQuiltWidth(v);
  }

  get quiltHeight() {
    return this.getQuiltHeight();
  }

  set quiltHeight(v) {
    this.setQuiltHeight(v);
  }

  getQuiltRepeatX() {
    return 0.0;
  }

  getQuiltRepeatY() {
    return 0.0;
  }

  getQuiltTexture() {
    return this.quiltTexture;
  }

  setQuiltTexture(v) {
    this.quiltTexture = v;
  }

  setQuiltTextureValue(v) {
    this.quiltTextureValue = v;
  }

  getQuiltTextureValue(v) {
    return this.quiltTextureValue;
  }

  getQuiltNormalMap() {
    return this.quiltNormalMap;
  }

  setQuiltNormalMap(v) {
    this.quiltNormalMap = v;
  }

  getQuiltNormalIntensity() {
    return this.quiltNormalIntensity;
  }
  setQuiltNormalIntensity(v) {
    this.quiltNormalIntensity = v;
  }

  getQuiltBumpMap() {
    return this.quiltBumpMap;
  }

  setQuiltBumpMap(b) {
    this.quiltBumpMap = b;
  }

  getQuiltTransform() {
    return this._quiltTransform;
  }

  setQuiltTransform(v) {
    this._quiltTransform = v;
  }

  get quiltTransform() {
    return this.getQuiltTransform();
  }

  set quiltTransform(v) {
    this.setQuiltTransform(v);
  }

  // Sample / Fabric settings
  getSampleWidth() {
    return this._sampleWidth;
  }

  setSampleWidth(v) {
    this._sampleWidth = v;
  }

  getSampleHeight() {
    return this._sampleHeight;
  }

  setSampleHeight(v) {
    this._sampleHeight = v;
  }

  get sampleWidth() {
    return this.getSampleWidth();
  }

  set sampleWidth(v) {
    this.setSampleWidth(v);
  }

  get sampleHeight() {
    return this.getSampleHeight();
  }

  set sampleHeight(v) {
    this.setSampleHeight(v);
  }

  getFabricType() {
    return this.fabricType;
  }

  setFabricType(type) {
    this.fabricType = type;
  }


  getSampleTexture() {
    return this.sampleTexture;
  }

  setSampleTexture(v) {
    this.sampleTexture = v;
  }

  getSampleNormalMap() {
    return this.sampleNormalMap;
  }

  setSampleNormalMap(v) {
    this.sampleNormalMap = v;
  }

  getSampleSpecularMap() {
    return this.sampleSpecularMap;
  }

  setSampleSpecularMap(v) {
    this.sampleSpecularMap = v;
  }

  getSampleTransform() {
    return this._sampleTransform;
  }

  setSampleTransform(v) {
    this._sampleTransform = v;
  }

  get sampleTransform() {
    return this.getSampleTransform();
  }

  set sampleTransform(v) {
    this.setSampleTransform(v);
  }

  // Applies the current sample transform settings to the matrix parameter
  applySampleTransformToMatrix(matrix) {
    if (!matrix) {
      return matrix;
    }

    return this._applyUVTransformMatrix(matrix, this.getSampleTransform());
  }

  applyQuiltTransformToMatrix(matrix) {
    if (!matrix) {
      return matrix;
    }

    return this._applyUVTransformMatrix(matrix, this.getQuiltTransform());
  }

  _applyUVTransformMatrix(matrix, transform) {
    if (!matrix || !transform) {
      return matrix;
    }
    if (!(transform instanceof Transform3D)) {
      return matrix;
    }
    const res = transform.applyMatrix4(matrix);

    if (!res) {
      return matrix;
    }

    return res;
  }
}
