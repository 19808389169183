export default class QuiltCollector {
  static _addQuilt(quilt, address, params, array = null) {
    if (!quilt) {
      return array;
    }
    let arr = array;

    if (!arr) {
      arr = [];
    }
    let texID = null;

    if (typeof (quilt) === 'string') {
      texID = quilt;
    } else if (quilt) {
      texID = quilt.id;
    }

    if (typeof (texID) !== 'undefined' && texID !== null) {
      arr.push({quiltId: texID, quilt: quilt, address: address});
    }

    return arr;
  }

  static getQuiltsByConfig(config, params = null, array = null) {
    if (!config) {
      return array;
    }
    let arr = array;

    const singles = config.singles;

    if (singles) {
      const numSingles = singles.length;

      for (let i = 0; i < numSingles; ++i) {
        arr = this._getQuiltsBySingle(singles[i], {part: 'single', config: config, singleIndex: i}, params, arr);
      }
    }

    return arr;
  }

  static getQuiltsBySingle(single, params = null, array = null) {
    return this._getQuiltsBySingle(single, {part: 'single'}, params, array);
  }

  static _hasQuilt(obj) {
    if (!obj) {
      return false;
    }
    if (!obj.quilt) {
      return false;
    }
    const id = obj.quilt.id;

    if (id === null) {
      return false;
    }
    const t = typeof (id);

    if (t === 'string' && id.length > 0) {
      return true;
    }
    if (t === 'number') {
      return true;
    }

    return false;
  }

  static _findQuiltId(object) {
    if (!object) {
      return null;
    }
    if (!object.quilt) {
      return null;
    }
    const id = object.quilt.id;
    const t = typeof (id);

    if (t === 'string' || t === 'number') {
      return id;
    }

    return null;
  }

  static _getQuiltsBySingle(single, address, params = null, array = null) {
    if (!single) {
      return array;
    }
    let arr = array;

    arr = this._getQuiltsByTopOrBottom('top', single.top, single, {single: single, part: 'top', parent: address}, params, arr);
    arr = this._getQuiltsByTopOrBottom('bottom', single.bottom, single, {single: single, part: 'bottom', parent: address}, params, arr);

    if (single.border) {
      arr = this._getQuiltsByBorder(single.border, {single: single, part: 'border'}, params, arr);
    }

    return arr;
  }

  static _getQuiltsByTopOrBottom(name, topOrBottom, single, address, params = null, array = null) {
    let arr = array;

    if (this._hasQuilt(topOrBottom)) {
      arr = this._addQuilt(topOrBottom.quilt, address, params, arr);
    } else if (this._hasQuilt(single)) {
      arr = this._addQuilt(single.quilt, address, params, arr);
    }

    if (!topOrBottom) {
      return arr;
    }

    const mirrorPanel = topOrBottom.mirrorPanel;

    if (mirrorPanel) {
      const mpPartName = `${name}MirrorPanel`;

      arr = this._addQuilt(mirrorPanel.quilt, {part: mpPartName, parent: address, mirrorPanel: mirrorPanel}, params, arr);
    }

    return arr;
  }

  static _getQuiltsByBorder(border, address, params, array) {
    if (!border) {
      return array;
    }

    return this._getQuiltsByBorderComponents(border.components, address, params, array);
  }

  static _getQuiltsByBorderComponents(borderComponents, address, params, array) {
    if (!borderComponents) {
      return array;
    }
    const num = borderComponents.length;

    if (!num) {
      return array;
    }
    let arr = array;

    for (let i = 0; i < num; ++i) {
      arr = this._getQuiltsByBorderComponent(borderComponents[i], i, num, address, params, arr);
    }

    return arr;
  }

  static _getQuiltsByBorderComponent(borderComponent, index, total, address, params, array) {
    if (!borderComponent) {
      return array;
    }
    let arr = array;

    let type = borderComponent.type;

    type = type ? type.toLowerCase() : type;

    if (type === 'fabric' || type === 'tape' || type === 'gusset' || type === 'border3d' || type === 'border') {
      arr = this._addQuilt(borderComponent.quilt, {parent: address, part: 'border', borderIndex: index, borderComponent: borderComponent}, params, arr);
    }

    return arr;
  }
}
