import GraphicsApp from '../../bgr/common/app/GraphicsApp';

import MattressThreeComponentController from '../controller/MattressThreeComponentController';
import BD3DThreeComponent from '../component/BD3DThreeComponent';
// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
// #endif

let instance = null;

export default class BD3DApp extends GraphicsApp {
  static create(params = null) {
    const res = new BD3DApp(params);

    return res;
  }

  static getInstance(params = null) {
    // #if DEBUG
    BD3DLogger.logger = params ? params.logger : null;
    // #endif

    if (instance) {
      const container = params ? params.container : null;

      instance.embed(container);
      if (instance.reset) {
        instance.reset();
      }
    } else {
      instance = this.create(params);
    }
    window.bd3dapp = instance;

    return instance;
  }

  _init(params = null) {
    if (this.isWebGLSupported()) {

      // Create component
      this._component = new BD3DThreeComponent();

      const DEFAULT_TAB_INDEX = 1000;
      let tabIndex = DEFAULT_TAB_INDEX;

      if (params && typeof (params.tabIndex) !== 'undefined' && params.tabIndex !== null) {
        tabIndex = parseInt(params.tabIndex, 10);
        if (isNaN(tabIndex)) {
          tabIndex = DEFAULT_TAB_INDEX;
        }
      }

      this.setTabIndex(tabIndex);

      // Initialize built-in controllers
      this._controllers = {
        mattress: new MattressThreeComponentController(params)
      };

      // Register optional controllers
      if (params) {
        this.registerControllers(params.controllers);
      }

      // Set initial controller
      let initialController;

      if (params) {
        initialController = params.initialController || params.initialContent;
      }

      if (typeof (initialController) === 'string') {
        initialController = this._controllers[initialController];
      }
      if (!initialController) {
        initialController = this._controllers.mattress;
      }
      this.setController(initialController);

      // Other params
      if (params) {
        const autoSizeEnabled = params.autoSizeEnabled !== false;
        const parent = params.container || params.parent;

        this._autoSizeEnabled = autoSizeEnabled;
        this._updateAutoSizeEnabled();
        this.embed(parent);
      }
    }
  }

  setTabIndex(index = -1) {
    const component = this._component;

    if (component && component.setTabIndex) {
      component.setTabIndex(index);
    }
  }

  registerControllers(controllers) {
    if (!controllers) {
      return;
    }
    for (const v in controllers) {
      if (controllers.hasOwnProperty(v)) {
        this._controllers[v] = controllers[v];
      }
    }
  }

  registerController(name, controller) {
    this._controllers[name] = controller;
  }

  unregisterController(name) {
    const contr = this._controllers[name];

    if (contr === this._controller) {
      this.setController(null);
    }

    Reflect.deleteProperty(this._controllers, name);
  }

  embed(parent) {
    let p = parent;

    if (!p) {
      p = this._parent;
    }
    if (!p) {
      return;
    }

    if (typeof (p) === 'string') {
      const id = p;

      p = document.getElementById(id);
    }

    if (!p) {
      return;
    }

    this._parent = p;

    const elem = this.getDOMElement(true);

    if (!elem) {
      return;
    }
    if (this.isAutoSizeEnabled()) {
      this._autoSize();
    }

    p.appendChild(elem);
  }

  _getParent() {
    return this._parent;
  }

  isAutoSizeEnabled() {
    return this._autoSizeEnabled;
  }

  setAutoSizeEnabled(e) {
    if (this._autoSizeEnabled === e) {
      return;
    }
    this._autoSizeEnabled = e;

  }

  get autoSizeEnabled() {
    return this.isAutoSizeEnabled();
  }

  set autoSizeEnabled(e) {
    this.setAutoSizeEnabled(e);
  }

  _updateAutoSizeEnabled() {
    const e = this.isAutoSizeEnabled();

    let windowResizeHandler = this._windowResizeHandler;

    if (e) {
      if (!windowResizeHandler) {
        const that = this;

        windowResizeHandler = this._windowResizeHandler = evt => {
          return that._handleWindowResize(evt);
        };
      }
      window.addEventListener('resize', windowResizeHandler);
      this.autoSize();
    } else if (windowResizeHandler) {
      window.removeEventListener('resize', windowResizeHandler);
    }
  }

  _handleWindowResize() {
    this.autoSize();
  }

  autoSize() {
    this._autoSize();
  }

  _autoSize() {
    const p = this._getParent();

    if (!p) {
      return;
    }
    this._beforeAutoSize();

    const w = p.offsetWidth;
    const h = p.offsetHeight;

    this.setSize(w, h);
  }

  _beforeAutoSize() {
    const comp = this._component;

    if (!comp || !comp._beforeAutoSize) {
      return;
    }

    comp._beforeAutoSize();
  }

  setSize(w, h) {
    const comp = this._component;

    if (!comp) {
      return;
    }
    comp.setSize(w, h);
  }

  reset() {
    const contr = this._controller;

    if (contr && contr.reset) {
      contr.reset();
    }
  }

  dispose() {
    return;
  }

  _disposeComponent(comp) {
    if (!comp) {
      return;
    }
    comp.dispose();
  }

  _getComponent() {
    return this._component;
  }

  getMattressConfigurator() {
    if (!this._controllers) {
      return null;
    }

    return this._controllers.mattress;
  }

  get mattressConfigurator() {
    return this.getMattressConfigurator();
  }

  getController() {
    return this._controller;
  }

  setController(contr) {
    let c = contr;

    if (typeof (c) === 'string') {
      if (!this._controllers) {
        return;
      }
      c = this._controllers[c];
    }
    if (this._controller === c) {
      return;
    }
    this._setControllerEnabled(this._controller, false);
    this._controller = c;
    this._setControllerEnabled(this._controller, true);
  }

  get controller() {
    return this.getController();
  }

  set controller(c) {
    this.setController(c);
  }

  _setControllerEnabled(contr, e = true) {
    if (!contr) {
      return;
    }

    let appMessageHandler = this._appMessageHandler;

    if (e) {
      const comp = this._getComponent();
      const that = this;

      contr.setComponent(comp);

      if (!appMessageHandler) {
        appMessageHandler = event => {
          that._handleAppMessage(event);
        };
      }
      contr.addEventListener('app_message', appMessageHandler);
    } else {
      contr.setComponent(null);
      if (appMessageHandler) {
        contr.removeEventListener('app_message', appMessageHandler);
      }
    }
  }

  _handleAppMessage(event) {
    if (!event) {
      return;
    }
    const message = event.data;

    this._onMessage(message);
  }

  _onMessage(message) {
    // #if DEBUG
    BD3DLogger.log('message', message);
    // #endif
    if (!message) {
      return;
    }
    const name = message.name;
    const args = message.args;

    if (!name) {
      return;
    }

    if (name === 'navigate') {

      if (typeof (args) === 'string' && this._controllers) {
        const contr = this._controllers[args];

        this.setController(contr);
      }
    }
  }

  isWebGLSupported() {
    // TODO: Check for webGL support
    return true;
  }

  get webGLSupported() {
    return this.isWebGLSupported();
  }
}
