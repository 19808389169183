import TweenAnimation from '../../bgr/common/anim/TweenAnimation';

const PI2 = Math.PI * 2;

export default class CameraViewTween extends TweenAnimation {
  constructor(orbitController, duration = 0) {
    super(duration);
    this._orbitController = orbitController;
  }

  getOrbitController() {
    return this._orbitController;
  }

  setOrbitController(v) {
    this._orbitController = v;
  }

  get orbitController() {
    return this.getOrbitController();
  }

  set orbitController(v) {
    this.setOrbitController(v);
  }

  getCameraViewSettings() {
    return this._cameraViewSettings;
  }

  setCameraViewSettings(v) {
    this._cameraViewSettings = v;
  }

  get cameraViewSettings() {
    return this.getCameraViewSettings();
  }

  set cameraViewSettings(v) {
    this.setCameraViewSettings(v);
  }

  tweenCameraViewSettings(settings, dur = -1) {
    this.setCameraViewSettings(settings);
    if (dur >= 0) {
      this.duration = dur;
    }
    this.start();
  }

  _fixAngle(angle) {
    let a = angle;

    a %= PI2;

    if (a < 0) {
      a += PI2;
    }

    return a;
  }
  _fixAngleX(angle) {
    return this._fixAngle(angle + Math.PI) - Math.PI;
  }

  _getShortestStartAngle(startAngle, destAngle) {
    const sa = this._fixAngle(startAngle);
    const da = this._fixAngle(destAngle);
    const deltaAngle = da - sa;

    if (deltaAngle > Math.PI) {
      return destAngle + PI2 - deltaAngle;
    } else if (deltaAngle < -Math.PI) {
      return destAngle - PI2 - deltaAngle;
    }
    if (da === destAngle) {
      return sa;
    }

    return destAngle - deltaAngle;
  }

  _tweenStart() {
    const orbit = this.getOrbitController();
    const cvs = this.getCameraViewSettings();

    let tweenData = this._tweenData;

    if (!tweenData) {
      tweenData = this._tweenData = {};
    }
    tweenData.startRotationX = this._fixAngleX(orbit.getRotationX());

    tweenData.startRotationY = this._fixAngle(orbit.getRotationY());
    tweenData.startDistance = orbit.getDistance();
    tweenData.startPositionX = orbit.getPositionX();
    tweenData.startPositionY = orbit.getPositionY();
    tweenData.startPositionZ = orbit.getPositionZ();
    tweenData.startViewOffsetX = orbit.getTargetOffsetX();
    tweenData.startViewOffsetY = orbit.getTargetOffsetY();

    tweenData.endRotationX = this._fixAngleX(cvs.getRotationX());
    tweenData.endRotationY = this._fixAngle(cvs.getRotationY());
    tweenData.endDistance = cvs.getDistance();
    tweenData.endPositionX = cvs.getPositionX();
    tweenData.endPositionY = cvs.getPositionY();
    tweenData.endPositionZ = cvs.getPositionZ();
    tweenData.endViewOffsetX = cvs.getViewOffsetX();
    tweenData.endViewOffsetY = cvs.getViewOffsetY();

    // tweenData.startRotationX = this._getShortestStartAngle(tweenData.startRotationX, tweenData.endRotationX);
    tweenData.startRotationY = this._getShortestStartAngle(tweenData.startRotationY, tweenData.endRotationY);

    tweenData.deltaRotationX = tweenData.endRotationX - tweenData.startRotationX;
    tweenData.deltaRotationY = tweenData.endRotationY - tweenData.startRotationY;

    tweenData.deltaPositionX = tweenData.endPositionX - tweenData.startPositionX;
    tweenData.deltaPositionY = tweenData.endPositionY - tweenData.startPositionY;
    tweenData.deltaPositionZ = tweenData.endPositionZ - tweenData.startPositionZ;

    tweenData.deltaViewOffsetX = tweenData.endViewOffsetX - tweenData.startViewOffsetX;
    tweenData.deltaViewOffsetY = tweenData.endViewOffsetY - tweenData.startViewOffsetY;

    tweenData.deltaDistance = tweenData.endDistance - tweenData.startDistance;
  }

  _tweenComplete() {
    const td = this._tweenData;
    const orbit = this._orbitController;

    orbit.setRotation(td.endRotationX, td.endRotationY);
    orbit.setPosition(td.endPositionX, td.endPositionY, td.endPositionZ);
    orbit.setTargetOffset(td.endViewOffsetX, td.endViewOffsetY);
    orbit.setDistance(td.endDistance);
  }

  _tweenAnimate(event) {
    const _3 = 3, _2 = 2;
    let t = this.time / this.duration;

    // smooth step
    t = t * t * (_3 - _2 * t);

    const td = this._tweenData;
    const orbit = this._orbitController;

    const rotX = td.startRotationX + td.deltaRotationX * t;
    const rotY = td.startRotationY + td.deltaRotationY * t;

    const posX = td.startPositionX + td.deltaPositionX * t;
    const posY = td.startPositionY + td.deltaPositionY * t;
    const posZ = td.startPositionZ + td.deltaPositionZ * t;

    const dist = td.startDistance + td.deltaDistance * t;

    const vOffX = td.startViewOffsetX + td.deltaViewOffsetX * t;
    const vOffY = td.startViewOffsetY + td.deltaViewOffsetY * t;

    orbit.setRotation(rotX, rotY);
    orbit.setPosition(posX, posY, posZ);
    orbit.setDistance(dist);
    orbit.setTargetOffset(vOffX, vOffY);

    const animator = event.animator;

    if (!animator) {
      return;
    }

    if (!animator.metaData) {
      animator.metaData = {};
    }
    animator.metaData.needsRender = true;
  }
}
