import Loader from './Loader';
import LoaderState from './LoaderState';
import LoaderEvent from './LoaderEvent';

const UNDEFINED = 'undefined';
const ZERO = 0;
const INVALID_CALL = 'Invalid call';
const DEFAULT_NUM_SIMULTANEOUS = 3;

export default class LoaderList extends Loader {
  /**
   * Constructor
   * @param  {Object} args - Optional object containing arguments
   *  loaders {Array} - Array of loaders
   *  numSimultaneous {int} - Number of loaders to load simultaneously
   */
  constructor(args = null) {
    super();

    let numSimultaneous = DEFAULT_NUM_SIMULTANEOUS;
    let loaders = null;

    if (args !== null && typeof (args) !== UNDEFINED) {
      if (args instanceof Array) {
        loaders = args;
        numSimultaneous = DEFAULT_NUM_SIMULTANEOUS;
      } else {
        if (args.numSimultaneous !== null && typeof (args.numSimultaneous) !== UNDEFINED) {
          numSimultaneous = args.numSimultaneous;
        }
        loaders = args.loaders;
      }
    }

    if (numSimultaneous === null || typeof (numSimultaneous) === UNDEFINED) {
      numSimultaneous = DEFAULT_NUM_SIMULTANEOUS;
    }

    this.numSimultaneous = numSimultaneous;
    this.loaders = loaders;
  }

  /**
   * Starts loading all loaders in the loaders array
   */
  start() {
    let ls = this._loaderState;

    if (ls === null || typeof (ls) === UNDEFINED) {
      ls = LoaderState.IDLE;
    }

    if (ls !== LoaderState.IDLE) {
      return;
    }

    const loaders = this.loaders;

    if (loaders === null || typeof (loaders) === UNDEFINED) {
      this._changeState(LoaderState.COMPLETED);

      return;
    }

    const numLoaders = loaders.length;

    if (numLoaders === ZERO) {
      this._changeState(LoaderState.COMPLETED);

      return;
    }
    const session = {
      index: 0,
      numLoaders: numLoaders,
      activeLoaders: null
    };

    this._changeState(LoaderState.PROGRESS);
    this._loadNext(session);
  }

  /**
   * Cancels the current loading process
   * @return {void}
   */
  cancel() {
    const session = this._currentSession;

    if (session === null || typeof (session) === UNDEFINED) {
      return;
    }
    const activeLoaders = session.activeLoaders;

    if (activeLoaders === null || typeof (activeLoaders) === UNDEFINED) {
      return;
    }

    const num = activeLoaders.length;

    if (num === 0) {
      return;
    }

    const subLoaderFinishedHandler = this._subLoaderFinishedHandler;

    for (let i = 0; i < num; ++i) {
      const loader = activeLoaders[i];

      if (subLoaderFinishedHandler !== null && typeof (subLoaderFinishedHandler) !== UNDEFINED) {
        loader.removeEventListener(LoaderEvent.FINISHED, subLoaderFinishedHandler);
      }
      loader.cancel();
    }
    activeLoaders.length = 0;
    this._changeState(LoaderState.CANCELED);
  }

  /**
   * Resets the loader
   * @param {Boolean} params = null - Params object
   * - resetLoaders {Boolean} - resets this loader's loaders.
   * @return {void}
   */
  reset(params = null) {
    super.reset(params);

    let subloaders = true;

    if (params !== null && typeof (params) !== UNDEFINED) {
      subloaders = params.resetLoaders !== false;
    }

    if (subloaders) {
      const loaders = this.loaders;

      if (loaders === null || typeof (loaders) === UNDEFINED) {
        return;
      }
      const num = loaders.length;

      if (num === 0) {
        return;
      }

      for (let i = 0; i < num; ++i) {
        const ldr = loaders[i];

        if (ldr !== null && typeof (ldr) !== UNDEFINED) {
          ldr.reset(params);
        }
      }
    }
  }

  /**
   * Returns the array instance used as internal active loaders array
   * @param  {Object} session - Loader session
   * @param  {[type]} clear = false - Clears the array if true
   * @return {Array} - Active loaders array
   */
  _getActiveLoadersArray(session, clear = false) {
    let activeLoaders = session.activeLoaders;

    if (activeLoaders === null || typeof (activeLoaders) === UNDEFINED) {
      activeLoaders = session.activeLoaders = [];
    } else if (clear) {
      activeLoaders.length = 0;
    }

    return activeLoaders;
  }

  /**
   * Returns the number of loaders that should be loaded simultaneously
   * @return {int} number of simultaneous loaders
   */
  getNumSimultaneous() {
    const ONE = 1;
    let numSimul = this.numSimultaneous;

    if (numSimul === null || typeof (numSimul) === UNDEFINED || numSimul < ONE) {
      numSimul = 1;
    }

    return numSimul;
  }

  /**
   * Loads the next amount of loaders
   * @param  {Object} session - loader sessiono object
   * @return {void}
   */
  _loadNext(session) {
    const loaders = this.loaders;

    if (loaders === null || typeof (loaders) === UNDEFINED) {
      throw new Error(INVALID_CALL);
    }

    const numLoaders = session.numLoaders;
    const fromIndex = session.index === null || typeof (session.index) === UNDEFINED || session.index < 0 ? 0 : session.index;
    const numSimul = this.getNumSimultaneous();

    let endIndex = fromIndex + numSimul;

    endIndex = endIndex < numLoaders ? endIndex : numLoaders;

    let activeLoaderCount = endIndex - fromIndex;
    const activeLoaders = this._getActiveLoadersArray(session, true);

    if (activeLoaderCount > 0) {

      let c = 0;
      let subLoaderFinishedHandler = this._subLoaderFinishedHandler;

      this._currentSession = session;

      if (subLoaderFinishedHandler === null || typeof (subLoaderFinishedHandler) === UNDEFINED) {
        const that = this;

        subLoaderFinishedHandler = evt => {
          that._handleSubloaderFinished(evt, that._currentSession);
        };
        this._subLoaderFinishedHandler = subLoaderFinishedHandler;
      }

      for (let i = fromIndex; i < endIndex; ++i) {
        const loader = loaders[i];

        if (loader !== null && typeof (loader) !== UNDEFINED) {
          loader.once(LoaderEvent.FINISHED, subLoaderFinishedHandler);
          activeLoaders[c++] = loader;
        }
      }
      activeLoaderCount = c;
    }
    if (activeLoaderCount > 0) {
      session.activeLoaders = activeLoaders;
      session.activeLoadersComplete = 0;
      session.activeLoaderCount = activeLoaderCount;
      this._startLoadActiveLoaders(session);
    } else {
      // All loaders complete
      activeLoaders.length = 0;
      this._currentSession = null;
      this._changeState(LoaderState.COMPLETED);
    }
  }

  /**
   * Starts all loaders in the active loaders array
   * @param  {Object} session Loader session object
   * @return {void}
   */
  _startLoadActiveLoaders(session) {
    const activeLoaders = session.activeLoaders;
    let num = session.activeLoaderCount;

    if (num === null || typeof (num) === UNDEFINED) {
      num = activeLoaders.length;
    }

    for (let i = 0; i < num; ++i) {
      const loader = activeLoaders[i];

      loader.start();
    }
  }

  /**
   * Internal method to change the loading progress
   * @param  {Number} numLoaded - Amount loaded
   * @param  {Number} numTotal - Total amount to load
   * @return {void}
   */
  _changeProgress(numLoaded, numTotal) {
    let changed = false;

    if (this._loaderState !== LoaderState.PROGRESS) {
      this._loaderState = LoaderState.PROGRESS;
      changed = true;
    }
    let progressData = this._progressData;

    if (progressData === null || typeof (progressData) === UNDEFINED) {
      progressData = this._progressData = {};
    }
    if (progressData.loaded !== numLoaded || progressData.total !== numTotal) {
      progressData.loaded = numLoaded;
      progressData.total = numTotal;
      changed = true;
    }
    if (changed) {
      if (this.hasEventListener(LoaderEvent.PROGRESS)) {
        const evt = this._getEventObject(LoaderEvent.PROGRESS, progressData);

        this._dispatchProgress(evt);
      }
    }
  }

  _handleSubloaderFinished(evt, session) {
    ++session.activeLoadersComplete;
    ++session.index;
    this._changeProgress(session.index, session.numLoaders);
    if (session.activeLoadersComplete >= session.activeLoaderCount) {

      this._loadNext(session);
    }
  }
}
