import SRTTransform3D from '../../../bgr/bgr3d/transform/SRTTransform3D';
import Vector3 from '../../../bgr/bgr3d/geom/Vector3';
import {castValue} from '../utils';

export default {
  create(data, id, session) {
    const position = castValue(data.position, Vector3, id, session);
    const rotation = castValue(data.rotation, Vector3, id, session);
    const scale = castValue(data.scale, Vector3, id, session);
    const rotationOrder = data.rotationOrder;

    const res = new SRTTransform3D(position, rotation, scale, rotationOrder);

    return res;
  },
  update(object, data, id, session) {
    const position = castValue(data.position, Vector3, id, session);
    const rotation = castValue(data.rotation, Vector3, id, session);
    const scale = castValue(data.scale, Vector3, id, session);
    const rotationOrder = data.rotationOrder;

    object.setPosition(position);
    object.setRotation(rotation);
    object.setScale(scale);
    object.setRotationOrder(rotationOrder);
  }
};
