import Vector3 from '../geom/Vector3';

const NEG_ONE = -1;

export default class VectorMath {
  static dot(v1, v2, dimension = NEG_ONE) {
    const l1 = v1.getDimension();
    const l2 = v2.getDimension();
    let dim = dimension;

    if (dim < 0) {
      dim = l1 < l2 ? l1 : l2;
    }
    let res = 0;

    for (let i = 0; i < dim; ++i) {
      res += v1.getCoord(i) * v2.getCoord(i);
    }

    return res;
  }

  static cross(v1, v2, result = null) {
    if (!v1 || !v2) {
      return result;
    }
    const v1x = v1.getCoord(0);
    const v1y = v1.getCoord(1);
    const v1z = v1.getDimension() > 2 ? v1.getCoord(2) : 0;

    const v2x = v2.getCoord(0);
    const v2y = v2.getCoord(1);
    const v2z = v2.getDimension() > 2 ? v2.getCoord(2) : 0;

    return this.crossCoords(v1x, v1y, v1z, v2x, v2y, v2z, result);
  }

  static crossCoords(x1, y1, z1, x2, y2, z2, result = null) {
    const cx = y1 * z2 - y2 * z1;
    const cy = z1 * x2 - z2 * x1;
    const cz = x1 * y2 - x2 * y1;

    let res = result;

    if (!res) {
      res = new Vector3();
    }

    res.setCoord(0, cx);
    res.setCoord(1, cy);
    res.setCoord(2, cz);

    return res;
  }

  static lengthSquared(v) {
    return VectorMath.dot(v, v);
  }

  static vectorLength(v) {
    const l = VectorMath.lengthSquared(v);

    if (l === 0 || l === 1) {
      return l;
    }

    return Math.sqrt(l);
  }

  static _vectorRotate(vector, angle, xCoord = 0, yCoord = 1, result = null) {
    if (!vector) {
      return result;
    }
    let res = result;

    if (!res) {
      res = vector;
    }

    if (angle === 0) {
      return res;
    }

    const cosine = Math.cos(angle);
    const sine = Math.sin(angle);

    const x = vector.getCoord(xCoord);
    const y = vector.getCoord(yCoord);

    const tx = x * cosine - y * sine;
    const ty = y * cosine + x * sine;

    res.setCoord(xCoord, tx);
    res.setCoord(yCoord, ty);

    return res;
  }

  static xRotate(vector, angle, result = null) {
    return this._vectorRotate(vector, angle, 2, 1, result);
  }

  static yRotate(vector, angle, result = null) {
    return this._vectorRotate(vector, angle, 0, 2, result);
  }

  static zRotate(vector, angle, result = null) {
    return this._vectorRotate(vector, angle, 0, 1, result);
  }

  static scale(vector, scalar, result = null) {
    let res = result;

    if (!res) {
      res = vector;
    }

    if (scalar === 1) {
      return res;
    }
    const dim = vector.getDimension();

    for (let i = 0; i < dim; ++i) {
      res.setCoord(i, vector.getCoord(i) * scalar);
    }

    return res;
  }

  static normalize(vector, result = null) {
    let res = result;

    if (!res) {
      res = vector;
    }

    const l = VectorMath.vectorLength(vector);

    if (l === 1) {
      return res;
    }

    let scalar = l;

    if (l === 0) {
      scalar = 0;
    } else {
      scalar = 1.0 / l;
    }

    return VectorMath.scale(vector, scalar, result);
  }
}
