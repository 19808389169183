import Vertex from '../../bgr/bgr3d/geom/Vertex';
import Vector3 from '../../bgr/bgr3d/geom/Vector3';
import BD3DMaterial from '../material/BD3DMaterial';
import MaterialType from '../material/MaterialType';
import BorderComponentType from './BorderComponentType';
import ImageAsset from '../asset/ImageAsset';
import QuiltDA from '../quilt/QuiltDA';
import QuiltConfig from '../quilt/QuiltConfig';
import QuiltTransform from '../material/QuiltTransform';
import BD3DNodeTypes from '../scenegraph/BD3DNodeTypes';
import SampleTransform from '../material/SampleTransform';

const DEFAULT_THICKNESS = 0.5;
const border3DMaterialType = new MaterialType('border3d');

const Assets = {
  TEX_BORDER3D_COLOR: new ImageAsset('border3d.tex_color'),
  TEX_BORDER3D_NORMAL: new ImageAsset('border3d.tex_normal'),
  TEX_BORDER3D_SPECULAR: new ImageAsset('border3d.tex_specular')
};

export default class Border3DComponentType extends BorderComponentType {
  static _getBorder3DMaterialType() {
    return border3DMaterialType;
  }

  _getBorder3DMaterialType() {
    return border3DMaterialType;
  }

  hasTextureUVCorrection() {
    return true;
  }

  getNodeType() {
    return BD3DNodeTypes.border3d;
  }

  addAssets(data, params, array = null, assetManager = null) {
    if (!data) {
      return array;
    }
    let arr = array;

    arr = Border3DComponentType.getBorderMaterialAssets(params, arr, assetManager);

    return arr;
  }

  static getBorderMaterialAssets(params, array = null, assetManager = null) {
    let arr = array;

    arr = assetManager.addAssetToArray(Assets.TEX_BORDER3D_COLOR, arr, params);
    arr = assetManager.addAssetToArray(Assets.TEX_BORDER3D_NORMAL, arr, params);
    arr = assetManager.addAssetToArray(Assets.TEX_BORDER3D_SPECULAR, arr, params);

    return arr;
  }

  static createMaterial(data, mattressData, buildParams = null, result = null) {
    let res = result;

    if (!res) {
      res = new BD3DMaterial();
    }
    res.setType(this._getBorder3DMaterialType());

    const assetCollections = buildParams && buildParams.assetCollections;
    /*
    const KEY_TEX = 'border3d_texture';
    const KEY_NORMALMAP = 'border3d_normalmap';
    const KEY_SPECULARMASK = 'border3d_specularmask';

    const assets = buildParams.assets;
    const texture = assets.getAsset(KEY_TEX);
    const normalMap = assets.getAsset(KEY_NORMALMAP);
    const specularMask = assets.getAsset(KEY_SPECULARMASK);
    */
    const texture = Assets.TEX_BORDER3D_COLOR;
    const normalMap = Assets.TEX_BORDER3D_NORMAL;
    const specularMask = Assets.TEX_BORDER3D_SPECULAR;

    res.set('diffuse', texture);
    res.set('normal', normalMap);
    res.set('specularMask', specularMask);
    res.set('colorMultiplier', data.color);

    let sampleTransform = res.get('sampleTransform');

    if (!sampleTransform) {
      sampleTransform = new SampleTransform();

      res.set('sampleTransform', sampleTransform);
    }
    sampleTransform.setRealWidth(0.5);
    sampleTransform.setRealHeight(0.5);

    let quiltConfig = res.get('quiltConfig');

    if (!quiltConfig) {
      quiltConfig = new QuiltConfig();

      res.set('quiltConfig', quiltConfig);
    }

    const quiltConfigData = data.quilt;
    let quiltAsset = null;
    let quiltBumpTransform = null;

    if (quiltConfigData && assetCollections) {
      const quiltID = QuiltDA.getID(quiltConfigData);

      if (quiltID) {
        quiltAsset = assetCollections.quilts.getAssetByName(quiltID);
      }
      if (!quiltAsset && quiltConfigData.img && quiltConfigData.img.src) {
        quiltAsset = assetCollections.quilts.getAssetByName(quiltConfigData.img.src);
      }
      const quiltData = quiltAsset ? quiltAsset.quiltData : null;
      const realWidth = QuiltDA.getRealWidth(quiltConfigData, quiltData);
      const realHeight = QuiltDA.getRealHeight(quiltConfigData, quiltData);

      quiltBumpTransform = new QuiltTransform();
      quiltBumpTransform.setRealWidth(realWidth);
      quiltBumpTransform.setRealHeight(realHeight);
      quiltBumpTransform.setConfigData(quiltConfigData);
      quiltBumpTransform.setData(quiltData);

      quiltConfig.setData(quiltData);
    }
    const quiltBumpMap = quiltAsset ? quiltAsset.getBumpMap() : null;

    quiltConfig.setQuiltAsset(quiltAsset);
    quiltConfig.setConfigData(quiltConfigData);

    res.set('quiltAsset', quiltAsset);
    res.set('quiltBump', quiltBumpMap);
    res.set('quiltTransform', quiltBumpTransform);
    res.set('quiltConfig', quiltConfig);

    return res;
  }

  getMaterial(data, mattressData, buildParams = null, result = null) {
    return Border3DComponentType.createMaterial(data, mattressData, buildParams, result);
  }

  _addArcVerts(startAngle, endAngle, radiusX, radiusY, X, Y, yOffset, startX, endX, borderShape, numSubdivs, array, info) {
    let ind = info ? info.index : 0;
    let arr = array;

    if (radiusX <= 0 || radiusY <= 0) {
      if (!arr) {
        arr = [];
      }
      let vert = arr[ind];


      if (!vert) {
        vert = arr[ind] = new Vertex();
      }
      let atts = vert.attributes;

      if (!atts) {
        atts = {};
      }

      let vertPos = vert.position;

      if (!vertPos) {
        vertPos = vert.position = new Vertex(new Vector3());
      }
      let xpos = X;

      if (startX !== null && startX !== undefined && !isNaN(startX)) {
        if (info && info.count === 0) {
          xpos = startX;
        }
      }
      if (endX !== null && endX !== undefined && !isNaN(endX)) {
        if (info && info.count === info.total) {
          xpos = endX;
        }
      }

      vertPos.setCoord(0, xpos);
      vertPos.setCoord(1, Y);

      vertPos = this.borderTransformVector3(vertPos, yOffset, borderShape, false, true, vertPos);
      ++ind;
      if (info) {
        ++info.count;
      }

    } else {
      for (let i = 0; i <= numSubdivs; ++i) {
        const t = i / numSubdivs;

        const angle = startAngle + (endAngle - startAngle) * t;
        let xpos = Math.cos(angle) * radiusX + X;
        const ypos = Math.sin(angle) * radiusY + Y;

        if (startX !== null && startX !== undefined && !isNaN(startX)) {
          if (info && info.count === 0) {
            xpos = startX;
          }
        }
        if (endX !== null && endX !== undefined && !isNaN(endX)) {
          if (info && info.count === info.total) {
            xpos = endX;
          }
        }

        if (!arr) {
          arr = [];
        }
        let vertPos = null;
        let vert = arr[ind];

        if (!vert) {
          vert = arr[ind] = new Vertex();
        }
        vertPos = vert.position;

        if (!vertPos) {
          vertPos = vert.position = new Vector3();
        }

        vertPos.setCoord(0, xpos);
        vertPos.setCoord(1, ypos);
        vertPos.setCoord(2, 0);

        let atts = vert.attributes;

        if (!atts) {
          atts = vert.attributes = {};
        }

        vertPos = this.borderTransformVector3(vertPos, yOffset, borderShape, false, true, vertPos);

        arr[ind] = vert;
        if (info) {
          ++info.count;
        }
        ++ind;
      }
    }
    info.index = ind;

    return arr;
  }

  getShapeXByY(ypos, data, mattressData) {
    let y = ypos;
    const h = this.getHeight(data);
    const t = this.getThickness(data);
    const hh = h * 0.5;
    const borderRadius = t;

    y = y < -hh ? -hh : y;
    y = y > hh ? hh : y;

    const p1 = -hh + borderRadius;
    const p2 = hh - borderRadius;
    let xpos;

    if (y >= (-hh) && y < p1) {
      const cosine = 1 - ((-hh) - y) / ((-hh) - p1);
      const sine = Math.sqrt(1 - cosine * cosine);

      xpos = -sine * borderRadius;

      return xpos;
    } else if (y <= (hh) && y > p2) {
      const cosine = 1 - (y - hh) / (p2 - hh);
      const sine = Math.sqrt(1 - cosine * cosine);

      xpos = -sine * borderRadius;

      return xpos;
    }

    xpos = -borderRadius;

    return xpos;
  }

  addVertices(borderComponentData, mattressData, yOffset, startX, endX, borderShape, array, index) {
    let ind = index;
    let arr = array;
    let newArr = null;

    if ((ind === null || ind === undefined)) {
      if (arr) {
        ind = arr.length;
      } else {
        ind = 0;
      }
    }
    const firstIndex = ind;
    const height = this.getHeight(borderComponentData, mattressData);
    const depth = this.getDepth(borderComponentData, mattressData);
    const thickness = this.getThickness(borderComponentData, mattressData);

    const radius = thickness;
    const radiusX = radius;
    let radiusY = radius;
    const minRadius = height * 0.5;

    radiusY = radiusY > minRadius ? minRadius : radiusY;

    const edgeLength = 1;
    const minSubdivs = 2;
    const maxSubdivs = 50;
    let numBorderSubdivs = 0;

    if (radiusX > 0 && radiusY > 0) {
      const minBorderSubdivs = minSubdivs;
      const maxBorderSubdivs = maxSubdivs;
      const borderLength = radius * Math.PI * 0.5;

      numBorderSubdivs = (borderLength / edgeLength) | 0;
      numBorderSubdivs = numBorderSubdivs < minBorderSubdivs ? minBorderSubdivs : numBorderSubdivs;
      numBorderSubdivs = numBorderSubdivs > maxBorderSubdivs ? maxBorderSubdivs : numBorderSubdivs;
    }

    const midHeight = height - radiusY - radiusY;
    let straightSubdivs = 0;

    if (midHeight > 0) {
      straightSubdivs = (midHeight / edgeLength) | 0;
      const minStraightSubdivs = minSubdivs;
      const maxStraightSubdivs = maxSubdivs;

      straightSubdivs = straightSubdivs < minStraightSubdivs ? minStraightSubdivs : straightSubdivs;
      straightSubdivs = straightSubdivs > maxStraightSubdivs ? maxStraightSubdivs : straightSubdivs;
    }
    let total = (numBorderSubdivs + 1) * 2;

    if (straightSubdivs > 2) {
      total += straightSubdivs - 2;
    }
    const info = {index: ind, count: 0, total: total, V: 0};

    arr = this._addArcVerts(Math.PI * 0.5, Math.PI, radiusX, radiusY, -depth, height * 0.5 - radiusY, yOffset, startX, endX, borderShape, numBorderSubdivs, arr, info);
    ind = info.index;

    if (midHeight > 0) {
      const startIndex = 1;
      const endIndex = straightSubdivs - 1;

      for (let i = startIndex; i <= endIndex; ++i) {
        const t = i / straightSubdivs;
        const ypos = height * 0.5 - radiusY - midHeight * t;
        const xpos = -depth - radiusX;

        if (!arr) {
          arr = [];
        }

        let vert = arr[ind];

        if (!vert) {
          vert = arr[ind] = new Vertex();
        }

        let vertPos = vert.position;


        if (!vertPos) {
          vertPos = new Vertex(new Vector3());
        }
        vertPos.setCoord(0, xpos);
        vertPos.setCoord(1, ypos);
        vertPos.setCoord(2, 0);

        let atts = vert.attributes;

        if (!atts) {
          atts = vert.attributes = {};
        }

        vertPos = this.borderTransformVector3(vertPos, yOffset, borderShape, false, true, vertPos);
        vert.position = vertPos;

        arr[ind] = vert;
        ++ind;
        ++info.count;
      }
    }

    info.index = ind;

    const _270degs = 1.5;

    newArr = this._addArcVerts(Math.PI, Math.PI * _270degs, radiusX, radiusY, -depth, -height * 0.5 + radiusY, yOffset, startX, endX, borderShape, numBorderSubdivs, arr, info);
    if (newArr) {
      arr = newArr;
    }
    ind = info.index;


    const numV = ind - firstIndex;

    this.computeVertexNormals(arr, numV, firstIndex);

    let offsetV = window.border3DOffsetV;

    if (offsetV === undefined || offsetV === null) {
      offsetV = 0;
    }

    this.computeUVs(arr, numV, firstIndex, -1, offsetV);

    /*
    for (let i = firstIndex; i < ind; ++i) {
      const vec = arr[i];
      const uv = vec.attributes.uv;

    }
    */

    return arr;
  }

  getThickness(borderComponentData, mattressData) {
    if (!borderComponentData) {
      return DEFAULT_THICKNESS;
    }

    return this._parseNum(borderComponentData.thickness, DEFAULT_THICKNESS);
  }

  getHeight(data, mattressData) {
    if (!data) {
      return 0;
    }

    return this._parseNum(data.height, 0);
  }

  getTop(data, mattressData) {
    return -this.getHeight(data, mattressData) * 0.5;
  }

  getBottom(data, mattressData) {
    return this.getHeight(data, mattressData) * 0.5;
  }
}
