import FabricBorderComponentType from './FabricBorderComponentType';
import PipingBorderComponentType from './PipingBorderComponentType';
// import RibbonBorderComponentType from './RibbonBorderComponentType';
import TapeBorderComponentType from './TapeBorderComponentType';
import ZipperBorderComponentType from './ZipperBorderComponentType';
import Border3DComponentType from './Border3DComponentType';
import GussetBorderComponentType from './GussetBorderComponentType';

const BorderComponentTypes = {
  BORDER3D: new Border3DComponentType(),
  FABRIC: new FabricBorderComponentType(),
  PIPING: new PipingBorderComponentType(),
  // RIBBON: new RibbonBorderComponentType(),
  TAPE: new TapeBorderComponentType(),
  ZIPPER: new ZipperBorderComponentType(),
  GUSSET: new GussetBorderComponentType()
};

export default BorderComponentTypes;
