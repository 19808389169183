import Vector2 from './Vector2';

const DIM = 3;
const Z_COORD_INDEX = 2;

export default class Vector3 extends Vector2 {
  constructor(x = 0, y = 0, z = 0) {
    super(x, y);
    this._z = z;
  }

  getZ() {
    return this.getCoord(Z_COORD_INDEX);
  }

  setZ(value) {
    this.setCoord(Z_COORD_INDEX, value);
  }

  get z() {
    return this.getZ();
  }

  set z(value) {
    this.setZ(value);
  }

  get [Z_COORD_INDEX]() {
    return this.getCoord(Z_COORD_INDEX);
  }

  set [Z_COORD_INDEX](v) {
    return this.setCoord(Z_COORD_INDEX, v);
  }

  getDimension() {
    return DIM;
  }
}
