import ThreeComponent from '../../bgr/three/component/ThreeComponent';

export default class BD3DThreeComponent extends ThreeComponent {

  _getContentContainer(create = true) {
    let container = this._contentContainer;

    if (container || !create) {
      return container;
    }
    container = this._contentContainer = document.createElement('div');

    container.style.position = 'absolute';
    container.style.left = '0px';
    container.style.top = '0px';
    container.style.overflow = 'hidden';

    return container;
  }

  getDOMElement(create = true) {
    let elem = this._domElement;

    if (elem || create === false) {
      return elem;
    }

    const contentContainer = this._getContentContainer(create);
    const canvas = this.getCanvas(create);

    canvas.style.position = 'absolute';
    canvas.style.left = '0px';
    canvas.style.top = '0px';

    contentContainer.appendChild(canvas);


    elem = this._domElement = document.createElement('div');
    elem.style.position = 'relative';
    elem.appendChild(contentContainer);

    return elem;
  }

  getPointerTargetElement() {
    return this.getDOMElement();
  }

  get pointerTargetElement() {
    return this.getPointerTargetElement();
  }

  _beforeAutoSize() {
    const contentContainer = this._getContentContainer(false);

    if (contentContainer) {
      contentContainer.style.width = 'auto';
      contentContainer.style.height = 'auto';
    }

    let w = -1;
    let h = -1;

    if (w === this.getWidth()) {
      w -= 1;
    }
    if (h === this.getHeight()) {
      h -= 1;
    }
    // Hack to trigger updateSize
    this._width = w;
    this._height = h;
  }

  _changedSize(w, h) {
    super._changedSize(w, h); // Resizes the three.js renderer
    // const elem = this.getDOMElement(false);
    const contentContainer = this._getContentContainer(false);

    if (contentContainer) {
      contentContainer.style.width = `${w}px`;
      contentContainer.style.height = `${h}px`;
    }
  }
}
