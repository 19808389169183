import HandleType from './HandleType';
import Object3DAsset from '../asset/Object3DAsset';
import GraphUtils from '../graph/GraphUtils';
import HandleUtils from './HandleUtils';
import MattressDA from '../mattress/MattressDA';
import Node3DMaterialUtils from '../material/Node3DMaterialUtils';
import BD3DContainerNode3D from '../scenegraph/BD3DContainerNode3D';
import BoundingBoxUtils from '../../bgr/bgr3d/utils/BoundingBoxUtils';
import HandleErrorType from './HandleErrorType';
import BD3DNodeTypes from '../scenegraph/BD3DNodeTypes';
import BD3DNodeUtils from '../scenegraph/BD3DNodeUtils';

/**
 * @class SuitcaseHandleType
 * @description Suitcase handle type, like a horizontal handle but part of a border component
 *
 *  ===========================
 *          \_________/
 *           _________
 *          /         \
 *  ===========================
 * */

const MIN_COMPONENTS = 3;
const MIN_LENGTH_FOR_HANDLES = 120;
const MIN_INNER_LENGTH_FOR_HANDLES = 80;
const MIN_BORDER_HEIGHT = 5;

const Assets = {
  HS041_OBJ: new Object3DAsset('handle.suitcase.HS041.object'),
  HS042_OBJ: new Object3DAsset('handle.suitcase.HS042.object'),
  HS043_OBJ: new Object3DAsset('handle.suitcase.HS043.object')
};

export default class SuitcaseHandleType extends HandleType {
  static getTypeName() {
    return 'suitcase';
  }

  static get typeName() {
    return this.getTypeName();
  }

  isValidSuitcaseBorderComponent(comp) {
    return (comp && (comp.type === 'fabric' || comp.type === 'border' || comp.type === 'border3d'));
  }

  isValidSuitcaseEdgeComponent(comp) {
    return (comp && (comp.type === 'piping' || comp.type === 'tape'));
  }

  addAssets(data, handleStyle, params, array, assetManager) {
    const objAsset = this._get3DObjectAsset(data);

    return assetManager.addAssetToArray(objAsset, array, params);
  }

  _get3DObjectAsset(data, mattressData, assets) {
    const styleID = this._getStyleID(data);

    if (!styleID) {
      return null;
    }
    const objAssetName = `${styleID}_OBJ`;
    const asset = Assets[objAssetName];

    return asset;
  }

  _getHandleGeomVertexAlignY(vertex, params) {
    if (params && params.handleBoundingBox) {
      const bbox = params.handleBoundingBox;
      const minY = bbox.miny;
      const maxY = bbox.maxy;
      const center = (minY + maxY) * 0.5;
      const y = vertex.getCoord(1);

      return y > center ? 1 : 0;
    }

    return 0;
  }

  // deprecated
  /*
  _getTargetBorder(handleData, mattressData) {
    return null;
  }
  */

  _getTargetBorders(handleData, mattressData, res = null) {
    if (!mattressData) {
      return null;
    }
    const border = mattressData.border;

    if (!border) {
      return null;
    }
    const comps = border.components;

    if (!comps) {
      return null;
    }
    const num = comps.length;

    if (!num) {
      return null;
    }

    for (let i = 0; i < num; ++i) {
      const comp = comps[i];
      const prevComp = (i > 0) ? comps[i - 1] : null;
      const nextComp = (i < (num - 1)) ? comps[i + 1] : null;

      if (
        this.isValidSuitcaseBorderComponent(comp) &&
        this.isValidSuitcaseEdgeComponent(prevComp) &&
        this.isValidSuitcaseEdgeComponent(nextComp)
      ) {
        if (res) {
          res.top = prevComp;
          res.middle = comp;
          res.bottom = nextComp;

          return res;
        }

        return {top: prevComp, middle: comp, bottom: nextComp};
      }
    }

    return null;
  }

  _createMaterialFromConfigObject(data) {
    return null;
  }

  create3DHandles(data, borderNodes, mattressData, borderShape, borderCurveGraph, buildParams, resultNode = null) {
    if (resultNode) {
      resultNode.removeChildren();
    }
    let stName = this._getStyleID(data);
    const single = mattressData;

    if (!stName) {
      stName = MattressDA.getHandleType(single);
    }

    const mattressWidth = MattressDA.getWidth(single);
    const mattressLength = MattressDA.getLength(single);
    // const height = MattressDA.getHeight(single);
    //
    const cornerRadius = MattressDA.getResultCornerRadius(single);
    const innerLength = mattressLength - cornerRadius - cornerRadius;
    const innerWidth = mattressWidth - cornerRadius - cornerRadius;

    const asset = this._get3DObjectAsset({type: stName});
    const node3d = asset.getNode3D();

    const borders = this._getTargetBorders(data, mattressData);
    // const border = this._getTargetBorder(data, mattressData);
    const border = borders ? borders.middle : null;
    const topBorder = borders ? borders.top : null;
    // const bottomBorder = borders ? borders.bottom : null;

    // var mf = mc._getMattress3DFactory();
    // var borderNode = mf.getNode3DByData(border);
    //

    const dataNode3DMap = buildParams ? buildParams.dataNode3DMap : null;
    const borderNode = dataNode3DMap ? dataNode3DMap.get(border) : null;
    const topBorderNode = dataNode3DMap ? dataNode3DMap.get(topBorder) : null;
    // const bottomBorderNode = dataNode3DMap ? dataNode3DMap.get(bottomBorder) : null;

    const borderType = borderNode.userData.borderComponentType;
    let handleNodeType = BD3DNodeTypes.suitcaseHandle;

    const params = {
      borders: borders,
      mattressData: single,
      deformMode: HandleUtils.HandleDeformMode.DEFORM_TRUE,
      alignY: 0,
      getVertexAlignY: this._getHandleGeomVertexAlignY,
      borderComponentData: border,
      borderComponentType: borderType,
      borderComponentNode: borderNode,
      topBorderNode: topBorderNode,
      handleNodeType: handleNodeType
    };

    // var handleData = MattressDA.getHandleData(single, false);
    // const handleData = data;
    // const handleNode = dataNode3DMap ? dataNode3DMap.get(handleData) : null;
    // const singleNode = dataNode3DMap ? dataNode3DMap.get(single) : null;

    // const middleContainer = singleNode.children[1];
    let srcMtl = null;

    /*
    srcMtl = Node3DMaterialUtils.getMaterial(topBorderNode);
    if (!srcMtl) {
      srcMtl = this._createMaterialFromConfigObject(topBorder);
    }
    */


    srcMtl = Node3DMaterialUtils.getMaterial(borderNode);

    /*
    if (border && (border.type === 'border' || border.type === 'border3d')) {
      if (topBorder && bottomBorder && topBorder.type === bottomBorder.type) {
        srcMtl = Node3DMaterialUtils.getMaterial(topBorderNode);
        handleNodeType = (topBorderNode && topBorderNode.getNodeType) ? topBorderNode.getNodeType() : null;
        params.handleNodeType = handleNodeType;
      }
    }
    // */
    if (topBorder && (topBorder.type === 'piping' || topBorder.type === 'tape')) {
      srcMtl = Node3DMaterialUtils.getMaterial(topBorderNode);
      handleNodeType = (topBorderNode && topBorderNode.getNodeType) ? topBorderNode.getNodeType() : null;
      params.handleNodeType = handleNodeType;
    }

    const node3DBounds = BoundingBoxUtils.getBoundingBox(node3d);
    const handleWidth = node3DBounds.maxx - node3DBounds.minx;

    let placementCountResult = null;

    placementCountResult = HandleUtils.largeEnoughForNumHandles(data, true, mattressWidth, innerWidth, handleWidth, MIN_LENGTH_FOR_HANDLES, MIN_INNER_LENGTH_FOR_HANDLES, placementCountResult);

    const {largeEnoughFor1Handle: largeEnoughFor1FrontHandle, largeEnoughFor2Handles: largeEnoughFor2FrontHandles} = placementCountResult;

    placementCountResult = HandleUtils.largeEnoughForNumHandles(data, false, mattressLength, innerLength, handleWidth, MIN_LENGTH_FOR_HANDLES, MIN_INNER_LENGTH_FOR_HANDLES, placementCountResult);

    const {largeEnoughFor1Handle: largeEnoughFor1SideHandle, largeEnoughFor2Handles: largeEnoughFor2SideHandles} = placementCountResult;

    const relSidePos = 0.5;
    const relFrontPos = 0.5;

    const resNode = resultNode ? resultNode : new BD3DContainerNode3D();
    const hlength = mattressLength * 0.5;
    const hwidth = mattressWidth * 0.5;


    if (largeEnoughFor2FrontHandles) {
      this._createSuitcaseHandle(node3d, hwidth * relFrontPos, hlength, srcMtl, params, resNode);
      this._createSuitcaseHandle(node3d, -hwidth * relFrontPos, hlength, srcMtl, params, resNode);
      this._createSuitcaseHandle(node3d, hwidth * relFrontPos, -hlength, srcMtl, params, resNode);
      this._createSuitcaseHandle(node3d, -hwidth * relFrontPos, -hlength, srcMtl, params, resNode);
    } else if (largeEnoughFor1FrontHandle) {
      this._createSuitcaseHandle(node3d, 0, hlength, srcMtl, params, resNode);
      this._createSuitcaseHandle(node3d, 0, -hlength, srcMtl, params, resNode);
    }

    if (largeEnoughFor2SideHandles) {
      this._createSuitcaseHandle(node3d, hwidth, hlength * relSidePos, srcMtl, params, resNode);
      this._createSuitcaseHandle(node3d, hwidth, -hlength * relSidePos, srcMtl, params, resNode);
      this._createSuitcaseHandle(node3d, -hwidth, hlength * relSidePos, srcMtl, params, resNode);
      this._createSuitcaseHandle(node3d, -hwidth, -hlength * relSidePos, srcMtl, params, resNode);
    } else if (largeEnoughFor1SideHandle) {
      this._createSuitcaseHandle(node3d, -hwidth, 0, srcMtl, params, resNode);
      this._createSuitcaseHandle(node3d, hwidth, 0, srcMtl, params, resNode);
    }

    return resNode;
  }

  _createSuitcaseHandle(template, xpos, zpos, srcMtl, params, container) {
    const handle = HandleUtils.attachHandleToBorderNode(template, xpos, 0, zpos, params);

    if (handle && srcMtl) {
      if (handle.setNodeType && (params && params.handleNodeType)) {
        BD3DNodeUtils.setNodeType(handle, params.handleNodeType, true);
      }
      const mtl = srcMtl.clone();
      let st = null;

      if (srcMtl.getSampleTransform) {
        st = srcMtl.getSampleTransform();
      } else if (srcMtl.get) {
        st = srcMtl.get('sampleTransform');
      }
      const stCopy = (st && st.clone) ? st.clone() : null;

      if (stCopy && stCopy.setGeometryUVData) {
        stCopy.setGeometryUVData(null);
      }

      if (mtl.setSampleTransform) {
        mtl.setSampleTransform(stCopy);
      } else if (mtl.set) {
        mtl.set('sampleTransform', stCopy);
      }


      Node3DMaterialUtils.assignMaterial(handle, mtl);
    }

    if (container && handle) {
      container.addChild(handle);
    }

    return handle;
  }

  _setAllowError(errorInfo, error) {
    if (!errorInfo) {
      return;
    }
    errorInfo.error = error;
  }

  isAllowedOnBorder(handleData, mattressData, borderData, errInf = null) {
    if (!handleData) {
      return false;
    }
    if (!mattressData) {
      return false;
    }
    if (!borderData) {
      return false;
    }

    const mattressBorder = mattressData.border;
    const mattressBorderComps = mattressBorder ? mattressBorder.components : null;
    const borderIndex = mattressBorderComps ? mattressBorderComps.indexOf(borderData, 0) : -1;

    if (!this.isValidSuitcaseBorderComponent(borderData)) {
      this._setAllowError(errInf, HandleErrorType.SUITCASE_BASE_ERROR);

      return false;
    }
    const height = borderData.height;

    if (height < MIN_BORDER_HEIGHT) {
      this._setAllowError(errInf, HandleErrorType.SUITCASE_BASE_ERROR);

      return false;
    }

    if (borderIndex < 0) {
      this._setAllowError(errInf, HandleErrorType.SUITCASE_BASE_ERROR);

      return false;
    }
    const prevBorder = mattressBorderComps[borderIndex - 1];
    const nextBorder = mattressBorderComps[borderIndex + 1];

    if (!this.isValidSuitcaseEdgeComponent(nextBorder) || !this.isValidSuitcaseEdgeComponent(prevBorder)) {
      this._setAllowError(errInf, HandleErrorType.SUITCASE_BASE_ERROR);

      return false;
    }

    this._setAllowError(errInf, null);

    return true;
  }

  isAllowed(handleData, mattressData, errorInfo) {
    if (!handleData || !mattressData) {
      return false;
    }
    const border = mattressData.border;

    if (!border) {
      this._setAllowError(errorInfo, HandleErrorType.SUITCASE_BASE_ERROR);

      return false;
    }
    const components = border.components;

    if (!components) {
      this._setAllowError(errorInfo, HandleErrorType.SUITCASE_BASE_ERROR);

      return false;
    }
    const numComponents = components.length;

    if (numComponents < MIN_COMPONENTS) {
      this._setAllowError(errorInfo, HandleErrorType.SUITCASE_BASE_ERROR);

      return false;
    }

    let comp, i;

    // For now, can't have handles when using a curved border
    const graph = mattressData.border ? mattressData.border.curve : null;

    if (graph) {
      if (GraphUtils.hasGraph(graph)) {
        this._setAllowError(errorInfo, HandleErrorType.SUITCASE_CURVEDBORDER_ERROR);

        return false;
      }
    }

    // Find top attachment component
    for (i = 0; i < numComponents; ++i) {
      comp = components[i];
      if (this.isValidSuitcaseBorderComponent(comp, i, mattressData)) {
        if (i > 0 && i < numComponents - 1) {
          const prevComp = components[i - 1];
          const nextComp = components[i + 1];

          if (this.isValidSuitcaseEdgeComponent(prevComp) &&
              this.isValidSuitcaseEdgeComponent(nextComp)) {
            return true;
          }
        }
      }
    }

    this._setAllowError(errorInfo, HandleErrorType.SUITCASE_BASE_ERROR);

    return false;
  }
}
