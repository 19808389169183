import BD3DCollisionBoxNode3D from '../../scenegraph/BD3DCollisionBoxNode3D';

export default {
  create(data, id, session) {
    const {box, transform} = data;

    return new BD3DCollisionBoxNode3D(box, transform);
  },
  update(object, data, id, session) {
    return;
  }
};
