import Numeric from './Numeric';
import {parseNumber} from './utils';

const operators = {
  add(params, a, b) {
    return parseNumber(a, params, 0) + parseNumber(b, params, 0);
  },
  subtract(params, a, b) {
    return parseNumber(a, params, 0) - parseNumber(b, params, 0);
  },
  multiply(params, a, b) {
    return parseNumber(a, params, 1) * parseNumber(b, params, 1);
  },
  divide(params, a, b) {
    return parseNumber(a, params, 1) / parseNumber(b, params, 1);
  },
  power(params, a, b) {
    const A = parseNumber(a, params, 1);
    const B = parseNumber(b, params, 1);

    return Math.pow(A, B);
  },
  squareRoot(params, a) {
    const A = parseNumber(a, params, 1);

    return Math.sqrt(A);
  },
  modulo(params, a, b) {
    const A = parseNumber(a, params, 1);
    const B = parseNumber(b, params, 1);

    return A % B;
  },
  negate(params, a) {
    const A = parseNumber(a, params, 1);

    if (A === 0) {
      return A;
    }

    return A;
  },
  pi() {
    return Math.PI;
  }
};

{
  const aliases = {
    add: ['+', 'sum', 'addition'],
    subtract: ['-', 'sub', 'subtraction'],
    multiply: ['*', 'mul', 'multiplication'],
    divide: ['/', ':', 'div', 'division'],
    power: ['**', 'pow'],
    squareRoot: ['sqrt'],
    modulo: ['%', 'mod'],
    negate: ['neg']
  };
  const ops = operators;

  for (const v in aliases) {
    if (aliases.hasOwnProperty(v)) {
      const list = aliases[v];
      const num = list ? list.length : 0;
      const op = ops[v];

      for (let i = 0; i < num; ++i) {
        const alias = list[i];

        if (alias && op) {
          ops[alias] = op;
        }
      }
    }
  }
}

function getOpFunc(op, params) {
  if (!op || !op.toLowerCase) {
    return null;
  }

  return operators[op.toLowerCase()];
}

export default class NumericOperation extends Numeric {
  constructor(operator = null, operands = null, defVal = 0) {
    super();
    this.operator = operator;
    this.operands = operands;
    this.defaultValue = defVal;
  }
  getNumber(params) {
    const {operator, operands} = this;
    const opFunc = getOpFunc(operator, params);

    if (!opFunc) {
      return this.defaultValue;
    }
    const num = operands ? operands.length : 0;

    if (!num) {
      return opFunc(params);
    }

    if (num === 0) {
      return opFunc(params);
    } else if (num === 1) {
      return opFunc(
        params,
        parseNumber(operands[0], params, null)
      );
    }
    let res = opFunc(
      params,
      parseNumber(operands[0], params, null),
      parseNumber(operands[1], params, null)
    );

    if (num <= 2) {
      return res;
    }

    for (let i = 2; i < num; ++i) {
      const o = parseNumber(operands[i], params, null);

      if (typeof (o) === 'number' && !isNaN(o)) {
        res = opFunc(params, res, o);
      }
    }

    return res;
  }
}
