import Vector4 from '../geom/Vector4';

const _0 = 0, _1 = 1, _2 = 2, _3 = 3,
  _4 = 4, _5 = 5, _6 = 6, _7 = 7,
  _8 = 8, _9 = 9, _10 = 10, _11 = 11,
  _12 = 12, _13 = 13, _14 = 14, _15 = 15;


export default class VecMat4Math {
  static transformVectorCoords(x, y, z, w = 1, m = null, result = null) {
    let res = result;

    if (!res) {
      res = new Vector4();
    }
    if (!m) {
      res.setCoords(x, y, z, w);

      return res;
    }
    const tx = this.transformVectorX(x, y, z, w, m);
    const ty = this.transformVectorY(x, y, z, w, m);
    const tz = this.transformVectorZ(x, y, z, w, m);
    const tw = this.transformVectorW(x, y, z, w, m);

    res.setCoords(tx, ty, tz, tw);

    return res;
  }

  static transformVectorX(x, y, z, w = 1, m = null) {
    if (!m) {
      return x;
    }

    return x * m[_0] + y * m[_4] + z * m[_8] + w * m[_12];
  }

  static transformVectorY(x, y, z, w = 1, m = null) {
    if (!m) {
      return y;
    }

    return x * m[_1] + y * m[_5] + z * m[_9] + w * m[_13];
  }

  static transformVectorZ(x, y, z, w = 1, m = null) {
    if (!m) {
      return z;
    }

    return x * m[_2] + y * m[_6] + z * m[_10] + w * m[_14];
  }

  static transformVectorW(x, y, z, w = 1, m = null) {
    if (!m) {
      return w;
    }

    return x * m[_3] + y * m[_7] + z * m[_11] + w * m[_15];
  }
}
