import Logger from './Logger';

// #if DEBUG
function asString(src) {
  if ((src instanceof Array) || (Array.isArray && Array.isArray(src))) {
    return src.join(' ');
  } else if (typeof (src) !== 'string') {
    return `${src}`;
  }

  return src;
}
// #endif
/**
* @class ConsoleLogger
* @extends Logger
* @description Logs everything to the browser console
*/
export default class ConsoleLogger extends Logger {
  _log(type, args) {
    // #if DEBUG
    if (!this.isEnabled()) {
      return;
    }
    if (typeof (console) === 'undefined') {
      return;
    }
    if (type === 'log') {
      console.log(...args);
    } else if (type === 'warn') {
      console.warn(...args);
    } else if (type === 'error') {
      console.error(...args);
    } else if (type === 'info') {
      console.info(...args);
    } else if (type === 'time') {
      console.time(asString(args));
    } else if (type === 'timeEnd') {
      console.timeEnd(asString(args));
    }
    // #endif

    return;
  }

  clear() {
    // #if DEBUG
    if (typeof (console) === 'undefined') {
      return;
    }
    if (console.clear) {
      console.clear();
    }
    // #endif

    return;
  }
}
