import Loader from '../../bgr/common/loading/Loader';
import ImageLoader from '../../bgr/common/loading/ImageLoader';
import LoaderState from '../../bgr/common/loading/LoaderState';
import MattressDA from '../mattress/MattressDA';
import QuiltService from '../services/QuiltService';
import ImageDistanceGlow from '../quilt/ImageDistanceGlow';
import ImageDistanceTransform from '../quilt/ImageDistanceTransform';

import BumpNormalUtils from '../utils/BumpNormalUtils';
import CanvasUtils from '../utils/CanvasUtils';
// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
// #endif

// import LoaderList from '../../bgr/common/loading/LoaderList';

export default class QuiltLoader extends Loader {
  constructor(args) {
    super();
    let quiltConfigData = null;
    let quiltService = null;
    let quiltLoadParams = null;

    if (args) {
      quiltConfigData = args.quiltConfigData || args.data;
      quiltService = args.quiltService || args.service;
      quiltLoadParams = args.quiltLoadParams || args.loadParams || args.params;
    }

    this.quiltConfigData = quiltConfigData;
    this.quiltService = quiltService;
    this.quiltLoadParams = quiltLoadParams;

    this.quiltData = null;
    this.images = null;
    this.bumpDistanceField = null;
    this.defaultQuiltService = null;
    this._imageLoaders = null;
    this._quiltFinishedHandler = null;
    this._imagesLoadedHandler = null;
  }

  getResult() {
    let res = this._result;

    if (!res) {
      res = this._result = {};
    }
    res.data = this.quiltData;
    res.images = this.images;
    res.bumpDistanceField = this.bumpDistanceField;

    return res;
  }

  get result() {
    return this.getResult();
  }

  setQuiltService(svc) {
    if (svc && !(svc instanceof QuiltService)) {
      // #if DEBUG
      BD3DLogger.log('Invalid quilt service', svc);
      // #endif

      return;
    }
    this.quiltService = svc;
  }

  _getQuiltService() {
    let res = this.quiltService;

    if (res) {
      return res;
    }

    res = this.defaultQuiltService;
    if (!res) {
      res = this.defaultQuiltService = new QuiltService();
    }

    return res;

  }

  _getQuiltID() {
    const data = this.quiltConfigData;

    if (!data) {
      return null;
    }
    if (typeof (data) === 'string') {
      return data;
    }

    return MattressDA.getQuiltID(data);
  }

  _getFinishedHandler() {
    let res = this._quiltFinishedHandler;

    if (!res) {
      const that = this;

      res = this._quiltFinishedHandler = evt => {
        that._handleQuiltFinished(evt);
      };
    }

    return res;
  }

  _handleQuiltFinished(evt) {
    const qs = this._getQuiltService();
    const loader = evt.loader;

    const qd = qs.getLoaderQuiltData(loader);

    this.quiltData = qd;

    // #if DEBUG
    BD3DLogger.log('quilt finished', qd);
    // #endif

    this._loadImages();
  }

  _quiltDataLoaded(qd) {
    this.quiltData = qd;

    // #if DEBUG
    BD3DLogger.log('quilt finished', qd);
    // #endif

    this._loadImages();
  }

  _loadImages() {
    const qs = this._getQuiltService();
    const imageParams = null;
    let imageLoaders = this._imageLoaders;

    if (!imageLoaders) {
      imageLoaders = this._imageLoaders = {};
    }

    const quiltID = this._getQuiltID();

    imageLoaders._bumpLoader = qs.getQuiltImageLoader(quiltID, 'bump', imageParams, {quiltData: this.quiltData, quiltConfigData: this.quiltConfigData}, imageLoaders._bumpLoader);

    if (!imageLoaders._bumpLoader) {
      if (this._startLoadingURLQuilt()) {
        // #if DEBUG
        BD3DLogger.warn('Error while loading quilt bump image - trying to load url ', ((this.quiltConfigData && this.quiltConfigData.img && this.quiltConfigData.img.src) ? this.quiltConfigData.img.src : ''));
        // #endif

        return;
      }
    }


    // if more images need to be loaded, use a LoaderList as imagesLoader
    const imagesLoader = imageLoaders._bumpLoader;

    this._imagesLoader = imagesLoader;

    if (imagesLoader) {
      imagesLoader.once('finished', this._getImagesLoadedHandler());

      imagesLoader.start();
    } else {
      this._changeState(LoaderState.ERROR);
    }
  }

  _getImagesLoadedHandler() {
    let res = this._imagesLoadedHandler;

    if (!res) {
      const that = this;

      res = this._imagesLoadedHandler = evt => {
        return that._handleImagesLoaded(evt);
      };
    }

    return res;
  }

  _handleImagesLoaded(evt) {
    let bumpImage = null;
    const imageLoaders = this._imageLoaders;

    if (imageLoaders) {
      if (imageLoaders._bumpLoader) {
        bumpImage = imageLoaders._bumpLoader.getImage();
        if (imageLoaders._bumpLoader.error || !bumpImage || !bumpImage.width || !bumpImage.height) {
          bumpImage = null;
        }
        imageLoaders._bumpLoader = null;
      }
      this._imagesLoaders = null;
    }

    if (!bumpImage && !this._loadURLAsFallback) {
      this._loadURLAsFallback = true;
      if (this._startLoadingURLQuilt()) {
        // #if DEBUG
        BD3DLogger.warn('Error while loading quilt bump image - trying to load url ', ((this.quiltConfigData && this.quiltConfigData.img && this.quiltConfigData.img.src) ? this.quiltConfigData.img.src : ''));
        // #endif

        return;
      }
    }

    let images = this.images;

    if (!images) {
      images = this.images = {};
    }
    images.bump = bumpImage;

    // this._applyGlow();

    this._changeState(LoaderState.COMPLETED);
  }

  reset() {
    super.reset();
    this._cancelLoading();

    this._imagesLoader = null;
    this._loadURLAsFallback = false;
    this.images = null;
    this._imageLoaders = null;
  }

  cancel() {
    super.cancel();
    this._cancelLoading();
  }

  _cancelLoading() {
    if (this._imagesLoader) {
      this._imagesLoader.cancel();
      this._imagesLoader.removeEventListeners();
    }
  }

  // not used anymore -> moved to QuiltAsset & QuiltConfig
  _applyGlow() {
    const images = this.images;

    if (!images) {
      return;
    }
    const bumpImage = images.bump;
    const enabled = false;

    if (enabled && bumpImage && BumpNormalUtils.isBumpMap(bumpImage)) {
      const glowParams = null;
      const width = CanvasUtils.getWidth(bumpImage);
      const height = CanvasUtils.getHeight(bumpImage);
      const imgData = CanvasUtils.toImageData(bumpImage);
      const distancesArray = ImageDistanceTransform.pixelsToDistances(imgData.data, width, height, glowParams);
      const minDist = 0;
      const maxDist = 100;
      const interpFunc = null;

      const bumpCanvas = ImageDistanceGlow.applyGlow(bumpImage, minDist, maxDist, width, height, distancesArray, interpFunc, null, glowParams);

      images.bumpSource = bumpImage;
      images.bump = bumpCanvas;
      this.bumpDistanceField = distancesArray;
    }
  }

  _startLoadingURLQuilt() {
    const qcfgData = this.quiltConfigData;

    if (!qcfgData || !qcfgData.img || !qcfgData.img.src) {
      return false;
    }

    let imageLoaders = this._imageLoaders;

    if (!imageLoaders) {
      imageLoaders = this._imageLoaders = {};
    }

    imageLoaders._bumpLoader = new ImageLoader({source: qcfgData.img.src, crossOrigin: 'Anonymous'});

    const loader = imageLoaders._bumpLoader;

    this._changeState(LoaderState.PROGRESS);

    loader.once('finished', this._getImagesLoadedHandler());
    loader.start();

    return true;
  }

  start() {
    const id = this._getQuiltID();

    if (!id) {
      if (this._startLoadingURLQuilt()) {
        // #if DEBUG
        BD3DLogger.log('No quilt id - trying to load url ', ((this.quiltConfigData && this.quiltConfigData.img && this.quiltConfigData.img.src) ? this.quiltConfigData.img.src : ''));
        // #endif

        return;
      }

      // #if DEBUG
      BD3DLogger.warn('Unable to load quilt - invalid quilt id. Quilt data=', this.quiltConfigData);
      // #endif
      this._changeState(LoaderState.ERROR);

      return;
    }

    const qs = this._getQuiltService();

    if (!qs instanceof QuiltService) {
      if (this._startLoadingURLQuilt()) {
        // #if DEBUG
        BD3DLogger.warn('No quilt service - trying to load url ', ((this.quiltConfigData && this.quiltConfigData.img && this.quiltConfigData.img.src) ? this.quiltConfigData.img.src : ''));
        // #endif

        return;
      }
      // #if DEBUG
      BD3DLogger.warn('Unable to load quilt - invalid quilt service', qs);
      // #endif
      this._changeState(LoaderState.ERROR);

      return;
    }

    let quiltDataCallback = this._quiltDataCallback;

    if (!quiltDataCallback) {
      const that = this;

      quiltDataCallback = this._quiltDataCallback = res => {
        that._quiltDataLoaded(res);
      };
    }
    this._changeState(LoaderState.PROGRESS);
    qs.getQuiltData(id, this.quiltConfigData, this.quiltLoadParams, quiltDataCallback);

    /*
    this._quiltLoader = qs.getQuiltDataLoader(id, this.quiltLoadParams, this._quiltLoader);

    if (!this._quiltLoader) {
      // #if DEBUG
      BD3DLogger.warn('Unable to load quilt - invalid quilt loader', this._quiltLoader);
      // #endif
      this._changeState(LoaderState.ERROR);

      return;
    }

    this._quiltLoader.once('finished', this._getFinishedHandler());
    this._quiltLoader.start();
    */
  }
}
