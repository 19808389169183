import Object3DAsset from './Object3DAsset';
import EventDispatcher from '../../bgr/common/events/EventDispatcher';

export default class OBJFileAsset extends Object3DAsset {
  constructor(name, url) {
    super(name);
    this.url = url;

    return this;
  }
  getURL() {
    return this.url;
  }

  getEventDispatcher() {
    let ed = this.eventDispatcher;

    if (ed) {
      return ed;
    }
    ed = new EventDispatcher();

    this.eventDispatcher = ed;

    return ed;
  }

  setData(data) {
    super.setData(data);

    const dispatcher = this.getEventDispatcher();

    dispatcher.dispatchEvent('data_changed', this);
  }
}
