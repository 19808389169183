import ArrayUtils from '../../bgr/common/utils/ArrayUtils';

export function getAllMattressSingles(config, params = null, resultArray = null) {
  if (!config) {
    return resultArray;
  }
  let res = resultArray;
  const condition = params ? params.condition : null;
  const numControllers = config.getNumControllers ? config.getNumControllers() : 0;

  for (let i = 0; i < numControllers; ++i) {
    const controller = config.getController(i);

    if (controller && controller.getTypeName) {
      const typeName = controller.getTypeName();
      let mattressSingle = null;

      if (typeName && typeName.toLowerCase() === 'boxspringcontrollerplugin') {
        mattressSingle = controller.getMattress ? controller.getMattress() : null;

        if (condition && !condition(mattressSingle, params)) {
          mattressSingle = null;
        }
      }

      if (mattressSingle) {
        if (!res) {
          res = [];
        }

        ArrayUtils.addOnce(res, mattressSingle);
      }
    }
  }
  const data = config.getData();
  const singles = data ? data.singles : null;
  const numSingles = singles ? singles.length : 0;

  for (let i = 0; i < numSingles; ++i) {
    const single = singles[i];
    let allowSingle = true;

    for (let j = 0; allowSingle && j < numControllers; ++j) {
      const controller = config.getController(j);
      let typeName = null;
      let containsSingle = false;

      if (controller && controller.getTypeName) {
        typeName = controller.getTypeName();
      }
      if (typeName && typeName.toLowerCase() === 'boxspringcontrollerplugin') {
        containsSingle = (controller.containsSingle(single));
      }

      if (containsSingle) {
        allowSingle = false;
      }
    }

    if (allowSingle && (!condition || condition(single, params))) {
      if (!res) {
        res = [];
      }
      ArrayUtils.addOnce(res, single);
    }
  }

  return res;
}

export function getMattressSinglesAllowingHandles(config, params = null, resultArray = null) {
  const options = {
    params,
    condition(single) {
      const handles = config.getAvailableHandles(single);

      return (handles && handles.length > 0) && (!params || !params.condition || params.condition(single, params));
    }
  };

  return getAllMattressSingles(config, options, resultArray);
}
