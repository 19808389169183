/* global Float32Array, Float64Array, ArrayBuffer */
/* global Map, WeakMap */
/* jslint unused: false */

import Node3D from '../scenegraph/Node3D';
import ContainerNode3D from '../scenegraph/ContainerNode3D';
import GeometryNode3D from '../scenegraph/GeometryNode3D';
/*
import Transform3D from '../transform/Transform3D';
import SRTTransform3D from '../transform/SRTTransform3D';
*/

import Vector from '../geom/Vector';
/*
import Vector2 from '../geom/Vector2';
import Vector3 from '../geom/Vector3';
import Vector4 from '../geom/Vector4';
import Vertex from '../geom/Vertex';
import Polygon from '../geom/Polygon';
*/
import Geometry from '../geom/Geometry';
import Matrix4Math from '../math/Matrix4Math';
import VecMat4Math from '../math/VecMat4Math';

const GeometryBoundingBoxMethod = {
  // Calculate the geometry bounding box using its vertices array
  VERTEX: 'vertex',
  // Calculate the geometry bounding box using its polygons
  POLYGON: 'polygon',
  // Calculate the geometry bounding box using its existing bounding box
  BOUNDINGBOX: 'boundingbox'
};

const DEFAULT_GEOMETRY_BOUNDINGBOX_METHOD = GeometryBoundingBoxMethod.BOUNDINGBOX;

/**
 * @class BoundingBoxUtils
 * @description Util to create copies of 3D nodes & geometries
 * */
export default class BoundingBoxUtils {

  static _expandBoundingBoxByVectors(vectors, params, matrix, result = null) {
    let res = result;

    if (!vectors) {
      return res;
    }
    const l = vectors.length;

    if (l === 0) {
      return res;
    }
    for (let i = 0; i < l; ++i) {
      const vec = vectors[i];

      res = this._expandBoundingBoxByVector(vec, params, matrix, res);
    }

    return res;
  }

  static _expandBoundingBoxByVector3(X, Y, Z, params, matrix, result = null) {
    let x = X, y = Y, z = Z;
    const W = 1;
    let res = result;
    const m = matrix;

    if (m) {
      const tx = VecMat4Math.transformVectorX(x, y, z, W, m);
      const ty = VecMat4Math.transformVectorY(x, y, z, W, m);
      const tz = VecMat4Math.transformVectorZ(x, y, z, W, m);

      x = tx;
      y = ty;
      z = tz;
    }
    let valid = true;

    if (res) {
      valid = res.valid === true;
    } else {
      valid = false;
      res = {};
    }
    if (valid) {
      res.minx = x < res.minx ? x : res.minx;
      res.miny = y < res.miny ? y : res.miny;
      res.minz = z < res.minz ? z : res.minz;

      res.maxx = x > res.maxx ? x : res.maxx;
      res.maxy = y > res.maxy ? y : res.maxy;
      res.maxz = z > res.maxz ? z : res.maxz;
    } else {
      res.valid = true;
      res.minx = res.maxx = x;
      res.miny = res.maxy = y;
      res.minz = res.maxz = z;
    }

    return res;
  }

  static _expandBoundingBoxByCoord(coordName, value, isMax, result) {
    let res = result;
    const entryName = (isMax ? 'max' : 'min') + coordName;

    let val = null;
    let valid = false;

    if (res) {
      val = res[entryName];
    }

    if (res) {
      valid = res.valid === true;
    }
    if (valid) {
      if (typeof (val) === 'undefined' || val === null) {
        valid = false;
      }
    }

    if (valid) {
      if (isMax) {
        val = value > val ? value : val;
      } else {
        val = value < val ? value : val;
      }
    } else {
      val = value;
    }
    if (!res) {
      res = {};
    }
    res[entryName] = val;
    res.valid = true;

    return res;
  }

  static _expandBoundingBoxByVector(vec, params, matrix, result) {
    let res = result;

    if (!vec) {
      return res;
    }

    const m = matrix;

    if (vec instanceof Vector) {
      const size = vec.getDimension();

      let X, Y, Z;
      const W = 1;

      X = size > 0 ? vec.getCoord(0) : 0;
      Y = size > 1 ? vec.getCoord(1) : 0;
      Z = size > 2 ? vec.getCoord(2) : 0;

      if (m) {
        const tx = VecMat4Math.transformVectorX(X, Y, Z, W, m);
        const ty = VecMat4Math.transformVectorY(X, Y, Z, W, m);
        const tz = VecMat4Math.transformVectorZ(X, Y, Z, W, m);

        X = tx;
        Y = ty;
        Z = tz;
      }

      res = this._expandBoundingBoxByCoord('x', X, false, res);
      res = this._expandBoundingBoxByCoord('x', X, true, res);
      res = this._expandBoundingBoxByCoord('y', Y, false, res);
      res = this._expandBoundingBoxByCoord('y', Y, true, res);
      res = this._expandBoundingBoxByCoord('z', Z, false, res);
      res = this._expandBoundingBoxByCoord('z', Z, true, res);
    }

    return res;
  }

  static _getStoredGeometryBoundingBox(geom) {
    if (!geom) {
      return null;
    }
    const ud = geom.userData;

    if (!ud) {
      return null;
    }

    return ud.boundingBox;
  }

  static _expandBoundingBox(value, params, parentMatrix, result = null) {
    let res = result;

    if (!value) {
      return res;
    }
    let i, l;

    if (value instanceof Node3D) {
      let useTransforms = true;

      if (params) {
        useTransforms = params.useTransforms !== false;
      }
      let globalMatrix = parentMatrix;

      if (useTransforms) {
        const tr = value.transform;

        if (tr) {
          globalMatrix = Matrix4Math.identity();
          tr.applyMatrix4(globalMatrix);
          if (parentMatrix) {
            globalMatrix = Matrix4Math.multiply(parentMatrix, globalMatrix, globalMatrix);
          }
        }
      }

      if (value instanceof ContainerNode3D) {
        const children = value.children;

        if (!children) {
          return res;
        }
        const num = children.length;

        if (num === 0) {
          return res;
        }
        for (i = 0; i < num; ++i) {
          res = this._expandBoundingBox(children[i], params, globalMatrix, res);
        }

        return res;
      } else if (value instanceof GeometryNode3D) {
        return this._expandBoundingBox(value.geometry, params, globalMatrix, res);
      }
    } else if (value instanceof Geometry) {
      let geomMethod = params ? params.geometryBoundingBoxMethod : null;

      if (!geomMethod) {
        geomMethod = DEFAULT_GEOMETRY_BOUNDINGBOX_METHOD;
      }
      geomMethod = geomMethod.toLowerCase();

      let verts = value.vertices;
      let polyVerts = value.polygonVertices;

      if (!verts) {
        verts = polyVerts;
      }
      const polys = value.polygons;
      let boundingBox;

      if (geomMethod === GeometryBoundingBoxMethod.VERTEX) {
        if (!verts) {
          geomMethod = GeometryBoundingBoxMethod.POLYGON;
        }
      }
      if (geomMethod === GeometryBoundingBoxMethod.BOUNDINGBOX) {
        boundingBox = this._getStoredGeometryBoundingBox(value);
        if (!boundingBox) {
          geomMethod = GeometryBoundingBoxMethod.POLYGON;
        }
      }

      //
      if (geomMethod === GeometryBoundingBoxMethod.VERTEX) {
        res = this._expandBoundingBoxByVectors(verts, params, parentMatrix, res);
      }
      if (geomMethod === GeometryBoundingBoxMethod.BOUNDINGBOX) {
        const minx = boundingBox.minx;
        const miny = boundingBox.miny;
        const minz = boundingBox.minz;
        const maxx = boundingBox.maxx;
        const maxy = boundingBox.maxy;
        const maxz = boundingBox.maxz;

        res = this._expandBoundingBoxByVector3(minx, miny, minz, params, parentMatrix, res);
        res = this._expandBoundingBoxByVector3(maxx, miny, minz, params, parentMatrix, res);
        res = this._expandBoundingBoxByVector3(minx, maxy, minz, params, parentMatrix, res);
        res = this._expandBoundingBoxByVector3(maxx, maxy, minz, params, parentMatrix, res);
        res = this._expandBoundingBoxByVector3(minx, miny, maxz, params, parentMatrix, res);
        res = this._expandBoundingBoxByVector3(maxx, miny, maxz, params, parentMatrix, res);
        res = this._expandBoundingBoxByVector3(minx, maxy, maxz, params, parentMatrix, res);
        res = this._expandBoundingBoxByVector3(maxx, maxy, maxz, params, parentMatrix, res);
      }
      if (geomMethod === GeometryBoundingBoxMethod.POLYGON && polys) {
        l = polys.length;
        for (i = 0; i < l; ++i) {
          const poly = polys[i];

          if (poly) {
            polyVerts = poly.vertices;
            res = this._expandBoundingBoxByVectors(polyVerts, params, parentMatrix, res);
          }
        }
      }

      return res;
    }

    return res;
  }

  static _calcSizes(result) {
    if (!result) {
      return;
    }
    if (result.width === undefined || result.width === null || result.width < 0) {
      result.width = result.maxx - result.minx;
    }
    if (result.height === undefined || result.height === null || result.height < 0) {
      result.height = result.maxy - result.miny;
    }
    if (result.length === undefined || result.length === null || result.length < 0) {
      result.length = result.maxz - result.minz;
    }
    result.depth = result.length;
  }

  static _resetBoundingBox(bbox) {
    if (!bbox) {
      return;
    }
    bbox.valid = false;
    bbox.width = -1;
    bbox.height = -1;
    bbox.depth = -1;
    bbox.length = -1;
  }

  static expandBoundingBox(value, params, result = null) {
    let res = result;

    res = this._expandBoundingBox(value, params, res);

    this._calcSizes(res);

    return res;
  }

  static getBoundingBox(value, params, result = null) {
    let res = result;

    res = this._resetBoundingBox(res);
    if (res) {
      res.valid = false;
    }
    res = this._expandBoundingBox(value, params, res);
    if (!res) {
      return res;
    }
    this._calcSizes(res);

    return res;
  }
}
