import Event from '../events/Event';

export default class LoaderEvent extends Event {
  constructor(type, loader, data = null) {
    super(type, data);
    this.loader = loader;
  }
}

LoaderEvent.FINISHED = 'finished'; // Loaded successfully or failed
LoaderEvent.COMPLETED = 'completed'; // Loaded successfully
LoaderEvent.ERROR = 'error'; // Failed loading
LoaderEvent.CANCELED = 'canceled'; // Canceled loading
LoaderEvent.PROGRESS = 'progress'; // Loading in progress
