import * as THREE from 'three';
import CanvasUtils from '../../bgr/common/utils/CanvasUtils';
import {
  ScaleMode,
  Align,
  DEFAULT_SCALEMODE,
  DEFAULT_ALIGN_X,
  DEFAULT_ALIGN_Y,
  getAlignValue,
  getScaleModeValue
} from '../utils/viewlayout';

function colorStringToInt(v) {
  if (!v) {
    return null;
  }
  const len = v.length;
  let res = v;
  const _3 = 3;
  const HEX = 16;

  if (v.charAt(0) === '#') { // # prefix
    res = v.substring(1, len);
    res = parseInt(res, HEX);
  } else if (len > 1 && v.charAt(0) === '0' && v.charAt(1) === 'x') { // 0x prefix
    res = v.substring(2, len);
    res = parseInt(res, HEX);
  } else if (v.toLowerCase().substring(0, _3) === 'rgb') {
    const idx = v.indexOf('(');

    if (idx < 0) {
      return null;
    }
    let lIdx = v.indexOf(')', idx);

    if (lIdx < 0) {
      lIdx = len;
    }

    let values = v.substring(idx + 1, lIdx);

    while (values.indexOf(' ', 0) >= 0) {
      values = values.replace(' ', '');
    }
    const parts = values.split(',');
    const numParts = parts.length;
    let firstPart = numParts > 0 ? parts[0] : null;

    if (firstPart && firstPart.length > 0 && firstPart.charAt(0) === '#') {
      firstPart = firstPart.substring(1, firstPart.length);

      return parseInt(firstPart, HEX);
    }
    const R = numParts > 0 ? parseInt(parts[0], 10) : 0;
    const G = numParts > 1 ? parseInt(parts[1], 10) : 0;
    const B = numParts > 2 ? parseInt(parts[2], 10) : 0;
    const RSHIFT = 16;
    const GSHIFT = 8;

    res = (R << RSHIFT) | (G << GSHIFT) | B;
  }

  return res;
}

function isElem(elem, type) {
  if (!elem || !type) {
    return false;
  }
  const nn = elem.nodeName;

  if (!nn) {
    return false;
  }

  return nn.toLowerCase() === type.toLowerCase();
}

function isImage(img) {
  if (!img) {
    return false;
  }
  if (typeof (Image) !== 'undefined' && img instanceof Image) {
    return true;
  }

  return isElem(img, 'img');
}

function isCanvas(cvs) {
  if (!cvs) {
    return false;
  }

  return isElem(cvs, 'canvas') && cvs.getContext;
}

function updateMatrixWorldRecurse(obj) {
  if (!obj || !(obj instanceof THREE.Object3D)) {
    return;
  }
  updateMatrixWorldRecurse(obj.parent);
  if (obj.matrixAutoUpdate) {
    obj.updateMatrix();
  }
  obj.updateMatrixWorld();
}


class BackgroundContentRenderer {
  render(renderer, scene, camera, target) {
    return;
  }

  setContent(v, params) {
    return;
  }

  activate() {
    return;
  }

  deactivate() {
    return;
  }

  dispose() {
    return;
  }
}

class BackgroundColorRenderer extends BackgroundContentRenderer {
  setContent(v, params) {
    let res = null;

    if (v instanceof THREE.Color) {
      res = v;
    } else if (typeof (v) === 'string') {
      res = colorStringToInt(v);
    } else if (typeof (v) === 'number') {
      res = v;
    }
    this._color = res;
  }

  render(renderer, scene, camera, target) {
    if (!renderer) {
      return;
    }
    const oldColorObj = renderer.getClearColor();
    let oldColor = 0;
    const oldAlpha = renderer.getClearAlpha();

    if (oldColorObj) {
      oldColor = oldColorObj.getHex();
    }
    renderer.setRenderTarget(target);
    renderer.setClearColor(this._color, 1.0);
    renderer.clear();
    renderer.setClearColor(oldColor, oldAlpha);
  }
}

/* eslint-disable */
// const scaleModeAliasMap = {
//   fill: ScaleMode.SCALEMODE_FILL,
//   cover: ScaleMode.SCALEMODE_FILL,
//   fit: ScaleMode.SCALEMODE_FIT,
//   contain: ScaleMode.SCALEMODE_FIT,
//   stretch: ScaleMode.SCALEMODE_STRETCH,
// };
// {
//   function capitalize(s) {
//     const first = s.charAt(0);
//     const rest = s.substring(1, s.length);
//     return first.toUpperCase() + rest;
//   }
//   // Aliases for fit-x and fit-y
//   const map = scaleModeAliasMap;
//   const directions = ['h', 'v'];
//   const scaleModes = [ScaleMode.SCALEMODE_FIT_H, ScaleMode.SCALEMODE_FIT_V];
//   const dirAliases = {
//     h: ['h', 'horizontal', 'x'],
//     v: ['v', 'vertical', 'y']
//   };
//   const names = ['contain','fit'];
//   for (let i = 0, numDirs = directions.length; i < numDirs; ++i) {
//     const dir = directions[i];
//     const scaleMode = scaleModes[i];
//     const aliases = dirAliases[dir];
//     for (let j = 0, numNames = names.length; j < numNames; ++j) {
//       const name = names[j];
//       for (let k = 0, numAliases = aliases.length; k < numAliases; ++k) {
//         const alias = aliases[j];
//         map[`${name}-${alias}`] = scaleMode;
//         map[`${name}_${alias}`] = scaleMode;
//         map[`${name.toLowerCase()}${capitalize(alias)}`] = scaleMode;
//         map[`${alias}-${name}`] = scaleMode;
//         map[`${alias}_${name}`] = scaleMode;
//         map[`${alias.toLowerCase()}${capitalize(name)}`] = scaleMode;
//       }
//     }
//   }
// }
/* eslint-enable */

class BackgroundTextureRenderer extends BackgroundContentRenderer {
  _setupRenderMaterial(renderer, scene, camera, mat, renderTarget) {
    if (!mat.uniforms.scale) {
      mat.uniforms.scale = new THREE.Uniform();
    }
    if (!mat.uniforms.translate) {
      mat.uniforms.translate = new THREE.Uniform();
    }
    if (!mat.uniforms.texture) {
      mat.uniforms.texture = new THREE.Uniform();
    }
    if (!mat.uniforms.clipBackground) {
      mat.uniforms.clipBackground = new THREE.Uniform(1);
    }
    if (!mat.uniforms.backgroundColor) {
      mat.uniforms.backgroundColor = new THREE.Uniform();
    }

    if (!mat.uniforms.scale.value) {
      mat.uniforms.scale.value = new THREE.Vector2(1.0, 1.0);
    }
    if (!mat.uniforms.translate.value) {
      mat.uniforms.translate.value = new THREE.Vector2(0.0, 0.0);
    }
    if (!mat.uniforms.backgroundColor.value) {
      mat.uniforms.backgroundColor.value = new THREE.Vector4(1, 1, 1, 1);
    }

    const scaleVec = mat.uniforms.scale.value;
    const translateVec = mat.uniforms.translate.value;
    const bgColorVec = mat.uniforms.backgroundColor.value;

    const bgColor = this.getBackgroundSetting('color');

    if (bgColor) {
      const MASK = 0xFF;
      const A_SHIFT = 24;
      const R_SHIFT = 16;
      const G_SHIFT = 8;
      const B_SHIFT = 0;

      const A = ((bgColor >> A_SHIFT) & MASK) / MASK;
      const R = ((bgColor >> R_SHIFT) & MASK) / MASK;
      const G = ((bgColor >> G_SHIFT) & MASK) / MASK;
      const B = ((bgColor >> B_SHIFT) & MASK) / MASK;

      bgColorVec.set(R, G, B, A);
    } else {
      bgColorVec.set(0, 0, 0, 0);
    }

    const domElem = renderer.domElement;
    let width = domElem.width;
    let height = domElem.height;

    if (renderTarget && renderTarget instanceof THREE.WebGLRenderTarget) {
      width = renderTarget.width;
      height = renderTarget.height;
    }
    const texW = this._textureWidth;
    const texH = this._textureHeight;
    const sm = getScaleModeValue(this.getScaleMode());
    // (smParam && smParam.toLowerCase && scaleModeAliasMap[smParam.toLowerCase()]) || smParam;

    let tX = 0, tY = 0;
    let scX = 1, scY = 1;
    const invWidth = width === 0 ? 0 : 1 / width;
    const invHeight = height === 0 ? 0 : 1 / height;

    const keepAspectRatio = sm === ScaleMode.SCALEMODE_FILL || sm === ScaleMode.SCALEMODE_FIT || sm === ScaleMode.SCALEMODE_FIT_V || sm === ScaleMode.SCALEMODE_FIT_H;

    if (keepAspectRatio) {
      let scaleX = width / texW;
      let scaleY = height / texH;
      let scale;

      let xpos = 0;
      let ypos = 0;

      const alignX = getAlignValue(this.getBackgroundSetting('alignX', DEFAULT_ALIGN_X), DEFAULT_ALIGN_X);
      const alignY = getAlignValue(this.getBackgroundSetting('alignY', DEFAULT_ALIGN_Y), DEFAULT_ALIGN_Y);

      if (sm === ScaleMode.SCALEMODE_FIT) {
        scale = scaleX < scaleY ? scaleX : scaleY;
      } else if (sm === ScaleMode.SCALEMODE_FILL) {
        scale = scaleX > scaleY ? scaleX : scaleY;
      } else if (sm === ScaleMode.SCALEMODE_FIT_H) {
        scale = scaleX;
      } else if (sm === ScaleMode.SCALEMODE_FIT_V) {
        scale = scaleY;
      }

      const maxScale = this.getBackgroundSetting('maxScale', -1);
      const minScale = this.getBackgroundSetting('minScale', -1);

      if (maxScale > 0) {
        scale = scale > maxScale ? maxScale : scale;
      }
      if (minScale >= 0) {
        scale = scale < minScale ? minScale : scale;
      }

      scaleX = scale;
      scaleY = scale;

      const newWidth = texW * scaleX;
      const newHeight = texH * scaleY;

      xpos = width * alignX - newWidth * alignX;
      ypos = height * alignY - newHeight * alignY;

      tX = (xpos * invWidth);
      tY = (ypos * invHeight);

      scX = (newWidth * invWidth);
      scY = (newHeight * invHeight);
    }

    let clipBg = this.getBackgroundSetting('clipBackground', 1);

    if (clipBg === true || clipBg === false) {
      clipBg = clipBg ? 1 : 0;
    }
    mat.uniforms.clipBackground.value = clipBg;

    translateVec.x = tX;
    translateVec.y = tY;

    scaleVec.x = scX;
    scaleVec.y = scY;
  }

  render(renderer, scene, camera, target) {
    if (!renderer) {
      return;
    }
    const v = this._texture;

    if (!(v instanceof THREE.Texture)) {
      return;
    }
    const sc = this.getScene();
    const cam = this.getCamera(camera);
    const mat = this.getMaterial();

    if (!mat.uniforms) {
      mat.uniforms = {};
    }

    this._setupRenderMaterial(renderer, scene, cam, mat, target);

    renderer.render(sc, cam, target);
  }
  setContent(v, params) {
    if (this._texture === v && !params) {
      return;
    }
    let texW = 0;
    let texH = 0;

    this._texture = v;

    if (v.image) {
      texW = v.image.width;
      texH = v.image.height;
    }

    this._textureWidth = texW;
    this._textureHeight = texH;
    this._textureAspectRatio = texH === 0 ? 0 : texW / texH;

    const m = this.getMaterial();

    this.setBackgroundSettings(params);

    if (!m.uniforms) {
      m.uniforms = {};
    }
    if (!m.uniforms.texture) {
      m.uniforms.texture = new THREE.Uniform();
    }
    m.uniforms.texture.value = v;

  }

  setTextureSize(w, h) {
    this._textureWidth = w;
    this._textureHeight = h;
  }

  getTextureWidth() {
    return this._textureWidth;
  }

  getTextureHeight() {
    return this._textureHeight;
  }

  setGeometry(pg) {
    this._planeGeometry = pg;
  }

  getGeometry() {
    const pg = this._planeGeometry;

    if (pg) {
      return pg;
    }

    return BackgroundTextureRenderer.getPlaneGeometry(true);
  }

  getMesh() {
    let m = this._mesh;

    if (!m) {
      m = this._mesh = new THREE.Mesh();
      m.geometry = this.getGeometry();
      m.frustumCulled = false;
      m.material = this.getMaterial();
    }

    return m;
  }

  getScene() {
    let sc = this._scene;

    if (!sc) {
      sc = this._scene = new THREE.Scene();
      sc.add(this.getMesh());
    }

    return sc;
  }

  getCamera() {
    let c = this._camera;

    if (!c) {
      c = this._camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);
    }

    return c;
  }

  _createMaterial() {
    return new THREE.RawShaderMaterial({
      transparent: true,
      vertexShader: [
        'precision mediump float;',
        'attribute vec3 position;',
        'varying vec2 v_uv;',
        'uniform vec2 scale;',
        'uniform vec2 translate;',
        'void main() {',
        ' vec4 pos = vec4(position, 1.0);',
        // ' vec2 uv = 0.5 * (pos.xy * scale + translate + vec2(1.0));',
        ' vec2 uv = 0.5 * (pos.xy + vec2(1.0));',
        ' uv = (uv - translate) / scale;',
        ' v_uv = uv;',
        ' pos.z = 1.0;',
        ' gl_Position = pos;',
        '}'
      ].join('\n'),
      fragmentShader: [
        'precision mediump float;',
        'uniform sampler2D texture;',
        'uniform float clipBackground;',
        'uniform vec4 backgroundColor;',
        'varying vec2 v_uv;',

        'void main() {',
        ' vec4 bgColor = backgroundColor;',
        ' vec2 uv = v_uv;',
        ' vec2 uvR = uv;', // repeating uv
        // ' uvR.x = fract(fract(uvR.x) + 1.0);',
        // ' uvR.y = fract(fract(uvR.y) + 1.0);',
        ' vec4 pix = texture2D(texture, uvR);',
        ' float a = 1.0;',
        // mask border
        ' float bm = 1.0;',
        ' bm *= step(0.0, uv.x);',
        ' bm *= 1.0 - step(1.0, uv.x);',
        ' bm *= step(0.0, uv.y);',
        ' bm *= 1.0 - step(1.0, uv.y);',

        ' a *= mix(1.0, bm, clipBackground);',
        ' a *= pix.a;',

        // fix premultiplied color
        // ' pix /= a;',
        // ' vec3 bgcol = texture2D(texture, vec2(0.0)).xyz;',
        // ' pix.rgb = bgcol + (pix.rgb - bgcol) / clamp(pix.a, 0.001, 1.0);',
        ' pix = mix(bgColor, pix, a);',
        ' gl_FragColor = pix;',
        '}'
      ].join('\n')
    });
  }

  getBackgroundSettings() {
    return this._backgroundSettings;
  }

  setBackgroundSettings(v) {
    this._backgroundSettings = v;
  }

  getBackgroundSetting(name, fallback = null) {
    if (!name) {
      return fallback;
    }
    const bgSettings = this.getBackgroundSettings();

    if (!bgSettings) {
      return fallback;
    }

    const res = bgSettings[name];

    if (typeof (res) === 'undefined' || res === null) {
      return fallback;
    }

    return res;
  }

  getScaleMode() {
    return this.getBackgroundSetting('scaleMode', DEFAULT_SCALEMODE);
  }

  getMaterial() {
    let m = this._material;

    if (!m) {
      m = this._material = this._createMaterial();
    }

    return m;
  }

  static getPlaneGeometry(create = false) {
    let res = this._planeGeometry;

    if (res || !create) {
      return res;
    }
    res = this._planeGeometry = new THREE.PlaneBufferGeometry(2, 2);

    return res;
  }

  static dispose() {
    const pg = this.getPlaneGeometry(false);

    if (pg) {
      pg.dispose();
    }
  }
  dispose() {
    const m = this._material;

    if (m) {
      m.dispose();
    }

    const pg = this.getPlaneGeometry();

    if (pg === BackgroundTextureRenderer.getPlaneGeometry(false)) {
      pg.dispose();
    }

    this._material = null;
  }
}

class BackgroundCubeTextureRenderer extends BackgroundTextureRenderer {
  render(renderer, scene, camera, target) {
    updateMatrixWorldRecurse(camera);
    super.render(renderer, scene, camera, target);
    // clear the depth buffer
    renderer.clear(false, true, true);
  }
  _setupRenderMaterial(renderer, scene, camera, mat) {
    mat.depthWrite = false;
  }

  getCamera(cam) {
    return cam;
  }

  _createMaterial() {
    return new THREE.RawShaderMaterial({
      vertexShader: [
        'precision mediump float;',
        'attribute vec3 position;',
        'varying vec3 v_position;',
        'uniform mat4 projectionMatrix;',
        'uniform mat4 modelViewMatrix;',

        'void main() {',
        ' vec4 pos = vec4(position, 1.0);',
        ' v_position = pos.xyz;',
        ' pos.xyz = (modelViewMatrix * vec4(pos.xyz, 0.0)).xyz;',
        ' pos = projectionMatrix * pos;',
        ' gl_Position = pos;',
        '}'
      ].join('\n'),
      fragmentShader: [
        'precision mediump float;',
        'uniform samplerCube texture;',
        'uniform vec4 backgroundColor;',
        'varying vec3 v_position;',

        'void main() {',
        ' vec4 pix = textureCube(texture, v_position);',
        ' gl_FragColor = pix;',
        '}'
      ].join('\n')
    });
  }

  setGeometry(pg) {
    this._boxGeometry = pg;
  }

  getGeometry() {
    const pg = this._boxGeometry;

    if (pg) {
      return pg;
    }

    return BackgroundCubeTextureRenderer.getBoxGeometry(true);
  }

  setContent(v, params) {
    if (this._texture === v && !params) {
      return;
    }
    this._texture = v;

    const m = this.getMaterial();

    this.setBackgroundSettings(params);

    if (!m.uniforms) {
      m.uniforms = {};
    }
    if (!m.uniforms.texture) {
      m.uniforms.texture = new THREE.Uniform();
    }
    m.uniforms.texture.value = v;
  }

  static getBoxGeometry(create = true) {
    let bg = this._boxGeometry;

    if (bg || !create) {
      return bg;
    }

    bg = this._boxGeometry = new THREE.BoxBufferGeometry(100, 100, 100);
    const indexBuff = bg.getIndex();
    const indexArr = indexBuff.array;
    const step = 3;
    const numIndices = indexArr.length;

    for (let i = 0; i < numIndices; i += step) {
      const i1 = indexArr[i];
      const i2 = indexArr[i + 1];
      const i3 = indexArr[i + 2];

      indexArr[i] = i1;
      indexArr[i + 1] = i3;
      indexArr[i + 2] = i2;
    }

    return bg;
  }

  static dispose() {
    const bg = this.getBoxGeometry(false);

    if (bg) {
      bg.dispose();
    }
  }
}

export default class BackgroundRenderer {
  constructor(renderer) {
    this._renderer = renderer;
    this._background = null;

    this._contentRenderers = {
      color: new BackgroundColorRenderer(),
      texture: new BackgroundTextureRenderer(),
      cubetexture: new BackgroundCubeTextureRenderer()
    };
  }

  _getBackgroundContentRenderer(type) {
    return this._contentRenderers[type];
  }

  getBackground() {
    return this._background;
  }

  setBackground(value, params) {
    let v = value;

    if (v === this._background && !params) {
      return;
    }
    this._background = v;
    const t = typeof (v);
    let cr = null;

    if (isImage(v) || isCanvas(v)) {
      let resizeParams = null;

      if (params && params.maxSize) {
        resizeParams = {maxSize: params.maxSize};
      }
      v = CanvasUtils.resizeToPO2(v, resizeParams);
      v = new THREE.Texture(v);
      v.needsUpdate = true;
    }

    if (v instanceof THREE.CubeTexture) {
      cr = this._getBackgroundContentRenderer('cubetexture');
      cr.setContent(v, params);
    } else if (v instanceof THREE.Texture) {
      cr = this._getBackgroundContentRenderer('texture');
      cr.setContent(v, params);
    } else if ((t === 'string') || (t === 'number') || (v instanceof THREE.Color)) {
      cr = this._getBackgroundContentRenderer('color');
      cr.setContent(v, params);
    }
    if (this._contentRenderer !== cr) {
      if (this._contentRenderer) {
        this._contentRenderer.deactivate();
      }
      this._contentRenderer = cr;
      if (this._contentRenderer) {
        this._contentRenderer.activate();
      }
    }
  }

  get background() {
    return this.getBackground();
  }

  set background(v) {
    this.setBackground(v);
  }

  getRenderer() {
    return this._renderer;
  }

  setRenderer(v) {
    this._renderer = v;
  }

  get renderer() {
    return this.getRenderer();
  }

  set renderer(v) {
    this.setRenderer(v);
  }

  render(renderer = null, scene, camera, target = null) {
    const cr = this._contentRenderer;

    if (!cr) {
      return;
    }
    let r = renderer;

    if (!r) {
      r = this.getRenderer();
    }
    cr.render(r, scene, camera, target);
  }
}
