import MattressConfigProperty from './MattressConfigProperty';
// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
// #endif
import MattressConfigDA from '../mattress/MattressConfigDA';
import MattressDA from '../mattress/MattressDA';

function newProperty(name, getter, setter) {
  return new MattressConfigProperty(name, getter, setter);
}

const DEFAULT_SINGLE = 0;

function getPropertySingle(property) {
  if (!property) {
    return DEFAULT_SINGLE;
  }
  const s = property.single;

  if (s === null) {
    return DEFAULT_SINGLE;
  }
  const t = typeof (s);

  if (t === 'string') {
    return s;
  }

  if (t === 'number') {
    if (isNaN(s)) {
      return DEFAULT_SINGLE;
    }

    return s;
  }

  return DEFAULT_SINGLE;
}

function getPropertyBorder(property) {
  if (!property) {
    return null;
  }
  const single = getPropertySingle(property);
  const singleObject = MattressConfigDA.getSingle(single);

  if (!singleObject) {
    return null;
  }

  let borderObject = null;

  borderObject = MattressDA.getBorderComponent(single, property.border);
  if (!borderObject) {
    borderObject = MattressDA.getBorderComponent(single, property.border.borderId);
  }
  if (!borderObject) {
    borderObject = MattressDA.getBorderComponent(single, property.border.borderID);
  }
  if (!borderObject) {
    borderObject = MattressDA.getBorderComponent(single, property.border.borderIndex);
  }

  return borderObject;
}

const mattressConfigProperties = [
  newProperty({name: 'controller_property',
    get(mcfg, property, fallback) {
      return mcfg.getControllerProperty(property.controllerName, property.controllerPropertyName, fallback);
    },
    set(mcfg, property, value) {
      mcfg.setControllerProperty(property.controllerName, property.controllerPropertyName, value);
    }
  }),

  newProperty({name: 'single_width',
    get(mcfg, property, fallback) {
      return mcfg.getWidth(getPropertySingle(property));
    },
    set(mcfg, property, value) {
      return mcfg.setWidth(getPropertySingle(property), value);
    }
  }),

  newProperty({name: 'single_length',
    get(mcfg, property, fallback) {
      return mcfg.getLength(getPropertySingle(property));
    },
    set(mcfg, property, value) {
      return mcfg.setLength(getPropertySingle(property), value);
    }
  }),

  newProperty({name: 'single_topheight',
    get(mcfg, property, fallback) {
      return mcfg.getTopHeight(getPropertySingle(property));
    },
    set(mcfg, property, value) {
      return mcfg.setTopHeight(getPropertySingle(property), value);
    }
  }),
  newProperty({name: 'single_bottomheight',
    get(mcfg, property, fallback) {
      return mcfg.getBottomHeight(getPropertySingle(property));
    },
    set(mcfg, property, value) {
      return mcfg.setBottomHeight(getPropertySingle(property), value);
    }
  }),
  newProperty({name: 'single_topradius',
    get(mcfg, property, fallback) {
      return mcfg.getTopBorderRadius(getPropertySingle(property));
    },
    set(mcfg, property, value) {
      return mcfg.setTopBorderRadius(getPropertySingle(property), value);
    }
  }),
  newProperty({name: 'single_bottomradius',
    get(mcfg, property, fallback) {
      return mcfg.getBottomBorderRadius(getPropertySingle(property));
    },
    set(mcfg, property, value) {
      return mcfg.setBottomBorderRadius(getPropertySingle(property), value);
    }
  }),
  newProperty({name: 'single_cornerradius',
    get(mcfg, property, fallback) {
      return mcfg.getCornerRadius(getPropertySingle(property));
    },
    set(mcfg, property, value) {
      return mcfg.setCornerRadius(getPropertySingle(property), value);
    }
  }),

  // Top & bottom
  newProperty({name: 'top_sample',
    get(mcfg, property, fallback) {
      return mcfg.getTopSample(getPropertySingle(property), fallback);
    },
    set(mcfg, property, value) {
      return mcfg.setTopSample(getPropertySingle(property), value);
    }
  }),
  newProperty({name: 'top_sample_xalign',
    get(mcfg, property, fallback) {
      return mcfg.getTopSampleAlignX(getPropertySingle(property), fallback);
    },
    set(mcfg, property, value) {
      mcfg.setTopSampleAlignX(getPropertySingle(property), value);
    }
  }),
  newProperty({name: 'top_sample_yalign ',
    get(mcfg, property, fallback) {
      return mcfg.getTopSampleAlignY(getPropertySingle(property), fallback);
    },
    set(mcfg, property, value) {
      mcfg.setTopSampleAlignY(getPropertySingle(property), value);
    }
  }),
  newProperty({name: 'top_sample_rotation'}),
  newProperty({name: 'top_quilt'}),
  newProperty({name: 'top_quilt_xoffset'}),
  newProperty({name: 'top_quilt_yoffset'}),
  newProperty({name: 'top_quilt_angle'}),
  newProperty({name: 'top_quilt_repeattype'}),
  newProperty({name: 'top_quilt_xrepeat'}),
  newProperty({name: 'top_quilt_yrepeat'}),
  newProperty({name: 'top_quilt_foam'}),
  newProperty({name: 'top_quilt_softness'}),
  newProperty({name: 'top_quilt_thickness'}),

  newProperty({name: 'bottom_sample'}),
  newProperty({name: 'bottom_sample_xalign',
    get(mcfg, property, fallback) {
      return mcfg.getBottomSampleAlignX(getPropertySingle(property), fallback);
    },
    set(mcfg, property, value) {
      mcfg.setBottomSampleAlignX(getPropertySingle(property), value);
    }
  }),
  newProperty({name: 'bottom_sample_yalign',
    get(mcfg, property, fallback) {
      return mcfg.getBottomSampleAlignY(getPropertySingle(property), fallback);
    },
    set(mcfg, property, value) {
      mcfg.setBottomSampleAlignY(getPropertySingle(property), value);
    }
  }),
  newProperty({name: 'bottom_sample_rotation'}),
  newProperty({name: 'bottom_quilt'}),
  newProperty({name: 'bottom_quilt_xoffset'}),
  newProperty({name: 'bottom_quilt_yoffset'}),
  newProperty({name: 'bottom_quilt_rotation'}),
  newProperty({name: 'bottom_quilt_repeattype'}),
  newProperty({name: 'bottom_quilt_xrepeat'}),
  newProperty({name: 'bottom_quilt_yrepeat'}),
  newProperty({name: 'bottom_quilt_foam'}),
  newProperty({name: 'bottom_quilt_softness'}),
  newProperty({name: 'bottom_quilt_thickness'}),

  newProperty({name: 'border_sample',
    get(mcfg, property, fallback) {
      const single = getPropertySingle(property);
      const border = getPropertyBorder(property);

      return mcfg.getBorderComponentSample(single, border, fallback);
    },
    set(mcfg, property, value) {
      const single = getPropertySingle(property);
      const border = getPropertyBorder(property);

      mcfg.setBorderComponentSample(single, border, value);
    }
  }),
  newProperty({name: 'border_sample_xalign',
    get(mcfg, property, fallback) {
      const single = getPropertySingle(property);
      const border = getPropertyBorder(property);

      return mcfg.getBorderComponentTextureAlignX(single, border, fallback);
    },
    set(mcfg, property, value) {
      const single = getPropertySingle(property);
      const border = getPropertyBorder(property);

      mcfg.setBorderComponentTextureAlignX(single, border, value);
    }
  }),
  newProperty({name: 'border_sample_yalign',
    get(mcfg, property, fallback) {
      const single = getPropertySingle(property);
      const border = getPropertyBorder(property);

      return mcfg.getBorderComponentTextureAlignY(single, border, fallback);
    },
    set(mcfg, property, value) {
      const single = getPropertySingle(property);
      const border = getPropertyBorder(property);

      mcfg.setBorderComponentTextureAlignY(single, border, value);
    }
  }),
  newProperty({name: 'border_sample_rotation'}),

  newProperty({name: ''})
];

const mattressConfigPropertiesMap = {};

{
  const num = mattressConfigProperties.length;

  for (let i = 0; i < num; ++i) {
    const pc = mattressConfigProperties[i];
    const name = (pc && pc.getName) ? pc.getName() : null;

    if (name) {
      mattressConfigPropertiesMap[name.toLowerCase()] = pc;
    }
  }
}

export default class MattressConfigPropertyUtil {
  static _getPropertyName(property) {
    if (!property) {
      return property;
    }
    const t = typeof (property);

    if (t === 'object') {
      return property.name || property.id || property.propertyName;
    } else if (t === 'string') {
      return property;
    }

    return null;
  }

  static _getPropertyObject(property) {
    if (!property || !mattressConfigPropertiesMap) {
      return null;
    }
    const name = this._getPropertyName(property);

    return mattressConfigPropertiesMap[name];
  }

  static getProperty(mattressConfig, property, fallback = null) {
    if (!mattressConfig || !property) {
      return fallback;
    }
    const pc = this._getPropertyObject(property);

    if (!pc) {
      // #if DEBUG
      BD3DLogger.warn('Can\'t get property ', property);
      // #endif

      return fallback;
    }

    return pc.getProperty(mattressConfig, property, fallback);
  }

  static setProperty(mattressConfig, property, value) {
    if (!mattressConfig || !property) {
      return;
    }
    const pc = this._getPropertyObject(property);

    if (!pc) {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set property ', property);
      // #endif

      return;
    }
    pc.setProperty(mattressConfig, property, value);
  }
}
