import Asset from './Asset';
import AssetGroup from './AssetGroup';
import ImageAsset from './ImageAsset';
import MattressDA from '../mattress/MattressDA';
import SampleDA from '../sample/SampleDA';
import {PIXELS_TO_CM} from '../utils/units';

const FALLBACK_TEXTURE_WIDTH = 1000;
const FALLBACK_TEXTURE_HEIGHT = 1000;

// Convert from pixels to cm (96 dpi)
const FALLBACK_TEXTURE_SCALE = PIXELS_TO_CM;

function addImageAsset(sampleAsset, images, v) {
  const img = images[v];
  let imgAsset = null;

  if (img instanceof ImageAsset) {
    imgAsset = img;
  } else if (img instanceof Image) {
    imgAsset = new ImageAsset(v);

    imgAsset.setImage(img);
  }
  if (imgAsset) {
    if (!sampleAsset.assets) {
      sampleAsset.assets = new AssetGroup();
    }
    sampleAsset.assets.addAsset(imgAsset, v);
  }
}

export default class SampleAsset extends Asset {
  constructor(id, name, sampleConfigData = null, params = null) {
    super(name, 'sample', null);
    this.id = id;
    this.params = params;

    this.sampleConfigData = sampleConfigData;
    this.assets = null;
    this.sampleData = null;
  }


  getSampleID() {
    let res = this.id;

    if (res === null || typeof (res) === 'undefined') {
      res = MattressDA.getSampleID(this.sampleConfigData);
    }

    return res;
  }

  getSampleConfigData() {
    return this.sampleConfigData;
  }

  getSampleData() {
    return this.sampleData;
  }

  getTexture() {
    return this.getAsset('texture');
  }

  getNormalMap() {
    return this.getAsset('normal');
  }

  getSpecularMap() {
    return this.getAsset('specular');
  }

  getFallbackWidth() {
    return this.getTextureWidth(FALLBACK_TEXTURE_WIDTH) * FALLBACK_TEXTURE_SCALE;
  }

  getFallbackHeight() {
    return this.getTextureHeight(FALLBACK_TEXTURE_HEIGHT) * FALLBACK_TEXTURE_SCALE;
  }

  getTextureWidth(fallback = 0) {
    const tex = this.getTexture();

    if (!tex) {
      return fallback;
    }
    if (tex instanceof ImageAsset) {
      return tex.getWidth(fallback);
    }

    return fallback;
  }

  getTextureHeight(fallback = 0) {
    const tex = this.getTexture();

    if (!tex) {
      return fallback;
    }
    if (tex instanceof ImageAsset) {
      return tex.getHeight(fallback);
    }

    return fallback;
  }

  _getFirstImageAsset() {
    const assets = this.assets;

    if (!assets) {
      return null;
    }
    const num = assets.getNumAssets();

    if (!num) {
      return null;
    }
    for (let i = 0; i < num; ++i) {
      const asset = assets.getAssetAt(i);

      if (asset instanceof ImageAsset) {
        return asset;
      }
    }

    return null;
  }

  getImageWidth() {
    const imgAsset = this._getFirstImageAsset();

    if (!imgAsset) {
      return 0;
    }

    return imgAsset.getWidth();
  }

  getImageHeight() {
    const imgAsset = this._getFirstImageAsset();

    if (!imgAsset) {
      return 0;
    }

    return imgAsset.getHeight();
  }

  getWidth(fallback = 0) {
    const sd = this.getSampleData();
    const scd = this.getSampleConfigData();

    return SampleDA.getRealWidth(scd, sd, fallback);
  }

  getHeight(fallback = 0) {
    const sd = this.getSampleData();
    const scd = this.getSampleConfigData();

    return SampleDA.getRealHeight(scd, sd, fallback);
  }

  getAsset(key) {
    if (!key) {
      return null;
    }
    const assets = this.assets;

    if (!assets) {
      return null;
    }

    return assets.getAsset(key);
  }

  setData(d) {
    super.setData(d);

    if (d) {
      if (this.assets) {
        this.assets.clear();
      }
      this.sampleData = d.data || d.sampleData || d.json;

      const images = d.images;

      if (images) {
        for (const v in images) {
          if (images.hasOwnProperty(v)) {
            addImageAsset(this, images, v);
          }
        }
      }
    }
  }
}
