import BD3DMaterial from './BD3DMaterial';

export default class BD3DHandleLogoMaterial extends BD3DMaterial {
  constructor() {
    super();
    this.imageTexture = null;
    this.fabricTexture = null;
    this.fabricWidth = 4;
    this.fabricHeight = 4;
    this.imageWidth = 4.4;
    this.imageHeight = 4.4;
    this.imageOffsetX = 0;
    this.imageOffsetY = 0;
    this.imageAlignX = 0.5;
    this.imageAlignY = 0.5;
    this.imageLocalAlignX = 0.5;
    this.imageLocalAlignY = 0.5;

    this.objectWidth = 0;
    this.objectHeight = 0;
    this.objectDepth = 0;
  }

  getImageAlignmentX() {
    return this.imageAlignX;
  }

  setImageAlignmentX(value) {
    this.imageAlignX = value;
  }

  getImageAlignmentY() {
    return this.imageAlignY;
  }

  setImageAlignmentY(value) {
    this.imageAlignY = value;
  }

  setImageAlignment(x, y) {
    this.imageAlignX = x;
    this.imageAlignY = y;
  }

  getImageLocalAlignmentX() {
    return this.imageLocalAlignX;
  }

  setImageLocalAlignmentX(value) {
    this.imageLocalAlignX = value;
  }

  getImageLocalAlignmentY() {
    return this.imageLocalAlignY;
  }

  setImageLocalAlignmentY(value) {
    this.imageLocalAlignY = value;
  }

  setImageLocalAlignment(x, y) {
    this.imageLocalAlignX = x;
    this.imageLocalAlignY = y;
  }

  getImageOffsetX() {
    return this.imageOffsetX;
  }

  setImageOffsetX(value) {
    this.imageOffsetX = value;
  }

  getImageOffsetY() {
    return this.imageOffsetY;
  }

  setImageOffsetY(value) {
    this.imageOffsetY = value;
  }

  setImageOffset(x, y) {
    this.imageOffsetX = x;
    this.imageOffsetY = y;
  }

  getFabricTexture() {
    return this.fabricTexture;
  }

  setFabricTexture(t) {
    this.fabricTexture = t;
  }

  setFabricSize(w, h) {
    this.fabricWidth = w;
    this.fabricHeight = h;
  }

  getFabricWidth() {
    return this.fabricWidth;
  }

  setFabricWidth(w) {
    this.fabricWidth = w;
  }

  getFabricHeight() {
    return this.fabricHeight;
  }

  setFabricHeight(h) {
    this.fabricHeight = h;
  }

  getImageTexture() {
    return this.imageTexture;
  }

  setImageTexture(t) {
    this.imageTexture = t;
  }

  setImageSize(x, y) {
    this.imageWidth = x;
    this.imageHeight = y;
  }

  getImageWidth() {
    return this.imageWidth;
  }

  setImageWidth(w) {
    this.imageWidth = w;
  }

  getImageHeight() {
    return this.imageHeight;
  }

  setImageHeight(h) {
    this.imageHeight = h;
  }

  setObjectSize(w, h, d) {
    this.objectWidth = w;
    this.objectHeight = h;
    this.objectDepth = d;
  }
}
