import EventDispatcher from '../../bgr/common/events/EventDispatcher';
import XHRLoader from '../../bgr/common/loading/XHRLoader';
import ImageLoader from '../../bgr/common/loading/ImageLoader';
import LoaderState from '../../bgr/common/loading/LoaderState';
// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
// #endif

function idAsString(id) {
  const t = typeof (id);

  if (t === 'number') {
    if (isNaN(id)) {
      return null;
    }

    return `${id}`;
  }
  if (!id) {
    return null;
  }

  return id;
}

function getQuiltDataOfLoader(ldr) {
  if (!ldr) {
    return null;
  }
  if (!ldr.getResponse) {
    return null;
  }

  const res = ldr.getResponse();
  let json = null;

  if (typeof (res) === 'string') {
    try {
      json = JSON.parse(res);
    } catch (ex) {
      // #if DEBUG
      BD3DLogger.warn('Invalid quilt json ', res);
      // #endif

      json = null;
    }
  } else if (typeof (res) === 'object') {
    json = res;
  }

  return json;
}

export default class QuiltService extends EventDispatcher {
  constructor() {
    super();
    this.onGetQuilt = null;
    this.onGetQuiltURL = null;
    this.onGetQuiltImageURL = null;

    // This piece of code should self-destruct in 14 days
    this._hardCodedQuilts = {
      /* eslint-disable */
      grid_soft_quilt: {
        "id": 'hc_1000',
        "name": "grid_soft_quilt",
        "bumpLocalAsset": true,
        "bump": "quilts/grid_soft_quilt.png",
        "realWidth": 10,
        "realHeight": 10
      },
      tiles01: {
        "id": 'hc_1001',
        "name": "tiles01",
        "bumpLocalAsset": true,
        "bump": "quilts/tiles01.png",
        "realWidth": 26,
        "realHeight": 26
      },
      squares5x5: {
        "id": 'hc_1002',
        "name": "squares5x5",
        "bumpLocalAsset": true,
        "bump": "quilts/squares.png",
        "realWidth": 5,
        "realHeight": 5
      },
      squares5x5x1: {
        "id": 'hc_1003',
        "name": "squares5x5x1",
        "bumpLocalAsset": true,
        "bump": "quilts/squares5x5x1.png",
        "realWidth": 10,
        "realHeight": 10
      },
      squares5x5x2: {
        "id": 'hc_1004',
        "name": "squares5x5x2",
        "bumpLocalAsset": true,
        "bump": "quilts/squares5x5x2.png",
        "realWidth": 5,
        "realHeight": 20
      },
      squares4x4x3: {
        "id": 'hc_1005',
        "name": "squares4x4x3",
        "bumpLocalAsset": true,
        "bump": "quilts/squares4x4x3.png",
        "realWidth": 4,
        "realHeight": 20
      },
      ellipse_seq8: {
        "id": 'hc_1006',
        "name": "ellipse_seq8",
        "bumpLocalAsset": true,
        "bump": "quilts/ellipse_seq8.jpg",
        "realWidth": 11,
        "realHeight": 11
      },
      circle_seq9: {
        "id": 'hc_1007',
        "name": "circle_seq9",
        "bumpLocalAsset": true,
        "bump": "quilts/circle_seq9.jpg",
        "realWidth": 11,
        "realHeight": 20
      },
      headboard01: {
        "id": 'hc_1008',
        "name": "headboard01",
        "bumpLocalAsset": true,
        "bump": "quilts/headboard_quilt_01.png",
        "realWidth": 85,
        "realHeight": 85
      },
      buttons01: {
        "id": 'buttons01',
        "name": "buttons01",
        "bumpLocalAsset": true,
        "bump": "quilts/buttons01.jpg",
        "realWidth": 50,
        "realHeight": 50
      },

      /* eslint-enable */
    };
  }
  _getXHRLoader(create) {
    let res = this._xhrLoader;

    if (res || !create) {
      return res;
    }
    res = this._xhrLoader = new XHRLoader();

    return res;
  }

  _getFinishedHandler() {
    let res = this._finishedHandler;

    if (res) {
      return res;
    }
    const that = this;

    res = this._finishedHandler = evt => {
      return that._handleQuiltDataFinished(evt);
    };

    return res;
  }

  _handleQuiltDataFinished(evt) {
    // #if DEBUG
    const loader = evt.loader;
    const res = loader.getResponse();

    BD3DLogger.log('...', evt);
    BD3DLogger.log('...', res);
    // #endif

    return;
  }

  _resolveImageURL(url, image, imageParams, params) {
    if (!url) {
      return url;
    }
    const qd = params ? params.quiltData : null;

    if (!qd) {
      return url;
    }
    if (!qd.bumpLocalAsset) {
      return url;
    }
    if (this.urlResolver) {
      return this.urlResolver.resolveURL(url, params);
    }

    return url;
  }

  getQuiltImageURL(id, image, imageParams, params) {
    let url = null;
    const escapedID = escape(id);
    const escapedImage = escape(image);

    if (this.onGetQuiltImageURL) {
      url = this.onGetQuiltImageURL(escapedID, escapedImage, imageParams, params);
    }
    if (!url) {
      const qd = params.quiltData;

      if (qd && qd.img && qd.img.src) {
        return this._resolveImageURL(qd.img.src, image, imageParams, params);
      }
      if (qd && qd.bump) {
        return this._resolveImageURL(qd.bump, image, imageParams, params);
      }
      const qcd = params.quiltConfigData;

      if (qcd && qcd.img && qcd.img.src) {
        return this._resolveImageURL(qcd.img.src, image, imageParams, params);
      }
    }
    /*
    if (!url) {
      const quiltParam = 'quiltid=';
      const imageParam = 'image=';
      const base = 'services/quiltservice.php?';
      const amp = '&';

      url = base + quiltParam + escapedID + amp + imageParam + escapedImage;
    }
    */

    return this._resolveImageURL(url, image, imageParams, params);
  }

  getQuiltImageLoader(id, image, imageParams, params, res = null) {
    const url = this.getQuiltImageURL(id, image, imageParams, params);

    if (!url) {
      if (res) {
        res.setSource(url);
      }

      return res;
    }
    let ldr = res;

    if (!ldr || !(ldr instanceof ImageLoader)) {
      ldr = new ImageLoader();
    }
    ldr.crossOrigin = 'Anonymous';

    ldr.setSource(url);

    return ldr;
  }

  _removeCachedQuiltDataLoader(id) {
    const loaders = this._quiltDataLoaders;

    if (!loaders) {
      return;
    }
    loaders[id] = null;
    if (typeof (Reflect) !== 'undefined' && Reflect.deleteProperty) {
      Reflect.deleteProperty(loaders, id);
    }
  }

  _getCachedQuiltDataLoader(quiltID, params, create = true) {
    let id = quiltID;

    if (typeof (id) === 'number') {
      id = `${id}`;
    }
    if (!id) {
      return null;
    }
    let loaders = this._quiltDataLoaders;

    if (!loaders && create) {
      loaders = this._quiltDataLoaders = {};
    }
    if (!loaders) {
      return null;
    }
    let res = loaders[id];

    if (res || !create) {
      return res;
    }
    res = loaders[id] = this.getQuiltDataLoader(id, params);

    return res;
  }

  getQuiltDataLoader(id, quiltConfigData, params, res = null) {
    let xhrLoader = res;

    if (!xhrLoader || !(xhrLoader instanceof XHRLoader)) {
      xhrLoader = new XHRLoader();
    }

    let url = null;
    const escapedID = escape(id);

    if (this.onGetQuiltURL) {
      url = this.onGetQuiltURL(escapedID, params, quiltConfigData);
    }
    /*
    if (!url) {
      const base = 'services/quiltservice.php?quiltid=';

      url = base + escapedID;
    }
    */
    if (!url) {
      return null;
    }
    xhrLoader.setSource(url);

    return xhrLoader;
  }

  getLoaderQuiltData(ldr) {
    return getQuiltDataOfLoader(ldr);
  }

  isHardCoded(quilt) {
    if (!quilt) {
      return false;
    }
    if (typeof (quilt) === 'string') {
      const q = this._getHardCodedQuiltById(quilt);

      return typeof (q) !== 'undefined' && q !== null;
    }

    const map = this._hardCodedQuilts;

    for (const v in map) {
      if (map.hasOwnProperty(v)) {
        const q = map[v];

        if (q === quilt) {
          return true;
        }
      }
    }

    return false;
  }

  _getHardCodedQuiltById(quiltId) {
    return this._getQuiltByIdFromMap(quiltId, this._hardCodedQuilts);
  }

  _getCachedQuiltById(quiltId) {
    const res = this._getQuiltByIdFromMap(quiltId, this._quiltsById);

    if (res) {
      return res;
    }

    return this._getHardCodedQuiltById(quiltId);
  }

  _getQuiltByIdFromMap(quiltId, map) {
    if (!map) {
      return null;
    }
    let id = quiltId;

    if (typeof (id) === 'number') {
      id = `${id}`;
    }
    const t = typeof (id);

    if (t !== 'string' && t !== 'symbol') {
      return null;
    }

    return map[id];
  }

  addQuilt(quiltData, ID) {
    if (!quiltData) {
      return;
    }
    let quiltsById = this._quiltsById;

    if (!quiltsById) {
      quiltsById = this._quiltsById = {};
    }

    const quiltID = idAsString(quiltData.id);
    const quiltName = idAsString(quiltData.name);
    const id = idAsString(ID);

    if (quiltID !== null) {
      quiltsById[quiltID] = quiltData;
    }
    if (quiltName) {
      quiltsById[quiltName] = quiltData;
    }
    if (id) {
      quiltsById[id] = quiltData;
    }
  }

  addQuilts(quilts) {
    if ((quilts instanceof Array) || (Array.isArray && Array.isArray(quilts))) {
      const num = quilts.length;

      if (!num) {
        return;
      }
      for (let i = 0; i < num; ++i) {
        this.addQuilt(quilts[i]);
      }
    } else if (typeof (quilts) === 'object') {
      for (const v in quilts) {
        if (quilts.hasOwnProperty(v)) {
          const quilt = quilts[v];

          this.addQuilt(quilt, v);
        }
      }
    }
  }

  removeQuilt(q) {
    if (!q) {
      return;
    }
    const qbid = this._quiltsById;

    if (!qbid) {
      return;
    }
    if (typeof (q) === 'string') {
      Reflect.deleteProperty(qbid, q);
    } else {
      for (const v in qbid) {
        if (qbid.hasOwnProperty(v)) {
          // if (val && (val === q || valID === idAsString(q.id))) {
          if (this._sameQuilt(qbid[v], q)) {
            Reflect.deleteProperty(qbid, v);
          }
        }
      }
    }
  }

  _sameQuilt(q1, q2) {
    if (q1 === q2) {
      return true;
    }
    if (!q1 || !q2) {
      return false;
    }
    const q1ID = idAsString(q1.id);
    const q2ID = idAsString(q2.id);

    if (q1ID && q2ID && (q1ID === q2ID)) {
      return true;
    }

    const q1N = idAsString(q1.name);
    const q2N = idAsString(q2.name);

    if (q1N && q2N && (q1N === q2N)) {
      return true;
    }

    return false;
  }

  removeQuilts(quilts) {
    if (!quilts) {
      return;
    }
    if (typeof (quilts) !== 'object') {
      return;
    }
    if ((quilts instanceof Array) || (Array.isArray && Array.isArray(quilts))) {
      const l = quilts.length;

      for (let i = 0; i < l; ++i) {
        this.removeQuilt(quilts[i]);
      }

    } else {
      for (const v in quilts) {
        if (quilts.hasOwnProperty(v)) {
          this.removeQuilt(quilts[v]);
        }
      }
    }
  }

  removeAllQuilts() {
    this._quiltsById = null;
  }

  getQuiltById(id) {
    return this._getCachedQuiltById(id);
  }

  getQuiltData(id, quiltConfigData, params, callback = null) {
    let res = this._getCachedQuiltById(id);

    if (res) {
      if (callback) {
        callback(res);

        return res;
      }

      return res;
    }

    if (this.onGetQuilt) {
      const getQuiltRes = this.onGetQuilt(id, quiltConfigData, params, callback);

      if (getQuiltRes) {
        if (getQuiltRes instanceof Promise) {
          // onGetSample returns a promise
          getQuiltRes.then(callback);

          return null;
        }

        // onGetSample returns the sample object
        if (callback) {
          callback(getQuiltRes);

          return getQuiltRes;
        }

        return getQuiltRes;
      }
      if (getQuiltRes !== false) {
        return null;
      }
    }

    res = this._getCachedQuiltById(id);

    if (res) {
      if (callback) {
        callback(res);

        return res;
      }

      return res;
    }

    const loader = this._getCachedQuiltDataLoader(id, params);

    if (!loader) {
      if (callback) {
        callback(null);

        return null;
      }

      return null;
    }

    // loader.once('finished', this._getFinishedHandler());

    if (callback) {
      const that = this;
      const handler = evt => {
        const ldr = evt.loader;
        const json = getQuiltDataOfLoader(ldr);

        that._removeCachedQuiltDataLoader(id);

        return callback(json);
      };

      loader.once('finished', handler);
    }
    if (loader.getState() !== LoaderState.PROGRESS) {
      loader.start();
    }

    return null;
  }
}
