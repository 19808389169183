const SEPARATOR = '/';

function strTrim(str) {
  if (!str) {
    return str;
  }
  const LAST_WS_INDEX = 32;

  if (str.charCodeAt(0) > LAST_WS_INDEX && str.charCodeAt(str.length - 1) > LAST_WS_INDEX) {

    return str;
  }
  if (str.trim) {

    return str.trim();
  }

  return str;
}


export default class URLUtils {
  static _join2URLArrays(part1, part2) {
    let p1 = part1;
    let p2 = part2;

    const separator = SEPARATOR;

    let protocolPrefix = null;
    let len;

    if (typeof (p1) === 'string') {
      p1 = strTrim(p1);
      let dsIdx = p1.indexOf('//'); // double slash index

      if (dsIdx >= 0) {
        dsIdx += 2;
        protocolPrefix = p1.substring(0, dsIdx);
        p1 = p1.substring(dsIdx, p1.length);
      }
      len = p1.length - 1;
      if (p1.charAt(len) === separator) {
        p1 = p1.substring(0, len);
      }
      p1 = p1.split(separator);
    }
    if (typeof (p2) === 'string') {
      p2 = strTrim(p2);
      if (p2.charAt(0) === separator) {
        p2 = p2.substring(1, p2.length);
      }
      p2 = p2.split(separator);
    }
    len = p2.length;
    let index = p1.length;

    for (let i = 0; i < len; ++i) {
      const part = p2[i];

      if (part !== '.') {
        if (part === '..') {
          --index;
        } else {
          p1[index] = part;
          ++index;
        }
      }
    }
    p1.length = index;

    let res = p1.join(separator);

    if (protocolPrefix) {
      res = protocolPrefix + res;
    }

    return res;
  }

  static join2URLParts(part1, part2) {
    if (!part1 && !part2) {
      return null;
    }
    if (!part1) {
      return part2;
    }
    if (!part2) {
      return part1;
    }
    let p1 = strTrim(part1);
    const p2 = strTrim(part2);

    if (p2.indexOf('./', 0) >= 0) {
      return this._join2URLArrays(p1, p2);
    }

    const p1lastIndex = p1.length - 1;

    const sep = SEPARATOR;
    const c1 = p1.charAt(p1lastIndex);
    const c2 = p2.charAt(0);

    if (c1 === sep && c2 === sep) {
      p1 = p1.substring(0, p1lastIndex);
    } else if (c1 !== sep && c2 !== sep) {
      p1 = p1 + sep;
    }

    return p1 + p2;
  }

  static joinURLParts(parts) {
    if (!parts) {
      return null;
    }
    const num = parts.length;

    if (num === 0) {
      return null;
    }

    let res = null;

    for (let i = 0; i < num; ++i) {
      const part = parts[i];

      if (res) {
        res = this.join2URLParts(res, part);
      } else {
        res = part;
      }
    }

    return res;
  }
  static joinURL(...args) {
    return this.joinURLParts(args);
  }
}
