const DEFAULT = 'default';
const EN = 'en';

export default class ConfigLocale {
  constructor(data) {
    this.data = data;
  }

  dispose() {
    this.data = null;
  }

  setData(d) {
    this.data = d;
  }

  getTranslations() {
    const {data} = this;

    if (!data) {
      return null;
    }

    return data.translations || null;
  }

  getDefaultLanguage() {
    const translations = this.getTranslations();

    if (!translations) {
      return null;
    }
    if (translations[DEFAULT]) {
      return DEFAULT;
    }
    if (translations[EN]) {
      return EN;
    }

    return null;
  }

  getTranslation(key, language = null) {
    const translations = this.getTranslations();

    if (!translations) {
      return null;
    }
    const lang = language || this.currentLanguage || this.getDefaultLanguage();

    if (!lang) {
      return null;
    }
    const langObj = translations[lang];

    if (!langObj) {
      return null;
    }

    return langObj[key] || null;
  }
}
