// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
// #endif

const BACKEND_DATA_SIZE_MULTIPLIER = 1.0; // backend data is stored in mm -> multiply by 0.1 to convert to cm
const CONFIG_SIZE_MULTIPLIER = 1.0; // json data is stored in cm

function parseNum(n) {
  const t = typeof (n);

  if (t === 'number' && !isNaN(n)) {
    return n;
  }
  if (t === 'string') {
    return parseFloat(n);
  }

  return n;
}

function isNum(n) {
  return (typeof (n) === 'number' && !isNaN(n));
}
/**
* @class SampleDA
* @description Static data accessor class for sample objects
* Note:
    parameters named 'sampleConfig' are the objects from the config json file
*   parameters named 'sample' are the objects from the backend service
*/
export default class SampleDA {
  /**
   * @method getSampleID
   * @static
   * @description Returns the id of the sample.
   *  Uses the object from the config file first, then tries the object from the backend
   *  Note: The sampleConfig parameter will be returned if it's a string
   * @param {Object} sampleConfig - Sample object from the config file
   * @param {Object} sample - (Optional) Sample object from the backend service
   * @return {String} - The id of the sample or null
   * */
  static getID(sampleConfig, sample) {
    if (typeof (sampleConfig) === 'string') {
      return sampleConfig;
    }
    let id = null;
    let t = null;

    if (sampleConfig) {
      id = sampleConfig.id;
    }
    t = typeof (id);
    if (t === 'string' || t === 'number') {
      return id;
    }

    if (typeof (sample) === 'string') {
      return sample;
    }
    if (sample) {
      id = sample.id;
    }
    t = typeof (id);
    if (id === 'string' || id === 'number') {
      return id;
    }

    // #if DEBUG
    if (!sampleConfig && !sample) {
      BD3DLogger.warn('getSampelID - invalid parameters: SampleConfig=', sampleConfig, 'sample=', sample);
    }
    // #endif

    return null;
  }

  static setAlign(sampleConfig, x, y) {
    if (!sampleConfig) {
      return;
    }
    let X = x, Y = y;

    if (x !== null && typeof (x) === 'object') {
      X = x.x;
      Y = x.y;
    }
    X = parseNum(X);
    Y = parseNum(Y);

    let align = sampleConfig.align;

    const validX = (X !== null && typeof (X) === 'number' && !isNaN(X));
    const validY = (Y !== null && typeof (Y) === 'number' && !isNaN(Y));

    if (!validX && !validY) {
      return;
    }

    if (!align) {
      align = sampleConfig.align = {};
    }
    if (validX) {
      align.x = X;
    }
    if (validY) {
      align.y = Y;
    }
  }

  static _getSampleSingleConfig(sample, singlePart) {
    if (!sample || !singlePart || !singlePart.toLowerCase) {
      return null;
    }
    const config = sample.config;

    if (!config) {
      return null;
    }

    let sp = singlePart.toLowerCase();

    sp = sp.replace(/[_\-]/g, '');

    if (sp === 'panel') {
      return config.panel;
    } else if (sp === 'toppanel' || sp === 'top') {
      return config.topPanel || config.toppanel || config.top || config.panel;
    } else if (sp === 'bottompanel' || sp === 'bottom') {
      return config.bottomPanel || config.bottompanel || config.bottom || config.panel;
    } else if (sp === 'fabric' || sp === 'border' || sp === 'borderfabric') {
      return config.fabric || config.border || config.borderfabric || config.borderFabric;
    }

    return null;
  }

  /**
   * @method getAlignX
   * @description returns the sample alignment on a part of a single
   * @param {Object} sampleConfig - config json object
   * @param {Object} sample - backend data json object
   * @param {String} singlePart - part of the single
   *   "panel": uses 'panel' settings (both top and bottom)
   *   "toppanel", "topPanel", "top-panel" or "top_panel": uses settings for top panel or just 'panel' if no 'top panel' config found in the backend data
   *   "bottompanel", "bottomPanel", "bottom-panel" or "bottom_panel": uses settings for bottom panel or just 'panel' if no bottom panel config found in the backend data
   *   "border" or "fabric": uses border fabric settings from backend data
   * @param {Number} fallback - default / fallback value if not found in config data
   * @return {Number} x-alignment of the sample
   * */
  static getAlignX(sampleConfig, sample, singlePart, fallback) {
    return this._getAlign('x', sampleConfig, sample, singlePart, fallback);
  }

  static getAlignY(sampleConfig, sample, singlePart, fallback) {
    return this._getAlign('y', sampleConfig, sample, singlePart, fallback);
  }

  static _getAlign(property, sampleConfig, sample, singlePart, fallback) {
    // 1) try to get align property from the config json
    let res = this._getAlignFrom(property, sampleConfig);

    if (isNum(res)) {
      return res;
    }
    // 2) try to get align property from the sample backend data
    if (!sample) {
      return fallback;
    }
    // 2.1) try to get the align property from the sample backend config settings
    const sampleSingleConfig = this._getSampleSingleConfig(sample, singlePart);

    if (sampleSingleConfig) {
      const plc = property && property.toLowerCase ? property.toLowerCase() : property;

      if (plc === 'x' || plc === 'alignx') {
        res = sampleSingleConfig.alignX;
        if (!isNum(res)) {
          res = sampleSingleConfig.alignx;
        }
      } else if (plc === 'y' || plc === 'aligny') {
        res = sampleSingleConfig.alignY;
        if (!isNum(res)) {
          res = sampleSingleConfig.aligny;
        }
      }
      if (isNum(res)) {
        return res;
      }
    }

    // 2.2) try to get the default align property from the sample backend data
    res = this._getAlignFrom(property, sample);
    if (isNum(res)) {
      return res;
    }

    return fallback;
  }

  static getTextureAlignX(textureObject, fallback = 0.5) {
    return this._getTextureAlign('x', textureObject, fallback);
  }

  static getTextureAlignY(textureObject, fallback = 0.5) {
    return this._getTextureAlign('y', textureObject, fallback);
  }

  static _getTextureAlign(property, textureObject, fallback) {
    const res = this._getAlignFrom(property, textureObject);

    return typeof (res) === 'number' && !isNaN(res) ? res : fallback;
  }

  static _getAlignFrom(property, object) {
    if (!object) {
      return null;
    }
    if (property === 'x') {
      if (object.align && isNum(object.align.x)) {
        return object.align.x;
      }
      if (isNum(object.alignX)) {
        return object.alignX;
      }
    }
    if (property === 'y') {
      if (object.align && isNum(object.align.y)) {
        return object.align.y;
      }
      if (isNum(object.alignY)) {
        return object.alignY;
      }
    }

    return null;
  }

  // relative x-coordinate of the rotation pivot point
  // 0 = left, 0.5 = center, 1 = right
  static getPivotX(sampleConfig, sample, fallback) {
    return this._getSamplePivot('x', sampleConfig, sample, fallback);
  }

  // relative y-coordinate of the rotation pivot point
  // 0 = top, 0.5 = middle, 1 = bottom
  static getPivotY(sampleConfig, sample, fallback) {
    return this._getSamplePivot('y', sampleConfig, sample, fallback);
  }

  static getRotation(sampleConfig, sample, singlePart, fallback) {
    if (sampleConfig) {
      if (isNum(sampleConfig.rotation)) {
        return sampleConfig.rotation;
      }
    }
    if (sample) {
      const sampleSingleConfig = this._getSampleSingleConfig(sample, singlePart);

      if (sampleSingleConfig && isNum(sampleSingleConfig.rotation)) {
        return sampleSingleConfig.rotation;
      }

      if (isNum(sample.rotation)) {
        return sample.rotation;
      }
    }

    return fallback;
  }

  static _getSamplePivot(property, sampleConfig, sample, fallback) {
    let res = this._getSamplePivotFrom(property, sampleConfig);

    if (isNum(res)) {
      return res;
    }
    res = this._getSamplePivotFrom(property, sample);
    if (isNum(res)) {
      return res;
    }

    return fallback;
  }

  static _getSamplePivotFrom(property, object) {
    if (!object) {
      return null;
    }
    let res = null;
    const pvt = object.pivot;

    if (property === 'x') {
      res = object.pivotX;
      if (isNum(res)) {
        return res;
      } else if (pvt) {
        res = pvt.x;
      }
    } else if (property === 'y') {
      res = object.pivotY;
      if (isNum(res)) {
        return res;
      } else if (pvt) {
        res = pvt.y;
      }
    }

    return res;
  }

  /**
   * @method getRealWidth
   * @description returns the real width of a sample (in cm).
   *  If this value is not found in the sampleConfig,
   *  the value of the backend data (sample) is used.
   * @param {Object} sampleConfig - sample data from the config file
   * @param {Object} sample - sample object from the backend
   * @param {Number} fallback - fallback value if no real width is found (default = undefined)
   * @return {Number} - the real width of the sample in cm
   **/
  static getRealWidth(sampleConfig, sample, fallback) {
    return this._getSampleRealSize('width', sampleConfig, sample, fallback);
  }

  /**
  * @method getRealHeight
  * @description returns the real height of a sample (in cm).
  *  If this value is not found in the sampleConfig,
  *  the value of the backend data (sample) is used.
  * @param {Object} sampleConfig - sample data from the config file
  * @param {Object} sample - sample object from the backend
  * @param {Number} fallback - fallback value if no real height is found (default = undefined)
  * @return {Number} - the real height of the sample in cm
  **/
  static getRealHeight(sampleConfig, sample, fallback) {
    return this._getSampleRealSize('height', sampleConfig, sample, fallback);
  }

  /**
  * @method _getSampleRealSize
  * @private
  * @description returns the real size of a sample (in cm).
  *  If this value is not found in the sampleConfig,
  *  the value of the backend data (sample) is used.
  * @param {String} property - 'width' or 'height'
  * @param {Object} sampleConfig - sample data from the config file
  * @param {Object} sample - sample object from the backend
  * @param {Number} fallback - fallback value if no real size is found (default = undefined)
  * @return {Number} - the real width or height of the sample in cm
  **/
  static _getSampleRealSize(property, sampleConfig, sample, fallback) {
    if (typeof (property) === 'undefined' || property === null) {
      return fallback;
    }
    if (sampleConfig && sampleConfig.size) {
      let res = -1;

      if (property === 'width') {
        res = parseNum(sampleConfig.size.width);
      } else if (property === 'height') {
        res = parseNum(sampleConfig.size.height);
      }
      if (typeof (res) === 'number' && !isNaN(res) && res >= 0) {
        res *= CONFIG_SIZE_MULTIPLIER;

        return res;
      }
    }
    if (sample) {
      let res = -1;

      if (property === 'width') {
        res = parseNum(sample.realWidth);
      }
      if (property === 'height') {
        res = parseNum(sample.realHeight);
      }
      if (typeof (res) === 'number' && !isNaN(res) && res >= 0) {
        res *= BACKEND_DATA_SIZE_MULTIPLIER;

        return res;
      }
    }

    return fallback;
  }
}
