import ImageAsset from '../asset/ImageAsset';
import Object3DAsset from '../asset/Object3DAsset';
import SampleAsset from '../asset/SampleAsset';
import QuiltAsset from '../asset/QuiltAsset';
import AssetHolder from '../asset/AssetHolder';

import Node3DMaterialUtils from '../material/Node3DMaterialUtils';
import MattressGeomUtils from '../geom/MattressGeomUtils';
import BGR3DToThreeConverter from '../../bgr/three/geom/BGR3DToThreeConverter';
import EventDispatcher from '../../bgr/common/events/EventDispatcher';
import Transform3D from '../../bgr/bgr3d/transform/Transform3D';
import * as THREE from 'three';
import BD3DSampleFabricMaterial from '../material/BD3DSampleFabricMaterial';
import ThreeFabricMaterial from './ThreeFabricMaterial';
import ThreePlasticMaterial from './ThreePlasticMaterial';
import ThreeHandleLogoMaterial from './ThreeHandleLogoMaterial';
import CanvasUtils from '../../bgr/common/utils/CanvasUtils';
import Geometry from '../../bgr/bgr3d/geom/Geometry';
import Node3D from '../../bgr/bgr3d/scenegraph/Node3D';
import GeometryNode3D from '../../bgr/bgr3d/scenegraph/GeometryNode3D';
import ThreeBumpToNormalConverter from './ThreeBumpToNormalConverter';
import BD3DMaterial from '../material/BD3DMaterial';
import BD3DFabricMaterial from '../material/BD3DFabricMaterial';
import BD3DPlasticMaterial from '../material/BD3DPlasticMaterial';
import BD3DHandleLogoMaterial from '../material/BD3DHandleLogoMaterial';
import BorderComponentTypes from '../borders/BorderComponentTypes';
import QuiltBumpBlurThree from '../three/QuiltBumpBlurThree';
import GridQuiltgeneratorThree from '../three/GridQuiltGeneratorThree';
import QuiltConfig from '../quilt/QuiltConfig';
import QuiltTypes from '../quilt/QuiltTypes';
// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
// #endif

import BD3DNodeTypes from '../scenegraph/BD3DNodeTypes';

import ColorUtils from '../utils/ColorUtils';
import Colors from '../colors/Colors';
import Material from '../../bgr/bgr3d/material/Material';

export default class BD3DThreeManager extends EventDispatcher {
  disposeThreeObject(object, params, recursive) {
    if (!object) {
      return;
    }
    if (object instanceof THREE.Texture) {
      if (!params || params.textures !== false) {
        object.dispose();
      }
    }

    if (object instanceof THREE.ShaderMaterial || object instanceof THREE.RawShaderMaterial) {
      const uniforms = object.uniforms;

      if (uniforms) {
        for (const v in uniforms) {
          if (uniforms.hasOwnProperty(v)) {
            const u = uniforms[v];
            const val = u ? u.value : null;

            this.disposeThreeObject(val, params, recursive);
          }
        }
      }
    }
    if (object instanceof THREE.Geometry || object instanceof THREE.BufferGeometry) {
      if (!params || params.geometries !== false) {
        object.dispose();
      }
    }
    if (object instanceof THREE.Mesh) {
      const mat = object.material;
      const geom = object.geometry;

      this.disposeThreeObject(mat, params, recursive);
      this.disposeThreeObject(geom, params, recursive);
    }
    if (object instanceof THREE.Object3D && recursive) {
      const children = object.children;

      if (!children) {
        return;
      }
      const numC = children.length;

      if (!numC) {
        return;
      }
      for (let i = 0; i < numC; ++i) {
        this.disposeThreeObject(children[i], params, recursive);
      }
    }
  }

  _getThreeConvertParams(source = null) {
    let res = this._convertParams;

    if (!res) {
      res = this._convertParams = {};
    }

    /*
    let extraGeomAtts = this._extraGeometryAttributeSettings;

    if (!extraGeomAtts) {
      extraGeomAtts = this._extraGeometryAttributeSettings = {
        staticUV: {itemSize: 2, default: [0, 0], flipY: false}
      };
    }
    res.geometryAttributes = extraGeomAtts;
    */

    if (source) {
      for (const v in source) {
        if (source.hasOwnProperty(v)) {
          res[v] = source[v];
        }
      }
    }

    return res;
  }

  _getConverter() {
    let res = this._converter;

    if (!res) {
      res = this._converter = new BGR3DToThreeConverter();
      const that = this;

      res.onGeometryNode = (geomNode, reslt, params = null) => {
        that._onGeometryNode(geomNode, reslt, params);
      };

      res.shouldConvertGeometry = (source, params, reslt) => {
        const mapping = that._getMapping(false);

        if (mapping && mapping.has(source)) {
          return false;
        }

        if (source && source.userData && source.userData.dynamic === true) {
          return true;
        }

        return false;
      };

      res.getCachedThreeObject = (source, params) => {
        return that._getCachedThreeObject(source, params);
      };

      res.setCachedThreeObject = (source, result, params) => {
        that._setCachedThreeObject(source, result, params);
      };
    }

    return res;
  }

  _getCachedThreeObject(source, params) {
    if (!source) {
      return null;
    }
    const ud = source.userData;

    if (ud && ud.threeObject) {
      return ud.threeObject;
    }

    return this.getThreeObject(source);
  }

  _setCachedThreeObject(source, result, params) {
    if (!source) {
      return;
    }

    if (source instanceof Geometry ||
      source instanceof Node3D ||
      source instanceof BD3DMaterial
    ) {
      if (!source.userData) {
        source.userData = {};
      }
      source.userData.threeObject = result;
    }


    const m = this._getMapping(true);

    m.set(source, result);
  }

  _onGeometryNode(geomNode, result, params = null) {
    const material = Node3DMaterialUtils.getMaterial(geomNode);

    const threeMat = this._getThreeMaterial(material, geomNode, result, params);

    if (result instanceof THREE.Mesh) {
      if (threeMat) {
        result.material = threeMat;
      } else if (result.material) {
        result.material.wireframe = true;
      }
    }
    // #if DEBUG
    if (geomNode.geometry && geomNode.geometry.userData && geomNode.geometry.userData.threeCallback) {
      geomNode.geometry.userData.threeCallback(geomNode, result, params);
    }
    if (geomNode && geomNode.userData && geomNode.userData.threeCallback) {
      geomNode.userData.threeCallback(geomNode, result, params);
    }
    // #endif
  }

  _getThreeHandleLogoMaterial(material, node, resultMesh, params) {
    const res = ThreeHandleLogoMaterial.create();
    const globalUniforms = params && params.globalUniforms;

    let fabricTex = this._getImageAssetTexture(material.getFabricTexture());
    let imageTex = this._getImageAssetTexture(material.getImageTexture());
    let imageAmount = 1.0;

    if (!fabricTex) {
      fabricTex = this._getDefaultWhiteTexture();
    }
    if (!imageTex) {
      imageTex = this._getDefaultWhiteTexture();
      imageAmount = 0;
    }

    const {objectWidth, objectHeight, objectDepth} = material;

    if (globalUniforms) {
      res.setUniform('cameraWorldMatrix', globalUniforms.cameraWorldMatrix);
    }
    res.setFabricSize(material.getFabricWidth(), material.getFabricHeight());
    res.setImageSize(material.getImageWidth(), material.getImageHeight());
    res.setImageOffset(material.getImageOffsetX(), material.getImageOffsetY());
    res.setImageAlignment(material.getImageAlignmentX(), material.getImageAlignmentY());
    res.setImageLocalAlignment(material.getImageLocalAlignmentX(), material.getImageLocalAlignmentY());

    res.setFabricTexture(fabricTex);
    res.setImageTexture(imageTex);
    res.setImageValue(imageAmount);

    if (
      typeof (objectWidth) === 'number' && !isNaN(objectWidth) &&
      typeof (objectHeight) === 'number' && !isNaN(objectHeight) &&
      typeof (objectHeight) === 'number' && !isNaN(objectHeight)
    ) {
      res.setObjectSize(objectWidth, objectHeight, objectDepth);
    }

    res.assignObjectScale(resultMesh);

    return res;
  }

  _getThreeMaterial(material, node, resultMesh, params) {
    let res = this.getThreeObject(material);

    if (!res) {
      res = this._getCachedThreeObject(material, params);
    }

    res = this._createThreeMaterial(material, node, resultMesh, params, res);

    if (resultMesh) {
      const mat = resultMesh.material;

      if ((mat instanceof ThreeFabricMaterial) || (res instanceof ThreeFabricMaterial)) {
        const geom = resultMesh.geometry;

        if (geom instanceof THREE.BufferGeometry) {

          if (geom.attributes && !geom.attributes.staticUV) {
            geom.addAttribute('staticUV', geom.attributes.uv);
          }
        }
      }
    }
    this._setThreeObject(material, res);
    this._setCachedThreeObject(material, res, params);

    return res;
  }

  _getThreePlasticMaterial(material, node, resultMesh, params) {
    const res = ThreePlasticMaterial.create();
    const lightmapAsset = material.getLightmap();
    const colorMultiplier = material.getColorMultiplier();
    const globalUniforms = params ? params.globalUniforms : null;

    if (globalUniforms) {
      res.setUniform('cameraWorldMatrix', globalUniforms.cameraWorldMatrix);
    }
    let lightmapTex = this._getImageAssetTexture(lightmapAsset);

    if (!lightmapTex) {
      lightmapTex = this._getDefaultWhiteTexture();
    }

    let specularPower = material.getSpecularPower();
    let specularMult = material.getSpecularMultiplier();

    if (typeof (specularPower) !== 'number' || isNaN(specularPower)) {
      specularPower = 1.0;
    }
    if (typeof (specularMult) !== 'number' || isNaN(specularMult)) {
      specularMult = 0.0;
    }

    res.setLightmap(lightmapTex);
    res.setColorMultiplier(this._getColor(colorMultiplier, null, '#FFFFFF'));

    res.setSpecularPower(specularPower);
    res.setSpecularMultiplier(specularMult);

    return res;
  }

  _findColorType(material, node, resultMesh, params, result) {
    if (node && node.getNodeType) {
      const nt = node.getNodeType();
      let colorTypeName = null;

      if (nt === BD3DNodeTypes.tape) {
        colorTypeName = 'tape';
      } else if (nt === BD3DNodeTypes.border3d) {
        colorTypeName = 'border3d';
      } else if (nt === BD3DNodeTypes.zipper) {
        colorTypeName = 'zipper';
      } else if (nt === BD3DNodeTypes.piping) {
        const matType = material && material.getFabricType ? material.getFabricType() : null;

        if (matType === 'satin') {
          colorTypeName = 'pipingglossy';
        } else {
          colorTypeName = 'pipingmatte';
        }
      } else if (nt === BD3DNodeTypes.borderRibbon) {
        colorTypeName = 'ribbon';
      }

      const materialType = material ? material.getType() : null;
      let materialTypeName = materialType ? materialType.getName() : null;

      if (materialTypeName) {
        materialTypeName = materialTypeName.toLowerCase();
        if (materialTypeName === 'border3d') {
          return 'border3d';
        }
      }
      if (colorTypeName) {
        return colorTypeName;
      }
    }
    if (material) {
      const matType = material.getType ? material.getType() : null;
      const matTypeName = matType && matType.getName ? matType.getName() : null;
      const colTypeName = material.getColorType ? material.getColorType() : null;

      if (matTypeName || colTypeName) {

        if (matTypeName === 'border3d' || colTypeName === 'border3d') {
          return 'border3d';
        } if (matTypeName === 'tape' || colTypeName === 'tape') {
          return 'tape';
        } if (matTypeName === 'ribbon' || colTypeName === 'ribbon') {
          return 'ribbon';
        } if (matTypeName === 'zipper' || colTypeName === 'zipper') {
          return 'zipper';
        } if (matTypeName === 'piping' || colTypeName === 'piping') {
          let matFabricType = null;

          if (material) {
            if (material.getFabricType) {
              matFabricType = material.getFabricType();
            } else if (material.get) {
              matFabricType = material.get('fabricType');
            }
          }
          if (matFabricType === 'satin') {
            return 'pipingglossy';
          }

          return 'pipingmatte';
        }
      }
    }

    return null;
  }

  _assignGlobalUniformsToThreeMaterial(threeMaterial, params) {
    if (!threeMaterial) {
      return;
    }

    if (!(threeMaterial instanceof ThreeFabricMaterial)) {
      return;
    }
    const globalUniforms = params && params.globalUniforms;

    if (!globalUniforms) {
      return;
    }
    const {
      cameraWorldMatrix,
      light1Intensity,
      light2Intensity,
      light2Translate
    } = globalUniforms;

    if (cameraWorldMatrix instanceof THREE.Uniform) {
      threeMaterial.setUniform('cameraWorldMatrix', cameraWorldMatrix);
    } else if (cameraWorldMatrix instanceof THREE.Matrix4) {
      threeMaterial.setUniformValue('cameraWorldMatrix', cameraWorldMatrix);
    } else {
      threeMaterial.setUniformValue('cameraWorldMatrix', new THREE.Matrix4());
    }

    // Light uniforms
    if (light1Intensity instanceof THREE.Uniform) {
      threeMaterial.setUniform('light1Intensity', light1Intensity);
    } else if (typeof (light1Intensity) === 'number') {
      threeMaterial.setUniformValue('light1Intensity', light1Intensity);
    } else {
      threeMaterial.setUniformValue('light1Intensity', 1);
    }
    if (light2Intensity instanceof THREE.Uniform) {
      threeMaterial.setUniform('light2Intensity', light2Intensity);
    } else if (typeof (light2Intensity) === 'number') {
      threeMaterial.setUniformValue('light2Intensity', light2Intensity);
    } else {
      threeMaterial.setUniformValue('light2Intensity', 1);
    }
    if (light2Translate instanceof THREE.Uniform) {
      threeMaterial.setUniform('light2Translate', light2Translate);
    } else if (light2Translate instanceof THREE.Vector3) {
      threeMaterial.setUniformValue('light2Translate', light2Translate);
    }
  }

  _getThreeFabricMaterial(material, node, resultMesh, params, result = null) {
    let res = result;

    const colorType = this._findColorType(material, node, resultMesh, params, result);

    let quiltNormalTexture = this._getQuiltNormalTextureWithFallback(material);

    let fabricType = null; // satin / cotton / ...

    if (material instanceof BD3DFabricMaterial) {
      fabricType = material.getFabricType();
    }

    const quiltImage = material.getQuiltTexture();
    let quiltNormalMap = material.getQuiltNormalMap();
    const quiltBumpMap = material.getQuiltBumpMap();

    if (!quiltNormalMap) {
      quiltNormalMap = quiltBumpMap;
    }

    const sampleImage = material.getSampleTexture();
    const sampleNormalMap = material.getSampleNormalMap();
    const sampleSpecularMap = material.getSampleSpecularMap();
    const staticMap = material.getStaticTexture();
    const staticNormalMap = material.getStaticNormalMap();

    const quiltRepeatX = material.getQuiltRepeatX();
    const quiltRepeatY = material.getQuiltRepeatY();

    const quiltNormalIntensity = material.getQuiltNormalIntensity();

    const sampleSpecularPower = 1.0;
    const sampleSpecularMultiplier = 0.25;

    if (!res || !(res instanceof ThreeFabricMaterial)) {
      res = ThreeFabricMaterial.create();
    }

    let quiltTexture = this._getImageAssetTexture(quiltImage);
    let sampleTexture = this._getImageAssetTexture(sampleImage);
    let sampleNormalTexture = this._getImageAssetNormalTexture(sampleNormalMap);
    let sampleSpecularTexture = this._getImageAssetTexture(sampleSpecularMap);
    let staticTexture = this._getImageAssetTexture(staticMap);
    let staticNormalTexture = this._getImageAssetNormalTexture(staticNormalMap);
    const hasQuiltTexture = quiltTexture !== null && typeof (quiltTexture) !== 'undefined';

    if (!quiltNormalTexture) {
      quiltNormalTexture = this._getImageAssetNormalTexture(quiltNormalMap);
    }
    if (!quiltTexture) {
      quiltTexture = this._getDefaultWhiteTexture();
    }
    if (!sampleTexture) {
      sampleTexture = this._getDefaultWhiteTexture();
    }
    if (!sampleNormalTexture) {
      sampleNormalTexture = this._getDefaultNormalMap();
    }
    if (!staticTexture) {
      staticTexture = this._getDefaultWhiteTexture();
    }
    if (!staticNormalTexture) {
      staticNormalTexture = this._getDefaultNormalMap();
    }
    if (!sampleSpecularTexture) {
      sampleSpecularTexture = this._getDefaultBlackTexture();
    }

    this._assignGlobalUniformsToThreeMaterial(res, params);

    res.setQuiltTexture(quiltTexture);
    res.setQuiltTextureValue(hasQuiltTexture ? 1 : 0);
    res.setQuiltNormalMap(quiltNormalTexture);
    res.setQuiltNormalIntensity(quiltNormalIntensity);
    res.setQuiltRepeat(quiltRepeatX, quiltRepeatY);

    res.setSampleTexture(sampleTexture);
    res.setSampleNormalMap(sampleNormalTexture);
    res.setSampleSpecularMap(sampleSpecularTexture);
    res.setStaticTexture(staticTexture);
    res.setStaticNormalMap(staticNormalTexture);
    res.setColorMultiplier(this._getMaterialColorMultiplier(material, colorType, '#FFFFFF'));

    res.setSpecularPower(sampleSpecularPower);
    res.setSpecularMultiplier(sampleSpecularMultiplier);

    this.setThreeFabricMaterialFabricType(res, fabricType, params);
    this.updateFabricTransform(material, res);

    return res;
  }

  _createThreeMaterial(material, node, resultMesh, params, result) {
    if (!material) {
      return null;
    }

    let res = result;

    if (!res && material.userData) {
      res = material.userData.threeMaterial;
    }

    if (material instanceof BD3DHandleLogoMaterial) {
      return this._getThreeHandleLogoMaterial(material, node, resultMesh, params);
    }
    if (material instanceof BD3DPlasticMaterial) {
      return this._getThreePlasticMaterial(material, node, resultMesh, params, res);
    }
    if (material instanceof BD3DFabricMaterial) {
      res = this._getThreeFabricMaterial(material, node, resultMesh, params, res);

      if (res) {
        return res;
      }
    }

    const materialType = material.getType();
    const colorType = this._findColorType(material, node, resultMesh, params, result);
    let typeName = materialType ? materialType.getName() : null;

    if (typeName) {
      typeName = typeName.toLowerCase();
    }

    if (material instanceof BD3DFabricMaterial) {
      if (!res || (!res instanceof ThreeFabricMaterial)) {
        res = ThreeFabricMaterial.create();
      }
    }
    if (!res && materialType) {
      // TODO: refactor the whole loading / asset / material pipeline
      // Too many if's, too much copy pasted code,
      if (typeName === 'sample' || typeName === 'fabric') {
        res = ThreeFabricMaterial.create();
      } else if (typeName === 'zipper') {
        res = ThreeFabricMaterial.create();
      } else if (typeName === 'border3d') {
        res = ThreeFabricMaterial.create();
      } else if (typeName === 'piping') {
        res = ThreeFabricMaterial.create();
      } else if (typeName === 'plastic') {
        res = ThreePlasticMaterial.create();
      }
    }
    if (res instanceof ThreeFabricMaterial) {
      res.setQuiltTextureValue(0);
      res.setColorMultiplier(1, 1, 1);
      this._assignGlobalUniformsToThreeMaterial(res, params);
    }
    if (res) {

      if (!material.userData) {
        material.userData = {};
      }
      material.userData.threeMaterial = res;
    }

    if (res) {
      const mSettings = material.getSettings();
      const globalUniforms = params ? params.globalUniforms : null;

      if (globalUniforms && (res instanceof ThreeFabricMaterial)) {
        res.setUniform('cameraWorldMatrix', globalUniforms.cameraWorldMatrix);
      }

      if (typeName === 'plastic') {
        const lightmapAsset = material.get('lightmap');

        if (globalUniforms) {
          res.setUniform('cameraWorldMatrix', globalUniforms.cameraWorldMatrix);
        }
        const lightmapTex = this._getImageAssetTexture(lightmapAsset);

        res.setLightmap(lightmapTex);

        res.setColorMultiplier(this._getColor(mSettings.colorMultiplier, colorType, '#FFFFFF'));
      }

      let fabricType = null; // satin / cotton / ...

      if (material instanceof BD3DFabricMaterial) {
        fabricType = material.getFabricType();
      }

      const assetManager = params.assetManager;

      if (typeName === 'sample' || typeName === 'fabric') {
        if (globalUniforms) {
          res.setUniform('cameraWorldMatrix', globalUniforms.cameraWorldMatrix);
        }

        let sampleID = mSettings ? mSettings.id : null;

        if (sampleID === undefined || sampleID === null) {
          if (mSettings && mSettings.sample) {
            sampleID = mSettings.sample;
            if (typeof (sampleID) !== 'number' && typeof (sampleID) !== 'string') {
              sampleID = mSettings.sample.id;
            }
          }
          if (typeof (sampleID) !== 'number' && typeof (sampleID) !== 'string' && material.getSampleID) {
            sampleID = material.getSampleID();
          }
        }
        const sampleIDType = typeof (sampleID);

        if (sampleIDType !== 'undefined' && sampleIDType !== 'string') {
          sampleID = `${sampleID}`;
        }

        let sampleAsset = null;
        let texture = null;
        let bump = null;
        let normal = null;
        let specularMap = null;

        if (sampleID) {
          sampleAsset = assetManager.assetCollections.samples.getAsset(sampleID);
        }
        let geomUVScaleU = 1;
        let geomUVScaleV = 1;
        let geomUVTranslateU = 0;
        let geomUVTranslateV = 0;

        // let texScaleX = 1;
        // let texScaleY = 1;
        // let texOffX = 0;
        // let texOffY = 0;

        let quiltScaleX = 1;
        let quiltScaleY = 1;
        let quiltOffX = 0;
        let quiltOffY = 0;
        const quiltRealW = 1;
        const quiltRealH = 1;

        const bumpToNormalConvParams = null;

        if (node instanceof GeometryNode3D) {
          const geom = node.geometry;
          const uvData = MattressGeomUtils.getGeometryUVWorldTransform(geom);

          if (uvData) {
            if (typeof (uvData.scaleU) === 'number' && !isNaN(uvData.scaleU)) {
              geomUVScaleU = uvData.scaleU;
            }
            if (typeof (uvData.scaleV) === 'number' && !isNaN(uvData.scaleV)) {
              geomUVScaleV = uvData.scaleV;
            }
            if (typeof (uvData.translateU) === 'number' && !isNaN(uvData.translateU)) {
              geomUVTranslateU = uvData.translateU;
            }
            if (typeof (uvData.translateV) === 'number' && !isNaN(uvData.translateV)) {
              geomUVTranslateV = uvData.translateV;
            }
          }

        }

        if (sampleAsset) {
          texture = this._getImageAssetTexture(sampleAsset.getAsset('texture'));
          // bump = this._getImageAssetTexture(sampleAsset.getAsset('bump'));
          // normal = this._getImageAssetTexture(sampleAsset.getAsset('normal'));
          normal = this._getImageAssetNormalTexture(sampleAsset.getAsset('normal'), params, bumpToNormalConvParams);
          if (!normal) {
            bump = this._getImageAssetNormalTexture(sampleAsset.getAsset('bump'), params, bumpToNormalConvParams);
          }
          specularMap = this._getImageAssetTexture(sampleAsset.getAsset('specular'));

          // const sampleData = sampleAsset.getData();
          // const sampleJSON = sampleData ? sampleData.json : null;
          // const realW = this._parseNum(sampleJSON ? sampleJSON.realWidth : 1, 1);
          // const realH = this._parseNum(sampleJSON ? sampleJSON.realHeight : 1, 1);

          // texScaleX = geomUVScaleU * realW;
          // texScaleY = geomUVScaleV * realH;

          // texOffX = 0.5 - (geomUVScaleU * realW * 0.5);
          // texOffY = 0.5 - (geomUVScaleV * realH * 0.5);

          // texOffX += geomUVTranslateU;
          // texOffY += geomUVTranslateV;

        } else if (fabricType === 'cotton') {
          texture = this._getImageAssetTexture(assetManager.assetCollections.commonTextures.getAssetByName('defaultfabric.color'));
          normal = this._getImageAssetNormalTexture(assetManager.assetCollections.commonTextures.getAssetByName('defaultfabric.normal'));
        }

        if (!texture) {
          texture = this._getDefaultWhiteTexture();
        }
        if (!normal) {
          normal = bump;
        }
        if (!normal) {
          normal = this._getDefaultNormalMap();
        }
        if (!specularMap) {
          specularMap = this._getDefaultBlackTexture();
        }

        res.setSampleTexture(texture);
        res.setSampleNormalMap(normal);
        res.setSampleSpecularMap(specularMap);

        const sampleSpecularPower = 2.0;
        const sampleSpecularMultiplier = 2.0;

        res.setSpecularPower(sampleSpecularPower);
        res.setSpecularMultiplier(sampleSpecularMultiplier);

        // res.setSampleTextureScale(texScaleX, texScaleY);
        // res.setSampleTextureOffset(texOffX, texOffY);
        this.updateFabricTransform(material, res);

        // quilt

        quiltScaleX = geomUVScaleU * quiltRealW;
        quiltScaleY = geomUVScaleV * quiltRealH;

        quiltOffX = 0.5 - (geomUVScaleU * quiltRealW * 0.5);
        quiltOffY = 0.5 - (geomUVScaleV * quiltRealH * 0.5);

        quiltOffX += geomUVTranslateU;
        quiltOffY += geomUVTranslateV;

        res.setQuiltTextureScale(quiltScaleX, quiltScaleY);
        res.setQuiltTextureOffset(quiltOffX, quiltOffY);

        let quiltTex = (material instanceof BD3DFabricMaterial) ? this._getImageAssetTexture(material.getQuiltTexture()) : null;

        if (!quiltTex) {
          quiltTex = this._getDefaultWhiteTexture();
        }
        res.setQuiltTexture(quiltTex);
        if (material instanceof BD3DFabricMaterial) {
          res.setQuiltTextureValue(material.getQuiltTextureValue() || 0);
        }

        let staticTex = (material instanceof BD3DFabricMaterial) ? this._getImageAssetTexture(material.getStaticTexture()) : null;

        if (!staticTex) {
          staticTex = this._getDefaultWhiteTexture();
        }
        res.setStaticTexture(staticTex);

        res.setColorMultiplier(this._getMaterialColorMultiplier(material, colorType, '#FFFFFF'));

        this.setThreeFabricMaterialFabricType(res, fabricType, params);

      } else if (typeName === 'zipper') {
        let texture = this._getImageAssetTexture(mSettings.diffuse);
        let normal = this._getImageAssetTexture(mSettings.normal);
        let specularMap = this._getImageAssetTexture(mSettings.specularMask);
        const colorMultiplier = mSettings.colorMultiplier;

        if (!texture) {
          texture = this._getDefaultWhiteTexture();
        }
        if (!normal) {
          normal = this._getDefaultNormalMap();
        }
        if (!specularMap) {
          specularMap = this._getDefaultBlackTexture();
        }
        const zipperSpecularPower = 50;
        const zipperSpecularMultiplier = 1;

        res.setSampleTextureScale(1, 1);
        // res.setSampleTextureOffset(0, 0);
        res.setSampleTexture(texture);
        res.setSampleNormalMap(normal);
        res.setSampleSpecularMap(specularMap);
        res.setSpecularMultiplier(zipperSpecularMultiplier);
        res.setSpecularPower(zipperSpecularPower);
        res.setColorMultiplier(this._getColor(colorMultiplier, colorType, '#FFFFFF'));

      } else if (typeName === 'border3d') {
        let texture = this._getImageAssetTexture(mSettings.diffuse);
        let normal = this._getImageAssetTexture(mSettings.normal);
        let specularMap = this._getImageAssetTexture(mSettings.specularMask);
        const border3DNormalMap = this._getQuiltNormalTextureWithFallback(material);

        if (!texture) {
          texture = this._getDefaultWhiteTexture();
        }
        if (!normal) {
          normal = this._getDefaultNormalMap();
        }
        if (!specularMap) {
          specularMap = this._getDefaultBlackTexture();
        }
        const border3DSpecularPower = 1;
        const border3DSpecularMultiplier = 0.4;

        res.setSpecularPower(border3DSpecularPower);
        res.setSpecularMultiplier(border3DSpecularMultiplier);
        const normalScale = 5;

        res.setSampleNormalScale(normalScale);
        res.setSampleTextureScale(1, 1);
        // res.setSampleTextureOffset(0, 0);

        res.setQuiltNormalIntensity(normalScale * 0.5);
        res.setQuiltNormalMap(border3DNormalMap);

        res.setSampleTexture(texture);
        res.setSampleNormalMap(normal);
        res.setSampleSpecularMap(specularMap);
        res.setColorMultiplier(this._getColor(mSettings.colorMultiplier, colorType, '#FFFFFF'));

        const colorM = res.getUniformValue('colorMultiplier');

        if (colorM) {
          const clampMin = 0.5;

          ColorUtils.clampMinColor(colorM.x, colorM.y, colorM.z, clampMin, colorM);
          // 3d border texture is quite dark
          colorM.x += 0.1;
          colorM.y += 0.1;
          colorM.z += 0.1;
        }

        this.updateFabricTransform(material, res);
      } else if (typeName === BorderComponentTypes.FABRIC.getRibbonMaterialTypeName()) {
        let quiltTexture = this._getImageAssetTexture(material.getQuiltTexture());
        let quiltNormal = this._getImageAssetTexture(material.getQuiltNormalMap());
        let specularMap = null;

        if (!quiltTexture) {
          quiltTexture = this._getDefaultWhiteTexture();
        }
        if (!quiltNormal) {
          quiltNormal = this._getDefaultNormalMap();
        }
        if (!specularMap) {
          specularMap = this._getDefaultBlackTexture();
        }

        res.setQuiltTexture(quiltTexture);
        res.setQuiltTextureValue(quiltTexture ? 1 : 0);
        res.setQuiltNormalMap(quiltNormal);
        res.setSampleSpecularMap(specularMap);
        res.setColorMultiplier(material.getColorMultiplier());

        this.setThreeFabricMaterialFabricType(res, fabricType || 'satin', params);
        this.updateFabricTransform(material, res);
      } else if (typeName === 'piping') {
        let texture = this._getImageAssetTexture(mSettings.diffuse);
        let normal = this._getImageAssetTexture(mSettings.normal);
        let specularMap = this._getImageAssetTexture(mSettings.specularMask);

        this.setThreeFabricMaterialFabricType(res, fabricType, params);

        if (!texture) {
          texture = this._getDefaultWhiteTexture();
        }
        if (!normal) {
          normal = this._getDefaultNormalMap();
        }
        if (!specularMap) {
          specularMap = this._getDefaultBlackTexture();
        }
        const pipingTexScale = 2;

        res.setSampleTextureScale(pipingTexScale, pipingTexScale);
        // res.setSampleTextureOffset(0, 0);
        res.setSampleTexture(texture);
        res.setSampleNormalMap(normal);
        res.setSampleSpecularMap(specularMap);
        res.setColorMultiplier(this._getMaterialColorMultiplier(material, colorType, '#FFFFFF'));
        this.updateFabricTransform(material, res);
      }

      return res;
    }

    const mat = new THREE.MeshBasicMaterial({color: 0xFF0000});

    // #if DEBUG
    BD3DLogger.warn('Invalid material? ', 'src material=', material, 'result material=', mat, 'node3d = ', node, 'three mesh=', resultMesh, 'params=', params);
    // #endif

    return mat;
  }

  setThreeFabricMaterialFabricType(material, type, params) {
    const t = type ? type.toLowerCase() : null;
    let brdfFactor = 0.0;

    const assetManager = params ? params.assetManager : null;
    const assets = assetManager ? assetManager.assetCollections.commonTextures : null;
    let brdfMapTexture = null;

    if (material instanceof ThreeFabricMaterial) {
      if (t === 'satin') {
        const brdfMap = assets ? assets.getAssetByName('satin.brdf') : null;

        if (brdfMap) {
          brdfMapTexture = this._getImageAssetTexture(brdfMap);
        }
        brdfFactor = 0.5;
      }
    }
    if (!brdfMapTexture) {
      brdfMapTexture = this._getDefaultWhiteTexture();
      brdfFactor = 0;
    }
    material.setBRDFMap(brdfMapTexture);
    material.setBRDFFactor(brdfFactor);
  }

  _getColor(color, type = null, fallback = null) {
    const t = typeof (color);

    if (t === 'number') {
      return color;
    }

    if (!color) {
      return fallback;
    }
    if (t === 'string') {
      if (color.length === 0) {
        return fallback;
      }
      if (type) {
        const res = Colors.getColorByTypeAndId(type, color);

        if (res) {
          if (typeof (res) === 'string') {
            return res;
          }
          if (res.color3d) {
            return res.color3d;
          }

          return res.color;
        }
      }
    }

    return color;
  }

  _getMaterialColorMultiplier(material, type, fallback = null) {
    let res = fallback;

    if (material instanceof BD3DFabricMaterial) {
      res = material.getColorMultiplier();
    } else if (material && material.get) {
      res = material.get('colorMultiplier');
    }

    return this._getColor(res, type, fallback);
  }

  updateObject(object, params = null) {
    this._updateObject(object, params);
  }

  _updateObject(object, params = null, nodeObject = null, resultMesh = null) {
    if (!object) {
      return;
    }
    var threeObj = this._getCachedThreeObject(object, params);
    if (object instanceof Node3D) {
      const material = (object.getMaterial && object.getMaterial()) || object.material;
      this._updateObject(material, params, object, threeObj);
      const children = (object.getChildren && object.getChildren()) || object.children;
      const numChildren = (children && children.length) || 0;
      for (let i = 0; i < numChildren; ++i) {
        const child = children[i];
        this._updateObject(child, params);
      }
    } else if (object instanceof Material) {
      this._createThreeMaterial(object, nodeObject, resultMesh, params, threeObj);
    }
  }

  updateSampleTransform(material, threeMaterial) {
    if (!(threeMaterial instanceof ThreeFabricMaterial)) {
      return;
    }
    const validMaterial = material instanceof BD3DFabricMaterial;
    const createMatrixIfNull = validMaterial;

    let sampleTransform = null;

    if (material.getSampleTransform) {
      sampleTransform = material.getSampleTransform();
    } else if (material.get) {
      sampleTransform = material.get('sampleTransform');
    }

    let mtx = this.getThreeObject(sampleTransform);

    if (!mtx) {
      mtx = threeMaterial.getSampleTransformMatrix(createMatrixIfNull);
      this._setThreeObject(sampleTransform, mtx);
      this._setCachedThreeObject(sampleTransform, mtx);
    }

    if (mtx) {
      mtx.identity();
    }
    /*
    if (transf) {
      transf.applyMatrix4(mtx.elements);
    }
    */

    if (validMaterial) {
      material.applySampleTransformToMatrix(mtx.elements);
    } else {
      const transf = material.get('sampleTransform');

      if (transf instanceof Transform3D) {
        transf.applyMatrix4(mtx.elements);
      }
    }
    threeMaterial.setSampleTransformMatrix(mtx);
  }

  updateQuiltTransform(material, threeMaterial) {
    if (!(threeMaterial instanceof ThreeFabricMaterial)) {
      return;
    }
    const isFabricMaterial = material instanceof BD3DFabricMaterial;
    const validMaterial = isFabricMaterial || (material instanceof BD3DMaterial);
    const createMatrixIfNull = validMaterial;

    const mtx = threeMaterial.getQuiltTransformMatrix(createMatrixIfNull);

    if (mtx) {
      mtx.identity();
    }

    /*
    if (transf) {
      transf.applyMatrix4(mtx.elements);
    }
    */
    if (isFabricMaterial) {
      material.applyQuiltTransformToMatrix(mtx.elements);
    } else {
      const transf = material.get('quiltTransform');

      if (transf instanceof Transform3D) {
        transf.applyMatrix4(mtx.elements);
      }
    }
    threeMaterial.setQuiltTransformMatrix(mtx);
  }

  // Synchronizes the fabric transform data to the three material sample matrix
  updateFabricTransform(material, threeMaterial) {
    this.updateSampleTransform(material, threeMaterial);
    this.updateQuiltTransform(material, threeMaterial);
  }

  _calcRealUVSize(realUVSize, textureSize) {
    return;
  }

  _parseNum(value, fallback) {
    let val = value;
    let t = typeof (val);

    if (t === 'string') {
      val = parseFloat(val);
      t = typeof (val);
    }
    if (t === 'number') {
      if (isNaN(val)) {
        return fallback;
      }

      return val;
    }

    return fallback;
  }

  _getDefaultWhiteTexture() {
    let tex = this._defaultWhiteTexture;

    if (!tex) {
      tex = this._defaultWhiteTexture = new THREE.Texture();
      tex.image = CanvasUtils.getColorCanvas('#FFFFFF');
      tex.needsUpdate = true;
    }

    return tex;
  }

  _getDefaultBlackTexture() {
    let tex = this._defaultBlackTexture;

    if (!tex) {
      tex = this._defaultBlackTexture = new THREE.Texture();
      tex.image = CanvasUtils.getColorCanvas('#000000');

      tex.needsUpdate = true;
    }

    return tex;
  }

  _getDefaultNormalMap() {
    let tex = this._defaultTexture;

    if (!tex) {
      tex = this._defaultTexture = new THREE.Texture();
      tex.image = CanvasUtils.getColorCanvas('#8080FF');
      tex.needsUpdate = true;
    }

    return tex;
  }

  _getTextureResizeParams() {
    let res = this._textureResizeParams;

    if (!res) {
      res = this._textureResizeParams = {};
    }

    return res;
  }

  _getMaxTextureSize() {
    const r = this._getRenderer();
    const gl = r ? r.context : null;
    let maxSize = 16384;

    if (gl) {
      const glMaxSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);

      maxSize = glMaxSize < maxSize ? glMaxSize : maxSize;
    }


    return maxSize;
  }

  _getBumpToNormalConverter() {
    let res = this._bumpToNormalConverter;

    if (!res) {
      res = this._bumpToNormalConverter = new ThreeBumpToNormalConverter();
    }

    return res;
  }
  _getGridQuiltGenerator() {
    let res = this._gridQuiltGenerator;

    if (res) {
      return res;
    }
    res = this._gridQuiltGenerator = new GridQuiltgeneratorThree();


    return res;
  }

  // Similar to _getQuiltNormalTexture, but returns a default normal map if no specified
  _getQuiltNormalTextureWithFallback(source, params = null, conversionParams = null) {
    const nrm = this._getQuiltNormalTexture(source, params, conversionParams);

    if (nrm) {
      return nrm;
    }

    return this._getDefaultNormalMap();
  }

  _getQuiltNormalTexture(source, params = null, conversionParams = null) {
    // TODO: check quilt type (image / grid)
    if (!source) {
      return null;
    }

    if (source instanceof BD3DSampleFabricMaterial) {
      const quiltAsset = source.getQuiltAsset();
      const quiltConfig = source.getQuiltConfig();

      if (quiltConfig instanceof QuiltConfig) {
        const type = quiltConfig.getQuiltType();

        if ((type === QuiltTypes.IMAGE || type === null) && quiltAsset) {
          // Quilt from image
          return this._getQuiltImageNormalTexture(quiltConfig, params);
        } else if (type === QuiltTypes.GRID) {
          // Quilt from grid settings
          return this._getQuiltGridNormalTexture(quiltConfig, params);
        }
      }
    } else if (source instanceof BD3DFabricMaterial) {
      let quiltNormalMap = source.getQuiltNormalMap();
      const quiltBumpMap = source.getQuiltBumpMap();

      if (!quiltNormalMap) {
        quiltNormalMap = quiltBumpMap;
      }

      return this._getImageAssetQuiltNormalTexture(quiltNormalMap, params, conversionParams);
    } else if (source instanceof BD3DMaterial) {
      // #if DEBUG
      BD3DLogger.log('3d border quilt in progress');
      // #endif
      const quiltConfig = source.get('quiltConfig');

      if (quiltConfig) {
        const type = quiltConfig.getQuiltType();

        if (type === QuiltTypes.GRID) {
          // Quilt from grid settings
          return this._getQuiltGridNormalTexture(quiltConfig, params);
        }

        return this._getQuiltImageNormalTexture(quiltConfig, params);
      }
      const quiltBumpAsset = source.get('quiltBump');

      return this._getImageAssetQuiltNormalTexture(quiltBumpAsset, params, conversionParams);
    }

    return null;
  }
  _useTexture(data, texture, params) {
    this._setThreeObject(data, texture);
    this._setCachedThreeObject(data, texture, params);
  }
  // Quilt from bump / normal map
  _getQuiltImageNormalTexture(quiltConfig, params) {
    let ud = quiltConfig.userData;

    if (!ud) {
      ud = quiltConfig.userData = {};
    }
    let threeData = ud.threeData;

    if (!threeData) {
      threeData = ud.threeData = {};
    }
    let tex = threeData.texture;

    if (!tex) {
      tex = ud.threeObject;
    }
    if (tex instanceof THREE.Texture) {
      if (!quiltConfig.isInvalid()) {
        this._useTexture(quiltConfig, tex);

        return tex;
      }
    }

    const quiltAsset = quiltConfig.getQuiltAsset();
    const normalMap = quiltAsset ? quiltAsset.getNormalMap() : null;
    let bumpImg = normalMap ? normalMap.getImage() : null;

    if (!bumpImg) {
      bumpImg = quiltConfig.getQuiltBumpImage();
    }
    if (!bumpImg) {
      return null;
    }
    const onPreProcessImage = this._getQuiltBlurPreprocessor();
    const onPreProcessImageParams = {quiltConfig: quiltConfig};
    const conversionParams = null;

    const oldTex = tex;

    tex = this._imageToNormalTexture(bumpImg, threeData, params, conversionParams, onPreProcessImage, onPreProcessImageParams);
    this._setCachedThreeObject(quiltConfig, tex, params);

    if (oldTex && oldTex !== tex) {
      oldTex.dispose();
    }

    ud.threeObject = tex;
    threeData.texture = tex;

    this._useTexture(quiltConfig, tex, params);

    return tex;
  }

  // Quilt from rows & columns
  _getQuiltGridNormalTexture(quiltConfig, params) {
    const quiltGen = this._getGridQuiltGenerator();

    let ud = quiltConfig.userData;

    if (!ud) {
      ud = quiltConfig.userData = {};
    }

    let threeData = ud.threeData;
    let texture = null;

    if (!threeData) {
      threeData = ud.threeData = {};
    }
    texture = threeData.texture;
    if (!texture) {
      texture = ud.threeObject;
    }
    let threeCache = threeData.cache;

    let update = false;

    if (!texture) {
      update = true;
    }
    if (!threeCache) {
      update = true;
      threeCache = threeData.cache = {};
    }
    const quiltConfigData = quiltConfig.getConfigData();

    if (threeCache.config !== quiltConfigData) {
      threeCache.config = quiltConfigData;
      update = true;
    }

    if (!update) {
      if (!threeCache.x) {
        threeCache.x = [];
        update = true;
      }
      if (!threeCache.y) {
        threeCache.y = [];
        update = true;
      }
      const count = 10;

      for (let i = 0; i < count; ++i) {
        const key = `${i}`;
        let xVal, yVal;

        if ((quiltConfigData.x instanceof Array) || (Array.isArray && Array.isArray(quiltConfigData.x))) {
          xVal = quiltConfigData.x[i];
        } else {
          xVal = quiltConfigData.x[key];
        }

        if ((quiltConfigData.y instanceof Array) || (Array.isArray && Array.isArray(quiltConfigData.y))) {
          yVal = quiltConfigData.y[i];
        } else {
          yVal = quiltConfigData.y[key];
        }

        if (threeCache.x[i] !== xVal) {
          threeCache.x[i] = xVal;
          update = true;
        }
        if (threeCache.y[i] !== yVal) {
          threeCache.y[i] = yVal;
          update = true;
        }
      }
    }

    if (update && quiltGen && quiltConfigData) {
      const renderer = this._getRenderer(params);
      const width = 512;
      const height = 512;
      let quiltGenParams = this._gridQuiltGenParams;
      let textureParams = this._gridQuiltTextureParams;

      if (!quiltGenParams) {
        quiltGenParams = this._gridQuiltGenParams = {
          normalMap: true,
          lineThickness: 64,
          depth: 1,
          normalIntensity: 2
        };
      }
      let blurSettings = quiltGenParams.blur;

      if (!blurSettings) {
        blurSettings = quiltGenParams.blur = {};
      }
      blurSettings.min = quiltConfig.getMinBlurScale();
      blurSettings.max = quiltConfig.getMaxBlurScale();
      blurSettings.passes = quiltConfig.getNumBlurPasses();

      const thicknessScale = 1000;
      const foamScale = 1;

      quiltGenParams.lineThickness = quiltConfig.getSoftness() * thicknessScale;
      quiltGenParams.depth = quiltConfig.getQuiltFoamValue() / foamScale;

      if (!textureParams) {
        textureParams = this._gridQuiltTextureParams = {
          anisotropy: 4,
          wrapS: THREE.RepeatWrapping,
          wrapT: THREE.RepeatWrapping
        };
      }

      texture = quiltGen.getTexture(renderer, quiltConfigData, width, height, textureParams, quiltGenParams, texture);

      threeData.texture = texture;

      // #if DEBUG
      texture.IS_GRID_QUILT_TEXTURE = true;
      // #endif

      this._useTexture(quiltConfig, texture, params);

      return texture;
    }

    return null;
  }

  _getQuiltBlurPreprocessor() {
    let onPreProcessImage = this._quiltBlurPreprocess;

    if (!onPreProcessImage) {
      const that = this;

      onPreProcessImage = this._quiltBlurPreprocess = (img, renderer, p) => {
        let blurTool = that._quiltBlurTool;

        if (!blurTool) {
          blurTool = that._quiltBlurTool = new QuiltBumpBlurThree();
        }
        let minBlurScale = 0;
        let maxBlurScale = 1;
        let numBlurPasses = 1;

        if (p) {
          const quiltConfig = p.quiltConfig;

          if (quiltConfig) {
            minBlurScale = quiltConfig.getMinBlurScale(minBlurScale);
            maxBlurScale = quiltConfig.getMaxBlurScale(maxBlurScale);
            numBlurPasses = quiltConfig.getNumBlurPasses(numBlurPasses);
          }
        }

        const res = blurTool.getBlurredTexture(img, renderer, minBlurScale, maxBlurScale, numBlurPasses, p);

        return res;
      };
    }

    return onPreProcessImage;
  }

  _getImageAssetQuiltNormalTexture(imageAsset, params = null, conversionParams = null) {
    const onPreProcessImage = this._getQuiltBlurPreprocessor();
    const onPreProcessImageParams = null;

    return this._getImageAssetNormalTexture(imageAsset, params, conversionParams, onPreProcessImage, onPreProcessImageParams);
  }

  _getRenderer(params) {
    let renderer = null;

    if (params) {
      renderer = params.renderer;
    }
    if (!renderer) {
      renderer = this.renderer || this._renderer;
    }

    return renderer;
  }

  _getImageAssetNormalTexture(imageAsset, params = null, conversionParams = null, onPreProcessImage = null, onPreProcessImageParams = null) {
    if (!imageAsset) {
      return null;
    }
    let d = imageAsset.getData();
    let img = null;

    if (d instanceof Image) {
      img = d;
      d = {};
      d.image = img;
      imageAsset.setData(d);
    }

    let tex = d ? d.texture : null;

    if (tex) {
      this._useTexture(img, tex, params);
      if (tex.image && (!tex.image.width || !tex.image.height)) {
        return null;
      }

      return tex;
    }

    if (!img && imageAsset && imageAsset.getImage) {
      img = imageAsset.getImage();
    }

    tex = this._imageToNormalTexture(img, d, params, conversionParams, onPreProcessImage, onPreProcessImageParams);

    this._useTexture(img, tex, params);

    return tex;
    /*
    if (!img || !img.width || !img.height) {
      return null;
    }

    if (!ThreeBumpToNormalConverter.isBumpMap(img)) {
      return this._getImageAssetTexture(imageAsset);
    }
    const sourceImage = img;

    const converter = this._getBumpToNormalConverter();

    const renderer = this._getRenderer(params);

    if (onPreProcessImage) {
      // Do something with the image before converting it to a normal map
      // E.g. blurring
      const newImage = onPreProcessImage(img, renderer, onPreProcessImageParams);

      if (newImage) {
        img = newImage;
      }
    }

    let convParams = conversionParams;

    if (!convParams) {
      convParams = {
        normalIntensity: 10,
        method: 'readpixels',
        flipY: true,
        textureParams: {
          wrapS: THREE.RepeatWrapping,
          wrapT: THREE.RepeatWrapping
        }
        // method: null
      };
    }

    const res = converter.convertToNormalTexture(img, renderer, convParams);

    if (res instanceof THREE.WebGLRenderTarget) {
      d.renderTarget = res;
      tex = res.texture;
    } else if (res instanceof THREE.Texture) {
      tex = res;
    }
    d.texture = tex;

    if (sourceImage !== img) {
      if (img !== d.texture && img !== d.renderTarget) {
        if (img instanceof THREE.Texture) {
          img.dispose();
        } else if (img instanceof THREE.WebGLRenderTarget) {
          img.dispose();
        }
      }
    }
    let texNeedsUpdate = false;

    if (tex.flipY) {
      tex.flipY = false;
      texNeedsUpdate = true;
    }
    if (texNeedsUpdate) {
      tex.needsUpdate = true;
    }

    return tex;
    */
  }

  _imageToNormalTexture(image, dataObj, params, conversionParams, onPreProcessImage, onPreProcessImageParams, textureProperties) {
    let img = image;
    const d = dataObj;

    if (!img || !img.width || !img.height) {
      return null;
    }
    let tex = null;
    let texNeedsUpdate = false;

    if (ThreeBumpToNormalConverter.isBumpMap(img)) {
      const sourceImage = img;

      const converter = this._getBumpToNormalConverter();

      const renderer = this._getRenderer(params);

      if (onPreProcessImage) {
        // Do something with the image before converting it to a normal map
        // E.g. blurring
        const newImage = onPreProcessImage(img, renderer, onPreProcessImageParams);

        if (newImage) {
          img = newImage;
        }
      }

      let convParams = conversionParams;

      if (!convParams) {
        convParams = {
          normalIntensity: 10,
          method: 'readpixels',
          flipY: true,
          textureParams: {
            wrapS: THREE.RepeatWrapping,
            wrapT: THREE.RepeatWrapping
          }
          // method: null
        };
      }

      const res = converter.convertToNormalTexture(img, renderer, convParams);

      if (res instanceof THREE.WebGLRenderTarget) {
        if (d) {
          d.renderTarget = res;
        }
        tex = res.texture;
      } else if (res instanceof THREE.Texture) {
        tex = res;
      }
      if (d) {
        d.texture = tex;
      }

      if (sourceImage !== img) {
        if (img !== d.texture && img !== d.renderTarget) {
          if (img instanceof THREE.Texture) {
            img.dispose();
          } else if (img instanceof THREE.WebGLRenderTarget) {
            img.dispose();
          }
        }
      }
    } else {
      tex = this._newImageToTexture(img, d, textureProperties);
      if (d) {
        d.texture = tex;
      }
    }

    if (tex) {
      if (tex.flipY) {
        tex.flipY = false;
        texNeedsUpdate = true;
      }
      if (texNeedsUpdate) {
        tex.needsUpdate = true;
      }
    }

    return tex;
  }
  _getImageAssetTexture(imageAsset) {
    if (!imageAsset) {
      return null;
    }
    let d = imageAsset.getData();

    if (!d) {
      return null;
    }

    let img = null;

    if (d instanceof Image) {
      img = d;

      d = {image: img};
      imageAsset.setData(d);
    } else {
      img = imageAsset.getImage();
    }

    let tex = d.texture;

    if (tex) {
      if (tex.image && !tex.image.width && !tex.image.height) {
        return null;
      }
      this._useTexture(img, tex);

      return tex;
    }
    if (!img) {
      img = imageAsset.getImage();
    }

    const textureProperties = imageAsset.metaData ? imageAsset.metaData.textureProperties : null;

    tex = this._newImageToTexture(img, d, textureProperties);

    if (tex && tex.image && !tex.image.width && !tex.image.height) {
      return null;
    }
    this._useTexture(img, tex);

    return tex;
    /*
    if (!img) {
      return null;
    }
    const params = this._getTextureResizeParams();

    params.maxSize = this._getMaxTextureSize();

    const texImage = CanvasUtils.resizeToPO2(img, params);

    if (!texImage) {
      return null;
    }

    d.textureImage = texImage;

    tex = new THREE.Texture();
    tex.flipY = false;
    tex.image = texImage;
    tex.anisotropy = 4;
    tex.wrapS = THREE.RepeatWrapping;
    tex.wrapT = THREE.RepeatWrapping;

    if (imageAsset.metaData && imageAsset.metaData.textureProperties) {
      let wrapS = imageAsset.metaData.textureProperties.wrapX;
      let wrapT = imageAsset.metaData.textureProperties.wrapY;

      wrapS = wrapS ? wrapS.toLowerCase() : wrapS;
      wrapT = wrapT ? wrapT.toLowerCase() : wrapT;

      if (wrapS === 'clamp') {
        tex.wrapS = THREE.ClampToEdgeWrapping;
      }
      if (wrapT === 'clamp') {
        tex.wrapT = THREE.ClampToEdgeWrapping;
      }
    }

    tex.needsUpdate = true;

    d.texture = tex;

    if (!texImage.width || !texImage.height) {
      return null;
    }

    return tex;
    */
  }

  _newImageToTexture(img, dataObj, textureProperties) {
    const res = this._imageToTexture(img, dataObj, textureProperties);

    /*
    const textureSet = this._getTextureSet(true);

    if (textureSet) {
      textureSet.add(res);
    }
    */
    this._setCachedThreeObject(img, res);
    this._setThreeObject(img, res);

    return res;
  }

  _imageToTexture(img, dataObj, textureProperties) {
    if (!img) {
      return null;
    }
    const d = dataObj;

    const params = this._getTextureResizeParams();

    params.maxSize = this._getMaxTextureSize();

    const texImage = CanvasUtils.resizeToPO2(img, params);

    if (!texImage) {
      return null;
    }
    if (d) {
      d.textureImage = texImage;
    }

    const tex = new THREE.Texture();

    tex.flipY = false;
    tex.image = texImage;
    tex.anisotropy = 4;
    tex.wrapS = THREE.RepeatWrapping;
    tex.wrapT = THREE.RepeatWrapping;

    if (textureProperties) {
      let wrapS = textureProperties.wrapX;
      let wrapT = textureProperties.wrapY;

      wrapS = wrapS ? wrapS.toLowerCase() : wrapS;
      wrapT = wrapT ? wrapT.toLowerCase() : wrapT;

      if (wrapS === 'clamp') {
        tex.wrapS = THREE.ClampToEdgeWrapping;
      }
      if (wrapT === 'clamp') {
        tex.wrapT = THREE.ClampToEdgeWrapping;
      }
    }

    tex.needsUpdate = true;

    d.texture = tex;

    if (!texImage.width || !texImage.height) {
      return null;
    }

    return tex;
  }

  _onGeometry(geom, threeGeom, params) {
    let ud = geom.userData;

    if (!ud) {
      ud = geom.userData = {};
    }

    let d = ud.eventDispatcher;

    if (!d) {
      d = ud.eventDispatcher = new EventDispatcher();
    }
    const disposeHandler = this._getGeometryDisposeHandler();

    d.once('dispose', disposeHandler);
  }

  _getGeometryDisposeHandler() {
    let handler = this._geometryDisposeHandler;

    if (!handler) {
      const that = this;

      handler = this._geometryDisposeHandler = evt => {
        that._handleGeometryDispose(evt);
      };
    }

    return handler;
  }

  _handleGeometryDispose(evt) {
    const geom = evt.geometry;

    this.disposeGeometry(geom);
  }

  disposeGeometry(geom) {
    // #if DEBUG
    BD3DLogger.log('dispose geometry', geom);
    // #endif

    return;
  }

  disposeTexture(texture) {
    // #if DEBUG
    BD3DLogger.log('dispose texture', texture);
    // #endif

    return;
  }

  _getReversedMapping(create = false) {
    let res = this._reversedMapping;

    if (res || !create) {
      return res;
    }

    res = this._reversedMapping = new Map();

    return res;
  }

  // Map to store the internal geometry/scenegraph data as key and the three data as value
  _getMapping(create = false) {
    let res = this._mapping;

    if (res || !create) {
      return res;
    }

    res = this._mapping = new Map();

    return res;
  }

  // Map used to temporarily copy data from the current map to compare which items are new and which ones are old
  _getOldMapping(create = false) {
    let res = this._oldMapping;

    if (res || !create) {
      return res;
    }
    res = this._oldMapping = new Map();

    return res;
  }

  getBD3DObjectByThreeObject(threeObject) {
    if (!threeObject) {
      return null;
    }
    const ud = threeObject.userData;

    if (!ud) {
      return null;
    }

    return ud.BD3DObject;
  }

  _setThreeObject(object, threeObject) {
    if (object) {
      const ud = object.userData;

      if (ud) {
        ud.threeObject = threeObject;
      }
      const m = this._getMapping(true);

      if (m) {
        m.set(object, threeObject);
      }
    }
  }

  getThreeObject(obj) {
    if (!obj) {
      return null;
    }

    if (obj.userData && obj.userData.threeObject) {
      return obj.userData.threeObject;
    }

    let m = this._getMapping(false);
    let threeObj = null;

    if (m && m.has(obj)) {
      threeObj = m.get(obj);
    }
    if (threeObj) {
      return threeObj;
    }

    m = this._getOldMapping(false);

    if (m && m.has(obj)) {
      threeObj = m.get(obj);
    }

    return threeObj;
  }

  clear() {
    const mapping = this._getMapping(false);
    const oldMapping = this._getOldMapping(false);

    if (mapping) {
      for (const [key, value] of mapping) {
        this._removed(key, value);
      }
      mapping.clear();
    }
    if (oldMapping) {
      oldMapping.clear();
    }
  }

  _getAddedEventObject() {
    let evt = this._addedEventObject;

    if (!evt) {
      evt = this._addedEventObject = {};
    }

    return evt;
  }

  _getRemovedEventObject() {
    let evt = this._removedEventObject;

    if (!evt) {
      evt = this._removedEventObject = {};
    }

    return evt;
  }

  // Dispatches event if object is added to the three container while converting
  _added(item, result) {
    const eventName = 'added';

    if (!this.hasEventListener(eventName)) {
      return;
    }
    const evt = this._getAddedEventObject();

    evt.type = eventName;
    evt.object = item;
    evt.threeObject = result;

    if (result) {
      if (typeof (result.userData) !== 'undefined') {
        if (!result.userData) {
          result.userData = {};
        }
        result.userData.BD3DObject = item;
      }
    }
    this.dispatchEvent(evt);
    evt.object = null;
    evt.threeObject = null;
  }

  // Dispatches event if object is removed from the three container while converting
  _removed(item, result) {
    const eventName = 'removed';

    if (!this.hasEventListener(eventName)) {
      return;
    }
    const evt = this._getRemovedEventObject();

    evt.type = eventName;
    evt.object = item;
    evt.threeObject = result;
    this.dispatchEvent(evt);
    evt.object = null;
    evt.threeObject = null;
  }

  _initThreeObject(object) {
    if (object instanceof THREE.Mesh) {
      const mat = object.material;

      if (mat instanceof ThreeHandleLogoMaterial) {
        let scaleX = 1, scaleY = 1, scaleZ = 1, o = object;

        while (o) {
          scaleX *= o.scale.x;
          scaleY *= o.scale.y;
          scaleZ *= o.scale.z;
          o = o.parent;
        }
        mat.setObjectScale(scaleX, scaleY, scaleZ);
      }
    }
  }

  disposeNode3D(node3d) {
    if (!node3d) {
      return;
    }
    const mapping = this._getMapping(false);

    if (mapping) {
      const threeObject = mapping.get(node3d);

      if (threeObject && threeObject.dispose) {
        threeObject.dispose();
      }
    }

    if (node3d.getGeometry) {
      const geom = node3d.getGeometry();

      this.disposeNode3D(geom);
    }

    if (node3d.getChildren) {
      const children = node3d.getChildren();
      const num = children ? children.length : 0;

      for (let i = 0; i < num; ++i) {
        const child = children[i];

        this.disposeNode3D(child);
      }
    }
  }

  disposeAsset(asset) {
    if (!asset) {
      return;
    }
    const mapping = this._getMapping(false);

    if (mapping) {
      const threeObject = mapping.get(asset);

      if (threeObject && threeObject.dispose) {
        threeObject.dispose();
      }
    }

    if (asset instanceof ImageAsset) {
      const img = asset.getImage();

      this.disposeAsset(img);
    } else if (asset instanceof Object3DAsset) {
      const node3d = asset.getNode3D();

      this.disposeNode3D(node3d);
    } else if (asset instanceof SampleAsset) {
      this.disposeAsset(asset.assets);
    } else if (asset instanceof QuiltAsset) {
      this.disposeAsset(asset.assets);
    } else if (asset instanceof AssetHolder) {
      const num = asset.getNumAssets();

      for (let i = 0; i < num; ++i) {
        const child = asset.getAssetAt(i);

        this.disposeAsset(child);
      }
    }
  }

  convert(node, params = null, res = null) {
    const convertParams = this._getThreeConvertParams(params);
    const converter = this._getConverter();
    let m = this._getMapping(false);
    let oldMapping = null;

    oldMapping = this._getOldMapping(false);
    if (oldMapping) {
      oldMapping.clear();
    }

    if (m) {
      if (!oldMapping) {
        oldMapping = this._getOldMapping(true);
        oldMapping.clear();
      }
      for (const [key, value] of m) {
        oldMapping.set(key, value);
      }
    }

    m = this._getMapping(true);
    convertParams.map = m;
    m.clear();

    const result = converter.convert(node, convertParams, res);

    if (result) {
      result.traverse(this._initThreeObject);
    }

    m = convertParams.map;
    if (oldMapping) {
      for (const [key, value] of oldMapping) {
        if (!m || !m.has(key)) {
          this._removed(key, value);
        }
      }
    }
    if (m) {
      for (const [key, value] of m) {
        if (!oldMapping || !oldMapping.has(key)) {
          this._added(key, value);
        }
      }
    }
    if (oldMapping) {
      oldMapping.clear();
    }
    convertParams.map = null;
    convertParams.assetManager = null;
    convertParams.globalUniforms = null;
    convertParams.renderer = null;

    return result;
  }
}
