import Vector from '../../../bgr/bgr3d/geom/Vector';
import Vector3 from '../../../bgr/bgr3d/geom/Vector3';
import {parseNumber} from '../utils';

export default {
  create(data, id, session) {
    let x = 0;
    let y = 0;
    let z = 0;

    if ((data instanceof Array) || (Array.isArray && Array.isArray(data))) {
      x = parseNumber(data[0], session);
      y = parseNumber(data[1], session);
      z = parseNumber(data[2], session);
    } else if (data instanceof Vector) {
      x = data.getCoord(0);
      y = data.getCoord(1);
      z = data.getCoord(2);
    } else if (data !== null && typeof (data) === 'object') {
      x = parseNumber(data.x, session);
      y = parseNumber(data.y, session);
      z = parseNumber(data.z, session);
    }

    return new Vector3(x, y, z);
  },
  update(object, data, id, session) {
    let x = 0;
    let y = 0;
    let z = 0;

    if ((data instanceof Array) || (Array.isArray && Array.isArray(data))) {
      x = parseNumber(data[0], session);
      y = parseNumber(data[1], session);
      z = parseNumber(data[2], session);
    } else if (data instanceof Vector) {
      x = data.getCoord(0);
      y = data.getCoord(1);
      z = data.getCoord(2);
    } else if (data !== null && typeof (data) === 'object') {
      x = parseNumber(data.x, session);
      y = parseNumber(data.y, session);
      z = parseNumber(data.z, session);
    }
    object.setCoords(x, y, z);
  }
};
