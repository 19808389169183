import GeometryNode3D from './GeometryNode3D';

export default class MaterialGeometryNode3D extends GeometryNode3D {
  constructor(geom = null, material = null, transform = null) {
    super(geom, transform);
    this._material = material;
  }

  getMaterial() {
    return this._material;
  }

  setMaterial(m) {
    this._material = m;
  }

  get material() {
    return this.getMaterial();
  }

  set material(m) {
    this.setMaterial(m);
  }
}
