// import BorderComponentType from './BorderComponentType';
import Vector2 from '../../bgr/bgr3d/geom/Vector2';
import Vector3 from '../../bgr/bgr3d/geom/Vector3';
import Vertex from '../../bgr/bgr3d/geom/Vertex';
import GeometryNode3D from '../../bgr/bgr3d/scenegraph/GeometryNode3D';
import MaterialTypes from '../material/MaterialTypes';
import BD3DFabricMaterial from '../material/BD3DFabricMaterial';
import BD3DSampleFabricMaterial from '../material/BD3DSampleFabricMaterial';
import MattressGeomUtils from '../geom/MattressGeomUtils';
import GeomUtils from '../../bgr/bgr3d/utils/GeomUtils';
import RoundBorderComponentType from './RoundBorderComponentType';
import BD3DContainerNode3D from '../scenegraph/BD3DContainerNode3D';
import BD3DGeometryNode3D from '../scenegraph/BD3DGeometryNode3D';
import Node3DMaterialUtils from '../material/Node3DMaterialUtils';
import MaterialType from '../material/MaterialType';
import BorderGeomUtils from './BorderGeomUtils';
import ImageAsset from '../asset/ImageAsset';
import FabricMaterialUtils from '../material/FabricMaterialUtils';
import BD3DNodeTypes from '../scenegraph/BD3DNodeTypes';
import ContainerNode3D from '../../bgr/bgr3d/scenegraph/ContainerNode3D';
/*
import SampleTransform from '../material/SampleTransform';
import SampleDA from '../sample/SampleDA';
*/

const ribbonMaterialType = new MaterialType('fabric_ribbon');
const ribbonScaleX = 0.3;
const ribbonVerts = [
  {x: 0.0143, y: 0.3482},
  {x: -0.0459, y: 0.7189},
  {x: -0.0821, y: 0.7434},
  {x: -0.0992, y: 0.7137},
  {x: -0.0894, y: 0.5117},
  {x: -0.0286, y: 0.3358},
  {x: -0.0977, y: 0.1734},
  {x: -0.118, y: 0.0},
  {x: -0.0977, y: -0.1734},
  {x: -0.0286, y: -0.3358},
  {x: -0.0894, y: -0.5117},
  {x: -0.0992, y: -0.7137},
  {x: -0.0821, y: -0.7434},
  {x: -0.0459, y: -0.7189},
  {x: 0.0143, y: -0.3482}
];

{
  const num = ribbonVerts.length;

  for (let i = 0; i < num; ++i) {
    const vert = ribbonVerts[i];

    vert.x *= ribbonScaleX;
  }
}
const ribbonMinY = -0.7434;
const ribbonMaxY = 0.7434;
const numRibbonVerts = ribbonVerts.length;

const adjustUVParams = {
  keepUVAspect: true,
  uvMaxFit: true
};

const Assets = {
  TEX_RIBBON_SATIN_COLOR: new ImageAsset('ribbon.satin.tex_color'),
  TEX_RIBBON_SATIN_NORMAL: new ImageAsset('ribbon.satin.tex_normal'),
  TEX_RIBBON_COTTON_COLOR: new ImageAsset('ribbon.cotton.tex_color'),
  TEX_RIBBON_COTTON_NORMAL: new ImageAsset('ribbon.cotton.tex_normal')
};

export default class FabricBorderComponentType extends RoundBorderComponentType {
  constructor() {
    super();
    this.thickness = 0.5;
    this.thicknessFactor = 0;
    this.roundness = 1;
  }

  getNodeMainGeometry(node) {
    if (node instanceof GeometryNode3D) {
      return node.geometry;
    } else if (node instanceof ContainerNode3D) {
      const child = node.getChild(0);

      if (node !== child) {
        return this.getNodeMainGeometry(child);
      }
    }

    return null;
  }

  getNodeType() {
    return BD3DNodeTypes.borderFabric;
  }

  addAssets(data, params, array = null, assetManager = null) {
    if (!data) {
      return array;
    }
    let arr = array;

    if (data.ribbon) {
      const ribbonData = data.ribbon;

      if (ribbonData.material === 'satin') {
        arr = assetManager.addAssetToArray(Assets.TEX_RIBBON_SATIN_COLOR, arr, params);
        arr = assetManager.addAssetToArray(Assets.TEX_RIBBON_SATIN_NORMAL, arr, params);
      } else {
        arr = assetManager.addAssetToArray(Assets.TEX_RIBBON_COTTON_COLOR, arr, params);
        arr = assetManager.addAssetToArray(Assets.TEX_RIBBON_COTTON_NORMAL, arr, params);
      }
    }

    return arr;
  }

  _createRibbon(name, fromY, toY, borderTopOffset, borderBottomOffset, path, borderShape, data, mattressData, params, buildParams, result) {
    let res = result;
    let geom = null;

    if (res instanceof GeometryNode3D) {
      geom = res.geometry;
    }

    geom = this._createRibbonGeom(name, fromY, toY, borderTopOffset, borderBottomOffset, path, borderShape, data, mattressData, params, buildParams, geom);

    if (!geom) {
      if (res) {
        res.geometry = geom;
      }

      return res;
    }

    if (!res) {
      res = new BD3DGeometryNode3D();
    }

    res.geometry = geom;
    res.material = this._getRibbonMaterial(data, mattressData, buildParams);
    if (res.setNodeType) {
      res.setNodeType(BD3DNodeTypes.borderRibbon);
    }

    return res;
  }

  _createRibbonGeom(name, fromY, toY, borderTopOffset, borderBottomOffset, path, borderShape, data, mattressData, params, buildParams, res) {
    const hasUVCorrection = false;
    let geom = null;
    const shape = [];

    // local top & bottom in 2d space (y increasing downward)
    const localTop = this.getTop(data, mattressData);
    const localBottom = this.getBottom(data, mattressData);
    const depth = this.getDepth(data, mattressData);

    const _0 = 0, _1 = 1, _4 = 4, _5 = 5, _12 = 12, _13 = 13;

    const fromYBorderSpace = -fromY - localTop;
    const toYBorderSpace = -toY - localTop;

    for (let y = 0; y < numRibbonVerts; ++y) {
      const srcVert = ribbonVerts[y];
      const rY = 1.0 - ((srcVert.y - ribbonMinY) / (ribbonMaxY - ribbonMinY));
      // const localY = localTop + (localBottom - localTop) * rY;
      const localY = fromY + (toY - fromY) * rY;
      const xoff = this.getShapeXByY(localY, data);

      let ypos = localTop + (localBottom - localTop) * rY;
      let xpos = srcVert.x * 2 + xoff;

      // positive increment moves the ribbon closer to the border component
      // const xOffset = 0.15;
      const xOffset = 0;

      xpos += xOffset;
      xpos -= depth;

      /* eslint-disable */

      const borderOffset = borderTopOffset + fromYBorderSpace + rY * (toYBorderSpace - fromYBorderSpace);
      const vCoord = localY;

      this._tempTransform = borderShape.getTransformAtLength(borderOffset, this._tempTransform);
      if (this._tempTransform && this._tempTransform.matrix4) {
        const m = this._tempTransform.matrix4;

        ypos = 0;

        const tx = xpos * m[_0] + ypos * m[_4] + m[_12];
        const ty = xpos * m[_1] + ypos * m[_5] + m[_13];

        xpos = tx;
        ypos = ty;
      }
      /* eslint-enable */

      const posVec = new Vector3(xpos, ypos, 0);
      const uv = new Vector2(0, vCoord);

      const vert = new Vertex(posVec, {uv: uv});
      // const polyVert = new Vertex(vert, {uv: uv});

      shape.push(vert);
    }

    GeomUtils.computePathVertexNormals(shape, false, 0, 1, 2, 1);

    geom = BorderGeomUtils.borderExtrusion(shape, path, hasUVCorrection, mattressData, params, res);

    GeomUtils.normalizePolygonUVs(geom.polygons, {keepUVAspect: true, uvAlignX: 0, uvAlignY: 0, maxUVFit: true});

    return geom;
  }

  getFabricBorderMaterial(borderCompGeom, comp, mattressData, buildParams, material) {
    const mtl = this.getMaterial(comp, mattressData, buildParams, material);

    if (!(mtl instanceof BD3DFabricMaterial)) {
      return mtl;
    }
    let ud = borderCompGeom.userData;
    let uvBounds = null;

    if (ud) {
      uvBounds = ud.uvBounds;
    }
    uvBounds = this._getUVBounds(borderCompGeom, uvBounds);
    if (!ud) {
      ud = borderCompGeom.userData = {};
    }
    ud.uvBounds = uvBounds;

    const quiltTransf = mtl.getQuiltTransform();

    if (quiltTransf && uvBounds) {
      quiltTransf.uvData = {
        translateV: -uvBounds.miny// - 0.5
      };
      quiltTransf.alignX = 0;
      quiltTransf.alignY = 0.5;
      quiltTransf.pivotX = 0;
      quiltTransf.pivotY = 0;
    }

    return mtl;
  }

  createNode3D(borderCompGeom, comp, mattressData, borderLoop, borderShape, borderCurveGraph, buildParams) {
    const borderCompNode = new BD3DGeometryNode3D(borderCompGeom);
    let material = Node3DMaterialUtils.getMaterial(borderCompNode);

    material = this.getFabricBorderMaterial(borderCompGeom, comp, mattressData, buildParams, material);

    const sampleTransform = material.getSampleTransform ? material.getSampleTransform() : null;
    const quiltTransform = material.getQuiltTransform ? material.getQuiltTransform() : null;

    const geometryUVWorldTransform = MattressGeomUtils.getGeometryUVWorldTransform(borderCompGeom);

    if (sampleTransform && sampleTransform.setGeometryUVData) {
      sampleTransform.setGeometryUVData(geometryUVWorldTransform);
    }
    if (quiltTransform && quiltTransform.setGeometryUVData) {
      quiltTransform.setGeometryUVData(geometryUVWorldTransform);
    }

    Node3DMaterialUtils.setMaterial(borderCompNode, material);

    const ribbonData = comp.ribbon;

    const hasRibbon = (ribbonData !== null) && (typeof (ribbonData) !== 'undefined') && (ribbonData.enabled !== false);

    if (!hasRibbon) {
      return borderCompNode;
    }

    // Create a container with the border mesh + ribbons
    const topY = -this.getTop(comp, mattressData);
    const bottomY = -this.getBottom(comp, mattressData);
    const ribbonExtraParams = null;

    const ribbonHeight = 1.5;
    let ribbonMargin = 3;

    if (typeof (ribbonData.margin) === 'number' && !isNaN(ribbonData.margin) && ribbonData.margin > 0) {
      ribbonMargin = ribbonData.margin;
    }

    let topRibbon = null;
    let bottomRibbon = null;

    const topRibbonFrom = topY - ribbonMargin;
    const topRibbonTo = topRibbonFrom - ribbonHeight;
    const bottomRibbonTo = bottomY + ribbonMargin;
    const bottomRibbonFrom = bottomRibbonTo + ribbonHeight;

    const borderTopOffset = borderCompGeom.userData.borderTopOffset;
    const borderBottomOffset = borderCompGeom.userData.borderBottomOffset;

    if (ribbonData.top !== false) {
      topRibbon = this._createRibbon('top', topRibbonFrom, topRibbonTo, borderTopOffset, borderBottomOffset, borderLoop, borderShape, comp, mattressData, ribbonExtraParams, buildParams, topRibbon);
    }

    if (ribbonData.bottom !== false) {
      bottomRibbon = this._createRibbon('bottom', bottomRibbonFrom, bottomRibbonTo, borderTopOffset, borderBottomOffset, borderLoop, borderShape, comp, mattressData, ribbonExtraParams, buildParams, bottomRibbon);
    }

    if (borderCompNode.setNodeType) {
      borderCompNode.setNodeType(BD3DNodeTypes.borderFabric);
    }

    const container = new BD3DContainerNode3D([
      borderCompNode,
      topRibbon,
      bottomRibbon
    ]);

    if (!container.userData) {
      container.userData = {};
    }
    container.userData.fabricMaterial = material;

    return container;
  }

  adjustUVs(uvs, geom, data, mattressData) {
    if (!MattressGeomUtils || !adjustUVParams || !GeomUtils) {
      return;
    }

    let minY = 0, maxY = 0;

    for (let i = 0; i < uvs.length; ++i) {
      const uv = uvs[i];

      const ypos = uv.getCoord(1);

      if (i === 0) {
        minY = maxY = ypos;
      } else {
        minY = ypos < minY ? ypos : minY;
        maxY = ypos > maxY ? ypos : maxY;
      }
    }

    /*
    const outputData = {};

    GeomUtils.normalizeUVs(uvs, adjustUVParams, outputData);

    MattressGeomUtils.assignGeometryUVWorldTransform(geom, outputData);
    // */
  }

  getRibbonMaterialType() {
    return ribbonMaterialType;
  }

  getRibbonMaterialTypeName() {
    if (!ribbonMaterialType) {
      return null;
    }

    return ribbonMaterialType.getName();
  }

  _getRibbonMaterial(data, mattressData, buildParams = null, result = null) {
    let res = result;

    if (res && res instanceof BD3DFabricMaterial) {
      res.clear();
    }

    if (!data.ribbon) {
      return res;
    }
    if (!res || !(res instanceof BD3DFabricMaterial)) {
      res = new BD3DFabricMaterial();
    }
    res.setType(this.getRibbonMaterialType());

    let quiltTexture = Assets.TEX_RIBBON_COTTON_COLOR,
      quiltNormalMap = Assets.TEX_RIBBON_COTTON_NORMAL;

    const specular = null;
    const ribbonData = data.ribbon;

    if (ribbonData.material === 'satin') {
      quiltTexture = Assets.TEX_RIBBON_SATIN_COLOR;
      quiltNormalMap = Assets.TEX_RIBBON_SATIN_NORMAL;
    }
    quiltNormalMap = null;

    res.setFabricType(ribbonData.material);
    res.setQuiltTexture(quiltTexture);
    res.setQuiltNormalMap(quiltNormalMap);
    res.setSampleSpecularMap(specular);
    res.setColorMultiplier(ribbonData.color);

    return res;
  }

  getMaterial(data, mattressData, buildParams = null, result = null) {
    let res = result;

    if (!res) {
      // res = new BD3DFabricMaterial();
      res = new BD3DSampleFabricMaterial();
    }
    res.setType(MaterialTypes.SAMPLE);
    res.setFabricType(data.material);
    res.setSettings(data.texture);


    res = FabricMaterialUtils.getBorderMaterialFromData(data.texture, data.quilt, buildParams, null, MaterialTypes.SAMPLE, res);
    /*
    if (res instanceof BD3DFabricMaterial) {
      let sampleTransform = res.getSampleTransform();

      if (!sampleTransform) {
        sampleTransform = new SampleTransform();

        res.setSampleTransform(sampleTransform);
      }
      const sampleConfigData = data.texture;
      const sampleID = SampleDA.getID(sampleConfigData);
      const sampleAsset = buildParams.assetCollections.samples.getAssetByName(sampleID);
      const sampleData = sampleAsset.getSampleData();

      if (sampleTransform instanceof SampleTransform) {
        sampleTransform.setSampleConfigData(data.texture);
        sampleTransform.setSampleData(sampleData);
      }

      if (res instanceof BD3DSampleFabricMaterial) {
        res.setSampleAsset(sampleAsset);
        res.setSampleData(sampleData);
        res.setSampleConfigData(sampleConfigData);
      }
    }
    */

    return res;
  }
}
