import * as THREE from 'three';
import Utils from '../utils/Utils';

const BORDER_SCALE_FACTOR = 1;
const DEFAULT_BORDER_SCALE = 5;

function assignColor(color, value, alpha = 1) {
  if (!color) {
    return;
  }
  let x = 0;
  let y = 0;
  let z = 0;
  let w = 1;
  let val = value;
  let t = typeof (val);

  if (t === 'string') {
    if (val.charAt(0) === '#') {
      val = val.substring(1, val.length);
    }
    const HEX = 16;

    val = parseInt(val, HEX);
    t = typeof (val);
  }
  if (t === 'number') {
    const MASK = 0xFF;
    const RSHIFT = 16;
    const GSHIFT = 8;

    x = ((val >> RSHIFT) & MASK) / MASK;
    y = ((val >> GSHIFT) & MASK) / MASK;
    z = ((val) & MASK) / MASK;
    w = alpha;
  }
  if (t === 'object') {
    x = Utils.tryValues(value.r, value.R, value.x, value.X, 0);
    y = Utils.tryValues(value.g, value.G, value.y, value.Y, 0);
    z = Utils.tryValues(value.b, value.B, value.z, value.Z, 0);
    w = Utils.tryValues(value.a, value.A, value.w, value.W, 1) * alpha;
  }
  color.x = x;
  color.y = y;
  color.z = z;
  color.w = w;
}

export default class ThreeSelectionRenderer {
  constructor(renderer) {
    this.renderer = renderer;
    this._softness = 0.25;
    this._fillAlpha = 0.25;
    this._borderScale = DEFAULT_BORDER_SCALE;
  }
  _getShaderPrecision() {
    return 'precision mediump float;';
  }
  // Simple material that renders highlighted objects in a white color
  _getHighlightMaterial(id) {
    const pfx = '_highlightMaterial';
    const name = pfx + id;
    let mat = this[name];

    if (!mat) {
      mat = this[name] = new THREE.RawShaderMaterial({
        vertexShader: [
          this._getShaderPrecision(),
          'attribute vec3 position;',
          'uniform mat4 projectionMatrix;',
          'uniform mat4 modelViewMatrix;',
          'void main() {',
          ' gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);',
          '}'
        ].join('\n'),
        fragmentShader: [
          this._getShaderPrecision(),
          'uniform float highlight;',
          'void main() {',
          ' gl_FragColor = vec4(vec3(highlight), 1.0);',
          '}'
        ].join('\n')
      });
    }

    return mat;
  }

  // Clears the renderer
  clear(color, depth, stencil) {
    if (!(this.renderer)) {
      return;
    }
    this.renderer.clear(color, depth, stencil);
  }

  // Returns the DOM element
  getDOMElement() {
    if (!this.renderer) {
      return null;
    }

    return this.renderer.domElement;
  }

  get domElement() {
    return this.getDOMElement();
  }

  // Returns true if an object is highlighted
  _isHighlighted(obj) {
    if (!obj) {
      return false;
    }

    if (this._objectIsHighlighted) {
      return this._objectIsHighlighted(obj);
    }

    return (obj.userData && obj.userData.highlight);
  }

  // This method overrides the renderer's renderBufferDirect method and replaces the material to a highlight material
  // The render output will be a black image with only the highlighted object in white
  _handleRenderBufferDirectHighlight(camera, fog, geom, material, object, group) {
    const obj = object;
    const grp = group;

    const highlighted = this._isHighlighted(object);

    if (!highlighted && material && material.transparent) {
      return;
    }
    const matID = highlighted ? 1 : 0;

    const mat = this._getHighlightMaterial(matID);

    if (!mat.uniforms) {
      mat.uniforms = {};
    }
    if (!mat.uniforms.highlight) {
      mat.uniforms.highlight = new THREE.Uniform(0.5);
    }
    mat.uniforms.highlight.value = highlighted ? 1.0 : 0.0;
    this._defaultRenderBufferDirect(camera, fog, geom, mat, obj, grp);
  }

  // Renders a scene with all objects in black except the highlighted ones
  _renderHighlighted(scene, camera, target, forceClear) {
    const renderer = this.renderer;

    if (!renderer) {
      return;
    }
    const oldClearColor = renderer.getClearColor();
    const oldClearAlpha = renderer.getClearAlpha();
    const oldAutoClear = renderer.autoClear;

    renderer.setClearColor(0x0, 1.0);

    // renderer.clear(true, true, true);
    const oldRBD = renderer.renderBufferDirect;

    let newRenderBufferDirect = this._renderBufferDirectHighlight;

    if (!newRenderBufferDirect) {
      const that = this;

      newRenderBufferDirect = this._renderBufferDirectHighlight = (cam, fog, geom, material, object, group) => {
        that._handleRenderBufferDirectHighlight(cam, fog, geom, material, object, group);
      };
    }
    this._defaultRenderBufferDirect = oldRBD;
    renderer.renderBufferDirect = newRenderBufferDirect;
    renderer.autoClear = false;
    renderer.render(scene, camera, target, true);

    renderer.setClearColor(oldClearColor, oldClearAlpha);
    renderer.autoClear = oldAutoClear;
    renderer.renderBufferDirect = oldRBD;
  }

  // Renders the 'highlighted' scene to a render target
  _renderHighlightedToTexture(scene, camera, target, forceClear) {
    const {renderer} = this;
    const {domElement} = renderer;
    const pr = this.getPixelRatio();
    const width = domElement.width / pr;
    const height = domElement.height / pr;
    let rtt = this._highlightedRTT;

    if (!rtt) {
      rtt = this._highlightedRTT = new THREE.WebGLRenderTarget();
    }
    if (rtt.width !== width || rtt.height !== height) {
      rtt.setSize(width, height);
    }
    this._renderHighlighted(scene, camera, rtt, forceClear);

    return rtt;
  }

  // Returns the render target with the 'highlighted' scene
  _getHighlightedRTT() {
    return this._highlightedRTT;
  }

  // Returns a scene with only a full-screen quad, used for post processing
  _getQuadScene(material) {
    let scene = this._quadScene;

    if (!scene) {
      scene = this._quadScene = new THREE.Scene();
    }
    let planeGeom = this._planeGeom;

    if (!planeGeom) {
      planeGeom = this._planeGeom = new THREE.PlaneBufferGeometry(2, 2);
    }

    let planeMesh = this._planeMesh;

    if (!planeMesh) {
      planeMesh = this._planeMesh = new THREE.Mesh(planeGeom, material);
    }
    planeMesh.material = material;
    planeMesh.frustumCulled = false;

    scene.add(planeMesh);

    return scene;
  }

  // Returns the texture of a value whether the value parameter is a texture or a render target
  _findTexture(value) {
    if (!value) {
      return null;
    }
    if (value instanceof THREE.Texture) {
      return value;
    }
    if (value instanceof THREE.WebGLRenderTarget) {
      return value.texture;
    }

    return null;
  }

  // mixes blur / glow with selection
  _getMixMaterial(silhouette, glow) {
    let mat = this._mixMaterial;

    if (!mat) {
      mat = this._mixMaterial = new THREE.RawShaderMaterial({
        vertexShader: [
          this._getShaderPrecision(),
          'attribute vec3 position;',
          'varying vec2 v_pos;',
          'void main() {',
          ' v_pos = position.xy;',
          ' gl_Position = vec4(position, 1.0);',
          '}'
        ].join('\n'),
        fragmentShader: [
          this._getShaderPrecision(),
          'varying vec2 v_pos;',
          'uniform sampler2D silhouette;',
          'uniform sampler2D glow;',
          'uniform vec4 innerColor;',
          'uniform vec4 outerColor;',
          'uniform vec2 colorRange;',
          'uniform float fillAlpha;',
          'uniform float softness;',
          'void main() {',
          ' vec2 uv = v_pos.xy * 0.5 + vec2(0.5);',
          ' float pixSilhouette = texture2D(silhouette, uv).r;',
          ' float pixGlow = texture2D(glow, uv).r;',
          ' float invPixSilhouette = 1.0 - (pixSilhouette);',

          // ' float colorT = (pixGlow - colorRange.x) / (colorRange.y - colorRange.x);',
          ' float colorT = smoothstep(colorRange.x, colorRange.y, pixGlow);',
          ' colorT = clamp(colorT, 0.0, 1.0);',

          ' float t = pixGlow * (invPixSilhouette);',
          ' const float minT = 0.0;',
          ' float maxT = softness;',
          ' t = (t - minT) / (maxT - minT);',
          ' t += clamp(pixSilhouette * fillAlpha, 0.0, 1.0);',
          ' t = clamp(t, 0.0, 1.0);',

          ' vec4 resColor = mix(outerColor, innerColor, colorT);',

          ' gl_FragColor = vec4(resColor.xyz, t * resColor.w);',
          '}'
        ].join('\n')
      });
    }
    if (!mat.uniforms) {
      mat.uniforms = {};
    }
    if (!mat.uniforms.silhouette) {
      mat.uniforms.silhouette = new THREE.Uniform();
    }
    if (!mat.uniforms.glow) {
      mat.uniforms.glow = new THREE.Uniform();
    }
    if (!mat.uniforms.innerColor) {
      mat.uniforms.innerColor = new THREE.Uniform();
    }
    if (!mat.uniforms.outerColor) {
      mat.uniforms.outerColor = new THREE.Uniform();
    }
    if (!mat.uniforms.colorRange) {
      mat.uniforms.colorRange = new THREE.Uniform();
    }
    if (!mat.uniforms.softness) {
      mat.uniforms.softness = new THREE.Uniform();
    }
    if (!mat.uniforms.fillAlpha) {
      mat.uniforms.fillAlpha = new THREE.Uniform();
    }
    mat.uniforms.silhouette.value = this._findTexture(silhouette);
    mat.uniforms.glow.value = this._findTexture(glow);
    mat.uniforms.innerColor.value = this.getInnerColor();
    mat.uniforms.outerColor.value = this.getOuterColor();
    mat.uniforms.colorRange.value = this.getColorRange();
    mat.uniforms.softness.value = this.getSoftness();
    mat.uniforms.fillAlpha.value = this.getFillAlpha();

    return mat;
  }

  getFillAlpha() {
    return this._fillAlpha;
  }

  setFillAlpha(v) {
    this._fillAlpha = v;
  }

  get fillAlpha() {
    return this.getFillAlpha();
  }

  set fillAlpha(v) {
    this.setFillAlpha(v);
  }

  getSoftness() {
    return this._softness;
  }

  setSoftness(v) {
    this._softness = v;
  }

  get softness() {
    return this.getSoftness();
  }

  set softness(v) {
    this.setSoftness(v);
  }

  getColorRangeMin() {
    const range = this.getColorRange();

    return range.x;
  }

  getColorRangeMax() {
    const range = this.getColorRange();

    return range.y;
  }

  get colorRangeMin() {
    return this.getColorRangeMin();
  }

  get colorRangeMax() {
    return this.getColorRangeMax();
  }

  setColorRange(min, max) {
    const range = this.getColorRange();

    range.x = min;
    range.y = max;
  }

  setColors(innerColor, outerColor) {
    if (innerColor !== null) {
      assignColor(this.getInnerColor(), innerColor);
    }
    if (outerColor !== null) {
      assignColor(this.getOuterColor(), outerColor);
    }
  }

  setInnerColor(color) {
    this.setColors(color, null);
  }

  setOuterColor(color) {
    this.setColors(null, color);
  }

  getInnerColor() {
    let res = this._innerColor;

    if (res) {
      return res;
    }
    res = new THREE.Vector4(1, 1, 1, 1);
    this._innerColor = res;

    return res;
  }

  getOuterColor() {
    let res = this._outerColor;

    if (res) {
      return res;
    }
    res = new THREE.Vector4(0, 0, 0, 1);
    this._outerColor = res;

    return res;
  }

  getColorRange() {
    let res = this._colorRange;

    if (res) {
      return res;
    }
    res = new THREE.Vector2(0, 1);
    this._colorRange = res;

    return res;
  }

  get innerColor() {
    return this.getInnerColor();
  }

  set innerColor(value) {
    return this.setInnerColor(value);
  }

  get outerColor() {
    return this.getOuterColor();
  }

  set outerColor(value) {
    return this.setOuterColor(value);
  }

  // Returns a shader material that blurs a texture in a given direction
  // This material is used twice in perpendicular directions to get a full blur effect
  _getDirBlurMaterial(texture, dirx, diry) {
    let mat = this._dirBlurMaterial;

    if (!mat) {
      mat = this._dirBlurMaterial = new THREE.RawShaderMaterial({
        vertexShader: [
          this._getShaderPrecision(),
          'attribute vec3 position;',
          'varying vec2 v_pos;',
          'void main() {',
          ' v_pos = position.xy;',
          ' gl_Position = vec4(position, 1.0);',
          '}'
        ].join('\n'),
        fragmentShader: [
          this._getShaderPrecision(),
          '#define ITERATIONS 5',

          'varying vec2 v_pos;',
          'uniform sampler2D texture;',
          'uniform vec2 dir;',
          'void main() {',
          ' vec2 uv = v_pos.xy * vec2(1.0, 1.0) * 0.5 + vec2(0.5);',
          ' vec4 pix = vec4(0.0);',
          ' float inc = 1.0 / float(ITERATIONS);',
          ' float step = -0.5 + inc * 0.5;',
          ' for (int i = 0; i < ITERATIONS; ++i) {',
          '   vec4 smp = texture2D(texture, uv + dir * step);',
          '   pix += smp;',
          '   step += inc;',
          ' }',
          ' pix *= inc;',
          ' gl_FragColor = pix;',
          '}'
        ].join('\n')
      });
    }
    const tex = this._findTexture(texture);

    if (!mat.uniforms) {
      mat.uniforms = {};
    }
    if (!mat.uniforms.texture) {
      mat.uniforms.texture = new THREE.Uniform();
    }
    if (!mat.uniforms.dir) {
      mat.uniforms.dir = new THREE.Uniform(new THREE.Vector2(0.0, 0.0));
    }
    mat.uniforms.dir.value.set(dirx, diry);
    mat.uniforms.texture.value = tex;

    return mat;
  }

  _getBorderScale() {
    if (typeof (this._borderScale) !== 'number') {
      this._borderScale = DEFAULT_BORDER_SCALE;
    }

    return this._borderScale;
  }

  _setBorderScale(v) {
    this._borderScale = v;
  }

  getBorderScale() {
    return this._getBorderScale() * BORDER_SCALE_FACTOR;
  }

  setBorderScale(v) {
    this._setBorderScale(v / BORDER_SCALE_FACTOR);
  }

  get borderScale() {
    return this.getBorderScale();
  }

  set borderScale(v) {
    this.setBorderScale(v);
  }

  // Get any internal render target by name
  _getRTT(name, updateSize = true) {
    if (!name) {
      return null;
    }
    let rttmap = this._rttmap;

    if (!rttmap) {
      rttmap = this._rttmap = {};
    }
    let rtt = rttmap[name];

    if (!rtt) {
      rtt = rttmap[name] = new THREE.WebGLRenderTarget();
    }
    if (updateSize) {
      const {renderer} = this;
      const {domElement} = renderer;
      const pd = this.getPixelRatio();
      const w = (domElement.width / pd) | 0;
      const h = (domElement.height / pd) | 0;

      rtt.setSize(w, h);
    }

    return rtt;
  }
  getPixelRatio() {
    const {renderer} = this;

    if (!renderer) {
      return 1.0;
    }

    return renderer.getPixelRatio() || 1;
  }

  getAbsoluteWidth() {
    const elem = this.getDOMElement();

    return elem ? elem.width : 0;
  }

  getAbsoluteHeight() {
    const elem = this.getDOMElement();

    return elem ? elem.height : 0;
  }

  getWidth() {
    const pd = this.getPixelRatio();
    const absW = this.getAbsoluteWidth();

    return absW / pd;
  }

  getHeight() {
    const pd = this.getPixelRatio();
    const absH = this.getAbsoluteHeight();

    return absH / pd;
  }

  // Renders textures needed for highlight seperately (highlight & blur of highlight)
  renderHighlightTextures(scene, camera, target, forceClear) {
    const width = this.getAbsoluteWidth();
    const height = this.getAbsoluteHeight();
    const pr = this.getPixelRatio();

    const rttHighlight = this._renderHighlightedToTexture(scene, camera, target, forceClear);
    const rttBlurX = this._getRTT('blurx');
    const rttBlurY = this._getRTT('blury');
    const borderScale = this._getBorderScale() * pr;

    let dirBlurMat, qscene;

    // Render the x-blur version of the 'highlighted' scene
    dirBlurMat = this._getDirBlurMaterial(rttHighlight, 0, borderScale / height);
    qscene = this._getQuadScene(dirBlurMat);
    this.renderer.render(qscene, camera, rttBlurX, true);

    // Render the y-blur version of the x-blur version of the 'highlighted' scene
    // (full blur)
    dirBlurMat = this._getDirBlurMaterial(rttBlurX, borderScale / width, 0);
    qscene = this._getQuadScene(dirBlurMat);
    this.renderer.render(qscene, camera, rttBlurY, true);
  }

  // Uses the 'highlighted' scene texture to mask the blurred 'highlighted'
  // scene texture to create a halo effect
  renderHighlight(scene, camera, target, forceClear) {
    const rttHighlight = this._getHighlightedRTT();
    const rttBlurY = this._getRTT('blury');
    const mixMat = this._getMixMaterial(rttHighlight, rttBlurY);
    const qscene = this._getQuadScene(mixMat);

    mixMat.transparent = true;
    this.renderer.render(qscene, camera, target, forceClear);
  }

  render(scene, camera, target = null, forceClear = false) {
    this.renderHighlightTextures(scene, camera, target, forceClear);

    this.renderHighlight(scene, camera, target, forceClear);
  }
}


// ---
