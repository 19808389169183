/* global THREE */
// import * as THREE from 'three';


const TYPE_UNDEFINED = 'undefined';
const TYPE_NUMBER = 'number';

function tryValues(...args) {
  const l = args.length;

  for (let i = 0; i < l; ++i) {
    const a = args[i];

    if (a !== null) {
      const t = typeof (a);

      if (t === TYPE_NUMBER) {
        if (!isNaN(a)) {
          return a;
        }
      } else if (t !== TYPE_UNDEFINED) {
        return a;
      }
    }
  }

  return null;
}

/* export default */class ThreeGeomUtils {
  static _error(msg, params) {
    if (params && params.onerror) {
      params.onerror(msg);

      return;
    }
    console.error(msg);
  }

  static _warn(msg, params) {
    if (params && params.onwarning) {
      params.onwarning(msg);

      return;
    }
  }

  static _calcBGTangents(bg, params = null) {
    let normalAttributeName = 'normal';
    let tangentAttributeName = 'tangent';
    let bitangentAttributeName = 'bitangent';
    let uvAttributeName = 'uv';
    let posAttributeName = 'position';

    let calcTangents = true;
    let calcBitangents = true;
    let calcHandedness = true;

    if (params) {
      normalAttributeName = tryValues(params.normalAttributeName, normalAttributeName);
      tangentAttributeName = tryValues(params.tangentAttributeName, tangentAttributeName);
      bitangentAttributeName = tryValues(params.bitangentAttributeName, bitangentAttributeName);
      uvAttributeName = tryValues(params.uvAttributeName, uvAttributeName);
      posAttributeName = tryValues(params.positionAttributeName, posAttributeName);

      calcTangents = tryValues(params.calcTangents, calcTangents);
      calcBitangents = tryValues(params.calcBitangents, calcHandedness);
      calcHandedness = tryValues(params.calcHandedness, calcHandedness);
    }

    if (!calcTangents && !calcBitangents) {
      return;
    }

    const normalAttribute = bg.attributes[normalAttributeName];
    let tangentAttribute = bg.attributes[tangentAttributeName];
    let bitangentAttribute = bg.attributes[bitangentAttributeName];
    const uvAttribute = bg.attributes[uvAttributeName];
    const posAttribute = bg.attributes[posAttributeName];

    if (!uvAttribute || !posAttribute) {
      this._error('Can\'t calculate tangents, need position & uv attributes', params);

      return;
    }
    const posArr = posAttribute.array;

    if (!posArr) {
      this._error('Position buffer has no array', params);

      return;
    }

    const _1 = 1;
    const _2 = 2;
    const _3 = 3;
    const _4 = 4;

    const numV = posArr.length / posAttribute.itemSize;

    if (calcTangents) {
      const tangentElementCount = calcHandedness ? _4 : _3;
      const tangentArrayLength = numV * tangentElementCount;

      if (!tangentAttribute) {
        tangentAttribute = new THREE.BufferAttribute(new Float32Array(tangentArrayLength), tangentElementCount);
        bg.addAttribute(tangentAttributeName, tangentAttribute);
      } else if (!tangentAttribute.array || tangentAttribute.array.length !== tangentArrayLength) {
        tangentAttribute.array = new Float32Array(tangentArrayLength);
        tangentAttribute.needsUpdate = true;
      }
    }

    if (calcBitangents) {
      const bitangentElementCount = _3;
      const bitangentArrayLength = numV * bitangentElementCount;

      if (!bitangentAttribute) {
        bitangentAttribute = new THREE.BufferAttribute(new Float32Array(bitangentArrayLength), bitangentElementCount);
        bg.addAttribute(bitangentAttributeName, bitangentAttribute);
      } else if (!bitangentAttribute.array || bitangentAttribute.array.length !== bitangentArrayLength) {
        bitangentAttribute.array = new Float32Array(bitangentArrayLength);
        bitangentAttribute.needsUpdate = true;
      }
    }

    let indexBuffer = null;

    if (bg.getIndex) {
      indexBuffer = bg.getIndex();
    } else {
      indexBuffer = bg.attributes.index;
    }

    if (indexBuffer) {
      const indexArr = indexBuffer.array;

      if (!indexArr) {
        this._error('Index buffer has no array', params);

        return;
      }
      const num = indexArr.length;

      if (num === 0) {
        return;
      }

      for (let i = 0; i < num; i += _3) {
        const i1 = indexArr[i];
        const i2 = indexArr[i + _1];
        const i3 = indexArr[i + _2];

        this._calcBGTriangleTangents(bg, i1, i2, i3,
          posAttribute, uvAttribute, normalAttribute, tangentAttribute, bitangentAttribute,
          params);
      }
    } else {
      for (let i = 0; i < numV; i += _3) {
        this._calcBGTriangleTangents(bg, i, i + _1, i + _2,
          posAttribute, uvAttribute, normalAttribute, tangentAttribute, bitangentAttribute,
          params);
      }
    }
  }

  static _calcBGTriangleTangents(bg, i1, i2, i3, posAtt, uvAtt, nrmAtt, tgtAtt, btgAtt, params) {
    this._calcBGTriangleTangentsFrom(bg, i1, i2, i3, posAtt, uvAtt, nrmAtt, tgtAtt, btgAtt, params);
    this._calcBGTriangleTangentsFrom(bg, i2, i1, i3, posAtt, uvAtt, nrmAtt, tgtAtt, btgAtt, params);
    this._calcBGTriangleTangentsFrom(bg, i3, i1, i2, posAtt, uvAtt, nrmAtt, tgtAtt, btgAtt, params);
  }

  static _calcBGTriangleTangentsFrom(bg, i1, i2, i3, posAtt, uvAtt, nrmAtt, tgtAtt, btgAtt, params) {
    const X = 0;
    const Y = 1;
    const Z = 2;
    const W = 3;

    const _4 = 4;

    const posArr = posAtt.array;
    const posItemSize = posAtt.itemSize;
    const posOff1 = i1 * posItemSize;
    const posOff2 = i2 * posItemSize;
    const posOff3 = i3 * posItemSize;

    const uvArr = uvAtt.array;
    const uvItemSize = uvAtt.itemSize;
    const uvOff1 = i1 * uvItemSize;
    const uvOff2 = i2 * uvItemSize;
    const uvOff3 = i3 * uvItemSize;

    const pos1x = posArr[posOff1 + X];
    const pos1y = posArr[posOff1 + Y];
    const pos1z = posArr[posOff1 + Z];

    const pos2x = posArr[posOff2 + X];
    const pos2y = posArr[posOff2 + Y];
    const pos2z = posArr[posOff2 + Z];

    const pos3x = posArr[posOff3 + X];
    const pos3y = posArr[posOff3 + Y];
    const pos3z = posArr[posOff3 + Z];

    const tex1x = uvArr[uvOff1 + X];
    const tex1y = uvArr[uvOff1 + Y];

    const tex2x = uvArr[uvOff2 + X];
    const tex2y = uvArr[uvOff2 + Y];

    const tex3x = uvArr[uvOff3 + X];
    const tex3y = uvArr[uvOff3 + Y];

    let orthogonalizeTangent = true;
    let orthogonalizeBitangent = true;
    let normalizeTangent = true;
    let normalizeBitangent = true;
    let calcTangents = true;
    let calcBitangents = true;
    let calcHandedness = true;

    if (params) {
      orthogonalizeTangent = tryValues(params.orthogonalizeTangent, params.orthogonalize, orthogonalizeTangent);
      orthogonalizeBitangent = tryValues(params.orthogonalizeBitangent, params.orthogonalize, orthogonalizeBitangent);
      normalizeTangent = tryValues(params.normalizeTangent, params.normalize, normalizeTangent);
      normalizeBitangent = tryValues(params.normalizeBitangent, params.normalize, normalizeBitangent);
      calcTangents = tryValues(params.calcTangents, calcTangents);
      calcBitangents = tryValues(params.calcBitangents, calcBitangents);
      calcHandedness = tryValues(params.calcHandedness, calcHandedness);
    }

    if (tgtAtt.itemSize < _4) {
      calcHandedness = false;
    }

    const x1 = pos2x - pos1x;
    const x2 = pos3x - pos1x;
    const y1 = pos2y - pos1y;
    const y2 = pos3y - pos1y;
    const z1 = pos2z - pos1z;
    const z2 = pos3z - pos1z;

    const u1 = tex2x - tex1x;
    const u2 = tex3x - tex1x;
    const v1 = tex2y - tex1y;
    const v2 = tex3y - tex1y;

    const det = (u1 * v2 - u2 * v1);

    let tx = 0, ty = 0, tz = 0, tw = 1.0;
    let bx = 0, by = 0, bz = 0;

    if (det !== 0) {
      const invDet = 1.0 / det;

      if (calcTangents) {
        tx = (v2 * x1 - v1 * x2) * invDet;
        ty = (v2 * y1 - v1 * y2) * invDet;
        tz = (v2 * z1 - v1 * z2) * invDet;
        tw = 1.0;
      }

      if (calcBitangents || (calcTangents && calcHandedness)) {
        bx = (u1 * x2 - u2 * x1) * invDet;
        by = (u1 * y2 - u2 * y1) * invDet;
        bz = (u1 * z2 - u2 * z1) * invDet;
      }
    }

    const hasNormal = nrmAtt && nrmAtt.array;
    let nx, ny, nz;
    const NORMALIZE_TOLL = 0.001;

    if (hasNormal) {
      const nrmArr = nrmAtt.array;
      const nrmItemSize = nrmAtt.itemSize;
      const nrmOff = i1 * nrmItemSize;

      nx = nrmArr[nrmOff + X];
      ny = nrmArr[nrmOff + Y];
      nz = nrmArr[nrmOff + Z];

      let nLen = nx * nx + ny * ny + nz * nz;
      const diff = nLen > 1 ? nLen - 1 : 1 - nLen;

      if (nLen !== 0 && diff > NORMALIZE_TOLL) {
        nLen = 1 / Math.sqrt(nLen);

        nx *= nLen;
        ny *= nLen;
        nz *= nLen;
      }
    }

    if (orthogonalizeTangent && hasNormal) {
      const dist = tx * nx + ty * ny + tz * nz;

      if (dist !== 0) {
        tx -= nx * dist;
        ty -= ny * dist;
        tz -= nz * dist;
      }
    }

    if (orthogonalizeBitangent && hasNormal) {
      const dist = bx * nx + by * ny + bz * nz;

      if (dist !== 0) {
        bx -= nx * dist;
        by -= ny * dist;
        bz -= nz * dist;
      }
    }

    if (normalizeTangent && hasNormal) {
      let nLen = tx * tx + ty * ty + tz * tz;
      const diff = nLen > 1 ? nLen - 1 : 1 - nLen;

      if (nLen !== 0 && diff > NORMALIZE_TOLL) {
        nLen = 1 / Math.sqrt(nLen);
        tx *= nLen;
        ty *= nLen;
        tz *= nLen;
      }
    }

    if (normalizeBitangent && hasNormal) {
      let nLen = bx * bx + by * by + bz * bz;
      const diff = nLen > 1 ? nLen - 1 : 1 - nLen;

      if (nLen !== 0 && diff > NORMALIZE_TOLL) {
        nLen = 1 / Math.sqrt(nLen);
        bx *= nLen;
        by *= nLen;
        bz *= nLen;
      }
    }

    if (calcTangents && calcHandedness && hasNormal) {
      let hnx = nx;
      let hny = ny;
      let hnz = nz;

      if (!hasNormal) {
        // no normal in attribute, use triangle normal
        hnx = y1 * z2 - z1 * y2;
        hny = z1 * x2 - x1 * z2;
        hnz = x1 * y2 - y1 * x2;
      }
      const cx = by * hnz - bz * hny;
      const cy = bz * hnx - bx * hnz;
      const cz = bx * hny - by * hnx;
      const dot = cx * tx + cy * ty + cz * tz;

      tw = dot < 0 ? -1 : 1;
    }

    if (calcTangents) {
      const arr = tgtAtt.array;
      const off = i1 * tgtAtt.itemSize;

      arr[off + X] = tx;
      arr[off + Y] = ty;
      arr[off + Z] = tz;

      if (calcHandedness) {
        arr[off + W] = tw;
      }
    }

    if (calcBitangents) {
      const arr = btgAtt.array;
      const off = i1 * btgAtt.itemSize;

      arr[off + X] = bx;
      arr[off + Y] = by;
      arr[off + Z] = bz;
    }
  }

  /**
   * @method calcTangents
   * @description Calculates the tangent & bitangent vectors of each geometry vertex
   * @static
   * @param {THREE.BufferGeometry} geom - Geometry
   * @param {object} params - optional params object:
      onerror {function}: callback for errors
      onwarning {function}: callback for warnings
      normalAttributeName {string}: optional name of the normal buffer attribute (default = 'normal')
      tangentAttributeName {string}: optional name of the tangent buffer attribute (default = 'tangent')
      bitangentAttributeName {string}: optional name of the bitangent buffer attribute (default = 'bitangent')
      uvAttributeName {string}: optional name of the uv buffer attribute (default = 'uv')
      positionAttributeName {string}: optional name of the position buffer attribute (default = 'position')

      calcTangents {boolean}: (optional, default=true)calculates the tangent attributes if true
      calcBitangents {boolean}: (optional, default=true)calculates the bitangent attributes if true
      calcHandedness {boolean}: (optional, default=true) calculates the handedness by including a 4th component to the tangent vector
        (w = 1 or w = -1)

      orthogonalizeTangent {boolean} - (optional, default = true) Orthogonalizes the tangent vector with the normal vector
      orthogonalizeBitangent {boolean} - (optional, default = true) Orthogonalizes the bitangent vector with the normal vector
      orthogonalize {boolean}: fallback orthogonalize param if the orthogonalizeTangent or orthogonalizeBitangent param is not set

      normalizeTangent {boolean} - (optional, default = true) Normalizes the tangent vector
      normalizeBitangent {boolean} - (optional, default = true) Normalizes the bitangent vector
      normalize {boolean} - Fallback normalize param if the normalizeTangent or normalizeBitangent params are not set

    @return {void}
  */
  static calcTangents(geom, params = null) {
    if (!geom) {
      return;
    }

    if (geom instanceof THREE.BufferGeometry) {
      this._calcBGTangents(geom, params);
    }
  }
}

window.ThreeGeomUtils = ThreeGeomUtils;
