import BoxSpringControllerPlugin from './BoxSpringControllerPlugin';

const ControllerTypes = {
  boxspring: BoxSpringControllerPlugin
};

function isBoxSpringController(value) {
  return value instanceof BoxSpringControllerPlugin;
}

export default class ControllerPluginManager {
  constructor() {
    this._controllerPlugins = null;
    this._controllerPluginsMap = null;
  }

  getNumControllers() {
    const arr = this._controllerPlugins;

    if (!arr) {
      return 0;
    }

    return arr.length;
  }

  get numControllers() {
    return this.getNumControllers();
  }

  getBoxSpringController() {
    return this.findController(isBoxSpringController);
  }

  get boxSpringController() {
    return this.getBoxSpringController();
  }


  findController(condition, params = null) {
    const index = this.findControllerIndex(condition, params);

    if (index < 0) {
      return null;
    }

    return this.getController(index);
  }

  findControllerIndex(condition, params = null) {
    if (!condition) {
      return -1;
    }
    const num = this.getNumControllers();

    for (let i = 0; i < num; ++i) {
      const contr = this.getController(i);

      if (condition(contr, i, num, params)) {
        return i;
      }
    }

    return -1;
  }

  getController(key) {
    if (key === null) {
      return null;
    }
    const t = typeof (key);

    if (t === 'undefined') {
      return null;
    }
    if (t === 'number') {
      const arr = this._controllerPlugins;

      if (!arr) {
        return null;
      }

      return arr[key | 0];
    } else if (t === 'string') {
      const map = this._controllerPluginsMap;

      if (!map) {
        return null;
      }

      return map[key];
    }

    return null;
  }

  _addControllerPluginToMap(data, inst) {
    if (data.name || data.keys || data.id) {
      if (!this._controllerPluginsMap) {
        this._controllerPluginsMap = {};
      }
      if (data.name) {
        this._controllerPluginsMap[data.name] = inst;
      }
      if (data.id) {
        this._controllerPluginsMap[data.id] = inst;
      }
      const keys = data.keys;

      if (keys) {
        const numKeys = keys.length;

        for (let i = 0; i < numKeys; ++i) {
          const k = keys[i];

          if (k) {
            this._controllerPluginsMap[k] = inst;
          }
        }
      }
    }
  }

  _addControllerPlugin(data, mattressConfig) {
    if (!data) {
      return;
    }
    if (data.type === 'controllerplugin') {
      const pluginName = data.plugin;

      if (!pluginName) {
        return;
      }
      const CT = ControllerTypes[pluginName.toLowerCase()];

      if (!CT) {
        return;
      }
      const inst = new CT({enabled: true, data: data, mattressConfig: mattressConfig});
      let plugins = this._controllerPlugins;

      if (!plugins) {
        plugins = this._controllerPlugins = [];
      }
      plugins.push(inst);

      this._addControllerPluginToMap(data, inst);
    }
  }

  _removeControllerPlugins() {
    this._controllerPluginsMap = null;

    const plugins = this._controllerPlugins;
    const num = plugins ? plugins.length : 0;

    for (let i = 0; i < num; ++i) {
      const p = plugins[i];

      if (p) {
        p.dispose();
      }
    }
    if (plugins) {
      plugins.length = 0;
    }
  }

  setControllerProperties(id, properties) {
    if (!id) {
      return;
    }
    const map = this._controllerPluginsMap;

    if (!map) {
      return;
    }
    const contr = map[id];

    if (!contr) {
      return;
    }

    contr.setProperties(properties, properties);
  }

  setControllerProperty(id, property, value) {
    if (!id) {
      return;
    }
    const map = this._controllerPluginsMap;

    if (!map) {
      return;
    }
    const contr = map[id];

    if (!contr) {
      return;
    }

    contr.setProperty(property, value);
  }

  getControllerProperty(id, property, fallback) {
    if (!id) {
      return fallback;
    }
    const map = this._controllerPluginsMap;

    if (!map) {
      return fallback;
    }
    const contr = map[id];

    if (!contr) {
      return fallback;
    }

    return contr.getProperty(property, fallback);
  }

  setConfig(data) {
    this._removeControllerPlugins();

    const controllers = data ? data.controllers : null;
    const num = controllers ? controllers.length : 0;

    for (let i = 0; i < num; ++i) {
      const contrData = controllers[i];

      this._addControllerPlugin(contrData, data);
    }
  }
  notifyPropertyChanged(single, property, value, oldValue, params, mattressConfig) {
    const plugins = this._controllerPlugins;
    const num = plugins ? plugins.length : 0;

    for (let i = 0; i < num; ++i) {
      const plugin = plugins[i];

      if (plugin && plugin.notifyPropertyChanged) {
        plugin.notifyPropertyChanged(single, property, value, oldValue, params, mattressConfig);
      }
    }
  }
  /*
  _handleChangeConfig(evt) {
    this._removeControllerPlugins();

    const cfg = this._controller.getMattressConfig();
    const data = cfg ? cfg.getData() : null;
    const controllers = data ? data.controllers : null;
    const num = controllers ? controllers.length : 0;

    for (let i = 0; i < num; ++i) {
      const contrData = controllers[i];

      this._addControllerPlugin(contrData);
    }

    return;
  }
  */
}
