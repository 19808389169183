const SCALEMODE_FIT = 'fit';
const SCALEMODE_FILL = 'fill';
const SCALEMODE_STRETCH = 'stretch';
const SCALEMODE_FIT_V = 'fit-v';
const SCALEMODE_FIT_H = 'fit-h';

const DEFAULT_SCALEMODE = SCALEMODE_FIT;

const DEFAULT_ALIGN_X = 0.5;
const DEFAULT_ALIGN_Y = 0.5;

export const Align = {
  ALIGN_LEFT: 0,
  ALIGN_CENTER: 0.5,
  ALIGN_RIGHT: 1,
  ALIGN_TOP: 0,
  ALIGN_MIDDLE: 0.5,
  ALIGN_BOTTOM: 1
};

export const ScaleMode = {
  SCALEMODE_FIT,
  SCALEMODE_FILL,
  SCALEMODE_STRETCH,
  SCALEMODE_FIT_V,
  SCALEMODE_FIT_H
};

export {
  DEFAULT_SCALEMODE,
  DEFAULT_ALIGN_X,
  DEFAULT_ALIGN_Y
};

/* eslint-disable */
const scaleModeAliasMap = {
  fill: ScaleMode.SCALEMODE_FILL,
  cover: ScaleMode.SCALEMODE_FILL,
  fit: ScaleMode.SCALEMODE_FIT,
  contain: ScaleMode.SCALEMODE_FIT,
  stretch: ScaleMode.SCALEMODE_STRETCH,
};
{
  function capitalize(s) {
    const first = s.charAt(0);
    const rest = s.substring(1, s.length);
    return first.toUpperCase() + rest;
  }
  // Aliases for fit-x and fit-y
  const map = scaleModeAliasMap;
  const directions = ['h', 'v'];
  const scaleModes = [SCALEMODE_FIT_H, SCALEMODE_FIT_V];
  const dirAliases = {
    h: ['h', 'horizontal', 'x'],
    v: ['v', 'vertical', 'y']
  };
  const names = ['contain','fit'];
  for (let i = 0, numDirs = directions.length; i < numDirs; ++i) {
    const dir = directions[i];
    const scaleMode = scaleModes[i];
    const aliases = dirAliases[dir];
    for (let j = 0, numNames = names.length; j < numNames; ++j) {
      const name = names[j];
      for (let k = 0, numAliases = aliases.length; k < numAliases; ++k) {
        const alias = aliases[j];
        map[`${name}-${alias}`] = scaleMode;
        map[`${name}_${alias}`] = scaleMode;
        map[`${name.toLowerCase()}${capitalize(alias)}`] = scaleMode;
        map[`${alias}-${name}`] = scaleMode;
        map[`${alias}_${name}`] = scaleMode;
        map[`${alias.toLowerCase()}${capitalize(name)}`] = scaleMode;
      }
    }
  }
}
/* eslint-enable */
const alignMap = {
  left: Align.ALIGN_LEFT,
  top: Align.ALIGN_TOP,
  middle: Align.ALIGN_MIDDLE,
  center: Align.ALIGN_CENTER,
  right: Align.ALIGN_RIGHT,
  bottom: Align.ALIGN_BOTTOM
};

export function getAlignValue(align, defaultValue) {
  let a = align;
  let t = typeof (a);

  if (t === 'object') {
    a = a.valueOf();
    t = typeof (a);
  }
  if (t === 'string') {
    let newA = alignMap[a.toLowerCase()];

    if (typeof (newA) !== 'number') {
      newA = parseFloat(a);
    }
    a = newA;
  }
  if (t === 'number') {
    if (isNaN(a)) {
      return defaultValue;
    }

    return a;
  }

  return defaultValue;
}

export function getScaleModeValue(scaleMode, defaultValue) {
  return (scaleMode && scaleMode.toLowerCase && scaleModeAliasMap[scaleMode.toLowerCase()]) || defaultValue;
}
