import GraphicsComponent from '../../common/component/graphics/GraphicsComponent';

import * as THREE from 'three';

export default class ThreeComponent extends GraphicsComponent {
  constructor(params = null) {
    super(params);
    let rendererParams = null;

    if (params) {
      rendererParams = params.rendererParams;
    }

    this._rendererParams = rendererParams;
  }

  _createRenderer() {
    let params = this._rendererParams;

    if (!params) {
      params = {
        antialias: true,
        alpha: true
      };
    }

    const r = new THREE.WebGLRenderer(params);

    return r;
  }

  getRenderer(create = true) {
    let r = this._renderer;

    if (r || create === false) {
      return r;
    }
    r = this._renderer = this._createRenderer();

    const w = this.getWidth();
    const h = this.getHeight();
    const p = this.getPixelRatio();

    r.setPixelRatio(p);
    if (w !== null && h !== null &&
      typeof (w) !== 'undefined' && typeof (h) !== 'undefined') {
      r.setSize(w, h);
    }

    return r;
  }

  get renderer() {
    return this.getRenderer(false);
  }

  _changedPixelRatio() {
    const pr = this.getPixelRatio();
    const renderer = this.getRenderer(false);

    if (!renderer) {
      return;
    }

    renderer.setPixelRatio(pr);
  }

  _changedSize(width, height) {
    const renderer = this.getRenderer(false);

    if (!renderer) {
      return;
    }
    renderer.setSize(width, height);
  }

  getCanvas(create = true) {
    const r = this.getRenderer(create);

    if (!r) {
      return null;
    }

    return r.domElement;
  }
}
