import Property from './Property';

const TYPE_GROUP = 'group';

export default class PropertyGroup extends Property {
  constructor(data) {
    super(data);
    const d = data || {};

    if (!this.data) {
      this.data = d;
    }

    d.type = TYPE_GROUP;
  }

  getProperties() {
    return this.get('properties');
  }

  get properties() {
    return this.getProperties();
  }

  getType() {
    return super.getType() || TYPE_GROUP;
  }
}
