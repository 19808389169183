import Node3D from './Node3D';

export default class ContainerNode3D extends Node3D {
  constructor(children = null, transform = null) {
    super(transform);
    this.addChildren(children);
  }

  addChildren(newChildren) {
    if (!newChildren) {
      return;
    }
    const num = newChildren.length;

    if (num === 0) {
      return;
    }

    for (let i = 0; i < num; ++i) {
      this.addChild(newChildren[i]);
    }
  }

  addChild(child) {
    if (!this._allowChild(child)) {
      return;
    }

    let children = this._children;

    if (!children) {
      children = this._children = [];
    }

    children.push(child);
  }

  getChild(index) {
    if (index < 0) {
      return null;
    }
    const children = this._children;

    if (!children) {
      return null;
    }

    return children[index];
  }

  _allowChild(child) {
    if (!child) {
      return false;
    }
    if (!(child instanceof Node3D)) {
      return false;
    }

    if (child === this) {
      // I'm not going to write an intelligent 'infinite recursion' check.
      // It's the responability of the user of this class to use it safely.
      return false;
    }

    return !this.hasChild(child);
  }

  hasChild(child) {
    if (!child) {
      return false;
    }
    const children = this._children;

    if (!children) {
      return false;
    }

    return children.indexOf(child, 0) >= 0;
  }

  setChild(index, child) {
    if (index < 0) {
      return;
    }
    if (!this._allowChild(child)) {
      return;
    }
    let children = this._children;

    if (!children) {
      children = this._children = [];
    }

    children[index] = child;
  }

  removeChild(child) {
    const children = this._children;

    if (!children) {
      return;
    }
    let index = -1;

    if (typeof (child) === 'number') {
      index = child;
    } else {
      index = children.indexOf(child, 0);
    }

    if (index < 0) {
      return;
    }
    children.splice(index, 1);
  }

  removeChildren() {
    const c = this._children;

    if (!c) {
      return;
    }

    c.length = 0;
  }

  getNumChildren() {
    if (!this._children) {
      return 0;
    }

    return this._children.length;
  }

  getChildren() {
    return this._children;
  }

  setChildren(c) {
    this._children = c;
  }

  get numChildren() {
    return this.getNumChildren();
  }

  get children() {
    return this.getChildren();
  }

  set children(c) {
    this.setChildren(c);
  }
}
