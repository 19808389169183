import * as THREE from 'three';
import ThreeMaterial from './ThreeMaterial';

let instance = null;

const vertexShaderSource = [
  // *
  'precision mediump float;',
  'attribute vec3 position;',
  'attribute vec3 normal;',

  'uniform mat4 modelMatrix;',
  'uniform mat4 viewMatrix;',
  'uniform mat4 projectionMatrix;',
  'uniform mat4 cameraWorldMatrix;',
  'uniform mat3 normalMatrix;',
  // */
  'uniform vec3 objectScale;',
  'uniform vec2 objectSize;',
  'uniform vec2 fabricSize;',
  'uniform vec2 imageSize;',
  'uniform vec2 imageOffset;',
  'uniform vec2 imageLocalAlignment;',
  'uniform vec2 imageAlignment;',

  'varying vec2 v_fabricUV;',
  'varying vec2 v_imageUV;',
  'varying float v_front;',
  'varying vec3 v_worldNormal;',

  'void main() {',
  ' vec4 pos = vec4(position, 1.0);',
  ' vec3 scaledPos = pos.xyz * objectScale;',
  ' v_fabricUV = ((scaledPos.xy * vec2(1, -1)) / fabricSize);',
  ' vec2 handleSize = objectSize.xy * objectScale.xy;',
  ' vec2 imgAlign = imageAlignment * 2.0 - vec2(1);',
  ' vec2 offset = handleSize * 0.5 * imgAlign + imageOffset;',
  ' v_imageUV = ((scaledPos.xy * vec2(1, -1) + (imageSize * imageLocalAlignment) - offset) / imageSize);',
  ' v_front = normal.z;',

  ' vec3 nrm = (normalMatrix * normal);',
  ' nrm = normalize((cameraWorldMatrix * vec4(nrm, 0)).xyz);',
  ' v_worldNormal = nrm;',

  // ' v_worldNormal = (modelMatrix * vec4(normal, 0.0)).xyz;',

  ' pos = projectionMatrix * viewMatrix * modelMatrix * pos;',
  ' gl_Position = pos;',
  '}'
].join('\n');

const fragmentShaderSource = [
  'precision mediump float;',

  'varying vec2 v_fabricUV;',
  'varying vec2 v_imageUV;',
  'varying float v_front;',
  'varying vec3 v_worldNormal;',

  'uniform sampler2D fabric;',
  'uniform sampler2D image;',
  'uniform float imageValue;',

  'void main() {',
  ' vec2 fabricUV = mod(v_fabricUV, vec2(1.0));',
  ' vec2 imageUV = clamp(v_imageUV, vec2(0.0), vec2(1.0));',
  ' vec4 fabricSample = texture2D(fabric, fabricUV);',
  ' vec4 imageSample = texture2D(image, imageUV);',

  ' const float minFront = -0.1;',
  ' const float maxFront = 0.1;',
  ' float f = (v_front - minFront) / (maxFront - minFront);',
  ' f = clamp(f, 0.0, 1.0);',
  ' float a = imageValue * f * imageSample.a * (1.0 - step(1.0, imageUV.x)) * (1.0 - step(imageUV.x, 0.0)) * (1.0 - step(1.0, imageUV.y)) * (1.0 - step(imageUV.y, 0.0));',
  ' vec4 res = fabricSample;',
  ' res.xyz = mix(fabricSample.xyz, imageSample.xyz, a);',

  ' const float minShading = -1.5;',
  ' const float maxShading = 1.0;',
  ' vec3 worldNormal = normalize(v_worldNormal);',
  ' float shading = (worldNormal.y - minShading) / (maxShading - minShading);',
  ' shading = clamp(shading, 0.0, 1.0);',
  ' shading *= 1.25;',
  ' shading = clamp(shading, 0.0, 1.0);',
  ' res.xyz *= shading;',

  ' gl_FragColor = res;',
  '}'
].join('\n');


function initInstance(inst) {
  const fabricSize = 4;
  const imageSize = 4.4;

  const DEFAULT_OBJECT_WIDTH = 6;
  const DEFAULT_OBJECT_HEIGHT = 22.8561;
  const DEFAULT_OBJECT_DEPTH = 2.2372;

  inst.setUniformValue('fabricSize', new THREE.Vector2(fabricSize, fabricSize));
  inst.setUniformValue('imageSize', new THREE.Vector2(imageSize, imageSize));
  inst.setUniformValue('imageOffset', new THREE.Vector2(0, 0));
  inst.setUniformValue('imageAlignment', new THREE.Vector2(0.5, 0.5));
  inst.setUniformValue('imageLocalAlignment', new THREE.Vector2(0.5, 0.5));
  inst.setUniformValue('objectScale', new THREE.Vector3(1, 1, 1));
  inst.setUniformValue('objectSize', new THREE.Vector3(
    DEFAULT_OBJECT_WIDTH,
    DEFAULT_OBJECT_HEIGHT,
    DEFAULT_OBJECT_DEPTH
  ));
}

export default class ThreeHandleLogoMaterial extends ThreeMaterial {
  constructor(args) {
    super(args);
    this.vertexShader = this.getVertexShaderSource();
    this.fragmentShader = this.getFragmentShaderSource();

    initInstance(this);
  }

  assignObjectScale(object) {
    if (!object) {
      return;
    }
    let o = object, scaleX = 1, scaleY = 1, scaleZ = 1;

    while (o) {
      scaleX *= o.scale.x;
      scaleY *= o.scale.y;
      scaleZ *= o.scale.z;
      o = o.parent;
    }

    this.setObjectScale(scaleX, scaleY, scaleZ);

  }
  _setVec2(name, x, y, defX = 0, defY = 0) {
    let vec = this.getUniformValue(name);
    let X = defX;
    let Y = defY;

    if (x instanceof THREE.Vector2) {
      X = x.x;
      Y = y.y;
    } else {
      X = typeof (x) === 'number' && !isNaN(x) ? x : 0;
      Y = typeof (y) === 'number' && !isNaN(y) ? y : 0;
    }

    if (vec) {
      vec.x = X;
      vec.y = Y;
    } else {
      vec = new THREE.Vector2(X, Y);
      this.setUniformValue(name, vec);
    }
  }

  setImageOffset(x, y) {
    this._setVec2('imageOffset', x, y);
  }

  setLogoAlignment(x, y) {
    this._setVec2('imageLocalAlignment', x, y, 0.5, 0.5);
  }

  setImageLocalAlignment(x, y) {
    this._setVec2('imageLocalAlignment', x, y, 0.5, 0.5);
  }

  setImageAlignment(x, y) {
    this._setVec2('imageAlignment', x, y, 0.5, 0.5);
  }

  assignToObject(object) {
    if (!object) {
      return;
    }

    this.assignObjectScale(object);

    if (object instanceof THREE.Mesh) {
      object.material = this;
    }
  }

  setObjectSize(X, Y, Z) {
    let x = X, y = Y, z = Z;

    if (x instanceof THREE.Vector3) {
      x = X.x;
      y = X.y;
      z = X.z;
    }
    let vec = this.getUniformValue('objectSize');

    if (!vec) {
      vec = new THREE.Vector3();
      this.setUniformValue('objectSize', vec);
    }
    vec.set(x, y, z);
  }

  setObjectScale(X, Y, Z) {
    let x = X, y = Y, z = Z;

    if (x instanceof THREE.Vector3) {
      x = X.x;
      y = X.y;
      z = X.z;
    }
    let vec = this.getUniformValue('objectScale');

    if (!vec) {
      vec = new THREE.Vector3();
      this.setUniformValue('objectScale', vec);
    }
    vec.set(x, y, z);
  }

  setFabricTexture(tex) {
    this.setUniformValue('fabric', tex);
  }

  setImageTexture(tex) {
    this.setUniformValue('image', tex);
  }

  setImageValue(value) {
    this.setUniformValue('imageValue', value);
  }

  setFabricSize(X, Y) {
    this._setVec2('fabricSize', X, Y);
    // let x = X, y = Y;

    // if (x instanceof THREE.Vector2) {
    //   x = X.x;
    //   y = X.y;
    // }

    // let vec = this.getUniformValue('fabricSize');

    // if (!vec) {
    //   vec = new THREE.Vector2();
    //   this.setUniformValue('fabricSize', vec);
    // }

    // vec.x = x;
    // vec.y = y;
  }

  setImageSize(X, Y) {
    let x = X, y = Y;

    if (x instanceof THREE.Vector2) {
      x = X.x;
      y = X.y;
    }

    let vec = this.getUniformValue('imageSize');

    if (!vec) {
      vec = new THREE.Vector2();
      this.setUniformValue('imageSize', vec);
    }

    if (
      (typeof (x) === 'number' && !isNaN(x)) ||
      (typeof (y) === 'number' && !isNaN(y))
    ) {
      vec.x = x;
      vec.y = y;
    }
  }

  getVertexShaderSource() {
    return ThreeHandleLogoMaterial.getVertexShaderSource();
  }

  getFragmentShaderSource() {
    return ThreeHandleLogoMaterial.getFragmentShaderSource();
  }

  static getVertexShaderSource() {
    return vertexShaderSource;
  }

  static getFragmentShaderSource() {
    return fragmentShaderSource;
  }

  static getInstance() {
    if (!instance) {
      instance = new ThreeHandleLogoMaterial();
    }

    return instance;
  }

  static create() {
    const inst = this.getInstance();
    const res = inst.clone();

    initInstance(res);

    return res;
  }
}
