import Geometry from '../geom/Geometry';
import Polygon from '../geom/Polygon';
import Vertex from '../geom/Vertex';
import Vector3 from '../geom/Vector3';
import Vector2 from '../geom/Vector2';
import VectorCloner from './VectorCloner';

function isTypedArray(value) {
  if (!value) {
    return false;
  }

  return ((typeof (value.BYTES_PER_ELEMENT) === 'number') && (value.buffer instanceof ArrayBuffer));
}

export default class GeometryCloner {
  static _cloneVector(vector, params) {
    return VectorCloner.clone(vector, params);
  }

  static _cloneVertexAttribute(name, attribute, attributes, params) {
    const res = this._cloneVector(attribute, params);

    return res;
  }

  static _cloneVertexRef(vertex, cloneVertexParams, params) {
    if (!vertex) {
      return null;
    }
    const cvp = cloneVertexParams;
    const srcGeom = cvp.srcGeometry;
    const resGeom = cvp.resultGeometry;
    const srcArray = cvp.getSourceVertexArray(this, srcGeom, cloneVertexParams, params);
    const dstArray = cvp.getResultVertexArray(this, resGeom, cloneVertexParams, params);

    if (!srcArray || !dstArray) {
      return this._cloneVector(vertex, params);
    }

    const cloneVertexFunc = cvp.cloneVertexFunction;
    const map = cvp.vertexIndexMap;
    let index = -1;
    let res = null;

    if (map) {
      index = map.get(vertex);
    }
    if (typeof (index) === 'undefined' || index === null || index < 0) {
      index = srcArray.indexOf(vertex);
      if (index < 0) {
        index = srcArray.length;
        res = cloneVertexFunc(this, vertex, cloneVertexParams, params);
        srcArray[index] = vertex;
        dstArray[index] = res;
      } else {
        res = dstArray[index];
      }
      if (map) {
        map.set(vertex, index);
      }
    }
    if (!res) {
      res = dstArray[index];
      if (!res) {
        res = cloneVertexFunc(this, vertex, cloneVertexParams, params);
        dstArray[index] = res;
      }
    }

    return res;
  }

  static _cloneGeometryVertex(vertex, geom, resultGeom, params, vertIndexMap) {
    let cvp = this._cloneGeometryVertexParams;
    const that = this;

    if (!cvp) {
      cvp = this._cloneGeometryVertexParams = {
        srcGeometry: null,
        resultGeometry: null,
        getSourceVertexArray: (scope, srcGeom, cloneVertexParams, p) => {
          if (!srcGeom) {
            return null;
          }
          if (!srcGeom.vertices) {
            srcGeom.vertices = [];
          }

          return srcGeom.vertices;
        },
        getResultVertexArray: (scope, resGeom, cloneVertexParams, p) => {
          if (!resGeom) {
            return null;
          }
          if (!resGeom.vertices) {
            resGeom.vertices = [];
          }

          return resGeom.vertices;
        },
        vertexIndexMap: null,
        cloneVertexFunction: (scope, vtx, cloneVertexParams, p) => {
          const res = scope._cloneVector(vtx, p);

          that._onClonedVertex(vtx, res, p);

          return res;
        },
        metaData: {
          vertexIndexMap: null
        }
      };
    }
    cvp.vertexIndexMap = vertIndexMap;
    cvp.metaData.vertexIndexMap = vertIndexMap;
    cvp.srcGeometry = geom;
    cvp.resultGeometry = resultGeom;

    return this._cloneVertexRef(vertex, cvp, params);
  }

  static _onClonedVertex(source, result, params) {
    if (params && source && result && params.onClonedVertex) {
      params.onClonedVertex(source, result, params);
    }
  }

  static _clonePolygonVertex(srcVertex, geometry, resultGeom, params, polyVertIndexMap, vertIndexMap) {
    let cvp = this._clonePolygonVertexParams;

    if (!cvp) {
      cvp = this._clonePolygonVertexParams = {
        srcGeometry: null,
        resultGeometry: null,
        getSourceVertexArray: (scope, srcGeom, cloneVertexParams, p) => {
          if (!srcGeom.polygonVertices) {
            srcGeom.polygonVertices = [];
          }

          return srcGeom.polygonVertices;
        },
        getResultVertexArray: (scope, resGeom, cloneVertexParams, p) => {
          if (!resGeom.polygonVertices) {
            resGeom.polygonVertices = [];
          }

          return resGeom.polygonVertices;
        },
        vertexIndexMap: null,
        cloneVertexFunction: (scope, vertex, cloneVertexParams, p) => {
          const geom = cloneVertexParams.srcGeometry;
          const resGeom = cloneVertexParams.resultGeometry;
          const vertIdxMap = cloneVertexParams.metaData.vertexIndexMap;

          if (vertex instanceof Vertex) {
            const posCopy = scope._cloneGeometryVertex(vertex.position, geom, resGeom, p, vertIdxMap);

            const resVert = new Vertex(posCopy);
            const atts = vertex.attributes;

            if (atts) {
              const resAtts = resVert.attributes = {};

              for (const v in atts) {
                if (atts.hasOwnProperty(v)) {
                  resAtts[v] = scope._cloneVertexAttribute(v, atts[v], atts, params);
                }
              }
            }

            return resVert;
          }
          const res = scope._cloneVector(vertex, params);

          this._onClonedVertex(vertex, res, params);

          return res;
        },
        metaData: {
          vertexIndexMap: null
        }
      };
    }
    cvp.vertexIndexMap = polyVertIndexMap;
    cvp.metaData.vertexIndexMap = vertIndexMap;
    cvp.srcGeometry = geometry;
    cvp.resultGeometry = resultGeom;

    const res = this._cloneVertexRef(srcVertex, cvp, params);

    if (params && params.onClonedPolygonVertex) {
      params.onClonedPolygonVertex(srcVertex, res, params);
    }

    return res;
  }

  /**
   * @method _clonePolygon
   * @private
   * @description Clones a polygon
   * @param {Polygon} polygon - source geometry
   * @param {Geometry} geom - source geometry
   * @param {Geometry} resGeom - result geometry
   * @param {Object} params - optional params object
   * @param {Map|WeakMap} polyVertexIndexMap - polygon vertex index map
   * @param {Map|WeakMap} vertexIndexMap - vertex index map
   * @returns {Geometry} - Copy of the source geometry
   **/
  static _clonePolygon(polygon, geom, resGeom, params, polyVertexIndexMap, vertexIndexMap) {
    if (!polygon) {
      return null;
    }
    const res = new Polygon();
    const polyVerts = polygon.vertices;

    if (!polyVerts) {
      return res;
    }

    const resPolyVerts = res.vertices = [];
    const numPolyVerts = polyVerts.length;

    for (let i = 0; i < numPolyVerts; ++i) {
      const polyVert = polyVerts[i];

      resPolyVerts[i] = this._clonePolygonVertex(polyVert, geom, resGeom, params, polyVertexIndexMap, vertexIndexMap);
    }

    return res;
  }

  static _copyVectorValues(source, dest) {
    if (!source || !dest) {
      return;
    }
    const srcDim = source.getDimension();
    const destDim = dest.getDimension();

    const dim = srcDim < destDim ? srcDim : destDim;

    for (let i = 0; i < dim; ++i) {
      dest.setCoord(i, source.getCoord(i));
    }
  }
  static _initClone(value, copy, map, level, params) {
    if (map) {
      map.set(value, copy);
    }
  }

  static _afterClonedPolygon(source, result, map, level, params) {
    if (params && params.onClonedPolygon) {
      params.onClonedPolygon(source, result, params);
    }
  }

  static _afterClonedVector(source, result, map, level, params) {
    if (params) {
      if (level === 'polygon.vertices' && params.onClonedPolygonVertex) {
        params.onClonedPolygonVertex(source, result, params);
      } else if (level === 'polygon.vertex.position' && params.onClonedVertex) {
        params.onClonedVertex(source, result, params);
      }
    }
  }

  // #if DEBUG
  static _DEBUGIsCorrectVectorListClone(source, result, level, srcGeometry, dstGeometry) {
    if (!source !== !result) {
      return false;
    }
    const srcLen = source.length;
    const resLen = result.length;

    if (srcLen !== resLen) {
      return false;
    }
    for (let i = 0; i < srcLen; ++i) {
      if (!this._DEBUGIsCorrectVectorClone(source[i], result[i], level, srcGeometry, dstGeometry)) {
        return false;
      }
    }

    return true;
  }

  static _DEBUGVectorsAreEqual(source, result) {
    if (source === result) {
      return true;
    }
    const srcDim = source.getDimension();
    const dstDim = result.getDimension();

    if (srcDim !== dstDim) {
      return false;
    }
    for (let i = 0; i < srcDim; ++i) {
      if (source.getCoord(i) !== result.getCoord(i)) {
        return false;
      }
    }

    return true;
  }

  static _DEBUGIsCorrectAttributesClone(srcAtts, dstAtts) {
    if (!srcAtts !== !dstAtts) {
      console.warn('Invalid attributes clone', srcAtts, dstAtts);

      return false;
    }
    if (srcAtts && dstAtts) {
      for (const v in srcAtts) {
        if (srcAtts.hasOwnProperty(v) !== dstAtts.hasOwnProperty(v)) {
          return false;
        }
      }
      for (const v in dstAtts) {
        if (srcAtts.hasOwnProperty(v) !== dstAtts.hasOwnProperty(v)) {
          return false;
        }
      }

      return true;
    }

    return false;
  }

  _arrIndex(arr, value, fromIdx) {
    if (!arr) {
      return -1;
    }
    if (arr.indexOf) {
      return arr.indexOf(value, fromIdx);
    }
    const l = arr.length;

    if (!l) {
      return -1;
    }
    for (let i = 0; i < l; ++i) {
      if (arr[i] === value) {
        return i;
      }
    }

    return -1;
  }

  static _DEBUGIsCorrectVectorClone(source, result, level, srcGeometry, dstGeometry) {
    if (!source && !result) {
      return true;
    }
    if (source === result) {
      console.warn('Vectors are same instance', source, result);

      return false;
    }
    if (!result) {
      console.warn('Invalid vector clone: ', result);

      return false;
    }

    if (
      (source instanceof Vertex) !== (result instanceof Vertex) ||
      (source instanceof Vector3) !== (result instanceof Vector3) ||
      (source instanceof Vector2) !== (result instanceof Vector2)
    ) {
      console.warn('Invalid vector clone - classes are not equal: ', source, result);

      return false;
    }

    if (level === 'polygon.vertex') {
      const srcPolyVerts = srcGeometry.polygonVertices;
      const dstPolyVerts = dstGeometry.polygonVertices;
      const srcVerts = srcGeometry.vertices;
      const dstVerts = dstGeometry.vertices;

      const i1 = this._arrIndex(srcPolyVerts, source, 0);
      const i2 = this._arrIndex(dstPolyVerts, result, 0);
      const j1 = this._arrIndex(srcVerts, source, 0);
      const j2 = this._arrIndex(dstVerts, result, 0);

      if (i1 !== i2) {
        return false;
      }
      if (j1 !== j2) {
        return false;
      }
    }

    if (source instanceof Vertex && result instanceof Vertex) {
      let lvl = level;

      if (level === 'polygon.vertex') {
        lvl = 'polygonvertex.vertex';
      }
      if (!this._DEBUGIsCorrectVectorClone(source.position, result.position, lvl, srcGeometry, dstGeometry)) {
        return false;
      }
      const srcAtts = source.attributes;
      const dstAtts = result.attributes;

      if (!this._DEBUGIsCorrectAttributesClone(srcAtts, dstAtts)) {
        return false;
      }
    }
    if (source.getDimension() !== result.getDimension()) {
      console.warn('Invalid vector clone dimensions: ', source, result);

      return false;
    }

    return true;
  }

  static _DEBUGIsCorrectPolygonClone(sourcePoly, resultPoly, srcGeometry, dstGeometry) {
    if (!sourcePoly !== !resultPoly) {
      return false;
    }
    if (sourcePoly === resultPoly) {
      console.warn('Polygon clones are same instance: ', sourcePoly, resultPoly);

      return false;
    }
    if (!(this._DEBUGIsCorrectVectorListClone(sourcePoly.vertices, resultPoly.vertices, 'polygon.vertices', srcGeometry, dstGeometry))) {
      return false;
    }

    return true;
  }

  static _DEBUGIsCorrectPolygonListClone(sourcePolys, resultPolys, srcGeometry, dstGeometry) {
    if (!sourcePolys !== !resultPolys) {
      return false;
    }
    if (sourcePolys === resultPolys) {
      console.warn('Polygon list clones are same instance: ', sourcePolys, resultPolys);

      return false;
    }
    const l1 = sourcePolys.length;
    const l2 = resultPolys.length;

    if (l1 !== l2) {
      return false;
    }

    for (let i = 0; i < l1; ++i) {
      if (!this._DEBUGIsCorrectPolygonClone(sourcePolys[i], resultPolys[i], srcGeometry, dstGeometry)) {
        return false;
      }
    }

    return true;
  }

  static _DEBUGIsCorrectGeometryClone(source, result) {
    if (source === result) {
      console.warn('Clones are the same instance');

      return false;
    }
    if (!source && !result) {
      return true;
    }
    if (!result) {
      console.warn('Invalid result: ', result);

      return false;
    }

    if (!this._DEBUGIsCorrectPolygonListClone(source.polygons, result.polygons, source, result)) {
      return false;
    }
    if (!this._DEBUGIsCorrectVectorListClone(source.polygonVertices, result.polygonVertices, source, result)) {
      return false;
    }
    if (!this._DEBUGIsCorrectVectorListClone(source.vertices, result.vertices, source, result)) {
      return false;
    }

    return true;
  }

  static _DEBUGTestGeometryClone(source, result) {
    if (this._DEBUGIsCorrectGeometryClone(source, result)) {
      console.info('Geometry clone valid');
    } else {
      console.warn('Geometry clone invalid!');
    }
  }
  // #endif

  static _cloneAny(value, cloneMap = null, level = null, params = null) {
    const map = cloneMap || new Map();
    let res = map ? map.get(value) : null;

    if (res) {
      return res;
    }

    if (!value) {
      return value;
    }
    const t = typeof (value);

    if (t === 'string' || t === 'number' || t === 'boolean') {
      return value;
    }

    let Cl = value.constructor;

    if (value instanceof Geometry) {
      Cl = Cl || Geometry;
      if (Cl) {
        res = new Cl();
      }
      this._initClone(value, res, map, level, params);
      if (params && params.onBeforeCloneGeometry) {
        params.onBeforeCloneGeometry(value, res, params);
      }

      res.polygons = this._cloneAny(value.polygons, map, 'geometry.polygons', params);
      res.polygonVertices = this._cloneAny(value.polygonVertices, map, 'geometry.polygonVertices', params);
      res.vertices = this._cloneAny(value.vertices, map, 'geometry.vertices', params);
      res.userData = this._cloneAny(value.userData, map, 'geometry.userData', params);
      res.attributes = this._cloneAny(value.attributes, map, 'geometry.attributes', params);

      if (params && params.onClonedGeometry) {
        params.onClonedGeometry(value, res, params);
      }
    } else if (value instanceof Polygon) {
      Cl = Cl || Polygon;
      res = new Cl();
      this._initClone(value, res, map, level, params);


      res.vertices = this._cloneAny(value.vertices, map, 'polygon.vertices', params);
      res.userData = this._cloneAny(value.userData, map, 'polygon.userData', params);

      this._afterClonedPolygon(value, res, map, level, params);

      // #if DEBUG
      if (window.TEST_GEOMETRY_CLONE) {
        this._DEBUGTestGeometryClone(value, res);
      }
      // #endif
    } else if (value instanceof Vertex) {
      Cl = Cl || Vertex;
      res = new Cl();
      this._initClone(value, res, map, level, params);
      let lvl = level;

      if (level === 'polygon.vertices') {
        lvl = 'polygon.vertex';
      }

      res.position = this._cloneAny(value.position, map, `${lvl}.position`, params);
      res.attributes = this._cloneAny(value.attributes, map, `${lvl}.attributes`, params);
      this._afterClonedVector(value, res, map, level, params);
    } else if (value instanceof Vector3) {
      Cl = Cl || Vector3;
      res = new Cl();
      this._initClone(value, res, map, level, params);
      this._copyVectorValues(value, res);
      this._afterClonedVector(value, res, map, level, params);
    } else if (value instanceof Vector2) {
      Cl = Cl || Vector2;
      res = new Cl();
      this._initClone(value, res, map, level, params);
      this._copyVectorValues(value, res);
      this._afterClonedVector(value, res, map, level, params);
    } else if ((value instanceof Array) || (Array.isArray && Array.isArray(value))) {
      const l = value.length;

      res = [];

      res.length = l;
      for (let i = 0; i < l; ++i) {
        res[i] = this._cloneAny(value[i], map, level, params);
      }
    } else {
      if (Cl === Object) {
        res = {};
      } else if (Cl === Array) {
        res = [];
      } else if (isTypedArray(value)) {
        // Typed array
        try {
          res = new Cl(value);
        } catch (ex) {
          res = null;
        }
      } else {
        try {
          res = new Cl();
        } catch (e) {
          res = {};
        }
      }
      if (res === null || typeof (res) === 'undefined') {
        return res;
      }
      this._initClone(value, res, map, level, params);
      if ((res instanceof Array) || (Array.isArray && Array.isArray(res))) {
        const l = res.length;

        for (let i = 0; i < l; ++i) {
          res[i] = this._cloneAny(value[i], map, level, params);
        }
      } else if ((typeof (res) === 'object') && !isTypedArray(value)) {
        for (const v in value) {
          if (value.hasOwnProperty(v)) {
            res[v] = this._cloneAny(value[v], map, level, params);
          }
        }
      }
    }
    if (map && !map.has(value)) {
      map.set(value, res);
    }

    return res;
  }

  /**
   * @method clone
   * @description Clones a geometry instance
   * @param {Geometry} geometry - source geometry
   * @param {Object} params - optional clone params object
   * @returns {Geometry} - Copy of the source geometry
   **/
  static clone(geometry, params = null) {
    const test = true;

    if (test) {
      return this._cloneAny(geometry, null, null, params);
    }

    if (!geometry) {
      return null;
    }
    if (!geometry instanceof Geometry) {
      return null;
    }
    const Cl = geometry.constructor;

    const geomCopy = new Cl();
    const polygons = geometry.polygons;
    const polygonsCopy = [];
    const numPolys = polygons.length;

    const polyVertexIndexMap = new WeakMap();
    const vertexIndexMap = new WeakMap();

    if (params && params.onBeforeCloneGeometry) {
      params.onBeforeCloneGeometry(geometry, geomCopy, params);
    }

    for (let i = 0; i < numPolys; ++i) {
      const poly = polygons[i];
      const polyCopy = this._clonePolygon(poly, geometry, geomCopy, params, polyVertexIndexMap, vertexIndexMap);

      polygonsCopy[i] = polyCopy;
    }
    geomCopy.polygons = polygonsCopy;

    if (params && params.onClonedGeometry) {
      params.onClonedGeometry(geometry, geomCopy, params);
    }

    return geomCopy;
  }
}
