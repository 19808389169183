const DEFAULT_DISTANCE = 260;

const perspectiveCamRotationRX = -0.5346018366025517;
const perspectiveCamRotationRY = -0.6346018366025518;

export default class CameraViewSettings {
  constructor(name = null, distance = DEFAULT_DISTANCE, rotationX = 0, rotationY = 0, x = 0, y = 0, z = 0, vOx = 0, vOy = 0) {
    this._name = name;
    this._distance = distance;
    this._rotationX = rotationX;
    this._rotationY = rotationY;
    this._positionX = x;
    this._positionY = y;
    this._positionZ = z;
    this._viewOffsetX = vOx;
    this._viewOffsetY = vOy;
  }

  getName() {
    return this._name;
  }

  get name() {
    return this.getName();
  }

  getDistance() {
    return this._distance;
  }

  setDistance(v) {
    this._distance = v;
  }

  get distance() {
    return this.getDistance();
  }

  set distance(v) {
    this.setDistance(v);
  }

  getRotationX() {
    return this._rotationX;
  }

  setRotationX(v) {
    this._rotationX = v;
  }

  get rotationX() {
    return this.getRotationX();
  }

  set rotationX(v) {
    this.setRotationX(v);
  }

  getRotationY() {
    return this._rotationY;
  }

  setRotationY(v) {
    this._rotationY = v;
  }

  get rotationY() {
    return this.getRotationY();
  }

  set rotationY(v) {
    this.setRotationY(v);
  }

  getPositionX() {
    return this._positionX;
  }

  setPositionX(v) {
    this._positionX = v;
  }

  get positionX() {
    return this.getPositionX();
  }

  set positionX(v) {
    this.setPositionX(v);
  }

  getPositionY() {
    return this._positionY;
  }

  setPositionY(v) {
    this._positionY = v;
  }

  get positionY() {
    return this.getPositionY();
  }

  set positionY(v) {
    this.setPositionY(v);
  }

  getPositionZ() {
    return this._positionZ;
  }

  setPositionZ(v) {
    this._positionZ = v;
  }

  get positionZ() {
    return this.getPositionZ();
  }

  set positionZ(v) {
    this.setPositionZ(v);
  }

  getViewOffsetX() {
    return this._viewOffsetX;
  }

  setViewOffsetX(v) {
    this._viewOffsetX = v;
  }

  getViewOffsetY() {
    return this._viewOffsetY;
  }

  setViewOffsetY(v) {
    this._viewOffsetY = v;
  }

  get viewOffsetX() {
    return this.getViewOffsetX();
  }

  set viewOffsetX(v) {
    this.setViewOffsetX(v);
  }

  get viewOffsetY() {
    return this.getViewOffsetY();
  }

  set viewOffsetY(v) {
    this.setViewOffsetY(v);
  }

  setPosition(x = 0, y = 0, z = 0) {
    this._positionX = x;
    this._positionY = y;
    this._positionZ = z;
  }

  setViewOffset(x, y) {
    this._viewOffsetX = x;
    this._viewOffsetY = y;
  }

  setRotation(x, y) {
    this._rotationX = x;
    this._rotationY = y;
  }
}

CameraViewSettings.PERSPECTIVE = new CameraViewSettings('perspective', DEFAULT_DISTANCE, perspectiveCamRotationRX, perspectiveCamRotationRY);
CameraViewSettings.FRONT = new CameraViewSettings('front', DEFAULT_DISTANCE, 0, 0);
CameraViewSettings.BACK = new CameraViewSettings('back', DEFAULT_DISTANCE, 0, Math.PI);
CameraViewSettings.SIDE = new CameraViewSettings('side', DEFAULT_DISTANCE, 0, Math.PI * 0.5);
CameraViewSettings.LEFT = new CameraViewSettings('left', DEFAULT_DISTANCE, 0, Math.PI * 0.5);
CameraViewSettings.RIGHT = new CameraViewSettings('right', DEFAULT_DISTANCE, 0, -Math.PI * 0.5);
CameraViewSettings.TOP = new CameraViewSettings('top', DEFAULT_DISTANCE, -Math.PI * 0.5, 0);
CameraViewSettings.BOTTOM = new CameraViewSettings('bottom', DEFAULT_DISTANCE, Math.PI * 0.5, 0);
