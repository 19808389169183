import FabricMaterialUtils from '../../material/FabricMaterialUtils';
import Node3DMaterialUtils from '../../material/Node3DMaterialUtils';

import {
  castValue,
  Property,
  getFactoryTypes,
  setGeometryUVWorldTransformRecursive
} from '../utils';
import Asset from '../../asset/Asset';
import AssetNode3D from '../../scenegraph/BD3DAssetNode3D';
import Transform3D from '../../../bgr/bgr3d/transform/Transform3D';
// import Material from '../../../bgr/bgr3d/material/Material';
import BD3DSampleFabricMaterial from '../../material/BD3DSampleFabricMaterial';
import BD3DMaterial from '../../material/BD3DMaterial';
import SampleTransform from '../../material/SampleTransform';
import StringUtils from '../../utils/StringUtils';

function passOnElementType(scope, assetNode, material) {
  const mdAssetNode = assetNode && assetNode.metaData;
  const mdMaterial = material && material.metaData;

  if (!mdAssetNode || !mdMaterial) {
    return;
  }
  mdMaterial.elementType = mdAssetNode.elementType;
}

export default {
  create(data, id, session) {
    let {asset, transform} = data;

    asset = castValue(asset, Asset, id, session);
    transform = castValue(transform, Transform3D, id, session);

    const res = new AssetNode3D(asset, transform);
    let md = res.metaData;

    if (data && data.elementType) {
      md = md || {};
      md.elementType = data.elementType;
    }

    res.metaData = md;

    res.setMaterial(data.material, true);
    passOnElementType(this, res, data.material);

    return res;
  },
  update(object, data, id, session, srcData) {
    object.selectable = data.selectable;
    object.selectionModes = data.selectionModes;
    const srcDataData = srcData ? srcData['@data'] : null;
    /*
    const asset = data.asset;
    const transform = data.transform;
    const asset = parseValue(data.asset, id, session);
    const transform = parseValue(data.transform, id, session);
    object.asset = asset;
    object.transform = transform;
    */

    const material = data.material;

    const resultUVTransform = setGeometryUVWorldTransformRecursive(object, data.uvtransform);

    if (material instanceof BD3DSampleFabricMaterial) {
      let mtlSampleTransform = null;

      if (material.getSampleTransform) {
        mtlSampleTransform = material.getSampleTransform();
      }
      if (!mtlSampleTransform && material.get) {
        mtlSampleTransform = material.get('sampleTransform');
      }

      // const sampleConfigData = material.getSampleConfigData();
      // const sampleData = material.getSampleData();

      if (!mtlSampleTransform && resultUVTransform) {
        mtlSampleTransform = new SampleTransform();
        material.setSampleTransform(mtlSampleTransform);
      }
      if (mtlSampleTransform && resultUVTransform) {
        mtlSampleTransform.setGeometryUVData(resultUVTransform);
      }
      // mtlSampleTransform.setSampleConfigData(sampleConfigData);
      // mtlSampleTransform.setSampleData(sampleData);

      // const sampleAsset = material.getSampleAsset();

      // mtlSampleTransform.setRealSize(sampleAsset.getWidth(), sampleAsset.getHeight());
      object.setMaterial(material, true);
      const sessionParams = session.getParams();
      const mtlSrcData = srcDataData.material['@data'];
      const mtlSrcTex = mtlSrcData ? mtlSrcData.texture : null;
      const mtlSrcQuilt = mtlSrcData ? mtlSrcData.quilt : null;
      const fabricMaterialType = null;
      const recursiveAssign = true;
      const buildParams = sessionParams ? sessionParams.buildParams : null;
      const singlePart = null;

      // if (!material || (material instanceof BD3DSampleFabricMaterial)) {
      if (!material) {
        FabricMaterialUtils.assignMaterialToNode(object, mtlSrcTex, mtlSrcQuilt, singlePart, buildParams, fabricMaterialType, recursiveAssign, material);
      }
    } else {
      let materialType = null;
      let materialTypeName = null;

      if (material) {
        if (material.getType) {
          materialType = material.getType();
        } else {
          materialType = material.type;
        }
      }
      if (typeof (materialType) === 'string') {
        materialTypeName = materialType;
      } else if (typeof (materialType) === 'object' && materialType !== null) {
        if (materialType.getName) {
          materialTypeName = materialType.getName();
        } else {
          materialTypeName = materialType.name;
        }
      }
      materialTypeName = materialTypeName && materialTypeName.toLowerCase ? materialTypeName.toLowerCase() : materialTypeName;

      if (materialTypeName === 'fabric') {
        const quilt = material ? material.quilt : null;
        const singlePart = null;
        const params = session ? session.getParams() : null;
        const buildParams = params ? params.buildParams : null;
        const fabricMaterialType = null;
        const recursiveAssign = true;

        if (materialTypeName === 'fabric') {
          const texture = material ? material.texture : null;

          if (texture) {
            FabricMaterialUtils.assignMaterialToNode(object, texture, quilt, singlePart, buildParams, fabricMaterialType, recursiveAssign);
          }

        }
      } else if (materialTypeName === 'border3d') {
        if (material instanceof BD3DMaterial) {
          const sampleTransform = material.get('sampleTransform');

          sampleTransform.setGeometryUVData(resultUVTransform);
          Node3DMaterialUtils.assignMaterial(object, material);
        }
      }
    }

    if (material) {
      if (material.setColorType) {
        material.setColorType(srcDataData.componentColorType || null);
      }
    }
    passOnElementType(this, object, material);

    return;
  },
  callMethod(object, data, method, params, options) {
    // default:
    if (!object) {
      return null;
    }
    const parser = options ? options.parser : null;
    const material = object.getMaterial ? object.getMaterial() : null;
    const materialData = parser ? parser.getDataByObject(material) : null;
    const type = materialData['@type'];

    if (material && type) {
      const factories = getFactoryTypes(options);
      const factory = factories[type];

      if (factory && factory.callMethod) {
        passOnElementType(this, object, material);

        return factory.callMethod(material, materialData, method, params, options);
      }
    }

    return null;
  },
  getProperty(object, data, property, params) {
    if (!property) {
      return null;
    }
    const isPropertyInstance = property instanceof Property;
    const key = isPropertyInstance ? property.getKey() : Property.getKey(property);

    if (key) {
      const parser = params ? params.parser : null;

      if (StringUtils.startsWith(key, 'sample')) {
        const material = object.getMaterial ? object.getMaterial() : null;
        const materialData = parser ? parser.getDataByObject(material) : null;
        const type = materialData['@type'];

        if (material && type) {
          const factories = getFactoryTypes(params);
          const factory = factories[type];

          if (factory) {
            return factory.getProperty(material, materialData, property, params);
          }
        }
      }
    }

    return null;
  },
  setProperty(object, data, property, value, params) {
    if (!property) {
      return;
    }
    const isPropertyInstance = property instanceof Property;
    const key = isPropertyInstance ? property.getKey() : Property.getKey(property);

    if (key) {
      const parser = params ? params.parser : null;

      if (StringUtils.startsWith(key, 'sample')) {
        const material = object.getMaterial ? object.getMaterial() : null;
        const materialData = parser.getDataByObject(material);
        const type = materialData['@type'];

        if (material && type) {
          const factories = getFactoryTypes(params);
          const factory = factories[type];

          if (factory) {
            const sceneParams = params && params.params; // params.params.params. ... :facepalm: what was I thinking??
            const outputParams = sceneParams && sceneParams.outputParams;
            if (outputParams) {
              outputParams.targetObject = material;
              outputParams.targetData = materialData;
            }
            factory.setProperty(material, materialData, property, value, params);
          }
        }
      }
    }
  }
};
