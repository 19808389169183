import ColorUtils from '../utils/ColorUtils';
import * as THREE from 'three';

// Uniform types
const UT_FLOAT = 'f';
const UT_VEC2 = 'v2';
const UT_VEC3 = 'v3';
const UT_VEC4 = 'v4';
const UT_TEXTURE = 't';
const UT_CUBETEXTURE = 't';
const UT_MAT4 = 'm4';

const TYPE_UNDEFINED = 'undefined';

let tempColorValues = null;

export default class ThreeMaterialUtils {
  static create(template, uniforms = null, properties = null) {
    if (!template || !(template instanceof THREE.ShaderMaterial)) {
      return null;
    }
    const res = template.clone();

    this.setMaterialUniforms(res, uniforms);

    if (properties) {
      for (const v in properties) {
        if (properties.hasOwnProperty(v)) {
          res[v] = properties[v];
        }
      }
    }

    return res;
  }

  static colorToVec3(R, G, B, vector3 = null) {
    let r = R, g = G, b = B, vec3 = null;

    vec3 = vector3;
    if (!(vec3 instanceof THREE.Vector3) && (B instanceof THREE.Vector3)) {
      vec3 = B;
    }
    if (!(vec3 instanceof THREE.Vector3) && (G instanceof THREE.Vector3)) {
      vec3 = G;
    }
    if (!vec3 || !(vec3 instanceof THREE.Vector3)) {
      vec3 = new THREE.Vector3();
    }

    const tR = typeof (R);
    const tG = typeof (G);
    const tB = typeof (B);

    if ((R === null || tR === 'undefined') &&
      (G === null || tG === 'undefined') &&
      (B === null || tB === 'undefined')) {
      r = g = b = 1.0;
    } else if (R instanceof THREE.Vector3) {
      r = R.x;
      g = R.y;
      b = R.z;
    } else if (tR === 'string' || tG !== 'number' || tB !== 'number') {
      tempColorValues = ColorUtils.colorToRGBA(R, tempColorValues);
      const div = 255;

      r = tempColorValues[0] / div;
      g = tempColorValues[1] / div;
      b = tempColorValues[2] / div;
    }

    vec3.x = r;
    vec3.y = g;
    vec3.z = b;

    return vec3;
  }

  static _getUniforms(uniforms, create = false) {
    if (uniforms || !create) {
      return uniforms;
    }

    return {};
  }

  static _getMaterialUniforms(material, create = false) {
    if (!material) {
      return null;
    }
    if (!create) {
      return material.uniforms;
    }
    material.uniforms = this._getUniforms(material.uniforms, create);

    return material.uniforms;
  }

  static setUniforms(srcUniforms, destUniforms = null, create = false) {
    if (!srcUniforms) {
      return destUniforms;
    }
    let destU = destUniforms;

    if (!destU && create) {
      destU = {};
    }
    if (!destU) {
      return destU;
    }

    for (const v in srcUniforms) {
      if (srcUniforms.hasOwnProperty(v)) {
        const val = srcUniforms[v];

        if (this.isUniform(val)) {
          this.setUniform(destU, v, val);
        } else {
          this.setUniformValue(destU, v, val);
        }
      }
    }

    return destU;
  }

  static setMaterialUniforms(material, uniforms) {
    if (!material || !uniforms) {
      return;
    }

    material.uniforms = this.setUniforms(uniforms, material.uniforms);
  }

  static isUniform(uniform) {
    if (!uniform) {
      return false;
    }

    if (typeof (THREE.Uniform) !== TYPE_UNDEFINED && uniform instanceof THREE.Uniform) {
      return true;
    }
    const t = typeof (uniform);

    if (t !== 'object') {
      return false;
    }

    return (uniform.type !== null && typeof (uniform.type) === 'string') ||
      (typeof (uniform.value) !== TYPE_UNDEFINED);
  }

  static newUniform(value) {
    if (typeof (THREE.Uniform) !== TYPE_UNDEFINED) {
      return new THREE.Uniform(value);
    }

    return {type: this.getUniformTypeName(value), value: value};
  }

  static getUniformValue(uniforms, name, fallback = null) {
    const uniform = this.getUniform(uniforms, name);

    if (!uniform) {
      return fallback;
    }
    const res = uniform.value;

    if (typeof (res) === TYPE_UNDEFINED) {
      return fallback;
    }

    return res;
  }

  static setUniformValue(uniforms, name, value) {
    let uniform = this.getUniform(uniforms, name);

    if (uniform) {
      uniform.value = value;
    } else {
      uniform = this.newUniform(value);
      this.setUniform(uniforms, name, uniform);
    }
  }

  static getMaterialUniformValue(material, name, fallback) {
    if (!material) {
      return null;
    }

    return this.getUniformValue(material.uniforms, name, fallback);
  }

  static setMaterialUniformValue(material, name, value) {
    if (!material) {
      return;
    }
    const uniforms = this._getMaterialUniforms(material, true);

    this.setUniformValue(uniforms, name, value);
  }

  static getMaterialUniform(material, name) {
    if (!material) {
      return null;
    }

    return this.getUniform(material.uniforms, name);
  }

  static getUniform(uniforms, name) {
    if (!uniforms) {
      return null;
    }

    return uniforms[name];
  }

  static setMaterialUniform(material, name, uniform) {
    if (!material) {
      return;
    }
    let uniforms = material.uniforms;

    if (!uniforms) {
      uniforms = material.uniforms = {};
    }

    this.setUniform(material.uniforms, name, uniform);
  }

  static setUniform(uniforms, name, uniform) {
    if (!uniforms) {
      return;
    }

    uniforms[name] = uniform;
  }

  static getUniformTypeName(value) {
    if (!value) {
      return null;
    }
    const t = typeof (value);

    if (t === 'number') {
      return UT_FLOAT;
    } else if (value instanceof THREE.Vector2) {
      return UT_VEC2;
    } else if (value instanceof THREE.Vector3) {
      return UT_VEC3;
    } else if (value instanceof THREE.Vector4) {
      return UT_VEC4;
    } else if (value instanceof THREE.Matrix4) {
      return UT_MAT4;
    } else if (value instanceof THREE.Texture) {
      return UT_TEXTURE;
    } else if (value instanceof THREE.CubeTexture) {
      return UT_CUBETEXTURE;
    }

    return null;
  }
}
