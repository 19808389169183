import ContainerNode3D from '../../bgr/bgr3d/scenegraph/ContainerNode3D';

export default class BD3DNodeUtils {
  static setNodeType(node, nodeType, recursive) {
    if (!node) {
      return;
    }
    if (node.setNodeType) {
      node.setNodeType(nodeType);
    }
    if (node instanceof ContainerNode3D) {
      const children = node.getChildren();
      const numChildren = children ? children.length : 0;

      for (let i = 0; i < numChildren; ++i) {
        this.setNodeType(children[i], nodeType);
      }
    }
  }
}
