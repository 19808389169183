import MaterialGeometryNode3D from '../../bgr/bgr3d/scenegraph/MaterialGeometryNode3D';

export default class BD3DGeometryNode3D extends MaterialGeometryNode3D {
  constructor(geom = null, material = null, transform = null, selectable = false, selectionModes = null) {
    super(geom, material, transform, selectable);
    this._selectable = selectable;
    this._nodeType = null;

    this.resultGeometry = null;
    this.useModifiers = true;
    this.selectionModes = selectionModes;
  }

  getGeometry() {
    if (!this.useModifiers) {
      return this.getSourceGeometry();
    }
    let geom = this.getResultGeometry();

    if (!geom) {
      geom = this.getSourceGeometry();
    }

    return geom;
  }

  getResultGeometry() {
    return this.resultGeometry;
  }

  setResultGeometry(v) {
    this.resultGeometry = v;
  }

  getSourceGeometry() {
    return super.getGeometry();
  }

  getNodeType() {
    return this._nodeType;
  }

  setNodeType(v) {
    this._nodeType = v;
  }

  get nodeType() {
    return this.getNodeType();
  }

  set nodeType(v) {
    this.setNodeType(v);
  }

  isSelectable() {
    return this._selectable;
  }

  setSelectable(b) {
    this._selectable = b;
  }

  get selectable() {
    return this.isSelectable();
  }

  set selectable(b) {
    this.setSelectable(b);
  }

  hasSelectionMode(mode) {
    const modes = this.selectionModes;

    if ((modes instanceof Array) || (Array.isArray && Array.isArray(modes))) {
      return modes.indexOf(mode, 0) >= 0;
    } else if (modes && typeof (modes) === 'object') {
      return modes[mode] === true;
    }

    return false;
  }
}
