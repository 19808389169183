import EventDispatcher from '../events/EventDispatcher';
import Event from '../events/Event';

const EVENT_RESIZE = 'resize';

export default class Component extends EventDispatcher {
  getWidth() {
    return this._width;
  }

  setWidth(w) {
    if (this._width === w) {
      return;
    }
    this._width = w;
    this._updateSize();
  }

  get width() {
    return this.getWidth();
  }

  set width(w) {
    this.setWidth(w);
  }

  getHeight() {
    return this._height;
  }

  setHeight(h) {
    if (this._height === h) {
      return;
    }
    this._height = h;
    this._updateSize();
  }

  get height() {
    return this.getHeight();
  }

  set height(h) {
    this.setHeight(h);
  }

  setSize(w, h) {
    if (this._width === w && this._height === h) {
      return;
    }
    this._width = w;
    this._height = h;

    this._updateSize();

    let resizeEvent = this._resizeEvent;

    if (resizeEvent) {
      resizeEvent.type = EVENT_RESIZE;
    } else {
      resizeEvent = this._resizeEvent = new Event(EVENT_RESIZE);
    }
    resizeEvent.component = this;

    this.dispatchEvent(resizeEvent);
  }

  _updateSize() {
    this._changedSize(this._width, this._height);
  }

  _changedSize() {
    return;
  }

  dispose() {
    return;
  }

}
