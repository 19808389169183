import * as THREE from 'three';
import CanvasUtils from '../utils/CanvasUtils';

/**
* @class ThreeTextureFilter
* @description Base class for WebGL based bitmap filters using Three.js
*/
export default class ThreeTextureFilter {
  getMaterialUniforms(mtl, create = true) {
    if (!mtl) {
      return null;
    }
    let u = mtl.uniforms;

    if (u || !create) {
      return u;
    }
    u = mtl.uniforms = {};

    return u;
  }

  getMaterialUniform(mtl, name, create = false) {
    if (!name) {
      return null;
    }
    const u = this.getMaterialUniforms(mtl, create);

    if (!u) {
      return null;
    }
    let res = u[name];

    if (res || !create) {
      return res;
    }
    res = u[name] = new THREE.Uniform();

    return res;
  }

  setMaterialUniform(mtl, name, value) {
    if (!mtl || !name || !value) {
      return;
    }

    const u = this.getMaterialUniforms(mtl, true);

    if (!u) {
      return;
    }
    u[name] = value;
  }

  getMaterialUniformValue(mtl, name, fallback) {
    if (!mtl || !name) {
      return fallback;
    }
    const u = this.getMaterialUniform(mtl, name, false);

    if (!u) {
      return fallback;
    }

    return u.value;
  }

  setMaterialUniformValue(mtl, name, value) {
    if (!mtl || !name) {
      return;
    }
    let u = this.getMaterialUniform(mtl, name, true);

    if (!u) {
      u = new THREE.Uniform();
      this.setMaterialUniform(mtl, name, u);
    }

    u.value = value;
  }

  setMaterialUniformVec2(mtl, name, x, y) {
    let vec = this.getMaterialUniformValue(mtl, name);

    if (!(vec instanceof THREE.Vector2)) {
      vec = new THREE.Vector2();
      this.setMaterialUniformValue(mtl, name, vec);
    }
    vec.set(x, y);
  }

  setMaterialUniformVec3(mtl, name, x, y, z) {
    let vec = this.getMaterialUniformValue(mtl, name);

    if (!(vec instanceof THREE.Vector3)) {
      vec = new THREE.Vector3();
      this.setMaterialUniformValue(mtl, name, vec);
    }
    vec.set(x, y, z);
  }

  setMaterialUniformVec4(mtl, name, x, y, z, w) {
    let vec = this.getMaterialUniformValue(mtl, name);

    if (!(vec instanceof THREE.Vector4)) {
      vec = new THREE.Vector4();
      this.setMaterialUniformValue(mtl, name, vec);
    }
    vec.set(x, y, z, w);
  }

  setMaterialUniformTexture(mtl, name, texture) {
    if (!mtl || !name) {
      return;
    }
    let tex = texture;

    if (tex instanceof THREE.WebGLRenderTarget) {
      tex = texture.texture;
    }
    if (tex instanceof THREE.Texture) {
      this.setMaterialUniformValue(mtl, name, tex);
    } else {
      // Create fallback texture?
      this.setMaterialUniformValue(mtl, name, null);
    }
  }

  render(renderer, scene, camera, target = null, forceClear = true) {
    if (!renderer || !scene || !camera) {
      return;
    }

    renderer.render(scene, camera, target, forceClear);
  }

  renderToCanvas(renderer, scene, camera, target = null, forceClear = true, params = null) {
    ThreeTextureFilter.renderToCanvas(renderer, scene, camera, target, forceClear, params);
  }

  convertRenderTargetToCanvas(renderer, renderTarget, canvas = null, params = null) {
    return ThreeTextureFilter.convertRenderTargetToCanvas(renderer, renderTarget, canvas, params);
  }

  static renderToCanvas(renderer, scene, camera, target = null, forceClear = true, params = null) {
    if (!renderer || !scene || !camera) {
      return;
    }
    let tgt = null, width, height, cvsContext;

    const isCanvas = CanvasUtils.isCanvas(target);
    const isImageData = CanvasUtils.isImageData(target);

    if (target instanceof THREE.WebGLRenderTarget) {
      tgt = target;
    } else if (isCanvas) {
      cvsContext = target.getContext('2d');
      tgt = new THREE.WebGLRenderTarget();
      width = target.width;
      height = target.height;
      tgt.setSize(width, height);
    } else if (CanvasUtils.isImageData(tgt)) {
      tgt = new THREE.WebGLRenderTarget();
      width = target.width;
      height = target.height;
      tgt.setSize(width, height);
    }
    renderer.render(scene, camera, tgt, forceClear);

    if (tgt) {
      let imgData = null;

      if (isCanvas) {
        imgData = cvsContext.createImageData(width, height);
      } else if (isImageData) {
        imgData = target;
      }
      if (CanvasUtils.isImageData(imgData)) {
        const imgDataData = imgData.data;
        let buffer, flipY = false;

        if (params) {
          flipY = params.flipY === true;
        }

        if (flipY) {
          buffer = new Uint8Array(imgDataData.length);
        } else {
          buffer = new Uint8Array(imgDataData.buffer);
        }

        renderer.readRenderTargetPixels(tgt, 0, 0, width, height, buffer);

        if (flipY) {
          let i = 0;
          const _3 = 3;

          for (let y = 0; y < height; ++y) {
            const invY = height - 1 - y;

            for (let x = 0; x < width; ++x) {
              const o = i << 2;
              const invI = x + invY * width;
              const invO = invI << 2;

              imgDataData[invO] = buffer[o];
              imgDataData[invO + 1] = buffer[o + 1];
              imgDataData[invO + 2] = buffer[o + 2];
              imgDataData[invO + _3] = buffer[o + _3];

              ++i;
            }
          }
        }

        if (isCanvas) {
          cvsContext.putImageData(imgData, 0, 0);
        }
      }
      if (tgt !== target) {
        tgt.dispose();
      }
    }
  }

  static convertRenderTargetToCanvas(renderer, renderTarget, canvas = null, params = null) {
    let cvs = canvas;

    if (!renderer || !renderTarget) {
      return cvs;
    }

    if (!cvs || !cvs.getContext) {
      cvs = document.createElement('canvas');
    }
    if (!cvs || !cvs.getContext) {
      return null;
    }
    const width = renderTarget.width;
    const height = renderTarget.height;

    cvs.width = width;
    cvs.height = height;

    const ctx = cvs.getContext('2d');
    const imageData = ctx.createImageData(width, height);
    const imageDataData = imageData.data;
    let buffer = null;
    let flipY = false;

    if (params) {
      flipY = params.flipY === true;
    }

    if (flipY) {
      buffer = new Uint8Array(imageDataData.length);
    } else {
      buffer = new Uint8Array(imageDataData.buffer);
    }

    renderer.readRenderTargetPixels(renderTarget, 0, 0, width, height, buffer);

    if (flipY) {
      let i = 0;
      const _3 = 3;

      for (let y = 0; y < height; ++y) {
        const invY = height - 1 - y;

        for (let x = 0; x < width; ++x) {
          const o = i << 2;
          const invI = x + invY * width;
          const invO = invI << 2;

          imageDataData[invO] = buffer[o];
          imageDataData[invO + 1] = buffer[o + 1];
          imageDataData[invO + 2] = buffer[o + 2];
          imageDataData[invO + _3] = buffer[o + _3];

          ++i;
        }
      }
    }

    ctx.putImageData(imageData, 0, 0);

    return cvs;
  }

  dispose() {
    return;
  }

  getPlaneScene(create = true) {
    return ThreeTextureFilter.getPlaneScene(create);
  }

  getPlaneMesh(create = true) {
    return ThreeTextureFilter.getPlaneMesh(create);
  }

  getPlaneGeometry(create = false) {
    return ThreeTextureFilter.getPlaneGeometry();
  }

  getCamera() {
    return ThreeTextureFilter.getCamera();
  }

  static getCamera(create = true) {
    let res = this._camera;

    if (res || !create) {
      return res;
    }
    res = this._camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);

    return res;
  }

  static dispose() {
    const planeGeom = this._planeGeometry;

    if (planeGeom && planeGeom.dispose) {
      planeGeom.dispose();
    }
    this._planeScene = null;
    this._planeMesh = null;
    this._planeGeometry = null;
    this._camera = null;
  }

  static getPlaneScene(create = true) {
    let res = this._scene;

    if (res || !create) {
      return res;
    }

    res = this._scene = new THREE.Scene();

    const mesh = this.getPlaneMesh(create);

    if (mesh) {
      res.add(mesh);
    }

    return res;
  }

  static getPlaneMesh(create = true) {
    let res = this._mesh;

    if (res || !create) {
      return res;
    }

    const geom = this.getPlaneGeometry(create);

    res = this._mesh = new THREE.Mesh(geom);

    return res;
  }

  static getPlaneGeometry(create = true) {
    let res = this._planeGeometry;

    if (res || !create) {
      return res;
    }

    res = this._planeGeometry = new THREE.PlaneBufferGeometry(2, 2);

    return res;
  }


}
