import BD3DBuildInfo from '../BD3DBuildInfo';
// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
// #endif
import MattressConfig from '../mattress/MattressConfig';
import ThreeComponentController from '../../bgr/three/controller/ThreeComponentController';
import BGR3DToThreeConverter from '../../bgr/three/geom/BGR3DToThreeConverter';
import GeometryNode3D from '../../bgr/bgr3d/scenegraph/GeometryNode3D';
import ContainerNode3D from '../../bgr/bgr3d/scenegraph/ContainerNode3D';
import * as THREE from 'three';

import Mattress3DFactory from '../mattress/Mattress3DFactory';
import MattressConfigDA from '../mattress/MattressConfigDA';
import MattressDA from '../mattress/MattressDA';

// import DragController from '../../bgr/common/dom/DragController';
import TouchDragController from '../../bgr/common/dom/TouchDragController';

import WheelController from '../../bgr/common/dom/WheelController';
import OrbitController from '../../bgr/three/orbit/OrbitController';
import ThreeSceneUtils from '../../bgr/three/utils/ThreeSceneUtils';

import ShadowPlane from '../three/ShadowPlane';

import ThreeManager from './ThreeManager';
import Geometry from '../../bgr/bgr3d/geom/Geometry';
import ThreeMaterialUtils from './ThreeMaterialUtils';

import BD3DMaterial from '../material/BD3DMaterial';
import BD3DFabricMaterial from '../material/BD3DFabricMaterial';
import BD3DSampleFabricMaterial from '../material/BD3DSampleFabricMaterial';
import SampleTransform from '../material/SampleTransform';
import ThreeFabricMaterial from './ThreeFabricMaterial';

import BD3DContainerNode3D from '../scenegraph/BD3DContainerNode3D';
import BD3DGeometryNode3D from '../scenegraph/BD3DGeometryNode3D';

import PointerHitDetection from './PointerHitDetection';
import ThreeSelectionRenderer from './ThreeSelectionRenderer';
import Node3DMaterialUtils from '../material/Node3DMaterialUtils';

import CameraViewSettings from './CameraViewSettings';
import CameraViewTween from './CameraViewTween';

import MattressCameraCollisionController from './MattressCameraCollisionController';

import Animator from '../../bgr/common/anim/Animator';
import TweenAnimation from '../../bgr/common/anim/TweenAnimation';

import ImageAsset from '../asset/ImageAsset';
import CubemapAsset from '../asset/CubemapAsset';
import BackgroundAsset from '../asset/BackgroundAsset';
import ThreeAssetUtils from './ThreeAssetUtils';

import BackgroundRenderer from './BackgroundRenderer';

import DefaultURLResolver from '../loading/DefaultURLResolver';

import ThreeScreenshotUtil from '../three/ThreeScreenshotUtil';
import ClickSelectionController from './ClickSelectionController';

import customCameraViewSettings from './customCameraViewSettings';
import Utils from '../utils/Utils';
// import VecMat4Math from '../../bgr/bgr3d/math/VecMat4Math';

import MattressConfigObjectTypes from '../mattress/MattressConfigObjectTypes';
import KeyListener from '../../bgr/common/keyboard/KeyListener';

import QuiltTransform from '../material/QuiltTransform';
import QuiltDA from '../quilt/QuiltDA';

import CanvasUtils from '../../bgr/common/utils/CanvasUtils';
import {RAD_TO_DEG, DEG_TO_RAD} from '../../bgr/common/utils/math';

import HistoryManager from '../history/HistoryManager';
import BD3DCamera from '../three/BD3DCamera';
/**
* @class BD3DMattressAppController
* @extends ThreeController
* @description Mattress configurator logic
*/

const CONFIG_PROPERTY_PARAMS = Symbol('CONFIG_PROPERTY_PARAMS');

const ZOOM_DISTANCE_OFFSET = 15;
const MAX_ZOOM_DISTANCE = 1400;
const ZOOM_FACTOR = 20;

const LOADING_PROGRESS = true;
const LOADING_COMPLETE = false;

const DEFAULT_MAX_HISTORY_LEVELS = 1;
const TYPE_BOOLEAN = 'boolean';
const TYPE_NUMBER = 'number';

function isEventAllowed(name, params) {
  if (!name) {
    return false;
  }
  const events = params && params.events;
  if (events === false || events === true) {
    return events;
  }
  if (!events) {
    return true;
  }
  const all = events.all;
  if (all === false || all === true) {
    return all;
  }
  const types = events.types;
  const defaultVal = events.default !== false;
  if (!types) {
    return defaultVal;
  }
  const type = types[name];
  const typeOfType = typeof type;
  if (typeOfType === TYPE_BOOLEAN) {
    return type;
  } else if (typeOfType === TYPE_NUMBER) {
    return type > 0;
  }
  return defaultVal;
}

export default class MattressThreeComponentController extends ThreeComponentController {
  constructor(params) {
    super();

    if (params) {
      this.setMinLoadDelay(params.minLoadDelay);
      this._maxHistoryLevels = params.maxHistoryLevels;
    }
    this.clickSelectionController = new ClickSelectionController({mattressConfigurator: this, enabled: true});

    this._mattressCameraCollisionController = null;

    this._selectionVisible = true;
    this._selectableCondition = null;

    this._initThreeData();
  }

  reset() {
    this.setMattressConfig(null);
    this.resetView(0);
  }

  // ////////////////////////////////////////////////////////////////
  //
  // Loading & assets
  //
  // ////////////////////////////////////////////////////////////////
  setMinLoadDelay(v) {
    const lmgr = this._getLoadingManager();

    if (!lmgr) {
      return;
    }
    lmgr.setMinLoadDelay(v);
  }

  getMinLoadDelay() {
    const lmgr = this._getLoadingManager();

    if (!lmgr) {
      return 0;
    }

    return lmgr.getMinLoadDelay();
  }

  get minLoadDelay() {
    return this.getMinLoadDelay();
  }

  set minLoadDelay(v) {
    this.setMinLoadDelay(v);
  }

  _getLoadingManager() {
    // TODO: Get LoadingManager instance from somewhere else
    const mf = this._getMattress3DFactory();

    return mf._getLoadingManager();
  }

  _getURLResolver() {
    const loadingMgr = this._getLoadingManager();

    if (!loadingMgr) {
      return null;
    }

    return loadingMgr.getURLResolver();
  }

  setAssetPath(path) {
    const urlResolver = this._getURLResolver();

    if (urlResolver instanceof DefaultURLResolver) {
      urlResolver.setAssetPath(path);
    }
    // #if DEBUG
    if (!(urlResolver instanceof DefaultURLResolver)) {
      console.error('Can\'t set asset path. URLResolver is not a DefaultURLResolver instance.');
    }
    // #endif
  }

  getAssetPath() {
    const urlResolver = this._getURLResolver();

    if (urlResolver instanceof DefaultURLResolver) {
      return urlResolver.getAssetPath();
    }

    return null;
  }

  set assetPath(ap) {
    this.setAssetPath(ap);
  }

  get assetPath() {
    return this.getAssetPath();
  }

  _getOrbitController() {
    let res = this._orbitController;

    if (!res) {
      res = this._orbitController = new OrbitController(this._camera);
    }

    return res;
  }

  _initThreeData() {
    const DEFAULT_CAMERA_DISTANCE = 260;
    const initialCamRotationRX = -0.5346018366025517;
    const initialCamRotationRY = -0.6346018366025518;

    this._scene = new THREE.Scene();
    // this._camera = new THREE.PerspectiveCamera();
    this._camera = new BD3DCamera();
    this._camera.targetAspect = 1;
    this._camera.near = 1;
    this._camera.far = 2000;
    this._camera.updateProjectionMatrix();

    this._orbitController = this._getOrbitController(); // new OrbitController(this._camera);

    this._orbitController.setTarget(this._camera);
    this._orbitController.setDistance(DEFAULT_CAMERA_DISTANCE);

    this._orbitController.setRotationX(initialCamRotationRX);
    this._orbitController.setRotationY(initialCamRotationRY);

    // const testGeom = new THREE.SphereGeometry();
    // const testColor = 0xFF0000;
    // const testMaterial = new THREE.MeshBasicMaterial({color: testColor});
    // const testMesh = new THREE.Mesh(testGeom, testMaterial);

    this._mattressContainer = new THREE.Group();

    // this._scene.add(testMesh);

    this._scene.add(this._mattressContainer);
    this._scene.add(this._orbitController.getContainer());

    this._initThreeShadow();
  }


  // Shadow plane stuff
  _initThreeShadow() {
    const shadowPlane = new ShadowPlane();
    const s = this._scene;
    const planeWidth = 300;
    const planeLength = 300;

    shadowPlane.rotation.x = Math.PI * 0.5;
    shadowPlane.scale.set(planeWidth, planeLength, 1);

    s.add(shadowPlane);

    this.shadowPlane = shadowPlane;
  }

  _renderShadow() {
    const r = this.getRenderer();
    const s = this.getScene();
    const sp = this.shadowPlane;

    sp.render(r, s);
  }

  _invalidateShadow() {
    const sp = this.shadowPlane;

    if (!sp) {
      return;
    }
    if (!sp.userData) {
      sp.userData = {};
    }
    sp.userData.valid = false;
  }

  _validateRenderShadow() {
    const sp = this.shadowPlane;

    if (!sp) {
      return;
    }
    const ud = sp.userData;

    if (!ud) {
      return;
    }
    if (ud.valid) {
      return;
    }
    this._renderShadow();
    ud.valid = true;
  }
  // End of shadow plane stuff

  _handlePreAnimate(evt) {
    const animator = evt.animator;

    if (!animator) {
      return;
    }
    if (!animator.metaData) {
      animator.metaData = {};
    }
    animator.metaData.needsRender = false;

    return;
  }

  _handlePostAnimate(evt) {
    const animator = evt.animator;

    if (animator && animator.metaData && animator.metaData.needsRender) {
      this.render();
    }
  }

  _getAnimator(create = true) {
    let res = this._animator;

    if (res || !create) {
      return res;
    }

    res = this._animator = new Animator();

    let preAnimHandler = this._preAnimHandler;
    let postAnimHandler = this._postAnimHandler;

    const that = this;

    if (!preAnimHandler) {
      preAnimHandler = this._preAnimHandler = evt => {
        that._handlePreAnimate(evt);
      };
    }
    if (!postAnimHandler) {
      postAnimHandler = this._postAnimHandler = evt => {
        that._handlePostAnimate(evt);
      };
    }

    res.addEventListener('preanimate', preAnimHandler);
    res.addEventListener('postanimate', postAnimHandler);

    return res;
  }

  _getDefaultCameraSettings() {
    const md = this.getMattressConfig();
    let res = null;

    if (md) {
      const json = md.getData();

      if (json) {
        res = json.defaultCameraSettings;
      }
    }
    if (!res) {
      return null;
    }
    const t = typeof (res);

    if (t === 'string') {
      return res;
    } else if (t === 'object') {
      let distance = Utils.tryValues(res.distance, res.zoom);
      let rotx = Utils.tryValues(
        res.xRotation, res.rotationX, res.rotationx, res.xrotation,
        res.angleX, res.xAngle, res.anglex, res.xangle
      );
      let roty = Utils.tryValues(
        res.yRotation, res.rotationY, res.rotationy, res.yrotation,
        res.angleY, res.yAngle, res.angley, res.yangle
      );
      const validDist = Utils.isNumber(distance);
      let validRotX = Utils.isNumber(rotx);
      let validRotY = Utils.isNumber(roty);

      if (!validRotX || !validRotY) {
        const {rotation} = res;

        if (rotation && !validRotX) {
          rotx = rotation.x;
          validRotX = Utils.isNumber(rotx);
        }
        if (rotation && !validRotY) {
          roty = rotation.y;
          validRotY = Utils.isNumber(roty);
        }
      }

      if (validDist || validRotX || validRotY) {
        const oc = this._getOrbitController();

        if (!validDist) {
          distance = oc.getDistance();
        }
        const DEG2RAD = Math.PI / 180;

        if (validRotX) {
          rotx *= DEG2RAD;
        } else {
          rotx = oc.getRotationX();
        }

        if (validRotY) {
          roty *= DEG2RAD;
        } else {
          roty = oc.getRotationY();
        }
        let customCamSettings = this._customCameraSettings;

        if (!customCamSettings) {
          customCamSettings = this._customCameraSettings = new CameraViewSettings('custom', distance, rotx, roty, 0, 0, 0, 0, 0);
        }

        return customCamSettings;
      }
    }

    return null;
  }

  resetView(duration = 0.5) {
    let defCamSettings = this._getDefaultCameraSettings();

    if (!defCamSettings) {
      defCamSettings = CameraViewSettings.PERSPECTIVE;
    }
    this.setCameraView(defCamSettings, duration);
  }

  getCameraViewData(result = null) {
    const res = result || {};
    const oc = this._orbitController;

    res.distance = oc ? oc.getDistance() : 0;
    res.rotationX = oc ? oc.getRotationX() * RAD_TO_DEG : 0;
    res.rotationY = oc ? oc.getRotationY() * RAD_TO_DEG : 0;
    res.positionX = oc ? oc.getPositionX() : 0;
    res.positionY = oc ? oc.getPositionY() : 0;
    res.positionZ = oc ? oc.getPositionZ() : 0;
    res.viewOffsetX = oc ? oc.getTargetOffsetX() : 0;
    res.viewOffsetY = oc ? oc.getTargetOffsetY() : 0;

    return res;
  }

  getCameraDistance() {
    const oc = this._orbitController;

    return oc ? oc.getDistance() : 0;
  }

  getCameraRotationX() {
    const oc = this._orbitController;

    return oc ? oc.getRotationX() * RAD_TO_DEG : 0;
  }

  getCameraRotationY() {
    const oc = this._orbitController;

    return oc ? oc.getRotationY() * RAD_TO_DEG : 0;
  }

  getCameraPositionX() {
    const oc = this._orbitController;

    return oc ? oc.getPositionX() : 0;
  }

  getCameraPositionY() {
    const oc = this._orbitController;

    return oc ? oc.getPositionY() : 0;
  }

  getCameraPositionZ() {
    const oc = this._orbitController;

    return oc ? oc.getPositionZ() : 0;
  }

  getCameraViewOffsetX() {
    const oc = this._orbitController;

    return oc ? oc.getTargetOffsetX() : 0;
  }

  getCameraViewOffsetY() {
    const oc = this._orbitController;

    return oc ? oc.getTargetOffsetY() : 0;
  }

  _setInternalCameraViewSettingsObject(o) {
    if (!o) {
      return null;
    }

    return this._setInternalCameraViewSettings(
      o.distance,
      o.rotationX,
      o.rotationY,
      o.positionX,
      o.positionY,
      o.positionZ,
      o.viewOffsetX,
      o.viewOffsetY
    );
  }

  _setInternalCameraViewSettings(
    distance,
    rotationX,
    rotationY,
    positionX,
    positionY,
    positionZ,
    viewOffsetX,
    viewOffsetY
  ) {
    const cvs = this._getInternalCameraViewSettings();

    const oc = this._orbitController;
    // Fallback values
    const fbDist = oc ? oc.getDistance() : 100;
    const fbRotX = oc ? oc.getRotationX() : 0;
    const fbRotY = oc ? oc.getRotationY() : 0;
    const fbPosX = oc ? oc.getPositionX() : 0;
    const fbPosY = oc ? oc.getPositionY() : 0;
    const fbPosZ = oc ? oc.getPositionZ() : 0;
    const fbViewOffX = oc ? oc.getTargetOffsetX() : 0;
    const fbViewOffY = oc ? oc.getTargetOffsetY() : 0;
    let rotX = rotationX;
    let rotY = rotationY;

    if (typeof (rotX) === 'number' && !isNaN(rotX)) {
      rotX *= DEG_TO_RAD;
    }
    if (typeof (rotY) === 'number' && !isNaN(rotY)) {
      rotY *= DEG_TO_RAD;
    }

    cvs.setDistance(
      Utils.parseNumber(distance, fbDist)
    );
    cvs.setRotation(
      Utils.parseNumber(rotX, fbRotX),
      Utils.parseNumber(rotY, fbRotY)
    );
    cvs.setPosition(
      Utils.parseNumber(positionX, fbPosX),
      Utils.parseNumber(positionY, fbPosY),
      Utils.parseNumber(positionZ, fbPosZ)
    );
    cvs.setViewOffset(
      Utils.parseNumber(viewOffsetX, fbViewOffX),
      Utils.parseNumber(viewOffsetY, fbViewOffY)
    );

    return cvs;
  }

  _getInternalCameraViewSettings() {
    let cvs = this._internalCameraViewSettings;

    if (cvs) {
      return cvs;
    }
    cvs = new CameraViewSettings();

    this._internalCameraViewSettings = cvs;

    return cvs;
  }

  setCameraViewValues(
    distance,
    rotationX,
    rotationY,
    positionX,
    positionY,
    positionZ,
    viewOffsetX,
    viewOffsetY,
    duration = 0.5
  ) {
    this.setCameraView(
      this._setInternalCameraViewSettings(
        distance,
        rotationX,
        rotationY,
        positionX,
        positionY,
        positionZ,
        viewOffsetX,
        viewOffsetY
      ),
      duration,
    );
  }

  setCameraView(view, duration = 0.5) {
    let v = view;

    if (typeof (v) === 'string') {
      v = v.toUpperCase();
      v = CameraViewSettings[v];

      if (!(v instanceof CameraViewSettings)) {
        // 'Special views'
        v = view.toUpperCase();
        v = customCameraViewSettings[v];
      }

    }
    if (!(v instanceof CameraViewSettings)) {

      if (typeof (v) === 'object' && v) {
        v = this._setInternalCameraViewSettingsObject(v);
      }
      if (!v) {
        // #if DEBUG
        BD3DLogger.warn('Invalid camera view: ', view);
        // #endif

        return;
      }
    }
    let tween = this._cameraTween;

    if (!tween) {
      tween = this._cameraTween = new CameraViewTween();
    }
    tween.setAnimator(this._getAnimator());
    tween.setOrbitController(this._getOrbitController());

    tween.tweenCameraViewSettings(v, duration);

    if (duration <= 0) {
      this.renderRequest();
    }
  }

  _changedComponent() {
    const comp = this.getComponent();

    // #if DEBUG
    BD3DLogger.log('changed component = ', comp);
    // #endif

    this.dispatchEvent('changed_component', this, comp);

    return;
  }

  _setComponentEnabled(comp, en = true) {
    super._setComponentEnabled(comp, en);
    let dragController = this._dragController;
    let wheelController = this._wheelController;
    let keyListener = this._keyListener;

    const elem = comp ? comp.getPointerTargetElement() : null;

    if (!keyListener && elem && en) {
      keyListener = this._keyListener = new KeyListener();
    }

    if (!dragController && elem && en) {
      // dragController = this._dragController = new DragController();
      dragController = this._dragController = new TouchDragController();
      dragController.isDrag = (evt, td, ptd, bounds) => {
        if (dragController.hasDragged()) {
          return true;
        }
        const dAngle = td.angle - ptd.angle;
        const dAngleAbs = dAngle < 0 ? -dAngle : dAngle;
        const dDistance = td.distance - ptd.distance;
        const dDistAbs = dDistance < 0 ? -dDistance : dDistance;
        const dx = td.x - ptd.x;
        const dy = td.y - ptd.y;
        const d = dx * dx + dy * dy;
        const toll = 25;
        const tollAngle = 0.02;
        const tollDist = 1;

        return d > toll || dAngleAbs > tollAngle || dDistAbs > tollDist;
      };
    }
    if (!wheelController && elem && en) {
      wheelController = this._wheelController = new WheelController();
    }
    if (dragController) {
      dragController.setElement(elem);
      dragController.setEnabled(en);
      if (en) {
        dragController.addEventListener('drag', this._getDragHandler(true));
        dragController.addEventListener('stopdrag', this._getStopDragHandler(true));
        dragController.addEventListener('startdrag', this._getStartDragHandler(true));
      } else {
        const dh = this._getDragHandler(false);
        const sdh = this._getStopDragHandler(false);
        const Sdh = this._getStartDragHandler(false);

        if (dh) {
          dragController.removeEventListener('drag', dh);
        }
        if (sdh) {
          dragController.removeEventListener('stopdrag', sdh);
        }
        if (Sdh) {
          dragController.removeEventListener('startdrag', Sdh);
        }
      }
    }
    if (wheelController) {
      wheelController.setElement(elem);
      wheelController.setEnabled(en);
      if (en) {
        wheelController.addEventListener('wheel', this._getWheelHandler());
      } else {
        const wh = this._getWheelHandler(false);

        if (wh) {
          wheelController.removeEventListener('wheel', wh);
        }
      }
    }


    if (keyListener) {
      keyListener.setTarget(window);
      keyListener.setEnabled(en);
      /*
      if (en) {
        keyListener.addEventListener(KeyListener.EVENT_KEYCHANGE, this._getKeyChangeHandler(true));
      } else {
        const wh = this._getKeyChangeHandler(false);

        if (wh) {
          keyListener.removeEventListener(KeyListener.EVENT_KEYCHANGE, wh);
        }
      }
      */
    }
    if (elem) {
      const mouseOverHandler = this._getMouseOverHandler(en);
      const mouseOutHandler = this._getMouseOutHandler(en);

      if (en) {
        if (mouseOverHandler) {
          elem.addEventListener('mouseover', mouseOverHandler);
        }
        if (mouseOutHandler) {
          elem.addEventListener('mouseout', mouseOutHandler);
        }
      } else {
        if (mouseOverHandler) {
          elem.removeEventListener('mouseover', mouseOverHandler);
        }
        if (mouseOutHandler) {
          elem.removeEventListener('mouseout', mouseOutHandler);
        }
      }
    }

    this.dispatchEvent('enable_component', this, comp, en);
  }

  _getMouseOverHandler(create = true) {
    let res = this._mouseOverHandler;

    if (res || !create) {
      return res;
    }
    const that = this;

    res = this._mouseOverHandler = evt => {
      that._handleMouseOver(evt);
    };

    return res;
  }

  _getMouseOutHandler(create = true) {
    let res = this._mouseOutHandler;

    if (res || !create) {
      return res;
    }
    const that = this;

    res = this._mouseOutHandler = evt => {
      that._handleMouseOut(evt);
    };

    return res;
  }

  _handleMouseOver(evt) {
    this._pointerInside = true;
  }

  _handleMouseOut(evt) {
    this._pointerInside = false;
  }

  _getKeyChangeHandler(create = true) {
    let h = this._keyChangeHandler;

    if (h || !create) {
      return h;
    }
    const that = this;

    h = this._keyChangeHandler = evt => {
      that._handleKeyChange(evt);
    };

    return h;
  }

  _getWheelHandler(create = true) {
    let wh = this._wheelHandler;

    if (!create || wh) {
      return wh;
    }
    const that = this;

    wh = this._wheelHandler = evt => {
      return that._handleWheel(evt);
    };

    return wh;
  }
  _getStartDragHandler(create = true) {
    let sdh = this._startDragHandler;

    if (sdh || !create) {
      return sdh;
    }
    const that = this;

    sdh = this._startDragHandler = evt => {
      return that._handleStartDrag(evt);
    };

    return sdh;
  }

  _getDragHandler(create = true) {
    let dh = this._dragHandler;

    if (!create || dh) {
      return dh;
    }

    const that = this;

    dh = this._dragHandler = evt => {
      return that._handleDrag(evt);
    };

    return dh;
  }

  _getStopDragHandler(create = true) {
    let sdh = this._stopDragHandler;

    if (!create || sdh) {
      return sdh;
    }

    const that = this;

    sdh = this._stopDragHandler = evt => {
      return that._handleStopDrag(evt);
    };

    return sdh;
  }

  _setCameraRotation(x, y) {
    let rX = x;
    const hPI = Math.PI * 0.5;

    if (rX < -hPI) {
      rX = -hPI;
    } else if (rX > hPI) {
      rX = hPI;
    }

    this._orbitController.setRotation(rX, y);
  }

  _handleKeyChange(evt) {
    if (this._pointerInside) {
      // #if DEBUG
      console.log('key change', evt);
      // #endif

      return;
    }

    return;
  }

  _isKeyDown(keyCode) {
    const kl = this._keyListener;

    if (!kl) {
      return false;
    }

    return kl.isKeyDown(keyCode);
  }

  _handleDrag(evt) {
    const dDist = evt.dDistance;
    const dAng = evt.dAngle;

    const oc = this._orbitController;
    const s = 0.01;
    const numP = evt.numPointers;
    const dx = evt.dx;
    const dy = evt.dy;
    const origEvt = evt.originalEvent;

    origEvt.preventDefault();

    let rX = oc.getRotationX(), rY = oc.getRotationY();

    if (numP === 1) {
      const btn = evt.originalEvent ? evt.originalEvent.button : -1;
      const KEY_SPACE = 32;


      if (btn === 1 || (this._pointerInside && (origEvt.altKey || origEvt.shiftKey || this._isKeyDown(KEY_SPACE)))) {
        // panning
        let vOx = oc.getTargetOffsetX();
        let vOy = oc.getTargetOffsetY();

        if (dx !== 0 || dy !== 0) {
          const dist = oc.getDistance();
          const factor = 0.001;
          const sensitivity = dist * factor;

          vOx -= dx * sensitivity;
          vOy += dy * sensitivity;
          oc.setTargetOffset(vOx, vOy);
        }
        /*
        const cam = this.getCamera();
        const matrix = cam.matrixWorld;
        const m = matrix.elements;

        const tdx = VecMat4Math.transformVectorX(-dx, dy, 0, 0, m);
        const tdy = VecMat4Math.transformVectorY(-dx, dy, 0, 0, m);
        const tdz = VecMat4Math.transformVectorZ(-dx, dy, 0, 0, m);

        oc.setPosition(oc.getPositionX() + tdx, oc.getPositionY() + tdy, oc.getPositionZ() + tdz);
        */
      } else {
        rX -= dy * s;
        rY -= dx * s;

        this._setCameraRotation(rX, rY);
      }
    }

    if (numP > 1) {
      let vOx = oc.getTargetOffsetX();
      let vOy = oc.getTargetOffsetY();

      const zoom = this.getZoom();
      const zoomPanDiv = 750.0;
      const zoomPanPct = zoom / zoomPanDiv;

      if (dx !== 0 || dy !== 0) {
        vOx -= dx * zoomPanPct;
        vOy += dy * zoomPanPct;
        oc.setTargetOffset(vOx, vOy);
      }

      const dAngToll = 0.00001;

      if ((dAng * dAng) > dAngToll) {
        rY += dAng;
      }

      // oc.setRotation(rX, rY);
      this._setCameraRotation(rX, rY);

      if ((dDist * dDist) > 1) {
        this._zoom(dDist);
      }
    }

    // change zoom if it hits object
    this.checkCameraInsideObject();
    this.renderRequest();
  }

  getObjectsUnderLocation(x, y) {
    const container = this.getScene();
    const camera = this.getCamera();
    const renderer = this.getRenderer();

    return PointerHitDetection.getObjectsUnderAbsLocFromRenderer(x, y, container, camera, renderer);
  }

  getObjectsUnderNdcLocation(x, y) {
    const container = this.getScene();
    const camera = this.getCamera();

    return PointerHitDetection.getObjectsUnderNdcLocFromRenderer(x, y, container, camera);
  }

  getSelectableObjectUnderLocation(x, y, mode = null) {
    const objects = this.getObjectsUnderLocation(x, y);

    if (!objects) {
      return null;
    }
    const numObjects = objects.length;

    if (numObjects < 1) {
      return null;
    }
    let hitObject = null;

    for (let i = 0; hitObject === null && i < numObjects; ++i) {
      const hitObj = objects[i];
      const obj = hitObj ? hitObj.object : null;

      if (obj) {
        const pointerEvents = !obj.userData || obj.userData.pointerEvents !== false;

        if (pointerEvents) {
          hitObject = hitObj;
          break;
        }
      }
    }

    if (!hitObject) {
      return null;
    }
    const object = hitObject.object;

    if (!object) {
      return null;
    }

    const threeMgr = this._getThreeManager();

    if (!threeMgr) {
      return null;
    }
    let res = null;
    let o = object;

    while (o && !this._isSelectableObject(res, mode)) {
      res = threeMgr.getBD3DObjectByThreeObject(o);
      o = o.parent;
    }

    return res;
  }

  getSelectionMode(mode) {
    const {clickSelectionController} = this;

    if (clickSelectionController) {
      return clickSelectionController.getSelectionMode();
    }

    return null;
  }

  setSelectionMode(mode) {
    const {clickSelectionController} = this;

    if (clickSelectionController) {
      clickSelectionController.setSelectionMode(mode);
    }
  }

  get selectionMode() {
    return this.getSelectionMode();
  }

  set selectionMode(mode) {
    this.setSelectionMode(mode);
  }

  getSelectableCondition() {
    return this._selectableCondition;
  }

  setSelectableCondition(c) {
    this._selectableCondition = c;
  }

  get selectableCondition() {
    return this.getSelectableCondition();
  }

  set selectableCondition(c) {
    this.setSelectableCondition(c);
  }

  _isSingle(o) {
    if (!o) {
      return false;
    }
    const mc = this.getMattressConfig();

    if (mc) {
      return mc.objectIsType(o, MattressConfigObjectTypes.SINGLE);
    }
    if (o.box) {
      return true;
    }

    return false;
  }

  getSelectedSingleIndex() {
    const single = this.getSelectedSingle();

    if (!single) {
      return -1;
    }

    const config = this.getMattressConfig();

    if (!config) {
      return -1;
    }
    const data = config.getData();

    if (!data) {
      return -1;
    }
    const singles = data.singles;

    if (!singles) {
      return -1;
    }

    return singles.indexOf(single);
  }

  get selectedSingleIndex() {
    return this.getSelectedSingleIndex();
  }

  getDataByNode3D(node3D) {
    return this.getDataByObject(node3D);
  }

  getDataByObject(object) {
    if (!object) {
      return null;
    }
    let res = null;
    const mf = this._mattressFactory;

    if (mf) {
      res = mf.getDataByNode3D(object);
    }

    if (!res) {
      const cfg = this.getMattressConfig();

      if (cfg) {
        const scene = cfg.getScene();

        if (scene) {
          res = scene.getDataByObject(object);
        }
      }
    }


    if (!res) {
      const threeData = this._getThreeData(object);

      if (!threeData) {
        return null;
      }
      const threeParent = threeData.parent;
      const parentNode = this._getNode3DByThreeData(threeParent);

      return this.getDataByNode3D(parentNode);
    }

    return res;
  }

  getSelectedSingle() {
    const obj = this.getSelectedObject();

    if (!obj) {
      return null;
    }
    const mf = this._mattressFactory;

    if (!mf) {
      return null;
    }
    let data = this.getDataByObject(obj); // mf.getDataByNode3D(obj);

    if (this._isSingle(data)) {
      return data;
    }

    const threeMgr = this._threeManager;

    if (!threeMgr) {
      return null;
    }

    const threeObj = threeMgr.getThreeObject(obj);

    if (!threeObj) {
      return null;
    }

    let t = threeObj;

    while (t) {
      const bd3dObj = threeMgr.getBD3DObjectByThreeObject(t);

      if (bd3dObj) {
        data = mf.getDataByNode3D(bd3dObj);
        if (this._isSingle(data)) {
          return data;
        }
      }

      t = t.parent;
    }

    return null;
  }

  _tryDispatchChangedSelection(options) {
    let withEvent = true;
    const typeOfOptions = typeof (options);

    if (options !== null && typeOfOptions !== 'undefined') {
      if (typeOfOptions === 'boolean') {
        withEvent = options;
      } else if (typeOfOptions === 'object') {
        withEvent = options.withEvent !== false;
      }
    }

    if (withEvent) {
      this._dispatchChangedSelection();
    }
  }

  _dispatchChangedSelection() {
    let evt = this._changedSelectionEvent;

    if (!evt) {
      evt = this._changedSelectionEvent = {};
    }
    evt.type = 'changed_selection';
    this.dispatchEvent(evt);
  }

  get selectedSingle() {
    return this.getSelectedSingle();
  }

  getGlobalSceneProperties(resultArray = null) {
    const config = this.getMattressConfig();

    if (!config) {
      return resultArray;
    }

    const scene = config ? config.getScene() : null;

    if (!scene) {
      return resultArray;
    }

    return scene.getGlobalProperties(resultArray);
  }

  getSelectedDataProperties(params = null, resultArray = null) {
    const selData = this.getSelectedData();

    if (!selData) {
      return resultArray;
    }
    const config = this.getMattressConfig();

    return config ? config.getScenePropertiesByTarget(selData, params, resultArray) : null;
  }

  getDataInfo(object) {
    if (!object) {
      return null;
    }
    const cfg = this.getMattressConfig();
    const res = (cfg && cfg.getObjectInfo) ? cfg.getObjectInfo(object) : null;

    return res;
  }

  getSelectedDataInfo() {
    const cfg = this.getMattressConfig();
    const selData = this.getSelectedData();
    const res = (cfg && cfg.getObjectInfo) ? cfg.getObjectInfo(selData) : null;

    return res;
  }

  getSelectedDataID() {
    const selData = this.getSelectedData();
    let res = MattressConfigDA.getObjectID(selData);

    if (!res) {
      const cfg = this.getMattressConfig();
      const scene = cfg ? cfg.getScene() : null;

      res = scene ? scene.getIdOfObject(selData) : null;
    }

    return res;
  }

  getSelectedDataIndex() {
    const data = this.getSelectedData();

    if (!data) {
      return -1;
    }

    const cfg = this.getMattressConfig();

    if (!cfg) {
      return -1;
    }

    return cfg.getObjectIndex(data);
  }

  setSelectedDataByIndex(index, options = null) {
    if (index === null || index === -1) {
      this.setSelectedData(null, options);

      return;
    }
    const cfg = this.getMattressConfig();

    if (!cfg) {
      return;
    }
    const obj = cfg.getObjectAt(index);

    if (!obj) {
      return;
    }

    this.setSelectedData(obj, options);
  }

  setSelectedDataByID(ID, options) {
    if (!ID) {
      this.setSelectedData(null, options);

      return;
    }
    const config = this.getMattressConfig();
    const obj = config.getObjectByID(ID);

    if (!obj) {
      return;
    }

    this.setSelectedData(obj, options);
  }

  setSelectedData(d, options = null) {
    if (this._selectedData === d) {
      return;
    }
    this._setSelectedData(d);

    this._tryDispatchChangedSelection(options);
    this.renderRequest();
  }

  removeDataFromSelection(data, options = null) {
    const sel = this.getSelectedData();

    if (sel !== data) {
      return;
    }

    this.setSelectedData(null, options);
  }

  _setSelectedData(d) {
    this._selectedData = d;

    const cfg = this.getMattressConfig();
    const mf = this._mattressFactory;

    // If the selected data is a group, select all objects of this group
    if (cfg && cfg.objectIsType(d, MattressConfigObjectTypes.GROUP)) {
      const elements = d.elements;
      const numElements = elements.length;

      this._clearSelectedObjects();
      for (let i = 0; i < numElements; ++i) {
        const elem = elements[i];
        let elemId = null;

        if (elem) {
          if (typeof (elem) === 'string') {
            elemId = elem;
          } else {
            elemId = elem.id;
          }
        }
        if (elemId) {
          const obj = cfg.getObjectByID(elemId);
          const node = mf.getNode3DByData(obj);

          this._addSelectedObject(node);
        }
      }

      return;
    }


    // TODO: check if d is a group -> select all nodes of this group
    let node = null;

    if (mf) {
      node = mf.getNode3DByData(d);
    }
    if (!node) {
      const scene = cfg.getScene();

      if (scene) {
        node = scene.getObjectByData(d);
      }
    }

    this._setSelectedObject(node);
  }

  getSelectedData() {
    if (this._selectedData) {
      return this._selectedData;
    }

    const mf = this._mattressFactory;

    if (!mf) {
      this._selectedData = null;

      return null;
    }
    const selObj = this.getSelectedObject();
    const data = this.getDataByObject(selObj);

    this._selectedData = data;

    return data;
  }

  get selectedData() {
    return this.getSelectedData();
  }

  set selectedData(d) {
    this.setSelectedData(d, null);
  }

  _isSelectableObject(obj, mode = null) {
    if (!obj) {
      return false;
    }
    if (obj instanceof BD3DContainerNode3D) {
      return obj.isSelectable() && (!mode || obj.hasSelectionMode(mode));
    }
    if (obj instanceof BD3DGeometryNode3D) {
      return obj.isSelectable() && (!mode || obj.hasSelectionMode(mode));
    }

    return false;
  }

  get selectedObject() {
    return this.getSelectedObject();
  }

  set selectedObject(o) {
    this.setSelectedObject(o);
  }

  getSelectedObjects() {
    return this._selectedObjects ? this._selectedObjects.concat() : null;
  }

  getSelectedObject() {
    return this._selectedObject || ((this._selectedObjects && this._selectedObjects.length === 1) ? this._selectedObject[0] : null);
  }

  setSelectedObject(object, options = null) {
    let obj = object;

    if (!obj) {
      obj = null;
    }
    if (obj === this._selectedObject) {
      return;
    }
    this._setSelectedObject(object);

    this._tryDispatchChangedSelection(options);

    this.renderRequest();
  }

  _clearSelectedObjects() {
    const arr = this._selectedObjects;

    if (!arr) {
      return;
    }
    const l = arr.length;

    if (!l) {
      return;
    }
    for (let i = 0; i < l; ++i) {
      this._setObjectSelected(arr[i], false);
    }

    arr.length = 0;
  }
  _addSelectedObject(o) {
    if (!o) {
      return;
    }
    let arr = this._selectedObjects;

    if (!arr) {
      arr = this._selectedObjects = [];
    }
    const idx = arr.indexOf(o, 0);

    if (idx < 0) {
      this._setObjectSelected(o, true);
      arr.push(o);
    }
  }

  _setSelectedObject(obj) {
    this._clearSelectedObjects();
    this._setObjectSelected(this._selectedObject, false);
    this._selectedObject = obj;
    this._setObjectSelected(this._selectedObject, true);
    this._addSelectedObject(this._selectedObject);

    let selData = null;

    if (obj) {
      selData = this.getDataByObject(obj);
      /*
      const mf = this._mattressFactory;

      if (mf) {
        selData = mf.getDataByNode3D(obj);
      }
      */
    }
    this._selectedData = selData;
  }

  isClickSelectEnabled() {
    const {clickSelectionController} = this;

    if (!clickSelectionController) {
      return false;
    }

    return clickSelectionController.isEnabled();
  }

  setClickSelectEnabled(e, options = null) {
    const {clickSelectionController} = this;

    if (!clickSelectionController) {
      return;
    }

    const oldDeselectOnDisable = clickSelectionController.deselectOnDisable;
    let deselectOnDisable = oldDeselectOnDisable;
    const typeOfOptions = typeof options;

    if (options !== null && typeOfOptions !== 'undefined') {
      if (typeOfOptions === 'boolean') {
        deselectOnDisable = options;
      } else if (typeOfOptions === 'object') {
        deselectOnDisable = options.deselectOnDisable !== false;
      }
    }

    clickSelectionController.deselectOnDisable = deselectOnDisable;
    clickSelectionController.setEnabled(e);
    clickSelectionController.deselectOnDisable = oldDeselectOnDisable;
  }

  get clickSelectEnabled() {
    return this.isClickSelectEnabled();
  }

  set clickSelectEnabled(e) {
    return this.setClickSelectEnabled(e);
  }

  isSelectionVisible() {
    return this._selectionVisible;
  }

  setSelectionVisible(v) {
    const old = this._selectionVisible;

    if (old === v) {
      return;
    }
    this._selectionVisible = v;
    this.renderRequest();
  }

  get selectionVisible() {
    return this.isSelectionVisible();
  }

  set selectionVisible(v) {
    this.setSelectionVisible(v);
  }

  hasSelection() {
    return (this._selectedObjects && this._selectedObjects.length > 0) || (this._selectedObject !== null && typeof (this._selectedObject) !== 'undefined');
  }

  _setObjectSelected(obj, sel = true) {
    if (!obj) {
      return;
    }
    const threeMgr = this._getThreeManager();

    if (!threeMgr) {
      return;
    }

    const threeObj = threeMgr.getThreeObject(obj);

    if (!threeObj) {
      return;
    }
    let cb = this._setThreeObjectHighlighted;

    if (!cb) {
      cb = this._setThreeObjectHighlighted = (o, p) => {
        if (o instanceof THREE.Mesh) {
          if (!o.userData) {
            o.userData = {};
          }
          o.userData.highlight = p;
        }
      };
    }

    ThreeSceneUtils.traverse(threeObj, cb, sel);
  }

  _focusDOMElement() {
    const component = this.getComponent();

    if (component) {
      const doc = document;
      const activeElem = doc.activeElement;
      const componentDomElem = component.getDOMElement();

      if (activeElem && activeElem !== doc.body && activeElem !== componentDomElem) {
        activeElem.blur();
      }
      if (componentDomElem && componentDomElem !== activeElem) {
        componentDomElem.focus();
      }
    }
  }

  _clickedHandler(evt) {
    this.dispatchEvent({type: 'click', x: evt.x, y: evt.y, originalEvent: evt.originalEvent});
  }


  _handleStopDrag(evt) {
    if (!evt.dragged) {
      this._clickedHandler(evt);
    }
  }

  _handleStartDrag(evt) {
    this._focusDOMElement();

    if (evt && evt.originalEvent) {
      evt.originalEvent.preventDefault();
    }

    return false;
    /*
    const btn = evt.originalEvent.button;

    if (btn === 1) {
    }
    */
  }

  getMaxZoom() {
    return MAX_ZOOM_DISTANCE;
  }

  _getZoomTween() {
    let res = this._zoomTween;

    if (!res) {
      res = this._zoomTween = new TweenAnimation(1, evt => {
        let t = res.time / res.duration;
        const _3 = 3;
        const _2 = 2;

        t = (_3 - _2 * t) * t * t;

        const z = res.startZoom + res.deltaZoom * t;

        res.target._setZoom(z);
        const animator = evt.animator;

        if (!animator) {
          return;
        }

        if (!animator.metaData) {
          animator.metaData = {};
        }
        animator.metaData.needsRender = true;

      });
    }

    return res;
  }

  _zoomAnim(value, duration = 0.5) {
    // const factor = 20.0;
    const v = ZOOM_FACTOR * value;

    const startZoom = this.getZoom();
    const endZoom = startZoom + v;

    this._tweenZoom(endZoom, duration);
  }

  _tweenZoom(endZoom, duration = 0.5) {
    const startZoom = this.getZoom();

    if (duration > 0) {
      const tween = this._getZoomTween();

      tween.startZoom = startZoom;
      tween.endZoom = startZoom;
      tween.deltaZoom = endZoom - startZoom;
      tween.target = this;
      tween.duration = duration;
      tween.time = 0;
      tween.setAnimator(this._getAnimator(true));

      tween.start();

    } else {
      this._setZoom(endZoom);
      this.renderRequest();
    }

  }
  zoomIn(value = 1, duration = 0.5) {
    this._zoomAnim(-value, duration);
  }

  zoomOut(value = 1, duration = 0.5) {
    this._zoomAnim(value, duration);
  }

  zoom(v, duration = 0) {
    if (duration > 0) {
      // ZOOM_FACTOR is calculated in zoomAnim
      this._zoomAnim(-v, duration);

      return;
    }
    this._zoom(v * ZOOM_FACTOR);
    this.renderRequest();
  }

  _zoom(v) {
    let dist = this.getZoom();

    dist -= v;
    this._setZoom(dist);
  }

  // TODO: should be moved to utils in bgr instead of bd3d?
  aabbIntersectionDistance(rayO, rayD, aabb) {
    const orig = rayO;
    const dir = rayD;
    const invdir = new THREE.Vector3(1 / dir.x, 1 / dir.y, 1 / dir.z);
    let tmin, tmax, tymin, tymax, tzmin, tzmax;

    tmin = (aabb.min.x - orig.x) * invdir.x;
    tmax = (aabb.max.x - orig.x) * invdir.x;

    if (tmin > tmax) {
      const temp = tmin;

      tmin = tmax;
      tmax = temp;
    }

    tymin = (aabb.min.y - orig.y) * invdir.y;
    tymax = (aabb.max.y - orig.y) * invdir.y;

    if (tymin > tymax) {
      const temp = tymin;

      tymin = tymax;
      tymax = temp;
    }

    if ((tmin > tymax) || (tymin > tmax)) {
      return Infinity;
    }

    if (tymin > tmin) {
      tmin = tymin;
    }

    if (tymax < tmax) {
      tmax = tymax;
    }

    tzmin = (aabb.min.z - orig.z) * invdir.z;
    tzmax = (aabb.max.z - orig.z) * invdir.z;

    if (tzmin > tzmax) {
      const temp = tzmin;

      tzmin = tzmax;
      tzmax = temp;
    }

    if ((tmin > tzmax) || (tzmin > tmax)) {
      return Infinity;
    }

    if (tzmin > tmin) {
      tmin = tzmin;
    }

    if (tzmax < tmax) {
      tmax = tzmax;
    }

    return tmin;
  }

  canZoomCamera(newDistance) {
    const currentDistance = this.getZoom();

    if (currentDistance < newDistance) {
      return true;
    }

    const diffDistance = currentDistance - newDistance;
    const cameraPosMatrix = this.getCamera().matrixWorld;
    const cameraPos = new THREE.Vector3();
    const direction = new THREE.Vector3(0, 0, -1);

    cameraPos.setFromMatrixPosition(cameraPosMatrix);

    direction.applyMatrix4(cameraPosMatrix);
    direction.x = direction.x - cameraPos.x;
    direction.y = direction.y - cameraPos.y;
    direction.z = direction.z - cameraPos.z;

    cameraPos.x += direction.x * diffDistance;
    cameraPos.y += direction.y * diffDistance;
    cameraPos.z += direction.z * diffDistance;

    const mattressRes = this._getMattress3DFactory().getResult();

    if (!mattressRes) {
      return true;
    }

    const aabb = mattressRes.getBoundingBox();
    let distance = this.aabbIntersectionDistance(cameraPos, direction, aabb);

    const distanceScene = this.rayHitDistanceScene(cameraPos, direction);

    if (typeof (distanceScene) === 'number' && !isNaN(distanceScene)) {
      distance = distanceScene < distance ? distanceScene : distance;
    }

    if (distance < ZOOM_DISTANCE_OFFSET) {
      return false;
    }

    return true;
  }

  checkCameraInsideObject() {
    this._updateCamZoom();
    /*
    const currentDistance = this.getZoom();
    const camera = this.getCamera();

    ThreeSceneUtils.updateWorldMatrix(camera);

    const cameraPosMatrix = camera.matrixWorld;
    const cameraPos = new THREE.Vector3();
    const direction = new THREE.Vector3(0, 0, -1);

    cameraPos.setFromMatrixPosition(cameraPosMatrix);

    direction.applyMatrix4(cameraPosMatrix);
    direction.x = direction.x - cameraPos.x;
    direction.y = direction.y - cameraPos.y;
    direction.z = direction.z - cameraPos.z;


    const mattressRes = this._getMattress3DFactory().getResult();

    if (!mattressRes) {
      return;
    }

    const aabb = mattressRes.getBoundingBox();

    if (!aabb) {
      return;
    }
    const margin = 10;
    const aabbMargin = {
      min: {
        x: aabb.min.x - margin,
        y: aabb.min.y - margin,
        z: aabb.min.z - margin
      },
      max: {
        x: aabb.max.x + margin,
        y: aabb.max.y + margin,
        z: aabb.max.z + margin
      }
    };

    let distance = this.aabbIntersectionDistance(cameraPos, direction, aabbMargin);

    const distanceScene = this.rayHitDistanceScene(cameraPos, direction);

    if (typeof (distanceScene) === 'number' && !isNaN(distanceScene)) {
      distance = distanceScene < distance ? distanceScene : distance;
    }

    if (distance < 0) {
      const newZoom = currentDistance - (distance);

      const oc = this._getOrbitController();

      oc.setDistance(newZoom);
    }
    */
  }

  getZoom() {
    const oc = this._orbitController;

    return oc.getDistance();
  }

  setZoom(value, duration = 0) {
    if (duration > 0) {
      this._tweenZoom(value, duration);

      return;
    }
    this._setZoom(value);
    this.renderRequest();
  }

  _setZoom(value) {
    let v = value;

    v = v > MAX_ZOOM_DISTANCE ? MAX_ZOOM_DISTANCE : v;
    // if (!this.canZoomCamera(v)) {
    //   return;
    // }

    const oc = this._orbitController;

    oc.setDistance(v);
    this._updateCamZoom();
    // this.checkCameraInsideObject();
  }

  getMattressCameraCollisionController() {
    let res = this._mattressCameraCollisionController;

    if (res) {
      return res;
    }
    res = new MattressCameraCollisionController(this);

    this._mattressCameraCollisionController = res;

    return res;
  }

  _updateCamZoom() {
    const controller = this.getMattressCameraCollisionController();

    controller.updateCamZoom();
  }

  _handleWheel(evt) {
    const s = 0.1;
    const dY = evt.deltaY * s;

    this._zoom(dY);

    this.renderRequest();
  }

  _getMattress3DFactory() {
    let mb = this._mattressFactory;

    if (!mb) {
      mb = this._mattressFactory = new Mattress3DFactory();
      const assetMgr = mb.getAssetManager();
      let disposeAssetHandler = this._disposeAssetHandler;

      if (!disposeAssetHandler) {
        disposeAssetHandler = (evt, asset) => {
          const threeMgr = this._threeManager;

          if (threeMgr) {
            threeMgr.disposeAsset(asset);
          }
        };
        this._disposeAssetHandler = disposeAssetHandler;
      }
      assetMgr.addEventListener('dispose', disposeAssetHandler);
    }

    return mb;
  }

  _getThreeConverter() {
    let converter = this._threeConverter;

    if (!converter) {
      converter = this._threeConverter = new BGR3DToThreeConverter();
    }

    return converter;
  }

  _getMattress3DCompleteHandler() {
    let handler = this._mattress3DCompleteHandler;

    if (!handler) {
      const that = this;

      handler = this._mattress3DCompleteHandler = evt => {
        that.handleMattress3DComplete(evt);
      };
    }

    return handler;
  }

  _getThreeConvertParams() {
    let p = this._threeConvertParams;

    if (!p) {
      p = this._threeConvertParams = {};
    }

    return p;
  }

  _getThreeManager() {
    let res = this._threeManager;

    if (!res) {
      res = this._threeManager = new ThreeManager();

      let addedHandler = this._addedThreeObjectHandler;
      let removedHandler = this._removedThreeObjectHandler;
      const that = this;

      if (!addedHandler) {
        addedHandler = this._addedThreeObjectHandler = evt => {
          return that._handleAddedThreeObject(evt);
        };
      }

      if (!removedHandler) {
        removedHandler = this._removedThreeObjectHandler = evt => {
          return that._handleRemovedThreeObject(evt);
        };
      }

      res.addEventListener('added', addedHandler);
      res.addEventListener('removed', removedHandler);
    }
    res.renderer = this.getRenderer();

    return res;
  }

  _getGeometryNode(node) {
    if (node instanceof GeometryNode3D) {
      return node;
    }
    if (!node) {
      return null;
    }
    if (node.userData && node.userData.geometryNode && (node.userData.geometryNode instanceof GeometryNode3D)) {
      return node.userData.geometryNode;
    }
    if (node instanceof ContainerNode3D) {
      const children = node.getChildren();

      if (!children) {
        return null;
      }
      const num = children.length;

      if (!num) {
        return null;
      }
      for (let i = 0; i < num; ++i) {
        const res = this._getGeometryNode(children[i]);

        if (res) {
          return res;
        }
      }
    }

    return null;
  }

  _getMattressNodeByName(name, parent) {
    if (!name) {
      return null;
    }
    let p = parent;

    if (!p) {
      p = this._mattressContainer3D;
    }
    if (!p) {
      return null;
    }
    const ud = p.userData;

    if (ud && ud.name === name) {
      return p;
    }
    const children = p.children;

    if (!children) {
      return null;
    }
    const num = children.length;

    if (num === 0) {
      return null;
    }

    for (let i = 0; i < num; ++i) {
      const child = children[i];
      const res = this._getMattressNodeByName(name, child);

      if (res) {
        return res;
      }
    }

    return null;
  }

  _handleAddedThreeObject(evt) {
    // #if DEBUG
    BD3DLogger.log('added three object', evt);
    // #endif

    return;
  }

  _handleRemovedThreeObject(evt) {
    if (evt.object instanceof Geometry) {
      if (evt.threeObject && evt.threeObject.dispose) {
        evt.threeObject.dispose();
      }
      // #if DEBUG
      BD3DLogger.log('removed three object', evt);
      // #endif
    } else if ((evt.threeObject instanceof THREE.Texture) || (evt.threeObject instanceof THREE.WebGLRenderTarget)) {
      // #if DEBUG
      BD3DLogger.log('dispose texture', evt);
      // #endif
      evt.threeObject.dispose();
    }
  }

  _getGlobalUniformObject(create = true) {
    let res = this._globalUniforms;

    if (!res && create) {
      res = {};
      this._globalUniforms = res;
    }

    return res;
  }

  _getGlobalUniforms(create = true) {
    const res = this._getGlobalUniformObject(create);

    if (res) {
      const cam = this.getCamera();

      this._setGlobalUniform('cameraWorldMatrix', cam.matrixWorld, res);
      // light params
      this._setGlobalUniform('light1Intensity', 1, res);
      this._setGlobalUniform('light2Intensity', 1, res);
      this._setGlobalUniform('light2Translate', new THREE.Vector3(0, 0, 0), res);
    }

    return res;
  }
  _setGlobalUniform(name, value, uniforms = null) {
    let u = uniforms;

    if (!u) {
      u = this._getGlobalUniformObject(true);
    }

    ThreeMaterialUtils.setUniformValue(u, name, value);
  }

  _getThreeMaterialByNode(node, parent) {
    if (!node) {
      return null;
    }
    let n = node;

    if (typeof (node) === 'string') {
      n = this._getMattressNodeByName(node, parent);
    }
    const threeManager = this._getThreeManager();

    if (!threeManager) {
      return null;
    }
    const mesh = threeManager.getThreeObject(n);

    if (mesh instanceof THREE.Mesh) {
      return mesh.material;
    }

    return null;
  }

  _getThreeMaterialByNodeName(nodeName, parent) {
    const node = this._getMattressNodeByName(nodeName, parent);

    if (!node) {
      return null;
    }

    const threeManager = this._getThreeManager();
    const mesh = threeManager.getThreeObject(node);

    if (!mesh) {
      return null;
    }

    return mesh.material;
  }

  getBottomFabricOffsetX(single) {
    return this.getFabricOffsetValue(single, 'bottom', 'x', 0);
  }

  setBottomFabricOffsetX(single, value) {
    this._setFabricOffsetValue(single, 'bottom', 'x', value);

    const mat = this._getThreeMaterialByNodeName('bottom');

    if (!mat || (!mat instanceof ThreeFabricMaterial)) {
      return;
    }

    mat.setSampleTextureOffsetX(value);
  }

  getBottomFabricOffsetY(single) {
    return this.getFabricOffsetValue(single, 'bottom', 'y', 0);
  }

  setBottomFabricOffsetY(single, value) {
    this._setFabricOffsetValue(single, 'bottom', 'y', value);

    const mat = this._getThreeMaterialByNodeName('bottom');

    if (!mat || (!mat instanceof ThreeFabricMaterial)) {
      return;
    }

    mat.setSampleTextureOffsetY(value);
  }

  getTopFabricOffsetX(single) {
    return this.getFabricOffsetValue(single, 'top', 'x', 0);
  }

  setTopFabricOffsetX(single, value) {
    this._setFabricOffsetValue(single, 'top', 'x', value);

    const mat = this._getThreeMaterialByNodeName('top');

    if (!mat || (!mat instanceof ThreeFabricMaterial)) {
      return;
    }

    // mat.setFabricOffsetX(value);
    mat.setSampleTextureOffsetX(value);
  }

  getTopFabricOffsetY(single) {
    return this.getFabricOffsetValue(single, 'top', 'y', 0);
  }

  setTopFabricOffsetY(single, value) {
    this._setFabricOffsetValue(single, 'top', 'y', value);

    const mat = this._getThreeMaterialByNodeName('top');

    if (!mat || (!mat instanceof ThreeFabricMaterial)) {
      return;
    }

    // mat.setFabricOffsetY(value);
    mat.setSampleTextureOffsetY(value);
  }

  getBottomFabricRotation(single) {
    return this._getFabricRotation(single, 'bottom', 0);
  }

  setBottomFabricRotation(single, value) {
    this._setFabricRotation(single, 'bottom', value);

    const mat = this._getThreeMaterialByNodeName('bottom');

    if (!mat || (!mat instanceof ThreeFabricMaterial)) {
      return;
    }
    // TODO: fix bottom sample rotation
    // mat.setFabricRotation(value);
  }

  getTopFabricRotation(single) {
    return this._getFabricRotation(single, 'top', 0);
  }

  setTopFabricRotation(single, value) {
    this._setFabricRotation(single, 'top', value);

    const mat = this._getThreeMaterialByNodeName('top');

    if (!mat || (!mat instanceof ThreeFabricMaterial)) {
      return;
    }
    // TODO: fix top sample rotation
    // mat.setFabricRotation(value);
  }

  getFabricOffsetValue(single, part, coord, fallback) {
    const off = this._getTextureOffset(single, part);

    if (!off) {
      return fallback;
    }
    const res = off[coord];

    if (res === null || typeof (res) === 'undefined' || isNaN(res)) {
      return fallback;
    }

    return res;
  }

  _setFabricOffsetValue(single, part, coord, value) {
    let off = this._getTextureOffset(single, part);

    if (!off) {
      const texData = this._getTextureData(single, part);

      if (!texData) {
        return;
      }
      off = texData.offset = {};
    }
    off[coord] = value;
  }

  _getFabricRotation(single, part, fb = 0) {
    const tex = this._getTextureData(single, part);

    if (!tex) {
      return fb;
    }
    if (tex.rotation === undefined || tex.rotation === null) {
      const singleObj = this._getSingle(single);

      if (!singleObj) {
        return fb;
      }

      if (!singleObj.texture) {
        return fb;
      }

      if (singleObj.texture.rotation === undefined || singleObj.texture.rotation === null) {
        return fb;
      }

      return singleObj.texture.rotation;
    }

    return tex.rotation;
  }

  _setFabricRotation(single, part, value) {
    const tex = this._getTextureData(single, part);

    if (!tex) {
      return;
    }

    tex.rotation = value;
  }

  _getTextureOffset(single, part) {
    const texData = this._getTextureData(single, part);

    if (!texData) {
      return null;
    }

    return texData.offset;
  }

  _getTextureData(single, part) {
    const singleObj = this._getSingle(single);

    if (!singleObj) {
      return null;
    }
    let obj = null;

    if (part !== null && part !== undefined) {
      obj = singleObj[part];
    }

    if (!obj) {
      obj = singleObj;
    }

    return obj.texture;
  }

  _getSingle(single) {
    if (single === undefined || single === null) {
      return null;
    }
    if (typeof (single) === 'number') {
      return this._getSingleAt(single);
    } else if (typeof (single) === 'string') {
      return this._getSingleById(single);
    }

    return single;
  }

  _getSingleAt(single) {
    const cfg = this._mattressConfig;

    if (!cfg) {
      return null;
    }
    const singles = cfg.singles;

    if (!singles) {
      return null;
    }

    return singles[single];
  }

  _getSingleById(id) {
    const cfg = this._mattressConfig;

    if (!cfg) {
      return null;
    }
    const singles = cfg.singles;

    if (!singles) {
      return null;
    }

    const num = singles.length;

    if (num === 0) {
      return null;
    }

    for (let i = 0; i < num; ++i) {
      const single = singles[i];

      if (single && single.id === id) {
        return single;
      }
    }

    return null;
  }

  handleMattress3DComplete(evt) {
    let cfg = null;
    let res = null;
    let bp = null;

    if (typeof (evt) === 'object' && evt !== null) {
      cfg = evt.config;
      res = evt.result;
      bp = evt.buildParams;
    }
    this._buildThreeScene(cfg, bp, res);
  }

  clearMattressContainer() {
    const mattressFactory = this._getMattress3DFactory();

    if (!mattressFactory || !mattressFactory.clearMattressContainer) {
      return;
    }
    mattressFactory.clearMattressContainer();
  }

  clearMattressThreeContainer() {
    const container = this._mattressContainer;
    const children = container ? container.children : null;
    const numChildren = children ? children.length : 0;

    for (let i = numChildren; i >= 0; --i) {
      container.remove(children[i]);
    }
    this._invalidateShadow();
  }

  _dispatchConfigComplete(options) {
    const evtName = 'config_complete';
    if (this.hasEventListener(evtName) && isEventAllowed(evtName, options)) {
      this.dispatchEvent({type: 'config_complete', options: options});
    }
  }

  _buildThreeScene(config, buildParams, result) {
    const mattressFactory = this._getMattress3DFactory();
    const res = mattressFactory.getResult();
    const threeManager = this._getThreeManager();
    const threeConvertParams = this._getThreeConvertParams();

    this._mattressContainer3D = res;

    let threeRes = this._threeMattressContainer3D;

    threeConvertParams.assetManager = mattressFactory.getAssetManager();
    threeConvertParams.globalUniforms = this._getGlobalUniforms(true);
    threeConvertParams.renderer = this.getRenderer();

    threeManager.disposeNode3D(this._mattressContainer3D);
    threeRes = threeManager.convert(res, threeConvertParams, threeRes);

    this._threeMattressContainer3D = threeRes;

    this.clearMattressThreeContainer();

    this._mattressContainer.add(threeRes);

    // Preserve the previous selection
    let oldSelection = null;

    if (mattressFactory) {
      oldSelection = this.getSelectedData();
      let oldSelectionIsGroup = false;

      if (oldSelection) {

        const cfg = this.getMattressConfig();

        if (cfg) {
          oldSelectionIsGroup = cfg.objectIsType(oldSelection, MattressConfigObjectTypes.GROUP);
        }
      }
      // Check if old selection is part of the current configuration
      const oldSelNode3d = this.getObjectByData(oldSelection);

      if (!oldSelNode3d && !oldSelectionIsGroup) {
        oldSelection = null;
      }
    }
    this._updateCamZoom();

    this._invalidateShadow();

    this._setSelectedData(oldSelection);

    this._setBackgroundFromConfig();

    this._setConfigLoadingState(LOADING_COMPLETE);

    this.render();

    const bp = buildParams || this._buildParams;
    const options = bp && bp.options;
    this._dispatchConfigComplete(options);
  }

  _disposeAsset(asset) {
    if (!asset) {
      return;
    }
    if (asset instanceof ImageAsset) {
      const data = asset.getData();

      if (data.texture && data.texture.dispose) {
        data.texture.dispose();
      }
    } else if (asset instanceof CubemapAsset) {
      const userData = asset.userData;
      const cubeTex = userData.cubeTexture;

      if (cubeTex) {
        cubeTex.dispose();
      }
    } else if (asset instanceof BackgroundAsset) {
      const data = asset.data;
      const assets = data ? data.assets : null;

      if (assets) {
        this._disposeAsset(assets.image);
        this._disposeAsset(assets.cubemap);
      }
    }
  }

  _setBackgroundFromConfig() {
    // Dispose previous background assets
    this._disposeAsset(this._currentBackgroundAsset);

    const cfg = this.getMattressConfig();
    const bgId = cfg ? cfg.getBackgroundId() : null;
    const bgCol = cfg ? cfg.getBackgroundColor() : null;

    const mf = this._getMattress3DFactory();
    const assetMgr = mf ? mf.getAssetManager() : null;
    let currentBgAsset = null;

    if (bgId && assetMgr) {
      const bgAsset = assetMgr.assetCollections.backgrounds.getAssetByName(bgId);
      const bgAssetData = bgAsset ? bgAsset.data : null;
      const bgAssets = bgAssetData ? bgAssetData.assets : null;

      currentBgAsset = bgAsset;

      // TODO: include background parameters (alignment, scale mode, ...)
      if (bgAssets) {
        if (bgAssets.image) {
          this.setBackground(bgAssets.image);
        } else if (bgAssets.cubemap) {
          this.setBackground(bgAssets.cubemap);
        }
      }
    } else if (bgCol !== null && typeof (bgCol) !== 'undefined') {
      // this.setBackground(null);
      this.setBackground(bgCol);
    }
    this._currentBackgroundAsset = currentBgAsset;
  }

  get backgroundService() {
    return this.getBackgroundService();
  }

  set backgroundService(v) {
    this.setBackgroundService(v);
  }

  getBackgroundService() {
    const factory = this._getMattress3DFactory();

    if (!factory) {
      return null;
    }

    return factory.getBackgroundService();
  }

  setBackgroundService(svc) {
    const factory = this._getMattress3DFactory();

    if (!factory) {
      return;
    }

    factory.setBackgroundService(svc);
  }

  addBackground(background, id = null) {
    const svc = this.getBackgroundService();

    if (!svc) {
      return;
    }
    svc.addBackground(background, id);
  }
  addBackgrounds(backgrounds) {
    const svc = this.getBackgroundService();

    if (!svc) {
      return;
    }
    svc.addBackgrounds(backgrounds);
  }
  removeBackground(background) {
    const svc = this.getBackgroundService();

    if (!svc) {
      return;
    }
    svc.removeBackground(background);
  }

  removeBackgrounds() {
    const svc = this.getBackgroundService();

    if (!svc) {
      return;
    }
    svc.addBackgrounds();
  }

  addSample(sample, id = null) {
    if (!sample) {
      return;
    }
    const sampleSvc = this.getSampleService();

    if (!sampleSvc) {
      return;
    }
    sampleSvc.addSample(sample, id);
  }

  removeSample(sample) {
    if (!sample) {
      return;
    }
    const sampleSvc = this.getSampleService();

    if (sampleSvc) {
      sampleSvc.removeSample(sample);
    }

    const mf = this._mattressFactory;
    const am = mf ? mf.getAssetManager() : null;
    const ac = am ? am.assetCollections : null;
    const samples = ac ? ac.samples : null;

    if (samples) {
      let id = typeof (sample) === 'string' ? sample : sample.id;

      if (typeof (id) !== 'string') {
        id = `${id}`;
      }

      samples.removeAsset(id);
    }
  }

  removeSamples(samples) {
    if (!samples) {
      return;
    }
    const sampleSvc = this.getSampleService();

    if (!sampleSvc) {
      return;
    }
    sampleSvc.rmoveSamples(samples);
  }

  removeAllSamples() {
    const sampleSvc = this.getSampleService();

    if (!sampleSvc) {
      return;
    }
    sampleSvc.removeAllSamples();
  }

  addSamples(samples) {
    if (!samples) {
      return;
    }

    const sampleSvc = this.getSampleService();

    if (!sampleSvc) {
      return;
    }
    sampleSvc.addSamples(samples);
  }

  getSampleService() {
    const factory = this._getMattress3DFactory();

    if (!factory) {
      return null;
    }

    return factory.getSampleService();
  }

  setSampleService(service) {
    const factory = this._getMattress3DFactory();

    if (!factory) {
      return;
    }

    factory.setSampleService(service);
  }

  get sampleService() {
    return this.getSampleService();
  }

  set sampleService(s) {
    this.setSampleService(s);
  }

  /**
   * @method addQuilt
   * @param {Object} quilt - quilt data object
   * @param {String|int} id - optional alternative quilt id
   * */
  addQuilt(quilt, id = null) {
    if (!quilt) {
      return;
    }
    const svc = this.getQuiltService();

    if (!svc) {
      return;
    }

    svc.addQuilt(quilt, id);
  }

  /**
   * @method addQuilts
   * @param {Object|Array} quilts - quilt dictionary or array
   * */
  addQuilts(quilts) {
    if (!quilts) {
      return;
    }
    const svc = this.getQuiltService();

    if (!svc) {
      return;
    }

    svc.addQuilts(quilts);
  }

  /**
   * @method removeQuilt
   * @param {Object|String|int} quilt - quilt or quilt id
   * */
  removeQuilt(quilt) {
    if (!quilt) {
      return;
    }
    const svc = this.getQuiltService();

    if (!svc) {
      return;
    }

    svc.removeQuilt(quilt);
  }

  /**
   * @method removeQuilts
   * @param {Object|Array} quilts - quilt dictionary or array
   * */
  removeQuilts(quilts) {
    if (!quilts) {
      return;
    }
    const svc = this.getQuiltService();

    if (!svc) {
      return;
    }

    svc.removeQuilts(quilts);
  }

  removeAllQuilts() {
    const svc = this.getQuiltService();

    if (!svc) {
      return;
    }

    svc.removeAllQuilts();
  }

  _assignQuiltServiceURLResolver() {
    const qs = this.getQuiltService();
    const res = this._getURLResolver();

    if (!qs) {
      return;
    }

    qs.urlResolver = res;
  }

  getQuiltService() {
    const factory = this._getMattress3DFactory();

    if (!factory) {
      return null;
    }

    return factory.getQuiltService();
  }

  setQuiltService(service) {
    const factory = this._getMattress3DFactory();

    if (!factory) {
      return;
    }

    factory.setQuiltService(service);
  }

  get quiltService() {
    return this.getQuiltService();
  }

  set quiltService(s) {
    this.setQuiltService(s);
  }

  getConfigPropertyValue(property, params = null) {
    const cfg = this.getMattressConfig();

    if (!cfg) {
      return null;
    }

    return cfg.getScenePropertyValue(property, params);
  }

  setConfigPropertyValue(property, value, params = null) {
    const cfg = this.getMattressConfig();

    if (!cfg) {
      return null;
    }

    const mf = this._getMattress3DFactory();
    const assetMgr = mf && mf.getAssetManager();
    const assetCollections = assetMgr && assetMgr.assetCollections;
    const configPropertyParams = this[CONFIG_PROPERTY_PARAMS] || {};
    this[CONFIG_PROPERTY_PARAMS] = configPropertyParams;
    configPropertyParams.assetCollections = assetCollections;

    return cfg.setScenePropertyValue(property, value, params, configPropertyParams);
  }

  getMattressConfig(create = false) {
    if (create && !this._mattressConfig) {
      this._mattressConfig = MattressConfig.create();
      this._setMattressConfigEventListeners(this._mattressConfig, true);
    }

    return this._mattressConfig;
  }

  /**
   * @method setSample
   * @description Applies hard-coded sample data to all elements of a single (top, borders, bottom)
   *  Examples:
   *   mattressConfigurator.setSample('some-mattress-id', {base_image: 'path/to/texture.jpg', base_bump: 'path/to/bumpmap', realWidth: 400, realHeight: 300});
   *   mattressConfigurator.setSample(0, {base_image: 'path/to/texture.jpg', realWidth: 400, realHeight: 300});
   *   mattressConfigurator.setSample(single, 'sample-id', 'path/to/texture.jpg', 'path/to/bumpmap.jpg', );
   * @param {Object|String|int} single - Single data, single id (string) or single index (int)
   * @param {Object|String|int} sampleData - sample object containing at least a base_image(string), realWidth(int) and realHeight(int) or
   *  the id of the sample (should specify other parameters in that case)
   * @param {String} imageURL - (optional if sampleData is an object) URL to the sample texture
   * @param {String} bumpURL - (optional if sampleData is an object) URL to the bump map
   * @param {number} realWidth - (optional if sampleData is an object) The sample width in mm
   * @param {number} realHeight - (optional if sampleData is an object) The sample height in mm
   * @return {void}
   *
   * */
  setSample(single, sampleData, imageURL = null, bumpURL = null, realWidth = 0, realHeight = 0) {
    if (single === null || typeof (single) === 'undefined') {
      return;
    }
    if (sampleData === null || typeof (sampleData) === 'undefined') {
      return;
    }
    let sampleID = sampleData, rw = realWidth, rh = realHeight,
      imgURL = imageURL, bmpURL = bumpURL, nrmURL = null, spcURL = null;

    const BASE_IMAGE = 'base_image';
    const BASE_BUMP = 'base_bump';
    const BASE_NORMAL = 'base_normal';
    const BASE_SPECULAR = 'base_specular';

    if (typeof (sampleID) === 'object') {
      sampleID = sampleData.id;
      rw = sampleData.realWidth;
      rh = sampleData.realHeight;
      imgURL = sampleData[BASE_IMAGE] || sampleData.baseImage;
      bmpURL = sampleData[BASE_BUMP] || sampleData.baseBump || sampleData.baseBumpMap;
      nrmURL = sampleData[BASE_NORMAL] || sampleData.baseNormal || sampleData.baseNormalMap;
      spcURL = sampleData[BASE_SPECULAR] || sampleData.baseSpecular || sampleData.baseSpecularMap;
    }
    if (!sampleID) {
      sampleID = '__temp_internal_sample_id__';
    }

    const sample = {
      id: sampleID,
      // real width & real height in mm
      realWidth: rw,
      realHeight: rh
    };

    sample[BASE_IMAGE] = imgURL;
    sample[BASE_BUMP] = bmpURL;
    sample[BASE_NORMAL] = nrmURL;
    sample[BASE_SPECULAR] = spcURL;

    this.removeSample(sampleID);
    this.addSample(sample);

    const cfg = this.getMattressConfig();

    if (!cfg) {
      return;
    }

    cfg.setSample(single, sampleID);
  }

  /**
   * @method setMattressConfig
   * @description set the current mattress config
   * @param {String|Object|MattressConfig} data - The mattress config
   *  (json string, json object or MattressConfig instance)
   * @param {Object} assets - object with assets.
   *  array with sample assets should be assigned to assets.samples
   *  array with quilt assets should be assigned to assets.quilts (coming soon)
   *
   * @return {void}
   *
   * */
  setMattressConfig(data, assets) {
    const cfg = data;

    if (!(cfg instanceof MattressConfig)) {
      this.setMattressConfigJSON(cfg, assets);

      return;
    }

    if (this._registerHistoryState && this.autoRegisterHistoryState !== false) {
      const state = this._createCurrentHistoryState();

      state.assets = assets;
      this._registerHistoryState(state);
    }

    this._addAssets(assets);

    this._setMattressConfig(data, assets);

  }

  _addAssets(assets) {
    if (!assets) {
      return;
    }
    this.addSamples(assets.samples);
    this.addQuilts(assets.quilts);
    this.addBackgrounds(assets.backgrounds);
  }

  _setMattressConfig(cfg, options) {
    if (this._mattressConfig !== cfg) {
      this._setMattressConfigEventListeners(this._mattressConfig, false);
      this._mattressConfig = cfg;
      this._setMattressConfigEventListeners(this._mattressConfig, true);
    }

    this._mattressConfig.setSampleService(this.getSampleService());
    this._mattressConfig.setQuiltService(this.getQuiltService());

    this._changedConfig(cfg, options);

    this._dispatchChangeConfig(cfg, options);

    if (this.autoRefresh === false || this.autoRefreshConfig === false) {
      return;
    }
    this.refresh(options);
  }

  _changedConfig(cfg, options) {
    this.setBackground(null);

    // Get camera settings from config
    const data = this.getMattressConfigJSON();
    const oc = this._orbitController;

    if (data) {

      if (!options || options.applyCameraSettings !== false) {
        const camSettings = data.cameraSettings;

        if (camSettings) {
          let rotX = null, rotY = null, dist = null, posX = null, posY = null, posZ = null,
            panX = null, panY = null;

          const pos = camSettings.position; // center of the orbit controller
          const rot = camSettings.rotation;
          const pan = camSettings.pan; // view space offset

          dist = camSettings.distance;
          if (typeof (dist) !== 'number' || isNaN(dist)) {
            dist = camSettings.zoom;
          }

          if (pos) {
            posX = pos.x;
            posY = pos.y;
            posZ = pos.z;
          }
          if (rot) {
            rotX = rot.x;
            rotY = rot.y;
          }
          if (pan) {
            panX = pan.x;
            panY = pan.y;
          }
          const DEG2RAD = Math.PI / 180;

          if (typeof (posX) === 'number' && !isNaN(posX)) {
            oc.setPositionX(posX);
          }
          if (typeof (posY) === 'number' && !isNaN(posY)) {
            oc.setPositionY(posY);
          }
          if (typeof (posZ) === 'number' && !isNaN(posZ)) {
            oc.setPositionZ(posZ);
          }

          if (typeof (rotX) === 'number' && !isNaN(rotX)) {
            oc.setRotationX(rotX * DEG2RAD);
          }
          if (typeof (rotY) === 'number' && !isNaN(rotY)) {
            oc.setRotationY(rotY * DEG2RAD);
          }
          if (typeof (dist) === 'number' && !isNaN(dist)) {
            oc.setDistance(dist);
          }
          if (typeof (panX) === 'number' && !isNaN(panX)) {
            oc.setTargetOffsetX(panX);
          }
          if (typeof (panY) === 'number' && !isNaN(panY)) {
            oc.setTargetOffsetY(panY);
          }
        }
      }
    }

    const threeMgr = this._getThreeManager();

    if (!threeMgr) {
      return;
    }
    // #if DEBUG
    const r = this.getRenderer();

    BD3DLogger.log('*** DISPOSE ALL THREE.JS & WEBGL JUNK ***');
    BD3DLogger.log('num textures before ', r.info.memory.textures);
    // #endif

    threeMgr.disposeThreeObject(this.getScene(), null, true);
    // #if DEBUG
    BD3DLogger.log('num textures after ', r.info.memory.textures);
    // #endif
  }

  _dispatchChangeConfig(cfg, options) {
    const evtName = 'change_config';

    if (this.hasEventListener(evtName)) {
      const evt = this._getEventObject(evtName);

      evt.config = cfg;
      evt.options = options;

      this.dispatchEvent(evt);
    }
  }

  // Called each time mattressConfig.getData() is called
  _handleExportData(evt) {
    if (!evt) {
      return;
    }
    const data = evt.data;

    if (!data) {
      return;
    }

    // update version (should this part be moved directly inside MattressConfig.js?)
    const version = BD3DBuildInfo.getVersion();

    data.version = version;

    // update camera settings
    let camSettings = data.cameraSettings;
    const oc = this._orbitController;

    if (!camSettings) {
      camSettings = data.cameraSettings = {};
    }
    let pos = camSettings.position;
    let rot = camSettings.rotation;
    let pan = camSettings.pan;

    if (!pos) {
      pos = camSettings.position = {};
    }
    if (!rot) {
      rot = camSettings.rotation = {};
    }
    if (!pan) {
      pan = camSettings.pan = {};
    }

    const RAD2DEG = 180 / Math.PI;
    const rotX = Math.round(oc.getRotationX() * RAD2DEG);
    const rotY = Math.round(oc.getRotationY() * RAD2DEG);
    const dist = oc.getDistance();

    rot.x = rotX;
    rot.y = rotY;

    // center position of the orbit controller
    pos.x = oc.getPositionX();
    pos.y = oc.getPositionY();
    pos.z = oc.getPositionZ();

    // local camera view offset
    pan.x = oc.getTargetOffsetX();
    pan.y = oc.getTargetOffsetY();

    camSettings.distance = Math.round(dist);
  }

  _setMattressConfigEventListeners(cfg, add = true) {
    if (!cfg) {
      return;
    }
    let changedDataHandler = this._changedDataHandler, changedPropertyHandler = this._changedPropertyHandler;
    let exportDataHandler = this._exportDataHandler;

    if (add) {
      const that = this;

      if (!changedDataHandler) {
        changedDataHandler = this._changedDataHandler = evt => {
          return that._handleChangedMattressConfigData(evt);
        };
      }
      if (!changedPropertyHandler) {
        changedPropertyHandler = this._changedPropertyHandler = evt => {
          return that._handleChangedMattressConfigProperty(evt);
        };
      }

      if (!exportDataHandler) {
        exportDataHandler = this._exportDataHandler = evt => {
          return that._handleExportData(evt);
        };
      }

      cfg.addEventListener('changed_property', changedPropertyHandler);
      cfg.addEventListener('changed_data', changedDataHandler);
      cfg.addEventListener('export_data', exportDataHandler);
    } else {
      if (changedPropertyHandler) {
        cfg.removeEventListener('changed_property', changedPropertyHandler);
      }
      if (changedDataHandler) {
        cfg.removeEventListener('changed_data', changedDataHandler);
      }
      if (exportDataHandler) {
        cfg.removeEventListener('export_data', exportDataHandler);
      }
    }
  }

  _getThreeData(data) {
    if (!data) {
      return null;
    }
    const tm = this._threeManager;

    if (!tm) {
      return null;
    }

    return tm.getThreeObject(data);
  }

  _getNode3DByThreeData(threeData) {
    if (!threeData) {
      return null;
    }
    const tm = this._threeManager;

    if (!tm) {
      return null;
    }

    return tm.getBD3DObjectByThreeObject(threeData);
  }

  _getNode3DByData(data) {
    if (!data) {
      return null;
    }
    const mf = this._mattressFactory;

    if (!mf) {
      return null;
    }

    return mf.getNode3DByData(data);
  }

  getObjectByData(data) {
    let res = this._getNode3DByData(data);

    if (!res) {
      const cfg = this.getMattressConfig();
      const scene = cfg ? cfg.getScene() : null;

      if (scene) {
        res = scene.getObjectByData(data);
      }
    }

    return res;
  }

  _handleChangedMattressConfigData(evt) {
    const options = evt ? evt.params : null;
    const data = evt ? evt.data : null;

    // #if DEBUG
    BD3DLogger.log('changed config data', evt);
    // #endif
    this._changedConfig(data, options);
    this._dispatchChangeConfig(data, options);

    if (this.autoRefresh === false || this.autoRefreshConfig === false) {
      return;
    }
    this.refresh(options);
  }

  _changeBorderFabricProperty(evt, propName, borderMat, borderData) {
    if (!evt || !propName || !borderData || !borderMat) {
      return;
    }

    const threeMgr = this._threeManager;
    let threeMat = null;

    if (threeMgr) {
      threeMat = this._getThreeData(borderMat);
    }

    if (propName === 'bordercomponent-fabric-offset' ||
      propName === 'bordercomponent-fabric-rotation' ||
      propName === 'bordercomponent-fabric-align-x' ||
      propName === 'bordercomponent-fabric-align-y' ||
      propName === 'bordercomponent-fabric-offset-x' ||

      propName === 'bordercomponent-fabric-offset-y') {
      this._changeBorderFabricTransformProperty(borderData, borderMat);
    } else if (
      propName === 'bordercomponent-quilt-align-x' ||
      propName === 'bordercomponent-quilt-align-y'
    ) {
      let qt = borderMat.getQuiltTransform();

      if (!qt) {
        qt = new QuiltTransform();

        borderMat.setQuiltTransform(qt);
      }
      const qcd = borderData.quilt;
      const qId = qcd ? qcd.id : null;
      const qs = this.getQuiltService();
      const qd = qs.getQuiltById(qId);

      const aX = QuiltDA.getAlignX(qcd, qd);
      const aY = QuiltDA.getAlignY(qcd, qd);

      qt.setAlignX(aX);
      qt.setAlignY(aY);

      threeMgr.updateQuiltTransform(borderMat, threeMat);

    } else if (propName === 'bordercomponent-quilt-foam-value') {
      let quiltNormalIntensity = 0;

      if (borderMat instanceof BD3DFabricMaterial) {
        quiltNormalIntensity = borderMat.getQuiltNormalIntensity();
      } else {
        const qCfg = borderMat.get('quiltConfig');

        if (qCfg) {
          quiltNormalIntensity = qCfg.getQuiltFoamValue();
        }
      }
      threeMat.setQuiltNormalIntensity(quiltNormalIntensity);
    }
  }

  _updateSampleMaterialTransform(material, textureData = null, quiltData = null, sample = true, quilt = true) {
    const threeMgr = this._threeManager;
    const isSampleMaterial = (material instanceof BD3DSampleFabricMaterial);

    if (isSampleMaterial) {
      const threeMat = this._getThreeData(material);

      // Update sample
      if (sample) {
        const mtlTexData = material.getSampleConfigData();
        const texData = textureData || mtlTexData;
        let sampleTransf = material.getSampleTransform();

        if (texData && texData !== mtlTexData) {
          material.setSampleConfigData(texData);
        }

        if (!sampleTransf && texData) {
          sampleTransf = new SampleTransform();
          material.setSampleTransform(sampleTransf);
        }

        if (sampleTransf) {
          const sampleData = material.getSampleData();

          sampleTransf.setData(sampleData);
          sampleTransf.setConfigData(texData);
        }
        threeMgr.updateFabricTransform(material, threeMat);
      }

      // Update quilt
      if (quilt) {
        const mtlQuiltCfgData = material.getQuiltConfig();
        const qCfgData = quiltData || mtlQuiltCfgData;
        let quiltTransf = material.getQuiltTransform();

        if (qCfgData && qCfgData !== mtlQuiltCfgData) {
          material.setQuiltConfig(qCfgData);
        }

        if (!quiltTransf && qCfgData) {
          quiltTransf = new QuiltTransform();
          material.setQuiltTransform(quiltTransf);
        }

        if (quiltTransf) {
          const qAsset = material.getQuiltAsset();
          const qAssetQuiltData = qAsset ? qAsset.quiltData : null;
          // TODO: make getQuiltData return the data from the quilt asset, just like how it's done with the samples
          const qData = material.getQuiltData() || qAssetQuiltData;

          quiltTransf.setQuiltData(qData);
          quiltTransf.setQuiltConfigData(qCfgData);
        }

        threeMgr.updateQuiltTransform(material, threeMat);
      }
    }
  }

  _changeBorderFabricTransformProperty(borderData, borderMat) {
    const textureData = borderData ? borderData.texture : null;
    const quiltData = borderData ? borderData.quilt : null;

    this._updateSampleMaterialTransform(borderMat, textureData, quiltData);
    /*
    let transf = borderMat.getSampleTransform();

    if (!transf) {
      transf = new SampleTransform();
      borderMat.setSampleTransform(transf);
    }
    if (transf) {
      transf.setConfigData(borderData.texture);

      if (borderMat instanceof BD3DSampleFabricMaterial) {
        const borderSampleData = borderMat.getSampleData();

        transf.setData(borderSampleData);
      }
    }

    const threeMgr = this._threeManager;

    if (threeMgr) {
      const threeMat = this._getThreeData(borderMat);

      threeMgr.updateFabricTransform(borderMat, threeMat);
    }
    */
  }
  _changeTopBottomFabricProperty(evt, propName, mat, threeMat, prop, threeMgr) {
    let updateFabricTransform = false;

    if (propName === 'top_fabric_rotation' ||
      propName === 'bottom_fabric_rotation' ||
      propName === 'top_fabric_align_x' ||
      propName === 'top_fabric_align_y' ||
      propName === 'top_fabric_offset_x' ||
      propName === 'top_fabric_offset_y' ||
      propName === 'bottom_fabric_align_x' ||
      propName === 'bottom_fabric_align_y' ||
      propName === 'bottom_fabric_offset_x' ||
      propName === 'bottom_fabric_offset_y') {
      const fabricTransform = mat.getSampleTransform();

      // TODO: maybe these lines are not necessary;
      // fabricTransform uses a direct reference to the json data
      if (prop === 'x') {
        fabricTransform.setOffsetX(evt.value);
      } else if (prop === 'y') {
        fabricTransform.setOffsetY(evt.value);
      } else if (prop === 'rotation') {
        fabricTransform.setConfigRotation(evt.value);
      } else if (prop === 'align-x') {
        fabricTransform.setAlignX(evt.value);
      } else if (prop === 'align-y') {
        fabricTransform.setAlignY(evt.value);
      }
      updateFabricTransform = true;
    } else if (
      propName === 'top_quilt_offset_x' ||
      propName === 'top_quilt_offset_y' ||
      propName === 'top_quilt_offset' ||
      propName === 'top_quilt_rotation' ||

      propName === 'top_quilt_align_x' ||
      propName === 'top_quilt_align_y' ||
      propName === 'top_quilt_align_xy' ||

      propName === 'bottom_quilt_offset_x' ||
      propName === 'bottom_quilt_offset_y' ||
      propName === 'bottom_quilt_offset' ||
      propName === 'bottom_quilt_rotation' ||
      propName === 'bottom_quilt_align_x' ||
      propName === 'bottom_quilt_align_y' ||
      propName === 'bottom_quilt_align_xy'
    ) {

      const quiltTransform = mat.getQuiltTransform();

      if (prop === 'x') {
        quiltTransform.setOffsetX(evt.value);
      } else if (prop === 'y') {
        quiltTransform.setOffsetY(evt.value);
      } else if (prop === 'rotation') {
        quiltTransform.setConfigRotation(evt.value);
      } else if (prop === 'align-x') {
        quiltTransform.setAlignX(evt.value);
      } else if (prop === 'align-y') {
        quiltTransform.setAlignY(evt.value);
      }
      updateFabricTransform = true;
    } else if (
      propName === 'top_quilt_repeat_x' ||
      propName === 'top_quilt_repeat_y' ||
      propName === 'top_quilt_repeat_type' ||
      propName === 'bottom_quilt_repeat_x' ||
      propName === 'bottom_quilt_repeat_y' ||
      propName === 'bottom_quilt_repeat_type'
    ) {
      threeMat.setQuiltRepeat(mat.getQuiltRepeatX(), mat.getQuiltRepeatY());
      updateFabricTransform = true;
    } else if (propName === 'top_quilt_foam_value' || propName === 'bottom_quilt_foam_value') {
      threeMat.setQuiltNormalIntensity(mat.getQuiltNormalIntensity());
    }
    if (updateFabricTransform) {
      threeMgr.updateFabricTransform(mat, threeMat);
    }
  }

  _reloadConfigAfterChangingBackground(evt, scope) {
    scope._setBackgroundFromConfig();
    scope.render();
  }

  _updateObjectFabricTransform(object, property) {
    if (!object) {
      return;
    }
    let material = null;

    if (object.getMaterial) {
      material = object.getMaterial();
    } else if (object.material) {
      material = object.material;
    }
    if (material) {
      const mtlData = this.getDataByObject(material);
      const mtlDataData = mtlData ? mtlData['@data'] : null;
      const textureData = mtlDataData ? mtlDataData.texture : null;

      this._updateSampleMaterialTransform(material, textureData);
    }
    let children = null;

    if (object.getChildren) {
      children = object.getChildren();
    } else if (object.children) {
      children = object.children;
    }
    const numChildren = children ? children.length : 0;

    for (let i = 0; i < numChildren; ++i) {
      this._updateObjectFabricTransform(children[i], property);
    }
  }

  _handleChangedMattressConfigProperty(evt) {
    if (this.autoRefresh === false || this.autoRefreshProperty === false) {
      return;
    }
    const {property} = evt;
    let needsRefreshRender = false;
    let needsRefresh = true;
    const needsRender = true; // Can be changed to a var if needed
    let needsReload = false;
    let reloadCallback = null;
    let registerHistoryState = true;
    let propName = null;

    if (property && typeof (property) === 'object') {
      const md = property.metaData;

      if (property.getKey) {
        propName = property.getKey();
      }
      if (!propName) {
        propName = property.property;
      }

      const isSceneObject = evt.objectType === 'scene-object';

      if (isSceneObject) {
        let meta = null;

        if (property.get) {
          meta = property.get('metadata');
        } else if (property.data) {
          meta = property.data.metadata;
        }

        let propertyTarget = null;

        if (property && property.getTarget) {
          propertyTarget = property.getTarget();
        } else if (property && property.data) {
          propertyTarget = property.data.target;
        }
        const targetObject = this.mattressConfig.getScene().getObjectById(propertyTarget);

        if (meta) {
          needsRefresh = meta.refresh !== false;
          needsRefreshRender = !!meta.refreshRender;
          needsReload = meta.reload !== false;

          if (meta.live) {
            // live scrolling & updating should not register history states on every change (only on release)
            registerHistoryState = false;
          }

          if (meta['sample-transform']) {
            /*
            Notes:
              data = this.mattressConfig.getObjectByID(property.getTarget());
              object = this.mattressConfig.getScene().getObjectById(property.getTarget());
              three object = this._getThreeData(object);
            */


            this._updateObjectFabricTransform(targetObject, property);
          }
        }
        if (!needsRefresh && !needsReload && needsRefreshRender) {
          const threeMgr = this._getThreeManager();
          if (threeMgr) {
            threeMgr.updateObject(targetObject);
          }
        }
        propName = null;
      }

      if (propName === 'bordercomponent-materialtype') {
        console.info('change border ', property.border, ' material type to ', evt.value);
      } else if (
        propName === 'background_id' ||
        propName === 'background_color' ||
        propName === 'background_clear' ||
        propName === 'background') {

        needsReload = false;
        needsRefresh = false;
        if (propName === 'background_id') {
          needsReload = true;
          reloadCallback = this._reloadConfigAfterChangingBackground;
        } else if (propName === 'background_clear') {
          this.setBackground(null);
        } else {
          this._setBackgroundFromConfig();
        }
      } else if (propName && (propName === 'top_fabric_rotation' ||
        propName === 'bottom_fabric_rotation' ||
        propName === 'top_fabric_offset_x' ||
        propName === 'top_fabric_offset_y' ||
        propName === 'bottom_fabric_offset_x' ||
        propName === 'bottom_fabric_offset_y' ||

        propName === 'top_fabric_align_x' ||
        propName === 'top_fabric_align_y' ||
        propName === 'bottom_fabric_align_x' ||
        propName === 'bottom_fabric_align_y' ||

        propName === 'top_quilt_offset_x' ||
        propName === 'top_quilt_offset_y' ||
        propName === 'top_quilt_offset' ||
        propName === 'top_quilt_rotation' ||

        propName === 'bottom_quilt_offset_x' ||
        propName === 'bottom_quilt_offset_y' ||
        propName === 'bottom_quilt_offset' ||
        propName === 'bottom_quilt_rotation' ||

        propName === 'top_quilt_repeat_x' ||
        propName === 'top_quilt_repeat_y' ||
        propName === 'top_quilt_repeat_type' ||

        propName === 'bottom_quilt_repeat_x' ||
        propName === 'bottom_quilt_repeat_y' ||
        propName === 'bottom_quilt_repeat_type' ||

        propName === 'top_quilt_align_x' ||
        propName === 'top_quilt_align_y' ||
        propName === 'top_quilt_align_xy' ||
        propName === 'bottom_quilt_align_x' ||
        propName === 'bottom_quilt_align_y' ||
        propName === 'bottom_quilt_align_xy' ||

        propName === 'top_quilt_foam_value' ||
        propName === 'bottom_quilt_foam_value')
      ) {

        // TODO: _getMattressNodeByName in right single container
        const singleData = MattressConfigDA.getSingle(evt.data, evt.single);
        const singleNode = this._mattressFactory.getNode3DByData(singleData);
        const node = this._getGeometryNode(this._getMattressNodeByName(md.part, singleNode));
        const mat = Node3DMaterialUtils.getMaterial(node);

        if (mat && (mat instanceof BD3DFabricMaterial)) {
          const threeMat = this._getThreeMaterialByNode(node, singleNode);
          // const threeMat = this._getThreeMaterialByNodeName(md.part, singleNode);
          const threeMgr = this._getThreeManager();
          const prop = md.property;

          this._changeTopBottomFabricProperty(evt, propName, mat, threeMat, prop, threeMgr);
        }
        needsRefresh = false;
      } else if (
        propName &&
        (propName === 'bordercomponent-quilt-foam-value' ||
        propName === 'bordercomponent-quilt-align-x' ||
        propName === 'bordercomponent-quilt-align-y' ||
        propName === 'bordercomponent-quilt-align-xy' ||

        propName === 'bordercomponent-fabric-offset' ||
        propName === 'bordercomponent-fabric-rotation' ||
        propName === 'bordercomponent-fabric-align-x' ||
        propName === 'bordercomponent-fabric-align-y' ||
        propName === 'bordercomponent-fabric-offset-x' ||
        propName === 'bordercomponent-fabric-offset-y')) {

        const border = property.border;
        const singleData = MattressConfigDA.getSingle(evt.data, evt.single);
        const borderData = MattressDA.getBorderComponent(singleData, border);
        const borderNode = this._getNode3DByData(borderData);
        let borderMat = Node3DMaterialUtils.getMaterial(borderNode);

        if (!borderMat && borderNode.userData) {
          borderMat = borderNode.userData.fabricMaterial;
        }

        // if (borderMat && borderMat instanceof BD3DFabricMaterial) {
        if (borderMat && borderMat instanceof BD3DMaterial) {
          this._changeBorderFabricProperty(evt, propName, borderMat, borderData);
          // this._changeBorderFabricTransformProperty(borderData, borderMat);
        }
        needsRefresh = false;
      }
    }

    if (registerHistoryState && this._registerCurrentHistoryState && this.autoRegisterHistoryState !== false) {
      this._registerCurrentHistoryState();
    }

    const evtParams = evt ? evt.params : null;
    const evtOptions = evtParams && evtParams.options;

    if (evtParams) {
      if (evtParams.overrideReload) {
        needsReload = evtParams.reload;
      }
      if (evtParams.overrideRefresh) {
        needsRefresh = evtParams.refresh;
      }
    }

    // Deselect if needed
    if (propName === 'handle_type') {
      const selInfo = this.getSelectedDataInfo();
      const selType = selInfo ? selInfo.type : null;
      const selObj = selInfo ? selInfo.object : null;
      const val = evt.value;

      if ((val === null || typeof (val) === 'undefined') && (selType === 'handles')) {
        this.removeDataFromSelection(selObj);
      }
    }

    const refreshParams = evtOptions && evtOptions.refreshParams;

    if (needsRefresh) {
      // Dirty way to prevent webgl memory leaks
      const renderer = this.getRenderer();
      const info = renderer ? renderer.info : null;
      const memInfo = info ? info.memory : null;
      const numGeometries = memInfo ? memInfo.geometries : 0;
      const numTextures = memInfo ? memInfo.textures : 0;

      const MAX_GEOM = 32;
      const MAX_TEXTURES = 32;

      const disposeGeometries = numGeometries > MAX_GEOM;
      const disposeTextures = numTextures > MAX_TEXTURES;

      if (disposeGeometries || disposeTextures) {
        const threeMgr = this._getThreeManager();

        threeMgr.disposeThreeObject(this.getScene(), {
          textures: disposeTextures,
          geometries: disposeGeometries
        }, true);
      }

      this.refresh(refreshParams);
    } else if (needsReload) {
      this._loadConfig(reloadCallback, refreshParams);
    } else if (needsRender) {
      this.renderRequest();
    }
  }

  setMattressConfigJSON(json, assets) {
    this._setMattressConfigJSON(json, assets);
  }

  _setMattressConfigJSON(json, assets, options) {
    const validJson = json !== null && typeof (json) !== 'undefined';
    const curCfg = this.getMattressConfig(false);

    if (!curCfg && !validJson) {
      return;
    }
    let cfg = curCfg;

    if (!cfg) {
      cfg = new MattressConfig();
    }
    let jsonData = json;
    const jsonIsString = typeof (json) === 'string';

    if (jsonIsString) {
      try {
        jsonData = JSON.parse(json);
      } catch (ex) {
        jsonData = null;
      }
    }

    let registerHistoryState = true;

    if (options) {
      registerHistoryState = options.registerHistoryState !== false;
    }

    if (registerHistoryState && this.autoRegisterHistoryState !== false) {
      if (this._registerHistoryState) {
        const state = this._createCurrentHistoryState();

        if (!state.config) {
          const jsonString = jsonIsString ? json : JSON.stringify(jsonData);

          state.config = jsonString;
        }

        state.assets = assets;
        this._registerHistoryState(state);
      }
    }
    if (assets) {
      this._addAssets(assets);
    }
    cfg.setData(jsonData, options);
    if (curCfg !== cfg) {
      this._setMattressConfig(cfg, options);
    }
  }

  exportMattressConfigJSON(params) {
    const cfg = this.getMattressConfig(false);

    if (!cfg) {
      return null;
    }

    return cfg.exportData(params);
  }

  exportMattressConfigJSONString(params = null) {
    const data = this.exportMattressConfigJSON(params);

    return this._toJSONString(data, params);
  }

  getMattressConfigJSON() {
    const cfg = this.getMattressConfig(false);

    if (!cfg) {
      return null;
    }

    return cfg.getData();
  }

  getMattressConfigJSONString(params) {
    const data = this.getMattressConfigJSON();

    return this._toJSONString(data, params);
  }

  _toJSONString(obj, params) {
    let indent = null;

    if (params) {
      if (typeof (params) === 'string') {
        indent = params;
      } else {
        indent = params.indent;
      }
    }

    return JSON.stringify(obj, null, indent);
  }

  _getEventObject(type, create = true) {
    let evtMap = this._eventObjects;

    if (!evtMap && create) {
      evtMap = this._eventObjects = {};
    }
    if (!evtMap) {
      return null;
    }

    let evt = evtMap[type];

    if (!evt && create) {
      evt = evtMap[type] = {};
    }

    evt.type = type;

    return evt;
  }

  _dispatchError(err) {
    if (!err) {
      return;
    }
    if (this.hasEventListener('error')) {
      this.dispatchEvent({
        type: 'error',
        error: err
      });
    }
  }

  _onBuildError(err) {
    this._dispatchError(err);

    return;
  }

  _getBuildParams() {
    let bp = this._buildParams;

    if (!bp) {
      const that = this;

      bp = this._buildParams = {};
      bp.onerror = err => {
        that._onBuildError(err);
      };

      bp.onAfterBuildMattress = (data, result, buildParams, bbox) => {
        this.dispatchEvent('mattress_built', data, result, buildParams, bbox);
      };
    }

    return bp;
  }

  refresh(options = null) {
    const cfg = this.getMattressConfig();

    if (!cfg) {
      return;
    }
    const mattressFactory = this._getMattress3DFactory();
    const buildParams = this._getBuildParams();

    if (buildParams) {
      buildParams.options = options;
    }

    this._setConfigLoadingState(LOADING_PROGRESS);
    if (this.hasEventListener('config_start') && isEventAllowed('config_start', params)) {
      this.dispatchEvent({type: 'config_start'});
    }

    this._assignQuiltServiceURLResolver();

    this.getMattressCameraCollisionController();

    mattressFactory.once('complete', this._getMattress3DCompleteHandler());
    mattressFactory.loadAndBuildMattressConfig(cfg, buildParams, this._mattressContainer3D);
  }

  _loadConfig(callback = null, params = null) {
    const cfg = this.getMattressConfig();

    if (!cfg) {
      if (callback) {
        callback();

        return;
      }

      return;
    }
    // const buildParams = this._getBuildParams();
    const mattressFactory = this._mattressFactory;
    const loadingMgr = mattressFactory._loadingManager;

    this._setConfigLoadingState(LOADING_PROGRESS);
    if (this.hasEventListener('config_start') && isEventAllowed('config_start', params)) {
      this.dispatchEvent({type: 'config_start'});
    }

    let onCompleteDelegate = this._loadConfigCompleteDelegate;

    if (!onCompleteDelegate) {
      onCompleteDelegate = this._loadConfigCompleteDelegate = {};

      onCompleteDelegate.handler = evt => {
        const evtParams = evt && evt.params;
        const loadParams = evtParams && evtParams.params;
        this._setConfigLoadingState(LOADING_COMPLETE);
        // if (this.hasEventListener('config_complete')) {
        //  this.dispatchEvent({type: 'config_complete'});
        // }
        const fn = onCompleteDelegate.func;
        let res = null;

        if (fn) {
          res = fn(evt, onCompleteDelegate.scope);
        }

        this._dispatchConfigComplete(loadParams);

        return res;
      };
    }
    onCompleteDelegate.scope = this;
    onCompleteDelegate.func = callback;


    loadingMgr.once('finished', onCompleteDelegate.handler);

    const mf = this._getMattress3DFactory();
    const assetMgr = mf && mf.getAssetManager();
    loadingMgr.loadConfig(cfg, {assetManager: assetMgr, params});
  }

  isLoading() {
    return this._loadingState === LOADING_PROGRESS;
  }


  _getLoadingStatesObject() {
    let res = this._loadingStates;

    if (!res) {
      res = this._loadingStates = {};
    }

    return res;
  }

  _setConfigLoadingState(state) {
    const loadingStates = this._getLoadingStatesObject();

    loadingStates.config = state;
    this._updateLoadingState();
  }

  _setBackgroundLoadingState(state) {
    const loadingStates = this._getLoadingStatesObject();

    loadingStates.background = state;
    this._updateLoadingState();
  }

  _updateLoadingState() {
    const loadingStates = this._getLoadingStatesObject();
    let state = LOADING_COMPLETE;

    if (loadingStates.config === LOADING_PROGRESS || loadingStates.background === LOADING_PROGRESS) {
      state = LOADING_PROGRESS;
    }

    this._setLoadingState(state);
  }

  _setLoadingState(state) {
    if (this._loadingState === state) {
      return;
    }
    this._loadingState = state;
    if (this.hasEventListener('loading_state')) {
      this.dispatchEvent({type: 'loading_state', loading: this.isLoading()});
    }
  }

  get mattressConfig() {
    return this.getMattressConfig();
  }

  getCamera() {
    return this._camera;
  }

  getScene() {
    return this._scene;
  }

  getDOMElement() {
    const component = this.getComponent();

    if (!component) {
      return null;
    }

    return component.getDOMElement();
  }

  _changedComponentSize() {
    const comp = this.getComponent();
    const width = comp.getWidth() || 0;
    const height = comp.getHeight() || 0;

    const aspect = (width && height) ? width / height : 1;

    const camera = this.getCamera();

    if (camera.aspect !== aspect) {
      camera.aspect = aspect;
      camera.updateProjectionMatrix();
    }

    if (!this._canRender()) {
      this.clearRender();
    }
    super._changedComponentSize();

    this.dispatchEvent('resize_component', this, width, height);
  }

  _canRender() {
    return !this.isLoading();
  }

  _renderOverlays(scene, camera, renderTarget) {
    if (!this._canRender()) {
      return;
    }
    const renderer = this.getRenderer();
    const oldAutoClear = renderer.autoClear;

    renderer.autoClear = false;

    // selection
    if (this.hasSelection() && this.isSelectionVisible()) {
      let sr = this._selectionRenderer;

      if (!sr) {
        sr = this._selectionRenderer = new ThreeSelectionRenderer();
        // const borderScale = 0.2;
        // const fillAlpha = 0.15;

        const borderScale = 5;
        const fillAlpha = 0;
        const softness = 0.01;

        // const innerColor = 0xB2FF;
        const innerColor = 0xD5F2FF;
        const outerColor = 0x3498db;
        const colorRangeMin = 0.2;
        const colorRangeMax = 0.3;

        sr.setBorderScale(borderScale);
        sr.setFillAlpha(fillAlpha);
        sr.setSoftness(softness);
        sr.setColorRange(colorRangeMin, colorRangeMax);
        sr.setColors(innerColor, outerColor);
      }

      sr.renderer = this.getRenderer();
      sr.render(scene, camera, renderTarget);
    }
    renderer.autoClear = oldAutoClear;
  }

  _setBackgroundTextureSize(width, height) {
    const renderer = this._getBackgroundRenderer();

    if (renderer) {
      const bgTextureRenderer = renderer._getBackgroundContentRenderer('texture');

      if (bgTextureRenderer) {
        bgTextureRenderer.setTextureSize(width, height);
      }
    }
    const camera = this.getCamera();

    const aspect = (height > 0 && width > 0) ? (width / height) : 1;

    if (camera.targetAspect !== aspect) {
      camera.targetAspect = aspect;
      camera.updateProjectionMatrix();
    }
  }

  setBackgroundImageURL(url, params) {
    this._disposeBackground();
    if (!url) {
      this._setBackgroundLoadingState(LOADING_COMPLETE);

      return;
    }

    this._setBackgroundLoadingState(LOADING_PROGRESS);

    const bg = {};
    const img = new Image();

    const that = this;
    const tex = new THREE.Texture();
    const handler = evt => {

      img.removeEventListener('load', handler);
      const imgW = img.width;
      const imgH = img.height;

      const renderer = that.getRenderer();
      const gl = renderer.context;

      const maxTexSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);

      const resImg = CanvasUtils.resizeToPO2(img, {maxSize: maxTexSize});
      let immediateRender = true;

      if (resImg && (resImg.width !== imgW || resImg.height !== imgH)) {
        tex.image = resImg;
        immediateRender = false;
      }


      tex.needsUpdate = true;

      that._setBackground(tex, params, immediateRender);
      if (!immediateRender) {
        that._setBackgroundTextureSize(imgW, imgH);
        // const bgRenderer = that._getBackgroundRenderer();
        // const bcRenderer = bgRenderer ? bgRenderer._contentRenderer : null;

        // if (bcRenderer && bcRenderer.setTextureSize) {
        //   bcRenderer.setTextureSize(imgW, imgH);
        // }
        that.renderRequest();
      }
      that._setBackgroundLoadingState(LOADING_COMPLETE);
    };

    this._background = bg;

    img.crossOrigin = 'Anonymous';
    img.addEventListener('load', handler);

    tex.image = img;
    bg.image = img;
    bg.texture = tex;
    bg.dispose = () => {
      if (img && handler) {
        img.removeEventListener('load', handler);
      }
      if (tex) {
        tex.dispose();
      }
    };

    // Dirty solution:
    // Appending a '?' at the end of an image url should fix the CORS issues in chrome
    let fixCORSURL = url;

    if (fixCORSURL.indexOf('?', 0) < 0) {
      fixCORSURL = `${fixCORSURL}?`;
    }
    img.src = fixCORSURL;
  }

  hasBackground() {
    const bgRenderer = this._getBackgroundRenderer();
    const bg = bgRenderer && bgRenderer.getBackground();

    return bg !== null && typeof bg !== 'undefined';
  }

  hasBackgroundColor() {
    const bgRenderer = this._getBackgroundRenderer();
    const bg = bgRenderer && bgRenderer.getBackground();
    const typeOfBg = typeof bg;

    return (typeOfBg === 'number') || (typeOfBg === 'string');
  }

  hasBackgroundImage() {
    const bgRenderer = this._getBackgroundRenderer();
    const bg = bgRenderer && bgRenderer.getBackground();

    return (bg instanceof THREE.Texture) || (bg instanceof Image);
  }

  _disposeBackground() {
    if (this._background && this._background.dispose) {
      this._background.dispose();
    }
    this._background = null;
    this._setBackground(null);
  }

  /**
   * @method setBackground
   * @description sets the background
   * @param {String|Number|THREE.Texture|THREE.CubeTexture|BD3D.ImageAsset} value - the background value
   *  Allowed values:
   *  1) hex strings, numbers for colors:
   *    #RRGGBB, rgb(R,G,B), rgba(R,G,B,A), 0xRRGGBB
   *  2) 2D THREE.Texture, ImageAsset for images
   *  3) THREE.CubeTexture for 360 degree environments
   * @param {Object} params - optional params object
   *  If using a 2D texture:
   *   scaleMode {String} - 'fit', 'fill' or 'stretch' (default = 'fit')
   *      fit: keeps the aspect ratio and fits the image in the viewport
   *      fill: keeps the aspect ratio and fills the whole image in the viewport,
   *          so some parts of the image could be cropped
   *      stretch: resizes to the viewport width and height without keeping the aspect ratio
   *   alignX {Number|String} - 0 or 'left' = left alignment, 0.5 or 'center' = center alignment, 1 or 'right' = right alignment, (default = 0.5 or 'center')
   *   alignY {Number|String} - 0 or 'top' = top alignment, 0.5 or 'middle' = middle alignment, 1 or bottom = bottom alignment (default = 0.5 or 'middle')
   *   maxScale {Number} - max scale, use -1 to ignore (default = -1)
   *   minScale {Number} - min scale, use -1 to ignore (default = -1)
   *   clipBackground {Number} - If 0, the image is repeated (default = 1, no repeat)
   * @return {void}
   * */
  setBackground(value, params = null) {
    this._disposeBackground();

    this._setBackground(value, params);
  }

  _setBackground(value, params = null, _render = true) {

    const validBg = value !== null && typeof (value) !== 'undefined';

    const bgr = this._getBackgroundRenderer(validBg);

    let val = value;
    let texW = params && params.texturewidth;
    let texH = params && params.textureheight;

    if (value instanceof ImageAsset) {
      val = ThreeAssetUtils.getImageAssetTexture(value);
      if (!(texW > 0 && texH > 0)) {
        texW = value.getWidth();
        texH = value.getHeight();
      }
    }

    if (value && !(texW > 0 && texH > 0) && (value instanceof THREE.Texture)) {
      const img = value.image;

      if (img) {
        texW = img.naturalWidth || img.width;
        texH = img.naturalHeight || img.height;
      }
    }

    if (!bgr) {
      return;
    }
    bgr.setBackground(val, params);
    const camera = this.getCamera();

    if (camera && camera.setScaleMode) {
      camera.setScaleMode(params && params.scaleMode);
    }

    this._setBackgroundTextureSize(texW, texH);

    if (_render) {
      this.renderRequest();
    }
  }

  _getBackgroundRenderer(create = false) {
    let bgRenderer = this._backgroundRenderer;

    if (bgRenderer || create === false) {
      return bgRenderer;
    }

    bgRenderer = this._backgroundRenderer = new BackgroundRenderer();

    return bgRenderer;
  }

  _renderBackground(target = null) {
    const bgRenderer = this._getBackgroundRenderer(false);

    if (!bgRenderer) {
      return;
    }
    const cam = this.getCamera();

    cam.updateMatrix();
    cam.updateMatrixWorld();
    bgRenderer.render(this.getRenderer(), this.getScene(), this.getCamera(), target);
  }

  _getRenderPluginParams() {
    let res = this._renderPluginParams;

    if (!res) {
      res = this._renderPluginParams = {};
    }

    return res;
  }

  _renderRenderPlugins(renderer, scene, camera, renderTarget, autoClear) {
    if (!this._canRender()) {
      return;
    }
    const p = this._renderPlugins;

    if (!p) {
      return;
    }
    const l = p.length;

    if (!l) {
      return;
    }
    let r = renderer;

    if (!r) {
      r = this.getRenderer();
    }
    if (!r) {
      return;
    }
    const params = this._getRenderPluginParams();

    params.globalUniforms = this._getGlobalUniforms();

    for (let i = 0; i < l; ++i) {
      const rp = p[i];

      if (rp && rp.render) {
        rp.render(r, scene, camera, renderTarget, autoClear, params);
      }
    }
  }

  addRenderPlugin(p) {
    if (!p) {
      return;
    }
    let plugins = this._renderPlugins;

    if (!plugins) {
      plugins = this._renderPlugins = [];
    }
    if (plugins.indexOf(p, 0) >= 0) {
      return;
    }
    plugins.push(p);
    this._autoRenderRequest();
  }

  removeRenderPlugin(p) {
    if (!p) {
      return;
    }
    const plugins = this._renderPlugins;

    if (!plugins) {
      return;
    }
    const i = plugins.indexOf(p, 0);

    if (i < 0) {
      return;
    }
    plugins.splice(i, 1);
    this._autoRenderRequest();
  }

  removeRenderPlugins() {
    const plugins = this._renderPlugins;

    if (!plugins) {
      return;
    }
    plugins.length = 0;
  }

  clearRender() {
    const r = this.getRenderer();

    if (!r) {
      return;
    }
    r.clear();
  }

  render() {
    if (!this._canRender()) {
      return;
    }
    const r = this.getRenderer();

    this.renderToRenderer(r);
  }

  renderToRenderer(r, cam = null, renderTarget = null) {
    if (!r) {
      return;
    }
    if (!this._canRender()) {
      return;
    }
    const preRenderEvtName = 'pre_render';

    if (this.hasEventListener(preRenderEvtName)) {
      const preRenderEvt = this._getEventObject(preRenderEvtName);

      this.dispatchEvent(preRenderEvt);
    }

    const oldAutoClear = r.autoClear;

    r.setRenderTarget(renderTarget);

    r.autoClear = false;

    r.clear();

    this._renderBackground(renderTarget);

    const camera = cam ? cam : this.getCamera();
    const scene = this.getScene();

    if (!camera || !scene) {
      return;
    }

    this._validateRenderShadow();
    r.render(scene, camera, renderTarget);

    this._renderOverlays(scene, camera, renderTarget);
    this._renderRenderPlugins(r, scene, camera, renderTarget);

    const evtName = 'post_render';

    if (this.hasEventListener(evtName)) {
      const evt = this._getEventObject(evtName);

      this.dispatchEvent(evt);
    }
    r.autoClear = oldAutoClear;
  }

  // //////////////////////////////////////////////////////////////
  //
  // Taking screenshots
  //
  // //////////////////////////////////////////////////////////////
  _getScreenshotUtil(create = true) {
    let res = this._screenshotUtil;

    if (res || !create) {
      return res;
    }
    res = this._screenshotUtil = new ThreeScreenshotUtil();
    let oldAspect = 1;

    if (!this._onBeforeRenderScreenshot) {
      this._onBeforeRenderScreenshot = () => {
        if (!this.hasBackgroundImage()) {
          const camera = this.getCamera();
          const component = this.getComponent();
          const componentWidth = component.getWidth();
          const componentHeight = component.getHeight();
          const componentAspect = componentWidth / componentHeight;

          oldAspect = camera.targetAspect;
          if (camera.targetAspect !== componentAspect) {
            camera.targetAspect = componentAspect;
            camera.updateProjectionMatrix();
          }
        }
      };
    }
    if (!this._onAfterRenderScreenshot) {
      this._onAfterRenderScreenshot = () => {
        if (!this.hasBackgroundImage()) {
          const camera = this.getCamera();

          if (camera.targetAspect !== oldAspect) {
            camera.targetAspect = oldAspect;
            camera.updateProjectionMatrix();
          }
        }
      };
    }

    res.onBeforeRender = this._onBeforeRenderScreenshot;
    res.onAfterRender = this._onAfterRenderScreenshot;

    return res;
  }

  _getScreenshotCamera() {
    return this.getCamera();
  }

  _getDefaultScreenshotParams() {
    let res = this._defaultScreenshotParams;

    if (!res) {
      res = this._defaultScreenshotParams = {};

      const that = this;

      res.render = (renderer, scene, camera, rt, autoClear, rparams, screenshotUtil) => {
        const oldAutoClear = renderer.autoClear;
        const imgFormat = screenshotUtil.getMimeTypeFromParams(rparams);

        renderer.autoClear = false;
        renderer.setRenderTarget(rt);

        const oldClearColor = renderer.getClearColor();
        const oldClearAlpha = renderer.getClearAlpha();

        if (imgFormat === 'image/jpeg') {
          const defBGColor = 0xFFFFFF;
          const bgColor = screenshotUtil.getBackgroundColorFromParams(rparams, defBGColor);

          renderer.setClearColor(bgColor, 1.0);
          renderer.clear();
        }

        const useDefaultCameraAngle = rparams && rparams.defaultCameraAngle;
        const currentCameraViewData = useDefaultCameraAngle ? this.getCameraViewData() : null;

        if (useDefaultCameraAngle) {
          that.resetView(0);
        }

        that._renderBackground(rt);
        renderer.render(scene, camera, rt, false);
        renderer.autoClear = oldAutoClear;
        renderer.setClearColor(oldClearColor, oldClearAlpha);

        if (currentCameraViewData) {
          this.setCameraView(currentCameraViewData, 0);
        }
      };
    }

    return res;
  }

  _getScreenshotParams(params = null) {
    const defParams = this._getDefaultScreenshotParams();
    const p = params || defParams;

    if (!p) {
      return p;
    }
    if (p !== defParams) {
      p.render = defParams.render;
    }

    if (typeof (p.multisample) !== 'number' || isNaN(p.multisample)) {
      p.multisample = 2;
    }

    return p;
  }

  // #if DEBUG
  openScreenshotWindow(params = null) {
    const useImage = params && params.useImage;

    if (useImage) {
      const img = new Image();
      const dataURI = this.getScreenshotDataURI(params);

      img.onload = () => {
        img.onload = null;
        img.style.border = '1px solid black';

        const w = img.width;
        const h = img.height;
        const win = window.open('', '_blank', `width=${w}, height=${h}`);
        const doc = win.document;

        doc.body.appendChild(img);
      };
      img.src = dataURI;

      return;
    }

    const canvas = this.getScreenshotCanvas(params);

    if (!canvas) {
      return;
    }

    canvas.style.border = '1px solid black';

    const w = canvas.width;
    const h = canvas.height;
    const win = window.open('', '_blank', `width=${w}, height=${h}`);

    const doc = win.document;

    doc.body.appendChild(canvas);
  }
  // #endif

  getScreenshotCanvas(params = null) {
    const util = this._getScreenshotUtil(true);
    const p = this._getScreenshotParams(params);

    return util.getScreenshotCanvas(this.getRenderer(), this.getScene(), this._getScreenshotCamera(), p);
  }

  getScreenshotDataURI(params = null) {
    const util = this._getScreenshotUtil(true);
    const p = this._getScreenshotParams(params);

    return util.getScreenshotDataURI(this.getRenderer(), this.getScene(), this._getScreenshotCamera(), p);
  }

  getScreenshotBase64(params = null) {
    const util = this._getScreenshotUtil(true);
    const p = this._getScreenshotParams(params);

    return util.getScreenshotBase64(this.getRenderer(), this.getScene(), this._getScreenshotCamera(), p);
  }

  // //////////////////////////////////////////////////////////////
  //
  // Disposing
  //
  // //////////////////////////////////////////////////////////////
  dispose() {
    this._disposeHistoryManager();
    this._disposeMattressCameraCollisionController();
    this.disposeThree();

  }

  disposeThree() {
    ThreeFabricMaterial.dispose();
    ThreeScreenshotUtil.dispose();
  }

  _disposeMattressCameraCollisionController() {
    const mattressCameraCollisionController = this._mattressCameraCollisionController;

    if (mattressCameraCollisionController) {
      mattressCameraCollisionController.dispose();
      this._mattressCameraCollisionController = null;
    }
  }

  // //////////////////////////////////////////////////////////////
  //
  // Undo / redo
  //
  // //////////////////////////////////////////////////////////////
  _disposeHistoryManager() {
    const undoH = this._undoHandler;
    const redoH = this._redoHandler;
    const hm = this._historyManager;

    if (hm) {
      if (undoH) {
        hm.removeEventListener('undo', undoH);
      }
      if (redoH) {
        hm.removeEventListener('redo', redoH);
      }
    }

    this._undoHandler = null;
    this._redoHandler = null;
    this._historyManager = null;
  }

  resetHistory() {
    const hm = this._getHistoryManager(false);

    if (!hm) {
      return;
    }
    const st = this._createCurrentHistoryState();

    hm.reset(st);
  }

  _initHistoryManager(hm) {
    if (!hm) {
      return;
    }
    let undoH = this._undoHandler, redoH = this._redoHandler;
    const that = this;

    if (!undoH) {
      undoH = this._undoHandler = evt => {
        return that._handleUndo(evt);
      };
    }
    if (!redoH) {
      redoH = this._redoHandler = evt => {
        return that._handleRedo(evt);
      };
    }

    hm.addEventListener('undo', undoH);
    hm.addEventListener('redo', redoH);

    let maxLevels = DEFAULT_MAX_HISTORY_LEVELS;

    if (typeof (this._maxHistoryLevels) === 'number') {
      maxLevels = this._maxHistoryLevels;
    }
    hm.maxLevels = maxLevels;

    return;
  }

  _applyHistoryState(state) {
    if (!state) {
      return;
    }

    const config = state.config;
    const assets = state.assets;

    if (assets) {
      this._addAssets(assets);
    }

    if (config) {
      const options = {
        registerHistoryState: false,
        applyCameraSettings: false
      };

      let configObj = null;

      if (config instanceof MattressConfig) {
        this._setMattressConfig(config, options);
        configObj = config;
      } else {
        this._setMattressConfigJSON(config, assets, options);
        configObj = this.getMattressConfig();
      }

      if (configObj) {
        configObj._setDirty(true);
      }
    }
  }

  _handleUndo(evt) {
    // #if DEBUG
    console.log('undo ', evt);
    // #endif
    if (evt) {
      this._applyHistoryState(evt.newState);
    }
  }

  _handleRedo(evt) {
    // #if DEBUG
    console.log('redo ', evt);
    // #endif
    if (evt) {
      this._applyHistoryState(evt.newState);
    }
  }

  _getHistoryManager(create, initialState) {
    let res = this._historyManager;

    if (res || !create) {
      return res;
    }
    res = this._historyManager = new HistoryManager(initialState);

    this._initHistoryManager(res);

    return res;
  }

  _createCurrentHistoryState() {
    const mcfg = this.getMattressConfig();
    const mcfgData = mcfg ? mcfg.getData() : null;
    const mcfgDataJSON = mcfgData ? JSON.stringify(mcfgData) : null;

    return {
      config: mcfgDataJSON
    };
  }

  // public method
  registerHistoryState() {
    this._registerCurrentHistoryState();
  }

  _registerCurrentHistoryState() {
    this._registerHistoryState(this._createCurrentHistoryState());
  }

  _registerHistoryState(state = null) {

    let hm = this._getHistoryManager(false);

    let st = state;

    if (!st) {
      st = this._createCurrentHistoryState();
    }

    // #if DEBUG
    const idIndex = this._historyIDIndex || 0;

    st.ID = `history_item_${idIndex}`;

    this._historyIDIndex = idIndex + 1;
    // #endif

    if (hm) {
      hm.registerState(st);
    } else {
      hm = this._getHistoryManager(true, st);
    }
    this.dispatchEvent('registerHistoryState');
  }

  undo() {
    const hm = this._historyManager;

    if (!hm) {
      return;
    }
    hm.undo();
  }

  redo() {
    const hm = this._historyManager;

    if (!hm) {
      return;
    }
    hm.redo();
  }

  undoAvailable() {
    const hm = this._historyManager;

    if (!hm || !hm.undoAvailable) {
      return false;
    }

    return hm.undoAvailable();
  }

  redoAvailable() {
    const hm = this._historyManager;

    if (!hm || !hm.redoAvailable) {
      return false;
    }

    return hm.redoAvailable();
  }

}
