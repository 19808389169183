import CubemapAsset from '../asset/CubemapAsset';
import ImageAsset from '../asset/ImageAsset';
import Loader from '../../bgr/common/loading/Loader';
import LoaderState from '../../bgr/common/loading/LoaderState';
import ImageLoader from '../../bgr/common/loading/ImageLoader';
import LoaderList from '../../bgr/common/loading/LoaderList';

function getImageAssetFromLoader(ldr) {
  if (!(ldr instanceof ImageLoader)) {
    return null;
  }
  const img = ldr.getImage();

  if (img instanceof ImageAsset) {
    return img;
  }
  const res = new ImageAsset();

  res.setImage(img);

  return res;
}

function getImagesFromLoaderList(loaderList) {
  if (!loaderList) {
    return null;
  }
  const loaders = loaderList.loaders;

  if (!loaders) {
    return null;
  }
  const numLoaders = loaders.length;

  if (!numLoaders) {
    return null;
  }
  const images = [];

  for (let i = 0; i < numLoaders; ++i) {
    const ldr = loaders[i];

    if (ldr instanceof ImageLoader) {
      const img = ldr.getImage();

      images.push(img);
    }
  }

  return images;
}

export default class BackgroundLoader extends Loader {
  constructor(id) {
    super();
    this.backgroundId = id;
    this.data = null;
  }

  start() {
    // #if DEBUG
    console.log('load background');
    // #endif
    const svc = this.getBackgroundService();

    this._changeState(LoaderState.PROGRESS);

    if (!svc || !this.backgroundId) {
      this._changeState(LoaderState.ERROR);

      return;
    }
    let completeHandler = this._backgroundDataCompleteHandler;

    if (!completeHandler) {
      const that = this;

      completeHandler = this._backgroundDataCompleteHandler = data => {
        return that._handleBackgroundDataComplete(data);
      };
    }
    svc.getBackgroundById(this.backgroundId, completeHandler);
  }

  _handleBackgroundDataComplete(data) {
    if (!this.data) {
      this.data = {};
    }
    this.data.data = data;
    this._loadAssets();
  }

  _getBackgroundType(bg) {
    if (!bg) {
      return null;
    }
    const type = bg.type;

    if (type) {
      return type;
    }

    const settings = bg.settings;

    if (!settings) {
      return null;
    }
    if (this._getSettingsImageURL(settings)) {
      return 'image';
    }
    if (settings.sides) {
      return 'skybox';
    }
    if (settings.color) {
      return 'color';
    }

    return null;
  }

  _getSettingsImageURL(settings) {
    if (!settings) {
      return null;
    }

    return settings.image || settings.source || settings.src || settings.url;
  }

  _newImageLoader(src) {
    const res = new ImageLoader(src);

    res.crossOrigin = 'Anonymous';

    return res;
  }

  _loadAssets() {
    const data = this.data;
    const bgData = data ? data.data : null;

    let type = this._getBackgroundType(bgData);
    const settings = bgData ? bgData.settings : null;

    if (!bgData) {
      this._changeState(LoaderState.ERROR);

      return;
    }
    type = type && type.toLowerCase ? type.toLowerCase() : null;

    let loader = null;

    if (settings) {
      if (type === 'skybox' || type === 'cubemap') {
        const sides = settings.sides;
        const numSides = sides ? settings.length : 0;
        const loaders = [];

        if (numSides > 0) {
          for (let i = 0; i < numSides; ++i) {
            const side = sides[i];

            loaders.push(this._newImageLoader(side));
          }
        }
        if (loaders && loaders.length > 0) {
          loader = new LoaderList(loaders);
        }
      } else if (type === 'color') {
        loader = null;
      } else {
        // 2d / image / ...
        const src = this._getSettingsImageURL(settings);

        loader = src ? this._newImageLoader(src) : null;
      }
    }

    if (!loader) {
      this._changeState(LoaderState.COMPLETED);

      return;
    }
    let completeHandler = this._assetsCompleteHandler;

    if (!completeHandler) {
      const that = this;

      completeHandler = this._assetsCompleteHandler = evt => {
        return that._handleAssetsComplete(evt);
      };
    }
    this._assetLoader = loader;
    loader.once('finished', completeHandler);
    loader.start();
  }

  _handleAssetsComplete(evt) {
    let data = this.data;
    const bgData = data.data;
    const bgDataSettings = bgData ? bgData.settings : null;

    if (!data) {
      data = this.data = {};
    }
    let assets = data.assets;

    if (!assets) {
      assets = data.assets = {};
    }

    const assetLdr = this._assetLoader;
    let type = bgDataSettings ? bgDataSettings.type : null;

    if (type && type.toLowerCase) {
      type = type.toLowerCase();
    }

    if (assetLdr instanceof ImageLoader) {
      assets.image = getImageAssetFromLoader(assetLdr);
    } else if (assetLdr instanceof LoaderList) {
      if (type === 'cubemap' || type === 'skybox') {
        assets.cubemap = new CubemapAsset(getImagesFromLoaderList(assetLdr));
      }
    }

    this._assetLoader = null;

    this._changeState(LoaderState.COMPLETED);
  }

  get backgroundService() {
    return this.getBackgroundService();
  }

  set backgroundService(v) {
    this.setBackgroundService(v);
  }

  getBackgroundService(svc) {
    return this._backgroundService;
  }

  setBackgroundService(svc) {
    this._backgroundService = svc;
  }

  getResult() {
    return this.data;
  }
}
