import Component from '../Component';

export default class GraphicsComponent extends Component {
  // TODO: Should 'getDOMElement' be moved to super class 'Component'?
  getDOMElement() {
    return this.getCanvas();
  }

  get domElement() {
    return this.getDOMElement();
  }

  setDOMElementAttribute(name, value) {
    if (!name) {
      return;
    }
    const elem = this.getDOMElement();

    if (!elem) {
      return;
    }
    let val = value;

    if (typeof (val) !== 'string') {
      val = `${val}`;
    }
    elem.setAttribute(name, val);
  }

  setTabIndex(index = -1) {
    this.setDOMElementAttribute('tabindex', index);
  }

  dispose() {
    const elem = this.getDOMElement();

    if (elem) {
      const parentElem = elem.parentNode;

      if (parentElem) {
        parentElem.removeChild(elem);
      }
    }
  }

  getCanvas(create = true) {
    let canvas = this._canvas;

    if (canvas || create === false) {
      return canvas;
    }
    if (typeof (document) !== 'undefined') {
      canvas = document.createElement('canvas');
      this._canvas = canvas;
    }

    return canvas;
  }

  getPixelRatio() {
    const p = this._pixelRatio;

    if (p === null || typeof (p) !== 'number' || p <= 0) {
      return window.devicePixelRatio;
    }

    return p;
  }

  setPixelRatio(value) {
    if (this._pixelRatio === value) {
      return;
    }
    this._pixelRatio = value;
    this._changedPixelRatio(value);
  }

  _changedPixelRatio() {
    // _updateSize calls _changedSize with current width and height
    this._updateSize();
  }

  get pixelRatio() {
    return this.getPixelRatio();
  }

  set pixelRatio(v) {
    this.setPixelRatio(v);
  }

  _changedSize(width, height) {
    const canvas = this.getCanvas(false);

    if (!canvas) {
      return;
    }
    const p = this.getPixelRatio();

    const w = width | 0;
    const h = height | 0;

    canvas.width = w * p;
    canvas.height = h * p;
    canvas.style.width = `${w}px`;
    canvas.style.height = `${h}px`;
  }

  get canvas() {
    return this.getCanvas(false);
  }
}
