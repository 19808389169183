import FabricTransform from './FabricTransform';
// import Matrix4Math from '../../bgr/bgr3d/math/Matrix4Math';
import SampleDA from '../sample/SampleDA';

const DEFAULT_PIVOT_X = 0.5;
const DEFAULT_PIVOT_Y = 0.5;
const DEFAULT_ALIGN_X = 0.5;
const DEFAULT_ALIGN_Y = 0.5;

/*
function scaleMatrix(scaleX, scaleY, matrix) {
  return Matrix4Math.scaleXYZ(matrix, scaleX, scaleY, 1, matrix);
}

function rotateMatrixZ(angle, matrix) {
  return Matrix4Math.zRotate(angle, matrix, matrix);
}

function translateMatrix(x, y, matrix) {
  return Matrix4Math.translateXYZ(matrix, x, y, 0, matrix);
}

function getMatrixInverse(matrix, result) {
  return Matrix4Math.getInverse(matrix, result);
}
*/

/**
* @class SampleTransform
* @description Transforms a matrix4 using the data of a sample / quilt
*  example:
*  {
*     "offset": {"x": 10, "y": 20},
*     "rotation": 180
*  }
*/

export default class SampleTransform extends FabricTransform {
  getRealWidth() {
    return SampleDA.getRealWidth(this.getSampleConfigData(), this.getSampleData(), super.getRealWidth());
  }

  getRealHeight() {
    return SampleDA.getRealHeight(this.getSampleConfigData(), this.getSampleData(), super.getRealHeight());
  }

  getPivotX() {
    return SampleDA.getPivotX(this.getSampleConfigData(), this.getSampleData(), DEFAULT_PIVOT_X);
  }

  getPivotY() {
    return SampleDA.getPivotY(this.getSampleConfigData(), this.getSampleData(), DEFAULT_PIVOT_Y);
  }

  getAlignX(singlePart = null) {
    const sp = singlePart || this.getSinglePart();

    return SampleDA.getAlignX(this.getSampleConfigData(), this.getSampleData(), sp, DEFAULT_ALIGN_X);
  }

  getAlignY(singlePart = null) {
    const sp = singlePart || this.getSinglePart();

    return SampleDA.getAlignY(this.getSampleConfigData(), this.getSampleData(), sp, DEFAULT_ALIGN_Y);
  }

  _getRotation(config, data, singlePart, fallback) {
    const sp = singlePart || this.getSinglePart();

    return SampleDA.getRotation(config, data, sp, fallback);
  }
  /*
  applyMatrix4(matrix) {
    const scaleU = this.getGeometryScaleU();
    const scaleV = this.getGeometryScaleV();

    const trU = this.getGeometryTranslateU();
    const trV = this.getGeometryTranslateV();
    const minU = this.getGeometryMinU();
    const minV = this.getGeometryMinV();
    const maxU = this.getGeometryMaxU();
    const maxV = this.getGeometryMaxV();

    const scaleX = this.getScaleX();
    const scaleY = this.getScaleY();

    const realWidth = this.getRealWidth();
    const realHeight = this.getRealHeight();

    const fabricW = realWidth * scaleX;
    const fabricH = realHeight * scaleY;

    const pivotX = this.getPivotX();
    const pivotY = this.getPivotY();

    const alignX = this.getAlignX();
    const alignY = this.getAlignY();

    const offsetX = this.getOffsetX();
    const offsetY = this.getOffsetY();

    let mtx = matrix;

    mtx = scaleMatrix(scaleU, scaleV, mtx);
    // translateMatrix(-fabricW * scaleU, -fabricH * scaleU, matrix);

    // translate offset
    mtx = translateMatrix(offsetX * scaleU, offsetY * scaleV, mtx);
    mtx = translateMatrix(-fabricW * scaleU * pivotX, -fabricH * scaleV * pivotY, mtx);
    mtx = rotateMatrixZ(this.getRotationRads(), mtx);
    mtx = translateMatrix(fabricW * scaleU * pivotX, fabricH * scaleV * pivotY, mtx);

    mtx = translateMatrix(trU, trV, mtx);
    mtx = scaleMatrix(fabricW, fabricH, mtx);
    mtx = translateMatrix(
      (minU + (maxU - minU) * alignX - fabricW * alignX) * scaleU,
      (minV + (maxV - minV) * alignY - fabricH * alignY) * scaleV,
      mtx);

    mtx = getMatrixInverse(matrix, mtx);

    return mtx;
  }
  */

  getSampleConfigData() {
    return this.getConfigData();
  }

  setSampleConfigData(scd) {
    this.setConfigData(scd);
  }

  get sampleConfigData() {
    return this.getSampleConfigData();
  }

  set sampleConfigData(scd) {
    this.setSampleConfigData(scd);
  }

  getSampleData() {
    return this.getData();
  }

  setSampleData(s) {
    this.setData(s);
  }

  get sampleData() {
    return this.getSampleData();
  }

  set sampleData(v) {
    this.setSampleData(v);
  }
}
