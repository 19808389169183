import Node3D from '../../bgr/bgr3d/scenegraph/Node3D';

export default class BD3DCollisionBoxNode3D extends Node3D {
  constructor(box, transform = null) {
    super(transform);
    this.box = box;
  }

  getBox() {
    return this.box;
  }

  setBox(b) {
    this.box = b;
  }
}
