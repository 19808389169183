export default class ColorList {
  constructor(arr) {
    let colorsById = null;
    let colorsByHex = null;

    if (arr) {
      const l = arr.length;

      for (let i = 0; i < l; ++i) {
        const c = arr[i];

        if (c && c.id) {
          if (!colorsById) {
            colorsById = {};
          }
          colorsById[c.id] = c;
        }
        if (c && c.color && c.color.toLowerCase) {
          if (!colorsByHex) {
            colorsByHex = {};
          }
          colorsByHex[c.color.toLowerCase()] = c;
        }
      }
    }

    this._colors = arr;
    this._colorsById = colorsById;
    this._colorsByHex = colorsByHex;
  }

  getColors() {
    return this._colors;
  }

  getColor(key) {
    const t = typeof (key);

    if (t === 'undefined' || key === null) {
      return null;
    }
    if (t === 'number') {
      return this.getColorAt(key);
    } else if (t === 'string') {
      if (key.charAt(0) === '#') {
        return this.getColorByHex(key);
      }

      return this.getColorById(key);
    }

    return null;
  }

  getColorAt(index) {
    const arr = this._colors;

    if (!arr) {
      return null;
    }

    return arr[index | 0];
  }

  getColorByHex(hex) {
    const map = this._colorsByHex;

    if (!map) {
      return null;
    }
    let h = hex;

    if (typeof (h) === 'number') {
      const HEX = 16;
      const pfx = '000000';
      const l = pfx.length;

      h = pfx + h.toString(HEX);
      h = h.substring(h.length - l, h.length);
    }
    if (typeof (h) === 'string') {
      if (h.charAt(0) !== '#') {
        h = `#${h}`;
      }

      return map[h.toLowerCase()];
    }

    return null;
  }

  getColorById(id) {
    if (!id) {
      return null;
    }

    const map = this._colorsById;

    if (map) {
      return map[id];
    }

    return null;
  }
}
