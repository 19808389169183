import Transform3D from './Transform3D';
import Vector3 from '../geom/Vector3';
import Matrix4Math from '../math/Matrix4Math';

export default class SRTTransform3D extends Transform3D {
  constructor(position, rotation, scale, rotationOrder) {
    super();
    this._position = position || new Vector3(0, 0, 0);
    this._rotation = rotation || new Vector3(0, 0, 0);
    this._scale = scale || new Vector3(1, 1, 1);
  }

  applyMatrix4(m4) {
    if (!m4) {
      return m4;
    }

    const position = this.getPosition();
    const rotation = this.getRotation();
    const scale = this.getScale();

    Matrix4Math.scale(m4, scale, m4);
    Matrix4Math.rotate(m4, rotation, this._rotationOrder, m4);
    Matrix4Math.translate(m4, position, m4);

    return m4;
  }

  getPosition() {
    return this._position;
  }

  setPosition(vector) {
    this._position = vector;
  }

  getRotation() {
    return this._rotation;
  }

  setRotation(vector) {
    this._rotation = vector;
  }

  getScale() {
    return this._scale;
  }

  setScale(vector) {
    this._scale = vector;
  }

  getRotationOrder() {
    return this._rotationOrder;
  }

  setRotationOrder(v) {
    this._rotationOrder = v;
  }

  get position() {
    return this.getPosition();
  }

  set position(vector) {
    this.setPosition(vector);
  }

  get rotation() {
    return this.getRotation();
  }

  get scale() {
    return this.getScale();
  }

  get rotationOrder() {
    return this.getRotationOrder();
  }

  set rotationOrder(v) {
    this.setRotationOrder(v);
  }
}
