/**
* @class Logger
* @abstract
* @description Abstract base class for all loggers
*/
export default class Logger {
  constructor(enabled = true) {
    this._enabled = enabled;
  }
  isEnabled() {
    return this._enabled;
  }
  setEnabled(e) {
    this._enabled = e;
  }

  get enabled() {
    return this.isEnabled();
  }

  set enabled(e) {
    this.setEnabled(e);
  }

  log(...args) {
    this._log('log', args);
  }

  info(...args) {
    this._log('info', args);
  }

  warn(...args) {
    this._log('warn', args);
  }

  error(...args) {
    this._log('error', args);
  }
  clear() {
    // override me
  }

  _log(type, args) {
    // override me
  }
}
