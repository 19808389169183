import Loader from './Loader';
import LoaderState from './LoaderState';
import Utils from '../utils/Utils';
// import LoaderEvent from './LoaderEvent';

const UNDEFINED = 'undefined';
// const EMPTY_URL = '';
const XHR_STATUS_SUCCESS = 200;
const XHR_STATE_READY = 4;
const METHOD_GET = 'get';

/**
 * Loader
 * @abstract
 * @extends EventDispatcher
 */
export default class XHRLoader extends Loader {
  constructor(args) {
    super(args);

    let source = null, asyncLoad = true, method = METHOD_GET, body = null,
      requestHeaders = null;

    if (args !== null && typeof (args) !== UNDEFINED) {
      requestHeaders = args.requestHeaders;
      source = Utils.tryValues(args.source, args.url, args.uri, args.URL, args.URI);
      asyncLoad = Utils.tryValues(args.asyncLoad, asyncLoad);
      method = Utils.tryValues(args.method, method);
      body = Utils.tryValues(args.body, args.postData, args.messageBody);
    }
    this.error = null;
    this._source = source;
    this.method = method;
    this.requestHeaders = requestHeaders;
    this.asyncLoad = asyncLoad;
    this.body = body;
  }

  getSource() {
    return this._source;
  }

  setSource(src) {
    if (this.getState() === LoaderState.PROGRESS) {
      throw new Error('Can\'t change the loader\'s source while loading');
    }
    this._source = src;
  }

  get source() {
    return this.getSource();
  }

  set source(src) {
    this.setSource(src);
  }

  /**
   * This loader's XMLHttpRequest instance
   * @param  {Boolean} create = true - creates an instance if null or undefined
   * @return {XMLHttpRequest} - XMLHttpRequest instance
   */
  _getXMLHttpRequest(create = true) {
    let xhr = this._xhr;

    if (!create) {
      return xhr;
    }
    if (xhr === null || typeof (xhr) === UNDEFINED) {
      if (typeof (XMLHttpRequest) !== UNDEFINED) {
        xhr = new XMLHttpRequest();
        this._xhr = xhr;
      }
    }

    return xhr;
  }

  _handleXHRSuccess(evt, xhr, result) {
    this._changeState(LoaderState.COMPLETED);
  }

  _handleXHRError(evt, xhr, error) {
    this.error = error;
    this._changeState(LoaderState.ERROR);
  }

  _handleXHRHttpError(evt, xhr, code) {
    this._handleXHRError(evt, xhr, {code: code});
  }

  _handleReadyStateChange(evt, xhr) {
    if (xhr.readyState === XHR_STATE_READY) {
      if (xhr.status === XHR_STATUS_SUCCESS) {
        this._handleXHRSuccess(evt, xhr, this._getXHRResponse(xhr));
      } else {
        this._handleXHRError(evt, xhr, this._getXHRResponse(xhr));
      }
      this._cleanupCurrentXHR();
    }
  }

  _getXHROnReadyStateChange(create = true) {
    let res = this._onreadystatechange;

    if (!create) {
      return res;
    }
    if (res === null || typeof (res) === UNDEFINED) {
      const that = this;

      res = evt => {
        let xhr = evt.target;

        if (!xhr || !xhr.open || !xhr.send) {
          xhr = that._xhr;
        }

        that._handleReadyStateChange(evt, xhr);
      };
    }

    return res;
  }

  getResponse() {
    const xhr = this._getXMLHttpRequest(false);

    return this._getXHRResponse(xhr);
  }

  _getXHRResponse(xhr) {
    if (xhr === null || typeof (xhr) === UNDEFINED) {
      return null;
    }
    if (xhr.readyState !== XHR_STATE_READY) {
      return null;
    }
    if (xhr.status !== XHR_STATUS_SUCCESS) {
      return null;
    }

    let resp = xhr.response;

    if (resp === null || typeof (resp) === UNDEFINED) {
      resp = xhr.responseText;
    }

    return resp;
  }

  getXMLHttpRequest() {
    return this._getXMLHttpRequest(false);
  }

  /**
   * Starts the loader
   * @return {void}
   */
  start() {
    const state = this.getState();

    if (state !== LoaderState.IDLE && state !== null && typeof (state) !== 'undefined') {
      return null;
    }

    this._changeState(LoaderState.PROGRESS);

    const xhr = this._getXMLHttpRequest(true);
    const body = this.body;
    const isAsync = this.asyncLoad !== false;
    const method = this.method;
    const src = this.getSource();
    const requestHeaders = this.requestHeaders;
    const responseType = this.responseType;

    if (responseType !== null && typeof (responseType) !== UNDEFINED) {
      xhr.responseType = responseType;
    }

    xhr.onreadystatechange = this._getXHROnReadyStateChange();

    xhr.open(method, src, isAsync);

    if (requestHeaders !== null && typeof (requestHeaders) !== UNDEFINED) {
      for (const v in requestHeaders) {
        if (requestHeaders.hasOwnProperty(v)) {
          xhr.setRequestHeader(v, requestHeaders[v]);
        }
      }
    }

    xhr.send(body);

    const reslt = this._getXHRResponse(xhr);

    return reslt;
  }

  /**
   * Cancels the loader
   * @return {void}
   */
  cancel() {

    const xhr = this.getXMLHttpRequest(false);

    if (xhr === null || typeof (xhr) === UNDEFINED) {
      return;
    }

    if (xhr.abort !== null && typeof (xhr.abort) !== UNDEFINED) {
      xhr.abort();
    }
    this._cleanupCurrentXHR();

    super.cancel();
  }

  _cleanupCurrentXHR() {
    if (!this.xhr) {
      return;
    }
    this._cleanupXHR(this._xhr);
    this._xhr = null;
  }

  _cleanupXHR(xhr) {
    if (!xhr) {
      return;
    }
    xhr.onreadystatechange = null;
  }

  /**
   * Reset the loader
   * @param {Object} params - params
   * @return {void}
   */
  reset(params) {
    super.reset(params);
    this.error = null;
  }
}
