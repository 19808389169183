// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
// #endif

const BACKEND_DATA_SIZE_MULTIPLIER = 1;
const CONFIG_SIZE_MULTIPLIER = 1;
const CUSTOM_QUILT_SIZE = 10; // custom quilt is 10 by 10 cm
const DEFAULT_ALIGN_X = 0.5;
const DEFAULT_ALIGN_Y = 0.5;

function parseNum(n) {
  const t = typeof (n);

  if (t === 'number' && !isNaN(n)) {
    return n;
  }
  if (t === 'string') {
    return parseFloat(n);
  }

  return n;
}

function isNum(n) {
  return (typeof (n) === 'number' && !isNaN(n));
}

function isSet(value) {
  return value !== null && typeof (value) !== 'undefined';
}

/**
* @class QuiltDA
* @description Static data accessor class for quilt objects
* Note:
    parameters named 'quiltConfig' are the objects from the config json file
*   parameters named 'quilt' are the objects from the backend service
*/
export default class QuiltDA {
  /**
   * @method getQuiltID
   * @static
   * @description Returns the id of the quilt.
   *  Uses the object from the config file first, then tries the object from the backend
   *  Note: The quiltConfig parameter will be returned if it's a string
   * @param {Object} quiltConfig - Quilt object from the config file
   * @param {Object} quilt - (Optional) Quilt object from the backend service
   * @return {String} - The id of the quilt or null
   * */
  static getID(quiltConfig, quilt) {
    let id = this._getIDFrom(quiltConfig);

    if (id) {
      return id;
    }
    id = this._getIDFrom(quilt);

    return id;
  }

  static getType(quiltConfig, quilt) {
    if (quiltConfig && quiltConfig.type) {
      return quiltConfig.type;
    }
    if (quilt && quilt.type) {
      return quilt.type;
    }

    return null;
  }

  static _getIDFrom(object) {
    if (!object) {
      return null;
    }
    if (typeof (object) === 'string') {
      return object;
    }
    let id = object.id;

    if (id && typeof (id) === 'string') {
      return id;
    }
    let img = object.img;

    if (img) {
      id = img.id;
      if (id && typeof (id) === 'string') {
        return id;
      }
    }

    img = object.image;
    if (img) {
      id = img.id;
      if (id && typeof (id) === 'string') {
        return id;
      }
    }

    return null;
  }

  static getMinBlurScale(quiltConfigData, quiltData, fallback = 0) {
    let res = null;

    if (quiltConfigData && quiltConfigData.blur) {
      res = parseNum(quiltConfigData.blur.min);
    }
    if (!isNum(res)) {
      if (quiltData && quiltData.blur) {
        res = parseNum(quiltData.blur.min);
      }
    }
    if (isNum(res)) {
      return res;
    }

    return fallback;
  }

  static getMaxBlurScale(quiltConfigData, quiltData, fallback = 1) {
    let res = null;

    if (quiltConfigData && quiltConfigData.blur) {
      res = parseNum(quiltConfigData.blur.max);
    }
    if (!isNum(res)) {
      if (quiltData && quiltData.blur) {
        res = parseNum(quiltData.blur.max);
      }
    }
    if (isNum(res)) {
      return res;
    }

    return fallback;
  }

  static getNumBlurPasses(quiltConfigData, quiltData, fallback = 1) {
    let res = null;

    if (quiltConfigData && quiltConfigData.blur) {
      res = parseNum(quiltConfigData.blur.passes);
    }
    if (!isNum(res)) {
      if (quiltData && quiltData.blur) {
        res = parseNum(quiltData.blur.passes);
      }
    }
    if (isNum(res)) {
      const MAX_BLUR_PASSES = 3;
      const MIN_BLUR_PASSES = 1;

      if (res > MAX_BLUR_PASSES) {
        return MAX_BLUR_PASSES;
      }
      if (res < MIN_BLUR_PASSES) {
        return MIN_BLUR_PASSES;
      }

      return res;
    }

    return fallback;
  }

  static getSoftness(quiltConfigData, quiltData, fallback) {
    let img = quiltConfigData ? quiltConfigData.img : null;
    let imgSettings = img ? img.settings : null;
    let softness = imgSettings ? imgSettings.softness : null;

    if (typeof (softness) !== 'number' || isNaN(softness)) {
      img = quiltData ? quiltData.img : null;
      imgSettings = img ? img.settings : null;
      softness = imgSettings ? imgSettings.softness : null;
    }
    if (typeof (softness) !== 'number' || isNaN(softness)) {
      return fallback;
    }

    return softness;
  }

  static getThickness(quiltConfigData, quiltData, fallback) {
    let img = quiltConfigData ? quiltConfigData.img : null;
    let imgSettings = img ? img.settings : null;
    let thickness = imgSettings ? imgSettings.thickness : null;

    if (typeof (thickness) !== 'number' || isNaN(thickness)) {
      img = quiltData ? quiltData.img : null;
      imgSettings = img ? img.settings : null;
      thickness = imgSettings ? imgSettings.thickness : null;
    }

    if (typeof (thickness) !== 'number' || isNaN(thickness)) {
      return fallback;
    }

    return thickness;
  }

  static getFoamThickness(quiltConfigData, quiltData, fallback) {
    let foam = quiltConfigData ? quiltConfigData.foam : null;
    let value = foam ? foam.value : null;

    if (typeof (value) !== 'number' || isNaN(value)) {
      foam = quiltData ? quiltData.foam : null;
      value = foam ? foam.value : null;
    }
    if (typeof (value) !== 'number' || isNaN(value)) {
      return fallback;
    }

    return value;
  }

  static setFoamThickness(quiltConfigData, value) {
    if (!quiltConfigData) {
      return;
    }
    if (!quiltConfigData.foam) {
      quiltConfigData.foam = {};
    }
    quiltConfigData.foam.value = parseNum(value);
  }

  static getFoamType(quiltConfigData, quiltData) {
    let foam = quiltConfigData ? quiltConfigData.foam : null;
    let value = foam ? foam.type : null;

    if (value === null || typeof (value) === 'undefined') {
      foam = quiltData ? quiltData.foam : null;
      value = foam ? foam.type : null;
    }
    if (value === null || typeof (value) === 'undefined') {
      return null;
    }

    return value;
  }

  /*
   * Quilt repeat type
   * 0 = single repeat
   * 1 = infinite repeat
   * 2 = custom repeat
   * */

  /**
   * @method getRepeatType
   * @static
   * @description Returns the repeat type of a quilt pattern
   * @param {Object} quiltConfig - Quilt data from the config file
   * @param {Object} quilt - Quilt data from the back-end
   * @param {Number|String} fallback - Fallback value if no quilt or quilt type is found
   * @return {Number|String} 0 (single repeat), 1 (infinite repeat), or 2 (custom repeat)
   * */
  static getRepeatType(quiltConfig, quilt, fallback) {
    let repeatObj = this._getRepeatObject(quiltConfig, false);

    const t = typeof (repeatObj);

    if (t === 'string' || t === 'number') {
      return repeatObj;
    }
    if (t === 'boolean') {
      return repeatObj ? 1 : 0;
    }

    if (repeatObj && isSet(repeatObj.type)) {
      return repeatObj.type;
    }

    repeatObj = this._getRepeatObject(quilt, false);
    if (repeatObj && isSet(repeatObj.type)) {
      return repeatObj.type;
    }

    return fallback;
  }

  /**
   * @method getRepeatType
   * @static
   * @description Sets the repeat type of a quilt pattern
   *  0 for single repeat, 1 for infinite repeat,
   *  2 for custom repeat (requires a value object with an x and y)
   * @param {Object} quiltConfig - Quilt data from the config file
   * @param {Number} v - repeat type
   * @return {void}
   * */
  static setRepeatType(quiltConfig, v) {
    const repeatObj = this._getRepeatObject(quiltConfig, true);

    if (!repeatObj) {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set repeat type. Quilt config =', quiltConfig);
      // #endif

      return;
    }

    repeatObj.type = v;
  }

  /**
   * @method getRepeatType
   * @static
   * @description Returns the repeat amount in the x-direction,
   * based on the repeat type (0 means infinite repeat)
   * @param {Object} quiltConfig - Quilt data from the config file
   * @param {Object} quilt - Quilt data from the back-end
   * @param {Number} fallback - fallback value if no quilt repeat is found
   * @return {Number} - The amount of times a quilt repeats in the x-direction,
   * 0 for infinite repeat
   * */
  static getResultRepeatX(quiltConfig, quilt, fallback) {
    const quiltType = this.getType(quiltConfig, quilt);

    if (quiltType === 'custom') {
      // infinite repeat for custom quilts
      return 0;
    }
    const type = this.getRepeatType(quiltConfig, quilt);

    if (type === '0' || type === 0) {
      return 1;
    } else if (type === '1' || type === 1) {
      return 0;
    } else if (type === '2' || type === 2) {
      return this.getRepeatX(quiltConfig, quilt, fallback);
    }

    return fallback;
  }

  /**
   * @method getRepeatType
   * @static
   * @description Returns the repeat amount in the y-direction,
   * based on the repeat type (0 means infinite repeat)
   * @param {Object} quiltConfig - Quilt data from the config file
   * @param {Object} quilt - Quilt data from the back-end
   * @param {Number} fallback - fallback value if no quilt repeat is found
   * @return {Number} - The amount of times a quilt repeats in the y-direction,
   * 0 for infinite repeat
   * */
  static getResultRepeatY(quiltConfig, quilt, fallback) {
    const quiltType = this.getType(quiltConfig, quilt);

    if (quiltType === 'custom') {
      // infinite repeat for custom quilts
      return 0;
    }

    const type = this.getRepeatType(quiltConfig, quilt);

    if (type === '0' || type === 0) {
      return 1;
    } else if (type === '1' || type === 1) {
      return 0;
    } else if (type === '2' || type === 2) {
      return this.getRepeatY(quiltConfig, quilt, fallback);
    }

    return fallback;
  }

  /**
   * @method getRepeatX
   * @static
   * @description Returns the repeat amount in the x-direction,
   * @param {Object} quiltConfig - Quilt data from the config file
   * @param {Object} quilt - Quilt data from the back-end
   * @param {Number} fallback - fallback value if no quilt repeat is found
   * @return {Number} - The amount of times a quilt repeats in the x-direction
   * */
  static getRepeatX(quiltConfig, quilt, fallback) {
    const vec = this._getRepeatVector(quiltConfig, quilt, false);

    if (!vec) {
      return fallback;
    }
    const x = parseNum(vec.x);

    if (isNum(x)) {
      return x;
    }

    return fallback;
  }

  /**
   * @method getRepeatY
   * @static
   * @description Returns the repeat amount in the y-direction,
   * @param {Object} quiltConfig - Quilt data from the config file
   * @param {Object} quilt - Quilt data from the back-end
   * @param {Number} fallback - fallback value if no quilt repeat is found
   * @return {Number} - The amount of times a quilt repeats in the y-direction
   * */
  static getRepeatY(quiltConfig, quilt, fallback) {
    const vec = this._getRepeatVector(quiltConfig, quilt, false);

    if (!vec) {
      return fallback;
    }
    const y = parseNum(vec.y);

    if (isNum(y)) {
      return y;
    }

    return fallback;
  }

  static setRepeatX(quiltConfig, x) {
    const value = this._getRepeatVector(quiltConfig, null, true);

    if (!value) {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set repeat, quilt config:', quiltConfig);
      // #endif

      return;
    }

    value.x = parseNum(x);
  }

  static setRepeatY(quiltConfig, y) {
    const value = this._getRepeatVector(quiltConfig, null, true);

    if (!value) {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set repeat, quilt config:', quiltConfig);
      // #endif

      return;
    }

    value.y = parseNum(y);
  }

  static setRepeatXY(quiltConfig, x, y) {
    const value = this._getRepeatVector(quiltConfig, null, true);

    if (!value) {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set repeat, quilt config:', quiltConfig);
      // #endif

      return;
    }

    value.x = parseNum(x);
    value.y = parseNum(y);
  }

  static _getRepeatObject(quiltConfig, create = false) {
    if (!quiltConfig) {
      return null;
    }
    let res = quiltConfig.repeat;

    if (res === 0) {
      // in case the repeat is set to a value directly
      return 0;
    }

    if (res || !create) {
      return res;
    }
    res = quiltConfig.repeat = {};

    return res;
  }

  static _getRepeatVector(quiltConfig, quiltData, create = false) {
    const repeatVectorConfig = this._getRepeatVectorFrom(quiltConfig, create);

    if (repeatVectorConfig) {
      return repeatVectorConfig;
    }

    const repeatVectorData = this._getRepeatVectorFrom(quiltData, false);

    return repeatVectorData;
  }

  static _getRepeatVectorFrom(object, create = false) {
    const repeatObj = this._getRepeatObject(object, create);

    if (!repeatObj) {
      return null;
    }
    let res = repeatObj.value;

    if (res || !create) {
      return res;
    }
    res = repeatObj.value = {};

    return res;
  }

  static getLocalAlignX(quiltConfig, quilt) {
    return this._getLocalAlign('x', quiltConfig, quilt, DEFAULT_ALIGN_X);
  }

  static setLocalAlignX(quiltConfig, value) {
    this._setLocalAlign(quiltConfig, 'x', value);
  }

  static getLocalAlignY(quiltConfig, quilt) {
    return this._getLocalAlign('y', quiltConfig, quilt, DEFAULT_ALIGN_Y);
  }

  static setLocalAlignY(quiltConfig, value) {
    this._setLocalAlign(quiltConfig, 'y', value);
  }

  static _setLocalAlign(quiltConfig, property, value) {
    if (!quiltConfig || !property) {
      return;
    }
    const localAlign = quiltConfig.localAlign || {x: 0, y: 0};

    quiltConfig.localAlign = localAlign;
    localAlign[property] = value;
  }

  static _getLocalAlign(property, quiltConfig, quilt, defaultValue) {
    let res = defaultValue;

    if (quiltConfig) {
      const {localAlign} = quiltConfig;

      if (localAlign) {
        res = localAlign[property];
      }
    }
    if (!isNum(res)) {
      if (quilt) {
        const {localAlign} = quilt;

        if (localAlign) {
          res = localAlign[property];
        }
      }
    }
    if (isNum(res)) {
      return res;
    }

    return defaultValue;
  }

  static getAlignX(quiltConfig, quilt) {
    return this._getAlign('x', quiltConfig, quilt, DEFAULT_ALIGN_X);
  }

  static setAlignX(quiltConfig, value) {
    if (!quiltConfig) {
      return;
    }
    if (!quiltConfig.align) {
      quiltConfig.align = {};
    }
    quiltConfig.align.x = value;
  }

  static getAlignY(quiltConfig, quilt) {
    return this._getAlign('y', quiltConfig, quilt, DEFAULT_ALIGN_Y);
  }

  static setAlignY(quiltConfig, value) {
    if (!quiltConfig) {
      return;
    }
    if (!quiltConfig.align) {
      quiltConfig.align = {};
    }
    quiltConfig.align.y = value;
  }

  static setAlign(quiltConfig, x, y) {
    if (!quiltConfig) {
      return;
    }
    let X = x;
    let Y = y;

    if (typeof (x) === 'object') {
      if (!x) {
        return;
      }
      if ((x instanceof Array) || (Array.isArray && Array.isArray(x))) {
        X = x[0];
        Y = x[1];
      } else {
        X = x.x;
        Y = x.y;
      }
    }
    if (!quiltConfig.align) {
      quiltConfig.align = {};
    }
    quiltConfig.align.x = X;
    quiltConfig.align.y = Y;
  }

  static _getAlign(property, quiltConfig, quilt, fallback) {
    let res = this._getAlignFrom(property, quiltConfig);

    if (isNum(res)) {
      return res;
    }
    res = this._getAlignFrom(property, quilt);
    if (isNum(res)) {
      return res;
    }

    return fallback;
  }

  static _getAlignFrom(property, object) {
    if (!object) {
      return null;
    }
    if (property === 'x') {
      if (isNum(object.alignX)) {
        return object.alignX;
      }
      if (!object.align) {
        return null;
      }

      return object.align.x;
    }
    if (property === 'y') {
      if (isNum(object.alignY)) {
        return object.alignY;
      }
      if (!object.align) {
        return null;
      }

      return object.align.y;
    }

    return null;
  }

  // relative x-coordinate of the rotation pivot point
  // 0 = left, 0.5 = center, 1 = right
  static getPivotX(quiltConfig, quilt, fallback) {
    return this._getQuiltPivot('x', quiltConfig, quilt, fallback);
  }

  // relative y-coordinate of the rotation pivot point
  // 0 = top, 0.5 = middle, 1 = bottom
  static getPivotY(quiltConfig, quilt, fallback) {
    return this._getQuiltPivot('y', quiltConfig, quilt, fallback);
  }

  static _getQuiltPivot(property, quiltConfig, quilt, fallback) {
    let res = this._getQuiltPivotFrom(property, quiltConfig);

    if (isNum(res)) {
      return res;
    }
    res = this._getQuiltPivotFrom(property, quilt);
    if (isNum(res)) {
      return res;
    }

    return fallback;
  }

  static _getQuiltPivotFrom(property, object) {
    if (!object) {
      return null;
    }
    let res = null;
    const pvt = object.pivot;

    if (property === 'x') {
      res = object.pivotX;
      if (isNum(res)) {
        return res;
      } else if (pvt) {
        res = pvt.x;
      }
    } else if (property === 'y') {
      res = object.pivotY;
      if (isNum(res)) {
        return res;
      } else if (pvt) {
        res = pvt.y;
      }
    }

    return res;
  }

  /**
   * @method getRealWidth
   * @description returns the real width of a quilt (in mm).
   *  If this value is not found in the quiltConfig,
   *  the value of the backend data (quilt) is used.
   * @param {Object} quiltConfig - quilt data from the config file
   * @param {Object} quilt - quilt object from the backend
   * @param {Number} fallback - fallback value if no real width is found (default = undefined)
   * @return {Number} - the real width of the quilt in mm
   **/
  static getRealWidth(quiltConfig, quilt, fallback) {
    return this._getQuiltRealSize('width', quiltConfig, quilt, fallback);
  }

  /**
  * @method getRealHeight
  * @description returns the real height of a quilt (in mm).
  *  If this value is not found in the quiltConfig,
  *  the value of the backend data (quilt) is used.
  * @param {Object} quiltConfig - quilt data from the config file
  * @param {Object} quilt - quilt object from the backend
  * @param {Number} fallback - fallback value if no real height is found (default = undefined)
  * @return {Number} - the real height of the quilt in mm
  **/
  static getRealHeight(quiltConfig, quilt, fallback) {
    return this._getQuiltRealSize('height', quiltConfig, quilt, fallback);
  }

  /**
  * @method _getQuiltRealSize
  * @private
  * @description returns the real size of a quilt (in mm).
  *  If this value is not found in the quiltConfig,
  *  the value of the backend data (quilt) is used.
  * @param {String} property - 'width' or 'height'
  * @param {Object} quiltConfig - quilt data from the config file
  * @param {Object} quilt - quilt object from the backend
  * @param {Number} fallback - fallback value if no real size is found (default = undefined)
  * @return {Number} - the real width or height of the quilt in mm
  **/
  static _getQuiltRealSize(property, quiltConfig, quilt, fallback) {
    if (typeof (property) === 'undefined' || property === null) {
      return fallback;
    }
    if (quiltConfig && this.getType(quiltConfig, quilt) === 'custom') {
      return CUSTOM_QUILT_SIZE;
    }
    if (quiltConfig && quiltConfig.size) {
      let res = -1;

      if (property === 'width') {
        res = parseNum(quiltConfig.size.width);
      } else if (property === 'height') {
        res = parseNum(quiltConfig.size.height);
      }
      if (typeof (res) === 'number' && !isNaN(res) && res >= 0) {
        res *= CONFIG_SIZE_MULTIPLIER;

        return res;
      }
    } else if (quiltConfig && (quiltConfig.img || quiltConfig.image)) {
      let res = -1;
      const img = quiltConfig.img || quiltConfig.image;

      if (img && img.size) {
        if (property === 'width') {
          res = parseNum(img.size.width);
        } else if (property === 'height') {
          res = parseNum(img.size.height);
        }
      }
      if (typeof (res) === 'number' && !isNaN(res) && res >= 0) {
        res *= BACKEND_DATA_SIZE_MULTIPLIER;

        return res;
      }
    }

    if (quilt) {
      let res = -1;

      if (property === 'width') {
        if (quilt.size && typeof (quilt.size.width) === 'number') {
          res = parseNum(quilt.size.width);
        }
        if (typeof (res) !== 'number' || res <= 1) {
          res = parseNum(quilt.realWidth);
        }
      }
      if (property === 'height') {
        if (quilt.size && typeof (quilt.size.height) === 'number') {
          res = parseNum(quilt.size.height);
        }
        if (typeof (res) !== 'number' || res <= 1) {
          res = parseNum(quilt.realHeight);
        }
      }
      if (typeof (res) === 'number' && !isNaN(res) && res >= 0) {
        return res;
      }
    }

    return fallback;
  }
}
