import NumericOperation from '../../../data/numeric/NumericOperation';

export default {
  create(data, id, session) {
    if (!data) {
      throw new Error('Invalid NumericOperation', data);
    }
    const {operator, operands} = data;

    return new NumericOperation(operator, operands);
  },
  update(object, data, id, session, srcData) {
    return;
  }
};
