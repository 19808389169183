import ThreeMaterial from './ThreeMaterial';
import ThreeMaterialUtils from './ThreeMaterialUtils';
// import * as THREE from 'three';

let vertexShader = null, fragmentShader = null;

// TODO: finish plastic material

vertexShader = [
  'precision mediump float;',
  'attribute vec3 position;',
  'attribute vec2 uv;',
  'attribute vec3 normal;',
  'varying vec3 v_viewNormal;',
  'varying vec3 v_viewPos;',
  'varying vec2 v_uv;',
  'uniform mat4 modelViewMatrix;',
  'uniform mat4 projectionMatrix;',
  'uniform mat3 normalMatrix;',
  'void main() {',
  'v_uv = uv;',
  'vec4 pos = vec4(position, 1.0);',
  'v_viewPos = (modelViewMatrix * pos).xyz;',
  'v_viewNormal = normalMatrix * normal;',
  'gl_Position = projectionMatrix * modelViewMatrix * pos;',
  '}'
].join('\n');

fragmentShader = [
  'precision mediump float;',
  'varying vec2 v_uv;',
  'varying vec3 v_viewNormal;',
  'varying vec3 v_viewPos;',
  'uniform mat4 cameraWorldMatrix;',
  'uniform vec3 colorMultiplier;',
  'uniform sampler2D lightmap;',
  'uniform float specularPower;',
  'uniform float specularMultiplier;',

  'vec3 specular(vec3 lightDir, vec3 lightColor, float lightMultiplier, float glossiness, vec3 surfaceDir) {',
  'float value = -dot(normalize(lightDir), normalize(surfaceDir));',
  'value = clamp(value, 0.0, 10.0);',
  'vec3 res = lightColor * value;',
  'res = mix(res, vec3(1.0), value);',

  'res = pow(res, vec3(glossiness));',
  'res *= lightMultiplier;',
  'return res;',
  '}',

  'void main() {',
  'vec3 viewNrm = normalize(v_viewNormal);',
  'vec3 nrm = (cameraWorldMatrix * vec4(viewNrm,0.0)).xyz;',
  'float shading = nrm.y;',
  'float minShading = -1.0;',
  'float maxShading = 1.0;',
  'shading = (shading - minShading) / (maxShading - minShading);',
  'shading = clamp(shading, 0.0, 1.0);',
  'shading = (3.0 - 2.0 * shading) * shading * shading;',
  'shading += 0.25;',
  'shading = clamp(shading, 0.0, 1.0);',

  'vec3 viewRefl = v_viewPos - 2.0 * dot(viewNrm, v_viewPos) * viewNrm;',
  'vec3 worldRefl = (cameraWorldMatrix * vec4(viewRefl, 0.0)).xyz;',

  'vec3 diffuse = vec3(shading*0.9) * colorMultiplier;',
  'vec4 res = vec4(diffuse, 1.0);',

  'res.xyz += specular(vec3(0,-1,0), vec3(1.0, 1.0, 1.0), specularMultiplier, specularPower, worldRefl);',
  'res.xyz += specular(vec3(-1,-1,-1), vec3(1.0, 1.0, 1.0), specularMultiplier, specularPower, worldRefl);',

  'vec4 lightmapPixel = texture2D(lightmap, v_uv);',
  'res.xyz *= lightmapPixel.xyz;',

  'gl_FragColor = res;',
  '}'
].join('\n');

// #if DEBUG
if (window.DEBUG_PLASTIC_VERTEX_SHADER) {
  vertexShader = window.DEBUG_PLASTIC_VERTEX_SHADER;
}
if (window.DEBUG_PLASTIC_FRAGMENT_SHADER) {
  fragmentShader = window.DEBUG_PLASTIC_FRAGMENT_SHADER;
}
// #endif

let instance = null;

export default class ThreePlasticMaterial extends ThreeMaterial {
  constructor() {
    super({
      vertexShader: vertexShader,
      fragmentShader: fragmentShader
    });
    const defaultSpecularPower = 80;
    const defaultSpecularMultiplier = 0.5;

    this.setSpecularPower(defaultSpecularPower);
    this.setSpecularMultiplier(defaultSpecularMultiplier);
  }

  getSpecularMultiplier() {
    return this.getUniformValue('specularMultiplier', 0);
  }

  setSpecularMultiplier(v) {
    this.setUniformValue('specularMultiplier', v);
  }

  getSpecularPower() {
    return this.getUniformValue('specularPower', 0);
  }

  setSpecularPower(v) {
    this.setUniformValue('specularPower', v);
  }

  setColorMultiplier(R, G, B) {
    const vec = this.getUniformValue('colorMultiplier');
    const newVec = ThreeMaterialUtils.colorToVec3(R, G, B, vec);

    if (newVec !== vec) {
      this.setUniformValue('colorMultiplier', newVec);
    }
  }

  setLightmap(lightmap) {
    this.setUniformValue('lightmap', lightmap);
  }

  static create(uniforms = null, properties = null) {
    if (!instance) {
      instance = new ThreePlasticMaterial();
    }

    return ThreeMaterialUtils.create(instance, uniforms, properties);
  }
}
