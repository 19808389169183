import Parser3D from './Parser3D';
import Geometry from '../geom/Geometry';

const XIndex = 1;
const YIndex = 2;
const ZIndex = 3;

// TODO: move to StringUtils
/**
 * @function strReplace
 * @description Simple string replace function
 * @param {String} string - source string
 * @param {String} search - part to be replaced
 * @param {String} replacement - replacement
 * @return {String} - string with parts replaced
 * */
function strReplace(string, search, replacement) {
  let repl = replacement;
  let str = string;

  if (!str) {
    return str;
  }
  if (search === undefined || search === null) {
    return str;
  }
  if (repl === undefined || repl === null) {
    repl = '';
  }
  if (search === repl) {
    return str;
  }
  if (search.length === 0) {
    return str;
  }
  if (str.indexOf(search, 0) < 0) {
    return str;
  }
  if (repl.indexOf(search, 0) >= 0) {
    if (str.replace) {
      return str.replace(search, repl);
    }

    return str.split(search).join(repl);
  }
  while (str.indexOf(search, 0) >= 0) {
    if (str.replace) {
      str = str.replace(search, repl);
    } else {
      str = str.split(search).join(repl);
    }
  }

  return str;
}

/**
 * @function strTrim
 * @description Simple string trim function
 * @param {String} str - input string
 * @return {String} - string without leading or trailing whitespace
 * */
function strTrim(str) {
  if (!str) {
    return str;
  }
  const l = str.length;

  if (l === 0) {
    return str;
  }
  const LAST_WHITESPACE_CHARCODE = 32;

  if (str.charCodeAt(0) > LAST_WHITESPACE_CHARCODE &&
      str.charCodeAt(l - 1) > LAST_WHITESPACE_CHARCODE) {
    return str;
  }

  if (str.trim) {
    return str.trim();
  }

  return str;
}

/**
* @function parseNum
* @description parses a number
* @param {Number|String} number - value to parse
* @param {Number} fallback - used value if input cannot be parsed
* @return {Number} - Number value
**/
function parseNum(number, fallback = 0) {
  let num = number;

  if (num === null || typeof (num) === 'undefined') {
    return fallback;
  }
  if (typeof (num) === 'string') {
    num = parseFloat(num);
  }
  if (typeof (num) !== 'number') {
    return fallback;
  }
  if (isNaN(num)) {
    return fallback;
  }

  return num;
}

// Simple function to get a value from an array
function getFromArray(arr, index, fb) {
  if (!arr) {
    return fb;
  }
  if (index < 0) {
    return fb;
  }

  return arr[index];
}

// Default function to create a new object
function createNewObject(name) {
  if (typeof (Geometry) === 'undefined') {
    return {name: name};
  }
  const obj = new Geometry();

  if (!obj.userData) {
    obj.userData = {};
  }
  obj.userData.name = name;

  return obj;
}

const regExpMap = {
  // v float float float
  v: /^v\s+([\d|\.|\+|\-|e|E]+)\s+([\d|\.|\+|\-|e|E]+)\s+([\d|\.|\+|\-|e|E]+)/,
  // vn float float float
  vn: /^vn\s+([\d|\.|\+|\-|e|E]+)\s+([\d|\.|\+|\-|e|E]+)\s+([\d|\.|\+|\-|e|E]+)/,
  // vt float float
  vt: /^vt\s+([\d|\.|\+|\-|e|E]+)\s+([\d|\.|\+|\-|e|E]+)/,

  fV: /^(\d+)\s*$/,
  fVUV: /^(\d+)\/(\d+)$/,
  fVN: /^(\d+)\/\/(\d+)$/,
  fVUVN: /^(\d+)\/(\d+)\/(\d+)$/,


  // o object_name | g group_name
  o: /^[og]\s*(.+)?/,
  // s boolean
  s: /^s\s+(\d+|on|off)/,
  // mtllib file_reference
  mtllib: /^mtllib /,
  // usemtl material_name
  usemtl: /^usemtl /
};

function parsePolyVert(part, line, index, parser, session) {
  let pv, vIndex, uvIndex, nrmIndex;

  const REGEXP_V_INDEX = 1;
  const REGEXP_UV_INDEX = 2;
  const REGEXP_N_INDEX = 3;

  let regExpRes = regExpMap.fV.exec(part);

  if (regExpRes) {
    vIndex = parseNum(regExpRes[REGEXP_V_INDEX], vIndex) | 0;
  } else {
    regExpRes = regExpMap.fVUV.exec(part);
    if (regExpRes) {
      vIndex = parseNum(regExpRes[REGEXP_V_INDEX], vIndex) | 0;
      uvIndex = parseNum(regExpRes[REGEXP_UV_INDEX], uvIndex) | 0;
    } else {
      regExpRes = regExpMap.fVN.exec(part);
      if (regExpRes) {
        vIndex = parseNum(regExpRes[REGEXP_V_INDEX], vIndex) | 0;
        nrmIndex = parseNum(regExpRes[REGEXP_N_INDEX], nrmIndex) | 0;
      } else {
        regExpRes = regExpMap.fVUVN.exec(part);
        if (regExpRes) {
          vIndex = parseNum(regExpRes[REGEXP_V_INDEX], vIndex) | 0;
          uvIndex = parseNum(regExpRes[REGEXP_UV_INDEX], uvIndex) | 0;
          nrmIndex = parseNum(regExpRes[REGEXP_N_INDEX], nrmIndex) | 0;
        }
      }
    }
  }
  --vIndex;
  --uvIndex;
  --nrmIndex;
  if (session && session.callbacks && session.callbacks.addPolygonVertex) {
    pv = session.callbacks.addPolygonVertex(vIndex, uvIndex, nrmIndex, line, index, session, parser);
  } else if (parser && parser.addPolygonVertex) {
    pv = parser.addPolygonVertex(vIndex, uvIndex, nrmIndex, line, index, session, parser);
  } else if (session && session.callbacks && session.callbacks.newPolygonVertex) {
    pv = session.callbacks.newPolygonVertex(vIndex, uvIndex, nrmIndex, line, index, session, parser);
  } else if (parser.newPolygonVertex) {
    pv = parser.newPolygonVertex(vIndex, uvIndex, nrmIndex, line, index, session, parser);
  } else {
    let vertex = null, uv = null, normal = null;

    if (session) {
      vertex = getFromArray(session.vertices, vIndex);
      uv = getFromArray(session.uvs, uvIndex);
      normal = getFromArray(session.normals, nrmIndex);
    }
    pv = {
      vertexIndex: vIndex,
      uvIndex: uvIndex,
      normalIndex: nrmIndex,
      vertex: vertex,
      uv: uv,
      normal: normal
    };
  }

  return pv;
}

const commParsers = [
  // material library
  {
    keys: ['mtllib'],
    parse: function (line, index, session, parser) {
      const comm = 'mtllib';
      const off = comm.length + 1;
      let mtllib = line.substring(off, line.length);

      const FIRST_NON_WHITESPACE_ASCII = 33;

      if (mtllib.charCodeAt(0) < FIRST_NON_WHITESPACE_ASCII ||
        mtllib.charCodeAt(mtllib.length - 1) < FIRST_NON_WHITESPACE_ASCII) {
        mtllib = strTrim(mtllib);
      }

      if (session && session.callbacks && session.callbacks.setMaterialLib) {
        session.callbacks.setMaterialLib(mtllib, line, index, session, parser);
      } else if (parser.setMaterialLib) {
        parser.setMaterialLib(mtllib, line, index, session, parser);
      }

      return;
    }
  },
  // use material
  {
    keys: ['usemtl'],
    parse: function (line, index, session, parser) {
      const comm = 'usemtl';
      const off = comm.length + 1;
      let mtl = line.substring(off, line.length);

      const FIRST_NON_WHITESPACE_ASCII = 33;

      if (mtl.charCodeAt(0) < FIRST_NON_WHITESPACE_ASCII ||
        mtl.charCodeAt(mtl.length - 1) < FIRST_NON_WHITESPACE_ASCII) {
        mtl = strTrim(mtl);
      }

      if (session && session.callbacks && session.callbacks.useMaterial) {
        session.callbacks.useMaterial(mtl, line, index, session, parser);
      } else if (parser.useMaterial) {
        parser.useMaterial(mtl, line, index, session, parser);
      }
    }
  },
  {
    // object / group
    keys: ['o', 'g'],
    parse: function (line, index, session, parser) {
      const res = regExpMap.o.exec(line);

      if (res) {
        const name = res[1];

        if (session.callbacks && session.callbacks.addObject) {
          session.callbacks.addObject(name, line, index, session, parser);
        } else if (parser.addObject) {
          parser.addObject(name, line, index, session, parser);
        } else {
          let merge = false;

          if (session.params) {
            merge = session.params.merge;
          }
          if (!merge || !session.currentObject) {
            let obj = null;

            if (session.callbacks && session.callbacks.newObject) {
              obj = session.callbacks.newObject(name, line, index, session, parser);
            } else if (parser.newObject) {
              obj = parser.newObject(name, line, index, session, parser);
            }
            if (!obj) {
              obj = createNewObject(name);
            }
            if (!session.objects) {
              session.objects = [];
            }
            session.objects.push(obj);
            if (name) {
              if (!session.objectsByName) {
                session.objectsByName = {};
              }
              session.objectsByName[name] = obj;
            }
            session.currentObject = obj;
          }
        }
      }
    }
  },
  {
    // vertex
    keys: ['v'],
    parse: function (line, index, session, parser) {
      const regExpRes = regExpMap.v.exec(line);

      if (regExpRes) {
        const x = parseNum(regExpRes[XIndex], 0);
        const y = parseNum(regExpRes[YIndex], 0);
        const z = parseNum(regExpRes[ZIndex], 0);

        if (session.callbacks && session.callbacks.addVertex) {
          session.callbacks.addVertex(x, y, z, line, index, session, parser);
        } else if (parser.addVertex) {
          parser.addVertex(x, y, z, line, index, session, parser);
        } else {
          let res = null;

          if (parser.newVertex) {
            res = parser.newVertex(x, y, z, line, index, session);
          }
          if (!res) {
            if (session.callbacks && session.callbacks.newVertex) {
              res = session.callbacks.newVertex(x, y, z, line, index, session);
            }
          }
          if (!res) {
            res = [x, y, z];
          }
          if (!session.vertices) {
            session.vertices = [];
          }
          session.vertices.push(res);
        }
      }
    }
  },

  {
    // texture coordinate
    keys: ['vt'],
    parse: function (line, index, session, parser) {
      const regExpRes = regExpMap.vt.exec(line);

      if (regExpRes) {
        const x = parseNum(regExpRes[XIndex], 0);
        let y = parseNum(regExpRes[YIndex], 0);

        if (session.callbacks && session.callbacks.addUV) {
          session.callbacks.addUV(x, y, line, index, session, parser);
        } else if (parser.addUV) {
          parser.addUV(x, y, line, index, session, parser);
        } else {
          if (session.params) {
            if (session.params.flipV) {
              y = 1 - y;
            }
          }
          let res;

          if (parser.newUV) {
            res = parser.newUV(x, y, line, index, session, parser);
          }
          if (!res) {
            if (session.callbacks && session.callbacks.newUV) {
              res = session.callbacks.newUV(x, y, line, index, session, parser);
            }
          }
          if (!res) {
            res = [x, y];
          }
          if (!session.uvs) {
            session.uvs = [];
          }
          session.uvs.push(res);
        }
      }
    }
  },
  {
    // normal
    keys: ['vn'],
    parse: function (line, index, session, parser) {
      const regExpRes = regExpMap.vn.exec(line);

      if (regExpRes) {
        const x = parseNum(regExpRes[XIndex], 0);
        const y = parseNum(regExpRes[YIndex], 0);
        const z = parseNum(regExpRes[ZIndex], 1);

        /* eslint-disable */
        if (session.nIndex === undefined) {
          session.nIndex = 0;
        }
        ++session.nIndex;
        /* eslint-enable */

        if (session.callbacks && session.callbacks.addNormal) {
          session.callbacks.addNormal(x, y, z, line, index, session, parser);
        } else if (parser.addNormal) {
          parser.addNormal(x, y, z, line, index, session, parser);
        } else {
          let res;

          if (parser.newNormal) {
            res = parser.newNormal(x, y, z, line, index, session, parser);
          }
          if (!res) {
            if (session.callbacks && session.callbacks.newNormal) {
              res = session.callacks.newNormal(x, y, z, line, index, session, parser);
            }
          }
          if (!res) {
            res = [x, y, z];
          }
          if (!session.normals) {
            session.normals = [];
          }
          session.normals.push(res);
        }
      }
    }
  },
  {
    // face / polygon
    keys: ['f'],
    parse: function (lineParam, index, session, parser) {
      let line = lineParam;

      if (line.indexOf('\t', 0) >= 0) {
        line = strReplace(line, '\t', ' ');
      }
      if (line.indexOf('  ', 0) >= 0) {
        line = strReplace(line, '  ', ' ');
      }
      let fromIndex = line.indexOf('f', 0);
      let startIndex, endIndex;
      const l = line.length;

      let loop = fromIndex >= 0;
      let polyVerts = null;

      if (session && session.callbacks && session.callbacks.beginPolygon) {
        session.callbacks.beginPolygon(line, index, session, parser);
      } else if (parser && parser.beginPolygon) {
        parser.beginPolygon(line, index, session, parser);
      }
      while (loop) {
        startIndex = line.indexOf(' ', fromIndex);
        loop = startIndex >= 0;
        if (loop) {
          ++startIndex;
          endIndex = line.indexOf(' ', startIndex);
          if (endIndex < 0) {
            endIndex = l;
            loop = false;
          } else {
            fromIndex = endIndex;
          }
          const part = line.substring(startIndex, endIndex);
          let pvMap = session.polyVertexMap;
          let pv = null;

          if (pvMap) {
            pv = pvMap[part];
          }

          if (!pv) {
            pv = parsePolyVert(part, line, index, parser, session);
          }
          if (pv) {
            if (!pvMap) {
              pvMap = session.polyVertexMap = {};
            }
            pvMap[part] = pv;
            if (!polyVerts) {
              polyVerts = [];
            }
            polyVerts.push(pv);
          }
        }
      }
      if (session && session.callbacks && session.callbacks.endPolygon) {
        session.callbacks.endPolygon(line, index, session, parser);
      } else if (parser && parser.endPolygon) {
        parser.endPolygon(line, index, session, parser);
      }
      if (polyVerts && polyVerts.length > 0) {
        if (session.callbacks && session.callbacks.addPolygon) {
          session.callbacks.addPolygon(polyVerts, line, index, session, parser);
        } else if (parser.addPolygon) {
          parser.addPolygon(polyVerts, line, index, session, parser);
        } else {
          let poly = null;

          if (parser.newPolygon) {
            poly = parser.newPolygon(polyVerts, line, index, session, parser);
          } else if (session && session.callbacks && session.callbacks.newPolygon) {
            poly = session.callbacks.newPolygon(polyVerts, line, index, session, parser);
          }
          if (!poly) {
            poly = {
              vertices: polyVerts
            };
          }
          if (!session.currentObject) {
            if (parser.newObject) {
              session.currentObject = parser.newObject(parser, session);
            } else if (session && session.callbacks && session.callbacks.newObject) {
              session.currentObject = session.callbacks.newObject(parser, session);
            }
            if (!session.currentObject) {
              session.currentObject = {};
            }
          }
          if (!session.currentObject.polygons) {
            session.currentObject.polygons = [];
          }
          session.currentObject.polygons.push(poly);
        }
      }
    }
  }
  /* ,
  {
    // use material <name>
    keys: ['usemtl'],
    parse: function (line, index, session, parser) {
      const regExpRes = regExpMap.usemtl.exec(line);
      let name = null;

      if (regExpRes) {
        name = regExpRes[1];
      }
      if (name) {
        if (session && session.callbacks && session.callbacks.setMaterial) {
          session.callbacks.setMaterial(name, line, index, session, parser);
        }
      }
    }
  }*/
];

const commParserMap = (function () {
  const res = {};
  const l = commParsers.length;

  for (let i = 0; i < l; ++i) {
    const p = commParsers[i];

    if (p && p.keys) {
      const numk = p.keys.length;

      for (let j = 0; j < numk; ++j) {
        const k = p.keys[j];

        if (k) {
          res[k] = p;
        }
      }
    }
  }

  return res;
}());


export default class OBJParser3D extends Parser3D {
  constructor(args = null) {
    super();
    let callbacks = null;

    if (args) {
      callbacks = args.callbacks;
    }

    this._callbacks = callbacks;
  }
  /**
   * @function getLineCommand
   * @description returns the instruction part of a line (first chars before the first space)
   * @param {String} lineParam - line in the obj source
   * @return {String} - instruction part of the line
   * */
  _getLineCommand(lineParam) {
    let line = lineParam;

    if (!line) {
      return null;
    }
    if (line.length === 0) {
      return null;
    }
    const idx = line.indexOf(' ');

    if (idx >= 0) {
      line = line.substring(0, idx);
    }

    return line;
  }

  /**
   * @function parseLine
   * @description parses a line in the obj source
   * @param {String} lineParam - the line content
   * @param {int} index - the line index
   * @param {Object} session - a session object containing shared data while parsing the obj file
   * @returns {void}
   * */
  _parseLine(lineParam, index, session) {
    let line = lineParam;

    const commIndex = line.indexOf('#', 0);

    if (commIndex >= 0) {
      let onCommentCallback = null;

      if (session && session.callbacks && session.callbacks.onComment) {
        onCommentCallback = session.callbacks.onComment;
      }
      if (!onCommentCallback && this.onComment) {
        onCommentCallback = this.onComment;
      }

      if (onCommentCallback) {
        const comm = line.substring(commIndex, line.length);

        onCommentCallback(comm, index, session, this);
      }

      line = line.substring(0, commIndex);
    }
    line = strTrim(line);
    if (line.length === 0) {
      return;
    }
    let cmd = this._getLineCommand(line);

    cmd = cmd.toLowerCase();

    const lineParser = commParserMap[cmd];

    if (lineParser) {
      lineParser.parse(line, index, session, this);
    }
  }

  /**
   * @function parse
   * @override
   * @description parses the obj source
   * @param {String} source - the obj source as string
   * @param {Object} params - optional params object:
   * */
  parse(source, params) {
    let src = source;

    if (!src) {
      return null;
    }
    if (src.indexOf('\r', 0) >= 0) {
      src = strReplace(src, '\r\n', '\n');
      src = strReplace(src, '\r', '\n');
    }
    if (src.indexOf('\\\n', 0) >= 0) {
      src = strReplace(src, '\\\n', '');
    }
    const session = {};

    const callbacks = this._callbacks;

    session.callbacks = callbacks;
    /*
    if (callbacks) {
      session.addObject = callbacks.addObject;
      session.newObject = callbacks.newObject;

      session.addVertex = callbacks.addVertex;
      session.newVertex = callbacks.newVertex;

      session.addUV = callbacks.addUV;
      session.newUV = callbacks.newUV;

      session.addNormal = callbacks.addNormal;
      session.newNormal = callbacks.newNormal;

      session.beginPolygon = callbacks.beginPolygon;
      session.endPolygon = callbacks.endPolygon;

      session.addPolygonVertex = callbacks.addPolygonVertex;
      session.newPolygonVertex = callbacks.newPolygonVertex;

      session.addPolygon = callbacks.addPolygon;
      session.newPolygon = callbacks.newPolygon;

      session.setMaterial = callbacks.setMaterial;

      session.getResult = callbacks.getResult;
    }
    */

    session.params = params;

    const lines = src.split('\n');
    const numLines = lines.length;

    for (let i = 0; i < numLines; ++i) {
      if (session.stop !== true) {
        this._parseLine(lines[i], i, session);
      }
    }
    let reslt = null;

    if (session && session.callbacks && session.callbacks.getResult) {
      reslt = session.callbacks.getResult(session, this);
    } else if (this.getResult) {
      reslt = this.getResult(session, this);
    } else {
      reslt = {
        objects: session.objects,
        objectsByName: session.objectsByName
      };
    }

    return reslt;
  }
}
