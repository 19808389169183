import Asset from './Asset';

export default class BackgroundAsset extends Asset {
  constructor(id) {
    super(id, 'background');
    this._data = null;
  }

  setData(data) {
    this._data = data;
  }

  getData() {
    return this._data;
  }
}
