import Asset from './Asset';
const UNDEFINED = 'undefined';

/**
* @class AssetHolder
* @abstract
* @description Base class for asset holders
*/
export default class AssetHolder extends Asset {
  constructor(name = null) {
    super(name, null, null);
    this.metaData = null;
  }

  clear() {
    this.clearAssets();
  }

  clearAssets() {
    return;
  }

  forEachAsset(callback, callbackData = null) {
    return;
  }

  getData() {
    return null;
  }

  setData(d) {
    return;
  }

  /**
   * @method getAsset
   * @description returns an asset by a key
   * @param {String|int} key - key as string or index
   * @returns {Asset} asset
   **/
  getAsset(key) {
    if (typeof (key) === UNDEFINED || key === null) {
      return null;
    }
    if (typeof (key) === 'number') {
      return this.getAssetAt(key);
    } else if (typeof (key) === 'string') {
      return this.getAssetByName(key);
    }

    return null;
  }

  /**
   * @method getAssetAt
   * @description returns an asset by an array index
   * @param {int} index - array index
   * @return {Asset} asset
   * */
  getAssetAt(index) {
    return null;
  }

  /**
   * @method getAssetByName
   * @description returns an asset by a key/name
   * @param {String} name - key/name
   * @return {Asset} asset
   * */
  getAssetByName(name) {
    return null;
  }

  /**
  * @method removeAsset
  * @description removes an asset
  * @param {Asset|String|int} asset - asset
  * @return {void}
  * */
  removeAsset(asset) {
    return;
  }

  /**
   * @method getNumAssets
   * @description Counts the number of assets
   * @return {int} number of assets
   * */
  getNumAssets() {
    return 0;
  }

  /**
  * numAssets
  * @description Counts the number of assets
  * @return {int} number of assets
  * */
  get numAssets() {
    return this.getNumAssets();
  }
}
