import Vector from './Vector';

const X = 0;
const Y = 1;
const Z = 2;
const W = 3;

export default class Vertex extends Vector {
  constructor(position, attributes = null) {
    super();
    this.position = position;
    this.attributes = attributes;
  }

  getCoord(i) {
    const pos = this.position;

    if (!pos) {
      return 0;
    }

    return pos.getCoord(i);
  }

  setCoord(i, value) {
    const pos = this.position;

    if (!pos) {
      return;
    }

    pos.setCoord(i, value);
  }

  setCoords(...values) {
    const pos = this.position;

    if (!pos) {
      return;
    }
    pos.setCoords(...values);
  }

  getX() {
    return this.getCoord(X);
  }

  setX(value) {
    return this.setCoord(X, value);
  }

  getY() {
    return this.getCoord(Y);
  }

  setY(value) {
    return this.setCoord(Y, value);
  }

  getZ() {
    return this.getCoord(Z);
  }

  setZ(value) {
    return this.setCoord(Z, value);
  }

  getW() {
    return this.getCoord(W);
  }

  setW(value) {
    return this.setCoord(W, value);
  }

  get x() {
    return this.getCoord(X);
  }

  set x(value) {
    this.setCoord(X, value);
  }

  get y() {
    return this.getCoord(Y);
  }

  set y(value) {
    this.setCoord(Y, value);
  }

  get z() {
    return this.getCoord(Z);
  }

  set z(value) {
    this.setCoord(Z, value);
  }

  get w() {
    return this.getCoord(W);
  }

  set w(value) {
    this.setCoord(W, value);
  }

  get [X]() {
    return this.getCoord(X);
  }

  set [X](value) {
    this.setCoord(X, value);
  }

  get [Y]() {
    return this.getCoord(Y);
  }

  set [Y](value) {
    this.setCoord(Y, value);
  }

  get [Z]() {
    return this.getCoord(Z);
  }

  set [Z](value) {
    this.setCoord(Z, value);
  }

  get [W]() {
    return this.getCoord(W);
  }

  set [W](value) {
    this.setCoord(W, value);
  }

  getDimension() {
    const pos = this.position;

    if (!pos) {
      return 0;
    }

    return pos.getDimension();
  }
}
