
export default class Geometry {
  constructor(polygons, polygonVertices, vertices, attributes = null) {
    this.polygons = polygons;

    // Vertices used in polygons
    this.polygonVertices = polygonVertices;

    // Vertex positions
    this.vertices = vertices;

    // Used for uvs, normals, morph targets, ...
    this.attributes = attributes;

    // Meta data
    this.userData = null;
  }
}
