import AssetHolderCollection from './AssetHolderCollection';
import EventDispatcher from '../../bgr/common/events/EventDispatcher';
import AssetGroup from './AssetGroup';
import Asset from './Asset';
import ImageAsset from './ImageAsset';
import Object3DAsset from './Object3DAsset';
import SampleAsset from './SampleAsset';
import QuiltAsset from './QuiltAsset';
import BackgroundAsset from './BackgroundAsset';

import LegType from '../legs/LegType';
import HandleTypes from '../handles/HandleTypes';
import HandleStyles from '../handles/HandleStyles';

import MattressConfig from '../mattress/MattressConfig';
import MattressConfigDA from '../mattress/MattressConfigDA';
import MattressDA from '../mattress/MattressDA';

import BorderComponentUtils from '../borders/BorderComponentUtils';
import BorderComponentTypes from '../borders/BorderComponentTypes';
// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
// #endif

export default class AssetManager extends EventDispatcher {
  constructor() {
    super();
    // common general textures

    const defaultFabric = new ImageAsset('defaultfabric.color');
    const defaultFabricNormal = new ImageAsset('defaultfabric.normal');

    const defaultMirrorPanelFabric = new ImageAsset('defaultmirrorpanelfabric.color');
    const defaultMirrorPanelFabricNormal = new ImageAsset('defaultmirrorpanelfabric.normal');

    const satinBRDF = new ImageAsset('satin.brdf');
    const logo = new ImageAsset('bd.logo');
    const logoFabric = new ImageAsset('bd.logo.fabric');
    const logoFabricBackground = new ImageAsset('bd.logo.fabric.background');

    if (!logo.metaData) {
      logo.metaData = {};
    }
    logo.metaData.textureProperties = {
      wrapX: 'clamp', wrapY: 'clamp'
    };

    const commonObjects = new AssetGroup('commonObjects');
    const commonTextures = new AssetGroup('commonTextures', [
      defaultFabric, defaultFabricNormal,
      defaultMirrorPanelFabric, defaultMirrorPanelFabricNormal,
      satinBRDF,
      logo, logoFabric, logoFabricBackground
    ]);

    // handles
    const handles = new AssetGroup('handles', [
      new Object3DAsset('verticalHandle_object3D'),
      new Object3DAsset('horizontalHandle_object3D'),
      this._newObject3DAsset('HHS012_obj', 'handles/horizontal/HHS012.obj', {
        parseParams: {
          flipV: true
        },
        minU: 0.008, minV: 0.011, maxU: 0.992, maxV: 0.352,
        minX: -10.96125, maxX: 10.95154, minY: -2.76035, maxY: 2.71301,
        uvData: {
          /* eslint-disable */
          scaleU: 1/22.269095528455285,
          scaleV: 1/16.05090909090909
          /* eslint-enable */
        }
      }),
      this._newObject3DAsset('HHS013_obj', 'handles/horizontal/HHS013.obj', {
        uvData: {
          /* eslint-disable */
          scaleU: 1/22.269095528455285,
          scaleV: 1/16.05090909090909
          /* eslint-enable */
        }
      }),
      this._newObject3DAsset('HHS015_obj', 'handles/horizontal/HHS015.obj', {
        uvData: {
          /* eslint-disable */
          scaleU: 1/22.269095528455285,
          scaleV: 1/16.05090909090909
          /* eslint-enable */
        }
      }),

      this._newObject3DAsset('HVS00001_obj', 'handles/vertical/HVS00001.obj', {
        uvData: {
          /* eslint-disable */
          scaleU: 0.043628483630603766,
          scaleV: 0.036876500407726526
          /* eslint-enable */
        }
      }),
      this._newObject3DAsset('HVS00002_obj', 'handles/vertical/HVS00002.obj', {
        uvData: {
          /* eslint-disable */
          scaleU: 0.043628483630603766,
          scaleV: 0.036876500407726526
          /* eslint-enable */
        }
      }),
      this._newObject3DAsset('HV004_obj', 'handles/vertical/HV004.obj', {
        uvData: {
          /* eslint-disable */
          scaleU: 0.043628483630603766,
          scaleV: 0.036876500407726526
          /* eslint-enable */
        }
      }),
      this._newObject3DAsset('HVS005_obj', 'handles/vertical/HVS005.obj', {
        uvData: {
          /* eslint-disable */
          scaleU: 0.043628483630603766,
          scaleV: 0.036876500407726526
          /* eslint-enable */
        }
      }),
      this._newObject3DAsset('HVS006_obj', 'handles/vertical/HVS006.obj', {
        uvData: {
          /* eslint-disable */
          scaleU: 0.043628483630603766,
          scaleV: 0.036876500407726526
          /* eslint-enable */
        }
      }),
      // HHP031
      this._newObject3DAsset('hhp031_obj', 'handle.pocket.hhp031.object')
    ]);

    // legs
    /*
    const legs = new AssetGroup('legs', [
      this._newObject3DAsset('leg_0', 'legs.leg_0.object'),
      this._newObject3DAsset('leg_1', 'legs.leg_1.object'),
      this._newObject3DAsset('leg_2', 'legs.leg_2.object')
    ]);
    */

    // Bekaert samples
    const samples = new AssetGroup('samples');

    // Quilts
    const quilts = new AssetGroup('quilts');

    // Backgrounds
    const backgrounds = new AssetGroup('backgrounds');

    const temporary = new AssetGroup('temporary');

    // config assets (should be cleared for each new config)
    const configAssets = new AssetGroup('config');

    // All asset holders together
    const all = new AssetHolderCollection('all', [
      commonObjects,
      commonTextures,
      handles,
      // legs,
      samples,
      quilts,
      backgrounds,

      configAssets,

      temporary
    ]);

    this.assets = all;

    // Store all asset holders in one map for easy access
    this.assetCollections = {
      all,
      handles,
      // legs,

      commonTextures,
      commonObjects,
      samples,
      quilts,
      backgrounds,
      temporary
    };
  }

  findAsset(...path) {
    return this.assetCollections.all.findAsset(...path);
  }

  findAssetHolder(...path) {
    return this.assetCollections.all.findAssetHolder(...path);
  }

  // Returns all assets used by a config
  getAssetsByConfig(config, params = null, array = null) {
    let resArray = array;
    let tempArr;

    tempArr = this.getMattressAssetsByConfig(config, params, resArray);
    if (tempArr) {
      resArray = tempArr;
    }
    tempArr = this.getBackgroundAssetsByConfig(config, params, resArray);
    if (tempArr) {
      resArray = tempArr;
    }
    tempArr = this.getSceneAssetsByConfig(
      config,
      {
        assetCollectParams: params,
        assetManager: this
      },
      resArray
    );
    if (tempArr) {
      resArray = tempArr;
    }

    return resArray;
  }

  getSceneAssetsByConfig(config, params, array = null) {
    if (config && config.getSceneAssets) {
      const res = config.getSceneAssets(params, array);

      if (res) {
        return res;
      }
    }

    return array;
  }

  getBackgroundAssetsByConfig(config, params = null, array = null) {
    let arr = array;
    let cfgJson = config;

    if (config instanceof MattressConfig) {
      cfgJson = config.getData();
    }
    if (!cfgJson) {
      return arr;
    }

    const bg = cfgJson.background;

    if (bg) {
      let bgId = typeof (bg) === 'string' ? bg : bg.id;

      if (typeof (bgId) === 'number') {
        bgId = `${bgId}`;
      }

      if (bgId) {
        const bgAssets = this.assetCollections.backgrounds;
        let asset = bgAssets.getAsset(bgId);

        if (!asset) {
          asset = new BackgroundAsset(bgId);

          bgAssets.addAsset(asset);
        }

        if (asset) {
          if (!arr) {
            arr = [];
          }
          if (arr.indexOf(asset, 0) < 0) {
            arr.push(asset);
          }
        }
      }
    }

    return arr;
  }

  getMattressAssetsByConfig(config, params = null, array = null) {
    if (!config) {
      return array;
    }
    let arr = array;

    let singles = null;
    let legs = null;

    if (config instanceof MattressConfig) {
      singles = config.getSingles();
      legs = config.getLegs();
    } else {
      singles = MattressConfigDA.getSingles(config);
      legs = config._data.legs;
    }

    arr = this.getAssetsBySingles(singles, params, arr);

    // add legs
    if (legs) {
      arr = this.getAssetsByLeg(legs, params, arr);
    }

    return arr;
  }

  getAssetsBySingles(singles, params = null, array = null) {
    if (!singles) {
      return array;
    }
    const num = singles.length;

    if (num === 0) {
      return array;
    }
    let arr = array;

    for (let i = 0; i < num; ++i) {
      arr = this.getAssetsBySingle(singles[i], params, arr);
    }

    return arr;
  }

  getAssetsBySingle(single, params = null, array = null) {
    if (!single) {
      return array;
    }
    let arr = array;

    arr = this.getAssetsByBorderSettings(single.border, params, arr);

    const options = single.options;

    arr = this.getAssetsBySingleOptions(options, params, arr);

    arr = this._getTopAssets(single, params, arr);
    arr = this._getBottomAssets(single, params, arr);

    return arr;
  }

  getAssetsBySingleOptions(options, params = null, array = null) {
    if (!options) {
      return array;
    }
    let arr = array;

    // 1) Handles
    let handles = options.handler;

    if (!handles) {
      handles = options.handle;
    }
    if (!handles) {
      handles = options.handles;
    }
    arr = this.getAssetsByHandles(handles, params, arr);

    // 2) Legs
    const legs = options.legs || options.leg;

    arr = this.getAssetsByLeg(legs, params, arr);

    return arr;
  }

  _getHandleStyleByName(name) {
    if (!name) {
      return null;
    }

    return HandleStyles.get(name);
  }

  _getHandleTypeByName(name) {
    if (!name) {
      return null;
    }
    const n = name.toUpperCase();

    return HandleTypes[n];
  }

  _parseQuiltAsset(data) {
    const t = typeof (data);
    let id = null;

    if (data === null || t === 'undefined') {
      return null;
    }

    if (t === 'string') {
      id = data;
    } else {
      id = MattressDA.getQuiltID(data);
    }
    if (typeof (id) === 'undefined' || id === null) {
      if (data.img && data.img.src) {
        const qaURL = data.img.src;
        let qa = this.assetCollections.quilts.getAsset(qaURL);

        if (!qa) {
          qa = new QuiltAsset(qaURL, data);
          this.assetCollections.quilts.addAsset(qa, qaURL);
        }

        return qa;
      }

      // no ID
      return null;
    }
    id = (typeof (id) === 'string') ? id : `${id}`;

    if (!id) {
      // Empty string -> no ID
      return null;
    }

    let asset = this.assetCollections.quilts.getAsset(id);

    if (asset) {
      return asset;
    }

    asset = new QuiltAsset(id, data);

    this.assetCollections.quilts.addAsset(asset, id);

    return asset;
  }

  _parseSampleAsset(data) {
    if (!data) {
      return null;
    }
    const t = typeof (data);
    let id = null;
    let params = null;

    if (t === 'string' || t === 'number') {
      id = data;
    } else {
      params = data; // Maybe this line should be removed
      id = data.id;
    }

    if (typeof (id) === 'undefined' || id === null) {
      return null;
    }

    const key = typeof (id) === 'string' ? id : `${id}`;

    let asset = this.assetCollections.samples.getAsset(key);

    if (asset) {
      return asset;
    }

    asset = new SampleAsset(id, key, data, params);
    this.assetCollections.samples.addAsset(asset, key);

    return asset;
  }

  _getTopAssets(single, params, array = null) {
    return this._getTopOrBottomAssets(single, 'top', params, array);
  }

  _getBottomAssets(single, params, array = null) {
    return this._getTopOrBottomAssets(single, 'bottom', params, array);
  }

  _getTopOrBottomAssets(single, key, params, array = null) {
    if (!single) {
      return array;
    }
    let arr = array;

    if (!arr) {
      arr = [];
    }
    const part = single[key];

    if (MattressDA.hasMirrorPanel(single)) {
      arr = this.addAssetToArray('defaultmirrorpanelfabric.color', arr, params);
      arr = this.addAssetToArray('defaultmirrorpanelfabric.normal', arr, params);
      const bcArr = single.border ? single.border.components : null;
      const firstBC = bcArr ? bcArr[0] : null;
      const lastBC = bcArr ? bcArr[bcArr.length - 1] : null;
      const firstBCType = firstBC ? firstBC.type : null;
      const lastBCType = lastBC ? lastBC.type : null;

      if (firstBCType === 'zipper') {
        BorderComponentTypes.ZIPPER.addZipperAssets(firstBC, params, arr, this, 'spiral_coil', 'horizontal_puller');
      }
      if (lastBCType === 'zipper' && lastBC !== firstBC) {
        BorderComponentTypes.ZIPPER.addZipperAssets(lastBC, params, arr, this, 'spiral_coil', 'horizontal_puller');
      }
    }

    let textureAsset = null;
    let quiltAsset = null;
    let mirrorPanel = null;

    if (part && part.mirrorPanel) {
      mirrorPanel = part.mirrorPanel;
    }
    if (!mirrorPanel && key === 'bottom' && single.mirrorPanel) {
      mirrorPanel = single.mirrorPanel;
    }
    if (mirrorPanel) {
      BorderComponentTypes.ZIPPER.addZipperAssets(mirrorPanel, params, arr, this, 'spiral_coil', 'horizontal_puller');
      const mpTextureAsset = this._parseSampleAsset(mirrorPanel.texture);
      const mpQuiltAsset = this._parseQuiltAsset(mirrorPanel.quilt);

      if (mpTextureAsset) {
        this._addAsset(mpTextureAsset, arr, params);
      } else {
        // arr = this.addAssetToArray('defaultfabric.color', arr, params);
        // arr = this.addAssetToArray('defaultfabric.normal', arr, params);
        arr = this.addAssetToArray('defaultmirrorpanelfabric.color', arr, params);
        arr = this.addAssetToArray('defaultmirrorpanelfabric.normal', arr, params);
      }

      if (mpQuiltAsset) {
        this._addAsset(mpQuiltAsset, arr, params);
      }
    }

    if (part && part.texture) {
      textureAsset = this._parseSampleAsset(part.texture);
    }
    if (part && part.quilt) {
      quiltAsset = this._parseQuiltAsset(part.quilt);
    }
    if (!textureAsset) {
      textureAsset = this._parseSampleAsset(single.texture);
    }
    if (!quiltAsset) {
      quiltAsset = this._parseQuiltAsset(single.quilt);
    }

    if (textureAsset) {
      this._addAsset(textureAsset, arr, params);
    } else {
      arr = this.addAssetToArray('defaultfabric.color', arr, params);
      arr = this.addAssetToArray('defaultfabric.normal', arr, params);
    }

    if (quiltAsset) {
      this._addAsset(quiltAsset, arr, params);
    }

    return arr;
  }

  getAssetsByHandles(handles, params = null, array = null) {
    if (!handles) {
      return array;
    }
    const handleStyle = HandleStyles.getFromJSON(handles);
    const handleTypeName = handles.type;
    let handleType = null;

    if (handleStyle) {
      handleType = handleStyle.type;
    }

    if (!handleType) {
      handleType = this._getHandleTypeByName(handleTypeName);
    }
    let arr = array;

    if (handleType) {
      const newArr = handleType.addAssets(handles, handleStyle, params, arr, this);

      if (newArr) {
        arr = newArr;
      }
      /*
      const style = handles.style;
      const o3d = style ? style.object3d : null;
      const handle3D = this.assetCollections.handles.getAssetByName(o3d);

      this._addAsset(handle3D, arr, params);

      let mat = style ? style.material : null; // single fallback material
      const materials = style ? style.materials : null; // material per object

      let sample = mat ? this._parseSampleAsset(mat.sample) : null;

      this._addAsset(sample, arr, params);

      if (materials) {
        for (const v in materials) {
          if (materials.hasOwnProperty(v)) {
            mat = materials[v];
            sample = mat ? this._parseSampleAsset(mat.sample) : null;
            this._addAsset(sample, arr, params);
          }
        }
      }

      if (!arr) {
        arr = [];
      }

      // handleType.collectAssets(handles, this, params, arr);
      */
    }

    return arr;
  }

  getAssetsByLeg(leg, params = null, array = null) {
    if (!leg) {
      return array;
    }

    const legType = this.legType || new LegType();

    const arr = array || [];

    if (legType) {
      const newArr = legType.addAssets(leg, params, arr, this);
      const texGroup = this.assetCollections.commonTextures;
      const defFabric = texGroup.getAssetByName('defaultfabric.color');
      const defFabricNormal = texGroup.getAssetByName('defaultfabric.normal');

      newArr.push(defFabric);
      newArr.push(defFabricNormal);
      if (newArr && newArr !== arr) {
        arr.concat(newArr);
      }
    }

    return arr;
  }

  getAssetsByBorderSettings(borderSettings, params = null, array = null) {
    if (!borderSettings) {
      return array;
    }
    let arr = array;

    arr = this.getAssetsByBorderComponents(borderSettings.components, params, arr);

    return arr;
  }

  getAssetsByBorderComponents(borderComponents, params = null, array = null) {
    if (!borderComponents) {
      return array;
    }
    const num = borderComponents.length;

    if (num === 0) {
      return array;
    }
    let arr = array;

    for (let i = 0; i < num; ++i) {
      arr = this.getAssetsByBorderComponent(borderComponents[i], params, arr);
    }

    return arr;
  }

  _addSatinBRDF(array, params) {
    let arr = array;
    const textureAssetGroup = this.assetCollections.commonTextures;
    const satinBRDFAsset = textureAssetGroup.getAssetByName('satin.brdf');

    arr = this._addAsset(satinBRDFAsset, arr, params);

    return arr;
  }

  getAssetsByBorderComponent(borderComponent, params = null, array = null) {
    if (!borderComponent) {
      return array;
    }
    let type = borderComponent.type;
    let borderComponentType = null;

    if (type) {
      borderComponentType = BorderComponentUtils.getBorderComponentTypeByName(type);
      type = type.toLowerCase();
    }

    let arr = array;
    let newArr = null;

    if (borderComponent.material === 'satin') {
      arr = this._addSatinBRDF(arr, params);
    }

    if (borderComponentType) {
      newArr = borderComponentType.addAssets(borderComponent, params, arr, this);
      if (newArr) {
        arr = newArr;
      }
    }

    arr = this._addSampleQuiltAssets(borderComponent, arr, params);

    if (type === 'zipper') {
      /*
      if (!arr) {
        arr = [];
      }

      const KEY_ZIPPER_TEX = 'zipper_texture';
      const KEY_ZIPPER_NORMALMAP = 'zipper_normal';
      const KEY_ZIPPER_SPECULARMASK = 'zipper_specularmask';
      const KEY_ZIPPER_PULLER_OBJECT = 'zipper_puller_object';
      const KEY_ZIPPER_PULLER_LIGHTMAP = 'zipper_puller_lightmap';

      const textureAssetGroup = this.assetCollections.commonTextures;
      const objectAssetGroup = this.assetCollections.commonObjects;

      let zipperTexture = textureAssetGroup.getAssetByName(KEY_ZIPPER_TEX);
      let zipperNormalMap = textureAssetGroup.getAssetByName(KEY_ZIPPER_NORMALMAP);
      let zipperSpecularMask = textureAssetGroup.getAssetByName(KEY_ZIPPER_SPECULARMASK);
      let zipperPullerObject = objectAssetGroup.getAssetByName(KEY_ZIPPER_PULLER_OBJECT);
      let zipperPullerLightmap = textureAssetGroup.getAssetByName(KEY_ZIPPER_PULLER_LIGHTMAP);

      if (!zipperTexture) {
        zipperTexture = this._newImageAsset(KEY_ZIPPER_TEX, 'zipper/zipper_color_white.jpg');
        this._addAssetToGroup(zipperTexture, textureAssetGroup);
      }
      if (!zipperNormalMap) {
        zipperNormalMap = this._newImageAsset(KEY_ZIPPER_NORMALMAP, 'zipper/zipper_normal.jpg');
        this._addAssetToGroup(zipperNormalMap, textureAssetGroup);
      }
      if (!zipperSpecularMask) {
        zipperSpecularMask = this._newImageAsset(KEY_ZIPPER_SPECULARMASK, 'zipper/zipper_specularmask.jpg');
        this._addAssetToGroup(zipperSpecularMask, textureAssetGroup);
      }

      if (!zipperPullerObject) {
        // zipper/zipper_puller.obj
        zipperPullerObject = this._newObject3DAsset(KEY_ZIPPER_PULLER_OBJECT, 'zipper/zipper_puller.obj');
        this._addAssetToGroup(zipperPullerObject, objectAssetGroup);
      }
      if (!zipperPullerLightmap) {
        zipperPullerLightmap = this._newImageAsset(KEY_ZIPPER_PULLER_LIGHTMAP, 'zipper/zipper_puller_lightmap.jpg');
        this._addAssetToGroup(zipperPullerLightmap, textureAssetGroup);
      }
      this._addAsset(zipperTexture, arr, params);
      this._addAsset(zipperNormalMap, arr, params);
      this._addAsset(zipperSpecularMask, arr, params);

      this._addAsset(zipperPullerObject, arr, params);
      this._addAsset(zipperPullerLightmap, arr, params);
      */

      return arr;
    } else if (type === 'border' || type === '3dborder' || type === 'border3d') {
      if (!arr) {
        arr = [];
      }
      const KEY_BORDER3D_TEX = 'border3d_texture';
      const KEY_BORDER3D_NORMALMAP = 'border3d_normalmap';
      const KEY_BORDER3D_SPECULARMASK = 'border3d_specularmask';
      const textureAssetGroup = this.assetCollections.commonTextures;
      let border3dTexture = textureAssetGroup.getAssetByName(KEY_BORDER3D_TEX);
      let border3dNormalMap = textureAssetGroup.getAssetByName(KEY_BORDER3D_NORMALMAP);
      let border3dSpecularMask = textureAssetGroup.getAssetByName(KEY_BORDER3D_SPECULARMASK);

      if (!border3dTexture) {
        border3dTexture = this._newImageAsset(KEY_BORDER3D_TEX, '/border3d/border3d_diffuse.jpg');
        this._addAssetToGroup(border3dTexture, textureAssetGroup);
      }
      if (!border3dNormalMap) {
        border3dNormalMap = this._newImageAsset(KEY_BORDER3D_NORMALMAP, '/border3d/border3d_normal.jpg');
        this._addAssetToGroup(border3dNormalMap, textureAssetGroup);
      }
      if (!border3dSpecularMask) {
        border3dSpecularMask = this._newImageAsset(KEY_BORDER3D_SPECULARMASK, '/border3d/border3d_specular.jpg');
        this._addAssetToGroup(border3dSpecularMask, textureAssetGroup);
      }

      this._addAsset(border3dTexture, arr, params);
      this._addAsset(border3dNormalMap, arr, params);
      this._addAsset(border3dSpecularMask, arr, params);

      const quiltAsset = this._parseQuiltAsset(borderComponent.quilt);

      if (quiltAsset) {
        this._addAsset(quiltAsset, arr, params);
      }

      return arr;
    } else if (type === 'fabric') {
      if (!arr) {
        arr = [];
      }

      if (borderComponent.ribbon) {
        const ribbonObj = borderComponent.ribbon;

        if (ribbonObj.material === 'satin') {
          arr = this._addSatinBRDF(arr, params);
        }
      }
      /*
      if (typeof (tex) === 'string') {
        const grp = this.assetCollections.samples;
        let asset = grp.getAssetByName(grp);

        if (!asset) {
          asset = this._newSampleAsset(tex, {type: 'getSample', sample: tex});
        }
      } else {
        // Legacy
        const img = tex.img;
        const bump = tex.bump;

        const imgAsset = this._newImageAsset(img, img);
        const bumpAsset = this._newImageAsset(bump, bump);

        this._addAsset(imgAsset, arr, params);
        this._addAsset(bumpAsset, arr, params);
      }
      */

      return arr;
    } else if (type === 'piping') {
      if (!arr) {
        arr = [];
      }
      const texGroup = this.assetCollections.commonTextures;
      const defFabric = texGroup.getAssetByName('defaultfabric.color');
      const defFabricNormal = texGroup.getAssetByName('defaultfabric.normal');

      /*
      const KEY_DEF_FABRIC = 'defaultfabric';
      const KEY_DEF_FABRIC_NORMAL = 'defaultfabric_normalmap';

      const textureAssetGroup = this.assetCollections.commonTextures;
      let defFabric = textureAssetGroup.getAssetByName(KEY_DEF_FABRIC);
      let defFabricNormal = textureAssetGroup.getAssetByName(KEY_DEF_FABRIC_NORMAL);

      if (!defFabric) {
        defFabric = this._newImageAsset(KEY_DEF_FABRIC, 'defaultfabric.jpg');
        this._addAssetToGroup(defFabric, textureAssetGroup);
      }
      if (!defFabricNormal) {
        defFabricNormal = this._newImageAsset(KEY_DEF_FABRIC_NORMAL, 'defaultfabric_normal.jpg');
        this._addAssetToGroup(defFabricNormal, textureAssetGroup);
      }
      */
      this._addAsset(defFabric, arr, params);
      this._addAsset(defFabricNormal, arr, params);
    }

    return arr;
  }

  _addSampleQuiltAssets(source, array, params) {
    if (!source) {
      return array;
    }
    if (source.texture || source.quilt) {
      let arr = array;

      const sampleAsset = this._parseSampleAsset(source.texture);
      const quiltAsset = this._parseQuiltAsset(source.quilt);

      if (sampleAsset) {
        if (!arr) {
          arr = [];
        }
        this._addAsset(sampleAsset, arr, params);
      }
      if (quiltAsset) {
        if (!arr) {
          arr = [];
        }
        this._addAsset(quiltAsset, arr, params);
      }

      return arr;
    }

    return array;
  }

  // Utility methods
  static addAssetToArray(asset, array, params = null) {
    if (!asset) {
      return array;
    }

    if (params && params.condition) {
      if (params.condition(asset, params) === false) {
        return array;
      }
    }

    let arr = array;

    if (!arr) {
      arr = [];
    }
    if (arr.indexOf(asset) < 0) {
      arr.push(asset);
    }

    return arr;
  }
  /**
   * @method _addAsset
   * @private
   * @description Internal method to add an asset to an array, mostly used by
   *  getAssetsBy* methods
   * @param {Asset} asset - The asset to be added
   * @param {Array} array - Array containing assets
   * @param {Object} params - optional params object, containing any of following values:
   *  - condition: optional condition function, return false if the asset should not be included
   *    signature: condition(asset, params)
   * @return {Array} array
   * */
  _addAsset(asset, array = null, params = null) {
    return AssetManager.addAssetToArray(asset, array, params);
  }

  addAssetToArray(asset, array = null, params = null) {
    let a = asset;

    if (!a) {
      return array;
    }

    if (typeof (a) === 'string') {
      if (!this.assets) {
        return array;
      }
      a = this.assets.getAssetByName(a);
    }

    if (a instanceof SampleAsset) {
      this._addAssetToGroup(a, this.assetCollections.samples);
    } else if (a instanceof ImageAsset) {
      this._addAssetToGroup(a, this.assetCollections.commonTextures);
    } else if (a instanceof Object3DAsset) {
      this._addAssetToGroup(a, this.assetCollections.commonObjects);
    }

    return AssetManager.addAssetToArray(a, array, params);
  }

  collectAsset(asset, array = null, params = null) {
    const res = this._addAsset(asset, array, params);

    if (res) {
      return res;
    }

    return asset;
  }

  _addAssetToGroup(asset, group) {
    if (!group || !asset) {
      return;
    }
    if ((group instanceof AssetGroup) && (asset instanceof Asset)) {
      group.addAsset(asset);
    } else {
      // #if DEBUG
      BD3DLogger.warn('Failed to add asset to group');
      // #endif

      return;
    }
  }

  _setAssetURL(asset, url) {
    if (!asset) {
      return asset;
    }
    let md = asset.metaData;

    if (!md && url) {
      md = asset.metaData = {};
    }
    if (!md) {
      return asset;
    }
    md.url = url;

    return asset;
  }

  _getAssetURL(asset) {
    if (!asset) {
      return null;
    }
    const md = asset.metaData;

    if (!md) {
      return null;
    }

    return md.url;
  }

  getAssetURL(asset) {
    return this._getAssetURL(asset);
  }

  setAssetURL(asset, url) {
    return this._setAssetURL(asset, url);
  }

  _newObject3DAsset(name, url, metaData = null) {
    const res = new Object3DAsset(name);

    this._setAssetURL(res, url);


    if (metaData) {
      for (const v in metaData) {
        if (metaData.hasOwnProperty(v)) {
          if (!res.metaData) {
            res.metaData = {};
          }
          res.metaData[v] = metaData[v];
        }
      }
    }

    return res;
  }

  _newImageAsset(name, url) {
    const res = new ImageAsset(name);

    this._setAssetURL(res, url);

    return res;
  }

  _newSampleAsset(name, url) {
    const res = new AssetGroup();

    this._setAssetURL(res, url);

    return res;
  }

  dispose() {
    this.removeEventListeners();
    const {assetCollections} = this;
    const samples = assetCollections ? assetCollections.samples : null;
    const quilts = assetCollections ? assetCollections.quilts : null;

    if (quilts) {
      quilts.clear();
    }

    if (samples) {
      samples.clear();
    }
  }

  compareAndDisposeUnusedAssets(usedAssets, previousAssets) {
    let prevAssets = previousAssets;

    if (prevAssets) {
      const num = prevAssets.length;

      for (let i = num - 1; i >= 0; --i) {
        const prevAsset = prevAssets[i];

        if (!usedAssets || usedAssets.indexOf(prevAsset, 0) < 0) {
          if (usedAssets) {
            prevAssets.splice(i, 1);
          }
          this.assetCollections.samples.removeAsset(prevAsset);
          this.assetCollections.quilts.removeAsset(prevAsset);
          this.assetCollections.backgrounds.removeAsset(prevAsset);
          this.dispatchEvent('dispose', prevAsset);
        }
      }
    }
    if (usedAssets) {
      const num = usedAssets.length;
      let newPrevAssets = prevAssets;

      if (!newPrevAssets) {
        newPrevAssets = prevAssets = [];
      }

      for (let i = 0; i < num; ++i) {
        const asset = usedAssets[i];

        if (!prevAssets || prevAssets.indexOf(asset, 0) < 0) {
          newPrevAssets.push(asset);
        }
      }
    } else if (prevAssets) {
      prevAssets.length = 0;
    }

    return prevAssets;
  }
}
