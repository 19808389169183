import ArrayUtils from '../../bgr/common/utils/ArrayUtils';
import BorderShape from '../geom/BorderShape';
import BorderComponentUtils from '../borders/BorderComponentUtils';
import HandleStyle from '../handles/HandleStyle';
import HandleStyles from '../handles/HandleStyles';
import HandleTypes from '../handles/HandleTypes';
import HandleType from '../handles/HandleType';
import HandleErrorType from '../handles/HandleErrorType';
import SampleCollector from '../sample/SampleCollector';
import QuiltCollector from '../quilt/QuiltCollector';
import SampleDA from '../sample/SampleDA';
import QuiltDA from '../quilt/QuiltDA';
import LegDA from './LegDA';
import BorderComponentDA from './BorderComponentDA';
import Utils from '../utils/Utils';
import Colors from '../colors/Colors';
// #if DEBUG
import BD3DLogger from '../logger/BD3DLogger';
import HandleDA from './HandleDA';
import HandleUtils from '../handles/HandleUtils';
// #endif

const TYPE_NUMBER = 'number';
const TYPE_UNDEFINED = 'undefined';

const DEFAULT_QUILT_FOAM_DEPTH = 1;
const DEFAULT_NOISE_SCALE = 0.01;
const DEFAULT_MATERIAL_TYPE = 'cotton';

const ANGLE_DEGREES = 'degs';
const ANGLE_RADIANS = 'rads';

const BORDER_INITIALIZERS = {
  piping(object) {
    if (typeof (object.height) !== 'number') {
      object.height = 0.3;
    }
  },
  tape(object) {
    if (typeof (object.height) !== 'number') {
      object.height = 0.3;
    }
  },
  gusset(object) {
    if (typeof (object.height) !== 'number') {
      object.height = 8;
    }
  },
  zipper(object) {
    if (typeof (object.height) !== 'number') {
      object.height = 1;
    }
  },
  border3d(object) {
    if (typeof (object.height) !== 'number') {
      object.height = 10;
    }
  },
  fabric(object) {
    if (typeof (object.height) !== 'number') {
      object.height = 10;
    }
  }
};

function getAllowHandleResult(errorObject) {
  const res = {};

  res.error = errorObject;
  if (errorObject) {
    res.result = false;
  } else {
    res.result = true;
  }

  return res;
}

function isNumber(value) {
  if (value === null) {
    return false;
  }
  if (typeof (value) === 'number' && !isNaN(value)) {
    return true;
  }

  return false;
}

function isNull(value) {
  if (value === null) {
    return true;
  }
  if (typeof (value) === 'undefined') {
    return true;
  }

  return false;
}

function getNumber(num, fb) {
  let nr = num;
  let t = typeof (nr);

  if (t === TYPE_NUMBER) {
    if (isNaN(num)) {
      return fb;
    }

    return num;
  }

  if (nr === null || t === TYPE_UNDEFINED) {
    return fb;
  }

  if (t === 'object') {
    nr = nr.valueOf();
    t = typeof (nr);
  }
  if (t === 'boolean') {
    return nr ? 1 : 0;
  }
  if (t === 'string') {
    nr = parseFloat(nr);
    t = typeof (nr);
  }
  if (t === TYPE_NUMBER) {
    if (isNaN(nr)) {
      return fb;
    }

    return nr;
  }

  return fb;
}


function fixRadians(rads, fallback) {
  let d = getNumber(rads, fallback);
  const ANGLE_360 = Math.PI * 2;

  d %= ANGLE_360;

  if (d < 0) {
    d += ANGLE_360;
  }

  return d;
}

function fixDegrees(degrees, fallback) {
  let d = getNumber(degrees, fallback);
  const ANGLE_360 = 360;

  d %= ANGLE_360;

  if (d < 0) {
    d += ANGLE_360;
  }

  return d;
}

function fixAngle(angle, unit, fallback) {
  if (unit === ANGLE_RADIANS) {
    return fixRadians(angle, fallback);
  }

  return fixDegrees(angle, fallback);
}

function getBoolean(bool, fb) {
  let b = bool;

  if (b === null) {
    return fb;
  }
  let t = typeof (b);

  if (t === 'undefined') {
    return fb;
  }
  if (t === 'object') {
    b = b.valueOf();
    t = typeof (b);
  }
  if (t === 'boolean') {
    return b;
  }
  if (t === 'string') {
    b = b.toLowerCase();

    return b === 'true' || b === '1';
  }
  if (t === 'number') {
    return b > 0;
  }

  return fb;
}

// setSingleProperty(single, ["box", "size", "width"], 120);

function setObjectProperty(object, property, value, createIfUndefined = true) {
  if (!object || property === null) {
    return;
  }

  const t = typeof (property);

  if (t === 'undefined') {
    return;
  }
  if (t === 'string') {
    if (property.indexOf('.', 0) >= 0) {
      setObjectProperty(object, property.split('.'), value, createIfUndefined);

      return;
    }
    object[property] = value;
  } else if (property instanceof Array) {
    const l = property.length;

    if (l === 0) {
      return;
    }
    let cur = object;

    for (let i = 0; i < l; ++i) {
      const prop = property[i];

      let newObj = cur[prop];

      if (createIfUndefined && (newObj === null || typeof (newObj) === 'undefined')) {
        const nextProp = property[i + 1];
        const nextPropType = typeof (nextProp);

        if (nextPropType === 'number') {
          newObj = [];
        } else if (nextPropType === 'string') {
          newObj = {};
        }
        cur[prop] = newObj;
      }
      if (newObj) {
        cur = newObj;
      } else {
        break;
      }
    }
  }
}
function getObjectProperty(object, property, fallback = null) {
  if (!object || property === null || typeof (property) === 'undefined') {
    return fallback;
  }
  const prop = property;

  if (typeof (prop) === 'string') {
    if (prop.indexOf('.', 0) >= 0) {
      return getObjectProperty(object, prop.split('.'), fallback);
    }
    if (typeof (object[prop]) === 'undefined') {
      return fallback;
    }

    return object[prop];
  } else if (prop instanceof Array) {
    const l = prop.length;

    if (l === 0) {
      return null;
    }
    let res = object;

    for (let i = 0; i < l; ++i) {
      const p = prop[i];

      if (typeof (p) === 'undefined' || p === null) {
        return fallback;
      }

      res = res[p];
      if (typeof (res) === 'undefined' || res === null) {
        return fallback;
      }
    }

    return res;
  }

  return fallback;
}

function getTimeMs() {
  if (Date.now) {
    return Date.now();
  }
  const d = new Date();

  return d.getTime();
}

let idCounter = 0;

function genID(index = -1) {
  const rng = 0x10000;
  const r1 = (Math.random() * rng) | 0;
  const r2 = (Math.random() * rng) | 0;
  const t = getTimeMs();
  const radix = 16;
  const sep = 'O';
  let c = index;

  if ((index === null) || (typeof (index) !== 'number') || isNaN(index) || (index < 0)) {
    c = idCounter;
    ++idCounter;
  }

  const res = r1.toString(radix) + sep + r2.toString(radix) + sep + t.toString(radix) + sep + c.toString(radix);

  return res;
}

export default class MattressDA {
  static getProperty(single, property, fallback = null) {
    getObjectProperty(single, property, fallback);
  }

  static setProperty(single, property, value) {
    setObjectProperty(single, property, value, true);
  }

  static getID(single, generate = true) {
    if (!single) {
      return null;
    }
    let id = single.id;

    if (generate && (typeof (id) === 'undefined' || id === null)) {
      id = single.id = genID();
    }

    return id;
  }

  static isSingle(single) {
    if (!single) {
      return false;
    }
    if (single.box && single.box.size) {
      return true;
    }

    return false;
  }

  static getSingleName(single) {
    if (!single) {
      return null;
    }

    return single.placeholder;
  }

  static setSingleName(single, value) {
    if (!single) {
      return;
    }

    single.placeholder = value;
  }

  static getSamples(single, params = null, array = null) {
    return SampleCollector.getSamplesBySingle(single, params, array);
  }

  static getQuilts(single, params = null, array = null) {
    return QuiltCollector.getSamplesBySingle(single, params, array);
  }

  // ////////////////////////////////////////////////////////////////
  //
  // Translation & Rotation
  //
  // ////////////////////////////////////////////////////////////////

  static getTranslationX(single) {
    const t = this._getTranslation(single, false);

    return this._getCoord(t, 'x', 0);
  }

  static setTranslationX(single, value) {
    const t = this._getTranslation(single, true);

    this._setCoord(t, 'x', value);
  }

  static getTranslationY(single) {
    const t = this._getTranslation(single, false);

    return this._getCoord(t, 'y', 0);
  }

  static setTranslationY(single, value) {
    const t = this._getTranslation(single, true);

    this._setCoord(t, 'y', value);
  }

  static getTranslationZ(single) {
    const t = this._getTranslation(single, false);

    return this._getCoord(t, 'z', 0);
  }

  static setTranslationZ(single, value) {
    const t = this._getTranslation(single, true);

    this._setCoord(t, 'z', value);
  }

  static setTranslation(single, x, y, z) {
    const t = this._getTranslation(single, true);

    if (!t) {
      return;
    }
    this._setCoord(t, 'x', x);
    this._setCoord(t, 'y', y);
    this._setCoord(t, 'z', z);
  }

  static getRotationX(single) {
    const t = this._getRotation(single, false);

    return this._getCoord(t, 'x', 0);
  }

  static setRotationX(single, value) {
    const t = this._getRotation(single, true);

    this._setCoord(t, 'x', value);
  }

  static getRotationY(single) {
    const t = this._getRotation(single, false);

    return this._getCoord(t, 'y', 0);
  }

  static setRotationY(single, value) {
    const t = this._getRotation(single, true);

    this._setCoord(t, 'y', value);
  }

  static getRotationZ(single) {
    const t = this._getRotation(single, false);

    return this._getCoord(t, 'z', 0);
  }

  static setRotationZ(single, value) {
    const t = this._getRotation(single, true);

    this._setCoord(t, 'z', value);
  }

  static setRotation(single, x, y, z) {
    const t = this._getRotation(single, true);

    if (!t) {
      return;
    }
    this._setCoord(t, 'x', x);
    this._setCoord(t, 'y', y);
    this._setCoord(t, 'z', z);
  }

  // Private methods
  static _getCoord(object, coord, fallback) {
    if (!object || !coord) {
      return fallback;
    }
    const res = getNumber(object[coord], fallback);

    if (isNumber(res)) {
      return res;
    }

    return fallback;
  }

  static _setCoord(object, coord, value) {
    if (!object || !coord) {
      return;
    }
    const v = getNumber(value);

    if (isNumber(v)) {
      object[coord] = v;
    }
  }

  static _getTranslation(single, create) {
    if (!single) {
      return null;
    }
    let res = single.translation;

    if (res || !create) {
      return res;
    }
    res = single.translation = {x: 0, y: 0, z: 0};

    return res;
  }

  static _getRotation(single, create) {
    if (!single) {
      return null;
    }
    let res = single.rotation;

    if (res || !create) {
      return res;
    }
    res = single.rotation = {x: 0, y: 0, z: 0};

    return res;
  }

  // ////////////////////////////////////////////////////////////////
  //
  // Box size
  //
  // ////////////////////////////////////////////////////////////////

  static getResultHeightWithLegs(single) {
    return this.getResultHeight(single) + this.getLegHeight(single);
  }

  static getResultHeight(single) {
    const borderH = this.getResultBorderHeight(single);
    let topH = this.getTopHeight(single);
    let btmH = this.getBottomHeight(single);

    topH = topH < 0 ? 0 : topH;
    btmH = btmH < 0 ? 0 : btmH;

    return topH + btmH + borderH;
  }
  // height = borderheight - topBorderRadius * PI/2 - bottomBorderRadius * PI/2 + topBorderRadius - bottomBorderRadius

  static getResultBorderHeight(single) {
    if (!single) {
      return 0;
    }
    // If no border components, use box.size.height
    if (!single.border || !single.border.components) {
      const box = single.box;

      if (!box) {
        return 0;
      }
      const size = box.size;

      if (!size) {
        return 0;
      }
      const h = size.height;

      if (typeof (h) === 'number' && !isNaN(h)) {
        return h;
      }

      return 0;
    }
    let bs = this._tempBorderShape;

    if (!bs) {
      bs = this._tempBorderShape = new BorderShape();
    }
    const topBorderRadius = this.getResultTopBorderRadius(single);
    const bottomBorderRadius = this.getResultBottomBorderRadius(single);
    const borderLength = this.getTotalBorderComponentHeight(single);
    const topHeight = this.getTopHeight(single, 0);
    const bottomHeight = this.getBottomHeight(single, 0);

    bs.setData(topBorderRadius, bottomBorderRadius, borderLength, topHeight, bottomHeight, 0);

    return bs.getBorderHeight();
  }

  static getHeight(single) {
    const size = this._getSizeObject(single, false);

    if (!size) {
      return 0;
    }

    return size.height;
  }

  static setHeight(single, value) {
    const size = this._getSizeObject(single, true);

    if (!size) {
      return;
    }

    size.height = getNumber(value, 0);
  }

  static getWidth(single) {
    const size = this._getSizeObject(single, false);

    if (!size) {
      return 0;
    }

    return size.width;
  }

  static setWidth(single, value) {
    const size = this._getSizeObject(single, true);

    if (!size) {
      return;
    }

    size.width = getNumber(value, 0);
  }

  static getLength(single) {
    const size = this._getSizeObject(single, false);

    if (!size) {
      return 0;
    }

    return size.length;
  }

  static setLength(single, value) {
    const size = this._getSizeObject(single, true);

    if (!size) {
      return;
    }

    size.length = getNumber(value, 0);
  }

  static getSize(single, key, fallback) {
    if (!key) {
      return fallback;
    }
    const size = this._getSizeObject(single, false);

    if (!size) {
      return fallback;
    }
    const res = size[key];

    if (res === null || typeof (res) === 'undefined') {
      return fallback;
    }

    return res;
  }

  static setSize(single, key, value) {
    if (!key) {
      return;
    }

    const size = this._getSizeObject(single, true);

    if (!size) {
      return;
    }

    size[key] = value;
  }

  static getCornerRadius(single) {
    const radius = this._getRadius(single, false);

    if (!radius) {
      return 0;
    }

    return getNumber(radius.corners, 0);
  }

  static setCornerRadius(single, value, safe = true) {
    const radius = this._getRadius(single, true);

    if (!radius) {
      return;
    }
    let v = value;

    if (safe) {
      v = this.calcResultCornerRadius(v, single);
    }

    radius.corners = getNumber(v, 0);
  }

  static getMaxCornerRadius(single) {
    if (!single) {
      return 0;
    }
    const w = this.getWidth(single);
    const l = this.getLength(single);
    const s = w < l ? w : l;

    return s * 0.5;
  }

  static getMaxBorderRadius(single) {
    if (!single) {
      return 0;
    }

    return this.getResultCornerRadius(single);
  }

  static getBorderInfo(single, create = true) {
    if (!single) {
      return null;
    }
    let border = single.border;

    if (!border && create) {
      border = single.border = {};
    }

    return border;
  }

  static _getBorderComponents(single, create = true) {
    if (!single) {
      return null;
    }
    const borderInfo = this.getBorderInfo(single, create);

    if (!borderInfo) {
      return null;
    }
    let comps = borderInfo.components;

    if (!comps && create) {
      comps = borderInfo.components = [];
    }

    return comps;
  }

  static getBorderComponent(single, key) {
    const keyType = typeof key;

    if (keyType === 'undefined' || key === null) {
      return null;
    }
    if (keyType === 'object') {
      return key;
    }
    if (!single) {
      return null;
    }
    const comps = this._getBorderComponents(single, false);

    if (!comps) {
      return null;
    }
    if (keyType === 'number') {
      return comps[key];
    } else if (keyType === 'string') {
      const num = comps.length;

      for (let i = 0; i < num; ++i) {
        const bc = comps[i];
        let bcID = bc['@id'];

        if (typeof (bcID) === 'undefined') {
          bcID = bc['@ID'];
        }
        if (typeof (bcID) === 'undefined') {
          bcID = bc.id;
        }

        if (typeof (bcID) === 'number') {
          bcID = `${bcID}`;
        }

        if (bcID === key) {
          return bc;
        }
      }
    }

    return null;
  }

  static getBorderComponentIndex(single, border) {
    const borderComps = this._getBorderComponents(single, false);

    if (!borderComps) {
      return -1;
    }
    if (typeof (border) === 'number') {
      if (border < 0) {
        return -1;
      }
      if (border >= borderComps.length) {
        return -1;
      }

      return border;
    }

    if (!borderComps) {
      return -1;
    }
    const b = this.getBorderComponent(single, border);

    if (b) {
      if (borderComps.indexOf) {
        return borderComps.indexOf(b);
      }
      const num = borderComps.length;

      for (let i = 0; i < num; ++i) {
        if (borderComps[i] === b) {
          return i;
        }
      }

      return -1;
    }

    return -1;
  }

  static setBorderComponentIndex(single, border, index) {
    if (index < 0) {
      return;
    }
    const oldIndex = this.getBorderComponentIndex(single, border);

    if (oldIndex === index) {
      return;
    }
    const borderComps = this._getBorderComponents(single, true);
    const borderComp = borderComps[oldIndex];

    if (oldIndex >= 0) {
      borderComps.splice(oldIndex, 1);
    }
    if (index >= 0) {
      borderComps.splice(index, 0, borderComp);
    }
  }

  // Returns 1 if a border property can be changed, 0 if not, -1 if not defined
  static _editableBorderPropertyValue(single, property, border = null) {
    if (!property) {
      return -1;
    }
    const borderComponent = this.getBorderComponent(single, border);

    if (border !== null && typeof (border) !== 'undefined' && !borderComponent) {
      // border is defined as param, but not found in single
      return 0;
    }
    let editableProperties = borderComponent ? borderComponent.editableProperties : null;

    if (editableProperties) {
      const res = editableProperties[property];

      if (res !== null && typeof (res) !== 'undefined') {
        return res ? 1 : 0;
      }
    }

    if (!editableProperties) {
      const borderInfo = this.getBorderInfo(single, false);

      editableProperties = borderInfo && borderInfo.editableProperties;
    }
    if (editableProperties) {
      const res = editableProperties[property];

      if (res !== null && typeof (res) !== 'undefined') {
        return res ? 1 : 0;
      }
    }

    return -1;
  }

  static hasEditableBorderPropertyDefined(single, property, border = null) {
    return this._editableBorderPropertyValue(single, property, border) >= 0;
  }

  static canChangeBorderComponentProperty(single, border, property, fallbackValue = true) {
    const value = this._editableBorderPropertyValue(single, property, border);

    if (value >= 0) {
      return value > 0;
    }

    return fallbackValue;
  }

  static canChangeBorderComponentHeight(single, border, fallbackValue = true) {
    return this.canChangeBorderComponentProperty(single, border, 'height', fallbackValue);
  }

  static singleHasAvailableBorderComponentTypes(single) {
    const border = this.getBorderInfo(single, false);
    const availableTypes = (border && border.availableTypes);

    return availableTypes !== null && typeof (availableTypes) !== 'undefined';
  }

  static getAvailableBorderComponentTypes(singleOrBorderComponentTypeArray) {
    if (ArrayUtils.isArray(singleOrBorderComponentTypeArray)) {
      return this._filterAvailableBorderComponentTypes(singleOrBorderComponentTypeArray);
    }
    if (!singleOrBorderComponentTypeArray) {
      return [];
    }
    const border = this.getBorderInfo(singleOrBorderComponentTypeArray, false);

    if (border) {
      const available = BorderComponentDA.filterAvailableBorderComponentTypes(border.availableTypes);

      if (available) {
        return available;
      }
    }

    return BorderComponentDA.getAvailableBorderComponents();
  }

  static get availableBorderComponentTypes() {
    return this.getAvailableBorderComponentTypes(null);
  }

  static getBorderComponents(single) {
    return this._getBorderComponents(single, false);
  }

  static removeBorderComponent(single, borderComponent) {
    const index = this.getBorderComponentIndex(single, borderComponent);
    const components = this._getBorderComponents(single, false);

    if (!components || index < 0) {
      return false;
    }
    components.splice(index, 1);

    return true;
  }

  static addBorderComponent(single, type, index = -1) {
    if (!single) {
      return null;
    }
    const typeType = typeof (type);

    if (typeType === 'undefined' || type === null) {
      return null;
    }
    let border = single.border;

    if (!border) {
      border = single.border = {};
    }
    let comps = border.components;

    if (!comps) {
      comps = border.components = {};
    }
    let typeObj = null;
    let typeName = null;

    if (typeType === 'string') {
      typeName = type;
      typeObj = {type};
    } else if (typeType === 'object') {
      typeObj = type;
      typeName = typeObj.type;
    }
    const initializer = typeName ? BORDER_INITIALIZERS[typeName] : null;

    if (initializer) {
      initializer(typeObj);
    }

    if (!typeObj) {
      return null;
    }
    if (index < 0 || typeof (index) < 0 || isNaN(index)) {
      comps.push(typeObj);
    } else {
      comps.splice(index, 0, typeObj);
    }

    return typeObj;
  }

  static getBorderComponentHeight(single, borderComponent, fallback) {
    const bc = this.getBorderComponent(single, borderComponent);

    if (!bc) {
      return fallback;
    }
    const h = bc.height;

    if (typeof (h) !== 'number' || isNaN(h)) {
      return fallback;
    }

    return h;
  }

  static getBorderComponentName(single, borderComponent) {
    const bc = this.getBorderComponent(single, borderComponent);

    if (!bc) {
      return null;
    }

    return bc.placeholder;
  }

  static setBorderComponentName(single, borderComponent, value) {
    const bc = this.getBorderComponent(single, borderComponent);

    if (!bc) {
      return;
    }
    bc.placeholder = value;
  }

  static setBorderComponentHeight(single, borderComponent, value) {
    const bc = this.getBorderComponent(single, borderComponent);

    if (!bc) {
      return;
    }
    bc.height = value;
  }

  static getBorderComponentDepth(single, borderComponent, fallback) {
    const bc = this.getBorderComponent(single, borderComponent);

    if (!bc) {
      return fallback;
    }
    const d = bc.depth;

    if (typeof (d) !== 'number' || isNaN(d)) {
      return fallback;
    }

    return d;
  }

  static setBorderComponentDepth(single, borderComponent, value) {
    const bc = this.getBorderComponent(single, borderComponent);

    if (!bc) {
      return;
    }
    bc.depth = value;
  }

  static getBorderComponentTextureData(single, borderComponent, create = false) {
    const bc = this.getBorderComponent(single, borderComponent);

    if (!bc) {
      return null;
    }
    let tex = bc.texture;

    if (tex || !create) {
      return tex;
    }
    tex = bc.texture = {};

    return tex;
  }

  static getBorderComponentTextureOffset(single, borderComponent, create = false) {
    const texData = this.getBorderComponentTextureData(single, borderComponent, create);

    if (!texData) {
      return null;
    }

    let off = texData.offset;

    if (off || !create) {
      return off;
    }
    off = texData.offset = {x: 0, y: 0};

    return off;
  }

  static _getBorderComponentTextureOffsetValue(single, borderComponent, key, create, fallback) {
    if (!key) {
      return fallback;
    }
    const off = this.getBorderComponentTextureOffset(single, borderComponent, create);

    if (!off) {
      return fallback;
    }
    let offVal = off[key];

    if (typeof (offVal) === 'string') {
      offVal = parseFloat(offVal);
    }
    if (typeof (offVal) === 'number' && !isNaN(offVal)) {
      return offVal;
    }

    return fallback;
  }

  static getBorderComponentTextureOffsetX(single, borderComponent, fallback = 0) {
    return this._getBorderComponentTextureOffsetValue(single, borderComponent, 'x', false, fallback);
  }

  static getBorderComponentTextureOffsetY(single, borderComponent, fallback = 0) {
    return this._getBorderComponentTextureOffsetValue(single, borderComponent, 'y', false, fallback);
  }

  /**
  * @method getBorderComponentTextureRotation
  * @static
  * @description returns the texture rotation of a border component in degrees
  * @param {Object} single - single object
  * @param {String|int} borderComponent - border component (string as id or index as number)
  * @param {SampleService} sampleService - sample service
  * @param {Number} fallback - default value if not set
  * @return {Number} - rotation in degrees
  * */
  static getBorderComponentTextureRotation(single, borderComponent, sampleService, fallback = 0) {
    return this._getBorderComponentTextureRotation(single, borderComponent, ANGLE_DEGREES, sampleService, fallback);
  }

  /**
   * @method setBorderComponentTextureRotation
   * @static
   * @description sets the texture rotation of a border component in degrees
   * @param {Object} single - single object
   * @param {String|int} borderComponent - border component (string as id or index as number)
   * @param {Number} angle - rotation in degrees
   * @return {void}
   * */
  static setBorderComponentTextureRotation(single, borderComponent, angle) {
    this._setBorderComponentTextureRotation(single, borderComponent, angle, ANGLE_DEGREES);
  }

  /**
  * @method getBorderComponentTextureRotationRads
  * @static
  * @description returns the texture rotation of a border component in radians
  * @param {Object} single - single object
  * @param {String|int} borderComponent - border component (string as id or index as number)
  * @param {SampleService} sampleService - sample service
  * @param {Number} fallback - default value if not set
  * @return {Number} - rotation in radians
  * */
  static getBorderComponentTextureRotationRads(single, borderComponent, sampleService, fallback = 0) {
    return this._getBorderComponentTextureRotation(single, borderComponent, ANGLE_RADIANS, sampleService, fallback);
  }

  /**
   * @method setBorderComponentTextureRotationRads
   * @static
   * @description sets the texture rotation of a border component in radians
   * @param {Object} single - single object
   * @param {String|int} borderComponent - border component (string as id or index as number)
   * @param {Number} angle - rotation in radians
   * @return {void}
   * */
  static setBorderComponentTextureRotationRads(single, borderComponent, angle) {
    this._setBorderComponentTextureRotation(single, borderComponent, angle, ANGLE_RADIANS);
  }

  /**
  * @method _getBorderComponentTextureRotation
  * @static
  * @private
  * @description returns the texture rotation of a border component in radians or degrees
  * @param {Object} single - single object
  * @param {String|int} borderComponent - border component (string as id or index as number)
  * @param {String} unit - degs / rads
  * @param {SampleService} sampleService - sample service
  * @param {Number} fallback - default value if not set
  * @return {Number} - rotation in radians or degrees
  * */
  static _getBorderComponentTextureRotation(single, borderComponent, unit, sampleService, fallback = 0) {
    const bc = this.getBorderComponent(single, borderComponent);

    if (!bc) {
      return fallback;
    }
    const texData = this.getBorderComponentTextureData(single, borderComponent, true);

    if (!texData) {
      return fallback;
    }
    const m = unit === ANGLE_RADIANS ? 180 / Math.PI : 1;

    return this._getSampleRotation(texData, sampleService, fallback) * m;
    // return fixDegrees(texData.rotation, fallback) * m;
  }
  /**
  * @method _setBorderComponentTextureRotation
  * @static
  * @private
  * @description sets the texture rotation of a border component in radians or degrees
  * @param {Object} single - single object
  * @param {String|int} borderComponent - border component (string as id or index as number)
  * @param {Number} angle - rotation in radians or degrees
  * @param {String} unit - rads / degs
  * @return {void}
  * */
  static _setBorderComponentTextureRotation(single, borderComponent, angle, unit) {
    const bc = this.getBorderComponent(single, borderComponent);

    if (!bc) {
      return;
    }
    const texData = this.getBorderComponentTextureData(single, borderComponent, true);

    if (!texData) {
      return;
    }
    let v = fixAngle(angle, unit, 0);

    if (unit === ANGLE_RADIANS) {
      v = fixDegrees(v * 180 / Math.PI, 0);
    }
    texData.rotation = v;
  }

  static setBorderComponentTextureOffset(single, borderComponent, x, y) {
    let X = x;
    let Y = y;

    if (x !== null && typeof (x) === 'object') {
      if (typeof (x.x) === 'number' && !isNaN(x.x)) {
        X = x.x;
      }
      if (typeof (x.y) === 'number' && !isNaN(x.y)) {
        Y = x.y;
      }
    }
    if (typeof (X) === 'string') {
      X = parseFloat(X);
    }
    if (typeof (Y) === 'string') {
      Y = parseFloat(Y);
    }
    let offObj = null;

    if (X !== null && typeof (X) === 'number' && !isNaN(X)) {
      offObj = this.getBorderComponentTextureOffset(single, borderComponent, true);
      if (offObj) {
        offObj.x = X;
      }
    }
    if (Y !== null && typeof (Y) === 'number' && !isNaN(Y)) {
      if (!offObj) {
        offObj = this.getBorderComponentTextureOffset(single, borderComponent, true);
      }
      if (offObj) {
        offObj.y = Y;
      }
    }
  }

  static setBorderComponentTextureOffsetX(single, borderComponent, x) {
    this.setBorderComponentTextureOffset(single, borderComponent, x, null);
  }
  static setBorderComponentTextureOffsetY(single, borderComponent, y) {
    this.setBorderComponentTextureOffset(single, borderComponent, null, y);
  }

  static setBorderComponentTextureAlign(single, borderComponent, x, y) {
    const bc = this.getBorderComponent(single, borderComponent);

    if (!bc) {
      return;
    }

    if (!bc.texture) {
      bc.texture = {};
    }

    SampleDA.setAlign(bc.texture, x, y);
  }

  static _getSampleFromService(id, svc) {
    if (!svc) {
      return null;
    }
    let sampleid = id;

    if (typeof (id) === 'object') {
      if (!id) {
        return null;
      }
      sampleid = id.id;
    }
    if (typeof (sampleid) !== 'number' && !sampleid) {
      return null;
    }

    return svc.getSampleById(sampleid);
  }

  static _getSampleAlignX(tex, svc, fallback) {
    return SampleDA.getAlignX(tex, this._getSampleFromService(tex, svc), fallback);
  }

  static _getSampleAlignY(tex, svc, fallback) {
    return SampleDA.getAlignY(tex, this._getSampleFromService(tex, svc), fallback);
  }

  static getBorderComponentTextureAlignX(single, borderComponent, sampleSvc, fallback = 0.5) {
    const bc = this.getBorderComponent(single, borderComponent);

    if (!bc) {
      return fallback;
    }

    // return SampleDA.getAlignX(bc.texture, null, fallback);
    return this._getSampleAlignX(bc.texture, sampleSvc, fallback);
  }

  static setBorderComponentTextureAlignX(single, borderComponent, x) {
    this.setBorderComponentTextureAlign(single, borderComponent, x, null);
  }


  static getBorderComponentTextureAlignY(single, borderComponent, sampleSvc, fallback = 0.5) {
    const bc = this.getBorderComponent(single, borderComponent);

    if (!bc) {
      return fallback;
    }

    // return SampleDA.getAlignY(bc.texture, null, fallback);

    return this._getSampleAlignY(bc.texture, sampleSvc, fallback);
  }

  static setBorderComponentTextureAlignY(single, borderComponent, y) {
    this.setBorderComponentTextureAlign(single, borderComponent, null, y);
  }

  static getBorderComponentSample(single, borderComponent) {
    const bc = this.getBorderComponent(single, borderComponent);

    if (!bc) {
      return null;
    }
    const type = bc.type;

    if (type === 'piping' || type === 'border3d' || type === 'zipper') {
      return null;
    }

    return bc.texture;
  }

  static borderComponentCanHaveSample(single, borderComponent) {
    const bc = this.getBorderComponent(single, borderComponent);

    if (!bc) {
      return false;
    }
    const type = bc.type;

    return (type && type !== 'piping' && type !== 'border3d' && type !== 'zipper');
  }

  static setBorderComponentSample(single, borderComponent, sample) {
    const bc = this.getBorderComponent(single, borderComponent);

    if (!bc) {
      return;
    }
    if (!this.borderComponentCanHaveSample(single, borderComponent)) {
      // #if DEBUG
      const type = bc.type;

      BD3DLogger.warn(`Can\'t set sample to border component with type=${type}`);
      // #endif

      return;
    }

    const s = sample;
    const t = typeof (s);

    bc.color = null;

    if (t !== 'object') {
      if (!bc.texture) {
        bc.texture = {};
      }
      this._resetTexture(bc.texture);
      bc.texture.id = s;

      return;
    }

    bc.texture = s;
  }

  static getBorderComponentSampleID(single, borderComponent, fallback) {
    const smp = this.getBorderComponentSample(single, borderComponent, fallback);

    if (!smp) {
      return fallback;
    }
    if (smp.id === null || typeof (smp.id) === 'undefined') {
      return fallback;
    }

    return smp.id;
  }

  static setBorderComponentSampleID(single, borderComponent, id) {
    this.setBorderComponentSample(single, borderComponent, id);
  }

  static getDefaultMaterialType() {
    return DEFAULT_MATERIAL_TYPE;
  }

  static getComponentColor(component) {
    if (!component) {
      return null;
    }

    return component.color;
  }

  // General way to apply a color to a component
  // (also clears the sample)
  static setComponentColor(component, color) {
    if (component.texture) {
      component.texture = null;
    }

    if (component.texture) {
      component.texture = null;
    }

    component.color = color;
  }

  static getComponentSampleID(component) {
    if (!component) {
      return null;
    }
    const {texture} = component;

    if (!texture) {
      return null;
    }

    return texture.id;
  }

  // General way to apply a sample to a component
  // (also clears color)
  static setComponentSample(component, sample) {
    if (!component) {
      return;
    }
    const s = sample;
    const t = typeof (s);

    component.color = null;

    if (t !== 'object') {
      if (!component.texture) {
        component.texture = {};
      }
      this._resetTexture(component.texture);
      component.texture.id = s;

      return;
    }

    component.texture = s;
  }

  static getBorderComponentMaterialType(single, borderComponent, fallback) {
    const bc = this.getBorderComponent(single, borderComponent);

    if (!bc) {
      return fallback || DEFAULT_MATERIAL_TYPE;
    }
    if (!bc.material) {
      return fallback || DEFAULT_MATERIAL_TYPE;
    }

    return bc.material;
  }

  static setBorderComponentMaterialType(single, borderComponent, materialType) {
    const bc = this.getBorderComponent(single, borderComponent);

    if (!bc) {
      return;
    }
    bc.material = materialType;
  }

  static getBorderComponentColorId(single, borderComponent, fallback) {
    const colorData = this.getBorderComponentColorData(single, borderComponent);

    if (!colorData) {
      return null;
    }
    if (typeof (colorData) === 'string') {
      return colorData;
    }

    return colorData.id;
  }

  static getBorderComponentColorData(single, borderComponent, fallback) {
    const col = this.getBorderComponentColor(single, borderComponent, null);

    if (!col) {
      return null;
    }
    if (typeof (col) === 'string') {
      const bc = this.getBorderComponent(single, borderComponent);

      if (!bc) {
        return null;
      }

      return Colors.getColorByComponentTypeMaterialAndId(bc.type, bc.material, col);
    }

    return null;
  }
  static getBorderComponentColor(single, borderComponent, fallback) {
    const bc = this.getBorderComponent(single, borderComponent);

    if (!bc) {
      return fallback;
    }
    const type = bc.type;

    if (type === 'fabric') {
      return null;
    }
    if (type === 'tape') {
      if (bc.texture && bc.texture.id) {
        return fallback;
      }
    }

    return bc.color;
  }

  static setBorderComponentColor(single, borderComponent, value) {
    const bc = this.getBorderComponent(single, borderComponent);

    if (!bc) {
      return;
    }

    const type = bc.type;

    if (type === 'fabric') {
      // #if DEBUG
      BD3DLogger.warn(`Can\'t set the color of a ${type} border component`);
      // #endif

      return;
    }
    if (bc.texture) {
      bc.texture = null;
    }

    bc.color = value;
  }

  static getBorderComponentZipperType(single, borderComponent, fallback) {
    const bc = this.getBorderComponent(single, borderComponent);

    if (!bc) {
      return fallback;
    }
    const type = bc.type;

    if (type !== 'zipper') {
      return fallback;
    }

    return bc.zipperType;
  }

  static setBorderComponentZipperType(single, borderComponent, value) {
    const bc = this.getBorderComponent(single, borderComponent);

    if (!bc) {
      return;
    }
    const type = bc.type;

    if (type !== 'zipper') {
      // #if DEBUG
      BD3DLogger.warn(`Can't set zipper type on a ${type} border component`);
      // #endif

      return;
    }

    if (value !== null && typeof (value) !== 'undefined') {
      if (value !== 'teeth' && value !== 'spiral_coil' && value !== 'reverse_coil') {
        // #if DEBUG
        BD3DLogger.warn('Invalid zipper type. Allowed values: teeth, spiral_coil and reverse_coil');
        // #endif

        return;
      }
    }

    bc.zipperType = value;
  }

  static getBorderRibbon(single, border) {
    return this._getBorderRibbon(single, border, false);
  }

  static setBorderRibbon(single, borderComponent, ribbon) {
    const bc = this.getBorderComponent(single, borderComponent);

    if (!bc) {
      return;
    }
    if (ribbon === null) {
      if (bc.ribbon !== null && typeof (bc.ribbon) !== 'undefined') {
        bc.ribbon = null;
      }

      return;
    }
    const allowed = (bc.type === 'fabric');

    if (allowed) {
      bc.ribbon = ribbon;
    }

    // #if DEBUG
    if (!allowed) {
      BD3DLogger.warn('Cannot set ribbon on this border component:', bc);
    }
    // #endif
  }

  static isBorderRibbonEnabled(single, borderComponent) {
    const r = this._getBorderRibbon(single, borderComponent, false);

    if (!r) {
      return false;
    }

    return r.enabled !== false;
  }

  static setBorderRibbonEnabled(single, borderComponent, enabled) {
    const e = enabled !== false;
    const r = this._getBorderRibbon(single, borderComponent, e);

    if (!r) {
      return;
    }
    r.enabled = e;
  }

  static getBorderRibbonColor(single, borderComponent) {
    const r = this._getBorderRibbon(single, borderComponent, false);

    if (!r) {
      return null;
    }

    return r.color;
  }

  static setBorderRibbonColor(single, borderComponent, value) {
    const r = this._getBorderRibbon(single, borderComponent, true);

    if (!r) {
      return;
    }
    r.color = value;
  }

  static getBorderRibbonMaterial(single, borderComponent) {
    const r = this._getBorderRibbon(single, borderComponent, false);

    if (!r) {
      return DEFAULT_MATERIAL_TYPE;
    }

    return r.material || DEFAULT_MATERIAL_TYPE;
  }

  static setBorderRibbonMaterial(single, borderComponent, value) {
    const r = this._getBorderRibbon(single, borderComponent, true);

    if (!r) {
      return;
    }
    const validValue = (value === null || value === 'satin' || value === 'cotton');

    if (validValue) {
      r.material = value;
    }

    // #if DEBUG
    if (!validValue) {
      BD3DLogger.warn('Could not set ribbon material: ', value, ' (Allowed values: \'satin\', \'cotton\' or null)');
    }
    // #endif
  }

  static _getBorderRibbon(single, borderComponent, create) {
    const bc = this.getBorderComponent(single, borderComponent);

    if (!bc) {
      return null;
    }
    let ribbon = bc.ribbon;

    if (ribbon || !create) {
      return ribbon;
    }
    if (bc.type !== 'fabric') {
      // #if DEBUG
      BD3DLogger.warn('Cannot create a ribbon for this border component:', bc, '(Border component should be fabric)');
      // #endif

      return null;
    }
    ribbon = bc.ribbon = {};

    return ribbon;
  }

  static getResultCornerRadius(single) {
    const cr = this.getCornerRadius(single);

    return this.calcResultCornerRadius(cr, single);
  }

  static calcResultCornerRadius(cornerR, single) {
    let cr = cornerR;
    const maxCR = this.getMaxCornerRadius(single);
    const d = 0.0001;

    cr = cr < maxCR ? cr : (maxCR - d);
    cr = cr < 0 ? 0 : cr;

    return cr;
  }

  static getTopData(single, create) {
    let top = single.top;

    if (!create) {
      return top;
    }

    if (!top) {
      top = single.top = {};
    }
    if (!top.texture) {
      top.texture = Utils.deepCopy(single.texture);
    }
    if (!top.quilt) {
      top.quilt = Utils.deepCopy(single.quilt);
    }

    return top;
  }

  static getBottomData(single, create) {
    let bottom = single.bottom;

    if (!create) {
      return bottom;
    }

    if (!bottom) {
      bottom = single.bottom = {};
    }
    if (!bottom.texture) {
      bottom.texture = Utils.deepCopy(single.texture);
    }
    if (!bottom.quilt) {
      bottom.quilt = Utils.deepCopy(single.quilt);
    }

    return bottom;
  }

  /*
   * @method _getSingleQuiltData
   * @private
   * @description Returns the data of a quilt pattern set on the top or bottom panel
   * @param {Object} single - Single object
   * @param {String} key - top, bottom or null (null == general/legacy/fallback data)
   * @param {boolean} create - should create the quilt data if null
   * @return {Object} quiltData
   *
  static _getSingleQuiltData(single, key, create = false) {
    if (!single) {
      // #if DEBUG
      BD3DLogger.warn('_getQuiltData - Invalid single: ', single);
      // #endif

      return null;
    }
    let quiltData = null;
    let singlePartData = null;

    if (key) {
      singlePartData = single[key];
      if (!singlePartData && create) {
        singlePartData = single[key] = {};
      }
      if (singlePartData) {
        quiltData = singlePartData.quilt;
        if (!quiltData && create) {
          quiltData = singlePartData.quilt = {};
        }
      }

      return quiltData;
    }

    quiltData = single.quilt;

    if (!quiltData && create) {
      quiltData = single.quilt = {};
    }

    return quiltData;
  }
  */

  /**
   * @method getQuiltID
   * @description Returns the id of the quiltData
   * @param {Object} quiltData - quilt data object
   * @return {String} - the id of the quilt or null if not found
   * */
  static getQuiltID(quiltData) {
    return QuiltDA.getID(quiltData);
  }
  /*
  static getQuiltIDBySingle(single, key) {
    let quiltData = this._getSingleQuiltData(single, key, false);
    let res = null;

    if (quiltData) {
      res = this.getQuiltID(quiltData);
      if (res) {
        return res;
      }
    }
    quiltData = this._getSingleQuiltData(single, null, false);

    res = this.getQuiltID(quiltData);

    return res;
  }
  */

  static setSample(single, sample) {
    if (!single) {
      return;
    }
    this.setTopSample(single, sample);
    this.setBottomSample(single, sample);

    const border = single ? single.border : null;
    const borderComps = border ? border.components : null;
    const numComps = borderComps ? borderComps.length : 0;

    for (let i = 0; i < numComps; ++i) {
      if (this.borderComponentCanHaveSample(single, i)) {
        this.setBorderComponentSample(single, i, sample);
      }
    }
  }

  static getSampleID(sample) {
    return SampleDA.getID(sample);
  }

  static _clearTextureTransform(texData) {
    if (!texData) {
      return;
    }
    texData.offset = null;
    texData.rotation = null;
  }

  static _resetTexture(texData) {
    if (texData.size) {
      texData.size = null;
    }
    if (texData.offset) {
      texData.offset = null;
    }
    if (typeof (texData.rotation) === 'number') {
      texData.rotation = null;
    }
    const img = texData.img;

    if (img && img.size) {
      img.size = null;
    }
  }

  static resetTextureData(texData) {
    if (!texData) {
      return;
    }
    this._resetTexture(texData);
  }

  static resetQuiltData(quiltData) {
    if (!quiltData) {
      return;
    }
    if (quiltData.repeat) {
      quiltData.repeat = null;
    }
    if (quiltData.align) {
      quiltData.align = null;
    }
    if (quiltData.blur) {
      quiltData.blur = null;
    }
    if (quiltData.foam) {
      quiltData.foam = null;
    }
    // also use resetTexture internally (same properties to reset)
    this._resetTexture(quiltData);
  }

  static _getTopOrBottom(single, key, create = false, useSingleAsFallback = true) {
    if (!single || !key) {
      return null;
    }
    let res = single[key];

    if (res) {
      return res;
    }

    if (!res && create) {
      res = single[key] = {};

      return res;
    }

    if (!res && useSingleAsFallback) {
      return single;
    }

    return res;
  }
  static _getFabricData(single, key, create = false, useFallback = true) {
    if (!single) {
      return null;
    }

    const partData = this._getTopOrBottom(single, key, create, useFallback);

    if (!partData) {
      return null;
    }

    let texData = partData.texture;

    if (!texData) {
      if (useFallback) {
        return single.texture;
      }
      if (!create) {
        return null;
      }
    }

    if (texData || !create) {
      return texData;
    }
    if (!useFallback) {
      texData = partData.texture = {};
    }

    return texData;
  }

  static _getFabricOffset(single, key, create = false, useFallback = true) {
    const texData = this._getFabricData(single, key, create, useFallback);

    if (!texData) {
      return null;
    }

    let off = texData.offset;

    if (!off && create) {
      off = texData.offset = {x: 0, y: 0};
    }

    return off;
  }

  static _getFabricOffsetValue(single, part, property, fallback) {
    if (!property) {
      return fallback;
    }
    const off = this._getFabricOffset(single, part, false, true);

    if (!off) {
      return fallback;
    }

    return getNumber(off[property], fallback);
  }

  static _setFabricOffsetValue(single, part, property, value) {
    if (!property) {
      return;
    }
    const off = this._getFabricOffset(single, part, true, false);

    if (!off) {
      return;
    }

    off[property] = getNumber(value, 0);
  }

  static getTopSample(single, fallback) {
    const texData = this._getFabricData(single, 'top', false, true);

    if (!texData) {
      return fallback;
    }
    if (texData.id) {
      return texData.id;
    }

    return fallback;
  }

  static setTopSample(single, value) {
    const texData = this._getFabricData(single, 'top', true, false);

    if (!texData) {
      return;
    }

    this._resetTexture(texData);

    texData.id = value;
  }

  static getBottomMirrorPanelSampleID(single) {
    const mp = this._getMirrorPanel(single, 'bottom');

    if (!mp) {
      return null;
    }
    const tex = mp.texture;
    const t = typeof (tex);

    if (tex === null || t === 'undefined') {
      return null;
    }
    if (t === 'string' || t === 'number') {
      return tex;
    }

    return tex.id;
  }

  static setBottomMirrorPanelSampleID(single, sampleID) {
    let mp = this._getMirrorPanel(single, 'bottom');

    if (!mp) {
      mp = {};
      this._setMirrorPanel(single, 'bottom', mp);
    }
    let tex = mp.texture;

    if (tex && typeof (tex) === 'object') {
      tex.id = sampleID;

      return;
    }
    if (!tex) {
      tex = mp.texture = {};
    }
    this._resetTexture(tex);

    tex.id = sampleID;
  }

  static isBottomMirrorPanelEnabled(single, b) {
    return this._isMirrorPanelEnabled(single, 'bottom');
  }

  static setBottomMirrorPanelEnabled(single, b) {
    this._setMirrorPanel(single, 'bottom', getBoolean(b, true));
  }

  static getBottomMirrorPanel(single, mirrorPanel) {
    this._getMirrorPanel(single, 'bottom');
  }

  static setBottomMirrorPanel(single, mirrorPanel) {
    this._setMirrorPanel(single, 'bottom', mirrorPanel);
  }

  static setTopMirrorPanel(single, mirrorPanel) {
    this._setMirrorPanel(single, 'top', mirrorPanel);
  }

  static setTopMirrorPanelSampleID(single, sampleID) {
    let mp = this._getMirrorPanel(single, 'top');

    if (!mp) {
      mp = {};
      this._setMirrorPanel(single, 'top', mp);
    }
    let tex = mp.texture;

    if (tex && typeof (tex) === 'object') {
      this._resetTexture(tex);
      tex.id = sampleID;

      return;
    }
    if (!tex) {
      tex = mp.texture = {};
    }
    this._resetTexture(tex);

    tex.id = sampleID;
  }

  static _isMirrorPanelEnabled(single, key) {
    const mp = this._getMirrorPanel(single, key);

    if (!mp) {
      return false;
    }

    return getBoolean(mp.enabled, true);
  }

  static _getMirrorPanel(single, key) {
    if (!single) {
      return false;
    }
    const part = single[key];

    if (!part) {
      return false;
    }

    return part.mirrorPanel;
  }

  static _setMirrorPanel(single, key, mirrorPanel) {
    if (!single) {
      return;
    }
    let mp = mirrorPanel;
    let part = single[key];

    if (!part) {
      part = single[key] = {};
    }
    const t = typeof (mp);

    if (t === 'string') {
      mp = mp.toLowerCase();
    }
    if (t === 'boolean' || t === 'number') {
      const e = getBoolean(mp, true);

      if (!part.mirrorPanel && e) {
        part.mirrorPanel = {};
      }
      if (part.mirrorPanel) {
        part.mirrorPanel.enabled = e;
      }
    } else if (t === 'object') {
      part.mirrorPanel = mp;
    }
  }

  static getBottomSample(single, fallback) {
    const texData = this._getFabricData(single, 'bottom', false, true);

    if (!texData) {
      return fallback;
    }
    if (texData.id) {
      return texData.id;
    }

    return fallback;
  }

  static setBottomSample(single, value) {
    const texData = this._getFabricData(single, 'bottom', true, false);

    if (!texData) {
      return;
    }

    this._resetTexture(texData);

    texData.id = value;
  }

  static getTopSampleOffsetX(single, fallback) {
    return this._getFabricOffsetValue(single, 'top', 'x', fallback);
  }

  static setTopSampleOffsetX(single, value) {
    this._setFabricOffsetValue(single, 'top', 'x', value);
  }

  static getTopSampleOffsetY(single, fallback) {
    return this._getFabricOffsetValue(single, 'top', 'y', fallback);
  }

  static setTopSampleOffsetY(single, value) {
    this._setFabricOffsetValue(single, 'top', 'y', value);
  }

  static getBottomSampleOffsetX(single, fallback) {
    return this._getFabricOffsetValue(single, 'bottom', 'x', fallback);
  }

  static setBottomSampleOffsetX(single, value) {
    this._setFabricOffsetValue(single, 'bottom', 'x', value);
  }

  static getBottomSampleOffsetY(single, fallback) {
    return this._getFabricOffsetValue(single, 'bottom', 'y', fallback);
  }

  static setBottomSampleOffsetY(single, value) {
    this._setFabricOffsetValue(single, 'bottom', 'y', value);
  }

  static setTopSampleAlign(single, x, y) {
    if (!single) {
      return;
    }
    if (!single.top) {
      single.top = {};
    }
    if (!single.top.texture) {
      single.top.texture = {};
    }
    SampleDA.setAlign(single.top.texture, x, y);
  }

  static getTopSampleAlignX(single, sampleService, fallback = 0.5) {
    const topData = this._getFabricData(single, 'top', false, false);

    if (topData) {
      return this._getSampleAlignX(topData, sampleService, fallback);
      // return SampleDA.getAlignX(topData, null, fallback);
    }
    if (!single) {
      return fallback;
    }

    return this._getSampleAlignX(single.texture, sampleService, fallback);
    // return SampleDA.getAlignX(single.texture, null, fallback);
  }

  static setTopSampleAlignX(single, x) {
    this.setTopSampleAlign(single, x, null);
  }

  static getTopSampleAlignY(single, sampleService, fallback = 0.5) {
    const topData = this._getFabricData(single, 'top', false, false);

    if (topData) {
      return this._getSampleAlignY(topData, sampleService, fallback);
      // return SampleDA.getAlignY(topData, null, fallback);
    }
    if (!single) {
      return fallback;
    }

    // return SampleDA.getAlignY(single.texture, null, fallback);
    return this._getSampleAlignY(single.texture, sampleService, fallback);
  }

  static setTopSampleAlignY(single, y) {
    this.setTopSampleAlign(single, null, y);
  }

  static setBottomSampleAlign(single, x, y) {
    if (!single) {
      return;
    }
    if (!single.bottom) {
      single.bottom = {};
    }
    if (!single.bottom.texture) {
      single.bottom.texture = {};
    }
    SampleDA.setAlign(single.bottom.texture, x, y);
  }

  static getBottomSampleAlignX(single, sampleService, fallback = 0.5) {
    const bottomData = this._getFabricData(single, 'bottom', false, false);

    if (bottomData) {
      return this._getSampleAlignX(bottomData, sampleService, fallback);
      // return SampleDA.getAlignX(bottomData, null, fallback);
    }
    if (!single) {
      return fallback;
    }

    return this._getSampleAlignX(single.texture, sampleService, fallback);
    // return SampleDA.getAlignX(single.texture, null, fallback);
  }

  static setBottomSampleAlignX(single, x) {
    this.setBottomSampleAlign(single, x, null);
  }

  static getBottomSampleAlignY(single, sampleService, fallback = 0.5) {
    const bottomData = this._getFabricData(single, 'bottom', false, false);

    if (bottomData) {
      return this._getSampleAlignY(bottomData, sampleService, fallback);
      // return SampleDA.getAlignY(bottomData, null, fallback);
    }
    if (!single) {
      return fallback;
    }

    return this._getSampleAlignY(single.texture, sampleService, fallback);
    // return SampleDA.getAlignY(single.texture, null, fallback);
  }

  static setBottomSampleAlignY(single, y) {
    this.setBottomSampleAlign(single, null, y);
  }

  // -- fabric rotation --

  static _getSampleRotation(tex, sampleService, fallback) {
    let r = SampleDA.getRotation(tex, this._getSampleFromService(tex, sampleService), fallback);

    if (typeof (r) === 'string') {
      r = parseFloat(r);
    }
    if (r === null || isNaN(r) || typeof (r) !== 'number') {
      r = 0;
    }

    r = fixDegrees(r);

    return r;
  }

  static _getFabricRotation(single, part, unit, sampleService, fallback = 0) {
    const texData = this._getFabricData(single, part, false, true);

    if (!texData) {
      return fallback;
    }

    let m = 1;

    if (unit === ANGLE_RADIANS) {
      m = Math.PI / 180;
    }

    return this._getSampleRotation(texData, sampleService, fallback) * m;
    // return fixDegrees(texData.rotation, fallback) * m;
  }

  static _setFabricRotation(single, part, value, unit) {
    const texData = this._getFabricData(single, part, true, false);

    if (!texData) {
      return;
    }

    let v = fixAngle(value, unit, 0);

    if (unit === ANGLE_RADIANS) {
      v = fixDegrees(v * 180 / Math.PI, 0);
    }

    // rotation is stored in degrees
    texData.rotation = v;
  }

  static getTopSampleRotation(single, sampleService, fallback = 0) {
    return this._getFabricRotation(single, 'top', ANGLE_DEGREES, sampleService, fallback);
  }

  static setTopSampleRotation(single, v) {
    this._setFabricRotation(single, 'top', v, ANGLE_DEGREES);
  }

  static getTopSampleRotationRads(single, sampleService, fallback = 0) {
    return this._getFabricRotation(single, 'top', ANGLE_RADIANS, sampleService, fallback);
  }

  static setTopSampleRotationRads(single, v) {
    this._setFabricRotation(single, 'top', v, ANGLE_RADIANS);
  }

  static getBottomSampleRotation(single, sampleService, fallback) {
    return this._getFabricRotation(single, 'bottom', ANGLE_DEGREES, sampleService, fallback);
  }

  static setBottomSampleRotation(single, v) {
    this._setFabricRotation(single, 'bottom', v, ANGLE_DEGREES);
  }

  static getBottomSampleRotationRads(single, sampleService, fallback) {
    return this._getFabricRotation(single, 'bottom', ANGLE_RADIANS, sampleService, fallback);
  }

  static setBottomSampleRotationRads(single, v) {
    this._setFabricRotation(single, 'bottom', v, ANGLE_RADIANS);
  }

  static getTopHeight(single, fallback) {
    const border = this._getBoxBorder(single, false);

    if (!border) {
      return fallback;
    }

    return getNumber(border.top, fallback);
  }

  static setTopHeight(single, value) {
    const border = this._getBoxBorder(single, true);

    if (!border) {
      return;
    }

    border.top = getNumber(value, 0);
  }

  static getBottomHeight(single, fallback) {
    const border = this._getBoxBorder(single, false);

    if (!border) {
      return fallback;
    }

    return getNumber(border.bottom, fallback);
  }

  static setBottomHeight(single, value) {
    const border = this._getBoxBorder(single, false);

    if (!border) {
      return;
    }

    border.bottom = getNumber(value, 0);
  }

  static getTopBorderRadius(single) {
    const radius = this._getRadius(single, false);

    if (!radius) {
      return 0;
    }

    return getNumber(radius.top, 0);
  }

  static setTopBorderRadius(single, value) {
    const radius = this._getRadius(single, true);

    if (!radius) {
      return;
    }

    radius.top = getNumber(value, 0);
  }

  static getBottomBorderRadius(single) {
    const radius = this._getRadius(single, false);

    if (!radius) {
      return 0;
    }

    return getNumber(radius.bottom, 0);
  }

  static setBottomBorderRadius(single, value) {
    const radius = this._getRadius(single, true);

    if (!radius) {
      return;
    }

    radius.bottom = getNumber(value, 0);
  }


  // Top quilt align
  static _getQuiltFromService(quilt, svc) {
    if (!svc) {
      return null;
    }
    let qID = quilt;

    if (typeof (quilt) === 'object') {
      if (!quilt) {
        return null;
      }
      qID = quilt.id;
    }

    return svc.getQuiltById(qID);
  }

  static getTopQuiltAlignX(single, quiltService) {
    const topQData = this._getTopBottomQuiltData(single, 'top', false, true);

    return QuiltDA.getAlignX(topQData, this._getQuiltFromService(topQData, quiltService));
  }

  static setTopQuiltAlignX(single, value) {
    const topQData = this._getTopBottomQuiltData(single, 'top', true, false);

    QuiltDA.setAlignX(topQData, value);
  }

  static getTopQuiltAlignY(single, quiltService) {
    const topQData = this._getTopBottomQuiltData(single, 'top', false, true);

    return QuiltDA.getAlignY(topQData, this._getQuiltFromService(topQData, quiltService));
  }

  static setTopQuiltAlignY(single, value) {
    const topQData = this._getTopBottomQuiltData(single, 'top', true, false);

    QuiltDA.setAlignY(topQData, value);
  }

  static setTopQuiltAlign(single, x, y) {
    const topQData = this._getTopBottomQuiltData(single, 'top', true, false);

    QuiltDA.setAlign(topQData, x, y);
  }

  // bottom quilt align

  static getBottomQuiltAlignX(single, quiltService) {
    const quiltData = this._getTopBottomQuiltData(single, 'bottom', false, true);

    return QuiltDA.getAlignX(quiltData, this._getQuiltFromService(quiltData, quiltService));
  }

  static setBottomQuiltAlignX(single, value) {
    const quiltData = this._getTopBottomQuiltData(single, 'bottom', true, false);

    return QuiltDA.setAlignX(quiltData, value);
  }

  static getBottomQuiltAlignY(single, quiltService) {
    const quiltData = this._getTopBottomQuiltData(single, 'bottom', false, true);

    return QuiltDA.getAlignY(quiltData, this._getQuiltFromService(quiltData, quiltService));
  }

  static setBottomQuiltAlignY(single, value) {
    const quiltData = this._getTopBottomQuiltData(single, 'bottom', true, false);

    return QuiltDA.setAlignY(quiltData, value);
  }

  static setBottomQuiltAlign(single, x, y) {
    const quiltData = this._getTopBottomQuiltData(single, 'bottom', true, false);

    return QuiltDA.setAlignY(quiltData, x, y);
  }

  static getBorderQuiltAlignX(single, border, quiltService) {
    const b = this.getBorderComponent(single, border);
    const bq = b ? b.quilt : null;

    return QuiltDA.getAlignX(bq, this._getQuiltFromService(quiltService));
  }
  static setBorderQuiltAlignX(single, border, value) {
    const b = this.getBorderComponent(single, border);

    if (!b) {
      return;
    }

    QuiltDA.setAlignX(b.quilt, value);
  }

  static getBorderQuiltAlignY(single, border, quiltService) {
    const b = this.getBorderComponent(single, border);
    const bq = b ? b.quilt : null;

    return QuiltDA.getAlignY(bq, this._getQuiltFromService(quiltService));
  }

  static setBorderQuiltAlignY(single, border, value) {
    const b = this.getBorderComponent(single, border);

    if (!b) {
      return;
    }

    QuiltDA.setAlignY(b.quilt, value);
  }

  static setBorderQuiltAlign(single, border, x, y) {
    const b = this.getBorderComponent(single, border);

    if (!b) {
      return;
    }

    QuiltDA.setAlign(b.quilt, x, y);
  }

  static getMaxHandles(handleData, atFront = false) {
    if (!handleData) {
      return -1;
    }

    return atFront ? handleData.maxHandlesFront : handleData.maxHandlesSide;
  }

  static setMaxHandles(handleData, atFront, value = -1) {
    if (!handleData) {
      return;
    }

    if (atFront) {
      handleData.maxHandlesFront = value;
    } else {
      handleData.maxHandlesSide = value;
    }
  }

  static getMaxHandlesOfSingle(single, atFront) {
    if (!single) {
      return -1;
    }
    const handleData = this.getHandleData(single, false);

    return this.getMaxHandles(handleData, atFront);
  }

  static setMaxHandlesOfSingle(single, atFront, value = -1) {
    if (!single) {
      return;
    }
    const handleData = this.getHandleData(single, true);

    this.setMaxHandles(handleData, atFront, value);
  }

  static getHandleStyleID(handleData) {
    const data = handleData;

    if (!data) {
      return null;
    }
    if (!data) {
      return null;
    }
    const handleStyleData = data.style;
    const handleTypeName = data.type;
    let handleStyleName = null;

    const t = typeof (handleStyleData);

    if (t === 'string') {
      return handleStyleData;
    }

    if (t === 'object') {
      handleStyleName = handleStyleData.id;
    }
    if (!handleStyleName) {
      // Maybe the style is accidentally set under 'type'
      handleStyleName = handleTypeName;
    }

    return handleStyleName;
  }

  static getHandleMaterial(single, part, create, useFallback) {
    const handleData = this.getHandleData(single, create);

    if (!handleData) {
      return null;
    }
    let style = handleData.style;

    if (!style) {
      if (!create) {
        return null;
      }

      style = handleData.style = {};
    }
    const prt = part;

    // let prt = part;

    if (prt) {
      //  translate 'tape' or 'fabric' to 'middle' or 'side'. Depends on handle type
      /*
      if (prt === 'tape' || prt === 'fabric') {
        let type = this.getHandleStyleID(handleData);

        type = type ? type.toLowerCase() : type;
        if (type === 'hvs005' || type === 'hhs016') {
          // handle with tape on the sides
          prt = prt === 'tape' ? 'side' : 'middle';
        } else if (type === 'hvs006' || type === 'hhs017') {
          // handle with tape in the middle
          prt = prt === 'tape' ? 'middle' : 'side';
        }
      }
      */
      let materials = style.materials;

      if (!materials) {
        if (useFallback && (prt === 'fabric') && style.material) {
          return style.material;
        }
        if (!create) {
          return null;
        }
        materials = style.materials = {};
      }

      if (create && !materials[prt]) {
        materials[prt] = {};
      }
      if (materials[prt]) {
        return materials[prt];
      }
      if (prt === 'fabric' && useFallback) {
        return style.material;
      }

      return null;
    }
    if (create && !style.material) {
      style.material = {};
      style.materials = null;
    }

    return style.material;
  }

  static getAvailableHandlesOnBorder(single, border, params) {
    const borderData = this.getBorderComponent(single, border);

    if (!borderData) {
      return null;
    }
    const type = borderData.type;
    const typeLc = type && type.toLowerCase ? type.toLowerCase() : type;

    if (typeLc !== 'fabric' && typeLc !== 'border' && typeLc !== 'border3d') {
      return null;
    }

    const handleStyles = HandleStyles.getHandleStyles();
    const num = handleStyles ? handleStyles.length : 0;
    const res = [];

    for (let i = 0; i < num; ++i) {
      const hs = handleStyles[i];

      const allowedRes = this.allowHandleOnBorder(single, borderData, hs);

      if (allowedRes && allowedRes.result) {
        res.push(hs);
      }
    }

    return this._modifyHandlesArray(res, params);
  }

  static getAvailableHandles(single, params) {
    if (!single) {
      return null;
    }
    const handleStyles = HandleStyles.getHandleStyles();
    const num = handleStyles ? handleStyles.length : 0;
    const res = [];

    for (let i = 0; i < num; ++i) {
      const hs = handleStyles[i];
      const name = hs ? hs.name : null;

      const allowedRes = this.allowHandle(single, name);

      if (allowedRes && allowedRes.result) {
        res.push(hs);
      }
    }

    return this._modifyHandlesArray(res, params);
  }

  static _modifyHandlesArray(handles, params) {
    if (!params || !handles) {
      return handles;
    }
    let groupBy = params.groupBy;

    if (!groupBy || !groupBy.toLowerCase) {
      return handles;
    }
    groupBy = groupBy.toLowerCase();

    if (groupBy === 'category') {
      const l = handles.length;

      const catArray = [];
      const catMap = {};

      for (let i = 0; i < l; ++i) {
        const handle = handles[i];
        const cat = handle.category;

        if (cat) {
          let catObj = catMap[cat];

          if (!catObj) {
            catObj = {
              categoryName: cat
            };
            catMap[cat] = catObj;
            catArray.push(catObj);
          }
          let hArr = catObj.handles;

          if (!hArr) {
            hArr = catObj.handles = [];
          }

          ArrayUtils.addOnce(hArr, handle);
        }
      }

      return catArray;
    }
    // #if DEBUG
    console.warn('getAvailableHandles() error :: Invalid \'groupBy\' param - Only \'category\' supported.');
    // #endif

    return handles;
  }

  static allowHandle(single, type) {
    if (!single) {
      return getAllowHandleResult(HandleErrorType.INVALID_SINGLE);
    }
    if (!type) {
      return getAllowHandleResult(HandleErrorType.INVALID_HANDLE_TYPE);
    }
    let handleStyle = null;

    if (type instanceof HandleStyle || type instanceof HandleType) {
      handleStyle = type;
    } else if (typeof (type) === 'string' || typeof (type) === 'number') {
      handleStyle = HandleStyles.get(type);
      if (!handleStyle && typeof (type) === 'string') {
        handleStyle = HandleTypes[type.toUpperCase()];
      }
    }

    if (!handleStyle) {
      return getAllowHandleResult(HandleErrorType.INVALID_HANDLE_TYPE);
    }
    let typeObject = null;

    if (handleStyle instanceof HandleStyle) {
      if (!HandleUtils.isHandleActive(handleStyle)) {
        return getAllowHandleResult(HandleErrorType.INVALID_HANDLE_TYPE);
      }
      typeObject = handleStyle.getType();
    } else if (handleStyle instanceof HandleType) {
      typeObject = handleStyle;
    }

    if (!typeObject || (!typeObject instanceof HandleType)) {
      return getAllowHandleResult(HandleErrorType.INVALID_HANDLE_TYPE);
    }

    const handleResObj = {};

    handleResObj.error = null;
    handleResObj.result = true;
    typeObject.isAllowed(type, single, handleResObj);

    return getAllowHandleResult(handleResObj.error);
  }

  static allowHandleOnBorder(single, border, handleType) {
    let type = handleType;

    if (!single) {
      return getAllowHandleResult(HandleErrorType.INVALID_SINGLE);
    }
    if (!type) {
      return getAllowHandleResult(HandleErrorType.INVALID_HANDLE_TYPE);
    }
    const borderData = this.getBorderComponent(single, border);

    if (!borderData) {
      return getAllowHandleResult(HandleErrorType.INVALID_HANDLE_BORDER);
    }

    let handleStyle = null;


    if (type instanceof HandleStyle || type instanceof HandleType) {
      handleStyle = type;
    }

    if (!handleStyle) {
      let t = typeof (type);

      if (t === 'object') {
        type = type.name;
        t = typeof (type);
      }
      if (t === 'string' || t === 'number') {
        handleStyle = HandleStyles.get(type);
        if (!handleStyle && typeof (type) === 'string') {
          handleStyle = HandleTypes[type.toUpperCase()];
        }
      }
    }

    if (!handleStyle) {
      return getAllowHandleResult(HandleErrorType.INVALID_HANDLE_TYPE);
    }
    let typeObject = null;

    if (handleStyle instanceof HandleStyle) {
      if (!HandleUtils.isHandleActive(handleStyle)) {
        return getAllowHandleResult(HandleErrorType.INVALID_HANDLE_TYPE);
      }
      typeObject = handleStyle.getType();
    } else if (handleStyle instanceof HandleType) {
      typeObject = handleStyle;
    }

    if (!typeObject || (!typeObject instanceof HandleType)) {
      return getAllowHandleResult(HandleErrorType.INVALID_HANDLE_TYPE);
    }

    const handleResObj = {};

    handleResObj.error = null;
    handleResObj.result = true;
    typeObject.isAllowedOnBorder(type, single, borderData, handleResObj);

    return getAllowHandleResult(handleResObj.error);

  }

  static canSetHandleFabric(single, part) {
    return this.canSetHandleSample(single, part);
  }

  static canSetHandleSample(single, part) {
    if (!single) {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set handle fabric - Invalid single: ', single);
      // #endif

      return false;
    }
    const handleData = this.getHandleData(single);

    if (!handleData) {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set handle fabric - no handle set:', single);
      // #endif

      return false;
    }

    const handleStyle = HandleStyles.getFromJSON(handleData);

    if (!handleStyle) {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set handle fabric - Invalid style ID:', handleData, 'single=', single);
      // #endif

      return false;
    }
    // #if DEBUG
    const styleID = handleStyle.getID();
    // #endif

    const components = handleStyle.editableComponents;
    const numComps = components ? components.length : 0;

    if (!numComps) {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set handle fabric of', styleID, ' - single=', single);
      // #endif

      return false;
    }
    let res = true;

    if (part) {
      res = components.indexOf(part, 0) >= 0;
    } else {
      res = components.indexOf('fabric', 0) >= 0;
    }
    // #if DEBUG
    if (!res) {
      if (part) {
        BD3DLogger.warn('Can\'t set fabric of handle', styleID, '(', part, ') - single=', single);
      } else {
        BD3DLogger.warn('Can\'t set handle fabric of', styleID, ' - single=', single);
      }
    }
    // #endif

    return res;

    // const styleID = this.getHandleStyleID(handleData);
    /*
    if (!styleID) {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set handle fabric - Invalid style ID:', styleID, 'single=', single);
      // #endif

      return false;
    }

    const styleIDLc = styleID && styleID.toLowerCase ? styleID.toLowerCase() : null;

    if (styleIDLc === 'hhs014' || styleIDLc === 'hvs003') {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set handle color of', styleID, ' - single=', single);
      // #endif

      return false;
    }

    const styleIDObject = HandleStyles.get(styleID);

    if (!styleIDObject) {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set handle fabric - Invalid style ID:', styleID, 'styleIDObject=', styleIDObject, 'single=', single);
      // #endif

      return false;
    }
    const handleType = styleIDObject.getType();

    if (!handleType) {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set handle fabric - Invalid style ID:', styleID, 'styleIDObject=', styleIDObject, 'single=', single);
      // #endif

      return false;
    }
    if (handleType === HandleTypes.POCKET || handleType === HandleTypes.SUITCASE) {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set handle fabric of pocket or suitcase. Single=', single);
      // #endif

      return false;
    }

    return true;
    */
  }

  static getHandleTextureSlots(handleStyleID) {
    if (!handleStyleID) {
      return null;
    }
    if (typeof (handleStyleID) !== 'string') {
      return null;
    }
    const hsID = handleStyleID.toLowerCase();

    if (this.handleTypeHasCustomizableLogo(hsID)) {
      // All handles with a fixed fabric (text / logo)
      return null;
    }

    if (hsID === 'hvs005' || hsID === 'hvs006' || hsID === 'hhs016' || hsID === 'hhs017') {
      // Handles with a tape & fabric
      return [
        {label: 'tape', value: 'tape'},
        {label: 'fabric', value: 'fabric'}
      ];
    }
    // All other handles with a single fabric

    return [{label: 'fabric', value: null}];
  }

  static getHandleTextureSlotsBySingle(single) {
    if (!single) {
      return null;
    }
    const hd = this.getHandleData(single);

    if (!hd) {
      return null;
    }

    const styleID = this.getHandleStyleID(hd);

    return this.getHandleTextureSlots(styleID);
  }

  static canSetHandleColor(single, part) {
    if (!single) {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set handle color - Invalid single: ', single);
      // #endif

      return false;
    }
    const prt = (part && part.toLowerCase) ? part.toLowerCase() : null;

    if (!prt) {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set handle color - Invalid part. Single = ', single);
      // #endif

      return false;
    }

    const handleData = this.getHandleData(single);

    if (!handleData) {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set handle color - no handle set:', single);
      // #endif

      return false;
    }
    const styleID = this.getHandleStyleID(handleData);

    if (!styleID) {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set handle color - Invalid style ID:', styleID, 'single=', single);
      // #endif

      return false;
    }
    const handleStyle = HandleStyles.get(styleID);

    if (!handleStyle) {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set handle color - Invalid style ID:', styleID, 'single=', single);
      // #endif

      return false;
    }
    const comps = handleStyle.editableComponents;
    const numComps = comps ? comps.length : 0;

    if (!numComps) {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set handle color of ', styleID, 'single=', single);
      // #endif

      return false;
    }
    const res = comps.indexOf('tape', 0) >= 0;

    // #if DEBUG
    if (!res) {
      BD3DLogger.warn('Can\'t set handle color of ', styleID, ' because it has no tape. Single=', single);
    }
    // #endif

    return res;
    /*
    const styleIDLc = styleID && styleID.toLowerCase ? styleID.toLowerCase() : null;

    if (styleIDLc === 'hhs014' || styleIDLc === 'hvs003') {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set handle color of', styleID, ' - single=', single);
      // #endif

      return false;
    }

    prt = prt.toLowerCase();

    if (prt !== 'tape') {
      if (
        (prt === 'side' && (styleIDLc === 'hvs005' || styleIDLc === 'hhs016')) ||
        (prt === 'middle' && (styleIDLc === 'hvs006' || styleIDLc === 'hhs017'))
      ) {
        prt = 'tape';
      }
    }
    if (prt !== 'tape') {
      // #if DEBUG
      BD3DLogger.warn('Handle colors can only be set on tapes - single=', single);
      // #endif

      return false;
    }

    const styleIDObject = HandleStyles.get(styleID);

    if (!styleIDObject) {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set handle color - Invalid style ID:', styleID, 'styleIDObject=', styleIDObject, 'single=', single);
      // #endif

      return false;
    }
    const handleType = styleIDObject.getType();

    if (!handleType) {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set handle color - Invalid style ID:', styleID, 'styleIDObject=', styleIDObject, 'single=', single);
      // #endif

      return false;
    }
    if (handleType === HandleTypes.POCKET || handleType === HandleTypes.SUITCASE) {
      // #if DEBUG
      BD3DLogger.warn('Can\'t set handle color of pocket or suitcase. Single=', single);
      // #endif

      return false;
    }

    return true;
    */
  }

  static getHandleColor(single, part, fallback) {
    const mtl = this.getHandleMaterial(single, part, false, true);

    if (!mtl) {
      return fallback;
    }

    if (mtl.sample || mtl.texture) {
      return fallback;
    }
    if (mtl.color) {
      return mtl.color;
    }

    return fallback;
  }

  static setHandleColor(single, part, value) {

    const handleData = this.getHandleData(single);
    const handleStyle = HandleStyles.getFromJSON(handleData);

    if (!handleStyle) {
      return;
    }

    const comps = handleStyle ? handleStyle.editableComponents : null;
    const numComps = comps ? comps.length : 0;

    if (numComps === 0) {
      return;
    }
    let mtl = null;

    if (numComps === 1 && comps[0] === part) {
      mtl = this.getHandleMaterial(single, null, true, false);
    } else {
      mtl = this.getHandleMaterial(single, part, true, false);
    }

    if (!mtl) {
      return;
    }
    if (mtl.sample) {
      mtl.sample = null;
    }
    if (mtl.texture) {
      mtl.texture = null;
    }
    mtl.color = value;
  }

  static getHandleFabric(single, part, fallback) {
    return this.getHandleSample(single, part, fallback);
  }
  static getHandleSample(single, part, fallback) {
    const mtl = this.getHandleMaterial(single, part, false, true);

    if (!mtl) {
      return fallback;
    }
    let sample = mtl.sample;

    if (!sample) {
      return null;
    }

    if (typeof (sample) === 'object') {
      sample = sample.id;
    }

    return sample;
  }
  static setHandleFabric(single, part, sampleID) {
    this.setHandleSample(single, part, sampleID);
  }
  static setHandleSample(single, part, sampleID) {
    if (!this.canSetHandleSample(single, part)) {
      return;
    }
    const validSampleID = sampleID !== null && (typeof (sampleID) === 'string' || typeof (sampleID) === 'number');

    const handleData = this.getHandleData(single);
    const handleStyleID = this.getHandleStyleID(handleData);
    const handleStyle = HandleStyles.get(handleStyleID);

    const editableComps = handleStyle ? handleStyle.editableComponents : null;
    const numEditableComps = editableComps ? editableComps.length : 0;

    let mtl = null;

    if (part === 'fabric' && numEditableComps === 1 && editableComps[0] === part) {
      mtl = this.getHandleMaterial(single, null, validSampleID, false);
    } else {
      mtl = this.getHandleMaterial(single, part, validSampleID, false);
    }

    if (!mtl) {
      const numMaterials = (handleStyle && handleStyle.getNumMaterials) ? handleStyle.getNumMaterials() : 0;

      mtl = (numMaterials === 1) ? this.getHandleMaterial(single, null, validSampleID, false) : null;
    }
    if (!mtl) {
      return;
    }
    mtl.sample = sampleID;

    if (!part) {
      const hd = this.getHandleData(single, false);

      if (hd && hd.style && hd.style.materials) {
        hd.style.materials = null;
      }
    }
  }

  static getHandleType(single) {
    const handleData = this.getHandleData(single, false);

    if (!handleData) {
      return null;
    }

    return handleData.type;
  }

  static setHandleType(single, type, handleParams = null, handleMaterials = null) {
    const hd = this.getHandleData(single, true);

    if (!hd) {
      return;
    }
    hd.type = type;
    hd.params = handleParams;
    if (hd.style) {
      if (hd.style.materials) {
        hd.style.materials = null;
      }
      if (hd.style.material) {
        hd.style.material = null;
      }
    }

    if (handleMaterials) {
      if (!hd.style) {
        hd.style = {};
      }
      if (typeof (handleMaterials) === 'object') {
        hd.style.materials = {};
        for (const v in handleMaterials) {
          if (handleMaterials.hasOwnProperty(v)) {
            this.setHandleSample(single, v, handleMaterials[v]);
          }
        }
      } else if (typeof (handleMaterials) === 'string' || typeof (handleMaterials) === 'number') {
        this.setHandleSample(single, null, handleMaterials);
      }
    }
  }

  static handleTypeHasCustomizableLogo(type) {
    return HandleDA.handleTypeHasCustomizableLogo(type);
  }

  static singleHasCustomizableLogoHandle(single) {
    const type = this.getHandleType(single);

    return this.handleTypeHasCustomizableLogo(type);
  }

  static getLogoHandleImageID(single) {
    const img = this._getLogoHandleImageData(single, false);

    return img && (img.ID || img.id);
  }

  static setLogoHandleImageID(single, id) {
    const validID = typeof (id) !== 'undefined' && id !== null;
    const img = this._getLogoHandleImageData(single, validID);

    if (!img) {
      return;
    }
    img.id = id;
  }

  static getLogoHandleImageURL(single) {
    const img = this._getLogoHandleImageData(single, false);

    return img && (img.source || img.src || img.image || img.img || img.url);
  }

  static setLogoHandleImageURL(single, url) {
    const validID = typeof (url) !== 'undefined' && url !== null;
    const img = this._getLogoHandleImageData(single, validID);

    if (!img) {
      return;
    }
    img.source = url;
  }

  static _getLogoHandleImageData(single, create = false) {
    const handleData = this.getHandleData(single, create);

    if (!handleData) {
      return null;
    }
    const {params} = handleData;

    if (params && params.image) {
      return params.image;
    }
    let img = handleData.image || handleData.image;

    if (!img && create) {
      img = {
      };
      handleData.image = img;
    }

    return img;
  }

  static getHandleData(single, create = false) {
    const options = this.getOptions(single, create);

    if (!options) {
      return null;
    }
    let h = options.handle;

    if (!h) {
      h = options.handles;
    }
    // ?
    if (!h) {
      h = options.handler;
    }
    if (!h) {
      h = options.handlers;
    }
    if (!h && create) {
      h = options.handles = {};
    }

    return h;
  }

  static getOptions(single, create = false) {
    if (!single) {
      return null;
    }
    if (create && !single.options) {
      single.options = {};
    }

    return single.options;
  }

  static setLegs(single, value) {
    if (!single) {
      return;
    }

    const t = typeof (value);

    if (value === null || t === 'undefined') {
      const options = this.getOptions(single);

      options.legs = null;

      return;
    }
    let legsID = null;

    if (t === 'object') {
      legsID = value.id;
    } else if (t === 'number' || t === 'string') {
      legsID = value;
    }

    const legs = this.getLegs(single, true);

    legs.id = legsID;
  }

  static getLegs(single, create = false) {
    if (!single) {
      return null;
    }

    const options = this.getOptions(single, create);

    if (!options) {
      return null;
    }

    if (options.legs) {
      return options.legs;
    }

    if (create) {
      options.legs = {};
    }

    return options.legs;
  }

  static getLegHeight(single) {
    const legs = this.getLegs(single, false);

    if (legs) {
      return LegDA.getLegHeight(legs);
    }

    return 0;
  }

  static setLegHeight(single, value) {
    const legs = this.getLegs(single, true);

    if (legs) {
      legs.height = value;
    }
  }

  static hasTopMirrorPanel(single) {
    return this.getTopHeight(single, 0) < 0;
  }

  static hasBottomMirrorPanel(single) {
    return this.getBottomHeight(single, 0) < 0;
  }

  static hasMirrorPanel(single) {
    return this.hasTopMirrorPanel(single) || this.hasBottomMirrorPanel(single);
  }

  static getResultTopBorderRadius(single) {
    let res = this.getTopBorderRadius(single);
    const cr = this.getResultCornerRadius(single);

    res = res < cr ? res : cr;

    return res;
  }

  static getResultBottomBorderRadius(single) {
    let res = this.getBottomBorderRadius(single);
    const cr = this.getResultCornerRadius(single);

    res = res < cr ? res : cr;

    return res;
  }

  static _getSizeObject(single, create = false) {
    const box = this._getBox(single, create);

    if (!box) {
      return null;
    }
    if (!box.size && create) {
      box.size = {};
    }

    return box.size;
  }

  static _getRadius(single, create = false) {
    const box = this._getBox(single, create);

    if (!box) {
      return null;
    }
    if (!box.radius && create) {
      box.radius = {};
    }

    return box.radius;
  }

  static _getBoxBorder(single, create = false) {
    const box = this._getBox(single, create);

    if (!box) {
      return null;
    }
    if (!box.border && create) {
      box.border = {};
    }

    return box.border;
  }

  static _getBox(single, create = true) {
    if (!single) {
      return null;
    }
    if (!single.box && create) {
      single.box = {};
    }

    return single.box;
  }

  static getTotalBorderComponentHeight(single) {
    if (!single) {
      return 0;
    }

    const border = single.border;

    if (!border) {
      return 0;
    }
    const components = border.components;

    if (!components) {
      return 0;
    }

    return this.getTotalHeightOfBorderComponents(components, single);
  }

  static getTotalHeightOfBorderComponents(components, single) {
    if (!components) {
      return 0;
    }
    const numComponents = components.length;

    if (numComponents === 0) {
      return 0;
    }

    let total = 0;

    for (let i = 0; i < numComponents; ++i) {
      const comp = components[i];

      if (comp) {
        const compType = comp.type;

        if (compType) {
          const bc = BorderComponentUtils.getBorderComponentTypeByName(compType);

          if (bc && bc.getSizeY) {
            total += bc.getSizeY(comp, single);
          }
        }
      }
    }

    return total;
  }

  // ////////////////////////////////////////////////////////////////
  //
  // Quilt methods
  //
  // ////////////////////////////////////////////////////////////////
  static getTopQuiltID(single) {
    return this._getTopBottomQuiltID(single, 'top');
  }

  static setTopQuiltID(single, value) {
    this._setTopBottomQuiltID(single, 'top', value);
  }

  static getTopQuiltRepeatType(single) {
    return this._getTopBottomQuiltRepeatType(single, 'top');
  }

  static setTopQuiltRepeatType(single, value) {
    this._setTopBottomQuiltRepeatType(single, 'top', value);
  }

  static getTopQuiltRepeatX(single) {
    const qd = this._getTopBottomQuiltData(single, 'top', false, true);

    return this._getQuiltRepeatX(qd, 0);
  }

  static setTopQuiltRepeatX(single, value) {
    const qd = this._getTopBottomQuiltData(single, 'top', true, false);

    this._setQuiltRepeatX(qd, value);
  }

  static getTopQuiltRepeatY(single) {
    const qd = this._getTopBottomQuiltData(single, 'top', false, true);

    return this._getQuiltRepeatY(qd, 0);
  }

  static setTopQuiltRepeatY(single, value) {
    const qd = this._getTopBottomQuiltData(single, 'top', true, false);

    this._setQuiltRepeatY(qd, value);
  }

  static getTopQuiltOffsetX(single) {
    return this._getTopBottomQuiltOffsetCoord(single, 'top', 'x', 0);
  }

  static setTopQuiltOffsetX(single, x) {
    this._setTopBottomQuiltOffsetCoord(single, 'top', 'x', x);
  }

  static getTopQuiltOffsetY(single) {
    return this._getTopBottomQuiltOffsetCoord(single, 'top', 'y', 0);
  }

  static setTopQuiltOffsetY(single, y) {
    this._setTopBottomQuiltOffsetCoord(single, 'top', 'y', y);
  }

  static setTopQuiltOffset(single, x, y) {
    this._setTopBottomQuiltOffset(single, 'top', x, y);
  }

  static getTopQuiltRotation(single) {
    if (!single) {
      return 0;
    }
    const q = this._getTopBottomQuiltData(single, 'top', false, true);

    if (!q) {
      return 0;
    }

    return getNumber(q.rotation, 0);
  }

  static setTopQuiltRotation(single, value) {
    if (!single) {
      return;
    }
    const q = this._getTopBottomQuiltData(single, 'top', true, false);

    if (!q) {
      return;
    }
    q.rotation = getNumber(value, 0);
  }

  static getTopQuiltFoamValue(single) {
    const qd = this._getTopBottomQuiltData(single, 'top', false, true);

    return this._getQuiltFoamValue(qd, DEFAULT_QUILT_FOAM_DEPTH);
  }

  static setTopQuiltFoamValue(single, value) {
    const qd = this._getTopBottomQuiltData(single, 'top', true, false);

    this._setQuiltFoamValue(qd, getNumber(value, DEFAULT_QUILT_FOAM_DEPTH));
  }


  static getBottomQuiltID(single) {
    return this._getTopBottomQuiltID(single, 'bottom');
  }

  static setBottomQuiltID(single, value) {
    this._setTopBottomQuiltID(single, 'bottom', value);
  }

  static getBottomQuiltRepeatType(single) {
    return this._getTopBottomQuiltRepeatType(single, 'bottom');
  }

  static setBottomQuiltRepeatType(single, value) {
    this._setTopBottomQuiltRepeatType(single, 'bottom', value);
  }

  static getBottomQuiltRepeatX(single) {
    const qd = this._getTopBottomQuiltData(single, 'bottom', false, true);

    return this._getQuiltRepeatX(qd, 0);
  }

  static setBottomQuiltRepeatX(single, value) {
    const qd = this._getTopBottomQuiltData(single, 'bottom', true, false);

    this._setQuiltRepeatX(qd, value);
  }

  static getBottomQuiltRepeatY(single) {
    const qd = this._getTopBottomQuiltData(single, 'bottom', false, true);

    return this._getQuiltRepeatY(qd, 0);
  }

  static setBottomQuiltRepeatY(single, value) {
    const qd = this._getTopBottomQuiltData(single, 'bottom', true, false);

    this._setQuiltRepeatY(qd, value);
  }

  static getBottomQuiltOffsetX(single) {
    return this._getTopBottomQuiltOffsetCoord(single, 'bottom', 'x', 0);
  }

  static setBottomQuiltOffsetX(single, x) {
    this._setTopBottomQuiltOffsetCoord(single, 'bottom', 'x', x);
  }

  static getBottomQuiltOffsetY(single) {
    return this._getTopBottomQuiltOffsetCoord(single, 'bottom', 'y', 0);
  }

  static setBottomQuiltOffsetY(single, y) {
    this._setTopBottomQuiltOffsetCoord(single, 'bottom', 'y', y);
  }

  static setBottomQuiltOffset(single, x, y) {
    this._setTopBottomQuiltOffset(single, 'bottom', x, y);
  }

  static getBottomQuiltRotation(single) {
    if (!single) {
      return null;
    }
    const q = this._getTopBottomQuiltData(single, 'bottom', false, true);

    if (!q) {
      return 0;
    }

    return getNumber(q.rotation, 0);
  }

  static setBottomQuiltRotation(single, value) {
    if (!single) {
      return;
    }
    const q = this._getTopBottomQuiltData(single, 'bottom', true, false);

    if (!q) {
      return;
    }
    q.rotation = getNumber(value, 0);
  }

  static getBottomQuiltFoamValue(single) {
    const qd = this._getTopBottomQuiltData(single, 'bottom', false, true);

    return this._getQuiltFoamValue(qd, DEFAULT_QUILT_FOAM_DEPTH);
  }

  static setBottomQuiltFoamValue(single, value) {
    const qd = this._getTopBottomQuiltData(single, 'bottom', true, false);

    this._setQuiltFoamValue(qd, getNumber(value, DEFAULT_QUILT_FOAM_DEPTH));
  }

  // private quilt methods
  static _getTopBottomQuiltRepeatType(single, key) {
    const qd = this._getTopBottomQuiltData(single, key, false, true);

    return this._getQuiltRepeatType(qd);
  }

  static _setTopBottomQuiltRepeatType(single, key, value) {
    const qd = this._getTopBottomQuiltData(single, key, true, false);

    return this._setQuiltRepeatType(qd, value);
  }

  static _getTopBottomQuiltRepeatCoord(single, key, coord, fallback) {
    const qd = this._getTopBottomQuiltData(single, key, false, true);

    return this._getQuiltRepeatCoord(qd, coord, fallback);
  }

  static _setTopBottomQuiltRepeatCoord(single, key, coord, value) {
    const qd = this._getTopBottomQuiltData(single, key, true, false);

    return this._setQuiltRepeatCoord(qd, coord, value);
  }

  static _getTopBottomQuiltOffsetCoord(single, key, coord, fallback) {
    if (!single || !key || !coord) {
      return fallback;
    }
    const off = this._getTopBottomQuiltOffset(single, key, false, true);

    if (!off) {
      return fallback;
    }
    const res = getNumber(off[coord]);

    if (!isNumber(res)) {
      return fallback;
    }

    return res;
  }

  static _setTopBottomQuiltOffsetCoord(single, key, coord, value) {
    if (!single || !key || !coord) {
      return;
    }
    const off = this._getTopBottomQuiltOffset(single, key, true, false);

    if (!off) {
      return;
    }
    off[coord] = getNumber(value, 0);
  }

  static _setTopBottomQuiltOffset(single, key, x, y) {
    if (x !== null && typeof (x) === 'object') {
      // Signature: _setTopBottomQuiltOffset(single, key, {x: x, y: y})
      x.x = getNumber(x.x, 0);
      x.y = getNumber(x.y, 0);
      const q = this._getTopBottomQuiltData(single, key, true, false);

      if (!q) {
        return;
      }
      q.offset = x;
    }

    const X = getNumber(x, 0);
    const Y = getNumber(y, 0);
    const off = this._getTopBottomQuiltOffset(single, key, true, false);

    if (!off) {
      return;
    }
    off.x = X;
    off.y = Y;
  }

  static _getTopBottomQuiltOffset(single, key, create, useFallback) {
    const qd = this._getTopBottomQuiltData(single, key, create, useFallback);

    if (!qd) {
      return null;
    }
    let off = qd.offset;

    if (!off && create) {
      off = qd.offset = {x: 0, y: 0};
    }

    return off;
  }

  static _getTopBottomQuiltID(single, key) {
    const q = this._getTopBottomQuiltData(single, key, false, true);

    if (!q) {
      return null;
    }

    return this._getQuiltID(q);
  }

  static _setTopBottomQuiltID(single, key, value) {
    const q = this._getTopBottomQuiltData(single, key, true, false);

    this.resetQuiltData(q);

    this._setPresetQuilt(q, value);
  }

  /**
   * @method _getTopBottomQuiltData
   * @static
   * @private
   * @description Returns the quilt data of the top or bottom part of a single
   *   If this method is used to put data in the quilt object,
   *      set the 'create' parameter to true and 'useFallback' to false
   *   If this method is used to request a property of the quilt object,
   *      set the 'create' parameter to false and 'useFallback' to true
   * @param {Object} single - the data of a single mattress
   * @param {String} partName - 'top' or 'bottom'
   * @param {Boolean} create - if true, creates the quilt data on the top or bottom part
   * @param {Boolean} useFallback - if true, returns the legacy quilt data from 'single.quilt'
   * @return {Object} - quilt data or null if not found
   * */
  static _getTopBottomQuiltData(single, partName, create, useFallback) {
    if (!single || !partName) {
      return null;
    }
    let part = null;

    if (partName === 'top') {
      part = single.top;
    } else if (partName === 'bottom') {
      part = single.bottom;
    }
    if (part && part.quilt) {
      return part.quilt;
    }
    if (create) {
      if (!part) {
        if (partName === 'top' || partName === 'bottom') {
          part = single[partName] = {};
        }
      }
      if (!part) {
        return null;
      }
      if (!part.quilt) {
        part.quilt = {};
      }

      return part.quilt;
    }
    if (useFallback) {
      return single.quilt;
    }

    return null;
  }

  /*
   * @method _getTopBottomQuiltData
   * @static
   * @private
   * @description Returns the quilt data of the top or bottom part of a single
   *   If this method is used to put data in the quilt object,
   *      set the 'create' parameter to true and 'useFallback' to false
   *   If this method is used to request a property of the quilt object,
   *      set the 'create' parameter to false and 'useFallback' to true
   * @param {Object} single - the data of a single mattress
   * @param {String} key - 'top' or 'bottom'
   * @param {Boolean} create - if true, creates the quilt data on the top or bottom part
   * @param {Boolean} useFallback - if true, returns the legacy quilt data from 'single.quilt'
   * @return {Object} - quilt data or null if not found
   * */
  /*
  static _getTopBottomQuiltData(single, key, create, useFallback) {
    if (!single) {
      return null;
    }
    let part = single[key];
    let q = null;

    if (!part && create) {
      part = single[key] = {};
    }
    if (!part && useFallback) {
      part = single;
    }
    if (!part) {
      return null;
    }
    q = part.quilt;
    if (!q && create && part !== single) {
      q = part.quilt = {};
    }

    return q;
  }
  */

  static getBorderQuiltID(single, border) {
    const comp = this.getBorderComponent(single, border);

    if (!comp) {
      return null;
    }
    const q = comp.quilt;

    if (!q) {
      return null;
    }

    return this._getQuiltID(q);
  }

  static setBorderQuiltID(single, border, value) {
    const comp = this.getBorderComponent(single, border);

    if (!comp) {
      return null;
    }
    let q = comp.quilt;

    if (!q && value !== null && typeof (value) !== 'undefined') {
      q = comp.quilt = {};
    }
    this.resetQuiltData(q);

    return this._setPresetQuilt(q, value);
  }

  static getBorderQuiltFoamValue(single, border) {
    const qd = this._getBorderComponentQuiltData(single, border, false);

    if (!qd) {
      // No quilt pattern set -> foam = 0
      return 0;
    }

    const res = this._getQuiltFoamValue(qd, DEFAULT_QUILT_FOAM_DEPTH);

    if (!isNumber(res)) {
      return DEFAULT_QUILT_FOAM_DEPTH;
    }

    return res;
  }

  static setBorderQuiltFoamValue(single, border, value) {
    const qd = this._getBorderComponentQuiltData(single, border, true);

    if (!qd) {
      return;
    }

    this._setQuiltFoamValue(qd, getNumber(value, DEFAULT_QUILT_FOAM_DEPTH));
  }

  static _getBorderComponentQuiltData(single, border, create) {
    const comp = this.getBorderComponent(single, border);

    if (!comp) {
      // #if DEBUG
      if (create) {
        BD3DLogger.warn(`'Can\'t access border quilt data - Invalid border: <single = ${single}, border = ${border}>`);
      }
      // #endif

      return null;
    }
    if (!comp.quilt && create) {
      comp.quilt = {};
    }

    return comp.quilt;
  }

  static _getQuiltSoftness(object, fallback) {
    if (!object) {
      return fallback;
    }
    let res;

    if (object.img && !isNull(object.img.softness)) {
      res = getNumber(object.img.softness);
    }
    if (isNumber(res)) {
      return res;
    }
    if (isNull(object.softness)) {
      return fallback;
    }

    return getNumber(object.softness, fallback);
  }

  static _getQuiltRepeatType(object) {
    if (!object) {
      return null;
    }
    if (!object.repeat) {
      return null;
    }

    return object.repeat.type;
  }

  static _setQuiltRepeatType(object, type) {
    const rep = this._getQuiltRepeat(object, true);

    if (!rep) {
      return;
    }
    rep.type = type;
  }

  static _setQuiltRepeatValues(object, type, x, y) {
    const rep = this._getQuiltRepeat(object, true);

    if (!rep) {
      return;
    }
    rep.type = type;
    this._setQuiltRepeatCount(object, x, y);
  }

  static _getQuiltRepeatX(object, fallback) {
    return this._getQuiltRepeatCoord(object, 'x', fallback);
  }
  static _setQuiltRepeatX(object, value) {
    return this._setQuiltRepeatCoord(object, 'x', value);
  }

  static _getQuiltRepeatY(object, fallback) {
    return this._getQuiltRepeatCoord(object, 'y', fallback);
  }

  static _setQuiltRepeatY(object, value) {
    return this._setQuiltRepeatCoord(object, 'y', value);
  }

  static _setQuiltRepeatCoord(object, coord, value) {
    const rep = this._getQuiltRepeat(object, true);

    if (!rep || !coord) {
      return;
    }
    if (!rep.value) {
      rep.value = {};
    }
    rep.value[coord] = value;
  }

  // set quilt repeat x or y
  static _getQuiltRepeatCoord(object, coord, fallback) {
    const rep = this._getQuiltRepeat(object, false);

    if (!rep || !coord) {
      return fallback;
    }
    if (!rep.value) {
      return fallback;
    }
    if (isNumber(rep.value[coord])) {
      return rep.value[coord];
    }

    return fallback;
  }

  // set quilt repeat by x and y or {x: amount, y: amount}
  static _setQuiltRepeatCount(object, x, y) {
    const rep = this._getQuiltRepeat(object, true);

    if (!rep) {
      return;
    }

    if (x !== null && typeof (x) === 'object' && (isNumber(x.x) || isNumber(x.y))) {
      rep.value = x;

      return;
    }
    if (!rep.value) {
      rep.value = {};
    }
    if (isNumber(x)) {
      rep.value.x = x;
    }
    if (isNumber(y)) {
      rep.value.y = y;
    }
  }

  static _getQuiltRepeat(object, create) {
    if (!object) {
      return null;
    }
    let rep = object.repeat;

    if (rep || !create) {
      return rep;
    }
    rep = object.repeat = {};

    return rep;
  }

  static _setQuiltRepeat(object, rep) {
    if (!object) {
      return;
    }

    object.repeat = rep;
  }

  static _getQuiltID(object) {
    if (!object) {
      return null;
    }
    let t = typeof (object.id);

    if (t === 'number' || t === 'string') {
      return object.id;
    }
    if (!object.img) {
      return null;
    }
    t = typeof (object.img.id);

    if (t === 'number' || t === 'string') {
      return object.img.id;
    }

    return null;
  }

  static _setPresetQuilt(object, quilt) {
    if (!object) {
      return;
    }
    const t = typeof (quilt);

    if (quilt === null || t === 'string' || t === 'number' || t === 'undefined') {
      object.id = quilt;
      if (quilt && !object.img) {
        object.img = {};
      }
      if (object.img) {
        this._resetTexture(object);
        object.img.id = quilt;
        this._setQuiltSizeObject(object.img, null);
      }
    } else {
      this._resetTexture(object);
      object.img = quilt;
    }

    this._setQuiltSizeObject(object, null);
    this._setQuiltType(object, 'preset');
  }

  static _setQuiltSizeObject(object, size) {
    if (!object) {
      return;
    }
    if (object.size) {
      object.size = size;
    }
  }

  // set quilt.x[index] = true / false
  static _setCustomQuiltX(object, index, value) {
    this._setCustomQuiltAt(object, 'x', index, value);
  }

  // set quilt.y[index] = true / false
  static _setCustomQuiltY(object, index, value) {
    this._setCustomQuiltAt(object, 'y', index, value);
  }

  // quilt.x[index]
  static _getCustomQuiltX(object, index, fallback) {
    return this._getCustomQuiltAt(object, 'x', index, fallback);
  }

  // quilt.y[index]
  static _getCustomQuiltY(object, index, fallback) {
    return this._getCustomQuiltAt(object, 'y', index, fallback);
  }

  static _getCustomQuiltAt(object, coord, index, fallback = false) {
    if (!object || !coord) {
      return fallback;
    }
    const c = object[coord];

    if (!c) {
      return fallback;
    }
    const i = `${index}`;

    return getBoolean(c[i], fallback);
  }

  static _setCustomQuiltAt(object, coord, index, value) {
    if (!object || !coord) {
      return;
    }
    let c = object[coord];

    if (!c) {
      c = object[coord] = {};
    }
    const i = `${index}`;

    c[i] = value !== false;
    this._setQuiltType(object, 'custom');
  }

  static _setCustomQuilt(object, customQuilt) {
    if (!object) {
      return;
    }
    if (customQuilt === true || customQuilt === false) {
      this._setQuiltType(object, customQuilt ? 'custom' : 'preset');
    } else {
      this._setQuiltType(object, 'custom');
      if (customQuilt) {
        if (typeof (customQuilt.x) !== 'undefined') {
          object.x = customQuilt.x;
        }
        if (typeof (customQuilt.y) !== 'undefined') {
          object.y = customQuilt.y;
        }
      }
    }
  }

  static _setQuiltType(object, type) {
    if (!object) {
      return;
    }
    object.type = type;
  }

  static _getQuiltType(object) {
    if (!object) {
      return null;
    }

    return object.type;
  }

  static _getQuiltFoamValue(object, fallback) {
    return QuiltDA.getFoamThickness(object, null, fallback);
    /*
    const foam = this._getQuiltFoam(object, false);

    if (!foam) {
      return fallback;
    }
    const res = getNumber(foam.value, fallback);

    if (!isNumber(res)) {
      return fallback;
    }

    return res;
    */
  }

  static _setQuiltFoamValue(object, value) {
    QuiltDA.setFoamThickness(object, value);
    /*
    const foam = this._getQuiltFoam(object, true);

    if (!foam) {
      return;
    }

    foam.value = getNumber(value, 0);
    */
  }

  static _getQuiltFoam(object, create = false) {
    if (!object) {
      return null;
    }
    let res = object.foam;

    if (res || !create) {
      return res;
    }
    res = object.foam = {};

    return res;
  }
  // Noise settings

  static hasNoise(single) {
    return this.getNoiseValue(single, 0) > 0;
  }

  static setNoiseValue(single, value) {
    if (!single) {
      return;
    }
    const v = getNumber(value);
    let options = single.options;

    if (!options) {
      options = single.options = {};
    }
    let noise = options.noise;
    let t = typeof (noise);

    if (t === 'number' || t === 'string' || t === 'boolean') {
      noise = {value: v};
      t = typeof (noise);
    }
    if (t !== 'object' || noise === null) {
      noise = {value: v};
    }
    noise.value = v;
    options.noise = noise;
  }

  static getNoiseValue(single, fallback = 0) {
    if (!single) {
      return fallback;
    }
    const options = single.options;

    if (!options) {
      return fallback;
    }
    let noise = options.noise;
    let t = typeof (noise);

    if (t === 'number' || t === 'string' || t === 'boolean') {
      noise = getNumber(noise, fallback);
      if (isNaN(noise)) {
        return fallback;
      }

      return noise;
    } else if (t === 'object') {
      noise = noise.value;
      t = typeof (noise);
      if (t !== 'number' || isNaN(noise)) {
        return fallback;
      }

      return noise;
    }

    return fallback;
  }

  static getNoiseScale(single, fallback = DEFAULT_NOISE_SCALE) {
    if (!single) {
      return fallback;
    }
    const options = single.options;

    if (!options) {
      return fallback;
    }

    const t = typeof (options.noiseScale);

    if (options.noiseScale !== null && t !== 'undefined') {
      if (t === 'number' && !isNaN(options.noiseScale)) {
        return options.noiseScale;
      } else if (t === 'string' || t === 'boolean') {
        const res = getNumber(options.noiseScale, null);

        if (typeof (res) === 'number' && !isNaN(res)) {
          return res;
        }
      }
    }

    const noise = options.noise;

    if (typeof (noise) !== 'object' || noise === null) {
      return fallback;
    }

    return getNumber(noise.scale, fallback);
  }

  static setNoiseScale(single, value) {
    if (!single) {
      return;
    }
    let options = single.options;

    if (!options) {
      options = single.options = {};
    }
    let noise = options.noise;
    const t = typeof (noise);

    if (t !== 'object' || t === null) {
      if (t === 'number' && !isNaN(noise)) {
        noise = options.noise = {value: noise};
      } else {
        noise = options.noise = {};
      }
    }
    noise.value = getNumber(value, 0);
  }
}
MattressDA.DEFAULT_NOISE_SCALE = DEFAULT_NOISE_SCALE;
