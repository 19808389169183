import App from './App';

/**
* @class GraphicsApp
* @extends App
* @description App that uses a graphics component (with a canvas element)
* to display graphics
*/
export default class GraphicsApp extends App {

  _getComponent() {
    return this._component;
  }

  // TODO: Should 'getDOMElement' be moved to super class 'App'?
  getDOMElement() {
    const comp = this._component;

    if (!comp) {
      return null;
    }

    return comp.getDOMElement();
  }

  getCanvas() {
    const comp = this._component;

    if (!comp) {
      return null;
    }

    return comp.getCanvas();
  }

  get domElement() {
    return this.getDOMElement();
  }

  get canvas() {
    return this.getCanvas();
  }
}
