import ContainerNode3D from '../../bgr/bgr3d/scenegraph/ContainerNode3D';
import GeometryNode3D from '../../bgr/bgr3d/scenegraph/GeometryNode3D';
import Node3D from '../../bgr/bgr3d/scenegraph/Node3D';
import MaterialGeometryNode3D from '../../bgr/bgr3d/scenegraph/MaterialGeometryNode3D';
import BD3DMaterial from './BD3DMaterial';
import Material from '../../bgr/bgr3d/material/Material';

export default class Node3DMaterialUtils {
  static _parseMaterial(mat, node, params = null) {
    if (!mat && (!params || !params.createMaterial)) {
      return null;
    }
    if (mat instanceof BD3DMaterial) {
      return mat;
    }
    let type = null;

    if (params && params.createMaterial) {
      const geometryMaterialName = this._getMaterialName(node);
      const res = params.createMaterial(mat, node, params, geometryMaterialName);

      if (res) {
        return res;
      }
    }

    if (params && params.getMaterialType) {
      type = params.getMaterialType(mat, node, params);
    }

    return new BD3DMaterial(type, mat);
  }

  static getMaterial(node) {
    if (!node) {
      return null;
    }
    if ((node instanceof MaterialGeometryNode3D) && (node.material instanceof Material)) {
      return node.material;
    }
    const ud = node.userData;

    if (!ud) {
      return null;
    }

    return ud.material;
  }

  static setMaterial(node, material, params) {
    if (!node) {
      return;
    }

    let mat = null;

    if (node instanceof MaterialGeometryNode3D) {
      if (!mat) {
        mat = this._parseMaterial(material, node, params);
      }
      node.setMaterial(mat);
    }

    let ud = node.userData;

    if (!ud && material) {
      ud = node.userData = {};
    }
    if (ud) {
      if (!mat) {
        mat = this._parseMaterial(material, node, params);
      }
      ud.material = mat;
    }
  }

  static collectMaterials(node, array = null) {

    if (!node) {
      return array;
    }
    let arr = array;

    const msettings = this.getMaterial(node);

    if (msettings) {
      if (!arr) {
        arr = [];
      }
      if (arr.indexOf(msettings, 0) < 0) {
        arr.push(msettings);
      }
    }

    if (node instanceof ContainerNode3D) {
      const children = node.children;

      if (children) {
        const num = children.length;

        for (let i = 0; i < num; ++i) {
          arr = this.collectMaterials(children[i], arr);
        }
      }
    }

    return arr;
  }

  static getMaterialName(node, params = null) {
    return this._getMaterialName(node, params);
  }

  static _getMaterialName(node, params = null) {
    if (!node) {
      return null;
    }
    if (node instanceof GeometryNode3D) {
      const res = this._getMaterialName(node.geometry, params);

      if (res) {
        return res;
      }
    }
    const ud = node.userData;

    if (!ud) {
      return null;
    }

    return ud.materialName;
  }

  /**
   * @method assignMaterial
   * @static
   * @description Traverses a node tree and assigns the same material to all GeometryNode3D instances
   * @param {Node3D} node - Node3D instance
   * @param {Material} material - Material instance
   * @param {Object} params - Optional params object
   * @return {void}
   * */
  static assignMaterial(node, material, params = null) {
    if (!node) {
      return;
    }
    if (!(node instanceof Node3D)) {
      return;
    }
    const mat = this._parseMaterial(material, node, params);

    if (node instanceof ContainerNode3D) {
      const children = node.getChildren();

      if (children) {
        const num = children.length;

        for (let i = 0; i < num; ++i) {
          this.assignMaterial(children[i], mat, params);
        }
      }
    } else if (node instanceof GeometryNode3D) {
      this.setMaterial(node, mat, params);
    }
  }

  static assignMaterials(node, materialLib, fallbackMaterial = null, params = null) {
    if (!node) {
      return;
    }
    if (!materialLib && !fallbackMaterial && !params) {
      return;
    }
    if (node instanceof ContainerNode3D) {
      const children = node.children;

      if (children) {
        const num = children.length;

        for (let i = 0; i < num; ++i) {
          this.assignMaterials(children[i], materialLib, fallbackMaterial, params);
        }
      }
    } else if (node instanceof GeometryNode3D) {
      let mat = null;

      let matName = this._getMaterialName(node, params);

      if (params && params.getMaterialFromLib) {
        mat = params.getMaterialFromLib(matName, materialLib, fallbackMaterial, node, params);
      }
      if (!mat) {
        if (!matName && params && params.getNode3DMaterialNameFallback) {
          matName = params.getNode3DMaterialNameFallback(node, params);
        }

        if (matName && materialLib) {
          mat = materialLib[matName];
        }
      }

      if (!mat) {
        mat = fallbackMaterial;
      }

      this.setMaterial(node, this._parseMaterial(mat, node, params), params);
    }
  }
}
