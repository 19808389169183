import FabricTransform from './FabricTransform';
// import Matrix4Math from '../../bgr/bgr3d/math/Matrix4Math';
import QuiltDA from '../quilt/QuiltDA';
import QuiltTypes from '../quilt/QuiltTypes';

const DEFAULT_PIVOT_X = 0.5;
const DEFAULT_PIVOT_Y = 0.5;
const DEFAULT_ALIGN_X = 0.5;
const DEFAULT_ALIGN_Y = 0.5;
const DEFAULT_LOCAL_ALIGN_X = 0.5;
const DEFAULT_LOCAL_ALIGN_Y = 0.5;

/*
function scaleMatrix(scaleX, scaleY, matrix) {
  return Matrix4Math.scaleXYZ(matrix, scaleX, scaleY, 1, matrix);
}

function rotateMatrixZ(angle, matrix) {
  return Matrix4Math.zRotate(angle, matrix, matrix);
}

function translateMatrix(x, y, matrix) {
  return Matrix4Math.translateXYZ(matrix, x, y, 0, matrix);
}

function getMatrixInverse(matrix, result) {
  return Matrix4Math.getInverse(matrix, result);
}
*/

/**
* @class QuiltTransform
* @description Transforms a matrix4 using the data of a quilt / quilt
*  example:
*  {
*     "offset": {"x": 10, "y": 20},
*     "rotation": 180
*  }
*/

export default class QuiltTransform extends FabricTransform {
  constructor() {
    super();
    this._realWidth = 1;
    this._realHeight = 1;
    this.pivotX = DEFAULT_PIVOT_X;
    this.pivotY = DEFAULT_PIVOT_Y;
    this.alignX = DEFAULT_ALIGN_X;
    this.alignY = DEFAULT_ALIGN_Y;
    this.localAlignX = DEFAULT_LOCAL_ALIGN_X;
    this.localAlignY = DEFAULT_LOCAL_ALIGN_Y;
    this.repeatX = 1;
    this.repeatY = 1;
  }

  clone() {
    const res = super.clone();

    res.pivotX = this.pivotX;
    res.pivotY = this.pivotY;
    res.alignX = this.alignX;
    res.alignY = this.alignY;
    res.repeatX = this.repeatX;
    res.repeatY = this.repeatY;

    return res;
  }
  getOffsetX() {
    let res = super.getOffsetX();
    const cfgData = this.getQuiltConfigData();
    const type = QuiltTypes.getTypeName(QuiltDA.getType(cfgData));

    if (type === QuiltTypes.GRID) {
      // Add 0.5 cm for alignment (1cm between grid lines, grid lines are placed on 0.5, 1.5, 2.5 etc.)
      // Maybe this should be fixed in the texture?
      /*
      0cm   1cm   2cm   3cm   4cm
      |     |     |     |     |
      |  :     :     :     :
      |  :     :     :     :
      |  :     :     :     :
        0.5cm       2.5cm
              1.5cm       3.5cm
      */
      res -= 0.5;
    }

    return res;
  }

  getOffsetY() {
    let res = super.getOffsetY();
    const cfgData = this.getQuiltConfigData();
    const type = QuiltTypes.getTypeName(QuiltDA.getType(cfgData));

    if (type === QuiltTypes.GRID) {
      // Add 0.5 cm for alignment (1cm between grid lines, grid lines are placed on 0.5, 1.5, 2.5 etc.)
      /*
      ===================== 0cm
      - - - - - - - - - - -     Quilt at 0.5cm
                            1cm
      - - - - - - - - - - -     Quilt at 1.5cm
                            2cm
      - - - - - - - - - - -     Quilt at 2.5cm
                            3cm
      - - - - - - - - - - -     Quilt at 3.5CM
      ...
      */
      res -= 0.5;
    }

    return res;
  }


  getRealWidth() {
    return QuiltDA.getRealWidth(this.getQuiltConfigData(), this.getQuiltData(), this._realWidth);
  }

  getRealHeight() {
    return QuiltDA.getRealHeight(this.getQuiltConfigData(), this.getQuiltData(), this._realHeight);
  }

  getPivotX() {
    return QuiltDA.getPivotX(this.getQuiltConfigData(), this.getQuiltData(), this.pivotX);
  }

  getPivotY() {
    return QuiltDA.getPivotY(this.getQuiltConfigData(), this.getQuiltData(), this.pivotY);
  }

  getAlignX() {
    return QuiltDA.getAlignX(this.getQuiltConfigData(), this.getQuiltData(), this.alignX);
  }

  getAlignY() {
    return QuiltDA.getAlignY(this.getQuiltConfigData(), this.getQuiltData(), this.alignY);
  }

  getLocalAlignX() {
    return QuiltDA.getLocalAlignX(this.getQuiltConfigData(), this.getQuiltData(), this.localAlignX);
  }

  getLocalAlignY() {
    return QuiltDA.getLocalAlignY(this.getQuiltConfigData(), this.getQuiltData(), this.localAlignY);
  }

  getRepeatX() {
    const res = QuiltDA.getResultRepeatX(this.getQuiltConfigData(), this.getQuiltData(), this.repeatX);

    if (res < 1) {
      return 1;
    }

    return res;
  }

  getRepeatY() {
    const res = QuiltDA.getResultRepeatY(this.getQuiltConfigData(), this.getQuiltData(), this.repeatY);

    if (res < 1) {
      return 1;
    }

    return res;
  }
  /*
  applyMatrix4(matrix) {
    const scaleU = this.getGeometryScaleU();
    const scaleV = this.getGeometryScaleV();

    const trU = this.getGeometryTranslateU();
    const trV = this.getGeometryTranslateV();
    const minU = this.getGeometryMinU();
    const minV = this.getGeometryMinV();
    const maxU = this.getGeometryMaxU();
    const maxV = this.getGeometryMaxV();

    const scaleX = this.getScaleX();
    const scaleY = this.getScaleY();

    const realWidth = this.getRealWidth();
    const realHeight = this.getRealHeight();

    const fabricW = realWidth * scaleX;
    const fabricH = realHeight * scaleY;

    const pivotX = this.getPivotX();
    const pivotY = this.getPivotY();

    const alignX = this.getAlignX();
    const alignY = this.getAlignY();

    const offsetX = this.getOffsetX();
    const offsetY = this.getOffsetY();

    let mtx = matrix;

    mtx = scaleMatrix(scaleU, scaleV, mtx);
    // translateMatrix(-fabricW * scaleU, -fabricH * scaleU, matrix);

    // translate offset
    mtx = translateMatrix(offsetX * scaleU, offsetY * scaleV, mtx);
    mtx = translateMatrix(-fabricW * scaleU * pivotX, -fabricH * scaleV * pivotY, mtx);
    mtx = rotateMatrixZ(this.getRotationRads(), mtx);
    mtx = translateMatrix(fabricW * scaleU * pivotX, fabricH * scaleV * pivotY, mtx);

    mtx = translateMatrix(trU, trV, mtx);
    mtx = scaleMatrix(fabricW, fabricH, mtx);
    mtx = translateMatrix(
      (minU + (maxU - minU) * alignX - fabricW * alignX) * scaleU,
      (minV + (maxV - minV) * alignY - fabricH * alignY) * scaleV,
      mtx);

    mtx = getMatrixInverse(matrix, mtx);

    return mtx;
  }
  */

  getQuiltConfigData() {
    return this.getConfigData();
  }

  setQuiltConfigData(scd) {
    this.setConfigData(scd);
  }

  get quiltConfigData() {
    return this.getQuiltConfigData();
  }

  set quiltConfigData(scd) {
    this.setQuiltConfigData(scd);
  }

  getQuiltData() {
    return this.getData();
  }

  setQuiltData(s) {
    this.setData(s);
  }

  get quiltData() {
    return this.getQuiltData();
  }

  set quiltData(v) {
    this.setQuiltData(v);
  }
}
