import Utils from '../utils/Utils';

export default class MattressConfigProperty {
  constructor(name, get, set) {
    if (name && typeof (name) === 'object') {
      this._name = name.name;
      this._getter = name.get;
      this._setter = name.set;
    } else {
      this._name = name;
      this._getter = get;
      this._setter = set;
    }
  }

  getName() {
    return this._name;
  }

  get name() {
    return this.getName();
  }

  getProperty(mattressConfig, propertyData, fallback) {
    const h = this._getter;

    if (!h) {
      return fallback;
    }

    return h(mattressConfig, propertyData, fallback);
  }

  setProperty(mattressConfig, propertyData, value) {
    const h = this._setter;

    if (!h) {
      return;
    }

    h(mattressConfig, propertyData, value);
  }
}
MattressConfigProperty.KIND = Utils.newSymbol('mattress_property_kind');
