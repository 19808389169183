import Vector3 from './Vector3';

const DIM = 4;
const W_COORD_INDEX = 3;

export default class Vector4 extends Vector3 {
  constructor(x = 0, y = 0, z = 0, w = 1) {
    super(x, y, z);
    this._w = w;
  }

  getW() {
    return this.getCoord(W_COORD_INDEX);
  }

  setW(value) {
    this.setCoord(W_COORD_INDEX, value);
  }

  get w() {
    return this.getW();
  }

  set w(value) {
    this.setW(value);
  }

  get [W_COORD_INDEX]() {
    return this.getCoord(W_COORD_INDEX);
  }

  set [W_COORD_INDEX](value) {
    this.setCoord(W_COORD_INDEX, value);
  }

  getDimension() {
    return DIM;
  }
}
