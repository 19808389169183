import {
  parseValue,
  castValue,
  getFactoryTypes
} from '../utils';
import BD3DContainerNode3D from '../../scenegraph/BD3DContainerNode3D';
import Node3D from '../../../bgr/bgr3d/scenegraph/Node3D';
import Transform3D from '../../../bgr/bgr3d/transform/Transform3D';

export default {
  create(data, id, session) {
    let {children, transform} = data;

    if (children && (children[0] && !(children[0] instanceof Node3D))) {
      children = parseValue(children, id, session);
    }
    transform = castValue(transform, Transform3D, id, session);

    const res = new BD3DContainerNode3D(children, transform);

    return res;
  },
  update(object, data, id, session) {
    const {children, transform} = data;

    object.selectable = data.selectable;
    object.selectionModes = data.selectionModes;

    object.children = children;
    object.transform = transform;
  },
  callMethod(object, data, method, params, options) {
    // default:
    const children = object ? object.getChildren() : null;
    const num = children ? children.length : 0;
    const parser = options ? options.parser : null;
    const factories = getFactoryTypes(options);

    let result = null;

    for (let i = 0; i < num; ++i) {
      const child = children[i];
      const childData = parser.getDataByObject(child);
      const childType = childData ? childData['@type'] : null;
      const factory = (childType && factories) ? factories[childType] : null;

      if (factory && factory.callMethod) {
        result = factory.callMethod(child, childData, method, params, options);
      }
    }

    return result;
  },
  getProperty(object, data, property, params) {
    // Delegate setProperty to children
    const children = object.getChildren();
    const numChildren = children ? children.length : 0;
    const parser = params ? params.parser : null;
    const factories = getFactoryTypes(params);

    for (let i = 0; i < numChildren; i += 1) {
      const child = children[i];
      const childData = parser.getDataByObject(child);
      const childType = childData ? childData['@type'] : null;
      const factory = (childType && factories) ? factories[childType] : null;

      if (factory && factory.getProperty) {
        const res = factory.getProperty(child, childData, property, params);

        if (res !== null && typeof (res) !== 'undefined') {
          return res;
        }
      }
    }

    return null;
  },
  setProperty(object, data, property, value, params) {
    // TODO: set properties of this object

    // Delegate setProperty to children
    const children = object.getChildren();
    const numChildren = children ? children.length : 0;
    const parser = params ? params.parser : null;
    const factories = getFactoryTypes(params);

    for (let i = 0; i < numChildren; i += 1) {
      const child = children[i];
      const childData = parser.getDataByObject(child);
      const childType = childData ? childData['@type'] : null;
      const factory = (childType && factories) ? factories[childType] : null;

      if (factory && factory.setProperty) {
        factory.setProperty(child, childData, property, value, params);
      }
    }
  }
};
