
export default class HandleErrorType {
  constructor(id, name, description) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.type = 'handleErrorType';
  }
}
HandleErrorType.INVALID_SINGLE = new HandleErrorType('901', 'err_invalid_single', 'Can\'t apply handle: invalid single.');
HandleErrorType.INVALID_HANDLE_TYPE = new HandleErrorType('902', 'err_invalid_handle_type', 'Invalid handle type.');
HandleErrorType.INVALID_HANDLE_BORDER = new HandleErrorType('903', 'err_invalid_handle_border', 'Invalid handle border.');

// Vertical error types
HandleErrorType.VERTICAL_ATTACHMENTS_ERROR = new HandleErrorType('1001', 'err_verticalhandle_attachments', 'Vertical handles require a mattress with at least two tape or two piping components.');
HandleErrorType.VERTICAL_CURVEDBORDER_ERROR = new HandleErrorType('1002', 'err_verticalhandle_curvedborder', 'Vertical handles cannot be placed on a mattress with a curved border.');

HandleErrorType.VERTICAL_TOPBOTTOM_RADIUS_ERROR = new HandleErrorType('1003', 'err_verticalhandle_topbottomradius',
  'Vertical handles can\'t be placed on a mattress with a top radius greater than its top height or a bottom radius greater than its bottom height');

HandleErrorType.VERTICAL_BORDER_COMPONENTS_ERROR = new HandleErrorType('1004', 'err_verticalhandle_border_components', 'Vertical handles can only be placed on mattresses with at least one border fabric or 3d border component.');
HandleErrorType.VERTICAL_BORDER_ERROR = new HandleErrorType('1005', 'err_verticalhandle_border', 'Vertical handles cannot be placed on one specific border.');


// Horizontal error types
HandleErrorType.HORIZONTAL_BORDER_COMPONENT_BASE_ERROR = new HandleErrorType('2001', 'err_horizontalhandle_border_component_base',
  'Could not find a valid attachment base for the horizontal handles (border fabric or 3d border with a minimum height of 5 cm or 1.97 inches).');

HandleErrorType.HORIZONTAL_CURVEDBORDER_ERROR = new HandleErrorType('2002', 'err_horizontalhandle_curvedborder', 'Horizontal handles cannot be placed on a mattress with a curved border.');

// Suitcase error types
HandleErrorType.SUITCASE_BASE_ERROR = new HandleErrorType('3001', 'err_suitcasehandle_base', 'Suitcase handles need a border configuration with piping-fabric-piping.');
HandleErrorType.SUITCASE_CURVEDBORDER_ERROR = new HandleErrorType('3002', 'err_suitcasehandle_curvedborder', 'Suitcase handles cannot be placed on a mattress with a curved border.');
//
// Pocket error types
HandleErrorType.POCKET_BASE_ERROR = new HandleErrorType('4001', 'err_pockethandle_base', 'Pocket handles need a border configuration with piping-fabric-piping.');
HandleErrorType.POCKET_CURVEDBORDER_ERROR = new HandleErrorType('4002', 'err_pockethandle_curvedborder', 'Pocket handles cannot be placed on a mattress with a curved border.');
